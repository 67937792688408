import { call, put, takeEvery } from 'redux-saga/effects';
import { PRINT_COCM, PRINT_AFFIDAVIT, PRINT_CUSTOMER_MANDATE } from 'action_creators/accessories';

import { postPrintCocm, downloadAffidavit, downloadCustomerMandate } from 'services/accessories';

import { sendToPrinter } from 'services/user';
import { actions } from 'redux/reducers/accessories';

import { AFFIDAVIT_TO_PRINTER, CUSTOMER_MANDATE_TO_PRINTER } from 'components/layout/constants';
import { notification } from 'antd';

const genericError = 'Something went wrong. Please try again later';

export function* printCocmCertificateSaga() {
  try {
    const response = yield call(postPrintCocm);
    if (response?.data) {
      sendToPrinter(response, 'printcontract', '_CocmCertificate');
      yield put(
        actions.logSuccess(
          'Clean Money Certificate printed sucessfully , printing receipt.'
        )
      );
      yield put(actions.setIsSuccessMsgVisible(true));
      yield put(actions.initSubmit(false));
    }
  } catch (e) {
    yield put(actions.logError(e?.response?.data?.error || genericError));
    yield put(actions.initSubmit(false));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* printAffidavit(action) {
  try {
    const response = yield call(downloadAffidavit, action.payload);
    if (response?.data) {
      sendToPrinter(response, 'printcontract', '_Affidavit');
      notification.success({
        message: AFFIDAVIT_TO_PRINTER,
        duration: 5,
      });
    }
  } catch (e) {
    notification.error({
      message: e?.response?.data?.error || genericError,
      duration: 5,
    });
  }
}

export function* printCustomerMandate() {
  try {
    const response = yield call(downloadCustomerMandate);
    if (response?.data) {
      sendToPrinter(response, 'printcontract', '_CustomerMandate');
      notification.success({
        message: CUSTOMER_MANDATE_TO_PRINTER,
        duration: 5,
      });
    }
  } catch (e) {
    notification.error({
      message: e?.response?.data?.error || genericError,
      duration: 5,
    });
  }
}

export function* watchPrintCocmCertificateSaga() {
  yield takeEvery(PRINT_COCM, printCocmCertificateSaga);
}

export function* watchPrintAffidavitSaga() {
  yield takeEvery(PRINT_AFFIDAVIT, printAffidavit);
}

export function* watchPrintCustomerMandateSaga() {
  yield takeEvery(PRINT_CUSTOMER_MANDATE, printCustomerMandate);
}
