import React, { useState, useEffect } from 'react';
import VirtualGrid from '../../../common/virtualGrid/index';
import { COLORS_CODE_TABLE, GRID_NO_URL } from 'globalConstants';
import ProgressTick from 'components/common/ProgressCheck';
import TableRow from '../../formatedCell';
import ImageLoader from 'components/common/ImageLoader';
import { SERVICE_NAME } from 'components/checkout/constants';

const CheckoutPurchaseReturn = (props: any) => {
  const rows = props?.item || [];

  const [columns] = useState([
    {
      name: 'servicePurchaseId',
      title: 'Purchase No.',
      getCellValue: (row: any) =>
        ProgressTick(
          row?.purchaseReturn?.response?.servicePurchaseId,
          row?.serviceStatusText
        ),
    },
    {
      name: 'serviceName',
      title: 'Service',
      getCellValue: () => SERVICE_NAME.PURCHASE_RETURN,
    },
    {
      name: 'purchaseValue',
      title: 'Purchase Amount',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.purchaseReturn?.request?.item?.purchaseValue,
    },
    {
      name: 'oneMonthFee',
      title: 'Fee',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.purchaseReturn?.request?.item?.oneMonthFee,
    },
    {
      //insert a blank column to create space before To Pay
      name: 'blankColumn',
      title: ' ',
      getCellValue: () => '',
    },
    {
      name: 'toPay',
      title: 'To Pay',
      type: 'currency',
      getCellValue: (row: any) => row?.purchaseReturn?.request?.item?.toPay,
    },
    {
      name: 'toTake',
      title: 'To Take',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.purchaseReturn?.request?.item?.toTakeAmount,
    },
    {
      name: '',
      title: '',
      getCellValue: (row: any) => '',
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'servicePurchaseId', width: '16%' },
    { columnName: 'serviceName', width: '15%' },
    { columnName: 'purchaseValue', width: '15%', align: 'right' },
    { columnName: 'oneMonthFee', width: '15%', align: 'right' },
    { columnName: 'blankColumn', width: '9%' },
    { columnName: 'toPay', width: '10%', align: 'right' },
    { columnName: 'toTake', width: '10%', align: 'right' },
    { columnName: '', width: '6%', align: 'right' },
  ]);

  const [formattedColumns] = useState([
    'toPay',
    'toTake',
    'purchaseValue',
    'oneMonthFee',
  ]);

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        formattedColumns={formattedColumns}
        rowData={rows}
        tableColumnExtensionsProps={tableColumnExtensions}
        headerBackground={COLORS_CODE_TABLE.PURCHASE_RETURN}
        cellComponent={TableRow}
        expandableComponent={PurchaseExpandableTable}
        detailRowIconPlacement={true}
      />
    </>
  );
};

export const PurchaseExpandableTable = ({ row }: any) => {
  const [rowData, setRowData] = useState<Array<any>>([]);

  useEffect(() => {
    const rowData = row?.purchaseReturn?.request?.item?.purchaseItems.filter(
      (x: any) => x.selectedForReturn
    );
    setRowData(rowData);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [columns] = useState([
    {
      name: 'itemImage',
      title: 'Item Image',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row.id}?isThumnails=false`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'barcode',
      title: 'Barcode',
      getCellValue: (row: any) => row?.barcode,
    },
    {
      name: 'description',
      title: 'Description',
      type: 'popover-no-api',
      getCellValue: (row: any) => row?.description,
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) => `${row?.weight?.toFixed(2)}g`,
    },
    {
      name: 'amount',
      title: 'Amount',
      type: 'currency',
      getCellValue: (row: any) => row?.amount,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'itemImage', width: 130 },
    { columnName: 'barcode', width: 180 },
    { columnName: 'description', width: 270 },
    { columnName: 'weight', width: 120, align: 'right' },
    { columnName: 'amount', width: 120, align: 'right' },
  ]);

  const [formattedColumns] = useState(['amount']);

  return (
    <>
      <div className="expandable-table-rows">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          formattedColumns={formattedColumns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={rowData}
          rowSelectionEnable={true}
          onSelectionChange={() => undefined}
        />
      </div>
    </>
  );
};

export default CheckoutPurchaseReturn;
