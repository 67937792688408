import React from 'react';
import { Route, Routes } from 'react-router-dom';

import CustomerSmartSearch from 'components/layout/CustomerSmartSearch';
import CustomerSummary from 'components/customer/customerSummary';
import HomePage from 'components/layout/HomePage';
import NewCustomer from 'components/customer/newCustomer';
import Pledge from 'components/pledge';
import RetailSales from 'components/retail';
import ChequeCashing from 'components/chequeCashing';
import PledgeDetails from 'components/pickupRenew';
import Basket from 'components/basket';
import Checkout from 'components/checkout';
import TravelMoney from 'components/travelmoney';
import WesternUnion from 'components/westernunion';
import PaymentContainer from 'components/payment/PaymentContainer';
import CashManagement from 'components/cashManagement';
import PledgeManagement from 'components/pledgemanagement';
import InventoryManagement from 'components/inventoryManagement';
import Admin from 'components/admin';
import ValuationCenter from 'components/valuationCenter';
import ConsumerDuty from 'components/consumerDuty';

import { ROUTE_CONFIG } from 'globalConstants';

const {
  CUSTOMER,
  SUMMARY,
  MODE,
  NEW,
  PLEDGE,
  PICKUPRENEW,
  RETAILSALES,
  BASKET,
  CHEQUE_CASHING,
  CASH_MGMT,
  TRAVEL_MONEY,
  WESTERN_UNION,
  PAYMENT,
  PLEDGE_MANAGEMENT,
  INVENTORY_MANAGEMENT,
  VALUATION_CENTER,
  ADMIN,
  CUSTOMERSEARCH,
  CONSUMER_DUTY,
} = ROUTE_CONFIG;

const routes = [
  {
    path: `/${BASKET}`,
    component: Basket,
  },
  {
    path: `/${PICKUPRENEW}`,
    component: PledgeDetails,
  },
  {
    path: `/${RETAILSALES}`,
    component: RetailSales,
  },
  {
    path: `/${PLEDGE}`,
    component: Pledge,
  },
  {
    path: `/${CUSTOMER}/${SUMMARY}/:customerId`,
    component: CustomerSummary,
  },
  {
    path: `/${CUSTOMER}/${NEW}`,
    component: NewCustomer,
  },
  {
    path: `/${CUSTOMER}/:customerId/:${MODE}`,
    component: NewCustomer,
  },
  {
    path: '/checkout',
    component: Checkout,
  },
  {
    path: `/${TRAVEL_MONEY}`,
    component: TravelMoney,
  },
  {
    path: `/${WESTERN_UNION}`,
    component: WesternUnion,
  },
  {
    path: `/${CHEQUE_CASHING}`,
    component: ChequeCashing,
  },
  {
    path: `/${CASH_MGMT}`,
    component: CashManagement,
  },
  {
    path: `/${PAYMENT}`,
    component: PaymentContainer,
  },
  {
    path: `/${PLEDGE_MANAGEMENT}`,
    component: PledgeManagement,
  },
  {
    path: `/${INVENTORY_MANAGEMENT}`,
    component: InventoryManagement,
  },
  {
    path: `/${ADMIN}`,
    component: Admin,
  },
  {
    path: `/${VALUATION_CENTER}`,
    component: ValuationCenter,
  },
  {
    path: `/${CONSUMER_DUTY}`,
    component: ConsumerDuty,
  },
  {
    path: `/${CUSTOMERSEARCH}`,
    component: CustomerSmartSearch,
  },
  {
    path: '/',
    component: HomePage,
  },
];

const Routers = () => {
  return (
    <Routes>
      {routes.map((route, i) => (
        <Route key={i} path={route.path} element={<route.component />} />
      ))}
    </Routes>
  );
};

export default Routers;
