import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';

import rootSaga from '../sagas';
import reducer from './reducers';

const sagaMiddleware = createSagaMiddleware();

function createStore(preloadedState) {
  const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        ...getDefaultMiddleware({ thunk: false }),
        sagaMiddleware,
      ]),
    preloadedState,
  });
  sagaMiddleware.run(rootSaga);

  return store;
}

export default createStore;
