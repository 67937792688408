import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Modal, Col, notification } from 'antd';
import { useSelector } from 'react-redux';
import { Button } from 'components/common/Button';
import {
  BUTTONS_LABEL,
  CASE_NOTES_UPDATE,
  NOTES_REQUIRED,
  VULNERABILITYORIGINATOR,
  VULNERABILITYOWNER,
} from '../constants';
import Textarea from 'components/common/TextArea';
import { GENERIC_ERROR, PROFANITY_CHECK } from 'globalConstants';
import {
  postCaseSummaryUpdateNotes,
  getCustomerNotes,
} from 'services/consumerDuty';
import ControlledAlert from 'components/common/ControlledAlert';

const StyledModal = styled(Modal)`
  & .title {
    font-size: var(--font-size-20);
    font-weight: var(--font-weight-500);
  }

  & .heading {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-600);

    span {
      color: var(--brick-red);
    }
  }
`;

const StyledRow = styled(Row)`
  padding: 10px 0 15px 0;
`;

interface ProfanityError {
  profanity: boolean;
  badWords: Array<string>;
}

const CaseUpdateModal = ({ visible, onClose }: any) => {
  const [notes, setNotes] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [customerSupportId, setCustomerSupportId] = useState<boolean>(false);
  const [workQueueTaskId, setworkQueueTaskId] = useState<any>('');

  const [profanityError, setProfanityError] = useState<ProfanityError>({
    profanity: false,
    badWords: [''],
  });
  const [isProfanity, setProfanity] = useState<boolean>(false);

  const onNotesChange = (event: any) => {
    const { value } = event.target;
    setNotes(value);
  };

  const {
    customer: { customer },
    consumerDuty: { ssteamRequestTaskId },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
      consumerDuty: state.consumerDuty,
    };
  });

  const getCustomerCaseNotes = async () => {
    try {
      const response = await getCustomerNotes(
        customer?.customerId,
        NOTES_REQUIRED.CUSTOMER_SUPPORT_ID
      );
      if (response?.data) {
        setCustomerSupportId(
          response?.data?.customerSupport?.customerSupportId
        );
        setworkQueueTaskId(response?.data?.customerSupport?.workQueueReference);
      }
    } catch (error: any) {
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  const handleProfanity = (profanity: boolean, badWords: Array<string>) => {
    setProfanityError({ profanity, badWords });
  };

  const onSubmitButtonClick = async () => {
    const params = {
      customerSupportId: customerSupportId,
      body: notes,
      subject: '',
      VulnerabilityOriginator: VULNERABILITYORIGINATOR.STORE,
      VulnerabilityOwner: VULNERABILITYOWNER.SSTEAM,
    };
    try {
      if (profanityError.profanity) {
        setProfanity(true);
      } else {
        setLoading(true);
        const response = await postCaseSummaryUpdateNotes(params);
        if (response?.data) {
          setLoading(false);
          onClose();
        }
      }
    } catch (e: any) {
      setLoading(false);
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  useEffect(() => {
    getCustomerCaseNotes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledModal
      open={visible}
      width={790}
      mask={false}
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
      style={{ top: '35vh', right: '15vw' }}
      footer={
        <Row justify="space-between">
          <Button itemProp="secondary" onClick={onClose}>
            {BUTTONS_LABEL.CANCEL}
          </Button>
          <Button
            disabled={!notes}
            onClick={onSubmitButtonClick}
            loading={loading}
          >
            {BUTTONS_LABEL.SUBMIT}
          </Button>
        </Row>
      }
    >
      <Row className="title">Case Note Update</Row>
      <StyledRow className="heading">
        <span>
          {`Open Case File ID:  
          ${ssteamRequestTaskId ? ssteamRequestTaskId : workQueueTaskId}`}
        </span>
      </StyledRow>
      <Row className="heading">{CASE_NOTES_UPDATE}</Row>
      <Row>
        <Col span={24}>
          <Textarea
            value={notes}
            rows={8}
            maxLength={500}
            onChange={onNotesChange}
            profanityExists={handleProfanity}
            autoComplete="on"
            spellCheck
            profanityCheck
          />
        </Col>
      </Row>
      {isProfanity && (
        <ControlledAlert
          id="caseUpdate"
          width={600}
          visible={isProfanity}
          message={PROFANITY_CHECK.REMOVE_PROFANITY}
          secondaryMessage={
            <>
              {PROFANITY_CHECK.PROFANITY_WORDS}
              <b>&nbsp;{profanityError.badWords.join(', ')}</b>
            </>
          }
          onClick={() => {
            setProfanity(false);
          }}
          yesButtonText={BUTTONS_LABEL.REMOVE_PROFANITY}
          isCancelBtn={false}
        />
      )}
    </StyledModal>
  );
};

export default CaseUpdateModal;
