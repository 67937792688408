import React, { useState } from 'react';
import VirtualGrid from 'components/common/virtualGrid/index';
import { URL_TYPE } from 'globalConstants';
import ImageLoader from 'components/common/ImageLoader';
import { NOT_AVAILABLE_LABEL } from '../constants';
import { currencyFormat } from 'utils/util';
import { Tooltip } from 'antd';

interface RefundExchangeProps {
  rows: Array<object>;
  onSelectionChange: (selectedRows: Array<number | string>) => void;
}
const RefundExchangeSummary = ({
  rows,
  onSelectionChange,
}: RefundExchangeProps) => {
  const [columns] = useState([
    {
      name: 'image',
      title: 'Image',
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row?.stockItemId}`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
      allowSorting: false,
    },
    {
      name: 'barcode',
      title: 'Barcode',
      getCellValue: (row: any) =>
        row.barcode === null ? NOT_AVAILABLE_LABEL : row?.barcode,
    },
    {
      name: 'description',
      title: 'Description',
      getCellValue: (row: any) =>
        row.description === null ? (
          NOT_AVAILABLE_LABEL
        ) : (
          <Tooltip
            placement="top"
            color={'var(--blue)'}
            title={row.description}
          >
            {row.description}
          </Tooltip>
        ),
    },
    {
      name: 'listValue',
      title: 'List Value',
      allowSorting: true,
      getCellValue: (row: any) =>
        '£' + currencyFormat(row?.listValue?.toFixed(2)),
    },
    {
      name: 'discount',
      title: 'Discount',
      getCellValue: (row: any) =>
        '£' + currencyFormat(row?.discount?.toFixed(2)),
    },
    {
      name: 'amountPaid',
      title: 'Amount Paid',
      getCellValue: (row: any) =>
        '£' + currencyFormat(row?.saleValue?.toFixed(2)),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'image', width: 100 },
    { columnName: 'barcode', width: 130 },
    { columnName: 'description' },
    { columnName: 'listValue', width: 120, align: 'right' },
    { columnName: 'discount', align: 'right' },
    { columnName: 'amountPaid', align: 'right' },
  ]);
  const [sortingStateColumnExtensions] = useState([
    { columnName: 'listValue', sortingEnabled: true },
  ]);
  const [rowSelectionEnable] = useState<boolean>(true);

  return (
    <>
      <VirtualGrid
        urlType={URL_TYPE.NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        sortingStateColumnExtensions={sortingStateColumnExtensions}
        rowData={rows}
        rowSelectionEnable={rowSelectionEnable}
        onSelectionChange={onSelectionChange}
      />
    </>
  );
};

export default RefundExchangeSummary;
