import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { GRID_NO_URL } from 'globalConstants';
import { Layout, Row, Col } from 'antd';
import {
  VIEW_AUCTION_DETAILS_TITLE,
  VIEW_AUCTION_CLOSED_TITLE,
  TABLE_HEAD_COLOR,
} from 'components/pledgemanagement/constants';
import FooterButtons from 'components/common/footerButtons';
import ClosedAuctionSummary from '../auctionDetailsSummary/closedAuctionSummary';
import VirtualGrid from 'components/common/virtualGrid/index';
import { BUTTON_LABELS } from 'components/pledgemanagement/constants';

const StyledContentLayout = styled(Layout)`
  background: var(--white);
  padding: 15px 30px;
  border-radius: 5px;
`;

const StyledDetailsRow = styled(Row)`
  padding-bottom: 10px;
`;

const StyledTableWrapper = styled.div`
  height: calc(100vh - 407px);
`;

interface ClosedAuctionDetailsProps {
  onExit: () => void;
}
const ClosedAuctionDetails = ({ onExit }: ClosedAuctionDetailsProps) => {
  const {
    pledgeManagement: { auctionItemDetails, isLoading },
  } = useSelector((state: any) => {
    return {
      pledgeManagement: state.pledgeManagement,
    };
  });

  const { auctionLoans, ...auctionDetailsTable } = auctionItemDetails;

  const [columns] = useState([
    {
      name: 'auctionDate',
      title: 'Auction Date',
      getCellValue: (row: any) => row?.auctionDate,
    },
    {
      name: 'bookDate',
      title: 'Book Date',
      getCellValue: (row: any) => row?.bookDate,
    },
    {
      name: 'collectionDate',
      title: 'Collection Date',
      getCellValue: (row: any) => row?.collectionDate,
    },
    {
      name: 'lastMailDate',
      title: 'Last Mail Sent',
      getCellValue: (row: any) => row?.lastMailDate,
    },
    {
      name: 'reserveValue',
      title: 'Reserve Value',
      getCellValue: (row: any) => row?.reserveValue,
    },
    {
      name: 'numberOfLots',
      title: 'No of Lots',
      getCellValue: (row: any) => row?.numberOfLots,
    },

    {
      name: 'returnDate',
      title: 'Return Date',
      getCellValue: (row: any) => row?.returnDate,
    },
    {
      name: 'auctionStatusText',
      title: 'Status',
      getCellValue: (row: any) => row?.auctionStatusText,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'auctionDate', width: '12%' },
    { columnName: 'bookDate', width: '12%' },
    { columnName: 'collectionDate', width: '12%' },
    { columnName: 'lastMailDate', width: '12%' },
    { columnName: 'reserveValue', width: '12%' },
    { columnName: 'numberOfLots', width: '12%' },
    { columnName: 'returnDate', width: '12%' },
    { columnName: 'auctionStatusText', width: '12%' },
  ]);

  const getRowId = (row: any) => row?.auctionId;

  return (
    <>
      <StyledContentLayout>
        <Row className="title-row">
          <Col span={24}>
            {VIEW_AUCTION_DETAILS_TITLE}
            {VIEW_AUCTION_CLOSED_TITLE}
          </Col>
        </Row>
        <StyledDetailsRow>
          <VirtualGrid
            urlType={GRID_NO_URL}
            columnsProps={columns}
            tableColumnExtensionsProps={tableColumnExtensions}
            rowData={[auctionDetailsTable]}
            getRowId={getRowId}
            headerBackground={TABLE_HEAD_COLOR.CLOSED}
          />
        </StyledDetailsRow>
        <StyledTableWrapper>
          <ClosedAuctionSummary auctionLoans={auctionLoans} />
        </StyledTableWrapper>
      </StyledContentLayout>
      <FooterButtons
        enableExtraClass
        enableBackButton={true}
        isBackButtonOnly={true}
        backButtonText={BUTTON_LABELS.BACK}
        isLoading={isLoading}
        onBackButtonClick={onExit}
        isBackButtonDisable={false}
      />
    </>
  );
};

export default ClosedAuctionDetails;
