import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Modal, Row, Form, Input, notification } from 'antd';
import { EXPENSE_IN_OUT_LABELS } from '../constants';
import { Button } from 'components/common/Button';
import styled from 'styled-components';
import AddExpense from 'components/cashManagement/ExpenseInOut/addExpense/AddExpense';
import ExpenseInOutSummary from './viewExpenseInOut/ExpenseInOutSummary';
import { getPettyCashCategories, postPettyCash } from 'services/cashManagement';
import { currencyFormat } from 'utils/util';
import { cacheStaticDataWithArgs } from 'utils/cacheServices';
import { cloneDeep } from 'lodash';

const StyledModal = styled(Modal)`
  & .ant-modal-header {
    background: var(--white);
    & .ant-modal-title {
      padding-bottom: 20px;
    }
  }
  & .ant-modal-body {
    background: var(--white);
    padding: 0;
  }
`;

const StyledTableWrapper = styled.div`
  height: 185px;
  margin-top: 75px;
  border: 1px solid var(--light-gray);
  border-bottom: none;
`;

const StyledSummaryDiv = styled.div`
  text-align: right;
  font-weight: 700;
  padding: 6px 20px;
  border: 1px solid var(--light-gray);
  border-top: 1px solid var(--mystic);
`;

const StyledRow = styled(Row)`
  margin-top: 25px;
`;

interface ExpenseInOutPopupProps {
  visible: boolean;
  setExpenseInOutPopupVisible: () => void;
}

const ExpenseInOutPopup = ({
  visible,
  setExpenseInOutPopupVisible,
}: ExpenseInOutPopupProps) => {
  const [form] = Form.useForm();

  const { selectedStore, user } = useSelector((state: any) => state.user);

  const [outCategoryList, setOutCategoryList] = useState<Array<any>>([]);
  const [inCategoryList, setInCategoryList] = useState<Array<any>>([]);
  const [expenseList, setExpenseList] = useState<Array<any>>([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getPettyCashCategory(EXPENSE_IN_OUT_LABELS.DIRECTION.IN.value);
    getPettyCashCategory(EXPENSE_IN_OUT_LABELS.DIRECTION.OUT.value);
  }, []);

  const getPettyCashCategory = async (direction: any) => {
    try {
      const response = await cacheStaticDataWithArgs(
        `PETTY_CASH-${direction}`,
        getPettyCashCategories,
        direction
      );

      if (response?.data) {
        const list = cloneDeep(response?.data);

        list.forEach((x: any) => {
          x.description = x.description.split('-')[1];
        });

        if (direction === EXPENSE_IN_OUT_LABELS.DIRECTION.OUT.value)
          setOutCategoryList(list);
        else setInCategoryList(list);
      }
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.error,
        duration: 3,
      });
    }
  };

  useEffect(() => {
    let amountSum = 0;
    expenseList.forEach((row) => {
      amountSum =
        row.direction === EXPENSE_IN_OUT_LABELS.DIRECTION.OUT.value
          ? amountSum - +row.amount
          : amountSum + +row.amount;
    });
    setTotalAmount(amountSum);
  }, [expenseList]);

  const onExpenseItemSelectionChange = (params: any) => {
    console.log('working', params); //TODO EDIT Selected item
  };

  const onAddClick = (expenseItem: any) => {
    setExpenseList((prevState) => [...prevState, expenseItem]);
  };

  const onDeleteExpenseItem = (id: number) => {
    const tempExpenseList = [...expenseList];

    const expenseItemIndex = expenseList.findIndex((x) => x.id === id);
    tempExpenseList.splice(expenseItemIndex, 1);
    setExpenseList(tempExpenseList);
  };

  const onSubmitClick = async () => {
    try {
      const params = expenseList.map((x) => {
        return {
          storeId: selectedStore?.storeId,
          activityId: x.category,
          userId: user?.userId,
          amount: +x.amount,
          linkedTransactionReference: '',
          cashTransactionImages: x.cashTransactionImages,
          note: x.notes,
        };
      });

      const authorisationHeaders = {
        authorisationUserName: form.getFieldValue('employeeId'),
        authorisationPassword: form.getFieldValue('password'),
      };

      setIsLoading(true);
      const response = await postPettyCash(params, authorisationHeaders);
      if (response?.data) {
        notification.success({
          message: EXPENSE_IN_OUT_LABELS.NOTIFICATION.SUCCESS,
          duration: 3,
        });
        setExpenseInOutPopupVisible();
      }
    } catch (e: any) {
      setIsLoading(false);
      notification.error({
        message: e?.response?.data?.error,
        duration: 3,
      });
    }
  };

  const onCancelClick = () => {
    setExpenseInOutPopupVisible();
  };

  return (
    <>
      <StyledModal
        title="Petty Cash"
        open={visible}
        width={1200}
        centered
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        footer={[
          <Row gutter={24} justify="space-between" key="expenseInOut">
            <Col>
              <Button
                itemProp="secondary"
                key="cancelExpenseInOut"
                onClick={onCancelClick}
              >
                Close
              </Button>
            </Col>
            <Col>
              <Button
                disabled={expenseList.length === 0}
                form="expenseInOutForm"
                loading={isLoading}
                key="expenseInOutForm"
                htmlType="submit"
              >
                Submit
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Row style={{ padding: '0px 30px 50px' }}>
          <Col span={8}>
            <Row>
              <Col span={24}>
                <AddExpense
                  inCategoryList={inCategoryList}
                  outCategoryList={outCategoryList}
                  onAddClick={onAddClick}
                />
              </Col>
            </Row>
          </Col>
          <Col span={15} offset={1}>
            <Row>
              <Col span={24}>
                <StyledTableWrapper className="item-added-table">
                  <ExpenseInOutSummary
                    expenseList={expenseList}
                    onDeleteExpenseItem={onDeleteExpenseItem}
                    onExpenseItemSelectionChange={onExpenseItemSelectionChange}
                  />
                </StyledTableWrapper>
                <StyledSummaryDiv>
                  Total Amount : {currencyFormat(totalAmount, true)}
                </StyledSummaryDiv>
              </Col>
            </Row>
            <StyledRow>
              <Col span={24}>
                <Form
                  id="expenseInOutForm"
                  onFinish={onSubmitClick}
                  layout="vertical"
                  form={form}
                  className="cash-transfer-form"
                >
                  <Row>
                    <Col span={8}>
                      <Form.Item
                        label={EXPENSE_IN_OUT_LABELS.EMPLOYEE_ID}
                        name="employeeId"
                        className="label-control"
                        rules={[
                          {
                            required: true,
                            message:
                              EXPENSE_IN_OUT_LABELS.ERROR_MSG.EMPLOYEE_ID,
                          },
                        ]}
                      >
                        <Input
                          placeholder="e.g. 11899"
                          className="input-control"
                          autoComplete="new-password"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8} offset={1}>
                      <Form.Item
                        label={EXPENSE_IN_OUT_LABELS.PASSWORD}
                        name="password"
                        className="label-control"
                        rules={[
                          {
                            required: true,
                            message: EXPENSE_IN_OUT_LABELS.ERROR_MSG.PASSWORD,
                          },
                        ]}
                      >
                        <Input
                          type="password"
                          className="input-control"
                          autoComplete="new-password"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </StyledRow>
          </Col>
        </Row>
      </StyledModal>
    </>
  );
};

export default ExpenseInOutPopup;
