import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Row, Col, Input, notification, Switch } from 'antd';
import styled from 'styled-components';
import { isEmpty, isNull } from 'lodash';
import {
  calculateTotalSumByKey,
  joinValues,
  filterBasketItemsOnServiceType,
} from 'utils/util';
import { Button as CustomButton } from '../common/Button';
import {
  PLEDGE_LABEL,
  PLEDGE_LOAN_DETAILS,
  ERROR_MSG,
  AGREEMENT_OPTIONS,
  TOGGLE_SWITCH,
  PURCHASE_ERROR_MSG,
  NOTE,
  AUCTION_ALERT_MSG,
  AUCTION_LOCK_ALERT_MSG,
} from './constants';
import CustomerHeader from '../customer/customerHeader';
import PledgeDetailsTable from './PledgeDetailsTable';
import {
  getPledgeAgreement,
  getPledgeAgreementItems,
  getPurchaseDetail,
  getPurchaseItemDetail,
} from 'services/pledge';
import { BASKET_STATUS, ROUTE_CONFIG, AUCTION_STATUS } from 'globalConstants';
import { CREATE_BASKET_FOR_PICKUP_OR_PURCHASE } from 'action_creators/basket';
import { actions } from 'redux/reducers/pickupRenew';
import { actions as basketActions } from 'redux/reducers/basket';
import { actions as customerActions } from 'redux/reducers/customer';
import { getCustomer } from 'services/customer';
import { BASKET_SERVICE_TYPE } from 'components/basket/constants';
import CustomerPopup from 'components/customer/CustomerPopup';
import { GET_PAID_PLEDGE_DETAILS } from 'action_creators/pickupRenew';
import AttentionPopup from './AttentionPopup';
import PaidPaymentDetailsPopup from './PaidPaymentDetailsPopup';
import WaiveInterestPopup from './WaiveInterestPopup';
import PledgePickupLoanTable from './PledgePickupLoanTable';
import PartialPaymentPopup from './PartialPaymentPopup';
import PaydownPopup from './PaydownPopup';
import ChangeOwnershipPopup from './ChangeOwnershipPopup';
import ChangeOwnershipSearchPopup from './ChangeOwnershipSearchPopup';
import AffidavitPopup from './AffidavitPopup';
import FooterButtons from 'components/common/footerButtons';
import RecallPopup from 'components/common/recall';
import {
  RECALL_MSG,
  RECALL_TITLE,
  PURCHASE_STATUS_ID,
} from 'components/customer/constants';
import { PLEDGE_STATUS } from 'components/pledge/constants';

import { getBasket } from 'services/basket';
import './index.less';
import {
  HelpPopoverInfo,
  StyledHelpPopoverInfoForButtons,
} from 'components/common/HelpPopoverInfo';
import PledgeHistoryPopup from './PledgeHistoryPopup';
import WithdrawPopup from './WithdrawPopup';
import PurchaseReturnDetailTable from './PurchaseReturnDetailTable';
import PurchaseReturnCostTable from './PurchaseReturnCostTable';
import ControlledAlert from 'components/common/ControlledAlert';
import { debounce } from 'lodash';

const StyledMainWrapper = styled(Row)`
  background: var(--white);
  border: 0.6px solid var(--linkwater);
  border-bottom: none;
  box-shadow: 0px 1px 10px rgba(1, 78, 169, 0.2);
  border-radius: 5px;
  margin: 15px 15px 0px;
  color: var(--black);
  display: flex;
  flex-direction: column;
`;

const StyledMainContainer = styled(Col)`
  padding: 15px 20px 0px 20px;
`;

const StyledRow = styled(Row)`
  background: var(--cyan);
  padding: 10px 30px;
  position: relative;
  left: -20px;
  width: calc(100% + 40px);
`;

const StyledTotalValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const StyledText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  flex-basis: 95%;
`;

const StyledCol = styled(Col)`
  padding: 15px 0 0 0;
`;

const StyledRowWrapper = styled(Row)`
  display: block;
  height: calc(100vh - 430px);
`;

const StyledRemoveCol = styled(Col)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const StyledTableContainerRow = styled(Row)`
  margin: 15px 10px;
`;

const StyledAgreementCol = styled(Col)`
  display: flex;
  align-items: center;
  font-weight: 500;
`;

const PledgeDetails = () => {
  const {
    customer: {
      customer,
      isRedirectToOtherPage,
      isStoreEnquiryMode,
      isPhoneEnquiryMode,
    },
    pickupRenew: {
      pledgeAgreement,
      selectedAgreementRowId,
      purchaseReturn,
      selectedPurchaseRowId,
      isProceedToBasket,
    },
    basket: { basket, isLoading: isBasketLoading },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
      pickupRenew: state.pickupRenew,
      basket: state.basket,
    };
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isPurchaseSearch, setIsPurchaseSearch] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>();
  const [copySearchValue, setCopySearchValue] = useState<string>();
  const [firstDigitLapseValue, setFirstDigitLapseValue] = useState<any>();
  const [lastDigitLapseValue, setLastDigitLapseValue] = useState<any>();
  const [isAutoSearch, setAutoSearch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAttentionPopupVisible, setAttentionPopupVisible] =
    useState<boolean>(false);
  const [agreementData, setAgreementData] = useState<any>({}); // state being used to capture data before setting in redux
  const [customerOnAgreement, setCustomerOnAngreement] = useState({}); // to show customer data on popup
  const [purchaseData, setPurchaseData] = useState<any>({}); // state being used to capture data before setting in redux
  const [isPaidPaymentPopupVisible, setPaidPaymentPopupVisible] =
    useState<boolean>(false);
  const [isWaiveInterestPopupVisible, setWaiveInterestPopupVisible] =
    useState<boolean>(false);
  const [isPartialPaymentPopupVisible, setPartialPaymentPopupVisible] =
    useState<boolean>(false);
  const [agreementRowData, setAgreementRowData] = useState<any>({});
  const [isPaydownPopupVisible, setPaydownPopupVisible] =
    useState<boolean>(false);
  const [
    isChangeOwnershipSearchPopupVisible,
    setChangeOwnershipSearchPopupVisible,
  ] = useState<boolean>(false);
  const [
    isChangeOwnershipAttnPopupVisible,
    setChangeOwnershipAttnPopupVisible,
  ] = useState<boolean>(false);
  const [isConfiscatedAttnPopupVisible, setConfiscatedAttnPopupVisible] =
    useState<boolean>(false);
  const [isAffidavitPopupVisible, setAffidavitPopupVisible] =
    useState<boolean>(false);
  const [isRecallPopupVisible, setRecallPopupVisible] =
    useState<boolean>(false);
  const [recallMessage, setRecallMessage] = useState<string>('');
  const [recallTitle, setRecallTitle] = useState<string>('');
  const [isPledgeHistoryPopupVisible, setPledgeHistoryPopupVisible] =
    useState<boolean>(false);
  const [isWithdrawPopupVisible, setWithdrawPopupVisible] =
    useState<boolean>(false);
  const [clearAction, setClearAction] = useState<any>({});
  const [takeFromCustomer, setTakeFromCustomer] = useState<string>('0.00');
  const [isAuctionAlertPopupVisible, setAuctionAlertPopupVisible] =
    useState<boolean>(false);
  const [isAggreementInAuction, setAggreementInAuction] =
    useState<boolean>(false);
  const [isClosedPledge, setIsClosedPledge] = useState<boolean>(false);
  const [isAuctionLockedForPickup, setIsAuctionLockedForPickup] =
    useState<boolean>(false);
  const [isAuctionLockedPopup, setIsAuctionLockedPopup] =
    useState<boolean>(false);

  const defaultConditionForGrids =
    pledgeAgreement?.length === 0 && purchaseReturn?.length > 0;

  const {
    street,
    town,
    property,
    county,
    postcode,
    houseName,
    area,
    firstNames,
    middleName,
    surname,
  } = customer;

  const onSwitchChange = (checked: boolean) => {
    setIsPurchaseSearch(checked);
  };

  const getCustomerNameForPurchase = async (data: any) => {
    try {
      const getCustomerOnAngreement = await getCustomer(data?.customerId);
      return Promise.resolve(getCustomerOnAngreement?.data);
    } catch (e: any) {
      notification.error({
        message: e?.message,
        duration: 5,
      });
      console.log(e);
    }
  };

  const onAgreementSearch = useCallback(
    async (trimmedSearchValue: any) => {
      if (
        pledgeAgreement.some(
          (o: any) => o.loanNumber.toString() === trimmedSearchValue
        )
      ) {
        notification.error({
          message: ERROR_MSG.AGREEMENT_ALREADY_LISTED,
          duration: 5,
        });
      } else if (
        filterBasketItemsOnServiceType(
          basket,
          BASKET_SERVICE_TYPE.PICKUPRENEW
        )[0]?.pickupRenew?.request?.items?.some(
          (o: any) => o.loanNumber.toString() === trimmedSearchValue
        )
      ) {
        notification.error({
          message: ERROR_MSG.AGREEMENT_EXISTS_IN_BASKET,
          duration: 5,
        });
      } else {
        try {
          setLoading(true);
          const response = await getPledgeAgreement(trimmedSearchValue);
          if (response?.data) {
            try {
              const itemsResponse = await getPledgeAgreementItems(
                response?.data?.originalLoanId
              );
              if (itemsResponse?.data)
                response.data.pledgeItems = itemsResponse?.data?.items || [];
              else response.data.pledgeItems = [];
            } catch (e: any) {
              setLoading(false);
              response.data.pledgeItems = [];
              notification.error({
                message: ERROR_MSG.ITEMS_NOT_FOUND,
                duration: 5,
              });
            } finally {
              setIsClosedPledge(!response?.data?.isValidForProcess);
              if (isEmpty(customer)) {
                try {
                  const getCustomerOnAngreement = await getCustomer(
                    response?.data?.customerId
                  );
                  setLoading(false);
                  setCustomerOnAngreement(getCustomerOnAngreement?.data);
                  setAgreementData(response.data);
                } catch (e: any) {
                  setLoading(false);
                  notification.error({
                    message: e?.message,
                    duration: 10,
                  });
                  console.log(e);
                }
              } else {
                setLoading(false);
                setCustomerOnAngreement({});
                setSearchValue('');
                if (response?.data?.customerId === customer?.customerId) {
                  response.data = {
                    ...response.data,
                    customerPresent: true,
                  };
                  setAgreementData(response.data);
                  setAuctionAlertPopupVisible(true);
                  setIsAuctionLockedPopup(true);
                  if (response.data.confiscated === 0) {
                    dispatch(actions.addPledgeAgreement(response.data)); // customer in front - so dispatching here
                  } else setConfiscatedAttnPopupVisible(true);
                } else if (
                  response?.data?.customerId !== customer?.customerId
                ) {
                  response.data = {
                    ...response.data,
                    customerPresent: false,
                  };
                  setAgreementData(response.data);
                  setAttentionPopupVisible(true);
                  setAuctionAlertPopupVisible(true);
                  setIsAuctionLockedPopup(true);
                }
              }
            }
          }
        } catch (e: any) {
          setLoading(false);
          notification.error({
            message: ERROR_MSG.AGREEMENT_NOT_FOUND,
            duration: 10,
          });
          console.log(e);
        }
      }
    },
    [basket, customer, dispatch, pledgeAgreement]
  );

  const onPurchaseSearch = useCallback(
    async (trimmedSearchValue: any) => {
      if (
        purchaseReturn?.some(
          (o: any) => o.purchaseNumber.toString() === trimmedSearchValue
        )
      ) {
        notification.error({
          message: PURCHASE_ERROR_MSG.ALREADY_LISTED,
          duration: 3,
        });
      } else if (
        filterBasketItemsOnServiceType(
          basket,
          BASKET_SERVICE_TYPE.PURCHASERETURN
        )[0]?.purchaseReturn?.request?.items?.some(
          (o: any) => o.purchaseNumber.toString() === trimmedSearchValue
        )
      ) {
        notification.error({
          message: PURCHASE_ERROR_MSG.EXISTS_IN_BASKET,
          duration: 3,
        });
      } else {
        try {
          setLoading(true);
          const response = await getPurchaseDetail(trimmedSearchValue);
          if (response?.data) {
            try {
              const itemsResponse = await getPurchaseItemDetail(
                trimmedSearchValue
              );
              if (itemsResponse?.data)
                response.data.purchaseItems = itemsResponse?.data?.items || [];
              else response.data.purchaseItems = [];
            } catch (e: any) {
              setLoading(false);
              response.data.purchaseItems = [];
              notification.error({
                message: PURCHASE_ERROR_MSG.ITEMS_NOT_FOUND,
                duration: 3,
              });
            } finally {
              if (isEmpty(customer)) {
                const getCustomerOnAgreement = await getCustomerNameForPurchase(
                  response?.data
                );
                setLoading(false);
                response.data.customerName = joinValues(
                  [
                    getCustomerOnAgreement?.firstNames,
                    getCustomerOnAgreement?.surname,
                  ],
                  true
                );
                setPurchaseData(response.data);
                setCustomerOnAngreement(getCustomerOnAgreement);
              } else {
                setLoading(false);
                setCustomerOnAngreement({});
                setSearchValue('');
                if (response?.data?.customerId === customer?.customerId) {
                  response.data = {
                    ...response.data,
                    customerPresent: true,
                    customerName: joinValues(
                      [customer?.firstNames, customer?.surname],
                      true
                    ),
                  };

                  setPurchaseData(response.data);
                  //TODO - check confiscations applicable or not
                  if (response.data.purchaseStatus !== PURCHASE_STATUS_ID.PFI) {
                    dispatch(actions.addPurchaseReturn(response.data));
                  } else setAttentionPopupVisible(true);
                } else if (
                  response?.data?.customerId !== customer?.customerId
                ) {
                  const getCustomerOnAgreement =
                    await getCustomerNameForPurchase(response?.data);
                  response.data = {
                    ...response.data,
                    customerPresent: false,
                    customerName: joinValues(
                      [
                        getCustomerOnAgreement?.firstNames,
                        getCustomerOnAgreement?.surname,
                      ],
                      true
                    ),
                  };
                  setPurchaseData(response.data);
                  if (response.data.purchaseStatus !== PURCHASE_STATUS_ID.PFI) {
                    dispatch(actions.addPurchaseReturn(response.data));
                  } else setAttentionPopupVisible(true);
                }
              }
            }
          }
        } catch (e: any) {
          setLoading(false);
          notification.error({
            message: PURCHASE_ERROR_MSG.NOT_FOUND,
            duration: 10,
          });
          console.log(e);
        }
      }
    },
    [basket, customer, dispatch, purchaseReturn]
  );

  useEffect(() => {
    if (lastDigitLapseValue && firstDigitLapseValue) {
      const difference = lastDigitLapseValue - firstDigitLapseValue;
      if (difference < 125 && difference > 0) {
        setAutoSearch(true);
      }
    }
  }, [firstDigitLapseValue, lastDigitLapseValue]);

  useEffect(() => {
    const trimmedSearchValue = searchValue?.trim();

    if (trimmedSearchValue && trimmedSearchValue?.length > 1 && isAutoSearch) {
      setAutoSearch(false);
      setFirstDigitLapseValue(null);
      setLastDigitLapseValue(null);
      !isPurchaseSearch
        ? onAgreementSearch(trimmedSearchValue)
        : onPurchaseSearch(trimmedSearchValue);
    }
  }, [
    isPurchaseSearch,
    isAutoSearch,
    onAgreementSearch,
    onPurchaseSearch,
    searchValue,
  ]);

  const setData = (customerPresent: boolean) => {
    const updatedAgreement = {
      ...agreementData,
      customerPresent: customerPresent,
    };
    const updatedPurchase = {
      ...purchaseData,
      customerPresent: customerPresent,
    };
    if (agreementData && !isPurchaseSearch) {
      if (agreementData.confiscated === 0) {
        dispatch(actions.addPledgeAgreement(updatedAgreement));
      } else setConfiscatedAttnPopupVisible(true);
    }
    if (purchaseData && isPurchaseSearch) {
      if (purchaseData.purchaseStatus !== PURCHASE_STATUS_ID.PFI) {
        dispatch(actions.addPurchaseReturn(updatedPurchase));
        setIsPurchaseSearch(false);
      } else setAttentionPopupVisible(true);
    }
  };

  const onCustomerPopupCancel = () => {
    setSearchValue('');
    setData(false);
    setAuctionAlertPopupVisible(true);
    setIsAuctionLockedPopup(true);
  };

  const onCustomerPopupOk = async (customer: any) => {
    setSearchValue('');
    dispatch(customerActions.getCustomerResponse(customer)); //customer session
    // check live basket for customer available or not , if yes then redirect to basket / checkout
    try {
      const response = await getBasket(customer?.customerId);
      if (response?.data) {
        if (response?.data?.basket?.basketId !== null) {
          dispatch(basketActions.getBasket(response?.data));
          setRecallPopupVisible(true);
          if (response?.data?.basket?.basketStatus === BASKET_STATUS.PENDING) {
            setRecallMessage(RECALL_MSG.START_BASKET_JOURNEY);
            setRecallTitle(RECALL_TITLE.BASKET);
          } else if (
            response?.data?.basket?.basketStatus ===
              BASKET_STATUS.INITIALISED ||
            response?.data?.basket?.basketStatus === BASKET_STATUS.SUCCESSFUL ||
            response?.data?.basket?.basketStatus === BASKET_STATUS.FAILED
          ) {
            setRecallMessage(RECALL_MSG.START_CHECKOUT_JOURNEY);
            setRecallTitle(RECALL_TITLE.BASKET);
          }
        } else {
          setAuctionAlertPopupVisible(true);
          setIsAuctionLockedPopup(true);
          checkForSurplus();
        }
      } else {
        setAuctionAlertPopupVisible(true);
        setIsAuctionLockedPopup(true);
        checkForSurplus();
      }
    } catch (e: any) {
      // not redirecting to basket / checkout on error, stay on the same page and BAU
      checkForSurplus();
    }
  };

  const checkForSurplus = () => {
    setData(true);
    dispatch(customerActions.initSurplusCheck(true));
  };

  const onPaidPaymentClick = (row: any) => {
    const params = {
      loanId: row.id, //loanId
    };
    setPaidPaymentPopupVisible(true);
    dispatch({
      type: GET_PAID_PLEDGE_DETAILS,
      payload: { params },
    });
  };

  const onWaiveInterestHyperlinkClick = (row: any) => {
    dispatch(actions.setSelectedAgreementRow([row.id]));
    setWaiveInterestPopupVisible(true);
  };

  const onPartialPaymentClick = (params: any) => {
    setAgreementRowData(params?.agreement);
    setPartialPaymentPopupVisible(true);
  };

  const onPaydownClick = (params: any) => {
    setAgreementRowData(params?.agreement);
    setPaydownPopupVisible(true);
  };

  const onWithdrawClick = (params: any) => {
    setAgreementRowData(params?.agreement);
    setWithdrawPopupVisible(true);
  };

  const onAffidavitClick = (params: any) => {
    dispatch(actions.setSelectedAgreementRow([params.id]));
    setAgreementRowData(params);
    setAffidavitPopupVisible(true);
  };

  const onAffiOnlyClick = (params: any) => {
    const row = params.agreement;
    const updatedAgreement = {
      ...row,
      toTakeAmount: row.affidavitAmount,
      newLoanAmount: 0,
    };
    dispatch(actions.updatePledgeAgreement(updatedAgreement));
  };

  const onViewPledgeHistoryClick = () => {
    if (selectedAgreementRowId?.length > 0) {
      setPledgeHistoryPopupVisible(true);
    }
  };

  const onRemovePledgeClick = () => {
    if (selectedAgreementRowId?.length > 0) {
      dispatch(actions.removePledgeAgreement());
    }
    if (selectedPurchaseRowId?.length > 0) {
      dispatch(actions.removePurchaseReturn());
    }
  };

  const customerOwnershipMessage = () => {
    const fullName = joinValues([customer.firstNames, customer.surname], true);
    return (
      <>
        <p>
          {`This agreement is not under ${fullName}'s name. You will only be able to
        pick up or partial pay against the loan in the current state.`}
        </p>
        <p>
          {`If you wish to renew or pay down please transfer ownership to ${fullName}.`}
        </p>
      </>
    );
  };

  const auctionAlertMsgString = () => {
    return (
      <>
        <p>
          {AUCTION_ALERT_MSG.COMMON_MSG_1}
          {agreementData?.auctionDate
            ? agreementData.auctionDisplayDate
            : 'DD/MM/YYY'}
          {agreementData?.auctionStatus === AUCTION_STATUS.LIVE_AUCTION
            ? AUCTION_ALERT_MSG.AT_AUCTION_MSG
            : AUCTION_ALERT_MSG.AT_STORE_MSG}
        </p>
        <p>{AUCTION_ALERT_MSG.COMMON_MSG_2}</p>
        {agreementData?.auctionStatus === AUCTION_STATUS.LIVE_AUCTION && (
          <p>{AUCTION_ALERT_MSG.AT_AUCTION_MSG_2}</p>
        )}
        <p>{AUCTION_ALERT_MSG.COMMON_MSG_3}</p>
      </>
    );
  };

  const auctionLockedAlertMsgString = () => {
    return (
      <>
        <p>
          {AUCTION_LOCK_ALERT_MSG.COMMON_MSG_1}
          {agreementData?.auctionDate
            ? agreementData.auctionDisplayDate
            : 'DD/MM/YYY'}
          {AUCTION_LOCK_ALERT_MSG.IS_LOCKED_MSG}
        </p>
        <p>{AUCTION_LOCK_ALERT_MSG.COMMON_MSG_2}</p>
      </>
    );
  };

  const checkCustomerOwnership = (data: any) => {
    if (data.confiscated === 0) {
      dispatch(actions.addPledgeAgreement(data));
    } else setConfiscatedAttnPopupVisible(true);
  };

  const onConfiscatedVulnerableOk = (data: any) => {
    if (!isPurchaseSearch) {
      dispatch(actions.addPledgeAgreement(data));
    } else dispatch(actions.addPurchaseReturn(data));
  };

  const onChangeCustomerClick = () => {
    if (isEmpty(customer)) {
      setChangeOwnershipSearchPopupVisible(true);
    } else {
      setChangeOwnershipAttnPopupVisible(true);
    }
  };

  const disableChangeCustomer = () => {
    if (!isStoreEnquiryMode && !isPhoneEnquiryMode) {
      if (selectedAgreementRowId?.length === 1) {
        const index = pledgeAgreement.findIndex(
          (x: any) => x.id === selectedAgreementRowId[0]
        );
        if (
          pledgeAgreement[index].isValidForProcess === true &&
          pledgeAgreement[index].customerPresent === false &&
          pledgeAgreement[index].confiscated === 0 &&
          pledgeAgreement[index].selectedActionType !==
            AGREEMENT_OPTIONS[0].id &&
          pledgeAgreement[index].selectedActionType !== AGREEMENT_OPTIONS[3].id // should be an open pledge, not confiscated or vulnerable, action should not be pickup / partial as CO is not applicable on these 2 operations
        ) {
          return false;
        }
      }
    }

    return true;
  };

  const onProceedToBasketClick = (pushToBasket = true) => {
    if (pledgeAgreement?.length > 0 || purchaseReturn?.length > 0) {
      if (pledgeAgreement?.length) {
        const action = pledgeAgreement.some((item: any) => {
          return item.selectedActionType === null;
        });
        if (action) {
          notification.error({
            message: ERROR_MSG.ACTION_NOT_SELECTED,
            description: '',
            duration: 5,
          });
          return;
        }
      }
      if (purchaseReturn?.length) {
        const itemSelectedForReturn = purchaseReturn.every((item: any) => {
          return item.toTakeAmount !== 0;
        });
        if (!itemSelectedForReturn) {
          notification.error({
            message: PURCHASE_ERROR_MSG.SELECT_AN_ITEM,
            description: '',
            duration: 5,
          });
          return;
        }
      }
      dispatch({
        type: CREATE_BASKET_FOR_PICKUP_OR_PURCHASE,
      });
    } else if (basket?.basketId !== null && pushToBasket) {
      navigate(`/${ROUTE_CONFIG.BASKET}`);
    }
  };

  const onConfirmExitOnlyClick = () => {
    dispatch(actions.clearItems());
  };

  useEffect(() => {
    if (!isRedirectToOtherPage && !isEmpty(customer)) {
      if (selectedAgreementRowId?.length === 1) {
        const index = pledgeAgreement.findIndex(
          (x: any) => x.id === selectedAgreementRowId[0]
        );
        const agreement = pledgeAgreement[index];
        const updatedAgreement = {
          ...agreement,
          customerPresent: true,
          changeOwnership: {
            ...agreement.changeOwnership,
            newCustomerId: customer.customerId,
            newCustomerName: joinValues(
              [customer.firstNames, customer.surname],
              true
            ),
            isOwnershipChanged: true,
            customer: {
              street,
              town,
              property,
              county,
              postcode,
              houseName,
              area,
              firstNames,
              middleName,
              surname,
            },
          },
        };
        dispatch(actions.updatePledgeAgreement(updatedAgreement));
        dispatch(actions.setSelectedAgreementRow([]));
        dispatch(customerActions.setRedirectToOtherPage(true));
      }
    }
  }, [isRedirectToOtherPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (basket.basketId !== null) {
      //update agremeent with customerPresent when coming from basket page
      if (pledgeAgreement?.length > 0) {
        const updatedAgreementWithCustomerPresent = pledgeAgreement.map(
          (item: any) => {
            if (
              customer.customerId === item.customerId ||
              (item.changeOwnership.newCustomerId !== null &&
                item.changeOwnership.customerId !==
                  item.changeOwnership.newCustomerId)
            ) {
              item = { ...item, customerPresent: true };
            } else item = { ...item, customerPresent: false };

            return item;
          }
        );
        dispatch(
          actions.addPledgeAgreement(updatedAgreementWithCustomerPresent)
        );
      }

      if (purchaseReturn?.length > 0) {
        const updatedAgreementWithCustomerPresent = purchaseReturn.map(
          (item: any) => {
            if (customer.customerId === item.customerId) {
              item = { ...item, customerPresent: true };
            }
            return item;
          }
        );
        dispatch(
          actions.addPurchaseReturn(updatedAgreementWithCustomerPresent)
        );
      }
    }
  }, [basket]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const pledgeToTake = calculateTotalSumByKey(
      pledgeAgreement,
      'toTakeAmount'
    );
    const purchaseToTake = calculateTotalSumByKey(
      purchaseReturn,
      'toTakeAmount'
    );
    const totalToTake = pledgeToTake + purchaseToTake;
    setTakeFromCustomer(totalToTake.toFixed(2));
  }, [pledgeAgreement, purchaseReturn]);

  useEffect(() => {
    if (isProceedToBasket) {
      navigate(`/${ROUTE_CONFIG.BASKET}`);
      dispatch(actions.clearItems());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProceedToBasket]);

  useEffect(() => {
    if (!searchValue) {
      setCopySearchValue('');
      setAutoSearch(false);
    }
  }, [searchValue]);

  const debouncedSearchValue = React.useRef(
    debounce((value: any) => {
      setSearchValue(value);
    }, 1000)
  ).current;

  const onKeyUp = (event: any) => {
    const currentTimeStamp = Date.now();
    if (event.target.value.length === 1) {
      setFirstDigitLapseValue(currentTimeStamp);
    } else if (event.target.value.length > 1) {
      setLastDigitLapseValue(currentTimeStamp);
    }
  };

  const agreementChangeHandler = (
    value: any,
    isSearchClicked?: boolean,
    event?: any
  ) => {
    if (event) {
      console.log(
        isSearchClicked,
        event.keyCode,
        event,
        ' EVENT',
        event.keyName,
        event.key
      );
    }

    setCopySearchValue(value);
    debouncedSearchValue(value);
    if (isSearchClicked && value) {
      setSearchValue(value);
      setAutoSearch(true);
    }
  };

  useEffect(() => {
    if (
      !isNull(agreementData?.isAuctionLocked) &&
      agreementData?.isAuctionLocked
    ) {
      setIsAuctionLockedForPickup(true);
    }
    if (
      (agreementData?.auctionStatus === AUCTION_STATUS.LIVE_AUCTION ||
        agreementData?.auctionStatus === AUCTION_STATUS.LIVE_STORE) &&
      !agreementData?.isAuctionLocked
    ) {
      setAggreementInAuction(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreementData]);

  const onExitandSaveClick = () => {
    if (pledgeAgreement?.length) {
      const action = pledgeAgreement.some((item: any) => {
        return item.selectedActionType === null;
      });
      if (action) {
        notification.error({
          message: ERROR_MSG.ACTION_NOT_SELECTED_ON_EXIT_AND_SAVE,
          description: '',
          duration: 5,
        });
        return;
      }
    } else {
      return true;
    }
  };

  const checkIsExitAndSaveEnabled = () => {
    return pledgeAgreement?.every((item: any) => {
      if (item.selectedActionType === null) return false;
      else return true;
    });
  };

  const isExitOnly = useMemo(() => {
    const isExit = pledgeAgreement?.every((item: any) => {
      if (item.selectedActionType === null) {
        return true;
      } else return false;
    });
    return (
      isExit ||
      (agreementData?.pledgeItems && !agreementData?.pledgeItems?.length)
    );
  }, [agreementData, pledgeAgreement]);

  return (
    <>
      <CustomerHeader
        title="Pickup/Renew"
        buttonsOption={true}
        helpSection="PICKUPRENEW"
      />
      <StyledMainWrapper>
        <StyledMainContainer span={24} className="pickup-renew-container">
          <Row>
            <StyledAgreementCol span={3}>
              <span
                style={{
                  padding: '10px 13px 10px 0px',
                  color: !isPurchaseSearch ? 'var(--black)' : 'var(--gray)',
                }}
              >
                {TOGGLE_SWITCH.PLEDGE}
              </span>
              <Switch
                className="pickup-switch"
                onChange={onSwitchChange}
                checked={isPurchaseSearch}
              />
              <span
                style={{
                  padding: '10px 0px 10px 13px',
                  color: isPurchaseSearch ? 'var(--black)' : 'var(--gray)',
                }}
              >
                {TOGGLE_SWITCH.PURCHASE}
              </span>
            </StyledAgreementCol>
            <Col span={5}>
              <Input.Group className="pickup-renew-input-group">
                <Input.Search
                  allowClear
                  placeholder="Search Agreement"
                  loading={loading}
                  onKeyUp={onKeyUp}
                  value={copySearchValue}
                  className="input-search"
                  onSearch={(value, event) =>
                    agreementChangeHandler(value, true, event)
                  }
                  onChange={(e) => agreementChangeHandler(e.target.value)}
                  autoFocus
                />
              </Input.Group>
              <StyledHelpPopoverInfoForButtons
                linkedID={`PICKUPRENEW_SEARCH`}
                position="rightTop"
                helpPosition="AFTER"
                attachedComp="BUTTON"
              ></StyledHelpPopoverInfoForButtons>
            </Col>
          </Row>
          <StyledRowWrapper>
            {pledgeAgreement?.length > 0 || !defaultConditionForGrids ? (
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Row>
                      <StyledCol span={24} className="pledge-details-grid">
                        <PledgeDetailsTable
                          rows={pledgeAgreement}
                          onPaidPaymentClick={onPaidPaymentClick}
                          onWaiveInterestClick={onWaiveInterestHyperlinkClick}
                          onPartialPaymentClick={onPartialPaymentClick}
                          onPaydownClick={onPaydownClick}
                          onWithdrawClick={onWithdrawClick}
                          onAffidavitClick={onAffidavitClick} //hyperlink action
                          onAffiOnlyClick={onAffiOnlyClick} // dropdown action
                          clearAction={clearAction}
                        />
                      </StyledCol>
                    </Row>
                  </Col>
                </Row>
              </Col>
            ) : null}

            {purchaseReturn?.length > 0 ? (
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Row>
                      <StyledCol span={24} className="pledge-details-grid">
                        <PurchaseReturnDetailTable rows={purchaseReturn} />
                      </StyledCol>
                    </Row>
                  </Col>
                </Row>
              </Col>
            ) : null}
          </StyledRowWrapper>

          <StyledTableContainerRow justify="space-between">
            <StyledRemoveCol span={5}>
              {pledgeAgreement?.length > 0 || !defaultConditionForGrids ? (
                <CustomButton
                  itemProp="secondary"
                  onClick={onViewPledgeHistoryClick}
                >
                  {PLEDGE_LABEL.VIEW_PLEDGE_HISTORY}
                </CustomButton>
              ) : null}
              <CustomButton itemProp="secondary" onClick={onRemovePledgeClick}>
                {PLEDGE_LABEL.REMOVE}
              </CustomButton>
              <span style={{ height: '32px' }}>
                <HelpPopoverInfo
                  linkedID={`PICKUPRENEW_REMOVE`}
                  position="leftTop"
                  style={{ height: '420px' }}
                ></HelpPopoverInfo>
              </span>
            </StyledRemoveCol>
            {purchaseReturn?.length > 0 ? (
              <Col span={6}>
                <PurchaseReturnCostTable allPurchases={purchaseReturn} />
              </Col>
            ) : null}
            {pledgeAgreement?.length > 0 || !defaultConditionForGrids ? (
              <Col span={13}>
                <PledgePickupLoanTable
                  isClosedPledge={isClosedPledge}
                  allAgreements={pledgeAgreement}
                />
              </Col>
            ) : null}
          </StyledTableContainerRow>

          <StyledRow>
            <Col span={24}>
              <Row>
                <Col offset={18} span={6}>
                  <StyledTotalValue className="pickup-input">
                    <StyledText>
                      {PLEDGE_LOAN_DETAILS.TAKE_FROM_CUSTOMER}
                    </StyledText>
                    <Input
                      className="total-value"
                      disabled
                      addonBefore={'£'}
                      placeholder="0.00"
                      value={takeFromCustomer}
                    />
                  </StyledTotalValue>
                </Col>
              </Row>
            </Col>
          </StyledRow>

          {!isEmpty(customerOnAgreement) && (
            <CustomerPopup
              customer={customerOnAgreement}
              onCustomerPopupCancel={onCustomerPopupCancel}
              onCustomerPopupOk={onCustomerPopupOk}
              additionalMessage={NOTE.PICKUP}
            />
          )}
          {isAttentionPopupVisible && (
            <AttentionPopup
              visible={isAttentionPopupVisible}
              setAttentionPopupVisible={() => setAttentionPopupVisible(false)}
              agreementData={!isPurchaseSearch ? agreementData : purchaseData}
              message={
                !isPurchaseSearch
                  ? customerOwnershipMessage()
                  : NOTE.PURCHASE_PFI_ITEM
              }
              onAttentionPopupOk={
                !isPurchaseSearch
                  ? checkCustomerOwnership
                  : () => setIsPurchaseSearch(false)
              }
            />
          )}
          {isPaidPaymentPopupVisible && (
            <PaidPaymentDetailsPopup
              visible={isPaidPaymentPopupVisible}
              setPaidPaymentPopupVisible={() =>
                setPaidPaymentPopupVisible(false)
              }
            />
          )}
          {isWaiveInterestPopupVisible && (
            <WaiveInterestPopup
              visible={isWaiveInterestPopupVisible}
              setWaiveInterestPopupVisible={() =>
                setWaiveInterestPopupVisible(false)
              }
            />
          )}
          {isPartialPaymentPopupVisible && (
            <PartialPaymentPopup
              visible={isPartialPaymentPopupVisible}
              setPartialPaymentPopupVisible={() => {
                setPartialPaymentPopupVisible(false);
              }}
              row={agreementRowData}
              setSelectedActionClear={() => setClearAction(agreementRowData)}
            />
          )}
          {isPaydownPopupVisible && (
            <PaydownPopup
              visible={isPaydownPopupVisible}
              setPaydownPopupVisible={() => {
                setPaydownPopupVisible(false);
              }}
              row={agreementRowData}
              setSelectedActionClear={() => setClearAction(agreementRowData)}
            />
          )}
          {isChangeOwnershipAttnPopupVisible && (
            <ChangeOwnershipPopup
              visible={isChangeOwnershipAttnPopupVisible}
              setChangeOwnershipAttnPopupVisible={() =>
                setChangeOwnershipAttnPopupVisible(false)
              }
            />
          )}
          {isChangeOwnershipSearchPopupVisible && (
            <ChangeOwnershipSearchPopup
              visible={isChangeOwnershipSearchPopupVisible}
              setChangeOwnershipSearchPopupVisible={() =>
                setChangeOwnershipSearchPopupVisible(false)
              }
            />
          )}
          {isConfiscatedAttnPopupVisible && (
            <AttentionPopup
              visible={isConfiscatedAttnPopupVisible}
              setAttentionPopupVisible={() =>
                setConfiscatedAttnPopupVisible(false)
              }
              agreementData={!isPurchaseSearch ? agreementData : purchaseData}
              message={
                agreementData?.confiscated === PLEDGE_STATUS.CONFISCATED
                  ? NOTE.CONFISCATED_AGREEMENT
                  : NOTE.VULNERABLE_AGREEMENT
              }
              onAttentionPopupOk={onConfiscatedVulnerableOk}
            />
          )}
          {isAffidavitPopupVisible && (
            <AffidavitPopup
              visible={isAffidavitPopupVisible}
              setAffidavitPopupVisible={() => setAffidavitPopupVisible(false)}
              affidavitData={agreementRowData}
              setSelectedActionClear={(params) => setClearAction(params)}
            />
          )}
          {isRecallPopupVisible && (
            <RecallPopup
              visible={isRecallPopupVisible}
              setRecallPopupVisible={(visibility: boolean) =>
                setRecallPopupVisible(visibility)
              }
              recallMessage={recallMessage}
              recallTitle={recallTitle}
            />
          )}
          {isPledgeHistoryPopupVisible && (
            <PledgeHistoryPopup
              visible={isPledgeHistoryPopupVisible}
              setPledgeHistoryPopupVisible={() =>
                setPledgeHistoryPopupVisible(false)
              }
            />
          )}
          {isWithdrawPopupVisible && (
            <WithdrawPopup
              visible={isWithdrawPopupVisible}
              setWithdrawPopupVisible={() => setWithdrawPopupVisible(false)}
              row={agreementRowData}
            />
          )}
          {isAggreementInAuction &&
            isAuctionAlertPopupVisible &&
            !isAttentionPopupVisible && (
              <ControlledAlert
                closable={true}
                visible={isAggreementInAuction}
                isCancelBtn={false}
                alertMessage={'Alert!'}
                message={auctionAlertMsgString()}
                contentMessage={agreementData?.loanNumber}
                onClick={() => {
                  setAggreementInAuction(false);
                  setAuctionAlertPopupVisible(false);
                }}
                yesButtonText={'Ok'}
              />
            )}

          {!isAttentionPopupVisible &&
            isAuctionLockedForPickup &&
            isAuctionLockedPopup && (
              <ControlledAlert
                closable={true}
                visible={isAuctionLockedForPickup}
                isCancelBtn={false}
                alertMessage={'Alert!'}
                message={auctionLockedAlertMsgString()}
                contentMessage={agreementData?.loanNumber}
                onClick={() => {
                  setIsAuctionLockedForPickup(false);
                }}
                yesButtonText={'Ok'}
              />
            )}

          <Row>
            <Col span={24}>
              <FooterButtons
                proceedButtonText={PLEDGE_LABEL.PROCEED_TO_BASKET}
                isExitOnly={isExitOnly}
                isLoading={isBasketLoading}
                onProceedClick={onProceedToBasketClick}
                isDisabled={isClosedPledge}
                extraButtonText={PLEDGE_LABEL.CHANGE_CUSTOMER}
                extraButtonOnClick={onChangeCustomerClick}
                extraButtonEanable={true}
                extraButtonItemProp="secondary"
                extraButtonDisabled={disableChangeCustomer()}
                onConfirmExitClick={onConfirmExitOnlyClick}
                helpSection="PICKUPRENEW"
                isSaveJourney={true}
                isExitAndSaveEnabled={checkIsExitAndSaveEnabled()}
                exitAndSaveHandler={() => onExitandSaveClick()}
                saveJourneyHandler={() => onProceedToBasketClick(false)}
              />
            </Col>
          </Row>
        </StyledMainContainer>
      </StyledMainWrapper>
    </>
  );
};

export default PledgeDetails;
