import React from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';

import { Row, Col } from 'antd';

import { getTimestamp } from 'utils/util';

import { FNS_DATE_FORMAT_WITH_CAP_TIME } from 'globalConstants';
import {
  CONSENT_TYPE,
  CUSTOMER_NOTE_TYPE_ID,
  NOTES_FILTERS_VALUE,
} from 'components/customer/constants';
interface NoteSummaryProps {
  rows: Array<Object>;
}

const TitleRow = styled.div<{ $isStopListNote: boolean }>`
  color: ${(props: any) => (props.$isStopListNote ? `var(--red)` : '')};
`;

const StyledNoNotes = styled.div`
  width: 100%;
  text-align: center;
  padding: 30px;

  font-size: var(--font-size-16);
  font-weight: var(--font-weight-500);
`;

const getNotesColorClass = (noteTeam: number) => {
  if (noteTeam === NOTES_FILTERS_VALUE.AT_RISK) return 'bg-orange';
  else if (noteTeam === NOTES_FILTERS_VALUE.SSTEAM) return 'bg-red';

  return 'bg-blue';
};

const getConsentEl = (consentDesc: string) => {
  if (consentDesc === CONSENT_TYPE.REFUSED)
    return (
      <span style={{ color: 'var(--red)' }}>(Explicit Consent Refused)</span>
    );
  else if (consentDesc === CONSENT_TYPE.GRANTED)
    return (
      <span style={{ color: 'var(--green)' }}>(Explicit Consent Granted)</span>
    );
  return <></>;
};

const NoteSummary = ({ rows }: NoteSummaryProps) => {
  const getNoteRow = (note: any) => {
    const notesBy = note?.createdUserName ? note?.createdUserName : '';
    const storeName = note?.storeName ? note?.storeName : '';
    const timeStamp = format(
      getTimestamp(note?.createdOn),
      FNS_DATE_FORMAT_WITH_CAP_TIME
    );

    const colorClass = getNotesColorClass(note?.noteTeam);
    const consentEl = getConsentEl(note?.grantedOrRefused);

    const isStopListNote =
      note?.customerNoteType?.customerNoteTypeId ===
        CUSTOMER_NOTE_TYPE_ID.CUSTOMER_ADDED_TO_STOP_LIST ||
      note?.customerNoteType?.customerNoteTypeId ===
        CUSTOMER_NOTE_TYPE_ID.CUSTOMER_REMOVED_FROM_STOP_LIST;

    return (
      <div key={note?.customerNoteId} className="note-wrapper">
        <div className={`note-content ${colorClass}`}>
          <TitleRow $isStopListNote={isStopListNote} className="note-title">
            {note.customerNoteType.description} {consentEl}
          </TitleRow>
          <div
            style={{whiteSpace: 'break-spaces'}}
          >{note?.noteText}</div>
          <div>
            {notesBy}, {storeName}, {timeStamp}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Row className="note-grid">
        {rows.length > 0 ? (
          <Col span={24}>{rows?.map((note: any) => getNoteRow(note))}</Col>
        ) : (
          <StyledNoNotes>No Notes Found.</StyledNoNotes>
        )}
      </Row>
    </>
  );
};

export default NoteSummary;
