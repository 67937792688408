import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Row, Col, notification, Spin } from 'antd';
import { isEmpty } from 'lodash';

import {
  GET_VALUATION_ITEM_DETAILS,
  POST_VALUATION_ACTIONS,
} from 'action_creators/valuationCenter';
import {
  BUTTON_LABELS,
  LABELS,
  VALUATION_ACTIONS,
  VALUATION_ERROR,
  VALUATION_HEADERS,
  VALUATION_VIEW_TYPE,
} from '../constants';

import ItemDetails from './ItemDetails';
import CoinDetails from './CoinDetails';
import StoneDetails from './StoneDetails';
import CustomerDetails from './CustomerDetails';
import ValuationHeader from './ValuationHeader';
import StoreChecklist from './StoreChecklist';
import FooterButtons from 'components/common/footerButtons';
import MultipleImageUploader from 'components/common/MultipleImageUploader';
import InputDecimal from 'components/common/InputDecimal';
import TextArea from 'components/common/TextArea';

const StyledCol = styled(Col)`
  padding: 15px 10px;
`;

const StyledNotesDiv = styled.div`
  height: 70px;
  border: 0.6px solid var(--light-gray);
  border-radius: 5px;
`;

const ViewValuations = () => {
  const dispatch = useDispatch();

  const {
    itemDetials,
    isLoading: isBackButtonLoading,
    isActionLoading,
  } = useSelector((state: any) => state.valuationCenter);

  const [valuationDetails, setValuationItemDetails] = useState<any>({});
  const [customerDetails, setCustomerDetails] = useState<any>({});
  const [storeDetails, setStoreDetails] = useState<any>({});
  const [workqueueRequestDataId, setWorkqueueRequestDataId] = useState<any>('');

  const [valuationNotes, setValuationNotes] = useState<string>('');
  const [valuationAmount, setValuationAmount] = useState<any>('');

  useEffect(() => {
    if (!isEmpty(itemDetials)) {
      const valuationData = itemDetials?.viewItemDetails[0]?.valuationItem;

      setValuationItemDetails(valuationData);
      setCustomerDetails(itemDetials?.customer);
      setStoreDetails(itemDetials?.store);
      setWorkqueueRequestDataId(
        itemDetials?.viewItemDetails[0]?.workqueueRequestDataId
      );
    }
  }, [itemDetials]);

  const onBackButtonClick = () => {
    const params = {
      storeId: itemDetials?.storeId,
      workqueueRequestId: itemDetials?.workqueueRequestId,
      agentId: itemDetials?.agentId,
      agentName: itemDetials?.agentName,
      viewType: VALUATION_VIEW_TYPE.VIEW_LISTING,
    };

    dispatch({
      type: GET_VALUATION_ITEM_DETAILS,
      payload: { params, viewType: VALUATION_VIEW_TYPE.VIEW_LISTING },
    });
  };

  const onValuationNoteChange = (event: any) => {
    const { value } = event.target;
    setValuationNotes(value);
  };

  const onValuationAmountChange = (event: any) => {
    const { value } = event.target;
    setValuationAmount(value);
  };

  const createValuationActionPayload = (action: number) => {
    return {
      storeId: storeDetails?.storeId,
      storeName: storeDetails?.storeName,
      agentId: itemDetials?.agentId,
      agentName: itemDetials?.agentName,
      workqueueRequestId: itemDetials?.workqueueRequestId,
      workqueueRequestDataId,
      action,
      notes: valuationNotes,
      valuationAmount,
    };
  };

  const validationError = (error: number) => {
    notification.error({
      message:
        error === VALUATION_ERROR.NOTES.VALUE
          ? VALUATION_ERROR.NOTES.ERROR
          : VALUATION_ERROR.AMOUNT.ERROR,
      duration: 5,
    });
  };

  const onRejectButtonClick = () => {
    if (!valuationNotes) return validationError(VALUATION_ERROR.NOTES.VALUE);
    dispatch({
      type: POST_VALUATION_ACTIONS,
      payload: {
        params: createValuationActionPayload(VALUATION_ACTIONS.REJECTED),
      },
    });
  };

  const onEditButtonClick = () => {
    if (!valuationNotes) return validationError(VALUATION_ERROR.NOTES.VALUE);
    dispatch({
      type: POST_VALUATION_ACTIONS,
      payload: {
        params: createValuationActionPayload(VALUATION_ACTIONS.EDIT_REQUESTED),
      },
    });
  };

  const onOfferButtonClick = () => {
    if (!valuationAmount) return validationError(VALUATION_ERROR.AMOUNT.VALUE);
    dispatch({
      type: POST_VALUATION_ACTIONS,
      payload: {
        params: createValuationActionPayload(VALUATION_ACTIONS.OFFERED),
      },
    });
  };

  return (
    <>
      <div className="content-wrapper">
        <Spin spinning={isActionLoading}>
          <Row className="header-row">
            <StyledCol span={5}>
              {VALUATION_HEADERS.VALUATION_REF} &nbsp;
              {itemDetials?.referenceNumber}
            </StyledCol>
            <StyledCol span={12}>
              {VALUATION_HEADERS.ITEM_DESC} &nbsp;
              {valuationDetails?.description?.split(':')?.join('/')}
            </StyledCol>
          </Row>
          <Row className="content">
            <StyledCol span={5}>
              <ValuationHeader title={VALUATION_HEADERS.ITEM_DETAILS} />
              {!isEmpty(valuationDetails) && (
                <ItemDetails details={valuationDetails} />
              )}
              {valuationDetails?.coins?.length > 0 && (
                <>
                  <ValuationHeader title={VALUATION_HEADERS.COINS} />
                  {valuationDetails?.coins?.map((item: any, index: number) => (
                    <CoinDetails details={item} index={index} key={index} />
                  ))}
                </>
              )}
              {valuationDetails?.stones?.length > 0 && (
                <>
                  <ValuationHeader title={VALUATION_HEADERS.STONES} />
                  {valuationDetails?.stones?.map((item: any, index: number) => (
                    <StoneDetails details={item} index={index} key={index} />
                  ))}
                </>
              )}
              <ValuationHeader title={VALUATION_HEADERS.NOTES} />
              <StyledNotesDiv>{valuationDetails?.metal?.notes}</StyledNotesDiv>
            </StyledCol>
            <StyledCol span={12}>
              {valuationDetails?.itemImageCollections?.images?.length > 0 && (
                <>
                  <ValuationHeader title={VALUATION_HEADERS.ITEM_IMAGES} />
                  <MultipleImageUploader
                    viewOnly={true}
                    columns={6}
                    imageList={valuationDetails?.itemImageCollections?.images}
                  />
                </>
              )}
              {valuationDetails?.checklists?.length > 0 && (
                <>
                  <ValuationHeader title={VALUATION_HEADERS.CHECKLIST} />
                  <StoreChecklist checklist={valuationDetails?.checklists} />
                </>
              )}
            </StyledCol>
            <StyledCol span={7}>
              <ValuationHeader title={VALUATION_HEADERS.SUBMITTED_BY} />
              <Row>
                <Col span={24}>
                  <table className="valuation-tables">
                    <tbody>
                    <tr>
                      <td>
                        Store: {storeDetails?.storeName} {storeDetails?.storeId}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Telephone: &nbsp;
                        <span className="telephone">
                          {storeDetails?.telephone}
                        </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
              <ValuationHeader title={VALUATION_HEADERS.CUSTOMER_DETAILS} />
              <CustomerDetails details={customerDetails} />
              <Row style={{ padding: '5px 0' }}>
                <Col span="24">
                  <div className="custom-label">{LABELS.DESIRED_AMOUNT}</div>
                  <InputDecimal
                    disabled
                    className="custom-input"
                    value={valuationDetails?.desiredAmount?.toFixed(2)}
                  />
                </Col>
              </Row>
              <Row style={{ padding: '5px 0' }}>
                <Col span="24">
                  <div className="custom-label">{LABELS.PREVIOUS_OFFER}</div>
                  <InputDecimal
                    disabled
                    placeholder={false}
                    className="custom-input"
                    value={valuationDetails?.valuationAmount}
                  />
                </Col>
              </Row>
              <Row style={{ padding: '5px 0' }}>
                <Col span="24">
                  <div className="custom-label">{LABELS.VALUATION}</div>
                  <InputDecimal
                    className="custom-input"
                    value={valuationAmount}
                    onChange={onValuationAmountChange}
                  />
                </Col>
              </Row>
              <Row style={{ padding: '5px 0' }}>
                <Col span="24">
                  <div className="custom-label">{LABELS.NOTES}</div>
                  <div className="notes-wrapper">
                    {/* Test new textarea UI befor code merge */}
                    <TextArea
                      rows={6}
                      className="custom-notes"
                      name="valuationNotes"
                      value={valuationNotes}
                      onChange={onValuationNoteChange}
                    />
                  </div>
                </Col>
              </Row>
            </StyledCol>
          </Row>
        </Spin>
      </div>
      <FooterButtons
        enableExtraClass
        enableBackButton
        isBackButtonOnly
        extraButtonEanable
        secondaryExtraButtonEnable
        isBackButtonLoading={isBackButtonLoading}
        backButtonText={BUTTON_LABELS.BACK}
        secondaryExtraButtonText={BUTTON_LABELS.REJECT}
        extraButtonText={BUTTON_LABELS.EDIT}
        proceedButtonText={BUTTON_LABELS.OFFER}
        onBackButtonClick={onBackButtonClick}
        onSecondaryExtraButtonClick={onRejectButtonClick}
        extraButtonOnClick={onEditButtonClick}
        onProceedClick={onOfferButtonClick}
        secondaryExtraButtonClass="decline-button"
        extraButtonClassName="edit-button"
        proceedButtonClassName="offer-button"
      />
    </>
  );
};

export default ViewValuations;
