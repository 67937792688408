import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Modal, notification, Row, Spin } from 'antd';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import { pledgeActions } from 'redux/reducers/pawnbroking';
import { actions } from 'redux/reducers/basket';

import { EDIT_PLEDGE_ITEMS } from 'action_creators/pledge';
import { GRID_NO_URL } from 'globalConstants';
import { Button } from 'components/common/Button';
import { addHistoricalItems, searchHistoricalItems } from 'services/pledge';
import VirtualGrid from 'components/common/virtualGrid';
import ImageLoader from 'components/common/ImageLoader';

const StyledModal = styled(Modal)`
  .ant-modal-body {
    height: 72vh;
  }
`;

interface addFromHistoryPopupProps {
  visible: boolean;
  setAddFromHistoryPopupVisible: () => void;
}

const AddFromHistoryPopup = ({
  visible,
  setAddFromHistoryPopupVisible,
}: addFromHistoryPopupProps) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isAddBtnLoading, setAddBtnLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const {
    customer: { customer },
    pawnbroking: { historicalItems, selectedHistoricalItem },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
      pawnbroking: state.pawnbroking,
    };
  });

  const [columns] = useState([
    {
      name: 'historyRadioBtn',
      title: ' ',
      type: 'radiovisibilty',
      onChange: (row: object, e: any) => {
        onRowChange(row, e.target.checked);
      },
    },
    {
      name: 'image',
      title: 'Item Image',
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row.id}`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'description',
      title: 'Description',
      getCellValue: (row: any) => {
        return row?.itemDescription;
      },
    },
    {
      name: 'weight',
      title: 'Weight',
      type: 'weight',
      getCellValue: (row: any) => {
        return row?.weight;
      },
    },
    {
      name: 'amount',
      title: 'Value',
      type: 'currency',
      getCellValue: (row: any) => row?.amount,
    },
    {
      name: 'blankColumn',
      title: ' ',
    },
    {
      name: 'closedDate',
      title: 'Closed Date',
      getCellValue: (row: any) => row?.closedDate,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'historyRadioBtn', width: '5%' },
    { columnName: 'image', width: '12%' },
    { columnName: 'description', width: '43%' },
    { columnName: 'weight', width: '10%' },
    { columnName: 'amount', width: '12%', align: 'right' },
    { columnName: 'blankColumn', width: '5%' },
    { columnName: 'closedDate', width: '13%' },
  ]);

  const [formattedColumns] = useState(['amount', 'weight', 'historyRadioBtn']);

  useEffect(() => {
    getItemDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onCancelClick = () => {
    dispatch(pledgeActions.setSelectedHistoricalItem({}));
    setAddFromHistoryPopupVisible();
  };

  const onAddClick = async () => {
    try {
      setAddBtnLoading(true);
      const response = await addHistoricalItems(selectedHistoricalItem?.id);
      if (response?.data) {
        dispatch({
          type: EDIT_PLEDGE_ITEMS,
          payload: { params: response?.data },
        });
        dispatch(actions.setBasketPledgeItemsEditMode(response?.data));
        dispatch(pledgeActions.setIsItemFromHistory(true));
        setAddBtnLoading(false);
        setAddFromHistoryPopupVisible();
      }
    } catch (e: any) {
      setAddBtnLoading(false);
      setAddFromHistoryPopupVisible();
    } finally {
      dispatch(pledgeActions.setSelectedHistoricalItem({}));
    }
  };

  const getItemDetails = async () => {
    try {
      if (customer?.customerId) {
        setLoading(true);
        const response = await searchHistoricalItems(customer?.customerId);
        if (response?.data) {
          setLoading(false);
          if (response?.data?.isSuccess) {
            dispatch(
              pledgeActions.setHistoricalItems(response?.data?.items?.items)
            );
          } else {
            return notification.error({
              message: response?.data?.message,
              duration: 2,
            });
          }
        }
      }
    } catch (e: any) {
      setLoading(false);
    }
  };

  const onRowChange = (row: any, checked: boolean) => {
    const updatedRow = { ...row, isRowSelected: checked };
    dispatch(pledgeActions.updateHistoricalItems(updatedRow));
    dispatch(pledgeActions.setSelectedHistoricalItem(updatedRow));
  };

  return (
    <>
      <StyledModal
        title="Add from History"
        open={visible}
        width={1000}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        footer={[
          <Row gutter={24} justify="end" key="footer">
            <Col key="1">
              <Button itemProp="secondary" key="back" onClick={onCancelClick}>
                Cancel
              </Button>
            </Col>
            <Col key="2">
              <Button
                key="submit"
                form="form"
                htmlType="submit"
                onClick={onAddClick}
                loading={isAddBtnLoading}
                disabled={isEmpty(selectedHistoricalItem)}
              >
                Add
              </Button>
            </Col>
          </Row>,
        ]}
      >
        {isLoading ? (
          <Spin />
        ) : (
          <VirtualGrid
            urlType={GRID_NO_URL}
            columnsProps={columns}
            tableColumnExtensionsProps={tableColumnExtensions}
            formattedColumns={formattedColumns}
            rowData={historicalItems}
          />
        )}
      </StyledModal>
    </>
  );
};

export default AddFromHistoryPopup;
