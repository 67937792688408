import React, { useState } from 'react';

import Overview from '../overview';
import Pawnbroking from '../pledge';
import PurchaseSummary from '../purchase';
import RetailSummary from '../retail';
import TravelMoneySummary from '../travelmoney';
import ChequeCashingSummary from '../chequeCashing';
import HorizontalTabs from 'components/common/HorizontalTabs';

import { CUSTOMER_SUMMARY_PANES } from 'components/customer/constants';

interface TabProps {
  pledgeOverview: any;
  purchaseOverview: any;
  travelMoneyOverview: any;
  purchaseSummary: any;
  travelMoneySummary: Array<any>;
  retailSummary: Array<any>;
  retailSaleOverview: any;
  chequeCashSummary: Array<any>;
  chequeCashingOverview: any;
  isProfileView: any;
}

const CustomerSummaryTabs = (props: TabProps) => {
  const [activeMenu, setActiveMenu] = useState<string>(
    CUSTOMER_SUMMARY_PANES[0].title
  );

  const onSummaryMenuClick = (clickedMenu: string) => {
    setActiveMenu(clickedMenu);
  };

  const renderActiveMenuContent = () => {
    switch (activeMenu) {
      case CUSTOMER_SUMMARY_PANES[1].title:
        return <Pawnbroking isProfileView={props?.isProfileView} />;
      case CUSTOMER_SUMMARY_PANES[2].title:
        return <PurchaseSummary {...props} />;
      case CUSTOMER_SUMMARY_PANES[3].title:
        return <RetailSummary {...props} />;
      case CUSTOMER_SUMMARY_PANES[4].title:
        return 'Layaways coming soon';
      case CUSTOMER_SUMMARY_PANES[5].title:
        return <TravelMoneySummary {...props} />;
      case CUSTOMER_SUMMARY_PANES[6].title:
        return <ChequeCashingSummary {...props} />;
      case CUSTOMER_SUMMARY_PANES[7].title:
        return 'Repairs coming soon';
      default:
        return <Overview {...props} />;
    }
  };

  return (
    <>
      <HorizontalTabs
        isBlueTheme
        isBorderRequired
        onMenuClick={onSummaryMenuClick}
        initialPanes={CUSTOMER_SUMMARY_PANES}
      />
      <div className="content">{renderActiveMenuContent()}</div>
    </>
  );
};
export default CustomerSummaryTabs;
