import React, { useState } from 'react';

type IValidatePayeeAccountResponse = {
  statusCode?: number;
  errorMessage?: string | null;
  payeeAccountId?: string | null;
  resultCode?: number;
  suggestedName?: string | null;
};

type IPayeeAccountRecord =
  | IValidatePayeeAccountResponse
  | Record<string, never>;

const PaymentContext = React.createContext({
  isBankTransferRequestRecently: false,
  setBankTransferRequestRecently: (isTrue: boolean) => {},
  isPaymentCompleted: false,
  setPaymentCompleted: (isTrue: boolean) => {},
  validatePayeeAccountResponse: {} as IPayeeAccountRecord,
  setValidatePayeeAccountResponse: (
    payload: IValidatePayeeAccountResponse
  ) => {},
  recentBankTransferStatus: '',
  setRecentBankTransferStatus: (status: string) => {},
});

function PaymentContextProvider({ children }: any) {
  const [validatePayeeAccountResponse, setValidatePayeeAccountResponse] =
    useState<IPayeeAccountRecord>({});
  const [isBankTransferRequestRecently, setBankTransferRequestRecently] =
    useState<boolean>(false);
  const [isPaymentCompleted, setPaymentCompleted] = useState<boolean>(false);
  const [recentBankTransferStatus, setRecentBankTransferStatus] =
    useState<string>('');

  return (
    <PaymentContext.Provider
      value={{
        isPaymentCompleted,
        setPaymentCompleted,
        isBankTransferRequestRecently,
        validatePayeeAccountResponse,
        setValidatePayeeAccountResponse,
        setBankTransferRequestRecently,
        recentBankTransferStatus,
        setRecentBankTransferStatus,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
}

const usePaymentContext = () => React.useContext(PaymentContext);

export { usePaymentContext, PaymentContextProvider };
