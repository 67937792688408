import React, { useState } from 'react';
import { Table } from 'antd';
import styled, { css } from 'styled-components';

const StyledTable = styled(Table)<{ $background: string; $textAlign: string }>`
  & .ant-table-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    color: var(--black);
    padding: 0 0 2px 0;
  }

  &.ant-table-wrapper {
    margin-bottom: 20px;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 0.8px solid var(--light-gray);
    border-top: 0.8px solid var(--light-gray);
  }
  .ant-table-thead > tr > th {
    border-top: 0.8px solid var(--light-gray);
  }

  .ant-table-tbody > tr > td:first-child,
  .ant-table-thead > tr > th:first-child {
    border-left: 0.8px solid var(--light-gray);
  }

  .ant-table-tbody > tr > td:last-child,
  .ant-table-thead > tr > th:last-child {
    border-right: 0.8px solid var(--light-gray);
  }

  ${(props) =>
    props.$background &&
    css`
      & .ant-table-thead > tr > th {
        background-color: var(--${props.$background});
      }
    `}
  ${(props) =>
    props.$textAlign &&
    css`
      .ant-table-thead > tr > th:last-child {
        text-align: ${props.$textAlign};
      }
      .ant-table-tbody > tr > td:last-child {
        text-align: ${props.$textAlign};
      }
    `}
`;

const StaticTable = ({
  tableColumns,
  pagination = false,
  dataSource,
  backgroundColor,
  title,
  textAlign = '',
}: any) => {
  const [state] = useState({
    bordered: false,
    loading: false,
    title: title ? title : undefined,
    tableLayout: undefined,
    pagination: undefined,
    top: 'none',
    bottom: 'bottomRight',
    ...title,
  });

  return (
    <StyledTable
      {...state}
      $textAlign={textAlign}
      $background={backgroundColor}
      pagination={pagination}
      columns={tableColumns}
      dataSource={dataSource}
    />
  );
};

export default StaticTable;
