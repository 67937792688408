import React from 'react';
import { Row, Col } from 'antd';
import { camelCase, isNull, isUndefined, startCase } from 'lodash';

const CoinDetails = ({ details, index }: any) => {
  const { coinConditionText, coinType, coinYear, quantity } = details;

  const getFieldValue = (property: any) => {
    if (isUndefined(property) || isNull(property) || property === 0) return '-';
    return startCase(camelCase(property));
  };

  const getTableRow = (name: any, value: any) => {
    return (
      <tr>
        <td>{name}</td>
        <td>{getFieldValue(value)}</td>
      </tr>
    );
  };

  return (
    <Row>
      <Col span={24}>
        <table className="valuation-tables">
          <tbody>
          {getTableRow(`Coin Type ${index + 1}`, coinType?.description)}
          {getTableRow(`Condition`, coinConditionText)}
          {getTableRow(`Year`, coinYear)}
          {getTableRow(`Quantity`, quantity)}
          </tbody>
        </table>
      </Col>
    </Row>
  );
};

export default CoinDetails;
