import React, { useEffect } from 'react';
import { Col, Modal, Row } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { actions as customerActions } from 'redux/reducers/customer';
import { isEmpty } from 'lodash';
import CustomerSmartSearch from 'components/layout/CustomerSmartSearch';
import { SERVICE_TYPE } from 'globalConstants';

const StyledModal = styled(Modal)`
  margin-top: 16px;
  .search-control-box {
    margin: 0;
  }
  .ant-modal-footer {
    display: none;
  }
`;

interface GuestSearchPopupProps {
  visible: boolean;
  closeGuestSearchPupup: () => void;
}

const GuestSearchPopup = ({
  visible,
  closeGuestSearchPupup,
}: GuestSearchPopupProps) => {
  const {
    customer: { customer, isRedirectToOtherPage },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
    };
  });
  const dispatch = useDispatch();

  const onCancelClick = () => {
    closeGuestSearchPupup();
  };

  const onFindCustomerClick = () => {
    if (isEmpty(customer)) {
      dispatch(customerActions.setRedirectToOtherPage(true));
    }
    closeGuestSearchPupup();
  };

  useEffect(() => {
    if (!isRedirectToOtherPage) {
      onFindCustomerClick();
    }
  }, [isRedirectToOtherPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StyledModal
        title="Find Customer"
        open={visible}
        width={800}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        footer={[]}
      >
        <Row>
          <Col span={24}>
            <CustomerSmartSearch width={24} serviceType={SERVICE_TYPE.RETAIL} />
          </Col>
        </Row>
      </StyledModal>
    </>
  );
};

export default GuestSearchPopup;
