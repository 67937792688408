import React, { useState } from 'react';
import { format } from 'date-fns';
import VirtualGrid from 'components/common/virtualGrid/index';
import styled from 'styled-components';

import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';
import './index.less';
import { Avatar, Col, Row } from 'antd';

import banked from '../../../../assets/images/icons/banked.svg';
import open from '../../../../assets/images/icons/open.svg';
import uncleared from '../../../../assets/images/icons/uncleared.svg';
import stop from '../../../../assets/images/icons/stop.svg';
import { STATUS } from 'components/chequeCashing/constants';

const StyledGridWrapper = styled.div`
  height: calc(65vh - 30px);
`;

const StyledRowWrapper = styled(Row)`
  margin-top: 3%;
  margin-left: 2%;
`;

const StyledAvatorWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
`;

const ChequeCashingSummary = ({ chequeCashSummary }: any) => {
  const [columns] = useState([
    {
      name: 'status',
      title: 'Status',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => row.status,
    },
    {
      name: 'chequeId',
      title: 'Cheque Id',
      getCellValue: (row: any) => row.thirdPartyChequeId,
    },
    {
      name: 'transactionDate',
      title: 'Transaction Date',
      getCellValue: (row: any) =>
        format(new Date(row.transactionDate), FNS_DATE_FORMAT),
    },
    {
      name: 'issuerName',
      title: 'Issuer',
      type: 'tooltip',
      getCellValue: (row: any) => row.issuerName,
    },
    {
      name: 'authorisation',
      title: 'Pre-Authorisation',
      getCellValue: (row: any) => row.authorisation,
    },
    {
      name: 'chequeAmount',
      title: 'Cheque Amount',
      type: 'currency',
      getCellValue: (row: any) => row?.chequeAmount,
    },
    {
      name: 'totalFee',
      title: 'Total Fee',
      type: 'currency',
      getCellValue: (row: any) => row?.totalFee,
    },
    {
      name: 'retained',
      title: 'Retained',
      type: 'currency',
      getCellValue: (row: any) => row?.retained,
    },

    {
      name: 'payout',
      title: 'Payout',
      type: 'currency',
      getCellValue: (row: any) => row?.payout,
    },

    {
      name: 'location',
      title: 'Location',
      type: 'tooltip',
      getCellValue: (row: any) => row.storeName,
    },
  ]);
  const [formattedColumns] = useState([
    'issuerName',
    'location',
    'status',
    'chequeAmount',
    'totalFee',
    'retained',
    'payout',
  ]);
  const [tableColumnExtensions] = useState([
    { columnName: 'status', width: '7%' },
    { columnName: 'issuerName', width: '8%' },
    { columnName: 'chequeId', width: '8%' },
    { columnName: 'transactionDate', width: '12%' },
    { columnName: 'retained', width: '7%' },
    { columnName: 'totalFee', width: '7%' },
    { columnName: 'chequeAmount', width: '11%' },
    { columnName: 'payout', width: '8%' },
    { columnName: 'location', width: '14%' },
    { columnName: 'authorisation', width: '13%' },
  ]);

  const getRowId = (row: any) => row.customerId;
  return (
    <>
      <StyledGridWrapper>
        <VirtualGrid
          formattedColumns={formattedColumns}
          urlType={GRID_NO_URL}
          columnsProps={columns}
          rowData={chequeCashSummary}
          tableColumnExtensionsProps={tableColumnExtensions}
          expandableComponent={ChequeCashingExpandableTable}
          getRowId={getRowId}
        />
      </StyledGridWrapper>
      <StyledRowWrapper>
        <Col span={9}>
          <Row justify="space-between" align='bottom'>  
            <Col>
              <StyledAvatorWrapper><Avatar size='small' src={banked} />{STATUS.BANKED}</StyledAvatorWrapper>
            </Col>
            <Col>
              <StyledAvatorWrapper><Avatar size='small' src={open} />{STATUS.OPEN}</StyledAvatorWrapper>
            </Col>
            <Col>
              <StyledAvatorWrapper><Avatar size='small' src={uncleared} />{STATUS.UNCLEARED}</StyledAvatorWrapper>
            </Col>
            <Col>
              <StyledAvatorWrapper><Avatar size='small' src={stop} />{STATUS.STOP}</StyledAvatorWrapper>
            </Col>
          </Row>
        </Col>
      </StyledRowWrapper>
    </>
  );
};

/**
 * calling inner rows
 */
export const ChequeCashingExpandableTable = ({ row }: any) => {
  const [columns] = useState([
    {
      name: 'chequeNo',
      title: 'Cheque No',
      getCellValue: (row: any) => row.chequeNumber,
    },
    {
      name: 'sortCode',
      title: 'Sort Code',
      getCellValue: (row: any) => row.sortCode,
    },
    {
      name: 'accountNumber',
      title: 'Account Number',
      getCellValue: (row: any) => row.accountNumber,
    },
    {
      name: 'chequeDate',
      title: 'Cheque Date',
      getCellValue: (row: any) =>
        format(new Date(row.chequeDate), FNS_DATE_FORMAT),
    },
    {
      name: 'bankDate',
      title: 'Bank Date',
      getCellValue: (row: any) =>
        format(new Date(row.bankDate), FNS_DATE_FORMAT),
    },
  ]);

  const [formattedColumns] = useState(['description']);
  const [tableColumnExtensions] = useState([
    { columnName: 'chequeNo', width: 160 },
    { columnName: 'sortCode', width: 160 },
    { columnName: 'accountNumber', width: 160 },
    { columnName: 'chequeDate', width: 160 },
    { columnName: 'bankDate', width: 160 },
  ]);

  return (
    <>
      <div className="retail-expandable-table-rows">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          formattedColumns={formattedColumns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={row.thirdPartyChequeSummaryDetails}
        />
      </div>
    </>
  );
};
export default ChequeCashingSummary;
