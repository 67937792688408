import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//imports for Pagination Grid
import { PagingState, CustomPaging } from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  PagingPanel,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';

import ControlledAlert from 'components/common/ControlledAlert';
import { Button } from 'components/common/Button';

import { dateDisplayFormat } from 'utils/util';

import { PROCESS_CONSUMERDUTY_REQUEST } from 'action_creators/consumerDuty';

import { FNS_DATE_FORMAT_WITH_TIME, NO_ITEMS } from 'globalConstants';
import {
  BUTTON_LABELS,
  MULTIPLE_USERS_ALERT,
  PAGINATION_GRID,
  VIEW_TYPE,
} from '../constants';

import { actions as userActions } from 'redux/reducers/user';

interface WorkQueueProps {
  workQueue: Array<any>;
}

const WorkQueue = ({ workQueue }: WorkQueueProps) => {
  const { selectedStore, user, notificationGridPage, totalNotificationCount } =
    useSelector((state: any) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [taskDetails, setTaskDetails] = useState<any>({});
  const [isUserAlertPopupVisible, setIsUserAlertPopupVisible] =
    useState<boolean>(false);

  const [columns] = useState([
    {
      name: 'reference',
      title: 'Reference',
      getCellValue: (row: any) => row?.reference,
    },
    {
      name: 'source',
      title: 'Source',
      getCellValue: (row: any) => row?.storeName,
    },
    {
      name: 'service',
      title: 'Service',
      getCellValue: (row: any) => row?.serviceName,
    },
    {
      name: 'customerName',
      title: 'Customer Name',
      getCellValue: (row: any) => row?.customerName,
    },
    {
      name: 'createdTimeStamp',
      title: 'Created On',
      getCellValue: (row: any) =>
        dateDisplayFormat(row?.createdOn, FNS_DATE_FORMAT_WITH_TIME),
    },
    {
      name: 'subject',
      title: 'Subject',
      getCellValue: (row: any) => row?.subject,
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row: any) => row?.statusName,
    },
    {
      name: 'actions',
      title: 'Actions',
      getCellValue: (row: any) => (
        <Button
          itemProp="secondary"
          disabled={row?.closedOn}
          onClick={() => onActionButtonClick(row)}
        >
          Process
        </Button>
      ),
    },
    {
      name: 'closed',
      title: 'Closed On',
      getCellValue: (row: any) =>
        dateDisplayFormat(row?.closedOn, FNS_DATE_FORMAT_WITH_TIME),
    },
    {
      name: 'user',
      title: 'User',
      getCellValue: (row: any) => row?.agentName || row?.agentId || '',
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'reference', width: 120 },
    { columnName: 'source', width: 200 },
    { columnName: 'service', width: 100 },
    { columnName: 'customerName', width: 200 },
    { columnName: 'createdTimeStamp', width: 210 },
    { columnName: 'subject', width: 200 },
    { columnName: 'status', width: 140 },
    { columnName: 'actions', width: 120 },
    { columnName: 'closed', width: 180 },
    { columnName: 'user', width: 150 },
  ]);

  const processConsumerDutyRequest = (taskDetails: any) => {
    const params = {
      storeId: selectedStore?.storeId,
      taskId: taskDetails?.id,
      agentId: user?.userId,
      agentName: user?.displayName,
      viewType: VIEW_TYPE.VIEW_TASK_DETAILS,
    };

    dispatch({
      type: PROCESS_CONSUMERDUTY_REQUEST,
      payload: {
        taskDetails,
        params,
        navigate,
      },
    });
  };

  const onActionButtonClick = (row: any) => {
    if (!row?.customerId) return;

    setTaskDetails(row);

    if (row?.agentId) setIsUserAlertPopupVisible(true);
    else processConsumerDutyRequest(row);
  };

  const onPopupProcessButtonClick = () => {
    setIsUserAlertPopupVisible(false);
    processConsumerDutyRequest(taskDetails);
  };

  const setCurrentPage = (page: number) => {
    dispatch(userActions.setNotificationGridPage(page));
  };

  const noDataCellComponent = ({ colSpan }: any) => (
    <td colSpan={colSpan} className="no-data-row">
      {NO_ITEMS}
    </td>
  );

  return (
    <>
      <div id="pagination-grid">
        <Grid rows={workQueue} columns={columns}>
          <PagingState
            currentPage={notificationGridPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={PAGINATION_GRID.PAGE_SIZE}
          />
          <CustomPaging totalCount={totalNotificationCount} />
          <VirtualTable
            height="auto"
            columnExtensions={tableColumnExtensions}
            noDataCellComponent={noDataCellComponent}
          />
          <TableHeaderRow />
          <PagingPanel />
        </Grid>
      </div>

      {isUserAlertPopupVisible && (
        <ControlledAlert
          width={500}
          maskClosable={false}
          visible={isUserAlertPopupVisible}
          message={MULTIPLE_USERS_ALERT}
          //Proceed button props
          isSuccessBtnOnRight
          itemProp=""
          yesButtonText={BUTTON_LABELS.PROCEED}
          onClick={onPopupProcessButtonClick}
          //Cancel button props
          cancelButtonText={BUTTON_LABELS.CANCEL}
          onCancel={() => setIsUserAlertPopupVisible(false)}
        />
      )}
    </>
  );
};

export default WorkQueue;
