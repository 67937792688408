import React from 'react';
import styled, { css } from 'styled-components';
import { Row, Col, Input } from 'antd';
import HelpPopoverInfo from '../HelpPopoverInfo';

const StyledTotalValueWrapper = styled(Row)<{
  $background: string;
}>`
  background: var(--cyan);
  ${(props) =>
    props.$background &&
    css`
      background: var(--${props.$background});
    `}
`;

const StyledTotalValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const StyledText = styled.div`
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-500);
  line-height: 16px;
  flex-basis: 95%;
`;

const StyledRightRow = styled(Row)`
  justify-content: flex-end;
  column-gap: 20px;
`;

const PayFooter = ({
  backgroundColor = '',
  payLabel = '',
  amount = 0.0,
  addonBefore = '£',
  helpSection = '',
}) => {
  const prefix = (
    <span dangerouslySetInnerHTML={{ __html: addonBefore }}></span>
  );
  return (
    <>
      <StyledTotalValueWrapper
        align="middle"
        justify="end"
        $background={backgroundColor}
      >
        <Col span={24}>
          <StyledRightRow justify="end">
            <Col span={24}>
              <StyledTotalValue>
                <StyledText>{payLabel}</StyledText>

                <Input
                  className="basket-total-value"
                  disabled
                  addonBefore={prefix}
                  placeholder="0.00"
                  value={amount}
                  data-testid={payLabel}
                />
                <HelpPopoverInfo
                  linkedID={`$('helpSection')_$('paylabel')`}
                  position="rightTop"
                  helpPosition="AFTER"
                ></HelpPopoverInfo>
              </StyledTotalValue>
            </Col>
          </StyledRightRow>
        </Col>
      </StyledTotalValueWrapper>
    </>
  );
};

export default PayFooter;
