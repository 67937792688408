import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'antd';
import styled from 'styled-components';
import { FAQ_QUES_POPUP } from './constants';
import { cacheStaticData } from 'utils/cacheServices';
import { getFAQData } from 'services/pledge';

const StyledModal = styled(Modal)`
  & .ant-modal-title {
    text-align: left;
  }
`;

const StyledDiv = styled.div`
  text-align: left;
  font-size: var(--font-size-16);
`;

const FAQQuestionsPopup = ({ visible, setFAQPopupVisible }: any) => {
  const [content, setContent] = useState<any>();

  const onCancelClick = () => {
    setFAQPopupVisible();
  };

  useEffect(() => {
    loadFAQData();
  }, []);

  const loadFAQData = async () => {
    try {
      const response = await cacheStaticData(getFAQData);
      if (response?.data) setContent(response?.data);
    } catch (e: any) {}
  };

  return (
    <>
      <StyledModal
        title={FAQ_QUES_POPUP.TITLE}
        open={visible}
        width={752}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        footer={null}
        closable={true}
        centered
      >
        <Row align="middle">
          <Col span={24}>
            <StyledDiv>
              <span dangerouslySetInnerHTML={{ __html: content }}></span>
            </StyledDiv>
          </Col>
        </Row>
      </StyledModal>
      ;
    </>
  );
};

export default FAQQuestionsPopup;
