import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import FooterButtons from 'components/common/footerButtons';

const StyledContentLayout = styled(Layout)`
  background: var(--white);
  padding: 15px 30px;
  border-radius: 5px;
`;
const Ledger = () => {
  const onConfirmExitOnlyClick = () => {/* do nothing */};
  return (
    <>
      <StyledContentLayout className="ledger-wrapper">
        Ledger comming soon
      </StyledContentLayout>
      <FooterButtons
        isExitOnly
        enableExtraClass
        extraButtonItemProp="secondary"
        onConfirmExitClick={onConfirmExitOnlyClick}
      />
    </>
  );
};

export default Ledger;
