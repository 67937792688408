import React, { useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import styled from 'styled-components';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Button } from 'components/common/Button';
import VirtualGrid from 'components/common/virtualGrid/index';
import { GRID_NO_URL } from 'globalConstants';
import { cacheStaticData } from 'utils/cacheServices';
import { getMetalPricing } from 'services/accessories';
import { SORTED_INDEX_METALPRICE_LIST, METALTYPE } from '../../../constants';

const StyledWrapper = styled.div`
  margin: 20px 30px 30px;
`;

const StyledTableWrapper = styled.div`
  margin-bottom: 0px;
`;

const StyledRow = styled(Row)`
  padding: 20px 0;
`;

const TableRow = ({ row, ...restProps }: any) => {
  const Cell = () => {
    const { column } = restProps;
    if (column.name === 'carat') {
      return <Table.Cell className="metalprice-carat-cell" {...restProps} />;
    }
    return <Table.Cell {...restProps} />;
  };

  return <>{Cell()}</>;
};

const MetalPricing = ({ onCancelClick }: any) => {
  const [rows, setRows] = useState<any>([]);
  const getRowId = (row: any) => row?.id;
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getCalculatedData();
  }, []);

  const getCalculatedData = async () => {
    try {
      setLoading(true);
      const response = await cacheStaticData(getMetalPricing);
      if (response?.data) {
        const sortedMelatPriceList = SORTED_INDEX_METALPRICE_LIST.map((el) =>
          response?.data?.metalPriceItems.find(
            (x: { carat: string }) => x.carat === el
          )
        );
        const elementNotInMetalSortedIndexList =
          response?.data?.metalPriceItems.filter(
            (o: { carat: string }) =>
              !SORTED_INDEX_METALPRICE_LIST.some((i) => i === o.carat)
          );
        setRows([...sortedMelatPriceList, ...elementNotInMetalSortedIndexList]);
        setLoading(false);
      }
    } catch (e: any) {
      setLoading(false);
    }
  };

  const [columns] = useState([
    {
      name: 'carat',
      title: 'Carat',
      getCellValue: (row: any) => row?.carat,
    },
    {
      name: 'spot',
      title: 'Spot',
      getCellValue: (row: any) => {
        if (row?.carat === METALTYPE.SILVER) {
          return '-';
        } else {
          return '£' + row?.spot?.toFixed(2);
        }
      },
    },
    {
      name: 'rateStart',
      title: 'Low',
      getCellValue: (row: any) => '£' + row?.rateStart?.toFixed(2),
    },
    {
      name: 'high',
      title: 'High',
      getCellValue: (row: any) => '£' + row?.high?.toFixed(2),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'carat', width: '23%' },
    { columnName: 'spot', width: '25%', align: 'center' },
    { columnName: 'rateStart', width: '25%', align: 'center' },
    { columnName: 'high', width: '18%', align: 'right' },
  ]);

  return (
    <>
      <Spin spinning={loading}>
        <StyledWrapper>
          <StyledTableWrapper>
            <VirtualGrid
              urlType={GRID_NO_URL}
              columnsProps={columns}
              tableColumnExtensionsProps={tableColumnExtensions}
              rowData={rows || []}
              getRowId={getRowId}
              cellComponent={(restProps: any) => <TableRow {...restProps} />}
            />
          </StyledTableWrapper>

          <StyledRow align="bottom" justify="end">
            <Col>
              <Button onClick={onCancelClick} itemProp="secondary">
                Close
              </Button>
            </Col>
          </StyledRow>
        </StyledWrapper>
      </Spin>
    </>
  );
};

export default MetalPricing;
