import { call, put, takeEvery } from 'redux-saga/effects';
// import * as westernUnionActions from 'action_creators/westernUnion';
import { uploadWesternUnionDoc } from 'services/westernUnion';
import { UPLOAD_WESTERN_UNION_DOCUMENT_REQUEST } from 'action_creators/westernUnion';
import { actions } from 'redux/reducers/westernUnion';

export function* uploadWesternUnionDocument(action) {
  try {
    const {
      payload: { image },
    } = action;
    yield put(actions.initLoading(true));
    yield put(actions.initSearch(true));
    const response = yield call(uploadWesternUnionDoc, image);
    if (response?.data) {
      yield put(actions.initLoading(false));
      yield put(actions.initSearch(false));
      yield put(actions.setDocumentsData(response?.data));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.initSearch(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* watchUploadWesternUnionDocumentSaga() {
  yield takeEvery(
    UPLOAD_WESTERN_UNION_DOCUMENT_REQUEST,
    uploadWesternUnionDocument
  );
}
