import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toString } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { actions as consumerDutyAction } from 'redux/reducers/consumerDuty';
import { actions as customerActions } from 'redux/reducers/customer';

import CustomerSummary from 'components/customer/customerSummary';
import FooterButtons from 'components/common/footerButtons';
import ControlledAlert from 'components/common/ControlledAlert';

import {
  BUTTONS_LABEL,
  ESCALATED_TO_SSTEAM_SUCCESS_MSG,
  ESCALATED_TO_SSTEAM_SUCCESS_TITLE,
  STORE_MANAGED_SUCCESS_MSG,
  STORE_MANGEED_SUCCESS_TITLE,
  TASK_OWNER_VALUE,
} from 'components/consumerDuty/constants';
import CustomerSupportModal from 'components/consumerDuty/modals/customerSupportModal';

interface ProfileOverviewProps {
  onCaseExit: () => void;
}

const ProfileOverview = ({ onCaseExit }: ProfileOverviewProps) => {
  const {
    selectedTab,
    selectedTaskOwner,
    isSupportRequestRefPopupVisible,
    isSSTeamRequestRefPopupVisible,
    ssteamRequestTaskId,
  } = useSelector((state: any) => state.consumerDuty);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isCustomerSupportModalVisible, setIsCustomerSupportModalVisible] =
    useState<boolean>(false);

  const onCustomerSupportClick = () => {
    setIsCustomerSupportModalVisible(true);
  };

  const onNextButtonClick = () => {
    const tab = toString(+selectedTab + 1);
    dispatch(consumerDutyAction.setSelectedTab(tab));
  };

  const onPreviousBtnClick = () => {
    const tab = toString(+selectedTab - 1);
    dispatch(consumerDutyAction.setSelectedTab(tab));
  };

  const onSupportPopupOKClick = () => {
    dispatch(consumerDutyAction.setIsSupportRequestRefPopupVisible(false));
  };

  const onSSTRefPopupOKClick = () => {
    dispatch(consumerDutyAction.setIsSSTeamRequestRefPopupVisible(false));
    dispatch(consumerDutyAction.resetConsumerDuty());
    dispatch(customerActions.clearAll({}));
    navigate(`/`);
  };

  return (
    <div className="profile-wrapper">
      <CustomerSummary isProfileView={true} />
      {isSupportRequestRefPopupVisible && (
        <ControlledAlert
          width={550}
          isCancelBtn={false}
          visible={isSupportRequestRefPopupVisible}
          alertMessage={STORE_MANGEED_SUCCESS_TITLE}
          message={STORE_MANAGED_SUCCESS_MSG}
          onClick={onSupportPopupOKClick}
          yesButtonText={BUTTONS_LABEL.OK}
          alertMessageStyle={{ color: `var(--black)`, padding: '20px 0' }}
        />
      )}
      {isSSTeamRequestRefPopupVisible && (
        <ControlledAlert
          width={550}
          isCancelBtn={false}
          visible={isSSTeamRequestRefPopupVisible}
          alertMessage={ESCALATED_TO_SSTEAM_SUCCESS_TITLE}
          message={ESCALATED_TO_SSTEAM_SUCCESS_MSG}
          secondaryMessage={`Reference: ${ssteamRequestTaskId}`}
          secondaryMsgClassName="message-bold"
          onClick={onSSTRefPopupOKClick}
          yesButtonText={BUTTONS_LABEL.OK}
          alertMessageStyle={{ color: `var(--black)`, padding: '20px 0' }}
        />
      )}

      <FooterButtons
        enableExtraClass
        //Exit Button Props
        isExitOnly={true}
        enableExitPopup={false}
        onConfirmExitClick={onCaseExit}
        //Customer Support Button Props
        extraButtonEanable={
          selectedTaskOwner === TASK_OWNER_VALUE.AT_RISK ? true : false
        }
        extraButtonAlign="left"
        extraButtonItemProp="secondary"
        extraButtonText={BUTTONS_LABEL.CUST_SUPPORT}
        extraButtonOnClick={onCustomerSupportClick}
        //Previous Button Props
        secondaryExtraButtonEnable={
          selectedTaskOwner === TASK_OWNER_VALUE.AT_RISK ? false : true
        }
        secondaryExtraButtonItemProp="secondary"
        secondaryExtraButtonText={BUTTONS_LABEL.PREVIOUS}
        onSecondaryExtraButtonClick={onPreviousBtnClick}
        //Next Button Props
        proceedButtonItemProp="secondary"
        proceedButtonText={BUTTONS_LABEL.NEXT}
        onProceedClick={onNextButtonClick}
      />
      {isCustomerSupportModalVisible && (
        <CustomerSupportModal
          visible={isCustomerSupportModalVisible}
          onClose={() => setIsCustomerSupportModalVisible(false)}
        />
      )}
    </div>
  );
};

export default ProfileOverview;
