export const YES = 'Yes';
export const NO = 'No';
export const GUEST = 'Guest';
export const EXIT_AND_SAVE = 'Exit & Save';
export const EXIT_AND_CLEAR = 'Exit & Clear';
export const EXIT = 'Exit';

export const EXIT_ONLY = {
  LINE_ONE: 'No Item details have been added.',
  LINE_TWO: 'Do you wish to proceed?',
};

export const EXIT_AND_SAVE_MSG = {
  LINE_ONE:
    'This session will be saved. The option to restore will be available under Recall ref.',
  LINE_TWO: ' Do you wish to Exit?',
};

export const EXIT_AND_CLEAR_MSG = {
  LINE_ONE: 'This session will be deleted.',
  LINE_TWO: 'You will not be able to recall this Basket.',
  LINE_THREE: 'Do you wish to Exit?',
};
