import React, { useEffect, useRef } from 'react';
import { Drawer, Button, Switch, Avatar, Col, Row } from 'antd';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../../redux/reducers/help';
import { useAuth } from 'providers/AuthProvider';
import './UserDrawer.less';

const StyledColLeft = styled(Col)`
  width: 100px;
`;

const StyledColRight = styled(Col)`
  text-align: left;
`;

const StyledDivUserNameContainer = styled.div`
  text-align: center;
  cursor: pointer;
  font-size: 1.8rem;
  margin-top: 10px;
  font-weight: bold;
  line-height: 20px;
`;

const StyledDivJobTitleContainer = styled.div`
  text-align: center;
  cursor: pointer;
  font-size: 1.2rem;
  margin-top: 5px;
`;

const StyledDivCentredContainer = styled.div`
  text-align: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  line-height: 40px;
`;
const StyledAvatar = styled(Avatar)`
  border: 2px solid var(--avatar-border);
`;

const StyledBottomContainer = styled.div`
  margin: 10px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const StyledUserControlWrapper = styled.div`
  box-shadow: 0px 1px 10px rgba(1, 78, 169, 0.2);
  border-radius: 10px 10px 0 0;
  width: 356px;
  top: 50px;
`;

const StyledCommonRow = styled(Row)`
  margin-block-start: 24px;
`;
const UserDrawer = (props: any) => {
  const { userFullName, userProfilePhoto, signOut } = useAuth();
  const { visible, onClose } = props;
  const {
    help: { help },
  } = useSelector((state: any) => {
    return {
      user: state.user,
      help: state.help,
    };
  });

  const drawerRef = useRef<HTMLDivElement>(null);

  const checkIfClickedOutside = (e: any) => {
    if (visible && drawerRef.current && !drawerRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [visible]); // eslint-disable-line react-hooks/exhaustive-deps

  const dispatch = useDispatch();

  const onHelpToggleClick = () => {
    dispatch(actions.toggle(!help.enabled));
  };
  const onColourModeClick = () => { /* do nothing */ };
  const onChangePhotoClick = () => { /* do nothing */ };

  return (
    <>
      <StyledUserControlWrapper
        style={{
          display: visible ? 'inline ' : 'none',
        }}
        className="profile-container"
      >
        <div ref={drawerRef}>
          <Drawer
            placement="top"
            zIndex={5}
            open={visible}
            onClose={onClose}
            getContainer={false}
            closable={false}
            height={480}
          >
            <StyledDivCentredContainer>
              <StyledAvatar
                src={
                  userProfilePhoto ||
                  require('../../../assets/images/guest_thumbnail.svg').default
                }
                size={80}
              />
            </StyledDivCentredContainer>

            <StyledDivCentredContainer>
              <Button
                itemProp="secondary"
                key="back"
                onClick={onChangePhotoClick}
              >
                Change Photo
              </Button>
            </StyledDivCentredContainer>
            <StyledDivUserNameContainer>
              {userFullName}
            </StyledDivUserNameContainer>
            <StyledDivJobTitleContainer>
              Job Title Here
            </StyledDivJobTitleContainer>
            <hr />
            <StyledCommonRow align="top" gutter={[0, 16]}>
              <StyledColLeft>
                <Col>Change Password</Col>
              </StyledColLeft>
            </StyledCommonRow>
            <StyledCommonRow align="top" gutter={[0, 16]}>
              <StyledColLeft>
                <Col>Sign in with different Account</Col>
              </StyledColLeft>
            </StyledCommonRow>
            <StyledCommonRow align="top" gutter={[0, 16]}>
              <StyledColLeft>
                <Col>Help Mode</Col>
              </StyledColLeft>
              <StyledColRight>
                <Switch
                  defaultChecked={help.enabled}
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  onChange={onHelpToggleClick}
                />
              </StyledColRight>
              {/* <Col span={4}>OFF</Col> */}
            </StyledCommonRow>
            <StyledCommonRow>
              <StyledColLeft>
                <Col>Colour Mode</Col>
              </StyledColLeft>
              <StyledColRight>
                <Switch
                  defaultChecked={true}
                  checkedChildren="Light"
                  unCheckedChildren="Dark"
                  onChange={onColourModeClick}
                />
              </StyledColRight>
              <br />
              <br />
            </StyledCommonRow>
            <hr />

            <StyledCommonRow>
              <StyledDivCentredContainer>
                <Button itemProp="secondary" key="back" onClick={signOut}>
                  Sign out
                </Button>
              </StyledDivCentredContainer>
            </StyledCommonRow>
          </Drawer>
        </div>
        <StyledBottomContainer></StyledBottomContainer>
      </StyledUserControlWrapper>
    </>
  );
};

export default UserDrawer;
