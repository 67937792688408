import React, { useState } from 'react';
import { Row, Radio, Form, Col } from 'antd';
import styled from 'styled-components';
import { EXPENSE_IN_OUT_LABELS } from '../../constants';
import { Button } from 'components/common/Button';
import { PlusOutlined } from '@ant-design/icons/lib/icons';
import { postReceiptUpload } from 'services/inventoryManagement';

import Select from 'components/common/Select';
import InputDecimal from 'components/common/InputDecimal';
import ItemPhotoPopup from 'components/common/ItemPhotoPopup';
import ImageLoader from 'components/common/ImageLoader';
import TextArea from 'components/common/TextArea';

const StyledRow = styled(Row)`
  margin-top: 25px;
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: contents;
  & .ant-radio-wrapper {
    & .ant-radio {
      & .ant-radio-inner {
        margin-left: 0px;
        height: 22px;
        width: 22px;
      }
    }
  }
`;

const StyledMultipleButtonsCol = styled(Col)`
  display: flex;
  justify-content: flex-start;
`;

const StyledButton = styled(Button)`
  height: 42px;
  width: 42px;
  padding: 0 14px;
`;

const StyledImage = styled.div`
  height: 42px;
  width: 42px;
  border-radius: 4px;
  border: 1px solid var(--light-gray);

  & .ant-image {
    height: 100%;
  }
`;

interface AddExpenseProps {
  inCategoryList: Array<any>;
  outCategoryList: Array<any>;
  onAddClick: (params: any) => void;
}

const AddExpense = ({
  inCategoryList,
  outCategoryList,
  onAddClick,
}: AddExpenseProps) => {
  const [form] = Form.useForm();

  const [direction, setDirection] = useState<number>(
    EXPENSE_IN_OUT_LABELS.DIRECTION.OUT.value
  );
  const [isReceiptPopupVisible, setIsReceiptPopupVisible] =
    useState<boolean>(false);
  const [isReceiptUploading, setIsReceiptUploading] = useState<boolean>(false);
  const [receipts, setReceipts] = useState<Array<any>>([]);

  const onDirectionChange = (event: any) => {
    const value = event.target.value;
    onClearClick();
    setDirection(value);
  };

  const onCaptureReceiptButtonClick = () => {
    setIsReceiptPopupVisible(true);
  };

  const onClearClick = () => {
    setReceipts([]);
    form.resetFields();
  };

  const onAddButtonClick = () => {
    const categoryId = form.getFieldValue('category');
    const categoryDesc =
      direction === EXPENSE_IN_OUT_LABELS.DIRECTION.IN.value
        ? inCategoryList.find((x) => x.activityId === categoryId)
        : outCategoryList.find((x) => x.activityId === categoryId);

    const expenseItem = {
      id: Date.now(),
      ...form.getFieldsValue(),
      direction: direction,
      categoryDesc: categoryDesc.description,
      cashTransactionImages: [...receipts],
    };

    onAddClick(expenseItem);
    onClearClick();
  };

  const onReceiptUploadClick = async (receipt: any) => {
    try {
      setIsReceiptUploading(true);

      const response = await postReceiptUpload(receipt);
      if (response?.data) {
        setIsReceiptUploading(false);
        setIsReceiptPopupVisible(false);

        setReceipts((prevState) => [...prevState, response?.data]);
      }
    } catch (e: any) {
      setIsReceiptUploading(false);
    }
  };

  return (
    <>
      <Row>
        <StyledRadioGroup onChange={onDirectionChange} value={direction}>
          <Radio value={EXPENSE_IN_OUT_LABELS.DIRECTION.IN.value}>
            {EXPENSE_IN_OUT_LABELS.DIRECTION.IN.label}
          </Radio>
          <Radio value={EXPENSE_IN_OUT_LABELS.DIRECTION.OUT.value}>
            {EXPENSE_IN_OUT_LABELS.DIRECTION.OUT.label}
          </Radio>
        </StyledRadioGroup>
      </Row>
      <StyledRow>
        <Col span={24}>
          <Form
            id="expenseItemForm"
            onFinish={onAddButtonClick}
            layout="vertical"
            form={form}
            className="cash-transfer-form"
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  label={EXPENSE_IN_OUT_LABELS.CATEGORY}
                  name="category"
                  className="label-control"
                  rules={[
                    {
                      required: true,
                      message: EXPENSE_IN_OUT_LABELS.ERROR_MSG.CATEGORY,
                    },
                  ]}
                >
                  <Select
                    options={
                      direction === EXPENSE_IN_OUT_LABELS.DIRECTION.OUT.value
                        ? outCategoryList.map((el: any) => {
                            return {
                              value: el.activityId,
                              label: el.description,
                            };
                          })
                        : inCategoryList.map((el: any) => {
                            return {
                              value: el.activityId,
                              label: el.description,
                            };
                          })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={EXPENSE_IN_OUT_LABELS.AMOUNT}
                  name="amount"
                  className="label-control"
                  rules={[
                    {
                      required: true,
                      message: EXPENSE_IN_OUT_LABELS.ERROR_MSG.AMOUNT,
                    },
                  ]}
                >
                  <InputDecimal
                    placeholder={false}
                    className="input-control amount-to-pay-input"
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={EXPENSE_IN_OUT_LABELS.NOTES}
                  name="notes"
                  className="label-control"
                >
                  <TextArea
                    className="input-control"
                    autoComplete="off"
                    rows={2}
                    maxLength={64}
                    dataTestId="expenseInOut-text-area"
                  />
                </Form.Item>
              </Col>
            </Row>
            <StyledRow>
              {receipts.length > 0 &&
                receipts.map((receipt: any, index: number) => (
                  <StyledMultipleButtonsCol span={4} key={index}>
                    <StyledImage>
                      <ImageLoader
                        preview
                        imageUrl={`/api/ledger/image/${receipt?.containerId}/${receipt?.blobId}`}
                        fallbackImg={'no_image_thumbnail.svg'}
                      />
                    </StyledImage>
                  </StyledMultipleButtonsCol>
                ))}
              {receipts.length < 4 && (
                <StyledMultipleButtonsCol span={4}>
                  <StyledButton itemProp="secondary" key="captureReceiptButton">
                    <PlusOutlined />
                  </StyledButton>
                </StyledMultipleButtonsCol>
              )}
              <StyledMultipleButtonsCol span={6}>
                <Button
                  disabled={receipts.length >= 4}
                  itemProp="secondary"
                  key="captureReceiptButton"
                  onClick={onCaptureReceiptButtonClick}
                >
                  Capture Receipt
                </Button>
              </StyledMultipleButtonsCol>
            </StyledRow>
            <StyledRow>
              <StyledMultipleButtonsCol span={7}>
                <Button
                  itemProp="secondary"
                  key="clearExpenseInOutForm"
                  onClick={onClearClick}
                >
                  Clear
                </Button>
              </StyledMultipleButtonsCol>
              <StyledMultipleButtonsCol span={7}>
                <Button
                  itemProp="secondary"
                  key="expenseItemFrom"
                  htmlType="submit"
                >
                  Add
                </Button>
              </StyledMultipleButtonsCol>
            </StyledRow>
          </Form>
        </Col>
      </StyledRow>

      {isReceiptPopupVisible && (
        <ItemPhotoPopup
          visible={isReceiptPopupVisible}
          loading={isReceiptUploading}
          onImageUploadClick={onReceiptUploadClick}
          photoPopupHandler={() => setIsReceiptPopupVisible(false)}
        />
      )}
    </>
  );
};

export default AddExpense;
