import * as urls from '../apiUrls';
import { doRequest } from '../../utils/request';

const postPrintCocm = (params = {}) => {
  return doRequest(
    `${urls.POST_PRINT_COCM}`,
    'get',
    params,
    'application/json',
    false,
    'arraybuffer'
  );
};

const getInterestCalculatedData = (bodyParams: any) => {
  return doRequest(`${urls.GET_INTEREST_CALCULATED_DATA}`, 'post', bodyParams);
};

const getMetalPricing = () => {
  return doRequest(`${urls.METAL_PRICING}`, 'get');
};

const getCutList = () => {
  return doRequest(`${urls.STONE_CUT_LIST}`, 'get'); // get basket by customerId
};

const getStoneSizeData = (bodyParams: any) => {
  return doRequest(`${urls.CALCULATE_STONE_SIZE}`, 'post', bodyParams);
};

const getTelephoneList = (params: any) => {
  return doRequest(
    `${urls.GET_TELEPHONE_LIST}/${params?.storeId}/${params?.colleagueName}`,
    'get'
  );
};

const getTelephoneStores = () => {
  return doRequest(`${urls.GET_TELEPHONE_STORES}`, 'get');
};

const downloadAffidavit = (affidavitType: any, params = {}) => {
  return doRequest(
    `${urls.DOWNLOAD_AFFIDAVIT}/${affidavitType}`,
    'get',
    params,
    'application/json',
    false,
    'arraybuffer'
  );
};

const downloadCustomerMandate = (params = {}) => {
  return doRequest(
    `${urls.DOWNLOAD_CUSTOMER_MANDATE}`,
    'get',
    params,
    'application/json',
    false,
    'arraybuffer'
  );
};

export {
  postPrintCocm,
  getInterestCalculatedData,
  getMetalPricing,
  getCutList,
  getStoneSizeData,
  getTelephoneList,
  getTelephoneStores,
  downloadAffidavit,
  downloadCustomerMandate,
};
