import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import VirtualGrid from 'components/common/virtualGrid/index';
import { GRID_NO_URL } from 'globalConstants';
import { DELETE_BASKET_PLEDGE_ITEMS } from 'action_creators/basket';
import { EDIT_PLEDGE_ITEMS } from 'action_creators/pledge';
import { pledgeActions } from 'redux/reducers/pawnbroking';
import { BASKET_SERVICE_TYPE } from 'components/basket/constants';
import ImageLoader from 'components/common/ImageLoader';
import { getParentImageUrl } from 'utils/util';

interface PurchaseSummaryProps {
  rows: any;
  onPurchaseSelectionChange: (payload: any) => void;
}

const TableRow = ({
  row,
  onClickTableRow,
  onClickDeleteRow,
  ...restProps
}: any) => {
  const onRowClick = () => {
    onClickTableRow(row);
  };

  const onDeleteClick = () => {
    onClickDeleteRow(row);
  };

  const Cell = () => {
    const { column, itemID, tableRow } = restProps;
    const isSelected = tableRow.row.itemId === itemID;

    if (column.name === 'delete') {
      return (
        <Table.Cell
          onClick={onDeleteClick}
          {...restProps}
          style={{
            cursor: 'pointer',
            backgroundColor: isSelected && `rgba(244, 244, 244, 1)`,
          }}
        />
      );
    } else if (column.name === 'image') {
      return (
        <Table.Cell
          {...restProps}
          style={{
            cursor: 'pointer',
            backgroundColor: isSelected && `rgba(244, 244, 244, 1)`,
          }}
        />
      );
    }
    return (
      <Table.Cell
        onClick={onRowClick}
        {...restProps}
        style={{
          cursor: 'pointer',
          backgroundColor: isSelected && `rgba(244, 244, 244, 1)`,
        }}
      />
    );
  };

  return <>{Cell()}</>;
};

const PurchaseSummary = ({
  rows,
  onPurchaseSelectionChange,
}: PurchaseSummaryProps) => {
  const dispatch = useDispatch();

  const {
    basket: { basket, editModeBasketPledgeItems },
    user: { user },
  } = useSelector((state: any) => {
    return {
      basket: state.basket,
      user: state.user,
    };
  });

  const onClickDeleteRow = (row: any) => {
    const deleteItemPayload = {
      basketId: basket?.basketId,
      serviceId: rows?.serviceId,
      itemIds: [row.itemId],
      userId: user?.userId,
    };

    dispatch({
      type: DELETE_BASKET_PLEDGE_ITEMS,
      payload: { params: deleteItemPayload },
    });
  };

  const onClickTableRow = (row: any) => {
    dispatch({
      type: EDIT_PLEDGE_ITEMS,
      payload: { params: row },
    });
    dispatch(pledgeActions.updateServiceType(BASKET_SERVICE_TYPE.PURCHASE));
  };

  const getItemIds = (rowId: any, rows: any) => {
    return rowId?.filter((el: any) => {
      return rows?.purchase?.request?.items?.some((item: any) => {
        return item.itemId === el;
      });
    });
  };

  const onSelectionChange = (rowId: any) => {
    const payload = {
      basketId: basket?.basketId,
      sourceServiceId: rows?.serviceId,
      sourceServiceType: rows?.serviceType,
      targetServiceId: null,
      itemIds: getItemIds(rowId, rows),
      userId: user?.userId,
    };
    if (rowId?.length !== 0) onPurchaseSelectionChange(payload);
    else onPurchaseSelectionChange({});
  };

  const [columns] = useState([
    {
      name: 'image',
      title: 'Item Image',
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={getParentImageUrl(row.itemImageCollections)}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'description',
      title: 'Description',
      getCellValue: (row: any) => {
        if (row?.description.includes(':')) {
          const description = row?.description.split(':').slice(1).join(':');
          return description;
        } else {
          return row?.description;
        }
      },
    },
    {
      name: 'totalWeight',
      title: 'Weight',
      type: 'weight',
      getCellValue: (row: any) => row?.totalWeight,
    },
    {
      name: 'loanAmount',
      title: 'Value',
      type: 'currency',
      getCellValue: (row: any) => row?.loanAmount,
    },
    {
      name: 'delete',
      title: ' ',
      type: 'delete',
      onClick: () => { /* do nothing */ },
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'image', width: '18%' },
    { columnName: 'description', width: '41%', wordWrapEnabled: true },
    { columnName: 'totalWeight', width: '13%', align: 'right' },
    { columnName: 'loanAmount', width: '11%', align: 'right' },
    { columnName: 'delete', width: 'auto', align: 'right' },
  ]);

  const [formattedColumns] = useState(['loanAmount', 'totalWeight', 'delete']);

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        formattedColumns={formattedColumns}
        rowData={!isEmpty(rows) ? rows?.purchase?.request?.items : []}
        cellComponent={(restProps: any) => (
          <TableRow
            onClickTableRow={onClickTableRow}
            onClickDeleteRow={onClickDeleteRow}
            itemID={editModeBasketPledgeItems.itemId}
            {...restProps}
          />
        )}
        rowSelectionEnable={true}
        onSelectionChange={onSelectionChange}
      />
    </>
  );
};

export default PurchaseSummary;
