import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'components/common/Button';
import styled from 'styled-components';
import { Row, Col, Form, Space, Input, notification, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCOUNT_VALIDATION_RESULT_CODES,
  BANK_TRANSFER_LABELS,
  ERROR_MESSAGE,
  MAX_ACCOUNT_VALIDATION_ATTEMPTS,
  MIN_AMT_BANK_TRANSFER,
  PAYMENT_ERROR_MESSAGE,
  PAYMENT_TAB_KEYS,
} from 'components/payment/constants';
import { actions } from 'redux/reducers/payment';
import InputDecimal from 'components/common/InputDecimal';
import GreenTickStrip from 'components/common/GreenTick';
import { isEmpty } from 'lodash';
import { validatePayeeAccount } from 'services/customer';
import { usePaymentContext } from 'components/payment/PaymentContext';
import AccountValidationFailed from './AccountValidationFailed';
import {
  GENERIC_ERROR,
  PAYMENT_MODES,
  PATTERN_INTEGER_ONLY,
} from 'globalConstants';
import ControlledAlert from 'components/common/ControlledAlert';
import './BankTransfer.less';

const StyledText = styled.div`
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-500);
`;

const StyledInput = styled(Input)`
  &::placeholder {
    font-weight: 700;
    color: var(--black);
  }
  & .ant-input-group-addon {
    width: 48px;
    background: var(--catskill-white);
  }
  & input {
    height: 42px;
  }
`;

type IValidatePayeePayload = {
  accountName: string;
  sortCode: string;
  accountNo: string;
  customerId: number;
};

const BankTransferForm = ({
  activeTab = PAYMENT_TAB_KEYS.BANK_TRANSFER,
  totalAmount = 0.0,
  balanceAmount = 0.0,
  addonBefore = '£',
  paymentMode = '',
  onAddPayment = (params: any) => {},
}) => {
  const prefix = (
    <span dangerouslySetInnerHTML={{ __html: addonBefore }}></span>
  );
  const dispatch = useDispatch();
  const [validateForm] = Form.useForm();
  const [form] = Form.useForm();
  const { setValidatePayeeAccountResponse } = usePaymentContext();
  const {
    customer: { customer },
    payment: { paymentMethods },
  } = useSelector((state: any) => {
    return {
      payment: state.payment,
      customer: state.customer,
    };
  });

  const [remainingAttempts, setRemainingAttempts] = useState<number>(
    MAX_ACCOUNT_VALIDATION_ATTEMPTS
  );
  const [isDisplayRemainingAttemptAlert, setDisplayRemainingAttemptAlert] =
    useState<boolean>(true);
  // local states
  const [isValidating, setIsValidating] = useState<boolean>(false);
  useState<boolean>(true);
  // Green Journey initially true
  const [isAccountHolderNameDisabled, setAccountHolderNameDisable] =
    useState<boolean>(true);
  const [isAccountInfoDisabled, setAccountInfoDisable] =
    useState<boolean>(false);
  // initially true
  const [isVerifyAccountDetailsDisable, setVerifyAccountDetailsDisable] =
    useState<boolean>(true);
  const [payeeAccountValidationData, setPayeeAccountValidationData] =
    useState<any>(null);

  const [isAccountDetailsVerified, setAccountDetailsVerified] =
    useState<boolean>(false);
  const [hideValidateAccount, setHideValidateAccount] =
    useState<boolean>(false);

  const resetFlags = useCallback(() => {
    setAccountHolderNameDisable(true);
    setVerifyAccountDetailsDisable(true);
    setPayeeAccountValidationData(null);
    setAccountDetailsVerified(false);
    setHideValidateAccount(false);
  }, []);

  const resetAccountValidationForm = useCallback(() => {
    if (activeTab !== PAYMENT_TAB_KEYS.BANK_TRANSFER) {
      validateForm.resetFields();
      resetFlags();
    }
    // set account holder name initially
    const fullName = customer?.middleName
      ? `${customer?.firstNames?.trim()} ${customer?.middleName?.trim()} ${customer?.surname?.trim()}`
      : `${customer?.firstNames?.trim()} ${customer?.surname?.trim()}`;

    validateForm.setFieldValue('accountName', fullName?.trim());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, customer?.firstNames, customer?.surname, resetFlags]);

  const resultCode = payeeAccountValidationData?.resultCode;

  // Display Varification Failed - if and only if atleast one of these three flag is true
  const isVerificationFailedAsCloseMatch =
    resultCode === ACCOUNT_VALIDATION_RESULT_CODES.CLOSE_MATCH;
  const isVerificationFailedAsNotFound =
    resultCode === ACCOUNT_VALIDATION_RESULT_CODES.FAIL;
  const isVerificationFailedAsUnSupported =
    resultCode === ACCOUNT_VALIDATION_RESULT_CODES.UNSUPPORTED;

  const handleReset = (event: any) => {
    event.preventDefault();
    handleClearSortCodeAndAccount();
  };
  const onValidateSubmit = async () => {
    setIsValidating(true);
    const accountName = validateForm.getFieldValue('accountName');
    const accountNo = validateForm.getFieldValue('accountNo');
    const sortCode = validateForm.getFieldValue('sortCode');
    // const newSortCodeValue = sortCode.replace(/_|-|\./g, '');

    try {
      const payload: IValidatePayeePayload = {
        accountName,
        sortCode,
        accountNo,
        customerId: customer?.customerId,
      };
      if (remainingAttempts !== 0) {
        const validatePayeeAccountResponse = await validatePayeeAccount(
          payload
        );
        setIsValidating(false);

        if (validatePayeeAccountResponse) {
          setAccountDetailsVerified(false);
          // store the response into context
          if (validatePayeeAccountResponse?.data?.statusCode === 200) {
            setAccountInfoDisable(false);
            const suggestedName =
              validatePayeeAccountResponse?.data?.suggestedName;
            setValidatePayeeAccountResponse(validatePayeeAccountResponse?.data);
            setPayeeAccountValidationData(validatePayeeAccountResponse?.data);
            if (validatePayeeAccountResponse?.data?.resultCode === 1) {
              // 1: Pass
              setAccountDetailsVerified(true);
              setHideValidateAccount(true);
              setRemainingAttempts(MAX_ACCOUNT_VALIDATION_ATTEMPTS);
            } else {
              // 2: Fail, 3: CloseMatch, 4: UnSupported
              if (remainingAttempts === 1) {
                setVerifyAccountDetailsDisable(true);
              }
              if (
                validatePayeeAccountResponse?.data?.resultCode === 3 &&
                !suggestedName
              ) {
                // 3: CloseMatch with No Name
                setAccountInfoDisable(true);
              }
              setRemainingAttempts(remainingAttempts - 1);
              // if (isVerificationFailedAsCloseMatch && suggestedName) {
              //   // setHideValidateAccount(true);
              // }
            }

            if (
              validatePayeeAccountResponse?.data?.resultCode === 3 ||
              validatePayeeAccountResponse?.data?.resultCode === 4
            ) {
              // 3: CloseMatch, No Name
              // 4: UnSupported
              setVerifyAccountDetailsDisable(true);
            }
          }
        }
      }
    } catch (e: any) {
      setIsValidating(false);
      notification.error({
        message: e?.response?.data?.errorMessage || '',
        description: GENERIC_ERROR,
        duration: 5,
      });
      setAccountDetailsVerified(false);
    }
  };
  const handleClearAllAccountDetail = () => {
    setPayeeAccountValidationData(null);
    validateForm.resetFields();
  };

  const handleClearSortCodeAndAccount = () => {
    setPayeeAccountValidationData(null); // unsupported, close match/no name,
    setHideValidateAccount(false);
    setAccountInfoDisable(false);
    validateForm.setFieldValue('sortCode', '');
    validateForm.setFieldValue('accountNo', '');
  };

  const onSubmit = () => {
    const amount = form.getFieldValue('amount');

    if (!+amount) return;
    const amt = +amount;
    const requestParams = {
      mode: paymentMode,
      amount: amt,
      key: Date.now(),
    };
    dispatch(actions.addAmounts(requestParams));
    onAddPayment(requestParams);
    dispatch(actions.totalAmounts({ amount: amt }));
    form.setFieldsValue({
      amount: '',
    });
    form.resetFields();
    handleClearSortCodeAndAccount();
    setAccountDetailsVerified(false);
    setHideValidateAccount(false);
  };

  useEffect(() => {
    resetAccountValidationForm();
  }, [resetAccountValidationForm]);

  const handleOnValuesChangeBankDetail = useCallback(
    (changedValues: any, allValues: any) => {
      const newSortCodeValue = allValues?.sortCode?.replace(/_|-|\./g, '');
      if (
        isEmpty(allValues?.accountName) ||
        isEmpty(allValues?.accountNo) ||
        isEmpty(newSortCodeValue) ||
        newSortCodeValue?.length < 6 ||
        remainingAttempts === 0 ||
        allValues?.accountNo.length < 8
      ) {
        setVerifyAccountDetailsDisable(true);
      } else {
        setVerifyAccountDetailsDisable(false);
      }
    },
    [remainingAttempts]
  );

  const onAmountButtonClick = () => {
    let amount = balanceAmount ? balanceAmount : totalAmount;
    form.setFieldsValue({
      amount: (+amount).toFixed(2),
    });
  };

  const handleAcceptAccount = useCallback(
    (suggestedName: any) => {
      setVerifyAccountDetailsDisable(false);
      setHideValidateAccount(false);
      if (suggestedName) {
        // Set Account is verified
        // Replace the account holder name with suggested name
        setAccountDetailsVerified(true);
        setHideValidateAccount(true);
        validateForm.setFieldValue('accountName', suggestedName);
      } else {
        if (isVerificationFailedAsUnSupported) {
          setHideValidateAccount(true);
        } else {
          // Enable account holder name and need to re-verify again
          setAccountHolderNameDisable(false);
        }
      }
    },
    [validateForm, isVerificationFailedAsUnSupported]
  );

  const onlyNumberAllowed = (
    rule: any,
    value: any,
    callback: (error?: string) => void
  ) => {
    const fieldName = rule?.field;
    if (isEmpty(value) || !PATTERN_INTEGER_ONLY.test(value)) {
      validateForm.setFieldsValue({
        [fieldName]: '',
      });
      return Promise.reject('');
    }
    return Promise.resolve();
  };

  return (
    <>
      <Form
        form={validateForm}
        layout="vertical"
        onFinish={onValidateSubmit}
        className="bank-transfer-validate-form"
        onValuesChange={handleOnValuesChangeBankDetail}
        onReset={handleReset}
      >
        <Row align="middle" justify="start">
          <Col span={24}>
            <Form.Item
              label={
                <StyledText>
                  {BANK_TRANSFER_LABELS.ACCOUNT_HOLDER_NAME}
                </StyledText>
              }
              name="accountName"
              className="label-control validation-class mb-12"
              rules={[{ required: true, message: '' }]}
            >
              <StyledInput
                className="input-control mr-0"
                disabled={isAccountHolderNameDisabled}
              />
            </Form.Item>

            <Row
              align="middle"
              justify="space-between"
              gutter={8}
              className="mb-12"
            >
              <Col span={12}>
                <Form.Item
                  validateTrigger="onChange"
                  label={
                    <StyledText>{BANK_TRANSFER_LABELS.SORT_CODE}</StyledText>
                  }
                  name="sortCode"
                  className="label-control"
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                    { validator: onlyNumberAllowed },
                  ]}
                >
                  <StyledInput
                    data-testId="bankTransfer-sortCode"
                    className="input-control"
                    maxLength={6}
                    disabled={isAccountInfoDisabled}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <StyledText>
                      {BANK_TRANSFER_LABELS.ACCOUNT_NUMBER}
                    </StyledText>
                  }
                  name="accountNo"
                  className="label-control ml-5"
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                    { validator: onlyNumberAllowed },
                  ]}
                >
                  <StyledInput
                    data-testId="bankTransfer-accountNo"
                    className="input-control"
                    maxLength={8}
                    disabled={isAccountInfoDisabled}
                  />
                </Form.Item>
              </Col>
            </Row>

            {!isAccountDetailsVerified &&
              (isVerificationFailedAsCloseMatch ||
                isVerificationFailedAsNotFound ||
                isVerificationFailedAsUnSupported) && (
                <AccountValidationFailed
                  classes="mb-12"
                  onAccept={handleAcceptAccount}
                  onClear={
                    isVerificationFailedAsUnSupported ||
                    isVerificationFailedAsCloseMatch ||
                    isVerificationFailedAsNotFound
                      ? handleClearSortCodeAndAccount
                      : handleClearAllAccountDetail
                  }
                  validationResult={payeeAccountValidationData}
                  isVerificationFailedAsCloseMatch={
                    isVerificationFailedAsCloseMatch
                  }
                  isVerificationFailedAsNotFound={
                    isVerificationFailedAsNotFound
                  }
                  isVerificationFailedAsUnSupported={
                    isVerificationFailedAsUnSupported
                  }
                />
              )}

            {paymentMethods.includes(PAYMENT_MODES.BANKTRANSFER) && (
              <Alert
                type="error"
                className="mb-12"
                message={PAYMENT_ERROR_MESSAGE.BANK_TRANSFER_ALREADY_IN_SUMMARY}
                showIcon
              />
            )}

            {!hideValidateAccount && (
              <Row justify="space-between" gutter={8} className="mb-12">
                <Col>
                  <Button
                    disabled={
                      isVerifyAccountDetailsDisable ||
                      paymentMethods.includes(PAYMENT_MODES.BANKTRANSFER)
                    }
                    type="primary"
                    itemProp="secondary"
                    htmlType="submit"
                    loading={isValidating}
                  >
                    {BANK_TRANSFER_LABELS.VERIFY_ACCOUNT_DETAILS}
                  </Button>
                </Col>

                <Col>
                  {remainingAttempts === MAX_ACCOUNT_VALIDATION_ATTEMPTS ? (
                    <Button
                      disabled={false}
                      itemProp="secondary"
                      type="primary"
                      htmlType="button"
                      onClick={handleReset}
                    >
                      {BANK_TRANSFER_LABELS.RESET}
                    </Button>
                  ) : (
                    <div className="mt-10 bold">
                      Remaining Attempts: {remainingAttempts}
                    </div>
                  )}
                </Col>
              </Row>
            )}
            {isAccountDetailsVerified && (
              <GreenTickStrip text="Account Verified" classes="mb-12" />
            )}
          </Col>
        </Row>
      </Form>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        className="bank-transfer-amount-from"
      >
        {hideValidateAccount && (
          <>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  validateTrigger="onChange"
                  label={<StyledText>{BANK_TRANSFER_LABELS.AMOUNT}</StyledText>}
                  name="amount"
                  className="label-control validation-class"
                  rules={[
                    () => ({
                      validator(_, value: any) {
                        if (+value < MIN_AMT_BANK_TRANSFER) {
                          return Promise.reject(
                            ERROR_MESSAGE.MIN_AMOUNT_BANK_TRANFER_VALIDATION
                          );
                        } else if (
                          +value >
                          (balanceAmount
                            ? Number(balanceAmount.toFixed(2))
                            : totalAmount)
                        ) {
                          return Promise.reject(ERROR_MESSAGE.MORE_THAN);
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <InputDecimal
                    addonBefore={prefix}
                    className="input-control"
                    // value={form.getFieldValue('amount')}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Space>
                  <Button
                    disabled={balanceAmount === 0}
                    itemProp="secondary"
                    type="primary"
                    htmlType="button"
                    onClick={onAmountButtonClick}
                    className={`mt-27 ${
                      balanceAmount &&
                      totalAmount > Number(balanceAmount.toFixed(2))
                        ? 'pl-10 pr-10'
                        : ''
                    }`}
                  >
                    {balanceAmount &&
                    totalAmount > Number(balanceAmount.toFixed(2))
                      ? BANK_TRANSFER_LABELS.REMAINING_AMOUT
                      : BANK_TRANSFER_LABELS.FULL_AMOUNT}
                  </Button>

                  <Button
                    disabled={balanceAmount === 0}
                    itemProp="secondary"
                    type="primary"
                    htmlType="submit"
                    className="mt-27"
                  >
                    {BANK_TRANSFER_LABELS.ADD}
                  </Button>
                </Space>
              </Col>
            </Row>
          </>
        )}
      </Form>
      {remainingAttempts === 0 && isDisplayRemainingAttemptAlert && (
        <ControlledAlert
          visible={isDisplayRemainingAttemptAlert}
          isCancelBtn={false}
          message={PAYMENT_ERROR_MESSAGE.FAILED_TO_CONFIRM_BANK_PAYMENT}
          secondaryMessage={
            PAYMENT_ERROR_MESSAGE.CHANGE_ALTERNATIVE_PAYMENT_METHOD
          }
          yesButtonText={BANK_TRANSFER_LABELS.OK}
          onClick={() => {
            setDisplayRemainingAttemptAlert(false);
          }}
        />
      )}
    </>
  );
};

export default BankTransferForm;
