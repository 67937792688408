import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Row, Col, Layout, notification, Modal, Alert } from 'antd';
import styled, { css } from 'styled-components';
import CustomerHeader from 'components/customer/customerHeader';
import PaymentTab from './paymenttabs';
import { sendToPrinter } from 'services/user';
import {
  FOOTER_BUTTONS_AND_TEXT,
  BASKET_STATUS,
  BASKET_SERVICE_TYPE,
  ROUTE_CONFIG,
  PAYMENT_MODES,
  ITEM_STATUS,
  GENERIC_ERROR,
} from 'globalConstants';
import {
  TITLE_LABEL,
  TAKEN_FROM_CUSTOMER,
  PAY_TO_CUSTOMER,
  TAKE_FROM_CUSTOMER,
  PAID_TO_CUSTOMER,
  SUCCESS_MESSAGE_ON_UPDATE,
  BAGSLIP_SERVICES,
  BAGSLIP_PICKUP_ACTION,
  ERROR_MESSAGE,
  PAYMENT_PROMPT,
} from './constants';
import PaymentSummary from './paymentsummary';
import Summary from './summary';
import { useDispatch, useSelector } from 'react-redux';
import './index.less';
import {
  postPayment,
  generateReceipt,
  generateBagSlip,
} from 'services/payment';
import { getBarcodeSticker } from 'services/inventoryManagement';

import { actions } from 'redux/reducers/payment';
import { actions as basketActions } from 'redux/reducers/basket';
import { actions as checkoutActions } from 'redux/reducers/checkout';
import { actions as customerActions } from 'redux/reducers/customer';
import { actions as retailActions } from 'redux/reducers/retail';
import { actions as pickupActions } from 'redux/reducers/pickupRenew';
import { actions as chequeCashingActions } from 'redux/reducers/chequeCashing';

import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import { Button } from 'components/common/Button';
import { getLayawayTransactions } from 'services/retail';
import { usePaymentContext } from 'components/payment/PaymentContext';
import { isEmpty } from 'lodash';
import ControlledAlert from 'components/common/ControlledAlert';

const StyledLayout = styled(Layout)`
  background-color: var(--white);
  //height: calc(100vh - 156px);
`;

const StyledRow = styled(Row)`
  flex-wrap: nowrap;
  margin: 0;
  padding: 0 20px 0 0;
`;

const StyledRightCol = styled(Col)`
  margin-left: 0;
  width: 100%;
  padding: 0;
  &.split-control-box {
    margin-top: 10px;
    margin-right: 0;
    min-height: calc(100vh - 285px);
    border: 0.6px solid var(--linkwater);
    border-radius: 5px;
  }
`;

const StyledLeftCol = styled(Col)`
  &.split-control-box {
    margin-left: 0;
    margin-top: 10px;
    min-height: calc(100vh - 285px);
    border: 0.6px solid var(--linkwater);
    border-radius: 5px;
  }
`;

const StyledBottomFooter = styled(Row)<{
  $background: string;
}>`
  bottom: 0;
  width: 100%;
  font-size: 2.4rem;
  font-weight: var(--font-weight-500);
  padding: 15px 20px;
  background: var(--catskill-white-light);
  position: absolute;
  ${(props) =>
    props.$background &&
    css`
      background: var(--${props.$background});
    `}
`;

const StyledRowHeader = styled(Row)<{
  $background: string;
}>`
  padding: 15px 20px;
  background: var(--honeydue);
  font-weight: var(--font-weight-500);
  font-size: 2.4rem;
  font-style: normal;
  ${(props) =>
    props.$background &&
    css`
      background: var(--${props.$background});
    `}
`;

const StyledSummaryContainer = styled(Col)`
  background: var(--catskill-white-light);
  padding: 10px 12px;
  min-height: 50vh;
`;

const StyledFormControl = styled(Row)`
  padding: 15px 20px;
`;

const StyledFooter = styled(Row)`
  padding: 20px 0 10px 0;
  & .footer-buttons {
    border: 0;
    box-shadow: none;
  }

  & .success-button {
    background-color: var(--mountain-meadow);

    &:focus,
    &:active,
    &:hover {
      background-color: var(--mountain-meadow);
      border-color: var(--mountain-meadow);
    }
  }
  .button-container {
    margin-left: 8px;
  }
`;

const StyledCustomError = styled.div`
  left: 14vw;
  margin-right: 12px;
  margin-bottom: 16px;
`;

const Payment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    basket: { basket },
    payment: {
      totalCalculatedAmount = 0.0,
      payment,
      paymentMethods,
      isPaymentSuccess,
      totalPayableAmount,
      paymentconfirmationCode,
    },
    customer: { customer },
  } = useSelector((state: any) => {
    return {
      basket: state.basket,
      payment: state.payment,
      customer: state.customer,
    };
  });

  // Local states
  const [isBasketDetailLoaded, setIsBasketDetailLoaded] =
    useState<boolean>(false);
  const [isPromptVisible, setIsPromptVisible] = useState<boolean>(false);
  const [isPaymentInitiated, setIsPaymentInitiated] = useState<boolean>(false);
  const [isLayawayFundsMethodVisible, setIsLayawayFundsMethodVisible] =
    useState<boolean>(false);
  const [availableLayawayFunds, setAvailableLayawayFunds] =
    useState<number>(0.0);
  const [isBankTransferInitiated, setIsBankTransferInitiated] =
    useState<boolean>(false);
  const [errorChangeDue, setErrorChangeDue] = useState<string>('');
  // Context Data
  const {
    validatePayeeAccountResponse,
    setValidatePayeeAccountResponse,
    setBankTransferRequestRecently,
    setPaymentCompleted,
    setRecentBankTransferStatus,
  } = usePaymentContext();

  useEffect(() => {
    dispatch(actions.setTotalPayableAmount(basket?.totalAmount || 0));
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(actions.clear({}));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const tempTotalPayableAmount: any =
    Math.abs(totalPayableAmount).toFixed(2) || 0;

  const totalCalcAmt = totalCalculatedAmount.toFixed(2);
  const balanceDue =
    totalCalculatedAmount > 0
      ? +tempTotalPayableAmount - totalCalculatedAmount
      : totalPayableAmount;
  const changeDue = balanceDue < 0 ? Math.abs(balanceDue) : 0;

  const isPaymentDone =
    isPaymentSuccess ||
    basket?.basketStatus === BASKET_STATUS.PAYMENTSUCCESSFUL;

  const completeAndEndButton = (
    <>
      <span
        style={{
          fontSize: 'var(--font-size-20)',
          fontWeight: 'bolder',
        }}
        dangerouslySetInnerHTML={{
          __html: FOOTER_BUTTONS_AND_TEXT.COMPLETE_AND_END,
        }}
      ></span>
      <br></br>
      <span
        style={{
          fontSize: 'var(--font-size-16)',
          fontWeight: 'bolder',
        }}
        dangerouslySetInnerHTML={{
          __html: FOOTER_BUTTONS_AND_TEXT.PRINT_RECIEPT,
        }}
      ></span>
    </>
  );

  const onPaymentClick = async () => {
    let bankTransferRequestPayload = {};
    if (
      paymentMethods.includes(PAYMENT_MODES.BANKTRANSFER) &&
      !isEmpty(validatePayeeAccountResponse)
    ) {
      // if clientRequestId is present in the basket response, it will be a retry request.
      bankTransferRequestPayload = {
        clientRequestId: basket?.payment?.clientRequestId || null,
        payeeAccountId: validatePayeeAccountResponse?.payeeAccountId,
      };
      setIsBankTransferInitiated(true);
      setBankTransferRequestRecently(true);
    } else {
      setIsBankTransferInitiated(false);
      setBankTransferRequestRecently(false);
    }
    const params = {
      ...payment,
      ...bankTransferRequestPayload,
      totalCardAmount: Number(payment.totalCardAmount.toFixed(2)),
      totalCalculatedAmount: Math.abs(totalPayableAmount),
      basketId: basket?.basketId,
      changeDue: changeDue.toFixed(2),
      CardAuthorizationCode: paymentconfirmationCode,
    };
    try {
      if (!isPaymentSuccess) {
        setIsPaymentInitiated(true);

        const response = await postPayment(params);
        if (response?.data) {
          const bankTransferStatus =
            response?.data?.basket?.payment?.bankTransferStatus || '';

          // Bank Transfer request completed if it's there
          setIsBankTransferInitiated(false);
          setPaymentCompleted(true);
          setRecentBankTransferStatus(bankTransferStatus);
          notification.success({
            message: SUCCESS_MESSAGE_ON_UPDATE.message,
            description: SUCCESS_MESSAGE_ON_UPDATE.description,
            duration: 5,
          });

          if (
            response?.data?.basket?.basketStatus ===
            BASKET_STATUS.PAYMENTSUCCESSFUL
          ) {
            dispatch(actions.updatePaymentSuccess(true));
            dispatch(basketActions.getBasket(response?.data));
          }
        }
        onPrintReceiptClick();
      }
    } catch (e: any) {
      setIsPaymentInitiated(false);
      // Bank Transfer request - error
      setTimeout(() => {
        setIsBankTransferInitiated(false);
      }, 500);
      notification.error({
        message: e?.response?.data?.error || GENERIC_ERROR,
        duration: 5,
      });
    }
  };

  const onPrintReceiptClick = async () => {
    await printReceipt();
    await printBagSlip();
    await printBarcode();

    setIsPromptVisible(true);
  };

  const printReceipt = async () => {
    try {
      const storeReceipt = await generateReceipt(basket?.basketId, 1); // store receipt
      const customerReceipt = await generateReceipt(basket?.basketId, 2); // customer receipt

      const values = await Promise.all([storeReceipt, customerReceipt]);

      values.forEach((receipt: any) => {
        if (receipt?.data) {
          sendToPrinter(receipt, 'printreceipt', '_PrintReceipt');
        }
      });
    } catch (e: any) {
      notification.error({
        message: e?.message,
        description: e?.message,
        duration: 5,
      });
    }
  };

  const printBagSlip = () => {
    const bagSlips = basket?.basketServices.filter((x: any) =>
      BAGSLIP_SERVICES.includes(x.serviceType)
    );

    bagSlips.forEach((item: any) => {
      switch (item.serviceType) {
        case BASKET_SERVICE_TYPE.PICKUPRENEW:
          if (
            BAGSLIP_PICKUP_ACTION.includes(
              item.pickupRenew.request.item.selectedActionType
            )
          ) {
            ((item: any) => {
              setTimeout(() => {
                generateBagSlips(basket?.basketId, item.serviceId);
              }, 0);
            })(item);
          }
          break;
        case BASKET_SERVICE_TYPE.PLEDGE:
        case BASKET_SERVICE_TYPE.PURCHASE:
          ((item: any) => {
            setTimeout(() => {
              generateBagSlips(basket?.basketId, item.serviceId);
            }, 0);
          })(item);
          break;
        default:
          return;
      }
    });
  };

  const generateBagSlips = async (basketId: any, bagSlipType: number) => {
    try {
      const receiptData = await generateBagSlip(basketId, bagSlipType);
      if (receiptData?.data) {
        sendToPrinter(receiptData, 'printreceipt', '_GenerateBagSlips');
      }
    } catch (e: any) {
      notification.error({
        message: e?.message,
        description: e?.message,
        duration: 5,
      });
    }
  };

  const printBarcode = async () => {
    const retailServices = basket?.basketServices.filter(
      (x: any) => x.serviceType === BASKET_SERVICE_TYPE.RETAIL
    );
    const barcodes = retailServices
      .flatMap((x: any) => x.retail.request.items)
      .flat()
      .filter((x: any) => x.status === ITEM_STATUS.TO_RETURN)
      .flatMap((x: any) => x.barcode);

    barcodes.forEach((x: string) => {
      generateBarcode(x);
    });
  };

  const generateBarcode = async (barcode: string) => {
    try {
      const pdf = await getBarcodeSticker({
        barcode: barcode,
      });
      if (pdf?.data) {
        sendToPrinter(pdf, 'printbarcode', '_Retail_Barcode');
      }
    } catch (e: any) {
      notification.error({
        message: (e as Error).message,
        description: 'Barcode service is not running, please check.',
        duration: 5,
      });
    }
  };

  const onCompleteAndExitClick = () => {
    if (isPaymentSuccess) {
      dispatch(customerActions.clearAll({})); //clear customer session
      dispatch(basketActions.removeBasket({}));
      dispatch(basketActions.clearItems()); //clear basket
      dispatch(checkoutActions.clearCheckout()); //clear checkout
      dispatch(actions.updatePaymentSuccess(false)); // set to initial value
      dispatch(retailActions.clearItems());
      dispatch(pickupActions.clearItems());
      dispatch(chequeCashingActions.clearItems());
      setRecentBankTransferStatus('');
      setBankTransferRequestRecently(false);
      setValidatePayeeAccountResponse({});
      navigate('/');
    }
  };

  const cardAmt = payment?.totalCardAmount?.toFixed(2);
  const isPayment =
    (tempTotalPayableAmount === 0 ||
      +totalCalcAmt >= +tempTotalPayableAmount) &&
    +cardAmt <= +tempTotalPayableAmount &&
    Number(changeDue.toFixed(2)) <= 49.99;

  let background = '';
  const honeyDue =
    totalPayableAmount > 0 && +totalCalcAmt >= +tempTotalPayableAmount;
  const roseWhite =
    totalPayableAmount < 0 &&
    totalPayableAmount !== 0 &&
    +totalCalcAmt >= +tempTotalPayableAmount;

  if (honeyDue) {
    background = 'honeydue';
  } else if (roseWhite || totalPayableAmount === 0) {
    background = 'rose-white';
  }

  let isValid = totalPayableAmount < 0;
  let errorMessage = '';
  if (
    isValid &&
    totalCalculatedAmount > 0 &&
    totalCalculatedAmount > Math.abs(totalPayableAmount)
  ) {
    isValid = false;
    errorMessage = `${ERROR_MESSAGE.MORE_THAN}`;
  } else if (
    isValid &&
    totalCalculatedAmount > 0 &&
    totalCalculatedAmount < Math.abs(totalPayableAmount)
  ) {
    isValid = false;
    errorMessage = `${ERROR_MESSAGE.LESS_THAN}`;
  }

  const onBackToCheckoutClick = () => {
    dispatch(customerActions.setRecallCheckoutCustomer(true));
    navigate(`/${ROUTE_CONFIG.CHECKOUT}`);
  };

  const onAddPayment = useCallback((requestParams: any) => {
    if (PAYMENT_MODES.LAYAWAY_FUNDS === requestParams?.mode) {
      setAvailableLayawayFunds((balance: number) => {
        if (balance > requestParams?.amount) {
          return balance - requestParams?.amount;
        }
        return 0;
      });
    }
  }, []);

  const onRemoveTransaction = useCallback(
    (transaction: any) => {
      if (isBasketDetailLoaded) {
        if (PAYMENT_MODES.LAYAWAY_FUNDS === transaction?.mode) {
          setAvailableLayawayFunds((balance: number) => {
            return balance + transaction?.amount;
          });
        }
      }
    },
    [isBasketDetailLoaded]
  );

  const handleBasketDetaillCallback = useCallback(
    async (basketDetailArg: any) => {
      let updatedLayawayTransAccBalance = 0.0;
      const customerId = customer?.customerId || 0;
      const totalRetailAmount = basketDetailArg?.totalRetailAmount || 0;

      if (totalRetailAmount && basketDetailArg?.totalAmount > 0) {
        if (customerId) {
          setIsLayawayFundsMethodVisible(true);
        }
        try {
          const transactionResponse = await getLayawayTransactions(customerId);
          if (transactionResponse) {
            if (transactionResponse?.data?.layawayTransactionAccountBalance) {
              updatedLayawayTransAccBalance =
                transactionResponse?.data?.layawayTransactionAccountBalance;
            }
          }
        } catch (e: any) {
          notification.error({
            message: (e as Error).message,
            description: ERROR_MESSAGE.LAYAWAY_TRANSACTION_FAILED,
            duration: 5,
          });
        }

        if (updatedLayawayTransAccBalance > totalRetailAmount) {
          setAvailableLayawayFunds(totalRetailAmount);
        } else if (updatedLayawayTransAccBalance <= totalRetailAmount) {
          setAvailableLayawayFunds(updatedLayawayTransAccBalance);
        }
      } else {
        // red basket

        setIsLayawayFundsMethodVisible(false);
      }
      setIsBasketDetailLoaded(true);
    },
    [customer?.customerId]
  );

  useEffect(() => {
    if (!isPayment && totalPayableAmount > 0 && balanceDue < 0) {
      setErrorChangeDue(ERROR_MESSAGE.CHANGE_DUE);
    } else {
      setErrorChangeDue('');
    }
  }, [balanceDue, isPayment, totalPayableAmount]);

  return (
    <>
      <CustomerHeader
        title={TITLE_LABEL}
        buttonsOption={false}
        helpSection="PAYMENT"
      />
      <StyledLayout className="control-box">
        <StyledRow>
          <StyledLeftCol span={16} className="split-control-box">
            <StyledRowHeader
              justify="start"
              $background={totalPayableAmount > 0 ? '' : 'rose-white'}
            >
              <Col>
                <span>
                  {totalPayableAmount > 0 ? (
                    <HelpPopoverInfo
                      linkedID="PAYMENT_FROMCUSTOMER"
                      position="rightTop"
                      helpPosition="AFTER"
                    >
                      {TAKE_FROM_CUSTOMER}:
                    </HelpPopoverInfo>
                  ) : (
                    <HelpPopoverInfo
                      linkedID="PAYMENT_TOCUSTOMER"
                      position="rightTop"
                      helpPosition="AFTER"
                    >
                      {PAY_TO_CUSTOMER}:
                    </HelpPopoverInfo>
                  )}
                  £{tempTotalPayableAmount || 0}
                </span>
              </Col>
            </StyledRowHeader>
            <StyledFormControl>
              <Col span={16}>
                {!isValid && errorMessage && (
                  <StyledCustomError className="ant-form-item-explain ant-form-item-explain-error">
                    <div role="alert">{errorMessage}</div>
                  </StyledCustomError>
                )}
                {errorChangeDue && (
                  <StyledCustomError className="ant-form-item-explain ant-form-item-explain-error">
                    <Alert
                      message={errorChangeDue}
                      type="error"
                      showIcon
                      closable
                    />
                  </StyledCustomError>
                )}
                <PaymentTab
                  isValid={isValid}
                  isPayment={isPaymentDone}
                  totalAmount={Math.abs(tempTotalPayableAmount)}
                  balanceAmount={Math.abs(balanceDue)}
                  totalPayableAmount={totalPayableAmount}
                  isLayawayFundsMethodVisible={isLayawayFundsMethodVisible}
                  availableLayawayFunds={availableLayawayFunds}
                  onAddPayment={onAddPayment}
                />
              </Col>
              <StyledSummaryContainer span={8}>
                <PaymentSummary
                  isPayment={isPaymentDone}
                  onRemoveTransaction={onRemoveTransaction}
                />
              </StyledSummaryContainer>
            </StyledFormControl>
            <StyledBottomFooter
              justify="space-between"
              $background={background}
            >
              <Col>
                {totalPayableAmount > 0 ? (
                  <HelpPopoverInfo
                    linkedID="PAYMENT_TAKENFROMCUSTOMER"
                    position="rightTop"
                    helpPosition="AFTER"
                  >
                    {TAKEN_FROM_CUSTOMER}:
                  </HelpPopoverInfo>
                ) : (
                  <HelpPopoverInfo
                    linkedID="PAYMENT_PAIDTOCUSTOMER"
                    position="rightTop"
                    helpPosition="AFTER"
                  >
                    {PAID_TO_CUSTOMER}:
                  </HelpPopoverInfo>
                )}
                £{totalCalcAmt}
              </Col>
              <Col>
                <span>
                  {balanceDue >= 0
                    ? `Balance Due: £${balanceDue.toFixed(2)}`
                    : changeDue !== 0
                    ? `Change Due: £${changeDue.toFixed(2)}`
                    : ''}
                </span>
              </Col>
            </StyledBottomFooter>
          </StyledLeftCol>
          <StyledRightCol className="split-control-box" span={8}>
            <Summary
              basket={basket}
              basektDetailCallback={handleBasketDetaillCallback}
            />
          </StyledRightCol>
        </StyledRow>

        <StyledFooter>
          <Col span={4} className="button-container">
            <Button
              type="primary"
              itemProp="secondary"
              disabled={isPaymentInitiated}
              className="checkout-back-button"
              onClick={onBackToCheckoutClick}
            >
              Back to Checkout
            </Button>
          </Col>
          <Col span={8} push={12} className="button-container">
            <Button
              onClick={
                isPaymentDone && !isValid ? onPrintReceiptClick : onPaymentClick
              }
              disabled={!isPayment}
              className="complete-end-button"
            >
              {completeAndEndButton}
            </Button>
          </Col>
        </StyledFooter>
      </StyledLayout>

      <Modal
        className="payment-prompt-wrapper"
        open={isPromptVisible}
        closable={false}
        footer={
          <>
            <Button className="danger-button" onClick={onPrintReceiptClick}>
              {PAYMENT_PROMPT.REPRINT}
            </Button>
            <Button className="success-button" onClick={onCompleteAndExitClick}>
              {PAYMENT_PROMPT.COMPLETE}
            </Button>
          </>
        }
      >
        <div className="prompt-message">{PAYMENT_PROMPT.MESSAGE}</div>
      </Modal>
      {isBankTransferInitiated && (
        <ControlledAlert
          visible={isBankTransferInitiated}
          isCancelBtn={false}
          alertMessage=""
          isLoadingIcon={true}
          contentMessage={ERROR_MESSAGE.PLEASE_HOLD}
          message={ERROR_MESSAGE.AWAITING_BANK_TRANSFER}
          onClick={() => {}}
          onCancel={() => {}}
          isFooterButtonVisible={false}
        />
      )}
    </>
  );
};

export default Payment;
