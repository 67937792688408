import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,

  //consumerDuty comp state
  selectedTab: '0',
  selectedTaskId: null,
  selectedLoanIds: [],
  loanPrivileges: [],
  newPledgePermissions: {},
  caseSummary: {},
  selectedTaskOwner: null,
  lockedPledgeDetails: [],
  isReviewChangesPopupVisible: false,
  taskSubject: '',
  isCustomerIdentityVerified: false,
  isStoreEnquiryModeActiveForCase: false,
  isViewOnlyMode: false,

  //customer component state
  isSupportRequestRefPopupVisible: false,
  isSSTeamRequestRefPopupVisible: false,
  ssteamRequestTaskId: null,
  isManagedCase: false,

  //loanException grid state
  loanCustomer: { customerId: null, customerName: '' },
};
const slice = createSlice({
  name: 'consumerDutyAction',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSelectedTab(state, action) {
      state.selectedTab = action.payload;
    },
    setTaskId(state, action) {
      state.selectedTaskId = action.payload;
    },
    setSelectedTaskOwner(state, action) {
      state.selectedTaskOwner = action.payload;
    },
    setTaskSubject(state, action) {
      state.taskSubject = action.payload;
    },
    setSelectedLoanIds(state, action) {
      state.selectedLoanIds = [...action.payload];
    },
    setLoanPrivileges(state, action) {
      state.loanPrivileges = action.payload;
    },

    setIsSupportRequestRefPopupVisible(state, action) {
      state.isSupportRequestRefPopupVisible = action.payload;
    },
    setIsSSTeamRequestRefPopupVisible(state, action) {
      state.isSSTeamRequestRefPopupVisible = action.payload;
    },
    setSSTeamRequestTaskId(state, action) {
      state.ssteamRequestTaskId = action.payload;
    },
    setCaseSummary(state, action) {
      state.caseSummary = action.payload;
    },
    setNewPledgePermissions(state, action) {
      state.newPledgePermissions = action.payload;
    },
    setLockedPledgeDetails(state, action) {
      state.lockedPledgeDetails = action.payload;
    },
    setIsReviewChangesPopupVisible(state, action) {
      state.isReviewChangesPopupVisible = action.payload;
    },
    setIsCustomerIdentityVerified(state, action) {
      state.isCustomerIdentityVerified = action.payload;
    },
    setIsStoreEnquiryModeActiveForCase(state, action) {
      state.isStoreEnquiryModeActiveForCase = action.payload;
    },
    resetNewPledgePermissions(state) {
      return {
        ...state,
        newPledgePermissions: initialState.newPledgePermissions,
      };
    },
    setIsManagedCase(state, action) {
      state.isManagedCase = action.payload;
    },
    setLoanCustomer(state, action) {
      state.loanCustomer = action.payload;
    },
    setIsViewOnlyMode(state, action) {
      state.isViewOnlyMode = action.payload;
    },

    resetLoanException(state) {
      return {
        ...state,
        loanCustomer: initialState.loanCustomer,
        loanPrivileges: [],
        selectedLoanIds: [],
      };
    },
    resetConsumerDuty(state) {
      return {
        ...initialState,
      };
    },
  },
});

export default slice.reducer;
export const { reducer, actions } = slice;
export { initialState };
