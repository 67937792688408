import React, { useState, useEffect } from 'react';
import VirtualGrid from 'components/common/virtualGrid/index';
import { URL_TYPE } from 'globalConstants';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/reducers/retail';
import { RETAIL_ITEM_REQUEST } from 'action_creators/retail';
import ImageLoader from 'components/common/ImageLoader';
import { currencyFormat } from 'utils/util';
import { RETAIL_ITEM_STATUS } from '../constants';
import { notification } from 'antd';
import '.././index.less';
interface RetailSaleProps {
  barcode: string;
}

const StyledStatus = styled.span`
  font-weight: var(--font-weight-500);
  color: var(--red);
`;

const RetailSalesSummary = ({ barcode }: RetailSaleProps) => {
  const dispatch = useDispatch();
  const {
    retail: { rows, showSoldMessage, retailMessage },
  } = useSelector((state: any) => {
    return {
      retail: state.retail,
    };
  });

  useEffect(() => {
    if (showSoldMessage) {
      notification.error({
        message: retailMessage,
        duration: 2,
      });
      dispatch(actions.showSoldMessage(false));
    }
  }, [showSoldMessage]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (barcode) {
      loadRetailItems();
    }
  }, [barcode]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadRetailItems = async () => {
    dispatch({
      type: RETAIL_ITEM_REQUEST,
      payload: {
        params: {
          barcode: barcode,
          row: rows,
        },
      },
    });
  };

  const onRemoveItem = (row: any) => {
    dispatch(actions.retailItemDelete(row.stockItemId));
  };

  const itemDescription = (row: any) => {
    return {
      title: row.description,
      anchor: row.description,
      placement: 'bottom',
      itemId: row.stockItemId,
      data: {},
    };
  };

  const [columns] = useState([
    {
      name: 'image',
      title: 'Image',
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row?.stockItemId}?isThumnails=false`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
      allowSorting: false,
    },
    {
      name: 'inventoryType',
      title: 'Inventory Type',
      getCellValue: (row: any) => row?.inventoryType,
    },
    {
      name: 'barcode',
      title: 'Barcode',
      getCellValue: (row: any) => row?.barcode,
    },

    {
      name: 'description',
      title: 'Description',
      type: 'popover',
      getCellValue: (row: any) => itemDescription(row),
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) => {
        if (!row?.weight) return '';
        else if (typeof row.weight === 'string') return row?.weight + 'g';
        else return row?.weight?.toFixed(2) + 'g';
      },
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row: any) => {
        return row?.status === RETAIL_ITEM_STATUS.TO_RETURN ? (
          <StyledStatus>{row.status}</StyledStatus>
        ) : (
          <span>{row.status}</span>
        );
      },
    },
    {
      name: 'listValue',
      title: 'List Value',
      getCellValue: (row: any) =>
        '£' + currencyFormat(row?.listValue?.toFixed(2)),
    },
    {
      name: 'balanceDue',
      title: 'Balance Due',
      getCellValue: (row: any) => {
        if (row?.status !== RETAIL_ITEM_STATUS.TO_RETURN) {
          const balanceDue = row?.listValue - row?.discount;
          return <span>{'£' + currencyFormat(balanceDue.toFixed(2))}</span>;
        } else {
          const balanceDue = row?.soldValue;
          return (
            <StyledStatus>
              {'£' + currencyFormat(balanceDue?.toFixed(2))}
            </StyledStatus>
          );
        }
      },
    },
    {
      name: 'rowRemove',
      title: ' ',
      type: 'delete',
      getCellValue: (row: any) => row.action,
      onClick: (row: any) => {
        onRemoveItem(row);
      },
    },
  ]);
  const [tableColumnExtensions] = useState([
    { columnName: 'image', width: '8%', align: 'center' },
    { columnName: 'inventoryType', width: '11%' },
    { columnName: 'barcode', width: '15%' },
    { columnName: 'description', width: '19%' },
    { columnName: 'weight', width: '11%', align: 'right' },
    { columnName: 'status', width: '11%', align: 'center' },
    { columnName: 'listValue', width: '10%', align: 'right' },
    { columnName: 'balanceDue', width: '8%', align: 'right' },
    { columnName: 'rowRemove', width: '5%', align: 'right' },
  ]);

  const [formattedColumns] = useState(['rowRemove', 'description']);
  const [sortingStateColumnExtensions] = useState([
    { columnName: 'image', sortingEnabled: false },
  ]);

  return (
    <>
      <div className="retailSale-table-grid">
        <VirtualGrid
          formattedColumns={formattedColumns}
          urlType={URL_TYPE.NO_URL}
          columnsProps={columns}
          displayContentWithImage={true}
          tableColumnExtensionsProps={tableColumnExtensions}
          sortingStateColumnExtensions={sortingStateColumnExtensions}
          rowData={rows}
        />
      </div>
    </>
  );
};

export default RetailSalesSummary;
