import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VirtualGrid from 'components/common/virtualGrid/index';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { GRID_NO_URL } from 'globalConstants';
import styled from 'styled-components';
import { CUSTOMER_BUYING_SELLING_LABEL } from '../../constants';
import { DELETE_BASKET_FX_ITEMS } from 'action_creators/basket';

const StyledConainter = styled.div`
  margin: 5px 0;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-500);
  font-style: normal;
  line-height: 25px;
`;

const TableRow = ({ row, onClickDeleteRow, ...restProps }: any) => {
  const onDeleteClick = () => {
    onClickDeleteRow(row);
  };

  const Cell = () => {
    const { column } = restProps;
    if (column.name === 'delete') {
      return (
        <Table.Cell
          onClick={onDeleteClick}
          {...restProps}
          style={{
            cursor: 'pointer',
          }}
        />
      );
    }
    return (
      <Table.Cell
        {...restProps}
        style={{
          cursor: 'pointer',
        }}
      />
    );
  };

  return <>{Cell()}</>;
};

const SellingTable = (props: any) => {
  const rows = props?.items || [];
  const dispatch = useDispatch();

  const {
    basket: { basket },
    user: { user },
  } = useSelector((state: any) => {
    return {
      basket: state.basket,
      user: state.user,
    };
  });

  const onClickDeleteRow = (row: any) => {
    const deleteItemPayload = {
      basketId: basket?.basketId,
      serviceIds: [row?.serviceId],
      itemIds: [],
      userId: user?.userId,
    };
    dispatch({
      type: DELETE_BASKET_FX_ITEMS,
      payload: { params: deleteItemPayload },
    });
  };

  const [columns] = useState([
    {
      name: 'transactionType',
      title: 'Service',
      getCellValue: (row: any) => row.fx.request.transactionType,
      allowSorting: false,
    },
    {
      name: 'fromCurrencyCode',
      title: 'Currency From',
      type: 'currency-tooltip',
      getCellValue: (row: any) => ({
        currencyCode: row?.fx?.request?.fromCurrencyCode,
        amount: row?.fx?.request?.fromAmount,
        currencyDesc: row?.fx?.request?.fromCurrencyDescription,
      }),
    },

    {
      name: 'currencyExchangeRate',
      title: 'Exchange Rate',
      getCellValue: (row: any) => row.fx.request.currencyExchangeRate,
    },
    {
      name: 'rateGuarantee',
      title: 'Gurantee',
      getCellValue: (row: any) => row.fx.request.rateGuarantee,
    },
    {
      name: 'toCurrencyCode',
      title: 'currency To',
      type: 'currency-tooltip',
      getCellValue: (row: any) => ({
        currencyCode: row?.fx?.request?.toCurrencyCode,
        amount: row?.fx?.request?.toAmount,
        currencyDesc: row?.fx?.request?.toCurrencyDescription,
      }),
    },
    {
      name: 'delete',
      title: ' ',
      type: 'delete',
      onClick: () => { /* do nothing */ }, //TODO - implementation later
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'transactionType', width: '14%' },
    { columnName: 'fromCurrencyCode', width: '21%', align: 'right' },
    { columnName: 'currencyExchangeRate', width: '20%', align: 'right' },
    { columnName: 'rateGuarantee', width: '16%', align: 'right' },
    { columnName: 'toCurrencyCode', width: '17%' },
    { columnName: 'delete', width: '11%', align: 'center' },
  ]);

  const formattedColumns = ['fromCurrencyCode', 'toCurrencyCode', 'delete'];

  return (
    <>
      <StyledConainter>{CUSTOMER_BUYING_SELLING_LABEL}</StyledConainter>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={rows}
        formattedColumns={formattedColumns}
        cellComponent={(restProps: any) => (
          <TableRow {...restProps} onClickDeleteRow={onClickDeleteRow} />
        )}
      />
    </>
  );
};

export default SellingTable;
