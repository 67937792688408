import React, { useState } from 'react';

import { notification } from 'antd';
import { isEmpty } from 'lodash';

import VirtualGrid from 'components/common/virtualGrid';
import CustomerPopup from 'components/customer/CustomerPopup';

import { Button } from 'components/common/Button';
import { Table } from '@devexpress/dx-react-grid-material-ui';

import { FNS_DATE_FORMAT_WITH_TIME, GRID_NO_URL } from 'globalConstants';
import {
  PAY_MY_CUSTOMER_APP,
  STATUS_LABELS,
} from 'components/notifications/constants';

import { getCustomer } from 'services/customer';

import { currencyFormat, dateDisplayFormat } from 'utils/util';

interface BankTransfersTableProps {
  bankTransfers: Array<any>;
  onProcessClick: (processDetails: any) => void;
}

const TableRow = ({ row, onClickTableRow, ...restProps }: any) => {
  const onRowClick = () => {
    onClickTableRow(row);
  };

  const Cell = () => {
    return <Table.Cell onClick={onRowClick} {...restProps} />;
  };

  return <>{Cell()}</>;
};

const BankTransfersTable = ({
  bankTransfers = [],
  onProcessClick,
}: BankTransfersTableProps) => {
  const [customerDetails, setCustomerDetails] = useState<any>({});
  const [processDetails, setProcessDetails] = useState<Array<any>>([]);

  const confirmCustomer = async (customerId: any) => {
    try {
      const getCustomerDetails = await getCustomer(customerId);
      setCustomerDetails(getCustomerDetails?.data);
    } catch (e: any) {
      setCustomerDetails({});
      notification.error({
        message: e?.response?.data?.error || e?.message,
        duration: 5,
      });
    }
  };

  const [columns] = useState([
    {
      name: 'referenceNumber',
      title: 'Reference',
      getCellValue: (row: any) => row?.customerRequestReviewId,
    },
    {
      name: 'applicationName',
      title: 'Application',
      getCellValue: (row: any) => row?.applicationName,
    },
    // {
    //   name: 'source',
    //   title: 'Source',
    //   getCellValue: (row: any) => '',
    // },
    {
      name: 'service',
      title: 'Service',
      getCellValue: (row: any) => 'Bank Transfer',
    },
    {
      name: 'customerName',
      title: 'Customer Name',
      getCellValue: (row: any) => row?.customerName,
    },
    {
      name: 'createdOn',
      title: 'Created Date & Time',
      getCellValue: (row: any) =>
        dateDisplayFormat(row?.createdOn, FNS_DATE_FORMAT_WITH_TIME),
    },
    // {
    //   name: 'subject',
    //   title: 'Subject',
    //   getCellValue: (row: any) => '-',
    // },

    {
      name: 'amount',
      title: 'Amount',
      getCellValue: (row: any) => currencyFormat(row?.amount, true),
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row: any) => row?.status,
    },
    {
      name: 'actions',
      title: 'Actions',
      getCellValue: (row: any) =>
        row?.applicationName !== PAY_MY_CUSTOMER_APP &&
        (row?.status === STATUS_LABELS.APPROVED ||
          row?.status === STATUS_LABELS.REJECTED) && (
          <Button itemProp="secondary" onClick={() => onActionButtonClick(row)}>
            Process
          </Button>
        ),
    },
    // {
    //   name: 'closedOn',
    //   title: 'Closed',
    //   getCellValue: (row: any) =>
    //     dateDisplayFormat(row?.closedOn, FNS_DATE_FORMAT_WITH_TIME),
    // },
    {
      name: 'userName',
      title: 'User',
      getCellValue: (row: any) => row?.userName,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'referenceNumber', width: '6%', align: 'left' },
    { columnName: 'applicationName', width: '8%' },
    // { columnName: 'source', width: '8%' },
    { columnName: 'service', width: '12%' },
    { columnName: 'customerName', width: '12%' },
    { columnName: 'createdOn', width: '12%', align: 'center' },
    // { columnName: 'subject', width: '8%' },
    { columnName: 'amount', width: '8%', align: 'right' },
    { columnName: 'status', width: '8%' },
    { columnName: 'actions', width: '8%' },
    // { columnName: 'closedOn', width: '10%' },
    { columnName: 'userName', width: '8%', align: 'left' },
  ]);

  const onClickTableRow = (row: any) => {};

  const onCustomerPopupCancel = () => {
    setCustomerDetails({});
  };

  const onCustomerPopupOk = async () => {
    onProcessClick([...processDetails]);
    setCustomerDetails({});
  };

  const onActionButtonClick = (row: any) => {
    const process_details = [
      row?.userId,
      row?.status,
      row?.amount,
      row?.clientReference,
      row?.customerRequestReviewId,
      row?.userName,
    ];
    if (row?.status === STATUS_LABELS.REJECTED) {
      confirmCustomer(row?.customerId);
      setProcessDetails(process_details);
    } else {
      onProcessClick(process_details);
    }
  };

  return (
    <>
      <div className="work-queue-grid-wrapper bank-transfer-grid">
        <VirtualGrid
          urlType={GRID_NO_URL}
          virtualScroll={false}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={bankTransfers}
          cellComponent={(restProps: any) => (
            <TableRow onClickTableRow={onClickTableRow} {...restProps} />
          )}
          showSelectionColumn={false}
          showSelectAll={false}
        />
      </div>

      {!isEmpty(customerDetails) && (
        <CustomerPopup
          customer={customerDetails}
          onCustomerPopupCancel={onCustomerPopupCancel}
          onCustomerPopupOk={onCustomerPopupOk}
          additionalMessage={''}
        />
      )}
    </>
  );
};

export default BankTransfersTable;
