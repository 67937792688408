import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import VirtualGrid from 'components/common/virtualGrid/index';

import { TILL_LABELS } from '../constants';
import { FNS_DATE_FORMAT_WITH_TIME, GRID_NO_URL } from 'globalConstants';

const StyledCashUp = styled.span`
  font-weight: var(--font-weight-500);
  color: var(--mountain-meadow);
`;

const TableRow = ({
  row,
  onClickTableRow,
  loadTransferDetails,
  ...restProps
}: any) => {
  const onRowClick = () => {
    onClickTableRow(row);
  };

  const Cell = () => {
    const { selectedid, tableRow } = restProps;
    const isSelected = tableRow.row.tillOperationId === selectedid;

    return (
      <Table.Cell
        onClick={onRowClick}
        {...restProps}
        style={{
          cursor: 'pointer',
          backgroundColor: isSelected && `var(--catskill-white)`,
        }}
      />
    );
  };

  return <>{Cell()}</>;
};

interface TillHistoryTableProps {
  onRowClick: (row: any) => Promise<void>;
}

const TillHistoryTable = ({ onRowClick }: TillHistoryTableProps) => {
  const {
    cashManagement: { storeTillHistory, selectedTillOperationId },
  } = useSelector((state: any) => {
    return {
      cashManagement: state.cashManagement,
    };
  });

  const [isComponentMounted, setIsComponentMounted] = useState(false);
  useEffect(() => {
    setIsComponentMounted(true);
  }, []);

  const [columns] = useState([
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row: any) =>
        row?.status === TILL_LABELS.CASHED_UP ? (
          <StyledCashUp>{row?.status}</StyledCashUp>
        ) : (
          row?.status
        ),
    },
    {
      name: 'currency',
      title: 'Currency',
      type: 'currency-tooltip',
      getCellValue: (row: any) => ({
        currencyCode: row?.currencyCode,
        currencyDesc: row?.currencyDescription,
      }),
    },
    {
      name: 'declared',
      title: 'Declared',
      getCellValue: (row: any) => (
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: row?.currencySymbol,
            }}
          ></span>
          <span>{row?.declaredAmount?.toFixed(2) || '0.00'}</span>
        </>
      ),
    },
    {
      name: 'variance',
      title: 'Variance',
      getCellValue: (row: any) => (
        <>
          {row?.variance < 0 && '-'}
          <span
            dangerouslySetInnerHTML={{
              __html: row?.currencySymbol,
            }}
          ></span>
          <span>{Math.abs(row?.variance?.toFixed(2))}</span>
        </>
      ),
    },
    {
      name: 'checkedUser',
      title: 'Checked By',
      getCellValue: (row: any) => row?.checkedUser,
    },
    {
      name: 'confirmedBy',
      title: 'Confirmed By',
      getCellValue: (row: any) => row?.confirmedUser,
    },
    {
      name: 'operationDate',
      title: 'Date & Time',
      getCellValue: (row: any) =>
        row?.operationDate &&
        `${format(new Date(row?.operationDate), FNS_DATE_FORMAT_WITH_TIME)}`,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'status', width: '85px' },
    { columnName: 'currency', width: '85px', align: 'center' },
    { columnName: 'declared', width: '100px', align: 'right' },
    { columnName: 'variance', width: '100px', align: 'right' },
    { columnName: 'checkedUser', width: '160px', align: 'center' },
    { columnName: 'confirmedBy', width: '160px', align: 'center' },
    { columnName: 'operationDate', width: '175px', align: 'right' },
  ]);

  const [formattedColumns] = useState(['currency']);

  const onClickTableRow = (row: any) => {
    onRowClick(row);
  };

  return isComponentMounted ? (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={storeTillHistory || []}
        virtualScroll={true}
        formattedColumns={formattedColumns}
        cellComponent={(restProps: any) => (
          <TableRow
            onClickTableRow={onClickTableRow}
            selectedid={selectedTillOperationId}
            {...restProps}
          />
        )}
      />
    </>
  ) : null;
};

export default TillHistoryTable;
