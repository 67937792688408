import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AUCTION_STATUS } from 'globalConstants';
import {
  AUCTION_MANAGER_TITLE,
  VIEW_AUCTION_CLOSED_TITLE,
  VIEW_AUCTION_LIVE_STORE_TITLE,
  VIEW_AUCTION_PENDING_RETURN_TITLE,
  VIEW_AUCTION_LIVE_AT_AUCTION_TITLE,
  VIEW_AUCTION_READY_FOR_AUCTION_TITLE,
} from 'components/pledgemanagement/constants';
import { actions } from 'redux/reducers/pledgeManagement';
import AuctionList from './auctionList/index';
import ClosedAuctionDetails from './auctionDetails/closedAuctionDetails';
import PendingReturnAuctionDetails from './auctionDetails/pendingReturnAuctionDetails';
import LiveAtAuctionDetails from './auctionDetails/liveAtAuctionDetails';
import LiveAtStoreDetails from './auctionDetails/liveAtStoreDetails';
import { GET_AUCTION_DETAILS } from 'action_creators/pledgeManagement';
import ReadyForAuctionDetails from './auctionDetails/readyForAuctionDetails';

const AuctionManager = () => {
  const { auctionItemDetails, confirmedCollection } = useSelector(
    (state: any) => state.pledgeManagement
  );

  const dispatch = useDispatch();

  const [activeView, setActiveView] = useState<string>(AUCTION_MANAGER_TITLE);

  useEffect(() => {
    return () => {
      dispatch(actions.clearAuctions());
    };
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (confirmedCollection?.id) {
      dispatch({
        type: GET_AUCTION_DETAILS,
        payload: {
          auctionId: confirmedCollection?.id,
          auctionStatus: AUCTION_STATUS.LIVE_AUCTION,
        },
      });
    }
  }, [confirmedCollection, dispatch]);

  useEffect(() => {
    if (
      auctionItemDetails?.auctionId &&
      auctionItemDetails?.auctionStatus === AUCTION_STATUS.CLOSED
    )
      setViewtoClosedAuctionDetails();
    else if (
      auctionItemDetails?.auctionId &&
      auctionItemDetails?.auctionStatus === AUCTION_STATUS.LIVE_STORE
    )
      setViewtoActiveAuctionDetails();
    else if (
      auctionItemDetails?.auctionStatus === AUCTION_STATUS.PENDING_RETURN
    )
      setViewtoPendingReturnAuctionDetails();
    else if (
      auctionItemDetails?.auctionId &&
      auctionItemDetails?.auctionStatus === AUCTION_STATUS.LIVE_AUCTION
    )
      setViewtoLiveAtAuctionDetails();
    else if (
      auctionItemDetails?.auctionId &&
      auctionItemDetails?.auctionStatus === AUCTION_STATUS.READY_FOR_AUCTION
    )
      setViewtoReadyForAuctionDetails();
  }, [auctionItemDetails]);

  const setViewtoClosedAuctionDetails = () => {
    setActiveView(VIEW_AUCTION_CLOSED_TITLE);
  };

  const setViewtoActiveAuctionDetails = () => {
    setActiveView(VIEW_AUCTION_LIVE_STORE_TITLE);
  };

  const setViewtoLiveAtAuctionDetails = () => {
    setActiveView(VIEW_AUCTION_LIVE_AT_AUCTION_TITLE);
  };

  const setViewtoPendingReturnAuctionDetails = () => {
    setActiveView(VIEW_AUCTION_PENDING_RETURN_TITLE);
  };

  const setViewtoAuctionManager = () => {
    setActiveView(AUCTION_MANAGER_TITLE);
  };

  const setViewtoReadyForAuctionDetails = () => {
    setActiveView(VIEW_AUCTION_READY_FOR_AUCTION_TITLE);
  };

  return (
    <>
      {activeView === AUCTION_MANAGER_TITLE && <AuctionList />}
      {activeView === VIEW_AUCTION_CLOSED_TITLE && (
        <ClosedAuctionDetails onExit={setViewtoAuctionManager} />
      )}
      {activeView === VIEW_AUCTION_LIVE_STORE_TITLE && (
        <LiveAtStoreDetails onExit={setViewtoAuctionManager} />
      )}
      {activeView === VIEW_AUCTION_PENDING_RETURN_TITLE && (
        <PendingReturnAuctionDetails onExit={setViewtoAuctionManager} />
      )}
      {activeView === VIEW_AUCTION_LIVE_AT_AUCTION_TITLE && (
        <LiveAtAuctionDetails onExit={setViewtoAuctionManager} />
      )}
      {activeView === VIEW_AUCTION_READY_FOR_AUCTION_TITLE && (
        <ReadyForAuctionDetails onExit={setViewtoAuctionManager} />
      )}
    </>
  );
};

export default AuctionManager;
