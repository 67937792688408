import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Form, Layout, Row, Col, Input, notification } from 'antd';
import { isEmpty } from 'lodash';
import { ArrowRightOutlined } from '@ant-design/icons';

import { actions } from 'redux/reducers/inventoryManagement';
import {
  DELETE_TRANSFER_ITEMS,
  STORES_REQUEST,
  AUTHORIZE_STOCK_TRANSFER,
  SEND_STOCK_TRANSFER,
} from 'action_creators/inventoryManagement';
import {
  STOCK_TRANSFER_LABELS,
  GENERIC_ERROR,
} from 'components/inventoryManagement/constants';
import { Button } from 'components/common/Button';
import {
  postBarcodeSearch,
  postCreateTransfer,
  postPrintStockTransfer,
} from 'services/inventoryManagement';

import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import FooterButtons from 'components/common/footerButtons';
import TransferSummary from './transferSummary';
import AuthorizationForm from 'components/inventoryManagement/AuthorizationForm';
import ControlledAlert from 'components/common/ControlledAlert';
import Select from 'components/common/Select';
import { sendToPrinter } from 'services/user';
import { currencyFormat } from 'utils/util';

const StyledContentLayout = styled(Layout)`
  background: var(--white);
  padding: 15px 30px;
  border-radius: 5px;
`;

const StyledRow = styled(Row)`
  position: absolute;
  bottom: 0;
  right: 0;
  padding-bottom: 10px;
}`;

const StyledSpan = styled.span`
  font-size: 20px;
  font-weight: 500;
`;

const StyledTableWrapper = styled.div`
  height: calc(100vh - 515px);
`;
const StyledSummaryDiv = styled.div`
  text-align: right;
  height: 40px;
  font-weight: 700;
  padding: 6px 20px;
  background: #f4f7fa;
`;

interface CreateCashTransferProps {
  onExit: () => void;
}

const CreateCashTransfer = ({ onExit }: CreateCashTransferProps) => {
  const {
    inventoryManagement: {
      submiting,
      searching,
      deleting,
      destinationStores,
      isAuthorized,
      authorizerName,
      transferParams,
      transferItemsList,
      isEditMode,
      isClosedTransfer,
      selectedItemIds,
    },
    user: { user, selectedStore },
  } = useSelector((state: any) => {
    return {
      inventoryManagement: state.inventoryManagement,
      user: state.user,
    };
  });

  const [sourceStoreId, setSourceStoreId] = useState<number>(-1);
  const [transferId, setTransferId] = useState<number>(-1);
  const [destinationStoreId, setDestinationStoreId] = useState<number>(-1);
  const [totalWeight, setTotalWeight] = useState<number>(0);
  const [totalCostPrice, setTotalCostPrice] = useState<number>(0);
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [isTransferNoGenerated, setIsTransferNoGenerated] =
    useState<boolean>(false);
  const [alertMsg, setAlertMsg] = useState<string>('');

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    destinationStores.length === 0 &&
      dispatch({
        type: STORES_REQUEST,
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEmpty(transferParams)) {
      if (transferParams.destinationStoreId !== -1) {
        form.setFieldsValue({ destination: transferParams.destinationStoreId });
        setDestinationStoreId(transferParams?.destinationStoreId || -1);
      }
      setTransferId(transferParams?.transferId);
      setSourceStoreId(transferParams?.sourceStoreId || -1);

      if (transferParams?.destinationStoreId !== -1)
        setIsTransferNoGenerated(true);
    }
  }, [transferParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let totalPrice = 0;
    let totalWeigth = 0;

    transferItemsList.forEach((el: any) => {
      totalPrice = totalPrice + el.costValue;
      totalWeigth = totalWeigth + el.weight;
    });

    setTotalCostPrice(totalPrice);
    setTotalWeight(totalWeigth);
  }, [transferItemsList]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSendClick = () => {
    const params = {
      TransferId: transferId,
      UserId: user.userId,
      SourceStoreId: selectedStore.storeId,
      DestinationStoreId: destinationStoreId,
    };
    const printParams = {
      TransferId: transferId,
      transferSentBy: user.displayName,
      transferApprovedBy: authorizerName,
    };

    dispatch({
      type: SEND_STOCK_TRANSFER,
      payload: { params, printParams },
    });
  };

  const onPrintClick = async () => {
    const printParams = {
      TransferId: transferId,
      transferSentBy: user.displayName,
      transferApprovedBy: authorizerName,
      TransferStatus: 'Pending',
    };

    try {
      const response = await postPrintStockTransfer(printParams);
      if (response?.data) {
        sendToPrinter(response, 'printcontract', '_StockTransferReceipt');
      }
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.error,
        duration: 3,
      });
    }
  };

  const onSearchClick = async () => {
    const params = {
      items: [],
      barcode: form.getFieldValue('barcode'),
      transferId: transferParams.transferId,
      userId: user.userId,
      SourceStoreId: selectedStore.storeId,
      DestinationStoreId: destinationStoreId,
    };

    try {
      dispatch(actions.initSearch(true));
      const response = await postBarcodeSearch(params);
      if (response?.data) {
        form.setFieldsValue({ barcode: '' });
        dispatch(actions.initSearch(false));
        dispatch(actions.newItemAdded(true));

        const transferList = [...transferItemsList];
        response.data.index = transferItemsList.length + 1;
        transferList.push(response.data);

        dispatch(actions.setTransferItemsList(transferList));
      } else {
        dispatch(actions.initSearch(false));
        notification.error({
          message: GENERIC_ERROR,
          duration: 3,
        });
      }
    } catch (e: any) {
      dispatch(actions.initSearch(false));
      if (e?.response?.status === 412) {
        setAlertMsg(e?.response?.data?.error);
        setIsAlertVisible(true);
      } else {
        notification.error({
          message: e?.response?.data?.error || GENERIC_ERROR,
          duration: 3,
        });
      }
    }
  };

  const onDestinationStoreChange = (event: any) => {
    setDestinationStoreId(event);
  };

  const onAlertButtonClick = async () => {
    const params = {
      transferReference: '',
      sourceStoreId: selectedStore.storeId,
      destinationStoreId: -1,
      employeeId: user.employeeId,
      transferId: '0',
    };

    try {
      setIsAlertVisible(false);
      dispatch(actions.initLoading(true));
      const response = await postCreateTransfer(params);
      if (response?.data) {
        dispatch(actions.initLoading(false));
        dispatch(actions.setTransferParams(response.data));
        dispatch(actions.setTransferItemsList([]));
      }
    } catch (e: any) {
      dispatch(actions.initLoading(false));
      notification.error({
        message: e?.response?.data?.error,
        duration: 3,
      });
    }
  };

  const onDeleteItemsClick = () => {
    if (selectedItemIds.length > 0) {
      const params = {
        transferId: transferId,
        userId: user.userId,
        Items: selectedItemIds,
        Barcode: '',
      };
      dispatch({
        type: DELETE_TRANSFER_ITEMS,
        payload: { params, transferItemsList },
      });
    }
  };

  const onExitClick = () => {
    dispatch(actions.exitCreateEditTransfer());
    onExit();
  };

  const authorizationHandler = (authorizationHeaders: any) => {
    const params = {
      TransferId: transferId,
      Username: authorizationHeaders.authorisationUserName,
      ApprovalType: STOCK_TRANSFER_LABELS.CREATE_NEW_TRANSFER.APPROVAL_TYPE,
      SourceStoreId: sourceStoreId,
      DestinationStoreId: destinationStoreId,
    };

    dispatch({
      type: AUTHORIZE_STOCK_TRANSFER,
      payload: { params, authorizationHeaders },
    });
  };

  return (
    <>
      <StyledContentLayout>
        <Row className="title-row">
          <Col span={24}>
            <HelpPopoverInfo
              linkedID={''}
              position="rightTop"
              helpPosition="AFTER"
            >
              {isClosedTransfer
                ? STOCK_TRANSFER_LABELS.TITLES.CLOSED_TRANSFER
                : isEditMode
                ? STOCK_TRANSFER_LABELS.TITLES.EDIT_TRANSFER
                : STOCK_TRANSFER_LABELS.TITLES.CREATE_NEW_TRANSFER}
            </HelpPopoverInfo>
          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <Form
              name="addItemForm"
              form={form}
              onFinish={onSearchClick}
              autoComplete="off"
              className="flex-column"
            >
              <Row style={{ padding: '5px 0' }}>
                <Col span={5}>
                  <Form.Item
                    label={
                      STOCK_TRANSFER_LABELS.CREATE_NEW_TRANSFER.DESTINATION
                    }
                    name="destination"
                    className="label-control"
                  >
                    <Select
                      disabled={isClosedTransfer || isTransferNoGenerated}
                      onChange={onDestinationStoreChange}
                      options={destinationStores
                        ?.filter(
                          (el: any) => el.storeId !== selectedStore.storeId
                        )
                        ?.map((el: any) => {
                          return {
                            value: el.storeId,
                            label: `${el.storeName} - ${el.storeId}`,
                          };
                        })}
                    />
                  </Form.Item>
                </Col>
                {!isClosedTransfer && (
                  <Col span={7} style={{ marginLeft: '20px' }}>
                    <Form.Item
                      label={STOCK_TRANSFER_LABELS.CREATE_NEW_TRANSFER.BARCODE}
                      name="barcode"
                      className="label-control"
                      rules={[
                        {
                          required: true,
                          message: 'Barcode is required',
                        },
                      ]}
                    >
                      <Input
                        disabled={destinationStoreId === -1}
                        className="input-control custon-input-addon"
                        placeholder="Scan/Enter Barcode"
                        addonAfter={
                          <Button
                            disabled={destinationStoreId === -1}
                            loading={searching}
                            htmlType="submit"
                          >
                            <ArrowRightOutlined style={{ fontSize: '18px' }} />
                          </Button>
                        }
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </Col>
          <Col span={6}>
            <StyledRow justify="end">
              <StyledSpan>
                Transfer Number: T-
                {sourceStoreId !== -1 ? sourceStoreId % 1000 : 'NA'}-
                {transferId}-
                {destinationStoreId !== -1 ? destinationStoreId % 1000 : 1}
              </StyledSpan>
            </StyledRow>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <StyledTableWrapper className="item-added-table">
              <TransferSummary stockTransferList={transferItemsList} />
            </StyledTableWrapper>
            <StyledSummaryDiv>
              <Col span={24}>
                <Row style={{ paddingTop: '5px' }}>
                  <Col span={8}>
                    <Row justify="start">
                      No. of Items: {transferItemsList.length}
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row justify="end">
                      Total Weight: {totalWeight.toFixed(2)}g
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row justify="end">
                      Cost Price: {currencyFormat(totalCostPrice, true)}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </StyledSummaryDiv>
          </Col>
        </Row>
        <Row style={{ paddingTop: '10px', height: '60px' }}>
          {!isClosedTransfer && (
            <AuthorizationForm
              addonBeforeText={STOCK_TRANSFER_LABELS.AUTHORIZATION_MSG}
              authorizationHandler={authorizationHandler}
            />
          )}
        </Row>
      </StyledContentLayout>
      <FooterButtons
        enableExtraClass
        secondaryExtraButtonEnable={transferItemsList?.length ? true : false}
        isLoading={submiting}
        isExtraButtonLoading={deleting}
        isDisabled={!isAuthorized}
        secondaryExtraButtonText={STOCK_TRANSFER_LABELS.PRINT_LABEL}
        isExitOnly={true}
        enableExitPopup={false}
        onConfirmExitClick={onExitClick}
        extraButtonEanable={isClosedTransfer ? false : true}
        extraButtonAlign="left"
        extraButtonText="Delete Item(s)"
        extraButtonOnClick={onDeleteItemsClick}
        onSecondaryExtraButtonClick={onPrintClick}
        secondaryExtraButtonClass="decline-button"
        proceedButtonText="Send"
        onProceedClick={onSendClick}
      />

      <ControlledAlert
        closable={true}
        visible={isAlertVisible}
        message={alertMsg}
        onClick={onAlertButtonClick}
        onCancel={() => setIsAlertVisible(false)}
        yesButtonText={STOCK_TRANSFER_LABELS.ALERT_BUTTON_LABEL}
        isCancelBtn={false}
      />
    </>
  );
};

export default CreateCashTransfer;
