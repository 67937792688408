import React, { useCallback } from 'react';
import { Row, Col } from 'antd';
import { CHECK_PRE_AUTH } from '../constants';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { POST_PRE_AUTHENTICATION } from 'action_creators/chequeCashing';
import { isNull } from 'lodash';
import { toFixedNoRound } from 'utils/util';

const StyledSpan = styled.span`
  color: var(--white);
`;

const StyledColTick = styled(Col)`
  padding-top: 8px;
  margin-left: 10px;
`;

const StyledRow = styled(Row)`
  min-width: 14vw;
  max-width: 35vw;
`;

const StyledDiv = styled(Row)<{ bgcolor: boolean }>`
  background-color: var(
    --${(props) => (props.bgcolor ? 'light-bg-green' : 'light-bg-red')}
  );
  position: relative;
  bottom: -9px;
  height: 2.2vw;
`;

const StyledText = styled.text`
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-500);
  line-height: 16px;
  flex-basis: 95%;
  margin: 15px;
  position: relative;
  top: 2px;
`;

const AuthorizationPayout = ({ isPreAuthDisabled }: any) => {
  const dispatch = useDispatch();
  const {
    customer,
    chequeCashing: {
      chequeDetails,
      requestedAmount,
      preAuthLoading,
      preAuthStatusCode,
      preAuthText,
    },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
      chequeCashing: state.chequeCashing,
    };
  });

  const preAuthorizationCheck = (
    <>
      <StyledDiv bgcolor={preAuthStatusCode === 0}>
        <Col span={24}>
          <StyledRow>
            <StyledColTick span={24}>
              <img
                alt={preAuthStatusCode === 0 ? 'pass tick' : 'failed tick'}
                className={
                  preAuthStatusCode === 0 ? 'pass-tick' : 'failed-tick'
                }
                src={require('../../../assets/images/' +
                  (preAuthStatusCode === 0 ? 'tick.svg' : 'Alert_icon.svg'))}
              />
              <StyledText>{preAuthText}</StyledText>
            </StyledColTick>
          </StyledRow>
        </Col>
      </StyledDiv>
    </>
  );

  const onClickPreAuthentication = useCallback(() => {
    const params = {
      customerId: customer?.customer?.customerId,
      issuerId: chequeDetails?.issuerDetails?.issuerId,
      issuerClassId: chequeDetails?.issuerDetails?.issuerClassId,
      amount: toFixedNoRound(requestedAmount),
      chequeNumber: chequeDetails.chequeNumber.trim(),
      chequeDate: chequeDetails.chequeDate,
      sortCode: chequeDetails.sortCode,
      accountNumber: chequeDetails.accountNumber,
      onStop: chequeDetails?.customer?.onStop || 0,
      note: chequeDetails?.chequeData?.notesDoc || '',
      chequeType: chequeDetails?.chequeTypeDetails?.chequeTypeId,
    };

    dispatch({
      type: POST_PRE_AUTHENTICATION,
      payload: params,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chequeDetails, requestedAmount, customer]);

  return (
    <>
      <Row className="pre-auth-row">
        <Col span={5}>
          <Row gutter={[16, 32]}>
            <Col span={24}>
              <Button
                className={`pre-auth-bg-color${
                  isNull(preAuthStatusCode)
                    ? ''
                    : preAuthStatusCode === 0
                    ? '-green'
                    : '-red'
                }`}
                loading={preAuthLoading}
                itemProp="secondary"
                key="back"
                onClick={onClickPreAuthentication}
                disabled={isPreAuthDisabled}
              >
                <StyledSpan> {CHECK_PRE_AUTH} </StyledSpan>
              </Button>
            </Col>
          </Row>
        </Col>
        {!isNull(preAuthStatusCode) && preAuthorizationCheck}
      </Row>
    </>
  );
};

export default AuthorizationPayout;
