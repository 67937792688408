import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { isArray, isEmpty } from 'lodash';
import { Row, Checkbox, Tooltip } from 'antd';
import { Table } from '@devexpress/dx-react-grid-material-ui';

import {
  CHECK_LIST,
  FINAL_CHECK_TITLE,
  RETAIL_ACTION_ID,
  WU_ACTIONS,
} from '../constants';
import { AGREEMENT_OPTIONS } from 'components/pickupRenew/constants';
import { RETAIL_ITEM_STATUS } from 'components/retail/constants';
import { GRID_NO_URL, BASKET_SERVICE_TYPE } from 'globalConstants';
import { getBasketByBasketId } from 'services/checkout';
import VirtualGrid from 'components/common/virtualGrid/index';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';

import './index.less';

const StyledRowHeader = styled(Row)`
  padding: 15px 20px;
  background: var(--lighter-gray);
  font-weight: var(--font-weight-500);
  font-size: 2.4rem;
  font-style: normal;
`;

const TableRow = ({ row, ...restProps }: any) => {
  const Cell = () => {
    const { column } = restProps;
    if (column.name === 'number')
      if (row.hyperLinkContent) {
        const preText =
          row.service === CHECK_LIST.RENEW.service ||
          row.service === CHECK_LIST.PAYDOWN.service
            ? 'Previous Agreement no:'
            : '';

        return (
          <Table.Cell
            {...restProps}
            style={{
              cursor: 'pointer',
            }}
          >
            <Tooltip
              title={`${preText} ${row.hyperLinkContent}`}
              placement="bottom"
              color="blue"
            >
              <span style={{ color: 'blue' }}>{row.number}</span>
            </Tooltip>
          </Table.Cell>
        );
      }

    return <Table.Cell {...restProps} style={{ cursor: 'default' }} />;
  };

  return <>{Cell()}</>;
};

const Summary = (props: any) => {
  const [basketDetails, setBasketDetails] = useState<any>();
  const [finalChecks, setFinalChecks] = useState<Array<any>>([]);

  useEffect(() => {
    props.basket.basketId && getUpdatedBasket();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (basketDetails?.basket?.basketServices.length > 0) {
      const checkList = basketDetails?.basket?.basketServices?.reduce(
        (arr: any, item: any) => {
          const obj = getCheckListRow(item);
          return !isEmpty(obj)
            ? isArray(obj)
              ? [...arr, ...obj]
              : [...arr, obj]
            : [...arr];
        },
        []
      );

      setFinalChecks(checkList);
    }
  }, [basketDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUpdatedBasket = async () => {
    try {
      //geting latest basket response with NEW AGREEMENT NO.
      const response = await getBasketByBasketId(props.basket.basketId);
      if (response?.data) setBasketDetails(response?.data);

      if (props.basektDetailCallback && response?.data) {
        props.basektDetailCallback(response?.data?.basket);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const getCheckListRow = (item: any) => {
    let items = {};

    switch (item?.serviceType) {
      case BASKET_SERVICE_TYPE.PLEDGE:
        items = {
          service: CHECK_LIST.PLEDGE.service,
          number: item?.pledge?.response?.serviceLoanNumber,
          action: CHECK_LIST.PLEDGE.action,
          hyperLinkContent: '',
          itemId: item?.pledge?.response?.serviceLoanNumber,
        };
        break;

      case BASKET_SERVICE_TYPE.PURCHASE:
        items = {
          service: CHECK_LIST.PURCHASE.service,
          number: item?.purchase?.response?.servicePurchaseId,
          action: CHECK_LIST.PURCHASE.action,
          hyperLinkContent: '',
          itemId: item?.purchase?.response?.servicePurchaseId,
        };
        break;

      case BASKET_SERVICE_TYPE.PURCHASERETURN:
        items = {
          service: CHECK_LIST.PURCHASERETURN.service,
          number: item?.purchaseReturn?.response?.servicePurchaseId,
          action: CHECK_LIST.PURCHASERETURN.action,
          hyperLinkContent: '',
          itemId: item?.purchaseReturn?.response?.servicePurchaseId,
        };
        break;

      case BASKET_SERVICE_TYPE.RETAIL:
        items = item?.retail?.request?.items.reduce((arr: any, item: any) => {
          const retailItem = {
            service: CHECK_LIST.RETAIL.service,
            number: item?.barcode,
            action:
              item.status === RETAIL_ITEM_STATUS.TO_RETURN
                ? CHECK_LIST.REFUND.action
                : CHECK_LIST.RETAIL.action,
            hyperLinkContent: item?.description,
            itemId: item?.barcode,
          };
          return [...arr, retailItem];
        }, []);

        break;

      case BASKET_SERVICE_TYPE.FX: {
        const action =
          item?.fx?.request?.transactionType === 'Buy'
            ? `Give (${item?.fx?.request?.toAmount} ${item?.fx?.request?.toCurrencyCode}) to the customer`
            : `Receive (${item?.fx?.request?.fromAmount} ${item?.fx?.request?.fromCurrencyCode}) from the customer`;

        items = {
          service: CHECK_LIST.TRAVEL_MONEY.service,
          number: '',
          action: action,
          hyperLinkContent: '',
          itemId: item?.fx?.request?.transactionId,
        };
        break;
      }
      case BASKET_SERVICE_TYPE.PICKUPRENEW: {
        const actionType = getPickupRenewActionDetails(
          item?.pickupRenew?.request,
          item?.pickupRenew?.response
        );

        items = { ...actionType };
        break;
      }
      case BASKET_SERVICE_TYPE.CHEQUECASHING:
        items = {
          service: CHECK_LIST.CHEQUE_CASHING.service,
          number: item?.thirdPartyCheque?.request?.item?.chequeNumber,
          action: CHECK_LIST.CHEQUE_CASHING.action,
          hyperLinkContent: '',
          itemId: item?.thirdPartyCheque?.request?.item?.chequeNumber,
        };
        break;

      case BASKET_SERVICE_TYPE.WU:
        items = {
          service: CHECK_LIST.WESTERN_UNION.service,
          number: item?.westernUnion?.request?.mtcnRefNo,
          action:
            item?.westernUnion?.request?.receivedAmount > 0
              ? WU_ACTIONS.TAKE_PAYMENT
              : WU_ACTIONS.PAY_CUSTOMER,
          hyperLinkContent: '',
          itemId: item?.westernUnion?.request?.mtcnRefNo,
        };
        break;
      case BASKET_SERVICE_TYPE.SURPLUS:
        items = {
          service: CHECK_LIST.SURPLUS.service,
          number: item?.surplus?.response?.loanNumber,
          action: CHECK_LIST.SURPLUS.action,
          hyperLinkContent: '',
          itemId: item?.surplus?.response?.loanNumber,
        };
        break;
      default:
        return;
    }
    return items;
  };

  const getPickupRenewActionDetails = (request: any, response: any) => {
    const actionId = AGREEMENT_OPTIONS.find(
      (obj: any) => obj.id === request?.item?.selectedActionType
    );

    switch (actionId?.id) {
      case RETAIL_ACTION_ID.PICKUP:
        return {
          service: CHECK_LIST.PICKUP.service,
          number: request?.item?.loanNumber,
          action: CHECK_LIST.PICKUP.action,
          hyperLinkContent: '',
          itemId: request?.item?.loanNumber,
        };
      case RETAIL_ACTION_ID.RENEW:
        return {
          service: CHECK_LIST.RENEW.service,
          number: response?.pledgeResponses[0]?.serviceLoanNumber,
          action: CHECK_LIST.RENEW.action,
          hyperLinkContent: request?.item?.loanNumber,
          itemId: response?.pledgeResponses[0]?.serviceLoanNumber,
        };
      case RETAIL_ACTION_ID.PAYDOWN:
        return {
          service: CHECK_LIST.PAYDOWN.service,
          number: response?.pledgeResponses[0]?.serviceLoanNumber,
          action: CHECK_LIST.PAYDOWN.action,
          hyperLinkContent: request?.item?.loanNumber,
          itemId: response?.pledgeResponses[0]?.serviceLoanNumber,
        };
      case RETAIL_ACTION_ID.PARTIAL_PAY:
        return {
          service: CHECK_LIST.PARTIAL_PAYMENT.service,
          number: request?.item?.loanNumber || '-',
          action: CHECK_LIST.PARTIAL_PAYMENT.action,
          hyperLinkContent: '',
          itemId: request?.item?.loanNumber || 0,
        };
      case RETAIL_ACTION_ID.WITHDRAW:
        return {
          service: CHECK_LIST.WITHDRAW.service,
          number: request?.item?.loanNumber,
          action: CHECK_LIST.WITHDRAW.action,
          hyperLinkContent: '',
          itemId: 0,
        };
      case RETAIL_ACTION_ID.AFFIDAVIT:
        return {
          service: CHECK_LIST.AFFIDAVIT.service,
          number: '-',
          action: CHECK_LIST.AFFIDAVIT.action,
          hyperLinkContent: '',
          itemId: 0,
        };
    }
  };

  const [columns] = useState([
    {
      name: 'service',
      title: 'Service',
      getCellValue: (row: any) => row.service,
    },
    {
      name: 'number',
      title: 'Number',
      getCellValue: (row: any) => row.number,
    },
    {
      name: 'action',
      title: 'Actions',
      getCellValue: (row: any) => row.action,
    },
    {
      name: '',
      title: '',
      getCellValue: (row: any) => <Checkbox />,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'service', width: '24%' },
    { columnName: 'number', width: '18%' },
    { columnName: 'action', width: '50%', wordWrapEnabled: true },
    { columnName: '', width: 'auto', align: 'middle' },
  ]);

  const formattedColumns = ['delete'];

  return (
    <>
      <div className="finalcheck-summary-table">
        <StyledRowHeader className="mx-h1">
          <HelpPopoverInfo
            linkedID="PAYMENT_FINALCHECKS"
            position="bottom"
            helpPosition="AFTER"
          >
            {FINAL_CHECK_TITLE}
          </HelpPopoverInfo>
        </StyledRowHeader>
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={finalChecks}
          cellComponent={(restProps: any) => <TableRow {...restProps} />}
          formattedColumns={formattedColumns}
        />
      </div>
    </>
  );
};

export default Summary;
