import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Spin } from 'antd';
import styled from 'styled-components';
import { flatten } from 'lodash';
import { Button } from 'components/common/Button';
import VirtualGrid from 'components/common/virtualGrid';
import ControlledAlert from 'components/common/ControlledAlert';

import { dateDisplayFormat, joinValues } from 'utils/util';
import { postNotificationsValuationsList } from 'services/valuationCenter';
import { actions as basketActions } from 'redux/reducers/basket';
import { pledgeActions } from 'redux/reducers/pawnbroking';
import { actions as customerActions } from 'redux/reducers/customer';
import { actions as valuationActions } from 'redux/reducers/valuationCenter';

import { BASKET_SERVICE_TYPE, GRID_NO_URL, GUEST_TYPES } from 'globalConstants';
import {
  VALUATION_SERVICE,
  VALUATION_STATUS,
  VALUATION_VIEW_TYPE,
} from 'components/valuationCenter/constants';
import { EDIT_PLEDGE_ITEMS } from 'action_creators/pledge';
import { VALUATION_ALERT } from 'components/pledge/constants';
import { GET_NOTIFICATION_ITEM_DETAILS } from 'action_creators/valuationCenter';

const StyledModal = styled(Modal)`
  .ant-modal-body {
    height: 60vh;

    & .table-wrapper {
      height: 52vh;

      & #dx-grid {
        & th {
          position: sticky;

          top: 0;
          z-index: 10;
        }
      }
    }
  }

  & .ant-modal-footer {
    display: none;
  }
`;

interface AddFromValuationPopupProps {
  visible: boolean;
  setAddFromValuationPopupVisible: () => void;
}

const AddFromValuationPopup = ({
  visible,
  setAddFromValuationPopupVisible,
}: AddFromValuationPopupProps) => {
  const dispatch = useDispatch();

  const {
    user: { selectedStore },
    customer: { customer },
    basket: { basket },
    valuationCenter: {
      isLoading: fetchingValuationDetails,
      isValuationItemToPledge,
      valuationItemDetails,
    },
  } = useSelector((state: any) => {
    return {
      user: state.user,
      customer: state.customer,
      valuationCenter: state.valuationCenter,
      basket: state.basket,
    };
  });

  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedValuation, setSelectedValuation] = useState<any>({});
  const [valuationList, setValuationList] = useState<Array<any>>([]);
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);

  const pledgePurchaseItems = basket?.basketServices
    ?.filter((service: any) => service?.pledge || service?.purchase)
    .map((filteredServie: any) => {
      if (filteredServie?.pledge) {
        return filteredServie?.pledge?.request?.items;
      } else {
        return filteredServie?.purchase?.request?.items;
      }
    });

  const flattenPledgePurchaseItemsArray = flatten(pledgePurchaseItems);
  const opportunityIdArray = flattenPledgePurchaseItemsArray?.map(
    (item: any) => item?.opportunityId
  );

  useEffect(() => {
    getValuations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getValuations = async () => {
    const payload = {
      storeId: selectedStore?.storeId,
      status: [VALUATION_STATUS.OFFERED],
      serviceType: [VALUATION_SERVICE.PLEDGE, VALUATION_SERVICE.PURCHASE],
      referenceNumber: '',
      fromDate: '',
      toDate: '',
    };

    try {
      setLoading(true);
      const response = await postNotificationsValuationsList(payload);
      if (response?.data) setValuationList(response?.data?.valuationItems);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
    }
  };

  const getValuationDetails = () => {
    setIsAlertVisible(false);
    requestValuationDetails(selectedValuation);
  };

  const requestValuationDetails = async (row: any) => {
    dispatch({
      type: GET_NOTIFICATION_ITEM_DETAILS,
      payload: {
        loanNumber: row.serviceReference,
        viewType: VALUATION_VIEW_TYPE.VIEW_ITEM_IN_PLEDGE,
      },
    });
  };

  const onClose = () => {
    setAddFromValuationPopupVisible();
  };

  const onProceedToPledge = (row: any) => {
    if (row.offeredAmount)
      dispatch(valuationActions.setOfferedAmount(row.offeredAmount));

    dispatch(valuationActions.setValuationItemDesiredAmount(row.desiredAmount));
    dispatch(valuationActions.setOpportunityId(row.serviceReference));
    dispatch(basketActions.addPledgeItemsFromBasket(true));
    dispatch(pledgeActions.updateServiceType(BASKET_SERVICE_TYPE.PLEDGE));
    dispatch({
      type: EDIT_PLEDGE_ITEMS,
      payload: { params: row.valuationItem },
    });
    if (!customer.customerId && !customer.firstNames) {
      dispatch(customerActions.setCustomerDetails(row.customer));
      if (!row?.customer?.customerId) {
        dispatch(customerActions.setGuestType(GUEST_TYPES.VALUATION));
      }
    }

    onClose();
  };

  useEffect(() => {
    if (isValuationItemToPledge) {
      onProceedToPledge(valuationItemDetails);
    }
  }, [isValuationItemToPledge]); // eslint-disable-line react-hooks/exhaustive-deps

  const [columns] = useState([
    {
      name: 'customerName',
      title: 'Customer',
      getCellValue: (row: any) => row?.customerName,
    },
    {
      name: 'serviceType',
      title: 'Service Type',
      getCellValue: (row: any) => row?.serviceType,
    },
    {
      name: 'reference',
      title: 'Service Reference',
      getCellValue: (row: any) => row?.serviceReference,
    },
    {
      name: 'store',
      title: 'Processed at',
      getCellValue: (row: any) => selectedStore.storeName,
    },
    {
      name: 'createdOn',
      title: 'Created',
      getCellValue: (row: any) => dateDisplayFormat(row?.createdOn),
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row: any) => row?.status,
    },
    {
      name: 'actions',
      title: 'Actions',
      getCellValue: (row: any) => (
        <Button
          itemProp="secondary"
          disabled={opportunityIdArray.includes(Number(row?.serviceReference))}
          onClick={() => onProcessClick(row)}
        >
          Process
        </Button>
      ),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'customerName', width: '15%' },
    { columnName: 'serviceType', width: '12%' },
    { columnName: 'reference', width: '15%' },
    { columnName: 'store', width: '20%' },
    { columnName: 'createdOn', width: '12%' },
    { columnName: 'status', width: '10%' },
    { columnName: 'actions', width: '12%' },
  ]);

  const [formattedColumns] = useState(['delete']);

  const onProcessClick = (row: any) => {
    setSelectedValuation(row);

    const journeyCustomerName = joinValues(
      [customer?.firstNames, customer?.surname],
      true
    );

    if (customer?.firstNames && journeyCustomerName !== row?.customerName)
      setIsAlertVisible(true);
    else requestValuationDetails(row);
  };

  return (
    <>
      <StyledModal
        title="Valuation Request"
        open={visible}
        width={1100}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onClose}
      >
        <Spin spinning={isLoading || fetchingValuationDetails}>
          <div className="table-wrapper">
            <>
              <VirtualGrid
                urlType={GRID_NO_URL}
                columnsProps={columns}
                tableColumnExtensionsProps={tableColumnExtensions}
                formattedColumns={formattedColumns}
                rowData={valuationList}
              />
            </>
          </div>
        </Spin>

        {isAlertVisible && (
          <ControlledAlert
            width={520}
            visible={isAlertVisible}
            message={VALUATION_ALERT.MESSAGE}
            onClick={getValuationDetails}
            onCancel={() => setIsAlertVisible(false)}
            yesButtonText={VALUATION_ALERT.PRIMARY_BUTTON_LABEL}
            cancelButtonText={VALUATION_ALERT.CANCEL_BUTTON_LABEL}
          />
        )}
      </StyledModal>
    </>
  );
};

export default AddFromValuationPopup;
