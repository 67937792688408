import * as urls from '../apiUrls';
import { doRequest } from 'utils/request';

const getVoidTransactions = (params, authorisationHeaders) => {
  return doRequest(
    `${urls.GET_BASKET_TRANSACTIONS}`,
    'post',
    params,
    '',
    false,
    '',
    authorisationHeaders
  );
};

const postVoidTransactions = (params, authorisationHeaders) => {
  return doRequest(
    `${urls.POST_VOID_TRANSACTIONS}`,
    'post',
    params,
    '',
    false,
    '',
    authorisationHeaders
  );
};

//USER MANAGER SERVICES
const getStoreUsers = (storeId) => {
  return doRequest(`${urls.ADMIN_STORE_USERS}?storeId=${storeId}`, 'get');
};

const getUserDetails = (userId) => {
  return doRequest(`${urls.ADMIN_USER_DETAILS}/${userId}`, 'get');
};

const updateUserDetails = (userId, params) => {
  return doRequest(`${urls.ADMIN_USER_DETAILS}/${userId}`, 'put', params);
};

const getUserPermissions = (userId) => {
  return doRequest(`${urls.USER_PERMISSIONS}/${userId}`, 'get');
};

const getGroupPermissions = (groupId) => {
  return doRequest(`${urls.GROUP_PERMISSIONS}/${groupId}`, 'get');
};

const getDefaultPermissions = (userId) => {
  return doRequest(`${urls.DEFAULT_PERMISSIONS}/${userId}`, 'get');
};

const postSaveUserPermissions = (params) => {
  return doRequest(`${urls.SAVE_USER_PERMISSIONS}`, 'post', params);
};

//TRAVEL MONEY RATES
const getTravelMoneyRates = (params) => {
  return doRequest(`${urls.TRAVEL_MONEY_RATE}`, 'post', params);
};

const getTravelMoneyRatesHistory = (params) => {
  return doRequest(`${urls.TRAVEL_MONEY_RATE_HISTORY}`, 'post', params);
};

const createTravelMoneyRates = (params) => {
  return doRequest(`${urls.CREATE_TRAVEL_MONEY_RATE}`, 'post', params);
};

export {
  getVoidTransactions,
  postVoidTransactions,
  getStoreUsers,
  getUserDetails,
  updateUserDetails,
  getUserPermissions,
  getGroupPermissions,
  getDefaultPermissions,
  postSaveUserPermissions,
  getTravelMoneyRates,
  getTravelMoneyRatesHistory,
  createTravelMoneyRates,
};
