import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Modal, Col, notification } from 'antd';
import { Button } from 'components/common/Button';
import {
  BUTTONS_LABEL,
  TASK_OWNER_VALUE,
  VULNERABILITYORIGINATOR,
  VULNERABILITYOWNER,
} from '../constants';
import TextArea from 'components/common/TextArea';
import { GENERIC_ERROR, PROFANITY_CHECK } from 'globalConstants';
import ControlledAlert from 'components/common/ControlledAlert';

import { actions as consumerDutyActions } from 'redux/reducers/consumerDuty';
import { actions as customerActions } from 'redux/reducers/customer';

import { postCloseCase, postCloseRisk } from 'services/consumerDuty';
import { cacheCustomerStatus, clearCacheByCacheKey } from 'utils/cacheServices';

const StyledModal = styled(Modal)`
  & .ant-modal-body {
    padding: 18px 30px 5px 24px;
  }

  & .title {
    font-size: var(--font-size-20);
    font-weight: var(--font-weight-500);
  }

  & .heading {
    font-size: var(--font-size-20);
    font-weight: var(--font-weight-500);
    margin-bottom: 5px;

    span {
      color: var(--brick-red);
    }
  }
`;

const StyledButtonCol = styled(Col)`
  & button {
    background: var(--dark-red) !important;
  }
`;

interface ProfanityError {
  profanity: boolean;
  badWords: Array<string>;
}

const CloseCaseModal = ({ visible, onClose }: any) => {
  const {
    user: { selectedStore, user },
    customer: { customer },
    consumerDuty: { selectedTaskOwner, selectedTaskId, caseSummary },
  } = useSelector((state: any) => {
    return {
      user: state.user,
      customer: state.customer,
      consumerDuty: state.consumerDuty,
    };
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [notes, setNotes] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const [profanityError, setProfanityError] = useState<ProfanityError>({
    profanity: false,
    badWords: [''],
  });
  const [isProfanity, setProfanity] = useState<boolean>(false);

  const onNotesChange = (event: any) => {
    const { value } = event.target;
    setNotes(value);
  };

  const handleProfanity = (profanity: boolean, badWords: Array<string>) => {
    setProfanityError({ profanity, badWords });
  };

  const onMarkAsCloseCaseButtonClick = async () => {
    // Params To close SST Case
    const params = {
      customerId: customer?.customerId,
      customerSupportId: caseSummary.customerSupportId,
      storeId: selectedStore?.storeId,
      userId: user?.userId,
      resolution: notes,
      vulnerabilityOwner: VULNERABILITYOWNER.SSTEAM,
      vulnerabilityOriginator:
        selectedTaskOwner === TASK_OWNER_VALUE.AT_RISK
          ? VULNERABILITYORIGINATOR.CSTeam
          : VULNERABILITYORIGINATOR.SSTEAM,
    };

    // Payload To close Risk
    const payload = {
      customerId: customer?.customerId,
      taskId: selectedTaskId,
      notes: notes,
    };
    try {
      if (profanityError.profanity) {
        setProfanity(true);
      } else {
        setLoading(true);
        const response =
          selectedTaskOwner === TASK_OWNER_VALUE.AT_RISK
            ? await postCloseRisk(payload)
            : await postCloseCase(params);
        if (response?.data?.success) {
          notification.success({
            message: response?.data?.message,
            duration: 5,
          });
          setLoading(false);
          dispatch(consumerDutyActions.resetConsumerDuty());
          dispatch(customerActions.clearAll({}));

          clearCacheByCacheKey(cacheCustomerStatus, customer?.customerId);
          onClose();

          navigate('/');
        }
      }
    } catch {
      setLoading(false);
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  return (
    <StyledModal
      open={visible}
      width={790}
      mask={false}
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
      style={{ top: '35vh', right: '1vw' }}
      footer={
        <Row justify="space-between">
          <Button itemProp="secondary" onClick={onClose}>
            {BUTTONS_LABEL.CANCEL}
          </Button>
          <StyledButtonCol>
            <Button
              disabled={!notes}
              onClick={onMarkAsCloseCaseButtonClick}
              loading={isLoading}
            >
              {selectedTaskOwner === TASK_OWNER_VALUE.AT_RISK
                ? BUTTONS_LABEL.CLOSE_RISK
                : BUTTONS_LABEL.MARK_AS_CLSOE_CASE}
            </Button>
          </StyledButtonCol>
        </Row>
      }
    >
      <Row className="heading">
        {selectedTaskOwner === TASK_OWNER_VALUE.AT_RISK
          ? 'Risk Resolution'
          : 'Case Resolution'}
      </Row>
      <Row>
        <Col span={24}>
          <TextArea
            value={notes}
            rows={8}
            maxLength={500}
            onChange={onNotesChange}
            profanityExists={handleProfanity}
            autoComplete="on"
            spellCheck
            profanityCheck
          />
        </Col>
      </Row>
      {isProfanity && (
        <ControlledAlert
          id="caseUpdate"
          width={600}
          visible={isProfanity}
          message={PROFANITY_CHECK.REMOVE_PROFANITY}
          secondaryMessage={
            <>
              {PROFANITY_CHECK.PROFANITY_WORDS}
              <b>&nbsp;{profanityError.badWords.join(', ')}</b>
            </>
          }
          onClick={() => {
            setProfanity(false);
          }}
          yesButtonText={BUTTONS_LABEL.REMOVE_PROFANITY}
          isCancelBtn={false}
        />
      )}
    </StyledModal>
  );
};

export default CloseCaseModal;
