import React, { useCallback, useEffect, useState } from 'react';
import {
  Modal,
  Row,
  Col,
  Input,
  Form,
  AutoComplete,
  Spin,
  notification,
} from 'antd';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import { REGEX_MOBILE, REGEX_POSTCODE } from 'globalConstants';
import {
  CLOSE,
  ADD,
  NEW_ISSUER_LABEL,
  ADDRESS_MANUALLY,
  COMPANY_TYPE_PLACEHOLDER,
  FIND_ISSUER_PLACEHOLDER,
  CREATE_ISSUER_ERROR_MESSAGES,
  VIEW_ISSUER,
} from '../constants';
import {
  getCompanyType,
  getIssuerAccountDetails,
  getViewIssuerDetails,
  postNewIssuer,
  searchForIssuer,
} from 'services/chequeCashing/index';
import './index.less';
import { NewIssuer } from 'components/interface/NewIssuer';
import { useDispatch, useSelector } from 'react-redux';
import { getAddress } from 'services/customer';
import { joinValues } from 'utils/util';
import debounce from 'lodash/debounce';
import { SelectProps } from 'antd/es/select';
import { RuleObject } from 'antd/lib/form';
import { isNull, isUndefined, isEmpty } from 'lodash';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import Select from 'components/common/Select';
import { actions } from 'redux/reducers/chequeCashing';
import { cacheStaticData } from 'utils/cacheServices';

const StyledHeader = styled(Row)`
  margin-bottom: 8px;
  font-size: 2rem;
  white-space: initial;
  color: var(--text-color);
  font-weight: var(--font-weight-500);
  line-height: 23.44px;
`;

const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
  margin-bottom: 38px;
  & .ant-input-affix-wrapper {
    & .anticon-caret-down {
      font-size: 12px;
    }
    & .anticon-close-circle {
      font-size: 24px;
    }
  }

  & input {
    background-color: var(--autocomplete-bg);

    &::placeholder {
      color: var(--gray);
      font-weight: var(--font-weight-400);
      font-size: var(--font-size-16);
      font: roboto;
      line-height: 18.75px;
    }
  }

  & .ant-input-affix-wrapper-lg .ant-input-search-icon::before {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & .ant-select-item-option:first-child {
    padding: 0 !important;
    height: 35px;
    cursor: default !important;
  }

  & .ant-select-item-option {
    padding: 8px 12px !important;
    border-bottom: 1px solid var(--mystic) !important;
  }

  & .ant-select-item-option:not(.ant-select-item-option-disabled):last-child {
    background-color: var(--white) !important;
    cursor: default !important;
    & .see-all-results-container {
      text-align: center;
    }
  }

  & .ant-select-item-empty {
    padding: 0;
    margin-top: -3px;
    cursor: default;
  }
`;

interface CreateNewIssuerProps {
  isNewIssuerVisible: boolean;
  isViewIssuerClick: boolean;
  isEditable: boolean;
  onNewIssuerPopupCancel: () => void;
  basketDetails: any;
  issuerDetails: any;
  issuerName: string;
  setNewIssuerData: (params: any) => void;
}

interface AddressOptions {
  value: number;
  label: string;
}

interface Address {
  property: string;
  street: string;
  area: string;
  town: string;
  county: string;
  postcode: string;
  house: string;
  country: string;
  id: number;
}
const NewIssuerDefaultValues: NewIssuer = {
  issuerName: '',
  issuerClassId: 0,
  storeId: 0,
  house: '',
  houseNumber: '',
  street: '',
  area: '',
  town: '',
  country: '',
  postCode: '',
  treadingAs: '',
  addContactName: '',
  creditLimit: 250,
  telephone: 0,
  mobileNumber: 0,
  additionalContactNumber: 0,
  limitedCompanyNumber: 0,
  badClassId: 1,
  searchableName: '',
  confirmTelphone: 1,
  confirmMobile: 1,
  createdBy: 0,
  issuerAccount: {
    accountNumber: '',
    sortCode: '',
    preferredAccount: 1,
    badClassId: 1,
    storeId: 0,
  },
};

const CreateNewIssuer = ({
  onNewIssuerPopupCancel,
  isViewIssuerClick,
  basketDetails,
  issuerDetails,
  issuerName,
  setNewIssuerData,
}: CreateNewIssuerProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [isNewIssuerVisible, setIsNewIssuerVisible] = useState<boolean>(true);
  const [manualAddressEnable, setManualAddressEnable] = useState<boolean>(true);
  const [companyTypeList, setCompanyTypeList] = useState<Array<any>>([]);
  const [addressOptions, setAddressOptions] = useState<any>([]);
  const [searchInProgress, setSearchInProgress] = useState<boolean>(false);
  const [addressList, setAddressesList] = useState<Address[]>([]);
  const [issuerAutoSuggestResults, setIssuerAutoSuggestResults] = useState<
    SelectProps<object>[]
  >([]);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [noResult, setNoResult] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const {
    user: { user, selectedStore },
    chequeCashing: { chequeDetails },
  } = useSelector((state: any) => {
    return {
      chequeCashing: state.chequeCashing,
      user: state.user,
    };
  });

  useEffect(() => {
    if (isNewIssuerVisible) {
      setVisible(true);
    }
  }, [isNewIssuerVisible]);

  useEffect(() => {
    if (isViewIssuerClick) {
      setVisible(true);
      viewIssuerAccountDetails(issuerDetails);
      setManualAddressEnable(false);
    } else {
      loadCompanyType();
      setSelectedValue(issuerName);
      form.setFieldsValue({ issuerName: issuerName });
    }
  }, [isViewIssuerClick, issuerDetails, form, issuerName, selectedStore]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCloseClick = useCallback(() => {
    setVisible(false);
    setIsNewIssuerVisible(false);
    if (isViewIssuerClick || isNewIssuerVisible) {
      onNewIssuerPopupCancel();
    }
  }, [onNewIssuerPopupCancel, isNewIssuerVisible, isViewIssuerClick]);

  const loadCompanyType = async () => {
    try {
      const response = await cacheStaticData(getCompanyType);
      if (response)
        setCompanyTypeList(response?.data?.thirdPartyChequeCompanyTypes);
    } catch (e: any) {
      console.log(e);
    }
  };

  const getAddresses = async (searchValue: string, callback: any) => {
    if (!REGEX_POSTCODE.test(searchValue)) {
      return;
    }
    try {
      setSearchInProgress(true);
      const response = await getAddress(searchValue);
      if (response) {
        const result: AddressOptions[] = [];
        response.data.addresses.forEach((address: Address) => {
          const text = joinValues([
            address.property,
            address?.house,
            address.street,
            address.town,
            address.county,
            address.postcode,
          ]);

          result.push({
            value: address.id,
            label: text,
          });
          setManualAddressEnable(false);
        });
        callback(result);
        setAddressesList(response.data.addresses);
        setSearchInProgress(false);
      } else {
        setNoResult(true);
      }
    } catch (e: any) {
      if (e?.response?.status === 404) {
        setAddressesList([]);
        setNoResult(true);
      }
      setSearchInProgress(false);
    }
  };
  const onSearch = (value: string) => {
    setAddressOptions([]);
    if (value) {
      getAddresses(value, (data: AddressOptions) => {
        setAddressOptions(data);
      });
      setInputValue(value);
    } else {
      setAddressOptions([]);
      setAddressesList([]);
    }
  };

  const loadIssuerAccountDetails = async (value: number) => {
    try {
      const response = await getIssuerAccountDetails(value);
      if (response?.data) {
        //TODO implement is future
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const viewIssuerAccountDetails = async (issuerDetails: any) => {
    const value = issuerDetails?.issuerId;
    try {
      const response = await getViewIssuerDetails(value);
      if (response?.data) {
        const resp = response?.data[0];
        form.setFieldsValue({
          issuerName: resp?.issuerName,
          issuerClassId: resp?.limitedCompanyTypeName,
          postcode: resp?.postCode,
          property: resp?.houseNumber,
          telephone: resp?.telephone,
          storeId: resp?.storeId,
          house: resp?.house,
          houseName: resp?.house,
          street: resp?.street,
          area: resp?.area,
          town: resp?.town,
          country: resp?.country,
          treadingAs: resp?.treadingAs,
          addContactName: resp?.addContactName,
          creditLimit: resp?.creditLimit,
          mobileNumber: resp?.mobileNumber,
          additionalContactNumber: resp?.additionalContactNumber,
          limitedCompanyNumber: resp?.limitedCompanyNumber,
          badClassId: resp?.badClassId,
          searchableName: resp?.searchableName,
          confirmTelphone: resp?.confirmTelphone,
          confirmMobile: resp?.confirmMobile,
          createdBy: resp?.createdBy,
        });
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const onCompanyNumberChange = (e: any) => {
    const value = e?.target?.value;
    const length = value?.length;
    if (length >= 6 && length <= 8) {
      loadIssuerAccountDetails(value);
    }
  };

  const onAddIssuerDetailsClick = () => {
    const { issuerAccount } = NewIssuerDefaultValues;
    issuerAccount.accountNumber = basketDetails.accountNumber;
    issuerAccount.sortCode = basketDetails.sortCode;
    issuerAccount.storeId = selectedStore?.storeId;
    addIssuer({
      ...form.getFieldsValue(),
      issuerName: form.getFieldValue('issuerName'),
      storeId: selectedStore?.storeId,
      searchableName: form.getFieldValue('issuerName').toUpperCase(),
      createdBy: user?.userId,
      house:
        form.getFieldValue('house') !== undefined
          ? form.getFieldValue('house')
          : ' ' + form.getFieldValue('houseName') !== undefined // eslint-disable-next-line no-useless-concat
          ? form.getFieldValue('houseName')
          : '',
      issuerAccount: issuerAccount,
      houseNumber: form.getFieldValue('property'),
    });
  };

  const onSelect = (value: string) => {
    const { street, town, property, county, postcode, house, country, area } =
      addressList.filter((item: any) => item.id === value)[0];

    form.setFieldsValue({
      street,
      town,
      property,
      county,
      houseName: house,
      area,
      country,
      postcode: postcode?.trim(),
    });
  };

  const renderItem = (title: any) => ({
    value: title,
  });

  const options = issuerAutoSuggestResults?.map((el: any, key: any) => {
    return {
      options: [renderItem(el.issuerName)],
    };
  });

  const addIssuer = async (params: any) => {
    try {
      setLoading(true);
      const response = await postNewIssuer(params);

      if (response) {
        const issuerDetailResponse = await getIssuerAccountDetails(
          basketDetails.accountNumber,
          basketDetails.sortCode
        );
        const issuerDetails = {
          ...chequeDetails,
          issuerDetails: issuerDetailResponse?.data,
        };
        dispatch(actions.addChequeDetails(issuerDetails));
        setNewIssuerData(issuerDetailResponse?.data);
        setLoading(false);
      }
      onCloseClick();
    } catch (e: any) {
      setLoading(false);
      console.log(e);
      notification.error({
        message: e?.response?.data?.error || e?.message,
        duration: 2,
      });
    }
  };

  const onNewIssuerSelect = (e: any) => {
    const value = e?.target?.value;
    setSelectedValue(value);
    form.setFieldsValue({
      issuerName: value,
    });
  };

  const postCodeValidation = (
    rule: RuleObject, //TODO check
    value: any,
    callback: (error?: string) => void
  ) => {
    if (isNull(value) || isUndefined(value) || isEmpty(value)) {
      callback(CREATE_ISSUER_ERROR_MESSAGES.POSTCODE.REQUIRED);
    } else {
      if (REGEX_POSTCODE.test(value)) {
        return callback();
      } else {
        callback(CREATE_ISSUER_ERROR_MESSAGES.POSTCODE.INVALID);
      }
    }
  };

  const noResultRow = () => {
    return (
      <>
        <div className="no-result-container">
          <div className="no-results">{`${CREATE_ISSUER_ERROR_MESSAGES.POSTCODE.NA}`}</div>
        </div>
      </>
    );
  };

  const onSearchChange = async (value: string) => {
    if (value === '') return;
    try {
      setInputValue(value);
      const response = await searchForIssuer(value);
      if (response?.data) {
        setIssuerAutoSuggestResults(response?.data);
      } else {
        setIssuerAutoSuggestResults([]);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <Modal
      open={visible}
      closable={true}
      key={1}
      centered
      width={892}
      maskClosable={false}
      onCancel={onCloseClick}
      footer={[
        <Button key={0} itemProp="primary" onClick={onCloseClick} disabled={loading}>
          {CLOSE}
        </Button>,
        !isViewIssuerClick && (
          <Button
            key={1}
            htmlType="submit"
            style={{ marginLeft: '28px' }}
            onClick={onAddIssuerDetailsClick}
            disabled={loading}
          >
            {ADD}
          </Button>
        ),
      ]}
    >
      <Spin spinning={loading}>
        <Row className="issuer-popup-body">
          <Col span={24}>
            <Form
              layout="vertical"
              form={form}
              onFinish={() => console.log('done')}
              onFinishFailed={(err: any) => console.log(err)}
            >
              <Row>
                <Col>
                  <StyledHeader>
                    <HelpPopoverInfo
                      linkedID={`CHEQUE_NEWISSUER`}
                      position="rightTop"
                    >
                      {isViewIssuerClick ? VIEW_ISSUER : NEW_ISSUER_LABEL}
                    </HelpPopoverInfo>
                  </StyledHeader>
                </Col>
              </Row>
              {isViewIssuerClick ? (
                <Row> </Row>
              ) : (
                <Row>
                  <Col span={22}>
                    <StyledAutoComplete
                      popupClassName="search-dropdown"
                      options={options}
                    >
                      <Input.Search
                        allowClear
                        size="middle"
                        width={50}
                        placeholder={FIND_ISSUER_PLACEHOLDER}
                        onChange={(e) => {
                          onSearchChange(e?.target?.value);
                        }}
                        onSelect={onNewIssuerSelect}
                      />
                    </StyledAutoComplete>
                  </Col>
                </Row>
              )}
              <Row>
                <Col span={8}>
                  <Form.Item
                    label="Issuer Name"
                    name="issuerName"
                    className="label-control"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        message:
                          CREATE_ISSUER_ERROR_MESSAGES.ISSUER_NAME.REQUIRED,
                      },
                    ]}
                  >
                    {isViewIssuerClick ? (
                      <AutoComplete value={issuerName}> </AutoComplete>
                    ) : (
                      <Input
                        className="input-control"
                        value={selectedValue}
                        type="text"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Company Type"
                    name="issuerClassId"
                    className="label-control"
                  >
                    <Select
                      style={{ width: '100%' }}
                      placeholder={COMPANY_TYPE_PLACEHOLDER}
                      options={companyTypeList.map((item: any) => {
                        return {
                          value: item.classId,
                          label: item.description,
                        };
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="LTD Company Number"
                    name="limitedCompanyNumber"
                    className="label-control"
                    rules={[
                      {
                        pattern: new RegExp('^0*[0-9][0-9]*$'),
                        message:
                          CREATE_ISSUER_ERROR_MESSAGES.COMPANY_TYPE.REQUIRED,
                      },
                    ]}
                  >
                    <Input
                      className="input-control"
                      onKeyUp={onCompanyNumberChange}
                      type="text"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={16}>
                  <Form.Item
                    label="Postcode"
                    name="postcode"
                    className="label-control"
                    rules={[
                      { required: true },
                      { validator: postCodeValidation },
                    ]}
                  >
                    <AutoComplete
                      showSearch={false}
                      className="input-control"
                      value={inputValue}
                      notFoundContent={
                        searchInProgress ? (
                          <Spin />
                        ) : inputValue !== '' && noResult ? (
                          noResultRow()
                        ) : null
                      }
                      onBlur={() => setInputValue('')}
                      onSearch={debounce(onSearch, 400)}
                      onSelect={onSelect}
                      options={addressOptions}
                    ></AutoComplete>
                  </Form.Item>
                </Col>
              </Row>
              <Row hidden={!manualAddressEnable}>
                <Col span={6}>
                  <span onClick={() => setManualAddressEnable(false)}>
                    {ADDRESS_MANUALLY}
                  </span>
                </Col>
              </Row>
              <Row
                className="manual-address-wrapper"
                hidden={manualAddressEnable}
              >
                <Col span={8}>
                  <Form.Item
                    label="Flat/House Number"
                    name="property"
                    className="label-control"
                  >
                    <Input
                      className="input-control"
                      type="text"
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="House Name"
                    name="houseName"
                    className="label-control"
                  >
                    <Input className="input-control" type="text" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Street/Road"
                    name="street"
                    className="label-control"
                  >
                    <Input className="input-control" type="text" />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                className="manual-address-wrapper"
                hidden={manualAddressEnable}
              >
                <Col span={8}>
                  <Form.Item label="Town" name="town" className="label-control">
                    <Input className="input-control" type="text" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Area" name="area" className="label-control">
                    <Input className="input-control" type="text" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Country"
                    name="country"
                    className="label-control"
                  >
                    <Input className="input-control" type="text" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Form.Item
                    label="Contact Telephone"
                    name="telephone"
                    className="label-control"
                    rules={[
                      { required: true },
                      {
                        pattern: new RegExp(REGEX_MOBILE),
                        message:
                          CREATE_ISSUER_ERROR_MESSAGES.TELEPHONE.REQUIRED,
                      },
                    ]}
                  >
                    <Input
                      className="input-control"
                      type={isViewIssuerClick ? ' ' : 'text'}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Additional telephone"
                    name="additionalContactNumber"
                    className="label-control"
                    rules={[
                      {
                        pattern: new RegExp(REGEX_MOBILE),
                        message:
                          CREATE_ISSUER_ERROR_MESSAGES.ADDITIONAL_TELEPHONE
                            .INVALID,
                      },
                    ]}
                  >
                    <Input className="input-control" type="text" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Contact Name"
                    name="addContactName"
                    className="label-control"
                    rules={[
                      {
                        type: 'string',
                        pattern: new RegExp('^[a-zA-Z]+[a-zA-Z- ]*$'),
                        message:
                          CREATE_ISSUER_ERROR_MESSAGES.CONTACT_NAME.INVALID,
                      },
                    ]}
                  >
                    <Input className="input-control" type="text" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Form.Item
                    label="Contact Mobile"
                    name="mobileNumber"
                    className="label-control"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        pattern: new RegExp(REGEX_MOBILE),
                        message: CREATE_ISSUER_ERROR_MESSAGES.MOBILE.REQUIRED,
                      },
                    ]}
                  >
                    <Input className="input-control" type="text" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};
export default CreateNewIssuer;
