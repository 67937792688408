import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Spin } from 'antd';
import { Button } from 'components/common/Button';
import styled from 'styled-components';
import {
  //BASE_PLEDGE_CATEGORIES,
  ADD_VALUATION,
  // ITEM_ATTRIBUTE_TYPE,
  // ITEM_ATTRIBUTE_REQUIRED,
  // PARENT_CATEGORIES,
} from '../../constants';
import { UPLOAD_MULTIPLE_IMAGE_REQUEST } from 'action_creators/pledge';
import { pledgeActions } from 'redux/reducers/pawnbroking';
import { useSelector, useDispatch } from 'react-redux';
//import { reduceWatchHandbagItems, getWatchHandbagFormItem } from 'utils/util';
//import { cacheStaticDataWithArgs } from 'utils/cacheServices';

import MultipleImageUploader from 'components/common/MultipleImageUploader';
//import { getItemCategoryAttributes } from 'services/pledge';
import TextArea from 'components/common/TextArea';
import { isEmpty, isUndefined } from 'lodash';

const StyledModal = styled(Modal)`
  & .ant-checkbox-wrapper.ant-checkbox-group-item {
    margin: 10px 0px 10px 0px;
    & .ant-checkbox-inner {
      width: 18px;
      height: 18px;
    }
  }
  & .ant-row.ant-form-item.label-control {
    margin-block-end: 18px;
  }
  & .ant-row.ant-form-item.label-control.hands-radio-btn {
    margin-top: 10px;
  }
  & .ant-radio-wrapper .ant-radio-inner {
    width: 18px;
    height: 18px;
    &::after {
      top: 4px;
      left: 4px;
    }
  }
`;

const StyledHeadingImages = styled.div`
  margin: 5px 0;
  font-weight: var(--font-weight-500);
`;

interface ItemAdditionalDetailsPopupProps {
  category: string | boolean;
  visible: boolean;
  setItemAddtionalDetailsPopupVisible: () => void;
}

const ItemAdditionalDetailsPopup = ({
  category,
  visible,
  setItemAddtionalDetailsPopupVisible,
}: ItemAdditionalDetailsPopupProps) => {
  const {
    pawnbroking: {
      itemAdditionalPropertiesJewelleryCoins,
      itemImageCollectionResponse,
    },
    valuationCenter: { isValuationItemToPledge },
  } = useSelector((state: any) => {
    return {
      pawnbroking: state.pawnbroking,
      valuationCenter: state.valuationCenter,
    };
  });

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  //const [itemAdditionAttributes, setItemAdditionAttributes] = useState<any>([]);   //commenting code as per EVB-1924
  const [loading, setLoading] = useState<boolean>(false);

  //commenting code as per EVB-1924
  // useEffect(() => {
  //   getItemAdditionalAttributes();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isUndefined(itemAdditionalPropertiesJewelleryCoins)) {
      form.setFieldsValue({ ...itemAdditionalPropertiesJewelleryCoins });
    }
  }, [itemAdditionalPropertiesJewelleryCoins]); // eslint-disable-line react-hooks/exhaustive-deps

  //commenting code as per EVB-1924
  // const getItemAdditionalAttributes = async () => {
  //   const categoryIndex = category === PARENT_CATEGORIES.JEWELLERY ? 0 : 2;

  //   try {
  //     setLoading(true);
  //     const response = await cacheStaticDataWithArgs(
  //       `ITEM-ATTRIBUTE-${BASE_PLEDGE_CATEGORIES[categoryIndex].id}`,
  //       getItemCategoryAttributes,
  //       BASE_PLEDGE_CATEGORIES[categoryIndex].id
  //     );
  //     if (response?.data) {
  //       setLoading(false);
  //       setItemAdditionAttributes(response?.data?.itemCategoryAttributes);
  //     }
  //   } catch (e: any) {
  //     setLoading(false);
  //   }
  // };

  const uploadImageHandler = (image: any) => {
    dispatch({
      type: UPLOAD_MULTIPLE_IMAGE_REQUEST,
      payload: { image, isDefault: false },
    });
  };

  const updateImageList = (imageList: any) => {
    const params = {
      ...itemImageCollectionResponse,
      images: imageList,
    };
    dispatch(pledgeActions.setItemImageCollectionResponse(params));
  };

  const onJewelleryPopupSubmit = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();

      if (Object.keys(values).every((key) => values[key] === undefined)) {
        setItemAddtionalDetailsPopupVisible();
        return;
      } else {
        dispatch(
          pledgeActions.updateItemAdditionalPropertiesJewelleryCoins(values)
        );
        setItemAddtionalDetailsPopupVisible();
      }
    } catch (errorInfo) {}
  };

  const onCancelClick = () => {
    setItemAddtionalDetailsPopupVisible();
  };

  //commenting code as per EVB-1924
  // const getFields = () => {
  //   const count = itemAdditionAttributes;
  //   const children = [];

  //   const { TEXTAREA } = ITEM_ATTRIBUTE_TYPE;
  //   const { YES } = ITEM_ATTRIBUTE_REQUIRED;

  //   for (let i = 0; i < count.length; i++) {
  //     const { name, attributeId, kind, required } = count[i];
  //     const textAreaRows = attributeId === 155 || attributeId === 157 ? 2 : 5;
  //     const maxLength = attributeId === 155 || attributeId === 157 ? 100 : 255;

  //     if (kind === TEXTAREA) {
  //       children.push(
  //         <Col span={24} key={i}>
  //           <Form.Item
  //             name={attributeId}
  //             label={`${name}${required === YES ? '*' : ''}`}
  //             className="label-control"
  //             rules={[
  //               {
  //                 required: required === YES ? true : false,
  //                 message: `${name} is required`,
  //               },
  //             ]}
  //           >
  //             <TextArea showCount rows={textAreaRows} maxLength={maxLength} />
  //           </Form.Item>
  //         </Col>
  //       );
  //     }
  //   }
  //   return children;
  // };

  return (
    <>
      <StyledModal
        title="Additional Details"
        centered
        open={visible}
        width={1100}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={onCancelClick}
        footer={
          <Row>
            <Col span="6" className="mandatory-label">
              (*) Mandatory
            </Col>
            <Col span="18">
              <Button itemProp="secondary" key="back" onClick={onCancelClick}>
                Cancel
              </Button>
              <Button
                key="submit"
                form="form"
                htmlType="submit"
                onClick={onJewelleryPopupSubmit}
              >
                {!isEmpty(itemAdditionalPropertiesJewelleryCoins)
                  ? ADD_VALUATION.UPDATE
                  : ADD_VALUATION.SUBMIT}
              </Button>
            </Col>
          </Row>
        }
      >
        {loading ? (
          <Spin />
        ) : (
          <Form
            layout="vertical"
            form={form}
            disabled={isValuationItemToPledge}
          >
            <Row>
              <Col span={9} style={{ height: '60vh' }}>
                <Row gutter={24} align="top">
                  {/* {getFields()}   //commenting code as per EVB-1924 */}
                  <Col span={24} key="item-description-field">
                    <Form.Item
                      name="itemDescription"
                      label="Item Description"
                      className="label-control"
                      // rules={[
                      //   {
                      //     required: required === YES ? true : false,
                      //     message: `${name} is required`,
                      //   },
                      // ]}
                    >
                      <TextArea showCount rows={2} maxLength={100} />
                    </Form.Item>
                  </Col>
                  <Col span={24} key="notes-description-field">
                    <Form.Item
                      name="additionalNotes"
                      label="Additional Notes"
                      className="label-control"
                      // rules={[
                      //   {
                      //     required: required === YES ? true : false,
                      //     message: `${name} is required`,
                      //   },
                      // ]}
                    >
                      <TextArea showCount rows={6} maxLength={300} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={14} offset={1}>
                <Row>
                  <Col span={24}>
                    <StyledHeadingImages>Images</StyledHeadingImages>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <MultipleImageUploader
                      imageList={itemImageCollectionResponse?.images}
                      uploadImageRequest={uploadImageHandler}
                      updateImageList={updateImageList}
                      viewOnly={isValuationItemToPledge}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </StyledModal>
    </>
  );
};

export default ItemAdditionalDetailsPopup;
