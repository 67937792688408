import React, { useEffect, useState } from 'react';
import { Button } from 'components/common/Button';
import { GRID_NO_URL } from 'globalConstants';
import VirtualGrid from 'components/common/virtualGrid';
import { getPartPaymentData } from 'services/chequeCashing';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/reducers/chequeCashing';
import { dateDisplayFormat } from 'utils/util';
import { Spin } from 'antd';

const PartPaymentSummaryTable = ({ customerToImport }: any) => {
  const { customer } = useSelector((state: any) => state.customer);
  const [rows, setRows] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (rows.length === 0) getPartPaymentRowsData(customer?.customerId);
  }, [rows?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const getPartPaymentRowsData = async (customerId: any) => {
    try {
      setLoading(true);
      const response = await getPartPaymentData(customerId);
      if (response?.data) {
        const data = response?.data;
        dispatch(actions.addPartPaymentDetails(data));
        setRows(data);
        setLoading(false);
      }
    } catch (e: any) {
      console.log(e);
      setLoading(false);
    }
  };
  const [columns] = useState([
    {
      name: 'customer',
      title: 'Customer',
      getCellValue: (row: any) => row?.customerName,
    },
    {
      name: 'transactionDate',
      title: 'Transaction Date',
      getCellValue: (row: any) =>
        row && row?.transactionDate
          ? dateDisplayFormat(row?.transactionDate)
          : null,
    },
    {
      name: 'issuer',
      title: 'Issuer',
      getCellValue: (row: any) => row?.issuerName,
    },
    {
      name: 'clearanceDate',
      title: 'Clearance Date',
      getCellValue: (row: any) => row?.clearanceDate,
    },
    {
      name: 'amount',
      title: 'Amount',
      getCellValue: (row: any) => row?.chequeAmount,
      type: 'currency',
    },
    {
      name: 'partPaid',
      title: 'Part Paid',
      getCellValue: (row: any) => row?.approvedPay,
      type: 'currency',
    },
    {
      name: 'retainedAmount',
      title: 'Retained Amount',
      getCellValue: (row: any) => row?.retained,
      type: 'currency',
    },
    {
      name: 'actions',
      title: 'Actions',
      getCellValue: (row: any) => (
        <Button
          itemProp="secondary"
          onClick={() => {
            customerToImport(row?.customerId, row?.customerName, {
              params: row,
              partPayment: true,
            });
          }}
        >
          {'Import'}
        </Button>
      ),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'customer', width: '15%', align: 'left' },
    { columnName: 'transactionDate', width: '13%', align: 'left' },
    { columnName: 'issuer', width: '15%', align: 'left' },
    { columnName: 'clearanceDate', width: '13%', align: 'left' },
    { columnName: 'amount', width: '10%', align: 'right' },
    { columnName: 'partPaid', width: '10%', align: 'right' },
    { columnName: 'retainedAmount', width: '14%', align: 'right' },
    { columnName: 'actions', width: '10%', align: 'center' },
  ]);

  const formattedColumns = ['amount', 'partPaid', 'retainerAmount'];
  return (
    <Spin spinning={loading}>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        formattedColumns={formattedColumns}
        rowData={rows}
      />
    </Spin>
  );
};

export default PartPaymentSummaryTable;
