import { call, put, takeEvery } from 'redux-saga/effects';
import {
  GET_CURRENCIES_REQUEST,
  GET_STORE_LIST_REQUEST,
  GET_STORE_TILL_HISTORY,
} from 'action_creators/cashManagement';
import { actions } from 'redux/reducers/cashManagement';
import {
  getCurrencies,
  getDestinationStoreList,
  getStoreTillHistory,
} from 'services/cashManagement';
import { cacheStaticData } from 'utils/cacheServices';

/**
 * this saga handles the GET currencies
 * (1) get currencies
 * (2) handle response
 */
export function* getCurrencyListSaga() {
  try {
    //request get currencies from API
    const payload = yield call(cacheStaticData, getCurrencies);
    if (payload?.data) {
      yield put(actions.setCurrencyList(payload.data));
    } else if (payload?.errors) {
      yield put(actions.setError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.setError(e));
  }
}

export function* watchGetCurrencyListSaga() {
  yield takeEvery(GET_CURRENCIES_REQUEST, getCurrencyListSaga);
}

/**
 * this saga handles the GET currencies
 * (1) get currencies
 * (2) handle response
 */
export function* getDestinationStoreListSaga() {
  try {
    //request get currencies from API
    const payload = yield call(cacheStaticData, getDestinationStoreList);
    if (payload?.data) {
      yield put(actions.setDestinationStoreList(payload.data));
    } else if (payload?.errors) {
      yield put(actions.setError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.setError(e));
  }
}

export function* watchGetDestinationStoreListSaga() {
  yield takeEvery(GET_STORE_LIST_REQUEST, getDestinationStoreListSaga);
}

export function* getStoreTillHistorySaga(action) {
  const {
    payload: { params },
  } = action;
  try {
    yield put(actions.setLoading(true));
    const payload = yield call(getStoreTillHistory, params);
    if (payload?.data) {
      yield put(actions.setStoreTillHistory(payload.data));
      yield put(actions.setLoading(false));
    } else if (payload?.errors) {
      yield put(actions.setLoading(false));
      yield put(actions.setTillHistoryError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.setLoading(false));
    yield put(actions.setTillHistoryError(e));
  }
}

export function* watchGetStoreTillHistorySaga() {
  yield takeEvery(GET_STORE_TILL_HISTORY, getStoreTillHistorySaga);
}
