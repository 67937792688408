import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/reducers/checkout';
import { actions as adminActions } from 'redux/reducers/admin';
import { getMatrixUserId } from 'utils/cookie';
import {
  JsonHubProtocol,
  HubConnectionState,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { getMatrixToken } from 'utils/cookie';

const isDev = process.env.NODE_ENV === 'development';
const options = {
  logMessageContent: isDev,
  logger: isDev ? LogLevel.Warning : LogLevel.Error,
  accessTokenFactory: () => getMatrixToken(),
};

const connectionHubURL = `${process.env.REACT_APP_API_DOMAIN}:443/signalr/basket`;
const useSignalRConnection = () => {
  const dispatch = useDispatch();
  const {
    admin: { isSignalRResponse },
    checkout: { isTimeoutSignalR, isRestartConnection },
  } = useSelector((state) => {
    return {
      admin: state.admin,
      checkout: state.checkout,
    };
  });

  let connection = new HubConnectionBuilder()
    .withUrl(connectionHubURL, options)
    .withAutomaticReconnect()
    .withHubProtocol(new JsonHubProtocol())
    .configureLogging(LogLevel.Information)
    .build();

  connection.serverTimeoutInMilliseconds = 60000;

  connection.onclose((error) => {
    console.assert(connection.state === HubConnectionState.Disconnected);
    console.log(
      'Connection closed due to error. Try refreshing this page to restart the connection',
      error
    );
  });

  connection.onreconnecting((error) => {
    console.assert(connection.state === HubConnectionState.Reconnecting);
    console.log('Connection lost due to error. Reconnecting.', error);
  });

  connection.onreconnected((connectionId) => {
    console.assert(connection.state === HubConnectionState.Connected);
    console.log(
      'Connection reestablished. Connected with connectionId',
      connectionId
    );
  });

  useEffect(() => {
    const startSignalRConnection = async (connection) => {
      try {
        await connection.start();
        console.assert(connection.state === HubConnectionState.Connected);
        console.log('SignalR connection established');

        const userId = getMatrixUserId();
        connection.invoke('addUserToGroup', userId);

        // if (connection.state === HubConnectionState.Connected) {
        //   invokeSendNotification();
        // }
      } catch (err) {
        console.assert(connection.state === HubConnectionState.Disconnected);
        console.error('SignalR Connection Error: ', err);
        //setTimeout(() => startSignalRConnection(connection), 5000);
      }
    };
    startSignalRConnection(connection);
    return () => connection.stop();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isTimeoutSignalR || isSignalRResponse) {
      connection.stop();
      console.assert(connection.state === HubConnectionState.Disconnected);
      console.log('Connection closed');
    }
  }, [isTimeoutSignalR, isSignalRResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isRestartConnection) {
      connection.start();
    }
  }, [isRestartConnection]); // eslint-disable-line react-hooks/exhaustive-deps

  // const invokeSendNotification = async () => {
  //   await fetch(
  //     `${process.env.REACT_APP_API_DOMAIN}:443/api/pledges/send-notification`
  //   );
  //   console.log('calling notification service');
  // };

  // connection.on('displayNotification', (res) => {
  //   dispatch(pledgeActions.updatePledge(res));
  // });
  connection.on('BasketResponseEvent', (res) => {
    console.log('res', res);
    dispatch(actions.signalrResponse(res));
  });
  connection.on('BasketVoidResponseEvent', (res) => {
    console.log('voidRes', res);
    dispatch(adminActions.signalrResponse(res));
  });

  return connection;
};

export default useSignalRConnection;
