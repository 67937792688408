import React, { useCallback, useEffect, useState } from 'react';
import { Row, Drawer, Col, Checkbox, notification, Spin } from 'antd';
import NoteSummary from './noteSummary';
import './index.less';
import { Button } from 'components/common/Button';
import {
  ADD_NOTE,
  ADD_TO_STOP_LIST,
  NOTES_FILTERS,
  NOTES_FILTERS_VALUE,
  REMOVE_FROM_STOP_LIST,
} from '../constants';
import NewNotePopup from './addNote';
import StopListNotePopup from './addStopListNotes';
import { useSelector } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { NOTES_TITLE } from '../constants';
import { postFetchAllCustomerNotes } from 'services/consumerDuty';
import { GENERIC_ERROR } from 'globalConstants';

interface CustomerNotesDrawerProps {
  customerNoteVisible: boolean;
  onCustomerNotesCancelClick: () => void;
}
const CustomerNotes = ({
  customerNoteVisible,
  onCustomerNotesCancelClick,
}: CustomerNotesDrawerProps) => {
  const { customer, isCustomerInStopList } = useSelector(
    (state: any) => state.customer
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [notesList, setNotesList] = useState<any>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [stopListNotesVisible, setStopListNotesVisible] =
    useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<Array<any>>([
    NOTES_FILTERS_VALUE.ALL,
  ]);

  useEffect(() => {
    fetchAllCustomerNotes();
  }, [selectedOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAllCustomerNotes = useCallback(async () => {
    setNotesList([]);

    try {
      setLoading(true);

      const response = await postFetchAllCustomerNotes(
        customer?.customerId,
        selectedOptions
      );
      if (response?.data) {
        const list = response?.data?.customerNotes?.map(
          (el: any, key: number) => {
            el.id = key + 1;
            return el;
          }
        );
        const sortedlist = list.sort((a: any, b: any) =>
          a.updatedOn < b.updatedOn ? (1 ? a.updatedOn === b.updatedOn : 0) : -1
        );

        setLoading(false);
        setNotesList(sortedlist);
      }
    } catch (error: any) {
      setLoading(false);
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  }, [selectedOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCancelClick = () => {
    setVisible(false);
  };

  const onStopListPopUpCancelClick = () => {
    setStopListNotesVisible(false);
  };

  const getNoteAdded = (added: boolean) => {
    if (added) fetchAllCustomerNotes();
  };

  const onCheckboxChange = (event: any) => {
    const { ALL } = NOTES_FILTERS_VALUE;

    const length = event.length;
    const options = [];

    if (length === 0 || event[length - 1] === ALL) options.push(ALL);
    else if (event.includes(ALL) && length > 1) {
      event.shift();
      options.push(...event);
    } else options.push(...event);

    setSelectedOptions(options);
  };

  return (
    <Drawer
      closable={false}
      open={customerNoteVisible}
      key="right"
      className="customer-notes-wrapper"
      footer={
        <Row>
          <Col span={24} className="footer-btn-row">
            <Button
              key="add-note-stop"
              onClick={() => {
                setStopListNotesVisible(true);
              }}
              className="add-stop-list-note-btn"
            >
              {isCustomerInStopList ? REMOVE_FROM_STOP_LIST : ADD_TO_STOP_LIST}
            </Button>
            <Button
              key="add-note"
              onClick={() => {
                setVisible(true);
              }}
              className="add-note-btn"
            >
              {ADD_NOTE}
            </Button>
          </Col>
          {visible ? (
            <NewNotePopup
              isNewNotePopupVisible
              onNewNotePopupCancel={onCancelClick}
              onNoteAddClicked={getNoteAdded}
            />
          ) : null}
          {stopListNotesVisible ? (
            <StopListNotePopup
              isStopListNotePopupVisible
              onStopListNotePopupCancel={onStopListPopUpCancelClick}
              onNoteAddClicked={getNoteAdded}
            />
          ) : null}
        </Row>
      }
    >
      <Row className="header-row">
        <Col span={23}>
          <span>{NOTES_TITLE}</span>
        </Col>
        <Col span={1}>
          <CloseOutlined onClick={onCustomerNotesCancelClick} />
        </Col>
      </Row>
      <Row className="filter-row">
        <Col span={2}>Filters</Col>
        <Col span={14}>
          <Checkbox.Group onChange={onCheckboxChange} value={selectedOptions}>
            <Row>
              <Col span={3}>
                <Checkbox value={NOTES_FILTERS_VALUE.ALL}>
                  {NOTES_FILTERS.ALL}
                </Checkbox>
              </Col>
              <Col span={5} className="bg-blue">
                <Checkbox value={NOTES_FILTERS_VALUE.STORE}>
                  {NOTES_FILTERS.STORE}
                </Checkbox>
              </Col>
              <Col span={5} className="bg-orange">
                <Checkbox value={NOTES_FILTERS_VALUE.AT_RISK}>
                  {NOTES_FILTERS.AT_RISK}
                </Checkbox>
              </Col>
              <Col span={5} className="bg-red">
                <Checkbox value={NOTES_FILTERS_VALUE.SSTEAM}>
                  {NOTES_FILTERS.SSTEAM}
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Col>
      </Row>
      {loading ? (
        <Spin className="notes-spinner" />
      ) : (
        <NoteSummary rows={notesList} />
      )}
    </Drawer>
  );
};

export default CustomerNotes;
