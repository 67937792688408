import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'redux/reducers/inventoryManagement';
import { format } from 'date-fns';
import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';
import VirtualGrid from 'components/common/virtualGrid/index';
import ImageLoader from 'components/common/ImageLoader';

interface AcceptTransferSummaryProps {
  stockTransferList: Array<any>;
}

const AcceptTransferSummary = ({
  stockTransferList = [],
}: AcceptTransferSummaryProps) => {
  const { selectedItemIds } = useSelector(
    (state: any) => state.inventoryManagement
  );

  const dispatch = useDispatch();

  const [columns] = useState([
    {
      name: 'count',
      title: 'Count',
      getCellValue: (row: any) => row.index,
    },
    {
      name: 'dateAdded',
      title: 'Date Added',
      getCellValue: (row: any) =>
        row && row?.dateAdded
          ? format(new Date(row?.dateAdded), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'image',
      title: 'Image',
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row.stockItemId}`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'barcode',
      title: 'Barcode',
      getCellValue: (row: any) => row.barcode,
    },
    {
      name: 'description',
      title: 'Description',
      getCellValue: (row: any) => row.description,
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) => `${(+row.weight).toFixed(2)}g`,
    },
    {
      name: 'retailPrice',
      title: 'Retail Price',
      type: 'currency',
      getCellValue: (row: any) => row.listValue,
    },
    {
      name: 'costPrice',
      title: 'Cost Price',
      type: 'currency',
      getCellValue: (row: any) => row.costValue,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'count', width: '9%' },
    { columnName: 'dateAdded', width: '11%' },
    { columnName: 'image', width: '10%' },
    { columnName: 'barcode', width: '11%' },
    { columnName: 'description', width: '18%' },
    { columnName: 'weight', width: '11%', align: 'right' },
    { columnName: 'retailPrice', width: '11%', align: 'right' },
    { columnName: 'costPrice', width: '11%', align: 'right' },
  ]);

  const [formattedColumns] = useState(['retailPrice', 'costPrice']);

  const onSelectionChange = (rowIds: any) => {
    dispatch(actions.setSelectedItemIds(rowIds));
  };

  return (
    <div key="stockTransfer">
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        formattedColumns={formattedColumns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={stockTransferList}
        rowSelectionEnable={true}
        showSelectionColumn={true}
        showSelectAll={false}
        selection={selectedItemIds}
        onSelectionChange={onSelectionChange}
        selectByRowClick={true}
        highlightRow={true}
      />
    </div>
  );
};

export default AcceptTransferSummary;
