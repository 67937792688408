import React, { useState } from 'react';
import { Modal, Row, Col, Input, notification } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/reducers/travelmoney';
import { Button } from 'components/common/Button';
import CustomerInfoHeader from 'components/common/CustomerInfoHeader';
import BuybackHistory from './history';
import { CURRENCY_BUYBACK_HISTORY_REQUEST } from 'action_creators/travelmoney';

import {
  CANCEL,
  PROCEED_TO_BAYBACK,
  BUYBACK_SERVICE_LABEL,
  NOT_AVAILABLE_LABEL,
  RATE_GUARANTEE_LABEL,
} from '../constants';
import { useEffect } from 'react';
import { isSoldDateExceed } from 'utils/util';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';

const { Search } = Input;

const StyledRow = styled(Row)`
margin-block-end: 10px;
`;

const StyledHeader = styled(Row)`
  margin-bottom: 8px;
  font-size: 2rem;
  white-space: initial;
  color: var(--text-color);
  font-weight: var(--font-weight-500);
  line-height: 23.44px;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
`;
const StyledContentContainer = styled(Col)`
  margin: 6px 0;
`;

const StyledTableWrapper = styled(Row)`
  height: 170px;
`;

const BuybackPopUp = () => {
  const dispatch = useDispatch();
  const {
    tm: {
      buybackHistory,
      isBuybackHistoryLoading,
      buybackCharges,
      error,
      proceedToBuyback,
      isBuySellTransLoading,
    },
    customer: { customer },
  } = useSelector((state: any) => {
    return { tm: state.tm, customer: state.customer };
  });
  const [visible, setVisible] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  useEffect(() => {
    if (buybackHistory.length > 0) {
      setVisible(true);
    }
  }, [buybackHistory]);

  useEffect(() => {
    if (proceedToBuyback && isBuySellTransLoading) {
      dispatch(actions.proceedToBuybackOff({}));
      setSearchValue('');
    }
  }, [dispatch, isBuySellTransLoading, proceedToBuyback]);

  useEffect(() => {
    if (error?.message) {
      notification.error({
        message: error?.message,
        description: error?.description,
        duration: 10,
      });
    }
  }, [error]);

  const baybackData = buybackHistory?.length > 0 && buybackHistory[0];
  const isDateExeeded = isSoldDateExceed(
    baybackData?.transactionDate,
    buybackCharges?.numberOfDays
  );

  const isDisabled =
    !isDateExeeded &&
    (buybackHistory?.length > 0 &&
      buybackHistory.some((item: any) => item.isRowSelected));

  const onCancelClick = () => {
    setVisible(false);
  };

  const onSearch = async () => {
    if (!searchValue) return;

    dispatch({
      type: CURRENCY_BUYBACK_HISTORY_REQUEST,
      payload: {
        params: {
          receiptNumber: searchValue,
          customerId: customer?.customerId,
        },
      },
    });
  };
  const onRowChange = (row: any, checked: boolean) => {
    if (isDateExeeded) return;
    dispatch(
      actions.selectedBuybackHistory({ ...row, isRowSelected: checked })
    );
  };
  const onProceedToBuybackClick = () => {
    dispatch(actions.proceedToBuybacks({}));
    setVisible(false);
  };

  const onSearchChange = (value: any) => {
    setSearchValue(value);
    if (!value && proceedToBuyback) dispatch(actions.proceedToBuybackOff({}));
  };

  return (
    <>
      <Row align="top" justify="space-around">
        <Col span={24}>
          <HelpPopoverInfo
            linkedID={`TRAVELMONEY_RateGuaranteeNumber`}
            position="rightTop"
          >
            {RATE_GUARANTEE_LABEL}
          </HelpPopoverInfo>
        </Col>
      </Row>
      <StyledRow align="top" justify="space-around">
        <Col span={24}>
          <Search
            placeholder="Enter Receipt Number"
            enterButton
            loading={isBuybackHistoryLoading}
            value={searchValue}
            onSearch={onSearch}
            onChange={(e) => onSearchChange(e.target.value)}
          />
        </Col>
      </StyledRow>
      <Modal
        open={visible}
        closable={true}
        key={1}
        centered
        width={941}
        maskClosable={false}
        onCancel={onCancelClick}
        footer={[
          <StyledButton itemProp="secondary" key="back" onClick={onCancelClick}>
            {CANCEL}
          </StyledButton>,
          <Button
            key="submit"
            disabled={!isDisabled}
            onClick={() => onProceedToBuybackClick()}
          >
            {PROCEED_TO_BAYBACK}
          </Button>,
        ]}
      >
        <StyledHeader>{BUYBACK_SERVICE_LABEL}</StyledHeader>
        <StyledContentContainer className="buyback-wrapper">
          <CustomerInfoHeader
            userName={baybackData?.createdUser || NOT_AVAILABLE_LABEL}
            storeName={baybackData?.storeLocation || NOT_AVAILABLE_LABEL}
            customerId={baybackData?.customerId}
            transactionId={searchValue}
            transactionDate={baybackData?.transactionDate || new Date()}
            isSoldDateExceed={
              baybackData?.transactionDate &&
              buybackCharges?.numberOfDays &&
              isSoldDateExceed(
                baybackData?.transactionDate,
                buybackCharges?.numberOfDays
              )
            }
          />
          <StyledTableWrapper className="buyback-summary-wrapper">
            <BuybackHistory rows={buybackHistory} onRowChange={onRowChange} />
          </StyledTableWrapper>
        </StyledContentContainer>
      </Modal>
    </>
  );
};
export default BuybackPopUp;
