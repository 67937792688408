import React, { useState, useEffect } from 'react';
import { Row, Col, Spin } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import { FNS_DATE_FORMAT } from 'globalConstants';
import { calculateTotalSumByKey, calculateTotalWeight } from 'utils/util';
import { PLEDGE_LOAN_PAYABLE } from 'action_creators/pledge';
import { BASKET_SERVICE_TYPE } from 'components/basket/constants';
import { PLEDGE_LOAN, PURCHASE_ITEM_TABLE } from '../constants';

import { isUndefined } from 'lodash';

const StyledItemValue = styled.div`
  padding: 5px 15px;
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

interface PurchaseTableContainerProps {
  purchaseItemRows: any;
  basketId?: any;
  userId?: any;
}

const TableRow = (label: string, value: any) => {
  return (
    <tr>
      <td width={180}>{label}</td>
      <td>
        {label !== PLEDGE_LOAN.TOTAL_WEIGHT
          ? `£${value?.toFixed(2)}`
          : `${value?.toFixed(2)}g`}
      </td>
    </tr>
  );
};

const PurchaseTableContainer = ({
  purchaseItemRows,
  basketId = null,
  userId = null,
}: PurchaseTableContainerProps) => {
  const { serviceId, purchase = {} } = Object(purchaseItemRows);
  const { request = {} } = Object(purchase);

  const { fee, loanPayableAmount, quarantineDate } = Object(request);

  const dispatch = useDispatch();
  const { selectedStore } = useSelector((state: any) => state.user);

  const [loanAmount, setLoanAmount] = useState<number>(0);
  const [totalWeight, setTotalWeight] = useState<number>(0);

  const allPurchaseItems = purchaseItemRows?.purchase?.request?.items;

  useEffect(() => {
    if (isUndefined(allPurchaseItems)) {
      setLoanAmount(0);
      setTotalWeight(0);
    }
    if (allPurchaseItems) {
      const amount = calculateTotalSumByKey(allPurchaseItems, 'loanAmount');
      const weight = calculateTotalWeight(allPurchaseItems);
      if (amount !== loanAmount || weight !== totalWeight) {
        dispatch({
          type: PLEDGE_LOAN_PAYABLE,
          payload: {
            params: {
              storeId: selectedStore?.storeId,
              loanAmount: amount,
              basketId,
              serviceId,
              userId,
              totalWeight: weight,
              serviceType: BASKET_SERVICE_TYPE.PURCHASE,
            },
          },
        });
      }
      setLoanAmount(amount);
      setTotalWeight(weight);
    }
  }, [allPurchaseItems]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row>
      <Col span={24}>
        <Row justify="end">
          <Col lg={17} xl={14} xxl={14}>
            <table className="pledge-valuation-table">
              <tbody>
                {TableRow(PURCHASE_ITEM_TABLE.TOTAL_WEIGHT, totalWeight || 0)}
                {TableRow(PURCHASE_ITEM_TABLE.SALE_AMOUNT, loanAmount || 0)}
                {TableRow(PURCHASE_ITEM_TABLE.FEE, fee || 0)}
                {TableRow(
                  PURCHASE_ITEM_TABLE.RECLAIM_AMT_PAYABLE,
                  loanPayableAmount || 0
                )}
              </tbody>
            </table>
          </Col>
          <StyledCol lg={7} xl={10} xxl={10}>
            <Row>
              <Col span={24}>
                <StyledItemValue>{`${PLEDGE_LOAN.ITEM_COUNT}: ${
                  allPurchaseItems ? allPurchaseItems.length : 0
                }`}</StyledItemValue>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <StyledItemValue>
                  {quarantineDate
                    ? `${PURCHASE_ITEM_TABLE.ITEM_RECLAIM} ${format(
                        new Date(quarantineDate),
                        FNS_DATE_FORMAT
                      )}`
                    : allPurchaseItems?.length > 0 && <Spin />}
                </StyledItemValue>
              </Col>
            </Row>
          </StyledCol>
        </Row>
      </Col>
    </Row>
  );
};

export default PurchaseTableContainer;
