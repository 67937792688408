import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { Layout, Row, Col } from 'antd';
import { GET_AUCTIONS_OVER_SEVENTYFIVE } from 'action_creators/pledgeManagement';
import { AUCTION_OVER_TITLE } from 'components/pledgemanagement/constants';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import FooterButtons from 'components/common/footerButtons';
import AuctionOverSummary from './auctionOverSummary';
import { actions } from 'redux/reducers/pledgeManagement';

const StyledContentLayout = styled(Layout)`
  background: var(--white);
  padding: 15px 30px;
  border-radius: 5px;
`;
const StyledTableWrapper = styled.div`
  height: calc(100vh - 308px);
`;

const AuctionOver = () => {
  const {
    pledgeManagement: { auctionsOverSeventyFiveList },
  } = useSelector((state: any) => {
    return {
      pledgeManagement: state.pledgeManagement,
    };
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: GET_AUCTIONS_OVER_SEVENTYFIVE,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onExitClick = () => {
    dispatch(actions.clearAuctions());
    navigate('/');
  };

  return (
    <>
      <StyledContentLayout>
        <Row className="title-row">
          <Col span={24}>
            <HelpPopoverInfo
              linkedID={''}
              position="rightTop"
              helpPosition="AFTER"
            >
              {AUCTION_OVER_TITLE}
            </HelpPopoverInfo>
          </Col>
        </Row>
        <StyledTableWrapper>
          <AuctionOverSummary
            auctionsOverSeventyFiveList={auctionsOverSeventyFiveList}
          />
        </StyledTableWrapper>
      </StyledContentLayout>
      <FooterButtons
        enableExtraClass
        isExitOnly={true}
        enableExitPopup={false}
        onConfirmExitClick={onExitClick}
      />
    </>
  );
};

export default AuctionOver;
