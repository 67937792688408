export const UPDATE_ROWS = 'UPDATE_ROWS';
export const START_LOADING = 'START_LOADING';
export const REQUEST_ERROR = 'REQUEST_ERROR';
export const FETCH_INIT = 'FETCH_INIT';
export const UPDATE_QUERY = 'UPDATE_QUERY';


type $state = {  
  rows: any,
  skip: number,
  requestedSkip: number,
  take: number,
  totalCount: 0,
  loading: boolean,
  lastQuery: any,
  pageSize: number,
};

const VIRTUAL_PAGE_SIZE = 100;
export const initialState: $state= {
  rows: [],
  skip: 0,
  requestedSkip: 0,
  take: VIRTUAL_PAGE_SIZE * 2,
  totalCount: 0,
  loading: false,
  lastQuery: "",
  pageSize: VIRTUAL_PAGE_SIZE
};


export function customerReducer(state: $state = initialState, { type, payload } : any) : $state {
  switch (type) {
    case UPDATE_ROWS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case START_LOADING:
      return {
        ...state,
        requestedSkip: payload.requestedSkip,
        take: payload.take,
      };
    case REQUEST_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_INIT:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_QUERY:
      return {
        ...state,
        lastQuery: payload,
      };
    default:
      return state;
  }
}