import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ControlledAlert from 'components/common/ControlledAlert';
import ManagerInfoPopup from './managerInfoPopup';
const AuthInfoPopUp = ({ onCancelClick }: any) => {
  const [visibleAuthInfoAlert, setVisibleAuthInfoAlert] = useState<any>({
    isAuthError: false,
    message: '',
    status: null,
  });

  const [visibleManagerPopup, setVisibleManagerPopup] =
    useState<boolean>(false);

  const { validateBasketServicesPayload } = useSelector(
    (state: any) => state.basket
  );

  useEffect(() => {
    if (validateBasketServicesPayload?.services) {
      const isAuthError = validateBasketServicesPayload?.services?.find(
        (er: any) => er.error
      );
      if (isAuthError?.error) {
        setVisibleAuthInfoAlert({
          isAuthError: true,
          message: isAuthError.error,
          status: isAuthError.status,
        });
      }
    }
  }, [validateBasketServicesPayload]);

  const onCancelPopUpClick = () => {
    setVisibleAuthInfoAlert({ isAuthError: false, message: '' });
    setVisibleManagerPopup(false);
    onCancelClick();
  };

  const onManagerOverrideClick = () => setVisibleManagerPopup(true);
  const onOkClick = () => {
    setVisibleAuthInfoAlert({ isAuthError: false, message: '' });
    setVisibleManagerPopup(false);
  };

  return (
    <>
      <ControlledAlert
        visible={visibleAuthInfoAlert?.isAuthError}
        message={visibleAuthInfoAlert?.message}
        status={visibleAuthInfoAlert?.status}
        onClick={onManagerOverrideClick}
        yesButtonText={'Manager Override'}
        onCancel={onCancelPopUpClick}
        cancelButtonText={'Cancel'}
      />
      <ManagerInfoPopup
        visible={visibleManagerPopup}
        onCancel={onCancelPopUpClick}
        onClick={onOkClick}
      />
    </>
  );
};

export default AuthInfoPopUp;
