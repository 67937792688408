import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  submiting: false, //loading status for submit button
  searching: false, //loading status for for search button
  deleting: false, //loading status for for delete button
  authorizing: false,
  isAuthorized: false,
  isErrorVisible: false,
  isSuccessMsgVisible: false,

  error: '',
  successMsg: '',
  authorizerName: '',

  //STOCK TRANSFER DATA
  transferParams: {},
  isEditMode: false,
  isClosedTransfer: false,
  isAcceptTransfer: false,
  searchTransferList: [] as Array<any>,
  destinationStores: [] as Array<any>,
  transferItemsList: [] as Array<any>,
  selectedItemIds: [],
  isItemAdded: false,
};

const slice = createSlice({
  name: 'inventoryManagementActions',
  initialState,
  reducers: {
    initLoading(state, action) {
      state.loading = action.payload;
    },
    initSubmit(state, action) {
      state.submiting = action.payload;
    },
    initSearch(state, action) {
      state.searching = action.payload;
    },
    initDelete(state, action) {
      state.deleting = action.payload;
    },
    initAuthorization(state, action) {
      state.authorizing = action.payload;
    },
    setTransferSearchList(state, action) {
      state.searchTransferList = action.payload;
    },
    setDestinationStores(state, action) {
      state.destinationStores = action.payload.stores;
    },
    setTransferParams(state, action) {
      state.transferParams = action.payload;
    },
    setEditMode(state, action) {
      state.isEditMode = action.payload;
    },
    setAcceptTransfer(state, action) {
      state.isAcceptTransfer = action.payload;
    },
    setClosedTransfer(state, action) {
      state.isClosedTransfer = action.payload;
    },
    setTransferItemsList(state, action) {
      state.transferItemsList = action.payload;
    },
    setSelectedItemIds(state, action) {
      state.selectedItemIds = action.payload;
    },
    setAuthorization(state, action) {
      state.isAuthorized = action.payload;
    },
    setAuthorizerName(state, action) {
      state.authorizerName = action.payload;
    },
    exitCreateEditTransfer(state) {
      state.authorizerName = '';
      state.isAuthorized = false;
      state.isEditMode = false;
      state.isClosedTransfer = false;
      state.isAcceptTransfer = false;
      state.transferParams = {};
      state.selectedItemIds = [];
      state.transferItemsList = [];
    },
    setIsErrorVisible(state, action) {
      state.isErrorVisible = action.payload;
    },
    setIsSuccessMsgVisible(state, action) {
      state.isSuccessMsgVisible = action.payload;
    },
    logSuccess(state, action) {
      state.successMsg = action.payload;
    },
    logError(state, action) {
      state.error = action.payload;
    },
    clearStockTransfer() {
      return {
        ...initialState,
      };
    },
    newItemAdded(state, action) {
      state.isItemAdded = action.payload;
    },
  },
});

export default slice.reducer;
export const { reducer, actions } = slice;
export { initialState };
