import React, { useState, useEffect } from 'react';
import { Select as AntSelect } from 'antd';

const Select = (props: any) => {
  const {
    showSearch = true,
    allowClear = false,
    onChange,
    onBlur,
    onClear,
    placeholder,
    className,
    addinputcontrolclass = true,
    options = [],
    ...rest
  } = props;

  const [fieldValue, setFieldValue] = useState<any>();

  useEffect(() => {
    if (props.value !== undefined) {
      setFieldValue(props.value);
    }
  }, [props.value]);

  const fieldPlaceholder =
    placeholder === undefined ? 'Select' : placeholder ? placeholder : '';

  const fieldClassName = `${addinputcontrolclass ? 'input-control' : ''} ${
    className ? className : ''
  }`;

  const onChangeHandler = (selectedOption: any) => {
    setFieldValue(selectedOption);
    onChange && onChange(selectedOption);
  };

  const onBlurHandler = (event: any) => {
    onBlur && onBlur(event);
  };

  const onClearHandler = () => {
    onClear && onClear();
  };

  return (
    <AntSelect
      {...props}
      {...rest}
      value={fieldValue}
      options={options}
      showSearch={showSearch}
      allowClear={allowClear}
      className={fieldClassName}
      placeholder={fieldPlaceholder}
      onBlur={onBlurHandler}
      onClear={onClearHandler}
      onChange={onChangeHandler}
      filterOption={(input: any, option: any) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    />
  );
};

export default Select;
