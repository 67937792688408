import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { FNS_DATE_FORMAT_WITH_TIME, GRID_NO_URL } from 'globalConstants';
import VirtualGrid from 'components/common/virtualGrid/index';
import { TILL_LABELS } from '../constants';
import { actions } from 'redux/reducers/cashManagement';

const StyledCashUp = styled.span`
  font-weight: var(--font-weight-500);
  color: var(--mountain-meadow);
`;

const StoreTillStatusTable = () => {
  const {
    cashManagement: { storeTillStatusData },
  } = useSelector((state: any) => {
    return {
      cashManagement: state.cashManagement,
    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const isTillActive = storeTillStatusData.some(
      (x: any) => x.tillStatus === 'Active'
    );
    dispatch(actions.setEndOfDayAvailable(!isTillActive));
  }, [storeTillStatusData]); // eslint-disable-line react-hooks/exhaustive-deps

  const [columns] = useState([
    {
      name: 'till',
      title: 'Till',
      getCellValue: (row: any) => row?.displayName,
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row: any) =>
        row?.tillStatus === TILL_LABELS.CASHED_UP ? (
          <StyledCashUp>{row?.tillStatus}</StyledCashUp>
        ) : (
          row?.tillStatus
        ),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'till', width: '270' },
    { columnName: 'status', width: '430' },
  ]);

  return (
    <>
      <VirtualGrid
        virtualScroll={true}
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={storeTillStatusData || []}
        expandableComponent={StoreTillExpandableTable}
      />
    </>
  );
};

export const StoreTillExpandableTable = ({ row }: any) => {
  const [columns] = useState([
    {
      name: 'currency',
      title: 'Currency',
      type: 'currency-tooltip',
      getCellValue: (row: any) => ({
        currencyCode: row?.currencyCode,
        currencyDesc: row?.currencyDescription,
      }),
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row: any) =>
        row?.status === TILL_LABELS.CASHED_UP ? (
          <StyledCashUp>{row?.status}</StyledCashUp>
        ) : (
          row?.status
        ),
    },
    {
      name: 'declared',
      title: 'Declared',
      getCellValue: (row: any) =>
        row?.declaredAmount &&
        `${row?.currencyCode} ${row?.declaredAmount?.toFixed(2) || '0.00'}`,
    },
    {
      name: 'variance',
      title: 'Variance',
      getCellValue: (row: any) =>
        row?.variance &&
        `${row?.variance < 0 ? '-' : ''} ${row?.currencyCode} ${
          Math.abs(row?.variance).toFixed(2) || '0.00'
        }`,
    },
    {
      name: 'confirmedBy',
      title: 'Confirmed By',
      getCellValue: (row: any) => row?.confirmedUser,
    },
    {
      name: 'closingDate',
      title: 'Closing Date & Time',
      getCellValue: (row: any) =>
        row?.closingDate &&
        `${format(new Date(row?.closingDate), FNS_DATE_FORMAT_WITH_TIME)}`,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'currency', width: '11%', align: 'left' },
    { columnName: 'status', width: '13%' },
    { columnName: 'declared', width: '15%', align: 'right' },
    { columnName: 'variance', width: '16%', align: 'right' },
    { columnName: 'confirmedBy', width: '16%' },
    { columnName: 'closingDate', width: '24%' },
  ]);

  const [formattedColumns] = useState(['currency']);

  return (
    <>
      <div className="expandable-table-rows ">
        <VirtualGrid
          urlType={GRID_NO_URL}
          formattedColumns={formattedColumns}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={row?.tillCurrencies || []}
        />
      </div>
    </>
  );
};

export default StoreTillStatusTable;
