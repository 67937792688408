import React from 'react';
import styled from 'styled-components';
import { Row, Modal, Col, notification } from 'antd';
import { Button } from 'components/common/Button';
import { BUTTONS_LABEL } from '../constants';
import { format } from 'date-fns';
import { FNS_DATE_FORMAT_WITH_TIME, GENERIC_ERROR } from 'globalConstants';
import { postMarkAsRead } from 'services/consumerDuty';

const StyledModal = styled(Modal)`
  & .title {
    font-size: var(--font-size-20);
    font-weight: var(--font-weight-500);
  }

  & .heading {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-600);

    span {
      color: var(--brick-red);
    }
  }
`;

const StyledDiv = styled.div`
  background: var(--white);
  border: 1px solid var(--light-gray);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  height: 200px;
  overflow-y: auto;
  padding: 10px;
`;

const StyledInnerDiv = styled.div`
  margin-bottom: 15px;
`;

const CaseMarkAsReadModal = ({ visible, onClose, noteToRead }: any) => {
  const onSubmitButtonClick = async () => {
    try {
      const params: never[] = noteToRead?.map((x: any) => x.customerNoteId);
      const response = await postMarkAsRead(params);
      if (response?.data?.statusCode === 200) {
        onClose();
        notification.success({ message: response?.data?.message, duration: 3 });
      } else notification.error({ message: GENERIC_ERROR, duration: 3 });
    } catch (e: any) {
      notification.error({ message: GENERIC_ERROR, duration: 3 });
    }
  };

  return (
    <StyledModal
      open={visible}
      width={790}
      mask={false}
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
      centered
      footer={
        <Row justify="space-between">
          <Button itemProp="secondary" onClick={onClose}>
            {BUTTONS_LABEL.CANCEL}
          </Button>
          <Button onClick={onSubmitButtonClick}>
            {BUTTONS_LABEL.MARK_AS_READ}
          </Button>
        </Row>
      }
    >
      <Row className="title">Case Summary Update</Row>
      <Row>
        <Col span={24}>
          <StyledDiv>
            {noteToRead?.map((x: any, index: number) => (
              <StyledInnerDiv key={index}>
                <div>{x.noteText}</div>
                <div>{`${x.createdUserName}, ${x.storeName}, ${format(
                  new Date(x?.createdOn),
                  FNS_DATE_FORMAT_WITH_TIME
                )}`}</div>
              </StyledInnerDiv>
            ))}
          </StyledDiv>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default CaseMarkAsReadModal;
