import * as urls from '../apiUrls';
import { doRequest } from 'utils/request';
import { AUCTION_STATUS } from 'globalConstants';

const postAuctionSearch = (params) => {
  return doRequest(`${urls.SEARCH_AUCTIONS}`, 'post', params);
};

const getAuctionsOver = () => {
  return doRequest(`${urls.LOANS_AUCTION}`, 'get');
};
const getPfiUnder = () => {
  return doRequest(`${urls.PFI_AUCTION}`, 'get');
};
const getPfiPurchaseUnder = () => {
  return doRequest(`${urls.PFI_PURCHASE}`, 'get');
};

const putConfirmCollection = (auctionId) => {
  return doRequest(`${urls.CONFIRM_COLLECTION}/${auctionId}`, 'put');
};

const getAuctionDetails = (id, auctionStatus) => {
  if (auctionStatus === AUCTION_STATUS.CLOSED) {
    return doRequest(`${urls.CLOSED_AUCTION_LOAN}/${id}`, 'get');
  } else if (auctionStatus === AUCTION_STATUS.LIVE_STORE) {
    return doRequest(`${urls.LIVE_AT_STORE}/${id}/false`, 'get');
  } else if (auctionStatus === AUCTION_STATUS.PENDING_RETURN) {
    return doRequest(`${urls.PENDING_RETURN_AUCTION_LOAN}/${id}`, 'get');
  } else if (auctionStatus === AUCTION_STATUS.LIVE_AUCTION) {
    return doRequest(`${urls.LIVE_AT_AUCTION}/${id}`, 'get');
  } else if (auctionStatus === AUCTION_STATUS.READY_FOR_AUCTION) {
    return doRequest(`${urls.LIVE_AT_STORE}/${id}/true`, 'get');
  }
};

const getConfirmAuctionSettlement = (auctionId) => {
  return doRequest(`${urls.CONFIRM_AUCTION_SETTLEMENT}/${auctionId}`, 'get');
};

const putSettleAuction = (auctionId) => {
  return doRequest(`${urls.SETTLE_AUCTION}/${auctionId}`, 'put');
};

const putUpdateSoldValue = (params) => {
  return doRequest(`${urls.UPDATE_SOLD_VALUE}`, 'put', params);
};

const putPassAuctionLot = (params) => {
  return doRequest(`${urls.PASS_AUCTION_LOT}`, 'put', params);
};

const getPrintPassList = (auctionId, printfor, printtype) => {
  return doRequest(
    `${urls.PRINT_AUCTION}/${auctionId}/${printfor}/${printtype}`,
    'get'
  );
};

const getLoanDetails = (loanNumber) => {
  return doRequest(`${urls.GET_LOAN_DETAILS}/${loanNumber}`, 'get');
};
const getPurchaseLoanDetails = (loanNumber) => {
  return doRequest(`${urls.GET_PURCHASE_LOAN_DETAILS}/${loanNumber}`, 'get');
};
const getParentItemCategory = (Id) => {
  return doRequest(`${urls.GET_PARENT_ITEM_CATEGORY}/${Id}`, 'get');
};
const postReviewItemsPfa = (params) => {
  return doRequest(`${urls.REVIEW_ITEMS_PFA}`, 'post', params);
};
const postPledgeToPfi = (params) => {
  return doRequest(`${urls.PUSH_PLEDGE_TO_PFI}`, 'post', params);
};
const postPurchaseToPfi = (params) => {
  return doRequest(`${urls.PUSH_PURCHASE_TO_PFI}`, 'post', params);
};
const postPfiBarcode = (barCodeParams) => {
  return doRequest(`${urls.POST_PFI_BARCODE}`, 'post', barCodeParams);
};
const putLockAuction = (auctionId) => {
  return doRequest(`${urls.LOCK_AUCTION}/${auctionId}`, 'put');
};

const getNotifyAuctionLocking = (auctionId) => {
  return doRequest(`${urls.NOTIFY_AUCTION_LOCKING}`, 'get');
};

const getPledgeItemsDetails = (itemId) => {
  return doRequest(`${urls.ITEMS_REVIEW_LIST}/${itemId}`, 'get');
};
const putPfaLotNumber = (params) => {
  return doRequest(`${urls.PFA_LOT_NUMBER}`, 'put', params);
};

const getGenerateLotSticker = (loanId) => {
  return doRequest(`${urls.GENERATE_LOT_STICKER}/${loanId}`, 'post');
};

const postPushToPFA = (params) => {
  return doRequest(`${urls.PUSH_TO_PFA}`, 'post', params);
};

const postConfiscatePledges = (params) => {
  return doRequest(`${urls.POST_CONFISCATE_PLEDGES}`, 'post', params);
};

const postVulnerablePledges = (params) => {
  return doRequest(`${urls.POST_VULNERABLE_PLEDGES}`, 'post', params);
};

const getVulnerablePledges = () => {
  return doRequest(`${urls.GET_VULNERABLE_PLEDGES}`, 'get');
};
const getConfiscationNotesByLoanId = (loanId) => {
  return doRequest(`${urls.GET_CONFISCATION_NOTES_BY_LOAID}/${loanId}`, 'get');
};

const addConfiscationNotes = (params) => {
  return doRequest(`${urls.GET_CONFISCATION_NOTES_BY_LOAID}`, 'post', params);
};

const getConfiscationLoans = () => {
  return doRequest(`${urls.GET_CONFISCATION_LOANS}`, 'get');
};

const printNPAStatement = () => {
  return doRequest(
    `${urls.PRINT_NPA_STATEMENT}`,
    'get',
    '',
    'application/json',
    false,
    'arraybuffer'
  );
};

const releaseConfiscation = (params) => {
  return doRequest(`${urls.RELEASE_CONFISCATION}`, 'post', params);
};

const getVulnerableNotes = (loanId) => {
  return doRequest(`${urls.GET_VULNERABLE_NOTES}/${loanId}`, 'get');
};

const postVulnerableNote = (params) => {
  return doRequest(`${urls.POST_VULNERABLE_NOTE}`, 'post', params);
};

const postRemoveVulnerableNote = (params) => {
  return doRequest(`${urls.POST_RELEASE_VULNERABLE}`, 'post', params);
};

export {
  postAuctionSearch,
  getAuctionsOver,
  getAuctionDetails,
  getPfiUnder,
  getPfiPurchaseUnder,
  putConfirmCollection,
  getConfirmAuctionSettlement,
  putSettleAuction,
  putUpdateSoldValue,
  getPrintPassList,
  getLoanDetails,
  getPurchaseLoanDetails,
  getParentItemCategory,
  putPassAuctionLot,
  putLockAuction,
  getNotifyAuctionLocking,
  postPfiBarcode,
  postPledgeToPfi,
  postPurchaseToPfi,
  getPledgeItemsDetails,
  postReviewItemsPfa,
  putPfaLotNumber,
  getGenerateLotSticker,
  postPushToPFA,
  postConfiscatePledges,
  postVulnerablePledges,
  getVulnerablePledges,
  getConfiscationNotesByLoanId,
  addConfiscationNotes,
  getConfiscationLoans,
  printNPAStatement,
  releaseConfiscation,
  getVulnerableNotes,
  postVulnerableNote,
  postRemoveVulnerableNote,
};
