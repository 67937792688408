import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { GRID_NO_URL } from 'globalConstants';
import { Layout, Row, Col } from 'antd';
import {
  VIEW_AUCTION_DETAILS_TITLE,
  VIEW_AUCTION_LIVE_AT_AUCTION_TITLE,
  AUCTION_PRINT_FOR,
  AUCTION_PRINT_TYPE,
  TABLE_HEAD_COLOR,
  CONFIRM_LOCK_HEADER,
  CONFIRM_LOCK_CONTENT,
  LOCK_AUCTION_DATE,
  LOCK_AUCTION_LOTS,
  LOCK_AUCTION_RESERVE_VALUE,
  RECONFIRM_LOCK_HEADER,
  RECONFIRM_LOCK_CONTENT,
  RECONFIRM_LOCK_WARNING,
} from 'components/pledgemanagement/constants';
import LiveAtAuctionSummary from '../auctionDetailsSummary/liveAtAuctionSummary';
import VirtualGrid from 'components/common/virtualGrid/index';
import { Button } from 'components/common/Button';
import {
  PRINT_PASS_LIST,
  LOCK_AUCTION,
} from 'action_creators/pledgeManagement';
import ControlledAlert from 'components/common/ControlledAlert';
import { BUTTON_LABELS } from 'components/pledgemanagement/constants';

const StyledContentLayout = styled(Layout)`
  background: var(--white);
  padding: 15px 30px;
  border-radius: 5px;
`;

const StyledDetailsRow = styled(Row)`
  padding-bottom: 10px;
`;

const StyledTableWrapper = styled.div`
  height: calc(100vh - 407px);
`;

const StyledBottomRow = styled(Row)`
  padding: 15px 30px;
  background: var(--white);
  border: 0.6px solid var(--linkwater);
  border-top: none;
  box-shadow: rgba(1, 78, 169, 0.2) 0px 4px 10px;
  border-radius: 0px 0px 5px 5px;
  box-shadow: none;
  border: 0;
  border-top: 1px solid var(--linkwater);
`;

const StyledButton = styled(Button)`
  margin: 0 15px;
`;

const StyledAlertText = styled(Row)`
  color: var(--red);
  padding: 10px 0 15px 0;
`;

const StyledHeadText = styled(Row)`
  font-size: var(--font-size-20);
  white-space: initial;
  font-weight: var(--font-weight-500);
`;

interface LiveAtAuctionDetailsProps {
  onExit: () => void;
}
const LiveAtAuctionDetails = ({ onExit }: LiveAtAuctionDetailsProps) => {
  const {
    pledgeManagement: { auctionItemDetails, successLockAuction },
  } = useSelector((state: any) => {
    return {
      pledgeManagement: state.pledgeManagement,
    };
  });

  const { auctionLoans, ...auctionDetailsTable } = auctionItemDetails;

  const [columns] = useState([
    {
      name: 'auctionDate',
      title: 'Auction Date',
      getCellValue: (row: any) => row?.auctionDate,
    },
    {
      name: 'bookDate',
      title: 'Book Date',
      getCellValue: (row: any) => row?.bookDate,
    },
    {
      name: 'collectionDate',
      title: 'Collection Date',
      getCellValue: (row: any) => row?.collectionDate,
    },
    {
      name: 'lastMailDate',
      title: 'Last Mail Sent',
      getCellValue: (row: any) => row?.lastMailDate,
    },
    {
      name: 'reserveValue',
      title: 'Reserve Value',
      getCellValue: (row: any) => row?.reserveValue,
    },
    {
      name: 'numberOfLots',
      title: 'Number of Lots',
      getCellValue: (row: any) => row?.numberOfLots,
    },
    {
      name: 'auctionStatusText',
      title: 'Status',
      getCellValue: (row: any) => row?.auctionStatusText,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'auctionDate', width: '14%' },
    { columnName: 'bookDate', width: '14%' },
    { columnName: 'collectionDate', width: '14%' },
    { columnName: 'lastMailDate', width: '14%' },
    { columnName: 'reserveValue', width: '14%' },
    { columnName: 'numberOfLots', width: '13%' },
    { columnName: 'auctionStatusText', width: '13%' },
  ]);

  const getRowId = (row: any) => row?.auctionId;
  const [isLockAlertVisible, setIsLockAlertVisible] = useState<boolean>(false);
  const [isConfirmLockAlertVisible, setIsConfirmLockAlertVisible] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const printPassList = (printType: number) => {
    dispatch({
      type: PRINT_PASS_LIST,
      payload: {
        auctionId: auctionDetailsTable?.auctionId,
        printFor: AUCTION_PRINT_FOR.LIVE_AT_AUCTION,
        printType: printType,
      },
    });
  };

  const onLockAuctionClick = (isVisible: boolean) => {
    setIsLockAlertVisible(isVisible);
  };

  const confirmLockAuction = (isVisible: boolean) => {
    setIsLockAlertVisible(false);
    setIsConfirmLockAlertVisible(isVisible);
  };

  const onLockAuctionProceed = () => {
    setIsConfirmLockAlertVisible(false);
    dispatch({
      type: LOCK_AUCTION,
      payload: {
        auctionId: auctionDetailsTable?.auctionId,
      },
    });
  };

  const confirmLockMsgString = () => {
    return (
      <>
        <StyledHeadText>{CONFIRM_LOCK_HEADER}</StyledHeadText>
        <StyledAlertText>{CONFIRM_LOCK_CONTENT}</StyledAlertText>
        <table className="auction-settlement-table">
          <thead>
            <tr>
              <th>{LOCK_AUCTION_DATE}</th>
              <th>{LOCK_AUCTION_LOTS}</th>
              <th>{LOCK_AUCTION_RESERVE_VALUE}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{auctionDetailsTable?.auctionDate}</td>
              <td>{auctionDetailsTable?.numberOfLots}</td>
              <td>{auctionDetailsTable?.reserveValue}</td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  const reConfirmLockMsgString = () => {
    return (
      <>
        <StyledHeadText>{RECONFIRM_LOCK_HEADER}</StyledHeadText>
        <StyledAlertText>{RECONFIRM_LOCK_CONTENT}</StyledAlertText>
        <StyledAlertText>{RECONFIRM_LOCK_WARNING}</StyledAlertText>
      </>
    );
  };

  useEffect(() => {
    if (successLockAuction?.success) {
      onExit();
    }
  }, [successLockAuction, onExit]);

  return (
    <>
      <StyledContentLayout>
        <Row className="title-row">
          <Col span={24}>
            {VIEW_AUCTION_DETAILS_TITLE}
            {VIEW_AUCTION_LIVE_AT_AUCTION_TITLE}
          </Col>
        </Row>
        <StyledDetailsRow>
          <VirtualGrid
            urlType={GRID_NO_URL}
            columnsProps={columns}
            tableColumnExtensionsProps={tableColumnExtensions}
            rowData={[auctionDetailsTable]}
            getRowId={getRowId}
            headerBackground={TABLE_HEAD_COLOR.ACTIVE}
          />
        </StyledDetailsRow>
        <StyledTableWrapper>
          <LiveAtAuctionSummary auctionLoans={auctionLoans || []} />
        </StyledTableWrapper>
      </StyledContentLayout>

      <Row>
        <Col span={24}>
          <StyledBottomRow>
            <Col span={16}>
              <Row align="bottom" justify="start">
                <StyledButton
                  onClick={onExit}
                  itemProp="secondary"
                  style={{ marginLeft: 0 }}
                >
                  {BUTTON_LABELS.BACK}
                </StyledButton>
                <StyledButton
                  itemProp="secondary"
                  onClick={() => printPassList(AUCTION_PRINT_TYPE.PASSES)}
                >
                  {BUTTON_LABELS.PRINT_PASSES}
                </StyledButton>
                <StyledButton
                  itemProp="secondary"
                  onClick={() => printPassList(AUCTION_PRINT_TYPE.AUCTION_BOOK)}
                >
                  {BUTTON_LABELS.PRINT_AUCTION_BOOK}
                </StyledButton>
              </Row>
            </Col>
            <Col span={8}>
              <Row align="bottom" justify="end">
                <StyledButton itemProp="secondary">Save</StyledButton>
                <StyledButton
                  itemProp="secondary"
                  onClick={() => onLockAuctionClick(true)}
                >
                  {BUTTON_LABELS.LOCK_AUCTION}
                </StyledButton>
              </Row>
            </Col>
          </StyledBottomRow>
        </Col>
      </Row>

      {isLockAlertVisible && (
        <ControlledAlert
          closable={true}
          visible={isLockAlertVisible}
          isCancelBtn={true}
          cancelButtonText={BUTTON_LABELS.CANCEL}
          onCancel={() => setIsLockAlertVisible(false)}
          alertMessage={''}
          message={confirmLockMsgString()}
          onClick={() => confirmLockAuction(true)}
          yesButtonText={BUTTON_LABELS.PROCEED}
        />
      )}

      {isConfirmLockAlertVisible && (
        <ControlledAlert
          closable={true}
          visible={isConfirmLockAlertVisible}
          isCancelBtn={true}
          cancelButtonText={BUTTON_LABELS.CANCEL}
          onCancel={() => setIsConfirmLockAlertVisible(false)}
          alertMessage={''}
          message={reConfirmLockMsgString()}
          onClick={onLockAuctionProceed}
          yesButtonText={BUTTON_LABELS.PROCEED}
        />
      )}
    </>
  );
};

export default LiveAtAuctionDetails;
