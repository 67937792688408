export const CUSTOMER_PROFILE_REQUEST = 'CUSTOMER_PROFILE_REQUEST';
export const UPLOAD_CUSTOMER_IMAGE_REQUEST = 'UPLOAD_CUSTOMER_IMAGE_REQUEST';
export const CUSTOMER_SAVE_REQUEST = 'CUSTOMER_SAVE_REQUEST';
export const CUSTOMER_LAST_VISITS = 'CUSTOMER_LAST_VISITS';
export const UPLOAD_CUSTOMER_DOCUMENT_REQUEST =
  'UPLOAD_CUSTOMER_DOCUMENT_REQUEST';
export const CUSTOMER_PROFILE_REQUEST_IN_BACKGROUND =
  'CUSTOMER_PROFILE_REQUEST_IN_BACKGROUND';

export const CREATE_LITE_CUSTOMER = 'CREATE_LITE_CUSTOMER';
export const LITE_CUSTOMER_DOC_UPLOAD = 'LITE_CUSTOMER_DOC_UPLOAD';
export const UPDATE_TRAVEL_MONEY_GUEST = 'UPDATE_TRAVEL_MONEY_GUEST';
export const UPLOAD_TM_GUEST_DOCUMENTS = 'UPLOAD_TM_GUEST_DOCUMENTS';

export const GET_PROFILE_STATUS = 'GET_PROFILE_STATUS';
export const UPLOAD_TPA_IMAGE_REQUEST = 'UPLOAD_TPA_IMAGE_REQUEST';
export const UPLOAD_TPA_DOCUMENT_REQUEST = 'UPLOAD_TPA_DOCUMENT_REQUEST';
