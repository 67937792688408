import React, { useState, useEffect } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import styled from 'styled-components';
import {
  BASKET_SERVICE_TYPE,
  GRID_NO_URL,
  REGEX_AMOUNT,
} from 'globalConstants';
import { currencyFormat, joinValues, toFixedNoRound } from 'utils/util';
import VirtualGrid from 'components/common/virtualGrid/index';
import ImageLoader from 'components/common/ImageLoader';
import InputDecimal from 'components/common/InputDecimal';
import {
  RETAIL_ITEM_STATUS,
  ERROR_MESSAGE_ON_RETAIL_BASKET_INVALID_INPUT,
} from 'components/retail/constants';
import { Row, Col, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/reducers/basket';
import { actions as retailActions } from 'redux/reducers/retail';
import { CREATE_BASKET_RETAIL_ITEMS } from 'action_creators/basket';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';

const StyledRow = styled(Row)`
  & .ant-input-group-wrapper {
    border: 1px solid var(--catskill-white-light);
    border-radius: 3px;
    color: var(--black) !important;
    text-align: right;
    & .ant-input-group-addon {
      background: var(--catskill-white-light);
    }
    & .ant-input {
      height: 4.2rem;
      color: var(--black) !important;
    }
  }
`;

const StyledStatus = styled.span`
  font-weight: var(--font-weight-500);
  color: var(--red);
`;

const TableRow = ({ row, onCellClick, ...restProps }: any) => {
  const Cell = () => {
    const { column, tableRow } = restProps;
    const { row } = tableRow;
    if (column.name === 'discount') {
      return (
        <Table.Cell
          className={
            row?.status === RETAIL_ITEM_STATUS.TO_RETURN ? 'noEdit' : ''
          }
          {...restProps}
        >
          {row?.status === RETAIL_ITEM_STATUS.TO_RETURN ? (
            `${currencyFormat(row?.discount || 0, true)}`
          ) : (
            <>
              <StyledRow>
                <Col span={24}>
                  <InputDecimal value={row?.discount} />
                </Col>
              </StyledRow>
            </>
          )}
        </Table.Cell>
      );
    }
    if (column.name === 'discountPercentage') {
      return (
        <Table.Cell
          className={
            row?.status === RETAIL_ITEM_STATUS.TO_RETURN ? 'noEdit' : ''
          }
          {...restProps}
        >
          {row?.status === RETAIL_ITEM_STATUS.TO_RETURN ? (
            `${(row?.discountPercentage || 0).toFixed(2)}%`
          ) : (
            <>
              <StyledRow>
                <Col span={24}>
                  <InputDecimal
                    value={row?.discountPercentage}
                    addonAfter={'%'}
                    addonBefore={false}
                  />
                </Col>
              </StyledRow>
            </>
          )}
        </Table.Cell>
      );
    }
    return (
      <Table.Cell className="noEdit" onClick={onCellClick} {...restProps} />
    );
  };

  return <>{Cell()}</>;
};

const EditorComponent = ({ value, onValueChange, onBlur, column }: any) => {
  if (column.name === 'discount')
    return (
      <div className="discount-input-retail-basket">
        <InputDecimal
          value={value}
          onBlur={onBlur}
          onPressEnter={onBlur}
          onChange={(event: { target: { value: any } }) =>
            onValueChange(event.target.value)
          }
          autoFocus
        />
      </div>
    );
  else if (column.name === 'discountPercentage')
    return (
      <div className="discount-input-retail-basket">
        <InputDecimal
          value={value}
          addonAfter={'%'}
          addonBefore={false}
          onBlur={onBlur}
          onPressEnter={onBlur}
          onChange={(event: { target: { value: any } }) =>
            onValueChange(event.target.value)
          }
          autoFocus
        />
      </div>
    );
};

const EditProvider = (props: any) => {
  return <DataTypeProvider editorComponent={EditorComponent} {...props} />;
};

const BasketRetailTable = (item: any) => {
  const rows = item?.retail?.request?.items || [];
  const serviceId = item?.serviceId;
  const dispatch = useDispatch();
  const [selection, setSelection] = useState<Array<any>>([]);

  const {
    customer: { customer },
    basket: { basket },
    user: { user, selectedStore },
  } = useSelector((state: any) => {
    return {
      retail: state.retail,
      customer: state.customer,
      basket: state.basket,
      user: state.user,
    };
  });

  const getRetailServiceId = () => {
    const retailService = basket?.basketServices?.filter(
      (x: any) => x.serviceType === BASKET_SERVICE_TYPE.RETAIL
    );
    if (retailService?.length > 0) {
      return retailService[0].serviceId;
    }
    return null;
  };

  const {
    street,
    town,
    property,
    county,
    postcode,
    house,
    area,
    firstNames,
    middleName,
    surname,
  } = customer;

  const onSelectionChange = (row: any) => {
    setSelection(row);
  };

  const createRowChange = (row: any, value: string, columnName: string) => {
    if (!REGEX_AMOUNT.test(value)) {
      return;
    }
    if (
      (columnName === 'discount' && +value > row?.listValue) ||
      (columnName === 'discountPercentage' &&
        (row.listValue / 100) * +value > row?.listValue)
    ) {
      return notification.error({
        message: ERROR_MESSAGE_ON_RETAIL_BASKET_INVALID_INPUT.message,
        description: ERROR_MESSAGE_ON_RETAIL_BASKET_INVALID_INPUT.description,
        duration: 2,
      });
    } else {
      if (columnName === 'discount') {
        row[columnName] = value;
        row['discountPercentage'] = ((+value / row.listValue) * 100).toFixed(2);
      }
      if (columnName === 'discountPercentage') {
        row[columnName] = value;
        row['discount'] = toFixedNoRound((row.listValue / 100) * +value);
      } else row[columnName] = value;
      return row;
    }
  };

  const commitEditChanges = ({ changed }: any) => {
    if (changed) {
      const changedRows = rows.map((row: any) =>
        changed[row.itemId] ? { ...row, ...changed[row.itemId] } : row
      );

      dispatch(retailActions.retailItemAddToBasket(changedRows));
      dispatch(actions.updateRetailDiscount({ serviceId, changedRows }));

      const retailItemPayload = {
        basketId: basket?.basketId,
        serviceId: basket ? getRetailServiceId() : null,
        customerId: customer?.customerId,
        storeId: selectedStore?.storeId,
        userId: user?.userId,
        items: changedRows,
        serviceType: BASKET_SERVICE_TYPE.RETAIL,
        paymentType: 0,
        customer: {
          street,
          town,
          property,
          county,
          postcode,
          house,
          area,
          firstNames,
          middleName,
          surname,
        },
        customerName:
          joinValues([customer.firstNames, customer.surname], true) ||
          undefined,
        storeAddress: selectedStore?.storeAddress,
        storePostalCode: selectedStore?.postalCode,
        storeTelephone: selectedStore?.telephone,
        userName: user?.authorizeUsername,
      };
      dispatch(retailActions.setTempPayloadWithVAT(retailItemPayload));

      dispatch({
        type: CREATE_BASKET_RETAIL_ITEMS,
        payload: { params: retailItemPayload },
      });
    }
  };

  const itemDescription = (row: any) => {
    return {
      title: row.description,
      anchor: row.description,
      placement: 'bottom',
      itemId: row.stockItemId,
      data: {},
    };
  };

  useEffect(() => {
    const payload = {
      sourceServiceId: item?.serviceId,
      itemIds: selection,
      sourceServiceType: item?.serviceType,
    };
    dispatch(actions.move(payload));
  }, [selection]); // eslint-disable-line react-hooks/exhaustive-deps

  const [columns] = useState([
    {
      name: 'image',
      title: 'Image',
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row?.stockItemId}`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'inventoryType',
      title: 'Inventory Type',
      getCellValue: (row: any) => row?.inventoryType,
    },
    {
      name: 'barcode',
      title: 'Barcode',
      getCellValue: (row: any) => row?.barcode,
    },

    {
      name: 'description',
      title: (
        <HelpPopoverInfo
          linkedID={`BASKET_RETAIL_DESCRIPTION`}
          position="rightTop"
        >
          {'Description'}
        </HelpPopoverInfo>
      ),
      type: 'popover',
      getCellValue: (row: any) => itemDescription(row),
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) => row?.weight + 'g',
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row: any) => {
        return row?.status === RETAIL_ITEM_STATUS.TO_RETURN ? (
          <StyledStatus>{row.status}</StyledStatus>
        ) : (
          <span>{row.status}</span>
        );
      },
    },
    {
      name: 'listValue',
      title: 'List Value',
      type: 'currency',
      getCellValue: (row: any) => row?.listValue,
    },
    {
      name: 'discount',
      title: (
        <HelpPopoverInfo linkedID={`BASKET_RETAIL_DISCOUNT`} position="leftTop">
          {'Discount'}
        </HelpPopoverInfo>
      ),
      getCellValue: (row: any) => row?.discount,
    },
    {
      name: 'discountPercentage',
      title: 'Discount %',
      getCellValue: (row: any) => row?.discountPercentage,
    },
    {
      name: 'balanceDue',
      title: 'Balance Due',
      getCellValue: (row: any) => {
        if (row?.status !== RETAIL_ITEM_STATUS.TO_RETURN) {
          const balanceDue = row?.listValue - row?.discount;
          return <span>{currencyFormat(balanceDue, true)}</span>;
        } else {
          const balanceDue = row?.soldValue;
          return (
            <StyledStatus>{currencyFormat(balanceDue, true)}</StyledStatus>
          );
        }
      },
    },
  ]);

  const tableColumnExtensions = [
    { columnName: 'image', editingEnabled: false, width: '6%' },
    { columnName: 'inventoryType', editingEnabled: false, width: '8%' },
    { columnName: 'barcode', editingEnabled: false, width: '8%' },
    { columnName: 'description', editingEnabled: false, width: '23%' },
    {
      columnName: 'weight',
      editingEnabled: false,
      width: '8%',
      align: 'right',
    },
    {
      columnName: 'status',
      editingEnabled: false,
      width: '7%',
      align: 'right',
    },
    {
      columnName: 'listValue',
      editingEnabled: false,
      width: '9%',
      align: 'right',
    },
    { columnName: 'discount', editingEnabled: true, width: '9%' },
    { columnName: 'discountPercentage', editingEnabled: true, width: '10%' },
    {
      columnName: 'balanceDue',
      editingEnabled: false,
      width: '8%',
      align: 'right',
    },
  ];

  const [formattedColumns] = useState(['description', 'listValue']);
  const columnsToEdit = ['discount', 'discountPercentage'];

  return (
    <>
      <div className="basket-item-table">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={rows}
          formattedColumns={formattedColumns}
          rowSelectionEnable={true}
          highlightRow={true}
          cellComponent={(restProps: any) => <TableRow {...restProps} />}
          selection={selection}
          onSelectionChange={onSelectionChange}
          rowEditingEnabled={true}
          createRowChange={createRowChange}
          commitEditChanges={commitEditChanges}
          columnsToEdit={columnsToEdit}
          EditProvider={EditProvider}
        />
      </div>
    </>
  );
};

export default BasketRetailTable;
