import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import VirtualGrid from 'components/common/virtualGrid';

import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Button } from 'components/common/Button';

import { GRID_NO_URL } from 'globalConstants';

import { dateDisplayFormat } from 'utils/util';

interface ValuationsTableProps {
  onActionButtonClick: (details: any) => void;
}

const TableRow = ({
  row,
  onClickTableRow,
  loadTransferDetails,
  ...restProps
}: any) => {
  const onRowClick = () => {
    onClickTableRow(row);
  };

  const Cell = () => {
    return <Table.Cell onClick={onRowClick} {...restProps} />;
  };

  return <>{Cell()}</>;
};

const ValuationsTable = ({ onActionButtonClick }: ValuationsTableProps) => {
  const {
    user: { selectedStore },
    valuationCenter: { notificationsWorkqueueList: valuations },
  } = useSelector((state: any) => {
    return {
      user: state.user,
      valuationCenter: state.valuationCenter,
    };
  });

  const [columns] = useState([
    {
      name: 'customerName',
      title: 'Customer',
      getCellValue: (row: any) => row?.customerName,
    },
    {
      name: 'serviceType',
      title: 'Service Type',
      getCellValue: (row: any) => row?.serviceType,
    },
    {
      name: 'serviceReference',
      title: 'Service Reference',
      getCellValue: (row: any) => row?.serviceReference,
    },
    {
      name: 'store',
      title: 'Processed at',
      getCellValue: () => selectedStore.storeName,
    },

    {
      name: 'creationTimeStamp',
      title: 'Created',
      getCellValue: (row: any) => dateDisplayFormat(row?.createdOn),
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row: any) => row?.status,
    },
    {
      name: 'actions',
      title: 'Actions',
      getCellValue: (row: any) => (
        <Button itemProp="secondary" onClick={() => onActionButtonClick(row)}>
          Process
        </Button>
      ),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'customerName', width: '17%' },
    { columnName: 'serviceType', width: '13%' },
    { columnName: 'serviceReference', width: '13%', align: 'right' },
    { columnName: 'store', width: '21%', align: 'center' },
    { columnName: 'creationTimeStamp', width: '14%', align: 'center' },
    { columnName: 'status', width: '10%' },
    { columnName: 'actions', width: '10%' },
  ]);

  const [formattedColumns] = useState(['delete']);

  const onClickTableRow = (row: any) => {};

  const getRowId = (row: any) => row?.serviceReference;

  return (
    <>
      <div className="work-queue-grid-wrapper ">
        <VirtualGrid
          urlType={GRID_NO_URL}
          // virtualScroll={true} if implemented need to convert column width to fix values insted of dynamic
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          formattedColumns={formattedColumns}
          rowData={valuations}
          getRowId={getRowId}
          cellComponent={(restProps: any) => (
            <TableRow onClickTableRow={onClickTableRow} {...restProps} />
          )}
          showSelectionColumn={false}
          showSelectAll={false}
        />
      </div>
    </>
  );
};

export default ValuationsTable;
