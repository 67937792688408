import * as urls from '../apiUrls';
import { doRequest } from 'utils/request';
import { b64toBlob } from 'utils/util';

const getStores = () => {
  return doRequest(`${urls.GET_STORES}`, 'get');
};

const getTransferItems = (transferId) => {
  return doRequest(`${urls.GET_TRANSFER_ITEMS}/${transferId}`, 'get');
};

const postCreateTransfer = (params) => {
  return doRequest(`${urls.POST_CREATE_TRANSFER}`, 'post', params);
};

const postTransferSearch = (params) => {
  return doRequest(`${urls.POST_TRANSFER_SEARCH}`, 'post', params);
};

const postBarcodeSearch = (params) => {
  return doRequest(`${urls.POST_BARCODE_SEARCH}`, 'post', params);
};

const postPrintStockTransfer = (params) => {
  return doRequest(
    `${urls.POST_PRINT_STOCK_TRANSFER}`,
    'post',
    params,
    'application/json',
    false,
    'arraybuffer'
  );
};

const putSendTransfer = (params) => {
  return doRequest(`${urls.PUT_SEND_TRANSFER}`, 'put', params);
};

const putAcceptTransfer = (params) => {
  return doRequest(`${urls.PUT_ACCEPT_TRANSFER}`, 'put', params);
};

const deleteTransferItems = (params) => {
  return doRequest(`${urls.DELETE_TRANSFER_ITEMS}`, 'delete', params);
};

const onBarcodeSearch = (id) => {
  return doRequest(`${urls.PRINT_ITEM_BARCODE}/${id}`, 'get');
};

const getBarcodeSticker = (params) => {
  return doRequest(
    `${urls.GENERATE_RETAIL_BARCODE}`,
    'post',
    params,
    'application/json',
    false,
    'arraybuffer'
  );
};

const putTransferAuthorization = (params, authorisationHeaders) => {
  return doRequest(
    `${urls.PUT_AUTHORIZATION_TRANSFER}`,
    'put',
    params,
    '',
    false,
    '',
    authorisationHeaders
  );
};

const postReceiptUpload = (receipt) => {
  let blob = b64toBlob(receipt);
  let formData = new FormData();

  formData.append('file', blob);

  return doRequest(
    `${urls.CASH_MNGMT_RECEIPT_UPLOAD}`,
    'post',
    formData,
    'multipart/form-data'
  );
};

export {
  getStores,
  postCreateTransfer,
  postTransferSearch,
  getTransferItems,
  postBarcodeSearch,
  postPrintStockTransfer,
  putSendTransfer,
  putAcceptTransfer,
  putTransferAuthorization,
  deleteTransferItems,
  onBarcodeSearch,
  getBarcodeSticker,
  postReceiptUpload,
};
