import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Input,
  Button,
  Dropdown,
  Menu,
  notification,
  Space,
} from 'antd';
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons/lib/icons';
import RetailSaleSummary from './RetailSaleSummary';
import { getRecieptDetails } from 'services/retail';
import FindSoldItemPopup from 'components/retail/viewRetailSale/FindSoldItemPopup';
import {
  REFUND_OPTIONS,
  FIND_ITEM,
  BARCODE_PLACEHOLDER,
  RECIEPT_PLACEHOLDER,
  SOLD_ITEM,
  ERROR_MESSAGE_ON_RECEIPT_NOT_FOUND,
  NOTIFICATION_FOR,
  RETAIL_ITEM_STATUS,
  RETAIL_BALANCE,
} from '../constants';
import RetailPopup from './RetailPopup';
import RefundExchangePopup from './RefundExchangePopup';
import { RecieptProps } from '../../interface/RecieptProps';
import { NOTIFICATION_TYPE } from 'globalConstants';
import { currencyFormat } from 'utils/util';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';

const StyledInput = styled(Input)`
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-500);
  text-align: right;
  border-radius: 3px;
  height: 4.2rem;
  background: var(--solitude);
  color: var(--black);
  &::placeholder {
    font-weight: 700;
    background: var(--solitude);
    color: var(--black);
  }
`;
const StyledText = styled.div`
  font-weight: var(--font-weight-500);
  line-height: 16px;
  font-size: var(--font-size-18);
`;
const StyledDropdownText = styled.div`
  font-weight: var(--font-weight-500);
  line-height: 16px;
  padding-right: 5px;
`;

const StyledButton = styled(Button)`
  background: var(--white);
  border: 1px solid var(--light-gray);
  box-shadow: 0px 1px 1px rgb(0 0 0 / 10%);
  border-radius: 3px;
  height: 42px;
  color: var(--black);
  &:focus,
  &:hover,
  &:active {
    background: var(--white);
    color: var(--black);
  }
  width: 100%;
`;

const StyledTotalLoanRow = styled(Row)`
  background: var(--cyan);
  padding: 10px;
`;

const StyledTotalLoanCol = styled(Col)`
  align-items: center;
  font-size: 16px;
`;
const StyledDropdownCol = styled(Col)`
  padding-top: 15px;
  padding-bottom: 15px;
`;

const StyledRowHelp = styled(Row)`
  margin-left: 30px;
  margin-top: 10px;
`;

const StyledDropdown = styled(Dropdown)`
  width: 273px;
  float: right;
  font-weight: var(--font-weight-500);
  padding-right: 77px !important;
`;
const StyledTableWrapper = styled.div`
  min-height: calc(100vh - 334px);
  padding: 0px 20px 0px 20px;
  background: var(--white);
`;
const StyledSearch = styled(Input.Search)`
  & .ant-input-group-wrapper {
    width: 299px !important;
    & .ant-input-affix-wrapper {
      height: 42px !important;
    }
    & .ant-input-search-button {
      height: 42px !important;
    }
  }
`;

const StyledTextCol = styled(Col)`
  padding: 11px 0px 0px 10px;
  font-size: 16px;
  cursor: pointer;
`;

const StyledMainRow = styled(Row)`
  padding: 0px 20px 0px 20px;
`;

const StyledSoldItem = styled.span`
  font-weight: var(--font-weight-500);
  font-size: 14px;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 0 15px;
`;

const StyledColAmountInput = styled(Col)`
  margin-right: 18px;
`;

interface ViewRetailSalesProps {
  rows: Array<object>;
}

const ViewRetailSales = ({ rows }: ViewRetailSalesProps) => {
  const {
    user: { user, selectedStore },
  } = useSelector((state: any) => {
    return {
      user: state.user,
    };
  });

  const [visible, setVisible] = useState<boolean>(false);
  const [soldItemPopupVisible, setSoldItemPopupVisible] =
    useState<boolean>(false);
  const [showRows, setShowRows] = useState<boolean>(false);
  const [barcode, setBarcode] = useState<string>('');
  const [recieptDetails, setRecieptDetails] = useState<RecieptProps>({
    retailTransactionId: 0,
    neoReceiptId: 0,
    transactionDate: '',
    customerId: 0,
    itemCount: 0,
    storeId: selectedStore?.storeId,
    storeName: '',
    createdUserName: '',
    paymentType: 0,
    userId: user?.userId,
    authUserId: 0,
    amountPaid: 0,
    cardAmount: 0,
    cashAmount: 0,
    paymentReference: '',
    retailRefundItemTransactionDetails: [],
  });
  const [recieptNo, setRecieptNo] = useState<string>('');

  const [exchangePopupVisible, setExchangePopupVisible] =
    useState<boolean>(false);
  const [overlayVisiblity, setOverlayVisiblity] = useState<boolean>(false);
  const [recieptLoading, setRecipetLoading] = useState<boolean>(false);
  const onFindItemClick = () => {
    setVisible(true);
  };

  const onFindSoldItemClick = () => {
    setOverlayVisiblity(false);
    setSoldItemPopupVisible(true);
  };

  const menuItems = [
    {
      key: '1',
      label: (
        <StyledSearch
          allowClear
          maxLength={30}
          width={299}
          className="reciept-number-search-wrapper"
          placeholder={RECIEPT_PLACEHOLDER}
          onChange={(event) => setRecieptNo(event.target.value)}
          value={recieptNo}
          onPressEnter={() => {
            findRecieptNo(recieptNo);
          }}
          onSearch={(event) => {
            if (event) {
              findRecieptNo(recieptNo);
            }
          }}
          enterButton
          loading={recieptLoading}
        />
      ),
    },
    {
      label: <StyledSoldItem>{SOLD_ITEM}</StyledSoldItem>,
      key: '2',
      onClick: onFindSoldItemClick,
    },
  ];

  const menu = (
    <Menu items={menuItems} className="retail-search-wrapper">
      <Menu.Divider className="custom-divider" />{' '}
    </Menu>
  );
  const onCancelClick = () => {
    setVisible(false);
  };

  const onFindSoldItemCancelClick = () => {
    setSoldItemPopupVisible(false);
  };

  const findRecieptNo = async (recieptNo: string) => {
    if (recieptNo.length > 0) {
      try {
        setRecipetLoading(true);
        const response = await getRecieptDetails(recieptNo);
        if (response?.data) {
          setRecipetLoading(false);
          setRecieptDetails(response?.data);
          setOverlayVisiblity(false);
        }
      } catch (e: any) {
        setRecipetLoading(false);
        setOverlayVisiblity(false);
        openNotificationWithIcon(
          NOTIFICATION_FOR.NOTIFY_FOR,
          NOTIFICATION_TYPE.ERROR
        );
      }
    }
  };

  const openNotificationWithIcon = (notifyFor: string, type: any) => {
    switch (notifyFor) {
      case NOTIFICATION_FOR.NOTIFY_FOR:
        if (type === NOTIFICATION_TYPE.ERROR) {
          notification.error({
            message: ERROR_MESSAGE_ON_RECEIPT_NOT_FOUND.message,
            description: ERROR_MESSAGE_ON_RECEIPT_NOT_FOUND.description,
            duration: 5,
          });
        }
        break;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (recieptDetails.retailTransactionId !== 0) {
      setExchangePopupVisible(true);
    } else {
      setExchangePopupVisible(false);
    }
    setRecieptNo('');
  }, [recieptDetails]);

  const onSearchHandler = async (barcodeValue?: string) => {
    if (barcodeValue) setBarcode(barcodeValue);
    else setBarcode('');
    setShowRows(true);
  };

  const onRefundExchangeCustomerCancelClick = () => {
    setExchangePopupVisible(false);
  };

  const totalBalanceDue: number = rows.reduce((total: number, item: any) => {
    if (item?.status === RETAIL_ITEM_STATUS.TO_RETURN) {
      return total - item.soldValue;
    } else return total + (item.listValue - item.discount);
  }, 0.0);

  useEffect(() => {
    if (rows.length) {
      setBarcode('');
    }
  }, [rows]);

  return (
    <>
      <Row className="retail-main-wrapper">
        <Col span={24}>
          <StyledMainRow>
            <StyledDropdownCol span={18}>
              <Space>
                <HelpPopoverInfo
                  linkedID={'RETAIL_SEARCHBARCODE'}
                  position="rightTop"
                  helpPosition="AFTER"
                  attachedComp="BUTTON"
                >
                  <StyledSearch
                    allowClear
                    className="barcode-search"
                    maxLength={30}
                    width={302}
                    placeholder={BARCODE_PLACEHOLDER}
                    onChange={(event) => {
                      onSearchHandler(event.currentTarget.value);
                    }}
                    value={barcode}
                    onPressEnter={() => {
                      //TODO implement in future
                    }}
                    onSearch={onSearchHandler}
                    enterButton
                    autoFocus
                  />
                </HelpPopoverInfo>

                <>
                  <Row>
                    <StyledTextCol>
                      <StyledDropdownText onClick={onFindItemClick}>
                        {FIND_ITEM}
                      </StyledDropdownText>
                    </StyledTextCol>
                    <Col>
                      <HelpPopoverInfo
                        linkedID={'RETAIL_FINDITEM'}
                        position="rightTop"
                      >
                        <img
                          src={
                            require('../../../assets/images/barcode.svg')
                              .default
                          }
                          alt="barcode-icon"
                          className="barcode-icon"
                          onClick={onFindItemClick}
                        />
                      </HelpPopoverInfo>
                    </Col>
                  </Row>
                  {visible ? (
                    <RetailPopup
                      isRetailPopVisible
                      onRetailPopupCancel={onCancelClick}
                    />
                  ) : null}
                  {soldItemPopupVisible ? (
                    <FindSoldItemPopup
                      isFindSoldItemPopupVisible
                      onFindSoldItemPopupCancel={onFindSoldItemCancelClick}
                    />
                  ) : null}
                  {exchangePopupVisible &&
                  recieptDetails.retailTransactionId !== 0 ? (
                    <RefundExchangePopup
                      refundExchangeCustomer
                      onRefundExchangeCustomerCancelClick={
                        onRefundExchangeCustomerCancelClick
                      }
                      recieptData={recieptDetails}
                    />
                  ) : null}
                </>
              </Space>
            </StyledDropdownCol>

            <StyledDropdownCol span={6}>
              <StyledDropdown
                overlay={menu}
                trigger={['click']}
                open={overlayVisiblity}
                onOpenChange={() => setOverlayVisiblity(!overlayVisiblity)}
              >
                <StyledButton>
                  {REFUND_OPTIONS} <DownOutlined className="anticon-down-img" />
                </StyledButton>
              </StyledDropdown>
              <StyledRowHelp>
                <HelpPopoverInfo
                  linkedID={'RETAIL_REFUNDITEM'}
                  position="rightTop"
                  helpPosition="BEFORE"
                ></HelpPopoverInfo>
              </StyledRowHelp>
            </StyledDropdownCol>
          </StyledMainRow>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <StyledTableWrapper>
            <RetailSaleSummary barcode={showRows ? barcode : ''} />
          </StyledTableWrapper>
        </Col>
      </Row>
      <StyledTotalLoanRow>
        <StyledTotalLoanCol span={24}>
          <Row>
            <Col flex="auto"></Col>
            <StyledCol flex="300px">
              <StyledText>
                {totalBalanceDue >= 0
                  ? RETAIL_BALANCE.TAKE_FROM
                  : RETAIL_BALANCE.PAY_TO}
              </StyledText>
            </StyledCol>
            <StyledColAmountInput flex="200px">
              <StyledInput
                className={
                  totalBalanceDue >= 0 ? 'total-value' : 'total-value danger'
                }
                placeholder="0.00"
                addonBefore="£"
                value={
                  rows?.length
                    ? totalBalanceDue >= 0
                      ? currencyFormat(totalBalanceDue.toFixed(2))
                      : currencyFormat((totalBalanceDue * -1).toFixed(2))
                    : ''
                }
                disabled
              />
            </StyledColAmountInput>
          </Row>
        </StyledTotalLoanCol>
      </StyledTotalLoanRow>
    </>
  );
};

export default ViewRetailSales;
