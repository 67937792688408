import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Layout, notification, Spin } from 'antd';
import { actions } from 'redux/reducers/inventoryManagement';

import HorizontalTabs from 'components/common/HorizontalTabs';
import StockTransfer from 'components/inventoryManagement/StockTransfer';
import AuditManager from 'components/inventoryManagement/AuditManager';
import BarcodeManager from 'components/inventoryManagement/BarcodeManager';
import LayawayManager from 'components/inventoryManagement/LayawayManager';
import { INVENTORY_MANAGEMENT_TABS } from 'components/inventoryManagement/constants';

import './index.less';

const StyledMenuLayout = styled(Layout)`
  &.control-box {
    padding: 0;
  }
`;

const InventoryManagement = () => {
  const { loading, isErrorVisible, isSuccessMsgVisible, successMsg, error } =
    useSelector((state: any) => state.inventoryManagement);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isErrorVisible) {
      notification.error({
        message: error,
        duration: 3,
      });
      dispatch(actions.setIsErrorVisible(false));
    }

    if (isSuccessMsgVisible) {
      notification.success({
        message: successMsg,
        duration: 5,
      });
      dispatch(actions.setIsSuccessMsgVisible(false));
    }
  }, [isErrorVisible, isSuccessMsgVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const [activeMenu, setActiveMenu] = useState<string>(
    INVENTORY_MANAGEMENT_TABS.STOCK_TRANSFER
  );

  const onMenuClick = (clickedMenu: string) => {
    setActiveMenu(clickedMenu);
  };

  return (
    <div className="inventory-management-wrapper">
      <StyledMenuLayout className="control-box">
        <HorizontalTabs
          onMenuClick={onMenuClick}
          initialPanes={INVENTORY_MANAGEMENT_TABS.INITIAL_PANES}
        />
      </StyledMenuLayout>
      <Layout className="split-control-box">
        <Spin spinning={loading}>
          <div className="main-container-wrapper">
            {activeMenu === INVENTORY_MANAGEMENT_TABS.STOCK_TRANSFER && (
              <StockTransfer />
            )}
            {activeMenu === INVENTORY_MANAGEMENT_TABS.AUDIT_MANAGER && (
              <AuditManager />
            )}
            {activeMenu === INVENTORY_MANAGEMENT_TABS.BARCODE_MANAGER && (
              <BarcodeManager />
            )}
            {activeMenu === INVENTORY_MANAGEMENT_TABS.LAYAWAY_MANAGER && (
              <LayawayManager />
            )}
          </div>
        </Spin>
      </Layout>
    </div>
  );
};

export default InventoryManagement;
