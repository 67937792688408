import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { format } from 'date-fns';
import { Layout, Row, Col, Form, Select } from 'antd';
import { SEARCH_AUCTION } from 'action_creators/pledgeManagement';
import { Button } from 'components/common/Button';
import {
  DATE_FORMAT_TWO_DIGIT_YEAR,
  FNS_DATE_FORMAT_YEAR,
} from 'globalConstants';
import {
  AUCTION_MANAGER_TITLE,
  VIEWING_LABEL,
  FROM_DATE_LABEL,
  TO_DATE_LABEL,
  FROM_DATE_REQUIRED_MSG,
  TO_DATE_REQUIRED_MSG,
  AUCTION_VIEWING_OPTIONS,
  BUTTON_LABELS,
} from 'components/pledgemanagement/constants';

import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import FooterButtons from 'components/common/footerButtons';
import AuctionManagerSummary from '../auctionManagerSummary/index';
import DatePickerMUI from 'components/common/DatePickerMUI';

const StyledContentLayout = styled(Layout)`
  background: var(--white);
  padding: 15px 30px;
  border-radius: 5px;
`;

const StyledDateLabel = styled.span`
  font-weight: var(--font-weight-500);
  display: flex;
  padding: 10px 32px;
`;

const StyledButton = styled(Button)`
  margin-left: 20px;
`;

const StyledFilterRow = styled(Row)`
  padding: 15px 10px;
`;

const StyledTableWrapper = styled.div`
  height: calc(100vh - 385px);
`;

const AuctionList = () => {
  const {
    pledgeManagement: { searching, searchAuctionList },
  } = useSelector((state: any) => {
    return {
      pledgeManagement: state.pledgeManagement,
    };
  });

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isDateRequired, setIsDateRequired] = useState<boolean>(false);

  useEffect(() => {
    onSearchClick();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSearchClick = () => {
    const { searchFromDate, searchToDate, viewing } = form.getFieldsValue();
    const params = {
      auctionType: viewing,
      fromDate: searchFromDate
        ? format(new Date(searchFromDate), FNS_DATE_FORMAT_YEAR)
        : '',
      toDate: searchFromDate
        ? format(new Date(searchToDate), FNS_DATE_FORMAT_YEAR)
        : '',
    };
    dispatch({
      type: SEARCH_AUCTION,
      payload: { params },
    });
  };

  const onExitClick = () => {
    navigate('/');
  };

  const checkIfDateRequired = (value: any) => {
    setIsDateRequired(value !== AUCTION_VIEWING_OPTIONS[0].OPTION_VALUE);
  };

  return (
    <>
      <StyledContentLayout>
        <Row className="title-row">
          <Col span={24}>
            <HelpPopoverInfo
              linkedID={''}
              position="rightTop"
              helpPosition="AFTER"
            >
              {AUCTION_MANAGER_TITLE}
            </HelpPopoverInfo>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form
              name="AuctionListForm"
              form={form}
              onFinish={onSearchClick}
              autoComplete="off"
              className="auction-manager"
              initialValues={{
                viewing: 1,
              }}
            >
              <StyledFilterRow>
                <Col span={4}>
                  <Form.Item
                    label={VIEWING_LABEL}
                    name="viewing"
                    className="label-control"
                  >
                    <Select
                      className="input-control"
                      placeholder="Select Viewing"
                      onSelect={checkIfDateRequired}
                    >
                      {AUCTION_VIEWING_OPTIONS.map((item, key) => {
                        return (
                          <Select.Option value={item.OPTION_VALUE} key={key}>
                            {item.LABEL}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <StyledDateLabel>Filter by</StyledDateLabel>
                </Col>
                <Col span={3}>
                  <Form.Item
                    label={FROM_DATE_LABEL}
                    name="searchFromDate"
                    className="label-control"
                    rules={[
                      {
                        required: isDateRequired,
                        message: FROM_DATE_REQUIRED_MSG,
                      },
                    ]}
                  >
                    <DatePickerMUI
                      format={DATE_FORMAT_TWO_DIGIT_YEAR}
                      placeholder={DATE_FORMAT_TWO_DIGIT_YEAR}
                      onChange={(date: any) =>
                        form.setFieldsValue({ searchFromDate: date })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={3} offset={1}>
                  <Form.Item
                    label={TO_DATE_LABEL}
                    name="searchToDate"
                    className="label-control"
                    rules={[
                      {
                        required: isDateRequired,
                        message: TO_DATE_REQUIRED_MSG,
                      },
                    ]}
                  >
                    <DatePickerMUI
                      format={DATE_FORMAT_TWO_DIGIT_YEAR}
                      placeholder={DATE_FORMAT_TWO_DIGIT_YEAR}
                      onChange={(date: any) =>
                        form.setFieldsValue({ searchToDate: date })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <StyledButton
                    loading={searching}
                    itemProp="secondary"
                    htmlType="submit"
                  >
                    {BUTTON_LABELS.SEARCH}
                  </StyledButton>
                </Col>
              </StyledFilterRow>
            </Form>
          </Col>
        </Row>
        <StyledTableWrapper>
          <AuctionManagerSummary auctionManagerList={searchAuctionList} />
        </StyledTableWrapper>
      </StyledContentLayout>
      <FooterButtons
        enableExtraClass
        isExitOnly={true}
        enableExitPopup={false}
        onConfirmExitClick={onExitClick}
      />
    </>
  );
};

export default AuctionList;
