import { PAYMENT_MODES } from 'globalConstants';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const usePaymentMethods = () => {
  const [isBankTransferVisible, setBankTransferVisible] =
    useState<boolean>(false);
  const [isCardModeDisable, setIsCardModeDisable] = useState<boolean>(false);
  const [isBankTransferModeDisable, setIsBankTransferModeDisable] =
    useState<boolean>(false);
  const {
    customer: { customer, isVATDetailsAvailable },
    payment: { totalPayableAmount, paymentSummary },
    basket: { basket },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
      payment: state.payment,
      basket: state.basket,
    };
  });

  useEffect(() => {
    const isBankTransferMode = paymentSummary.some(
      (paymentModeObj: any) =>
        paymentModeObj.mode === PAYMENT_MODES.BANKTRANSFER
    );
    const isCardMode = paymentSummary.some(
      (paymentModeObj: any) => paymentModeObj.mode === PAYMENT_MODES.CARD
    );
    if (isBankTransferMode) {
      setIsCardModeDisable(true);
    } else {
      setIsCardModeDisable(false);
    }
    if (isCardMode) {
      setIsBankTransferModeDisable(true);
    } else {
      setIsBankTransferModeDisable(false);
    }
  }, [paymentSummary]);

  useEffect(() => {
    // service: thirdPartyCheque
    const isBasketHas3PCService = basket?.basketServices?.find((item: any) => {
      return item?.thirdPartyCheque;
    });

    // service: Traval Money - fx
    // Travel money should be a fully registered customer only and only with GBP payout
    let isTravelMoneyWithGBPPayout = true;
    const travelMoneyFX = basket?.basketServices?.find((item: any) => {
      return item?.fx;
    });

    if (travelMoneyFX) {
      isTravelMoneyWithGBPPayout =
        travelMoneyFX?.fx?.request?.toCurrencyCode === 'GBP';
    }

    // Note: Faster payment is not available for green baskets where the customer is paying money to H&T
    if (
      !customer?.customerId ||
      totalPayableAmount > 0 ||
      isBasketHas3PCService ||
      !isTravelMoneyWithGBPPayout ||
      isVATDetailsAvailable
    ) {
      setBankTransferVisible(false);
    } else {
      setBankTransferVisible(true);
    }
  }, [
    basket?.basketServices,
    customer?.customerId,
    isVATDetailsAvailable,
    totalPayableAmount,
  ]);

  // Below flags are available to destructure from this hook
  return {
    isBankTransferVisible,
    isCardModeDisable,
    isBankTransferModeDisable,
  };
};

export default usePaymentMethods;
