import { LOGOFFTIME } from '../constants';
import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import TimeOutLayout from './TimeOutLayout';

function TimeOut(props: any) {
  const { signOut } = props;
  const [sessionTimeOut, setSessionTimeOut] = useState(false);

  const handleOnIdle = (event: any) => {
    console.log('last active', getLastActiveTime());
  };

  const handleOnAction = (event: any) => {
    if (event.type === 'mousedown') {
      if (event.target.innerText === 'Log Off') {
        signOut();
      } else {
        setSessionTimeOut(false);
        sessionStorage.setItem('isTimeOut', '0');
      }
    }
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * LOGOFFTIME,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 100,
  });

  const timeOutInterval = setInterval(() => {
    if (getRemainingTime() === 0 && sessionTimeOut === false) {
      setSessionTimeOut(true);
    }
  }, 1000);

  useEffect(() => () => {
    clearInterval(timeOutInterval);
  });

  return (
    <>
      {sessionTimeOut && (
        <TimeOutLayout signOut={signOut} sessionTimeOut={sessionTimeOut} />
      )}
    </>
  );
}

export default TimeOut;
