import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getItemDetails } from 'services/retail';
import { Row, Col, Spin } from 'antd';

interface SummaryProps {
  rows: object;
  onPopoverHideClick: () => void;
  isDataFetchedFromApi: boolean;
}

const StyledPopoverDiv = styled.div`
  min-width: 320px;
`;

const StyledRow = styled(Row)`
  padding: 5px;
  font-weight: var(--font-weight-500);
`;

const StyledRowItem = styled(Row)`
  padding: 5px;
  overflow-y: auto;
`;

const StyledSpin = styled(Spin)`
  display: block;
  position: relative;
  align-items: center;
  top: calc(100% - 75%);
`;

const SummaryGrid = ({
  rows,
  onPopoverHideClick,
  isDataFetchedFromApi,
}: SummaryProps) => {
  const [itemDetails, setItemDetails] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isDataFetchedFromApi) loadItemDetails(rows);
    else setItemDetails(rows);
  }, [rows]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadItemDetails = async (rows: any) => {
    setLoading(true);
    const response = await getItemDetails(rows);
    if (response?.data) {
      delete response.data['stockItemId'];
      setLoading(false);
      setItemDetails(response.data);
    }
  };
  return loading ? (
    <StyledSpin />
  ) : (
    <StyledPopoverDiv>
      <StyledRow>
        <Col span={22}>{itemDetails?.description}</Col>
        <Col span={2}>
          <img
            className="popover-cross"
            onClick={onPopoverHideClick}
            src={require('../../assets/images/Close.svg').default}
            alt="close"
          />
        </Col>
      </StyledRow>
      <StyledRowItem>
        <Col span={8}>Weight:</Col>
        <Col span={16}>
          {itemDetails.weight > 0 ? itemDetails.weight + 'g' : ''}
        </Col>
      </StyledRowItem>
      <StyledRowItem>
        <Col span={8}>Carat:</Col>
        <Col span={16}>{itemDetails?.fineness}</Col>
      </StyledRowItem>
      {itemDetails?.stones?.length > 0
        ? itemDetails.stones.map((item: any, index: number) => {
            return (
              <StyledRowItem key={index}>
                <Col span={8}>{`Stone Type ${index + 1}:`}</Col>
                <Col span={16}>{`${item.description}`}</Col>
              </StyledRowItem>
            );
          })
        : null}
      {itemDetails?.coins?.length > 0
        ? itemDetails.coins.map((item: any, index: number) => {
            return (
              <StyledRowItem key={index}>
                <Col span={8}>{`Coin Type ${index + 1}:`}</Col>
                <Col span={16}>{`${item.description}`}</Col>
              </StyledRowItem>
            );
          })
        : null}
    </StyledPopoverDiv>
  );
};

export default SummaryGrid;
