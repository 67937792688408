import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { format } from 'date-fns';
import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';
import VirtualGrid from 'components/common/virtualGrid/index';

import { GET_TRANSFER_ITEMS } from 'action_creators/inventoryManagement';

interface TransferManagerSummaryProps {
  transferManagerList: Array<any>;
}

const TableRow = ({
  row,
  onClickTableRow,
  loadTransferDetails,
  ...restProps
}: any) => {
  const onRowClick = () => {
    onClickTableRow(row);
  };

  const onTransferNoClick = () => {
    loadTransferDetails(row);
  };

  const Cell = () => {
    const { column } = restProps;
    if (column.name === 'transferNo') {
      return (
        <Table.Cell
          onClick={onTransferNoClick}
          className="transfer-id-cell"
          {...restProps}
        />
      );
    }
    return <Table.Cell onClick={onRowClick} {...restProps} />;
  };

  return <>{Cell()}</>;
};

const TransferManagerSummary = ({
  transferManagerList = [],
}: TransferManagerSummaryProps) => {
  const dispatch = useDispatch();

  const onSelectionChange = (rowId: any) => { /* do nothing */ };

  const [columns] = useState([
    {
      name: 'creationDate',
      title: 'Creation Date',
      getCellValue: (row: any) =>
        row && row?.creationDate
          ? format(new Date(row?.creationDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'transferNo',
      title: 'Transfer Number',
      getCellValue: (row: any) => row.transferReference,
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row: any) => row.transferStatus,
    },
    {
      name: 'from',
      title: 'From',
      getCellValue: (row: any) => row.fromStore,
    },
    {
      name: 'direction',
      title: 'Direction',
      getCellValue: (row: any) => row.direction,
    },
    {
      name: 'to',
      title: 'To',
      getCellValue: (row: any) => (row.toStore ? row.toStore : 'NA'),
    },
    {
      name: 'noOfItems',
      title: 'No of Items',
      getCellValue: (row: any) => row.totalItems,
    },
    {
      name: 'cost',
      title: 'Cost',
      type: 'currency',
      getCellValue: (row: any) => row?.loanValue,
    },
    {
      name: 'sentDate',
      title: 'Sent Date',
      getCellValue: (row: any) =>
        row && row?.sentDate
          ? format(new Date(row?.sentDate), FNS_DATE_FORMAT)
          : null,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'creationDate', width: '12%' },
    { columnName: 'transferNo', width: '12%' },
    { columnName: 'status', width: '13%' },
    { columnName: 'from', width: '14%' },
    { columnName: 'direction', width: '12%' },
    { columnName: 'to', width: '11%' },
    { columnName: 'noOfItems', width: '7%', align: 'center' },
    { columnName: 'cost', width: '8%', align: 'right' },
    { columnName: 'sentDate', width: '11%', align: 'right' },
  ]);

  const [formattedColumns] = useState(['delete', 'cost']);

  const onClickTableRow = () => { /* do nothing */ };

  const loadTransferDetails = (row: any) => {
    dispatch({
      type: GET_TRANSFER_ITEMS,
      payload: {
        direction: row.direction,
        transferId: row.transferId,
      },
    });
  };

  return (
    <div key="transferManagerSummary">
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        formattedColumns={formattedColumns}
        rowData={transferManagerList}
        cellComponent={(restProps: any) => (
          <TableRow
            onClickTableRow={onClickTableRow}
            loadTransferDetails={loadTransferDetails}
            {...restProps}
          />
        )}
        rowSelectionEnable={true}
        showSelectionColumn={false}
        showSelectAll={false}
        onSelectionChange={onSelectionChange}
        selectByRowClick={true}
        highlightRow={true}
      />
    </div>
  );
};

export default TransferManagerSummary;
