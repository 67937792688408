import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Modal, Row } from 'antd';
import styled from 'styled-components';
import { CALCULATE_PLEDGE_AGREEMENT_LOAN_AMOUNT } from 'action_creators/pickupRenew';
import { Button } from 'components/common/Button';
import { WITHDRAW, AGREEMENT_OPTIONS } from './constants';

const StyledModal = styled(Modal)`
  & .ant-modal-title {
    text-align: center;
  }

  & .ant-modal-body {
    text-align: center;
  }
`;

const StyledColOne = styled(Col)<{ fontSize: string; paddingtop: string }>`
  font-size: var(--${(props) => props.fontSize});
  font-weight: var(--font-weight-500);
  padding-top: ${(props) => props.paddingtop};
`;

const StyledColTwo = styled(Col)`
  padding: 20px;
`;

interface WithdrawPopupProps {
  visible: boolean;
  setWithdrawPopupVisible: () => void;
  row: any;
}

const WithdrawPopup = ({
  visible,
  setWithdrawPopupVisible,
  row,
}: WithdrawPopupProps) => {
  const dispatch = useDispatch();

  const onProceedClick = () => {
    try {
      const updatedRow = {
        ...row,
        selectedActionType: AGREEMENT_OPTIONS[4].id,
        withdraw: {
          ...row.withdraw,
          notificationType: 'In Person',
        },
      };
      const params = {
        agreement: updatedRow,
      };
      dispatch({
        type: CALCULATE_PLEDGE_AGREEMENT_LOAN_AMOUNT,
        payload: { params },
      });
      setWithdrawPopupVisible();
    } catch (errorInfo) {
      console.log(errorInfo);
    }
  };

  return (
    <>
      <StyledModal
        title={WITHDRAW.TITLE}
        open={visible}
        width={500}
        destroyOnClose={true}
        maskClosable={false}
        closable={false}
        footer={[
          <Row gutter={24} justify="center" key="withdrawPopup">
            <Col>
              <Button
                itemProp="secondary"
                key="withdrawNow"
                htmlType="submit"
                onClick={onProceedClick}
              >
                Proceed
              </Button>
            </Col>
          </Row>,
        ]}
        centered
      >
        <Row>
          <StyledColOne
            span={24}
            fontSize="font-size-14"
            paddingtop="0px"
          >{`${WITHDRAW.METHOD}: ${WITHDRAW.IN_PERSON}`}</StyledColOne>
        </Row>
        <Row>
          <StyledColTwo
            span={24}
          >{`${WITHDRAW.RIGHT_TO_WITHDRAW}`}</StyledColTwo>
        </Row>
      </StyledModal>
    </>
  );
};

export default WithdrawPopup;
