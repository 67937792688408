import React, { useEffect, useState } from 'react';
import { Input } from 'antd';

const InputDecimal = (props: any) => {
  const {
    onChange,
    onBlur,
    placeholder,
    addonBefore,
    numberofdecimalrequired = 2,
  } = props;
  const [decimalValue, setDecimalValue] = useState<any>('');

  useEffect(() => {
    setDecimalValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (props.value !== undefined) {
      setDecimalValue(props.value);
    }
  }, [props.value]);

  const fieldPlaceholder =
    placeholder === undefined ? '0.00' : placeholder ? placeholder : '';

  const fieldAddonBefore =
    addonBefore === undefined ? '£' : addonBefore ? addonBefore : '';

  const inputHandler = (event: any) => {
    const val = event.target.value;

    //allowing only 2 numeric after decimal and 9 before it
    const patternStr =
      `(\\d{0,9})[^.]*((?:\\.\\d{0,` + numberofdecimalrequired + `})?)`;
    const decimalPattern = new RegExp(patternStr, 'g');
    const match = decimalPattern.exec(val);

    const value =
      match !== undefined && match !== null && match.length > 0
        ? match[1] + match[2]
        : 0;

    setDecimalValue(value);
    event.target.value = value;

    //firing onChange if any
    onChange && onChange(event);
  };

  const twoDecimalConvertor = (event: any) => {
    if (decimalValue === '') return;

    const twoDecimalValue = Number(decimalValue).toFixed(
      numberofdecimalrequired
    );
    if (Number.isNaN(+twoDecimalValue)) {
      setDecimalValue('');
      event.target.value = '';
    } else {
      setDecimalValue(twoDecimalValue);
      event.target.value = twoDecimalValue;
    }

    //firing onChange and onBlur if any
    onChange && onChange(event);
    onBlur && onBlur(event);
  };

  const restrictionHandler = (event: any) => {
    //restricting all the input values except numeric and decimal(.)
    if (!/[0-9.]/.test(event.key)) event.preventDefault();
  };

  return (
    <Input
      {...props}
      type={props?.type ? props.type : 'text'}
      autoComplete="off"
      value={decimalValue}
      addonBefore={fieldAddonBefore}
      placeholder={fieldPlaceholder}
      onChange={inputHandler}
      onBlur={twoDecimalConvertor}
      onKeyPress={restrictionHandler}
    />
  );
};

export default InputDecimal;
