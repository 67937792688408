import React from 'react';
import { Modal, Row, Col } from 'antd';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import { GDPR_INFO } from './constants';

const StyledModal = styled(Modal)`
  font-size: var(--font-size-16);
  text-align: center;
  & .ant-modal-header {
    padding: 27px 30px;
    text-align: center;
    background: var(--red);
    & .ant-modal-title {
      color: var(--white);
    }
  }
`;

const StyledColOne = styled(Col)`
  padding: 20px 40px;
`;

interface GdprProps {
  visible: boolean;
  setGdprDeclineVisible: (visible: boolean) => void;
  resetSearch: () => void;
}

const GdprDeclinePopup = ({
  visible,
  setGdprDeclineVisible,
  resetSearch,
}: GdprProps) => {
  const onOkClick = () => {
    setGdprDeclineVisible(false);
    resetSearch();
  };

  return (
    <StyledModal
      title="GDPR Declined!"
      open={visible}
      closable={false}
      width={500}
      onOk={onOkClick}
      centered
      maskClosable={false}
      footer={[
        <Button
          itemProp="secondary"
          key="privacyNotice"
          style={{ float: 'left' }}
          onClick={() => { /* do nothing */ }}
        >
          {GDPR_INFO.PRINT_PRIVACY_NOTICE}
        </Button>,

        <Button key="submit" onClick={onOkClick}>
          OK
        </Button>,
      ]}
    >
      <Row>
        <StyledColOne span={24}>{GDPR_INFO.GDPR_DECLINE_MESSAGE}</StyledColOne>
      </Row>
    </StyledModal>
  );
};

export default GdprDeclinePopup;
