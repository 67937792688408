import React, { useEffect, useState } from 'react';
import VirtualGrid from 'components/common/virtualGrid/index';
import { URL_TYPE } from 'globalConstants';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import ImageLoader from 'components/common/ImageLoader';
import { currencyFormat } from 'utils/util';

interface RetailSalesProps {
  parentCallback: any;
  onRowChange: (row: object) => void;
}

const StyledSpan = styled.span`
  font-weight: var(--font-weight-500);
`;

const StyledRedSpan = styled.span`
  font-weight: var(--font-weight-500);
  color: var(--red);
`;

const RetailSaleSummaryPopup = ({ onRowChange }: RetailSalesProps) => {
  ///////////////////////////States & Logics////////////////////////////////////

  const { retailItems } = useSelector((state: any) => state.retail);

  const [isComponentMounted, setIsComponentMounted] = useState(false);
  useEffect(() => {
    setIsComponentMounted(true);
  }, []);

  const itemDescription = (row: any) => {
    return {
      title: row.description,
      anchor: row.description,
      placement: 'bottom',
      itemId: row.stockItemId,
      data: {},
    };
  };

  const [columns] = useState([
    {
      name: ' ',
      title: ' ',
      type: 'radio',
      getCellValue: (row: any) => row.stockItemId,
      onClick: (row: any) => {
        onRowChange(row);
      },
      onChange: (row: object) => {
        onRowChange(row);
      },
    },
    {
      name: 'image',
      title: 'Image',
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row?.stockItemId}`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
      allowSorting: false,
    },
    {
      name: 'inventoryType',
      title: 'Inventory Type',
      getCellValue: (row: any) => row.inventoryType,
    },
    {
      name: 'barcode',
      title: 'Barcode',
      getCellValue: (row: any) => row.barcode,
    },

    {
      name: 'description',
      title: 'Description',
      type: 'popover',
      getCellValue: (row: any) => itemDescription(row),
    },
    {
      name: 'location',
      title: 'Location',
      allowSorting: true,
      getCellValue: (row: any) => {
        if (row?.location?.toLowerCase().trim() === 'local (layaway)') {
          return <StyledRedSpan>{row?.location}</StyledRedSpan>;
        } else {
          return <StyledSpan>{row?.location}</StyledSpan>;
        }
      },
    },
    {
      name: 'metalType',
      title: 'Metal Type',
      getCellValue: (row: any) => row.metalType,
    },
    {
      name: 'fineness',
      title: 'Fineness',
      getCellValue: (row: any) => row.fineness,
    },
    {
      name: 'weight',
      title: 'Weight ',
      getCellValue: (row: any) => row?.weight?.toFixed(2) + 'g',
    },

    {
      name: 'currentPrice',
      title: 'Current Price',
      getCellValue: (row: any) =>
        '£' + currencyFormat(row?.listValue?.toFixed(2)),
    },
    {
      name: '',
      title: '',
      type: 'popover',
      getCellValue: (row: any) => {
        return {
          title: ' Item History',
          anchor: 'Item History',
          placement: 'left',
          itemId: row.stockItemId,
          data: [],
        };
      },
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: ' ', width: 55 },
    { columnName: 'image', width: 100 },
    { columnName: 'inventoryType' },
    { columnName: 'barcode', width: 130 },
    { columnName: 'description', width: 190 },
    { columnName: 'location' },
    { columnName: 'metalType' },
    { columnName: 'fineness', width: 80, align: 'right' },
    { columnName: 'weight', width: 90, align: 'right' },
    { columnName: 'currentPrice', width: 130, align: 'right' },
    { columnName: '' },
  ]);
  const [formattedColumns] = useState([' ', '', 'description']);
  const [sortingStateColumnExtensions] = useState([
    { columnName: 'location', sortingEnabled: true },
  ]);

  //////////////////////////////////////////////////////

  ///////////////////RENDERING///////////////////////////

  const renderVirtualGrid = () => {
    //Below code has been added to make sure
    //VirtualGrid should not render till RetailSaleSummaryPopup is ready with all the internal states are all good to go.
    //This fixes the rending issue with Virtual Grid when launched in pop-up
    return isComponentMounted ? (
      <VirtualGrid
        formattedColumns={formattedColumns}
        urlType={URL_TYPE.NO_URL}
        virtualScroll={true}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        sortingStateColumnExtensions={sortingStateColumnExtensions}
        rowData={retailItems}
      />
    ) : null;
  };

  //////////////////////////////////////////////////////

  return <>{renderVirtualGrid()}</>;
};

export default RetailSaleSummaryPopup;
