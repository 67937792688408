import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

const StyledHeader = styled.div`
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-18);
  line-height: 21px;
  margin-bottom: 5px;
`;

const ValuationHeader = ({ title = '' }) => {
  return (
    <Row>
      <Col span={24}>
        <StyledHeader>{title}</StyledHeader>
      </Col>
    </Row>
  );
};

export default ValuationHeader;
