import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Modal, Row, Col, Space } from 'antd';
import { Button } from 'components/common/Button';
import Webcam, { IMAGE_CATEGORY } from './WebcamComponent';
import { BUTTON_LABELS } from './constant';
import './WebcamItemPhoto.less';

const StyledModal = styled(Modal)`
  & .ant-modal-body {
    padding-top: 20px;
  }
`;
const LeftAlignedCol = styled(Col)`
  text-align: left;
`;

const WebcamItemPhoto = ({
  isOpen,
  loading,
  onImageUploadClick,
  onCancel,
}: any) => {
  const [itemPhoto, setItemPhoto] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    if (!loading) {
      isUploading && onCancel();
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const getItemPhoto = useCallback((data: any) => {
    setItemPhoto(data);
  }, []);

  const onAddClick = () => {
    onImageUploadClick(itemPhoto);
    setIsUploading(true);
  };

  return (
    <StyledModal
      open={isOpen}
      title="Add Item Photo"
      closable={false}
      centered
      width={900}
      maskClosable={false}
      onCancel={onCancel}
      footer={[
        <Row key="addItemPhotoFooter">
          <LeftAlignedCol span={12}>
            <Space align="start" size="middle">
              <Button itemProp="secondary" key="back" onClick={onCancel}>
                {BUTTON_LABELS.CANCEL}
              </Button>
            </Space>
          </LeftAlignedCol>
          <Col span={12}>
            <Space size="middle">
              <Button key="submit" loading={loading} onClick={onAddClick}>
                {BUTTON_LABELS.ADD_PHOTO}
              </Button>
            </Space>
          </Col>
        </Row>,
      ]}
    >
      <Row gutter={16} className="photo-container">
        <Col span={24}>
          <Webcam
            isRetake={true}
            type={IMAGE_CATEGORY.ITEM_PHOTO}
            width={450}
            height={350}
            getItemPhoto={getItemPhoto}
          />
        </Col>
      </Row>
    </StyledModal>
  );
};

export default WebcamItemPhoto;
