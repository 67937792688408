import React, { useState } from 'react';
import { GRID_NO_URL } from 'globalConstants';
import VirtualGrid from 'components/common/virtualGrid/index';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { GET_AUCTION_DETAILS } from 'action_creators/pledgeManagement';
import { useDispatch } from 'react-redux';
import { actions } from 'redux/reducers/pledgeManagement';

interface AuctionManagerSummaryProps {
  auctionManagerList: Array<any>;
}

const TableRow = ({ row, loadAuctionDetails, ...restProps }: any) => {
  const onRowClick = () => {
    loadAuctionDetails(row);
  };
  const Cell = () => {
    return <Table.Cell onClick={onRowClick} {...restProps} />;
  };

  return <>{Cell()}</>;
};

const AuctionManagerSummary = ({
  auctionManagerList = [],
}: AuctionManagerSummaryProps) => {
  const [columns] = useState([
    {
      name: 'auctionDate',
      title: 'Auction Date',
      getCellValue: (row: any) => row?.auctionDate,
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row: any) => row?.auctionStatusText,
    },
    {
      name: 'bookDate',
      title: 'Book Date',
      getCellValue: (row: any) => row?.bookDate,
    },
    {
      name: 'collectionDate',
      title: 'Collection Date',
      getCellValue: (row: any) => row?.collectionDate,
    },
    {
      name: 'lastMailDate',
      title: 'Last Mail Sent',
      getCellValue: (row: any) => row?.lastMailDate,
    },
    {
      name: 'numberOfLots',
      title: 'No of Lots',
      getCellValue: (row: any) => row?.numberOfLots,
    },
    {
      name: 'reserveValue',
      title: 'Reserve Value',
      getCellValue: (row: any) => row?.reserveValue,
    },
    {
      name: 'returnDate',
      title: 'Return Date',
      getCellValue: (row: any) => row?.returnDate,
    },
    {
      name: 'auctionSoldValue',
      title: 'Sold Value',
      getCellValue: (row: any) => row?.auctionSoldValue,
    },
    {
      name: 'lotsSold',
      title: 'Lots Sold',
      getCellValue: (row: any) => row?.lotsSold,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'auctionDate', width: '11%' },
    { columnName: 'status', width: '11%' },
    { columnName: 'bookDate', width: '11%' },
    { columnName: 'collectionDate', width: '11%' },
    { columnName: 'lastMailDate', width: '11%' },
    { columnName: 'numberOfLots', width: '9%' },
    { columnName: 'reserveValue', width: '9%' },
    { columnName: 'returnDate', width: '10%' },
    { columnName: 'auctionSoldValue', width: '8%' },
    { columnName: 'lotsSold', width: '8%' },
  ]);

  const getRowId = (row: any) => row?.auctionId;

  const onSelectionChange = (rowId: any) => { /* do nothing */ };
  const dispatch = useDispatch();

  const loadAuctionDetails = (row: any) => {
    dispatch(actions.initLoading(true));
    dispatch({
      type: GET_AUCTION_DETAILS,
      payload: {
        auctionId: row?.auctionId,
        auctionStatus: row?.auctionStatus,
      },
    });
  };

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={auctionManagerList}
        cellComponent={(restProps: any) => (
          <TableRow loadAuctionDetails={loadAuctionDetails} {...restProps} />
        )}
        rowSelectionEnable={true}
        onSelectionChange={onSelectionChange}
        showSelectAll={false}
        showSelectionColumn={false}
        selectByRowClick={true}
        highlightRow={true}
        getRowId={getRowId}
      />
    </>
  );
};

export default AuctionManagerSummary;
