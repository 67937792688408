import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Checkbox } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import ImageLoader from 'components/common/ImageLoader';

interface SingleImageProps {
  viewOnly: boolean;
  columnSpan: number;
  imageKey: number;
  imageDetails: any;
  onDeleteIconClick: (index: number) => void;
  onLabelChange?: (event: any) => void;
  onCheckboxChange?: (event: any) => void;
}

export const SingleImage = ({
  viewOnly,
  columnSpan,
  imageKey,
  imageDetails,
  onDeleteIconClick,
  onLabelChange,
  onCheckboxChange,
}: SingleImageProps) => {
  const imageUrl = '/api/items/image/stock-image-temp';

  const [label, setLabel] = useState<string>('');

  useEffect(() => {
    if (imageDetails?.label != null) setLabel(imageDetails?.label);
  }, [imageDetails]);

  const onInputChange = (event: any) => {
    const { value } = event.target;
    setLabel(value);
  };

  return (
    <Col span={columnSpan} key={`${imageKey}_col`}>
      <div className={`single-image-wrapper ${viewOnly && 'view-only'}`}>
        <Row>
          <Col span={24}>
            <div className="item-photo-wrapper">
              <div className="item-photo-div">
                {!viewOnly && (
                  <div className="delete-icon">
                    <DeleteOutlined
                      onClick={() => onDeleteIconClick(imageKey)}
                    />
                  </div>
                )}
                {!viewOnly && (
                  <div className="checkbox">
                    <Checkbox
                      id={`${imageKey}`}
                      checked={imageDetails?.isDefault || false}
                      onChange={onCheckboxChange}
                    />
                  </div>
                )}
                <ImageLoader
                  preview
                  imageUrl={`${imageUrl}/${imageDetails?.blobId}`}
                  fallbackImg={'no_image_thumbnail.svg'}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Input
              disabled={viewOnly}
              id={`${imageKey}_input`}
              className="styled-input"
              value={label}
              onChange={onInputChange}
              onBlur={onLabelChange}
            />
          </Col>
        </Row>
      </div>
    </Col>
  );
};
