import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  isUpdating: false, // scondary loading variable for spinner
  searching: false, //loading status for for search button
  isErrorVisible: false,
  error: '',
  successMsg: '',

  searchAuctionList: [] as Array<any>,
  auctionsOverSeventyFiveList: [] as Array<any>,
  pfiUnderSeventyFiveList: [] as Array<any>,
  auctionItemDetails: {},
  confirmedCollection: {},
  pledgeItemsList: [] as Array<any>,
  newPledgeItemsList: [] as Array<any>,
  pledgeItemDetails: {},
  fetchBarCodeStatus: false,
  isActivePfiReview: false,
  selectedLoanNumber: '',
  selectedPFIServiceType: '',
  parentItemId: {},
  itemCount: '',
  loanDetails: {},
  purchaseLoanDetails: {},
  tabsVisible: true,
  confirmSettlement: {},
  successSettlement: {},
  updateSoldValue: {},
  printPassList: {},
  confirmItemDetails: [] as Array<any>,
  pledgeInReview: 0,
  auctionItemsValue: {},
  selectedItemId: '',
  successPassAuctionLot: false,
  successLockAuction: {},
  isAuctionLocked: false,
  failedPfiBarcodeList: [] as Array<any>,
  newPfiBarcodeList: [] as Array<any>,
  totalWeight: '',
  pfiStatus: false,
  confirmedItems: [] as Array<any>,
  generatedLotNumber: {},
  totalLoanWeight: 0,
  pfaStatus: false,

  pledgeNoticeType: '',
  isPledgeNoticePopupVisible: false,
  isPledgeNotesPopupVisible: false,
  vulnerablePledgesList: [],
  confiscationList: [],
  selectedVulnerableAgreement: [],
  isPledgeReleasePopupVisible: false,
  isNoticeButtonEnable: false, //To enable or disable vulnerable & confiscations button on customer summary
};

const slice = createSlice({
  name: 'pledgeManagementActions',
  initialState,
  reducers: {
    initLoading(state, action) {
      state.loading = action.payload;
    },
    initUpdate(state, action) {
      state.isUpdating = action.payload;
    },
    initSearch(state, action) {
      state.searching = action.payload;
    },
    setAuctionSearchList(state, action) {
      state.searchAuctionList = action.payload;
    },
    setAuctionItemDetails(state, action) {
      state.auctionItemDetails = action.payload;
    },
    setAuctionLoanItemDetails(state, action) {
      state.auctionItemDetails = action.payload;
    },
    setAuctionsOverSeventyFive(state, action) {
      state.auctionsOverSeventyFiveList = action.payload;
    },
    setPfiUnderSeventyFive(state, action) {
      state.pfiUnderSeventyFiveList = action.payload;
    },
    setConfirmedCollection(state, action) {
      state.confirmedCollection = action.payload;
      state.successMsg = action.payload.message;
    },
    setActivePfiReview(state, action) {
      state.isActivePfiReview = action.payload;
    },

    setPledgeItemsList(state, action) {
      state.pledgeItemsList = action.payload;
    },

    setNewPledgeItemsList(state, action) {
      state.newPledgeItemsList.push(action.payload);
    },
    setPledgeItemDetails(state, action) {
      state.pledgeItemDetails = action.payload;
    },
    setFetchBarCodeStatus(state, action) {
      state.fetchBarCodeStatus = action.payload;
    },

    setSelectedLoanNumber(state, action) {
      state.selectedLoanNumber = action.payload;
    },
    setSelectedPFIServiceType(state, action) {
      state.selectedPFIServiceType = action.payload;
    },
    setParentItemId(state, action) {
      state.parentItemId = action.payload;
    },
    setFailedPfiBarcodeList(state, action) {
      if (
        action?.payload?.obj?.id &&
        state.failedPfiBarcodeList.indexOf(action.payload.obj.id) === -1
      ) {
        state.failedPfiBarcodeList.push(action.payload.obj.id);
      }
    },
    setNewPfiBarcodeList(state, action) {
      state.newPfiBarcodeList.push(action.payload);
    },
    setTabsVisible(state, action) {
      state.tabsVisible = action.payload;
    },
    setItemCount(state, action) {
      state.itemCount = action.payload;
    },
    setTotalWeight(state, action) {
      state.totalWeight = action.payload;
    },

    setLoanDetails(state, action) {
      state.loanDetails = action.payload;
    },
    setPurchaseLoanDetails(state, action) {
      state.purchaseLoanDetails = action.payload;
    },
    setConfirmSettlement(state, action) {
      state.confirmSettlement = action.payload;
    },
    setSuccessSettlement(state, action) {
      state.successSettlement = action.payload;
      state.successMsg = action.payload.message;
    },
    setUpdateSoldValue(state, action) {
      state.updateSoldValue = action.payload;
      state.successMsg = action.payload.message;
    },
    setPrintPassList(state, action) {
      state.printPassList = action.payload;
    },
    setConfirmItemsDetails(state, action) {
      if (
        action?.payload?.itemReview.itemId &&
        state.confirmedItems.indexOf(action.payload?.itemReview?.itemId) === -1
      ) {
        state.confirmedItems.push(action.payload?.itemReview?.itemId);
        state.confirmItemDetails.push(action.payload?.itemReview);
        state.totalLoanWeight += Number(action.payload?.itemReview?.weight);
      }
    },
    setSelectedItemId(state, action) {
      state.selectedItemId = action.payload;
    },
    itemPriceValuationResponse(state, action) {
      state.auctionItemsValue = action.payload;
    },
    setPledgeInReview(state, action) {
      state.pledgeInReview = action.payload;
    },
    logError(state, action) {
      state.error = action.payload;
    },
    logSuccess(state, action) {
      state.successMsg = action.payload;
    },
    setIsErrorVisible(state, action) {
      state.isErrorVisible = action.payload;
    },
    setSuccessPassAuctionLot(state, action) {
      state.successPassAuctionLot = action.payload;
    },
    setSuccessLockAuction(state, action) {
      state.successLockAuction = action.payload;
      state.successMsg = action.payload.message;
    },
    setIsAuctionLocked(state, action) {
      state.isAuctionLocked = action.payload;
    },
    setPfiStatus(state, action) {
      state.pfiStatus = action.payload;
    },
    setGeneratedLotNumber(state, action) {
      state.generatedLotNumber = action.payload;
    },
    setTotalLoanWeight(state, action) {
      state.totalLoanWeight = action.payload;
    },
    setPfaStatus(state, action) {
      state.pfaStatus = action.payload;
    },
    resetPledgeItemDetails(state) {
      state.pledgeItemDetails = {};
      state.auctionItemsValue = {};
    },
    clearPFA(state) {
      state.auctionsOverSeventyFiveList = [];
      state.pfiUnderSeventyFiveList = [];
      state.pledgeItemsList = [];
      state.pledgeItemDetails = {};
      state.selectedLoanNumber = '';
      state.parentItemId = {};
      state.itemCount = '';
      state.loanDetails = {};
      state.tabsVisible = true;
      state.confirmItemDetails = [];
      state.pledgeInReview = 0;
      state.auctionItemsValue = {};
      state.selectedItemId = '';
      state.confirmedItems = [];
      state.generatedLotNumber = {};
      state.totalLoanWeight = 0;
      state.pfaStatus = false;
      state.failedPfiBarcodeList = [];
      state.newPledgeItemsList = [];
      state.fetchBarCodeStatus = false;
    },
    clearAuctions() {
      return {
        ...initialState,
      };
    },

    //confiscation and vulnerable Pledge actions
    setPledgeNoticeType(state, action) {
      state.pledgeNoticeType = action.payload;
    },
    setIsPledgeNoticePopupVisible(state, action) {
      state.isPledgeNoticePopupVisible = action.payload;
    },
    setIsPledgeNotesPopupVisible(state, action) {
      state.isPledgeNotesPopupVisible = action.payload;
    },
    setVulnerablePledgesList(state, action) {
      state.vulnerablePledgesList = action.payload;
    },
    setConfiscationList(state, action) {
      state.confiscationList = action.payload;
    },
    setSelectedVulnerableAgreement(state, action) {
      state.selectedVulnerableAgreement = action.payload;
    },
    setIsPledgeReleasePopupVisible(state, action) {
      state.isPledgeReleasePopupVisible = action.payload;
    },
    setIsNoticeButtonEnable(state, action) {
      state.isNoticeButtonEnable = action.payload;
    },
  },
});

export default slice.reducer;
export const { reducer, actions } = slice;
export { initialState };
