import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import PartPaymentSummaryTable from './PartPaymentSummaryTable';
import { PART_PAY_HEADING, PART_PAY_HEADING_SECOND } from '../constants';

const StyledModal = styled(Modal)`
  & .ant-modal-title {
    text-align: left;
  }
`;

const StyledDiv = styled.div`
  margin-top: -13px;
  padding-bottom: 12px;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-500);
`;

const PartPaymentPopup = ({
  visible,
  setFAQPopupVisible,
  customerToImport,
}: any) => {
  const onCancelClick = () => {
    setFAQPopupVisible();
  };

  return (
    <>
      <StyledModal
        title={PART_PAY_HEADING}
        open={visible}
        width={997}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        footer={null}
        closable={true}
        className={'partpay-modal-wrap'}
        centered
      >
        <StyledDiv> {PART_PAY_HEADING_SECOND} </StyledDiv>
        <div className="partpay-grid-wrapper">
          <PartPaymentSummaryTable customerToImport={customerToImport} />
        </div>
      </StyledModal>
    </>
  );
};

export default PartPaymentPopup;
