import React, { useState } from 'react';
import styled from 'styled-components';
import { Menu } from 'antd';

const StyledMenu = styled(Menu)<{
  $isBlueTheme: boolean;
  $isBorderRequired: boolean;
}>`
  &.inventory-management-custom-menu {
    font-weight: 700;
    color: ${(props) => (props.$isBlueTheme ? 'var(--white)' : 'var(--black)')};
    border-radius: 5px;
    line-height: 52px;
    background: ${(props) =>
      props.$isBlueTheme
        ? 'linear-gradient(180deg,#53bcff 0%,#077ce9 100%) !important'
        : 'var(--white)'};

    li.ant-menu-item {
      padding: 0 10px;
      margin: 0;
      width: 180px;
      text-align: center;
      top: 0px;

      border-left: ${(props) =>
        props.$isBorderRequired ? '2px solid white' : ''};

      &:first-child {
        border-left: 0;
      }
    }
    .ant-menu-item:hover {
      color: ${(props) =>
        props.$isBlueTheme ? 'var(--white)' : 'var(--black)'};
      border-bottom: ${(props) =>
        props.$isBlueTheme ? '2px solid var(--white)' : ''};
    }
    .ant-menu-item-selected {
      &.ant-menu-item-active {
        color: ${(props) =>
          props.$isBlueTheme ? 'var(--blue)' : 'var(--white)'};
      }
      color: ${(props) =>
        props.$isBlueTheme ? 'var(--blue)' : 'var(--white)'};
      background: ${(props) =>
        props.$isBlueTheme ? 'var(--white)' : 'var(--blue)'};
      border-bottom: ${(props) =>
        props.$isBlueTheme
          ? '2px solid var(--white)'
          : '2px solid var(--blue)'};
    }
    .ant-menu-item-selected::after {
      border-bottom: ${(props) =>
        props.$isBlueTheme
          ? '2px solid var(--white)'
          : '2px solid var(--blue)'};
    }
  }
`;

interface HorizontalTabsProps {
  onMenuClick: (clickedMenu: string) => void;
  initialPanes: Array<any>;
  activeKey?: string;
  isBlueTheme?: boolean;
  isBorderRequired?: boolean;
}

const HorizontalTabs = ({
  onMenuClick = () => { /* do nothing */ },
  initialPanes = [],
  activeKey = '',
  isBlueTheme = false,
  isBorderRequired = false,
}: HorizontalTabsProps) => {
  const [key, setActiveKey] = useState(activeKey || initialPanes[0].title);

  const handleTabChange = (event: any) => {
    setActiveKey(event.key);
    onMenuClick(event.key);
  };
  return (
    <>
      <StyledMenu
        selectedKeys={[key]}
        className="inventory-management-custom-menu"
        mode="horizontal"
        onClick={handleTabChange}
        $isBlueTheme={isBlueTheme}
        $isBorderRequired={isBorderRequired}
        items={initialPanes.map((pane) => ({
          key: pane.title,
          label: pane.title,
          style: { width: pane.width },
        }))}
      ></StyledMenu>
    </>
  );
};

export default HorizontalTabs;
