import React, { useState } from 'react';
import VirtualGrid from '../../common/virtualGrid';
import { GRID_NO_URL } from 'globalConstants';
import TableRow from '../formatedCell';
import '../index.less';

const CheckoutSummaryRow = (props: any) => {
  const rows = props.summaryData || [];

  const [columns] = useState([
    {
      //insert a blank column to create space
      name: 'togglecolumn',
      title: ' ',
      getCellValue: () => '',
    },
    {
      //insert a blank column to create space before To Pay
      name: 'payLabel',
      title: ' ',
      getCellValue: (row: any) => `${row?.payLabel} subtotal value`,
    },
    {
      name: 'checkoutToPay',
      title: '',
      type: 'currency',
      getCellValue: (row: any) => Math.abs(row?.toPay),
    },
    {
      name: 'checkoutToTake',
      title: '',
      type: 'currency',
      getCellValue: (row: any) => Math.abs(row?.toTake),
    },
    {
      name: 'blank',
      title: '',
      getCellValue: (row: any) => '',
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'togglecolumn', width: '48px', align: 'right' },
    { columnName: 'payLabel', width: '70%', align: 'right' },
    { columnName: 'checkoutToPay', width: '10%', align: 'right' },
    { columnName: 'checkoutToTake', width: '10%', align: 'right' },
    { columnName: 'blank', width: '6%', align: 'right' },
  ]);

  const [formattedColumns] = useState([
    'toPay',
    'toTake',
    'checkoutToPay',
    'checkoutToTake',
  ]);

  return (
    <>
      <div className="summary-row">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          formattedColumns={formattedColumns}
          rowData={rows}
          tableColumnExtensionsProps={tableColumnExtensions}
          cellComponent={TableRow}
        />
      </div>
    </>
  );
};

export default CheckoutSummaryRow;
