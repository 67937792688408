import { call, put, takeEvery } from 'redux-saga/effects';
import {
  AUTHORIZE_VOID_TRANSACTION,
  GET_VOID_TRANSACTIONS,
  ADMIN_STORE_USERS,
  ADMIN_USER_DETAILS,
  ADMIN_UPDATE_USER_DETAILS,
  GET_TRAVEL_MONEY_RATES,
  GET_TRAVEL_MONEY_RATES_HISTORY,
  CREATE_TRAVEL_MONEY_RATES,
  ADMIN_GET_USER_PERMISSIONS,
  ADMIN_GET_DEFAULT_PERMISSIONS,
  SAVE_USER_PERMISSIONS,
  GET_GROUP_PERMISSIONS,
} from 'action_creators/admin';

import {
  postVoidTransactions,
  getVoidTransactions,
  getUserDetails,
  updateUserDetails,
  getUserPermissions,
  getGroupPermissions,
  getDefaultPermissions,
  postSaveUserPermissions,
  getTravelMoneyRates,
  getTravelMoneyRatesHistory,
  createTravelMoneyRates,
  getStoreUsers,
} from 'services/admin';

import { actions } from 'redux/reducers/admin';
import { cacheStaticDataWithArgs } from 'utils/cacheServices';

const genericError = 'Something went wrong. Please try again later';

export function* voidTransactionAuthorizationSaga(action) {
  const {
    payload: { params, authorizationHeaders },
  } = action;

  try {
    yield put(actions.initLoading(true));
    const response = yield call(
      postVoidTransactions,
      params,
      authorizationHeaders
    );
    if (response?.data) {
      yield put(actions.initLoading(false));
    }
  } catch (e) {
    yield put(actions.logError(e?.response?.data?.error || genericError));
    yield put(actions.initLoading(false));
    yield put(actions.setIsErrorVisible(true));
    yield put(actions.setVoidErrorStopTimer(true));
  }
}

export function* getVoidTransactionsSaga(action) {
  const {
    payload: { params },
  } = action;

  try {
    yield put(actions.initLoading(true));
    const response = yield call(getVoidTransactions, params);
    if (response?.data) {
      yield put(
        actions.setVoidTransactionsList(response?.data?.transactions || [])
      );
      yield put(actions.initLoading(false));
      yield put(actions.setDisableVoidTransaction(false));
    } else {
      yield put(actions.setVoidTransactionsList([]));
      yield put(actions.initLoading(false));
      yield put(actions.setDisableVoidTransaction(false));
    }
  } catch (error) {
    yield put(actions.setVoidTransactionsList([]));
    yield put(actions.logError(error || genericError));
    yield put(actions.setIsErrorVisible(true));
    yield put(actions.initLoading(false));
  }
}

//USER MANAGER SAGAS
export function* getStoreUsersSaga(action) {
  const {
    payload: { storeId },
  } = action;

  try {
    yield put(actions.initGridLoading(true));
    yield put(actions.clearUserDetails());
    const response = yield call(
      cacheStaticDataWithArgs,
      `ADMIN_STORE-${storeId}`,
      getStoreUsers,
      storeId
    );

    if (response?.data) {
      yield put(actions.setStoreUsers(response.data));
      yield put(actions.initGridLoading(false));
    }
  } catch (error) {
    yield put(actions.initGridLoading(false));
  }
}

export function* getUserDetailsSaga(action) {
  const {
    payload: { userId, details },
  } = action;

  try {
    yield put(actions.initLoading(true));
    const response = yield call(getUserDetails, userId);
    if (response?.data) {
      const assignedPolicies = response?.data?.policies?.reduce((obj, el) => {
        return {
          ...obj,
          [el.policyId]:
            el.policyId === 6 ? el.value : Number(el.value).toFixed(2),
        };
      }, {});

      const assignedRoles = response?.data?.roles?.reduce((obj, el) => {
        return { ...obj, [el.roleId]: true };
      }, []);

      const assignedStores = response?.data?.stores?.reduce((arr, el) => {
        return [...arr, el.storeId];
      }, []);

      yield put(actions.setAssignedRoles(assignedRoles));
      yield put(actions.setAssignedStores(assignedStores));
      yield put(actions.setAssignedPolicies(assignedPolicies));
      yield put(actions.setSelectedUser(details));
      yield put(actions.setUserDetails(response.data));
      yield put(actions.initLoading(false));
    }
  } catch (error) {
    if (error?.response?.status === 404)
      yield put(actions.logError('Details not Found.'));
    else
      yield put(actions.logError(error?.response?.data?.error || genericError));

    yield put(actions.setIsErrorVisible(true));
    yield put(actions.clearUserDetails());
    yield put(actions.initLoading(false));
  }
}

export function* updateUserDetailsSaga(action) {
  const {
    payload: { params, userId },
  } = action;

  try {
    yield put(actions.initLoading(true));
    const response = yield call(updateUserDetails, userId, params);
    if (response?.status === 200) {
      yield put(actions.logSuccess('Details Updated'));
      yield put(actions.clearUserDetails());
      yield put(actions.setIsSuccessMsgVisible(true));
      yield put(actions.initLoading(false));
    }
  } catch (error) {
    yield put(actions.logError(error?.response?.data?.error || genericError));
    yield put(actions.setIsErrorVisible(true));
    yield put(actions.initLoading(false));
  }
}

export function* getUserPermissionsSaga(action) {
  const {
    payload: { userId },
  } = action;

  try {
    yield put(actions.initLoading(true));
    const response = yield call(getUserPermissions, userId);
    if (response?.data) {
      const assignedStores = response?.data?.userStore?.reduce((arr, el) => {
        return [...arr, el.storeId];
      }, []);
      yield put(actions.setUserPermissions(response?.data));
      yield put(actions.setAssignedStores(assignedStores));
      yield put(actions.setSelectedGroup(response?.data?.userGroup?.groupId));
      if (response?.data?.userGroup?.groupId) {
        const groupPermissionsResponse = yield call(
          getGroupPermissions,
          response?.data?.userGroup?.groupId
        );
        if (groupPermissionsResponse?.data) {
          yield put(
            actions.setGroupPermissions(groupPermissionsResponse?.data)
          );
          yield put(
            actions.setGroupRoles(groupPermissionsResponse?.data?.groupRoles)
          );
          yield put(
            actions.setGroupPolicy(groupPermissionsResponse?.data?.groupPolicy)
          );
        }
      }

      yield put(actions.initLoading(false));
    }
  } catch (error) {
    yield put(actions.logError(error?.response?.data?.error || genericError));
    yield put(actions.setIsErrorVisible(true));
    yield put(actions.initLoading(false));
  }
}

export function* getGroupPermissionsSaga(action) {
  const {
    payload: { groupId },
  } = action;

  try {
    yield put(actions.initLoading(true));
    const response = yield call(getGroupPermissions, groupId);
    if (response?.data) {
      yield put(actions.setGroupPermissions(response?.data));
      yield put(actions.setGroupRoles(response?.data?.groupRoles));
      yield put(actions.setGroupPolicy(response?.data?.groupPolicy));
      yield put(actions.initLoading(false));
    }
  } catch (error) {
    yield put(actions.logError(error?.response?.data?.error || genericError));
    yield put(actions.setIsErrorVisible(true));
    yield put(actions.initLoading(false));
  }
}

export function* getDefaultPermissionsSaga(action) {
  const {
    payload: { userId },
  } = action;

  try {
    yield put(actions.initGridLoading(true));
    yield put(actions.initLoading(true));
    yield put(actions.clearUserDetails());
    const response = yield call(getDefaultPermissions, userId);
    if (response?.data) {
      yield put(actions.setDefaultPermissions(response?.data));
      yield put(actions.setGroupRoles(response?.data?.elevatedPermissions));
      yield put(actions.setSelectedGroup(response?.data?.userGroup?.groupId));
      yield put(actions.initGridLoading(false));
      yield put(actions.initLoading(false));
    }
  } catch (error) {
    yield put(actions.logError(error?.response?.data?.error || genericError));
    yield put(actions.setIsErrorVisible(true));
    yield put(actions.initGridLoading(false));
    yield put(actions.initLoading(false));
  }
}

export function* postSaveUserPermissionsSaga(action) {
  const {
    payload: { params },
  } = action;

  try {
    yield put(actions.initLoading(true));
    const response = yield call(postSaveUserPermissions, params);
    if (response?.status === 200) {
      yield put(actions.logSuccess('Details Updated'));
      yield put(actions.clearUserDetails());
      yield put(actions.setIsSuccessMsgVisible(true));
      yield put(actions.initLoading(false));
    }
  } catch (error) {
    yield put(actions.logError(error?.response?.data?.error || genericError));
    yield put(actions.setIsErrorVisible(true));
    yield put(actions.initLoading(false));
  }
}

export function* getTravelMoneyRatesSaga(action) {
  try {
    yield put(actions.initLoading(true));
    const response = yield call(getTravelMoneyRates, action.payload);
    if (response?.status === 200) {
      yield put(actions.setTravelMoneyRates(response?.data));
      yield put(actions.initLoading(false));
    }
  } catch (error) {
    yield put(actions.logError(error?.response?.data?.error || genericError));
    yield put(actions.setIsErrorVisible(true));
    yield put(actions.initLoading(false));
  }
}

export function* getTravelMoneyRatesHistorySaga(action) {
  try {
    yield put(actions.initLoading(true));
    const response = yield call(getTravelMoneyRatesHistory, action.payload);
    if (response?.status === 200) {
      yield put(actions.setTravelMoneyRateHistory(response?.data));
      yield put(actions.initLoading(false));
    }
  } catch (error) {
    yield put(actions.logError(error?.response?.data?.error || genericError));
    yield put(actions.setIsErrorVisible(true));
    yield put(actions.initLoading(false));
  }
}

export function* createTravelMoneyRatesSaga(action) {
  try {
    yield put(actions.initLoading(true));
    const response = yield call(createTravelMoneyRates, action.payload);
    if (response?.status === 200) {
      yield put(actions.setTravelMoneyRates(response?.data));
      yield put(actions.logSuccess('Travel Money Rates Updated.'));
      yield put(actions.initLoading(false));
    }
  } catch (error) {
    yield put(actions.logError(error?.response?.data?.error || genericError));
    yield put(actions.setIsErrorVisible(true));
    yield put(actions.initLoading(false));
  }
}

export function* watchVoidTransactionAuthorizationSaga() {
  yield takeEvery(AUTHORIZE_VOID_TRANSACTION, voidTransactionAuthorizationSaga);
}

export function* watchGetVoidTransactionsSaga() {
  yield takeEvery(GET_VOID_TRANSACTIONS, getVoidTransactionsSaga);
}

export function* watchGetStoreUsersSaga() {
  yield takeEvery(ADMIN_STORE_USERS, getStoreUsersSaga);
}

export function* watchGetUserDetailsSaga() {
  yield takeEvery(ADMIN_USER_DETAILS, getUserDetailsSaga);
}

export function* watchUpdateUserDetailsSaga() {
  yield takeEvery(ADMIN_UPDATE_USER_DETAILS, updateUserDetailsSaga);
}

export function* watchGetUserPermissionsSaga() {
  yield takeEvery(ADMIN_GET_USER_PERMISSIONS, getUserPermissionsSaga);
}

export function* watchGetGroupPermissionsSaga() {
  yield takeEvery(GET_GROUP_PERMISSIONS, getGroupPermissionsSaga);
}

export function* watchGetDefaultPermissionsSaga() {
  yield takeEvery(ADMIN_GET_DEFAULT_PERMISSIONS, getDefaultPermissionsSaga);
}

export function* watchPostSaveUserPermissionsSaga() {
  yield takeEvery(SAVE_USER_PERMISSIONS, postSaveUserPermissionsSaga);
}

export function* watchGetTravelMoneyRatesSaga() {
  yield takeEvery(GET_TRAVEL_MONEY_RATES, getTravelMoneyRatesSaga);
}

export function* watchGetTravelMoneyRatesHistorySaga() {
  yield takeEvery(
    GET_TRAVEL_MONEY_RATES_HISTORY,
    getTravelMoneyRatesHistorySaga
  );
}

export function* watchCreateTravelMoneyRatesSaga() {
  yield takeEvery(CREATE_TRAVEL_MONEY_RATES, createTravelMoneyRatesSaga);
}
