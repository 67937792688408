import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Row, notification } from 'antd';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import LoanExceptionTable from './loanExceptionTable';
import { Button } from 'components/common/Button';

import ManageServicesPopup from 'components/consumerDuty/widgets/serviceManager/manageServicesPopup';
import ReviewChangesPopup from 'components/consumerDuty/widgets/serviceManager/reviewChangesPopup';
import IconDescription from 'components/customer/customerSummary/pledge/iconDescription';

import { getTaskByCustomerId } from 'services/workQueue';

import { actions as consumerDutyActions } from 'redux/reducers/consumerDuty';
import { actions as userActions } from 'redux/reducers/user';
import { PROCESS_CONSUMERDUTY_REQUEST } from 'action_creators/consumerDuty';

import { BUTTON_LABELS, VIEW_TYPE } from '../constants';
import { TASK_OWNER_VALUE } from 'components/consumerDuty/constants';
import { GENERIC_ERROR } from 'globalConstants';

interface LoanExceptionProps {
  onExit: () => void;
}

const StyledFooterButtonRow = styled(Row)`
  padding: 10px 30px;
  border: 0.6px solid var(--linkwater);
  border-top: none;
  box-shadow: rgba(1, 78, 169, 0.2) 0px 4px 10px;
  border-radius: 0px 0px 5px 5px;
`;

const StyledButtonWrapper = styled.div`
  & button {
    margin: 0 12px;
    font-weight: var(--font-weight-600) !important;
  }
`;

const StyledDiv = styled.div`
  height: 70px;
  padding: 0 20px;
`;

const LoanException = ({ onExit }: LoanExceptionProps) => {
  const {
    consumerDuty: { isReviewChangesPopupVisible, loanPrivileges },
    user: { lockedLoans, user, selectedStore },
  } = useSelector((state: any) => {
    return { consumerDuty: state.consumerDuty, user: state.user };
  });

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    return () => clearManagerServiceData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const dispatch = useDispatch();

  const [isManageServicesPopupVisible, setManageServicesPopupVisible] =
    useState<boolean>(false);

  const onManageServiceClick = () => {
    //fetching case details for the selected loan
    getTaskDetailsByCustomerId();

    let selectedTeam = lockedLoans[0].isAtRisk
      ? TASK_OWNER_VALUE.AT_RISK
      : TASK_OWNER_VALUE.SSTEAM;
    let customerName = lockedLoans[0].customerName;
    let customerId = lockedLoans[0].customerId || null;

    dispatch(consumerDutyActions.setLoanCustomer({ customerId, customerName }));
    dispatch(consumerDutyActions.setSelectedTaskOwner(selectedTeam));
    setManageServicesPopupVisible(true);
  };

  const getTaskDetailsByCustomerId = async (
    redirectToConsumerDuty: boolean = false
  ) => {
    try {
      let taskOwner = lockedLoans[0].isAtRisk
        ? TASK_OWNER_VALUE.AT_RISK
        : TASK_OWNER_VALUE.SSTEAM;
      let customerId = lockedLoans[0].customerId || null;

      setIsLoading(true);
      const response = await getTaskByCustomerId(customerId, taskOwner);
      if (response?.data) {
        setIsLoading(false);
        dispatch(consumerDutyActions.setTaskId(response?.data?.taskId));

        if (redirectToConsumerDuty) loadCustomerCaseFile(response?.data);
      }
    } catch (e: any) {
      setIsLoading(false);
      notification.error({
        message: e?.response?.data?.error || GENERIC_ERROR,
        duration: 5,
      });
    }
  };

  const loadCustomerCaseFile = (caseDetails: any) => {
    const taskDetails = {
      id: caseDetails?.taskId,
      serviceName: caseDetails?.serviceName,
      customerId: caseDetails?.customerId,
      subject: caseDetails?.taskDescription,
    };

    const params = {
      storeId: selectedStore?.storeId,
      taskId: taskDetails.id,
      agentId: user?.userId,
      agentName: user?.displayName,
      viewType: VIEW_TYPE.VIEW_TASK_DETAILS,
    };

    dispatch({
      type: PROCESS_CONSUMERDUTY_REQUEST,
      payload: {
        taskDetails,
        params,
        navigate,
      },
    });
  };

  const onProfileButtonClick = () => {
    getTaskDetailsByCustomerId(true);
  };

  const onReviewChangeButtonClick = (): void => {
    dispatch(consumerDutyActions.setIsReviewChangesPopupVisible(true));
  };

  const clearManagerServiceData = () => {
    dispatch(consumerDutyActions.resetLoanException());
    dispatch(userActions.setLockedLoans([]));
  };

  return (
    <>
      <div className="loan-exception-grid-wrapper">
        <LoanExceptionTable />
      </div>

      <StyledDiv>
        <IconDescription pledgeSummary={lockedLoans} />
      </StyledDiv>

      <StyledFooterButtonRow justify="space-between" className="footer-buttons">
        <Button itemProp="secondary" onClick={onExit}>
          {BUTTON_LABELS.EXIT}
        </Button>
        <StyledButtonWrapper>
          <Button
            loading={isLoading}
            disabled={lockedLoans.length === 0}
            onClick={onProfileButtonClick}
          >
            {BUTTON_LABELS.PROFILE}
          </Button>
          <Button
            disabled={lockedLoans.length === 0}
            onClick={onManageServiceClick}
          >
            {BUTTON_LABELS.MANAGE}
          </Button>
          <Button
            disabled={isEmpty(loanPrivileges)}
            onClick={onReviewChangeButtonClick}
          >
            {BUTTON_LABELS.REVIEW_CHANGES}
          </Button>
        </StyledButtonWrapper>
        <div></div>
      </StyledFooterButtonRow>

      {isManageServicesPopupVisible && (
        <ManageServicesPopup
          open={isManageServicesPopupVisible}
          pledgeSummary={lockedLoans}
          setManageServicesPopupVisible={() =>
            setManageServicesPopupVisible(false)
          }
        />
      )}

      {isReviewChangesPopupVisible && (
        <ReviewChangesPopup isVisible={isReviewChangesPopupVisible} />
      )}
    </>
  );
};

export default LoanException;
