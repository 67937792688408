import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Row, Col, Checkbox } from 'antd';
import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';
import { removeHTMLTags, joinValues } from 'utils/util';
import { CustomerPopupProps } from '../interface/CustomerPopUp';
import { Button } from 'components/common/Button';
import { FNS_DATE_FORMAT } from 'globalConstants';
import ImageLoader from 'components/common/ImageLoader/index';
import {
  isFrontCustomer,
  isPhoneCustomer,
  VERIFY_CUSTOMER_IDENTITIES,
  VERIFY_CUSTOMER_IDENTITY,
  THIRD_PARTY_ASSISTANT,
} from './constants';
import { getProfileLabel, getCustomerKycStatusIcon } from '../../utils/util';
import { actions } from 'redux/reducers/customer';
import { actions as consumerDutyActions } from 'redux/reducers/consumerDuty';
import { getCustomerVulnerableStatus } from 'services/customer';

interface CustomerIdentity {
  key: string;
  value: string;
  checked: boolean;
  isTpaItem?: boolean;
}

const StyledRow = styled(Row)`
  margin: 15px 0;
`;

const StyledHeader = styled(Row)`
  margin-bottom: 30px;
  font-size: 2rem;
  white-space: initial;
  color: var(--text-color);
  font-weight: var(--font-weight-500);
  line-height: 23.44px;
`;

const StyledTpaHeader = styled(Row)`
  margin-bottom: 10px;
  font-size: 2rem;
  white-space: initial;
  color: var(--text-color);
  font-weight: var(--font-weight-500);
  line-height: 23.44px;
`;

const StyledContent = styled.div<{
  $color: string;
}>`
  margin: 30px 5px 0px 15px;
  font-weight: var(--font-weight-600);
  padding-top: 5px;
  ${(props) =>
    props.$color === 'yellow'
      ? css`
          color: var(--red);
        `
      : css`
          color: var(--${props.$color});
        `}
`;

const StyledTick = styled.img`
  margin-top: 30px;
`;

const StyledSubTitle = styled(Row)`
  font-weight: 500;
  color: var(--text-color);
  margin: 20px 0;
  line-height: 16.41px;
`;

const StyledContentContainer = styled.div`
  border: 1px solid var(--light-gray);
  padding: 5px 20px;
  margin: 10px 0;
  background: var(--white);
  border-radius: var(--border-radius-large);
  color: var(--text-color);
`;

const StyledCheckboxCol = styled(Col)`
  text-align: center;
`;

const StyledNote = styled.div`
  color: var(--red);
  line-height: 20px;
  padding: 10px 0 0 0;
`;

const CustomerPopup = ({
  customer,
  additionalMessage,
  onCustomerPopupCancel,
  onCustomerPopupOk,
  isPopupFromManagedServices,
}: CustomerPopupProps) => {
  const [visible, setVisible] = useState(false);
  const [profileStatus, setCustomerProfileStatus] = useState<string>('');
  const [selectedIdentities, setSelectedIdentities] = useState<
    CustomerIdentity[]
  >([]);
  const [customerNomineeList, setCustomerNomineeList] = useState<Array<any>>(
    []
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (customer) {
      setVisible(true);
      getCustomerProfileStatus(customer);
    }
  }, [customer]);

  const onOkClick = useCallback(() => {
    dispatch(actions.setCustomerVulnerabilityStatus(profileStatus));
    dispatch(actions.setSelectedCustomer(customer));
    setVisible(false);
    onCustomerPopupOk(customer);
    const items =
      selectedIdentities &&
      selectedIdentities.filter((item) => item?.isTpaItem === true);
    if (items.length > 0) {
      dispatch(actions.setTpaEnquiryMode(true));
    }
  setSelectedIdentities([]);
  }, [profileStatus, onCustomerPopupOk, setVisible, selectedIdentities]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCancelClick = useCallback(() => {
    setVisible(false);
    onCustomerPopupCancel();
    setSelectedIdentities([]);
  }, [onCustomerPopupCancel, setVisible]);

  const onStoreEnquiryClick = useCallback(() => {
    if(!isPopupFromManagedServices){
      dispatch(actions.setCustomerVulnerabilityStatus(profileStatus));
      dispatch(actions.setStoreEnquiryMode(true));
      dispatch(actions.setSelectedCustomer(customer));
      setVisible(false);
      onCustomerPopupOk(customer);
    }
    else {
      dispatch(actions.setStoreEnquiryMode(true));
      setVisible(false);
      dispatch(consumerDutyActions.setIsStoreEnquiryModeActiveForCase(true));
    }
    
  }, [profileStatus, onCustomerPopupOk, setVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPhoneEnquiryClick = useCallback(() => {
    dispatch(actions.setCustomerVulnerabilityStatus(profileStatus));
    dispatch(actions.setPhoneEnquiryMode(true));
    dispatch(actions.setSelectedCustomer(customer));

    setVisible(false);
    onCustomerPopupOk(customer);
  }, [profileStatus, onCustomerPopupOk, setVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeHandler = (
    checked: boolean,
    key: any,
    value: any,
    isTpaItem = false
  ) => {
    const checkedData = [
      {
        key,
        value,
        checked,
        isTpaItem,
      },
    ];

    const items =
      selectedIdentities &&
      selectedIdentities.filter((item) => item.key.includes(key));
    if (items.length === 0) {
      setSelectedIdentities([...selectedIdentities, ...items, ...checkedData]);
    } else {
      setSelectedIdentities([
        ...selectedIdentities.filter((item) => !item.key.includes(key)),
      ]);
    }
  };

  const firstNames = removeHTMLTags(customer.firstNames);
  const surname = removeHTMLTags(customer.surname);
  const dateOfBirth = customer.dateOfBirth
    ? format(new Date(removeHTMLTags(customer.dateOfBirth)), FNS_DATE_FORMAT)
    : '';
  const property = removeHTMLTags(customer.property);
  const mobile = removeHTMLTags(customer.mobile);
  const postcode = removeHTMLTags(customer.postcode);
  const fullName = joinValues([firstNames, surname], true);

  const isLiteCustomer =
    customer?.customerId &&
    !customer?.property?.trim() &&
    !customer?.town?.trim();

  const customerIdentityObject = [
    {
      key: dateOfBirth,
      text: 'Date of Birth',
      id: 1,
    },
    {
      key: mobile,
      text: 'Mobile No.',
      id: 2,
    },
    {
      key: property,
      text: 'Door No.',
      id: 3,
    },
    {
      key: postcode,
      text: 'Postcode',
      id: 4,
    },
  ];

  const getCustomerProfileStatus = async (customerParam: any) => {
    try {
      const response = await getCustomerVulnerableStatus(
        customerParam.customerId
      );
      if (response?.data?.customerId === +customerParam.customerId) {
        setCustomerProfileStatus(response?.data?.colorCode);
      }
      if (response?.data?.customerNominee?.length > 0) {
        setCustomerNomineeList([...response?.data?.customerNominee]);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const checkVerifiedIndentities = (): boolean => {
    if (!isPopupFromManagedServices) {
      return !isEmpty(selectedIdentities)  ? false : true;
    } else {
      return !isEmpty(selectedIdentities) &&
        selectedIdentities.length > 1
        ? false
        : true;
    }
  };

  const htmlmsg =
    profileStatus === 'yellow'
      ? `${getProfileLabel(profileStatus)} before continuing`
      : getProfileLabel(profileStatus);

  return (
    <Modal
      open={visible}
      closable={false}
      key={customer.customerId}
      width={710}
      onOk={onOkClick}
      onCancel={onCancelClick}
      maskClosable={false}
      footer={
        <Row>
          <Col span={4}>
            <Button
              disabled={isLiteCustomer}
              itemProp="secondary"
              key="store"
              style={{ float: 'left' }}
              onClick={onStoreEnquiryClick}
            >
              Store Enquiry
            </Button>
          </Col>
          <Col span={4} style={{ marginLeft: '10px' }}>
            {!isPopupFromManagedServices && (
              <Button
                key="phone"
                itemProp="secondary"
                style={{ float: 'left', marginLeft: '28px' }}
                disabled={
                  !isEmpty(selectedIdentities) && !isLiteCustomer ? false : true
                }
                onClick={onPhoneEnquiryClick}
              >
                Phone Enquiry
              </Button>
            )}
          </Col>
          <Col span={3} offset={9}>
            <Button
              itemProp="secondary"
              style={{ marginRight: '20px' }}
              key="back"
              onClick={onCancelClick}
            >
              No
            </Button>
          </Col>
          <Col span={3}>
            <Button
              key="submit"
              disabled={checkVerifiedIndentities()}
              onClick={onOkClick}
            >
              Yes
            </Button>
          </Col>
        </Row>
      }
    >
      <Row>
        <Col span={9}>
          <ImageLoader
            customerId={customer.customerId}
            imageUrl={`/api/customers/images/${customer.customerId}`}
            classes={'mx-larger-avatar mx-larger-avatar'}
            tip="Loading..."
            fallbackImg={'customer-pic.svg'}
            isCaching={false}
          />
          <Row>
            <StyledContent
              $color={profileStatus}
              dangerouslySetInnerHTML={{ __html: htmlmsg }}
            ></StyledContent>
            {profileStatus !== 'yellow' && (
              <StyledTick
                alt=""
                src={getCustomerKycStatusIcon(profileStatus)}
              />
            )}
          </Row>
        </Col>
        <Col span={15}>
          {!isPopupFromManagedServices ? (
            <StyledHeader>
            {isFrontCustomer(
              fullName,
              customerNomineeList?.length > 0
                ? THIRD_PARTY_ASSISTANT.OR_TEXT
                : ''
            )}
          </StyledHeader>
          ) : (
            <StyledHeader>{isPhoneCustomer(fullName)}</StyledHeader>
          )}
          
          {customerNomineeList?.length > 0 && (
            <>
              <StyledTpaHeader>
                {THIRD_PARTY_ASSISTANT.TPA_HEADING}
              </StyledTpaHeader>
              <StyledContentContainer>
                {customerNomineeList.map((item, index) => {
                  const { nomineeName, relationship } = item;
                  return (
                    <StyledRow key={`customer-identity${index}`}>
                      <Col span={8}>{nomineeName}</Col>
                      <Col span={14}>{relationship}</Col>
                      {nomineeName && (
                        <StyledCheckboxCol span={2}>
                          <Checkbox
                            key={nomineeName + index}
                            onChange={(e) =>
                              onChangeHandler(
                                e.target.checked,
                                THIRD_PARTY_ASSISTANT.TPA_HEADING,
                                nomineeName,
                                true
                              )
                            }
                          />
                        </StyledCheckboxCol>
                      )}
                    </StyledRow>
                  );
                })}
              </StyledContentContainer>
            </>
          )}
            
          <StyledSubTitle>
          {!isPopupFromManagedServices ? VERIFY_CUSTOMER_IDENTITY :  VERIFY_CUSTOMER_IDENTITIES }
          </StyledSubTitle>
          <StyledContentContainer>
            {customerIdentityObject.map((item, index) => {
              const { key, text, id } = item;
              return (
                <StyledRow key={`customer-identity${index}`}>
                  <Col span={8}>{text}</Col>
                  <Col span={14}>{key}</Col>
                  {key && (
                    <StyledCheckboxCol span={2}>
                      <Checkbox
                        key={id}
                        onChange={(e) =>
                          onChangeHandler(e.target.checked, text, key)
                        }
                      />
                    </StyledCheckboxCol>
                  )}
                </StyledRow>
              );
            })}
          </StyledContentContainer>
          {additionalMessage ? (
            <StyledNote>{additionalMessage}</StyledNote>
          ) : null}
        </Col>
      </Row>
    </Modal>
  );
};

export default CustomerPopup;
