import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Select, Spin, notification } from 'antd';
import { Plugin, Getter } from '@devexpress/dx-react-core';
import { IntegratedSelection } from '@devexpress/dx-react-grid';
import { Table, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import VirtualGrid from '../../../common/virtualGrid/index';
import styled from 'styled-components';
import {
  FNS_DATE_FORMAT,
  GRID_NO_URL,
} from '../../../../globalConstants/index';
import { getPledgeAgreementItems } from 'services/pledge';
import { pledgeActions } from 'redux/reducers/pawnbroking/index';
import {
  PAWNBROKING_ACTIONS,
  PROFILE_STATUS,
} from 'components/customer/constants';
import { LOAN_STATUS } from 'components/pickupRenew/constants';
import WithdrawLaterNoticePopup from './WithdrawLaterNoticePopup';
import EarlySettlementPopup from './EarlySettlementPopup';
import ImageLoader from 'components/common/ImageLoader';
import { downloadBagSlip } from 'services/payment';
import { sendToPrinter } from 'services/user';
import PledgeNoticePopup from 'components/pledgemanagement/widgets/common/pledgeNoticePopup';
import PledgeNotesPopup from 'components/pledgemanagement/widgets/common/pledgeNotesPopup';
import {
  CONFISCATE_PLEDGES,
  VULNERABLE_PLEDGES,
} from 'action_creators/pledgeManagement';
import { actions as pledgeMngmtActions } from 'redux/reducers/pledgeManagement';
import {
  CONFISCATE_NOTICE_POPUP,
  PLEDGE_ACTION_NOTES_TITLES,
  PLEDGE_NOTICE_TYPE,
  VULNERABLE_NOTICE_POPUP,
} from 'components/pledgemanagement/constants';

import { getPledgeIcons } from 'utils/util';

import IconDescription from './iconDescription';

import './index.less';

const StyledGridWrapper = styled.div`
  height: calc(65vh - 30px);
`;

const rowSelectionEnabled = (row: any, isProfileView: boolean) => {
  return (
    row.loanStatus === LOAN_STATUS.OPEN &&
    row.confiscated === 0 &&
    isProfileView // show actions on customer summary, not on case file when vulnerable/at risk customer CDA-232
  );
};

const PatchedIntegratedSelection = ({ ...restProps }: any) => {
  return (
    <Plugin>
      <IntegratedSelection {...restProps} />
      <Getter
        name="rows"
        computed={({ rows }: any) => {
          return rows;
        }}
      />
    </Plugin>
  );
};

const PatchedTableSelection = ({
  showSelectAll,
  vulnerabilityStatus,
  isProfileView,
  ...restProps
}: any) => {
  return (
    <TableSelection
      cellComponent={(props: any) =>
        rowSelectionEnabled(props.tableRow.row, !isProfileView) ? (
          <TableSelection.Cell {...props} />
        ) : (
          <Table.StubCell {...props} />
        )
      }
      {...restProps}
    />
  );
};

const TableRow = ({
  row,
  onActionChange,
  onActionClear,
  selectedBagSlip,
  vulnerabilityStatus,
  isProfileView,
  ...restProps
}: any) => {
  const onSelectedActionChange = (value: any) => {
    onActionChange(row, value);
  };
  const checkActionType = (row: any) => {
    const value = selectedBagSlip.includes(row?.loanNumber)
      ? 3
      : row?.isRequestedForWithdraw
      ? 1
      : row.isEarlySettlement
      ? 2
      : -1;
    return PAWNBROKING_ACTIONS[value - 1]?.id;
  };

  const Cell = () => {
    const { column, tableRow } = restProps;
    const { row } = tableRow;

    if (
      column.name === 'actions' &&
      row?.loanStatus === LOAN_STATUS.OPEN &&
      row?.confiscated === 0 &&
      !isProfileView // show actions on customer summary, not on case file when vulnerable/at risk customer CDA-232
    ) {
      return (
        <Table.Cell {...restProps}>
          <Select
            placeholder="Select"
            className="grid-select-option"
            allowClear
            value={checkActionType(row)}
            onSelect={onSelectedActionChange}
            onClear={() => undefined}
            disabled={
              row.loanStatus === LOAN_STATUS.OPEN &&
              (row.isRequestedForWithdraw || row.isEarlySettlement)
            }
          >
            {PAWNBROKING_ACTIONS.map((item: any, index: number) => {
              return (
                <Select.Option
                  value={item.id}
                  key={index}
                  disabled={item.id === 1 && !row.isApplicableForWithdraw}
                >
                  {item.value}
                </Select.Option>
              );
            })}
          </Select>
        </Table.Cell>
      );
    }
    return <Table.Cell {...restProps} />;
  };

  return <>{Cell()}</>;
};

const PledgeSummary = ({ isProfileView }: any) => {
  const {
    pawnbroking: { pawnbrokingSummary },
    pickupRenew: { pledgeAgreement },
    pledgeManagement: {
      isPledgeNoticePopupVisible,
      pledgeNoticeType,
      isPledgeNotesPopupVisible,
    },
    customer: { customer, customerStatusColor },
    user: { user },
  } = useSelector((state: any) => {
    return {
      pawnbroking: state.pawnbroking,
      pickupRenew: state.pickupRenew,
      customer: state.customer,
      pledgeManagement: state.pledgeManagement,
      user: state.user,
    };
  });

  const [selectedBagSlip, setSelectedBagSlip] = useState<any>([]);
  const [pledgeList, setPledgeList] = useState<any>([]);

  useEffect(() => {
    if (customerStatusColor === PROFILE_STATUS.BLACK) {
      const pledges = pawnbrokingSummary.map((pledge: any) => {
        if (pledge.loanStatus === 'Open') return { ...pledge, isLocked: true };
        return pledge;
      });

      setPledgeList(pledges);
    } else setPledgeList(pawnbrokingSummary);
  }, [pawnbrokingSummary, customerStatusColor]);

  const [columns] = useState([
    {
      name: 'loanStatusId',
      title: 'Status',
      type: 'icon',
      displayContent: false,
      isMultipleIcons: true, // when isMultipleIcons = true , send an array of icon values
      isIconClickable: true,
      getCellValue: (row: any) => getPledgeIcons(row, customerStatusColor),
      onClick: (row: any) => {
        onIconClick(row);
      },
    },
    {
      name: 'startDate',
      title: 'Start Date',
      getCellValue: (row: any) =>
        format(new Date(row?.startDate), FNS_DATE_FORMAT),
    },
    {
      name: 'loanNumber',
      title: 'Agreement No.',
      getCellValue: (row: any) => row?.loanNumber,
    },

    {
      name: 'amount',
      title: 'Amount',
      type: 'currency',
      getCellValue: (row: any) => row?.amount,
    },
    {
      name: 'dueDate',
      title: 'Due Date',
      getCellValue: (row: any) =>
        format(new Date(row?.dueDate), FNS_DATE_FORMAT),
    },
    {
      name: 'numberOfRenewals',
      title: 'No. of Renewal',
      getCellValue: (row: any) => row?.numberOfRenewals,
    },
    {
      name: 'closedDate',
      title: 'Closed Date',
      getCellValue: (row: any) =>
        row?.closedDate
          ? format(new Date(row.closedDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'location',
      title: 'Location',
      getCellValue: (row: any) => row?.storeName,
    },
    {
      name: 'actions',
      title: 'Actions',
      getCellValue: (row: any) => row?.actions,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'loanStatusId', width: '11%' },
    { columnName: 'startDate', width: '8%' },
    { columnName: 'loanNumber', width: '10%', align: 'right' },
    { columnName: 'amount', width: '7%', align: 'right' },
    { columnName: 'dueDate', width: '8%', align: 'right' },
    {
      columnName: 'numberOfRenewals',
      align: 'center',
      wordWrapEnabled: true,
    },
    { columnName: 'closedDate', width: '8%' },
    { columnName: 'location' },
    { columnName: 'actions' },
  ]);

  const [formattedColumns] = useState(['loanStatusId', 'amount']);
  const [pledgeRow, setPledgeRow] = useState<Array<any>>([]);
  const [isWithdrawLaterPopupVisible, setWithdrawLaterPopupVisible] =
    useState<boolean>(false);
  const [isWithdrawViewMode, setWithdrawViewMode] = useState<boolean>(false);
  const [isESPopupVisible, setESPopupVisible] = useState<boolean>(false);
  const [isESMode, setESMode] = useState<boolean>(false);
  const [selectedAgreement, setSelectedAgreement] = useState<Array<any>>([]);
  const [selectedAgreementRows, setSelectedAgreementRows] = useState<
    Array<any>
  >([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState<any>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const selectedAgreement = pawnbrokingSummary
      .filter((o1: { id: any }) =>
        pledgeAgreement.some((o2: { id: any }) => o1.id === o2.id)
      )
      .map((o: { id: any }) => o.id);
    setSelectedAgreement(selectedAgreement);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isProfileView) setHiddenColumnNames(['actions']);
    else setHiddenColumnNames([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileView]);

  const onSelectionChange = (rowId: any) => {
    setSelectedAgreement([...rowId]);
    dispatch(pledgeActions.setNoAgreementSelection(rowId));
    const selectedAgreementRow = pawnbrokingSummary?.filter(
      (o1: { id: any; confiscated: any; storeId: number }) =>
        rowId?.includes(o1?.id) &&
        o1?.confiscated === 0 &&
        o1?.storeId === user?.selectedStore?.storeId
    );
    setSelectedAgreementRows(selectedAgreementRow);
    dispatch(
      pledgeMngmtActions.setIsNoticeButtonEnable(
        selectedAgreementRow?.length > 0
      )
    );
  };

  const onIconClick = (row: any) => {
    setPledgeRow(row);
    if (row?.isEarlySettlement) {
      setESMode(true);
      setESPopupVisible(true);
    } else if (row?.isRequestedForWithdraw) {
      setWithdrawViewMode(true);
      setWithdrawLaterPopupVisible(true);
    }
  };

  const setPrintBagslip = async (row: any) => {
    try {
      const receiptData = await downloadBagSlip(row.loanNumber);
      if (receiptData?.data) {
        sendToPrinter(receiptData, 'printreceipt', '_GenerateBagSlips');
      }
    } catch (e: any) {
      notification.error({
        message: e?.message,
        description: e?.message,
        duration: 5,
      });
    }
  };

  const onActionChange = (row: any, value: any) => {
    setPledgeRow(row);
    const bagSlips = selectedBagSlip.filter(
      (loanNumber: any) => loanNumber !== row?.loanNumber
    );
    switch (value) {
      case 1:
        setWithdrawViewMode(false);
        setWithdrawLaterPopupVisible(true);
        break;
      case 2:
        setESMode(false);
        setESPopupVisible(true);
        break;
      case 3:
        setPrintBagslip(row);
        bagSlips.push(row?.loanNumber);
        setSelectedBagSlip(bagSlips);
        break;
    }
  };

  const onPledgeNotesConfirm = (note: string) => {
    const loanIds = selectedAgreementRows?.reduce(
      (loanIdList: any, row: any) => [...loanIdList, +row?.id],
      []
    );

    if (pledgeNoticeType === PLEDGE_NOTICE_TYPE.CONFISCATE) {
      const params = { loanIds, note, userName: user?.displayName };
      dispatch({ type: CONFISCATE_PLEDGES, payload: { params } });
    } else if (pledgeNoticeType === PLEDGE_NOTICE_TYPE.VULNERABLE) {
      const params = {
        loanIds,
        reasonNotes: note,
        customerId: customer?.customerId,
        storeId: customer?.storeId,
        agentName: user?.displayName,
      };
      dispatch({ type: VULNERABLE_PLEDGES, payload: { params } });
    }
  };

  const getRowId = (row: any) => row.id;
  return (
    <>
      <StyledGridWrapper className="customer-pledge-summary-table">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          formattedColumns={formattedColumns}
          rowData={pledgeList}
          selection={selectedAgreement}
          getRowId={getRowId}
          onSelectionChange={onSelectionChange}
          cellComponent={(restProps: any) => (
            <TableRow
              onActionChange={onActionChange}
              selectedBagSlip={selectedBagSlip}
              vulnerabilityStatus={customerStatusColor}
              isProfileView={isProfileView}
              {...restProps}
            />
          )}
          customSelectionRequired={true}
          customTableSelection={(restProps: any) => (
            <PatchedTableSelection
              showSelectAll
              {...restProps}
              vulnerabilityStatus={customerStatusColor}
              isProfileView={isProfileView}
            />
          )}
          customIntegratedSelection={(restProps: any) => (
            <PatchedIntegratedSelection {...restProps} />
          )}
          expandableComponent={PledgeExpandableTable}
          hiddenColumnNames={hiddenColumnNames}
          setHiddenColumnNames={setHiddenColumnNames}
        />

        {isWithdrawLaterPopupVisible && (
          <WithdrawLaterNoticePopup
            visible={isWithdrawLaterPopupVisible}
            setWithdrawLaterPopupVisible={() => {
              setWithdrawLaterPopupVisible(false);
              setWithdrawViewMode(false);
            }}
            row={pledgeRow || []}
            isWithdrawViewMode={isWithdrawViewMode}
          />
        )}
        {isESPopupVisible && (
          <EarlySettlementPopup
            visible={isESPopupVisible}
            setESPopupVisible={() => {
              setESPopupVisible(false);
            }}
            row={pledgeRow || []}
            isESMode={isESMode}
          />
        )}

        {isPledgeNoticePopupVisible && (
          <PledgeNoticePopup
            visible={isPledgeNoticePopupVisible}
            noticeType={pledgeNoticeType}
            selectedAgreement={selectedAgreementRows}
            textConstants={
              pledgeNoticeType === PLEDGE_NOTICE_TYPE.CONFISCATE
                ? CONFISCATE_NOTICE_POPUP
                : VULNERABLE_NOTICE_POPUP
            }
          />
        )}

        {isPledgeNotesPopupVisible && (
          <PledgeNotesPopup
            visible={isPledgeNotesPopupVisible}
            noticeType={pledgeNoticeType}
            selectedAgreement={selectedAgreementRows}
            onConfirm={onPledgeNotesConfirm}
            title={
              pledgeNoticeType === PLEDGE_NOTICE_TYPE.CONFISCATE
                ? PLEDGE_ACTION_NOTES_TITLES.CONFISCATE
                : PLEDGE_ACTION_NOTES_TITLES.VULNERABLE
            }
          />
        )}
      </StyledGridWrapper>
      <IconDescription pledgeSummary={pledgeList} />
    </>
  );
};

export const PledgeExpandableTable = ({ row }: any) => {
  const { pawnbrokingSummary } = useSelector((state: any) => state.pawnbroking);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!Object.keys(row).includes('items'))
      getPledgeDetails(row.originalLoanId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getPledgeDetails = async (id: any) => {
    try {
      setLoading(true);
      const response = await getPledgeAgreementItems(id);
      if (response?.data?.items) {
        const pledgeSummary = JSON.parse(JSON.stringify(pawnbrokingSummary));
        const index = pawnbrokingSummary.findIndex(
          (obj: any) => obj.id === row.id
        );
        pledgeSummary[index].items = response.data.items;
        dispatch(pledgeActions.setPawnbrokingSummary(pledgeSummary));
        setLoading(false);
      }
    } catch (e: any) {
      console.log(e);
      setLoading(false);
    }
  };

  const [columns] = useState([
    {
      name: 'image',
      title: 'Item Image',
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row.id}`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'barcode',
      title: 'Barcode',
      getCellValue: (row: any) => row?.barcode,
    },
    {
      name: 'description',
      title: 'Description',
      getCellValue: (row: any) => row?.description,
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) =>
        (row?.weight > 0 ? row?.weight?.toFixed(2) : row?.weight) + 'g',
    },
    {
      name: 'amount',
      title: 'Amount',
      type: 'currency',
      getCellValue: (row: any) => row?.amount,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'image', width: '10%', align: 'center' },
    { columnName: 'barcode', width: '20%' },
    { columnName: 'description', width: '20%' },
    { columnName: 'weight', width: '10%', align: 'right' },
    { columnName: 'amount', width: '10%', align: 'right' },
  ]);

  const [formattedColumns] = useState(['amount']);

  return (
    <Spin spinning={loading}>
      <div className="pledge-expandable-table-rows">
        <VirtualGrid
          urlType={GRID_NO_URL}
          formattedColumns={formattedColumns}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={row.items || []}
        />
      </div>
    </Spin>
  );
};

export default PledgeSummary;
