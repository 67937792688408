import {
  call,
  put,
  takeEvery,
  takeLatest,
  select,
  all,
} from 'redux-saga/effects';

import * as retailActions from 'action_creators/retail';
import { actions } from 'redux/reducers/retail';
import {
  getRetailItems,
  findRetailItems,
  findSoldItems,
  getItemDetails,
} from 'services/retail';

/**
 * this saga handles the GET Retail Items
 * (1) get Retail Items
 * (2) handle response
 */
export function* getRetailItemSaga(action) {
  try {
    const {
      payload: { params },
    } = action;

    yield put(actions.retailItemRequest());
    //request get Retail Items from API
    let requestPayload = { barcode: params.barcode };
    const payload = yield call(getRetailItems, requestPayload);
    if (payload?.data) {
      if (payload?.data?.message && payload?.data?.retailItems?.length === 0) {
        yield put(actions.findretailItemMessage(payload?.data?.message));
        yield put(actions.showSoldMessage(true));
      }
      if (payload.data.retailItems.length > 0) {
        let payloadfilter = params.row.filter(
          (item) => item.stockItemId !== payload.data.retailItems[0].stockItemId
        );
        let payloadUpdated = payload.data.retailItems;
        yield put(
          actions.retailItemResponse([...payloadfilter, ...payloadUpdated])
        );
      } else {
        yield put(actions.retailItemsNotFound());
      }
    } else if (payload?.errors) {
      yield put(actions.retailItemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.retailItemError(e));
  }
}

export function* findRetailItemSaga(action) {
  try {
    const {
      payload: { params },
    } = action;

    yield put(actions.retailItemRequest());
    //request get Retail Items from API
    const payload = yield call(findRetailItems, params);
    if (payload?.data) {
      //TODO please remove .map functionality please move into redux
      let payloadUpdated = payload.data.retailItems.map((item) => {
        return {
          ...item,
          isRowSelected: false,
        };
      });
      if (payload?.data?.message && payload?.data?.retailItems?.length === 0) {
        yield put(actions.findretailItemMessage(payload?.data?.message));
        yield put(actions.showSoldMessage(true));
      }
      yield put(actions.setRetailItemResponse([...payloadUpdated]));
    } else if (payload?.errors) {
      yield put(actions.retailItemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.retailItemError(e));
  }
}

export function* findSoldItemSaga(action) {
  try {
    const {
      payload: { params },
    } = action;
    const { storeId } = params;

    yield put(actions.retailSoldItemRequest());
    //request get Retail Items from API
    const payload = yield call(findSoldItems, params, storeId);

    if (payload?.data) {
      let i = 0;
      //TODO please remove .map functionality please move into redux
      let payloadUpdated = payload.data.retailSoldItems.map((item) => {
        return {
          ...item,
          id: i++,
          isRowSelected: false,
        };
      });

      yield put(actions.setRetailItemResponse([...payloadUpdated]));
    } else if (payload?.errors) {
      yield put(actions.retailSoldItemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.retailSoldItemError(e));
  }
}

/**
 * this saga handles the GET Retail Items Details by itemId
 * (1) get Retail Items Details
 * (2) handle response
 */
export function* getItemDetailsSaga(id) {
  try {
    //request get Retail Item Details by itemId from API
    const payload = yield call(getItemDetails, id);
    if (payload?.data) {
      yield put(actions.retailItemsDetailResponse(payload.data));
    } else if (payload?.errors) {
      yield put(actions.retailItemsDetailError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.retailItemsDetailError(e));
  }
}

/**
 * this saga handles async call by item id's
 */
export function* getItemsDetailsByIDsSaga() {
  const { retailItems } = yield select((state) => state.retail);
  yield all(
    retailItems.map((item) => call(getItemDetailsSaga, item.stockItemId))
  );
}

export function* watchGetRetailItems() {
  yield takeLatest(retailActions.RETAIL_ITEM_REQUEST, getRetailItemSaga);
}

export function* watchFindRetailItems() {
  yield takeEvery(retailActions.FIND_RETAIL_ITEMS, findRetailItemSaga);
}

export function* watchFindSoldItems() {
  yield takeEvery(retailActions.FIND_SOLD_ITEMS, findSoldItemSaga);
}
