import React from 'react';

import styled from 'styled-components';

import { Col, Row } from 'antd';
import { CUSTOMER_PROFILE_STATUS } from './constants';
import { getCustomerKycStatusIcon, getProfileLabel } from 'utils/util';

const StyledIconRow = styled(Row)`
  padding-top: 15px;
`;

const StyledIconWrapper = styled(Col)`
  & .ant-avatar {
    margin: 0 8px;
  }
`;

const StyledTick = styled.img`
  padding: 0 12px;
`;

const StyledProfileStatus = styled.div`
  font-weight: var(--font-weight-600);
  text-align: left;
  font-size: var(--font-size-16);
`;

const StatusIconDescription = () => {
  const renderIcons = () => {
    return CUSTOMER_PROFILE_STATUS.map(
      (profileStatus: string, index: number) => {
        return (
          <StyledIconWrapper key={index} span={3}>
            <Row>
              <StyledTick
                alt=""
                src={getCustomerKycStatusIcon(profileStatus)}
              />

              <StyledProfileStatus
                dangerouslySetInnerHTML={{
                  __html: getProfileLabel(profileStatus),
                }}
              />
            </Row>
          </StyledIconWrapper>
        );
      }
    );
  };

  return <StyledIconRow>{renderIcons()}</StyledIconRow>;
};

export default StatusIconDescription;
