import React from 'react';
import { Col, Modal, Row } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DELETE_BASKET } from 'action_creators/basket';
import { actions } from 'redux/reducers/customer';
import { actions as basketActions } from 'redux/reducers/basket';
import { actions as checkoutActions } from 'redux/reducers/checkout';
import { actions as pickupActions } from 'redux/reducers/pickupRenew';
import { actions as retailActions } from 'redux/reducers/retail';
import { actions as fxActions } from 'redux/reducers/travelmoney';
import { actions as valuationActions } from 'redux/reducers/valuationCenter';
import { actions as chequeCashingActions } from 'redux/reducers/chequeCashing';
import { EXIT_AND_CLEAR, EXIT_AND_CLEAR_MSG } from './constants';
import { Button } from '../Button';

const StyledDiv = styled.div`
  text-align: center;
  font-size: var(--font-size-16);
  line-height: 16px;
  padding: 5px 0 5px 0;
`;

const StyledExitDiv = styled.div`
  text-align: center;
  font-size: var(--font-size-16);
  padding: 15px 0 10px 0;
`;

const StyledModal = styled(Modal)`
  & .ant-modal-title {
    text-align: center;
    color: var(--red);
  }
`;

const ExitAndClearPopup = ({ visible, setExitAndClearPopupVisible }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    basket: { basket },
  } = useSelector((state: any) => {
    return {
      basket: state.basket,
    };
  });

  const onCancelClick = () => {
    setExitAndClearPopupVisible();
  };

  const onConfirmClick = () => {
    setExitAndClearPopupVisible();
    if (basket?.basketId !== null) {
      dispatch({
        type: DELETE_BASKET,
        payload: basket?.basketId,
      });
    }
    dispatch(actions.clearAll({})); //clear customer session
    dispatch(basketActions.clearItems()); //clear basket
    dispatch(checkoutActions.clearCheckout()); //clear checkout

    //TODO -change in phase 1.1 - start
    dispatch(pickupActions.clearItems()); //clear pickup
    dispatch(retailActions.clearItems()); // clear retail
    dispatch(fxActions.clearFx()); //clear tavel money
    dispatch(chequeCashingActions.clearItems()); //clear cheque-cashing
    //TODO -change in phase 1.1 - end

    dispatch(valuationActions.clearValuation()); //clear selected valuation details

    navigate(`/`);
  };

  return (
    <>
      <StyledModal
        title={EXIT_AND_CLEAR}
        open={visible}
        width={420}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        closable={false}
        footer={[
          <Row gutter={24} justify="center" key="exitPopup">
            <Col>
              <Button
                key="confirmExit"
                itemProp="secondary"
                onClick={onConfirmClick}
              >
                Yes
              </Button>
            </Col>
            <Col>
              <Button
                itemProp="secondary"
                key="cancelExit"
                onClick={onCancelClick}
              >
                No
              </Button>
            </Col>
          </Row>,
        ]}
        centered
      >
        <Row>
          <Col span={24}>
            <StyledDiv>{EXIT_AND_CLEAR_MSG.LINE_ONE}</StyledDiv>
            <StyledDiv>{EXIT_AND_CLEAR_MSG.LINE_TWO}</StyledDiv>
            <StyledExitDiv>{EXIT_AND_CLEAR_MSG.LINE_THREE}</StyledExitDiv>
          </Col>
        </Row>
      </StyledModal>
      ;
    </>
  );
};

export default ExitAndClearPopup;
