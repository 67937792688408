import React, { useEffect, useState } from 'react';
import { Col, Modal, Row, Form, notification, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import format from 'date-fns/format';

import { FNS_DATE_FORMAT_YEAR } from 'globalConstants';
import { GET_STORE_TILL_HISTORY } from 'action_creators/cashManagement';
import { Button } from 'components/common/Button';
import Select from 'components/common/Select';
import { TILL_LABELS } from '../constants';
import { isEmpty } from 'lodash';

import DatePickerMUI from 'components/common/DatePickerMUI';
import TillHistoryTable from './TillHistoryTable';
import HistoryDenominationTable from './HistoryDenominationTable';

import {
  getLedgerDenominationsHistory,
  getFXDenominationsHistory,
  getCurrencies,
} from 'services/cashManagement';
import { actions } from 'redux/reducers/cashManagement';
import { cacheStaticData } from 'utils/cacheServices';

const StyledModal = styled(Modal)`
  & .ant-modal-header {
    background: var(--white);
  }
`;

const StyledDiv = styled.div`
  padding: 0 25px 0 0;
`;

const StyledTableWrapper = styled.div`
  margin: 105px 25px 0;
`;

const StyledTotalRow = styled(Row)`
  border: 1px solid var(--light-gray);
  border-top: none;
  border-radius: 4px 4px 0px 0px;
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-500);
  padding: 10px 20px;
`;

const ButtonCol = styled(Col)`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

interface TillHistoryPopupProps {
  visible: boolean;
  setTillHistoryPopupVisible: () => void;
}

const TillHistoryPopup = ({
  visible,
  setTillHistoryPopupVisible,
}: TillHistoryPopupProps) => {
  const [tillUser, setTillUser] = useState<any>();
  const [form] = Form.useForm();
  const {
    user: { user },
    cashManagement: { isLoading, fetchingHistory, storeTillUsers, tillError },
  } = useSelector((state: any) => {
    return {
      user: state.user,
      cashManagement: state.cashManagement,
    };
  });

  const [currencySymbol, setCurrencySymbol] = useState<any>('');
  const [isDenomDataAvailable, setIsDenomDataAvailable] =
    useState<boolean>(true);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [currencyDenominations, setCurrencyDenominations] = useState<
    Array<any>
  >([]);
  const [currencyList, setCurrencyList] = useState<Array<any>>([]);

  const dispatch = useDispatch();

  const getStoreCurrency = async () => {
    try {
      const selectAllCurrency = [
        {
          currencyDescription: TILL_LABELS.SELECT_ALL_CURRENCIES,
          currencyCode: TILL_LABELS.SELECT_ALL_CURRENCIES,
        },
      ];
      const response = await cacheStaticData(getCurrencies);
      if (response) setCurrencyList([...selectAllCurrency, ...response?.data]);
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.error,
        duration: 3,
      });
    }
  };

  useEffect(() => {
    const defaultTillUser = storeTillUsers?.find(
      (x: any) => x.userId === user.userId
    );
    setTillUser(defaultTillUser?.userId);

    const params = {
      tillUserId: defaultTillUser?.userId,
      currencyCode:
        form.getFieldValue('currency') !== TILL_LABELS.SELECT_ALL_CURRENCIES
          ? form.getFieldValue('currency')
          : '',
      fromDate: format(new Date(), FNS_DATE_FORMAT_YEAR),
      toDate: format(new Date(), FNS_DATE_FORMAT_YEAR),
    };

    dispatch({
      type: GET_STORE_TILL_HISTORY,
      payload: { params },
    });
    getStoreCurrency();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLoading && !isEmpty(tillError)) {
      notification.error({
        message: tillError?.description || tillError?.message,
        duration: 2,
      });
    }
  }, [isLoading, tillError]);

  const onCancelClick = () => {
    setTillHistoryPopupVisible();
  };

  const onTillUserChange = (value: any) => {
    setTillUser(value);
  };

  const onSearch = () => {
    const fromDate = form.getFieldValue('dateFrom')
      ? format(new Date(form.getFieldValue('dateFrom')), FNS_DATE_FORMAT_YEAR)
      : '';
    const toDate = form.getFieldValue('dateTo')
      ? format(new Date(form.getFieldValue('dateTo')), FNS_DATE_FORMAT_YEAR)
      : '';

    const params = {
      tillUserId: tillUser,
      fromDate,
      toDate,
      currencyCode:
        form.getFieldValue('currency') !== TILL_LABELS.SELECT_ALL_CURRENCIES
          ? form.getFieldValue('currency')
          : '',
    };

    dispatch({
      type: GET_STORE_TILL_HISTORY,
      payload: { params },
    });
  };

  const getOperationIdHistory = async (row: any) => {
    try {
      dispatch(actions.initFetchingHistory(true));

      const response =
        row.currencyCode === 'GBP'
          ? await getLedgerDenominationsHistory(row.tillOperationId)
          : await getFXDenominationsHistory(row.tillOperationId);

      if (response?.data) {
        const totalAmount = response.data.reduce((sum: any, obj: any) => {
          return sum + obj.value;
        }, 0);

        if (response?.data?.length === 0) {
          notification.error({
            message: TILL_LABELS.NOTIFICATION.DENOMINATION_HISTORY_NOT_FOUND,
            duration: 2,
          });
          setIsDenomDataAvailable(false);
        }

        setCurrencyDenominations(response?.data);
        setTotalAmount(totalAmount);
        setCurrencySymbol(response?.data[0]?.currencySymbol);

        dispatch(actions.initFetchingHistory(false));
        dispatch(actions.setSelectedTillOperationId(row.tillOperationId));
      }
    } catch (e: any) {
      dispatch(actions.initFetchingHistory(true));
    }
  };

  return (
    <>
      <StyledModal
        title={TILL_LABELS.BUTTON.TILL_HISTORY}
        open={visible}
        width={1300}
        centered
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        footer={[
          <Row gutter={24} justify="start" key="tillHistory">
            <Col>
              <Button
                itemProp="secondary"
                key="cancelTillHistory"
                onClick={onCancelClick}
              >
                Exit
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Row>
          <Col span={17}>
            <Row>
              <Col span={24}>
                <Form
                  onFinish={onSearch}
                  layout="vertical"
                  form={form}
                  initialValues={{
                    currency: TILL_LABELS.SELECT_ALL_CURRENCIES,
                  }}
                >
                  <Row>
                    <Col span={6}>
                      <StyledDiv>
                        <Form.Item
                          label="User"
                          className="label-control text-weight"
                        >
                          <Select
                            value={tillUser}
                            onChange={onTillUserChange}
                            options={storeTillUsers?.map((el: any) => {
                              return {
                                value: el.userId,
                                label: el.displayName,
                              };
                            })}
                          />
                        </Form.Item>
                      </StyledDiv>
                    </Col>
                    <Col span={6}>
                      <StyledDiv>
                        <Form.Item
                          label="Currency"
                          className="label-control text-weight"
                          name="currency"
                        >
                          <Select
                            options={currencyList?.map((el: any) => {
                              return {
                                value: el.currencyCode,
                                label: `${el?.currencyDescription} (${el?.currencyCode})`,
                              };
                            })}
                          />
                        </Form.Item>
                      </StyledDiv>
                    </Col>
                    <Col span={5}>
                      <StyledDiv>
                        <Form.Item
                          label="Date From"
                          name="dateFrom"
                          className="label-control text-weight"
                          rules={[
                            {
                              required: true,
                              message: 'From Date is required',
                            },
                          ]}
                        >
                          <DatePickerMUI
                            disableFutureDates
                            initialValue={moment(new Date())}
                            onChange={(date: any) =>
                              form.setFieldsValue({ dateFrom: date })
                            }
                          />
                        </Form.Item>
                      </StyledDiv>
                    </Col>
                    <Col span={5}>
                      <StyledDiv>
                        <Form.Item
                          label="Date To"
                          name="dateTo"
                          className="label-control text-weight"
                          rules={[
                            { required: true, message: 'To Date is required' },
                          ]}
                        >
                          <DatePickerMUI
                            disableFutureDates
                            initialValue={moment(new Date())}
                            onChange={(date: any) =>
                              form.setFieldsValue({ dateTo: date })
                            }
                          />
                        </Form.Item>
                      </StyledDiv>
                    </Col>
                    <ButtonCol span={2}>
                      <Button
                        itemProp="secondary"
                        htmlType="submit"
                        loading={isLoading}
                      >
                        Search
                      </Button>
                    </ButtonCol>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="cash-mgmt-summary-div">
                Summary
              </Col>
              <Spin spinning={fetchingHistory}>
                <Col span={24} className="till-history-grid">
                  <TillHistoryTable onRowClick={getOperationIdHistory} />
                </Col>
              </Spin>
            </Row>
          </Col>

          <Col span={7}>
            <StyledTableWrapper>
              <HistoryDenominationTable
                rows={currencyDenominations}
                isMessageContentAvailable={isDenomDataAvailable}
              />
              <StyledTotalRow justify="space-between">
                <Col span={12}>Total</Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: currencySymbol,
                    }}
                  ></span>
                  <span>{totalAmount.toFixed(2)}</span>
                </Col>
              </StyledTotalRow>
            </StyledTableWrapper>
          </Col>
        </Row>
      </StyledModal>
    </>
  );
};

export default TillHistoryPopup;
