import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { format } from 'date-fns';
import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';
import { actions } from 'redux/reducers/inventoryManagement';
import VirtualGrid from 'components/common/virtualGrid/index';
import ImageLoader from 'components/common/ImageLoader';

interface NewStockTransferSummaryProps {
  stockTransferList: Array<any>;
}

const TableRow = ({ row, onClickTableRow, ...restProps }: any) => {
  const onRowClick = () => {
    onClickTableRow(row);
  };

  const Cell = () => {
    return (
      <Table.Cell
        onClick={onRowClick}
        {...restProps}
        style={{
          cursor: 'pointer',
        }}
      />
    );
  };

  return <>{Cell()}</>;
};

const NewStockTransferSummary = ({
  stockTransferList = [],
}: NewStockTransferSummaryProps) => {
  const dispatch = useDispatch();

  const [columns] = useState([
    {
      name: 'count',
      title: 'Count',
      getCellValue: (row: any) => row?.index,
    },
    {
      name: 'dateAdded',
      title: 'Date Added',
      getCellValue: (row: any) =>
        row && row?.dateAdded
          ? format(new Date(row?.dateAdded), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'image',
      title: 'Image',
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row?.itemId}`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'barcode',
      title: 'Barcode',
      getCellValue: (row: any) => row?.barcode,
    },
    {
      name: 'description',
      title: 'Description',
      getCellValue: (row: any) => row?.description,
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) => `${(+row?.weight)?.toFixed(2)}g`,
    },
    {
      name: 'retailPrice',
      title: 'Retail Price',
      type: 'currency',
      getCellValue: (row: any) => row?.listValue,
    },
    {
      name: 'costPrice',
      title: 'Cost Price',
      type: 'currency',
      getCellValue: (row: any) => row?.costValue,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'count', width: '8%', align: 'center' },
    { columnName: 'dateAdded', width: '11%' },
    { columnName: 'image', width: '10%' },
    { columnName: 'barcode', width: '11%' },
    { columnName: 'description', width: '20%' },
    { columnName: 'weight', width: '11%', align: 'right' },
    { columnName: 'retailPrice', width: '11%', align: 'right' },
    { columnName: 'costPrice', width: '13%', align: 'right' },
  ]);

  const [formattedColumns] = useState(['delete', 'retailPrice', 'costPrice']);

  const onSelectionChange = (rowIds: any) => {
    dispatch(actions.setSelectedItemIds(rowIds));
  };

  const onClickTableRow = (row: any) => { /* do nothing */ };

  return (
    <div key="transferSummary" className="create-transafer">
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        formattedColumns={formattedColumns}
        rowData={stockTransferList}
        cellComponent={(restProps: any) => (
          <TableRow onClickTableRow={onClickTableRow} {...restProps} />
        )}
        rowSelectionEnable={true}
        showSelectionColumn={true}
        showSelectAll={false}
        onSelectionChange={onSelectionChange}
        selectByRowClick={true}
        highlightRow={true}
      />
    </div>
  );
};

export default NewStockTransferSummary;
