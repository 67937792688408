import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Col, Modal, notification, Row } from 'antd';
import { BUTTON_LABELS } from 'components/pledgemanagement/constants';
import { Button } from 'components/common/Button';

import { actions as pledgeMngmtActions } from 'redux/reducers/pledgeManagement';
import { PLEDGE_NOTICE_TYPE } from 'components/pledgemanagement/constants';
import { printNPAStatement } from 'services/pledgeManagement';
import { sendToPrinter } from 'services/user';

const StyledCols = styled(Col)`
  padding-bottom: 19px;
`;

interface PledgeNoticeProps {
  visible: boolean;
  selectedAgreement: Array<any>;
  noticeType: string;
  textConstants: any;
}

const PledgeNoticePopup = ({
  visible,
  selectedAgreement,
  noticeType = '',
  textConstants,
}: PledgeNoticeProps) => {
  const { loading } = useSelector((state: any) => state.pledgeManagement);

  const dispatch = useDispatch();

  const onCancelClick = () => {
    dispatch(pledgeMngmtActions.setPledgeNoticeType(''));
    dispatch(pledgeMngmtActions.setIsPledgeNoticePopupVisible(false));
  };

  const onPrintNPAStatement = async () => {
    try {
      dispatch(pledgeMngmtActions.initLoading(true));

      const pdf = await printNPAStatement();
      if (pdf?.data) sendToPrinter(pdf, 'printcontract', '_NPA_Statement');
      dispatch(pledgeMngmtActions.initLoading(false));
    } catch (e: any) {
      dispatch(pledgeMngmtActions.initLoading(false));
      notification.error({
        message: e?.response?.data?.error,
        duration: 3,
      });
    }
  };

  const onContinueClick = () => {
    dispatch(pledgeMngmtActions.setIsPledgeNotesPopupVisible(true));
    dispatch(pledgeMngmtActions.setIsPledgeNoticePopupVisible(false));
  };

  return (
    <>
      <Modal
        centered
        width={550}
        title={textConstants.TITLE}
        open={visible}
        className="confirm-vulnerable-wrapper"
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        footer={[
          <Row gutter={24} key="confirmVulnerablePopup">
            <Col span={6} style={{ textAlign: 'left' }}>
              <Button
                itemProp="secondary"
                key="confirmVulnerablePopupCancel"
                onClick={onCancelClick}
              >
                {BUTTON_LABELS.CANCEL}
              </Button>
            </Col>
            <Col span={12} style={{ textAlign: 'center' }}>
              {noticeType === PLEDGE_NOTICE_TYPE.CONFISCATE && (
                <Button
                  loading={loading}
                  itemProp="secondary"
                  onClick={() => onPrintNPAStatement()}
                >
                  {BUTTON_LABELS.PRINT_NPA_STATEMENT}
                </Button>
              )}
            </Col>
            <Col span={6}>
              <Button
                itemProp="secondary"
                key="confirmVulnerableContinue"
                onClick={() => onContinueClick()}
              >
                {BUTTON_LABELS.CONTINUE}
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <>
          <Row>
            <StyledCols span={24}>
              {selectedAgreement?.map((agreement: any, ind: number) => (
                <p className="loan-no-list" key={ind}>
                  {agreement?.loanNumber}
                </p>
              ))}
            </StyledCols>
          </Row>
          {noticeType === PLEDGE_NOTICE_TYPE.CONFISCATE && (
            <Row>
              <Col span={24}>Have you been presented with a DPA-29 Notice?</Col>
            </Row>
          )}
          <Row className="confirm-text">
            <Col span={24}>{textConstants.CONTENT}</Col>
          </Row>
          <Row>
            <Col span={24}>
              The pledge(s) cannot be picked-up, renewed or PFI&apos;d in this state.
            </Col>
          </Row>
        </>
      </Modal>
    </>
  );
};

export default PledgeNoticePopup;
