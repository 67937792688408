import React, { useState } from 'react';
import { Col, Form, Modal, Row, Input } from 'antd';
import styled from 'styled-components';
import { CODE_MESSAGES, PAYMENT_POPUP } from './constants';
import { useDispatch } from 'react-redux';
import { actions } from 'redux/reducers/payment';
import { Button } from 'components/common/Button';

const StyledTitle = styled.div<{
  fontSize: string;
  width: string;
  padding: string;
}>`
  font-weight: var(--font-weight-500);
  font-size: var(--${(props) => props.fontSize});
  line-height: 30px;
  color: var(--black);
  text-align: center;
  width: ${(props) => props.width};
  padding-bottom: ${(props) => props.padding};
`;

const StyledRow = styled(Row)`
  display: flex;
  justify-content: center;
`;

const PaymentConfimationCodePopup = ({
  visible,
  setpaymentConfimationCodePopupVisible,
  onOkClick,
}: any) => {
  const onCancelClick = () => {
    setpaymentConfimationCodePopupVisible();
  };

  const [isDisableAddButton, setDisableAddButton] = useState<boolean>(true);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const setCode = (value: any) => {
    dispatch(actions.paymentConfirmationCode(value));
  };
  return (
    <>
      <Modal
        title={''}
        open={visible}
        width={487}
        onCancel={onCancelClick}
        footer={[
          <Row justify="space-between" key="paymentTransfer">
            <Col>
              <Button
                itemProp="secondary"
                key="cancelPaymentTransfer"
                onClick={onCancelClick}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                itemProp="secondary"
                key="proceedPaymentTransfer"
                onClick={onOkClick}
                disabled={isDisableAddButton}
              >
                OK
              </Button>
            </Col>
          </Row>,
        ]}
        closable={false}
        centered
      >
        <StyledRow>
          <StyledTitle fontSize="font-size-20" width="30rem" padding="25px">
            {PAYMENT_POPUP.TITLE}
          </StyledTitle>
          <Form form={form} layout="vertical">
            <StyledRow>
              <StyledTitle fontSize="font-size-18" width="35rem" padding="10px">
                {PAYMENT_POPUP.LABEL1}
              </StyledTitle>
              <Col span={10}>
                <Form.Item
                  label=""
                  name="code"
                  className="label-control"
                  rules={[
                    {
                      required: true,
                      message: CODE_MESSAGES.CODE_REQUIRED,
                    },
                    {
                      pattern: CODE_MESSAGES.CODE_PATTERN,
                      message: CODE_MESSAGES.CODE_PATTERN_INVALID,
                    },
                  ]}
                >
                  <Input
                    placeholder="e.g.123456"
                    style={{ textAlign: 'center' }}
                    className="input-control"
                    autoComplete="off"
                    onChange={(e) =>
                      e.target.value.length >= 6
                        ? setDisableAddButton(false)
                        : setDisableAddButton(true)
                    }
                    onBlur={(e) => setCode(e.target.value)}
                    autoFocus={true}
                  />
                </Form.Item>
              </Col>
            </StyledRow>
          </Form>
        </StyledRow>
      </Modal>
    </>
  );
};

export default PaymentConfimationCodePopup;
