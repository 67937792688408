import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { EMPTY_BASKET } from '../constants';
import { DELETE_BASKET } from 'action_creators/basket';
import { actions as basketActions } from 'redux/reducers/basket';
import { actions } from 'redux/reducers/customer';
import { actions as pickupActions } from 'redux/reducers/pickupRenew';
import { actions as retailActions } from 'redux/reducers/retail';
import { actions as fxActions } from 'redux/reducers/travelmoney';
import { actions as chequeCashingActions } from 'redux/reducers/chequeCashing';
import { Button } from 'components/common/Button';

const StyledContainerCol = styled(Col)`
  padding: 20rem 0 32rem 0;
  height: calc(100vh - 187px);
`;

const StyledEmptyBasketCol = styled(Col)`
  font-size: 32px;
  font-weight: var(--font-weight-500);
`;

const StyledDiv = styled.div`
  font-size: var(--font-size-22);
  font-weight: var(--font-weight-500);
  padding: 30px 0;
`;

const EmptyBasket = () => {
  const {
    basket: { basket },
  } = useSelector((state: any) => {
    return {
      basket: state.basket,
    };
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onExitAndClearClick = () => {
    if (basket?.basketId !== null) {
      dispatch({
        type: DELETE_BASKET,
        payload: basket?.basketId,
      });
    }
    dispatch(actions.clearAll({})); //clear customer session
    dispatch(basketActions.clearItems()); //clear basket
    //TODO -change in phase 1.1 - start
    dispatch(pickupActions.clearItems()); //clear pickup
    dispatch(retailActions.clearItems()); // clear retail
    dispatch(fxActions.clearFx()); //clear tavel money
    dispatch(chequeCashingActions.clearItems()); //clear cheque-cashing
    //TODO -change in phase 1.1 - end
    navigate(`/`);
  };

  return (
    <>
      <Row>
        <StyledContainerCol span={24}>
          <Row>
            <StyledEmptyBasketCol span={24}>
              {EMPTY_BASKET.EMPTY}
            </StyledEmptyBasketCol>
          </Row>
          <Row>
            <Col span={24}>
              <StyledDiv
                dangerouslySetInnerHTML={{ __html: EMPTY_BASKET.SERVICE }}
              ></StyledDiv>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button onClick={onExitAndClearClick} itemProp="secondary">
                Exit & Clear
              </Button>
            </Col>
          </Row>
        </StyledContainerCol>
      </Row>
    </>
  );
};

export default EmptyBasket;
