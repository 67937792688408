import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customer: {},
  liteCustomer: {},
  guestType: '', //Valuation guest/FX guest.....
  selectedCustomer: {}, //passing details to surplus popup
  error: {},
  loading: false,
  message: '',
  imageData: '',
  docData: '',
  isOCRLoading: false,
  documentTypeId: null,
  isProfileSaved: false,
  isSaving: false,
  isImageUploaded: false,
  isImageUploading: false,
  isDocUploading: false,
  isDocUploaded: false,
  isGdprAccepted: false,
  isRedirectToOtherPage: true, //when finding a customer
  isStoreEnquiryMode: false,
  isPhoneEnquiryMode: false,
  isVATDetailsAvailable: false, //when customer records details for VAT true, else false
  isCustomerSessionActive: false, //when in a customer session it will be true, else false
  allTimeCustomerLoansCount: null, // total loans of the customer till date - to check new customer's 1st loans
  isRecallCheckoutCustomer: false, // to check if customer is recalled on checkout
  isNewCustomerGenerateFromFinder: false, //to Check if VAT customer Popup open and create new customer from Finder popup
  isCustFromHeader: false,
  resumeJourneyUrl: '',
  isSurplusPopupVisible: false, // if customer surplus amount available, set this to true
  customerSurplusData: [],
  lastVisits: [],
  isLastVisitsLoading: false,
  isRedirectToEditPage: false,
  isSurplusLoading: false, //to run surplus check
  newCustomerDocs: [],
  isCustomerDocUploading: false,
  isLiteCustomerLoading: false,
  inputValue: '',
  isLiteDocRequired: false,
  isLiteDocUploaded: false,
  isLiteDocUploading: false,
  isCustomerCompleteProfileLoaded: false,
  isVulnerablePopupVisible: false,
  isVulnerableConfirmed: false,
  isCustomerInStopList: false,
  isCustomerAddedToStopList: false,
  isCustomerImageUpdated: false,

  customertPastTransactionAmount: 0,
  customerFxDocumentId: 0,
  isDocumentPrinting: false,
  customerStatusColor: '',
  thirdPartyAssistantDocs: [],
  tpaList: [],
  tpaImageData: {},
  isTpaEnquiryMode: false,
  isTpaDetailsAdded: false,
  nomineeImageUploadingStatus: {},
};
const slice = createSlice({
  name: 'customerActions',
  initialState,
  reducers: {
    initOCRRequest(state, action) {
      state.isOCRLoading = true;
    },
    setCustomerDetails(state, action) {
      state.customer = action.payload;
    },
    setOCRResult(state, action) {
      state.customer = action.payload;
      state.isOCRLoading = false;
    },
    getCustomerRequest(state, action) {
      state.loading = true;
    },
    getCustomerResponse(state, action) {
      state.customer = action.payload;
      state.loading = false;
      state.isCustomerSessionActive = true;
    },
    getCustomerError(state, action) {
      state.error = {
        message: action.payload.message,
        description: action?.payload?.response?.data?.error,
      };
      state.loading = false;
    },
    uploadImageRequest(state) {
      state.isImageUploading = true;
      state.isImageUploaded = false;
    },
    uploadImageResponse(state, action) {
      state.isImageUploading = false;
      state.message = action?.payload;
      state.isImageUploaded = true;
      state.isCustomerImageUpdated = true;
      state.imageData = '';
    },
    uploadImageError(state, action) {
      state.isImageUploading = false;
      state.isImageUploaded = false;
      state.imageData = '';
      state.error = {
        message: action.payload.message,
        description: action?.payload?.response?.data?.error,
      };
    },
    customerSaveRequest(state, action) {
      state.isSaving = true;
      state.isImageUploaded = false;
    },
    customerSaveResponse(state, action) {
      state.customer = action.payload;
      state.isProfileSaved = true;
      state.isSaving = false;
      state.isImageUploading = Boolean(state?.imageData);
      state.isImageUploaded = false;
    },
    customerSaveError(state, action) {
      state.isProfileSaved = false;
      state.isSaving = false;
      state.isImageUploaded = false;
      state.error = {
        message: action.payload.message,
        description: action?.payload?.response?.data?.error,
      };
    },
    setImage(state, action) {
      state.imageData = action.payload;
    },
    setDocumentType(state, action) {
      state.documentTypeId = action.payload;
    },
    setDocument(state, action) {
      state.docData = action.payload;
    },
    setNewCustomerDocs(state, action) {
      state.newCustomerDocs = action.payload;
    },
    uploadDocRequest(state) {
      state.isDocUploading = true;
      state.isDocUploaded = false;
    },
    uploadDocResponse(state, action) {
      state.isDocUploading = false;
      state.message = action?.payload;
      state.isDocUploaded = true;
    },
    uploadImageAndDocResponse(state, action) {
      return {
        ...state,
        ...action?.payload,
      };
    },
    uploadImageAndDocUploaded(state, action) {
      state.isDocUploading = false;
      state.isDocUploaded = true;
      state.isImageUploaded = true;
    },
    uploadImageAndDocUploadingError(state, action) {
      state.isDocUploading = false;
      state.isImageUploading = false;
      state.isDocUploaded = false;
      state.isImageUploaded = false;
      state.isCustomerDocUploading = false;
    },
    uploadDocError(state, action) {
      state.isDocUploading = false;
      state.isDocUploaded = false;
      state.isRedirectToEditPage = true;
      state.isCustomerDocUploading = false;
      state.docData = '';
      state.error = {
        message: action?.payload?.message,
        description: action?.payload?.response?.data?.error,
      };
    },
    setSelectedCustomer(state, action) {
      state.selectedCustomer = action.payload;
    },
    initSurplusCheck(state, action) {
      state.isSurplusLoading = action.payload;
    },
    clearCustomerDocs(state) {
      state.newCustomerDocs = [];
    },
    clearUploadDoc(state) {
      //state.docData = '';
      state.isProfileSaved = false;
      state.isDocUploading = false;
      state.isDocUploaded = false;
      state.isRedirectToEditPage = false;
      state.message = '';
      state.error = {};
    },
    clearUploadImage(state) {
      state.imageData = '';
      state.isProfileSaved = false;
      state.isImageUploading = false;
      state.isImageUploaded = false;
      state.message = '';
    },
    clearCustomerSave(state, action) {
      state.isProfileSaved = false;
      state.error = '';
    },
    setRedirectToOtherPage(state, action) {
      state.isRedirectToOtherPage = action.payload;
    },
    setStoreEnquiryMode(state, action) {
      state.isStoreEnquiryMode = action.payload;
    },
    setPhoneEnquiryMode(state, action) {
      state.isPhoneEnquiryMode = action.payload;
    },
    setGdprAcceptance(state, action) {
      state.isGdprAccepted = action.payload;
    },
    setAllTimeCustomerLoansCount(state, action) {
      state.allTimeCustomerLoansCount = action.payload;
    },
    setRecallCheckoutCustomer(state, action) {
      state.isRecallCheckoutCustomer = action.payload;
    },
    setVATDetailsAvailable(state, action) {
      state.isVATDetailsAvailable = action.payload.value;
    },
    setNewCustomerGenerateFromFinder(state, action) {
      state.isNewCustomerGenerateFromFinder = action.payload;
    },
    setCustomerFromHeader(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setCustomerSurplusPopopVisible(state, action) {
      state.isSurplusPopupVisible = action.payload;
    },
    setCustomerSurplusData(state, action) {
      state.customerSurplusData = action.payload;
    },
    initLastVisitsLoading(state, action) {
      state.isLastVisitsLoading = action.payload;
    },
    setLastVisitsData(state, action) {
      state.lastVisits = action.payload;
    },
    setIsCustomerDocUploading(state, action) {
      state.isCustomerDocUploading = action.payload;
    },
    setGuestType(state, action) {
      state.guestType = action.payload;
    },
    setLiteCustomerDetails(state, action) {
      state.liteCustomer = action.payload;
    },
    setIsLiteCustomerLoading(state, action) {
      state.isLiteCustomerLoading = action.payload;
    },
    setInputValue(state, action) {
      state.inputValue = action.payload;
    },
    setIsLiteDocRequired(state, action) {
      state.isLiteDocRequired = action.payload;
    },
    setIsLiteDocUploaded(state, action) {
      state.isLiteDocUploaded = action.payload;
    },
    setIsLiteDocUploading(state, action) {
      state.isLiteDocUploading = action.payload;
    },
    setIsCustomerCompleteProfileLoaded(state, action) {
      state.isCustomerCompleteProfileLoaded = action.payload;
    },

    setIsVulnerablePopupVisible(state, action) {
      state.isVulnerablePopupVisible = action.payload;
    },
    setIsVulnerableConfirmed(state, action) {
      state.isVulnerableConfirmed = action.payload;
    },
    setIsCustomerInStopList(state, action) {
      state.isCustomerInStopList = action.payload;
    },
    setIsCustomerAddedToStopList(state, action) {
      state.isCustomerAddedToStopList = action.payload;
    },

    setCustomertPastTranactionAmount(state, action) {
      state.customertPastTransactionAmount = action.payload;
    },
    setCustomerFxDocumentId(state, action) {
      state.customerFxDocumentId = action.payload;
    },
    setIsDocumentPrinting(state, action) {
      state.isDocumentPrinting = action.payload;
    },
    setCustomerVulnerabilityStatus(state, action) {
      state.customerStatusColor = action.payload;
    },
    setThirdPartyAssistantDocs(state, action) {
      state.thirdPartyAssistantDocs = action.payload;
    },
    clearThirdPartyAssistantDocs(state) {
      state.thirdPartyAssistantDocs = [];
    },
    setTpaList(state, action) {
      state.tpaList = action.payload;
    },
    setTpaDetailsAdded(state, action) {
      state.isTpaDetailsAdded = action.payload;
    },
    uploadTpaImageError(state, action) {
      // state.tpaImageData = '';
      state.error = {
        message: action.payload.message,
        description: action?.payload?.response?.data?.error,
      };
    },
    clearUploadTpaImage(state, action) {
      state.tpaImageData = {
        [action.payload]: '',
      };
    },
    setTpaImageData(state, action) {
      state.tpaImageData = {
        [action.payload.nomineeRowIndex]: action.payload.image,
      };
    },
    setNomineeImageUploadingStatus(state, action) {
      state.nomineeImageUploadingStatus = action.payload;
    },
    setTpaEnquiryMode(state, action) {
      state.isTpaEnquiryMode = action.payload;
    },

    clearAll(state, action) {
      return {
        ...initialState,
      };
    },
  },
});

export default slice.reducer;
export const { reducer, actions } = slice;
export { initialState };
