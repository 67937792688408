export const CURRENCIES_REQUEST = 'CURRENCIES_REQUEST';
export const BUYBACK_CHARGES_REQUEST = 'BUYBACK_CHARGES_REQUEST';
export const CONVERT_CURRENCY_REQUEST = 'CONVERT_CURRENCY_REQUEST';
export const UPDOWN_CURRENCY_REQUEST = 'UPDOWN_CURRENCY_REQUEST';
export const CURRENCY_LEDGER_BALANCE = 'CURRENCY_LEDGER_BALANCE';
export const CURRENCY_LOGCASH_SUM = 'CURRENCY_CLOGCASH_SUM';
export const CURRENCY_STOCK_BALANCE_REQUEST = 'CURRENCY_STOCK_BALANCE_REQUEST';
export const CURRENCY_BUYBACK_HISTORY_REQUEST =
  'CURRENCY_BUYBACK_HISTORY_REQUEST';
export const POST_FX_TRANSACTION = 'POST_FX_TRANSACTION';
