export const AUTHORIZE_VOID_TRANSACTION = 'AUTHORIZE_VOID_TRANSACTION';
export const GET_VOID_TRANSACTIONS = 'GET_VOID_TRANSACTIONS';

//USER MANAGER ACTIONS
export const ADMIN_STORE_USERS = 'ADMIN_STORE_USERS';
export const ADMIN_USER_DETAILS = 'ADMIN_USER_DETAILS';
export const ADMIN_UPDATE_USER_DETAILS = 'ADMIN_UPDATE_USER_DETAILS';
export const ADMIN_GET_USER_PERMISSIONS = 'ADMIN_GET_USER_PERMISSIONS';
export const ADMIN_GET_DEFAULT_PERMISSIONS = 'ADMIN_GET_DEFAULT_PERMISSIONS';
export const GET_GROUP_PERMISSIONS = 'GET_GROUP_PERMISSIONS';
export const SAVE_USER_PERMISSIONS = 'SAVE_USER_PERMISSIONS';

//TRAVEL MONEY RATES ACTIONS
export const GET_TRAVEL_MONEY_RATES = 'GET_TRAVEL_MONEY_RATES';
export const GET_TRAVEL_MONEY_RATES_HISTORY = 'GET_TRAVEL_MONEY_RATES_HISTORY';
export const CREATE_TRAVEL_MONEY_RATES = 'CREATE_TRAVEL_MONEY_RATES';
