import { call, put, takeEvery } from 'redux-saga/effects';
import {
  SEARCH_TRANSFER,
  STORES_REQUEST,
  GET_TRANSFER_ITEMS,
  DELETE_TRANSFER_ITEMS,
  AUTHORIZE_STOCK_TRANSFER,
  SEND_STOCK_TRANSFER,
  ACCEPT_STOCK_TRANSFER,
} from 'action_creators/inventoryManagement';

import {
  getTransferItems,
  postTransferSearch,
  postPrintStockTransfer,
  putTransferAuthorization,
  putSendTransfer,
  putAcceptTransfer,
  deleteTransferItems,
  getStores,
} from 'services/inventoryManagement';
import { cacheStaticData } from 'utils/cacheServices';

import { actions } from 'redux/reducers/inventoryManagement';
import { sendToPrinter } from 'services/user';
import { RESPONSE_STATUS } from 'globalConstants';

const genericError = 'Something went wrong. Please try again later';
const noContentError = 'No content found';

export function* searchTransferSaga(action) {
  const {
    payload: { params, transferStatus },
  } = action;
  const isClosed = transferStatus === 'closed' ? true : false;

  try {
    yield put(actions.initSearch(true));
    const response = yield call(postTransferSearch, params);
    if (response?.data) {
      yield put(actions.initSearch(false));
      yield put(actions.setTransferSearchList(response?.data?.transferItems));
      yield put(actions.setClosedTransfer(isClosed));
    }
  } catch (e) {
    yield put(actions.initSearch(false));
    yield put(actions.logError(e?.response?.data?.error || genericError));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* getStoresSaga() {
  try {
    const response = yield call(cacheStaticData, getStores);
    if (response?.data) {
      yield put(actions.setDestinationStores(response.data));
    }
  } catch (e) {
    /* continue regardless of error */
  }
}

export function* getTrasnferItemsSaga(action) {
  const {
    payload: { direction, transferId },
  } = action;
  try {
    yield put(actions.initLoading(true));
    const response = yield call(getTransferItems, transferId);
    if (response?.data) {
      const transferParams = {
        destinationStoreId: response?.data.destinationStoreId,
        transferId: transferId,
        sourceStoreId: response?.data.sourceStoreId,
        sentApprovedBy: response?.data.sentApprovedBy,
        transferReference: response?.data.transferReference,
      };

      //setting count value for table display
      response.data.transferItemsDetails.forEach(
        (el, index) => (el.index = index + 1)
      );

      if (direction === 'Inbound') yield put(actions.setAcceptTransfer(true));
      yield put(actions.initLoading(false));
      yield put(actions.setTransferParams(transferParams));
      yield put(actions.setEditMode(true));
      yield put(
        actions.setTransferItemsList(response.data.transferItemsDetails)
      );
    } else if (response?.status === RESPONSE_STATUS.NO_CONTENT) {
      yield put(actions.initLoading(false));
      yield put(actions.logError(noContentError));
      yield put(actions.setIsErrorVisible(true));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error || genericError));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* deleteTransferItemsSaga(action) {
  const {
    payload: { params, transferItemsList },
  } = action;

  try {
    yield put(actions.initDelete(true));
    const response = yield call(deleteTransferItems, params);
    if (response?.data) {
      //deleting items from transferList
      const deleteItemList = response.data.items;
      const transferList = [...transferItemsList];

      deleteItemList.forEach((el) => {
        let index = transferList.findIndex((x) => x.itemId === el);
        transferList.splice(index, 1);
      });

      yield put(actions.initDelete(false));
      yield put(actions.setSelectedItemIds([]));
      yield put(actions.setTransferItemsList(transferList));
      yield put(actions.newItemAdded(true));
    }
  } catch (e) {
    actions.initDelete(false);
  }
}

export function* stockTransferAuthorizationSaga(action) {
  const {
    payload: { params, authorizationHeaders },
  } = action;

  try {
    yield put(actions.initAuthorization(true));
    const response = yield call(
      putTransferAuthorization,
      params,
      authorizationHeaders
    );
    if (response?.data) {
      yield put(actions.logSuccess('Authorisation Successful'));
      yield put(actions.setAuthorizerName(response.data.displayName));
      yield put(actions.initAuthorization(false));
      yield put(actions.setAuthorization(true));
      yield put(actions.setIsSuccessMsgVisible(true));
    }
  } catch (e) {
    yield put(actions.logError(e?.response?.data?.error || genericError));
    yield put(actions.initAuthorization(false));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* sendStockTransferSaga(action) {
  const {
    payload: { params, printParams },
  } = action;

  try {
    yield put(actions.initSubmit(true));
    const response = yield call(putSendTransfer, params);
    if (response?.data) {
      const download = yield call(postPrintStockTransfer, printParams);
      if (download?.data)
        // createWindowURL('', download.data, '_StockTransferReceipt');
        sendToPrinter(download, 'printcontract', '_StockTransferReceipt');

      yield put(
        actions.logSuccess(
          'Stock Transfer send successfully, printing receipt.'
        )
      );
      yield put(actions.setIsSuccessMsgVisible(true));
      yield put(actions.initSubmit(false));
      yield put(actions.exitCreateEditTransfer());
    }
  } catch (e) {
    yield put(actions.logError(e?.response?.data?.error || genericError));
    yield put(actions.initSubmit(false));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* acceptStockTransferSaga(action) {
  const {
    payload: { params, printParams },
  } = action;

  try {
    yield put(actions.initSubmit(true));
    const response = yield call(putAcceptTransfer, params);
    if (response?.data) {
      const download = yield call(postPrintStockTransfer, printParams);
      if (download?.data)
        //createWindowURL('', download.data, '_StockTransferReceipt');
        sendToPrinter(download, 'printcontract', '_StockTransferReceipt');

      yield put(
        actions.logSuccess('Stock Transfer accepted, printing receipt')
      );
      yield put(actions.setIsSuccessMsgVisible(true));
      yield put(actions.initSubmit(false));
      yield put(actions.exitCreateEditTransfer());
    }
  } catch (e) {
    yield put(actions.logError(e?.response?.data?.error || genericError));
    yield put(actions.initSubmit(false));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* watchSearchTransferSaga() {
  yield takeEvery(SEARCH_TRANSFER, searchTransferSaga);
}

export function* watchGetStoresSaga() {
  yield takeEvery(STORES_REQUEST, getStoresSaga);
}

export function* watchGetTransferItemsSaga() {
  yield takeEvery(GET_TRANSFER_ITEMS, getTrasnferItemsSaga);
}

export function* watchDeleteTransferItemsSaga() {
  yield takeEvery(DELETE_TRANSFER_ITEMS, deleteTransferItemsSaga);
}

export function* watchStockTransferAuthorizationSaga() {
  yield takeEvery(AUTHORIZE_STOCK_TRANSFER, stockTransferAuthorizationSaga);
}

export function* watchSendStockTransferSaga() {
  yield takeEvery(SEND_STOCK_TRANSFER, sendStockTransferSaga);
}

export function* watchAcceptStockTransferSaga() {
  yield takeEvery(ACCEPT_STOCK_TRANSFER, acceptStockTransferSaga);
}
