import React, { useState, useEffect } from 'react';
import { Layout, Spin, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import HorizontalTabs from 'components/common/HorizontalTabs';
import {
  LedgerManager,
  AuctionOverManager,
  PFIManager,
  AuctionManager,
  ConfiscationsManager,
  VulnerableManager,
} from 'components/pledgemanagement/widgets';
import { PLEDGE_MANAGEMENT_TABS } from 'globalConstants';
import { actions } from 'redux/reducers/pledgeManagement';
import './index.less';

const StyledMenuLayout = styled(Layout)`
  &.control-box {
    padding: 0;
  }
`;

const PledgeManagement = () => {
  const { loading, isErrorVisible, error, successMsg, tabsVisible } =
    useSelector((state: any) => state.pledgeManagement);
  const [activeMenu, setActiveMenu] = useState<string>(
    PLEDGE_MANAGEMENT_TABS.CONFISCATIONS
  );
  const currentActiveMenu = (menu: string) => {
    setActiveMenu(menu);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (isErrorVisible) {
      notification.error({
        message: error,
        duration: 3,
      });
      dispatch(actions.setIsErrorVisible(false));
    }
    if (successMsg) {
      notification.success({
        message: successMsg,
        duration: 3,
      });
      dispatch(actions.setIsErrorVisible(false));
    }
  }, [isErrorVisible, successMsg]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {tabsVisible && (
        <StyledMenuLayout className="control-box">
          <HorizontalTabs
            onMenuClick={currentActiveMenu}
            initialPanes={PLEDGE_MANAGEMENT_TABS.INITIALPANES}
            activeKey={activeMenu}
          />
        </StyledMenuLayout>
      )}
      <Layout className="pledge-management-wrapper split-control-box">
        <Spin spinning={loading}>
          <div className="main-container-wrapper">
            {activeMenu === PLEDGE_MANAGEMENT_TABS.LEDGER && <LedgerManager />}
            {activeMenu === PLEDGE_MANAGEMENT_TABS.AUCTION_OVER && (
              <AuctionOverManager />
            )}
            {activeMenu === PLEDGE_MANAGEMENT_TABS.PFI && <PFIManager />}
            {activeMenu === PLEDGE_MANAGEMENT_TABS.AUCTION_MANAGER && (
              <AuctionManager />
            )}
            {activeMenu === PLEDGE_MANAGEMENT_TABS.CONFISCATIONS && (
              <ConfiscationsManager />
            )}
            {activeMenu === PLEDGE_MANAGEMENT_TABS.VULNERABLE && (
              <VulnerableManager />
            )}
          </div>
        </Spin>
      </Layout>
    </>
  );
};

export default PledgeManagement;
