import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { notification } from 'antd';

import { CUSTOMER_PROFILE_REQUEST } from 'action_creators/customer';
import {
  PROCESS_CONSUMERDUTY_REQUEST,
  RESET_CUSTOMER_DATA,
  GET_CASE_SUMMARY,
  GET_LOCKED_PLEDGE_DETAILS,
} from 'action_creators/consumerDuty';
import { GET_CUSTOMER_PAWNBROKING_TAB_SUMMARY } from 'action_creators/pledge';

import { getCustomerProfileSagaInBackground } from 'sagas/customer';
import { cacheCustomerStatus, clearCacheByCacheKey } from 'utils/cacheServices';

import { actions as consumerDutyActions } from 'redux/reducers/consumerDuty';
import { actions as userActions } from 'redux/reducers/user';

import { postViewNotificationItem } from 'services/workQueue';
import { getLockedPledgeDetails } from 'services/consumerDuty';
import { getCaseSummay } from 'services/consumerDuty';

import { GENERIC_ERROR, ROUTE_CONFIG } from 'globalConstants';
import {
  TASK_OWNER_VALUE,
  VULNERABILITYORIGINATOR,
  NOTIFICATION_SERVICE_TYPE,
} from 'components/consumerDuty/constants';
import { STATUS_LABELS } from 'components/notifications/constants';

export function* resetCustomerDataSaga() {
  try {
    const { customer } = yield select((state) => state.customer);
    yield call(clearCacheByCacheKey, cacheCustomerStatus, customer?.customerId);
    yield delay(3000);

    yield put({
      type: GET_CUSTOMER_PAWNBROKING_TAB_SUMMARY,
      payload: { customerId: customer?.customerId },
    });
    yield put({
      type: CUSTOMER_PROFILE_REQUEST,
      payload: { customerId: customer?.customerId },
    });
  } catch (e) {}
}

export function* getCaseSummarySaga(action) {
  const {
    payload: { customerId, service },
  } = action;
  try {
    if (service?.trim() !== NOTIFICATION_SERVICE_TYPE.RISK) {
      const response = yield call(getCaseSummay, customerId);
      if (response?.data) {
        yield put(consumerDutyActions.setCaseSummary(response?.data));

        switch (response?.data?.vulnerabilityOwner) {
          case VULNERABILITYORIGINATOR.SSTEAM:
            return yield put(
              consumerDutyActions.setSelectedTaskOwner(TASK_OWNER_VALUE.SSTEAM)
            );
          case VULNERABILITYORIGINATOR.AT_RISK:
            return yield put(
              consumerDutyActions.setSelectedTaskOwner(TASK_OWNER_VALUE.AT_RISK)
            );
          case VULNERABILITYORIGINATOR.STORE:
            return yield put(
              consumerDutyActions.setSelectedTaskOwner(TASK_OWNER_VALUE.STORE)
            );
          default:
            return '';
        }
      }
    } else {
      yield put(
        consumerDutyActions.setSelectedTaskOwner(TASK_OWNER_VALUE.AT_RISK)
      );
    }
  } catch (e) {
    notification.error({
      message: e?.response?.data?.error || GENERIC_ERROR,
      duration: 5,
    });
  }
}

export function* getLockedPledgeDetailsSaga(action) {
  const {
    payload: { customerId, team },
  } = action;
  try {
    yield put(consumerDutyActions.setLoading(true));
    const response = yield call(getLockedPledgeDetails, customerId, team);
    if (response?.data) {
      yield put(consumerDutyActions.setLoading(false));
      yield put(consumerDutyActions.setLockedPledgeDetails(response?.data));
    }
  } catch (e) {
    yield put(consumerDutyActions.setLoading(false));
    notification.error({
      message: e?.response?.data?.error || GENERIC_ERROR,
      duration: 5,
    });
  }
}

export function* processConsumerDutySaga(action) {
  const {
    payload: { taskDetails, navigate, params },
  } = action;

  try {
    yield put(userActions.setLoading(true));

    const response = yield call(postViewNotificationItem, params);
    if (response?.data) {
      yield call(getCaseSummarySaga, {
        payload: {
          customerId: taskDetails?.customerId,
          service: taskDetails?.serviceName,
        },
      });

      // loading customer profile to redux in background
      yield call(getCustomerProfileSagaInBackground, {
        payload: { customerId: taskDetails?.customerId },
      });

      yield put(consumerDutyActions.setTaskId(taskDetails?.id));
      yield put(consumerDutyActions.setTaskSubject(taskDetails?.subject));
      yield put(userActions.setLoading(false));

      //setting up the VIEW ONLY MODE for SSTeam (Managed Case Tab)
      if (taskDetails?.statusName === STATUS_LABELS.COMPLETED)
        yield put(consumerDutyActions.setIsViewOnlyMode(true));

      //redirecting to consumerDuty component
      navigate(`/${ROUTE_CONFIG.CONSUMER_DUTY}`);
    }
  } catch (error) {
    yield put(userActions.setLoading(false));
    notification.error({ message: GENERIC_ERROR, duration: 5 });
  }
}

export function* watchResetCustomerDataSaga() {
  yield takeEvery(RESET_CUSTOMER_DATA, resetCustomerDataSaga);
}

export function* watchProcessConsumerDutySaga() {
  yield takeEvery(PROCESS_CONSUMERDUTY_REQUEST, processConsumerDutySaga);
}

export function* watchGetCaseSummarySaga() {
  yield takeEvery(GET_CASE_SUMMARY, getCaseSummarySaga);
}
export function* watchGetLockedPledgeDetailsSaga() {
  yield takeEvery(GET_LOCKED_PLEDGE_DETAILS, getLockedPledgeDetailsSaga);
}
