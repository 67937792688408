import React, { useState, useEffect } from 'react';
import VirtualGrid from 'components/common/virtualGrid/index';
import { format } from 'date-fns';
import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';
import { getFxPendingTransfers } from 'services/cashManagement';
import { currencyFormat } from 'utils/util';

interface InboundDeliveryTableProps {
  inboundMethod: number;
  onRowIdChange: (params: any) => void;
}

enum InboundMethod {
  CmsRecall = 1,
  StoreSystem = 2,
}

const InboundDeliveryTable = ({
  inboundMethod,
  onRowIdChange,
}: InboundDeliveryTableProps) => {
  const [rows, setRows] = useState<Array<any>>([]);
  const [cmsRows, setCmsRows] = useState<Array<any>>([]);
  const [selection, setSelection] = useState<Array<any>>([]);

  const getAmount = (row: any) => {
    return (
      <>
        <span
          dangerouslySetInnerHTML={{
            __html: row?.currencySymbol,
          }}
        ></span>
        <span>{currencyFormat(row?.currencyAmount)}</span>
      </>
    );
  };
  const [columns] = useState([
    {
      name: 'sourceStoreName',
      title: 'Source',
      getCellValue: (row: any) => row?.sourceStoreName,
    },
    {
      name: 'note',
      title: 'Notes',
      getCellValue: (row: any) => row?.note,
    },
    {
      name: 'reference',
      title: 'Reference',
      getCellValue: (row: any) => row?.reference,
    },
    {
      name: 'transferDate',
      title: 'Date',
      getCellValue: (row: any) =>
        row && row?.transferDate
          ? format(new Date(row?.transferDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'currencyCode',
      title: 'Currency',
      type: 'currency-tooltip',
      getCellValue: (row: any) => ({
        currencyCode: row?.currencyCode,
        currencyDesc: row?.currencyDescription,
      }),
    },
    {
      name: 'sterlingAmount',
      title: 'Sterling',
      type: 'currency',
      getCellValue: (row: any) => row?.sterlingAmount,
    },
    {
      name: 'currencyAmount',
      title: 'Amount',
      getCellValue: (row: any) => getAmount(row),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'sourceStoreName', width: 160 },
    { columnName: 'note', width: 300 },
    { columnName: 'reference', width: 160 },
    { columnName: 'transferDate', width: 130 },
    { columnName: 'currencyCode', width: 80 },
    { columnName: 'sterlingAmount', width: 140, align: 'right' },
    { columnName: 'currencyAmount', width: 140, align: 'right' },
  ]);

  const [formattedColumns] = useState(['sterlingAmount', 'currencyCode']);
  const [hiddenColumnNames, setHiddenColumnNames] = useState<any>(['note']);

  const getFxPendingTransferData = async () => {
    try {
      const response = await getFxPendingTransfers();
      if (response?.data) {
        const filteredRowsForCMS = response?.data?.filter(
          (x: any) => x.sourceStoreId === 0
        );
        const filteredRowsForStoreSystem = response?.data?.filter(
          (x: any) => x.sourceStoreId !== 0
        );
        setRows(filteredRowsForStoreSystem);
        setCmsRows(filteredRowsForCMS);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const changeHiddenColumns = () => {
    if (inboundMethod === InboundMethod.StoreSystem) {
      setHiddenColumnNames(['reference', 'sterlingAmount']);
    } else {
      setHiddenColumnNames(['note']);
    }
  };

  useEffect(() => {
    getFxPendingTransferData();
  }, []);

  useEffect(() => {
    changeHiddenColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboundMethod]);

  const onRowSelectionChange = (rowId: any) => {
    const length = rowId.length - 1;
    setSelection([rowId[length]]); // making only one checkbox selectable
    onRowIdChange(rowId[length]);
  };

  return (
    <div className="inbound-cash-delivery-table">
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={inboundMethod === InboundMethod.StoreSystem ? rows : cmsRows}
        formattedColumns={formattedColumns}
        rowSelectionEnable
        selection={selection}
        onSelectionChange={onRowSelectionChange}
        highlightRow
        showSelectAll={false}
        selectByRowClick
        hiddenColumnNames={hiddenColumnNames}
        setHiddenColumnNames={setHiddenColumnNames}
      />
    </div>
  );
};

export default InboundDeliveryTable;
