import React, { useState, useEffect, useRef } from 'react';
import { Modal, Row, Tabs, Spin, List, notification } from 'antd';
import styled from 'styled-components';
import { CheckCircleFilled } from '@ant-design/icons/lib/icons';
import { CATEGORY_PANE_COLOR, COIN_ATTRIBUTE_ID } from '../../constants';
import { useDispatch } from 'react-redux';
import { pledgeActions } from 'redux/reducers/pawnbroking';
import { NOTIFICATION_TYPE } from 'globalConstants';
import { cacheStaticDataWithArgs } from 'utils/cacheServices';
import {
  getPledgeCategory,
  getItemCategoryAllAttributes,
} from 'services/pledge';

const StyledTabs = styled(Tabs)`
  & .ant-tabs-tab,
  & .ant-tabs-tab-active {
    font-weight: 500;
    border: 1px solid rgba(255, 255, 255, 0.25);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin: 0;
    margin-right: -20px;
    clip-path: polygon(75% 0%, 88% 50%, 75% 100%, 0% 100%, 11% 50%, 0% 0%);
    color: var(--black);
    height: 67px;
    width: 157px;
    text-align: center;
    word-break: break-word;
    &:first-of-type {
      background: var(--dark-blue-icon);
      clip-path: polygon(0% 0%, 75% 0%, 90% 50%, 75% 100%, 0% 100%);
      color: var(--white);
      height: 67px;
      width: 110px;
    }
    &:nth-of-type(n + 2) {
      background: var(--dust-yellow);
    }
    &:nth-of-type(n + 3) {
      background: var(--lilac-purple);
    }
    &:nth-of-type(n + 4) {
      background: var(--cyan);
    }
    &:nth-of-type(n + 5) {
      background: var(--dust-green);
    }
    &:nth-of-type(n + 6) {
      background: var(--peach);
    }
  }
  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--black);
    font-weight: 500;
  }
  & .ant-tabs-tab .ant-tabs-tab-btn {
    white-space: normal;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    padding: 0 32px 0 18px;
    justify-content: center;
  }
  & .ant-tabs-tab.ant-tabs-tab-disabled {
    cursor: default;
    display: none;
    &:first-of-type {
      display: block;
      margin-right: -12px;
    }
  }
  & .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    left: 0 !important;
    width: 0 !important;
  }
  > .ant-tabs-nav {
    margin: 0 0 20px;
    &::before {
      border-bottom: 0;
    }
  }
  & .ant-tabs-content-holder {
    padding: 8px 0 8px 0;
  }
`;

const StyledCategoryWrapper = styled.div<{ $background: string }>`
  height: 448px;
  overflow: auto;
  & .subcategory-list-item {
    padding: 0 !important;
    & .ant-list-item {
      background: var(--catskill-white);
      width: 100%;
      border-radius: 4px;
      margin-bottom: 2px;
      padding: 10px 16px 10px 16px;
      cursor: pointer;
      & .anticon.anticon-check-circle {
        visibility: hidden;
      }
    }
    & .ant-list-item:hover {
      background: var(--${(props) => props.$background});
      & .anticon.anticon-check-circle {
        visibility: visible;
      }
    }
  }
`;

const StyledSpin = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCheckCircleFilled = styled(CheckCircleFilled)<{
  $checkboxColor: string;
}>`
  margin-left: 20px;
  font-size: 20px;
  color: var(--${(props) => props.$checkboxColor});
`;

interface CategoriesPopupProps {
  visible: boolean;
  setCategoriesPopupVisible: () => void;
  itemCategory: any;
  resetItems: () => void;
  disableAddCoin: (isCoinAttribute: boolean) => void;
}

interface PaneProps {
  title: string;
  key: string;
  descriptionColor: string;
  checkboxColor: string;
  id: number;
}

interface TabHeaderProps {
  title: string;
}

const TabHeader = ({ title }: TabHeaderProps) => {
  return <div>{title}</div>;
};

const PaneContent = (props: any) => {
  const {
    descriptionColor,
    subCategory,
    innerLoading,
    checkboxColor,
    getListItems,
    apiLoading,
  } = props;

  return (
    <StyledCategoryWrapper $background={descriptionColor}>
      <List aria-label="categories" className="subcategory-list-item">
        {subCategory?.map((item: any, index: any) => {
          return (
            <List.Item
              key={index}
              onClick={() => !apiLoading && getListItems(item, index)}
              extra={
                <Row justify="end" align="middle">
                  {innerLoading[index] ? <StyledSpin size="small" /> : null}
                  <StyledCheckCircleFilled $checkboxColor={checkboxColor} />
                </Row>
              }
            >
              {item.name}
            </List.Item>
          );
        })}
      </List>
    </StyledCategoryWrapper>
  );
};

const CategoriesPopup = ({
  visible,
  setCategoriesPopupVisible,
  itemCategory,
  resetItems,
  disableAddCoin,
}: CategoriesPopupProps) => {
  const [activeKey, setActiveKey] = useState('2'); // 2 because first tab - category is always disabled
  const [panes, setPanes] = useState<PaneProps[]>([]);
  const [itemDescription, setItemDescription] = useState<Array<string>[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [innerLoading, setInnerLoading] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [apiLoading, setApiLoading] = useState<any>(false);
  const newTabIndex = useRef(2);
  const dispatch = useDispatch();

  const getCategories = async (categoryId: number) => {
    try {
      setLoading(true);
      const response = await cacheStaticDataWithArgs(
        `PLEDGE-CATEGORY-${categoryId}`,
        getPledgeCategory,
        categoryId
      );

      if (response?.data) {
        setLoading(false);
        return response?.data?.itemCategories;
      }
    } catch (e: any) {
      setLoading(false);
    }
  };

  const getListItems = async (item: any, index: any) => {
    setApiLoading(true);
    try {
      await onListItemClick(item, index);
    } catch (e: any) {
      notification.error({
        message: NOTIFICATION_TYPE.ERROR,
        duration: 3,
      });
    } finally {
      setApiLoading(false);
    }
  };

  const onListItemClick = async (listItem: any, index: number) => {
    innerLoading[index] = true;
    setInnerLoading([...innerLoading]);
    const newActiveKey = (++newTabIndex.current).toString();
    try {
      const categories = await getCategories(listItem.id);
      if (itemDescription.indexOf(listItem.name) === -1)
        itemDescription.push(listItem.name);
      setItemDescription(itemDescription);
      setCategories(categories);
      if (categories.length > 0) {
        const paneObject = {
          title: listItem.name,
          key: newActiveKey,
          descriptionColor:
            CATEGORY_PANE_COLOR[newTabIndex.current - 2]?.description,
          checkboxColor: CATEGORY_PANE_COLOR[newTabIndex.current - 2]?.checkbox,
          id: listItem.id,
        };

        setPanes([...panes, paneObject]);
        setActiveKey(newActiveKey);
        setInnerLoading([]);
      } else {
        resetItems();
        dispatch(pledgeActions.updateItemCategory(itemCategory));
        dispatch(pledgeActions.updateSubItemCategory(listItem));
        dispatch(pledgeActions.updateItemDescription(itemDescription));
        onCancelClick();
        try {
          const response = await getItemCategoryAllAttributes(listItem.id);
          if (response?.data?.itemCategoryAttributes) {
            const isCoinAttribute =
              response?.data?.itemCategoryAttributes?.some((x: any) =>
                COIN_ATTRIBUTE_ID.includes(x.attributeId)
              );
            disableAddCoin(!isCoinAttribute);
          }
        } catch (e: any) {
          // do nothing
        }
      }
    } catch (e: any) {
      setInnerLoading([]);
    }
  };

  const onCancelClick = () => {
    newTabIndex.current = 2;
    setActiveKey('2');
    setPanes([]);
    setCategories([]);
    setCategoriesPopupVisible();
  };

  const onChangeTab = async (targetKey: string) => {
    const key = +targetKey;
    panes.splice(key);
    newTabIndex.current = key;
    itemDescription.splice(key - 1);
    setItemDescription([...itemDescription]);
    setPanes([...panes]);
    setActiveKey(targetKey);
    try {
      const categories = await getCategories(panes[key - 1]?.id);
      if (categories) {
        setCategories(categories);
      }
    } catch (e: any) {
      console.log('err', e);
    }
  };

  useEffect(() => {
    async function getInitCategories() {
      const categories = await getCategories(itemCategory.id);
      setPanes([
        ...panes,
        {
          title: 'Category',
          key: '1',
          descriptionColor: '',
          checkboxColor: '',
          id: 0,
        },
        {
          title: itemCategory.name,
          key: '2',
          descriptionColor: CATEGORY_PANE_COLOR[0].description,
          checkboxColor: CATEGORY_PANE_COLOR[0].checkbox,
          id: itemCategory.id,
        },
      ]);
      setCategories(categories);
    }
    getInitCategories();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setItemDescription([...itemDescription, itemCategory.name]);
  }, [itemCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  const categoriesTabItems = panes.map((pane) => {
    return {
      disabled: pane.key === '1',
      label: <TabHeader title={pane.title} />,
      key: pane.key,
      children: (
        <PaneContent
          descriptionColor={pane.descriptionColor}
          subCategory={categories}
          innerLoading={innerLoading}
          getListItems={getListItems}
          checkboxColor={pane.checkboxColor}
          apiLoading={apiLoading}
        />
      ),
    };
  });

  return (
    <>
      <Modal
        title="Select Item Category"
        open={visible}
        footer={null}
        width={888}
        onCancel={onCancelClick}
        destroyOnClose={true}
        bodyStyle={{ height: '604px' }}
        maskClosable={false}
      >
        {isLoading && panes.length === 0 ? (
          <Spin />
        ) : (
          <StyledTabs
            onChange={onChangeTab}
            activeKey={activeKey}
            items={categoriesTabItems}
          />
        )}
      </Modal>
    </>
  );
};

export default CategoriesPopup;
