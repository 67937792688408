import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomerHeader from '../customer/customerHeader';
import { Row, Col, notification } from 'antd';
import styled from 'styled-components';
import { isEmpty, isNull, isUndefined } from 'lodash';
import { joinValues } from 'utils/util';
import ViewRetailSales from './viewRetailSale';
import './index.less';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'redux/reducers/retail';
import {
  BUTTON_LABELS,
  HEADER_TITLE,
  USERNAME,
  INVENTORY_TYPE,
} from './constants';
import { CREATE_BASKET_RETAIL_ITEMS } from 'action_creators/basket';
import { ROUTE_CONFIG } from 'globalConstants';
import { BASKET_SERVICE_TYPE } from 'components/basket/constants';
import FooterButtons from 'components/common/footerButtons';
import { actions as basketActions } from 'redux/reducers/basket';
import LayawayModal from './LayawayModal';

const StyledMainContainer = styled.div`
  background: var(--white);
  border: 0.6px solid var(--linkwater);
  border-bottom: none;
  box-shadow: 0px 1px 10px rgba(1, 78, 169, 0.2);
  border-radius: 5px 5px 0 0;
  margin: 0px 15px;
  color: var(--black);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledButtonContainerRow = styled(Row)`
  margin: 0px 15px 15px;
  position: fixed;
  width: calc(100% - 110px);
`;

const RetailSales = (props: any) => {
  const {
    retail: { rows, savedRetailItems },
    customer: { customer, isStoreEnquiryMode, isPhoneEnquiryMode },
    basket: { basket },
    user: { user, selectedStore },
  } = useSelector((state: any) => {
    return {
      retail: state.retail,
      customer: state.customer,
      basket: state.basket,
      user: state.user,
    };
  });

  const navigate = useNavigate();

  const isNamedCustomer = useMemo(() => !isEmpty(customer), [customer]);

  const [customerName, setCustomerName] = useState<string>('');
  const [isLayawayModalOpen, setIsLayawayModalOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const getRetailServiceId = useCallback(() => {
    const retailService = basket?.basketServices?.filter(
      (x: any) => x.serviceType === BASKET_SERVICE_TYPE.RETAIL
    );
    if (retailService?.length > 0) {
      return retailService[0].serviceId;
    }
    return null;
  }, [basket?.basketServices]);

  const itemsPayload = rows?.map((item: any) => {
    return {
      ...item,
      inventoryType: item.isNew ? INVENTORY_TYPE.NEW : INVENTORY_TYPE.PREOWNED,
      storeId: item?.storeId,
    };
  });

  const {
    street,
    town,
    property,
    county,
    postcode,
    houseName,
    area,
    firstNames,
    middleName,
    surname,
    dateOfBirth,
  } = customer;

  const retailItemPayload = useMemo(() => {
    return {
      basketId: basket?.basketId,
      serviceId: basket ? getRetailServiceId() : null,
      customerId: customer?.customerId || 0,
      storeId: selectedStore?.storeId,
      userId: user?.userId,
      items: [...itemsPayload],
      serviceType: BASKET_SERVICE_TYPE.RETAIL,
      paymentType: 0,
      customer: {
        street,
        town,
        property,
        county,
        postcode,
        houseName,
        area,
        firstNames,
        middleName,
        surname,
        dateOfBirth,
      },
      customerName:
        joinValues([customer.firstNames, customer.surname], true) || undefined,
      employeeId: user?.employeeId,
      storeAddress: selectedStore?.storeAddress,
      storePostalCode: selectedStore?.postalCode,
      storeTelephone: selectedStore?.telephone,
      userName: user?.authorizeUsername,
    };
  }, [
    area,
    basket,
    county,
    customer?.customerId,
    customer.firstNames,
    customer.surname,
    dateOfBirth,
    firstNames,
    getRetailServiceId,
    houseName,
    itemsPayload,
    middleName,
    postcode,
    property,
    selectedStore?.postalCode,
    selectedStore?.storeAddress,
    selectedStore?.storeId,
    selectedStore?.telephone,
    street,
    surname,
    town,
    user?.authorizeUsername,
    user?.employeeId,
    user?.userId,
  ]);

  // To validate layaway item and proceed to basket
  const validateLayawayItems = (items: any, customerId: number) => {
    let tempItem: any = {};
    const isLayawayError = items?.some((item: any) => {
      if (item?.layawayCustomerId) {
        tempItem = item;
        return item.layawayCustomerId !== customerId;
      }
      return false;
    });
    if (isLayawayError) {
      notification.error({
        message: '',
        description: `Item with barcode ${tempItem?.barcode} is in layaway status with another customer, so can't proceed.`,
        duration: 5,
      });
      return false;
    }
    return true;
  };

  const onPushToBasketClick = (pushToBasket = true): boolean => {
    const totalItems = [...rows, ...savedRetailItems];

    const layawayItem = totalItems.find(
      (item) =>
        !isNull(item.layawayCustomerId) &&
        !isUndefined(item.layawayCustomerId) &&
        customer.customerId !== item.layawayCustomerId
    );

    if (layawayItem) {
      notification.error({
        message: '',
        description: `You cannot proceed through to the basket as the item with barcode ${layawayItem.barcode} is currently held in Layaway`,
        duration: 10,
      });
      return false;
    }

    const uniqueItems: any = [];
    const uniqueObject: any = {};
    for (const i in totalItems) {
      const itemIds = totalItems[i]['itemId'];
      uniqueObject[itemIds] = totalItems[i];
    }
    for (const i in uniqueObject) {
      uniqueItems.push(uniqueObject[i]);
    }

    if (uniqueItems?.length > 0) {
      dispatch(actions.retailItemAddToBasket([...uniqueItems]));
      dispatch(actions.setTempPayloadWithVAT(retailItemPayload));
      dispatch({
        type: CREATE_BASKET_RETAIL_ITEMS,
        payload: { params: retailItemPayload },
      });
      if (pushToBasket) {
        navigate(`/${ROUTE_CONFIG.BASKET}`);
      }
    } else if (basket?.basketId !== null && pushToBasket)
      navigate(`/${ROUTE_CONFIG.BASKET}`);
    return true;
  };

  const onAddToBasketLayawayItems = useCallback(
    (items: any) => {
      // if (items?.length > 0) {
      const customerId = customer?.customerId;
      const layawayItemPayload = items?.map((item: any) => {
        return {
          ...item,
          inventoryType: item.isNew
            ? INVENTORY_TYPE.NEW
            : INVENTORY_TYPE.PREOWNED,
          storeId: item?.storeId,
        };
      });
      const updatedItems = [...retailItemPayload.items, ...layawayItemPayload];
      // To validate layaway item and proceed to basket
      const isProceed = validateLayawayItems(updatedItems, customerId);

      if (isProceed) {
        const updatedPayload = {
          ...retailItemPayload,
          items: updatedItems,
        };
        dispatch(actions.setTempPayloadWithVAT(updatedPayload));
        dispatch({
          type: CREATE_BASKET_RETAIL_ITEMS,
          payload: { params: updatedPayload },
        });
        navigate(`/${ROUTE_CONFIG.BASKET}`);
      }
    },
    [customer?.customerId, dispatch, history, retailItemPayload]
  );

  useEffect(() => {
    getCustomerName();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomerName = () => {
    if (Object.keys(customer).length !== 0) {
      setCustomerName(customer.firstNames);
    } else setCustomerName(USERNAME);
  };

  const onConfirmExitOnlyClick = () => {
    dispatch(actions.clearItems());
    dispatch(basketActions.clearBasket());
  };

  const toggleLayawayModal = () => setIsLayawayModalOpen(!isLayawayModalOpen);

  return (
    <div className="retail-wrapper">
      <CustomerHeader
        title={HEADER_TITLE}
        name={customerName}
        preferredName=""
        buttonsOption={false}
        profilePic={true}
        helpSection="RETAIL"
      />
      <Row>
        <Col span={24}>
          <StyledMainContainer>
            <ViewRetailSales rows={rows} />
          </StyledMainContainer>
        </Col>
      </Row>
      <StyledButtonContainerRow>
        <Col span={24}>
          <FooterButtons
            isExitOnly={!rows?.length}
            proceedButtonText={BUTTON_LABELS.PROCEED_TO_BASKET}
            isDisabled={
              rows.filter((elem: any) => elem.listValue <= 0).length > 0
            }
            onProceedClick={onPushToBasketClick}
            onConfirmExitClick={onConfirmExitOnlyClick}
            helpSection="RETAIL"
            isSaveJourney={true}
            saveJourneyHandler={() => onPushToBasketClick(false)}
            extraButtonEanable={isNamedCustomer}
            extraButtonItemProp="secondary"
            extraButtonText={BUTTON_LABELS.LAYAWAY}
            extraButtonOnClick={toggleLayawayModal}
          />
        </Col>
        {isLayawayModalOpen && (
          <LayawayModal
            isOpen={isLayawayModalOpen}
            onCancel={toggleLayawayModal}
            onAddToBasket={onAddToBasketLayawayItems}
            isStoreEnquiryMode={isStoreEnquiryMode}
            isPhoneEnquiryMode={isPhoneEnquiryMode}
          />
        )}
      </StyledButtonContainerRow>
    </div>
  );
};

export default RetailSales;
