import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Select, Spin } from 'antd';
import styled from 'styled-components';
import { SERVICE_TYPE_AS_LIST } from 'globalConstants';
import { Button } from 'components/common/Button';
import Alert from 'components/common/ControlledAlert';
import useSignalRConnection from 'middleware/signalR';
import { actions } from 'redux/reducers/admin';
import {
  AUTHORIZE_VOID_TRANSACTION,
  GET_VOID_TRANSACTIONS,
} from 'action_creators/admin';
import { CURRENCIES_REQUEST } from 'action_creators/travelmoney';
import { getStoreUsers } from 'services/cashManagement';
import { cacheStaticDataWithArgs } from 'utils/cacheServices';
import { USER_MANAGER, VOID_SUCCESS_MESSAGE } from '../../constants';
import AuthorizationForm from './authorizationForm';
import VoidTransactionsTable from './voidTransectionsTable';
import './index.less';

const StyledMainWrapper = styled(Row)`
  background: var(--white);
  border-bottom: none;
  border-radius: 5px 5px 0px 0px;
  color: var(--black);
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const StyledMainContainer = styled(Col)`
  margin: 0px;
`;

const CustomRow = styled(Row)`
  gap: 20px;
`;

const StyledTimer = styled.div`
  position: static;
  font-size: 3rem;
  left: 55%;
  text-align: center;
`;

const StyledText = styled.div<{ fontSize: string; padding: string }>`
  font-weight: var(--font-weight-500);
  font-size: var(--${(props) => props.fontSize});
  padding: ${(props) => props.padding};
`;
const StyledCol = styled(Col)`
  padding: 15px 0 10px 0;
`;
const StyledButton = styled(Button)`
  height: 42px;
  width: 83px;
`;

const VoidTransactions = () => {
  const [adminStoreUsersList, setAdminStoreUsersList] = useState<Array<any>>(
    []
  );
  const [selectedUserId, setUserId] = useState<any>(null);
  const [selectedUserName, setUserName] = useState<any>('all');
  const [selectedServiceName, setServiceName] = useState<any>('all');
  const [disableVoidTransactionBtn, setDisableVoidTransactionBtn] =
    useState<boolean>(true);
  const [authFormData, setAuthFormData] = useState<any>({});
  const [second, setSecond] = useState<any>('00');
  const [minute, setMinute] = useState<any>('00');
  const [isTimerActive, setIsTimerActive] = useState<boolean>(false);
  const [counter, setTimerCounter] = useState<number>(0);
  const [clearAllFields, setClearAllFields] = useState<any>(false);

  useSignalRConnection();
  const dispatch = useDispatch();

  const {
    admin: {
      voidTransactionsList,
      loading,
      voidSignalRSuccess,
      voidErrorStopTimer,
      serviceIdList,
      basketIdList,
    },
    user: { groups, storeId },
  } = useSelector((state: any) => {
    return {
      admin: state.admin,
      user: state.user.user,
    };
  });

  useEffect(() => {
    async function getUserData() {
      const response = await cacheStaticDataWithArgs(
        `STORE_USER-${storeId}`,
        getStoreUsers,
        storeId
      );

      if (response?.data) setAdminStoreUsersList(response?.data);
    }
    getUserData();
  }, [storeId]);

  useEffect(() => {
    if (voidSignalRSuccess || voidErrorStopTimer) {
      stopTimer();
    }
  }, [voidSignalRSuccess, voidErrorStopTimer]);

  const onSelectUser = (value: any) => {
    const selectedUserData = value?.split(',');
    setUserId(selectedUserData[1]);
    setUserName(selectedUserData[0]);
  };
  const onSelectServices = (value: number) => {
    setServiceName(value);
  };
  const onSearch = async () => {
    const params = {
      UserId: selectedUserId === 'all' ? null : selectedUserId,
      ServiceType: selectedServiceName === 'all' ? null : selectedServiceName,
    };
    dispatch({
      type: GET_VOID_TRANSACTIONS,
      payload: { params },
    });
    setClearAllFields(true);
  };
  const onReset = () => {
    setUserId(null);
    setUserName('all');
    setServiceName('all');
    onSearch();
  };

  const stopTimer = () => {
    setIsTimerActive(false);
    setTimerCounter(0);
    setSecond('00');
    setMinute('00');
  };

  //** this timer is use for start/stop signalR if not getting response within the time period */
  useEffect(() => {
    let intervalId: any;
    if (isTimerActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);
        const computedSecond: any =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        const computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;
        setSecond(computedSecond);
        setMinute(computedMinute);
        setTimerCounter((counter) => counter + 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isTimerActive, counter]); // eslint-disable-line react-hooks/exhaustive-deps

  const startSignalR = () => {
    setIsTimerActive(true);
  };
  const getServiceIds = (serviceIdList: any) => {
    if (serviceIdList?.length) {
      return serviceIdList;
    } else {
      const selectedBasket = voidTransactionsList?.find(
        (basket: any) => basket?.basketId === basketIdList?.[0]
      );
      return selectedBasket?.basketServices?.map(
        (bask: any) => bask?.serviceId
      );
    }
  };

  const authorizationVoidTransactionHandler = (authorizationHeaders: any) => {
    const params = {
      basketId: basketIdList?.[0],
      reason: authorizationHeaders.reasonToVoid,
      UserId: selectedUserId === 'all' ? null : selectedUserId,
      ServiceType: selectedServiceName === 'all' ? null : selectedServiceName,
      serviceIds: getServiceIds(serviceIdList),
    };
    dispatch({
      type: AUTHORIZE_VOID_TRANSACTION,
      payload: { params, authorizationHeaders },
    });
    dispatch(actions.setBasketIdList([]));
    dispatch(actions.setServiceIdList([]));
    startSignalR();
  };

  const isUserRoleITAdmin = groups?.find(
    (obj: any) => obj.groupId === USER_MANAGER.GROUP_IDS.IT_ADMIN
  );

  useEffect(() => {
    onSearch();
    dispatch({
      type: CURRENCIES_REQUEST,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StyledMainWrapper className="admin-management-wrapper">
        <StyledMainContainer span={24} className="admin-container-wrapper">
          <Row>
            <Col span={12} className="void-text-row">
              Void (Completed Transactions)
            </Col>
            <Col>
              {isTimerActive && (
                <StyledTimer>
                  <span className="minute">{minute}</span>
                  <span>:</span>
                  <span className="second">{second}</span>
                </StyledTimer>
              )}
            </Col>
          </Row>
          <CustomRow>
            <Col span={4}>
              <StyledText fontSize="font-size-14" padding="0">
                {'User'}
              </StyledText>
            </Col>
            <Col span={6}>
              <StyledText fontSize="font-size-14" padding="0">
                {'Service Type (Contains)'}
              </StyledText>
            </Col>
          </CustomRow>

          <CustomRow className="search-row">
            <Col span={4}>
              <Select
                className="input-control"
                placeholder="Select"
                value={selectedUserName}
                onChange={onSelectUser}
              >
                <Select.Option key={0} value={'all'}>
                  {'All'}
                </Select.Option>
                {adminStoreUsersList?.map(({ displayName, userId }) => (
                  <Select.Option
                    key={userId}
                    value={`${displayName},${userId}`}
                  >
                    {displayName}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col span={4}>
              <Select
                className="input-control"
                placeholder="Select"
                value={selectedServiceName}
                onChange={onSelectServices}
              >
                <Select.Option key={0} value={'all'}>
                  {'All'}
                </Select.Option>
                {SERVICE_TYPE_AS_LIST?.map(({ id, title }) => (
                  <Select.Option key={id} value={id}>
                    {title}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col span={3}>
              <StyledButton
                itemProp="secondary"
                htmlType="submit"
                onClick={onSearch}
              >
                Search
              </StyledButton>
            </Col>
            <Col span={3}>
              <StyledButton
                itemProp="secondary"
                htmlType="submit"
                onClick={onReset}
              >
                Reset
              </StyledButton>
            </Col>
          </CustomRow>
          <Row>
            <StyledCol span={24} className="void-details-grid">
              {loading ? (
                <Spin className="spin-loader" size="large" />
              ) : (
                <VoidTransactionsTable rows={voidTransactionsList || []} />
              )}
            </StyledCol>
          </Row>
          {(basketIdList?.length > 0 || serviceIdList?.length > 0) && (
            <CustomRow className="reason-row">
              <Col span={15}>
                <StyledText fontSize="font-size-14" padding="0">
                  {'Reason'}
                </StyledText>
              </Col>
              <Col span={4}>
                <StyledText fontSize="font-size-14" padding="0">
                  {'Confirmed By'}
                </StyledText>
              </Col>
              <Col span={4}>
                <StyledText fontSize="font-size-14" padding="0">
                  {'Password'}
                </StyledText>
              </Col>
            </CustomRow>
          )}
          <Row className="auth-row">
            <AuthorizationForm
              addonBeforeText=""
              authorizationHandler={authorizationVoidTransactionHandler}
              disableVoidTransactionBtn={disableVoidTransactionBtn}
              setDisableVoidTransactionBtn={setDisableVoidTransactionBtn}
              authFormData={authFormData}
              isUserRoleITAdmin={isUserRoleITAdmin}
              setAuthFormData={setAuthFormData}
              clearAllFields={clearAllFields}
              setClearAllFields={setClearAllFields}
            />
          </Row>
        </StyledMainContainer>
      </StyledMainWrapper>
      {voidSignalRSuccess && (
        <Alert
          visible={true}
          width={457}
          yesButtonText={'OK'}
          itemProp={'secondary'}
          contentMessage={VOID_SUCCESS_MESSAGE}
          alertMessage={''}
          isCancelBtn={false}
          onClick={onSearch}
          onCancel={onSearch}
        />
      )}
    </>
  );
};

export default VoidTransactions;
