import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GRID_NO_URL } from 'globalConstants';
import VirtualGrid from 'components/common/virtualGrid/index';
import { actions } from 'redux/reducers/basket';

const BasketWUTable = (props: any) => {
  const rows = props?.items || [];
  const dispatch = useDispatch();
  const [selection, setSelection] = useState<Array<any>>([]);

  useEffect(() => {
    const payload = {
      serviceIds: selection,
    };
    //update redux merge payload when row selected
    dispatch(actions.move(payload));
  }, [selection]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectionChange = (row: any) => {
    setSelection(row);
  };

  const [columns] = useState([
    {
      name: 'transactionType',
      title: 'Service',
      getCellValue: (row: any) =>
        row?.westernUnion?.request?.isRefund
          ? 'Refunded to Customer'
          : row?.westernUnion?.request?.receivedAmount
          ? 'Received from Customer'
          : 'Paid to Customer',
    },
    {
      name: 'mtcnRefNo',
      title: 'MTCN',
      getCellValue: (row: any) => row?.westernUnion?.request?.mtcnRefNo,
    },
    {
      name: 'receivedAmount',
      title: 'Amount',
      type: 'currency',
      getCellValue: (row: any) => {
        const amount = row?.westernUnion?.request?.isRefund
          ? row?.westernUnion?.request?.paidAmount
          : row?.westernUnion?.request?.receivedAmount
          ? row?.westernUnion?.request?.receivedAmount
          : row?.westernUnion?.request?.paidAmount;

        return +amount;
      },
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'service', width: 200 },
    { columnName: 'mtcnRefNo', width: 300 },
    { columnName: 'receivedAmount', width: 250, align: 'right' },
  ]);

  const formattedColumns = ['service', 'receivedAmount'];

  return (
    <>
      <div className="basket-item-table">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={rows}
          formattedColumns={formattedColumns}
          selection={selection}
          onSelectionChange={onSelectionChange}
          highlightRow={true}
          rowSelectionEnable={true}
        />
      </div>
    </>
  );
};

export default BasketWUTable;
