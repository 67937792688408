import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GRID_NO_URL } from 'globalConstants';
import { Spin } from 'antd';
import VirtualGrid from 'components/common/virtualGrid/index';
import { getPledgeAgreementItems } from 'services/pledge';
import { actions } from 'redux/reducers/pledgeManagement/index';
import ImageLoader from 'components/common/ImageLoader';

const AuctionDetailsExpandableTable = ({ row }: any) => {
  const { auctionItemDetails } = useSelector(
    (state: any) => state.pledgeManagement
  );

  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!Object.keys(row).includes('loanItems')) getLoanId(row.loanId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getLoanId = async (id: any) => {
    try {
      setLoading(true);
      const response = await getPledgeAgreementItems(id);
      if (response?.data?.items) {
        const auctionLoans = JSON.parse(
          JSON.stringify(auctionItemDetails.auctionLoans)
        );
        const index = auctionItemDetails.auctionLoans.findIndex(
          (obj: any) => obj.loanId === row.loanId
        );
        auctionLoans[index].loanItems = response.data.items;
        dispatch(
          actions.setAuctionLoanItemDetails({
            ...auctionItemDetails,
            auctionLoans,
          })
        );
        setLoading(false);
      }
    } catch (e: any) {
      console.log(e);
      setLoading(false);
    }
  };
  const [columns] = useState([
    {
      name: 'image',
      title: 'Item Image',
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row.id}?isThumnails=false`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'description',
      title: 'Description',
      getCellValue: (row: any) => row?.description,
    },
    {
      name: 'fineness',
      title: 'Fineness',
      getCellValue: (row: any) => row?.fineness,
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) => row?.weight,
    },
    {
      name: 'amount',
      title: 'Loan Value',
      getCellValue: (row: any) => row?.amount,
    },
    {
      name: 'barcode',
      title: 'Post Auction Barcode',
      getCellValue: (row: any) => row?.barcode,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'image', width: '8%' },
    { columnName: 'description', width: '10%' },
    { columnName: 'fineness', width: '7%' },
    { columnName: 'weight', width: '7%' },
    { columnName: 'amount', width: '7%' },
    { columnName: 'barcode', width: '10%' },
  ]);

  return (
    <Spin spinning={loading}>
      <div className="expandable-table-rows">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={row.loanItems || []}
        />
      </div>
    </Spin>
  );
};

export default AuctionDetailsExpandableTable;
