import React from 'react';
import { Row, Col } from 'antd';
import styled, { css } from 'styled-components';
import {
  AMOUNT_TO_RECEIVE,
  AMOUNT_TO_PAYOUT,
} from 'components/basket/constants';

const StyledTd = styled.td<{
  $color?: boolean;
}>`
  padding: 10px !important;
  border-left: 1px solid var(--light-gray);
  font-weight: var(--font-weight-500) !important;
  ${(props) =>
    props.$color &&
    css`
      color: var(--red) !important;
    `}
`;

const StyledTr = styled.tr`
  border: 1px solid var(--light-gray);
  border-radius: 2px;
`;

const TableRow = (label: string, values: any, isPay = false) => {
  return (
    <StyledTr>
      <StyledTd>{label}</StyledTd>
      {values.map((item: any, indx: any) => (
        <StyledTd $color={isPay} key={indx}>
          {item.currency} {item.amount}
        </StyledTd>
      ))}
    </StyledTr>
  );
};

const FxInfo = ({ totalFX }: any) => {
  return (
    <Row justify="end">
      <Col span={24}>
        <Row justify="end">
          <Col>
            <table>
              <tbody>
                {TableRow(
                  AMOUNT_TO_PAYOUT,
                  totalFX?.fxCurrencyToPayout || [],
                  true
                )}
                {TableRow(
                  AMOUNT_TO_RECEIVE,
                  totalFX?.fxCurrencyToReceive || []
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FxInfo;
