import React, { useState } from 'react';
import { Layout, Modal } from 'antd';
import styled from 'styled-components';
import HorizontalTabs from 'components/common/HorizontalTabs';
import DailyInterestCalculator from './dailyInterestCalculator';
import StoneSizeCalculator from './stoneSizeCalculator';
import MetalPricing from './metalPricing';

import {
  ACCESSORIES_LINKS,
  ACCESSORIES_COMMON_MODAL,
} from 'components/layout/constants';

const StyledMenuLayout = styled(Layout)`
  &.control-box {
    padding: 0;
  }
`;

const CommonAccessoriesModal = ({ selectedLink, onCancelClick }: any) => {
  const [activeMenu, setActiveMenu] = useState<string>(selectedLink);

  const onMenuClick = (clickedMenu: string) => {
    setActiveMenu(clickedMenu);
  };

  return (
    <Modal
      open
      centered
      width={821}
      closable={false}
      maskClosable={false}
      onCancel={onCancelClick}
      footer={''}
      className="common-accessorios-modal-wrapper"
    >
      <StyledMenuLayout className="daily-horizon-tabs">
        <HorizontalTabs
          onMenuClick={onMenuClick}
          initialPanes={ACCESSORIES_COMMON_MODAL.INITIAL_PANES}
          activeKey={selectedLink}
          isBlueTheme={true}
        />
      </StyledMenuLayout>

      {activeMenu === ACCESSORIES_LINKS.DAILY_INTEREST_CALCULATOR && (
        <DailyInterestCalculator onCancelClick={onCancelClick} />
      )}
      {activeMenu === ACCESSORIES_LINKS.METAL_PRICING && (
        <MetalPricing onCancelClick={onCancelClick} />
      )}
      {activeMenu === ACCESSORIES_LINKS.STONE_SIZE_CALCULATOR && (
        <StoneSizeCalculator onCancelClick={onCancelClick} />
      )}
    </Modal>
  );
};

export default CommonAccessoriesModal;
