import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
import * as BASKET_ACTIONS from 'action_creators/basket';
import * as PLEDGE_ACTIONS from 'action_creators/pledge';
import {
  postPledgeIntoBasket,
  putPledgeIntoBasket,
  deletePledgeFromBasket,
  splitPledgeIntoBasket,
  mergePledgeIntoBasket,
  movePledgeItemsBasket,
  postRetailIntoBasket,
  deleteRetailFromBasket,
  putRetailIntoBasket,
  putChequeCashingIntoBasket,
  putPledgeLoanAmountIntoBasket,
  getBasket,
  movePledgePurchaseItemsBasket,
  postPickupRenewIntoBasket,
  deletePickupRenewFromBasket,
  deleteFxTransaction,
  deleteCustomerBasket,
  deleteChequeCashingFromBasket,
  postChequeCashingIntoBasket,
  deleteBasketService,
  postMergeGuestBasket,
  postWesternUnionBasket,
  deleteWesternUnionFromBasket,
  postPurchaseReturnIntoBasket,
  deletePurchaseReturnItemFromBasket,
  putTenItemsInPledge,
  postSurplusBasket,
  deleteSurplusItemFromBasket,
  postUpdatedCustomerDetails,
} from 'services/basket';
import { getBasketByBasketId } from 'services/checkout';

import { actions } from 'redux/reducers/basket/index';
import { actions as checkoutActions } from 'redux/reducers/checkout/index';
import { actions as pickupActions } from 'redux/reducers/pickupRenew/index';
import { pledgeActions } from 'redux/reducers/pawnbroking/index';
import { stoneActions } from 'redux/reducers/pawnbroking/stone';
import { actions as chequeCashingActions } from 'redux/reducers/chequeCashing/index';
import { coinActions } from 'redux/reducers/pawnbroking/coin';
import { actions as customerActions } from 'redux/reducers/customer';
import { actions as valutaionActions } from 'redux/reducers/valuationCenter';

import {
  reducePledgeItems,
  joinValues,
  getPledgePurchaseChildTab,
} from 'utils/util';
import { COIN_FROM } from 'components/pledge/constants';
import { BASKET_SERVICE_TYPE } from 'components/basket/constants';
import { groupBy } from 'lodash';
import { RESPONSE_STATUS, BASKET_STATUS, ROUTE_CONFIG } from 'globalConstants';
import { sendToSupportCenter } from 'services/chequeCashing';

import { CHEQUE_CASHING_HISTORY_EXISTS } from 'components/chequeCashing/constants';

/**
 * this saga handles the GET basket
 * (1) get basket
 * (2) handle response
 */
export function* basketSaga() {
  try {
    const { customer } = yield select((state) => state.customer);
    if (!customer?.customerId) {
      return;
    }
    yield put(actions.initLoading(true));

    const payload = yield call(getBasket, customer.customerId);
    if (payload?.data) {
      yield put(actions.getBasket(payload?.data));
      yield put(actions.initLoading(false));
    } else {
      if (payload?.status === RESPONSE_STATUS.NO_CONTENT) {
        const error = {
          message: 'No live basket found',
          response: { data: { error: 'No live basket found' } },
        };

        yield put(actions.setErrorCode(RESPONSE_STATUS.NO_CONTENT));
        yield put(actions.removeBasket(error));
      } else yield put(actions.removeBasket(payload?.errors));
    }
  } catch (e) {
    yield put(actions.removeBasket(e));
  }
}

export function* watchBasket() {
  yield takeEvery(BASKET_ACTIONS.GET_BASKET, basketSaga);
}

/**
 * this saga handles the GET basket by basket Id
 * (1) get basket
 * (2) handle response
 */
export function* basketByBasketIdSaga() {
  try {
    const { basket } = yield select((state) => state.basket);
    yield put(actions.initLoading(true));
    const payload = yield call(getBasketByBasketId, basket?.basketId);
    if (payload?.data) {
      yield put(actions.createBasket(payload?.data));
      yield put(actions.initLoading(false));
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* watchBasketByBasketId() {
  yield takeEvery(BASKET_ACTIONS.GET_BASKET_BY_BASKETID, basketByBasketIdSaga);
}

/**
 * this saga handles the GET basket
 * (1) get basket for merge
 * (2) handle response
 */
export function* getBasketForMergeSaga() {
  let params = {
    customerBasketId: null,
    guestBasketId: null,
    customerId: null,
    customer: {},
  };
  let {
    customer: { customer },
    basket: { basket },
    retail: { tempVATPayload },
  } = yield select((state) => {
    return {
      customer: state.customer,
      basket: state.basket,
      retail: state.retail,
    };
  });
  if (!customer?.customerId) {
    return;
  }
  const {
    street,
    town,
    property,
    county,
    postcode,
    house,
    area,
    firstNames,
    middleName,
    surname,
  } = Object(customer);

  params = {
    ...params,
    ...tempVATPayload,
    customerId: customer?.customerId,
    guestBasketId: basket?.basketId,
    customer: {
      street,
      town,
      property,
      county,
      postcode,
      house,
      area,
      firstNames,
      middleName,
      surname,
    },
  };
  try {
    yield put(actions.setIsBasketMerging(true));
    const payload = yield call(getBasket, customer.customerId);
    if (payload?.data) {
      if (payload?.data?.basket?.basketId) {
        params = {
          ...params,
          customerBasketId: payload?.data?.basket?.basketId,
        };
        yield call(mergeGuestBasketSaga, params);
        yield put({
          type: BASKET_ACTIONS.LOAN_AMOUNT_CALCULATOR_ON_BASKET,
        });
      } else {
        yield call(checkServicesForMerge, params);
      }
    } else {
      yield call(checkServicesForMerge, params);
      yield put(customerActions.setNewCustomerGenerateFromFinder(false));
    }
  } catch (e) {
    yield call(checkServicesForMerge, params);
    yield put(customerActions.setNewCustomerGenerateFromFinder(false));
  }
}

/**Handle if PLEDGE, PURCHASE, 3PC.. service and
 * retail service, for retail merge calling retail items service
 * For other service calling merge-guest-basket*/
function* checkServicesForMerge(action) {
  let {
    basket: { basket },
  } = yield select((state) => {
    return {
      basket: state.basket,
    };
  });

  const groupedItems = groupBy(basket?.basketServices, 'serviceType');
  const isRetailService = groupedItems[BASKET_SERVICE_TYPE.RETAIL];

  if (basket?.basketServices.length > 1 || !isRetailService) {
    yield call(mergeGuestBasketSaga, action);
  } else {
    yield put({
      type: BASKET_ACTIONS.CREATE_BASKET_RETAIL_ITEMS,
      payload: { params: action },
    });
  }
}

/**
 * this saga handles the GET basket
 * (1) post basketId by customer basket and merge with guest basket
 * (2) handle response
 */
export function* mergeGuestBasketSaga(action) {
  try {
    const payload = yield call(postMergeGuestBasket, action);
    if (payload?.data) {
      // checking if a basket is already in committed state, if yes take user to checkout page
      if (payload?.data?.basket?.basketStatus !== BASKET_STATUS.PENDING) {
        yield put(actions.setBasketMergeError(true));
        return;
      } else {
        yield put(actions.getBasket(payload?.data));
        yield put(actions.setIsBasketMerging(false));
        yield put(actions.setBasketMergeError(false));
      }
    } else {
      if (payload?.status === RESPONSE_STATUS.NO_CONTENT) {
        const error = {
          message: 'Merge basket - No content error',
          response: { data: { error: 'Merge basket - No content error' } },
        };
        yield put(actions.itemError(error));
      } else yield put(actions.itemError(payload?.errors));
      yield put(actions.setBasketMergeError(false));
    }
  } catch (e) {
    yield put(actions.setBasketMergeError(true));
    yield put(actions.itemError(e));
  }
}

//merge guest basket with customer basket
export function* watchMergeGuestBasket() {
  yield takeEvery(
    BASKET_ACTIONS.MERGE_GUEST_BASKET_REQUEST,
    getBasketForMergeSaga
  );
}

//delete basket by basket id
export function* deleteBasketSaga(action) {
  try {
    const payload = yield call(deleteCustomerBasket, action.payload);
    if (payload?.data) {
      yield put(actions.removeBasket({}));
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* watchDeleteBasket() {
  yield takeEvery(BASKET_ACTIONS.DELETE_BASKET, deleteBasketSaga);
}

//delete basket service, params - basketId, serviceIds
export function* deleteBasketServiceSaga(action) {
  try {
    const payload = yield call(deleteBasketService, action.payload);
    if (payload?.data) {
      yield put(actions.getBasket(payload?.data));
      delay(100);
      yield put(checkoutActions.success(payload?.data));
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* watchDeleteBasketService() {
  yield takeEvery(
    BASKET_ACTIONS.DELETE_BASKET_SERVICE,
    deleteBasketServiceSaga
  );
}

/** start pledge related basket */
export function* createPledgeIntoBasketSaga(action) {
  try {
    const {
      payload: { params },
    } = action;
    yield put(actions.initLoading(true));
    const payload = yield call(postPledgeIntoBasket, params);
    if (payload?.data) {
      yield put(actions.createBasket(payload?.data));
      yield put(pledgeActions.clearItems());
      yield put(pledgeActions.setImageCaptured(false));
      yield put(stoneActions.clearStoneItems());
      yield put(coinActions.clearCoinItems());
      yield put(valutaionActions.clearValuation());

      if (payload?.data?.serviceType) {
        if (payload?.data?.serviceType === 1) {
          yield put(
            pledgeActions.updatePledgeServiceId(payload?.data?.serviceId)
          );
        } else
          yield put(
            pledgeActions.updatePurchaseServiceId(payload?.data?.serviceId)
          );
      }
      yield delay(150);
      yield put({
        type: BASKET_ACTIONS.LOAN_AMOUNT_CALCULATOR_ON_BASKET,
      });
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* deletePledgeFromBasketSaga(action) {
  try {
    const {
      payload: { params },
    } = action;
    const { editModeBasketPledgeItems, mergedPayload } = yield select(
      (state) => state.basket
    );
    yield put(actions.initDeleteLoadingSpin(params.itemIds[0]));
    const payload = yield call(deletePledgeFromBasket, params);
    if (payload?.data) {
      const items = payload.data.basket?.basketServices?.filter(
        (item) => item.serviceId === mergedPayload.sourceServiceId
      );
      if (items.length === 0) {
        // if all items in a pledge are deleted, the serviceID is also deleted at backend , so we make it null
        yield put(actions.move({ ...mergedPayload, sourceServiceId: null }));
        yield put(actions.deletedTab(params?.serviceId));
      }

      yield put(actions.createBasket(payload?.data));
      yield delay(150);
      yield put({
        type: BASKET_ACTIONS.LOAN_AMOUNT_CALCULATOR_ON_BASKET,
      });
      if (params.itemIds[0] === editModeBasketPledgeItems.itemId) {
        yield put(pledgeActions.clearItems());
        yield put(stoneActions.clearStoneItems());
        yield put(coinActions.clearCoinItems());
        yield put(actions.clearEditMode());
      }
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* editPledgeIntoBasketSaga(action) {
  try {
    const {
      payload: { params },
    } = action;
    yield put(actions.initLoading(true));
    const payload = yield call(putPledgeIntoBasket, params);
    if (payload?.data) {
      yield put(actions.createBasket(payload?.data));
      yield delay(150);
      yield put({
        type: BASKET_ACTIONS.LOAN_AMOUNT_CALCULATOR_ON_BASKET,
      });
      yield put(pledgeActions.clearItems());
      yield put(pledgeActions.setImageCaptured(false));
      yield put(stoneActions.clearStoneItems());
      yield put(coinActions.clearCoinItems());
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* editPledgeItemsSaga(action) {
  const {
    payload: { params },
  } = action;

  yield put(actions.setBasketPledgeItemsEditMode(params));
  yield put(pledgeActions.setImageCaptured(false));
  yield put(pledgeActions.updateItemCategory(params.loanItemType));
  yield put(pledgeActions.updateSubItemCategory(params.subItemType));
  yield put(pledgeActions.updateItemDescription(params.description));
  yield put(pledgeActions.updateMetalPrice(params.metal));
  yield put(pledgeActions.updateWatches(params));
  yield put(pledgeActions.updateHandbags(params));
  yield put(
    pledgeActions.updateItemAdditionalProperties(
      params.itemAdditionalProperties
    )
  );
  yield put(stoneActions.stoneAllItems(params.stones));
  yield put(
    pledgeActions.setItemImageCollectionResponse(params.itemImageCollections)
  );
  //TODO - to be changed to auto increment
  yield put(
    stoneActions.stoneRowId(
      params.stones && params.stones?.length > 0 ? params.stones.length + 1 : 1
    )
  );
  yield put(stoneActions.stoneItemsPrice(reducePledgeItems(params.stones)));
  yield put(
    coinActions.coinAllItems(
      params?.coins?.filter((item) => item.coinFrom === COIN_FROM.ADD_COIN)
    )
  );
  yield put(coinActions.coinItemsPrice(reducePledgeItems(params.coins)));
  //TODO - to be changed to auto-increment
  yield put(
    coinActions.coinRowId(
      params.coins && params.coins?.length > 0 ? params.coins.length + 1 : 1
    )
  );

  //setting image url
  const imageUrl =
    params?.containerId && params?.blobId
      ? `/api/items/image/${params?.containerId}/${params?.blobId}`
      : ``;
  yield put(pledgeActions.updateItemImage(imageUrl));
}

export function* splitPledgeBasketSaga(action) {
  try {
    const {
      payload: { params },
    } = action;
    yield put(actions.initSplit());
    yield put(actions.lockMerge());
    const payload = yield call(splitPledgeIntoBasket, params);
    if (payload?.data) {
      yield put(actions.createBasket(payload?.data));
      yield delay(150);
      yield put({
        type: BASKET_ACTIONS.LOAN_AMOUNT_CALCULATOR_ON_BASKET,
      });
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
      yield put(actions.unlockMerge());
    }
  } catch (e) {
    yield put(actions.itemError(e));
    yield put(actions.unlockMerge());
  }
}

export function* mergePledgeBasketSaga(action) {
  try {
    const {
      payload: { params },
    } = action;

    const { currentActiveTab } = yield select((state) => state.basket);

    yield put(actions.clearMove());
    yield put(actions.initMerge());
    yield put(actions.lockMerge());
    const payload = yield call(mergePledgeIntoBasket, params);
    if (payload?.data) {
      yield put(actions.createBasket(payload?.data));
      //  It is rare that loan-amount calls are triggered with wrong basket, but I have observed that the loan calls did went wrong.
      //  So, to make sure the basket always updates fully before making any loan-amount calls, we need to use the delay
      //  we also could have passed this as payload to loan-amount, load amount pay load depends on redux store for values other than basket.
      //  So we choose the delay of 150ms before making any loan-amount calls
      yield delay(150);
      yield put({
        type: BASKET_ACTIONS.LOAN_AMOUNT_CALCULATOR_ON_BASKET,
      });

      const childTabToDisplay = getPledgePurchaseChildTab(
        payload?.data?.basket,
        currentActiveTab,
        params?.targetServiceId
      );
      yield put(actions.setCurrentChildActiveTab(childTabToDisplay));
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* movePledgeItemsBasketSaga(action) {
  try {
    const {
      payload: { params },
    } = action;

    const { currentActiveTab } = yield select((state) => state.basket);

    yield put(actions.lockMerge());
    const payload = yield call(movePledgeItemsBasket, params);
    if (payload?.data) {
      yield put(actions.createBasket(payload?.data));
      yield delay(150);
      yield put({
        type: BASKET_ACTIONS.LOAN_AMOUNT_CALCULATOR_ON_BASKET,
      });

      const childTabToDisplay = getPledgePurchaseChildTab(
        payload?.data?.basket,
        currentActiveTab
      );
      yield put(actions.setCurrentChildActiveTab(childTabToDisplay));
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
      yield put(actions.unlockMerge());
    }
  } catch (e) {
    yield put(actions.itemError(e));
    yield put(actions.unlockMerge());
  }
}

export function* movePledgePurchaseItemsBasketSaga(action) {
  try {
    const {
      payload: { params, isActionFromBasket = true },
    } = action;

    const { currentActiveTab } = yield select((state) => state.basket);

    yield put(actions.setMoveToLoading(true));
    const payload = yield call(movePledgePurchaseItemsBasket, params);
    if (payload?.data) {
      yield put(actions.createBasket(payload?.data));

      if (payload?.data?.serviceType) {
        if (payload?.data?.serviceType === 1) {
          yield put(
            pledgeActions.updatePledgeServiceId(payload?.data?.serviceId)
          );
        } else
          yield put(
            pledgeActions.updatePurchaseServiceId(payload?.data?.serviceId)
          );
      }
      yield delay(150);
      yield put({
        type: BASKET_ACTIONS.LOAN_AMOUNT_CALCULATOR_ON_BASKET,
      });

      if (isActionFromBasket) {
        const childTabToDisplay = getPledgePurchaseChildTab(
          payload?.data?.basket,
          currentActiveTab,
          params?.targetServiceId
        );
        yield put(actions.setCurrentChildActiveTab(childTabToDisplay));
      }
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* calculatePledgeLoanBasketSaga(action) {
  try {
    const {
      payload: { params },
    } = action;

    yield put(actions.interestPercentageLoading());
    yield put(actions.initMerge());
    const payload = yield call(putPledgeLoanAmountIntoBasket, params);
    if (payload?.data) {
      yield put(actions.createBasket(payload?.data));
      return payload?.data;
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* createPickupRenewFromBasketSaga(action) {
  try {
    const {
      payload: { params },
    } = action;
    yield put(actions.initLoading(true));
    const payload = yield call(postPickupRenewIntoBasket, params);
    if (payload?.data) {
      yield put(actions.createBasket(payload?.data));
      yield put(pickupActions.clearItems());
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* createChequeCashingFromBasketSaga(action) {
  try {
    const { payload } = action;
    let { isChequeCashingHistoryExists } = yield select(
      (state) => state.chequeCashing
    );

    const response = yield call(postChequeCashingIntoBasket, payload);
    if (response?.data) {
      yield put(actions.createBasket(response?.data));

      if (payload?.sendToSupportCenter) {
        const payloadSupport = {
          basketId: response?.data?.basket?.basketId,
          serviceId: response?.data?.currentServiceId,
          cashedBefore: isChequeCashingHistoryExists
            ? CHEQUE_CASHING_HISTORY_EXISTS.YES
            : CHEQUE_CASHING_HISTORY_EXISTS.NO,
        };

        const resp = yield call(sendToSupportCenter, payloadSupport);
        if (resp?.data)
          yield put(chequeCashingActions.sentToSupportCenter(true));
        else yield put(chequeCashingActions.sentToSupportCenter(false));

        yield put(chequeCashingActions.setSendToSupportCenterClicked(false));
      }
      yield put(
        chequeCashingActions.setIsProceedToBasket(payload?.redirectToBasket)
      );
    } else if (response?.errors) {
      yield put(chequeCashingActions.sentToSupportCenter(false));
    }
  } catch (e) {
    yield put(chequeCashingActions.sentToSupportCenter(false));
    yield put(actions.itemError(e));
  }
}

export function* deletePickupRenewFromBasketSaga(action) {
  try {
    const {
      payload: { params },
    } = action;
    yield put(actions.initLoading(true));
    const payload = yield call(deletePickupRenewFromBasket, params);
    if (payload?.data) {
      yield put(actions.createBasket(payload?.data));
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* deleteChequeCashingFromBasketSaga(action) {
  try {
    const {
      payload: { params },
    } = action;
    const response = yield call(deleteChequeCashingFromBasket, params);
    if (response?.data) {
      yield put(actions.createBasket(response?.data));
    } else if (response?.errors) {
      yield put(actions.itemError(response?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* watchDeleteChequeChashingBasket() {
  yield takeEvery(
    BASKET_ACTIONS.DELETE_BASKET_CHEQUE_CASHING_ITEMS,
    deleteChequeCashingFromBasketSaga
  );
}

//not in use now - will be removed
export function* watchCreatePickupRenewBasket() {
  yield takeEvery(
    BASKET_ACTIONS.CREATE_BASKET_PICKUP_RENEW_ITEMS,
    createPickupRenewFromBasketSaga
  );
}

export function* watchCreateChequeCashingBasket() {
  yield takeEvery(
    BASKET_ACTIONS.CREATE_BASKET_CHEQUE_CASHING_ITEMS,
    createChequeCashingFromBasketSaga
  );
}

export function* watchDeletePickupRenewBasket() {
  yield takeEvery(
    BASKET_ACTIONS.DELETE_BASKET_PICKUP_RENEW_ITEMS,
    deletePickupRenewFromBasketSaga
  );
}

export function* watchCreatePledgeBasket() {
  yield takeEvery(
    BASKET_ACTIONS.CREATE_BASKET_PLEDGE_ITEMS,
    createPledgeIntoBasketSaga
  );
}

export function* watchDeletePledgeBasket() {
  yield takeEvery(
    BASKET_ACTIONS.DELETE_BASKET_PLEDGE_ITEMS,
    deletePledgeFromBasketSaga
  );
}

export function* watchSplitPledgeBasket() {
  yield takeEvery(
    BASKET_ACTIONS.SPLIT_BASKET_PLEDGE_ITEMS,
    splitPledgeBasketSaga
  );
}
export function* watchMergePledgeBasket() {
  yield takeEvery(
    BASKET_ACTIONS.MERGE_BASKET_PLEDGE_ITEMS,
    mergePledgeBasketSaga
  );
}

export function* watchMovePledgeItemsBasket() {
  yield takeEvery(
    BASKET_ACTIONS.MOVE_BASKET_PLEDGE_ITEMS,
    movePledgeItemsBasketSaga
  );
}

export function* watchMovePledgePurchaseItemsBasket() {
  yield takeEvery(
    BASKET_ACTIONS.MOVE_BASKET_PLEDGE_PURCHASE_ITEMS,
    movePledgePurchaseItemsBasketSaga
  );
}

export function* watchCalculatePledgeLoanBasket() {
  yield takeEvery(
    BASKET_ACTIONS.BASKET_PLEDGE_LOAN_CALCULATE,
    calculatePledgeLoanBasketSaga
  );
}

//No api call in below watcher
export function* watchEditPledgeItemsForReducer() {
  yield takeEvery(PLEDGE_ACTIONS.EDIT_PLEDGE_ITEMS, editPledgeItemsSaga);
}

export function* watchEditPledgeBasket() {
  yield takeEvery(
    BASKET_ACTIONS.EDIT_BASKET_PLEDGE_ITEMS,
    editPledgeIntoBasketSaga
  );
}

/** end start pledge related basket */

/** start retail related basket */
export function* createRetailIntoBasketSaga(action) {
  let { isExitandSaveMode } = yield select((state) => state.basket);
  try {
    const {
      payload: { params },
    } = action;
    yield put(actions.initLoading(true));
    const payload = yield call(postRetailIntoBasket, params);
    if (payload?.data) {
      if (isExitandSaveMode) {
        yield put(actions.checkIsExitAndSaveMode(false));
        return;
      }
      yield put(actions.createBasket(payload.data));
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
    yield put(actions.setIsBasketMerging(false));
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* deleteRetailFromBasketSaga(action) {
  try {
    const {
      payload: { params },
    } = action;
    yield put(actions.initLoading(true));
    const payload = yield call(deleteRetailFromBasket, params);
    if (payload?.data) {
      yield put(actions.createBasket(payload?.data));
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* editRetailIntoBasketSaga(action) {
  try {
    const {
      payload: { params },
    } = action;
    const payload = yield call(putRetailIntoBasket, params);
    if (payload?.data) {
      yield put(actions.createBasket(payload?.data));
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

/**
this saga handles the Edit check caching
(1) edit check caching and merge
(2) handle response
*/
export function* editChequeCashingIntoBasketSaga(action) {
  try {
    const { payload } = action;
    const response = yield call(putChequeCashingIntoBasket, payload);
    if (response?.data) {
      yield put(actions.createBasket(response?.data));
      yield put(
        chequeCashingActions.setIsProceedToBasket(payload?.redirectToBasket)
      );
    } else if (response?.errors) {
      yield put(actions.itemError(response?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* watchDeleteRetailBasket() {
  yield takeEvery(
    BASKET_ACTIONS.DELETE_BASKET_RETAIL_ITEMS,
    deleteRetailFromBasketSaga
  );
}

export function* watchCreateRetailBasket() {
  yield takeEvery(
    BASKET_ACTIONS.CREATE_BASKET_RETAIL_ITEMS,
    createRetailIntoBasketSaga
  );
}

export function* watchEditRetailBasket() {
  yield takeEvery(
    BASKET_ACTIONS.EDIT_BASKET_RETAIL_ITEMS,
    editRetailIntoBasketSaga
  );
}

export function* watchEditChequeCashingBasket() {
  yield takeEvery(
    BASKET_ACTIONS.EDIT_BASKET_CHEQUE_CASHING_ITEMS,
    editChequeCashingIntoBasketSaga
  );
}

/**start fx */
export function* deleteFxTransactionSaga(action) {
  const {
    payload: { params },
  } = action;
  try {
    //request delete fx transaction from API
    yield put(actions.initDeleteLoadingSpin(params.serviceIds[0]));
    const payload = yield call(deleteFxTransaction, params);
    if (payload?.data) {
      yield put(actions.createBasket(payload?.data));
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}
export function* watchDeleteFxTransactionSaga() {
  yield takeEvery(
    BASKET_ACTIONS.DELETE_BASKET_FX_ITEMS,
    deleteFxTransactionSaga
  );
}
/** end fx */

/**start western union */
export function* postWesternUnionSaga(action) {
  let { isExitandSaveMode } = yield select((state) => state.basket);
  const {
    payload: { params },
  } = action;
  try {
    //request insert western union from API
    yield put(actions.initLoading(true));
    const payload = yield call(postWesternUnionBasket, params);
    if (payload?.data) {
      if (isExitandSaveMode) {
        yield put(actions.checkIsExitAndSaveMode(false));
        return;
      }
      yield put(actions.createBasket(payload?.data));
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* deleteWesternUnionFromBasketSaga(action) {
  try {
    const {
      payload: { params },
    } = action;
    yield put(actions.initLoading(true));
    const payload = yield call(deleteWesternUnionFromBasket, params);
    if (payload?.data) {
      yield put(actions.createBasket(payload?.data));
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* watchPostWesternUnionSaga() {
  yield takeEvery(
    BASKET_ACTIONS.CEATE_BASKET_WESTERN_UNION,
    postWesternUnionSaga
  );
}

export function* watchDeleteWesternUnionBasket() {
  yield takeEvery(
    BASKET_ACTIONS.DELETE_BASKET_WESTERN_UNION_ITEMS,
    deleteWesternUnionFromBasketSaga
  );
}
/** end western union */

/** purchase return */
function* purchaseReturnBasketCommonFunction(payload, purchaseReturn) {
  let {
    basket: { basket },
  } = yield select((state) => {
    return {
      basket: state.basket,
    };
  });

  const requestPayload = {
    ...payload,
    serviceType: BASKET_SERVICE_TYPE.PURCHASERETURN,
    items: purchaseReturn,
    basketId: basket?.basketId,
  };
  try {
    const payload = yield call(postPurchaseReturnIntoBasket, requestPayload);
    if (payload?.data) {
      yield put(actions.createBasket(payload?.data));
      yield put(pickupActions.setProceedToBasket(true));
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* createPledgeOrPurchaseReturnBasketSaga(action) {
  let {
    customer: { customer },
    basket: { basket },
    user: { user, selectedStore },
    pickupRenew: { pledgeAgreement, purchaseReturn },
  } = yield select((state) => {
    return {
      customer: state.customer,
      basket: state.basket,
      user: state.user,
      pickupRenew: state.pickupRenew,
    };
  });

  const {
    street,
    town,
    property,
    county,
    postcode,
    houseName,
    area,
    firstNames,
    middleName,
    surname,
  } = customer;

  let requestPayload = {
    customerId: customer.customerId || undefined,
    storeId: selectedStore?.storeId,
    userId: user?.userId,
    customer: {
      street,
      town,
      property,
      county,
      postcode,
      houseName,
      area,
      firstNames,
      middleName,
      surname,
    },
    customerName:
      joinValues([customer.firstNames, customer.surname], true) || undefined,
    employeeId: user?.employeeId,
    storeAddress: selectedStore?.storeAddress,
    storePostalCode: selectedStore?.postalCode,
    storeTelephone: selectedStore?.telephone,
    userName: user?.displayName,
  };

  if (pledgeAgreement?.length > 0) {
    requestPayload = {
      ...requestPayload,
      serviceType: BASKET_SERVICE_TYPE.PICKUPRENEW,
      items: pledgeAgreement,
      basketId: basket?.basketId,
    };
    try {
      yield put(actions.initLoading(true));
      const payload = yield call(postPickupRenewIntoBasket, requestPayload);
      if (payload?.data) {
        yield put(actions.createBasket(payload?.data));
        if (purchaseReturn?.length > 0) {
          yield call(
            purchaseReturnBasketCommonFunction,
            requestPayload,
            purchaseReturn
          );
        } else yield put(pickupActions.setProceedToBasket(true));
      } else if (payload?.errors) {
        yield put(actions.itemError(payload?.errors));
        if (purchaseReturn?.length > 0) {
          yield call(
            purchaseReturnBasketCommonFunction,
            requestPayload,
            purchaseReturn
          );
        }
      }
    } catch (e) {
      yield put(actions.itemError(e));
    }
  } else if (purchaseReturn?.length > 0) {
    yield call(
      purchaseReturnBasketCommonFunction,
      requestPayload,
      purchaseReturn
    );
  }
}

export function* deletePurchaseReturnBasketSaga(action) {
  try {
    const {
      payload: { params },
    } = action;
    yield put(actions.initLoading(true));
    const payload = yield call(deletePurchaseReturnItemFromBasket, params);
    if (payload?.data) {
      yield put(actions.createBasket(payload?.data));
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* watchCreatePledgeOrPurchaseReturnBasket() {
  yield takeEvery(
    BASKET_ACTIONS.CREATE_BASKET_FOR_PICKUP_OR_PURCHASE,
    createPledgeOrPurchaseReturnBasketSaga
  );
}

export function* watchDeletePurchaseReturnBasket() {
  yield takeEvery(
    BASKET_ACTIONS.DELETE_BASKET_PURCHASE_RETURN_ITEMS,
    deletePurchaseReturnBasketSaga
  );
}

export function* maxTenItemsInPledgeBasketSaga(sagaAction) {
  let {
    basket: { basket },
  } = yield select((state) => {
    return {
      basket: state.basket,
    };
  });

  try {
    yield put(actions.initLoading(true));
    const payload = yield call(putTenItemsInPledge, basket.basketId);
    if (payload?.data) {
      yield put(actions.getBasket(payload?.data));
      yield put(actions.initLoading(false));
    } else if (payload?.errors) {
      console.log(payload?.errors);
    }
  } catch (e) {
    console.log(e);
  } finally {
    sagaAction?.navigate(`/${ROUTE_CONFIG.BASKET}`);
  }
}

export function* watchTenItemsInBasket() {
  yield takeEvery(
    BASKET_ACTIONS.PUT_TEN_ITEMS_IN_BASKET,
    maxTenItemsInPledgeBasketSaga
  );
}

export function* mergeGuestRecallBasketSaga(action) {
  const {
    payload: { params },
  } = action;

  yield put(actions.initGuestBasketRestoreLoading(true));

  try {
    const response = yield call(postMergeGuestBasket, params);
    if (response?.data) {
      yield put(actions.createBasket(response?.data));
      yield put(actions.setGuestBasketRestoreError(false));
      yield delay(150);
      yield put({
        type: BASKET_ACTIONS.LOAN_AMOUNT_CALCULATOR_ON_BASKET,
      });
    } else {
      yield put(actions.setGuestBasketRestoreError(true));
    }
    yield put(actions.initGuestBasketRestoreLoading(false));
  } catch (e) {
    yield put(actions.setGuestBasketRestoreError(true)); // true to catch the error and not redirect to basket page
    yield put(actions.initGuestBasketRestoreLoading(false));
  }
}

export function* watchMergeBasketsSaga() {
  yield takeEvery(
    BASKET_ACTIONS.RECALL_MERGE_BASKETS,
    mergeGuestRecallBasketSaga
  );
}

export function* surplusBasketSaga(action) {
  const {
    payload: { params },
  } = action;

  try {
    const surplusBasket = yield call(postSurplusBasket, params);
    if (surplusBasket?.data) {
      yield put(actions.createBasket(surplusBasket?.data));
    } else if (surplusBasket?.errors) {
      yield put(actions.itemError(surplusBasket?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* watchSurplusBasketSaga() {
  yield takeEvery(
    BASKET_ACTIONS.CREATE_BASKET_SURPLUS_ITEMS,
    surplusBasketSaga
  );
}
export function* updateCustomerDetailsBasketSaga(action) {
  const {
    payload: { params },
  } = action;

  try {
    const response = yield call(postUpdatedCustomerDetails, params);
    if (response?.data) {
      yield put(actions.initLoading(false));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* watchUpdateCustomerDetailsBasketSaga() {
  yield takeEvery(
    BASKET_ACTIONS.UPDATE_CUSTOMER_DETAILS,
    updateCustomerDetailsBasketSaga
  );
}

export function* deleteSurplusBasketSaga(action) {
  try {
    const {
      payload: { params },
    } = action;
    yield put(actions.initLoading(true));
    const payload = yield call(deleteSurplusItemFromBasket, params);
    if (payload?.data) {
      yield put(actions.createBasket(payload?.data));
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* watchDeleteSurplusBasketSaga() {
  yield takeEvery(
    BASKET_ACTIONS.DELETE_BASKET_SURPLUS_ITEMS,
    deleteSurplusBasketSaga
  );
}
