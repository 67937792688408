import React, { useEffect } from 'react';
import { Modal, Row, Col } from 'antd';
import { SERVICE_TYPE } from 'globalConstants';
import CustomerSmartSearch from 'components/layout/CustomerSmartSearch';
import { useDispatch, useSelector } from 'react-redux';
import { actions as customerActions } from 'redux/reducers/customer';

import './index.less';

interface FindCustomerProps {
  isFindCustomerVisible: boolean;
  onFindCustomerPopupCancel: () => void;
}

const FindCustomer = ({
  isFindCustomerVisible,
  onFindCustomerPopupCancel,
}: FindCustomerProps) => {
  const {
    customer: { isRedirectToOtherPage },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
      pickupRenew: state.pickupRenew,
    };
  });
  const dispatch = useDispatch();

  const onCancelClick = () => {
    onFindCustomerPopupCancel();
  };

  const onFindCustomerClick = () => {
    dispatch(customerActions.setRedirectToOtherPage(true));
    onFindCustomerPopupCancel();
  };

  useEffect(() => {
    if (!isRedirectToOtherPage) {
      onFindCustomerClick();
    }
  }, [isRedirectToOtherPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      title="Find Customer"
      open={isFindCustomerVisible}
      width={850}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={onCancelClick}
      footer={null}
      centered
      className="find-customer-popup"
    >
      <Row>
        <Col span={24}>
          <CustomerSmartSearch
            width={24}
            serviceType={SERVICE_TYPE.CHEQUE_CASHING}
          />
        </Col>
      </Row>
    </Modal>
  );
};
export default FindCustomer;
