import React, { useState } from 'react';
import VirtualGrid from 'components/common/virtualGrid/index';
import { GRID_NO_URL } from 'globalConstants';

const BuybackHistory = ({ rows = [], onRowChange }: any) => {
  const [columns] = useState([
    {
      name: ' ',
      title: ' ',
      type: 'radiovisibilty',
      getCellValue: (row: any) => row.transactionId,
      onChange: (row: object, e: any) => {
        onRowChange(row, e.target.checked);
      },
    },
    {
      name: 'transactionType',
      title: 'Service',
      getCellValue: (row: any) => row.transactionType,
      allowSorting: false,
    },
    {
      name: 'fromCurrencyCode',
      title: 'Currency From',
      getCellValue: (row: any) => (
        <span>
          {row.fromCurrencyCode} {row.fromAmount?.toFixed(2)}
        </span>
      ),
    },

    {
      name: 'currencyExchangeRate',
      title: 'Exchange Rate',
      getCellValue: (row: any) => row?.currencyExchangeRate,
    },
    {
      name: 'rateGuarantee',
      title: 'Guarantee',
      getCellValue: (row: any) => row?.rateGuarantee,
    },
    {
      name: 'toCurrencyCode',
      title: 'Currency To',
      getCellValue: (row: any) => (
        <span>
          {row.toCurrencyCode} {row.toAmount?.toFixed(2)}
        </span>
      ),
    },
    {
      name: 'buyBackBalance',
      title: 'Available',
      getCellValue: (row: any) => (
        <span>
          {row.toCurrencyCode} {row.buyBackBalance?.toFixed(2)}
        </span>
      ),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: ' ', width: 70 },
    { columnName: 'transactionType', width: 100 },
    { columnName: 'fromCurrencyCode', width: 150, align: 'right' },
    { columnName: 'currencyExchangeRate', width: 150, align: 'right' },
    { columnName: 'rateGurantee', width: 100, align: 'right' },
    { columnName: 'toCurrencyCode', width: 150, align: 'right' },
    { columnName: 'buyBackBalance', width: 150, align: 'right' },
  ]);

  const formattedColumns = [' '];

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={rows}
        formattedColumns={formattedColumns}
      />
    </>
  );
};

export default BuybackHistory;
