import React from 'react';
import styled from 'styled-components';

const StyleHeading = styled.div`
  width: calc(100% - 18px);
  height: 4.2rem;
  font-weight: bold;
  font-size: var(--font-size-16);
  color: rgba(34, 52, 69, 0.85);
  background: var(--catskill-white);
  padding: 10px 12px;
  position: absolute;
  bottom: 1px;
`;

const ModalFooterStrip: React.FC<any> = ({ className, children }) => (
  <div>
    <StyleHeading className={className}>{children}</StyleHeading>
  </div>
);

export default ModalFooterStrip;
