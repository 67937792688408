import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Input, Form, notification } from 'antd';
import styled from 'styled-components';
import {
  getTillCurrencies,
  postCashUp,
  postFxCashUp,
  getStoreTillStatus,
  getCurrencyDenominations,
  getStoreUsers,
} from 'services/cashManagement';
import { actions } from 'redux/reducers/cashManagement';
import { Button } from 'components/common/Button';
import DenominationsTable from './DenominationsTable';
import { TILL_LABELS } from '../constants';
import '../index.less';

import { cacheStaticDataWithArgs } from 'utils/cacheServices';
import { calculateTotalSumByKey, toFixedNoRound } from 'utils/util';
import { isEmpty } from 'lodash';
import Select from 'components/common/Select';
import TextArea from 'components/common/TextArea';

const StyledRow = styled(Row)`
  padding: 16px;
  flex-grow: 1;
`;

const StyledText = styled.div<{ fontSize: string; padding: string }>`
  font-weight: var(--font-weight-500);
  font-size: var(--${(props) => props.fontSize});
  padding: ${(props) => props.padding};
`;

const StyledFormRow = styled(Row)`
  margin-block-end: 13px;
`;

const StyledTotalRow = styled(Row)`
  border: 1px solid var(--light-gray);
  border-top: none;
  border-radius: 4px 4px 0px 0px;
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-500);
  padding: 10px 20px;
`;

const StyledCol = styled(Col)`
  margin-top: 20px;
`;

const CreateTill = ({
  setUserTillData,
  userTillVarianceValues,
  resetTillVariance,
}: any) => {
  const [form] = Form.useForm();
  const [storeUsersList, setStoreUsersList] = useState<Array<any>>([]);
  const [tillUser, setTillUser] = useState<any>();
  const [userTillCurrencies, setUserTillCurrencies] = useState<Array<any>>([]);
  const [tillCurrency, setTillCurrency] = useState<any>({});
  const [totalAmount, setTotalAmount] = useState<any>(0);
  const [isCashUpLoading, setCashUpLoading] = useState<boolean>(false);
  const [isDenominationAmountValid, setIsDenominationAmountValid] =
    useState<boolean>(false);

  const dispatch = useDispatch();
  const {
    user: { selectedStore, user },
    cashManagement: { currencyDenominations, initCurrencyDenominations, reset },
  } = useSelector((state: any) => {
    return {
      user: state.user,
      cashManagement: state.cashManagement,
    };
  });

  const onTillUserChange = (value: any) => {
    setTillUser(value);
    setTillCurrency({});
    resetTillVariance();
    form.resetFields();
  };

  const onTillCurrencyChange = (value: any) => {
    const updatedValue = JSON.parse(value);
    if (
      updatedValue &&
      updatedValue.currencyCode !==
        (tillCurrency.currencyCode || userTillVarianceValues?.currencyCode)
    ) {
      setTillCurrency(updatedValue);
      resetTillVariance();
      form.resetFields();
    }
  };

  const getStoreUsersForTill = async () => {
    try {
      const response = await cacheStaticDataWithArgs(
        `STORE_USER-${selectedStore?.storeId}`,
        getStoreUsers,
        selectedStore?.storeId
      );

      if (response?.data) {
        setStoreUsersList(response.data);
        const defaultTillUser = response?.data.find(
          (x: any) => x.userId === user.userId
        );
        setTillUser(defaultTillUser?.userId);
        dispatch(actions.setStoreTillUsers(response.data));
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const getTillCurrenciesForUser = async () => {
    try {
      if (tillUser) {
        const response = await getTillCurrencies(tillUser);
        if (response?.data) {
          setUserTillCurrencies(response.data);
          const defaultTillCurrency = response?.data.find(
            (x: any) => x.currencyCode === TILL_LABELS.DEFAULT_TILL_CURRENCY
          );
          if (defaultTillCurrency) setTillCurrency(defaultTillCurrency);
          else setTillCurrency(response?.data[0] || {});
        }
      }
    } catch (e: any) {
      console.log(e);
    }
  };
  const getCurrencyDenominationsforTillCurrency = async () => {
    try {
      if (!isEmpty(tillCurrency)) {
        const response = await cacheStaticDataWithArgs(
          `CURRENCY-${tillCurrency?.currencyCode}`,
          getCurrencyDenominations,
          tillCurrency?.currencyCode
        );
        if (response) {
          dispatch(actions.updateCurrencyDenominationsRow(response.data));
          dispatch(actions.setInitCurrencyDenominationsRow(response.data)); // storing the initial denominations to redux to retrieve the initial values later on to avoid making api call
        }
      } else dispatch(actions.updateCurrencyDenominationsRow([]));
    } catch (e: any) {
      console.log(e);
    }
  };

  const onCashUpClick = async () => {
    const {
      tillOperationId,
      tillUserId,
      totalCountBalance = 0,
      currencyCode,
    } = userTillVarianceValues;
    const authorisationHeaders = {
      authorisationUserName: form.getFieldValue('employeeId'),
      authorisationPassword: form.getFieldValue('employeePassword'),
    };
    const payload = {
      tillOperationId,
      tillUserId,
      totalCountBalance,
      note: form.getFieldValue('notes'),
      currencyCode,
    };

    try {
      setCashUpLoading(true);
      if (currencyCode === 'GBP') {
        const response = await postCashUp(payload, authorisationHeaders);
        onCashUpResponse(response);
      } else {
        const response = await postFxCashUp(payload, authorisationHeaders);
        onCashUpResponse(response);
      }
    } catch (e: any) {
      console.log(e);
      setCashUpLoading(false);
      form.resetFields();
      resetTillVariance();
      notification.error({
        message: e?.response?.data?.error,
        duration: 2,
      });
    }
  };

  const onCashUpResponse = (response: any) => {
    if (response?.data) {
      setCashUpLoading(false);
      getTillCurrenciesForUser(); // refresh till currencies to remove the cashed up currency
      getStoreTillsStatus(); // refresh store till status
      form.resetFields();
      resetTillVariance();
      notification.success({
        message: TILL_LABELS.NOTIFICATION.CASH_UP_SUCCESS,
        duration: 2,
      });
    }
  };

  const getStoreTillsStatus = async () => {
    try {
      const response = await getStoreTillStatus();
      if (response?.data) {
        dispatch(actions.setStoreTillStatusData(response.data));
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  useEffect(() => {
    getStoreUsersForTill();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getTillCurrenciesForUser();
  }, [tillUser]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getCurrencyDenominationsforTillCurrency();
  }, [tillCurrency]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reset) {
      dispatch(
        actions.updateCurrencyDenominationsRow(initCurrencyDenominations)
      );
      form.resetFields();
      resetTillVariance();
    }
  }, [reset]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const total = calculateTotalSumByKey(currencyDenominations, 'amount');
    setTotalAmount(total);
    setUserTillData({
      currencyCode: tillCurrency?.currencyCode,
      userId: tillUser,
      userTillTotal: total,
    });

    const isValuesValid = currencyDenominations.every(
      (el: any) => !el?.isInvalidAmount
    );

    setIsDenominationAmountValid(isValuesValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyDenominations]);

  return (
    <>
      <StyledRow>
        <Col span={24}>
          <Row justify="space-between">
            <Col span={12}>
              <StyledText fontSize="font-size-16" padding="0">
                {TILL_LABELS.TILL}
              </StyledText>
            </Col>
            <Col span={11}>
              <StyledText fontSize="font-size-16" padding="0">
                {TILL_LABELS.CURRENCY}
              </StyledText>
            </Col>
          </Row>
          <Row justify="space-between" gutter={16}>
            <Col span={12}>
              <Select
                value={tillUser}
                onChange={onTillUserChange}
                options={storeUsersList?.map((el: any) => {
                  return {
                    value: el.userId,
                    label: el.displayName,
                  };
                })}
              />
            </Col>
            <Col span={11}>
              <Select
                value={
                  !isEmpty(tillCurrency)
                    ? `${tillCurrency?.currencyDescription} (${tillCurrency.currencyCode})`
                    : ''
                }
                onChange={onTillCurrencyChange}
                options={userTillCurrencies?.map((el: any) => {
                  return {
                    key: el.currencyCode,
                    value: JSON.stringify(el),
                    label: `${el?.currencyDescription} (${el.currencyCode})`,
                  };
                })}
              />
            </Col>
          </Row>
          <Row>
            <StyledCol span={24}>
              <div className="hr-line"></div>
            </StyledCol>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="space-between">
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <DenominationsTable />
                </Col>
              </Row>
              <StyledTotalRow justify="space-between">
                <Col span={12}>Total</Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: tillCurrency?.currencySymbol,
                    }}
                  ></span>
                  <span>{totalAmount.toFixed(2)}</span>
                </Col>
              </StyledTotalRow>
            </Col>
            <Col span={11}>
              <Row>
                <Col span={24}>
                  <table className="till-check-table">
                    <tbody>
                      <tr>
                        <td style={{ width: '60%' }}>{TILL_LABELS.BALANCE}</td>
                        <td>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: tillCurrency?.currencySymbol,
                            }}
                          ></span>
                          <span>
                            {toFixedNoRound(
                              userTillVarianceValues?.totalCountBalance || 0
                            )}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>{TILL_LABELS.SYSTEM_AMOUNT}</td>
                        <td>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: tillCurrency?.currencySymbol,
                            }}
                          ></span>
                          <span>
                            {toFixedNoRound(
                              userTillVarianceValues?.systemAmount || 0
                            )}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>{TILL_LABELS.VARIANCE}</td>
                        <td>
                          {userTillVarianceValues?.variance < 0 && '-'}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: tillCurrency?.currencySymbol,
                            }}
                          ></span>
                          <span>
                            {toFixedNoRound(
                              Math.abs(userTillVarianceValues?.variance) || 0
                            )}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <StyledText fontSize="font-size-14" padding="15px 0px">
                    {TILL_LABELS.CONFIRMED_BY}
                  </StyledText>
                </Col>
                <Col span={24}>
                  <Form
                    onFinish={onCashUpClick}
                    layout="vertical"
                    form={form}
                    id="cashUpForm"
                  >
                    <StyledFormRow>
                      <Col span={24}>
                        <Form.Item
                          label={TILL_LABELS.EMPLOYEE_ID}
                          name="employeeId"
                          className="label-control text-weight"
                          rules={[
                            {
                              required: true,
                              message: TILL_LABELS.ERROR_MSG.EMPLOYEE_ID,
                            },
                          ]}
                        >
                          <Input
                            className="input-control"
                            autoComplete="new-password"
                            disabled={!userTillVarianceValues?.cashupNeeded}
                          />
                        </Form.Item>
                      </Col>
                    </StyledFormRow>
                    <StyledFormRow>
                      <Col span={24}>
                        <Form.Item
                          label={TILL_LABELS.PASSWORD}
                          name="employeePassword"
                          className="label-control text-weight"
                          rules={[
                            {
                              required: true,
                              message: TILL_LABELS.ERROR_MSG.PASSWORD,
                            },
                          ]}
                        >
                          <Input
                            className="input-control"
                            autoComplete="new-password"
                            type="password"
                            disabled={!userTillVarianceValues?.cashupNeeded}
                          />
                        </Form.Item>
                      </Col>
                    </StyledFormRow>
                    <StyledFormRow>
                      <Col span={24}>
                        <Form.Item
                          label={TILL_LABELS.NOTES}
                          name="notes"
                          className="label-control text-weight"
                          rules={[
                            {
                              required: userTillVarianceValues?.variance,
                              message: TILL_LABELS.ERROR_MSG.NOTES,
                            },
                          ]}
                        >
                          <TextArea
                            rows={5}
                            autoComplete="off"
                            className="input-control till-check-textarea"
                            maxLength={128} //as per what we have in NEO
                            disabled={!userTillVarianceValues?.cashupNeeded}
                          />
                        </Form.Item>
                      </Col>
                    </StyledFormRow>
                    <StyledFormRow>
                      <Col span={24}>
                        <Button
                          form="cashUpForm"
                          itemProp="secondary"
                          key="cashUp"
                          htmlType="submit"
                          disabled={
                            isEmpty(userTillVarianceValues) ||
                            !userTillVarianceValues?.cashupNeeded ||
                            !isDenominationAmountValid
                          }
                          loading={isCashUpLoading}
                        >
                          {TILL_LABELS.BUTTON.CASH_UP}
                        </Button>
                      </Col>
                    </StyledFormRow>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </StyledRow>
    </>
  );
};

export default CreateTill;
