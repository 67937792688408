import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { SERVICE_TYPE } from 'globalConstants';
import { joinValues } from 'utils/util';
import CustomerSmartSearch from 'components/layout/CustomerSmartSearch';
import { actions as customerActions } from 'redux/reducers/customer';
import { actions } from 'redux/reducers/pickupRenew';
import { isEmpty } from 'lodash';

interface ChangeOwnershipSearchPopupProps {
  visible: boolean;
  setChangeOwnershipSearchPopupVisible: () => void;
}

const StyledModal = styled(Modal)`
  & .ant-modal-body {
    padding: 20px 0px 0px 10px;
  }
`;

const ChangeOwnershipSearchPopup = ({
  visible,
  setChangeOwnershipSearchPopupVisible,
}: ChangeOwnershipSearchPopupProps) => {
  const {
    customer: { customer, isRedirectToOtherPage },
    pickupRenew: { pledgeAgreement, selectedAgreementRowId },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
      pickupRenew: state.pickupRenew,
    };
  });
  const dispatch = useDispatch();
  const [agreement, setAgreement] = useState<any>({});

  const {
    street,
    town,
    property,
    county,
    postcode,
    houseName,
    area,
    firstNames,
    middleName,
    surname,
  } = customer;

  const onCancelClick = () => {
    setChangeOwnershipSearchPopupVisible();
  };

  const onFindCustomerClick = () => {
    if (!isEmpty(customer)) {
      const updatedAgreement = {
        ...agreement,
        customerPresent: true,
        changeOwnership: {
          ...agreement.changeOwnership,
          newCustomerId: customer.customerId,
          newCustomerName: joinValues(
            [customer.firstNames, customer.surname],
            true
          ),
          isOwnershipChanged: true,
          customer: {
            street,
            town,
            property,
            county,
            postcode,
            houseName,
            area,
            firstNames,
            middleName,
            surname,
          },
        },
      };
      dispatch(actions.updatePledgeAgreement(updatedAgreement));
    }
    dispatch(actions.setSelectedAgreementRow([]));
    dispatch(customerActions.setRedirectToOtherPage(true));
    setChangeOwnershipSearchPopupVisible();
  };

  useEffect(() => {
    if (selectedAgreementRowId?.length === 1) {
      const index = pledgeAgreement.findIndex(
        (x: any) => x.id === selectedAgreementRowId[0]
      );
      setAgreement(pledgeAgreement[index]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isRedirectToOtherPage) {
      onFindCustomerClick();
    }
  }, [isRedirectToOtherPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StyledModal
        title="Find Customer"
        open={visible}
        width={800}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        footer={null}
        centered
      >
        <Row>
          <Col span={24}>
            <CustomerSmartSearch width={24} serviceType={SERVICE_TYPE.PICKUP} />
          </Col>
        </Row>
      </StyledModal>
    </>
  );
};

export default ChangeOwnershipSearchPopup;
