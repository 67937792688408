export const TRANS_MESSAGE = 'Transaction(s) did not process successfully. ';
export const RECHECK_STATUS = 'Recheck Status';
export const TERMS_CONDITION = 'Terms & Conditions';

export const ENABLE_TERMS_FOR_SERVICE = [1, 6, 8]; // 1 - pledge, 6-checkcashing
export const ENABLE_TERMS_FOR_ACTION = [2, 3]; // 2 - Renew , 3 - Paydown
export const PRE_CONTRACT_SERVICES = [1, 8]; // 1 - pledge, 8 - pickup

export const TERM_COND_PLDEGE_TITLE = 'Important Customer Information ';
export const TERM_COND_CHECK_CASHING_TITLE = 'Third Party Cheque Mandate ';

export const TERM_COND_PLEDGE_CONTENT = `
    <p>Please read this to the customer</>
    <p>If you do not redeem your article(s) by <strong>{date}</strong> by paying the outstanding balance under the agreement then it/they may be sold. </p>
    <p>You have the right to withdraw from this agreement and you have 14 days to do this. You can let us know verbally or in writing. However, you would have to pay back the money borrowed plus interest calculated on a daily rate as specified in the agreement up to the day you repay us. </p>
    <p>Do you have any questions?</p>
`;

export const TERM_COND_CHECK_CASHING_CONTENT = `
    <div>The Customer Third Party Cheque Mandate has been 
    sent to the printer
    Please confirm the customer has signed the mandate and the back of the cheque to continue.</div>
`;

export const PRE_CONTRACT_INFO_TITLE = 'Print Pre-Contracts';
export const PRE_CONTRACTS_INFO = `
  <p>Please read this to the customer </p>
  <p>You are entitled to a copy of the Pre-Contract before the pawn agreement is made and it would be provided free of charge.</p> 
`;

export const PRE_CONTRACTS_PLEDGE_TITLE = `
  <p>Would you like us to print a Pre-Contract for each agreement?</p>
`;

export const RECHECK_TRANS_MESSAGE = `
  <div>Transaction(s) did not process successfully</div>
  <div>Please select one of the following options:</div>
`;

export const RETRY = 'Retry';
export const REMOVE_FAILED_TRANSACTIONS = 'Remove Failed Transaction(s)';
export const RECHECK_REMOVE_WARNING =
  'Warning: Remove Failed Transaction(s) will permanently delete the failed transaction(s)';

export const SERVICE_NAME = {
  PLEDGE: 'New Pledge',
  PURCHASE: 'Purchase',
  RETAIL: {
    SALE: 'Sale',
    RETURN: 'Return',
  },
  PURCHASE_RETURN: 'Purchase Return',
  SURPLUS: 'Pledge Surplus',
};

export const EMPTY_CHECKOUT = {
  EMPTY: 'Your checkout is empty!',
  SERVICE:
    'Select a service to continue serving your customer <p style="font-size: 22px; font-weight: 500; margin:0;">OR</p> Press Exit & Save to end this customer session',
};

export const PRINT_ALERT = {
  BUTTON_LABEL: 'Ok',
  TITLE: 'Warning',
  CONTENT_1:
    'One or more document have failed to print. Please check the printer and then reprint the necessary paperwork.',
  CONTENT_2: 'If this fails, please contact IT support for assistance.',
};

export const SUCCESSFUL = 'successful';
