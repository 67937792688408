import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { GRID_NO_URL } from 'globalConstants';
import VirtualGrid from 'components/common/virtualGrid/index';
import ImageLoader from 'components/common/ImageLoader';
import { GET_PLEDGE_ITEM_DETAILS } from 'action_creators/pledgeManagement';
import { getBarcodeSticker } from 'services/inventoryManagement';
import { sendToPrinter } from 'services/user';
import { CloseOutlined } from '@ant-design/icons';
import { Button as LinkButton, notification, Row, Col } from 'antd';
import { BARCODE_STICKER_FAILED_TEXT, SENT_TO_PRINTER } from '../../constants';
import { BUTTON_LABELS } from '../../constants';
import { getParentImageUrl } from 'utils/util';

import { actions } from 'redux/reducers/pledgeManagement';
import { pledgeActions } from 'redux/reducers/pawnbroking';
import { coinActions } from 'redux/reducers/pawnbroking/coin';
import { stoneActions } from 'redux/reducers/pawnbroking/stone';

interface PledgeLoanSummaryProps {
  pledgeItemsList: Array<any>;
}

const TableRow = ({
  row,
  onClickTableRow,
  onClickDeleteRow,
  confirmedItems,
  failedPfiBarcodeList,
  newPledgeItemsList,
  onPrintBarcode,
  pledgeItemsList,
  ...restProps
}: any) => {
  const onRowClick = () => {
    onClickTableRow(row);
  };

  const Cell = () => {
    const { column } = restProps;
    if (column.name === 'image') {
      return (
        <Table.Cell
          {...restProps}
          style={{
            cursor: 'pointer',
          }}
        />
      );
    }
    if (column.name === 'status' && confirmedItems.includes(row.id)) {
      if (newPledgeItemsList?.length || row?.barcode) {
        return (
          <LinkButton
            type="link"
            title="Print barcode Sticker"
            onClick={() => onPrintBarcode(row?.barcode)}
            className="link-button"
          >
            {BUTTON_LABELS.REPRINT}
          </LinkButton>
        );
      } else {
        return (
          <Table.Cell {...restProps}>
            <img
              alt="green tick"
              className={'green-tick'}
              src={require('../../../../assets/images/tick.svg')}
            />
          </Table.Cell>
        );
      }
    }
    if (
      column.name === 'barcode' &&
      failedPfiBarcodeList.includes(row.id) &&
      !row.barcode
    ) {
      return (
        <Table.Cell {...restProps}>
          <Row className="chekout-failed">
            <Col span={18}>
              <Row className="top-border"></Row>
              <Row>
                <Col>{BUTTON_LABELS.FAILED}</Col>
              </Row>
            </Col>
            <Col span={4}>
              <CloseOutlined />
            </Col>
          </Row>
        </Table.Cell>
      );
    }

    return (
      <Table.Cell
        onClick={onRowClick}
        {...restProps}
        style={{
          cursor: 'pointer',
        }}
      />
    );
  };

  return <>{Cell()}</>;
};

const PledgeLoanSummary = ({
  pledgeItemsList = [],
}: PledgeLoanSummaryProps) => {
  const {
    pledgeManagement: {
      confirmedItems,
      newPledgeItemsList,
      failedPfiBarcodeList,
    },
  } = useSelector((state: any) => {
    return {
      pledgeManagement: state.pledgeManagement,
    };
  });
  const dispatch = useDispatch();

  const onClickTableRow = (row: any) => {
    dispatch(actions.resetPledgeItemDetails());

    //clear all redux states (pledge, coin, stone)
    dispatch(pledgeActions.clearItems());
    dispatch(stoneActions.clearStoneItems());
    dispatch(coinActions.clearCoinItems());

    dispatch({
      type: GET_PLEDGE_ITEM_DETAILS,
      payload: {
        itemId: row.id,
      },
    });
    dispatch(actions.setSelectedItemId(row.id));
  };

  const onSelectionChange = (rowId: any) => {
    /* do nothing */
  };

  const [columns] = useState([
    {
      name: 'image',
      title: 'Item Image',
      getCellValue: (row: any) => {
        <ImageLoader
          imageUrl={getParentImageUrl(row.itemImageCollections)}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />;
      },
    },
    {
      name: 'description',
      title: 'Description',
      getCellValue: (row: any) => row?.description,
    },
    {
      name: 'totalWeight',
      title: 'Weight',
      type: 'weight',
      getCellValue: (row: any) => row?.weight?.toFixed(2) + 'g',
    },
    {
      name: 'loanAmount',
      title: 'Value',
      type: 'currency',
      getCellValue: (row: any) => row?.amount,
    },
    {
      name: 'barcode',
      title: 'Barcode',
      type: 'currency',
      getCellValue: (row: any) => row?.barcode,
    },
    {
      name: 'status',
      title: 'Status',
      type: 'currency',
      getCellValue: (row: any) => row?.loanAmount,
    },
  ]);

  const onPrintBarcode = async (inputValue: any) => {
    try {
      const pdf = await getBarcodeSticker({
        barcode: inputValue,
        custom: {
          description: '',
        },
      });
      if (pdf?.data) {
        sendToPrinter(pdf, 'printbarcode', '_Retail_Barcode');
        notification.success({
          message: SENT_TO_PRINTER,
          duration: 5,
        });
      }
    } catch (e: any) {
      notification.error({
        message: (e as Error).message,
        description: BARCODE_STICKER_FAILED_TEXT,
        duration: 10,
      });
    }
  };

  useEffect(() => {
    if (
      newPledgeItemsList.length > 0 &&
      failedPfiBarcodeList.length === 0 &&
      newPledgeItemsList.length === pledgeItemsList.length
    ) {
      newPledgeItemsList?.map((item: any) => onPrintBarcode(item?.barcode));
    }
  }, [newPledgeItemsList, failedPfiBarcodeList, pledgeItemsList]);

  const [tableColumnExtensions] = useState([
    { columnName: 'image', width: '15%' },
    { columnName: 'description', width: '35%' },
    { columnName: 'totalWeight', width: '12%', align: 'right' },
    { columnName: 'loanAmount', width: '10%', align: 'right' },
    { columnName: 'barcode', width: '13%', align: 'right' },
    { columnName: 'status', width: '10%', align: 'right' },
  ]);

  const [formattedColumns] = useState(['loanAmount', 'delete']);

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        formattedColumns={formattedColumns}
        rowData={
          newPledgeItemsList?.length ? newPledgeItemsList : pledgeItemsList
        }
        cellComponent={(restProps: any) => (
          <TableRow
            confirmedItems={confirmedItems}
            failedPfiBarcodeList={failedPfiBarcodeList}
            onClickTableRow={onClickTableRow}
            onPrintBarcode={onPrintBarcode}
            newPledgeItemsList={newPledgeItemsList}
            pledgeItemsList={pledgeItemsList}
            {...restProps}
          />
        )}
        rowSelectionEnable={false}
        showSelectionColumn={false}
        onSelectionChange={onSelectionChange}
      />
    </>
  );
};

export default PledgeLoanSummary;
