import * as urls from '../apiUrls';
import { doRequest } from 'utils/request';
import { b64toBlob } from 'utils/util';

const getCurrencies = () => {
  return doRequest(`${urls.GET_FX_CURRENCIES}`, 'get');
};

const getBuyback = (params) => {
  return doRequest(`${urls.GET_FX_CURRENCY_BUYBACK}`, 'post', params);
};

const getBuybackCharges = (params) => {
  return doRequest(`${urls.GET_FX_CURRENCY_BUYBACK_CHARGES}`, 'get');
};

const postConvertCurrency = (params) => {
  return doRequest(`${urls.FX_CURRENCY_CONVERTED}`, 'post', params);
};

const postRoundUpDownCurrency = (params) => {
  return doRequest(`${urls.FX_CURRENCY_ROUND_UP_DOWN}`, 'post', params);
};

const postLedgerBalanceCurrency = (params) => {
  return doRequest(`${urls.FX_CURRENCY_LEDGER_BALANCE}`, 'post', params);
};

const postStockBalanceCurrency = (params) => {
  return doRequest(`${urls.FX_CURRENCY_STOCK_BALANCE}`, 'post', params);
};

const getFxTransactionSummary = (params) => {
  return doRequest(
    `${urls.GET_FX_CURRENCY_TRANSACTION_SUMMARY}/${params}`,
    'get'
  );
};

const postFxTransaction = (params) => {
  return doRequest(`${urls.FX_CURRENCY_TRANSACTION}`, 'post', params);
};

const getFxTransactionOverview = (params) => {
  return doRequest(`${urls.FX_TRANSACTION_OVERVIEW}/${params}`, 'get');
};

const validateFXTransaction = (params) => {
  return doRequest(`${urls.VALIDATE_FX_TRANSACTION}`, 'post', params);
};

const getTMGuestDocumenList = (params) => {
  return doRequest(`${urls.GET_TM_GUEST_DOCUMENTS}`, 'post', params);
};

const uploadTravelMoneyGuestDocuments = (guestParams, imageParams) => {
  let blob = b64toBlob(imageParams?.image);

  let formData = new FormData();
  formData.append('firstNames', guestParams?.firstNames);
  formData.append('surname', guestParams?.surname);
  formData.append('dateOfBirth', guestParams?.dateOfBirth);
  formData.append('postcode', guestParams?.postcode);

  formData.append('file', blob);
  formData.append('documentTypeId', imageParams?.documentTypeId);
  formData.append(
    'documentTypeName',
    imageParams?.documentTypeName || 'document'
  );

  return doRequest(
    `${urls.UPLOAD_TM_GUEST_DOCUMENT}`,
    'post',
    formData,
    'multipart/form-data'
  );
};

export {
  getCurrencies,
  getBuyback,
  getBuybackCharges,
  postConvertCurrency,
  postRoundUpDownCurrency,
  postLedgerBalanceCurrency,
  postStockBalanceCurrency,
  getFxTransactionSummary,
  postFxTransaction,
  getFxTransactionOverview,
  validateFXTransaction,
  getTMGuestDocumenList,
  uploadTravelMoneyGuestDocuments,
};
