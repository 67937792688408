import { call, put, takeEvery, select, all } from 'redux-saga/effects';

import { notification } from 'antd';

import * as ACTIONS from 'action_creators/basket';
import {
  validateCreatedPledge,
  validateCreatedRetail,
  validateCreatedPickupRenew,
  validateCreatedFx,
  validateCreatedPurchase,
  validateCreatedThirdPartyCheque,
  validateCreatedWU,
  validateCreatedPurchaseReturn,
  getUserSterlingBalance,
  getUserFxBalance,
  validateCreatedSurplus,
  validateLoanActions,
  validateNewPledgePermission,
} from 'services/basket/validation';

import { basketSaga } from './index';
import { actions } from 'redux/reducers/basket/index';
import { groupBy } from 'lodash';

import { BASKET_SERVICE_TYPE, GENERIC_ERROR } from 'globalConstants';
import {
  BLOCKED_LOAN_ACTIONS,
  TASK_OWNER_VALUE,
} from 'components/consumerDuty/constants';
import { RETAIL_ACTION_ID } from 'components/payment/constants';

import { checkAvailableCurrencies } from 'utils/util';

export function* validateCreatedServicesBasketSaga(action) {
  const { payload: { authorisationHeaders = null } = {} } = action;
  //latest call for get basket because interestRate is comming different value
  yield call(basketSaga);

  const { basket } = yield select((state) => state.basket);
  if (!basket?.basketServices) {
    return;
  }

  yield put(actions.clearValidateCheckoutServices({}));

  const groupedItems = groupBy(basket.basketServices, 'serviceType');
  let parallelCalls = [];
  Object.keys(groupedItems).forEach((serviceItem) => {
    const service = groupedItems[serviceItem];
    service.forEach((item) => {
      let params = {};
      switch (serviceItem) {
        case `${BASKET_SERVICE_TYPE.PLEDGE}`:
          params = {
            serviceId: item.serviceId,
            params: item?.pledge?.request,
          };
          parallelCalls.push(
            call(validatePledgeServiceSaga, {
              payload: params,
              authorisationHeaders: authorisationHeaders,
            })
          );
          break;
        case `${BASKET_SERVICE_TYPE.RETAIL}`:
          params = {
            serviceId: item.serviceId,
            params: item?.retail?.request,
          };
          parallelCalls.push(
            call(validateRetailServiceSaga, {
              payload: params,
              authorisationHeaders: authorisationHeaders,
            })
          );
          break;
        case `${BASKET_SERVICE_TYPE.PICKUPRENEW}`:
          params = {
            serviceId: item.serviceId,
            params: item?.pickupRenew?.request,
          };
          parallelCalls.push(
            call(validatePickupServiceSaga, {
              payload: params,
              authorisationHeaders: authorisationHeaders,
            })
          );
          break;
        case `${BASKET_SERVICE_TYPE.FX}`:
          params = {
            serviceId: item.serviceId,
            params: item?.fx?.request,
          };
          parallelCalls.push(
            call(validateFxServiceSaga, {
              payload: params,
              authorisationHeaders: authorisationHeaders,
            })
          );
          break;
        case `${BASKET_SERVICE_TYPE.PURCHASE}`:
          params = {
            serviceId: item.serviceId,
            params: item?.purchase?.request,
          };
          parallelCalls.push(
            call(validatePurchaseServiceSaga, {
              payload: params,
              authorisationHeaders: authorisationHeaders,
            })
          );
          break;
        case `${BASKET_SERVICE_TYPE.CHEQUECASHING}`:
          params = {
            serviceId: item.serviceId,
            params: item?.thirdPartyCheque?.request,
          };
          parallelCalls.push(
            call(validateTpcServiceSaga, {
              payload: params,
              authorisationHeaders: authorisationHeaders,
            })
          );
          break;
        case `${BASKET_SERVICE_TYPE.WU}`:
          params = {
            serviceId: item.serviceId,
            params: item?.westernUnion?.request,
          };
          parallelCalls.push(
            call(validateWUServiceSaga, {
              payload: params,
              authorisationHeaders: authorisationHeaders,
            })
          );
          break;
        case `${BASKET_SERVICE_TYPE.PURCHASERETURN}`:
          params = {
            serviceId: item.serviceId,
            params: item?.purchaseReturn?.request,
          };
          parallelCalls.push(
            call(validatePurchaseReturnServiceSaga, {
              payload: params,
              authorisationHeaders: authorisationHeaders,
            })
          );
          break;
        case `${BASKET_SERVICE_TYPE.SURPLUS}`:
          params = {
            serviceId: item.serviceId,
            params: item?.surplus?.request,
          };
          parallelCalls.push(
            call(validateCreatedSurplusSaga, {
              payload: params,
              authorisationHeaders: authorisationHeaders,
            })
          );
          break;
        default:
          break;
      }
    });
  });

  yield call(validateCurrenciesAvailabilityBasketSaga);
  if (parallelCalls.length > 0) {
    try {
      const response = yield all(parallelCalls);
      console.log('Response', response);
      if (response.length > 0) {
        yield put(actions.setProceedForCheckout(true));
      }
    } catch (e) {
      console.log('payload', e);
    }
  } else {
    //if not any service validate then need jump for next page
    yield put(actions.setProceedForCheckout());
  }
}

/**validate pledge service payload for authorization */
export function* validatePledgeServiceSaga(action) {
  const {
    payload: { params, serviceId },
    authorisationHeaders,
  } = action;

  let validatePayload = {
    serviceId: serviceId,
    authorizationToken: null,
    status: null,
  };
  try {
    const payload = yield call(
      validateCreatedPledge,
      params,
      authorisationHeaders
    );
    if (payload?.data) {
      validatePayload.authorizationToken = payload?.data?.token;
      yield put(actions.setValidateCheckoutServices(validatePayload));
    } else if (payload?.errors) {
      validatePayload.error = payload?.data?.error;
      yield put(actions.setValidateCheckoutServices(validatePayload));
    }
  } catch (e) {
    validatePayload.error = e?.response?.data?.error;
    validatePayload.status = e?.response?.status;
    yield put(actions.setValidateCheckoutServices(validatePayload));
  }
}

/**validate retail service payload for authorization */
export function* validateRetailServiceSaga(action) {
  const {
    payload: { params, serviceId },
    authorisationHeaders,
  } = action;

  let validatePayload = {
    serviceId: serviceId,
    authorizationToken: null,
    status: null,
  };
  try {
    const payload = yield call(
      validateCreatedRetail,
      params,
      authorisationHeaders
    );

    if (payload?.data) {
      validatePayload.authorizationToken = payload?.data?.token;
      yield put(actions.setValidateCheckoutServices(validatePayload));
    } else if (payload?.errors) {
      validatePayload.error = payload?.data?.error;
      yield put(actions.setValidateCheckoutServices(validatePayload));
    }
  } catch (e) {
    validatePayload.error = e?.response?.data?.error;
    validatePayload.status = e?.response?.status;
    yield put(actions.setValidateCheckoutServices(validatePayload));
  }
}

/**validate pickup service payload for authorization */
export function* validatePickupServiceSaga(action) {
  const {
    payload: { params, serviceId },
    authorisationHeaders,
  } = action;

  let validatePayload = {
    serviceId: serviceId,
    authorizationToken: null,
    status: null,
  };
  try {
    const payload = yield call(
      validateCreatedPickupRenew,
      params,
      authorisationHeaders
    );

    if (payload?.data) {
      validatePayload.authorizationToken = payload?.data?.token;
      yield put(actions.setValidateCheckoutServices(validatePayload));
    } else if (payload?.errors) {
      validatePayload.error = payload?.data?.error;
      yield put(actions.setValidateCheckoutServices(validatePayload));
    }
  } catch (e) {
    validatePayload.status = e?.response?.status;
    validatePayload.error = e?.response?.data?.error;
    yield put(actions.setValidateCheckoutServices(validatePayload));
  }
}

/**validate fx service payload for authorization */
export function* validateFxServiceSaga(action) {
  const {
    payload: { params, serviceId },
    authorisationHeaders,
  } = action;

  let validatePayload = {
    serviceId: serviceId,
    authorizationToken: null,
    status: null,
  };
  try {
    const payload = yield call(validateCreatedFx, params, authorisationHeaders);

    if (payload?.data) {
      validatePayload.authorizationToken = payload?.data?.token;
      yield put(actions.setValidateCheckoutServices(validatePayload));
    } else if (payload?.errors) {
      validatePayload.error = payload?.data?.error;
      yield put(actions.setValidateCheckoutServices(validatePayload));
    }
  } catch (e) {
    validatePayload.status = e?.response?.status;
    validatePayload.error = e?.response?.data?.error;
    yield put(actions.setValidateCheckoutServices(validatePayload));
  }
}

/*validate thirdpartycheque service payload for authorization */
export function* validateTpcServiceSaga(action) {
  const {
    payload: { params, serviceId },
    authorisationHeaders,
  } = action;

  let validatePayload = {
    serviceId: serviceId,
    authorizationToken: null,
    status: null,
  };
  try {
    const payload = yield call(
      validateCreatedThirdPartyCheque,
      params,
      authorisationHeaders
    );

    if (payload?.data) {
      validatePayload.authorizationToken = payload?.data?.token;
      yield put(actions.setValidateCheckoutServices(validatePayload));
    } else if (payload?.errors) {
      validatePayload.error = payload?.data?.error;
      yield put(actions.setValidateCheckoutServices(validatePayload));
    }
  } catch (e) {
    validatePayload.status = e?.response?.status;
    validatePayload.error = e?.response?.data?.error;
    yield put(actions.setValidateCheckoutServices(validatePayload));
  }
}

/*validate purchase service payload for authorization */
export function* validatePurchaseServiceSaga(action) {
  const {
    payload: { params, serviceId },
    authorisationHeaders,
  } = action;

  let validatePayload = {
    serviceId: serviceId,
    authorizationToken: null,
    status: null,
  };
  try {
    const payload = yield call(
      validateCreatedPurchase,
      params,
      authorisationHeaders
    );

    if (payload?.data) {
      validatePayload.authorizationToken = payload?.data?.token;
      yield put(actions.setValidateCheckoutServices(validatePayload));
    } else if (payload?.errors) {
      validatePayload.error = payload?.data?.error;
      yield put(actions.setValidateCheckoutServices(validatePayload));
    }
  } catch (e) {
    validatePayload.status = e?.response?.status;
    validatePayload.error = e?.response?.data?.error;
    yield put(actions.setValidateCheckoutServices(validatePayload));
  }
}

/*validate Western union service payload for authorization */
export function* validateWUServiceSaga(action) {
  const {
    payload: { params, serviceId },
    authorisationHeaders,
  } = action;

  let validatePayload = {
    serviceId: serviceId,
    authorizationToken: null,
    status: null,
  };
  try {
    const payload = yield call(validateCreatedWU, params, authorisationHeaders);

    if (payload?.data) {
      validatePayload.authorizationToken = payload?.data?.token;
      yield put(actions.setValidateCheckoutServices(validatePayload));
    } else if (payload?.errors) {
      validatePayload.error = payload?.data?.error;
      yield put(actions.setValidateCheckoutServices(validatePayload));
    }
  } catch (e) {
    validatePayload.status = e?.response?.status;
    validatePayload.error = e?.response?.data?.error;
    yield put(actions.setValidateCheckoutServices(validatePayload));
  }
}

/*validate purchase-return service payload for authorization */
export function* validatePurchaseReturnServiceSaga(action) {
  const {
    payload: { params, serviceId },
    authorisationHeaders,
  } = action;

  let validatePayload = {
    serviceId: serviceId,
    authorizationToken: null,
    status: null,
  };
  try {
    const payload = yield call(
      validateCreatedPurchaseReturn,
      params,
      authorisationHeaders
    );

    if (payload?.data) {
      validatePayload.authorizationToken = payload?.data?.token;
      yield put(actions.setValidateCheckoutServices(validatePayload));
    } else if (payload?.errors) {
      validatePayload.error = payload?.data?.error;
      yield put(actions.setValidateCheckoutServices(validatePayload));
    }
  } catch (e) {
    validatePayload.status = e?.response?.status;
    validatePayload.error = e?.response?.data?.error;
    yield put(actions.setValidateCheckoutServices(validatePayload));
  }
}

export function* validateCurrenciesAvailabilityBasketSaga(action) {
  const {
    basket: { basket, noCurrenciesAvailableCheck },
    user: { user, selectedStore },
  } = yield select((state) => {
    return {
      basket: state.basket,
      user: state.user,
    };
  });
  if (noCurrenciesAvailableCheck) return;

  let parallelCalls = [];
  const fxCurrencies = basket?.totalFX?.fxCurrencyToPayout?.filter(
    (x) => x.currency !== 'GBP'
  );

  const payloadParam = {
    userId: user?.userId,
    storeNumber: selectedStore?.storeId,
  };

  let orderedPayload = {
    gbp: [],
    fx: [],
  };

  if (basket?.totalAmount < 0) {
    orderedPayload.gbp = [
      { currency: 'GBP', amount: Math.abs(basket?.totalAmount) },
    ];
    parallelCalls.push(
      call(getUserSterlingBalance, {
        ...payloadParam,
        currencies: orderedPayload.gbp,
      })
    );
  }
  if (fxCurrencies?.length > 0) {
    orderedPayload.fx = fxCurrencies;
    parallelCalls.push(
      call(getUserFxBalance, {
        ...payloadParam,
        currencies: orderedPayload.fx,
      })
    );
  }

  if (parallelCalls.length > 0) {
    try {
      const [gbpBalance, fxBlalance] = yield all(parallelCalls);
      let availCurrencies = [];
      if (gbpBalance?.data?.currencies) {
        availCurrencies = [...availCurrencies, ...gbpBalance.data.currencies];
      }
      if (fxBlalance?.data?.currencies) {
        availCurrencies = [...availCurrencies, ...fxBlalance.data.currencies];
      }
      const availableAmts = checkAvailableCurrencies(availCurrencies, [
        ...orderedPayload.gbp,
        ...orderedPayload.fx,
      ]);
      if (availableAmts?.isCurrenciesNotAvailable) {
        yield put(actions.setCurrenciesAvailable(availableAmts));
      } else {
        yield put(actions.clearCurrenciesAvailable());
      }
    } catch (e) {
      //Block if Service through any error
      yield put(
        actions.setCurrenciesAvailable({
          currencies: [],
          isCurrenciesNotAvailable: true,
        })
      );
    }
  } else {
    //Allow if balance is available
    yield put(actions.clearCurrenciesAvailable());
  }
}

/*validate Surplus service payload for authorization */
export function* validateCreatedSurplusSaga(action) {
  const {
    payload: { params, serviceId },
    authorisationHeaders,
  } = action;

  let validatePayload = {
    serviceId: serviceId,
    authorizationToken: null,
    status: null,
  };
  try {
    const payload = yield call(
      validateCreatedSurplus,
      params,
      authorisationHeaders
    );

    if (payload?.data) {
      validatePayload.authorizationToken = payload?.data?.token;
      yield put(actions.setValidateCheckoutServices(validatePayload));
    } else if (payload?.errors) {
      validatePayload.error = payload?.data?.error;
      yield put(actions.setValidateCheckoutServices(validatePayload));
    }
  } catch (e) {
    validatePayload.status = e?.response?.status;
    validatePayload.error = e?.response?.data?.error;
    yield put(actions.setValidateCheckoutServices(validatePayload));
  }
}

export function* consumerDutyValidationSaga() {
  const { basket } = yield select((state) => state.basket);
  if (!basket?.basketServices) {
    return;
  }

  var parallelCalls = [];
  var groupedItems = groupBy(basket.basketServices, 'serviceType');

  yield put(
    actions.setConsumerDutyValidationError({ message: '', error: false })
  );

  Object.keys(groupedItems).forEach((serviceItem) => {
    if (serviceItem === `${BASKET_SERVICE_TYPE.PICKUPRENEW}`) {
      var blockedActions = {};
      var loanIds = [];

      const service = groupedItems[serviceItem];
      service.forEach((item) => {
        let loanId = item?.pickupRenew?.request?.item?.id || 0;
        let action = item?.pickupRenew?.request?.item?.selectedActionType;

        if (
          action === RETAIL_ACTION_ID.PAYDOWN ||
          action === RETAIL_ACTION_ID.RENEW ||
          action === RETAIL_ACTION_ID.PARTIAL_PAY
        ) {
          let actionName =
            action === RETAIL_ACTION_ID.RENEW
              ? BLOCKED_LOAN_ACTIONS.RENEWAL
              : action === RETAIL_ACTION_ID.PAYDOWN
              ? BLOCKED_LOAN_ACTIONS.PAYDOWN
              : BLOCKED_LOAN_ACTIONS.PARTIAL_PAY;

          blockedActions[loanId] = actionName;
          loanIds.push(loanId);
        }
      });

      parallelCalls.push(
        call(validateLoanActionsSaga, {
          payload: { loanIds, blockedActions, customerId: basket?.customerId },
        })
      );
    }

    if (serviceItem === `${BASKET_SERVICE_TYPE.PLEDGE}`) {
      parallelCalls.push(
        call(validateNewPledgePermissionSaga, {
          payload: {
            totalPledgeAmount: Math.abs(basket?.totalPledgeAmount),
            customerId: basket?.customerId,
          },
        })
      );
    }
  });

  if (parallelCalls.length > 0) {
    try {
      const response = yield all(parallelCalls);
      if (response.length > 0) {
        const blockedServices = [];
        response?.forEach((res) => blockedServices.push(...res));

        yield put(actions.setConsumerDutyBlockedServices(blockedServices));
        yield put(actions.setConsumerDutyActionsValidated(true));
      }
    } catch (e) {
      yield put(actions.setConsumerDutyActionsValidated(false));
      notification.error({
        message: 'Failed to validate Consumer Duty Actions',
        duration: 5,
      });
    }
  } else yield put(actions.setConsumerDutyActionsValidated(true));
}

function* validateLoanActionsSaga(action) {
  const {
    payload: { loanIds, blockedActions, customerId },
  } = action;
  try {
    let payload = { customerId, loanIds };

    const response = yield call(validateLoanActions, payload);
    if (response?.data) {
      const blockedServices = [];
      response?.data?.forEach((loan) => {
        const blockedServicesForLoan = [];
        loan?.loanServices.forEach((services) => {
          if (services?.serviceStatus === 'Disabled')
            blockedServicesForLoan.push(services?.serviceName);
        });

        let isServiceRequestedBlocked = blockedServicesForLoan.includes(
          blockedActions[loan.loanId]
        );
        if (isServiceRequestedBlocked)
          blockedServices.push(blockedActions[loan.loanId]);
      });

      const uniqueServices = blockedServices.filter(
        (value, index, array) => array.indexOf(value) === index
      );
      return uniqueServices;
    }
  } catch (error) {
    let validationError = {
      message: 'Consumer duty loan action validation issue',
      error: true,
    };

    yield put(actions.setConsumerDutyValidationError(validationError));
    notification.error({
      message: GENERIC_ERROR,
      duration: 5,
    });
  }
}

function* validateNewPledgePermissionSaga(action) {
  const {
    payload: { customerId, totalPledgeAmount },
  } = action;

  let params = {
    customerId,
    totalPledgeAmount,
    team: TASK_OWNER_VALUE.SSTEAM,
  };

  try {
    const response = yield call(validateNewPledgePermission, params);
    if (response?.data) {
      const blockedServices = [];
      if (!response?.data?.serviceAllowed)
        blockedServices.push(response?.data?.serviceName);

      return blockedServices;
    }
  } catch (error) {
    let validationError = {
      message: 'Consumer duty pledge permission validation issue',
      error: true,
    };

    yield put(actions.setConsumerDutyValidationError(validationError));
    notification.error({
      message: GENERIC_ERROR,
      duration: 5,
    });
  }
}

/**watcher for all service validate which is in basket */
export function* watchValidateCreatedServicesBasket() {
  yield takeEvery(
    ACTIONS.VALIDATE_ALL_SERVICES_BASKET,
    validateCreatedServicesBasketSaga
  );
}

export function* watchValidatePledgeServiceBasket() {
  yield takeEvery(
    ACTIONS.VALIDATE_PLEDGE_SERVICE_BASKET,
    validatePledgeServiceSaga
  );
}

export function* watchValidateRetailServiceBasket() {
  yield takeEvery(
    ACTIONS.VALIDATE_RETAIL_SERVICE_BASKET,
    validateRetailServiceSaga
  );
}

export function* watchValidatePickupServiceBasket() {
  yield takeEvery(
    ACTIONS.VALIDATE_PICKUP_SERVICE_BASKET,
    validatePickupServiceSaga
  );
}

export function* watchValidateFxServiceBasket() {
  yield takeEvery(ACTIONS.VALIDATE_FX_SERVICE_BASKET, validateFxServiceSaga);
}

export function* watchValidateTpcServiceBasket() {
  yield takeEvery(ACTIONS.VALIDATE_TPC_SERVICE_BASKET, validateTpcServiceSaga);
}

export function* watchValidatePurchaseServiceBasket() {
  yield takeEvery(
    ACTIONS.VALIDATE_PURCHASE_SERVICE_BASKET,
    validatePurchaseServiceSaga
  );
}

export function* watchValidateWUServiceBasket() {
  yield takeEvery(ACTIONS.VALIDATE_WU_SERVICE_BASKET, validateWUServiceSaga);
}

export function* watchValidatePurchaseReturnServiceBasket() {
  yield takeEvery(
    ACTIONS.VALIDATE_PURCHASE_RETURN_SERVICE_BASKET,
    validatePurchaseReturnServiceSaga
  );
}

export function* watchValidateUserTillBalance() {
  yield takeEvery(
    ACTIONS.VALIDATE_USER_TILL_BALANCE,
    validateCurrenciesAvailabilityBasketSaga
  );
}

export function* watchValidateSurplusServiceBasket() {
  yield takeEvery(
    ACTIONS.VALIDATE_SURPLUS_SERVICE_BASKET,
    validateCreatedSurplusSaga
  );
}

export function* watchConsumerDutyValidationSaga() {
  yield takeEvery(ACTIONS.VALIDATE_CONSUMER_DUTY, consumerDutyValidationSaga);
}

export function* watchValidateLoanActionsSaga() {
  yield takeEvery(ACTIONS.VALIDATE_LOAN_ACTIONS, validateLoanActionsSaga);
}

export function* watchValidateNewPledgePermission() {
  yield takeEvery(
    ACTIONS.VALIDATE_NEW_PLEDGE_PERMISSIONS,
    validateNewPledgePermissionSaga
  );
}
