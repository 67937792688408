import React from 'react';
import ControlledAlert from 'components/common/ControlledAlert';
import { Row, Spin } from 'antd';
import styled from 'styled-components';
import { currencyFormat } from 'utils/util';
import {
  SHOW_ALERT_TYPE,
  PAYMENT_POPUP_TEXT,
  BUTTON_LABELS,
} from 'components/notifications/constants';

const StyledNote = styled(Row)`
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-500);
  width: 325px;
`;

const StyledNoCashNote = styled(Row)`
  font-size: var(--font-size-base);
  margin-top: 10px;
`;

const StyledMsg = styled(Row)`
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-500);
`;

const StyledAmount = styled(Row)`
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-500);
  margin-top: 20px;
`;

interface PaymentsStatusPopupProps {
  paymentStatusAlertType: any;
  isPaymentPopupVisible: boolean;
  setIsPaymentPopupVisible: (isVisible: boolean) => void;
  amount: any;
  isDisableComplete: boolean;
  onCompleteClick: () => void;
  userName: string;
}

const PaymentsStatusPopup = ({
  paymentStatusAlertType = 0,
  isPaymentPopupVisible = false,
  setIsPaymentPopupVisible,
  amount = 0,
  isDisableComplete = false,
  onCompleteClick,
  userName = '',
}: PaymentsStatusPopupProps) => {
  let yesButtonMarginRight = '318px';
  let deafultCompleteBtnText = BUTTON_LABELS.COMPLETE;
  if (
    paymentStatusAlertType !== SHOW_ALERT_TYPE.APPROVED &&
    paymentStatusAlertType !== SHOW_ALERT_TYPE.DIFFERENT_USER &&
    paymentStatusAlertType !== SHOW_ALERT_TYPE.STORE_TILL &&
    paymentStatusAlertType !== SHOW_ALERT_TYPE.USER_TILL
  ) {
    deafultCompleteBtnText = BUTTON_LABELS.COMPLETE_AS_CASH;
  } else if (paymentStatusAlertType === SHOW_ALERT_TYPE.APPROVED) {
    yesButtonMarginRight = '250px';
    deafultCompleteBtnText = BUTTON_LABELS.COMPLETE_AS_FASTERPAYMENT;
  } else if (paymentStatusAlertType === SHOW_ALERT_TYPE.DIFFERENT_USER) {
    deafultCompleteBtnText = BUTTON_LABELS.COMPLETE_AS_CASH;
  }

  const MsgString = () => {
    return (
      <>
        {paymentStatusAlertType === SHOW_ALERT_TYPE.APPROVED ? (
          <StyledMsg>{PAYMENT_POPUP_TEXT.APPROVED_MESSAGE}</StyledMsg>
        ) : (
          <StyledMsg>{PAYMENT_POPUP_TEXT.REJECTED_MESSAGE}</StyledMsg>
        )}
      </>
    );
  };

  const getSecondaryMessage = () => {
    return (
      <>
        {paymentStatusAlertType === SHOW_ALERT_TYPE.STORE_TILL && (
          <StyledNote>{PAYMENT_POPUP_TEXT.NO_FUND_STORE_TILL}</StyledNote>
        )}
        {paymentStatusAlertType === SHOW_ALERT_TYPE.USER_TILL && (
          <StyledNote>{PAYMENT_POPUP_TEXT.NO_FUND_USER_TILL}</StyledNote>
        )}
        {paymentStatusAlertType === SHOW_ALERT_TYPE.DIFFERENT_USER && (
          <>
            <StyledNote>
              {PAYMENT_POPUP_TEXT.DIFFERENT_USER_BEFORE}
              {userName}
              {PAYMENT_POPUP_TEXT.DIFFERENT_USER_AFTER}
              {`${userName}.`}
            </StyledNote>
            <StyledNoCashNote>
              {PAYMENT_POPUP_TEXT.NO_PHYSICAL_TRANSFER}
            </StyledNoCashNote>
          </>
        )}
        {paymentStatusAlertType === SHOW_ALERT_TYPE.APPROVED ? (
          <StyledAmount>
            {PAYMENT_POPUP_TEXT.AMOUNT_PAID}: {currencyFormat(amount, true)}
          </StyledAmount>
        ) : (
          <StyledAmount>
            {PAYMENT_POPUP_TEXT.AMOUNT_DUE}: {currencyFormat(amount, true)}
          </StyledAmount>
        )}
      </>
    );
  };

  const cancelButtonText = (
    <>
      {isDisableComplete ? <Spin /> : null}
      {deafultCompleteBtnText}
    </>
  );

  const handleOnCompleteClick = (event: any) => {
    if (event.keyCode === 27) {
      setIsPaymentPopupVisible(false);
    } else {
      onCompleteClick();
    }
  };

  return (
    <>
      <ControlledAlert
        width={625}
        visible={isPaymentPopupVisible}
        isCancelBtn={
          paymentStatusAlertType !== SHOW_ALERT_TYPE.STORE_TILL &&
          paymentStatusAlertType !== SHOW_ALERT_TYPE.USER_TILL
            ? true
            : false
        }
        onClick={() => setIsPaymentPopupVisible(false)}
        yesButtonText={BUTTON_LABELS.CLOSE}
        yesButtonStyle={
          paymentStatusAlertType !== SHOW_ALERT_TYPE.STORE_TILL &&
          paymentStatusAlertType !== SHOW_ALERT_TYPE.USER_TILL
            ? { marginRight: yesButtonMarginRight }
            : { marginRight: '20px' }
        }
        cancelButtonText={cancelButtonText}
        onCancel={handleOnCompleteClick}
        cancelButtonDisabled={isDisableComplete}
        alertMessage={
          paymentStatusAlertType === SHOW_ALERT_TYPE.APPROVED
            ? PAYMENT_POPUP_TEXT.TITLE_APPROVED
            : PAYMENT_POPUP_TEXT.TITLE_REJECTED
        }
        message={MsgString()}
        secondaryMessage={getSecondaryMessage()}
        maskClosable={false}
      />
    </>
  );
};
export default PaymentsStatusPopup;
