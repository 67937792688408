import React from 'react';
import { Input, Avatar, Spin, Tooltip, Row, Col } from 'antd';
import styled from 'styled-components'; //
import '../index.less';
import CommonPopover from '../../CommonPopover';
import {
  DeleteFilled,
  FormOutlined,
  LoadingOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { OPTIONS_RADIO_DISABLE } from '../../../../globalConstants';
import { useSelector } from 'react-redux';
import { LOAN_STATUS } from 'components/pickupRenew/constants';
import { currencyFormat } from 'utils/util';

const StyledAvatar = styled(Avatar)`
  margin-left: 10px;
`;

const DeleteRowFormatter = (props: any) => {
  const antIcon = <LoadingOutlined style={{ color: 'var(--gray)' }} spin />;
  const {
    basket: { deleteLoadingSpin },
  } = useSelector((state: any) => {
    return {
      basket: state.basket,
    };
  });

  return (
    <>
      {deleteLoadingSpin === props.row.itemId ||
      deleteLoadingSpin === props.row.serviceId ? (
        <Spin indicator={antIcon} />
      ) : (
        <>
          {!props?.column?.crossicon && (
            <DeleteFilled
              className="grid-icon"
              onClick={() => props?.column?.onClick(props.row)}
            />
          )}
          {props?.column?.crossicon && (
            <CloseOutlined
              className="red-cross"
              onClick={() => props?.column?.onClick(props.row)}
            />
          )}
        </>
      )}
    </>
  );
};

const TooltipFormattor = ({ value }: any) => {
  return (
    <Tooltip placement="top" color={'var(--blue)'} title={value}>
      {value}
    </Tooltip>
  );
};

const InputFormatter = ({ value }: any) => {
  return (
    <Input
      type="text"
      value={value}
      disabled
      className="grid-input"
      addonBefore={'£'}
      placeholder="0.00"
    />
  );
};

const IconComponent = (props: any) => {
  return (
    <>
      <StyledAvatar
        className="mx-smaller-icon find-item-popup-grid"
        src={require(`../../../../assets/images${
          props?.column?.type === 'icon' ? '/icons' : ''
        }/${
          props?.value?.indexOf('.') >= 0
            ? props?.value
            : props?.value?.toLowerCase().replace(/\s/g, '') + '.svg'
        }`)}
      />
      {props?.column?.displayContent && (
        <span style={{ paddingLeft: '15px' }}>{props?.value}</span>
      )}
    </>
  );
};

const loadSVG = (item: any) => {
  try {
    const loadedImg = require(`../../../../assets/images/icons/${
      item.toLowerCase().replace(/\s/g, '') + '.svg'
    }`);

    return loadedImg;
  } catch (e: any) {
    return undefined;
  }
};

const IconFormatter = (props: any) => {
  if (props?.column?.isMultipleIcons) {
    const multipleIcons = props?.value?.map(
      (item: any, index: React.Key | null | undefined) => {
        if (item) {
          const image = loadSVG(item);

          return (
            <Col
              span={5}
              key={index}
              onClick={
                (item === LOAN_STATUS.EARLY_SETTLEMENT ||
                  item === LOAN_STATUS.WITHDRAW_LATER) &&
                props?.column?.isIconClickable
                  ? () => props?.column?.onClick(props.row)
                  : () => undefined
              }
              style={{
                cursor:
                  (item === LOAN_STATUS.EARLY_SETTLEMENT ||
                    item === LOAN_STATUS.WITHDRAW_LATER) &&
                  props?.column?.isIconClickable
                    ? 'pointer'
                    : 'default',
              }}
            >
              <Avatar
                className="mx-smaller-icon find-item-popup-grid"
                src={image}
                key={index}
              />
            </Col>
          );
        } else {
          return null;
        }
      }
    );
    return (
      <Row>
        {multipleIcons}
        {props.column.displayContent && (
          <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ paddingLeft: '7px' }}>{props.value[0]}</span>
          </Col>
        )}
      </Row>
    );
  } else return <IconComponent {...props} />;
};

const RadioFormatter = (props: any) => {
  return (
    <input
      value={props.value}
      name="row-radio"
      type="radio"
      checked={props.row.isRowSelected}
      disabled={OPTIONS_RADIO_DISABLE.indexOf(props.row.location) > -1}
      className="input-control-radio"
      onClick={() => props?.column?.onClick(props.row)}
      onChange={() => props?.column?.onChange(props.row)}
    />
  );
};
const CheckboxFormatter = (props: any) => {
  return (
    <input
      value={props.value}
      name={'row-checkbox'}
      type="checkbox"
      className="input-control-checkbox"
      onChange={() => props.column.onChange(props.row)}
    />
  );
};

const PopoverFormatter = (props: any) => {
  return (
    <CommonPopover
      column={props.column}
      value={props.value.anchor}
      title={props.value.title}
      placement={props.value.placement || 'left'}
      event={props.value.event || 'click'}
      content={props.noApi ? props.row : props.value}
      isDataFetchedFromApi={props.noApi ? false : true}
    ></CommonPopover>
  );
};

const EditRowFormatter = () => <FormOutlined className="grid-icon" />;

const CurrencyFormatter = (props: any) => {
  const value = currencyFormat(props?.value || 0, true);
  return <>{value}</>;
};

const WeightFormatter = (props: any) => {
  const value = props.value.toFixed(2);
  return <div>{`${value}g`}</div>;
};

const RadioVisiblityFormatter = (props: any) => {
  if (props?.row?.transactionType === 'Sell') {
    return null;
  }
  return (
    <input
      value={props.value}
      name="row-radio"
      type="radio"
      checked={props.row.isRowSelected}
      className="input-control-radio"
      onChange={(e) => props?.column?.onChange(props.row, e)}
    />
  );
};

const CurrencyTooltipFormatter = (props: any) => {
  const { currencyCode, amount = '', currencyDesc = '' } = props.value;

  return (
    <>
      <Tooltip placement="bottom" title={currencyDesc}>
        <span style={{ color: `var(--blue)`, cursor: 'pointer' }}>
          {currencyCode}
        </span>
      </Tooltip>
      &nbsp; {amount !== '' && currencyFormat(amount)}
    </>
  );
};

const Formatter = (prop: any) => {
  const { props } = prop;
  const type = props?.column?.type;

  switch (type) {
    case 'input':
      return <InputFormatter {...props} />;
    case 'icon':
      return <IconFormatter {...props} />;
    case 'radio':
      return <RadioFormatter {...props} />;
    case 'popover':
      return <PopoverFormatter {...props} />;
    case 'popover-no-api':
      return <PopoverFormatter {...props} noApi />;
    case 'delete':
      return <DeleteRowFormatter {...props} />;
    case 'checkbox':
      return <CheckboxFormatter {...props} />;
    case 'edit':
      return <EditRowFormatter {...props} />;
    case 'currency':
      return <CurrencyFormatter {...props} />;
    case 'weight':
      return <WeightFormatter {...props} />;
    case 'radiovisibilty':
      return <RadioVisiblityFormatter {...props} />;
    case 'tooltip':
      return <TooltipFormattor {...props} />;
    case 'currency-tooltip':
      return <CurrencyTooltipFormatter {...props} />;
    default:
      return null;
  }
};

export default Formatter;
