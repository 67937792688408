import React, { useState } from 'react';
import VirtualGrid from '../../../common/virtualGrid/index';
import { COLORS_CODE_TABLE, GRID_NO_URL } from 'globalConstants';
import ProgressTick from 'components/common/ProgressCheck';
import TableRow from '../../formatedCell';
import { useSelector } from 'react-redux';
import { getCurrencySymbol } from 'utils/util';

const CheckoutFX = (props: any) => {
  const rows = props?.item || [];
  const { currencies } = useSelector((state: any) => state.tm);

  const [columns] = useState([
    {
      name: '',
      title: '',
      getCellValue: (row: any) => '',
    },
    {
      name: 'serviceTransactionId',
      title: 'Processed',
      getCellValue: (row: any) =>
        ProgressTick('Processed', row?.serviceStatusText),
    },
    {
      name: 'serviceName',
      title: 'Service',
      getCellValue: (row: any) => row?.fx?.request?.transactionType,
    },
    {
      name: 'fromCurrencyCode',
      title: 'Currency From',
      type: 'currency-tooltip',
      getCellValue: (row: any) => ({
        currencyCode: row?.fx?.request?.fromCurrencyCode,
        currencyDesc: row?.fx?.request?.fromCurrencyDescription,
      }),
    },
    {
      name: 'toCurrencyCode',
      title: 'Currency To',
      type: 'currency-tooltip',
      getCellValue: (row: any) => ({
        currencyCode: row?.fx?.request?.toCurrencyCode,
        currencyDesc: row?.fx?.request?.toCurrencyDescription,
      }),
    },
    {
      name: 'currencyExchangeRate',
      title: 'Exchange Rate',
      getCellValue: (row: any) => row?.fx?.request?.currencyExchangeRate,
    },
    {
      name: 'rateGuarantee',
      title: 'Guarantee',
      type: 'currency',
      getCellValue: (row: any) => row?.fx?.request?.rateGuarantee,
    },
    {
      //insert a blank column to create space before To Pay
      name: 'blankColumn',
      title: ' ',
      getCellValue: () => '',
    },
    {
      name: 'fromAmount',
      title: 'To Pay',
      getCellValue: (row: any) => {
        const currency = getCurrencySymbol(
          currencies,
          row?.fx?.request?.toCurrencyCode
        );
        return (
          <span>
            <span
              key={row?.fx?.request?.toCurrencyCode}
              dangerouslySetInnerHTML={{
                __html: currency ? `${currency}` : '',
              }}
            ></span>
            {row?.fx?.request?.toAmount}
          </span>
        );
      },
    },
    {
      name: 'toAmount',
      title: 'To Take',
      getCellValue: (row: any) => {
        const currency = getCurrencySymbol(
          currencies,
          row?.fx?.request?.fromCurrencyCode
        );
        return (
          <span>
            <span
              key={row?.fx?.request?.fromCurrencyCode}
              dangerouslySetInnerHTML={{
                __html: currency ? `${currency}` : '',
              }}
            ></span>
            {row?.fx?.request?.fromAmount}
          </span>
        );
      },
    },
    {
      name: '',
      title: '',
      getCellValue: (row: any) => '',
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: '', width: '48px' },
    { columnName: 'serviceTransactionId', width: '11%' },
    { columnName: 'serviceName', width: '10%' },
    { columnName: 'fromCurrencyCode', width: '10%' },
    { columnName: 'toCurrencyCode', width: '9%' },
    { columnName: 'currencyExchangeRate', width: '10%', align: 'right' },
    { columnName: 'rateGuarantee', width: '10%', align: 'right' },
    { columnName: 'blankColumn', width: '10%', align: 'right' },
    { columnName: 'fromAmount', width: '10%', align: 'right' },
    { columnName: 'toAmount', width: '10%', align: 'right' },
    { columnName: '', width: '6%', align: 'right' },
  ]);

  const [formattedColumns] = useState([
    'toCurrencyCode',
    'fromCurrencyCode',
    'toPay',
    'toTake',
    'rateGuarantee',
  ]);

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        formattedColumns={formattedColumns}
        rowData={rows}
        tableColumnExtensionsProps={tableColumnExtensions}
        headerBackground={COLORS_CODE_TABLE.TRAVELMONEY}
        cellComponent={TableRow}
      />
    </>
  );
};

export default CheckoutFX;
