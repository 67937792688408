import { Button } from 'antd';
import styled from 'styled-components';

const DropdownButton = styled(Button)`
  background: var(--white);
  border: 1px solid var(--light-gray);
  box-shadow: 0px 1px 1px rgb(0 0 0 / 10%);
  border-radius: 3px;
  height: 44px;
  color: var(--black);
  &:focus,
  &:hover,
  &:active {
    background: var(--white);
    color: var(--black);
  }
  width: 100%;
  font-weight: 500;
`;
const DropdownButtonWithHelp = styled(DropdownButton)`
  float: right;
  width: 81%;
`;

export { DropdownButton, DropdownButtonWithHelp };
