import React, { useState } from 'react';
import { GRID_NO_URL } from 'globalConstants';
import VirtualGrid from 'components/common/virtualGrid/index';

const InterestRateTable = ({ interestRateBand = [] }: any) => {
  const [columns] = useState([
    {
      name: 'loanValue',
      title: 'Loan Value (£)',
      getCellValue: (row: any) =>
        row?.loanAmountLowerLimit + row?.loanAmountUpperLimit,
    },
    {
      name: 'interestRate',
      title: 'Interest Rate',
      getCellValue: (row: any) =>
        row?.monthlyInterestPercentCharge.toFixed(2) + '%',
    },
    {
      name: 'apr',
      title: 'APR',
      getCellValue: (row: any) =>
        row?.publishedInterestAnnualPercentageRate?.toFixed(1),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'loanValue', width: '35%' },
    { columnName: 'interestRate', width: '35%', align: 'center' },
    { columnName: 'apr', width: '27%', align: 'right' },
  ]);

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={interestRateBand}
      />
    </>
  );
};

export default InterestRateTable;
