import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { format } from 'date-fns';
import { isEmpty, toString } from 'lodash';
import { Col, Row, Spin, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { actions as consumerDutyAction } from 'redux/reducers/consumerDuty';

import {
  getCustomerNotes,
  postCaseSummaryUpdateNotes,
  validateManagedCases,
} from 'services/consumerDuty';
import { postSaveAsManagedCase } from 'services/consumerDuty';

import FooterButtons from 'components/common/footerButtons';
import Select from 'components/common/Select';
import { Button } from 'components/common/Button';

import TextArea from 'components/common/TextArea';
import ControlledAlert from 'components/common/ControlledAlert';
import CloseCaseModal from 'components/consumerDuty/modals/closeCaseModal';

import {
  FNS_DATE_FORMAT_WITH_TIME,
  GENERIC_ERROR,
  PROFANITY_CHECK,
} from 'globalConstants';
import {
  BUTTONS_LABEL,
  HEADINGS,
  MANAGED_SERVICES_MSG_ON_CLOSING_CASE,
  NOTES_REQUIRED,
  NOTES_SUBJECT,
  TASK_OWNER_VALUE,
  VULNERABILITYORIGINATOR,
  VULNERABILITYOWNER,
} from 'components/consumerDuty/constants';

const StyledLeftCol = styled(Col)`
  padding: 10px 25px;
  border-right: 1px solid var(--light-gray);
`;

const StyledRightCol = styled(Col)`
  padding: 0 26px;
  margin: 26px 0;

  & > .ant-row {
    height: 100%;
    flex-direction: column;
  }
`;

const StyledRow = styled(Row)`
  margin: 18px 0;
`;

const StyledBottomRow = styled(Row)`
  & button {
    height: 56px;
    width: 100%;
    color: var(--white);
    background: var(--dark-red) !important;

    &:disabled {
      color: var(--white-rgba);
    }
  }
`;

const StyledCommentCol = styled(Col)`
  margin: 3px 0;
  padding-bottom: 5px;

  border-bottom: 1px solid var(--light-gray);
`;

interface CaseNotesProps {
  onCaseExit: (isAlertRequired?: boolean) => void;
}

interface ProfanityError {
  profanity: boolean;
  badWords: Array<string>;
}

const CaseNotes = ({ onCaseExit }: CaseNotesProps) => {
  const [profanityError, setProfanityError] = useState<ProfanityError>({
    profanity: false,
    badWords: [''],
  });
  const [isProfanity, setProfanity] = useState<boolean>(false);

  const {
    consumerDuty: {
      selectedTab,
      selectedTaskId,
      caseSummary,
      selectedTaskOwner,
      isManagedCase,
      isViewOnlyMode,
    },
    customer: { customer },
  } = useSelector((state: any) => {
    return {
      consumerDuty: state.consumerDuty,
      customer: state.customer,
    };
  });

  const dispatch = useDispatch();

  const [newNote, setNewNote] = useState<string>('');
  const [caseStory, setCaseStory] = useState<any>('');
  const [caseComments, setCaseComments] = useState<any>([]);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isCloseCaseLoading, setIsCloseCaseLoading] = useState<boolean>(false);
  const [isCaseNotesLoading, setIsCaseNotesLoading] = useState<boolean>(false);

  const [isAlertPopupVisible, setAlertPopupVisible] = useState<boolean>(false);
  const [isCloseCaseModalVisible, setCloseCaseModalVisible] =
    useState<boolean>(false);

  useEffect(() => {
    getCAseStoryAndNotes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onNewNoteChange = (event: any) => {
    const { value } = event.target;
    setNewNote(value);
  };

  const onSaveAsManagedClick = async () => {
    const params = {
      customerSupportId: caseSummary.customerSupportId,
      customerId: customer?.customerId,
      taskId: selectedTaskId,
    };

    try {
      setIsSaving(true);
      const response = await postSaveAsManagedCase(params);
      if (response?.data?.success) {
        notification.success({
          message: response?.data?.message,
          duration: 5,
        });

        onCaseExit(false);
      } else
        notification.error({
          message: response?.data?.message,
          duration: 5,
        });

      setIsSaving(false);
    } catch {
      setIsSaving(false);
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  const onPreviousBtnClick = () => {
    const tab = toString(+selectedTab - 1);
    dispatch(consumerDutyAction.setSelectedTab(tab));
  };

  const handleProfanity = (profanity: boolean, badWords: Array<string>) => {
    setProfanityError({ profanity, badWords });
  };

  const getCAseStoryAndNotes = async () => {
    try {
      setIsCaseNotesLoading(true);

      const response = await getCustomerNotes(
        customer?.customerId,
        NOTES_REQUIRED.CUSTOMER_CASE_NOTES
      );
      if (response?.data) {
        setCaseStory(response?.data?.customerSupport);
        setCaseComments(response?.data?.customerSupportNotes);

        setIsCaseNotesLoading(false);
      }
    } catch (error: any) {
      setIsCaseNotesLoading(false);
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  const onSaveButtonClick = async () => {
    const params = {
      customerSupportId: caseSummary?.customerSupportId,
      body: newNote,
      subject: NOTES_SUBJECT.SST_NEW_COMMENT,
      vulnerabilityOriginator:
        selectedTaskOwner === TASK_OWNER_VALUE.STORE
          ? VULNERABILITYORIGINATOR.STORE
          : VULNERABILITYORIGINATOR.SSTEAM,
      vulnerabilityOwner: VULNERABILITYOWNER.SSTEAM,
    };

    try {
      if (profanityError.profanity) {
        setProfanity(true);
      } else {
        setLoading(true);
        const response = await postCaseSummaryUpdateNotes(params);
        if (response?.data) {
          setNewNote('');
          setLoading(false);
          getCAseStoryAndNotes();
        }
      }
    } catch {
      setLoading(false);
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  const onCloseCaseButtonClick = async () => {
    try {
      setIsCloseCaseLoading(true);
      const response = await validateManagedCases(
        customer?.customerId,
        TASK_OWNER_VALUE.SSTEAM
      );
      if (response?.data?.managed) setCloseCaseModalVisible(true);
      else setAlertPopupVisible(true);

      setIsCloseCaseLoading(false);
    } catch (error: any) {
      setIsCloseCaseLoading(false);
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  const onCloseCasePopupClose = () => {
    setCloseCaseModalVisible(false);
  };

  return (
    <div className="case-note-wrapper">
      <Row className="content">
        <StyledLeftCol span={18}>
          <Row className="title-row">Case Story</Row>
          <Row className="notes-container" style={{ height: '100px' }}>
            {!isEmpty(caseStory) && (
              <Col span={24} style={{ whiteSpace: 'break-spaces' }}>
                {caseStory?.summary}
                <div className="sub-text">
                  {caseStory?.createdBy},&nbsp;
                  {caseStory?.location},&nbsp;
                  {format(
                    new Date(caseStory?.createdOn),
                    FNS_DATE_FORMAT_WITH_TIME
                  )}
                </div>
              </Col>
            )}
          </Row>
          <Row className="heading">{HEADINGS.COMMENTS}</Row>

          <Spin spinning={isCaseNotesLoading}>
            <Row className="notes-container" style={{ height: '260px' }}>
              {caseComments?.map((comment: any, index: number) => (
                <StyledCommentCol key={index} span={24}>
                  <Row className="comment-heading">{comment?.subject}</Row>
                  <div style={{ whiteSpace: 'break-spaces' }}>
                    {comment?.noteText}
                  </div>
                  <div className="sub-text">
                    {comment?.createdUserName || '-'},&nbsp;
                    {comment?.storeName},&nbsp;
                    {format(
                      new Date(comment?.createdOn),
                      FNS_DATE_FORMAT_WITH_TIME
                    )}
                  </div>
                </StyledCommentCol>
              ))}
            </Row>
          </Spin>

          <Row className="heading">{HEADINGS.ADD_COMMENTS}</Row>
          <Row>
            <Col span={24}>
              <TextArea
                disabled={isViewOnlyMode}
                value={newNote}
                onChange={onNewNoteChange}
                maxLength={500}
                autoComplete="on"
                spellCheck
                profanityCheck
                profanityExists={handleProfanity}
              />
            </Col>
          </Row>
          <StyledRow justify="space-between">
            <Button
              disabled={isViewOnlyMode}
              itemProp="secondary"
              onClick={() => setNewNote('')}
            >
              {BUTTONS_LABEL.CLEAR}
            </Button>
            <Button
              itemProp="secondary"
              loading={loading}
              disabled={!newNote}
              onClick={onSaveButtonClick}
            >
              {BUTTONS_LABEL.SAVE}
            </Button>
          </StyledRow>
        </StyledLeftCol>

        <StyledRightCol span={6}>
          <Row justify="space-between">
            <Row>
              <Col span={24}>
                <Row className="heading">{HEADINGS.VIEW_ADD_DOCS}</Row>
                <Row>
                  <table className="document-table">
                    <tbody>
                      <tr>
                        <td className="title">Uploaded On</td>
                      </tr>
                      <tr>
                        <td className="no-item">No Item</td>
                      </tr>
                    </tbody>
                  </table>
                </Row>
                <StyledRow>
                  <div className="add-doc-btn">
                    <PlusOutlined />
                  </div>
                  <Button itemProp="secondary" disabled>
                    {BUTTONS_LABEL.CAPTURE_DOCS}
                  </Button>
                </StyledRow>
                <Row className="heading">{HEADINGS.REVIEW_FREQUENCY}</Row>
                <Row>
                  <Col span={24}>
                    <Select disabled />
                  </Col>
                </Row>
                <StyledRow>
                  <Button itemProp="secondary" disabled>
                    {BUTTONS_LABEL.SAVE_UPDATES}
                  </Button>
                </StyledRow>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <StyledBottomRow>
                  <Col span={24}>
                    <Button
                      disabled={isViewOnlyMode}
                      loading={isCloseCaseLoading}
                      onClick={onCloseCaseButtonClick}
                    >
                      {BUTTONS_LABEL.CLOSE_CASE}
                    </Button>
                  </Col>
                </StyledBottomRow>
              </Col>
            </Row>
          </Row>
        </StyledRightCol>
      </Row>

      {isProfanity && (
        <ControlledAlert
          id="caseNotes"
          width={600}
          visible={isProfanity}
          message={PROFANITY_CHECK.REMOVE_PROFANITY}
          secondaryMessage={
            <>
              {PROFANITY_CHECK.PROFANITY_WORDS}
              <b>&nbsp;{profanityError.badWords.join(', ')}</b>
            </>
          }
          onClick={() => {
            setProfanity(false);
          }}
          yesButtonText={BUTTONS_LABEL.REMOVE_PROFANITY}
          isCancelBtn={false}
        />
      )}

      {isCloseCaseModalVisible && (
        <CloseCaseModal
          visible={isCloseCaseModalVisible}
          onClose={onCloseCasePopupClose}
        />
      )}

      {isAlertPopupVisible && (
        <ControlledAlert
          width={465}
          visible={isAlertPopupVisible}
          isCancelBtn={false}
          message={MANAGED_SERVICES_MSG_ON_CLOSING_CASE}
          onClick={() => setAlertPopupVisible(false)}
          onCancel={() => setAlertPopupVisible(false)}
          yesButtonText={BUTTONS_LABEL.OK}
        />
      )}

      <FooterButtons
        enableExtraClass
        //Exit Button Props
        isExitOnly={true}
        enableExitPopup={false}
        onConfirmExitClick={onCaseExit}
        //Previous Button Props
        //wont be visible in Managed case scenerio
        extraButtonEanable={!isManagedCase}
        extraButtonAlign="right"
        extraButtonItemProp="secondary"
        extraButtonText={BUTTONS_LABEL.PREVIOUS}
        extraButtonOnClick={onPreviousBtnClick}
        //Save as Managed Button Props
        //in Managed Case scenario - Previos button props
        isLoading={isSaving}
        proceedButtonItemProp="secondary"
        proceedButtonText={
          isManagedCase ? BUTTONS_LABEL.PREVIOUS : BUTTONS_LABEL.SAVE_AS_MANAGED
        }
        onProceedClick={
          isManagedCase ? onPreviousBtnClick : onSaveAsManagedClick
        }
      />
    </div>
  );
};

export default CaseNotes;
