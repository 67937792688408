import React from 'react';
import { Col, Modal, Row } from 'antd';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import { CUSTOMER_EDIT } from 'components/customer/constants';

const StyledText = styled(Row)`
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-20);
  line-height: 23.44px;
`;

const StyledLabel = styled.div`
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-16);
  line-height: 21px;
  display: flex;
  justify-content: left;
  margin-top: -15px;
`;

const StyledImage = styled.img`
  padding-right: 20px;
`;

const CustomerEditProfilePopup = ({
  customerName,
  data,
  visible,
  onOkClick,
}: any) => {
  return (
    <Modal
      title={
        <StyledText>{`'${customerName}' customer profile updated `}</StyledText>
      }
      open={visible}
      closable={false}
      centered
      maskClosable={false}
      width={459}
      footer={[
        <Row key={0} gutter={24} justify="center">
          <Col>
            <Button key="confirm" onClick={onOkClick}>
              OK
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <StyledLabel>{CUSTOMER_EDIT.CUSTOMER_PROFILE_UPDATE}</StyledLabel>
      {data?.map((item: any, index: number) => {
        return (
          <div key={index} style={{ paddingTop: '10px' }}>
            <StyledImage
              alt="green tick"
              className={'green-tick'}
              src={require('../../../../assets/images/tick.svg').default}
            />
            {item}
          </div>
        );
      })}
    </Modal>
  );
};

export default CustomerEditProfilePopup;
