// to set User and Store information
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  help: {
    enabled: false,
    helpStatus: 'Help OFF',
  },
};
const slice = createSlice({
  name: 'helpActions',
  initialState,
  reducers: {
    toggle(state, action) {
      state.help.enabled = !state.help.enabled;
      state.help.helpStatus = state.help.enabled ? 'Help ON' : 'Help OFF';
    },
    setHelp(state, action) {
      state.help = {
        ...state.help,
        ...action.payload,
      };
      state.helpStatus = !state.help.enabled ? 'Help ON' : 'Help OFF';
    },
  },
});

export default slice.reducer;
export const { reducer, actions } = slice;
export { initialState };
