import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import format from 'date-fns/format';
import { Col, Modal, Row, Spin } from 'antd';
import styled from 'styled-components';
import { FNS_DATE_FORMAT } from 'globalConstants';
import { currencyFormat } from 'utils/util';
import { getPledgeHistory } from 'services/pledge';
import { PLEDGE_HISTORY } from './constants';

const StyledCol = styled(Col)`
  max-height: 335px !important;
  overflow: auto;
`;

interface PledgeHistoryPopupProps {
  visible: boolean;
  setPledgeHistoryPopupVisible: () => void;
}

const PledgeHistoryPopup = ({
  visible,
  setPledgeHistoryPopupVisible,
}: PledgeHistoryPopupProps) => {
  const [pledgeHistoryData, setPledgeHistoryData] = useState<Array<any>>([]);
  const [loanNumber, setLoanNumber] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    pickupRenew: { pledgeAgreement, selectedAgreementRowId },
  } = useSelector((state: any) => {
    return {
      pickupRenew: state.pickupRenew,
    };
  });

  const tableRow = (
    date: string,
    txn: string,
    amount: number,
    dueToDate: number,
    index: number
  ) => {
    return (
      <tr key={index}>
        <td>{format(new Date(date), FNS_DATE_FORMAT)}</td>
        <td>{txn}</td>
        <td>{amount || amount === 0 ? currencyFormat(amount, true) : '-'}</td>
        <td>
          {dueToDate || dueToDate === 0 ? currencyFormat(dueToDate, true) : '-'}
        </td>
      </tr>
    );
  };

  const getPledgeHistoryData = async () => {
    try {
      setLoading(true);
      const response = await getPledgeHistory(selectedAgreementRowId[0]); // show details for first selected loan
      if (response?.data)
        setPledgeHistoryData([
          ...response?.data?.loanConfiscationVulnerableHistories,
          ...response?.data?.historys,
        ]);

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
    }
  };

  const onCancel = () => {
    setPledgeHistoryPopupVisible();
  };

  useEffect(() => {
    const loan = pledgeAgreement?.find(
      (x: any) => x.id === selectedAgreementRowId[0]
    );
    if (loan) setLoanNumber(loan?.loanNumber);
    getPledgeHistoryData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Modal
        title={`${PLEDGE_HISTORY.TITLE} - ${loanNumber}`}
        open={visible}
        width={715}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancel}
        footer={null}
        centered
      >
        <Row>
          <StyledCol span={24}>
            {!isLoading ? (
              <table className="pledge-history-table">
                <thead>
                  <tr>
                    <th>{PLEDGE_HISTORY.DATE}</th>
                    <th>{PLEDGE_HISTORY.TRANSACTION}</th>
                    <th>{PLEDGE_HISTORY.AMOUNT}</th>
                    <th>{PLEDGE_HISTORY.DUE_TO_DATE}</th>
                  </tr>
                </thead>
                <tbody>
                  {pledgeHistoryData?.length > 0 ? (
                    pledgeHistoryData?.map((x: any, index: number) => {
                      return tableRow(
                        x?.createdOn || x?.displayDate,
                        x?.transactionName,
                        x?.amount,
                        x?.amountDue,
                        index
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4} style={{ textAlign: 'center' }}>
                        No Items
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : (
              <Spin />
            )}
          </StyledCol>
        </Row>
      </Modal>
    </>
  );
};

export default PledgeHistoryPopup;
