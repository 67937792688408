import React, { useState } from 'react';
import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';
import { format } from 'date-fns';
import VirtualGrid from 'components/common/virtualGrid/index';
import ImageLoader from 'components/common/ImageLoader';
import styled from 'styled-components';
import AuctionDetailsExpandableTable from './auctionDetailsExpandableTable';

const StyledSpan = styled.span`
  text-align: center;
  padding: 0 5px 0 5px;
`;

interface ClosedAuctionSummaryProps {
  auctionLoans: Array<any>;
}

const ClosedAuctionSummary = ({
  auctionLoans = [],
}: ClosedAuctionSummaryProps) => {
  const [columns] = useState([
    {
      name: 'customerName',
      title: 'Customer Name',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <>
          <ImageLoader
            customerId={row?.customerId}
            imageUrl={
              row?.customerId &&
              `/api/customers/images/${row?.customerId}?isThumnails=false`
            }
            classes={`mx-small-avatar mx-small-avatar-yellow`}
            borderRadius={'50%'}
            fallbackImg={'customer-pic.svg'}
            preview
          />
          <StyledSpan>{row?.customerName}</StyledSpan>
        </>
      ),
    },
    {
      name: 'lotNumber',
      title: 'Lot No.',
      getCellValue: (row: any) => row?.lotNumber,
    },
    {
      name: 'followsLotNumber',
      title: 'Fellows Lot No.',
      getCellValue: (row: any) => row?.followsLotNumber,
    },
    {
      name: 'loanNumber',
      title: 'Agreement',
      getCellValue: (row: any) => row?.loanNumber,
    },
    {
      name: 'loanValue',
      title: 'Loan Value',
      getCellValue: (row: any) => row?.loanValue,
    },
    {
      name: 'dueDate',
      title: 'Due Date',
      getCellValue: (row: any) =>
        row && row?.dueDate
          ? format(new Date(row?.dueDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'items',
      title: 'Items',
      getCellValue: (row: any) => row?.items,
    },
    {
      name: 'letterDue',
      title: 'Letter Due',
      getCellValue: (row: any) =>
        row && row?.letterDue
          ? format(new Date(row?.letterDue), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'reserveValue',
      title: 'Reserve Value',
      getCellValue: (row: any) => row?.reserveValue,
    },
    {
      name: 'soldValue',
      title: 'Sold Value',
      getCellValue: (row: any) => row?.soldValue,
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row: any) => row?.status,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'customerName', width: '16%' },
    { columnName: 'lotNumber', width: '8%' },
    { columnName: 'followsLotNumber', width: '10%' },
    { columnName: 'loanNumber', width: '8%' },
    { columnName: 'loanValue', width: '8%' },
    { columnName: 'dueDate', width: '8%' },
    { columnName: 'items', width: '6%' },
    { columnName: 'letterDue', width: '8%' },
    { columnName: 'reserveValue', width: '8%' },
    { columnName: 'soldValue', width: '8%' },
    { columnName: 'status', width: '8%' },
  ]);

  const getRowId = (row: any) => row?.auctionId;

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={auctionLoans}
        expandableComponent={AuctionDetailsExpandableTable}
        getRowId={getRowId}
      />
    </>
  );
};

export default ClosedAuctionSummary;
