import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PFI_LABELS } from 'components/pledgemanagement/constants';
import PfiUnder from './pfiSummary/pfiUnder';
import PfiReview from './pfiReview/pfiReview';

const PfiManager = () => {
  const { isActivePfiReview } = useSelector(
    (state: any) => state.pledgeManagement
  );

  const [activeView, setActiveView] = useState<string>(PFI_LABELS.PFI_UNDER);

  useEffect(() => {
    if (isActivePfiReview) setViewtoPfiReview();
    else setViewtoPfiUnder();
  }, [isActivePfiReview]);

  const setViewtoPfiReview = () => {
    setActiveView(PFI_LABELS.PFI_REVIEW);
  };

  const setViewtoPfiUnder = () => {
    setActiveView(PFI_LABELS.PFI_UNDER);
  };

  return (
    <>
      {activeView === PFI_LABELS.PFI_UNDER && <PfiUnder />}
      {activeView === PFI_LABELS.PFI_REVIEW && (
        <PfiReview
          PfiReviewHandler={setViewtoPfiReview}
          onExit={setViewtoPfiUnder}
        />
      )}
    </>
  );
};

export default PfiManager;
