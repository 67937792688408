import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Spin } from 'antd';
import styled from 'styled-components';
import { getTelephoneList, getTelephoneStores } from 'services/accessories';
import TelephoneListTable from './TelephoneListTable';
import { TELEPHONE_LIST_TABS } from 'components/layout/constants';
import { cacheStaticData } from 'utils/cacheServices';

import Search from 'antd/lib/input/Search';
import Select from 'components/common/Select';

import '../index.less';

const StyledMainContainer = styled(Col)`
  margin: 0px;
`;

const StyledCol = styled(Col)`
  margin-top: 6px;
`;

const StyledOR = styled.div`
  background: var(--solitude);
  border-radius: 100%;
  height: 35px;
  width: 35px;
  position: relative;
  text-align: center;
  & span {
    top: 7px;
    position: relative;
  }
  margin: 30px;
`;

const StyledGridCol = styled(Col)`
  height: 52vh;
`;

const TelephoneList = ({ onCancelClick }: any) => {
  const [form] = Form.useForm();

  const [stores, setStores] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [telephoneList, setTelephoneList] = useState<Array<any>>([]);

  useEffect(() => {
    loadAllStores();
  }, [!stores.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadAllStores = async () => {
    try {
      const response = await cacheStaticData(getTelephoneStores);
      if (response) setStores([response?.data]);
    } catch (e: any) {
      console.log(e);
    }
  };

  useEffect(() => {
    loadTelephoneList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadTelephoneList = async () => {
    try {
      setIsLoading(true);
      const { storeId, colleagueName } = form.getFieldsValue();
      const param = {
        storeId: storeId && !colleagueName ? storeId : -1,
        colleagueName: colleagueName ? colleagueName : '',
      };

      const response = await getTelephoneList(param);
      if (response?.data) setTelephoneList(response?.data);
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <StyledMainContainer>
        <Modal
          title={'Telephone List'}
          open
          closable={true}
          width={1534}
          centered
          maskClosable={false}
          onCancel={onCancelClick}
          destroyOnClose={true}
          footer={null}
          className="telephone-wrapper"
        >
          <Form id="telephoneListForm" layout="vertical" form={form}>
            <Row>
              <Col span={7}>
                <Form.Item
                  label={TELEPHONE_LIST_TABS.SEARCH_BY_STORE}
                  name="storeId"
                  className="label-control"
                >
                  <Select
                    placeholder="All"
                    onSelect={loadTelephoneList}
                    onChange={loadTelephoneList}
                    allowClear
                    options={stores[0]?.map((item: any) => {
                      return {
                        value: item?.storeId,
                        label: item?.storeName,
                      };
                    })}
                  />
                </Form.Item>
              </Col>
              <StyledOR>
                <span>OR</span>
              </StyledOR>
              <StyledCol span={7} margin-top="5px">
                <Form.Item
                  label={TELEPHONE_LIST_TABS.SEARCH_BY_COLLEAGUE}
                  name="colleagueName"
                  className="telephone-list"
                >
                  <Search
                    placeholder={'Search Colleague Name'}
                    enterButton
                    onChange={loadTelephoneList}
                    size="large"
                  />
                </Form.Item>
              </StyledCol>
            </Row>
          </Form>
          <Spin spinning={isLoading}>
            <Row>
              <StyledGridCol span={24}>
                <TelephoneListTable telephoneList={telephoneList} />
              </StyledGridCol>
            </Row>
          </Spin>
        </Modal>
      </StyledMainContainer>
    </>
  );
};

export default TelephoneList;
