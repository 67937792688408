// to set User and Store information
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {
    userId: null,
    employeeId: null,
    email: null,
    authorizeUsername: null,
    displayName: null,
    isEnabled: false,
    policies: [],
    roles: [],
    groups: [],
    stores: [],
  },
  selectedStore: {},
  isRecallMenuVisible: false,
  menus: [],

  // workQueue
  isLoading: false,
  reloadBankTransfers: false,
  userDashboardTabs: [],
  notifications: [],
  notificationGridPage: 0,
  totalNotificationCount: 0,
  managedCases: [], // Manage Cases Tab
  lockedLoans: [], //Loan Exceptions tab
  selectedNotificationsServices: [], // Notifications filter services
  bankTransfers: [], //Bank Transfer tab
};

const slice = createSlice({
  name: 'userActions',
  initialState,
  reducers: {
    setUserAndStore(state, action) {
      const { stores, selectedStore } = action.payload;

      state.user = {
        ...state.user,
        ...action.payload,
        stores,
      };
      state.selectedStore = selectedStore;
    },
    setMenuConfigration(state, action) {
      state.menus = action.payload;
    },
    setRecallMenuVisibility(state, action) {
      state.isRecallMenuVisible = action.payload;
    },

    //workQueue actions
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setNotificationsWorkQueue(state, action) {
      state.notifications = action.payload;
    },
    setManagedCases(state, action) {
      state.managedCases = action.payload;
    },
    setLockedLoans(state, action) {
      state.lockedLoans = action.payload;
    },
    setSelectedNotificationsServices(state, action) {
      state.selectedNotificationsServices = action.payload;
    },
    setBankTransfers(state, action) {
      state.bankTransfers = action.payload;
    },
    setUserDashboardTabs(state, action) {
      state.userDashboardTabs = action.payload;
    },
    initReloadBankTransfers(state, action) {
      state.reloadBankTransfers = action.payload;
    },
    setNotificationGridPage(state, action) {
      state.notificationGridPage = action.payload;
    },
    setTotalNotificationCount(state, action) {
      state.totalNotificationCount = action.payload;
    },
  },
});

export default slice.reducer;
export const { reducer, actions } = slice;
export { initialState };
