import ImageLoader from 'components/common/ImageLoader';
import VirtualGrid from 'components/common/virtualGrid';
import { GRID_NO_URL } from 'globalConstants';
import React, { useState } from 'react';
import styled from 'styled-components';
import { currencyFormat } from 'utils/util';

const StyledTitle = styled.div`
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-16);
`;

const ItemDetailsTable = ({ rows }: any) => {
  const itemDescription = (row: any) => {
    return {
      title: row.description,
      anchor: row.description,
      placement: 'bottom',
      data: {},
    };
  };

  const [columns] = useState([
    {
      name: 'itemImage',
      title: 'Item Image',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row.id}`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
        />
      ),
    },
    {
      name: 'description',
      title: 'Description',
      type: 'popover-no-api',
      getCellValue: (row: any) => itemDescription(row),
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) => `${row?.weight?.toFixed(2)}g`,
    },
    {
      name: 'amount',
      title: 'Amount',
      getCellValue: (row: any) => `${currencyFormat(row?.amount, true)}`,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'itemImage', width: 130 },
    { columnName: 'description', width: 270 },
    { columnName: 'weight', width: 120, align: 'right' },
    { columnName: 'amount', width: 120, align: 'right' },
  ]);

  const [formattedColumns] = useState(['description']);

  return (
    <>
      <StyledTitle>Item Details</StyledTitle>

      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        formattedColumns={formattedColumns}
        rowData={rows}
      />
    </>
  );
};

export default ItemDetailsTable;
