import * as urls from '../apiUrls';
import { doRequest } from 'utils/request';
import { notification } from 'antd';
import { RESPONSE_STATUS } from 'globalConstants';

/**
 *
 * @param {*} result is arraybuffer data
 *  @param {*} type which type of printer need select for print like normal/thermal printer
 *  @param {*} filename is use for display
 * @returns
 */

const sendToPrinter = async (
  result,
  type = 'printcontract',
  filename,
  options = {}
) => {
  let base64 = btoa(
    new Uint8Array(result?.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ''
    )
  );
  const obj = {
    ImageFileName: filename,
    ImageFile: base64,
  };

  const params = { ...obj, ...options };

  try {
    await doRequest(`${urls.PRINTER_API}/${type}`, 'post', params);

    return RESPONSE_STATUS.SUCCESS;
  } catch (e) {
    notification.error({
      message: e.message,
      description: 'Printer service is not running, please check.',
      duration: 10,
    });

    return RESPONSE_STATUS.SERVICE_UNAVAILABLE;
  }
};

const imageFromScanner = async () => {
  try {
    const img = await doRequest(`${urls.PRINTER_API}/scan/png`, 'get');
    return img.data?.ImageFile;
  } catch (e) {
    notification.error({
      message: e.message,
      description: 'Printer service is not running, please check.',
      duration: 10,
    });
  }
};

export { sendToPrinter, imageFromScanner };
