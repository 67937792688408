import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Col, Row, Modal } from 'antd';

import ImageLoader from 'components/common/ImageLoader';

const StyledModal = styled(Modal)`
  &.customer-id-popover {
    & .ant-modal-content {
      & .ant-modal-body {
        text-align: center;
        padding: 85px 0;

        & .ant-image-img {
          height: 200px !important;
          width: 300px !important;
        }
      }
    }
  }
  .ant-modal-footer {
    display: none;
  }
`;

const CustomerIdPopover = ({
  visible,
  onCustomerDocPopoverClose,
  customerDocs = [],
}: any) => {
  const { customer } = useSelector((state: any) => state.customer);

  return (
    <StyledModal
      centered
      open={visible}
      closable={true}
      onCancel={onCustomerDocPopoverClose}
      width={550}
      className="customer-id-popover"
    >
      <Row>
        <Col span={24}>
          <ImageLoader
            imageUrl={
              customer?.customerId
                ? `/api/customers/images/${customerDocs[0]?.customerDocumentId}/doc?isThumnails=false`
                : `/api/fxbuyandsell/image/${customerDocs[0]?.customerDocumentId}?isThumnails=false`
            }
            classes={'mx-portrait-avatar'}
            fallbackImg={'no_image_thumbnail.svg'}
            preview
          />
        </Col>
      </Row>
    </StyledModal>
  );
};
export default CustomerIdPopover;
