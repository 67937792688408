import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Input } from 'antd';
import { format } from 'date-fns';
import {
  ADD_PROFANITY_WORDS,
  BYPASS_PROFANITY_WORDS,
  FNS_DATE_FORMAT,
} from 'globalConstants';

import Filter from 'bad-words';

const StyledContainer = styled.div`
  & .history-container {
    overflow: auto;

    border: 1px solid #d9d9d9;
    border-bottom: none;
    border-radius: 5px 5px 0 0;

    & .history-row {
      padding: 10px 10px 20px;
      border-bottom: 1px solid #d9d9d9;

      &:last-child {
        border-bottom: none;
      }

      & .sub-text {
        color: var(--gray);
      }
    }
  }

  & .note-container {
    & textarea {
      border-radius: 0 0 5px 5px;
    }
  }

  & .common-textarea {
    box-shadow: none;
    height: auto;

    textarea {
      height: 100%;
    }
  }
`;

const TextArea = ({
  value = '',
  rows = 4,
  maxLength = 600,
  className = '',
  historyClassName = '',
  containerClassName = '',
  placeholder = '',
  onChange = () => {
    /* do nothing */
  },
  onBlur = () => {
    /* do nothing */
  },
  history = [],
  disabled = false,
  showCount = false,
  dataTestId,
  profanityCheck = false,
  autoComplete = 'off',
  spellCheck = false,
  profanityExists = (profanity: boolean, badWords: Array<string>) => {
    /* do nothing */
  },
}: any) => {
  const { TextArea: AntTextArea } = Input;

  useEffect(() => {
    if (value !== undefined) {
      setFieldValue(value);
    }
  }, [value]);

  const [fieldValue, setFieldValue] = useState<any>();

  const onChangeHandler = (event: any) => {
    const val = event.target.value;
    setFieldValue(val);

    // Check profanity
    profanityCheck && val && handleProfanity(val);

    //firing onChange if any
    onChange && onChange(event);
  };

  const onBlurHandler = (event: any) => {
    const val = event.target.value;
    setFieldValue(val);

    //firing onBlur if any
    onBlur && onBlur(event);
  };

  const onlyUnique = (word: string, index: number, badWords: Array<string>) => {
    return badWords.indexOf(word) === index;
  };

  const handleProfanity = (val: string) => {
    const filter = new Filter();
    // Added explicit list of bad words to profanity filter which are not detected by the filter automatically. Words listed in JIRA ID as provided by business.
    filter.addWords(...ADD_PROFANITY_WORDS);
    // List of words that should be bypassed by the filter , which are currently detected as a bad word but are not.
    filter.removeWords(...BYPASS_PROFANITY_WORDS);
    const profanity = filter.isProfane(val);
    const originalVal = val.split(' ');
    const updatedVal = filter.clean(val).split(' ');
    const badWords = originalVal.filter((word) => !updatedVal.includes(word));
    const uniqueBadWords = badWords.filter(onlyUnique);
    profanityExists(profanity, uniqueBadWords);
  };

  const renderNotes = () => (
    <AntTextArea
      showCount={showCount}
      rows={rows}
      maxLength={maxLength}
      value={fieldValue}
      placeholder={placeholder}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
      autoComplete={autoComplete}
      spellCheck={spellCheck}
      className={`input-control common-textarea ${className}`}
      disabled={disabled}
      data-testid={dataTestId}
    />
  );

  const renderNotesWithHistory = () => (
    <>
      <Row className={`history-container ${historyClassName}`}>
        <Col span={24}>
          {history?.map((details: any, index: number) => (
            <Row key={index} className="history-row">
              <Col span={24}>
                {details?.note}
                <div className="sub-text">
                  {details?.userName}&nbsp;
                  {format(new Date(details?.createdOn), FNS_DATE_FORMAT)}
                </div>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      <Row className="note-container">
        <Col span={24}>{renderNotes()}</Col>
      </Row>
    </>
  );

  return (
    <StyledContainer className={containerClassName}>
      {history.length > 0 ? renderNotesWithHistory() : renderNotes()}
    </StyledContainer>
  );
};

export default TextArea;
