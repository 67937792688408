import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import styled from 'styled-components';
import {
  Row,
  Col,
  Form,
  Input,
  AutoComplete,
  Spin,
  Button as LinkButton,
  notification,
} from 'antd';
import { debounce, isEmpty } from 'lodash';
import moment from 'moment';

import { actions as retailActions } from 'redux/reducers/retail';
import { joinValues, validateDateOfBirth } from 'utils/util';
import { getAddress, getDocTypes } from 'services/customer';
import { imageFromScanner } from 'services/user';
import { actions as customerActions } from 'redux/reducers/customer';
import { postRetailIntoBasket } from 'services/basket';

import Select from './Select';
import DatePickerMUI from './DatePickerMUI';
import { Button } from './Button';
import ImageLoader from './ImageLoader';
import CustomerIdPopover from 'components/customer/customerSummary/customerIdPopover';
import ScannerPopover from 'components/customer/newCustomer/scannerPopover';
import DocumentCapture from 'components/customer/newCustomer/documentCapture';
import { cacheStaticData } from 'utils/cacheServices';
import {
  DATE_FORMAT,
  FNS_DATE_FORMAT,
  GUEST_TYPES,
  REGEX_POSTCODE,
  TM_AMOUNT_ALERT,
  NOTIFICATION_TYPE,
  BASKET_SERVICE_TYPE,
} from 'globalConstants';
import {
  DEFAULT_SCAN_MESSAGE,
  DOCUMENT_TYPE_OTHER,
  DOCUMENT_TYPE_USE_CAMERA,
  SORRY_MESSAGE,
  TITLES,
  DOB_ALLOWEd_AGE,
} from 'components/customer/constants';

const StyledAlertRow = styled(Row)`
  height: 45px;
  margin: 15px 0;
  padding: 15px;
  border-radius: 4px;

  color: var(--red);
  background: var(--warning-bg);
`;

const StyledFieldErrorRow = styled(Row)`
  & .alert-text {
    color: var(--brick-red);
  }
`;

const StyledDocTable = styled.table`
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid var(--light-gray);

  & tr {
    border-bottom: 1px solid var(--light-gray);

    & th {
      padding: 5px 12px !important;
    }

    & td {
      padding: 5px 12px !important;

      &:first-child {
        width: 50%;
      }
    }
  }

  & .image-link {
    text-align: right;
  }

  & button {
    padding: 0;
  }
`;

interface AddressOptions {
  value: number;
  label: string;
}

export interface CustomerVAT {
  firstNames: string;
  surname: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  postcode: string;
}

interface Address {
  property: string;
  street: string;
  area: string;
  town: string;
  county: string;
  postcode: string;
  house: string;
  country: string;
  id: number;
}

const customerVATDefaultValues: CustomerVAT = {
  firstNames: '',
  surname: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  postcode: '',
};

enum CustomerIdKeys {
  id = 'documentTypeId',
  captureDate = 'idCaptureDate',
  image = 'image',
}

const defaultDocObj = {
  documentTypeId: '',
  idCaptureDate: '',
  image: '',
};

const { VALUATION, FX, VAT } = GUEST_TYPES;

//Add guest type to desired array to make it visible for the registration form
const GUEST_REQUIRED_FIELD_GROUPS = {
  TITLE: [VALUATION], //title
  NAMES: [VALUATION, VAT], //firstNames, Surname
  NAMES_SPAN_12: [FX],
  DATE_OF_BIRTH: [FX],
  POSTCODE: [VALUATION],
  POSTCODE_SPAN_12: [FX],
  POSTCODE_SPAN_24: [VAT],
  POSTCODE_INPUT_ONLY: [FX], //Postcode without autocomplete/address search
  DETAILED_ADDRESS: [VALUATION], //house number, house name, street, town, area, county
  DETAILED_ADDRESS_LINE: [VAT], //Address line1 , Address line2 , Address line3
  CONTACT: [VALUATION], //phone, email
};

const GuestCustomer = ({ guestType, onOKClick }: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const {
    retail: {
      tempVATPayload,
      isGuestCustomerAbstained,
      isSaveAndContinueClicked,
    },
    basket: { basket },
    customer: { customer, isLiteDocRequired: isDocRequired },
    user: { user, selectedStore },
  } = useSelector((state: any) => {
    return {
      retail: state.retail,
      customer: state.customer,
      basket: state.basket,
      user: state.user,
    };
  });

  const [inputValue, setInputValue] = useState('');
  const [addressOptions, setAddressOptions] = useState<any>([]);
  const [addressList, setAddressesList] = useState<Address[]>([]);
  const [noResult, setNoResult] = useState<boolean>(false);
  const [searchInProgress, setSearchInProgress] = useState<boolean>(false);
  const [idTypes, setIDType] = useState<any>([]);
  const [docList, setDocList] = useState<any>([]);
  const [docAlert, setDocAlert] = useState<boolean>(false);
  const [previousDocuments, setPreviousDocuments] = useState<Array<any>>([]);
  const [selectedDocument, setSelectedDocument] = useState<any>([]);
  const [isAllPreviousDocsVisible, setIsAllPreviousDocsVisible] =
    useState<boolean>(false);
  const [isCustomerDocPopupVisible, setIsCustomerDocPopupVisible] =
    useState<boolean>(false);
  const [customerVATInfo, setCustomerVATInfo] = useState<any>({});
  const [isScanning, setIsScanning] = useState<boolean>(false);
  const [isAddAnotherIdEnable, setIsAddAnotherIdEnable] =
    useState<boolean>(false);
  const [initalDate, setInitialDate] = useState<any>(null);

  const [isDocCaptureVisible, setIsDocCaptureVisible] =
    useState<boolean>(false);
  const [captureDocIndex, setCaptureDocIndex] = useState<number>(0);
  const [captureImage, setCaptureImage] = useState<any>(undefined);

  useEffect(() => {
    if (isDocRequired) loadDocTypes();

    if (customer?.customerId || customer?.firstNames) {
      form.setFieldsValue({
        firstNames: customer?.firstNames,
        surname: customer?.surname,
        postcode: customer?.postcode,
      });

      if (guestType === FX) {
        const dateOfBirth = customer?.dateOfBirth
          ? moment(customer?.dateOfBirth)
          : '';

        setInitialDate(dateOfBirth);
        form.setFieldsValue({
          dateOfBirth: dateOfBirth,
        });
      }
    }
    if (isEmpty(tempVATPayload)) {
      const retailItemPayload = {
        basketId: basket?.basketId,
        serviceId: null,
        customerId: customer?.customerId || 0,
        storeId: selectedStore?.storeId,
        userId: user?.userId,
        items: getItemPayload(),
        serviceType: BASKET_SERVICE_TYPE.RETAIL,
        paymentType: 0,
        customer: { ...customer },
        customerName:
          joinValues([customer.firstNames, customer.surname], true) ||
          undefined,
        employeeId: user?.employeeId,
        storeAddress: selectedStore?.storeAddress,
        storePostalCode: selectedStore?.postalCode,
        storeTelephone: selectedStore?.telephone,
        userName: user?.authorizeUsername,
      };

      dispatch(retailActions.setTempPayloadWithVAT(retailItemPayload));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isGuestCustomerAbstained && isSaveAndContinueClicked) {
      onVATFormSubmit([]);
      dispatch(retailActions.onSaveAndContinueMode(false));
    }
  }, [isGuestCustomerAbstained, isSaveAndContinueClicked]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (docList[docList?.length - 1]?.idCaptureDate !== '') {
      setIsAddAnotherIdEnable(true);
    } else {
      setIsAddAnotherIdEnable(false);
    }
  }, [docList]);

  const loadDocTypes = async () => {
    try {
      const response = await cacheStaticData(getDocTypes);
      if (response?.data) {
        const docTypeList = response?.data;

        docTypeList.push({
          id: DOCUMENT_TYPE_USE_CAMERA,
          description: 'Camera',
        });
        setIDType(docTypeList);

        if (customer?.customerDocuments?.length > 0) {
          const documentList = customer?.customerDocuments?.reduce(
            (list: any, doc: any) => {
              const selectedId = docTypeList.find(
                (el: any) => el.id === doc?.documentTypeId
              );

              const idName = selectedId.description.split(' ').slice(1).join(' ');

              return [
                ...list,
                {
                  idType: idName,
                  captureDate: doc?.dateOfCapture
                    ? format(new Date(doc?.dateOfCapture), FNS_DATE_FORMAT)
                    : '',
                  customerDocumentId: customer?.customerId
                    ? doc?.customerDocumentId
                    : doc?.fxCustomerDocumentId,
                },
              ];
            },
            []
          );

          setPreviousDocuments(documentList);
        }
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const onSearch = (value: string) => {
    setAddressOptions([]);
    if (value) {
      getAddresses(value, (data: AddressOptions) => {
        setAddressOptions(data);
      });
      setInputValue(value);
    } else {
      setAddressOptions([]);
      setAddressesList([]);
    }
  };

  const getAddresses = async (searchValue: string, callback: any) => {
    if (!REGEX_POSTCODE.test(searchValue)) {
      return;
    }
    try {
      setSearchInProgress(true);
      const response = await getAddress(searchValue);
      if (response) {
        const result: AddressOptions[] = [];
        response.data.addresses.forEach((address: Address) => {
          const text = joinValues([
            address.property,
            address?.house,
            address.street,
            address.town,
            address.county,
            address.postcode,
          ]);

          result.push({
            value: address.id,
            label: text,
          });
        });
        callback(result);
        setAddressesList(response.data.addresses);
        setSearchInProgress(false);
        if (response.data.addresses.length === 0) {
          setNoResult(true);
        }
      }
    } catch (e: any) {
      if (e?.response?.status === 404) {
        setNoResult(true);
        setAddressesList([]);
      }
      setSearchInProgress(false);
    }
  };

  const onSelect = (value: string) => {
    const { street, town, property, county, postcode, house, area } =
      addressList.filter((item: any) => item.id === value)[0];

    if (guestType === GUEST_TYPES.VAT) {
      setCustomerVATInfo({
        firstNames: '',
        surname: '',
        street,
        town,
        property,
        county,
        postcode,
        houseName: house,
      });
      form.setFieldsValue({
        addressLine1: joinValues([property, house]),
        addressLine2: joinValues([street, town]),
        addressLine3: joinValues([county, postcode]),
        postcode: postcode?.trim(),
      });
    } else {
      form.setFieldsValue({
        area,
        property,
        house,
        street,
        town,
        county,
        postcode: postcode?.trim(),
      });
    }
  };

  const noResultRow = () => {
    return (
      <>
        <div className="no-result-container">
          <div className="no-results">{`${SORRY_MESSAGE}`}</div>
        </div>
      </>
    );
  };

  const renderNameFields = (span: number) => (
    <>
      <Col span={span}>
        <Form.Item
          label={guestType === GUEST_TYPES.VAT ? 'Forename' : 'First Name*'}
          name="firstNames"
          className="label-control"
          rules={[
            {
              required: true,
              message: 'First Name is required',
            },
            {
              type: 'string',
              max: 40,
              min: 0,
              pattern: new RegExp("^[a-zA-Z]+[a-zA-Z-' ]*$"),
              message: 'Numeric or special characters not allowed',
            },
          ]}
        >
          <Input className="input-control" />
        </Form.Item>
      </Col>
      <Col span={span}>
        <Form.Item
          label="Surname*"
          name="surname"
          className="label-control"
          rules={[
            {
              required: true,
              message: 'Surname is required',
            },
            {
              type: 'string',
              max: 40,
              min: 0,
              pattern: new RegExp("^[a-zA-Z]+[a-zA-Z-' ]*$"),
              message: 'Numeric or special characters not allowed',
            },
          ]}
        >
          <Input className="input-control" />
        </Form.Item>
      </Col>
    </>
  );

  const renderPostCodeField = (span: number) => (
    <Col span={span}>
      <Form.Item
        label="Postcode*"
        name="postcode"
        className="label-control autocomplete-input-selection"
        validateTrigger="onBlur"
        rules={[
          {
            required: !isGuestCustomerAbstained,
            message: 'Postcode is required',
          },
          {
            pattern: REGEX_POSTCODE,
            message: 'Postcode is not correct',
          },
        ]}
      >
        {GUEST_REQUIRED_FIELD_GROUPS.POSTCODE_INPUT_ONLY.some(
          (field: any) => field === guestType
        ) ? (
          <Input className="input-control" />
        ) : (
          <AutoComplete
            showSearch={false}
            className="input-control"
            value={inputValue}
            notFoundContent={
              searchInProgress ? (
                <Spin />
              ) : inputValue !== '' && noResult ? (
                noResultRow()
              ) : null
            }
            onBlur={() => setInputValue('')}
            onSearch={debounce(onSearch, 400)}
            onSelect={onSelect}
            options={addressOptions}
            //MP-6571 - removed for address lookup search fix
            // filterOption={(inputValue: any, option: any) =>
            //   option?.label.toUpperCase().indexOf(inputValue.toUpperCase()) !==
            //   -1
            // }
          />
        )}
      </Form.Item>
    </Col>
  );

  const onFinishFailed = () => {
    if (isDocRequired && !docList[0]?.[CustomerIdKeys.image]) setDocAlert(true);
  };

  const onSaveAndContinue = () => {
    if (isDocRequired && !docList[0]?.[CustomerIdKeys.image])
      return setDocAlert(true);

    const guestDetails = form.getFieldsValue();
    if (guestDetails?.dateOfBirth)
      guestDetails.dateOfBirth = moment(guestDetails.dateOfBirth).format(
        'YYYY-MM-DD'
      );

    if (guestType === GUEST_TYPES.FX) {
      const params = {
        customer: guestDetails,
        docs: docList,
      };
      dispatch(customerActions.setLiteCustomerDetails(params));
      if (!customer?.customerId)
        dispatch(customerActions.setCustomerDetails(guestDetails));
    } else dispatch(customerActions.setCustomerDetails(form.getFieldsValue()));

    onOKClick();
  };

  const getItemPayload = () => {
    if (!isEmpty(basket)) {
      const retailService = basket?.basketServices?.filter(
        (x: any) => x.serviceType === BASKET_SERVICE_TYPE.RETAIL
      );
      if (retailService?.length > 0) {
        return retailService[0]?.retail?.request?.items || [];
      }
    }
    return [];
  };

  const onVATFormSubmit = async (data: any) => {
    const customerVATData: CustomerVAT = {
      ...customerVATDefaultValues,
      ...data,
    };

    const params = {
      ...tempVATPayload,
      retailCustomer: {
        ...customerVATData,
      },
      customerId: customer?.customerId || 0,
      customer: {
        ...customer,
        ...customerVATInfo,
        firstNames: data.firstNames,
        surname: data.surname,
      },
      basketId: basket?.basketId,
      isCustomerAbstained: isGuestCustomerAbstained,
    };

    dispatch(retailActions.setTempPayloadWithVAT(params));
    dispatch(customerActions.setIsLiteCustomerLoading(true));

    try {
      const response = await postRetailIntoBasket(params);
      if (response?.data) {
        dispatch(customerActions.setCustomerDetails(form.getFieldsValue()));
        dispatch(retailActions.setIsGuestCustomerAbstained(false));
        dispatch(customerActions.setIsLiteCustomerLoading(false));

        onOKClick();
      }
    } catch (e: any) {
      dispatch(customerActions.setIsLiteCustomerLoading(false));

      notification.error({
        message: NOTIFICATION_TYPE.GENERIC_API_ERROR,
        duration: 5,
      });
    }
  };

  const onDocTypeChange = (docType: number, index: number) => {
    let id = docType;

    if (docType === DOCUMENT_TYPE_USE_CAMERA) {
      id = DOCUMENT_TYPE_OTHER;
      setCaptureDocIndex(index);
      setIsDocCaptureVisible(true);
    }

    const docsCopy = JSON.parse(JSON.stringify(docList));
    docsCopy[index][CustomerIdKeys.id] = id;

    setDocAlert(false);
    setDocList(docsCopy);
  };

  const onDocumentCaptureClick = async (index: number) => {
    setIsScanning(true);
    const img = await imageFromScanner();
    if (img === undefined) return;

    const data = `data:image/png;base64, ${img}`;

    const docsCopy = JSON.parse(JSON.stringify(docList));
    docsCopy[index][CustomerIdKeys.image] = data;
    docsCopy[index][CustomerIdKeys.captureDate] = format(
      new Date(),
      FNS_DATE_FORMAT
    );

    setIsScanning(false);
    setDocList(docsCopy);
    setDocAlert(false);
  };

  const onViewIdClick = (docDetails: any) => {
    setSelectedDocument(docDetails);
    setIsCustomerDocPopupVisible(true);
  };

  const renderPreviousDocuments = () => {
    const documents = isAllPreviousDocsVisible
      ? previousDocuments
      : previousDocuments?.slice(0, 1);

    return (
      <Row>
        <Col span={24}>
          <StyledDocTable>
            <tr>
              <th>ID Type</th>
              <th>Date of Capture</th>
              <th></th>
            </tr>

            <tbody>
              {documents?.map((doc: any, index: number) => (
                <tr key={index}>
                  <td>{doc?.idType}</td>
                  <td>{doc?.captureDate}</td>
                  <td className="image-link">
                    <LinkButton
                      type="link"
                      onClick={() => onViewIdClick([doc])}
                    >
                      View ID Image
                    </LinkButton>
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <LinkButton
                    type="link"
                    onClick={() =>
                      setIsAllPreviousDocsVisible((prevState) => !prevState)
                    }
                  >
                    {isAllPreviousDocsVisible ? 'Hide IDs' : 'View All IDs'}
                  </LinkButton>
                </td>
              </tr>
            </tbody>
          </StyledDocTable>
        </Col>
      </Row>
    );
  };

  const onAddAnotherIdClick = () => {
    setDocList((prevState: any) => [...prevState, defaultDocObj]);
  };

  const onDateChange = (selectedDate: any) => {
    form.setFieldsValue({ dateOfBirth: selectedDate });
  };

  const onDocumentCapture = (image: any) => {
    setCaptureImage(image);
  };

  const onAddDocumentImage = () => {
    if (!captureImage) return;

    const docsCopy = JSON.parse(JSON.stringify(docList));
    docsCopy[captureDocIndex][CustomerIdKeys.image] = captureImage;
    docsCopy[captureDocIndex][CustomerIdKeys.captureDate] = format(
      new Date(),
      FNS_DATE_FORMAT
    );

    setDocList(docsCopy);
    setIsDocCaptureVisible(false);
  };

  return (
    <>
      <Form
        form={form}
        id="guestCustomerForm"
        onFinish={
          guestType === GUEST_TYPES.VAT ? onVATFormSubmit : onSaveAndContinue
        }
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <Row gutter={30}>
          {GUEST_REQUIRED_FIELD_GROUPS.TITLE.some(
            (field: any) => field === guestType
          ) && (
            <Col span={8}>
              <Form.Item
                label="Title*"
                name="title"
                className="label-control"
                rules={[
                  {
                    required: true,
                    message: 'Title is required',
                  },
                ]}
              >
                <Select
                  options={TITLES.map((item: any) => {
                    return {
                      value: item,
                      label: item,
                    };
                  })}
                />
              </Form.Item>
            </Col>
          )}
          {GUEST_REQUIRED_FIELD_GROUPS.NAMES.some(
            (field: any) => field === guestType
          ) && renderNameFields(8)}
          {GUEST_REQUIRED_FIELD_GROUPS.NAMES_SPAN_12.some(
            (field: any) => field === guestType
          ) && renderNameFields(12)}
        </Row>
        <Row gutter={30}>
          {GUEST_REQUIRED_FIELD_GROUPS.DATE_OF_BIRTH.some(
            (field: any) => field === guestType
          ) && (
            <Col span={12}>
              <Form.Item
                label="Date of Birth*"
                name="dateOfBirth"
                className="label-control"
                rules={[
                  {
                    required: true,
                    message: 'Date of Birth is required',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      } else if (value) {
                        return validateDateOfBirth(
                          _,
                          value,
                          guestType === GUEST_TYPES.FX
                            ? DOB_ALLOWEd_AGE.MIN_AGE_TM_LITE
                            : DOB_ALLOWEd_AGE.MIN_AGE
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePickerMUI
                  initialValue={initalDate}
                  disableFutureDates={true}
                  onChange={onDateChange}
                />
              </Form.Item>
            </Col>
          )}
          {GUEST_REQUIRED_FIELD_GROUPS.POSTCODE.some(
            (field: any) => field === guestType
          ) && renderPostCodeField(8)}
          {GUEST_REQUIRED_FIELD_GROUPS.POSTCODE_SPAN_12.some(
            (field: any) => field === guestType
          ) && renderPostCodeField(12)}
          {GUEST_REQUIRED_FIELD_GROUPS.POSTCODE_SPAN_24.some(
            (field: any) => field === guestType
          ) && renderPostCodeField(24)}
          {GUEST_REQUIRED_FIELD_GROUPS.DETAILED_ADDRESS.some(
            (field: any) => field === guestType
          ) && (
            <>
              <Col span={8}>
                <Form.Item
                  label="Flat / House Number"
                  name="property"
                  className="label-control"
                >
                  <Input className="input-control" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="House Name"
                  name="houseName"
                  className="label-control"
                >
                  <Input className="input-control" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Street / Road"
                  name="street"
                  className="label-control"
                >
                  <Input className="input-control" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Town" name="town" className="label-control">
                  <Input className="input-control" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Area" name="area" className="label-control">
                  <Input className="input-control" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="County"
                  name="county"
                  className="label-control"
                >
                  <Input className="input-control" />
                </Form.Item>
              </Col>
            </>
          )}
          {GUEST_REQUIRED_FIELD_GROUPS.DETAILED_ADDRESS_LINE.some(
            (field: any) => field === guestType
          ) && (
            <>
              <Col span={8}>
                <Form.Item
                  label="Address Line1"
                  name="addressLine1"
                  className="label-control"
                  rules={[
                    {
                      required: !isGuestCustomerAbstained,
                    },
                    {
                      type: 'string',
                      max: 60,
                      min: 0,
                    },
                  ]}
                >
                  <Input className="input-control" autoComplete="off" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Address Line2"
                  name="addressLine2"
                  className="label-control"
                  rules={[
                    {
                      type: 'string',
                      max: 60,
                      min: 0,
                    },
                  ]}
                >
                  <Input className="input-control" autoComplete="off" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Address Line3"
                  name="addressLine3"
                  className="label-control"
                  rules={[
                    {
                      type: 'string',
                      max: 60,
                      min: 0,
                    },
                  ]}
                >
                  <Input className="input-control" autoComplete="off" />
                </Form.Item>
              </Col>
            </>
          )}
          {GUEST_REQUIRED_FIELD_GROUPS.CONTACT.some(
            (field: any) => field === guestType
          ) && (
            <>
              <Col span={8}>
                <Form.Item
                  label="Mobile Number"
                  name="mobile"
                  className="label-control"
                >
                  <Input className="input-control" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Email ID"
                  name="emailAddress"
                  className="label-control"
                >
                  <Input className="input-control" />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        {isDocRequired && (
          <>
            <StyledAlertRow>
              <Col span={24}>{TM_AMOUNT_ALERT}</Col>
            </StyledAlertRow>
            {previousDocuments.length > 0 && renderPreviousDocuments()}
            {docList.map((doc: any, index: any) => (
              <Row key={index} gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item className="label-control">
                    <Select
                      value={doc?.documentTypeId}
                      onChange={(value: any) => onDocTypeChange(value, index)}
                      options={idTypes.map((item: any) => {
                        return {
                          value: item.id,
                          label: item.description,
                        };
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item className="label-control">
                    <Input
                      disabled
                      value={doc?.idCaptureDate}
                      className="input-control"
                      placeholder={DATE_FORMAT}
                    />
                  </Form.Item>
                </Col>
                <Col span={2} style={{ padding: '0 0 8px 0' }}>
                  <ImageLoader
                    classes={'mx-portrait-avatar scanned-image'}
                    imageUrl={doc?.image}
                    fallbackImg={'no_image_thumbnail.svg'}
                    preview
                  />
                </Col>
                <Col span={4}>
                  <Button
                    type="primary"
                    itemProp="secondary"
                    htmlType="button"
                    onClick={() => onDocumentCaptureClick(index)}
                    disabled={!doc?.documentTypeId}
                    style={{ width: '100%' }}
                  >
                    {doc?.image ? 'Update ID' : 'Capture ID'}
                  </Button>
                </Col>
              </Row>
            ))}
            <Row>
              <Button
                type="primary"
                itemProp="secondary"
                disabled={!isAddAnotherIdEnable}
                onClick={onAddAnotherIdClick}
              >
                {previousDocuments?.length > 0 || docList?.length > 0
                  ? `Add Another ID`
                  : 'Add ID'}
              </Button>
            </Row>
            {docAlert && (
              <StyledFieldErrorRow gutter={[4, 12]}>
                <Col span={24}>
                  <div className="alert-text">Document upload is required</div>
                </Col>
              </StyledFieldErrorRow>
            )}
          </>
        )}
      </Form>

      {isCustomerDocPopupVisible && (
        <CustomerIdPopover
          visible={isCustomerDocPopupVisible}
          customerDocs={selectedDocument}
          onCustomerDocPopoverClose={() => setIsCustomerDocPopupVisible(false)}
        />
      )}

      {isDocCaptureVisible && (
        <DocumentCapture
          visible={isDocCaptureVisible}
          docType={DOCUMENT_TYPE_OTHER}
          onCancel={() => setIsCustomerDocPopupVisible(false)}
          onDocCapture={onDocumentCapture}
          onAddClick={onAddDocumentImage}
        />
      )}

      <ScannerPopover visible={isScanning} message={DEFAULT_SCAN_MESSAGE} />
    </>
  );
};

export default GuestCustomer;
