import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import SubsequentRefundSummary from './SubsequentRefundSummary';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { FNS_DATE_FORMAT_WITH_TIME } from 'globalConstants';
import { RETURN_DATE_AND_TIME, RETURN_AT } from '../constants';

const WrapperRow = styled(Row)`
  border: 1px solid var(--red);
  background: #fdf3f0;
  width: 100%;
  height: 133px;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0.8;
  padding: 0px !important;
  top: 0px;
`;

const SubsequentRefund = () => {
  const { alreadyRefunded } = useSelector((state: any) => {
    return {
      alreadyRefunded: state.retail.alreadyRefunded,
    };
  });
  const { returnedDate, status, storeName } = alreadyRefunded[0];

  return (
    <WrapperRow className="subsequent-refund-wrapper">
      <Row className="subsequent-refund-header">
        <Col span={24} className="order-status">
          {status ? 'Refunded' : ''}
        </Col>
      </Row>
      <Row className="subsequent-refund-details">
        <Col span={12} className="order-returned-date">
          {RETURN_DATE_AND_TIME}
          {format(new Date(returnedDate), FNS_DATE_FORMAT_WITH_TIME)}
        </Col>
        <Col span={12} className="order-returned-at">
          {RETURN_AT + storeName}
        </Col>
      </Row>
      <Row className="subsequent-refund-summary">
        <SubsequentRefundSummary rows={alreadyRefunded} />
      </Row>
    </WrapperRow>
  );
};
export default SubsequentRefund;
