import React, { useState, useEffect } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import VirtualGrid from 'components/common/virtualGrid';
import { GRID_NO_URL } from 'globalConstants';
import { isEmpty } from 'lodash';

interface CoinDetailsProps {
  coinRow: Array<any>;
  removeCoinRow: (row: any) => void;
  onClickCoinRow: (row: any) => void;
  clearSelection: boolean;
  coinEditRow?: CoinRowProps | any;
  conditionAttributes: Array<any>;
  isValuationItemToPledge?: boolean | undefined;
}

interface CoinRowProps {
  id?: any;
  coinType?: any;
  coinCondition?: any;
  coinYear?: number | string;
  quantity?: number | string;
}

const TableRow = ({ row, onClickCoinRow, ...restProps }: any) => {
  const onClickTableRow = () => {
    onClickCoinRow(row);
  };

  const Cell = () => {
    const { column } = restProps;
    if (column.name === 'delete') {
      return (
        <Table.Cell
          {...restProps}
          style={{
            cursor: 'pointer',
          }}
        />
      );
    }
    return (
      <Table.Cell
        onClick={onClickTableRow}
        {...restProps}
        style={{
          cursor: 'pointer',
        }}
      />
    );
  };

  return <>{Cell()}</>;
};

const CoinDetailsTable = ({
  coinRow,
  removeCoinRow,
  onClickCoinRow,
  clearSelection,
  coinEditRow,
  conditionAttributes,
  isValuationItemToPledge,
}: CoinDetailsProps) => {
  const [selection, setSelection] = useState<Array<any>>([]);
  const [isRowDeleted, setRowDeleted] = useState<boolean>(false);

  if (clearSelection && selection.length !== 0) {
    setSelection([]);
    setRowDeleted(false);
  }

  if (clearSelection && isRowDeleted) {
    setRowDeleted(false);
  }

  const onSelectionChange = (row: any) => {
    const lastSelected = row.find(
      (selected: any) => selection.indexOf(selected) === -1
    );
    if (lastSelected !== undefined && !isRowDeleted) {
      setSelection([lastSelected]);
      setRowDeleted(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(coinEditRow)) {
      onSelectionChange([coinEditRow.id]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [columns] = useState([
    {
      name: 'id',
      title: 'Ref No.',
      getCellValue: (row: any) => row.id,
    },
    {
      name: 'coinType',
      title: 'Coin Type',
      getCellValue: (row: any) => row.coinType?.description,
    },
    {
      name: 'coinCondition',
      title: 'Condition',
      getCellValue: (row: any) => {
        const condition = conditionAttributes[0].itemFixedAttributes.find(
          (x: any) => x?.id === row?.coinCondition
        );
        return condition?.description;
      },
    },
    {
      name: 'coinYear',
      title: 'Year',
      getCellValue: (row: any) => row.coinYear,
    },
    {
      name: 'quantity',
      title: 'Quantity',
      getCellValue: (row: any) => row.quantity,
    },
    {
      name: 'delete',
      title: ' ',
      type: 'delete',
      onClick: (row: any) => {
        if (!isValuationItemToPledge) {
          removeCoinRow(row);
          setRowDeleted(true);
        }
      },
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'id', width: '15%' },
    { columnName: 'coinType', width: '20%' },
    { columnName: 'coinCondition', width: '15%' },
    { columnName: 'coinYear', width: '15%' },
    { columnName: 'quantity', width: '15%', align: 'center' },
    { columnName: 'delete', width: '15%', align: 'right' },
  ]);
  const formattedColumns = ['delete'];

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={coinRow}
        formattedColumns={formattedColumns}
        cellComponent={(restProps: any) => (
          <TableRow onClickCoinRow={onClickCoinRow} {...restProps} />
        )}
        rowSelectionEnable={true}
        showSelectionColumn={false}
        showSelectAll={false}
        selection={selection}
        onSelectionChange={onSelectionChange}
        selectByRowClick={true}
        highlightRow={true}
      />
    </>
  );
};

export default CoinDetailsTable;
