import React, { useState, useEffect } from 'react';
import { Col, Form, Input, Modal, Row } from 'antd';
import { Button } from 'components/common/Button';
import styled from 'styled-components';
import { ADD_STONE_LABELS } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  PLEDGE_ITEM_PRICE_REQUEST,
  UPLOAD_MULTIPLE_IMAGE_REQUEST,
} from 'action_creators/pledge';
import { stoneActions as actions } from 'redux/reducers/pawnbroking/stone';
import { pledgeActions } from 'redux/reducers/pawnbroking/index';
import isEmpty from 'lodash/isEmpty';
import { reducePledgeItems } from 'utils/util';

import Select from 'components/common/Select';
import InputDecimal from 'components/common/InputDecimal';
import StoneDetailsTable from './StoneDetailsTable';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import MultipleImageUploader from 'components/common/MultipleImageUploader';
import TextArea from 'components/common/TextArea';

const StyledModal = styled(Modal)`
  & .ant-row.ant-form-item.label-control {
    margin-block-end: 12px;
  }
`;

const StyledHeading = styled.div`
  margin: 5px 0;
  font-weight: var(--font-weight-500);
`;

interface StonePopupProps {
  visible: boolean;
  setStonePopupVisible: () => void;
  itemFixedAttributes: Array<any>;
  stoneEditRow?: StoneRowProps | any;
}
interface StoneSelectOptionProps {
  id?: number;
  description?: string;
  sortOrder?: number;
}

interface StoneRowProps {
  id?: any;
  stoneType?: any;
  stoneClarity?: any;
  stoneClass?: any;
  stoneShape?: any;
  reportNumber?: string;
  stonePoint?: number | string;
  stoneQuantity?: number | string;
  stoneNotes?: string;
}

const validateMessages = {
  /* eslint-disable no-template-curly-in-string */
  required: '${label} is required',
};

enum StoneAttributeLabels {
  Type = 'Type/Colour',
  Shape = 'Shape',
  Colour = 'Colour',
  Clarity = 'Clarity',
}

enum StoneAttributeNames {
  Type = 'stoneType',
  Shape = 'stoneShape',
  Clarity = 'stoneClass',
  Colour = 'stoneClarity',
}

enum StoneAttributeTypes {
  Type = 'STONE TYPES',
  Shape = 'STONE SHAPES',
  Clarity = 'STONE CONDITION', // clarity for UI
  Colour = 'CLARITY',
}

enum FormButton {
  Add = 'Add',
  Update = 'Update',
}

const AddStonePopup = ({
  visible,
  setStonePopupVisible,
  itemFixedAttributes,
  stoneEditRow,
}: StonePopupProps) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    stone: {
      stoneRowId,
      stoneItemsTempData,
      stoneAllItemsData,
      stoneRemoveData,
      isStoneRowRemoved,
      isStoneRowEdit,
      stoneEditData,
    },
    coin: { coinPrices },
    pawnbroking: { itemCategory, metalPrice, itemImageCollectionResponse },
    valuationCenter: { isValuationItemToPledge },
  } = useSelector((state: any) => {
    return {
      stone: state.stone,
      coin: state.coin,
      pawnbroking: state.pawnbroking,
      valuationCenter: state.valuationCenter,
    };
  });
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [clearSelection, setClearSelection] = useState<boolean>(false);

  const StoneSelectOptions = (
    label: string,
    name: string,
    itemOptions?: any
  ) => {
    return (
      <Row>
        <Col span={24}>
          <Form.Item
            label={`${label}*`}
            name={name}
            className="label-control"
            rules={[{ required: true, message: `${label} is required` }]}
          >
            <Select
              options={
                itemOptions &&
                itemOptions[0]?.itemFixedAttributes.map(
                  (el: StoneSelectOptionProps) => {
                    return {
                      key: el.id,
                      value: JSON.stringify(el),
                      label: el.description,
                    };
                  }
                )
              }
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const onStonePopupCancelClick = () => {
    dispatch(actions.stoneRowId(stoneRowId - stoneItemsTempData.length));
    if (isStoneRowRemoved)
      dispatch(actions.stoneAllItems([...stoneRemoveData]));
    if (isStoneRowEdit) dispatch(actions.stoneAllItems([...stoneEditData]));
    else dispatch(actions.stoneItemsTemp([]));
    setStonePopupVisible();
    setClearSelection(false);
  };

  const onStonePopupSubmitClick = () => {
    const data = [...stoneAllItemsData, ...stoneItemsTempData];
    const stonePrices = reducePledgeItems(data);
    const params = {
      categoryId: itemCategory?.id,
      metalPrice,
      coinPrices,
      stonePrices,
    };
    dispatch({
      type: PLEDGE_ITEM_PRICE_REQUEST,
      payload: { params },
    });
    dispatch(
      actions.stoneAllItems([...stoneAllItemsData, ...stoneItemsTempData])
    );
    dispatch(actions.stoneItemsPrice(params.stonePrices));
    setStonePopupVisible();
    setClearSelection(false);
  };

  const getStoneAttributes = (item: string) => {
    return itemFixedAttributes.filter((x) => item === x.description);
  };

  const onFormSubmit = () => {
    dispatch(actions.stoneRowId(stoneRowId + 1));
    const obj: object = {
      id: stoneRowId,
      stoneType: JSON.parse(form.getFieldValue('stoneType')),
      stoneShape: JSON.parse(form.getFieldValue('stoneShape')),
      stoneClass: JSON.parse(form.getFieldValue('stoneClass')),
      stoneClarity: JSON.parse(form.getFieldValue('stoneClarity')),
      reportNumber: form.getFieldValue('reportNumber'),
      stonePoint: form.getFieldValue('stonePoint'),
      stoneQuantity: form.getFieldValue('stoneQuantity'),
      stoneNotes: form.getFieldValue('stoneNotes'),
    };

    dispatch(actions.stoneItemsTemp([...stoneItemsTempData, obj]));
    form.resetFields();
    setClearSelection(false);
  };

  const onFormEdit = () => {
    const id = form.getFieldValue('id');
    const tempIndex = stoneItemsTempData.findIndex((x: any) => x.id === id);
    const allIndex = stoneAllItemsData.findIndex((x: any) => x.id === id);
    const isIndexEdit = allIndex !== -1 ? true : false;
    const stoneData: object = {
      id: id,
      stoneType: JSON.parse(form.getFieldValue('stoneType')),
      stoneShape: JSON.parse(form.getFieldValue('stoneShape')),
      stoneClass: JSON.parse(form.getFieldValue('stoneClass')),
      stoneClarity: JSON.parse(form.getFieldValue('stoneClarity')),
      reportNumber: form.getFieldValue('reportNumber'),
      stonePoint: form.getFieldValue('stonePoint'),
      stoneQuantity: form.getFieldValue('stoneQuantity'),
      stoneNotes: form.getFieldValue('stoneNotes'),
    };

    dispatch(
      actions.editStoneRow({
        index: isIndexEdit ? allIndex : tempIndex,
        stoneData: stoneData,
        isIndexEdit: isIndexEdit,
      })
    );
    setEditMode(false);
    form.resetFields();
    setClearSelection(true);
  };

  const onCancelClick = () => {
    setEditMode(false);
    form.resetFields();
    setClearSelection(true);
  };

  const getStoneRowData = () => {
    return [...stoneAllItemsData, ...stoneItemsTempData];
  };

  const removeStoneRow = (row: StoneRowProps) => {
    dispatch(actions.removeStoneRow(row));
    if (form.getFieldValue('id') === row.id) {
      onCancelClick();
    }
  };

  const onClickStoneRow = (row: any) => {
    setClearSelection(false);
    setEditMode(true);
    getStoneEditDetails(row);
  };

  const getStoneEditDetails = (stoneEditRow: StoneRowProps) => {
    const {
      id,
      reportNumber,
      stonePoint,
      stoneQuantity,
      stoneNotes
    } = stoneEditRow;
    let {
      stoneClarity,
      stoneClass,
      stoneType,
      stoneShape
    } = stoneEditRow;

    stoneClarity = JSON.stringify(stoneClarity);
    stoneClass = JSON.stringify(stoneClass);
    stoneType = JSON.stringify(stoneType);
    stoneShape = JSON.stringify(stoneShape);

    form.setFieldsValue({
      id,
      reportNumber,
      stonePoint,
      stoneQuantity,
      stoneNotes,
      stoneClarity,
      stoneClass,
      stoneType,
      stoneShape,
    });
  };

  useEffect(() => {
    if (!isEmpty(stoneEditRow)) {
      setEditMode(true);
      getStoneEditDetails(stoneEditRow);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const uploadImageHandler = (image: any) => {
    dispatch({
      type: UPLOAD_MULTIPLE_IMAGE_REQUEST,
      payload: { image, isDefault: false },
    });
  };

  const updateImageList = (imageList: any) => {
    const params = {
      ...itemImageCollectionResponse,
      images: imageList,
    };
    dispatch(pledgeActions.setItemImageCollectionResponse(params));
  };

  return (
    <>
      <StyledModal
        title={ADD_STONE_LABELS.TITLE}
        open={visible}
        width={1500}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={onStonePopupCancelClick}
        onOk={onStonePopupSubmitClick}
        centered
        footer={[
          <Row gutter={24} justify="end" key="stoneFooter">
            <Col>
              <Button
                itemProp="secondary"
                key="back"
                onClick={onStonePopupCancelClick}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                key="submit"
                form="form"
                htmlType="submit"
                onClick={onStonePopupSubmitClick}
                disabled={
                  stoneItemsTempData?.length === 0 &&
                  !isStoneRowRemoved &&
                  !isStoneRowEdit
                }
              >
                Submit
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Row>
          <Col span={6}>
            <Form
              onFinish={!isEditMode ? onFormSubmit : onFormEdit}
              layout="vertical"
              form={form}
              validateMessages={validateMessages}
              disabled={isValuationItemToPledge}
            >
              <Row align="top">
                <Col span={21}>
                  <Row>
                    <Col span={24}>
                      <Form.Item label="id" name="id" noStyle>
                        <Input type="hidden" />
                      </Form.Item>
                    </Col>
                  </Row>
                  {StoneSelectOptions(
                    StoneAttributeLabels.Type,
                    StoneAttributeNames.Type,
                    getStoneAttributes(StoneAttributeTypes.Type)
                  )}
                  {StoneSelectOptions(
                    StoneAttributeLabels.Shape,
                    StoneAttributeNames.Shape,
                    getStoneAttributes(StoneAttributeTypes.Shape)
                  )}
                  {StoneSelectOptions(
                    StoneAttributeLabels.Colour,
                    StoneAttributeNames.Colour,
                    getStoneAttributes(StoneAttributeTypes.Colour)
                  )}
                  {StoneSelectOptions(
                    StoneAttributeLabels.Clarity,
                    StoneAttributeNames.Clarity,
                    getStoneAttributes(StoneAttributeTypes.Clarity)
                  )}
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Size (pts)*"
                        name="stonePoint"
                        className="label-control"
                        rules={[
                          {
                            required: true,
                            message: 'Size is required',
                          },
                        ]}
                      >
                        <InputDecimal
                          addonBefore={false}
                          placeholder={false}
                          className="input-control"
                          autoComplete="off"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Quantity*"
                        name="stoneQuantity"
                        className="label-control"
                        rules={[
                          {
                            required: true,
                            message: 'Quantity is required',
                          },
                          {
                            pattern: new RegExp('^0*[1-9][0-9]*$'),
                            message:
                              'Quantity should be a number and greater than 0',
                          },
                        ]}
                      >
                        <Input className="input-control" autoComplete="off" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Report Number"
                        name="reportNumber"
                        className="label-control"
                      >
                        <Input className="input-control" autoComplete="off" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Notes"
                        name="stoneNotes"
                        className="label-control"
                      >
                        <TextArea
                          rows={2}
                          maxLength={100}
                          autoComplete="off"
                          className="input-control"
                          dataTestId="addStonePopup-textArea"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24} justify="end">
                    <Col>
                      <Button
                        itemProp="secondary"
                        key="back"
                        onClick={onCancelClick}
                      >
                        Clear
                      </Button>
                    </Col>
                    <Col>
                      <Button type="primary" htmlType="submit">
                        {!isEditMode ? FormButton.Add : FormButton.Update}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col span={18}>
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <StyledHeading>{ADD_STONE_LABELS.IMAGES}</StyledHeading>
                  </Col>
                </Row>
                <Row>
                  <MultipleImageUploader
                    columns={6}
                    imageList={itemImageCollectionResponse?.images}
                    uploadImageRequest={uploadImageHandler}
                    updateImageList={updateImageList}
                    viewOnly={isValuationItemToPledge}
                  />
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <StyledHeading>
                      {ADD_STONE_LABELS.STONE_DETAILS}
                    </StyledHeading>
                    <HelpPopoverInfo
                      linkedID="ADDSTONE_ADDSTONE"
                      position="rightTop"
                    ></HelpPopoverInfo>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="stone-details-table-wrapper">
                    <StoneDetailsTable
                      stoneRow={getStoneRowData()}
                      removeStoneRow={removeStoneRow}
                      onClickStoneRow={onClickStoneRow}
                      clearSelection={clearSelection} // for clearing the selection on actions
                      stoneEditRow={stoneEditRow} // for selection on popup open
                      isValuationItemToPledge={isValuationItemToPledge}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledModal>
    </>
  );
};

export default AddStonePopup;
