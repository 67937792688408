import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Drawer, Col, Row, Button as LinkButton } from 'antd';
import styled from 'styled-components';

import CommonAccessoriesModal from './commonAccessoriesModal';
import TelephoneList from './telephoneList';
import ViewInterestTable from './interestRateTable/ViewInterestTable';

import { ACCESSORIES_LINKS, SAR_FORM_LINK, AFFIDAVIT_TYPE } from '../constants';
import { PRINT_COCM, PRINT_AFFIDAVIT, PRINT_CUSTOMER_MANDATE } from 'action_creators/accessories';

import './index.less';

const StyledLinkButton = styled(LinkButton)`
  margin-left: -15px;
`;

const StyledCommonRow = styled(Row)`
  text-align: left;
  cursor: pointer;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 44px;
`;

const AccessoriesDrawer = ({ visible, onClose }: any) => {
  const dispatch = useDispatch();
  const drawerRef = useRef<HTMLDivElement>(null);

  const [selectedLink, setSelectedLink] = useState<string>('');
  const [isLinkButtonClicked, setIsLinkButtonClicked] =
    useState<boolean>(false);

  const checkIfClickedOutside = (e: any) => {
    if (visible && drawerRef.current && !drawerRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (!isLinkButtonClicked)
      document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [visible, isLinkButtonClicked]); // eslint-disable-line react-hooks/exhaustive-deps

  const onModalClose = () => {
    setSelectedLink('');
    setIsLinkButtonClicked(false);
  };

  const onLinkClick = (link: any) => {
    if (link === ACCESSORIES_LINKS.CLEAN_MONEY)
      dispatch({
        type: PRINT_COCM,
      });
    else if (link === ACCESSORIES_LINKS.UNDER_75_AFFIDAVIT)
      dispatch({
        type: PRINT_AFFIDAVIT,
        payload: AFFIDAVIT_TYPE.UNDER_75_AFFIDAVIT,
      });
    else if (link === ACCESSORIES_LINKS.OVER_75_AFFIDAVIT)
      dispatch({
        type: PRINT_AFFIDAVIT,
        payload: AFFIDAVIT_TYPE.OVER_75_AFFIDAVIT,
      });
    else if (link === ACCESSORIES_LINKS.CUSTOMER_THIRD_PARTY_CHEQUE_MANDATE)
      dispatch({
        type: PRINT_CUSTOMER_MANDATE,
      });
    else if (link === ACCESSORIES_LINKS.SUSPICIOUS_ACTIVITY_REPORT)
      window.open(SAR_FORM_LINK, '_blank');
    else {
      setSelectedLink(link);
      setIsLinkButtonClicked(true);
    }
  };

  return (
    <>
      <div
        style={{
          display: visible ? 'inline ' : 'none',
        }}
      >
        <div ref={drawerRef}>
          <Drawer
            className="accessories-drawer-wrapper"
            zIndex={10}
            open={visible}
            onClose={onClose}
            getContainer={false}
            closable={false}
          >
            {Object.values(ACCESSORIES_LINKS).map((link: any) => (
              <StyledCommonRow key={link} align="top">
                <Col span={12}>
                  <StyledLinkButton
                    type="link"
                    onClick={() => onLinkClick(link)}
                  >
                    {link}
                  </StyledLinkButton>
                </Col>
              </StyledCommonRow>
            ))}
          </Drawer>
        </div>

        {(selectedLink === ACCESSORIES_LINKS.DAILY_INTEREST_CALCULATOR ||
          selectedLink === ACCESSORIES_LINKS.METAL_PRICING ||
          selectedLink === ACCESSORIES_LINKS.STONE_SIZE_CALCULATOR) && (
          <CommonAccessoriesModal
            selectedLink={selectedLink}
            onCancelClick={onModalClose}
          />
        )}
        {selectedLink === ACCESSORIES_LINKS.INTEREST_RATE_BANDS && (
          <ViewInterestTable onCancelClick={onModalClose} />
        )}
        {selectedLink === ACCESSORIES_LINKS.TELEPHONE_LIST && (
          <TelephoneList onCancelClick={onModalClose} />
        )}
      </div>
    </>
  );
};
export default AccessoriesDrawer;
