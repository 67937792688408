export const HEADER_TITLE = 'Create';
export const QUICK_QUOTE_TITLE = 'Quick Quote';
export const SERVICE_FEE = 'Service Fee';
export const ITEM_FEE = 'Item Fee';
export const SPECIAL_EXPRESS_FEE = 'Special/Express Fee';
export const PROMOTION = 'Promotion';
export const TOTAL_CHARGES = 'Total Charges';
export const RESET_BUTTON = 'Reset';
export const ADD = 'Add';
export const EXIT = 'Exit';
export const AMOUNT_TITLE = 'Amount';
export const STEP_ONE = 'Step 1: Find Customer';
export const STEP_TWO = 'Step 2: Enter Cheque Details';
export const STEP_THREE = 'Step 3: Pre-Authorisation';

export const FIND_CUSTOMER = 'Find Customer';
export const VIEW_ISSUER_DETAILS = 'View Issuer Details';
export const VIEW_ISSUER = 'View Issuer';
export const FIND_CREATE_ISSUER = 'Find/Create New Issuer';
export const CHECK_PRE_AUTH = 'Check Pre-Authorisation';
export const CLOSE = 'Close';
export const NEW_ISSUER_LABEL = 'Create New Issuer';
export const FIND_ISSUER_LABEL = 'Find Issuer (As shown on cheque)';
export const FIND_CUSTOMER_LABEL = 'Find Customer';
export const FIND_ISSUER_PLACEHOLDER =
  'Enter full name of Issuer as printed on the cheque';
export const DONT_FIND_MESSAGE = 'Didn’t find the right match?';
export const SORRY_MESSAGE = `Sorry we couldn't find any matches for`;
export const CLICK_HERE_REGISTER_MESSAGE = `Click here to create new issuer`;
export const ADDRESS_MANUALLY = 'Enter Address Manually';
export const TRADING_AS_PLACEHOLDER = 'Select Trading As';
export const COMPANY_TYPE_PLACEHOLDER = 'Select Company Type';
export const DEFAULT_NEW_ISSUER = 'Issuer not found';
export const PRE_AUTHRISATION_PASSED = 'Passed';
export const PRE_AUTHRISATION_FAILED = 'Failed';
export const SORT_CODE_PATTERN = /^(\d{2}-\d{2}-\d{2}|\d{6})$/;
export const ACCOUNT_NUMBER_PATTERN = /^(\d{6}|\d{7}|\d{8})$/;
export const ISSUER_NAME_PATTERN = /[a-zA-Z]$/;
export const SORT_CODE_INVALID_PATTERN = /^[0-9-]*$/;
export const CHEQUE_NUMBER_PATTERN = /^(\d{3,6})$/;
export const CHEQUE_NUMBER_ALREADY_EXIST = 'Cheque Number Already Exist!';
export const PART_PAY_HEADING = 'Third Party Part Payment';
export const PART_PAY_HEADING_SECOND =
  'Select a cheque to make part payment on';
export const CHEQUE_AUTH_REQUEST_SENT_SUCCESSFULLY =
  'Cheque Auth Request Sent Successfully';
export const CHEQUE_AUTH_REQUEST_SENT_SUCCESSFULLY_SECOND =
  'You will be notified when the Cheque Authorisations Team has provided \na response via Email';

export const FORM_BUTTON_LABELS = {
  CHEQUE_AMOUNT_LABEL: 'Cheque Amount',
};

export const CREATE_ISSUER_ERROR_MESSAGES = {
  POSTCODE: {
    REQUIRED: 'Enter a postcode',
    INVALID: 'Enter a valid postcode',
    NA: 'Could not find any results for the given postcode',
  },
  COMPANY_TYPE: {
    REQUIRED: 'Ltd Company Number should be a number.',
    INVALID: 'Choose Company Type',
  },
  ISSUER_NAME: {
    REQUIRED: 'Enter a issuer name',
  },
  COMPANY_NUMBER: {
    REQUIRED: 'Enter a Ltd company number',
  },
  TELEPHONE: {
    REQUIRED: 'Enter a telephone number',
    INVALID: 'Mobile is not a valid UK or International phone number.',
  },
  ADDITIONAL_TELEPHONE: {
    REQUIRED: 'Enter a additional telephone number',
    INVALID: 'Mobile is not a valid UK or International phone number.',
  },
  CONTACT_NAME: {
    REQUIRED: 'Enter a contact name',
    INVALID: 'Name should not contain numbers',
  },
  MOBILE: {
    REQUIRED: 'Enter a mobile number',
    INVALID: 'Mobile is not a valid UK or International phone number.',
  },
};

export const CHEQUE_DETAILS_ERROR_MESSAGES = {
  CHEQUE_NUMBER: {
    REQUIRED: 'Enter a cheque number',
    INVALID: 'Enter valid cheque number',
  },
  ACCOUNT_NUMBER: {
    REQUIRED: 'Enter an account number',
    INVALID: 'Enter a valid account number between 6 and 8 digits',
  },
  ISSUER_NAME: {
    REQUIRED: 'Enter a cheque issuer name',
  },
  CHEQUE_TYPE: {
    REQUIRED: 'Select a cheque type',
  },
  CHEQUE_NOTE: {
    REQUIRED: 'Enter a note',
  },
  SORT_CODE: {
    REQUIRED: 'Enter a sort code',
    DIGIT_INVALID: 'Enter a valid 6 digit sort code',
    INVALID: 'Enter a valid sort code',
  },
  CHEQUE_DATE: {
    INVALID: 'Cheque is out of date',
    REQUIRED: 'Enter cheque date',
  },
  APPROVED_RETAINED_AMOUNT: {
    INVALID: 'Value cannot be greater than requested amount.',
  },
};

export const PART_PAYMENT_LABELS = {
  CHEQUE_FACE_VALUE: 'Cheque Face Value',
  TOTAL_CHARGES: 'Total Charges',
  AMOUNT: 'Amount',
  PAID_TO_DATE: 'Paid to date',
  OUTSTANDING: 'Outstanding',
  APPROVE_TO_PAY: 'Approve to Pay',
  RETAINED_AMOUNT: 'Retained Amount',
  REQUESTED_AMOUNT: 'Requested Amount',
};

export const STATUS = {
  BANKED: 'Banked',
  OPEN: 'Open',
  UNCLEARED: 'Uncleared',
  STOP: 'Stop',
};

export const CHEQUE_CASHING_HISTORY_EXISTS = {
  YES: 1,
  NO: 0,
};

export const CHEQUE_SERVICE_FEE = {
  PERSONAL_CHEQUE: {
    KEY: 'personalCheque',
    LABEL: 'Personal Cheque',
    FEE: '13.00%',
  },
};
