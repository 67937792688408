//STOCK TRANSFER ACTIONS
export const SEARCH_TRANSFER = 'SEARCH_TRANSFER';
export const STORES_REQUEST = 'STORES_REQUEST';
export const CREATE_TRANSFER_ID = 'CREATE_TRANSFER_ID';
export const GET_TRANSFER_ITEMS = 'GET_TRANSFER_ITEMS';
export const TRANSFER_BARCODE_SEARCH = 'TRANSFER_BARCODE_SEARCH';
export const DELETE_TRANSFER_ITEMS = 'DELETE_TRANSFER_ITEMS';
export const AUTHORIZE_STOCK_TRANSFER = 'AUTHORIZE_STOCK_TRANSFER';
export const SEND_STOCK_TRANSFER = 'SEND_STOCK_TRANSFER';
export const ACCEPT_STOCK_TRANSFER = 'ACCEPT_STOCK_TRANSFER';
