import * as urls from '../apiUrls';
import { doRequest } from 'utils/request';

/**validate created pledge basket */
const validateCreatedPledge = (params, authorisationHeaders) => {
  return doRequest(
    `${urls.VALIDATE_CREATED_PLEDGE_BASKET}`,
    'post',
    params,
    '',
    false,
    '',
    authorisationHeaders
  );
};

/**validate created retail basket */
const validateCreatedRetail = (params, authorisationHeaders) => {
  return doRequest(
    `${urls.VALIDATE_CREATED_RETAIL_BASKET}`,
    'post',
    params,
    '',
    false,
    '',
    authorisationHeaders
  );
};

const validateCreatedPickupRenew = (params, authorisationHeaders) => {
  return doRequest(
    `${urls.VALIDATE_CREATED_PICKUP_BASKET}`,
    'post',
    params,
    '',
    false,
    '',
    authorisationHeaders
  );
};

const validateCreatedFx = (params, authorisationHeaders) => {
  return doRequest(
    `${urls.VALIDATE_CREATED_FX_BASKET}`,
    'post',
    params,
    '',
    false,
    '',
    authorisationHeaders
  );
};

const validateCreatedThirdPartyCheque = (params, authorisationHeaders) => {
  return doRequest(
    `${urls.VALIDATE_CREATED_TPC_BASKET}`,
    'post',
    params,
    '',
    false,
    '',
    authorisationHeaders
  );
};

const validateCreatedPurchase = (params, authorisationHeaders) => {
  return doRequest(
    `${urls.VALIDATE_CREATED_PURCHASE_BASKET}`,
    'post',
    params,
    '',
    false,
    '',
    authorisationHeaders
  );
};

/**Wester union validate post request */
const validateCreatedWU = (params, authorisationHeaders) => {
  return doRequest(
    `${urls.VALIDATE_CREATED_WU_BASKET}`,
    'post',
    params,
    '',
    false,
    '',
    authorisationHeaders
  );
};

const validateCreatedPurchaseReturn = (params, authorisationHeaders) => {
  return doRequest(
    `${urls.VALIDATE_CREATED_PURCHASE_RETURN_BASKET}`,
    'post',
    params,
    '',
    false,
    '',
    authorisationHeaders
  );
};

const validateCreatedSurplus = (params, authorisationHeaders) => {
  return doRequest(
    `${urls.VALIDATE_CREATED_SURPLUS_BASKET}`,
    'post',
    params,
    '',
    false,
    '',
    authorisationHeaders
  );
};

const getUserSterlingBalance = (params) => {
  return doRequest(`${urls.CHECK_USER_STERLING_BALANCE}`, 'post', params);
};

const getUserFxBalance = (params) => {
  return doRequest(`${urls.CHECK_USER_FX_BALANCE}`, 'post', params);
};

const validateLoanActions = (params) => {
  return doRequest(`${urls.VALIDATE_LOAN_ACTIONS}`, 'post', params);
};

const validateNewPledgePermission = (params) => {
  return doRequest(`${urls.VALIDATE_NEW_PLEDGE_PERMISSIONS}`, 'post', params);
};

export {
  validateCreatedPledge,
  validateCreatedRetail,
  validateCreatedPickupRenew,
  validateCreatedFx,
  validateCreatedThirdPartyCheque,
  validateCreatedPurchase,
  validateCreatedWU,
  validateCreatedPurchaseReturn,
  getUserSterlingBalance,
  getUserFxBalance,
  validateCreatedSurplus,
  validateLoanActions,
  validateNewPledgePermission,
};
