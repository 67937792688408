import React from 'react';
import styled from 'styled-components';

const StyleHeading = styled.div`
  width: 100%;
  height: 4.2rem;
  font-weight: bold;
  font-size: var(--font-size-16);
  color: rgba(34, 52, 69, 0.85);
  background: var(--catskill-white);
  padding: 10px 12px;
`;

type Props = {
  heading: string;
  children?: any;
};

const ModalHeaderStrip: React.FC<Props> = ({ heading, children }) => (
  <>
    <StyleHeading>{heading}</StyleHeading>
    {children ? <div>{children}</div> : null}
  </>
);

export default ModalHeaderStrip;
