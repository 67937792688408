import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ControlledAlert from 'components/common/ControlledAlert';
import { PAYMENT_METHOD, USER_BALANCE_ALERT } from '../constants';
import { actions } from 'redux/reducers/basket';
import { Checkbox, Col, Row } from 'antd';
import styled from 'styled-components';

const StyledChecbox = styled(Checkbox)`
  font-size: var(--font-size-14);
  margin: 0 !important;
`;

const StyledRow = styled(Row)`
  display: flex;
  justify-content: flex-start;
`;

const UserBalancePopup = ({ setIsProceeding, isUserBalanceAlert }: any) => {
  const [isUserBalancePopupVisible, setUserBalancePopupVisible] =
    useState<boolean>(false);

  const [isPayByCard, setPayByCard] = useState<boolean>(false);
  const [isPayByFP, setPayByFP] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { currenciesAvailable } = useSelector((state: any) => state.basket);

  useEffect(() => {
    if (isUserBalanceAlert) {
      setUserBalancePopupVisible(true);
    } else {
      setUserBalancePopupVisible(false);
    }
  }, [isUserBalanceAlert]);

  const onOkClick = () => {
    const isFxCurrencies = currenciesAvailable.currencies.filter(
      (x: any) => x.currency !== 'GBP'
    );
    if (
      currenciesAvailable?.isPayByOtherMethods?.length &&
      !isFxCurrencies?.length
    ) {
      dispatch(actions.updateCurrenciesNotAvailable());
    } else dispatch(actions.clearIsServicesValidate({}));

    setUserBalancePopupVisible(false);
    setIsProceeding();
  };

  const onPayByCardChange = (e: any) => {
    setPayByCard(e.target.checked);
    if (e.target.checked) dispatch(actions.updatePayByOtherMethods(['card']));
    else dispatch(actions.updatePayByOtherMethods([]));
  };

  const onPayByFPChange = (e: any) => {
    setPayByFP(e.target.checked);
    if (e.target.checked)
      dispatch(actions.updatePayByOtherMethods(['fasterpayment']));
    else dispatch(actions.updatePayByOtherMethods([]));
  };

  const fxBalance = () => {
    return (
      <div style={{ textAlign: 'start' }}>
        <div style={{ paddingBlockEnd: '15px' }}>{USER_BALANCE_ALERT}</div>
        {currenciesAvailable?.currencies?.map((item: any, indx: any) => (
          <div key={indx}>
            <strong>
              {item.currency} {`(current value in till ${item.amount})`}
            </strong>

            {item.currency === 'GBP' ? (
              <StyledRow>
                <Col span={24}>
                  <StyledChecbox
                    checked={isPayByCard}
                    onChange={onPayByCardChange}
                  >
                    {PAYMENT_METHOD.CARD}
                  </StyledChecbox>
                </Col>
                <Col span={24}>
                  <StyledChecbox checked={isPayByFP} onChange={onPayByFPChange}>
                    {PAYMENT_METHOD.FASTER_PAYMENT}
                  </StyledChecbox>
                </Col>
                {currenciesAvailable?.currencies?.length > 1 ? (
                  <hr
                    style={{ height: '1px', width: '100%', margin: '10px 0' }}
                  />
                ) : null}
              </StyledRow>
            ) : null}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <ControlledAlert
        visible={isUserBalancePopupVisible && isUserBalanceAlert}
        message={fxBalance()}
        onClick={onOkClick}
        isCancelBtn={false}
        yesButtonText="OK"
        onCancel={onOkClick}
      />
    </>
  );
};

export default UserBalancePopup;
