import React, { useEffect, useState, useCallback } from 'react';
import { Layout } from 'antd';
import { format } from 'date-fns';
import {
  AutoComplete,
  Row,
  Col,
  Form,
  Radio,
  Input,
  Spin,
  Checkbox,
  notification,
  Button as LinkButton,
  Progress,
} from 'antd';

import { useNavigate, useParams } from 'react-router-dom';

import { findValueByKey, validateDateOfBirth } from 'utils/util';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'components/common/Button';
import {
  getAddress,
  getCountries,
  getDocTypes,
  getPreferredLangauge,
  putCustomer,
  verfiyMobileNumberSMS,
  verfiyMobileNumberVerifyCode,
  getTpaDocTypes,
} from 'services/customer';
import { Customer } from 'components/interface/Customer';
import CustomerHeader from '../customerHeader/index';
import MarketingPreferencePopup from './marketingPreferencePopup';
import styled from 'styled-components';
import { joinValues } from 'utils/util';
import {
  NOTIFICATION_TYPE,
  DATE_FORMAT,
  ROUTE_CONFIG,
  REGEX_POSTCODE,
  FNS_DATE_FORMAT,
} from 'globalConstants';
import {
  PERSONAL_INFORMATION_HEADER,
  CONTANT_DETAILS_HEADER,
  ADDRESS_DETAILS_HEADER,
  MARKETING_PREFERENCES,
  MARKETING_PREFERENCE_HEADER,
  LETTER_PREFERENCES,
  TITLES,
  ERROR_MESSAGE_ON_UPDATE,
  SUCCESS_MESSAGE_ON_UPDATE,
  CUSTOMER_HEADER_LABEL,
  SUCCESS_MESSAGE_ON_UPLOAD_IMAGE,
  IMAGE_UPLOADING_TEXT,
  CHANGE_PHOTO,
  SORRY_MESSAGE,
  ENTER_ADDRESS_MANUALLY,
  SUCCESS_MESSAGE_ON_UPLOAD_DOC,
  DOCUMENT_TYPE_OTHER,
  DOCUMENT_TYPE_USE_CAMERA,
  FIELD_LABELS,
  OCR_MESSAGE,
  DEFAULT_SCAN_MESSAGE,
  ALERT_MESSAGES,
  THIRD_PARTY_DETAILS_ADDED,
} from '../constants';
import { CUSTOMER_PROFILE_REQUEST } from 'action_creators/customer';
import { validateMessages } from './validationConfig';
import Webcam, { IMAGE_CATEGORY } from 'components/webcam';
import ImageLoader from 'components/common/ImageLoader';
import { actions } from 'redux/reducers/customer';
import { actions as basketActions } from 'redux/reducers/basket';
import GdprMessagePopup from '../GdprMessagePopup';
import GdprDeclinePopup from '../GdprDeclinePopup';
import DocumentCapture from './documentCapture';
import { CustomerValidation, VerifyResult } from './customerValidation';
import { imageFromScanner } from 'services/user';
import * as customerActions from 'action_creators/customer';
import moment from 'moment';
import { getMatrixStoreId } from 'utils/cookie';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import Select from 'components/common/Select';
import { validateCustomer } from 'services/customer';
import {
  cacheCustomerStatus,
  clearCacheByCacheKey,
  cacheStaticData,
} from 'utils/cacheServices';

import UpdatedCustomerInformation from './customerEditProfilePopup';
import PhoneValidation from './phoneValidation';
import ScannerPopover from './scannerPopover';
import DatePickerMUI from 'components/common/DatePickerMUI';
import '../customerSummary/index.less';

import AddThirdPartyAssistant from './addThirdPartyAssistant';

const StyledRow = styled(Row)`
  margin-block-end: 10px;
  margin-top: 10px;
`;

const StyledFooterRow = styled(Row)`
  margin-block-end: 15px;
`;

const StyledFullHr = styled.hr`
  border: 0;
  height: 1px;
  margin: 0;
  background-color: var(--linkwater);
  margin-inline: -30px;
  margin-bottom: 15px;
`;

const StyledLeftRow = styled(Row)`
  justify-content: flex-start;
  column-gap: 20px;
`;

const StyledRightRow = styled(Row)`
  justify-content: flex-end;
  column-gap: 20px;
`;

const StyledCol = styled(Col)`
  padding: 0 0 8px;
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
`;

const StyledCheckboxCol = styled(Col)`
  padding: 0 20px 0 0;
`;

const StyledCustomError = styled.div`
  position: absolute;
  top: 50px;
  width: 200px;
`;

const StyledContainer = styled.div`
  margin-block-start: 30px;

  & .autocomplete-input-selection {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector input {
      cursor: text;
      margin: 5px 0;
    }
  }
`;

const StyledCaptureRow = styled(Row)`
  margin-top: 18px;
`;

const StyledLinkButton = styled(LinkButton)`
  margin: 10px 10px 10px 30px;
`;

const StyledChangePhoto = styled(Row)`
  margin: 0px 32px 0px 0px;
  span {
    font-weight: 500;
  }
`;

const StyledProgressRow = styled(Row)`
  width: 50%;
  margin-top: 10px;
`;

const StyledCamIcon = styled(Row)`
  position: absolute;
  margin: -28px 72px;
  z-index: 1;
  cursor: pointer;
`;

const StyledImageError = styled.div`
  margin-top: 25px;
`;

const StyledAddTpaRow = styled(Row)`
  margin: 30px 0px 30px 0px;
`;

interface Address {
  property: string;
  street: string;
  area: string;
  town: string;
  county: string;
  postcode: string;
  house: string;
  country: string;
  id: number;
}

interface AddressOptions {
  value: number;
  label: string;
}

const customerDefaultValues: Customer = {
  customerId: null,
  storeId: getMatrixStoreId(),
  kycStatus: {},
  title: '',
  firstNames: '',
  middleName: '',
  idExpiryDate: '',
  countryId: '',
  surname: '',
  houseName: '',
  preferredName: '',
  preferredLanguageId: null,
  dateOfBirth: '',
  property: '',
  street: '',
  area: '',
  town: '',
  county: '',
  postcode: '',
  emailAddress: '',
  mobile: null,
  telephone: '',
  idShownDate: '2020-01-15',
  marketingEmailAccepted: false,
  marketingPostAccepted: false,
  marketingSmsAccepted: false,
  marketingPhoneAccepted: null,
  marketingOptOut: null,
  prefersEmailContact: false,
  isRegisteredLoans: false,
  isRegisteredTpc: false,
  isRegisteredPledge: false,
  documentTypeId: null,
  customerDocuments: [],
  isMobileVerified: false,
};

function insertAfter(referenceNode: any, newNode: any) {
  referenceNode?.parentNode?.insertBefore(newNode, referenceNode.nextSibling);
}

const documentObject = {
  documentTypeId: '',
  idCaptureDate: '',
  image: '',
  isScanning: false,
};

enum CustomerIdKeys {
  id = 'documentTypeId',
  captureDate = 'idCaptureDate',
  image = 'image',
  isScanning = 'isScanning',
}

const tpaListObject = {
  nomineeId: '',
  storeId: '',
  firstName: '',
  middleName: '',
  surname: '',
  dateOfBirth: '',
  relationShip: '',
  countryId: '',
  postcode: '',
  property: '',
  houseName: '',
  street: '',
  area: '',
  town: '',
  county: '',
  emailAddress: '',
  mobile: '',
  rowSaved: false,
  note: '',
};

const NewCustomer = () => {
  const navigate = useNavigate();

  const { customerId, mode } = useParams<any>(); //if mode is edit then please use id for fetch details
  const isEditMode = mode === 'edit';
  const [form] = Form.useForm();
  const [preferredLanguages, setPreferredLanguages] = useState([]);
  const [countries, setCountries] = useState([]);
  const [addressList, setAddressesList] = useState<Address[]>([]);
  const [addressOptions, setAddressOptions] = useState<any>([]);
  const [valueArray] = useState<any>([]);
  const [isLanguageLoading, setIsLanguageLoading] = useState<boolean>(false);
  const [searchInProgress, setSearchInProgress] = useState<boolean>(false);
  const [autoFill, setAutoFill] = useState<boolean>(false);
  const [marketingPopupVisible, setMarketingPopupVisible] = useState(false);
  const [customer, setCustomer] = useState<Customer>(customerDefaultValues);
  const [isPostCodeSelected, setIsPostCodeSelected] = useState<boolean>(false);
  const [isPostCodeEntered, setIsPostCodeEntered] = useState<boolean>(false);
  const [isSaveButtonClicked, setIsSaveButtonClicked] =
    useState<boolean>(false);

  const [isManualButtonClicked, setIsManualButtonClicked] =
    useState<boolean>(false);

  const [isManualAddressVisible, setIsManualAddressVisible] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCamVisible, setIsCamVisible] = useState<boolean>(false);
  const [isRefreshCam, setIsRefreshCam] = useState<boolean>(false);
  const [isImageCaptured, setIsImageCaptured] = useState<boolean>(false);
  const [isDocCaptured, setIsDocCaptured] = useState<boolean>(false);
  const [isPreferenceErrorVisible, setIsPreferenceErrorVisible] =
    useState<boolean>(false);
  const [isImageShaked, setIsImageShaked] = useState<boolean>(false);
  const [docTypes, setDocTypes] = useState<Array<any>>([]);
  const [docTypeId] = useState<number>();
  const [title, setTitle] = useState<string>();
  const [isAddressValidated, setAddressValidated] = useState<boolean>(false);
  const [isAddressVerified, setAddressVerified] = useState<boolean>(false);
  const [selectedDocRowIndex, setSelectedDocRowIndex] = useState<any>();
  const [isScanning, setIsScanning] = useState<boolean>(false);
  const [tpaDocTypes, setTpaDocTypes] = useState<Array<any>>([]);

  const dispatch = useDispatch();
  const {
    customer: customerResult,
    message,
    isImageUploaded,
    loading,
    isImageUploading,
    isDocUploaded,
    imageData,
    error,
    isGdprAccepted,
    isDocUploading,
    docData,
    newCustomerDocs,
    isCustFromHeader,
    isOCRLoading,
    resumeJourneyUrl,
    isRedirectToEditPage,
    isCustomerDocUploading,
    isCustomerImageUpdated,
    tpaList,
    isTpaDetailsAdded,
  } = useSelector((state: any) => state.customer);

  const { selectedStore } = useSelector((state: any) => state.user);
  const [isGdprMessageVisible, setGdprMessageVisible] =
    useState<boolean>(false);
  const [isGdprDeclineVisible, setGdprDeclineVisible] =
    useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const [noResult, setNoResult] = useState<boolean>(false);
  const [isDocumentCaptureVisible, setDocumentCaptureVisible] =
    useState<boolean>(false);
  const [phoneValidationError, setPhoneValidationError] = useState({});
  const [isRequiredPin, setRequirePin] = useState<boolean>(false);
  const [oldCustomerDocs, setOldCustomerDocs] = useState<Array<any>>([]);
  const [viewAllPreviousIds, setViewAllPreviousIds] = useState<boolean>(false);
  const [isAddAnotherIdEnable, setIsAddAnotherIdEnable] =
    useState<boolean>(false);
  const [selectedImageData, setSelectedImageData] = useState<any>('');
  const [selectedDocType, setSelectedDocType] = useState<any>(99);
  const [initalDate, setInitialDate] = useState<any>(null);

  const [isEnableAddTpa, setIsEnableAddTpa] = useState<boolean>(true);

  useEffect(() => {
    loadPreferredLanguages();
    loadCountries();
    loadDocTypes();
    loadTpaDocTypes();
    if (isEditMode) {
      dispatch({
        type: CUSTOMER_PROFILE_REQUEST,
        payload: { customerId },
      });
      dispatch(actions.setGdprAcceptance(false));
    } else {
      dispatch(actions.setNewCustomerDocs([documentObject]));
    }
    if (!isGdprAccepted && !isEditMode) {
      setGdprMessageVisible(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newTpaList = tpaList?.filter((row: any) => {
      return row?.rowSaved === false;
    });
    if (newTpaList?.length > 0) {
      setIsEnableAddTpa(false);
    } else {
      setIsEnableAddTpa(true);
    }
  }, [tpaList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEmpty(customerResult)) {
      loadCustomer();
    }
  }, [customerResult]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isCustomerImageUpdated) valueArray.push(FIELD_LABELS.customerImage);
    if (isTpaDetailsAdded) valueArray.push(THIRD_PARTY_DETAILS_ADDED);
  }, [isCustomerImageUpdated, isTpaDetailsAdded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isRedirectToEditPage) {
      navigate(
        `/${ROUTE_CONFIG.CUSTOMER}/${customerResult.customerId}/${ROUTE_CONFIG.EDIT}`
      );
    }
    if (isImageUploaded && message === 200) {
      setIsCamVisible(false);
      notification.success({
        message: SUCCESS_MESSAGE_ON_UPLOAD_IMAGE.message,
        description: SUCCESS_MESSAGE_ON_UPLOAD_IMAGE.description,
        duration: 5,
      });
    }
    if (!isDocUploading && isDocUploaded && message === 200) {
      setDocumentCaptureVisible(false);
      notification.success({
        message: SUCCESS_MESSAGE_ON_UPLOAD_DOC.message,
        description: SUCCESS_MESSAGE_ON_UPLOAD_DOC.description,
        duration: 5,
      });
    }
    if (!isEmpty(error)) {
      notification.error({
        message: error?.message,
        description: error?.description,
        duration: 10,
      });
      setIsCamVisible(false);
      setDocumentCaptureVisible(false);
    }
  }, [
    isImageUploaded,
    isDocUploading,
    isDocUploaded,
    message,
    error,
    isRedirectToEditPage,
    customerResult,
    navigate,
  ]);

  useEffect(() => {
    if (addressOptions.length > 0 && !postCodeFlyoutButton()) {
      const flyoutButton = document.createElement('button');
      flyoutButton.innerHTML = ENTER_ADDRESS_MANUALLY;
      // flyoutButton.innerHTML += (
      //   <HelpPopoverInfo
      //     linkedID="CUSTOMERNEW_MANUALADDRESS"
      //     position="rightTop"
      //   ></HelpPopoverInfo>
      // );
      flyoutButton.id = 'manualbutton';
      flyoutButton.className = 'click-here-result extra-flyout-button';
      flyoutButton.onclick = onManualAddressClick;
      const postCodeList =
        document.querySelector('#postcode_list')?.nextElementSibling;
      insertAfter(postCodeList, flyoutButton);
    }
  }, [addressOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      (isImageCaptured && !customer?.customerId && imageData) ||
      (isImageCaptured && customer?.customerId && imageData)
    ) {
      setIsImageCaptured(false);
      setIsImageShaked(false);
    }
    if (newCustomerDocs[newCustomerDocs?.length - 1]?.idCaptureDate !== '') {
      setIsAddAnotherIdEnable(true);
    } else {
      setIsAddAnotherIdEnable(false);
    }
    if (
      (isDocCaptured &&
        !customer?.customerId &&
        newCustomerDocs[0][CustomerIdKeys.image]) ||
      (isDocCaptured && customer?.customerId && docData) ||
      (isDocCaptured &&
        customer?.customerId &&
        customerResult?.customerDocuments?.length === 0 &&
        newCustomerDocs[0][CustomerIdKeys.image])
    ) {
      setIsDocCaptured(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isImageCaptured,
    isDocCaptured,
    imageData,
    docData,
    newCustomerDocs,
    customer,
  ]);

  useEffect(() => {
    setDocumentCaptureVisible(false);
  }, [docData]);

  useEffect(() => {
    if (noResult) {
      form.setFields([
        {
          name: 'postcode',
          errors: [],
        },
      ]);
    }
  }, [noResult]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadCustomer = () => {
    const dateOfBirth = customerResult?.dateOfBirth
      ? moment(customerResult?.dateOfBirth)
      : '';

    const customerData: Customer = {
      ...customerResult,
      dateOfBirth: dateOfBirth,
      idExpiryDate: customerResult?.idExpiryDate
        ? moment(customerResult?.idExpiryDate)
        : defaultExpiryDateForNeoCustomer(customerResult),
      title: getCustomerTitle(customerResult?.title)
        ? getCustomerTitle(customerResult?.title)
        : title,
      documentTypeId:
        customerResult?.customerDocuments?.length > 0
          ? [...customerResult?.customerDocuments]?.pop()?.documentTypeId
          : docTypeId,
    };

    setInitialDate(dateOfBirth);
    setCustomer(customerData);
    setAutoFill(true);
    form.setFieldsValue({ ...customerData });
    setIsManualAddressVisible(false);
    setOldCustomerDocs(customerResult?.customerDocuments || []);

    if (
      customerResult?.customerDocuments?.length === 0 &&
      newCustomerDocs?.length === 0
    )
      dispatch(actions.setNewCustomerDocs([documentObject]));

    if (customerData?.documentTypeId) {
      dispatch(actions.setDocumentType(customerData.documentTypeId));
    }

    if (
      customerResult?.customerNominee &&
      customerResult?.customerNominee?.length > 0
    ) {
      dispatch(actions.setTpaList([...customerResult?.customerNominee]));
    }
  };
  const loadPreferredLanguages = async () => {
    try {
      setIsLanguageLoading(true);
      const response = await cacheStaticData(getPreferredLangauge);
      if (response?.data) {
        setPreferredLanguages(response?.data?.preferredLanguages);
        setIsLanguageLoading(false);
      }
    } catch (e: any) {
      setIsLanguageLoading(false);
    }
  };

  const loadCountries = async () => {
    try {
      const response = await cacheStaticData(getCountries);
      if (response?.data) setCountries(response?.data);
    } catch (e: any) {
      console.log(e);
    }
  };

  const loadDocTypes = useCallback(async () => {
    try {
      const response = await cacheStaticData(getDocTypes);
      if (response?.data) {
        const docType = response?.data;
        docType.push({ id: DOCUMENT_TYPE_USE_CAMERA, description: 'Camera' });
        setDocTypes(docType);
      }
    } catch (e: any) {
      console.log(e);
    }
  }, [setDocTypes]);

  const loadTpaDocTypes = useCallback(async () => {
    try {
      const response = await cacheStaticData(getTpaDocTypes);
      if (response?.data) {
        setTpaDocTypes(response?.data);
      }
    } catch (e) {
      console.log(e);
    }
  }, [setTpaDocTypes]);

  const excludeTpaFormFields = (data: any) => {
    return Object.keys(data)
      .filter((key) => !key.includes('Tpa'))
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: data[key],
        });
      }, {});
  };

  const onSubmit = (data: any) => {
    const newData = excludeTpaFormFields(data);

    if (!customer?.customerId && !imageData) {
      setIsImageCaptured(true);
      setIsImageShaked(true);
      setTimeout(() => {
        setIsImageShaked(false);
      }, 2000);
      return;
    }

    if (!customer?.customerId && !newCustomerDocs[0][CustomerIdKeys.image]) {
      setIsDocCaptured(true);
      return;
    }

    setMarketingPopupVisible(true);
    const customerData: Customer = {
      ...customerDefaultValues,
      ...newData,
      firstNames: data?.firstNames?.trim(),
      middleName: data?.middleName?.trim(),
      surname: data?.surname?.trim(),
      storeId: selectedStore?.storeId,
      dateOfBirth: moment(data.dateOfBirth).format('YYYY-MM-DD'),
      idExpiryDate: null,
      isMobileVerified: form.getFieldValue('isMobileVerified'),
      customerThirdParty: tpaList,
    };

    setCustomer(customerData);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); //Need this console for checking error
    const isPreferenceValidated = validatePreference();
    const isCustomerImageNotExist = document.querySelector(
      '.is-customer-image-not-exist.ant-image-error'
    );
    if ((!customer?.customerId && !imageData) || isCustomerImageNotExist) {
      setIsImageCaptured(true);
      setIsImageShaked(true);
      setTimeout(() => {
        setIsImageShaked(false);
      }, 2000);
    }
    if (
      (!customer?.customerId && !newCustomerDocs[0][CustomerIdKeys.image]) ||
      (customer?.customerId && customer?.customerDocuments?.length === 0)
    ) {
      setIsDocCaptured(true);
    }

    if (!isPreferenceValidated) setIsPreferenceErrorVisible(true);
    setIsSaveButtonClicked(false);
  };

  const letterPreferenceOnChange = (e: any) => {
    form.setFieldsValue({ prefersEmailContact: e.target.value });
  };

  const onMarketingPreferenceChange = (e: any) => {
    if (e.target.id === 'marketingOptOut') {
      form.setFieldsValue({
        marketingEmailAccepted: false,
        marketingPostAccepted: false,
        marketingSmsAccepted: false,
        marketingPhoneAccepted: false,
      });
    } else {
      if (form.getFieldValue('marketingOptOut'))
        form.setFieldsValue({ marketingOptOut: false });
    }

    setIsPreferenceErrorVisible(false);
  };

  const onResetClick = () => {
    if (isEditMode) {
      loadCustomer();
    } else {
      form.resetFields();
      dispatch(actions.setNewCustomerDocs([documentObject]));

      setAutoFill(false);
      reloadCam();
      setIsCamVisible(false);
    }
    setIsManualAddressVisible(false);
  };

  const onExitClick = () => {
    dispatch(actions.clearAll({}));
    dispatch(basketActions.clearItems()); //clear basket
    navigate('/');
  };

  const onBackToJourney = () => {
    dispatch(
      actions.setCustomerFromHeader({
        isCustFromHeader: false,
      })
    );
    navigate(resumeJourneyUrl);
  };

  const onSearch = (value: string) => {
    postCodeFlyoutButton();
    setAddressOptions([]);
    setIsPostCodeSelected(false);
    setIsManualButtonClicked(false);
    if (value) {
      getAddresses(value, (data: AddressOptions) => {
        setAddressOptions(data);
      });
      setInputValue(value);
      setIsPostCodeEntered(true);
    } else {
      setIsPostCodeEntered(false);
      setAddressOptions([]);
      setAddressesList([]);
    }
  };

  const onSelect = (value: string) => {
    const { street, town, property, county, postcode, house, country, area } =
      addressList.filter((item: any) => item.id === value)[0];
    setAutoFill(true);
    setIsManualButtonClicked(false);
    form.setFieldsValue({
      street,
      town,
      property,
      county,
      houseName: house,
      area,
      country,
      postcode: postcode?.trim(),
    });

    if (postcode) {
      setIsPostCodeSelected(true);
    } else {
      setIsPostCodeSelected(false);
    }
  };

  const validatePostCode = (e: any) => {
    if (form.isFieldTouched('postcode') && e?.target?.id === 'manualbutton') {
      //Just for break the checking conditions
    } else if (
      !isManualButtonClicked &&
      e?.target?.id === 'postcode' &&
      form.isFieldTouched('postcode') &&
      !isPostCodeSelected &&
      isPostCodeEntered &&
      e?.relatedTarget?.id !== 'manualbutton'
    ) {
      form.setFieldsValue({
        postcode: '',
      });
      form.setFields([
        {
          name: 'postcode',
          errors: ['Invalid postcode!'] || [],
        },
      ]);
    }
  };

  const getAddresses = async (searchValue: string, callback: any) => {
    if (!REGEX_POSTCODE.test(searchValue)) {
      return;
    }
    try {
      setSearchInProgress(true);
      const response = await getAddress(searchValue);
      if (response) {
        const result: AddressOptions[] = [];
        response.data.addresses.forEach((address: Address) => {
          const text = joinValues([
            address.property,
            address?.house,
            address.street,
            address.town,
            address.county,
            address.postcode,
          ]);

          result.push({
            value: address.id,
            label: text,
          });
        });

        result.sort((a, b) =>
          a.label.localeCompare(b.label, 'en', { numeric: true })
        );

        callback(result);
        setAddressesList(response.data.addresses);
        setSearchInProgress(false);
        if (response.data.addresses.length === 0) {
          setNoResult(true);
        }
      }
    } catch (e: any) {
      if (e?.response?.status === 404) {
        setNoResult(true);
        setAddressesList([]);
      } else if (e?.response?.status === 400) {
        form.setFields([
          {
            name: 'postcode',
            errors: ['Invalid postcode'] || [],
          },
        ]);
      }
      setSearchInProgress(false);
    }
  };

  const getCustomerTitle = (title: string): string => {
    let customerTitle =
      title?.charAt(0).toUpperCase() + title?.toLowerCase().slice(1);
    customerTitle = customerTitle && customerTitle?.replaceAll(/\W/g, ''); //replace all special characters
    return TITLES.includes(customerTitle) ? customerTitle : '';
  };

  const onSubmitEditMode = async (data: Customer) => {
    if (
      customerResult?.customerDocuments?.length === 0 &&
      !newCustomerDocs[0][CustomerIdKeys.image]
    ) {
      setIsDocCaptured(true);
      return;
    }

    const isPreferenceValidated = validatePreference();
    if (!isPreferenceValidated) {
      setIsPreferenceErrorVisible(true);
      setIsSaveButtonClicked(false);
      return;
    }

    const newData = excludeTpaFormFields(data);
    const customerData: Customer = {
      ...customerDefaultValues,
      ...newData,
      firstNames: data?.firstNames?.trim(),
      middleName: data?.middleName?.trim(),
      surname: data?.surname?.trim(),
      dateOfBirth: data?.dateOfBirth
        ? moment(data?.dateOfBirth).format('YYYY-MM-DD')
        : '',
      idExpiryDate: null,
      telephone: data.telephone || JSON.parse('null'),
      isMobileVerified: form.getFieldValue('isMobileVerified'),
      customerThirdParty: tpaList,
    };

    try {
      setIsLoading(true);
      const response = await putCustomer(customerId, customerData);
      if (response?.data) {
        setIsSaveButtonClicked(true);
        setIsLoading(false);
        openNotificationWithIcon(NOTIFICATION_TYPE.SUCCESS);
        Array.from(new Set(valueArray)).length === 0 && onSaveEditDetails();

        clearCacheByCacheKey(cacheCustomerStatus, customerId);
        dispatch(actions.clearCustomerDocs());
      }
    } catch (e: any) {
      setIsLoading(false);
      setIsSaveButtonClicked(false);
      Object.keys(e?.response?.data?.errors).forEach((item, index) => {
        const key = Object.keys(e?.response?.data?.errors)[index];
        form.setFields([
          {
            name: `${key.toLowerCase()}`,
            errors: e?.response?.data?.errors[item] || [],
          },
        ]);
      });
      openNotificationWithIcon(NOTIFICATION_TYPE.ERROR);
    }
  };

  const onSaveEditDetails = () => {
    navigate(`/${ROUTE_CONFIG.CUSTOMER}/${ROUTE_CONFIG.SUMMARY}/${customerId}`);
  };

  const validatePreference = () => {
    if (
      !(
        form.getFieldValue('marketingEmailAccepted') ||
        form.getFieldValue('marketingPostAccepted') ||
        form.getFieldValue('marketingSmsAccepted') ||
        form.getFieldValue('marketingPhoneAccepted') ||
        form.getFieldValue('marketingOptOut')
      )
    )
      return false;
    return true;
  };

  const openNotificationWithIcon = (type: any) => {
    if (type === 'error') {
      notification.error({
        message: ERROR_MESSAGE_ON_UPDATE.message,
        description: ERROR_MESSAGE_ON_UPDATE.description,
        duration: 5,
      });
    } else {
      notification.success({
        message: SUCCESS_MESSAGE_ON_UPDATE.message,
        description: SUCCESS_MESSAGE_ON_UPDATE.description,
        duration: 5,
      });
    }
  };

  const onManualAddressClick = () => {
    setIsPostCodeSelected(false);
    setIsManualButtonClicked(true);
    setSearchInProgress(false);
    setAutoFill(true);
    setNoResult(false);
    setAddressOptions([]);
    postCodeFlyoutButton();
    form.setFieldsValue({
      street: '',
      town: '',
      property: '',
      county: '',
      houseName: '',
      area: '',
    });
  };

  const postCodeFlyoutButton = () => {
    const extraFlyoutButton = document.querySelector('.extra-flyout-button');
    if (extraFlyoutButton) {
      extraFlyoutButton.remove();
    }
    return extraFlyoutButton;
  };

  const showCam = () => {
    setIsCamVisible(true);
  };

  const reloadCam = () => {
    setIsCamVisible(true);
    dispatch(actions.clearUploadImage());

    setIsRefreshCam(true);
    setTimeout(() => {
      setIsRefreshCam(false);
    }, 100);
  };

  const changePhoto = () => {
    return (
      <StyledLinkButton
        type="link"
        onClick={() => reloadCam()}
        title="Change Photo"
      >
        {CHANGE_PHOTO}
      </StyledLinkButton>
    );
  };

  const resetSearch = () => {
    navigate('/');
  };

  const noResultRow = () => {
    return (
      <div className="no-result-container">
        <div className="no-results">{`${SORRY_MESSAGE}`}</div>
        <button
          className="click-here-result"
          id="manualbutton-no-result"
          onClick={onManualAddressClick}
        >
          {ENTER_ADDRESS_MANUALLY}
        </button>
      </div>
    );
  };

  const OCRSupportedlist = [
    { id: 1, type: IMAGE_CATEGORY.DOCUMENT_DRIVING_LICENCE },
    { id: 2, type: IMAGE_CATEGORY.DOCUMENT_PASSPORT },
  ];

  const scanDocument = async (
    index: any,
    docsObj = newCustomerDocs,
    isTpa = false
  ) => {
    //setDocumentCaptureVisible(true); //MP-4910-Enable when need cam test for document
    const documentsCopy = JSON.parse(JSON.stringify(docsObj));
    documentsCopy[index][CustomerIdKeys.isScanning] = true;
    if (isTpa) {
      dispatch(actions.setThirdPartyAssistantDocs(documentsCopy));
    } else {
      dispatch(actions.setNewCustomerDocs(documentsCopy));
    }

    setIsScanning(true);
    const img = await imageFromScanner();
    const docTypeId = docsObj[index][CustomerIdKeys.id];
    setIsScanning(false);

    if (img === undefined) {
      return;
    }
    const data = `data:image/png;base64, ${img}`;

    if (OCRSupportedlist.filter((x) => x.id === docTypeId).length > 0) {
      const ocrType = OCRSupportedlist.filter((x) => x.id === docTypeId)[0]
        .type;

      dispatch({
        type: isTpa
          ? customerActions.UPLOAD_TPA_DOCUMENT_REQUEST
          : customerActions.UPLOAD_CUSTOMER_DOCUMENT_REQUEST,
        payload: { image: data, ocr: ocrType, index },
      });
    } else {
      dispatch({
        type: isTpa
          ? customerActions.UPLOAD_TPA_DOCUMENT_REQUEST
          : customerActions.UPLOAD_CUSTOMER_DOCUMENT_REQUEST,
        payload: { image: data, index },
      });
    }
  };

  const onDocumentCaptureClick = async (index: any) => {
    if (selectedDocType === DOCUMENT_TYPE_USE_CAMERA) {
      setSelectedDocRowIndex(index);
      setDocumentCaptureVisible(true);
    } else {
      scanDocument(index);
    }
  };

  const onCancelClick = () => {
    setDocumentCaptureVisible(false);
  };

  const onTitleChange = (value: string) => {
    setTitle(value);
  };

  const onDocTypeChange = (value: number, index: number) => {
    setSelectedDocType(value);
    if (value === DOCUMENT_TYPE_USE_CAMERA) {
      setSelectedDocRowIndex(index);
      setDocumentCaptureVisible(true);
    }

    const documentsCopy = JSON.parse(JSON.stringify(newCustomerDocs));
    documentsCopy[index][CustomerIdKeys.id] = value;
    dispatch(actions.setNewCustomerDocs(documentsCopy));
  };

  //MP:5089 - removing expiry date concept - documents will not be deleted
  // const presetExpiryDate = (value: number) => {
  //   var dte = form.getFieldValue('idExpiryDate');
  //   if (!!dte) return;

  //   var doc = docTypes.filter((x: any) => x.id === value);
  //   if (doc[0]?.description[0] === 'B') {
  //     form.setFieldsValue({
  //       idExpiryDate: moment().add(1, 'y'),
  //     });
  //   } else {
  //     form.setFieldsValue({ idExpiryDate: '' });
  //   }
  // };

  const defaultExpiryDateForNeoCustomer = (customerResult: any) => {
    if (customerResult?.customerDocuments?.length > 0) {
      return moment(customerResult.updatedOn).add(1, 'y');
    } else {
      return '';
    }
  };

  const CallValidateRequest = (customer: any) => {
    return {
      Title: customer.title || '',
      Firstname: customer.firstNames || '',
      Surname: customer.surname || '',
      Dateofbirth:
        new Date(customer.dateOfBirth).toLocaleDateString('en-UK') || '',
      Phonenumber: customer.mobile || '',
      Emailaddress: customer.emailAddress || '',
      Appartament: '',
      Buildingnumber: customer.property,
      Buildingname: customer.houseName,
      Address: customer.street,
      Town: customer.town,
      Postcode: customer.postcode,
    };
  };

  const onCallValidateClick = async () => {
    const customer = form.getFieldsValue();
    const params = CallValidateRequest(customer);

    const validateResponse = await validateCustomer(params);
    const result = validateResponse?.data.identityResult;

    if (result === 'Pass') {
      setAddressVerified(true);
    } else {
      setAddressVerified(false);
    }
    setAddressValidated(true);
  };

  const isNameOrAddress = (o: any) => {
    const keys = Object.keys(o);
    if (keys.length === 0) {
      return;
    }
    const firstkey = keys[0];
    const checkerlist = [
      'title',
      'firstNames',
      'surname',
      'dateOfBirth',
      'property',
      'houseName',
      'street',
      'town',
      'postcode',
      'area',
      'county',
    ];
    return checkerlist.includes(firstkey);
  };

  const onExistingFieldUpdate = (e: any) => {
    const updatedFieldLabel = findValueByKey(FIELD_LABELS, e[0]);
    if (
      updatedFieldLabel !== FIELD_LABELS.dateOfBirth ||
      form.getFieldValue('dateOfBirth') !== customer.dateOfBirth
    ) {
      valueArray.push(updatedFieldLabel);
    }
  };

  const handleValidationSMSRequest = async () => {
    const mobile = form.getFieldValue('mobile');
    if (mobile) {
      return await verfiyMobileNumberSMS({
        mobileNumber: mobile,
      });
    } else {
      return;
    }
  };

  const handleValidationVerifyPinRequest = async (requestId: string) => {
    return await verfiyMobileNumberVerifyCode({
      verificationPinNumber: form.getFieldValue('pin'),
      requestId: requestId,
    });
  };

  const onDocumentCapture = (data: any) => {
    setSelectedImageData(data);
  };

  const onAddDocumentImage = () => {
    const documentsCopy = JSON.parse(JSON.stringify(newCustomerDocs));
    documentsCopy[selectedDocRowIndex][CustomerIdKeys.id] = DOCUMENT_TYPE_OTHER;
    dispatch(actions.setNewCustomerDocs(documentsCopy));

    dispatch({
      type: customerActions.UPLOAD_CUSTOMER_DOCUMENT_REQUEST,
      payload: { image: selectedImageData, index: selectedDocRowIndex },
    });
    onCancelClick();
  };

  const renderOldDocs = () => {
    const documents = viewAllPreviousIds
      ? oldCustomerDocs
      : oldCustomerDocs?.slice(0, 1);

    return documents?.map((doc: any, index: any) => {
      const dateOfCapture =
        doc?.dateOfCapture &&
        format(new Date(doc?.dateOfCapture), FNS_DATE_FORMAT);

      return (
        <StyledRow key={index} id={index} align="middle" gutter={32}>
          <Col span={12}>
            <Form.Item label={'ID Type'} className="label-control">
              <Select
                disabled
                value={doc?.documentTypeId}
                options={docTypes?.map((item: any) => {
                  return {
                    value: item.id,
                    label: item.description,
                  };
                })}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Capture Date" className="label-control">
              <Input disabled value={dateOfCapture} className="input-control" />
            </Form.Item>
          </Col>
          <Col span={8} style={{ padding: 0 }}>
            <StyledCaptureRow align="middle">
              <Col span={4}>
                <ImageLoader
                  imageUrl={`/api/customers/images/${doc?.customerDocumentId}/doc?isThumnails=false`}
                  classes={'mx-portrait-avatar'}
                  fallbackImg={'no_image_thumbnail.svg'}
                  preview
                />
              </Col>
            </StyledCaptureRow>
          </Col>
        </StyledRow>
      );
    });
  };

  const onAddAnotherIdClick = () => {
    dispatch(actions.setNewCustomerDocs([...newCustomerDocs, documentObject]));
  };

  const onDateChange = (selectedDate: any) => {
    form.setFieldsValue({ dateOfBirth: selectedDate });
  };

  const shakedImage = isImageShaked ? 'shaked-image' : '';

  const onAddTpaClick = () => {
    dispatch(actions.setTpaList([...tpaList, tpaListObject]));
  };

  return (
    <>
      <CustomerHeader
        title={
          isEditMode
            ? CUSTOMER_HEADER_LABEL.EDIT_PROFILE
            : CUSTOMER_HEADER_LABEL.ADD_NEW_CUSTOMER
        }
        buttonsOption={isEditMode}
        gdprAccepted={isGdprAccepted}
        helpSection={isEditMode ? 'CUSTOMEREDIT' : 'CUSTOMERNEW'}
      />
      <Layout className="form-control-box new-customer-form">
        {loading ? (
          <Row align="top" justify="center">
            <Spin />
          </Row>
        ) : (
          <Spin spinning={isOCRLoading || isCustomerDocUploading}>
            <Form
              onFinish={!isEditMode ? onSubmit : onSubmitEditMode}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              form={form}
              initialValues={{
                preferredLanguageId: 16,
                prefersEmailContact: true,
                countryId: 76,
                countryIdTpa: 76,
              }}
              validateMessages={validateMessages}
              onValuesChange={async (changedValues) => {
                isEditMode && onExistingFieldUpdate(Object.keys(changedValues));
                isNameOrAddress(changedValues) &&
                  isAddressValidated &&
                  setAddressValidated(false);
              }}
            >
              <Row align="top">
                <Col span={6}>
                  <Row justify="start">
                    <Form.Item
                      label=""
                      name="customerImage"
                      className="label-control"
                    >
                      {!isCamVisible && (
                        <ImageLoader
                          customerId={customer?.customerId}
                          imageUrl={
                            customer?.customerId &&
                            `/api/customers/images/${customer?.customerId}?isThumnails=false`
                          }
                          classes={
                            customer?.customerId
                              ? `mx-larger-avatar is-customer-image-not-exist larger-avatar-width`
                              : `${shakedImage}`
                          }
                          tip={customer?.customerId && 'Loading...'}
                          isCaching={false}
                          fallbackImg={
                            customer?.customerId
                              ? 'customer-pic.svg'
                              : 'default-customer-pic.svg'
                          }
                          preview
                        />
                      )}
                    </Form.Item>

                    {isCamVisible && (
                      <Webcam
                        type={IMAGE_CATEGORY.CUSTOMER_PHOTO}
                        isRetake={isRefreshCam}
                      />
                    )}
                  </Row>
                  {!isCamVisible && !customerResult?.customerId && (
                    <StyledCamIcon justify="start">
                      <img
                        alt="camera"
                        onClick={showCam}
                        src={
                          require(`../../../assets/images/camera-icon.svg`)
                            .default
                        }
                      />
                    </StyledCamIcon>
                  )}
                  {!customerResult?.customerId && imageData && (
                    <StyledChangePhoto
                      justify="start"
                      data-testid="change-guest-photo"
                    >
                      {changePhoto()}
                    </StyledChangePhoto>
                  )}
                  {isImageCaptured && (
                    <StyledImageError className="ant-form-item-explain ant-form-item-explain-error">
                      <div role="alert" className="alert">
                        {ALERT_MESSAGES.PHOTO}
                      </div>
                    </StyledImageError>
                  )}
                  {!isCamVisible && customerResult?.customerId && (
                    <StyledChangePhoto
                      justify="start"
                      data-testid="change-customer-photo"
                    >
                      {changePhoto()}
                    </StyledChangePhoto>
                  )}
                  {isImageUploading && (
                    <>
                      <StyledProgressRow justify="start">
                        <Progress percent={30} size="small" />
                      </StyledProgressRow>
                      <Row justify="start">{IMAGE_UPLOADING_TEXT}</Row>
                    </>
                  )}
                </Col>
                <Col span={18}>
                  <div>
                    <h3 className="form-h3-title">
                      {PERSONAL_INFORMATION_HEADER}
                    </h3>
                    {isEditMode && renderOldDocs()}
                    {newCustomerDocs?.map((doc: any, index: any) => (
                      <StyledRow
                        key={index}
                        id={index}
                        align="middle"
                        gutter={32}
                      >
                        <Col span={12}>
                          <Form.Item
                            label={'ID Type'}
                            className="label-control"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              id={index}
                              value={doc?.documentTypeId}
                              onChange={(value: any) =>
                                onDocTypeChange(value, index)
                              }
                              options={docTypes?.map((item: any) => {
                                return {
                                  value: item.id,
                                  label: item.description,
                                };
                              })}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            label="Capture Date"
                            className="label-control"
                          >
                            <Input
                              disabled
                              value={doc?.idCaptureDate}
                              className="input-control"
                              placeholder={DATE_FORMAT}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8} style={{ padding: 0 }}>
                          <StyledCaptureRow align="middle">
                            {doc?.image && (
                              <Col span={4}>
                                <ImageLoader
                                  isLoadFromRedux={true}
                                  imageUrl={doc?.image}
                                  classes={'mx-portrait-avatar'}
                                  fallbackImg={'no_image_thumbnail.svg'}
                                  isCaching={false}
                                  preview
                                />
                              </Col>
                            )}
                            <Col span={8}>
                              <Button
                                type="primary"
                                itemProp="secondary"
                                htmlType="button"
                                onClick={() => onDocumentCaptureClick(index)}
                                loading={doc.isScanning}
                                disabled={!doc?.documentTypeId}
                                style={{ marginLeft: '15px' }}
                              >
                                {doc.isScanning
                                  ? 'Scanning'
                                  : doc?.image
                                  ? 'Update ID'
                                  : 'Capture ID'}
                              </Button>
                              <HelpPopoverInfo
                                linkedID={'CUSTOMERNEW_CAPTUREID'}
                                position="rightTop"
                              ></HelpPopoverInfo>
                              {isDocCaptured && index === 0 && (
                                <StyledCustomError className="ant-form-item-explain ant-form-item-explain-error">
                                  <div role="alert" className="alert">
                                    {ALERT_MESSAGES.DOCUMENT}
                                  </div>
                                </StyledCustomError>
                              )}
                            </Col>
                          </StyledCaptureRow>
                        </Col>
                      </StyledRow>
                    ))}

                    <Row>
                      {isEditMode && (
                        <Button
                          type="primary"
                          itemProp="secondary"
                          style={{ marginRight: '30px', width: '160px' }}
                          onClick={() =>
                            setViewAllPreviousIds((prevState) => !prevState)
                          }
                        >
                          {viewAllPreviousIds
                            ? `Hide Previous ID(s)`
                            : `View Previous ID(s)`}
                        </Button>
                      )}
                      {newCustomerDocs?.length < 10 && (
                        <Button
                          type="primary"
                          itemProp="secondary"
                          disabled={!isAddAnotherIdEnable}
                          onClick={onAddAnotherIdClick}
                        >
                          Add Another ID
                        </Button>
                      )}
                    </Row>
                  </div>
                  <StyledRow align="top" justify="space-around" gutter={32}>
                    <Col span={6}>
                      <Form.Item
                        label="Title"
                        name="title"
                        className="label-control"
                        rules={[{ required: true }]}
                      >
                        <Select
                          onChange={onTitleChange}
                          options={TITLES.map((item: any) => {
                            return {
                              value: item,
                              label: item,
                            };
                          })}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={18}></Col>
                  </StyledRow>
                  <StyledRow align="top" justify="space-around" gutter={32}>
                    <Col span={6}>
                      <Form.Item
                        label="First Name"
                        name="firstNames"
                        className="label-control"
                        rules={[
                          {
                            required: true,
                          },
                          {
                            type: 'string',
                            max: 40,
                            min: 0,
                            pattern: new RegExp("^[a-zA-Z]+[a-zA-Z-' ]*$"),
                          },
                        ]}
                      >
                        <Input className="input-control" autoComplete="off" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Middle Name"
                        name="middleName"
                        className="label-control"
                        rules={[
                          {
                            type: 'string',
                            max: 40,
                            min: 0,
                            pattern: new RegExp("^[a-zA-Z]+[a-zA-Z-' ]*$"),
                          },
                        ]}
                      >
                        <Input className="input-control" autoComplete="off" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Last Name"
                        name="surname"
                        className="label-control"
                        rules={[
                          {
                            required: true,
                          },
                          {
                            type: 'string',
                            max: 40,
                            min: 0,
                            pattern: new RegExp("^[a-zA-Z]+[a-zA-Z-' ]*$"),
                          },
                        ]}
                      >
                        <Input className="input-control" autoComplete="off" />
                      </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                  </StyledRow>
                  <StyledRow align="top" justify="space-around" gutter={32}>
                    <Col span={6}>
                      <Form.Item
                        label={
                          <HelpPopoverInfo
                            linkedID={'CUSTOMERNEW_PREFERREDNAME'}
                            position="rightTop"
                          >
                            <span>Preferred Name</span>
                          </HelpPopoverInfo>
                        }
                        name="preferredName"
                        className="label-control"
                        rules={[
                          {
                            type: 'string',
                            max: 50,
                            min: 0,
                          },
                        ]}
                      >
                        <Input className="input-control" autoComplete="off" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label={
                          <HelpPopoverInfo
                            linkedID={'CUSTOMERNEW_PREFERREDLANGUAGE'}
                            position="rightTop"
                          >
                            <span>Preferred Langauge</span>
                          </HelpPopoverInfo>
                        }
                        name="preferredLanguageId"
                        className="label-control"
                      >
                        <Select
                          loading={isLanguageLoading}
                          options={preferredLanguages?.map((item: any) => {
                            return {
                              value: item.id,
                              label: item.name,
                            };
                          })}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Date of Birth"
                        name="dateOfBirth"
                        className="label-control"
                        rules={[
                          { required: true },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value) {
                                return Promise.resolve();
                              } else if (value) {
                                return validateDateOfBirth(_, value);
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <DatePickerMUI
                          initialValue={initalDate}
                          disableFutureDates={true}
                          onChange={onDateChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                  </StyledRow>
                  <StyledContainer>
                    <h3 className="form-h3-title">{ADDRESS_DETAILS_HEADER}</h3>
                    <StyledRow align="top" justify="space-around" gutter={32}>
                      <Col span={9}>
                        <Form.Item
                          label={
                            // <HelpPopoverInfo
                            //   linkedID={'CUSTOMERNEW_POSTCODE'}
                            //   position="rightTop"
                            // >
                            //   Postcode
                            // </HelpPopoverInfo>
                            'Postcode'
                          }
                          name="postcode"
                          className="label-control autocomplete-input-selection"
                          validateTrigger="onBlur"
                          rules={[
                            {
                              required: true,
                            },
                            {
                              pattern: REGEX_POSTCODE,
                              message: 'Postcode is not correct',
                            },
                          ]}
                        >
                          <AutoComplete
                            showSearch={false}
                            id="postcode"
                            className="input-control"
                            value={inputValue}
                            notFoundContent={
                              searchInProgress ? (
                                <Spin />
                              ) : inputValue !== '' && noResult ? (
                                noResultRow()
                              ) : null
                            }
                            onBlur={validatePostCode}
                            onSearch={debounce(onSearch, 400)}
                            onSelect={onSelect}
                            options={addressOptions}
                            //MP-6571 - removed for address lookup search fix
                            // filterOption={(inputValue: any, option: any) =>
                            //   option?.label
                            //     .toUpperCase()
                            //     .indexOf(inputValue.toUpperCase()) !== -1
                            // }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={9}>
                        <Form.Item
                          label="Country"
                          name="countryId"
                          className="label-control"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            options={countries?.map((item: any) => {
                              return {
                                value: item.id,
                                label: item.name,
                              };
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}></Col>
                    </StyledRow>
                    {(autoFill || isManualAddressVisible) && (
                      <>
                        <StyledRow
                          align="top"
                          justify="space-around"
                          gutter={32}
                        >
                          <Col span={6}>
                            <Form.Item
                              label="Flat/House Number"
                              name="property"
                              className="label-control"
                              rules={[
                                {
                                  required: true,
                                },
                                {
                                  type: 'string',
                                  max: 60,
                                  min: 0,
                                },
                              ]}
                            >
                              <Input
                                className="input-control"
                                autoComplete="off"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              label="House Name"
                              name="houseName"
                              className="label-control"
                              rules={[
                                {
                                  type: 'string',
                                  max: 60,
                                  min: 0,
                                },
                              ]}
                            >
                              <Input
                                className="input-control"
                                autoComplete="off"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              label="Street/Road"
                              name="street"
                              className="label-control"
                              rules={[
                                {
                                  type: 'string',
                                  max: 60,
                                  min: 0,
                                },
                              ]}
                            >
                              <Input
                                className="input-control"
                                autoComplete="off"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6}></Col>
                        </StyledRow>
                        <StyledRow
                          align="top"
                          justify="space-around"
                          gutter={32}
                        >
                          <Col span={6}>
                            <Form.Item
                              label="Town"
                              name="town"
                              className="label-control"
                              rules={[
                                { required: true },
                                {
                                  type: 'string',
                                  max: 35,
                                  min: 0,
                                },
                              ]}
                            >
                              <Input
                                className="input-control"
                                autoComplete="off"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              label="Area"
                              name="area"
                              className="label-control"
                              rules={[
                                {
                                  type: 'string',
                                  max: 35,
                                  min: 0,
                                },
                              ]}
                            >
                              <Input
                                className="input-control"
                                autoComplete="off"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              label="County"
                              name="county"
                              className="label-control"
                              rules={[
                                {
                                  type: 'string',
                                  max: 30,
                                  min: 0,
                                },
                              ]}
                            >
                              <Input
                                className="input-control"
                                autoComplete="off"
                              />
                            </Form.Item>
                          </Col>

                          <Col span={6}>
                            {isAddressValidated ? (
                              <VerifyResult verified={isAddressVerified} />
                            ) : (
                              <CustomerValidation
                                onButtonClick={onCallValidateClick}
                              />
                            )}
                          </Col>
                        </StyledRow>
                      </>
                    )}
                  </StyledContainer>
                  <StyledContainer>
                    <h3 className="form-h3-title">{CONTANT_DETAILS_HEADER}</h3>
                    <StyledRow align="top" justify="space-around" gutter={32}>
                      <Col span={18}>
                        <Form.Item
                          label="Email"
                          name="emailAddress"
                          className="label-control"
                          validateTrigger="onBlur"
                          rules={[
                            { required: true },
                            {
                              type: 'email',
                              max: 60,
                              min: 0,
                            },
                          ]}
                        >
                          <Input className="input-control" autoComplete="off" />
                        </Form.Item>
                      </Col>
                      <Col span={6}></Col>
                    </StyledRow>

                    <StyledRow align="top" gutter={32}>
                      <Col span={9}>
                        <Form.Item
                          label="Mobile No."
                          name="mobile"
                          className="label-control"
                          validateTrigger="onBlur"
                          rules={[
                            { required: true },
                            {
                              pattern: new RegExp('^[+]?[0-9 ]{11}$'),
                              message:
                                'Mobile is not a valid UK or International phone number.',
                            },
                          ]}
                          {...phoneValidationError}
                        >
                          <Input
                            max="11"
                            className="input-control"
                            autoComplete="off"
                            onChange={() => {
                              setPhoneValidationError({});
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        span={3}
                        style={{ display: isRequiredPin ? 'block' : 'none' }}
                      >
                        <Form.Item
                          label="Pin"
                          name="pin"
                          className="label-control label-visibility"
                        >
                          <Input
                            className="input-control"
                            placeholder="Enter pin"
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>
                      {
                        <PhoneValidation
                          defaultVerified={form.getFieldValue(
                            'isMobileVerified'
                          )}
                          onValidationSuccess={() => {
                            form.setFieldsValue({ isMobileVerified: true });
                          }}
                          onValidationSMSRequest={handleValidationSMSRequest}
                          onVerifyPinRequest={handleValidationVerifyPinRequest}
                          onValidationStarted={() => {
                            setRequirePin(true);
                          }}
                          onValidationEnded={() => {
                            setRequirePin(false);
                            form.setFieldsValue({ pin: '' });
                          }}
                          onValidateFail={(msg: string) => {
                            setPhoneValidationError({
                              validateStatus: 'error',
                              help: msg,
                            });
                          }}
                        />
                      }
                    </StyledRow>
                    <StyledRow align="top">
                      <Col span={18}>
                        <Form.Item
                          label="Home Telephone No."
                          name="telephone"
                          className="label-control"
                          rules={[
                            {
                              pattern: new RegExp('^[+]?[0-9]{11}$'),
                              message:
                                'Telephone No. is not a valid UK or International phone number.',
                            },
                          ]}
                        >
                          <Input className="input-control" autoComplete="off" />
                        </Form.Item>
                      </Col>
                      <Col span={6}></Col>
                    </StyledRow>
                    <StyledRow align="top">
                      <Col span={18}>
                        <Form.Item
                          label={
                            <HelpPopoverInfo
                              linkedID={'CUSTOMERNEW_COMMSPREF'}
                              position="topLeft"
                            >
                              <span>Communication Preference</span>
                            </HelpPopoverInfo>
                          }
                          name="prefersEmailContact"
                          className="label-control"
                        >
                          <Radio.Group onChange={letterPreferenceOnChange}>
                            <Radio value={true}>
                              {LETTER_PREFERENCES.ELECTRONIC}
                            </Radio>
                            <Radio value={false}>
                              {LETTER_PREFERENCES.PAPER}
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={6}></Col>
                    </StyledRow>
                    {isEditMode ? (
                      <>
                        <Row align="top">
                          <StyledCol span={24}>
                            <HelpPopoverInfo
                              linkedID={'CUSTOMERNEW_MARKETINGPREF'}
                              position="topLeft"
                            >
                              {MARKETING_PREFERENCE_HEADER}
                            </HelpPopoverInfo>
                          </StyledCol>
                        </Row>
                        <StyledRow align="top">
                          {MARKETING_PREFERENCES.map((item, index) => {
                            const { key, text } = item;
                            return (
                              <StyledCheckboxCol key={index}>
                                <Form.Item
                                  label=""
                                  name={key}
                                  className="label-control"
                                  valuePropName="checked"
                                >
                                  <Checkbox
                                    onChange={onMarketingPreferenceChange}
                                  >
                                    {text}
                                  </Checkbox>
                                </Form.Item>
                              </StyledCheckboxCol>
                            );
                          })}
                        </StyledRow>
                        {isPreferenceErrorVisible && (
                          <Row style={{ marginTop: '-15px' }}>
                            <Col span={24}>
                              <div className="ant-form-item-explain ant-form-item-explain-error">
                                <div role="alert" className="alert">
                                  {ALERT_MESSAGES.MARKETING_PREFERENCES}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        )}
                      </>
                    ) : null}
                  </StyledContainer>
                </Col>
              </Row>
              {/* EVB:1972 - Disbaling 3PA for test package */}
              {/* {tpaList?.map((tpa: any, index: any) => (
                <AddThirdPartyAssistant
                  customer={customer}
                  shakedImage={shakedImage}
                  customerResult={customerResult}
                  isImageUploading={isImageUploading}
                  countries={countries}
                  documentObject={documentObject}
                  rowIndex={index}
                  rowData={tpa}
                  selectedStore={selectedStore}
                  tpaDocTypes={tpaDocTypes}
                  scanDocument={scanDocument}
                  key={'tpa' + index}
                />
              ))} */}

              {/* EVB:1972 - Disbaling 3PA for test package */}
              {/* <StyledAddTpaRow>
                <Col span={6}></Col>
                <Col span={18}>
                  <Button
                    type="primary"
                    onClick={onAddTpaClick}
                    itemProp="secondary"
                    htmlType="button"
                    disabled={!isEnableAddTpa}
                  >
                    {isEnableAddTpa && tpaList.length > 0
                      ? 'Add another 3rd party assistant'
                      : 'Add 3rd party assistant'}
                  </Button>
                </Col>
              </StyledAddTpaRow> */}
              <StyledFullHr />
              <StyledFooterRow align="bottom">
                <Col span={24}>
                  <Row>
                    <Col span={6}>
                      <StyledLeftRow align="middle">
                        <Col>
                          <HelpPopoverInfo
                            linkedID={'CUSTOMERNEW_EXIT'}
                            position="rightTop"
                            helpPosition="AFTER"
                          >
                            <Button
                              type="primary"
                              itemProp="secondary"
                              htmlType="button"
                              onClick={
                                isCustFromHeader ? onBackToJourney : onExitClick
                              }
                            >
                              {isCustFromHeader ? 'Back to Journey' : 'Exit'}
                            </Button>
                          </HelpPopoverInfo>
                        </Col>
                        <Col>
                          <HelpPopoverInfo
                            linkedID={'CUSTOMERNEW_RESET'}
                            position="rightTop"
                            helpPosition="AFTER"
                          >
                            <Button
                              type="primary"
                              itemProp="secondary"
                              htmlType="button"
                              onClick={onResetClick}
                            >
                              Reset
                            </Button>
                          </HelpPopoverInfo>
                        </Col>
                      </StyledLeftRow>
                    </Col>
                    <Col span={12}></Col>
                    <Col span={6}>
                      <StyledRightRow align="middle">
                        <Col>
                          <HelpPopoverInfo
                            linkedID={'CUSTOMERNEW_MANAGEROVERRIDE'}
                            position="rightTop"
                            helpPosition="BEFORE"
                          >
                            <Button
                              type="primary"
                              itemProp="secondary"
                              htmlType="button"
                            >
                              Manager Override
                            </Button>
                          </HelpPopoverInfo>
                        </Col>
                        <Col>
                          <HelpPopoverInfo
                            linkedID={'CUSTOMERNEW_SAVE'}
                            position="rightTop"
                            helpPosition="BEFORE"
                          >
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={isLoading}
                            >
                              Save
                            </Button>
                          </HelpPopoverInfo>
                        </Col>
                      </StyledRightRow>
                    </Col>
                  </Row>
                </Col>
              </StyledFooterRow>
              <MarketingPreferencePopup
                customer={customer}
                visible={marketingPopupVisible}
                setMarketingPopupVisible={() => setMarketingPopupVisible(false)}
              />
              {isGdprMessageVisible && (
                <GdprMessagePopup
                  visible={isGdprMessageVisible}
                  setGdprMessageVisible={() => setGdprMessageVisible(false)}
                  setGdprDeclineVisible={(visibility: boolean) =>
                    setGdprDeclineVisible(visibility)
                  }
                />
              )}
              {isGdprDeclineVisible && (
                <GdprDeclinePopup
                  visible={isGdprDeclineVisible}
                  setGdprDeclineVisible={(visibility: boolean) =>
                    setGdprDeclineVisible(visibility)
                  }
                  resetSearch={resetSearch}
                />
              )}
              {isDocumentCaptureVisible && (
                <DocumentCapture
                  visible={isDocumentCaptureVisible}
                  docType={DOCUMENT_TYPE_OTHER}
                  onCancel={onCancelClick}
                  onDocCapture={onDocumentCapture}
                  onAddClick={onAddDocumentImage}
                />
              )}
              {isSaveButtonClicked && (
                <UpdatedCustomerInformation
                  visible={
                    isSaveButtonClicked &&
                    Array.from(new Set(valueArray)).length > 0
                  }
                  customerName={
                    customer?.firstNames +
                    ' ' +
                    (customer?.middleName ? customer.middleName : '') +
                    ' ' +
                    customer?.surname
                  }
                  data={Array.from(
                    new Set(valueArray.filter((obj: any) => obj !== ''))
                  )}
                  onOkClick={onSaveEditDetails}
                />
              )}
            </Form>

            <ScannerPopover
              visible={isScanning || isOCRLoading}
              message={isOCRLoading ? OCR_MESSAGE : DEFAULT_SCAN_MESSAGE}
            />
          </Spin>
        )}
      </Layout>
    </>
  );
};

export default NewCustomer;
