import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Layout, Row, Spin, notification } from 'antd';

import WorkQueue from './workQueue';
import LoanException from './loanException';
import ManagedCases from './managedCases';
import BankTransfers from './bankTransfers';
import Valuations from './valuations';

import NotificationFilters from './notificationFilters';

import CardTabs from 'components/common/CardTabs';

import { cacheStaticData } from 'utils/cacheServices';

import { getUserTeams } from 'services/workQueue';

import { actions as userActions } from 'redux/reducers/user';

import {
  TABS_BY_ID,
  WORKQUEUE_TABS_SST,
  WORKQUEUE_TABS_CST,
  DASHBOARD_TABS,
  MY_ACTION_TAB,
} from './constants';
import { GENERIC_ERROR } from 'globalConstants';
import { VULNERABILITYORIGINATOR } from 'components/consumerDuty/constants';

import './index.less';

const NotificationsCenter = () => {
  const { userDashboardTabs, notifications, isLoading } = useSelector(
    (state: any) => state.user
  );

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState<string>(TABS_BY_ID.MY_ACTIONS);

  useEffect(() => {
    if (userDashboardTabs.length === 0) setUserDashboardTabs();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onMenuClick = (key: any) => {
    setActiveTab(key);
  };

  const onExitClick = () => {
    setActiveTab(TABS_BY_ID.MY_ACTIONS);
  };

  const setUserDashboardTabs = async () => {
    const loggedUserTeamTabs = await getLoggedUserTeams();

    let userAllowedTabs = [
      ...MY_ACTION_TAB,
      ...loggedUserTeamTabs,
      ...DASHBOARD_TABS,
    ];

    dispatch(userActions.setUserDashboardTabs(userAllowedTabs));
  };

  const getLoggedUserTeams = async () => {
    try {
      const response = await cacheStaticData(getUserTeams);
      if (response?.data) {
        const { teams } = response?.data;

        if (teams.includes(VULNERABILITYORIGINATOR.SSTEAM))
          return WORKQUEUE_TABS_SST;
        else if (teams.includes(VULNERABILITYORIGINATOR.CSTeam))
          return WORKQUEUE_TABS_CST;
      }

      return [];
    } catch (e: any) {
      notification.error({ message: GENERIC_ERROR, duration: 5 });
      return [];
    }
  };

  return (
    <div className="notification-center-wrapper">
      <Row>
        <Col span={24}>
          <CardTabs
            activeKey={activeTab}
            className="workqueue-tabs"
            onMenuClick={onMenuClick}
            tabslist={userDashboardTabs}
          />
        </Col>
      </Row>
      {userDashboardTabs.length > 0 && (
        <Layout>
          <div className="content-wrapper">
            <NotificationFilters activeTab={activeTab} />

            <Spin spinning={isLoading}>
              <div className="grid-wrapper">
                {activeTab === TABS_BY_ID.MY_ACTIONS && (
                  <WorkQueue workQueue={notifications} />
                )}
                {activeTab === TABS_BY_ID.LOAN_EXCEPTION && (
                  <LoanException onExit={onExitClick} />
                )}
                {activeTab === TABS_BY_ID.MANAGE_CASES && (
                  <ManagedCases onExit={onExitClick} />
                )}
                {activeTab === TABS_BY_ID.BANK_TRANSFER && <BankTransfers />}
                {activeTab === TABS_BY_ID.VALUATION && <Valuations />}
              </div>
            </Spin>
          </div>
        </Layout>
      )}
    </div>
  );
};

export default NotificationsCenter;
