import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currencyList: [], // currency list for currency dropdown
  destinationStoreList: [], // store list for destination
  initCurrencyDenominations: [],
  currencyDenominations: [],
  storeTillUsers: [],
  storeTillStatusData: [],
  storeTillHistory: [],
  selectedTillOperationId: '',
  isEndOfDayAvailable: false,
  isLoading: false,
  fetchingHistory: false,
  reset: false,
  isCashDeliveryPopupVisible: false,
  isCashTransferPopupVisible: false,
  isExpenseInOutPopupVisible: false,
  error: {},
  tillError: {},
  userTillUpdated: 0,
};

const slice = createSlice({
  name: 'cashManagementActions',
  initialState,
  reducers: {
    setStoreTillUsers(state, action) {
      state.storeTillUsers = action.payload;
    },
    setCurrencyList(state, action) {
      state.currencyList = action.payload;
    },
    setDestinationStoreList(state, action) {
      state.destinationStoreList = action.payload;
    },
    updateCurrencyDenominationsRow(state, action) {
      state.currencyDenominations = action.payload;
      state.reset = false;
    },
    setInitCurrencyDenominationsRow(state, action) {
      state.initCurrencyDenominations = action.payload;
    },
    setStoreTillStatusData(state, action) {
      state.storeTillStatusData = action.payload;
    },
    setStoreTillHistory(state, action) {
      state.storeTillHistory = action.payload;
      state.tillError = {};
    },
    updateReset(state, action) {
      state.reset = action.payload;
    },
    setEndOfDayAvailable(state, action) {
      state.isEndOfDayAvailable = action.payload;
    },
    setCashDeliveryPopupVisiblity(state, action) {
      state.isCashDeliveryPopupVisible = action.payload;
    },
    setCashTransferPopupVisibility(state, action) {
      state.isCashTransferPopupVisible = action.payload;
    },
    setExpenseInOutPopupVisibility(state, action) {
      state.isExpenseInOutPopupVisible = action.payload;
    },
    setSelectedTillOperationId(state, action) {
      state.selectedTillOperationId = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    initFetchingHistory(state, action) {
      state.fetchingHistory = action.payload;
    },
    setTillHistoryError(state, action) {
      state.tillError = {
        message: action?.payload?.message,
        description: action?.payload?.response?.data?.error,
      };
    },
    setError(state, action) {
      state.error = {
        message: action?.payload?.message,
        description: action?.payload?.response?.data?.error,
      };
    },
    setUserTillUpdated(state, action) {
      state.userTillUpdated = state.userTillUpdated + 1;
    },
    clearItems() {
      return {
        ...initialState,
      };
    },
  },
});

export default slice.reducer;
export const { reducer, actions } = slice;
export { initialState };
