import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Modal, Row, Spin } from 'antd';
import styled from 'styled-components';
import { format } from 'date-fns';
import { FNS_DATE_FORMAT } from 'globalConstants';
import { currencyFormat, joinValues } from 'utils/util';
import {
  getWithdrawLater,
  getWithdrawNotificationMethod,
  postWithdrawLater,
} from 'services/pledge';
import { Button } from 'components/common/Button';
import { WITHDRAW_LATER } from 'components/customer/constants';
import { GET_CUSTOMER_PAWNBROKING_TAB_SUMMARY } from 'action_creators/pledge';
import { sendToPrinter } from 'services/user';
import Select from 'components/common/Select';
import { cacheStaticData } from 'utils/cacheServices';

const StyledCol = styled(Col)<{ fontSize: string }>`
  font-size: var(--${(props) => props.fontSize});
  font-weight: var(--font-weight-500);
  padding: 20px 0px 0px;
  color: var(--red);
`;

const StyledCols = styled(Col)`
  padding: 10px 0;
`;

const StyledDiv = styled.div`
  font-weight: var(--font-weight-500);
`;

interface WithdrawLaterNoticePopupProps {
  visible: boolean;
  setWithdrawLaterPopupVisible: () => void;
  row: any;
  isWithdrawViewMode: boolean;
}

interface WithdrawObject {
  maxAmount?: number;
  expiryDate?: string;
  noticeDate?: string;
}

const WithdrawLaterNoticePopup = ({
  visible,
  setWithdrawLaterPopupVisible,
  row,
  isWithdrawViewMode,
}: WithdrawLaterNoticePopupProps) => {
  const [notificationMethods, setNotificationMethods] = useState<Array<any>>(
    []
  );
  const [notificationType, setNotificationType] = useState<string>();
  const [withdrawData, setWithdrawData] = useState<WithdrawObject | any>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    customer: { customer },
    user: { user, selectedStore },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
      user: state.user,
    };
  });

  const dispatch = useDispatch();

  const getNotificationMethods = async () => {
    try {
      const response = await cacheStaticData(getWithdrawNotificationMethod);
      if (response?.data) {
        setNotificationMethods(response?.data);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const getWithdrawLaterData = async () => {
    try {
      setLoading(true);
      const response = await getWithdrawLater(row?.loanNumber);
      if (response?.data) {
        setLoading(false);
        setWithdrawData(response?.data?.withdraw);
        if (isWithdrawViewMode)
          setNotificationType(response?.data?.withdraw?.notificationType);
      }
    } catch (e: any) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getNotificationMethods();
    getWithdrawLaterData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onCancelClick = () => {
    setWithdrawLaterPopupVisible();
  };

  const onSaveClick = async (isPrint = false) => {
    const payload = {
      loanNumber: row?.loanNumber,
      maxAmount: withdrawData?.maxAmount,
      notificationType: notificationType,
      noticeDate: withdrawData?.noticeDate,
      isPrint: isPrint,
      storeId: selectedStore?.storeId,
      userId: user?.userId,
      customerName: joinValues([customer.firstNames, customer.surname], true),
    };

    try {
      const response = await postWithdrawLater(payload);
      if (response?.data) {
        const customerId = customer?.customerId;
        dispatch({
          type: GET_CUSTOMER_PAWNBROKING_TAB_SUMMARY,
          payload: { customerId },
        });
        setWithdrawLaterPopupVisible();
        if (isPrint) {
          sendToPrinter(response, 'printreceipt', '_WithdrawLaterNotice');
        }
      }
    } catch (e: any) {
      setWithdrawLaterPopupVisible();
      console.log(e);
    }
  };

  return (
    <>
      <Modal
        title={WITHDRAW_LATER.TITLE}
        open={visible}
        width={650}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        footer={
          !isWithdrawViewMode
            ? [
                <Row gutter={24} key="withdrawLaterNoticePopup">
                  <Col span={4}>
                    <Button
                      itemProp="secondary"
                      key="withdrawLaterEmail"
                      onClick={onCancelClick}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col span={4} offset={10}>
                    <Button
                      itemProp="secondary"
                      key="withdrawLaterNo"
                      onClick={() => onSaveClick()}
                      disabled={!notificationType}
                    >
                      Save
                    </Button>
                  </Col>
                  <Col span={6}>
                    <Button
                      key="withdrawLaterYes"
                      onClick={() => onSaveClick(true)}
                      itemProp="secondary"
                      disabled={!notificationType}
                    >
                      Save & Print
                    </Button>
                  </Col>
                </Row>,
              ]
            : null
        }
        centered
      >
        <Row>
          <StyledCols span={24}>{WITHDRAW_LATER.RIGHT_TO_WITHDRAW}</StyledCols>
        </Row>
        <Row>
          <Col span={24}>
            <StyledDiv>{WITHDRAW_LATER.METHOD_OF_NOTIFICATION}</StyledDiv>
          </Col>
          <Col span={24}>
            <Select
              value={notificationType}
              onChange={(e: any) => setNotificationType(e)}
              disabled={isWithdrawViewMode}
              options={notificationMethods?.map((item: any) => {
                return {
                  key: item.id,
                  value: item.description,
                  label: item.description,
                };
              })}
            />
          </Col>
        </Row>
        {isLoading ? (
          <Row>
            <StyledCols span={24}>
              <Spin />
            </StyledCols>
          </Row>
        ) : (
          <>
            <Row>
              <StyledCols span={24}>
                <table className="withdraw-table">
                  <thead>
                    <tr>
                      <th>{WITHDRAW_LATER.MAX_WITHDRAW_VALUE}</th>
                      <th>{WITHDRAW_LATER.NOTICE_GIVEN}</th>
                      <th>{WITHDRAW_LATER.EXPIRY_DATE}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{`${currencyFormat(
                        withdrawData?.maxAmount,
                        true
                      )}`}</td>
                      <td>
                        {withdrawData &&
                          format(
                            new Date(withdrawData?.noticeDate),
                            FNS_DATE_FORMAT
                          )}
                      </td>
                      <td>
                        {withdrawData &&
                          format(
                            new Date(withdrawData?.expiryDate),
                            FNS_DATE_FORMAT
                          )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </StyledCols>
            </Row>
            <Row>
              <StyledCol span={24} fontSize="font-size-14">
                {`The customer will pay no more than £${
                  withdrawData?.maxAmount
                } until ${
                  withdrawData &&
                  format(new Date(withdrawData?.expiryDate), FNS_DATE_FORMAT)
                } to withdraw this agreement. Are you sure you wish to apply this withdraw?`}
              </StyledCol>
            </Row>
          </>
        )}
      </Modal>
    </>
  );
};

export default WithdrawLaterNoticePopup;
