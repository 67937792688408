import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Row, Col, Form, Input, notification, Switch } from 'antd';
import { RuleObject } from 'antd/lib/form';

import { isEmpty, isNull, isUndefined } from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import { differenceInBusinessDays } from 'date-fns';

import { Button } from 'components/common/Button';
import InputDecimal from 'components/common/InputDecimal';
import Select from 'components/common/Select';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import FindCustomer from 'components/chequeCashing/FindCustomer';
import DatePickerMUI from 'components/common/DatePickerMUI';
import ImageLoader from 'components/common/ImageLoader';
import ScannerPopover from 'components/customer/newCustomer/scannerPopover';
import AuthorizationPayout from '../AuthorizationPayout';
import CreateNewIssuer from '../newIssuer';
import FindIssuer from '../FindIssuer';

import { UPLOAD_CHEQUE_CASHING_DOCUMENT_REQUEST } from 'action_creators/chequeCashing';
import { actions } from 'redux/reducers/chequeCashing';
import { getCustomer } from 'services/customer';
import { imageFromScanner } from 'services/user';
import {
  getChequeNumberValidation,
  getChequeType,
  getIssuerAccountDetails,
  postNewIssuer,
} from 'services/chequeCashing';

import {
  DOCUMENT_TYPE_OTHER,
  SCAN_MESSGAE,
  UPLOAD_DOC_MESSAGE,
} from 'components/customer/constants';
import { FIND_CUSTOMER } from 'components/retail/constants';
import {
  STEP_ONE,
  STEP_TWO,
  VIEW_ISSUER_DETAILS,
  FIND_CREATE_ISSUER,
  DEFAULT_NEW_ISSUER,
  ACCOUNT_NUMBER_PATTERN,
  CHEQUE_NUMBER_PATTERN,
  SORT_CODE_INVALID_PATTERN,
  CHEQUE_DETAILS_ERROR_MESSAGES,
  CHEQUE_NUMBER_ALREADY_EXIST,
  PART_PAYMENT_LABELS,
} from '../constants';
import DocumentCapture from 'components/customer/newCustomer/documentCapture';
import { currencyFormat, toFixedNoRound } from 'utils/util';
import { cacheStaticData } from 'utils/cacheServices';
import TextArea from 'components/common/TextArea';

const DocumentCaptureRow = styled(Row)`
  margin: 15px 0px 5px 0px;

  & .mx-portrait-avatar {
    width: 7rem !important;
    height: 5rem !important;
  }

  & .image-wrapper {
    max-width: 480px;

    & .ant-image {
      margin: 0 10px 10px 0;
    }
  }

  & .no-doc {
    width: 42px;
    height: 42px;
    margin-right: 20px;
  }
`;

const StyledRow = styled(Row)`
  padding: 20px;
`;

const StyledPartialPayRow = styled(Row)`
  padding: 10px 20px;
  border-bottom: 1px solid var(--light-gray);
`;

const StyledNotesRow = styled(Row)`
  margin-top: 8px;
`;

const StyledSpan = styled.span`
  color: var(--white);
`;

interface CustomerDetailsProps {
  totalAmount: number;
  setDisableProcBasket: (disableProcBasket: boolean) => void;
}

const CustomerDetails = ({
  totalAmount,
  setDisableProcBasket,
}: CustomerDetailsProps) => {
  const [initalDate, setInitialDate] = useState<any>(null); // used for MUIDatePicker
  const [chequeTypeList, setChequeTypeList] = useState<Array<any>>([]);
  const [issuerDetails, setIssuerDetails] = useState<any>({});
  const [capturedImageData, setCapturedImageData] = useState<any>({});
  const [basketDetails, setBasketDetails] = useState<any>({});
  const [chequeNumber, setChequeNumber] = useState<any>('');

  const [isChequeTypeOther, setIsChequeTypeOther] = useState<boolean>(false);
  const [isIssuerLoading, setIsIssuerLoading] = useState<boolean>(false);
  const [isFindCustomerVisible, setIsFindCustomerVisible] =
    useState<boolean>(false);
  const [isDocumentCaptureVisible, setDocumentCaptureVisible] =
    useState<boolean>(false);

  const [isScanning, setIsScanning] = useState<boolean>(false);

  const [isAddIssuerClicked, setIsAddIssuerClicked] = useState<boolean>(false);
  const [isViewIssuerClicked, setIsViewIssuer] = useState<boolean>(false);
  const [enableIssuerButton, setEnableIssuerButton] = useState<boolean>(true);
  const [disabledIssuerButton, setDisabledIssuerButton] =
    useState<boolean>(true);
  const [issuerName, setIssuerName] = useState<string>('');
  const [validDate, setValidDate] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [validated, setValidated] = useState<any>({
    accountNumberValidated: false,
    sortCodeValidated: false,
  });

  const [isChequeDetailsValid, setIsChequeDetailsValid] =
    useState<boolean>(false);
  const [isDocScanner, setIsDocScanner] = useState<boolean>(false);

  const dispatch = useDispatch();
  const {
    customer: { customer },
    chequeCashing: {
      isDocUploading,
      chequeItemDetails,
      preAuthStatusCode,
      preAuthText,
      thirdPartyChequePreAuthErrorsList,
      documentsImages,
      requestedAmount,
      partPaymentClicked,
      chequeApprovedAmount,
      isApprovedEditable,
      chequeDetails,
    },
    user: { user, selectedStore },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
      chequeCashing: state.chequeCashing,
      user: state.user,
    };
  });
  const [form] = Form.useForm();
  const { firstNames, surname, customerId } = customer;

  useEffect(() => {
    if (requestedAmount >= 0) {
      const roundedAmount = requestedAmount
        ? requestedAmount.toFixed(2)
        : totalAmount.toFixed(2);

      form.setFieldsValue({
        requestedAmount: roundedAmount,
      });
    }
  }, [requestedAmount]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (preAuthStatusCode === 0) {
      if (isEmpty(chequeItemDetails) || !chequeApprovedAmount) {
        const roundedAmount = requestedAmount
          ? requestedAmount.toFixed(2)
          : totalAmount.toFixed(2);

        form.setFieldsValue({
          approvedAmount: roundedAmount,
          retainedAmount: 0,
        });
        dispatch(actions.setApprovedAmount(requestedAmount));
        dispatch(actions.setRetainedAmount('0.00'));
      }
      dispatch(actions.setIsApprovedEditable(true));
    } else if (!isNull(preAuthStatusCode) && preAuthStatusCode !== 0) {
      if (isEmpty(chequeItemDetails) || !chequeApprovedAmount) {
        dispatch(
          actions.setDocNotes(
            thirdPartyChequePreAuthErrorsList ?? preAuthText + '\n'
          )
        );
        form.setFieldsValue({
          requestedAmount: totalAmount.toFixed(2),
          approvedAmount: 0.0,
          retainedAmount: 0.0,
          note: thirdPartyChequePreAuthErrorsList ?? preAuthText + '\n',
        });
      }

      dispatch(actions.setIsApprovedEditable(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    preAuthStatusCode,
    chequeItemDetails,
    preAuthText,
    thirdPartyChequePreAuthErrorsList,
  ]);

  const setChequeDetails = (chequeDetails: any) => {
    const {
      chequeNumber,
      sortCode,
      chequeType,
      accountNumber,
      totalFee,
      itemFee,
    } = chequeDetails;

    const date = moment(chequeDetails?.chequeDate);
    const isChequeTypeOther = chequeType?.toLowerCase().match('other');

    setEditMode(true);
    setValidDate(true);
    setEnableIssuerButton(false);
    setDisabledIssuerButton(false);
    setIsChequeTypeOther(isChequeTypeOther);
    setIsChequeDetailsValid(true);

    setInitialDate(date);
    setIssuerDetails(chequeDetails);

    setIssuerName(chequeDetails?.issuerName);
    setChequeNumber(chequeNumber);

    form.setFieldsValue({
      sortCode,
      accountNumber,
      chequeNumber,
      chequeDate: date,
      chequeType: chequeDetails?.chequeTypeName,
      retainedAmount: partPaymentClicked
        ? 0
        : toFixedNoRound(chequeDetails?.retained),
      approvedAmount: toFixedNoRound(chequeDetails?.payout),
      requestedAmount: toFixedNoRound(
        chequeDetails?.amount - chequeDetails?.totalFee
      ),
      note: chequeDetails?.note,
    });

    const basketDetailsParams = {
      chequeType: chequeDetails?.chequeTypeName,
      chequeTypeDetails: {
        description: chequeDetails?.chequeTypeName,
      },
      issuerDetails: {
        accountNumber,
        issuerClassId: chequeDetails?.issuerClassId,
        issuerId: chequeDetails?.issuerId,
        issuerName: chequeDetails?.issuerName,
        sortCode,
      },
      accountNumber,
      sortCode,
      chequeNumber,
      customer: { ...customer },
      chequeDate: date,
      totalFee,
      itemFee,
    };

    setBasketDetails((prevState: any) => {
      return {
        ...prevState,
        ...basketDetailsParams,
        preAuthorization: chequeDetails?.preAuthorization,
      };
    });

    setValidated({
      ...validated,
      accountNumberValidated: true,
      sortCodeValidated: true,
    });
  };

  useEffect(() => {
    loadChequeTypes();
    if (!isEmpty(chequeItemDetails)) setChequeDetails(chequeItemDetails);
  }, [chequeItemDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (chequeDetails?.issuerDetails?.issuerId) {
      setEnableIssuerButton(false);
    }
  }, [chequeDetails]);

  useEffect(() => {
    let isChequeValidForAuth = false;
    const { chequeNumber, accountNumber, sortCode, chequeTypeDetails, notes } =
      basketDetails;

    if (
      (chequeTypeDetails?.description === 'Other' && !notes) ||
      issuerName === 'Issuer not found' ||
      !isEmpty(form.getFieldError('chequeNumber'))
    )
      isChequeValidForAuth = false;
    else if (
      customer?.customerId &&
      validDate &&
      accountNumber &&
      sortCode &&
      chequeNumber &&
      +totalAmount > 0 &&
      chequeTypeDetails &&
      issuerName.trim()
    )
      isChequeValidForAuth = true;

    setIsChequeDetailsValid(isChequeValidForAuth);
  }, [basketDetails, issuerName, totalAmount, validDate, customer]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadCustomerDetailsById = async (id: number) => {
    const response = await getCustomer(id);
    if (response?.data)
      setBasketDetails((prevState: any) => {
        return { ...prevState, customer: response?.data };
      });
  };

  useEffect(() => {
    customerId && loadCustomerDetailsById(customerId);
  }, [customerId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const { sortCodeValidated, accountNumberValidated } = validated;
    if (sortCodeValidated && accountNumberValidated && customer?.customerId) {
      loadIssuerDetails(basketDetails?.accountNumber, basketDetails?.sortCode);
    }

    if (sortCodeValidated && accountNumberValidated)
      setDisabledIssuerButton(false);
    else setDisabledIssuerButton(true);
  }, [validated, customer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (chequeNumber)
      setBasketDetails((prevState: any) => {
        return {
          ...prevState,
          chequeNumber: chequeNumber,
        };
      });
  }, [chequeNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEmpty(basketDetails))
      dispatch(actions.addChequeDetails(basketDetails));
  }, [basketDetails, issuerName, issuerDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const isChequeDateValid = () => {
    const currentDate = new Date();
    const lastDate = new Date().setMonth(currentDate.getMonth() - 5);
    const validDateDiff = differenceInBusinessDays(
      currentDate.getTime(),
      lastDate
    );

    // 30 day cheque logic - commenting for future use
    // const lastThirtyDays = new Date().setMonth(currentDate.getMonth() - 5);
    // const validThirtyDaysDiff = differenceInBusinessDays(
    //   currentDate.getTime(),
    //   lastThirtyDays
    // );
    // if (validThirtyDaysDiff >= chequeDiff) setIsThirtyDaysExceed(true);
    // else setIsThirtyDaysExceed(false);

    const cheque = new Date(form.getFieldValue('chequeDate')).getTime();
    const chequeDiff = differenceInBusinessDays(currentDate.getTime(), cheque);

    if (validDateDiff >= chequeDiff) return true;
    else return false;
  };

  const loadChequeTypes = async () => {
    try {
      const response = await cacheStaticData(getChequeType);
      if (response) setChequeTypeList(response?.data?.thirdPartyChequeTypes);
    } catch (e: any) {
      // do nothing
    }
  };

  const onNewIssuerPopupCancelClick = useCallback(() => {
    setIsAddIssuerClicked(false);
    setIsViewIssuer(false);
  }, []);

  const getSelection = async (data: any) => {
    const newAccountNumber = form.getFieldValue('accountNumber');
    const newSortCode = form.getFieldValue('sortCode')?.split('-')?.join('');
    setIssuerName(data?.issuerName);
    setIssuerDetails(data);
    setBasketDetails({
      ...basketDetails,
      issuerDetails: data,
    });
    setIsAddIssuerClicked(false);
    if (
      parseInt(data?.accountNumber) !== parseInt(newAccountNumber) ||
      parseInt(data?.sortCode) !== parseInt(newSortCode)
    ) {
      //EVB-1016, EVB-1010 create issuer call here to add new account number with existing issuer
      const params = {
        issuerName: data?.issuerName || chequeItemDetails?.issuerName,
        issuerId: data?.issuerId,
        storeId: selectedStore?.storeId,
        issuerClassId: data?.issuerClassId,
        isNewIssuer: false,
        createdBy: user?.userId,
        issuerAccount: {
          accountNumber: newAccountNumber,
          sortCode: newSortCode,
          storeId: selectedStore?.storeId,
          preferredAccount: 1,
        },
      };
      try {
        setIsIssuerLoading(true);
        const response = await postNewIssuer(params);
        if (response) {
          setIsIssuerLoading(false);
          setEnableIssuerButton(false);
          form.setFieldsValue({
            issuerName: data?.issuerName || chequeItemDetails?.issuerName,
          });
          setBasketDetails({
            ...basketDetails,
            issuerDetails: data,
            sortCode: newSortCode,
            issuerName: data?.issuerName || chequeItemDetails?.issuerName,
            accountNumber: newAccountNumber,
          });
        } else {
          form.setFieldsValue({
            issuerName: '',
          });
          setIssuerName('');
        }
      } catch (e: any) {
        setIsIssuerLoading(false);
        form.setFieldsValue({
          issuerName: '',
        });
        setIssuerName('');
        notification.error({
          message: e?.response?.data?.error || e?.message,
          duration: 2,
        });
      }
    }
  };

  const onAccountDetailsChange = (event: any) => {
    const { value } = event.target;

    if (ACCOUNT_NUMBER_PATTERN.test(value) && value && value !== null) {
      setBasketDetails({
        ...basketDetails,
        accountNumber: value,
      });
      setValidated({ ...validated, accountNumberValidated: true });
    } else {
      setBasketDetails({
        ...basketDetails,
        accountNumber: '',
      });
      setValidated({ ...validated, accountNumberValidated: false });
    }
  };

  const chequeDateValidation = (rule: RuleObject, value: any) => {
    const check = isChequeDateValid();
    if (check) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error(
        isNull(value)
          ? CHEQUE_DETAILS_ERROR_MESSAGES.CHEQUE_DATE.REQUIRED
          : CHEQUE_DETAILS_ERROR_MESSAGES.CHEQUE_DATE.INVALID
      )
    );
  };

  const sortCodeValidation = (event: any) => {
    const { value } = event.target;

    if (
      !isNull(value) &&
      !isUndefined(value) &&
      !isEmpty(value) &&
      SORT_CODE_INVALID_PATTERN.test(value)
    ) {
      form.setFieldsValue({
        sortCode: value?.match(/\d{2}(?=\d{1})|\d+/g).join('-'),
      });

      const formattedSortCode = value.split('-').join('');

      if (formattedSortCode.length === 6) {
        setValidated({ ...validated, sortCodeValidated: true });
        setBasketDetails({
          ...basketDetails,
          sortCode: formattedSortCode,
        });

        form.setFields([
          {
            name: 'sortCode',
            errors: [],
          },
        ]);
      } else {
        setValidated({ ...validated, sortCodeValidated: false });
        setBasketDetails({
          ...basketDetails,
          sortCode: '',
        });
        form.setFields([
          {
            name: 'sortCode',
            errors: [CHEQUE_DETAILS_ERROR_MESSAGES.SORT_CODE.DIGIT_INVALID],
          },
        ]);
      }
    } else {
      setValidated({ ...validated, sortCodeValidated: false });
      setBasketDetails({
        ...basketDetails,
        sortCode: '',
      });

      form.setFields([
        {
          name: 'sortCode',
          errors: [CHEQUE_DETAILS_ERROR_MESSAGES.SORT_CODE.REQUIRED],
          value: '',
        },
      ]);
    }
  };

  const onChequeTypeChange = (value: any) => {
    const selectedChequeType = chequeTypeList.find(
      (el: any) => el.chequeTypeId === value
    );

    setBasketDetails({
      ...basketDetails,
      chequeTypeDetails: selectedChequeType,
    });
    setIsChequeTypeOther(
      selectedChequeType?.description?.toLowerCase().match('other')
    );
  };

  const loadIssuerDetails = async (accountNumber: number, sortCode: number) => {
    if (accountNumber && sortCode) {
      setIsIssuerLoading(true);
      try {
        const response = await getIssuerAccountDetails(accountNumber, sortCode);
        if (response?.data) {
          const data = response?.data;
          setBasketDetails((prevState: any) => {
            return {
              ...prevState,
              issuerDetails: data,
              classId: data?.badClassId,
              issuerClassId: data?.issuerClassId,
              issuerId: data?.issuerId,
            };
          });
          setIssuerName(data?.issuerName);
          setIssuerDetails(data);
          setEnableIssuerButton(false);
          setDisabledIssuerButton(false);
          setIsIssuerLoading(false);
        } else {
          setIssuerName(DEFAULT_NEW_ISSUER);
          setEnableIssuerButton(true);
          setDisabledIssuerButton(false);
          setIsIssuerLoading(false);
        }
      } catch (e: any) {
        setEnableIssuerButton(true);
        setIssuerName(DEFAULT_NEW_ISSUER);
        setDisabledIssuerButton(false);
        setIsIssuerLoading(false);
      }
    }
  };

  const onCloseClick = useCallback(() => {
    setIsAddIssuerClicked(false);
    setIsViewIssuer(false);
    onNewIssuerPopupCancelClick();
  }, [onNewIssuerPopupCancelClick]);

  const onChequeDateChange = () => {
    setBasketDetails({
      ...basketDetails,
      chequeDate: form.getFieldValue('chequeDate')?.format(),
    });
    setValidDate(isChequeDateValid());
  };

  const onChangeDocNotes = useCallback((event: any) => {
    dispatch(actions.setDocNotes(event.target.value));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const validateAccountDetails = async () => {
    const { chequeNumberValidated, sortCodeValidated, accountNumberValidated } =
      validated;
    if (sortCodeValidated && accountNumberValidated && chequeNumberValidated) {
      form.setFields([
        {
          name: 'chequeNumber',
          errors: [],
        },
      ]);
      const params = {
        chequeNumber: form.getFieldValue('chequeNumber'),
        sortCode: form.getFieldValue('sortCode')?.split('-')?.join(''),
        accountNumber: form.getFieldValue('accountNumber'),
      };
      const response = await getChequeNumberValidation(params);
      if (response?.data) {
        setIsChequeDetailsValid(false);
        form.setFields([
          {
            name: 'chequeNumber',
            errors: [CHEQUE_NUMBER_ALREADY_EXIST] || [],
          },
        ]);
      }
    }
  };

  const validateChequeNumber = (event: any) => {
    const { value } = event.target;
    setChequeNumber(value);
    if (CHEQUE_NUMBER_PATTERN.test(value) && value && value !== null) {
      setValidated({ ...validated, chequeNumberValidated: true });
      setBasketDetails({
        ...basketDetails,
        chequeNumber: value,
      });
    } else {
      setValidated({ ...validated, chequeNumberValidated: false });
      setBasketDetails({
        ...basketDetails,
        chequeNumber: '',
      });
    }
  };

  const scanDocument = async () => {
    setIsScanning(true);
    const img = await imageFromScanner();

    if (img === undefined) {
      return setIsScanning(false);
    }
    const data = `data:image/png;base64, ${img}`;

    dispatch({
      type: UPLOAD_CHEQUE_CASHING_DOCUMENT_REQUEST,
      payload: { image: data },
    });

    setIsScanning(false);
  };

  const approvedAmountValidation = (
    rule: RuleObject,
    value: any,
    callback: (error?: string) => void
  ) => {
    const reqAmount = form.getFieldValue('requestedAmount');
    if (Number(value) > Number(reqAmount)) {
      setDisableProcBasket(true);
      return callback(
        CHEQUE_DETAILS_ERROR_MESSAGES.APPROVED_RETAINED_AMOUNT.INVALID
      );
    } else {
      setDisableProcBasket(false);
    }
  };

  const onAppAmountChange = () => {
    const apprAmount = form.getFieldValue('approvedAmount');
    const retainedAmount = (
      Number(requestedAmount * 100 - apprAmount * 100) / 100
    ).toFixed(2);

    form.setFieldsValue({
      retainedAmount: retainedAmount,
    });
    dispatch(actions.setApprovedAmount(apprAmount));
    dispatch(actions.setRetainedAmount(retainedAmount));
  };

  const onRetAmountChange = () => {
    const retAmount = form.getFieldValue('retainedAmount');
    const approvedAmt = (
      Number(requestedAmount * 100 - retAmount * 100) / 100
    ).toFixed(2);
    form.setFieldsValue({
      approvedAmount: approvedAmt,
    });
    dispatch(actions.setRetainedAmount(retAmount));
    dispatch(actions.setApprovedAmount(approvedAmt));
  };

  const onDocumentCapture = (data: any) => {
    setCapturedImageData(data);
  };

  const onAddDocumentImage = () => {
    dispatch({
      type: UPLOAD_CHEQUE_CASHING_DOCUMENT_REQUEST,
      payload: { image: capturedImageData },
    });

    setDocumentCaptureVisible(false);
  };

  const onFormValueChange = (event: any) => {
    if (!isNull(preAuthStatusCode)) {
      const keys = Object.keys(event);

      switch (keys[0]) {
        case 'note':
        case 'approvedAmount':
        case 'retainedAmount':
          return;
        case 'chequeDate': {
          const responseDate = moment(chequeItemDetails?.chequeDate);
          const formDate = event['chequeDate'];
          if (formDate?.format() === responseDate?.format()) return;
        }
      }

      dispatch(actions.resetPreAuthStatusCode());
      form.setFieldsValue({
        approvedAmount: 0,
        retainedAmount: 0,
      });
    }
  };

  const onOtherNotesChange = (event: any) => {
    const { value } = event?.target;

    setBasketDetails((prevState: any) => {
      return {
        ...prevState,
        notes: value,
      };
    });
  };

  const onSwitchChange = (checked: boolean) => {
    setIsDocScanner(checked);
  };

  const onDocCaptureClick = () => {
    if (isDocScanner) {
      scanDocument();
    } else {
      setDocumentCaptureVisible(true);
    }
  };

  return (
    <>
      <Form layout="vertical" onValuesChange={onFormValueChange} form={form}>
        <Row>
          <Col span={18}>
            <Row className="title-row">
              <Col span={24}>
                {isEmpty(customer) ? (
                  <HelpPopoverInfo
                    linkedID={'CHEQUE_STEP1'}
                    position="rightTop"
                    helpPosition="AFTER"
                  >
                    {STEP_ONE}
                  </HelpPopoverInfo>
                ) : (
                  'Step 1: ' + firstNames + ' ' + surname
                )}
                {isEmpty(customer) ? (
                  ''
                ) : (
                  <img
                    alt="green tick"
                    className={'green-tick'}
                    src={require('../../../assets/images/tick.svg').default}
                  />
                )}
              </Col>
            </Row>

            <Row className="find-customer-row">
              <Col span={24}>
                {isEmpty(customer) && (
                  <Button
                    itemProp="secondary"
                    key="back"
                    onClick={() => setIsFindCustomerVisible(true)}
                  >
                    {FIND_CUSTOMER}
                  </Button>
                )}
              </Col>
            </Row>

            <Row className="cheque-detail-title">
              <Col span={24}>
                <HelpPopoverInfo
                  linkedID={'CHEQUE_STEP2'}
                  position="rightTop"
                  helpPosition="AFTER"
                >
                  {STEP_TWO}
                </HelpPopoverInfo>
              </Col>
            </Row>
            <Row className="cheque-detail-row">
              <Col span={24}>
                <Row>
                  <Col span={6}>
                    <Form.Item
                      label="Cheque Number"
                      name="chequeNumber"
                      className="label-control"
                      rules={[
                        {
                          required: true,
                          message:
                            CHEQUE_DETAILS_ERROR_MESSAGES.CHEQUE_NUMBER
                              .REQUIRED,
                        },
                        {
                          pattern: CHEQUE_NUMBER_PATTERN,
                          message:
                            CHEQUE_DETAILS_ERROR_MESSAGES.CHEQUE_NUMBER.INVALID,
                        },
                      ]}
                    >
                      <Input
                        className="input-control"
                        onChange={(e) => {
                          validateChequeNumber(e);
                        }}
                        disabled={partPaymentClicked}
                        onKeyUp={validateAccountDetails}
                        type="text"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Sort Code"
                      name="sortCode"
                      className="label-control"
                    >
                      <Input
                        type="text"
                        className="input-control"
                        disabled={partPaymentClicked}
                        onChange={sortCodeValidation}
                        onKeyUp={validateAccountDetails}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <Form.Item
                      label="Account Number"
                      name="accountNumber"
                      className="label-control"
                      rules={[
                        {
                          message:
                            CHEQUE_DETAILS_ERROR_MESSAGES.ACCOUNT_NUMBER
                              .REQUIRED,
                          required: true,
                        },
                        {
                          pattern: ACCOUNT_NUMBER_PATTERN,
                          message:
                            CHEQUE_DETAILS_ERROR_MESSAGES.ACCOUNT_NUMBER
                              .INVALID,
                        },
                      ]}
                    >
                      <Input
                        className="input-control"
                        type="text"
                        disabled={partPaymentClicked}
                        onChange={onAccountDetailsChange}
                        onKeyUp={validateAccountDetails}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Cheque Issuer"
                      name="chequeIssuer"
                      className="label-control"
                      rules={[
                        {
                          required: true,
                          message:
                            CHEQUE_DETAILS_ERROR_MESSAGES.ISSUER_NAME.REQUIRED,
                        },
                      ]}
                    >
                      <Input
                        className="input-control"
                        value={issuerName}
                        disabled={partPaymentClicked}
                      />
                      <p style={{ display: 'none' }}>{issuerName}</p>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <HelpPopoverInfo
                      linkedID={'CHEQUE_VIEWISSUER'}
                      position="rightTop"
                      helpPosition="AFTER"
                    >
                      <Button
                        loading={isIssuerLoading}
                        itemProp="secondary"
                        key="issuer_details"
                        onClick={() =>
                          enableIssuerButton
                            ? setIsAddIssuerClicked(true)
                            : setIsViewIssuer(true)
                        }
                        disabled={disabledIssuerButton}
                      >
                        {enableIssuerButton
                          ? FIND_CREATE_ISSUER
                          : VIEW_ISSUER_DETAILS}
                      </Button>
                    </HelpPopoverInfo>

                    {isAddIssuerClicked && enableIssuerButton && (
                      <FindIssuer
                        isFindIssuerVisible
                        onFindIssuerPopupCancel={onCloseClick}
                        onSelection={getSelection}
                        basketDetails={basketDetails}
                      />
                    )}

                    {isViewIssuerClicked && (
                      <CreateNewIssuer
                        onNewIssuerPopupCancel={onCloseClick}
                        isNewIssuerVisible
                        isViewIssuerClick
                        isEditable
                        issuerName={issuerName}
                        basketDetails={basketDetails}
                        issuerDetails={issuerDetails}
                        setNewIssuerData={issuerDetails}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <Form.Item
                      label="Cheque Date"
                      name="chequeDate"
                      className="label-control"
                      rules={[{ validator: chequeDateValidation }]}
                      style={{ width: '11vw' }}
                    >
                      <DatePickerMUI
                        disableFutureDates={true}
                        initialValue={initalDate}
                        onChange={onChequeDateChange}
                        disabled={partPaymentClicked}
                        isTextInputReadOnly={true}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Cheque Type"
                      name="chequeType"
                      className="label-control"
                      rules={[
                        {
                          required: true,
                          message:
                            CHEQUE_DETAILS_ERROR_MESSAGES.CHEQUE_TYPE.REQUIRED,
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        className="cheque-type-select"
                        onChange={onChequeTypeChange}
                        disabled={partPaymentClicked}
                        options={chequeTypeList.map((item: any) => {
                          return {
                            value: item.chequeTypeId,
                            label: item.description,
                          };
                        })}
                      />
                    </Form.Item>
                  </Col>

                  {isChequeTypeOther && (
                    <Col span={8}>
                      <Form.Item
                        label=" "
                        name="notes"
                        className="label-control"
                        rules={[
                          {
                            required: true,
                            message:
                              CHEQUE_DETAILS_ERROR_MESSAGES.CHEQUE_NOTE
                                .REQUIRED,
                          },
                        ]}
                      >
                        <Input
                          className="input-control"
                          onChange={onOtherNotesChange}
                          disabled={partPaymentClicked}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>

            <AuthorizationPayout
              isPreAuthDisabled={!isChequeDetailsValid || partPaymentClicked}
            />

            <StyledNotesRow>
              <Col span={18}>
                <Form.Item label="Notes" name="note" className="label-control">
                  <TextArea
                    rows={2}
                    autoComplete="off"
                    maxLength={100}
                    onChange={onChangeDocNotes}
                    disabled={partPaymentClicked || isNull(preAuthStatusCode)}
                  />
                </Form.Item>
                <Form.Item name="supportingDocuments" className="label-control">
                  <DocumentCaptureRow>
                    <Col>
                      <div className="image-wrapper">
                        {documentsImages?.length > 0 &&
                          documentsImages?.map((item: any, index: number) => (
                            <>
                              <ImageLoader
                                key={index}
                                imageUrl={`/api/thirdpartycheque/images/${item?.containerId}/${item?.blobId}?isThumnails=false`}
                                classes={'mx-portrait-avatar'}
                                fallbackImg={'no_image_thumbnail.svg'}
                                isCaching={false}
                                preview
                              />
                            </>
                          ))}
                      </div>

                      {documentsImages?.length === 0 && (
                        <div className="no-doc">
                          <img
                            alt="no document captured"
                            src={
                              require('../../../assets/images/no_image_thumbnail.svg')
                                .default
                            }
                          />
                        </div>
                      )}
                    </Col>
                    <Col span={12}>
                      <div className="capture-button-wrapper">
                        <span
                          style={{
                            padding: '10px 13px 10px 0px',
                            color: !isDocScanner
                              ? 'var(--black)'
                              : 'var(--gray)',
                          }}
                        >
                          {'Camera'}
                        </span>
                        <Switch
                          className="pickup-switch"
                          onChange={onSwitchChange}
                          checked={isDocScanner}
                          disabled={
                            partPaymentClicked ||
                            !isChequeDetailsValid ||
                            documentsImages?.length === 12
                          }
                        />
                        <span
                          style={{
                            padding: '10px 0px 10px 13px',
                            color: isDocScanner
                              ? 'var(--black)'
                              : 'var(--gray)',
                          }}
                        >
                          {'Scanner'}
                        </span>
                      </div>

                      <Button
                        itemProp="secondary"
                        className="dropdown-btn"
                        onClick={onDocCaptureClick}
                        disabled={
                          partPaymentClicked ||
                          !isChequeDetailsValid ||
                          documentsImages?.length === 12
                        }
                      >
                        Capture Documents
                      </Button>
                    </Col>
                  </DocumentCaptureRow>
                </Form.Item>
              </Col>
            </StyledNotesRow>
          </Col>
          <Col span={6} className="amount-column-wrapper">
            {partPaymentClicked ? (
              <>
                <Col span={24} className="partial-pay-right-col">
                  <StyledPartialPayRow>
                    <Col span={12}>{PART_PAYMENT_LABELS.CHEQUE_FACE_VALUE}</Col>
                    <Col span={12}>
                      {currencyFormat(chequeItemDetails?.chequeAmount, true)}
                    </Col>
                  </StyledPartialPayRow>
                  <StyledPartialPayRow>
                    <Col span={12}>{PART_PAYMENT_LABELS.TOTAL_CHARGES}</Col>
                    <Col span={12}>
                      {currencyFormat(chequeItemDetails?.totalFee, true)}
                    </Col>
                  </StyledPartialPayRow>
                  <StyledPartialPayRow>
                    <Col span={12}>{PART_PAYMENT_LABELS.AMOUNT}</Col>
                    <Col span={12}>
                      {currencyFormat(chequeItemDetails?.payout, true)}
                    </Col>
                  </StyledPartialPayRow>
                  <StyledPartialPayRow>
                    <Col span={12}>{PART_PAYMENT_LABELS.PAID_TO_DATE}</Col>
                    <Col span={12}>
                      {currencyFormat(
                        chequeItemDetails?.payout - chequeItemDetails?.retained,
                        true
                      )}
                    </Col>
                  </StyledPartialPayRow>
                  <StyledPartialPayRow>
                    <Col span={12}>{PART_PAYMENT_LABELS.OUTSTANDING}</Col>
                    <Col span={12}>
                      {currencyFormat(chequeItemDetails?.retained, true)}
                    </Col>
                  </StyledPartialPayRow>
                </Col>
                <StyledRow>
                  <Form.Item label={PART_PAYMENT_LABELS.APPROVE_TO_PAY}>
                    <InputDecimal
                      className="cheque-amount-input"
                      placeholder="0.00"
                      value={chequeItemDetails?.retained}
                      disabled={true}
                      addonBefore={'£'}
                    />
                  </Form.Item>

                  <Form.Item
                    label={PART_PAYMENT_LABELS.RETAINED_AMOUNT}
                    name="retainedAmount"
                    className="label-control "
                  >
                    <InputDecimal
                      className="cheque-amount-input"
                      placeholder="0.00"
                      disabled={true}
                      addonBefore={'£'}
                    />
                  </Form.Item>
                </StyledRow>
              </>
            ) : (
              <StyledRow>
                <Col span={24}>
                  {!isNull(preAuthStatusCode) && preAuthStatusCode !== 0 && (
                    <Row>
                      <Button
                        className={`app-val-bg-color${
                          preAuthStatusCode === 0 || isApprovedEditable
                            ? '-green'
                            : '-red'
                        }`}
                        itemProp="secondary"
                        key="back"
                        disabled={!editMode}
                        onClick={() =>
                          dispatch(actions.setIsApprovedEditable(true))
                        }
                      >
                        <StyledSpan> Enter Approved Value </StyledSpan>
                      </Button>
                    </Row>
                  )}
                  <Row>
                    <Col span={21}>
                      <Form.Item
                        label={PART_PAYMENT_LABELS.REQUESTED_AMOUNT}
                        name="requestedAmount"
                        className="label-control "
                      >
                        <InputDecimal
                          className="cheque-amount-input"
                          placeholder="0.00"
                          addonBefore={'£'}
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={21}>
                      <Form.Item
                        label={PART_PAYMENT_LABELS.APPROVE_TO_PAY}
                        name="approvedAmount"
                        className="label-control "
                        rules={[{ validator: approvedAmountValidation }]}
                      >
                        <InputDecimal
                          className="cheque-amount-input"
                          placeholder="0.00"
                          onChange={onAppAmountChange}
                          disabled={!isApprovedEditable}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={21}>
                      <Form.Item
                        label={PART_PAYMENT_LABELS.RETAINED_AMOUNT}
                        name="retainedAmount"
                        className="label-control "
                        rules={[{ validator: approvedAmountValidation }]}
                      >
                        <InputDecimal
                          className="cheque-amount-input"
                          placeholder="0.00"
                          onChange={onRetAmountChange}
                          disabled={!isApprovedEditable}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </StyledRow>
            )}
          </Col>
        </Row>
      </Form>

      <ScannerPopover
        visible={isScanning || isDocUploading}
        title={isDocUploading ? UPLOAD_DOC_MESSAGE : SCAN_MESSGAE}
      />

      {isFindCustomerVisible && (
        <FindCustomer
          isFindCustomerVisible
          onFindCustomerPopupCancel={() => setIsFindCustomerVisible(false)}
        />
      )}

      {isDocumentCaptureVisible && (
        <DocumentCapture
          visible={isDocumentCaptureVisible}
          docType={DOCUMENT_TYPE_OTHER}
          onCancel={() => setDocumentCaptureVisible(false)}
          onDocCapture={onDocumentCapture}
          onAddClick={onAddDocumentImage}
        />
      )}
    </>
  );
};

export default CustomerDetails;
