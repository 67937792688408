import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { isNull } from 'lodash';

import { TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { DATE_FORMAT } from 'globalConstants';

const StyledInput = styled(TextField)<{
  $iconSize: string;
  $bordered: boolean;
}>`
  width: 100%;
  height: 42px;
  border-radius: 3px;
  border: ${(props) =>
    props.$bordered ? '1px solid var(--light-gray) !important' : 'none'};
  background-color: var(--white);
  flex-direction: row !important;

  fieldset {
    display: none;
  }

  & > div {
    width: 100%;
  }

  & div {
    &::before,
    ::after,
    :hover:not(.Mui-disabled):before {
      border: none;
    }

    &.Mui-disabled {
      color: var(--black);

      &::before {
        border-bottom-style: none;
      }
    }

    & input {
      font-size: var(--font-size-base);
    }

    & svg {
      font-size: var(--font-size-24);
    }
  }

  ${(props) =>
    props.$iconSize &&
    css`
      & svg {
        font-size: var(--${props.$iconSize}) !important;
      }
    `}
`;

const DatePickerMUI = ({
  initialValue = null,
  disabled = false,
  placeholder = DATE_FORMAT,
  format = DATE_FORMAT,
  disableFutureDates = true,
  disablePast = false,
  minDate = '',
  maxDate = '',
  iconSize = '',
  onChange = () => {
    /* do nothing */
  },
  bordered = true,
  dataTestId,
  isTextInputReadOnly = false,
}: any) => {
  const [selectedDate, setSelectedDate] = useState<any>(null);

  useEffect(() => {
    if (!isNull(initialValue) && initialValue !== '') {
      setSelectedDate(initialValue);
      onChange(initialValue);
    }
  }, [initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFieldBlur = () => {
    onChange(selectedDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        disabled={disabled}
        disableFuture={disableFutureDates}
        disablePast={disablePast}
        minDate={minDate}
        maxDate={maxDate}
        value={selectedDate}
        inputFormat={format}
        views={['year', 'month', 'day']}
        onAccept={(value: any) => onChange(value)}
        onChange={(value: any) => setSelectedDate(value)}
        renderInput={(params: any) => (
          <StyledInput
            {...params}
            $iconSize={iconSize}
            onBlur={onFieldBlur}
            data-testid={dataTestId}
            inputProps={{
              ...params.inputProps,
              placeholder: placeholder,
              readOnly: isTextInputReadOnly,
            }}
            $bordered={bordered}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePickerMUI;
