import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { GRID_NO_URL } from 'globalConstants';
import { Layout, Row, Col } from 'antd';
import {
  VIEW_AUCTION_DETAILS_TITLE,
  VIEW_AUCTION_LIVE_STORE_TITLE,
  TABLE_HEAD_COLOR,
  AUCTION_PRINT_TYPE,
  AUCTION_PRINT_FOR,
} from 'components/pledgemanagement/constants';
import LiveAtStoreSummary from '../auctionDetailsSummary/liveAtStoreSummary';
import VirtualGrid from 'components/common/virtualGrid/index';
import { Button } from 'components/common/Button';
import {
  PUT_CONFIRM_COLLECTION,
  PRINT_PASS_LIST,
  GET_AUCTION_DETAILS,
} from 'action_creators/pledgeManagement';
import { actions } from 'redux/reducers/pledgeManagement';
import { BUTTON_LABELS } from 'components/pledgemanagement/constants';

const StyledContentLayout = styled(Layout)`
  background: var(--white);
  padding: 15px 30px;
  border-radius: 5px;
`;

const StyledDetailsRow = styled(Row)`
  padding-bottom: 10px;
`;

const StyledTableWrapper = styled.div`
  height: calc(100vh - 407px);
`;

const StyledBottomRow = styled(Row)`
  padding: 15px 30px;
  background: var(--white);
  border: 0.6px solid var(--linkwater);
  border-top: none;
  box-shadow: rgba(1, 78, 169, 0.2) 0px 4px 10px;
  border-radius: 0px 0px 5px 5px;
  box-shadow: none;
  border: 0;
  border-top: 1px solid var(--linkwater);
`;

const StyledButton = styled(Button)`
  margin: 0 15px;
`;

interface LiveAtStoreDetailsProps {
  onExit: () => void;
}
const LiveAtStoreDetails = ({ onExit }: LiveAtStoreDetailsProps) => {
  const {
    pledgeManagement: { auctionItemDetails, successPassAuctionLot },
  } = useSelector((state: any) => {
    return {
      pledgeManagement: state.pledgeManagement,
    };
  });

  const { auctionLoans, ...auctionDetailsTable } = auctionItemDetails;

  const [columns] = useState([
    {
      name: 'auctionDate',
      title: 'Auction Date',
      getCellValue: (row: any) => row?.auctionDate,
    },
    {
      name: 'bookDate',
      title: 'Book Date',
      getCellValue: (row: any) => row?.bookDate,
    },
    {
      name: 'collectionDate',
      title: 'Collection Date',
      getCellValue: (row: any) => row?.collectionDate,
    },
    {
      name: 'lastMailDate',
      title: 'Last Mail Sent',
      getCellValue: (row: any) => row?.lastMailDate,
    },
    {
      name: 'reserveValue',
      title: 'Reserve Value',
      getCellValue: (row: any) => row?.reserveValue,
    },
    {
      name: 'numberOfLots',
      title: 'Number of Lots',
      getCellValue: (row: any) => row?.numberOfLots,
    },
    {
      name: 'auctionStatusText',
      title: 'Status',
      getCellValue: (row: any) => row?.auctionStatusText,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'auctionDate', width: '14%' },
    { columnName: 'bookDate', width: '14%' },
    { columnName: 'collectionDate', width: '14%' },
    { columnName: 'lastMailDate', width: '14%' },
    { columnName: 'reserveValue', width: '14%' },
    { columnName: 'numberOfLots', width: '13%' },
    { columnName: 'auctionStatusText', width: '13%' },
  ]);

  const getRowId = (row: any) => row?.auctionId;
  const dispatch = useDispatch();
  const onConfirmCollection = () => {
    dispatch({
      type: PUT_CONFIRM_COLLECTION,
      payload: {
        auctionId: auctionDetailsTable?.auctionId,
      },
    });
  };
  const printPassList = (printType: number) => {
    dispatch({
      type: PRINT_PASS_LIST,
      payload: {
        auctionId: auctionDetailsTable?.auctionId,
        printFor: AUCTION_PRINT_FOR.LIVE_AT_STORE,
        printType: printType,
      },
    });
  };

  useEffect(() => {
    if (successPassAuctionLot) {
      dispatch(actions.initLoading(true));
      dispatch(actions.setSuccessPassAuctionLot(false));
      dispatch({
        type: GET_AUCTION_DETAILS,
        payload: {
          auctionId: auctionDetailsTable?.auctionId,
          auctionStatus: auctionDetailsTable?.auctionStatus,
        },
      });
    }
  }, [successPassAuctionLot, auctionDetailsTable, dispatch]);

  return (
    <>
      <StyledContentLayout>
        <Row className="title-row">
          <Col span={24}>
            {VIEW_AUCTION_DETAILS_TITLE}
            {VIEW_AUCTION_LIVE_STORE_TITLE}
          </Col>
        </Row>
        <StyledDetailsRow>
          <VirtualGrid
            urlType={GRID_NO_URL}
            columnsProps={columns}
            tableColumnExtensionsProps={tableColumnExtensions}
            rowData={[auctionDetailsTable]}
            getRowId={getRowId}
            headerBackground={TABLE_HEAD_COLOR.ACTIVE}
          />
        </StyledDetailsRow>
        <StyledTableWrapper>
          <LiveAtStoreSummary
            auctionId={auctionDetailsTable.auctionId}
            auctionLoans={auctionLoans || []}
          />
        </StyledTableWrapper>
      </StyledContentLayout>

      <Row>
        <Col span={24}>
          <StyledBottomRow>
            <Col span={16}>
              <Row align="bottom" justify="start">
                <StyledButton
                  onClick={onExit}
                  itemProp="secondary"
                  style={{ marginLeft: 0 }}
                >
                  {BUTTON_LABELS.BACK}
                </StyledButton>
                <StyledButton
                  itemProp="secondary"
                  onClick={() => printPassList(AUCTION_PRINT_TYPE.PASSES)}
                >
                  {BUTTON_LABELS.PRINT_PASSES}
                </StyledButton>
                <StyledButton
                  itemProp="secondary"
                  onClick={() => printPassList(AUCTION_PRINT_TYPE.AUCTION_BOOK)}
                >
                  {BUTTON_LABELS.PRINT_AUCTION_BOOK}
                </StyledButton>
              </Row>
            </Col>
            <Col span={8}>
              <Row align="bottom" justify="end">
                <StyledButton itemProp="secondary">
                  {BUTTON_LABELS.EMAIL_AUCTION_BOOK}
                </StyledButton>
                <StyledButton
                  itemProp="secondary"
                  onClick={onConfirmCollection}
                >
                  {BUTTON_LABELS.CONFIRM_COLLECTION}
                </StyledButton>
              </Row>
            </Col>
          </StyledBottomRow>
        </Col>
      </Row>
    </>
  );
};

export default LiveAtStoreDetails;
