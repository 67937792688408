export const CONSUMER_DUTY_TABS = [
  { key: '0', title: 'Case Summary' },
  { key: '1', title: 'Profile Overview' },
  { key: '2', title: 'Service Manager' },
  { key: '3', title: 'Case Notes' },
];

export const CONSUMER_DUTY_CST_TABS = [
  { key: '1', title: 'Profile Overview' },
  { key: '2', title: 'Service Manager' },
  { key: '4', title: 'At Risk Notes' },
];

export const TABS_NAME = {
  CASE_SUMMARY: '0',
  PROFILE_OVERVIEW: '1',
  SERVICE_MANAGER: '2',
  CASE_NOTES: '3',
  AT_RISK_NOTES: '4',
};

export const CONSUMER_DUTY = {
  STORE: 'Store',
  STORE_MANAGER: 'Store Manager',
  SSTEAM: 'SSTeam',
  SST: 'SST',
};

export const SST = {
  SELECT_IDENTIFIERS:
    'Please select any identifiers from the list(s) below. This will assist us in better understanding the customer’s needs and get them the assistance they require. (*)',
  SELECTION_REQUIRED: '(*) Minimum of one selection required',
  CASE_SUMMARY: 'Case Summary',
  MANDATORY: 'Mandatory',
  QUESTION_TITLE: 'Mandatory Questions for SSTeam',
  WHATHAPPENED_QUES:
    'What has happened to stop you from Renewing/Picking up your pledge(s)?',
  ANYONEHELPING_QUES:
    'Is anyone already helping you? (Third-party, financial or medical)',
  OURHELP_QUES: 'How would you like us to help you?',
  EXPECTED_RES_TIME: 'Expected Resolution Time (*)',
  VALIDATE_REQUEST: 'Validate Request',
  READ_TO_CUSTOMER: 'Please read to customer',
  CONSENT_NOTE:
    'In order for us to use this information, do you give me consent to make a note of what we have discussed on your account.',
  EXPLICIT_CONSENT_GRANTED: 'Explicit Consent GRANTED',
  EXPLICIT_CONSENT_REFUSED: 'Explicit Consent REFUSED',
  CASE_HANDLED_NOTE:
    'Based on the selected reasons above, this case can be handled by: ',
  EDIT_CASE: 'Edit Case',
  NEXT: 'Next',
  CONSENT_REFUSED_NOTE:
    'Please complete the questions with due care. Do not note any personal information that the customer has not given consent to and only disclose required accurate information.',
  CONSENT_CHECKBOX:
    'Please select below checkbox if customer happy to give consent.',
  SSTEAM: 'SSTeam',
  WHATHAPPENED: 'What happened',
  ANYONEHELPING: 'Anyone helping',
  OURHELP: 'Our help',
  PLEDGE_PERMISSION: {
    NOTES: 'Notes are mandatory',
    AMOUNT: 'Amount To should be greater than/ equal to Amount To',
    DATE: 'Date From should be less than/ equal to Date To',
    MANDATORY_AMOUNT: 'Amount To is mandatory',
    MANDATORY_DATE: 'Dates are mandatory',
  },
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  CASE_NOTES_UPDATED: 'Customer Support Enquiry - Updated',
};

export const HEADINGS = {
  COMMENTS: 'Comments',
  ADD_COMMENTS: 'Add New Comment',
  VIEW_ADD_DOCS: 'View / Add Document',
  REVIEW_FREQUENCY: 'Set Review Frequency',
  SERVICES_ALLOWED: 'Services allowed for: ',
  RENEWAL: 'Renewal',
  PAYDOWN: 'Paydown',
  PARTIAL_PAY: 'Partial Payment',
  PFI: 'PFI/Auction',
  NEW_PLEDGE: 'New Pledge',
  NEW_PLEDGE_LIMITATION: 'New Pledge with Limitations',
  AMOUNT: 'Amount',
  TIME: 'Time',
  WAIVE_INTEREST: 'Waive Interest',
  WAIVED_INTEREST_HISTORY: 'Waived Interest History',
  DATE_FROM: 'Date From',
  DATE_TO: 'Date To',
  NO_OF_DAYS: 'No. of days',
  REASON: 'Reason',
  STORE_MANAGED: "The customer's status has now been updated to Store Managed ",
  AMOUNT_RANGE: 'Amount Range',
  DATE_RANGE: 'Date Range',
};

export const BUTTONS_LABEL = {
  OK: 'OK',
  RELEASE_ACC: 'Release Account',
  CUST_SUPPORT: 'Customer Support',
  CLOSE: 'Close',
  ESCALATE_TO_SST: 'Escalate to SSTeam',
  SUBMIT_TO_SST: 'Submit to SSTeam',
  CANCEL: 'Cancel',
  SUBMIT: 'Submit',
  CLEAR: 'Clear',
  SAVE: 'Save',
  CAPTURE_DOCS: 'Capture Documents',
  SAVE_UPDATES: 'Save Updates',
  CLOSE_CASE: 'Close Case',
  EXIT: 'Exit',
  NEXT: 'Next',
  PREVIOUS: 'Previous',
  MANAGE_SERVICES: 'Manage Services',
  NEW_PLEDGE_PERMISSION: 'New Pledge Permission',
  SAVE_AS_MANAGED: 'Save as Managed Case',
  CONFIRM: 'Confirm Changes',
  VIEW_PROFILE: 'View Profile',
  REMOVE_PROFANITY: 'Ok',
  MARK_AS_CLSOE_CASE: 'Mark as Close Case',
  DE_ESCALATE_TO_STORE_ASSISTED: 'De-escalate to Store Assisted',
  MANAGE_CASE_BY_SST: 'Manage case by SST',
  MARK_AS_READ: 'Mark as Read',
  CLOSE_RISK: 'Close Risk',
};

export const STORE_MANAGED_SUCCESS_MSG =
  'The customer support request has been saved in the customer notes.';

export const STORE_MANGEED_SUCCESS_TITLE =
  'Support request has successfully been saved';

export const ESCALATED_TO_SSTEAM_SUCCESS_TITLE =
  'Support Request Successfully Sent to SSTeam';

export const ESCALATED_TO_SSTEAM_SUCCESS_MSG =
  'This customer’s account is temporarily frozen while the SSTeam review the request. Upon completion of this review, services and the status of this account will be set and agreed with the customer.';

export const NOTES_REQUIRED = {
  CUSTOMER_CASE_NOTES: 1,
  CUSTOMER_SUPPORT_ID: 0,
};

export const OPTION_TO_DEESCALATE_MSG =
  'Do you wish to de-escalate the case to store managed or keep the case open and managed by the SST? ';

export const CONFIRMATION_TO_DEESCALATE_MSG_ONE =
  'The case will now be de-escalated to Store Assisted and will be removed from the SST managed case list';

export const CONFIRMATION_TO_DEESCALATE_MSG_SECOND =
  'Please contact the store to ensure they are aware.';

export const CASE_SUMMARY_UPDATE_MSG =
  'The identifiers have been updated, please update the case summary';

export const CASE_SAVED_SUCCESSFULLY = 'Case saved successfully!';

export const EXIT_MSG = {
  PRIMARY: 'Are you sure you wish to exit this case?',
  SECONDARY: `The case will remain open in the team's notification work queue`,
};

export const VULNERABILITYORIGINATOR = {
  STORE: 'Store',
  SSTEAM: 'SSTeam',
  CSTeam: 'CSTeam',
};

export const VULNERABILITYOWNER = {
  STORE_MANAGER: 'StoreManager',
  SSTEAM: 'SSTeam',
};

export const TASK_OWNER_VALUE = {
  STORE: 1,
  SSTEAM: 2,
  AT_RISK: 3, //CST
};

export const BLOCKED_LOAN_ACTIONS = {
  RENEWAL: 'Renew',
  PAYDOWN: 'Paydown',
  PARTIAL_PAY: 'Partial Payment',
  PFI: 'PFI/Auction',
};

export const NOTIFICATION_SERVICE_TYPE = {
  RISK: 'CSTeam',
  VULNERABLE: 'SSTeam',
};

export const CONSUMER_DUTY_VIEW_HEADING = {
  CASE_FILE: 'Case File',
  CUSTOMER_RISK_REVIEW: 'Customer Risk Review',
};

export const MANAGED_SERVICES_MSG_ON_CLOSING_CASE =
  'One or more loans are not managed. Please manage all loans to close the case/risk.';

export const NOTES_SUBJECT = {
  SST_NEW_COMMENT: 'New Case Note Added',
};

export const CASE_NOTES_UPDATE = 'Case Notes Update';

export const CD_ERRORS = {
  DATE_RANGE_OVERLAP:
    'The selected date range overlaps with waived interest history date range(s).',
};
