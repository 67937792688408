// for Watches and Handbags
export const ADD_VALUATION = {
  ADD: 'Add',
  UPDATE: 'Update',
  SUBMIT: 'Submit',
  ADD_MORE: 'Add More Details for Valuation',
  UPDATE_MORE: 'Update Details for Valuation',
  VIEW: 'View Valuation Details',
};

export const CATEGORY_PANE_COLOR = [
  { description: 'dust-yellow', checkbox: 'dust-dark-yellow' },
  { description: 'lilac-purple', checkbox: 'lilac-dark-purple' },
  { description: 'cyan', checkbox: 'blue' },
  { description: 'dust-green', checkbox: 'mountain-meadow' },
  { description: 'peach', checkbox: 'dark-peach' },
];

export const CATEGORY_COLORS = [
  'desert-yellow',
  'lilac',
  'lighter-blue',
  'baby-pink',
  'light-green',
];

export const BASE_PLEDGE_CATEGORIES = [
  { id: '310', name: 'Jewellery' },
  { id: '45', name: 'Watches' },
  { id: '695', name: 'Coins/Bullion' },
  { id: '2392', name: 'Handbags' },
];

export const BASE_CATEGORY_BULLION_COIN = 'Bullion/Coin';

export const COIN_SUB_ITEM_CATEGORIES = [
  { id: 697, name: 'Unmounted Bullion Coin' },
];

export const HALLMARKED_OPTIONS = [
  {
    id: 1,
    description: 'HALLMARKED',
    itemFixedAttributes: [
      { id: 'YES', description: 'YES' },
      { id: 'NO', description: 'NO' },
      { id: 'EXEMPT', description: 'EXEMPT' },
    ],
  },
];

export const STONE_ATTRIBUTES = [
  'STONE TYPES',
  'STONE SHAPES',
  'STONE CONDITION', // clarity for UI
  'CLARITY', //colour for UI
];

export const PLEDGE_ITEM = {
  CONDITION: {
    NAME: 'metalCondition',
    LABEL: 'Condition',
  },
  METAL_TYPE: {
    NAME: 'metalType',
    LABEL: 'Metal Type',
  },
  FINENESS: {
    NAME: 'fineness',
    LABEL: 'Fineness',
  },
  HALLMARKED: {
    NAME: 'isHallmark',
    LABEL: 'Hallmark',
  },
  COIN_TYPE: {
    NAME: 'coinType',
    LABEL: 'Coin Type',
  },
  WEIGHT: {
    NAME: 'weightInGrams',
    LABEL: 'Weight',
  },
  NOTES: {
    NAME: 'notes',
    LABEL: 'Notes',
  },
};

export const JEWELLERY_MANDATORY_FIELDS = [
  'metalCondition',
  'metalType',
  'fineness',
  'isHallmark',
  'weightInGrams',
];

export const COINS_OR_WATCHES_MANDATORY_FIELDS = [
  'metalCondition',
  'metalType',
  'fineness',
  'isHallmark',
  'weightInGrams',
];

export const WATCHES_MANDATORY_FIELDS = [
  'metalType',
  'fineness',
  'weightInGrams',
];

export const HANDBAGS_MANDATORY_FIELDS = ['metalCondition'];

export const COIN_MANDATORY_FIELD = ['coinType'];

export const COIN_DISABLED_FIELDS = [
  'metalCondition',
  'metalType',
  'fineness',
  'isHallmark',
  'weightInGrams',
];

export const PLEDGE_ACTIONS = {
  ADD_TO_PLEDGE: 'Add to Pledge',
  ADD_TO_PURCHASE: 'Add to Purchase',
  CLEAR_ITEM: 'Clear Item',
  PUSH_TO_VALUATION: 'Push to Valuation',
  ADD_FROM_HISTORY: 'Add from History',
  PRINT_SEND_QUOTE: 'Print/Send Quote',
  PROCEED_TO_BASKET: 'Proceed to Basket',
  EXIT: 'Exit',
  MOVE_TO_PURCHASE: 'Move to Purchase',
  MOVE_TO_PLEDGE: 'Move to Pledge',
  UPDATE_PLEDGE: 'Update Pledge',
  UPDATE_PURCHASE: 'Update Purchase',
  ADD_FROM_VALUATION: 'Add from Valuation',
};

export const ITEM_TYPE = {
  COINS: 'COINS',
  METAL_TYPES: 'METAL TYPES',
  COIN: 'coin',
};

export const COIN_FROM = {
  ITEM_TABLE: 'itemTable',
  ADD_COIN: 'addCoin',
};

export const TOTAL_ITEM_VALUE = 'Total Item Value';

export const TAKE_ITEM_PHOTO = 'Take Item Photo';

export const ITEM_DESCRIPTION = 'Item Description';
export const PRINT_OR_SEND_QUOTE = 'Print or Send Quote';

export const VALUATION = {
  METAL_VALUE: 'Metal Value',
  COIN_VALUE: 'Coin Value',
  STONES_TOTAL_VALUE: 'Stones Total Value',
  OVERALL_GUIDANCE: 'Overall Guidance',
  DESIRED_AMOUNT: 'Desired Amount',
  VALUATION: 'Valuation',
};

export const ADD_STONE_LABELS = {
  LABEL: 'Add Stone',
  TITLE: 'Add Stone Details',
  IMAGES: 'Stone Images (Optional)',
  STONE_DETAILS: 'Stone Details',
  ANOTHER_STONE: 'Add Another Stone',
};

export const ADD_COIN_LABELS = {
  LABEL: 'Add Coin',
  TITLE: 'Add Coin Details',
  IMAGES: 'Coin Images (Optional)',
  COIN_DETAILS: 'Coin Details',
  ANOTHER_COIN: 'Add Another Coin',
};

export const ADD_MORE_DETAILS_VALUATION = 'Add more details for Valuation';

export const PLEDGE_LOAN = {
  TOTAL_WEIGHT: 'Total Weight',
  LOAN_AMOUNT: 'Loan Amount',
  DAILY_INTEREST: 'Daily Interest',
  LOAN_AMT_PAYABLE: 'Loan Amount Payable',
  TOTAL_AMT_PAYABLE: 'Total Amount Payable',
  ITEM_COUNT: 'Item Count',
  MONTHLY_RATE: 'Monthly Rate',
  APR: 'APR',
};

export const PURCHASE_ITEM_TABLE = {
  TOTAL_WEIGHT: 'Total Weight',
  SALE_AMOUNT: 'Sale Amount',
  FEE: 'Fee',
  RECLAIM_AMT_PAYABLE: 'Reclaim Amount Payable',
  ITEM_COUNT: 'Item Count',
  ITEM_RECLAIM: 'Item available to reclaim until',
  PURCHASE_VALUE: 'Pay to Customer',
};

export const ITEM_ATTRIBUTE_TYPE = {
  NOT_AVAILABLE: 0, //no description as all records marked as deleted = 1
  INPUT: 1, //single line textbox
  TEXTAREA: 2, //multiline textbox
  NUMERIC_TEXT: 4, //numeric value with lower and higher value limit
  DROPDOWN: 5, //dropdown with options values - multiple values to bind based on the comma
  CHECKBOX: 6, //added to support checklist questions for Handbags/Watches
  RADIO: 7, //added to support radio button option for Handbags/Watches
};

export const ITEM_ATTRIBUTE_REQUIRED = {
  YES: 1,
  NO: 0,
};

export const LOWER_VALUE_WARNING =
  'Warning ! The allocated item value is lower than the recommended guidance value';
export const HIGHER_VALUE_WARNING =
  'Warning ! The allocated item value is higher than the recommended guidance value';
export const INCOMPLETE_PLEDGE_WARNING =
  'Are you sure you wish to proceed to basket? The details for the item you have started to create will be lost if you proceed to basket.';
export const HIGHER_VALUATION_WARNING =
  'Warning ! The allocated item value is higher than the recommended valuation.';

export const PARENT_CATEGORIES = {
  JEWELLERY: 'jewellery',
  HANDBAGS: 'handbags',
  WATCHES: 'watches',
  COINS: 'coins',
};

export const ADDITIONAL_DETAILS_ITEMS_LIST = [
  'watches',
  'handbags',
  'jewellery',
  'coins',
];

export const VALUATION_ALERT = {
  MESSAGE: `This item was sent to valuations under a different customer's name, are you sure you want to add it to your current customer's basket?`,
  PRIMARY_BUTTON_LABEL: 'Yes',
  CANCEL_BUTTON_LABEL: 'No',
};

export const PAWNBROKING_ERRORS = {
  TOTAL_ITEM_VALUE: {
    name: ['isTotalItemValue'],
    errors: ['Total item value is required.'],
  },
  DESIRED_AMOUNT: {
    name: ['isDesriedAmount'],
    errors: ['Desired amount is required.'],
  },
  ITEM_IMAGE: { name: ['isItemImage'], errors: ['Item image is required.'] },
};

export const PLEDGE_STATUS = {
  CONFISCATED: 1,
  VULNERABLE: 2,
};

export const PROCEED_TO_BASKET_LOADER_TIP = 'Proceeding to Basket...';

export const COIN_ATTRIBUTE_ID = [70, 71, 72, 73]; // EVB-1569 - enable Add Coins only when these attribute ids are available in response
