import React, { useState } from 'react';
import { format } from 'date-fns';
import VirtualGrid from '../../../common/virtualGrid/index';
import styled from 'styled-components';
import { FNS_DATE_FORMAT, GRID_NO_URL } from 'globalConstants';

const StyledGridWrapper = styled.div`
  height: calc(65vh - 10px);
`;

const TravelMoneySummary = ({ travelMoneySummary }: any) => {
  const [columns] = useState([
    {
      name: 'transactionType',
      title: 'Service',
      type: 'icon',
      displayContent: true,
      getCellValue: (row: any) => row.transactionType,
    },
    {
      name: 'transactionDate',
      title: 'Transaction Date',
      getCellValue: (row: any) =>
        format(new Date(row.transactionDate), FNS_DATE_FORMAT),
    },
    {
      name: 'fromCurrencyCode',
      title: 'Currency From',
      type: 'currency-tooltip',
      getCellValue: (row: any) => ({
        currencyCode: row?.fromCurrencyCode,
        amount: row?.fromAmount,
        currencyDesc: row?.fromCurrencyDescription,
      }),
    },

    {
      name: 'currencyExchangeRate',
      title: 'Exchange Rate',
      getCellValue: (row: any) => row?.currencyExchangeRate,
    },
    {
      name: 'rateGurantee',
      title: 'Gurantee',
      getCellValue: (row: any) => row?.rateGurantee,
    },
    {
      name: 'toCurrencyCode',
      title: 'Currency To',
      type: 'currency-tooltip',
      getCellValue: (row: any) => ({
        currencyCode: row?.toCurrencyCode,
        amount: row?.toAmount,
        currencyDesc: row?.toCurrencyDescription,
      }),
    },
    {
      name: 'location',
      title: 'Location',
      getCellValue: (row: any) => row.storeLocation,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'transactionType', width: '14%' },
    { columnName: 'transactionDate', width: '14%' },
    { columnName: 'fromCurrency', width: '8%', align: 'right' },
    { columnName: 'currencyExchangeRate', width: '10%', align: 'right' },
    { columnName: 'rateGurantee', width: '10%', align: 'right' },
    { columnName: 'toCurrency', width: '8%', align: 'right' },
    { columnName: 'storeLocation', width: '32%', align: 'right' },
  ]);
  const [formattedColumns] = useState([
    'fromCurrencyCode',
    'toCurrencyCode',
    'transactionType',
  ]);
  const onSelectionChange = () => { /* do nothing */ };
  const getRowId = (row: any) => row.storeNumber;

  return (
    <>
      <StyledGridWrapper className="customer-travel-money-table">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          formattedColumns={formattedColumns}
          rowData={travelMoneySummary}
          rowSelectionEnable={false}
          showSelectAll={false}
          onSelectionChange={onSelectionChange}
          getRowId={getRowId}
        />
      </StyledGridWrapper>
    </>
  );
};

export default TravelMoneySummary;
