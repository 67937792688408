import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import { Row, Col, Dropdown, Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  REMOVE,
  ADD_PLEDGE,
  BASKET_ADD_PLEDGE,
  REMOVE_ACTION_TYPES,
  ENABLE_CHILD_TABS,
  REMOVE_PLEDGE_ALERT,
  BASKET_SERVICE_TYPE,
  REMOVE_PURCHASE_ALERT,
  REMOVE_PICKUP_ALERT,
  REMOVE_ALERT,
  REMOVE_CHEQUE_CASHING_ALERT,
  ENABLE_ADD_ANOTHER_BUTTON,
  BASKET_TABS,
} from '../constants';
import {
  MOVE_BASKET_PLEDGE_PURCHASE_ITEMS,
  SPLIT_BASKET_PLEDGE_ITEMS,
} from 'action_creators/basket';
import { DownOutlined } from '@ant-design/icons';
import { actions } from 'redux/reducers/basket';
import { useNavigate } from 'react-router-dom';
import { ROUTE_CONFIG, SERVICE_TYPE_LABEL_BY_KEY } from 'globalConstants';
import {
  findValueByKey,
  getPledgeAgreementItemsArray,
  getPurchaseReturnItems,
} from 'utils/util';
import { actions as pickupActions } from 'redux/reducers/pickupRenew';
import { actions as retailActions } from 'redux/reducers/retail';
import { pledgeActions } from 'redux/reducers/pawnbroking';
import { coinActions } from 'redux/reducers/pawnbroking/coin';
import { stoneActions } from 'redux/reducers/pawnbroking/stone';
import { actions as chequeCashingActions } from 'redux/reducers/chequeCashing';
import ControlledAlert from 'components/common/ControlledAlert';
import {
  StyledHelpPopoverInfoForButtons,
  HelpPopoverInfo,
} from 'components/common/HelpPopoverInfo';
const StyledCustomButton = styled(Button)`
  margin: 0 5px 0 0;
  /* margin: 0 25px 0 0; */
`;

const ContainerButtons = ({
  item,
  basketId,
  userId,
  keyIndex = 0,
  keyName,
  isSplitLoading,
  itemIds = [],
  serviceIds = [],
}: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    basket: { isLoading, basket, mergedPayload },
  } = useSelector((state: any) => {
    return {
      basket: state.basket,
    };
  });
  const [visible, setVisible] = useState<boolean>(false);

  //action type get remove action as per keyname like pledge, retail etc
  //Please configure remove action type when new module add

  const removeActionType = findValueByKey(REMOVE_ACTION_TYPES, keyName);

  const onRemoveClicked = () => {
    if (
      itemIds?.length > 0 ||
      serviceIds?.length > 0 ||
      ENABLE_CHILD_TABS.includes(keyName) // itemIds or serviceIds not required for removing pledge & purchase
    )
      setVisible(true);
  };

  const onSplitPledgeClicked = () => {
    const payload = {
      basketId: basketId,
      serviceId: item?.serviceId,
      itemIds: [],
      userId: userId,
    };
    dispatch({
      type: SPLIT_BASKET_PLEDGE_ITEMS,
      payload: { params: payload },
    });
    dispatch(actions.setResetChildTabOnSplitAdd(true));
  };

  const onAddNewItemToPledgeClick = () => {
    dispatch(actions.addPledgeItemsFromBasket(true));
    dispatch(pledgeActions.updateServiceType(item?.serviceType));
    dispatch(pledgeActions.clearItems());
    dispatch(coinActions.clearCoinItems());
    dispatch(stoneActions.clearStoneItems());
    if (item?.serviceType === BASKET_SERVICE_TYPE.PLEDGE) {
      dispatch(pledgeActions.updatePledgeServiceId(item?.serviceId));
    } else dispatch(pledgeActions.updatePurchaseServiceId(item?.serviceId));
    navigate(`/${ROUTE_CONFIG.PLEDGE}`);
  };

  const onCreateNewPledgeClick = () => {
    dispatch(actions.addPledgeItemsFromBasket(true));
    dispatch(pledgeActions.updateServiceType(item?.serviceType));
    dispatch(pledgeActions.clearItems());
    dispatch(coinActions.clearCoinItems());
    dispatch(stoneActions.clearStoneItems());
    navigate(`/${ROUTE_CONFIG.PLEDGE}`);
  };

  const goBackClicked = () => {
    //back code
    switch (item?.serviceType) {
      case BASKET_SERVICE_TYPE.RETAIL:
        navigate(`/${ROUTE_CONFIG.RETAILSALES}`);
        break;
      case BASKET_SERVICE_TYPE.FX:
        navigate(`/${ROUTE_CONFIG.TRAVEL_MONEY}`);
        break;
      case BASKET_SERVICE_TYPE.CHEQUECASHING:
        navigate(`/${ROUTE_CONFIG.CHEQUE_CASHING}`);
        dispatch(chequeCashingActions.clearItems());
        break;
      case BASKET_SERVICE_TYPE.WU:
        navigate(`/${ROUTE_CONFIG.WESTERN_UNION}`);
        break;
    }
  };

  const onAddEditClicked = () => {
    switch (item?.serviceType) {
      case BASKET_SERVICE_TYPE.PICKUPRENEW: {
        const items = getPledgeAgreementItemsArray(basket?.basketServices);
        dispatch(pickupActions.addPledgeAgreement(items));
        navigate(`/${ROUTE_CONFIG.PICKUPRENEW}`);
        break;
      }
      case BASKET_SERVICE_TYPE.RETAIL:
        dispatch(
          retailActions.retailItemAddToBasket(item?.retail?.request?.items)
        );
        dispatch(
          retailActions.retailItemResponse(item?.retail?.request?.items)
        );
        navigate(`/${ROUTE_CONFIG.RETAILSALES}`);
        break;
      case BASKET_SERVICE_TYPE.PURCHASERETURN: {
        const purchaseItems = getPurchaseReturnItems(basket?.basketServices);
        dispatch(pickupActions.addPurchaseReturn(purchaseItems));
        navigate(`/${ROUTE_CONFIG.PICKUPRENEW}`);
        break;
      }
      default:
        return;
    }
  };

  const onCancelClicked = () => {
    setVisible(false);
  };

  const onYesClicked = () => {
    const payload = {
      basketId: basketId,
      serviceId: item?.serviceId,
      itemIds: itemIds,
      serviceIds: serviceIds,
      userId: userId,
    };
    dispatch({
      type: removeActionType,
      payload: { params: payload },
    });
    setVisible(false);
  };

  const getAlertMessage = () => {
    switch (item?.serviceType) {
      case BASKET_SERVICE_TYPE.PLEDGE:
        return REMOVE_PLEDGE_ALERT;
      case BASKET_SERVICE_TYPE.PURCHASE:
        return REMOVE_PURCHASE_ALERT;
      case BASKET_SERVICE_TYPE.PICKUPRENEW:
        return REMOVE_PICKUP_ALERT;
      case BASKET_SERVICE_TYPE.RETAIL:
      case BASKET_SERVICE_TYPE.FX:
      case BASKET_SERVICE_TYPE.WU:
      case BASKET_SERVICE_TYPE.PURCHASERETURN:
      case BASKET_SERVICE_TYPE.SURPLUS:
        return REMOVE_ALERT;
      case BASKET_SERVICE_TYPE.CHEQUECASHING:
        return REMOVE_CHEQUE_CASHING_ALERT;
    }
  };

  const menuItems = [
    {
      label: `${BASKET_ADD_PLEDGE.ADD_NEW_ITEM_TO_PLEDGE} ${keyName}`,
      key: '1',
      onClick: onAddNewItemToPledgeClick,
    },
    {
      label: `${BASKET_ADD_PLEDGE.CREATE_NEW_PLEDGE} ${keyName}`,
      key: '2',
      onClick: onCreateNewPledgeClick,
    },
  ];

  const DropdownMenu = <Menu items={menuItems}></Menu>;

  const anotherButtonLabel = ENABLE_ADD_ANOTHER_BUTTON.find((itm) =>
    keyName.startsWith(itm)
  );

  const onAddToPledgePurchaseCliked = () => {
    const payload = {
      basketId,
      sourceServiceId: mergedPayload.sourceServiceId,
      sourceServiceType: item.serviceType,
      targetServiceId: null,
      itemIds: mergedPayload.itemIds,
      userId,
    };

    const activeTab =
      item.serviceType === BASKET_SERVICE_TYPE.PLEDGE
        ? BASKET_SERVICE_TYPE.PURCHASE
        : BASKET_SERVICE_TYPE.PLEDGE;

    dispatch(actions.setCurrentActiveTab(`${activeTab}`));
    dispatch({
      type: MOVE_BASKET_PLEDGE_PURCHASE_ITEMS,
      payload: { params: payload },
    });
  };

  return (
    <>
      <Row>
        <Col span={24}>
          {ENABLE_CHILD_TABS.includes(keyName) && (
            <>
              <StyledHelpPopoverInfoForButtons
                linkedID={`BASKET_${item?.serviceType}_Add`}
                position="rightTop"
                helpPosition="AFTER"
              >
                <Dropdown overlay={DropdownMenu}>
                  <StyledCustomButton itemProp="secondary">
                    {ADD_PLEDGE} <DownOutlined />
                  </StyledCustomButton>
                </Dropdown>
              </StyledHelpPopoverInfoForButtons>
              <StyledHelpPopoverInfoForButtons
                linkedID={`BASKET_${item?.serviceType}_Split`}
                position="rightTop"
                helpPosition="AFTER"
              >
                <StyledCustomButton
                  onClick={onSplitPledgeClicked}
                  itemProp="secondary"
                  loading={isSplitLoading}
                >
                  Split {keyName}
                </StyledCustomButton>
              </StyledHelpPopoverInfoForButtons>
              <StyledHelpPopoverInfoForButtons
                linkedID={`BASKET_${item?.serviceType}_Move`}
                position="rightTop"
                helpPosition="AFTER"
              >
                <StyledCustomButton
                  disabled={!mergedPayload?.itemIds?.length}
                  onClick={onAddToPledgePurchaseCliked}
                  itemProp="secondary"
                >
                  Add to{' '}
                  {item?.serviceType === BASKET_SERVICE_TYPE.PLEDGE
                    ? SERVICE_TYPE_LABEL_BY_KEY.PURCHASE
                    : SERVICE_TYPE_LABEL_BY_KEY.PLEDGE}
                </StyledCustomButton>
              </StyledHelpPopoverInfoForButtons>
              <StyledHelpPopoverInfoForButtons
                linkedID={`BASKET_${item?.serviceType}_Remove`}
                position="rightTop"
                helpPosition="AFTER"
              >
                <StyledCustomButton
                  onClick={onRemoveClicked}
                  itemProp="secondary"
                >
                  {REMOVE} this {keyName}
                </StyledCustomButton>
              </StyledHelpPopoverInfoForButtons>
            </>
          )}
          {!ENABLE_CHILD_TABS.includes(keyName) && (
            <>
              {item?.serviceType === BASKET_SERVICE_TYPE.PICKUPRENEW ||
              item?.serviceType === BASKET_SERVICE_TYPE.PURCHASERETURN ||
              item?.serviceType === BASKET_SERVICE_TYPE.RETAIL ? (
                <span style={{ marginRight: '20px' }}>
                  <HelpPopoverInfo
                    linkedID={`BASKET_${item?.serviceType}_${keyName}`}
                    position="rightTop"
                    style={{
                      float: 'left',
                      marginTop: '10px',
                      display: 'inline',
                    }}
                  >
                    <StyledCustomButton
                      onClick={onAddEditClicked}
                      itemProp="secondary"
                    >
                      Add/Edit {keyName}
                    </StyledCustomButton>
                  </HelpPopoverInfo>
                </span>
              ) : keyName !== BASKET_TABS[16] ? (
                <HelpPopoverInfo
                  linkedID={`BASKET_${item?.serviceType}_${keyName}`}
                  position="rightTop"
                  style={{
                    float: 'left',
                    marginTop: '10px',
                    display: 'inline',
                  }}
                >
                  <StyledCustomButton
                    onClick={goBackClicked}
                    itemProp="secondary"
                  >
                    {anotherButtonLabel
                      ? `Add another ${anotherButtonLabel}`
                      : `Back to ${keyName}`}
                  </StyledCustomButton>
                </HelpPopoverInfo>
              ) : null}
              <HelpPopoverInfo
                linkedID={`BASKET_REMOVE`}
                position="rightTop"
                style={{
                  float: 'left',
                  marginTop: '10px',
                  display: 'inline',
                }}
              >
                <StyledCustomButton
                  onClick={onRemoveClicked}
                  itemProp="secondary"
                  loading={isLoading}
                >
                  {REMOVE} from Basket
                </StyledCustomButton>
              </HelpPopoverInfo>
            </>
          )}
        </Col>
      </Row>
      <ControlledAlert
        visible={visible}
        message={getAlertMessage()}
        onClick={onYesClicked}
        onCancel={onCancelClicked}
      />
    </>
  );
};

export default ContainerButtons;
