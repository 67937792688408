import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'antd';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import { useSelector, useDispatch } from 'react-redux';
import { joinValues } from 'utils/util';
import { actions } from 'redux/reducers/pickupRenew';

const StyledModal = styled(Modal)`
  & .ant-modal-title {
    color: var(--red);
    text-align: center;
  }
  & .ant-modal-body {
    & p {
      font-size: var(--font-size-16);
    }
    text-align: center;
  }
  & .ant-modal-footer {
    text-align: center;
  }
`;

interface ChangeOwnershipPopupProps {
  visible: boolean;
  setChangeOwnershipAttnPopupVisible: () => void;
}

const ChangeOwnershipPopup = ({
  visible,
  setChangeOwnershipAttnPopupVisible,
}: ChangeOwnershipPopupProps) => {
  const {
    customer: { customer },
    pickupRenew: { pledgeAgreement, selectedAgreementRowId },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
      pickupRenew: state.pickupRenew,
    };
  });

  const dispatch = useDispatch();
  const [agreement, setAgreement] = useState<any>({});
  const fullName = joinValues([customer.firstNames, customer.surname], true);

  const {
    street,
    town,
    property,
    county,
    postcode,
    houseName,
    area,
    firstNames,
    middleName,
    surname,
  } = customer;

  const onCancelClick = () => {
    setChangeOwnershipAttnPopupVisible();
    dispatch(actions.setSelectedAgreementRow([]));
  };

  const onOkClick = () => {
    const updatedAgreement = {
      ...agreement,
      customerPresent: true,
      changeOwnership: {
        ...agreement.changeOwnership,
        newCustomerId: customer.customerId,
        newCustomerName: fullName,
        isOwnershipChanged: true,
        customer: {
          street,
          town,
          property,
          county,
          postcode,
          houseName,
          area,
          firstNames,
          middleName,
          surname,
        },
      },
    };
    dispatch(actions.updatePledgeAgreement(updatedAgreement));
    dispatch(actions.setSelectedAgreementRow([]));
    setChangeOwnershipAttnPopupVisible();
  };

  useEffect(() => {
    if (selectedAgreementRowId?.length === 1) {
      const index = pledgeAgreement.findIndex(
        (x: any) => x.id === selectedAgreementRowId[0]
      );
      setAgreement(pledgeAgreement[index]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StyledModal
        title="Attention!"
        open={visible}
        width={457}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        footer={[
          <Row gutter={24} justify="center" key="changeOwner">
            <Col>
              <Button
                itemProp="secondary"
                key="cancelOwner"
                onClick={onCancelClick}
              >
                No
              </Button>
            </Col>
            <Col>
              <Button key="confirmOwner" htmlType="submit" onClick={onOkClick}>
                Yes
              </Button>
            </Col>
          </Row>,
        ]}
        centered
      >
        <Row>
          <Col span={24}>
            <p>
              {`This will change ownership of the selected Pledge No. (${agreement.loanNumber})
              to ${fullName} and allow for Renewal or Paydown.`}
            </p>

            <p>{`Are you sure you wish to continue?`}</p>
          </Col>
        </Row>
      </StyledModal>
    </>
  );
};

export default ChangeOwnershipPopup;
