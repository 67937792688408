import './index.less';

import {
  ADD,
  AMOUNT_TITLE,
  CHEQUE_AUTH_REQUEST_SENT_SUCCESSFULLY,
  CHEQUE_AUTH_REQUEST_SENT_SUCCESSFULLY_SECOND,
  HEADER_TITLE,
} from './constants';
import {
  BASKET_SERVICE_TYPE,
  FOOTER_BUTTONS_AND_TEXT,
  ROUTE_CONFIG,
  SERVICE_TYPE,
} from 'globalConstants';
import {
  CREATE_BASKET_CHEQUE_CASHING_ITEMS,
  EDIT_BASKET_CHEQUE_CASHING_ITEMS,
} from 'action_creators/basket';
import { EDIT_CHEQUE_CASHING_ITEM } from 'action_creators/chequeCashing';
import { Col, Input, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Alert from 'components/common/ControlledAlert';
import { Button } from 'components/common/Button';
import CustomerDetails from './CustomerDetails';
import CustomerHeader from 'components/customer/customerHeader';
import CustomerSmartSearch from 'components/layout/CustomerSmartSearch';
import FooterButtons from 'components/common/footerButtons';
import PartPaymentPopup from './PartPayment';
import QuickQuote from './QuickQuote';
import { actions } from 'redux/reducers/chequeCashing';
import { actions as basketActions } from 'redux/reducers/basket';
import { actions as customerActions } from 'redux/reducers/customer';
import { isEmpty, isNull } from 'lodash';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { getChequeCashingOverview } from 'services/chequeCashing';

const StyledHeaderCol = styled(Col)`
  cursor: pointer;
`;

const StyledRow = styled(Row)`
  background: var(--cyan);
  padding: 10px 5px;
  width: calc(100% + 40px);
  position: relative;
  right: 20px;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-16);
  text-align: center;
  align-items: center;
`;

const StyledInput = styled(Input)`
  &::placeholder {
    font-weight: var(--font-weight-500);
    color: var(--black);
  }
  & .ant-input-group-addon {
    width: 48px;
    background: var(--catskill-white);
    color: var(--black);
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-16);
    background: var(--solitude);
  }
  & input {
    height: 42px;
    color: var(--black);
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-16);
    background: var(--solitude);
    text-align: right !important;
  }
`;

const StyledChequeCashingColumn = styled.div<{ $isLeft: boolean }>`
  background: var(--white);
  border: 0.6px solid var(--linkwater);
  border-bottom: 1px solid var(--linkwater);
  height: calc(100vh - 212px);
  box-shadow: 0px 1px 10px rgba(1, 78, 169, 0.2);
  border-radius: 5px 5px 0 0;
  padding: ${(props) => (props.$isLeft ? '20px 20px 0px' : '20px')};
  color: var(--black);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  font-weight: var(--font-weight-500);
`;

const ChequeCashing = () => {
  const [customerName] = useState<string>('');
  const [totalAmount, setTotalAmount] = useState<any>(0.0);
  const [amount, setAmount] = useState<any>(0.0);
  const [isExitOnly, setIsExitOnly] = useState<boolean>(true);
  const [isPartPaymentClicked, setPartPaymentClicked] =
    useState<boolean>(false);
  const [customerToSearch, setCustomerToSearch] = useState<any>({});
  const [isImportCustomer, setIsImportCustomer] = useState<boolean>(false);
  const [isSupportCenterClicked, setIsSupportCenterClicked] =
    useState<boolean>(false);

  const [isDisableProcBasket, setDisableProcBasket] = useState<boolean>(false);
  const [isDisableSendToSupport, setDisableSendToSupport] =
    useState<boolean>(false);
  const [isAmountOverPayout, setIsAmountOverPayout] = useState<boolean>(false);
  const [importedRowData, setImportedRowData] = useState<any>({});

  const {
    basket: { basket },
    customer: { customer },
    user,
    chequeCashing: {
      preAuthText,
      preAuthStatusCode,
      quickQuote,
      chequeDetails,
      chequeItemDetails,
      editRowServiceId,
      documentsImages,
      docNotes,
      sentToSupportCenter,
      chequeRetainedAmount,
      chequeApprovedAmount,
      isPreAuthPassed,
      partPaymentClicked,
      paymentDataIndex,
      isProceedToBasket,
      isApprovedEditable,
    },
  } = useSelector((state: any) => {
    return {
      basket: state.basket,
      customer: state.customer,
      user: state.user,
      chequeCashing: state.chequeCashing,
    };
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (sentToSupportCenter) {
      dispatch(actions.setApprovedAmount('0.00'));
      dispatch(actions.setRetainedAmount('0.00'));
    } else {
      setIsSupportCenterClicked(false);
    }
  }, [sentToSupportCenter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (chequeItemDetails?.amount) {
      setAmount(chequeItemDetails?.amount);
      onAddButtonClick();
    } else {
      setAmount(0.0);
    }
  }, [totalAmount, chequeItemDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (customer?.customerId) getCustomerChequeCashingHistory();
  }, [customer?.customerId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isNull(preAuthStatusCode)) setIsExitOnly(false);
  }, [preAuthStatusCode]);

  useEffect(() => {
    if (
      partPaymentClicked ||
      chequeDetails?.totalFee ||
      isNull(preAuthStatusCode) ||
      docNotes === '' ||
      documentsImages?.length === 0 ||
      isSupportCenterClicked
    )
      setDisableSendToSupport(true);
    else setDisableSendToSupport(false);

    if (
      ((preAuthStatusCode !== 0 || isNull(preAuthStatusCode)) &&
        !chequeDetails?.itemFee) ||
      (editRowServiceId && !isApprovedEditable && preAuthStatusCode !== 0) ||
      isAmountOverPayout
    )
      setDisableProcBasket(true);
    else setDisableProcBasket(false);
  }, [
    partPaymentClicked,
    chequeDetails,
    docNotes,
    preAuthStatusCode,
    editRowServiceId,
    isApprovedEditable,
    isAmountOverPayout,
    documentsImages,
    isSupportCenterClicked,
  ]);

  const getCustomerChequeCashingHistory = async () => {
    try {
      const response = await getChequeCashingOverview(customer?.customerId);
      if (response?.data)
        dispatch(actions.setIsChequeCashingHistoryExists(true));
    } catch (e: any) {
      //do nothing
    }
  };

  const onFindCustomerClick = () => {
    // to be implemented later
  };

  const onAddButtonClick = () => {
    setAmount(totalAmount);
    dispatch(actions.setRequestedAmount(totalAmount));
    if (chequeItemDetails?.preAuthorization === 0) {
      dispatch(
        actions.setApprovedAmount(
          chequeApprovedAmount ? chequeApprovedAmount : totalAmount
        )
      );
    }
  };
  const getTotalAmount = useCallback((res: any) => {
    setTotalAmount(res);
  }, []);

  const createChequeCashingBasket = (
    redirectToBasket = true,
    sendToSupportCenter = false,
    isExitAndSave = false
  ) => {
    const basketItems: any = [];
    basketItems.push({
      issuer: chequeDetails?.issuerDetails,
      issuerId: chequeDetails?.issuerDetails?.issuerId,
      issuerName: chequeDetails?.issuerDetails?.issuerName,
      issuerClassId: chequeDetails?.issuerDetails?.issuerClassId,
      itemDocumentCollections: {
        documents: documentsImages,
      },
      transactionDate: chequeItemDetails?.transactionDate || new Date(),
      chequeNumber: chequeDetails?.chequeNumber,
      sortCode: chequeDetails?.sortCode,
      accountNumber: chequeDetails?.accountNumber,
      amount: Number(quickQuote?.chequeAmount),
      serviceFee: Number(quickQuote?.serviceFee),
      itemFee: Number(quickQuote?.itemFee || chequeItemDetails?.itemFee),
      cardFee: 0,
      clearanceFee: 0,
      miscFee: 0,
      note: docNotes || chequeItemDetails?.note,
      partPaymentStatus: 0,
      chequeDate: chequeDetails?.chequeDate,
      classId:
        chequeDetails?.chequeTypeDetails?.chequeTypeId ||
        chequeItemDetails?.classId,
      badClassId: chequeDetails?.issuerDetails?.badClassId,
      bankedDate: null,
      miscFeeNote: '',
      batchId: 0,
      searchFee: 0,
      chequeId: 0,
      otherClassText: '',
      preAuthorization: preAuthStatusCode,
      preAuthText,
      discountAmount: 0,
      chequeTypeName:
        chequeDetails?.chequeTypeDetails?.description ||
        chequeItemDetails?.chequeTypeName,
      retained:
        sendToSupportCenter && !isPreAuthPassed
          ? 0
          : Number(chequeRetainedAmount),
      retainedAmount:
        sendToSupportCenter && !isPreAuthPassed
          ? 0
          : Number(chequeRetainedAmount),
      payout: Number(chequeApprovedAmount),
      totalAmount: Number(chequeDetails?.amount) || 0,
      itemId: chequeDetails?.itemId || chequeItemDetails?.itemId,
    });

    const params = {
      basketId: basket?.basketId || null,
      customerId: customer?.customerId,
      customer: {
        firstNames: customer?.firstNames,
        middleName: customer?.middleName,
        surname: customer?.surname,
        property: customer?.property,
        houseName: customer?.houseName,
        street: customer?.street,
        area: customer?.area,
        town: customer?.town,
        county: customer?.county,
        postcode: customer?.postcode,
        mobile: customer?.mobile,
        email: customer?.emailAddress,
        dateOfBirth: customer?.dateOfBirth,
        telephone: customer?.telephone,
        marketingEmail: customer?.marketingEmailAccepted || false,
        marketingSMS: customer?.marketingSmsAccepted || false,
        postalMarketing: customer?.marketingPostAccepted || false,
        registerTPC: customer?.isRegisteredTpc || false,
      },
      sendToSupportCenter: editRowServiceId
        ? chequeItemDetails?.sendToSupportCenter
        : sendToSupportCenter,
      isPartPayment: partPaymentClicked,
      isExitAndSave: chequeItemDetails?.isExitAndSave
        ? chequeItemDetails?.isExitAndSave
        : isExitAndSave,
      storeId: user?.selectedStore?.storeId,
      storeAddress: user?.selectedStore?.storeAddress,
      storePostalCode: user?.selectedStore?.postalCode,
      storeTelephone: user?.selectedStore?.telephone,
      userId: user?.user?.userId,
      userName: user?.user?.displayName,
      serviceId: editRowServiceId || null,
      serviceType: BASKET_SERVICE_TYPE.CHEQUECASHING,
      items: basketItems,
      itemId: chequeDetails?.itemId || chequeItemDetails?.itemId,
      redirectToBasket: redirectToBasket,
    };

    dispatch(
      basketActions.setIsPartPayment(
        paymentDataIndex?.totalFee ? true : partPaymentClicked
      )
    );
    if (sendToSupportCenter) {
      dispatch(actions.setSendToSupportCenterClicked(true));
    }

    if (!sentToSupportCenter || editRowServiceId) {
      dispatch({
        type: editRowServiceId
          ? EDIT_BASKET_CHEQUE_CASHING_ITEMS
          : CREATE_BASKET_CHEQUE_CASHING_ITEMS,
        payload: params,
      });
    }
  };

  useEffect(() => {
    if (isProceedToBasket) {
      dispatch(actions.clearItems());
      navigate(`/${ROUTE_CONFIG.BASKET}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProceedToBasket]);

  const onProceedClick = () => {
    createChequeCashingBasket();
  };

  const onConfirmExitOnlyClick = () => {
    dispatch(actions.clearItems());
  };

  const onSendToSupportCenterClick = () => {
    createChequeCashingBasket(false, true);
    setIsSupportCenterClicked(true);
  };

  const onPartPaymentClicked = () => {
    setPartPaymentClicked(true);
  };

  const customerToImport = (id: number, name: string, importRowData: any) => {
    if (isEmpty(customer) || !customer?.customerId) {
      setCustomerToSearch({ id: id, name: name });
      setIsImportCustomer(true);
      setImportedRowData(importRowData);
    } else if (customer?.customerId) {
      setImportedRowData(importRowData);
      dispatch({
        type: EDIT_CHEQUE_CASHING_ITEM,
        payload: importRowData,
      });
    }
    setPartPaymentClicked(false);
  };

  const onOKClick = () => {
    navigate(`/${ROUTE_CONFIG.BASKET}`);
    setIsSupportCenterClicked(false);
    //dispatch(actions.clearDocumentsData());
    onConfirmExitOnlyClick();
    dispatch(basketActions.clearItems());
    dispatch(customerActions.clearAll({}));
    // history.push(`/`);
  };

  const onCustomerYesClick = () => {
    dispatch({
      type: EDIT_CHEQUE_CASHING_ITEM,
      payload: importedRowData,
    });
  };

  return (
    <div className="cheque-cashing-wrapper">
      <StyledHeaderCol onClick={onFindCustomerClick}>
        <CustomerHeader
          title={HEADER_TITLE}
          name={customerName}
          preferredName=""
          buttonsOption={true}
          profilePic={true}
          helpSection="CHEQUE"
        />
      </StyledHeaderCol>
      <Row className="main-container-wrapper" gutter={16}>
        <Col className="container-column" span={6}>
          <StyledChequeCashingColumn $isLeft={true}>
            <Row>
              <Col span={24}>
                <QuickQuote
                  totalAmount={getTotalAmount}
                  setAmount={setAmount}
                ></QuickQuote>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <StyledRow>
                  <Col span={5}>{AMOUNT_TITLE}</Col>
                  <Col span={13}>
                    <StyledInput
                      placeholder={'0.00'}
                      addonBefore={'£'}
                      value={parseFloat(
                        totalAmount < 0 ? 0 : totalAmount
                      ).toFixed(2)}
                      disabled={partPaymentClicked}
                    />
                  </Col>
                  <Col span={6}>
                    <Button
                      itemProp="secondary"
                      key="add"
                      onClick={onAddButtonClick}
                      disabled={
                        totalAmount > 0 && !partPaymentClicked ? false : true
                      }
                    >
                      {ADD}
                    </Button>
                  </Col>
                </StyledRow>
              </Col>
            </Row>
          </StyledChequeCashingColumn>
          <FooterButtons
            extraButtonEanable={true}
            extraButtonDisabled={partPaymentClicked || isPreAuthPassed}
            extraButtonText="Part Payment"
            extraButtonAlign="left"
            extraButtonJustifyContent={true}
            extraButtonItemProp="secondary"
            extraButtonOnClick={onPartPaymentClicked}
            isProceedButtonRequired={false}
            helpSection="CHEQUE"
            isExitOnly={isExitOnly}
            isSaveJourney={true}
            onConfirmExitClick={onConfirmExitOnlyClick}
            saveJourneyHandler={() =>
              createChequeCashingBasket(false, false, true)
            }
          ></FooterButtons>
        </Col>
        <Col className="container-column" span={18}>
          <StyledChequeCashingColumn $isLeft={false}>
            <CustomerDetails
              totalAmount={amount}
              setDisableProcBasket={setIsAmountOverPayout}
            ></CustomerDetails>
          </StyledChequeCashingColumn>
          <FooterButtons
            isExitRequired={false}
            onProceedClick={onProceedClick}
            isDisabled={isDisableProcBasket}
            proceedButtonText={FOOTER_BUTTONS_AND_TEXT.PROCEED_TO_BASKET}
            extraButtonText={'Send To Support Centre'}
            extraButtonOnClick={onSendToSupportCenterClick}
            extraButtonEanable={true}
            extraButtonDisabled={isDisableSendToSupport}
            extraButtonItemProp="secondary"
            isProceedButtonRequired={true}
            helpSection="CHEQUE"
            isSaveJourney={true}
          ></FooterButtons>
        </Col>
      </Row>
      {isPartPaymentClicked && (
        <PartPaymentPopup
          visible={isPartPaymentClicked}
          setFAQPopupVisible={() => setPartPaymentClicked(false)}
          customerToImport={customerToImport}
        />
      )}
      {isImportCustomer && (
        <CustomerSmartSearch
          width={24}
          serviceType={SERVICE_TYPE.CHEQUE_CASHING}
          singleCustomerToFind={customerToSearch}
          setIsImportCustomer={() => setIsImportCustomer(false)}
          isImportCustomer={isImportCustomer}
          onCustomerYesClick={onCustomerYesClick}
        />
      )}
      {isSupportCenterClicked && sentToSupportCenter && (
        <Alert
          visible={true}
          width={557}
          yesButtonText={'OK'}
          yesButtonDisabled={!isSupportCenterClicked}
          itemProp={'secondary'}
          contentMessage={CHEQUE_AUTH_REQUEST_SENT_SUCCESSFULLY}
          alertMessage={''}
          message={CHEQUE_AUTH_REQUEST_SENT_SUCCESSFULLY_SECOND}
          isCancelBtn={false}
          onClick={onOKClick}
        />
      )}
    </div>
  );
};

export default ChequeCashing;
