import { Checkbox, Col, Modal, Row, notification } from 'antd';
import { Button } from 'components/common/Button';
import {
  BUTTONS_LABEL,
  CASE_SUMMARY_UPDATE_MSG,
  CONFIRMATION_TO_DEESCALATE_MSG_ONE,
  CONFIRMATION_TO_DEESCALATE_MSG_SECOND,
  CONSUMER_DUTY,
  HEADINGS,
  NOTES_REQUIRED,
  NOTIFICATION_SERVICE_TYPE,
  OPTION_TO_DEESCALATE_MSG,
  SST,
  TASK_OWNER_VALUE,
  VULNERABILITYORIGINATOR,
  VULNERABILITYOWNER,
} from 'components/consumerDuty/constants';

import { isEmpty, isNull, toString } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getVulnerabilityIndicator,
  getVulnerabilityResolutionTime,
  postCustomerSupportRequest,
  postVulnerabilityValidator,
  postEscalateToSST,
  postUpdateCase,
  deleteCaseFromWorkqueue,
  getCustomerNotes,
  postEscalateCstToSst,
} from 'services/consumerDuty';

import styled from 'styled-components';
import { cacheStaticData } from 'utils/cacheServices';
import CDFooter from './footer';
import ControlledAlert from 'components/common/ControlledAlert';
import { useSelector } from 'react-redux';

import {
  GET_CASE_SUMMARY,
  RESET_CUSTOMER_DATA,
} from 'action_creators/consumerDuty';
import { actions as consumerDutyActions } from 'redux/reducers/consumerDuty';
import { actions as customerActions } from 'redux/reducers/customer';
import { GENERIC_ERROR, PROFANITY_CHECK } from 'globalConstants';
import TextArea from 'components/common/TextArea';
import { joinValues } from 'utils/util';
import '../../index.less';

import { useNavigate } from 'react-router-dom';

import CaseMarkAsReadModal from 'components/consumerDuty/modals/caseMarkReadModal';

const StyledRow = styled(Row)`
  background: var(--white);
`;
const StyledCol = styled(Col)<{ $border: boolean; $isDisabled: boolean }>`
  border-right: ${(props) =>
    props.$border ? '1px solid var(--light-gray)' : ''};
  padding: 20px 25px 14px;
  color: ${(props) =>
    props.$isDisabled ? 'var(--disabled-rgba)' : 'var(--black)'};
`;

const StyledTitle = styled.div`
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-600);
  margin-bottom: 10px;
`;

const StyledWrapperRow = styled(Row)`
  border: 1px solid var(--light-gray);
  border-radius: 4px;
  margin-bottom: 20px;
`;

const StyledInnerRow = styled(Row)<{
  $isHidden: boolean;
  $isDisabled: boolean;
}>`
  padding: 12px;
  visibility: ${(props) => (props.$isHidden ? 'hidden' : 'visible')};
  color: ${(props) =>
    props.$isDisabled ? 'var(--disabled-rgba)' : 'var(--black)'};
`;

const StyledSpan = styled.span`
  font-weight: var(--font-weight-600);
`;

const StyledQuesRow = styled(Row)`
  margin: 12px 0;
`;

const StyledCaseNote = styled.div<{ $owner: string }>`
  background: ${(props) =>
    props.$owner ? 'var(--light-peach)' : 'var(--catskill-white-light)'};

  color: ${(props) => (props.$owner ? 'var(--black)' : 'var(--gray-rgba)')};

  font-size: var(--font-size-20);
  padding: 15px;
`;

const StyledExplicitConsentRow = styled(Row)<{ $isModal: boolean }>`
  text-align: center;
  padding: ${(props) => (props.$isModal ? '' : '58px 180px')};
`;

const StyledExplicitConsentDiv = styled.div`
  color: var(--brick-red);
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-600);
`;

const StyledFooter = styled(Row)`
  border: none;
  box-shadow: none;
  background: var(--catskill-white-light);
  padding: 15px 20px;
`;

type CaseSummaryProps = {
  isModalView?: boolean;
  className?: string;
  onClose?: () => void;
  onCaseExit?: () => void;
};

interface ProfanityError {
  profanity: boolean;
  badWords: Array<string>;
}

const CaseSummary = ({
  isModalView = false,
  className = '',
  onClose = () => {},
  onCaseExit = () => {},
}: CaseSummaryProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isConsentGranted, setConsentGranted] = useState<any>(null);
  const [isConsentPopupOpen, setContentPopupOpen] = useState<boolean>(false);
  const [isCaseAlreadyEdited, setCaseAlreadyEdited] = useState<boolean>(false);
  const [isCaseEditing, setCaseEditing] = useState<boolean>(false);
  const [isCaseSummaryUpdatePopup, setCaseSummaryUpdatePopup] =
    useState<boolean>(false);
  const [isOptionToDeescalatePopUpVisible, setOptionToDeescalatePopUpVisible] =
    useState<boolean>(false);
  const [
    isConfirmationToDeescalatePopUpVisible,
    setConfirmationToDeescalatePopUpVisible,
  ] = useState<boolean>(false);
  const [vulnerabilityIndicatorsList, setVulnerabilityIndicatorsList] =
    useState<Array<any>>([]);
  const [checkedIndicators, setCheckedIndicators] = useState<Array<any>>([]);
  const [vulnerabilityOwner, setVulnerabilityOwner] = useState<string>('');
  const [expectedResolutionTimeList, setExpectedResolutionTimeList] = useState<
    Array<any>
  >([]);
  const [expectedResolutionTime, setExpectedResolutionTime] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [caseSummaryNotes, setCaseSummaryNotes] = useState<string>('');
  const [whatHappendNotes, setWhatHappenedNotes] = useState<string>('');
  const [anyoneHelpingNotes, setAnyoneHelpingNotes] = useState<string>('');
  const [ourHelpNotes, setOurHelpNotes] = useState<string>('');
  const [storeSupportRequestId, setStoreSupportRequestId] =
    useState<string>('');
  const [isSavePopupVisible, setSavePopupVisible] = useState<boolean>(false);
  const [isSaveLoading, setSaveLoading] = useState<boolean>(false);
  const [isSendLoading, setSendLoading] = useState<boolean>(false);
  const [caseSummaryProfanityError, setCaseSummaryProfanityError] =
    useState<ProfanityError>({ profanity: false, badWords: [''] });
  const [whatHappenedProfanityError, setWhatHappenedProfanityError] =
    useState<ProfanityError>({ profanity: false, badWords: [''] });
  const [anyonehelpingProfanityError, setAnyonehelpingProfanityError] =
    useState<ProfanityError>({ profanity: false, badWords: [''] });
  const [ourHelpProfanityError, setOurHelpProfanityError] =
    useState<ProfanityError>({ profanity: false, badWords: [''] });
  const [isTextAreaProfanity, setTextAreaProfanity] = useState<boolean>(false);
  const [isMarkAsReadPopupVisible, setMarkAsReadPopupVisible] =
    useState<boolean>(false);
  const [noteToRead, setNoteToRead] = useState<Array<any>>([]);

  const {
    user: { selectedStore, user },
    customer: { customer },
    consumerDuty: {
      caseSummary,
      selectedTaskId,
      selectedTab,
      isManagedCase,
      selectedTaskOwner,
      isViewOnlyMode,
    },
  } = useSelector((state: any) => {
    return {
      user: state.user,
      customer: state.customer,
      consumerDuty: state.consumerDuty,
    };
  });

  useEffect(() => {
    getVulnerabilityIndicatorData();
    getVulnerabilityResolutionTimeData();
    if (!isEmpty(caseSummary)) {
      getCaseSummaryData(caseSummary);
    }
  }, [caseSummary]);

  useEffect(() => {
    if (isManagedCase) {
      getCustomerCaseNotes();
    }
  }, [isManagedCase]); // eslint-disable-line react-hooks/exhaustive-deps

  const getVulnerabilityIndicatorData = async () => {
    try {
      const response = await cacheStaticData(getVulnerabilityIndicator);
      if (response?.data) {
        setVulnerabilityIndicatorsList(
          response?.data?.sort(
            ({ sortOrder: a }: any, { sortOrder: b }: any) => a - b
          )
        );
      }
    } catch (e: any) {
      setVulnerabilityIndicatorsList([]);
    }
  };

  const getVulnerabilityResolutionTimeData = async () => {
    try {
      const response = await cacheStaticData(getVulnerabilityResolutionTime);
      if (response?.data) setExpectedResolutionTimeList(response?.data);
    } catch (e: any) {
      setExpectedResolutionTimeList([]);
    }
  };

  const getCaseSummaryData = (summary: any) => {
    setCheckedIndicators(summary.vulnerabilityIdentifiers);
    setExpectedResolutionTime(summary.expectedResolutionTime);
    setCaseSummaryNotes(summary.caseSummary.trim());
    setConsentGranted(summary.isConsentGranted);
    setVulnerabilityOwner(summary.vulnerabilityOwner);
    setCaseAlreadyEdited(summary.isCaseSummaryAlreadyUpdated);
    setWhatHappenedNotes(
      summary.mandatoryQuestions
        .find(
          (el: { displayName: string }) => el.displayName === 'Whathappened'
        )
        ?.attributeValue.trim() || ''
    );
    setAnyoneHelpingNotes(
      summary.mandatoryQuestions
        .find(
          (el: { displayName: string }) => el.displayName === 'Anyonehelping'
        )
        ?.attributeValue.trim() || ''
    );
    setOurHelpNotes(
      summary.mandatoryQuestions
        .find((el: { displayName: string }) => el.displayName === 'Ourhelp')
        ?.attributeValue.trim() || ''
    );
  };

  const getCustomerCaseNotes = async () => {
    try {
      const response = await getCustomerNotes(
        customer?.customerId,
        NOTES_REQUIRED.CUSTOMER_CASE_NOTES
      );
      if (response?.data) {
        const notes = response?.data?.customerSupportNotes
          ?.filter(
            (x: any) =>
              x.subject.trim() === SST.CASE_NOTES_UPDATED &&
              x.isMarkedAsRead === false
          )
          .map((x: any) => x);

        setNoteToRead(notes);
        if (notes?.length > 0) setMarkAsReadPopupVisible(true);
      }
    } catch (error: any) {
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  const onVulnerabilityCheckboxChange = (checked: any, option: any) => {
    if (checked) {
      setVulnerabilityOwner('');
      setCheckedIndicators([...checkedIndicators, option]);
    } else {
      const mergedArray = checkedIndicators.filter(
        (obj) => obj.attributeId !== option.attributeId
      );
      setCheckedIndicators([...mergedArray]);
    }
  };

  const onResolutionCheckboxChange = (e: any) => {
    setExpectedResolutionTime(e.target.value);
  };

  const getIndicatorsPayload = () => {
    return checkedIndicators?.reduce((arr: any, obj: any) => {
      return [
        ...arr,
        {
          attributeId: obj.attributeId,
          displayName: obj.displayName,
        },
      ];
    }, []);
  };

  const onValidateRequestClick = async () => {
    try {
      const payload = getIndicatorsPayload();

      setLoading(true);
      const response = await postVulnerabilityValidator(payload);
      if (response?.data) {
        setVulnerabilityOwner(response.data);
        setLoading(false);
      }
    } catch (e: any) {
      setLoading(false);
      notification.error({ message: GENERIC_ERROR, duration: 3 });
    }
  };

  const onCheckboxChange = (e: any) => {
    setConsentGranted(e.target.value);
    if (e.target.value === 0) {
      setContentPopupOpen(true);
      setCheckedIndicators([]);
    }
  };

  const resetScoreCard = (e: any) => {
    setExpectedResolutionTime('');
    onCheckboxChange(e);
  };

  const ConsentRefused = ({ isModal = false }) => (
    <StyledExplicitConsentRow $isModal={isModal}>
      <Col span={24}>
        <StyledExplicitConsentDiv>
          {SST.EXPLICIT_CONSENT_REFUSED}
        </StyledExplicitConsentDiv>
        <div style={{ padding: '15px 30px', fontSize: 'var(--font-size-16)' }}>
          {SST.CONSENT_REFUSED_NOTE}
        </div>
        {!isModal && (
          <>
            <StyledSpan> {SST.CONSENT_CHECKBOX}</StyledSpan>
            <div>
              <Checkbox
                value={1}
                checked={isConsentGranted}
                onChange={resetScoreCard}
                className="explicit-consent-granted"
              >
                <StyledSpan>{SST.EXPLICIT_CONSENT_GRANTED}</StyledSpan>
              </Checkbox>
            </div>
          </>
        )}
      </Col>
    </StyledExplicitConsentRow>
  );

  const onSaveButtonClick = async () => {
    const indicators = getIndicatorsPayload();

    const params = {
      customerVulnerability: indicators,
      isConsentGranted,
      expectedResolutionTime: toString(expectedResolutionTime),
      caseSummary: caseSummaryNotes.trim(),
      vulnerabilityOwner:
        vulnerabilityOwner === CONSUMER_DUTY.STORE
          ? 'StoreManager'
          : CONSUMER_DUTY.SSTEAM,
      customerId: customer?.customerId,
      vulnerabilityOriginator: isModalView
        ? CONSUMER_DUTY.STORE
        : CONSUMER_DUTY.SST,
      storeId: selectedStore?.storeId,
      userId: user?.userId,
    };
    try {
      if (
        caseSummaryProfanityError.profanity ||
        whatHappenedProfanityError.profanity ||
        anyonehelpingProfanityError.profanity ||
        ourHelpProfanityError.profanity
      ) {
        setTextAreaProfanity(true);
      } else {
        setSaveLoading(true);
        const response = await postCustomerSupportRequest(params);
        if (response?.data) {
          setStoreSupportRequestId(response?.data);
          setSaveLoading(false);
          if (vulnerabilityOwner === CONSUMER_DUTY.STORE)
            setSavePopupVisible(true);
          return response?.data;
        }
      }
    } catch {
      setSaveLoading(false);
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  const onEscalateToSSTButtonClick = async (id: string | number = '') => {
    // Params for Escalating To SST Team
    const escalateToSstparams = {
      supportRequestAttributes: [
        {
          supportAttributeName: SST.WHATHAPPENED,
          supportAttributeValue: whatHappendNotes.trim(),
        },
        {
          supportAttributeName: SST.ANYONEHELPING,
          supportAttributeValue: anyoneHelpingNotes.trim(),
        },
        {
          supportAttributeName: SST.OURHELP,
          supportAttributeValue: ourHelpNotes.trim(),
        },
      ],
      customerName: joinValues([customer.firstNames, customer.surname], true),
      storeId: selectedStore?.storeId,
      customerId: customer?.customerId,
      customerSupportId: storeSupportRequestId || id,
      userId: user?.userId,
      description: caseSummaryNotes.trim(),
      isEscalated:
        vulnerabilityOwner === VULNERABILITYORIGINATOR.SSTEAM ? false : true,
    };

    // Payload Request for Escalating CST To SST Team
    const cstToSstpayload = {
      storeId: selectedStore?.storeId,
      customerId: customer?.customerId,
      customerSupportId: storeSupportRequestId || id,
      userId: user?.userId,
      taskId: selectedTaskId,
      customerName: joinValues([customer.firstNames, customer.surname], true),
      mandatoryQuestions: [
        {
          displayName: SST.WHATHAPPENED,
          attributeValue: whatHappendNotes.trim(),
        },
        {
          displayName: SST.ANYONEHELPING,
          attributeValue: anyoneHelpingNotes.trim(),
        },
        {
          displayName: SST.OURHELP,
          attributeValue: ourHelpNotes.trim(),
        },
      ],
    };

    try {
      if (
        caseSummaryProfanityError.profanity ||
        whatHappenedProfanityError.profanity ||
        anyonehelpingProfanityError.profanity ||
        ourHelpProfanityError.profanity
      ) {
        setTextAreaProfanity(true);
      } else {
        setSendLoading(true);
        const response =
          selectedTaskOwner === TASK_OWNER_VALUE.AT_RISK
            ? await postEscalateCstToSst(cstToSstpayload)
            : await postEscalateToSST(escalateToSstparams);
        if (response?.data) {
          setSendLoading(false);
          dispatch({ type: RESET_CUSTOMER_DATA });
          dispatch(consumerDutyActions.setIsSSTeamRequestRefPopupVisible(true));
          dispatch(
            consumerDutyActions.setSSTeamRequestTaskId(
              response?.data?.reference
            )
          );
          onClose();
        }
      }
    } catch {
      setSendLoading(false);
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  const onDeleteTaskFromWorkQueue = async () => {
    const params = {
      taskId: selectedTaskId,
      userId: user.userId,
    };
    try {
      setSendLoading(true);
      const response = await deleteCaseFromWorkqueue(params);
      if (response?.data) {
        setSendLoading(false);
        setCaseEditing(false);
        setCaseAlreadyEdited(true);
        dispatch(consumerDutyActions.resetConsumerDuty());
        dispatch(customerActions.clearAll({}));
        navigate(`/`);
      }
    } catch {
      setSendLoading(false);
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  const onUpdateCustomerCaseClick = async (retainWithSST: boolean) => {
    const params = {
      customerSupportId: caseSummary.customerSupportId || null,
      customerId: customer?.customerId,
      isConsentGranted: isConsentGranted,
      vulnerabilityOwner:
        vulnerabilityOwner === CONSUMER_DUTY.STORE && !retainWithSST
          ? 'StoreManager'
          : CONSUMER_DUTY.SSTEAM,
      customerVulnerability: checkedIndicators,
      caseSummary: caseSummaryNotes.trim(),
      storeId: selectedStore?.storeId,
      userId: user?.userId,
    };

    try {
      setSendLoading(true);
      const response = await postUpdateCase(params);
      if (response?.data?.success) {
        setSendLoading(false);
        if (
          params.vulnerabilityOwner === VULNERABILITYOWNER.STORE_MANAGER &&
          !retainWithSST
        ) {
          if (response?.data?.success) {
            notification.success({
              message: response?.data?.message,
              duration: 3,
            });
            onDeleteTaskFromWorkQueue();
            setConfirmationToDeescalatePopUpVisible(false);
          } else {
            notification.warning({
              message: response?.data?.message,
              duration: 3,
            });
            setConfirmationToDeescalatePopUpVisible(false);
          }
        } else {
          if (response?.data?.success) {
            notification.success({
              message: response?.data?.message,
              duration: 3,
            });
            dispatch({
              type: GET_CASE_SUMMARY,
              payload: {
                customerId: customer.customerId,
                service: NOTIFICATION_SERVICE_TYPE.VULNERABLE,
              },
            });
            setCaseEditing(false);
            setCaseAlreadyEdited(true);
          } else {
            notification.warning({
              message: response?.data?.message,
              duration: 3,
            });
          }
        }
      }
    } catch (e: any) {
      setSendLoading(false);
      console.log(e);
    }
  };

  const onSubmitToSSTButtonClick = async () => {
    const response = await onSaveButtonClick();
    if (response) onEscalateToSSTButtonClick(response);
  };

  const onViewProfileClick = () => {
    dispatch({ type: RESET_CUSTOMER_DATA });
    dispatch(consumerDutyActions.setIsSupportRequestRefPopupVisible(true));
    onClose();
  };

  const checkMandatoryFieldPresent = () => {
    if (
      isNull(isConsentGranted) ||
      isEmpty(caseSummaryNotes) ||
      isEmpty(whatHappendNotes) ||
      isEmpty(anyoneHelpingNotes) ||
      isEmpty(ourHelpNotes)
    )
      return true;
    return false;
  };

  const checkDisable = (): boolean => {
    if (isModalView) {
      return isEmpty(vulnerabilityOwner);
    } else return isEmpty(vulnerabilityOwner) && isCaseEditing && isModalView;
  };

  const handleCaseSummaryProfanity = (
    profanity: boolean,
    badWords: Array<string>
  ) => {
    setCaseSummaryProfanityError({ profanity, badWords });
  };

  const handleWhatHappenedProfanity = (
    profanity: boolean,
    badWords: Array<string>
  ) => {
    setWhatHappenedProfanityError({ profanity, badWords });
  };

  const handleAnyonehelpingProfanity = (
    profanity: boolean,
    badWords: Array<string>
  ) => {
    setAnyonehelpingProfanityError({ profanity, badWords });
  };

  const handleOurHelpProfanity = (
    profanity: boolean,
    badWords: Array<string>
  ) => {
    setOurHelpProfanityError({ profanity, badWords });
  };

  const getSSTProfanityWordsList = (updateMode: boolean = false) => {
    let badWords: string[] = [];
    let uniqueBadWords: string[] = [];

    if (whatHappenedProfanityError.profanity) {
      badWords = badWords.concat(whatHappenedProfanityError.badWords);
    }
    if (anyonehelpingProfanityError.profanity) {
      badWords = badWords.concat(anyonehelpingProfanityError.badWords);
    }
    if (ourHelpProfanityError.profanity) {
      badWords = badWords.concat(ourHelpProfanityError.badWords);
    }
    if (caseSummaryProfanityError.profanity) {
      badWords = badWords.concat(caseSummaryProfanityError.badWords);
    }

    badWords.forEach((item) => {
      if (uniqueBadWords.indexOf(item) === -1) {
        uniqueBadWords.push(item);
      }
    });

    return uniqueBadWords.join(', ');
  };

  const onNextButtonClick = () => {
    const tab = toString(+selectedTab + 1);
    dispatch(consumerDutyActions.setSelectedTab(tab));
  };

  const onEditCaseButtonClick = () => {
    setVulnerabilityOwner('');
    setCaseEditing(true);
  };

  const onUpdateCaseButtonClick = () => {
    if (
      whatHappenedProfanityError.profanity ||
      anyonehelpingProfanityError.profanity ||
      ourHelpProfanityError.profanity ||
      caseSummaryProfanityError.profanity
    ) {
      setTextAreaProfanity(true);
    } else {
      if (vulnerabilityOwner && vulnerabilityOwner === CONSUMER_DUTY.STORE) {
        caseSummary.caseSummary === caseSummaryNotes
          ? setCaseSummaryUpdatePopup(true)
          : setOptionToDeescalatePopUpVisible(true);
      } else {
        caseSummary.caseSummary === caseSummaryNotes
          ? setCaseSummaryUpdatePopup(true)
          : onUpdateCustomerCaseClick(true);
      }
    }
  };

  const onCaseUpdateClick = () => {
    if (vulnerabilityOwner) {
      onUpdateCustomerCaseClick(true);
      setOptionToDeescalatePopUpVisible(false);
    }
  };

  const onDeescalateToSSTClick = () => {
    setConfirmationToDeescalatePopUpVisible(true);
  };

  const onCancelButtonClick = () => {
    setConfirmationToDeescalatePopUpVisible(false);
  };

  const onOkToDeescalateButtonClick = () => {
    setOptionToDeescalatePopUpVisible(false);
    onUpdateCustomerCaseClick(false);
  };

  return (
    <>
      <StyledRow className="case-summary-wrapper">
        <StyledCol
          $border={true}
          span={14}
          $isDisabled={false}
          className="case-summary-left-wrapper"
        >
          <StyledWrapperRow>
            <Col span={24}>
              {!isNull(isConsentGranted) &&
              !isConsentGranted &&
              !isConsentPopupOpen ? (
                <StyledInnerRow $isHidden={false} $isDisabled={false}>
                  <Col span={24}>
                    <ConsentRefused />
                  </Col>
                </StyledInnerRow>
              ) : (
                <StyledInnerRow
                  $isHidden={isConsentPopupOpen}
                  $isDisabled={false}
                >
                  <Col span={24}>
                    <Row>
                      <Col
                        span={24}
                        style={{
                          fontWeight: '500',
                        }}
                      >
                        {SST.SELECT_IDENTIFIERS}
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: '10px',
                      }}
                      className="select-identifiers-row"
                    >
                      {vulnerabilityIndicatorsList?.map((option, index) => (
                        <Col span={6} style={{ padding: '3px 0' }} key={index}>
                          <Checkbox
                            key={option?.attributeId}
                            onChange={(e) =>
                              onVulnerabilityCheckboxChange(
                                e.target.checked,
                                option
                              )
                            }
                            checked={checkedIndicators.some(
                              (ele: any) =>
                                ele.attributeId === option.attributeId
                            )}
                            disabled={!isCaseEditing && !isModalView}
                          >
                            {option?.displayName}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                    <Row>
                      <Col span={24}>
                        <StyledSpan>{SST.EXPECTED_RES_TIME}</StyledSpan>
                      </Col>
                    </Row>
                    <Row
                      style={{ marginBottom: '10px' }}
                      className="resolution-identifiers-row"
                    >
                      {expectedResolutionTimeList.map((option, index) => (
                        <Col span={6} style={{ padding: '5px 0' }} key={index}>
                          <Checkbox
                            key={index}
                            onChange={(e) => onResolutionCheckboxChange(e)}
                            checked={
                              option.vulnerabilityResolutionPeriodId ===
                              expectedResolutionTime
                            }
                            value={option.vulnerabilityResolutionPeriodId}
                            disabled={!isCaseEditing && !isModalView}
                          >
                            {option?.displayName}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Button
                          onClick={() => onValidateRequestClick()}
                          disabled={
                            expectedResolutionTime === '' ||
                            checkedIndicators?.length === 0 ||
                            (!isCaseEditing && !isModalView)
                          }
                          loading={loading}
                          itemProp="secondary"
                        >
                          {SST.VALIDATE_REQUEST}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </StyledInnerRow>
              )}
              <Row>
                <Col span={24}>
                  <StyledCaseNote $owner={vulnerabilityOwner}>
                    {SST.CASE_HANDLED_NOTE}
                    <StyledSpan>
                      {vulnerabilityOwner === CONSUMER_DUTY.STORE
                        ? CONSUMER_DUTY.STORE_MANAGER
                        : vulnerabilityOwner}
                    </StyledSpan>
                  </StyledCaseNote>
                </Col>
              </Row>

              {!isNull(isConsentGranted) &&
              !isConsentGranted &&
              !isConsentPopupOpen ? (
                ''
              ) : (
                <StyledInnerRow $isHidden={false} $isDisabled={checkDisable()}>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <StyledTitle>{SST.READ_TO_CUSTOMER}</StyledTitle>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <StyledSpan>{SST.CONSENT_NOTE}</StyledSpan>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8} style={{ padding: '5px 0px' }}>
                        <Checkbox
                          value={1}
                          checked={isConsentGranted}
                          onChange={onCheckboxChange}
                          className="explicit-consent-granted"
                          disabled={
                            (isEmpty(vulnerabilityOwner) &&
                              isCaseEditing &&
                              isModalView) ||
                            isViewOnlyMode
                          }
                        >
                          <StyledSpan>
                            {SST.EXPLICIT_CONSENT_GRANTED}
                          </StyledSpan>
                        </Checkbox>
                      </Col>
                      <Col span={8} style={{ padding: '5px 0px' }}>
                        <Checkbox
                          value={0}
                          checked={
                            !isNull(isConsentGranted) && !isConsentGranted
                          }
                          onChange={onCheckboxChange}
                          className="explicit-consent-refused"
                          disabled={
                            (isEmpty(vulnerabilityOwner) &&
                              isCaseEditing &&
                              isModalView) ||
                            isViewOnlyMode
                          }
                        >
                          <StyledSpan>
                            {SST.EXPLICIT_CONSENT_REFUSED}
                          </StyledSpan>
                        </Checkbox>
                      </Col>
                    </Row>
                  </Col>
                </StyledInnerRow>
              )}
            </Col>
          </StyledWrapperRow>
          <Row>
            <Col span={24}>
              <StyledSpan>{SST.CASE_SUMMARY}</StyledSpan> {`(${SST.MANDATORY})`}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <TextArea
                rows={3}
                maxLength={600}
                autoComplete="on"
                spellCheck
                profanityCheck
                profanityExists={handleCaseSummaryProfanity}
                showCount={false}
                value={caseSummaryNotes}
                disabled={!isCaseEditing && !isModalView}
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => setCaseSummaryNotes(e.target.value)}
              />
            </Col>
          </Row>
        </StyledCol>
        <StyledCol
          $border={false}
          span={10}
          $isDisabled={checkDisable()}
          className="case-summary-right-wrapper"
        >
          <Row>
            <Col span={24}>
              <StyledTitle>{SST.QUESTION_TITLE}</StyledTitle>
            </Col>
          </Row>
          <StyledQuesRow>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <StyledSpan>{SST.WHATHAPPENED_QUES}</StyledSpan>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <TextArea
                    rows={9}
                    maxLength={600}
                    autoComplete="on"
                    spellCheck
                    profanityCheck
                    profanityExists={handleWhatHappenedProfanity}
                    showCount={false}
                    disabled={!isCaseEditing && !isModalView}
                    value={whatHappendNotes}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setWhatHappenedNotes(e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </StyledQuesRow>
          <StyledQuesRow>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <StyledSpan>{SST.ANYONEHELPING_QUES}</StyledSpan>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <TextArea
                    rows={6}
                    maxLength={600}
                    autoComplete="on"
                    spellCheck
                    profanityCheck
                    profanityExists={handleAnyonehelpingProfanity}
                    showCount={false}
                    disabled={!isCaseEditing && !isModalView}
                    value={anyoneHelpingNotes}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setAnyoneHelpingNotes(e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </StyledQuesRow>
          <StyledQuesRow>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <StyledSpan>{SST.OURHELP_QUES}</StyledSpan>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <TextArea
                    rows={6}
                    maxLength={600}
                    autoComplete="on"
                    spellCheck
                    profanityCheck
                    profanityExists={handleOurHelpProfanity}
                    showCount={false}
                    value={ourHelpNotes}
                    disabled={!isCaseEditing && !isModalView}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setOurHelpNotes(e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </StyledQuesRow>
        </StyledCol>
      </StyledRow>
      {!isModalView ? (
        <Row>
          <Col span={24}>
            <CDFooter
              proceedButtonText={SST.NEXT}
              proceedButtonItemProp={'secondary'}
              onProceedClick={onNextButtonClick}
              isProceedButtonDisabled={false}
              extraButtonAlign={'left'}
              extraButtonEanable
              extraButtonItemProp={'secondary'}
              extraButtonDisabled={
                isCaseAlreadyEdited ||
                isEmpty(vulnerabilityOwner) ||
                isViewOnlyMode
              }
              extraButtonText={!isCaseEditing ? SST.EDIT_CASE : 'Update Case'}
              extraButtonOnClick={
                !isCaseEditing ? onEditCaseButtonClick : onUpdateCaseButtonClick
              }
              onExitButtonClick={onCaseExit}
            />
          </Col>
        </Row>
      ) : (
        <StyledFooter>
          <Col span={14}>
            <Row justify="space-between">
              <Button itemProp="secondary" onClick={onClose}>
                {BUTTONS_LABEL.CANCEL}
              </Button>
              <Button
                loading={isSaveLoading}
                onClick={() => onSaveButtonClick()}
                disabled={
                  isNull(isConsentGranted) ||
                  isEmpty(caseSummaryNotes) ||
                  vulnerabilityOwner.includes(SST.SSTEAM)
                }
              >
                {BUTTONS_LABEL.SAVE}
              </Button>
            </Row>
          </Col>
          <Col span={10}>
            <Row justify="end">
              <Button
                loading={isSendLoading}
                onClick={() =>
                  vulnerabilityOwner.includes(SST.SSTEAM)
                    ? onSubmitToSSTButtonClick()
                    : onEscalateToSSTButtonClick()
                }
                disabled={checkMandatoryFieldPresent()}
              >
                {vulnerabilityOwner.includes(SST.SSTEAM)
                  ? BUTTONS_LABEL.SUBMIT_TO_SST
                  : BUTTONS_LABEL.ESCALATE_TO_SST}
              </Button>
            </Row>
          </Col>
        </StyledFooter>
      )}
      {!isNull(isConsentGranted) && !isConsentGranted && (
        <Modal
          open={isConsentPopupOpen}
          width={600}
          destroyOnClose={true}
          maskClosable={false}
          mask={false}
          style={{
            top: `${isModalView ? '17%' : '23%'}`,
            right: `${isModalView ? '17%' : '20%'}`,
          }}
          onCancel={() => setContentPopupOpen(false)}
          footer={[
            <Row gutter={24} justify="center" key="consentRefused">
              <Col>
                <Button
                  itemProp="secondary"
                  key="ok"
                  onClick={() => setContentPopupOpen(false)}
                >
                  OK
                </Button>
              </Col>
            </Row>,
          ]}
        >
          <ConsentRefused isModal />
        </Modal>
      )}

      {isOptionToDeescalatePopUpVisible && (
        <ControlledAlert
          maskClosable={false}
          visible={isOptionToDeescalatePopUpVisible}
          message={OPTION_TO_DEESCALATE_MSG}
          onCancel={onDeescalateToSSTClick}
          onClick={onCaseUpdateClick}
          cancelButtonText={BUTTONS_LABEL.DE_ESCALATE_TO_STORE_ASSISTED}
          yesButtonText={BUTTONS_LABEL.MANAGE_CASE_BY_SST}
          itemPropCancel=""
          width={550}
        />
      )}

      {isCaseSummaryUpdatePopup && (
        <ControlledAlert
          maskClosable={false}
          visible={isCaseSummaryUpdatePopup}
          message={CASE_SUMMARY_UPDATE_MSG}
          onClick={() => setCaseSummaryUpdatePopup(false)}
          yesButtonText={BUTTONS_LABEL.OK}
          isCancelBtn={false}
          itemProp=""
        />
      )}

      {isConfirmationToDeescalatePopUpVisible && (
        <ControlledAlert
          maskClosable={false}
          visible={isConfirmationToDeescalatePopUpVisible}
          message={CONFIRMATION_TO_DEESCALATE_MSG_ONE}
          secondaryMessage={CONFIRMATION_TO_DEESCALATE_MSG_SECOND}
          onCancel={onOkToDeescalateButtonClick}
          onClick={onCancelButtonClick}
          cancelButtonText={BUTTONS_LABEL.OK}
          yesButtonText={BUTTONS_LABEL.CANCEL}
          itemPropCancel=""
          width={550}
        />
      )}

      {isSavePopupVisible && (
        <ControlledAlert
          visible={isSavePopupVisible}
          message={HEADINGS.STORE_MANAGED}
          onClick={onViewProfileClick}
          onCancel={() => setSavePopupVisible(false)}
          yesButtonText={BUTTONS_LABEL.VIEW_PROFILE}
          cancelButtonText={BUTTONS_LABEL.ESCALATE_TO_SST}
          itemPropCancel=""
        />
      )}

      {isTextAreaProfanity && (
        <ControlledAlert
          id="caseSummary"
          width={600}
          visible={isTextAreaProfanity}
          message={PROFANITY_CHECK.REMOVE_PROFANITY}
          secondaryMessage={
            <>
              {PROFANITY_CHECK.PROFANITY_WORDS}
              <b>&nbsp;{getSSTProfanityWordsList()}</b>
            </>
          }
          onClick={() => setTextAreaProfanity(false)}
          yesButtonText={BUTTONS_LABEL.REMOVE_PROFANITY}
          isCancelBtn={false}
        />
      )}

      {isMarkAsReadPopupVisible && (
        <CaseMarkAsReadModal
          visible={isMarkAsReadPopupVisible}
          onClose={() => setMarkAsReadPopupVisible(false)}
          noteToRead={noteToRead}
        />
      )}
    </>
  );
};

export default CaseSummary;
