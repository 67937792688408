import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Form, Layout, Row, Col, Input, notification } from 'antd';
import { isEmpty } from 'lodash';
import { ArrowRightOutlined } from '@ant-design/icons';

import {
  STORES_REQUEST,
  AUTHORIZE_STOCK_TRANSFER,
  ACCEPT_STOCK_TRANSFER,
} from 'action_creators/inventoryManagement';
import { STOCK_TRANSFER_LABELS } from 'components/inventoryManagement/constants';
import { actions } from 'redux/reducers/inventoryManagement';
import { Button } from 'components/common/Button';

import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import FooterButtons from 'components/common/footerButtons';
import AcceptTrasnferSummary from './acceptTransferSummary';
import AuthorizationForm from 'components/inventoryManagement/AuthorizationForm';
import Select from 'components/common/Select';
import { currencyFormat } from 'utils/util';

const StyledContentLayout = styled(Layout)`
  background: var(--white);
  padding: 15px 30px;
  border-radius: 5px;
`;

const StyledRow = styled(Row)`
  position: absolute;
  bottom: 0;
  right: 0;
  padding-bottom: 10px;
}`;

const StyledSpan = styled.span`
  font-size: 20px;
  font-weight: 500;
`;

const StyledTableWrapper = styled.div`
  height: calc(100vh - 515px);
`;
const StyledSummaryDiv = styled.div`
  text-align: right;
  height: 40px;
  font-weight: 700;
  padding: 6px 20px;
  background: #f4f7fa;
`;

interface AcceptTransferProps {
  onExit: () => void;
}

const AcceptTransfer = ({ onExit }: AcceptTransferProps) => {
  const {
    inventoryManagement: {
      submiting,
      searching,
      isAuthorized,
      authorizerName,
      isClosedTransfer,
      destinationStores,
      transferParams,
      transferItemsList,
      selectedItemIds,
    },
    user: { user },
  } = useSelector((state: any) => {
    return {
      inventoryManagement: state.inventoryManagement,
      user: state.user,
    };
  });

  const [totalWeight, setTotalWeight] = useState<number>(0);
  const [totalCostPrice, setTotalCostPrice] = useState<number>(0);
  const [transferReference, setTransferReference] = useState<string>('');

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    destinationStores.length === 0 &&
      dispatch({
        type: STORES_REQUEST,
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEmpty(transferParams)) {
      form.setFieldsValue({ source: transferParams.sourceStoreId });
    }
    setTransferReference(transferParams.transferReference);
  }, [transferParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let totalPrice = 0;
    let totalWeigth = 0;

    transferItemsList.forEach((el: any) => {
      totalPrice = totalPrice + el.costValue;
      totalWeigth = totalWeigth + el.weight;
    });

    setTotalCostPrice(totalPrice);
    setTotalWeight(totalWeigth);
  }, [transferItemsList]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSearchClick = () => {
    const barcode = form.getFieldValue('barcode');
    const itemDetails = transferItemsList.find(
      (el: any) => el.barcode === barcode
    );

    if (itemDetails) {
      const itemsId = [...selectedItemIds];
      itemsId.push(itemDetails.itemId);
      dispatch(actions.setSelectedItemIds(itemsId));
      form.setFieldsValue({ barcode: '' });
    } else {
      notification.error({
        message: STOCK_TRANSFER_LABELS.ERROR_MSG.BARCODE_NOT_FOUND,
        duration: 3,
      });
    }
  };

  const onExitClick = () => {
    dispatch(actions.exitCreateEditTransfer());
    onExit();
  };

  const authorizationHandler = (authorizationHeaders: any) => {
    const params = {
      TransferId: transferParams?.transferId,
      Username: authorizationHeaders.authorisationUserName,
      ApprovalType: STOCK_TRANSFER_LABELS.ACCEPT_TRANSFER.APPROVAL_TYPE,
      SourceStoreId: transferParams.sourceStoreId,
      DestinationStoreId: transferParams.destinationStoreId,
    };

    dispatch({
      type: AUTHORIZE_STOCK_TRANSFER,
      payload: { params, authorizationHeaders },
    });
  };

  const onAcceptTransferClick = () => {
    const params = {
      TransferId: transferParams.transferId,
      UserId: user.userId,
      SourceStoreId: transferParams.sourceStoreId,
      DestinationStoreId: transferParams.destinationStoreId,
    };
    const printParams = {
      TransferId: transferParams.transferId,
      transferSentBy: user.displayName,
      transferApprovedBy: authorizerName,
    };
    dispatch({
      type: ACCEPT_STOCK_TRANSFER,
      payload: { params, printParams },
    });
  };

  return (
    <>
      <StyledContentLayout>
        <Row className="title-row">
          <Col span={24}>
            <HelpPopoverInfo
              linkedID={''}
              position="rightTop"
              helpPosition="AFTER"
            >
              {isClosedTransfer
                ? STOCK_TRANSFER_LABELS.TITLES.CLOSED_TRANSFER
                : STOCK_TRANSFER_LABELS.TITLES.ACCEPT_TRANSFER}
            </HelpPopoverInfo>
          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <Form
              name="addItemForm"
              form={form}
              onFinish={onSearchClick}
              autoComplete="off"
              className="flex-column"
            >
              <Row style={{ padding: '5px 0' }}>
                <Col span={5}>
                  <Form.Item
                    label={STOCK_TRANSFER_LABELS.ACCEPT_TRANSFER.SOURCE}
                    name="source"
                    className="label-control"
                  >
                    <Select
                      disabled={true}
                      options={destinationStores?.map((el: any) => {
                        return {
                          value: el.storeId,
                          label: `${el.storeName} - ${el.storeId}`,
                        };
                      })}
                    />
                  </Form.Item>
                </Col>
                {!isClosedTransfer && (
                  <Col span={5} style={{ marginLeft: '20px' }}>
                    <Form.Item
                      label={STOCK_TRANSFER_LABELS.CREATE_NEW_TRANSFER.BARCODE}
                      name="barcode"
                      className="label-control"
                      rules={[
                        {
                          required: true,
                          message:
                            STOCK_TRANSFER_LABELS.ERROR_MSG.BARCODE_REQUIRED,
                        },
                      ]}
                    >
                      <Input
                        className="input-control custon-input-addon"
                        placeholder="Scan/Enter Barcode"
                        addonAfter={
                          <Button loading={searching} htmlType="submit">
                            <ArrowRightOutlined style={{ fontSize: '18px' }} />
                          </Button>
                        }
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </Col>
          <Col span={6}>
            <StyledRow justify="end">
              <StyledSpan>Transfer Number: {transferReference}</StyledSpan>
            </StyledRow>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <StyledTableWrapper className="item-added-table">
              <AcceptTrasnferSummary stockTransferList={transferItemsList} />
            </StyledTableWrapper>
            <StyledSummaryDiv>
              <Col span={24}>
                <Row style={{ paddingTop: '5px' }}>
                  <Col span={8}>
                    <Row justify="start">
                      No. of Items: {transferItemsList.length}
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row justify="end">
                      Total Weight: {totalWeight.toFixed(2)}g
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row justify="end">
                      Cost Price: {currencyFormat(totalCostPrice)}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </StyledSummaryDiv>
          </Col>
        </Row>
        <Row style={{ paddingTop: '10px', height: '60px' }}>
          {!isClosedTransfer && (
            <AuthorizationForm
              addonBeforeText={STOCK_TRANSFER_LABELS.AUTHORIZATION_MSG}
              authorizationHandler={authorizationHandler}
            />
          )}
        </Row>
      </StyledContentLayout>
      <FooterButtons
        enableExtraClass
        isLoading={submiting}
        isDisabled={!isAuthorized}
        isExitOnly={true}
        enableExitPopup={false}
        onConfirmExitClick={onExitClick}
        proceedButtonText="Accept Transfer"
        onProceedClick={onAcceptTransferClick}
      />
    </>
  );
};

export default AcceptTransfer;
