import React, { useState } from 'react';
import { format } from 'date-fns';
import VirtualGrid from '../../../common/virtualGrid/index';
import {
  COLORS_CODE_TABLE,
  GRID_NO_URL,
  FNS_DATE_FORMAT,
} from 'globalConstants';
import ProgressTick from 'components/common/ProgressCheck';
import TableRow from '../../formatedCell';
import ImageLoader from 'components/common/ImageLoader';
import { SERVICE_NAME } from 'components/checkout/constants';

import { getParentImageUrl } from 'utils/util';

const CheckoutPurchase = (props: any) => {
  const rows = props?.item || [];

  const [columns] = useState([
    {
      name: 'servicePurchaseId',
      title: 'Purchase No.',
      getCellValue: (row: any) =>
        ProgressTick(
          row?.purchase?.response?.servicePurchaseId,
          row?.serviceStatusText
        ),
    },
    {
      name: 'serviceName',
      title: 'Service',
      getCellValue: () => SERVICE_NAME.PURCHASE,
    },
    {
      name: 'quarantineDate',
      title: 'Last Chance',
      getCellValue: (row: any) =>
        format(
          new Date(row?.purchase?.request?.quarantineDate),
          FNS_DATE_FORMAT
        ),
    },
    {
      name: 'loanAmount',
      title: 'Sale Amount',
      type: 'currency',
      getCellValue: (row: any) => row?.purchase?.request?.loanAmount,
    },
    {
      name: 'fee',
      title: 'Reclaim Fee',
      type: 'currency',
      getCellValue: (row: any) => row?.purchase?.request?.fee,
    },
    {
      //insert a blank column to create space before To Pay
      name: 'blankColumn',
      title: ' ',
      getCellValue: () => '',
    },
    {
      name: 'toPay',
      title: 'To Pay',
      type: 'currency',
      getCellValue: (row: any) => row?.serviceAmount * -1,
    },
    {
      name: 'toTake',
      title: 'To Take',
      type: 'currency',
      getCellValue: (row: any) => 0,
    },
    {
      name: '',
      title: '',
      getCellValue: (row: any) => '',
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'servicePurchaseId', width: '13%' },
    { columnName: 'serviceName', width: '13%' },
    { columnName: 'quarantineDate', width: '12%' },
    { columnName: 'loanAmount', width: '12%', align: 'right' },
    { columnName: 'fee', width: '12%', align: 'right' },
    { columnName: 'blankColumn', width: '8%' },
    { columnName: 'toPay', width: '10%', align: 'right' },
    { columnName: 'toTake', width: '10%', align: 'right' },
    { columnName: '', width: '6%', align: 'right' },
  ]);

  const [formattedColumns] = useState(['toPay', 'toTake', 'loanAmount', 'fee']);

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        formattedColumns={formattedColumns}
        rowData={rows}
        tableColumnExtensionsProps={tableColumnExtensions}
        headerBackground={COLORS_CODE_TABLE.PURCHASE}
        cellComponent={TableRow}
        expandableComponent={PurchaseExpandableTable}
        detailRowIconPlacement={true}
      />
    </>
  );
};

export const PurchaseExpandableTable = ({ row }: any) => {
  const [columns] = useState([
    {
      name: 'itemImage',
      title: 'Item Image',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={getParentImageUrl(row.itemImageCollections)}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'description',
      title: 'Description',
      getCellValue: (row: any) => row.description,
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) => `${row?.totalWeight?.toFixed(2)}g`,
    },
    {
      name: 'amount',
      title: 'Amount',
      type: 'currency',
      getCellValue: (row: any) => row?.loanAmount,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'itemImage', width: 130 },
    { columnName: 'description', width: 230 },
    { columnName: 'weight', width: 120, align: 'right' },
    { columnName: 'amount', width: 120, align: 'right' },
  ]);

  const [formattedColumns] = useState(['amount']);

  return (
    <>
      <div className="expandable-table-rows">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          formattedColumns={formattedColumns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={row?.purchase?.request?.items}
          rowSelectionEnable={true}
          onSelectionChange={() => undefined}
        />
      </div>
    </>
  );
};

export default CheckoutPurchase;
