import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Layout } from 'antd';
import styled from 'styled-components';
import { useAuth } from 'providers/AuthProvider';
import AppDrawer from '../AppDrawer/AppDrawer';
import HeaderContent from '../Header/Header';
import Routers from 'routes/index';
import { ROUTE_CONFIG } from 'globalConstants';
import './App.less';
import TimeOut from '../TimeOut/TimeOut';

// import AuctionLockAlert from 'components/common/AuctionLockAlert/AuctionLockAlert'; {/* commenting Notify Auction Locking code as per - MP-6476, EVB-861 */}
import ControlledAlert from 'components/common/ControlledAlert';
import { RECALL_MENU_LABELS } from '../constants';
import { actions } from 'redux/reducers/customer';
import { actions as basketActions } from 'redux/reducers/basket';
import { getBasket } from 'services/basket';

// Disabling Surplus popup for release branch EVB-830
import SurplusPopup from 'components/common/SurplusPopup';
import ScannerPopover from 'components/customer/newCustomer/scannerPopover';

import { PRINTING_DOCUMENT } from 'components/customer/constants';

const StyledSider = styled(Layout.Sider)`
  background-color: var(--menu-background);
`;

const StyledContent = styled(Layout.Content)`
  background-color: var(--background);
  margin-top: 6rem;
`;

const StyledLogo = styled.img`
  margin: 16px;
  margin-left: 20px;
  width: 110px;
  height: 36.19px;
`;

const StyledLogoIcon = styled.img`
  margin: 16px;
  margin-left: 20px;
`;

function App() {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { signOut } = useAuth();

  const location = useLocation();

  const {
    customer: { customer, isDocumentPrinting },
    basket: { isBasketMergeError, isExitClearBasket, basket },
  } = useSelector((state: any) => {
    return { basket: state.basket, customer: state.customer };
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === '/') {
      setCollapsed(false);
    } else setCollapsed(true);
  }, [location]);

  useEffect(() => {
    if (isExitClearBasket && basket?.basketId) {
      dispatch(basketActions.clearItems());
    }
  }, [isExitClearBasket, dispatch, basket]);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const onAlertClick = async () => {
    dispatch(actions.setRecallCheckoutCustomer(true));
    dispatch(basketActions.setBasketMergeError(false));
    const payload = await getBasket(customer.customerId);
    if (payload?.data) {
      dispatch(basketActions.getBasket(payload?.data));
      navigate(`/${ROUTE_CONFIG.CHECKOUT}`);
    }
  };

  return (
    <>
      <Layout style={{ height: '100vh' }}>
        <StyledSider
          role="sider"
          trigger={null}
          collapsible
          collapsed={collapsed}
          width="240px"
        >
          {collapsed ? (
            <StyledLogoIcon
              src={require('../../../assets/images/logo-icon.png')}
            />
          ) : (
            <StyledLogo src={require('../../../assets/images/logo.png')} />
          )}
          <AppDrawer />
        </StyledSider>
        <Layout className="main-section-container">
          <HeaderContent collapsed={collapsed} toggle={toggle} />
          <StyledContent>
            <TimeOut signOut={signOut} />
            {/* commenting Notify Auction Locking code as per - MP-6476, EVB-861 */}
            {/* <AuctionLockAlert /> */}
            {/* Enabling Surplus popup for sprint-59 EVB-1215 */}
            <SurplusPopup />
            <Routers />
          </StyledContent>
        </Layout>
        {isBasketMergeError && (
          <ControlledAlert
            visible={isBasketMergeError}
            width={600}
            yesButtonText={'OK'}
            itemProp={'secondary'}
            message={RECALL_MENU_LABELS.MERGE_BASKET_ERROR}
            alertMessage={'Alert'}
            isCancelBtn={false}
            onClick={onAlertClick}
            onCancel={() => {
              /* do nothing */
            }}
          />
        )}

        {isDocumentPrinting && (
          <ScannerPopover
            visible={isDocumentPrinting}
            title={PRINTING_DOCUMENT}
          />
        )}
      </Layout>
    </>
  );
}

export default App;
