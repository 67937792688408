import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pledgeAgreement: [] as any,
  paidPledgeDetails: [] as any,
  selectedAgreementRowId: [] as any,
  affidavitReasons: [],
  purchaseReturn: [] as any,
  selectedPurchaseRowId: [] as any,
  selectedPurchaseItemId: [] as any,
  isLoading: {},
  isApiLoading: false,
  error: {},
  isProceedToBasket: false,
  printContractCounter: 3,
};
const slice = createSlice({
  name: 'pickupActions',
  initialState,
  reducers: {
    addPledgeAgreement(state, action) {
      //when moving from basket to pu/r page
      if (Array.isArray(action.payload)) state.pledgeAgreement = action.payload;
      else state.pledgeAgreement = [...state.pledgeAgreement, action.payload]; //to add additional pledge agreements to the existing agreements
    },
    updatePledgeAgreement(state, action) {
      const index = state.pledgeAgreement.findIndex(
        (x: any) => x.id === action.payload.id
      );
      state.pledgeAgreement[index] = {
        ...state.pledgeAgreement[index],
        ...action.payload,
      };
      state.isLoading = {};
    },
    removePledgeAgreement(state) {
      state.pledgeAgreement = state.pledgeAgreement.filter(
        (item: any) => !state.selectedAgreementRowId?.includes(item?.id)
      );
      state.selectedAgreementRowId = [];
    },
    updateNoAgreements(state, action) {
      const data = [...state.pledgeAgreement, ...action.payload];
      state.pledgeAgreement = data.filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
      );
    },
    getPaidPledgePayment(state, action) {
      state.paidPledgeDetails = action.payload;
      state.isApiLoading = false;
    },
    addPurchaseReturn(state, action) {
      //when moving from basket to pu/r page
      if (Array.isArray(action.payload)) state.purchaseReturn = action.payload;
      else state.purchaseReturn = [...state.purchaseReturn, action.payload]; //to add additional pledge agreements to the existing agreements
    },
    removePurchaseReturn(state) {
      state.purchaseReturn = state.purchaseReturn.filter(
        (item: any) => !state.selectedPurchaseRowId?.includes(item?.id)
      );
      state.selectedPurchaseRowId = [];
    },
    updatePurchaseReturn(state, action) {
      const index = state.purchaseReturn.findIndex(
        (x: any) => x.id === action.payload.id
      );
      state.purchaseReturn[index] = {
        ...state.purchaseReturn[index],
        ...action.payload,
      };
    },
    setLoading(state, action) {
      state.isLoading = action.payload; // for each row in Select Actions
    },
    setApiLoading(state, action) {
      state.isApiLoading = action.payload;
    },
    setSelectedAgreementRow(state, action) {
      state.selectedAgreementRowId = action.payload;
    },
    setSelectedPurchaseRow(state, action) {
      state.selectedPurchaseRowId = action.payload;
    },
    setSelectedPurchaseItemId(state, action) {
      state.selectedPurchaseItemId = action.payload;
    },
    setAffidavitReasons(state, action) {
      state.affidavitReasons = action.payload;
    },
    setProceedToBasket(state, action) {
      state.isProceedToBasket = action.payload;
    },
    decrementPrintContractCounter(state, action) {
      state.printContractCounter = state.printContractCounter - 1;
    },
    resetPrintContractCounter(state, action) {
      state.printContractCounter = 3;
    },
    clearItems() {
      return {
        ...initialState,
      };
    },
    itemError(state, action) {
      state.error = {
        message: action?.payload?.message,
        description: action?.payload?.response?.data?.error,
      };
      state.paidPledgeDetails = [];
      state.isLoading = {};
      state.isApiLoading = false;
      state.selectedAgreementRowId = [];
      state.affidavitReasons = [];
    },
  },
});

export default slice.reducer;
export const { reducer, actions } = slice;
export { initialState };
