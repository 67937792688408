import React from 'react';

import styled from 'styled-components';

import FooterButtons from 'components/common/footerButtons';

const StyledDiv = styled.div`
  & .footer-buttons {
    border: none;
    box-shadow: none;
    background: var(--catskill-white-light);
  }
`;

type CDFooterProps = {
  extraButtonAlign?: any;
  proceedButtonText?: any;
  proceedButtonItemProp?: any;
  onProceedClick?: () => void;
  isProceedButtonDisabled?: boolean; //proceed button disable
  extraButtonOnClick?: () => void;
  extraButtonText?: any;
  extraButtonEanable?: boolean;
  extraButtonItemProp?: any;
  onExitButtonClick: () => void;
  extraButtonDisabled?: boolean;
};

const CDFooter = ({
  proceedButtonText,
  proceedButtonItemProp,
  onProceedClick,
  isProceedButtonDisabled,
  extraButtonAlign,
  extraButtonEanable,
  extraButtonItemProp,
  extraButtonText,
  extraButtonOnClick,
  onExitButtonClick,
  extraButtonDisabled,
}: CDFooterProps) => {
  return (
    <>
      <StyledDiv>
        <FooterButtons
          proceedButtonText={proceedButtonText}
          proceedButtonItemProp={proceedButtonItemProp}
          onProceedClick={onProceedClick}
          isDisabled={isProceedButtonDisabled} //proceed button disable condition - T&C not read and basket status is not moved to initialized yet
          extraButtonEanable={extraButtonEanable}
          extraButtonOnClick={extraButtonOnClick}
          extraButtonItemProp={extraButtonItemProp}
          extraButtonText={extraButtonText}
          extraButtonAlign={extraButtonAlign}
          isExitOnly
          enableExitPopup={false}
          onConfirmExitClick={onExitButtonClick}
          extraButtonDisabled={extraButtonDisabled}
        />
      </StyledDiv>
    </>
  );
};

export default CDFooter;
