import * as urls from '../apiUrls';
import { doPaymentRequest, doRequest } from 'utils/request';

const postPayment = (params) => {
  return doPaymentRequest(`${urls.POST_PAYMENT}`, 'post', params);
};

const generateReceipt = (params, id) => {
  return doRequest(
    `${urls.GENERATE_RECEIPT}/${params}/${id}`,
    'get',
    '',
    'application/json',
    false,
    'arraybuffer'
  );
};

const generateBagSlip = (params, id) => {
  return doRequest(
    `${urls.GENERATE_BAGSLIP}/${params}/${id}`,
    'get',
    '',
    'application/json',
    false,
    'arraybuffer'
  );
};

const downloadBagSlip = (loanNumber) => {
  return doRequest(
    `${urls.DOWNLOAD_BAGSLIP}/${loanNumber}`,
    'get',
    '',
    'application/json',
    false,
    'arraybuffer'
  );
};

export { postPayment, generateReceipt, generateBagSlip, downloadBagSlip };
