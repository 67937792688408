import React, { useState } from 'react';
import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';
import { format } from 'date-fns';
import VirtualGrid from 'components/common/virtualGrid/index';
import ImageLoader from 'components/common/ImageLoader';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import { currencyFormat } from 'utils/util';
import AuctionDetailsExpandableTable from './auctionDetailsExpandableTable';
import { BUTTON_LABELS } from 'components/pledgemanagement/constants';

const StyledSpan = styled.span`
  text-align: center;
  padding: 0 5px 0 5px;
`;

const StyledButton = styled(Button)`
  margin: 0 5px;
  position: inherit;
`;

interface ReadyForAuctionSummaryProps {
  auctionLoans: Array<any>;
  setIsPassLoanConfirmVisible: (isVisible: boolean) => void;
  setSelectedRowObj: (row: any) => void;
}

const ReadyForAuctionSummary = ({
  auctionLoans = [],
  setIsPassLoanConfirmVisible,
  setSelectedRowObj,
}: ReadyForAuctionSummaryProps) => {
  const [columns] = useState([
    {
      name: 'customerName',
      title: 'Customer Name',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <>
          <ImageLoader
            customerId={row?.customerId}
            imageUrl={
              row?.customerId &&
              `/api/customers/images/${row?.customerId}?isThumnails=false`
            }
            classes={`mx-small-avatar mx-small-avatar-yellow`}
            borderRadius={'50%'}
            fallbackImg={'customer-pic.svg'}
            preview
          />
          <StyledSpan>{row?.customerName}</StyledSpan>
        </>
      ),
    },
    {
      name: 'lotNumber',
      title: 'Lot No.',
      getCellValue: (row: any) => row?.lotNumber,
    },
    {
      name: 'followsLotNumber',
      title: 'Fellows Lot No.',
      getCellValue: (row: any) => row?.followsLotNumber,
    },
    {
      name: 'loanNumber',
      title: 'Agreement',
      getCellValue: (row: any) => row?.loanNumber,
    },
    {
      name: 'loanValue',
      title: 'Loan Value',
      getCellValue: (row: any) =>
        '£' + currencyFormat(row?.loanValue?.toFixed(2)),
    },
    {
      name: 'dueDate',
      title: 'Due Date',
      getCellValue: (row: any) =>
        row && row?.dueDate
          ? format(new Date(row?.dueDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'items',
      title: 'Items',
      getCellValue: (row: any) => row?.items,
    },
    {
      name: 'letterDue',
      title: 'Letter Due',
      getCellValue: (row: any) =>
        row && row?.letterDue
          ? format(new Date(row?.letterDue), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'letterSent',
      title: 'Letter Sent',
      getCellValue: (row: any) =>
        row && row?.letterSent
          ? format(new Date(row?.letterSent), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'reserveValue',
      title: 'Reserve Value',
      getCellValue: (row: any) =>
        '£' + currencyFormat(row?.reserveValue?.toFixed(2)),
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row: any) => row?.status,
    },
    {
      name: '',
      title: '',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <>
          <StyledButton itemProp="secondary">{'View'}</StyledButton>
          <StyledButton
            itemProp="secondary"
            onClick={() => {
              setIsPassLoanConfirmVisible(true);
              setSelectedRowObj(row);
            }}
          >
            {BUTTON_LABELS.PASS_LOAN}
          </StyledButton>
        </>
      ),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'customerName', width: '13%' },
    { columnName: 'lotNumber', width: '6%' },
    { columnName: 'followsLotNumber', width: '8%' },
    { columnName: 'loanNumber', width: '7%' },
    { columnName: 'loanValue', width: '7%' },
    { columnName: 'dueDate', width: '7%' },
    { columnName: 'items', width: '5%' },
    { columnName: 'letterDue', width: '7%' },
    { columnName: 'letterSent', width: '7%' },
    { columnName: 'reserveValue', width: '8%' },
    { columnName: 'status', width: '5%' },
    { columnName: '', width: '14%' },
  ]);

  const getRowId = (row: any) => row?.auctionId;

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={auctionLoans}
        expandableComponent={AuctionDetailsExpandableTable}
        getRowId={getRowId}
      />
    </>
  );
};

export default ReadyForAuctionSummary;
