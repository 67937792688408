import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currencies: [] as Array<any>,
  error: {},
  buybackCharges: {
    buybackCharge: 0,
  },
  convertedCurrencies: {
    currencyExchangeRate: 0,
    fromAmount: '',
    fromCurrencyCode: '',
    toCurrencyCode: '',
    toAmount: '',
    isRoundDown: true,
    receiptNumber: '',
  },
  currenciesDescription: {
    fromCurrencyDescription: '',
    toCurrencyDescription: '',
  },
  originalFromAmount: 0,
  originalToAmount: 0,
  totalCurrencyAmount: {
    fromAmount: 0,
    toAmount: 0,
    buybackCharge: 0,
  },
  currencyStockBalance: {},
  buybackHistory: [] as Array<any>,
  isBuybackApply: false,
  isStockBlanceLoading: false,
  isConvertLoading: false,
  isUpDownLoading: false,
  isBuybackHistoryLoading: false,
  currencySymbol: {
    fromCurrencySymbol: '',
    toCurrencySymbol: '',
  },
  isBuySellTransLoading: false,
  proceedToBuyback: false,
  resetForm: false,
  selectedToCurrencyCode: '',
  buybackStoreId: 0,
  showRateGauranteePopup: false,
};
const slice = createSlice({
  name: 'tmActions',
  initialState,
  reducers: {
    currenciesSuccess(state, action) {
      state.error = {};
      state.currencies = action.payload;
    },
    buybackChargesSuccess(state, action) {
      state.error = {};
      state.buybackCharges = action.payload;
    },
    currenciesError(state, action) {
      state.error = {
        message: action.payload.message,
        description: action?.payload?.response?.data?.error,
      };
    },
    buybackChargesError(state, action) {
      state.error = {
        message: action.payload.message,
        description: action?.payload?.response?.data?.error,
      };
    },
    setBuyBackApply(state, action) {
      if (action.payload.isBuybackApply) {
        state.totalCurrencyAmount.buybackCharge = action.payload.buybackCharge;
      } else {
        state.totalCurrencyAmount.buybackCharge = 0;
      }
      state.isBuybackApply = action.payload.isBuybackApply;
    },
    initConvertedCurrency(state, action) {
      state.error = {};
      state.isConvertLoading = true;
    },
    initUpDownCurrency(state, action) {
      state.error = {};
      state.isUpDownLoading = true;
    },
    setConvertedCurrency(state, action) {
      return {
        ...state,
        error: {},
        isConvertLoading: false,
        isUpDownLoading: false,
        convertedCurrencies: {
          ...action.payload,
          receiptNumber: state.convertedCurrencies?.receiptNumber,
        },
        totalCurrencyAmount: {
          ...action.payload,
          buybackCharge: state.isBuybackApply
            ? state.buybackCharges.buybackCharge
            : 0,
        },
      };
    },
    setCurrenciesDescription(state, action) {
      state.currenciesDescription = { ...action.payload };
    },
    setOriginalAamount(state, action) {
      state.originalFromAmount = +action.payload?.fromAmount;
      state.originalToAmount = +action.payload?.toAmount;
    },
    setUpdownCurrency(state, action) {
      return {
        ...state,
        error: {},
        isConvertLoading: false,
        isUpDownLoading: false,
        convertedCurrencies: { ...action.payload },
        totalCurrencyAmount: {
          ...action.payload,
          buybackCharge: state.isBuybackApply
            ? state.buybackCharges.buybackCharge
            : 0,
        },
      };
    },
    convertedCurrencyError(state, action) {
      state.error = {
        message: action.payload.message,
        description: action?.payload?.response?.data?.error,
      };
      state.isConvertLoading = false;
      state.isUpDownLoading = false;
    },
    initCurrencyStockBalance(state, action) {
      state.error = {};
      state.isStockBlanceLoading = true;
      state.currencyStockBalance = {};
    },
    setCurrencyStockBalance(state, action) {
      return {
        ...state,
        error: {},
        currencyStockBalance: { ...action.payload },
        isStockBlanceLoading: false,
      };
    },
    currencyStockBalanceError(state, action) {
      state.error = {
        message: action.payload.message,
        description: action?.payload?.response?.data?.error,
      };
      state.isStockBlanceLoading = false;
    },
    initBuybackHistory(state, action) {
      state.error = {};
      state.isBuybackHistoryLoading = true;
      state.buybackHistory = [];
    },
    setBuybackHistory(state, action) {
      state.error = {};
      state.buybackHistory = action.payload;
      state.isBuybackHistoryLoading = false;
    },
    buybackHistoryError(state, action) {
      state.error = {
        message: action?.payload?.response?.data?.error,
        description: action?.payload?.response?.data?.errors?.receiptNumber,
      };
      state.isBuybackHistoryLoading = false;
      state.buybackHistory = [];
    },
    initTransaction(state, action) {
      state.error = {};
      state.isBuySellTransLoading = true;
    },
    setTransactionError(state, action) {
      state.error = {
        message: action.payload.message,
        description: action?.payload?.response?.data?.errors?.receiptNumber,
      };
      state.isBuySellTransLoading = false;
    },
    setTransactionSuccess(state, action) {
      state.error = {};
      state.isBuySellTransLoading = false;
      state.convertedCurrencies = initialState.convertedCurrencies;
      state.totalCurrencyAmount = initialState.totalCurrencyAmount;
      state.currenciesDescription = initialState.currenciesDescription;
      state.originalToAmount = 0;
      state.originalFromAmount = 0;
      state.isBuybackApply = false;
      state.resetForm = true;
    },
    selectedBuybackHistory(state, action) {
      return {
        ...state,
        error: {},
        buybackHistory: state.buybackHistory.map((buybackdata, i) =>
          buybackdata.transactionId === action.payload.transactionId
            ? { ...buybackdata, ...action.payload }
            : buybackdata
        ),
      };
    },
    proceedToBuybacks(state, action) {
      const selectedBuybackData =
        state.buybackHistory.filter((item) => item.isRowSelected)[0] || {};
      const updatedData = {
        ...selectedBuybackData,
        fromCurrencyCode: selectedBuybackData?.toCurrencyCode,
        toCurrencyCode: selectedBuybackData?.fromCurrencyCode,
        fromAmount: selectedBuybackData?.toAmount,
        toAmount: selectedBuybackData?.fromAmount,
        buybackCharge: 0,
      };

      return {
        ...state,
        convertedCurrencies: { ...updatedData },
        proceedToBuyback: true,
        buybackStoreId: updatedData?.storeNumber || 0,
      };
    },
    proceedToBuybackOff(state, action) {
      return {
        ...state,
        proceedToBuyback: false,
        resetForm: true,
      };
    },
    setCurrencySymbol(state, action) {
      return {
        ...state,
        currencySymbol: {
          ...state.currencySymbol,
          ...action.payload,
        },
      };
    },
    setSelectedToCurrencyCode(state, action) {
      state.selectedToCurrencyCode = action.payload;
    },
    clearBuybackHistory(state, action) {
      state.buybackHistory = [];
    },
    resetForm(state, action) {
      return {
        ...state,
        resetForm: false,
        convertedCurrencies: initialState.convertedCurrencies,
        currenciesDescription: initialState.currenciesDescription,
        totalCurrencyAmount: initialState.totalCurrencyAmount,
      };
    },
    resetTotalCurrency(state, action) {
      return {
        ...state,
        isBuybackApply: false,
        totalCurrencyAmount: initialState.totalCurrencyAmount,
      };
    },
    clearFx(state) {
      return {
        ...initialState,
      };
    },
    checkRateGuaranteePopup(state, action) {
      state.showRateGauranteePopup = action.payload;
    },
  },
});

export default slice.reducer;
export const { reducer, actions } = slice;
export { initialState };
