import * as urls from '../apiUrls';
import { b64toBlob } from 'utils/util';
import { doRequest } from 'utils/request';

const getChequeType = () => {
  return doRequest(`${urls.GET_CHEQUE_TYPES}`, 'get');
};

const getServiceFee = () => {
  return doRequest(`${urls.GET_SERVICE_FEE}`, 'get');
};

const getViewIssuerDetails = (id) => {
  return doRequest(`${urls.GET_VIEWISSUER_DETAILS}/${id}`, 'get');
};

const getCompanyType = () => {
  return doRequest(`${urls.GET_COMPANY_TYEPS}`, 'get');
};

const getIssuerAccountDetails = (ACCOUNT_NO, SORT_CODE) => {
  return doRequest(
    `${urls.GET_ACCOUNT_DETAILS}/${ACCOUNT_NO}/${SORT_CODE}`,
    'get'
  );
};

const getChequeNumberValidation = (params) => {
  return doRequest(`${urls.VALIDATE_CHEQUE_NUMBER}`, 'post', params);
};

const getFeeDetails = () => {
  return doRequest(`${urls.GET_ITEM_FEE_DETAILS}`, 'get');
};

const getChequeCashingSummary = (id) => {
  return doRequest(`${urls.THIRD_PARTY_SUMMARY}/${id}`, 'get');
};
const getChequeCashingOverview = (id) => {
  return doRequest(`${urls.THIRD_PARTY_OVERVIEW}/${id}`, 'get');
};

const searchForIssuer = (param) => {
  return doRequest(`${urls.SEARCH_ISSUER}/${param}`, 'get');
};

const postPreAuthentication = (params) => {
  return doRequest(`${urls.PRE_AUTH}`, 'post', params);
};

const postNewIssuer = (params) => {
  return doRequest(`${urls.ADD_NEW_ISSUER}`, 'post', params);
};

const uploadChequeCashingDoc = (params) => {
  let blob = b64toBlob(params);

  let formData = new FormData();
  formData.append('file', blob);

  return doRequest(
    `${urls.CHEQUE_CASHING_DOCUMENT}`,
    'post',
    formData,
    'multipart/form-data'
  );
};
const sendToSupportCenter = (params) => {
  return doRequest(`${urls.SEND_TO_SUPPORT_CENTER}`, 'post', params);
};

const getPartPaymentData = (customerId) => {
  return doRequest(
    `${urls.PART_PAYMENT_DATA}/${customerId ? customerId : ''}`,
    'get'
  );
};

const getPartPaymentDocs = (thirdPartyChequeId) => {
  return doRequest(`${urls.PART_PAYMENT_DOCS}/${thirdPartyChequeId}`, 'get');
};

export {
  searchForIssuer,
  getChequeCashingSummary,
  getChequeCashingOverview,
  postNewIssuer,
  getChequeType,
  getCompanyType,
  getServiceFee,
  getViewIssuerDetails,
  getFeeDetails,
  getIssuerAccountDetails,
  postPreAuthentication,
  getChequeNumberValidation,
  uploadChequeCashingDoc,
  sendToSupportCenter,
  getPartPaymentData,
  getPartPaymentDocs,
};
