import React, { useState } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { GRID_NO_URL } from 'globalConstants';
import { EXPENSE_IN_OUT_LABELS } from 'components/cashManagement/constants';
import VirtualGrid from 'components/common/virtualGrid/index';
import ImageLoader from 'components/common/ImageLoader';

interface ExpenseInOutSummaryProps {
  expenseList: Array<any>;
  onDeleteExpenseItem: (id: number) => void;
  onExpenseItemSelectionChange: (params: any) => void;
}

// interface ExpenseRowProps {
//   direction?: number;
//   category?: string;
//   notes?: string;
//   receipt?: any;
//   amount?: number | string;
// }

const TableRow = ({
  row,
  onClickTableRow,
  onClickDeleteRow,
  ...restProps
}: any) => {
  const onRowClick = () => {
    onClickTableRow(row);
  };

  const onDeleteClick = () => {
    onClickDeleteRow(row);
  };

  const Cell = () => {
    const { column } = restProps;
    if (column.name === 'delete') {
      return (
        <Table.Cell
          onClick={onDeleteClick}
          {...restProps}
          style={{
            cursor: 'pointer',
          }}
        />
      );
    }
    return (
      <Table.Cell
        onClick={onRowClick}
        {...restProps}
        style={{
          cursor: 'pointer',
        }}
      />
    );
  };

  return <>{Cell()}</>;
};

const ExpenseInOutSummary = ({
  expenseList = [],
  onDeleteExpenseItem,
  onExpenseItemSelectionChange,
}: ExpenseInOutSummaryProps) => {
  const onClickDeleteRow = (row: any) => {
    onDeleteExpenseItem(row.id);
  };

  const onClickTableRow = (row: any) => {
    onExpenseItemSelectionChange(row);
  };

  const onSelectionChange = (rowId: any) => {/* do nothing */};

  const [columns] = useState([
    {
      name: 'direction',
      title: 'Direction',
      getCellValue: (row: any) => {
        if (row.direction === EXPENSE_IN_OUT_LABELS.DIRECTION.IN.value)
          return EXPENSE_IN_OUT_LABELS.DIRECTION.IN.label;
        return EXPENSE_IN_OUT_LABELS.DIRECTION.OUT.label;
      },
    },
    {
      name: 'category',
      title: 'Category',
      getCellValue: (row: any) => row.categoryDesc,
    },
    {
      name: 'notes',
      title: 'Notes',
      getCellValue: (row: any) => row.notes,
    },
    {
      name: 'receipt',
      title: 'Receipt',
      getCellValue: (row: any) => {
        const url = row?.cashTransactionImages[0]
          ? `/api/ledger/image/${row?.cashTransactionImages[0]?.containerId}/${row?.cashTransactionImages[0]?.blobId}?isThumnails=false`
          : '';

        return (
          <ImageLoader
            imageUrl={url}
            classes={'item-photo-img'}
            fallbackImg={'no_image_thumbnail.svg'}
            borderRadius={'5%'}
            preview
          />
        );
      },
    },
    {
      name: 'amount',
      title: 'Amount',
      type: 'currency',
      getCellValue: (row: any) => +row?.amount,
    },
    {
      name: 'delete',
      title: ' ',
      type: 'delete',
      onClick: () => undefined,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'direction', width: 100 },
    { columnName: 'category', width: 180 },
    { columnName: 'notes', width: 180 },
    { columnName: 'receipt', width: 100 },
    { columnName: 'amount', width: 100, align: 'right' },
    { columnName: 'delete', width: 50, align: 'center' },
  ]);

  const [formattedColumns] = useState(['delete', 'amount']);

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        formattedColumns={formattedColumns}
        rowData={expenseList}
        cellComponent={(restProps: any) => (
          <TableRow
            onClickTableRow={onClickTableRow}
            onClickDeleteRow={onClickDeleteRow}
            {...restProps}
          />
        )}
        rowSelectionEnable={true}
        showSelectionColumn={false}
        showSelectAll={false}
        onSelectionChange={onSelectionChange}
        selectByRowClick={true}
        highlightRow={true}
      />
    </>
  );
};

export default ExpenseInOutSummary;
