import React from 'react';
import styled from 'styled-components';
import { Row, Col, Modal } from 'antd';

import { CaseSummary } from '../widgets';

const StyledModal = styled(Modal)`
  & .ant-modal-body {
    padding: 0;

    & .custom-popup {
      & > .ant-col {
        padding-top: 0;
      }
    }
  }
`;

const StyledRow = styled(Row)`
  & .left-col {
    padding: 18px 20px 0;
    border-right: 1px solid var(--light-gray);
  }

  & .title {
    font-size: var(--font-size-20);
    font-weight: var(--font-weight-500);
  }

  & .content {
    text-align: right;
    padding: 8px 0 0 0;
  }
`;

const CustomerSupportModal = ({ visible, onClose }: any) => {
  return (
    <StyledModal
      open={visible}
      centered
      width={1700}
      mask={false}
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
      footer={null}
    >
      <StyledRow>
        <Col span={14} className="left-col">
          <Row>
            <Col span={12} className="title">
              Customer Support Request
            </Col>
            <Col span={12} className="content">
              (*) Minimum of one selection required
            </Col>
          </Row>
        </Col>
      </StyledRow>
      <CaseSummary isModalView className="custom-popup" onClose={onClose} />
    </StyledModal>
  );
};

export default CustomerSupportModal;
