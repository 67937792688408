import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'antd';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { isEmpty } from 'lodash';
import { BASKET_STATUS, ROUTE_CONFIG } from 'globalConstants';
import { Button } from '../Button';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'redux/reducers/customer';

const StyledDiv = styled.div`
  text-align: center;
  font-size: var(--font-size-16);
  line-height: 25px;
  padding: 5px 0;
`;

const StyledModal = styled(Modal)`
  & .ant-modal-title {
    text-align: center;
    color: var(--red);
  }
`;

const RecallPopup = ({
  visible,
  setRecallPopupVisible,
  recallMessage,
  recallTitle,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    basket: { basket },
  } = useSelector((state: any) => {
    return {
      basket: state.basket,
    };
  });

  const [isOkClicked, setOkClicked] = useState<boolean>(false);

  useEffect(() => {
    if (isOkClicked && !isEmpty(basket) && basket?.basketStatus) {
      setRecallPopupVisible(false);

      if (
        basket?.basketStatus !== BASKET_STATUS.INITIALISED ||
        basket?.basketStatus !== BASKET_STATUS.SUCCESSFUL ||
        basket?.basketStatus !== BASKET_STATUS.FAILED
      )
        dispatch(actions.initSurplusCheck(true));

      switch (basket?.basketStatus) {
        case 2:
        case 3:
        case 4:
          dispatch(actions.setRecallCheckoutCustomer(true));
          navigate(`/${ROUTE_CONFIG.CHECKOUT}`);
          break;
        case 1:
        default:
          navigate(`/${ROUTE_CONFIG.BASKET}`);
          break;
      }
    }
  }, [basket, isOkClicked]); // eslint-disable-line react-hooks/exhaustive-deps

  const onConfirmClick = () => {
    setOkClicked(true);
  };

  return (
    <>
      <StyledModal
        title={recallTitle}
        open={visible}
        width={460}
        destroyOnClose={true}
        maskClosable={false}
        closable={false}
        footer={[
          <Row gutter={24} justify="center" key="recallPopup">
            <Col>
              <Button key="confirmRecall" onClick={onConfirmClick}>
                OK
              </Button>
            </Col>
          </Row>,
        ]}
        centered
      >
        <Row>
          <Col span={24}>
            <StyledDiv>{recallMessage}</StyledDiv>
          </Col>
        </Row>
      </StyledModal>
      ;
    </>
  );
};

export default RecallPopup;
