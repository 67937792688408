import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Modal, Row, Spin } from 'antd';
import styled from 'styled-components';
import { format } from 'date-fns';
import { FNS_DATE_FORMAT } from 'globalConstants';
import { currencyFormat, joinValues } from 'utils/util';
import { GET_CUSTOMER_PAWNBROKING_TAB_SUMMARY } from 'action_creators/pledge';
import { getEarlySettlement, postEarlySettlement } from 'services/pledge';
import { Button } from 'components/common/Button';
import { EARLY_SETTLEMENT } from 'components/customer/constants';
import { sendToPrinter } from 'services/user';

const StyledCol = styled(Col)<{ fontSize: string }>`
  font-size: var(--${(props) => props.fontSize});
  font-weight: var(--font-weight-500);
  padding: 20px 0px 0px;
  color: var(--red);
`;

const StyledCols = styled(Col)`
  padding: 10px 0;
`;

interface EarlySettlementPopupProps {
  visible: boolean;
  setESPopupVisible: () => void;
  row: any;
  isESMode: boolean;
}

interface EarlySettlmentObject {
  maxAmount?: number;
  expiryDate?: string;
  noticeDate?: string;
}

const EarlySettlementPopup = ({
  visible,
  setESPopupVisible,
  row,
  isESMode,
}: EarlySettlementPopupProps) => {
  const [settlementData, setSettlementData] = useState<
    EarlySettlmentObject | any
  >();
  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    customer: { customer },
    user: { user, selectedStore },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
      user: state.user,
    };
  });

  const dispatch = useDispatch();

  const getEarlySettlementData = async () => {
    try {
      setLoading(true);
      const response = await getEarlySettlement(row?.loanNumber);
      if (response?.data) {
        setLoading(false);
        setSettlementData(response?.data?.settle);
      }
    } catch (e: any) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getEarlySettlementData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onCancelClick = () => {
    setESPopupVisible();
  };

  const onSaveClick = async (isPrint = false) => {
    const payload = {
      loanNumber: row?.loanNumber,
      maxAmount: settlementData?.maxAmount,
      noticeDate: settlementData?.noticeDate,
      isPrint: isPrint,
      storeId: selectedStore?.storeId,
      userId: user?.userId,
      customerName: joinValues([customer.firstNames, customer.surname], true),
    };

    try {
      const response = await postEarlySettlement(payload);
      if (response?.data) {
        const customerId = customer?.customerId;
        dispatch({
          type: GET_CUSTOMER_PAWNBROKING_TAB_SUMMARY,
          payload: { customerId },
        });
        setESPopupVisible();
        if (isPrint) {
          sendToPrinter(response, 'printcontract', '_EarlySettlementNotice');
        }
      }
    } catch (e: any) {
      setESPopupVisible();
      console.log(e);
    }
  };

  return (
    <>
      <Modal
        title={EARLY_SETTLEMENT.TITLE}
        open={visible}
        width={650}
        destroyOnClose
        maskClosable={false}
        centered
        onCancel={onCancelClick}
        footer={
          !isESMode
            ? [
                <Row gutter={24} key="EarlySettlementPopup">
                  <Col span={4}>
                    <Button
                      itemProp="secondary"
                      key="earlySettlementCancel"
                      onClick={onCancelClick}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col span={4} offset={10}>
                    <Button
                      itemProp="secondary"
                      key="earlySettlementSave"
                      onClick={() => onSaveClick()}
                    >
                      Save
                    </Button>
                  </Col>
                  <Col span={6}>
                    <Button
                      key="earlySettlementSavePrint"
                      onClick={() => onSaveClick(true)}
                      itemProp="secondary"
                    >
                      Save & Print
                    </Button>
                  </Col>
                </Row>,
              ]
            : null
        }
      >
        {isLoading ? (
          <Row>
            <StyledCols span={24}>
              <Spin />
            </StyledCols>
          </Row>
        ) : (
          <>
            <Row>
              <StyledCols span={24}>
                <table className="withdraw-table">
                  <thead>
                    <tr>
                      <th>{EARLY_SETTLEMENT.MAX_SETTLEMENT_VALUE}</th>
                      <th>{EARLY_SETTLEMENT.NOTICE_GIVEN}</th>
                      <th>{EARLY_SETTLEMENT.EXPIRY_DATE}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{`${currencyFormat(
                        settlementData?.maxAmount,
                        true
                      )}`}</td>
                      <td>
                        {settlementData &&
                          format(
                            new Date(settlementData?.noticeDate),
                            FNS_DATE_FORMAT
                          )}
                      </td>
                      <td>
                        {settlementData &&
                          format(
                            new Date(settlementData?.expiryDate),
                            FNS_DATE_FORMAT
                          )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </StyledCols>
            </Row>
            <Row>
              <StyledCol span={24} fontSize="font-size-14">
                {`The customer will pay no more than £${
                  settlementData?.maxAmount
                } until ${
                  settlementData &&
                  format(new Date(settlementData?.expiryDate), FNS_DATE_FORMAT)
                } to settle this agreement. Are you sure you wish to apply this settlement?`}
              </StyledCol>
            </Row>
          </>
        )}
      </Modal>
    </>
  );
};

export default EarlySettlementPopup;
