import axios from 'axios';
import {
  getMatrixToken,
  getMatrixStoreId,
  getMatrixUserId,
  getPaymentAccessToken,
  // removeMatrixToken,
  // setMatrixToken,
  // setRefreshToken,
  // getRefreshToken
} from 'utils/cookie';
import { encryptData } from 'utils/encrypt';
import { notification } from 'antd';

// import { useAuth } from 'providers/AuthProvider';

/**
 *
 * @param {*} url
 * @param {*} method
 * @param {*} dataOrParams? pass param with get method its automatically take as parameter,
 * pass param with post method its automatically take as body
 */
let source;
source = axios.CancelToken.source();
// axios.interceptors.request.use(
//   async function (config) {
//     // Do something before request is sent check token is active or not
//     if (getMatrixToken()) {
//       config.headers['Authorization'] = `Bearer ${getMatrixToken()}`;
//       config.headers['Accept'] = 'application/json';
//       config.headers['storeId'] = getMatrixStoreId();
//       config.headers['userId'] = getMatrixUserId();
//     }
//     return config;
//   },
//   async function (error) {
//     // Do something with request error
//     console.log('error', error);
//   }
// );

// axios.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (err) => {
//     const { signOut } = useAuth();
//     const originalConfig = err.config;
//     if (!originalConfig.url.includes("/users/login") && err.response) {
//       // Access Token was expired
//       if (err.response.status === 401 && !originalConfig._retry) {
//         originalConfig._retry = true;
//         try {
//           const payload = {
//             refereshToken: getRefreshToken(),
//           };
//           let response = await axios.post(
//             `${process.env.REACT_APP_API_DOMAIN}/api/users/refresh-token`,
//             payload,
//             {
//               headers: {
//                 Authorization: `Bearer ${getMatrixToken()}`,
//               },
//             }
//           );
//           if (response.status === 200) {
//             authenticationHandler(response.data);
//           }
//         } catch (error) {
//           notification.error({
//             message: error?.response?.data?.error,
//             duration: 3,
//           });
//           signOut();
//         }
//       }
//     }
//     return Promise.reject(err);
//   }
// );

// const authenticationHandler = (params) => {
//   const accessToken = params.access_token;
//   const refreshToke = params.refresh_token;

//   setAccessToken(accessToken);
//   setRefreshToken(refreshToke);
// }

// const setAccessToken = (accessToken) => {
//   removeMatrixToken();
//   setMatrixToken(accessToken);
// };

export async function doRequest(
  url,
  method = 'get',
  dataOrParams,
  contentType = '',
  isCancelToken = false,
  responseType = '',
  authorisationHeaders = null,
  validateUserAndStore = true
) {
  if (validateUserAndStore) {
    if (!getMatrixStoreId() || !getMatrixUserId()) {
      notification.error({
        message: 'Something went wrong. Please login again.',
        duration: 5,
      });
      return;
    }
  }

  let headers = {
    Accept: 'application/json',
    responseType: responseType,
    'Content-Type': contentType ? contentType : 'application/json',
    Authorization: `Bearer ${getMatrixToken()}`,
    storeId: getMatrixStoreId(),
    userId: getMatrixUserId(),
  };
  if (authorisationHeaders) {
    headers = {
      ...headers,
      AuthorizationUsername: authorisationHeaders?.authorisationUserName,
      AuthorizationPassword: encryptData(
        authorisationHeaders?.authorisationPassword
      ),
      TickValue: process.env.REACT_APP_SECRET_KEY,
    };
  }

  const params = method === 'get' ? dataOrParams : {};
  const data = method !== 'get' ? dataOrParams : undefined;

  /** cancel token is use for cancel previous request , send latest request
   * handling for frequent request on search
   */
  if (source && isCancelToken) {
    source.cancel();
    source = axios.CancelToken.source();
  }

  return await axios({
    url,
    method,
    data,
    timeout: 30 * 1 * 1000, //timeout
    params,
    headers,
    responseType,
    cancelToken: source?.token,
  });
}

export async function doPaymentRequest(
  url,
  method = 'get',
  dataOrParams,
  responseType = '',
  isCancelToken = false
) {
  if (!getMatrixStoreId() || !getMatrixUserId()) {
    notification.error({
      message: 'Something went wrong. Please login again.',
      duration: 5,
    });
    return;
  }

  let headers = {
    Accept: 'application/json',
    responseType: responseType,
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getMatrixToken()}`,
    storeId: getMatrixStoreId(),
    userId: getMatrixUserId(),
    paymentAccessToken: getPaymentAccessToken(),
  };

  const params = method === 'get' ? dataOrParams : {};
  const data = method !== 'get' ? dataOrParams : undefined;

  /** cancel token is use for cancel previous request , send latest request
   * handling for frequent request on search
   */
  if (source && isCancelToken) {
    source.cancel();
    source = axios.CancelToken.source();
  }

  return await axios({
    url,
    method,
    data,
    timeout: 30 * 1 * 1000, //timeout
    params,
    headers,
    responseType,
    cancelToken: source?.token,
  });
}
