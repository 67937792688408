import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { Col, Form, Input, Row, Tag, notification } from 'antd';
import { format } from 'date-fns';

import BankTransferFilters from './bankTransferFilters';
import ValuationFilters from './valuationFilters';

import Select from 'components/common/Select';
import DatePickerMUI from 'components/common/DatePickerMUI';
import { Button } from 'components/common/Button';

import {
  getFiltersAllServices,
  getFiltersAllStatuses,
  getManagedCaseStatuses,
  postGetAllManagedCases,
  postGetAllNotificationQuery,
} from 'services/workQueue';

import { cacheStaticData } from 'utils/cacheServices';
import { actions as userActions } from 'redux/reducers/user';

import { BUTTON_LABELS, PAGINATION_GRID, TABS_BY_ID } from '../constants';
import { FNS_DATE_FORMAT_YEAR, GENERIC_ERROR } from 'globalConstants';
import { searchLoanExceptions } from 'services/consumerDuty';
interface NotificationFiltersProps {
  activeTab: string;
}

const StyledCol = styled(Col)`
  padding: 0 6px;
`;

const StyledButtonRow = styled(Row)`
  height: 100%;
  padding-bottom: 6px;
  align-items: flex-end;
`;

const NotificationFilters = ({ activeTab }: NotificationFiltersProps) => {
  const { selectedNotificationsServices, notificationGridPage } = useSelector(
    (state: any) => state.user
  );

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [statusList, setStatusList] = useState<Array<any>>([]);
  const [serviceList, setServiceList] = useState<Array<any>>([]);
  const [manageCaseStatuses, setManageCaseStatuses] = useState<Array<any>>([]);

  const searchPayload = {
    customerName: '',
    referenceNumber: '',
    serviceIds: [],
    statusIds: [],
    description: "",
    dateFrom: '',
    dateTo: '',
  };

  const managedCasePayload = {
    customerName: '',
    referenceNumber: '',
    statusIds: [],
  };

  useEffect(() => {
    getStatusList();
    getServiceList();
    getManagedCaseStatusList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      activeTab === TABS_BY_ID.MY_ACTIONS &&
      serviceList?.length > 0 &&
      statusList?.length > 0
    )
      updateNotificationGridData();
  }, [notificationGridPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (serviceList?.length > 0 && statusList?.length > 0) {
      let availableServices = serviceList?.reduce(
        (arr: Array<number>, item: any) => [...arr, item?.id],
        []
      );
      let payload = {
        ...searchPayload,
        serviceIds:
          selectedNotificationsServices.length > 0
            ? selectedNotificationsServices
            : availableServices,
      };
      form.setFieldsValue({
        service:
          selectedNotificationsServices.length > 0
            ? selectedNotificationsServices
            : availableServices,
      });
      getPaginatedNotification(payload);
    }
  }, [statusList, serviceList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeTab === TABS_BY_ID.MANAGE_CASES) {
      getAllManagedCases(managedCasePayload);
    }
  }, [activeTab]); // eslint-disable-line react-hooks/exhaustive-deps

  const getStatusList = async () => {
    try {
      const response: any = await cacheStaticData(getFiltersAllStatuses);
      if (response?.data) setStatusList(response?.data?.statuses);
    } catch (e: any) {
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  const getManagedCaseStatusList = async () => {
    try {
      const response = await cacheStaticData(getManagedCaseStatuses);
      if (response?.data) setManageCaseStatuses(response?.data?.statuses);
    } catch (e: any) {
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  const getServiceList = async () => {
    try {
      const response = await cacheStaticData(getFiltersAllServices);
      if (response?.data) setServiceList(response?.data?.services);
    } catch (e: any) {
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  const getAllNotifications = async (payload: any) => {
    dispatch(userActions.setNotificationGridPage(0));
    getPaginatedNotification(payload);
  };

  const getPaginatedNotification = async (payload: any) => {
    try {
      dispatch(userActions.setLoading(true));

      const skip = PAGINATION_GRID.PAGE_SIZE * notificationGridPage;
      const response = await postGetAllNotificationQuery(
        payload,
        skip,
        PAGINATION_GRID.PAGE_SIZE
      );

      if (response?.data) {
        dispatch(
          userActions.setNotificationsWorkQueue(response?.data?.notifications)
        );
        dispatch(
          userActions.setTotalNotificationCount(response?.data?.totalCount)
        );
        dispatch(userActions.setLoading(false));
      }
    } catch (error: any) {
      dispatch(userActions.setLoading(false));
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  const getAllManagedCases = async (payload: any) => {
    try {
      dispatch(userActions.setLoading(true));

      const response = await postGetAllManagedCases(payload);
      if (response?.data)
        dispatch(userActions.setManagedCases(response?.data?.notifications));
      dispatch(userActions.setLoading(false));
    } catch (e: any) {
      dispatch(userActions.setLoading(false));
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  const onSearchClick = () => {
    const {
      customerName = '',
      referenceNumber = '',
      dateFrom,
      dateTo,
      status = [],
      service = [],
      description = '',
    } = form.getFieldsValue();

    const payload = {
      ...searchPayload,
      customerName,
      referenceNumber,
      statusIds: status,
      description,
      serviceIds: service,
      dateFrom: dateFrom
        ? format(new Date(dateFrom), FNS_DATE_FORMAT_YEAR)
        : '',
      dateTo: dateTo ? format(new Date(dateTo), FNS_DATE_FORMAT_YEAR) : '',
    };

    getAllNotifications(payload);
  };

  const updateNotificationGridData = () => {
    const {
      customerName = '',
      referenceNumber = '',
      dateFrom,
      dateTo,
      status = [],
      service = [],
      description = '',
    } = form.getFieldsValue();

    const payload = {
      ...searchPayload,
      customerName,
      referenceNumber,
      statusIds: status,
      description,
      serviceIds:
        selectedNotificationsServices.length > 0
          ? selectedNotificationsServices
          : service,
      dateFrom: dateFrom
        ? format(new Date(dateFrom), FNS_DATE_FORMAT_YEAR)
        : '',
      dateTo: dateTo ? format(new Date(dateTo), FNS_DATE_FORMAT_YEAR) : '',
    };

    getPaginatedNotification(payload);
  };

  const onSearchByReference = () => {
    const { referenceNumber } = form.getFieldsValue();
    if (!referenceNumber) return;

    const payload = {
      ...searchPayload,
      referenceNumber,
    };

    getAllNotifications(payload);
  };
  const onSearchBySubject = () => {
    const { description } = form.getFieldsValue();
    if (!description) return;

    const payload = {
      ...searchPayload,
      description,
    };

    getAllNotifications(payload);
  };

  const onSearchByCustomer = () => {
    const { customerName } = form.getFieldsValue();
    if (!customerName) return;

    const payload = {
      ...searchPayload,
      customerName,
    };

    getAllNotifications(payload);
  };

  const onResetBtnClick = () => {
    form.resetFields();

    let availableServices = serviceList?.reduce(
      (arr: Array<number>, item: any) => [...arr, item?.id],
      []
    );

    let payload = {
      ...searchPayload,
      serviceIds: availableServices,
    };
    form.setFieldsValue({
      service: availableServices,
    });

    getAllNotifications(payload);
  };

  const onSearchManagedCaseByCustomer = () => {
    const { managedCaseCustomer } = form.getFieldsValue();

    if (!managedCaseCustomer) return;

    const payload = {
      ...managedCasePayload,
      customerName: managedCaseCustomer.trim(),
    };

    getAllManagedCases(payload);
  };

  const onSearchManagedCaseByRef = () => {
    const { managedCaseReference } = form.getFieldsValue();
    if (!managedCaseReference) return;

    const payload = {
      ...managedCasePayload,
      referenceNumber: managedCaseReference,
    };

    getAllManagedCases(payload);
  };

  const onResetManagedCaseClick = () => {
    form.resetFields();
    getAllManagedCases(managedCasePayload);
  };

  const onSearchManagedCaseClick = () => {
    const {
      managedCaseCustomer = '',
      managedCaseReference = '',
      managedCaseStatus = [],
    } = form.getFieldsValue();

    const payload = {
      ...managedCasePayload,
      customerName: managedCaseCustomer.trim(),
      referenceNumber: managedCaseReference,
      statusIds: managedCaseStatus,
    };

    getAllManagedCases(payload);
  };

  const searchLockedLoans = async (payload: any) => {
    try {
      dispatch(userActions.setLoading(true));
      const response = await searchLoanExceptions(payload);
      if (response?.data) {
        const data = [];
        data.push(response.data);
        dispatch(userActions.setLockedLoans(data));
        form.resetFields();
      }
      dispatch(userActions.setLoading(false));
    } catch (e: any) {
      dispatch(userActions.setLoading(false));
      notification.error({
        message: e?.response?.data?.error || GENERIC_ERROR,
        duration: 5,
      });
    }
  };

  const onSearchByLoanNumber = () => {
    const { searchLockedLoan = '' } = form.getFieldsValue();

    const payload = {
      loanNumber: searchLockedLoan,
    };

    searchLockedLoans(payload);
  };

  const onSelectServices = (value: any) => {
    dispatch(userActions.setSelectedNotificationsServices(value));
  };

  const tagRenderer = (props: any) => {
    const { label, onClose } = props;

    return (
      <Tag closable onClose={onClose} className="custom-select-tag">
        {label}
      </Tag>
    );
  };

  return (
    <div className="filter-wrapper">
      <Form form={form}>
        {activeTab === TABS_BY_ID.MY_ACTIONS && (
          <Row>
            <StyledCol span={6}>
              <Row>
                <Col span={4} className="heading">
                  Filter By
                </Col>
                <Col span={10}>
                  <Form.Item
                    label="Service"
                    name="service"
                    className="label-control"
                  >
                    <Select
                      mode="multiple"
                      tagRender={tagRenderer}
                      onChange={onSelectServices}
                      options={serviceList.map((item: any) => {
                        return {
                          value: item.id,
                          label: item.name.trim(),
                        };
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item
                    label="Status"
                    name="status"
                    className="label-control"
                  >
                    <Select
                      mode="multiple"
                      tagRender={tagRenderer}
                      options={statusList?.map((item: any) => {
                        return {
                          value: item.id,
                          label: item.name.trim(),
                        };
                      })}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </StyledCol>
            <StyledCol span={10}>
              <Row>
                <Col span={3} className="heading">
                  Search By
                </Col>
                <Col span={7}>
                  <Form.Item
                    label="Reference"
                    name="referenceNumber"
                    className="label-control"
                  >
                    <Input.Search
                      placeholder="Enter Reference No."
                      className="input-control"
                      onSearch={onSearchByReference}
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    label="Subject"
                    name="description"
                    className="label-control"
                  >
                    <Input.Search
                      placeholder="Enter Subject"
                      className="input-control"
                      onSearch={onSearchBySubject}
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    label="Customer"
                    name="customerName"
                    className="label-control"
                  >
                    <Input.Search
                      placeholder="Enter Customer Name"
                      className="input-control"
                      onSearch={onSearchByCustomer}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </StyledCol>
            <StyledCol span={5}>
              <Row className="date-wrapper">
                <Col span={12}>
                  <Form.Item
                    name="dateFrom"
                    label="From Date"
                    className="label-control"
                  >
                    <DatePickerMUI />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="dateTo"
                    label="To Date"
                    className="label-control"
                  >
                    <DatePickerMUI />
                  </Form.Item>
                </Col>
              </Row>
            </StyledCol>
            <StyledCol span={3}>
              <StyledButtonRow justify="space-between">
                <Button itemProp="secondary" onClick={onSearchClick}>
                  {BUTTON_LABELS.SEARCH}
                </Button>
                <Button itemProp="secondary" onClick={onResetBtnClick}>
                  {BUTTON_LABELS.RESET}
                </Button>
              </StyledButtonRow>
            </StyledCol>
          </Row>
        )}

        {activeTab === TABS_BY_ID.LOAN_EXCEPTION && (
          <Row>
            <StyledCol span={5}>
              <Form.Item
                label="Search for Soft Locked Pledge"
                name="searchLockedLoan"
                className="label-control"
              >
                <Input.Search
                  className="input-control"
                  onSearch={onSearchByLoanNumber}
                />
              </Form.Item>
            </StyledCol>
            {/* <StyledCol span={1} className="heading">
              OR
            </StyledCol>
            <StyledCol span={12}>
              <Form.Item
                label="Search for Customer with Soft Locked Pledge"
                name="searchCustomer"
                className="label-control"
              >
                <Input.Search className="input-control" />
              </Form.Item>
            </StyledCol>
            <StyledCol span={5}></StyledCol> */}
          </Row>
        )}

        {activeTab === TABS_BY_ID.MANAGE_CASES && (
          <Row>
            <StyledCol span={6}>
              <Row>
                <Col span={4} className="heading">
                  Filter By
                </Col>

                <Col span={20}>
                  <Form.Item
                    label="Status"
                    name="managedCaseStatus"
                    className="label-control"
                  >
                    <Select
                      mode="multiple"
                      options={manageCaseStatuses?.map((item: any) => {
                        return {
                          value: item.id,
                          label: item.name,
                        };
                      })}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </StyledCol>
            <StyledCol span={18}>
              <Row>
                <Col span={2} className="heading">
                  Search By
                </Col>
                <StyledCol span={5}>
                  <Form.Item
                    label="Search for Case Reference"
                    name="managedCaseReference"
                    className="label-control"
                  >
                    <Input.Search
                      placeholder="Enter Reference No."
                      className="input-control"
                      onSearch={onSearchManagedCaseByRef}
                    />
                  </Form.Item>
                </StyledCol>
                <StyledCol span={1} className="heading">
                  OR
                </StyledCol>
                <StyledCol span={10}>
                  <Form.Item
                    label="Search for Customer with  Locked Pledge"
                    name="managedCaseCustomer"
                    className="label-control"
                  >
                    <Input.Search
                      className="input-control"
                      placeholder="Enter Customer Name"
                      onSearch={onSearchManagedCaseByCustomer}
                    />
                  </Form.Item>
                </StyledCol>
                <StyledCol span={5}>
                  <StyledButtonRow justify="space-between">
                    <StyledCol span={8}>
                      <Button
                        itemProp="secondary"
                        onClick={onSearchManagedCaseClick}
                      >
                        {BUTTON_LABELS.SEARCH}
                      </Button>
                    </StyledCol>
                    <StyledCol span={8}>
                      <Button
                        itemProp="secondary"
                        onClick={onResetManagedCaseClick}
                      >
                        {BUTTON_LABELS.RESET}
                      </Button>
                    </StyledCol>
                  </StyledButtonRow>
                </StyledCol>
              </Row>
            </StyledCol>
          </Row>
        )}
      </Form>

      {activeTab === TABS_BY_ID.BANK_TRANSFER && <BankTransferFilters />}
      {activeTab === TABS_BY_ID.VALUATION && <ValuationFilters />}
    </div>
  );
};

export default NotificationFilters;
