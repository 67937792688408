import React, { useState } from 'react';
import { format } from 'date-fns';
import VirtualGrid from 'components/common/virtualGrid/index';
import styled from 'styled-components';
import ImageLoader from 'components/common/ImageLoader';
import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';
import './index.less';

const StyledGridWrapper = styled.div`
  height: calc(65vh - 10px);
`;

const RetailSummary = ({ retailSummary }: any) => {
  const [columns] = useState([
    {
      name: 'status',
      title: 'Status',
      type: 'icon',
      displayContent: true,
      getCellValue: (row: any) => row.type,
    },
    {
      name: 'transactionDate',
      title: 'Date',
      getCellValue: (row: any) =>
        format(new Date(row.transactionDate), FNS_DATE_FORMAT),
    },
    {
      name: 'neoReceiptId',
      title: 'Receipt No.',
      getCellValue: (row: any) => row.neoReceiptId || row.retailTransactionId, // if neoReceiptId is null then pick retailTransactionId
    },

    {
      name: 'amountPaid',
      title: 'Amount',
      type: 'currency',
      getCellValue: (row: any) => row?.amountPaid,
    },
    {
      name: 'blankColumn',
      title: ' ',
      getCellValue: () => '',
    },
    {
      name: 'cashAmount',
      title: 'Refunded',
      type: 'currency',
      getCellValue: (row: any) => row?.refundAmount,
    },
    {
      name: 'blankColumn1',
      title: ' ',
      getCellValue: () => '',
    },
    {
      name: 'location',
      title: 'Location',
      getCellValue: (row: any) => row.storeName,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'status', width: '15%', align: 'left' },
    { columnName: 'transactionDate', width: '13%' },
    { columnName: 'neoReceiptId', width: '11%', align: 'right' },
    { columnName: 'amountPaid', width: '13%', align: 'right' },
    { columnName: 'blankColumn', width: '3%' },
    { columnName: 'cashAmount', width: '13%', align: 'right' },
    { columnName: 'blankColumn1', width: '3%' },
    { columnName: 'location', width: '23%' },
  ]);

  const [formattedColumns] = useState(['status', 'amountPaid', 'cashAmount']);
  const getRowId = (row: any) => row.id;
  return (
    <>
      <StyledGridWrapper key="customerSummaryRetail">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          rowData={retailSummary}
          tableColumnExtensionsProps={tableColumnExtensions}
          formattedColumns={formattedColumns}
          expandableComponent={RetailExpandableTable}
          getRowId={getRowId}
        />
      </StyledGridWrapper>
    </>
  );
};

/**
 * calling inner rows
 */
export const RetailExpandableTable = ({ row }: any) => {
  const [columns] = useState([
    {
      name: 'image',
      title: 'Image',
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row.itemId}`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'barcode',
      title: 'Barcode',
      getCellValue: (row: any) => row.barcode,
    },
    {
      name: 'description',
      title: 'Description',
      type: 'popover',
      getCellValue: (row: any) => {
        return {
          title: row.description,
          anchor: row.description,
          placement: 'bottom',
          itemId: row.itemId,
          data: {},
        };
      },
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) =>
        row?.weight > 0 ? row?.weight?.toFixed(2) + 'g' : row?.weight,
    },
    {
      name: 'listValue',
      title: 'Amount',
      type: 'currency',
      getCellValue: (row: any) => row?.saleValue,
    },
  ]);

  const [formattedColumns] = useState(['description', 'listValue']);
  const [tableColumnExtensions] = useState([
    { columnName: 'image' },
    { columnName: 'barcode' },
    { columnName: 'description', width: 200 },
    { columnName: 'weight', width: 200, align: 'right' },
    { columnName: 'listValue', align: 'right' },
  ]);

  return (
    <>
      <div className="retail-expandable-table-rows">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          formattedColumns={formattedColumns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={row.retailTransactionSummaryDetails}
        />
      </div>
    </>
  );
};
export default RetailSummary;
