export const PAYMENT_POPUP = {
  TITLE: 'Has the payment been processed through the PDQ Machine?',
  CODE_INVALID: 'Invalid Code',
  LABEL1: 'Please enter the authorisation code below:',
};

export const CODE_MESSAGES = {
  CODE_REQUIRED: 'Authorisation Code Required',
  CODE_PATTERN: new RegExp('^[0-9a-zA-Z]{6}$'),
  CODE_PATTERN_INVALID: 'Please enter 6 digit code',
};
