import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import { Layout, Row, Col, Form, Input, notification, Space } from 'antd';
import { sendToPrinter } from 'services/user';
import { Button } from 'components/common/Button';
import { BARCODE_MANAGER_LABELS } from 'components/inventoryManagement/constants';
import {
  onBarcodeSearch,
  getBarcodeSticker,
} from 'services/inventoryManagement';
import FooterButtons from 'components/common/footerButtons';

const StyledContentLayout = styled(Layout)`
  background: var(--white);
  padding: 15px 30px;
  border-radius: 5px;
`;

const StyledTableWrapper = styled.div`
  height: calc(100vh - 390px);
`;

const StyledMainContainer = styled(Col)`
  margin: 0px;
`;

const StyledTr = styled.table`
  margin-top: 30px;
`;

const StyledTotalRow = styled(Row)`
  font-weight: var(--font-weight-500);
  color: black;
  line-height: 30px;
`;

const StyledRow = styled(Row)`
  text-align: left;
  line-height: 30px;
`;

const FormContainer = styled(Row)`
  margin-top: 5px;
`;

const StyledButton = styled(Button)`
  height: 40px;
  margin-left: 20px;
  margin-top: 30px;
`;

const StyledInput = styled(Input)`
  padding: 0;
`;

const tableRow = (label: string, valueOne: any) => {
  return (
    <tr>
      <td width="40%">
        <StyledTotalRow> {label} </StyledTotalRow>
      </td>
      <td>
        <StyledRow>{valueOne}</StyledRow>
      </td>
    </tr>
  );
};

const BarcodeManager = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState('');
  const [content, setContent] = useState<any>();
  const [freetextDescription, setFreetextDescription] = useState<any>();

  const onSubmitClick = async (barcode: string) => {
    try {
      const response = await onBarcodeSearch(barcode);
      if (response?.data) {
        setContent(response?.data);
      }
    } catch (e: any) {
      /* continue regardless of error */
    }
  };

  const onSearch = () => {
    onSubmitClick(inputValue);
  };

  const onPrintBarcode = async () => {
    try {
      const pdf = await getBarcodeSticker({
        barcode: inputValue,
        custom: {
          description: freetextDescription,
        },
      });
      if (pdf?.data) {
        sendToPrinter(pdf, 'printbarcode', '_Retail_Barcode');
        notification.success({
          message: 'Sent to printer successfully',
          duration: 5,
        });
      }
    } catch (e: any) {
      notification.error({
        message: (e as Error).message,
        description: 'Barcode service is not running, please check.',
        duration: 10,
      });
    }
  };

  const onFreetextDescriptionChanged = (e: any) => {
    setFreetextDescription(e.target.value);
  };

  const onReset = () => {
    form.resetFields(['barcode']);
    setContent('');
    setInputValue('');
  };
  const onExitClick = () => {
    navigate('/');
  };
  return (
    <>
      <StyledContentLayout>
        <Row className="title-row">
          <Col span={24}>{BARCODE_MANAGER_LABELS.TITLES.BARCODE_MANAGER}</Col>
        </Row>
        <FormContainer>
          <Col span={24}>
            <Form
              form={form}
              name="addItemForm"
              layout="vertical"
              autoComplete="off"
              className="flex-column"
            >
              <Row>
                <Col span={6}>
                  <Form.Item
                    label="Scan/Enter Barcode"
                    name="barcode"
                    className="label-control"
                    rules={[
                      {
                        required: true,
                        message: 'barcode is required',
                      },
                    ]}
                  >
                    <Input
                      className="input-control custon-input-addon"
                      onChange={(e) => {
                        setInputValue(e.target.value);
                        onSubmitClick(e.target.value);
                      }}
                      value={inputValue}
                      placeholder="Enter Barcode"
                    />
                  </Form.Item>
                </Col>
                <Col span={14}>
                  <Space>
                    <StyledButton
                      itemProp="secondary"
                      htmlType="submit"
                      onClick={onSearch}
                    >
                      Submit
                    </StyledButton>

                    <StyledButton
                      itemProp="secondary"
                      htmlType="submit"
                      onClick={onReset}
                    >
                      Clear
                    </StyledButton>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Col>
        </FormContainer>
        <Row>
          <StyledTableWrapper className="item-table">
            <StyledMainContainer
              span={24}
              className="barcode-container-wrapper"
            >
              <Col lg={12} xl={12} xxl={12}>
                <StyledTr className="pledge-valuation-table">
                  <tbody>
                    {tableRow(
                      BARCODE_MANAGER_LABELS.TABLE_HEADINGS.BARCODE,
                      content ? content?.barcode : ''
                    )}
                    {tableRow(
                      BARCODE_MANAGER_LABELS.TABLE_HEADINGS.DESCRIPTION,
                      content ? (
                        <StyledInput
                          bordered={false}
                          placeholder="Freetext Description"
                          value={freetextDescription}
                          onChange={onFreetextDescriptionChanged}
                        />
                      ) : (
                        ''
                      )
                    )}
                  </tbody>
                </StyledTr>
                <StyledTr className="pledge-valuation-table second">
                  <tbody>
                    {tableRow(
                      BARCODE_MANAGER_LABELS.TABLE_HEADINGS.ITEMTYPE,
                      content ? content?.itemType : ''
                    )}
                    {tableRow(
                      BARCODE_MANAGER_LABELS.TABLE_HEADINGS.LOCATION,
                      content ? content?.location : ''
                    )}
                    {tableRow(
                      BARCODE_MANAGER_LABELS.TABLE_HEADINGS.QUCIKKAT,
                      content ? content?.quickCat : ''
                    )}
                    {tableRow(
                      BARCODE_MANAGER_LABELS.TABLE_HEADINGS.DESCRIPTION,
                      content ? content?.description : ''
                    )}
                    {tableRow(
                      BARCODE_MANAGER_LABELS.TABLE_HEADINGS.PRICE,
                      content ? content?.price : ''
                    )}
                  </tbody>
                </StyledTr>
              </Col>
            </StyledMainContainer>
          </StyledTableWrapper>
        </Row>
      </StyledContentLayout>
      <FooterButtons
        isExitOnly={true}
        enableExitPopup={false}
        onConfirmExitClick={onExitClick}
        proceedButtonText={BARCODE_MANAGER_LABELS.FOOTER_BUTTON_LABEL}
        onProceedClick={onPrintBarcode}
      />
    </>
  );
};
export default BarcodeManager;
