import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import AmountInputComponent from './AmountInput';
import LayawayFundsInput from './LayawayFundsInput';
import BankTransferForm from '../bankTransfer';
import { Tabs } from 'antd';
import { PAYMENT_MODES } from 'globalConstants';
import usePaymentMethods from 'components/payment/usePaymentMethods';

import { usePaymentContext } from 'components/payment/PaymentContext';
import {
  BankTransactionCompleted,
  BankTransactionSendToLSCCompleted,
  TransactionCompleted,
} from '../paymentConfirmation/DisplayMessageTransactionCompleted';
import {
  ACCOUNT_VALIDATION_RESULT_CODES,
  BANK_TRANSFER_STATUS,
  PAYMENT_TAB_KEYS,
} from '../constants';

const StyledTabs = styled(Tabs)<{
  $success: boolean;
  $isPaymentCompleted: boolean;
}>`
  &.payment-custom-tabs > .ant-tabs-content-holder {
    border: 0;
    margin: 0px 10px;
    .ant-tabs-content {
      .ant-tabs-tabpane {
        border: 3px solid var(--blue);
        border-radius: 5px;
        padding: 0 10px 10px 10px;
      }
      ${(props) =>
        props.$success &&
        css`
          margin-top: 30% !important;
        `}
      div[id$='-panel-1'] {
        margin-top: -5px !important;
      }
      div[id$='-panel-2'] {
        margin-top: 50px !important;
      }
      div[id$='-panel-3'] {
        margin-top: 115px !important;
      }
      div[id$='-panel-4'] {
        margin-top: 100px !important;
      }
      div[id$='-panel-5'] {
        margin-top: 40px !important;
      }
      div[id$='-panel-bank-transfer'] {
        margin-top: 10% !important;
      }
      ${(props) =>
        props.$isPaymentCompleted === true &&
        css`
          div[id$='-panel-bank-transfer'] {
            margin-top: 30% !important;
          }
          .ant-tabs-tabpane {
            border: 0;
          }
        `}
    }
  }
  &.payment-custom-tabs.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  &.payment-custom-tabs.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    transition: none !important;
    transform: none !important;
  }
  & .ant-tabs-nav-list {
    width: 28.5vh;
  }

  & .ant-tabs-tab {
    height: 7vh;
    width: 13.5vw;
    color: var(--blue);
    font-style: normal;
    font-weight: bold !important;
    background: var(--white) !important;
    padding: 15px 31.5px 15px 16px !important;
    border: 2px solid var(--blue) !important;
    border-radius: 4px !important;
    font-size: 2rem;

    &.ant-tabs-tab-btn:focus {
      color: var(--white) !important;
    }
    &.ant-tabs-tab-active {
      width: 13.5vw;
      & .ant-tabs-tab-btn {
        color: var(--white) !important;
        & img {
          filter: brightness(20);
        }
      }
      background: var(--blue) !important;
      box-sizing: border-box;
      border: 2px solid var(--blue) !important;

      &:after {
        content: '';
        position: absolute;
        top: 40%;
        left: 100%;
        margin-top: -5px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent transparent var(--blue);
      }
    }
    &.ant-tabs-tab-disabled {
      color: var(--white-rgba) !important;
      background: var(--gray) !important;
      border: 2px solid var(--light-gray) !important;
    }
  }

  .ant-tabs-content {
    margin-bottom: 50px;
  }

  ${(props) =>
    props.$success &&
    css`
      & .ant-tabs-tab {
        color: var(--grey) !important;
        border: 2px solid var(--light-gray) !important;

        & img {
          filter: grayscale(1);
        }

        &.ant-tabs-tab-active {
          width: 13.5vw;
          & .ant-tabs-tab-btn {
            color: var(--grey) !important;
            & img {
              filter: grayscale(1);
            }
          }
          border: 2px solid var(--light-gray) !important;
          background: var(--white) !important;
          &:after {
            content: '';
            border-width: 0px;
            border-style: thin;
            border-color: transparent;
          }
        }
      }
    `}
`;

const StyledIcon = styled.img`
  top: 6px;
  right: 12px;
  position: absolute;
`;

const StyledPaneIcon = styled(StyledIcon)<{
  $top?: string;
}>`
  ${(props) =>
    props.$top &&
    css`
      top: ${props.$top};
    `}
`;

const PaymentTab = (props: any) => {
  const { paymentSummary } = useSelector((state: any) => state.payment);

  const {
    isBankTransferVisible,
    isBankTransferModeDisable,
    isCardModeDisable,
  } = usePaymentMethods();
  const {
    isBankTransferRequestRecently,
    validatePayeeAccountResponse,
    isPaymentCompleted,
    recentBankTransferStatus,
  } = usePaymentContext();

  const [isBankTransferPaymentMethod, setBankTransferPaymentMethod] =
    useState<boolean>(isBankTransferVisible);

  const [key, setActiveKey] = useState('');

  const { isLayawayFundsMethodVisible } = props;
  const initialPanes = useMemo(() => {
    return [
      {
        title: PAYMENT_MODES.CASH,
        content: (
          <AmountInputComponent {...props} paymentMode={PAYMENT_MODES.CASH} />
        ),
        key: PAYMENT_TAB_KEYS.CASH,
        disabled: false,
        icon: 'cash_icon.svg',
      },
      {
        title: PAYMENT_MODES.CARD,
        content: (
          <AmountInputComponent
            {...props}
            isConfirmationRequired={true}
            paymentMode={PAYMENT_MODES.CARD}
          />
        ),
        key: PAYMENT_TAB_KEYS.CARD,
        disabled: isCardModeDisable,
        icon: 'card_icon.svg',
      },
      {
        title: PAYMENT_MODES.LAYAWAY_FUNDS,
        content: (
          <LayawayFundsInput
            {...props}
            paymentMode={PAYMENT_MODES.LAYAWAY_FUNDS}
            availableLayawayFunds={props?.availableLayawayFunds || 0.0}
            onAddPayment={props.onAddPayment}
          />
        ),
        key: PAYMENT_TAB_KEYS.LAYAWAY_FUNDS,
        disabled: false,
        icon: 'layaway_funds_icon.svg',
        iconWidth: '40px',
        iconHeight: '50px',
      },
      {
        title: PAYMENT_MODES.BANKTRANSFER,
        content: (
          <BankTransferForm
            {...props}
            activeTab={key}
            paymentMode={PAYMENT_MODES.BANKTRANSFER}
          />
        ),
        key: PAYMENT_TAB_KEYS.BANK_TRANSFER,
        disabled: isBankTransferModeDisable,
        icon: 'Banktransfer_ic_blue.svg',
        iconWidth: '36px',
        iconHeight: '45px',
        top: '8px',
      },
      {
        title: PAYMENT_MODES.VOUCHER,
        content: 'coming soon',
        key: PAYMENT_TAB_KEYS.VOUCHER,
        disabled: true,
        icon: '',
      },
    ];
  }, [isBankTransferModeDisable, props, isCardModeDisable, key]);

  const [panes, setPanes] = useState(initialPanes);

  const handleTabChange = (key: any) => {
    setActiveKey(key);
  };

  useEffect(() => {
    const availablePanes = initialPanes.filter((panesAvailabe: any) => {
      if (panesAvailabe.title === PAYMENT_MODES.LAYAWAY_FUNDS) {
        return isLayawayFundsMethodVisible;
      }
      if (panesAvailabe.title === PAYMENT_MODES.BANKTRANSFER) {
        return isBankTransferPaymentMethod;
      }
      return true;
    });
    setPanes([...availablePanes]);

    if (props.isPayment) setActiveKey('1');
  }, [
    initialPanes,
    isBankTransferPaymentMethod,
    isLayawayFundsMethodVisible,
    props.isPayment,
  ]);

  useEffect(() => {
    setActiveKey('');
  }, [paymentSummary]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setBankTransferPaymentMethod(isBankTransferVisible);
  }, [isBankTransferVisible]);

  const paymentTabItems = panes.map((pane) => {
    return {
      disabled: pane.disabled,
      label: (
        <>
          {pane.title}
          {pane.icon && (
            <StyledPaneIcon
              src={require(`../../../assets/images/${pane.icon}`)}
              width={pane?.iconWidth}
              height={pane?.iconHeight}
              $top={pane?.top}
            />
          )}
        </>
      ),
      key: pane.key,
      children: (
        <>
          {!props.isPayment ? (
            pane.content
          ) : isBankTransferRequestRecently ? (
            validatePayeeAccountResponse?.resultCode ===
              ACCOUNT_VALIDATION_RESULT_CODES.UNSUPPORTED ||
            recentBankTransferStatus === BANK_TRANSFER_STATUS.PENDING ? (
              <BankTransactionSendToLSCCompleted
                bankTransferStatus={recentBankTransferStatus}
              />
            ) : (
              <BankTransactionCompleted />
            )
          ) : (
            <TransactionCompleted />
          )}
        </>
      ),
    };
  });

  return (
    <StyledTabs
      className="payment-custom-tabs"
      onChange={handleTabChange}
      type="card"
      activeKey={key}
      tabPosition={'left'}
      tabBarGutter={10}
      animated={{ inkBar: false, tabPane: false }}
      $success={props.isPayment}
      $isPaymentCompleted={isPaymentCompleted}
      items={paymentTabItems}
    />
  );
};

export default PaymentTab;
