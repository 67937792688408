export const TILL_LABELS = {
  TILL: 'Till',
  NEO_DENOMINATION_MESSAGE: 'Neo Till Check',
  CURRENCY: 'Currency',
  BALANCE: 'Total Count Balance',
  SYSTEM_AMOUNT: 'System Amount',
  VARIANCE: 'Variance',
  CONFIRMED_BY: 'Confirmed By',
  EMPLOYEE_ID: 'Employee ID',
  PASSWORD: 'Password',
  NOTES: 'Notes',
  STORE_TILL_STATUS: 'Store Till Status',
  DEFAULT_TILL_CURRENCY: 'GBP',
  CASHED_UP: 'Cashed Up',
  BUTTON: {
    TILL_CHECK: 'Till Check',
    TILL_HISTORY: 'Till History',
    RESET: 'Reset',
    CASH_UP: 'Cash Up',
    END_DAY: 'End Day',
  },
  ERROR_MSG: {
    EMPLOYEE_ID: 'Employee ID is required',
    PASSWORD: 'Password is required',
    NOTES:
      'A reason must be entered for all till balances showing a variance in order to cash up',
  },
  NOTIFICATION: {
    CASH_UP_SUCCESS: 'Cash Up done successfully.',
    CASH_UP_ERROR: 'Cash Up could not be processed. Please try again later.',
    DENOMINATION_HISTORY_NOT_FOUND: 'Denomination history not found!',
  },
  SELECT_ALL_CURRENCIES: 'All',
};

export const CASH_DELIVERY = {
  OUTBOUND: 'Outbound',
  INBOUND: 'Inbound',
  METHOD: 'Method',
  DESTINATION: 'Destination',
  REFERENCE: 'Reference',
  CURRENCY: 'Currency',
  AMOUNT: 'Amount',
  SOURCE: 'Source',
  NOTES: 'Notes',
  CHECK_BY_EMP: 'Check by Employee ID',
  PASSWORD: 'Password',
  NOTIFICATION: {
    SUCCESS: 'Cash Delivery processed successfully',
    DENOMINATION_NOT_EXIST:
      'The amount is not possible in the denominations for this currency.',
  },
};

export const OUTBOUND_OPTIONS = [
  { id: 318, description: 'Outbound to Store' },
  { id: 331, description: 'Outbound to Bank' },
  // { id: '331C', description: 'Outbound to Bank (Courier)' }, // TODO - for later phase
  { id: 323, description: 'Outbound to CMS Recall (Courier)' },
];

export const INBOUND_OPTIONS = [
  { id: 308, description: 'Inbound from Store' },
  { id: 2, description: 'Inbound from Store (System)' }, // id not used for store system
  { id: 307, description: 'Inbound from Bank' },
  { id: 1, description: 'Inbound from CMS (Courier)' }, // id not used for cms
];

export const OUTBOUND_LABELS = {
  CMS_RECALL: 'CMS Recall',
  ERROR_MSG: {
    REFERENCE: 'Reference is required',
    DESTINATION: 'Destination is required',
    METHOD: 'Method is required',
    CURRENCY: 'Currency is required',
    AMOUNT: 'Amount is required',
    EMPLOYEE_ID: 'Employee ID is required',
    PASSWORD: 'Password is required',
  },
};

export const CASH_TRANSFER = {
  FROM: 'From',
  TO: 'To',
  CURRENCY: 'Currency',
  AMOUNT: 'Amount',
  EMPLOYEE_ID: 'Employee ID',
  PASSWORD: 'Password',
  ERROR_MSG: {
    FROM: {
      REQUIRED: 'From is required',
      SAME_USER: `From and To User can't be same`,
    },
    TO: {
      REQUIRED: 'To is required',
      SAME_USER: `From and To User can't be same`,
    },
    CURRENCY: 'Currency is required',
    AMOUNT: 'Amount is required',
    EMPLOYEE_ID: 'Employee ID is required',
    PASSWORD: 'Password is required',
  },
  NOTIFICATION: {
    SUCCESS: 'Cash Transfer processed successfully',
  },
};

export const EXPENSE_IN_OUT_LABELS = {
  DIRECTION: {
    IN: {
      value: 1,
      label: 'IN',
    },
    OUT: {
      value: -1,
      label: 'OUT',
    },
  },
  CATEGORY: 'Category',
  AMOUNT: 'Amount',
  NOTES: 'Notes',
  EMPLOYEE_ID: 'Check by Employee ID',
  PASSWORD: 'Password',

  ERROR_MSG: {
    AMOUNT: 'Amount is required',
    CATEGORY: 'Category is required',
    EMPLOYEE_ID: 'Employee ID is required',
    PASSWORD: 'Password is required',
  },
  NOTIFICATION: {
    SUCCESS: 'Petty Cash processed successfully',
  },
  OUT_CATEGORY_LIST: [
    { activityId: 336, description: 'RAF Voucher' },
    { activityId: 304, description: 'Cleaning Supplies' },
    { activityId: 319, description: 'Competitor Analysis (Paid)' },
    { activityId: 320, description: 'Computer Consumables' },
    { activityId: 321, description: 'Customer Compensation' },
    { activityId: 301, description: 'Door and Lock repairs / Key cutting' },
    { activityId: 333, description: 'KwikLoan paid out' },
    { activityId: 340, description: 'LOCAL AREA MARKETING' },
    { activityId: 322, description: 'Local Advertising ' },
    { activityId: 302, description: 'Minor Property Repairs' },
    { activityId: 300, description: 'Other Payments' },
    { activityId: 324, description: 'Postage' },
    { activityId: 325, description: 'Salary advance' },
    { activityId: 326, description: 'Small Tools and Equipment' },
    { activityId: 327, description: 'Stationery' },
    { activityId: 305, description: 'Tea / Coffee / Milk / Water /Sugar' },
    { activityId: 306, description: 'Trade Refuse' },
    { activityId: 329, description: 'Travel Costs' },
    { activityId: 303, description: 'Window Cleaning' },
    { activityId: 330, description: 'Window Display Items' },
    { activityId: 335, description: 'Jewellery Repair and Sizing' },
  ],
};

export const END_OF_DAY_POPUP = {
  TITLE: 'End Day',
  CONTENT: 'Closing store session logged',
  MESSAGE:
    'If reopened the active user will need to cash up and run end of day process again.',
};

export const CASH_MNGNT_ERROR = {
  STERLING_INSUFFICIENT_AMOUNT: 'Insufficient amount in user till.',
};

export const CASH_DELIVERY_TABS = [
  { key: 0, title: 'Outbound' },
  { key: 1, title: 'Inbound' },
];
