import { Col, Row, Space } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import { BANK_TRANSFER_LABELS, ERROR_MESSAGE } from '../constants';
import { WarningFilled } from '@ant-design/icons';

const LeftAlignedCol = styled(Col)`
  text-align: left;
`;
const RightAlignedCol = styled(Col)`
  text-align: right;
`;

function AccountValidationFailed({
  classes = '',
  onClear = () => {},
  onAccept = () => {},
  validationResult = null,
  isVerificationFailedAsCloseMatch = false, // amber
  isVerificationFailedAsNotFound = false, // red
  isVerificationFailedAsUnSupported = false, // amber
}: any) {
  const [isDisableFooterButtons, setIsDisableFooterButtons] =
    useState<boolean>(false);

  const handleAcceptOrEdit = () => {
    if (isVerificationFailedAsUnSupported) {
      setIsDisableFooterButtons(true);
    }
    onAccept(validationResult?.suggestedName);
  };

  const handleClear = () => {
    onClear();
  };

  const sugesstedName = validationResult?.suggestedName;
  const idEdit = isVerificationFailedAsCloseMatch && !sugesstedName;

  return (
    <Row
      className={
        isVerificationFailedAsNotFound
          ? `red-warning ${classes}`
          : `amber-warning ${classes}`
      }
    >
      <Col span={24}>
        <Row className="warning-msg">
          <LeftAlignedCol>
            <WarningFilled className="amber-icon" />
          </LeftAlignedCol>
          <Col>
            <Space direction="vertical">
              <div className="status">Account verification failed</div>
              {isVerificationFailedAsCloseMatch ? (
                <>
                  <div>{ERROR_MESSAGE.POTIENTIAL_MATCH_TEXT}</div>
                  <div className="bold">{sugesstedName || 'No Name'}</div>
                </>
              ) : (
                isVerificationFailedAsUnSupported && (
                  <div>{ERROR_MESSAGE.ACCOUNT_VALIDATION_UNSUPPORTED}</div>
                )
              )}
            </Space>
          </Col>
        </Row>
        {!isDisableFooterButtons && (
          <Row className="account-ac-footer">
            <LeftAlignedCol span={12}>
              <Button
                itemProp="secondary"
                key="back"
                onClick={handleClear}
                title="Clear out the above account details"
              >
                {BANK_TRANSFER_LABELS.CLEAR}
              </Button>
            </LeftAlignedCol>
            {!isVerificationFailedAsNotFound && (
              <RightAlignedCol span={12}>
                <Button
                  itemProp="secondary"
                  key="back"
                  onClick={handleAcceptOrEdit}
                >
                  {idEdit
                    ? BANK_TRANSFER_LABELS.EDIT
                    : BANK_TRANSFER_LABELS.ACCEPT}
                </Button>
              </RightAlignedCol>
            )}
          </Row>
        )}
      </Col>
    </Row>
  );
}

export default AccountValidationFailed;
