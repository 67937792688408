import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'antd';
import styled from 'styled-components';
import { format } from 'date-fns';
import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';
import { currencyFormat, getPledgeIcons } from 'utils/util';
import VirtualGrid from 'components/common/virtualGrid/index';
import { AGREEMENT_OPTIONS } from 'components/pickupRenew/constants';
import { actions } from 'redux/reducers/basket';
import ImageLoader from 'components/common/ImageLoader';

const StyledInput = styled(Input)`
  background: var(--white) !important;
  color: var(--black) !important;
  border: 1px solid var(--light-gray);
  box-shadow: 0px 1px 1px rgb(0 0 0 / 10%);
  border-radius: 3px;
  height: 42px;
  font-weight: var(--font-weight-500);
`;

const BasketPickupTable = (item: any) => {
  const { customerStatusColor } = useSelector((state: any) => state.customer);
  const rows = item?.items || [];
  const dispatch = useDispatch();

  const [selection, setSelection] = useState<any>([]);

  useEffect(() => {
    const payload = {
      serviceIds: selection,
    };
    //update redux merge payload when row selected
    dispatch(actions.move(payload));
  }, [selection]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectionChange = (row: any) => {
    setSelection(row);
  };


  const [columns] = useState([
    {
      name: 'loanNumber',
      title: 'Pledge No.',
      getCellValue: (row: any) => row?.pickupRenew?.request?.item?.loanNumber,
    },
    {
      name: 'loanStatusId',
      title: 'Status',
      type: 'icon',
      isMultipleIcons: true,
      isIconClickable: false,
      getCellValue: (row: any) =>
        getPledgeIcons(row?.pickupRenew?.request?.item, customerStatusColor),
    },
    {
      name: 'startDate',
      title: 'Start Date',
      getCellValue: (row: any) =>
        format(
          new Date(row?.pickupRenew?.request?.item?.startDate),
          FNS_DATE_FORMAT
        ),
    },
    {
      name: 'dueDate',
      title: 'Due Date',
      getCellValue: (row: any) =>
        format(
          new Date(row?.pickupRenew?.request?.item?.dueDate),
          FNS_DATE_FORMAT
        ),
    },
    {
      name: 'loanAmount',
      title: 'Loan Amount',
      type: 'currency',
      getCellValue: (row: any) => row?.pickupRenew?.request?.item?.loanAmount,
    },
    {
      name: 'interestRate',
      title: 'Interest',
      getCellValue: (row: any) =>
        `${row?.pickupRenew?.request?.item?.interestRate.toFixed(2)}%`,
    },
    {
      name: 'dailyInterest',
      title: 'Daily',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.pickupRenew?.request?.item?.dailyInterest,
    },
    {
      name: 'paidAmount',
      title: 'Paid',
      type: 'currency',
      getCellValue: (row: any) => row?.pickupRenew?.request?.item?.paidAmount,
    },
    {
      name: 'waivedOffAmount',
      title: 'Waived',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.pickupRenew?.request?.item?.waivedOffAmount,
    },
    {
      name: 'dueToday',
      title: 'Due Today',
      type: 'currency',
      getCellValue: (row: any) => row?.pickupRenew?.request?.item?.dueToday,
    },
    {
      name: 'affidavitAmount',
      title: 'Affidavit',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.pickupRenew?.request?.item?.affidavitAmount,
    },
    {
      name: 'actions',
      title: 'Actions',
      getCellValue: (row: any) => {
        return row?.pickupRenew?.request?.item?.selectedActionType ? (
          <StyledInput
            defaultValue={
              AGREEMENT_OPTIONS[
                row?.pickupRenew?.request?.item?.selectedActionType - 1
              ].value
            }
            disabled
          />
        ) : null;
      },
    },

    {
      name: 'newLoanAmount',
      title: 'New Loan',
      type: 'input',
      getCellValue: (row: any) =>
        currencyFormat(row?.pickupRenew?.request?.item?.newLoanAmount),
    },
    {
      name: 'toTakeAmount',
      title: 'To Take',
      type: 'input',
      getCellValue: (row: any) =>
        currencyFormat(row?.pickupRenew?.request?.item?.toTakeAmount),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'loanNumber', width: '7%' },
    { columnName: 'loanStatusId', width: '4%' },
    { columnName: 'startDate', width: '7%' },
    { columnName: 'dueDate', width: '7%' },
    { columnName: 'loanAmount', width: '7%', align: 'right' },
    { columnName: 'interestRate', width: '5%', align: 'right' },
    { columnName: 'dailyInterest', width: '5%', align: 'right' },
    { columnName: 'paidAmount', width: '5%', align: 'right' },
    { columnName: 'affidavitAmount', width: '7%', align: 'right' },
    { columnName: 'dueToday', width: '7%', align: 'right' },
    { columnName: 'actions', width: '8%' },
    { columnName: 'waivedOffAmount', width: '7%', align: 'right' },
    { columnName: 'newLoanAmount', width: '8%' },
    { columnName: 'toTakeAmount', width: '8%' },
  ]);

  const [formattedColumns] = useState([
    'newLoanAmount',
    'toTakeAmount',
    'loanStatusId',
    'loanAmount',
    'dailyInterest',
    'paidAmount',
    'waivedOffAmount',
    'dueToday',
    'affidavitAmount',
  ]);

  return (
    <>
      <div className="basket-item-table">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={rows}
          formattedColumns={formattedColumns}
          expandableComponent={PledgeExpandableTable}
          rowSelectionEnable={true}
          selection={selection}
          onSelectionChange={onSelectionChange}
          highlightRow={true}
        />
      </div>
    </>
  );
};

export const PledgeExpandableTable = ({ row }: any) => {
  const [columns] = useState([
    {
      name: 'itemImage',
      title: 'Item Image',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row.id}?isThumnails=false`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'description',
      title: 'Description',
      getCellValue: (row: any) => row.description,
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) => `${row?.weight?.toFixed(2)}g`,
    },
    {
      name: 'amount',
      title: 'Amount',
      type: 'currency',
      getCellValue: (row: any) => row?.amount,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'itemImage', width: 130 },
    { columnName: 'description', width: 230 },
    { columnName: 'weight', width: 120, align: 'right' },
    { columnName: 'amount', width: 120, align: 'right' },
  ]);

  return (
    <>
      <div className="expandable-table-rows">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={row?.pickupRenew?.request?.item?.pledgeItems}
        />
      </div>
    </>
  );
};

export default BasketPickupTable;
