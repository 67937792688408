import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Row, Col, Button, Space } from 'antd';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Select from '../Select';

import { BUTTON_LABELS } from './constant';
import WebcamIcons from './WebcamIcons';

const CameraDropdownRow = styled(Row)`
  margin-top: 20px;
`;

const StyledCol = styled(Col)`
  border: 1px dashed grey;
  padding: 10px;
  margin-top: 20px;
`;

export const IMAGE_CATEGORY = {
  CUSTOMER_PHOTO: 'CUSTOMER_PHOTO',
  DOCUMENT: 'DOCUMENT',
  DOCUMENT_DRIVING_LICENCE: 'DOCUMENT_DRIVING_LICENCE',
  DOCUMENT_PASSPORT: 'DOCUMENT_PASSPORT',
  ITEM_PHOTO: 'ITEM_PHOTO',
  OTHER: 'OTHER',
};

const cameraDropdown = [
  {
    key: 'webcam',
    value: 'wecam',
    label: 'Webcam',
  },
];

const Webcam = ({
  isRetake = false,
  width = 200,
  height = 200,
  type = '',
  getItemPhoto = (data: any) => {
    /* do nothing */
  },
}) => {
  const cropperRef = useRef<HTMLImageElement>(null);
  const [isTakePhoto, setIsTakePhoto] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);

  const [camera, setCamera] = useState<string>('webcam');
  const [image, setImage] = useState<any>(null);

  const [cropper, setCropper] = useState<any>();

  const setModifiedData = useCallback(
    (newCropper: any) => {
      const data = newCropper.getCroppedCanvas().toDataURL();
      getItemPhoto(data);
    },
    [getItemPhoto]
  );

  const onCrop = useCallback(() => {
    const imageElement: any = cropperRef?.current;
    const imgCropper: any = imageElement?.cropper;
    setModifiedData(imgCropper);
  }, [setModifiedData]);

  const handleZoom = useCallback(
    (zoomTo: number, event: any) => {
      event.stopPropagation();
      if (typeof cropper !== 'undefined') {
        cropper.zoom(zoomTo);
        setModifiedData(cropper);
      }
    },
    [cropper, setModifiedData]
  );

  const handleRotate = useCallback(
    (rotateTo: number, event: any) => {
      event.stopPropagation();
      if (typeof cropper !== 'undefined') {
        cropper.rotate(rotateTo);
        setModifiedData(cropper);
      }
    },
    [cropper, setModifiedData]
  );

  let myStream: any = null;
  const videoRef = useRef(null);
  const photoRef = useRef(null);

  useEffect(() => {
    getVideo();
    return () => {
      if (myStream !== null) {
        myStream.getTracks().forEach((track: any) => track.stop());
      }
    };
  }, [videoRef]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isRetake) {
      setIsTakePhoto(false);
      setIsRefresh(true);
    }
  }, [isRetake]); // eslint-disable-line react-hooks/exhaustive-deps

  const getVideo = async () => {
    await navigator.mediaDevices
      .getUserMedia({ video: { width, height } })
      .then((stream) => {
        myStream = stream;
        const video: any = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.error('error:', err);
      });
  };

  const paintToCanvas = () => {
    const video = videoRef.current;
    const photo: any = photoRef.current;
    const ctx = photo.getContext('2d');

    photo.width = 1000;
    photo.height = 720;
    return setInterval(() => {
      ctx.drawImage(video, 0, 0, 1000, 720);
    }, 200);
  };

  const takePhoto = () => {
    const photo: any = photoRef.current;
    const data: any = photo.toDataURL();
    setImage(data);
    setIsTakePhoto(true);
    if (type === IMAGE_CATEGORY.ITEM_PHOTO) {
      getItemPhoto(data);
    }
  };

  const reTakePhoto = () => {
    getVideo();
    setIsTakePhoto(false);
    setIsRefresh(true);
  };

  const onChangeCamera = (selectedValue: any) => {
    setCamera(selectedValue);
  };

  const isRefreshed = isRefresh ? 'none' : 'block';
  const isReCapture = isTakePhoto ? 'block' : isRefreshed;
  const isVideo = isTakePhoto ? 'none' : 'block';

  return (
    <>
      <Row justify="end">
        <Col className="webcam-icon-container">
          <WebcamIcons
            onRotate={handleRotate}
            onZoom={handleZoom}
            isDisableIcons={isReCapture !== 'block'}
          />
        </Col>
      </Row>
      <Row align="top" justify="center">
        <StyledCol span={24}>
          <Row className="evo-cropper-container" justify="center">
            <Col span={14} className="center-aligned">
              <Cropper
                src={image}
                style={{
                  height: 350,
                  width: '100%',
                  display: isReCapture,
                }}
                movable={true}
                rotatable={true}
                crop={onCrop}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                toggleDragModeOnDblclick={true}
                background={false}
                modal={true}
                ref={cropperRef}
                guides={true}
                autoCrop={false}
                scalable={true}
              />
              <video
                onCanPlay={() => paintToCanvas()}
                ref={videoRef}
                style={{
                  borderRadius: '8px',
                  width: '100%',
                  height: height,
                  objectFit: 'cover',
                  display: isVideo,
                }}
              />
              <canvas ref={photoRef} style={{ display: 'none' }} width="450" />
            </Col>
          </Row>
        </StyledCol>
      </Row>
      <CameraDropdownRow align="top" justify="center">
        <Col
          span={24}
          style={{
            textAlign: 'center',
          }}
        >
          <Space>
            <label className="bold">Choose Camera</label>
            <Select
              placeholder="Select"
              onChange={onChangeCamera}
              addinputcontrolclass={false}
              options={cameraDropdown}
              value={camera}
              className="select-camera-dropdown"
            />
            {!isTakePhoto && (
              <Button
                type="primary"
                block
                onClick={() => takePhoto()}
                className="btnPhoto"
              >
                {BUTTON_LABELS.TAKE_PHOTO}
              </Button>
            )}

            {isRetake && isTakePhoto && (
              <Button
                type="primary"
                block
                onClick={reTakePhoto}
                className="btnPhoto"
              >
                {BUTTON_LABELS.CHANGE_PHOTO}
              </Button>
            )}
          </Space>
        </Col>
      </CameraDropdownRow>
    </>
  );
};

export default Webcam;
