import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { currencyFormat, calculateTotalSumByKey } from 'utils/util';
import { PLEDGE_LOAN_DETAILS } from './constants';

const StyledTableRow = styled(Row)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

interface PledgePickupLoanTableProps {
  isClosedPledge?: boolean;
  allAgreements: Array<any>;
}

const LoanRow = (label: string, value: number) => {
  return (
    <tr>
      <td>{label}</td>
      <td>{currencyFormat(value.toFixed(2), true)}</td>
    </tr>
  );
};

const PledgePickupLoanTable = ({
  isClosedPledge = false,
  allAgreements,
}: PledgePickupLoanTableProps) => {
  /* Commenting as per EVB-1272 --- start  */
  // const calculateCostToRenew = () => {
  //   const costToRenew =
  //     calculateTotalSumByKey(allAgreements, 'interestAmount') -
  //     calculateTotalSumByKey(allAgreements, 'paidAmount');
  //   return costToRenew;
  // };
  /* Commenting as per EVB-1272 --- end  */

  return (
    <>
      <StyledTableRow>
        <Col span={11}>
          {/* Commenting the table as per EVB-1272 --- start  */}
          {/* <table className="pledge-details-table">
            <tbody>
              {LoanRow(
                PLEDGE_LOAN_DETAILS.COST_TO_RENEW,
                !isClosedPledge ? calculateCostToRenew() : 0
              )}
              {LoanRow(
                PLEDGE_LOAN_DETAILS.COST_TO_PICKUP,
                !isClosedPledge
                  ? calculateTotalSumByKey(allAgreements, 'dueToday')
                  : 0
              )}
            </tbody>
          </table> */}
          {/* Commenting the table as per EVB-1272 --- end  */}
        </Col>
        <Col span={12}>
          <table className="pledge-details-table">
            <tbody>
              {LoanRow(
                PLEDGE_LOAN_DETAILS.TOTAL_NEW_LOANS,
                !isClosedPledge
                  ? calculateTotalSumByKey(allAgreements, 'newLoanAmount')
                  : 0
              )}
              {LoanRow(
                PLEDGE_LOAN_DETAILS.TOTAL_TAKE_FROM_CUSTOMER,
                !isClosedPledge
                  ? calculateTotalSumByKey(allAgreements, 'toTakeAmount')
                  : 0
              )}
            </tbody>
          </table>
        </Col>
      </StyledTableRow>
    </>
  );
};

export default PledgePickupLoanTable;
