import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { Row, Col, Select, Switch } from 'antd';
import styled from 'styled-components';
import {
  formatDayAndDate,
  calculateTotalSumByKey,
  calculateTotalWeight,
} from 'utils/util';
import { actions as basketActions } from 'redux/reducers/basket';
import { PLEDGE_LOAN_PAYABLE } from 'action_creators/pledge';
import { LOAN_AMOUNT_CALCULATOR_ON_BASKET } from 'action_creators/basket';
import { BASKET_SERVICE_TYPE } from 'components/basket/constants';
import { PLEDGE_LOAN } from '../constants';
import { cacheStaticData } from 'utils/cacheServices';
import { ROUTE_CONFIG } from 'globalConstants';
import { getPledgeInterestRate } from 'services/pledge';

const StyledItemValue = styled.div`
  padding: 5px 15px;
`;

const StyledSelect = styled.div`
  padding: 5px 15px;
  & .ant-select.input-control {
    height: 35px !important;
    & .ant-select-selector {
      height: 33px !important;
    }
  }
  & .ant-select.ant-select-disabled {
    & .ant-select-selector {
      color: var(--black);
    }
  }
`;

const StyledMonthlyRate = styled(Col)`
  display: flex;
  align-items: center;
  // padding: 5px 0px;
`;

const StyledCol = styled(Col)`
  display: flex;
  padding: 0px 0 0px 15px;
  align-items: center;
  justify-content: space-between;
`;

const StyledBasketCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

interface PledgeLoanContainerProps {
  pledgeItemRows: any;
  basketId?: any;
  userId?: any;
  loanAmount?: any;
  totalWeight?: any;
}

const TableRow = (label: string, value: any) => {
  return (
    <tr>
      <td width={180}>{label}</td>
      <td>
        {label !== PLEDGE_LOAN.TOTAL_WEIGHT
          ? `£${value?.toFixed(2)}`
          : `${value?.toFixed(2)}g`}
      </td>
    </tr>
  );
};

const PledgeLoanContainer = ({
  pledgeItemRows,
  basketId = null,
  userId = null,
  loanAmount,
  totalWeight,
}: PledgeLoanContainerProps) => {
  const {
    basket: {
      isMonthlyInterestPercentageLoading,
      isSelectedInterestOnSplit,
      interestRateList,
      isAutoCalculated,
      basket,
    },
    user: { selectedStore },
  } = useSelector((state: any) => {
    return {
      basket: state.basket,
      user: state.user,
    };
  });
  const { serviceId, pledge = {} } = Object(pledgeItemRows);
  const { request = {} } = Object(pledge);

  const {
    dailyInterestRate,
    interestRate: resInterestRate,
    currentLoanPayableAmount,
    loanPayableAmount,
    annualPercentageRate,
  } = Object(request);

  const dispatch = useDispatch();
  const location = useLocation();

  const [interestRate, setInterestRate] = useState<any>();
  const [apr, setApr] = useState<any>();
  const allPledgeItems = pledgeItemRows?.pledge?.request?.items;

  useEffect(() => {
    getInterestRateList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch({
      type: LOAN_AMOUNT_CALCULATOR_ON_BASKET,
    });
  }, [isAutoCalculated]); // eslint-disable-line react-hooks/exhaustive-deps
  // whenever isAutoCalculated is changed , we need to re-calculate interest rate.

  const onInterestRateChange = (
    value: any,
    isManualOnChangeInterest: any = false
  ) => {
    if (isManualOnChangeInterest) {
      dispatch(basketActions.onChangeInterest(true));
    }
    const updateValue = typeof value === 'string' ? JSON.parse(value) : value;
    setInterestRate(updateValue);
    setApr(updateValue.publishedInterestAnnualPercentageRate);
    dispatch({
      type: PLEDGE_LOAN_PAYABLE,
      payload: {
        params: {
          storeId: selectedStore?.storeId,
          loanAmount: loanAmount,
          interestRate: isManualOnChangeInterest
            ? updateValue.monthlyInterestPercentCharge
            : resInterestRate || updateValue.monthlyInterestPercentCharge,
          publishedInterestAnnualPercentageRate:
            updateValue.publishedInterestAnnualPercentageRate,
          basketId,
          serviceId,
          userId,
          totalWeight,
          serviceType: BASKET_SERVICE_TYPE.PLEDGE,
          isAutoCalculated: isAutoCalculated,
          totalPledgeAmount: Math.abs(basket.totalPledgeAmount),
          isManualInterestSet: isManualOnChangeInterest,
        },
      },
    });
    return true;
  };

  const getInterestRateList = async () => {
    try {
      const response = await cacheStaticData(getPledgeInterestRate);
      if (response?.data) {
        const interest = response?.data?.interestRates[0];
        setInterestRate(interest);
        setApr(interest?.publishedInterestAnnualPercentageRate);
        dispatch(basketActions.setInterestRateList(response?.data));
        dispatch(
          basketActions.updateInitialInterestRate(
            interest.monthlyInterestPercentCharge
          )
        );
      }
    } catch (e: any) {
      console.log('error : ', e);
    }
  };

  const onAutoCalculateToggle = (checked: boolean) => {
    dispatch(basketActions.setAutoCalculatedBasketLoanAmount(!checked));
    if (!checked) {
      dispatch(basketActions.onChangeInterest(false));
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="end">
          <Col lg={17} xl={14} xxl={14}>
            <table className="pledge-valuation-table">
              <tbody>
                {TableRow(
                  PLEDGE_LOAN.TOTAL_WEIGHT,
                  calculateTotalWeight(allPledgeItems) || 0
                )}
                {TableRow(
                  PLEDGE_LOAN.LOAN_AMOUNT,
                  calculateTotalSumByKey(allPledgeItems, 'loanAmount') || 0
                )}
                {TableRow(PLEDGE_LOAN.DAILY_INTEREST, dailyInterestRate || 0)}
                {TableRow(
                  PLEDGE_LOAN.LOAN_AMT_PAYABLE,
                  currentLoanPayableAmount || 0
                )}
                {location.pathname === `/${ROUTE_CONFIG.BASKET}`
                  ? TableRow(
                      PLEDGE_LOAN.TOTAL_AMT_PAYABLE,
                      loanPayableAmount || 0
                    )
                  : null}
              </tbody>
            </table>
          </Col>
          <StyledBasketCol lg={7} xl={10} xxl={10}>
            <Row>
              <Col span={24}>
                <StyledItemValue>{`${PLEDGE_LOAN.ITEM_COUNT}: ${
                  allPledgeItems ? allPledgeItems.length : 0
                }`}</StyledItemValue>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {pledgeItemRows?.pledge?.request?.dueDate &&
                allPledgeItems.length ? (
                  <StyledItemValue>
                    {formatDayAndDate(pledgeItemRows?.pledge?.request?.dueDate)}
                  </StyledItemValue>
                ) : (
                  <div style={{ padding: '10px' }}></div>
                )}
              </Col>
            </Row>
            <Row>
              {location.pathname === `/${ROUTE_CONFIG.PLEDGE}` ? null : (
                <StyledCol span={12}>
                  <span
                    style={{
                      color: isAutoCalculated ? 'var(--black)' : 'var(--gray)',
                    }}
                  >
                    Basket %
                  </span>
                  <Switch
                    size="small"
                    className="pickup-switch"
                    onChange={onAutoCalculateToggle}
                    checked={!isAutoCalculated}
                  />
                  <span
                    style={{
                      color: isAutoCalculated ? 'var(--gray)' : 'var(--black)',
                    }}
                  >
                    Pledge %
                  </span>
                </StyledCol>
              )}
              <Col
                span={location.pathname === `/${ROUTE_CONFIG.PLEDGE}` ? 13 : 8}
              >
                <StyledSelect>
                  {interestRate || resInterestRate ? (
                    <Select
                      className="input-control"
                      placeholder="Select"
                      value={
                        (isSelectedInterestOnSplit && resInterestRate) ||
                        resInterestRate ||
                        interestRate.monthlyInterestPercentCharge
                      }
                      loading={isMonthlyInterestPercentageLoading}
                      onChange={(value: any) =>
                        onInterestRateChange(value, true)
                      }
                      disabled={
                        isAutoCalculated ||
                        location.pathname === `/${ROUTE_CONFIG.PLEDGE}`
                      }
                    >
                      {interestRateList &&
                        interestRateList?.interestRates?.map(
                          (item: any, index: number) => (
                            <Select.Option
                              key={index}
                              value={JSON.stringify(item)}
                            >
                              {item.monthlyInterestPercentCharge}
                            </Select.Option>
                          )
                        )}
                    </Select>
                  ) : null}
                </StyledSelect>
              </Col>
              <StyledMonthlyRate
                span={location.pathname === `/${ROUTE_CONFIG.PLEDGE}` ? 11 : 4}
              >
                {PLEDGE_LOAN.MONTHLY_RATE}
              </StyledMonthlyRate>
            </Row>
            <StyledItemValue>{`${
              annualPercentageRate ? annualPercentageRate : apr
            }% APR`}</StyledItemValue>
          </StyledBasketCol>
        </Row>
      </Col>
    </Row>
  );
};

export default PledgeLoanContainer;
