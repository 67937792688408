import React, { ReactNode } from 'react';
import { Spin } from 'antd';

interface SpinnerProps {
    tip?: string;
    size?: 'default' | 'small' | 'large';
    spinning: boolean;
    children: ReactNode;
}

const Spinner = (props: SpinnerProps) => {
    return (
        <Spin tip={props.tip || ''} size={props.size || 'default'} spinning={props.spinning}>
            {props.children}
        </Spin>
    );
};

export default Spinner;
