import * as urls from '../apiUrls';
import { doRequest } from 'utils/request';

const getRetailItems = (params) => {
  return doRequest(`${urls.GET_RETAIL_ITEMS}`, 'get', params);
};

const getMetalTypeAndFineness = (metalTypeId, finenessId) => {
  return doRequest(
    `${urls.GET_ITEM_FIXED_ATTRIBUTES}?attributeId=${metalTypeId}&attributeId=${finenessId}`,
    'get'
  );
};

const findRetailItems = (params) => {
  return doRequest(`${urls.FIND_RETAIL_ITEMS}`, 'post', params);
};

const findSoldItems = (params) => {
  return doRequest(`${urls.FIND_SOLD_ITEMS}`, 'post', params);
};

const getRefundReasons = () => {
  return doRequest(`${urls.GET_REFUND_REASONS}`, 'get');
};

const getAllStores = () => {
  return doRequest(`${urls.GET_ALL_STORES}`, 'get');
};

const getItemDetails = (rows) => {
  return doRequest(`${urls.GET_ITEM_DETAILS}/${rows.itemId}`, 'get');
};
const getRetailSaleSummary = (customerId) => {
  return doRequest(`${urls.GET_RETAIL_SALE_SUMMARY}/${customerId}`, 'get');
};

const getItemImage = (itemID) => {
  return doRequest(`${urls.GET_ITEM_IMAGE}/${itemID}`, 'get');
};

const getRecieptDetails = (recieptNumber) => {
  return doRequest(`${urls.GET_RECIEPT_DETAILS}/${recieptNumber}`, 'get');
};

const getRecieptDetailssoldItems = (stockRetailId) => {
  return doRequest(
    `${urls.GET_RECIEPT_DETAILS_SOLD_ITEMS}/${stockRetailId}`,
    'get'
  );
};

const getItemHistory = (rows) => {
  return doRequest(`${urls.GET_ITEM_HISTORY}/${rows.itemId}`, 'get');
};

const getRetailSummaryByCustomerId = (customerId) => {
  return doRequest(
    `${urls.GET_RETAIL_SUMMARY_BY_CUSTOMER_ID}/${customerId}`,
    'get'
  );
};

// params: {customerId: 0, barcode: ''}
const searchAddLayawayItem = (params) => {
  return doRequest(`${urls.SEARCH_ADD_LAYAWAY_ITEM}`, 'post', params);
};

// params: {customerId: 0, itemId: 0}
const deleteLayawayItem = (params) => {
  return doRequest(`${urls.DELETE_LAYAWAY_ITEM}`, 'post', params);
};

const getLayawayItems = (customer_id) => {
  return doRequest(`${urls.GET_LAYAWAY_ITEMS}/${customer_id}`, 'get');
};

const getLayawayTransactions = (customer_id) => {
  return doRequest(`${urls.GET_LAYAWAY_TRANSACTIONS}/${customer_id}`, 'get');
};

const depositWithdrawLayawayFunds = (params) => {
  return doRequest(`${urls.DEPOSIT_WITHDRAW_LAYAWAY_FUNDS}`, 'post', params);
};

const generateLayawayReceipt = (params, receiptType = 1) => {
  return doRequest(
    `${urls.GENERATE_LAYAWAY_RECEIPT}/${receiptType}`,
    'post',
    params,
    'application/json',
    false,
    'arraybuffer'
  );
};

const generateLayawayContract = (params) => {
  return doRequest(
    `${urls.GENERATE_LAYAWAY_CONTRACT}`,
    'post',
    params,
    'application/json',
    false,
    'arraybuffer'
  );
};

const generateLayawayStatement = (params) => {
  return doRequest(
    `${urls.GENERATE_LAYAWAY_STATEMENT}`,
    'post',
    params,
    'application/json',
    false,
    'arraybuffer'
  );
};

export {
  getRetailSummaryByCustomerId,
  getRefundReasons,
  getRecieptDetails,
  getRecieptDetailssoldItems,
  getItemImage,
  getAllStores,
  getRetailItems,
  getRetailSaleSummary,
  getMetalTypeAndFineness,
  findRetailItems,
  findSoldItems,
  getItemDetails,
  getItemHistory,
  searchAddLayawayItem,
  getLayawayItems,
  deleteLayawayItem,
  depositWithdrawLayawayFunds,
  generateLayawayReceipt,
  getLayawayTransactions,
  generateLayawayContract,
  generateLayawayStatement,
};
