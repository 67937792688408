import { combineReducers } from 'redux';
import customer from './customer';
import retail from './retail';
import chequeCashing from './chequeCashing';
import pawnbroking, { stone, coin } from './pawnbroking';
import basket from './basket';
import checkout from './checkout';
import pickupRenew from './pickupRenew';
import tm from './travelmoney';
import cashManagement from './cashManagement';
import inventoryManagement from './inventoryManagement';
import user from './user';
import payment from './payment';
import help from './help';
import admin from './admin';
import pledgeManagement from './pledgeManagement';
import westernUnion from './westernUnion';
import valuationCenter from './valuationCenter';
import consumerDuty from './consumerDuty';

const allReducers = combineReducers({
  coin,
  chequeCashing,
  customer,
  retail,
  stone,
  pawnbroking,
  basket,
  checkout,
  pickupRenew,
  tm,
  cashManagement,
  inventoryManagement,
  user,
  payment,
  help,
  admin,
  pledgeManagement,
  westernUnion,
  valuationCenter,
  consumerDuty,
});

const rootReducer = (state, action) => {
  if (action.type === 'SIGN_OUT') {
    state = undefined;
  }
  return allReducers(state, action);
};

export default rootReducer;
