import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Row, Col, Input, Form } from 'antd';
import styled from 'styled-components';
import { FindSoldItemPopupProps } from 'components/interface/FindSoldItemPopup';
import { Button } from 'components/common/Button';
import {
  getAllStores,
  getMetalTypeAndFineness,
  getRecieptDetailssoldItems,
} from 'services/retail/index';
import { FindSoldItemRequest } from 'components/interface/FindSoldItemRequest';
import {
  SELECT_PLACEHOLDER,
  RESET,
  STONE_SIZE_TO,
  STONE_SIZE_FROM,
  WEIGHT_RANGE_MAX,
  WEIGHT_RANGE_MIN,
  DESCRIPTION_PLACEHOLDER,
  CANCEL,
  PRICE_RANGE_MIN,
  PRICE_RANGE_MAX,
  SEARCH,
  TRANSACTION_FROM,
  TRANSACTION_TO,
  CONTINUE,
  METAL_TYPE_ATTRIBUTE_ID,
  FINENESS_ATTRIBUTE_ID,
  SOLD_ITEM,
} from '../constants';
import { actions } from 'redux/reducers/retail';
import FindSoldItemSummary from 'components/retail/viewRetailSale/FindSoldItemSummary';
import { useSelector, useDispatch } from 'react-redux';
import { FIND_SOLD_ITEMS } from 'action_creators/retail';
import {
  MetalTypeProps,
  FinenessProps,
} from 'components/interface/metalTypeAndFineness';
import { RecieptProps } from 'components/interface/RecieptProps';

import RefundExchangePopup from './RefundExchangePopup';
import InputDecimal from 'components/common/InputDecimal';
import Select from 'components/common/Select';
import DatePickerMUI from 'components/common/DatePickerMUI';
import { cacheStaticData } from 'utils/cacheServices';
import { dateDisplayFormat } from 'utils/util';

const StyledHeader = styled(Row)`
  margin-bottom: 8px;
  font-size: 2rem;
  white-space: initial;
  color: var(--text-color);
  font-weight: var(--font-weight-500);
  line-height: 23.44px;
`;

const StyledCol = styled(Col)`
  margin-right: 0px;
`;
const StyledDateCol = styled(Col)`
  margin-right: 0px;
  padding-left: 0px !important;
  & .ant-form-item-label {
    margin-left: 10px;
  }
`;

const StyledColLeft = styled(Col)`
  padding-left: 65px !important;
  padding-top: 25px !important;
  padding-right: 0px !important;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

const StyledContentContainer = styled.div`
  padding: 20px;
  margin: 10px 0;
  background: var(--catskill-white-light);
  border-radius: 6px;
`;

const StyledTableWrapper = styled(Col)`
  height: 40vh;
  min-height: 40vh;
`;

const StyledTransactionRow = styled(Row)`
  flex-flow: initial;

  & .MuiTextField-root {
    width: 400px;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  & .transactionTo {
    width: 34px;
  }
`;

const FindSoldItemPopup = ({
  isFindSoldItemPopupVisible,
  onFindSoldItemPopupCancel,
}: FindSoldItemPopupProps) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleRows, setVisibleRows] = useState<boolean>(false);
  const [barcode, setBarcode] = useState<string>('');
  const [row, setRow] = useState<Array<object>>([]);
  const [flag] = useState<boolean>(false);
  const [disabledSearch, setDisabledSearch] = useState<boolean>(false);
  const [allStores, setAllStores] = useState([]);
  const dispatch = useDispatch();
  const [metalType, setMetalType] = useState<Array<MetalTypeProps>>([]);
  const [fineness, setFineness] = useState<Array<FinenessProps>>([]);
  const [transactionMax, setTransactionMax] = useState<any>(undefined);
  const [transactionMin, setTransactionMin] = useState<any>(undefined);
  const [isContinueClicked, setIsContinueClicked] = useState<boolean>(false);

  const {
    retail: { rows, retailItems, isRowSelected },
    user: { selectedStore },
  } = useSelector((state: any) => {
    return {
      retail: state.retail,
      user: state.user,
    };
  });

  const [recieptDetails, setRecieptDetails] = useState<RecieptProps>({
    retailTransactionId: 0,
    neoReceiptId: 0,
    transactionDate: '',
    customerId: 0,
    itemCount: 0,
    storeId: selectedStore?.storeId,
    storeName: '',
    createdUserName: '',
    paymentType: 0,
    userId: 0,
    authUserId: 0,
    amountPaid: 0,
    cardAmount: 0,
    cashAmount: 0,
    paymentReference: '',
    retailRefundItemTransactionDetails: [],
  });

  useEffect(() => {
    if (isFindSoldItemPopupVisible) {
      setVisible(true);
    }
  }, [isFindSoldItemPopupVisible]);

  const loadAllStores = async () => {
    const response = await cacheStaticData(getAllStores);

    if (response?.data?.stores) {
      response?.data?.stores?.splice(0, 0, {
        storeId: selectedStore?.storeId,
        storeName: 'All',
      });
      setAllStores(response?.data?.stores);
    }
  };
  useEffect(() => {
    loadAllStores();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onCancelClick = useCallback(() => {
    setVisible(false);
    setIsContinueClicked(false);
    onFindSoldItemPopupCancel();
    dispatch(actions.resetRetailItems());
  }, [onFindSoldItemPopupCancel, dispatch]);

  const onInputKeyUp = () => {
    setDisabledSearch(false);
  };

  const addToRetailStore = () => {
    findRecieptNoSoldItem(row);
  };

  const onSubmitSearchForm = () => {
    const fieldSet: any = {
      storeId: selectedStore?.storeId,
      SelectedStoreId:
        Number(form.getFieldValue('SelectedStoreId')) || undefined,
      Barcode: form.getFieldValue('Barcode') || undefined,
      TransactionFromDate: transactionMax,
      TransactionToDate: transactionMin,
      Description: form.getFieldValue('Description') || undefined,
      MetalType: Number(form.getFieldValue('MetalType')) || undefined,
      Fineness: Number(form.getFieldValue('Fineness')) || undefined,
      WeightRange: {
        Min: Number(form.getFieldValue('weightRangeMin')) || undefined,
        Max: Number(form.getFieldValue('weightRangeMax')) || undefined,
      },
      PriceRange: {
        Min: Number(form.getFieldValue('priceRangeMin')) || undefined,
        Max: Number(form.getFieldValue('priceRangeMax')) || undefined,
      },
      StoneSize: {
        Min: Number(form.getFieldValue('stoneSizeFrom')) || undefined,
        Max: Number(form.getFieldValue('stoneSizeTo')) || undefined,
      },
    };

    Object.keys(fieldSet).forEach((key: any) => {
      if (fieldSet[key] === undefined || fieldSet[key] === '') {
        delete fieldSet[key];
      } else if (typeof fieldSet[key] === 'object') {
        if (
          fieldSet[key].Max === undefined &&
          fieldSet[key].Min === undefined
        ) {
          delete fieldSet[key];
        } else if (
          fieldSet[key].Max === undefined ||
          fieldSet[key].Max === ''
        ) {
          delete fieldSet[key].Max;
        } else if (
          fieldSet[key].Min === undefined ||
          fieldSet[key].Min === ''
        ) {
          delete fieldSet[key].Min;
        }
      }
    }); // Please mark /TODO optimize in future
    findSoldItems(fieldSet);
  };

  const findSoldItems = (params: FindSoldItemRequest) => {
    if (retailItems.length > 0) {
      dispatch(actions.resetRetailItems());
    }
    dispatch({
      type: FIND_SOLD_ITEMS,
      payload: {
        params: params,
      },
    });
  };

  useEffect(() => {
    if (flag) {
      setDisabledSearch(false);
    } else {
      setDisabledSearch(true);
    }
  }, [flag]);

  const onChangeMetalHandler = (e: any) => {
    onInputKeyUp();
    form.setFieldsValue({ metalType: e });
  };

  const onChangeStoreHandler = (e: any) => {
    onInputKeyUp();

    const filteredStores = allStores.filter((el: any) => {
      return el.storeId === parseInt(e);
    });

    if (filteredStores.length > 0) {
      form.setFieldsValue({ store: e });
    } else {
      form.setFieldsValue({ store: SELECT_PLACEHOLDER });
    }
  };

  const onChangeFinessHandler = (e: any) => {
    onInputKeyUp();
    form.setFieldsValue({ fineness: e });
  };

  const onResetClick = (e: any) => {
    e.preventDefault();
    setDisabledSearch(true);
    form.resetFields();
    setTransactionMin(undefined);
    setTransactionMax(undefined);
    dispatch(actions.resetRetailItems());
  };

  const onCallbackHandler = (barcode: string) => {
    setBarcode(barcode);
    setVisibleRows(false);
  };
  useEffect(() => {
    if (visibleRows) {
      onCancelClick();
    }
  }, [visibleRows, barcode, onCancelClick]);

  const onRowChange = (row: any) => {
    const stockItemFound = rows?.some((item: any) => {
      return row.stockItemId === item.stockItemId;
    });
    if (rows?.length === 0 || !stockItemFound) {
      setRow(row);
    }

    const payload = { row: row, isRowSelected: true };
    dispatch(actions.enableAddButton(payload));
  };

  useEffect(() => {
    loadMetalTypeAndFineness();
  }, []);

  const datePickerMax = (value: any) => {
    const formatedDate = dateDisplayFormat(value);

    form.setFieldsValue({ TransactionFromDate: value });
    setTransactionMax(formatedDate);
    onInputKeyUp();
  };
  const datePickerMin = (value: any) => {
    const formatedDate = dateDisplayFormat(value);

    form.setFieldsValue({ TransactionToDate: value });
    setTransactionMin(formatedDate);
    onInputKeyUp();
  };

  const loadMetalTypeAndFineness = async () => {
    const response = await getMetalTypeAndFineness(
      METAL_TYPE_ATTRIBUTE_ID,
      FINENESS_ATTRIBUTE_ID
    );
    if (response?.data) {
      response.data.itemFixedAttributeTypes.forEach((item: any) => {
        if (item.id === METAL_TYPE_ATTRIBUTE_ID) {
          setMetalType(item.itemFixedAttributes);
        } else if (item.id === FINENESS_ATTRIBUTE_ID) {
          setFineness(item.itemFixedAttributes);
        }
      });
    }
  };

  const findRecieptNoSoldItem = async (row: any) => {
    try {
      const response = await getRecieptDetailssoldItems(row.stockRetailId);
      if (response?.data) {
        setRecieptDetails(response?.data);
        setIsContinueClicked(true);
        setVisible(false);
      }
    } catch (e: any) {/* continue regardless of error */}
  };

  const onRefundExchangeCustomerCancelClick = () => {
    setIsContinueClicked(false);
    onCancelClick();
  };

  return (
    <Modal
      open={visible}
      closable={true}
      key={1}
      centered
      width={1556}
      maskClosable={false}
      onCancel={onCancelClick}
      footer={[
        <StyledButton itemProp="secondary" key="back" onClick={onCancelClick}>
          {CANCEL}
        </StyledButton>,
        <Button
          key="submit"
          disabled={!isRowSelected}
          onClick={addToRetailStore}
        >
          {CONTINUE}
        </Button>,
      ]}
    >
      <Row>
        <Col>
          <Row>
            <StyledHeader>{SOLD_ITEM}</StyledHeader>
          </Row>
          <Form layout="vertical" form={form}>
            <StyledContentContainer className="retail-wrapper">
              <Row gutter={25}>
                <StyledCol span={6} className="gutter-row">
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Barcode"
                        name="Barcode"
                        className="label-control"
                      >
                        <Input
                          className="input-control"
                          onKeyUp={onInputKeyUp}
                          onPressEnter={onSubmitSearchForm}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </StyledCol>
                <StyledCol span={6} className="gutter-row">
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Store"
                        name="SelectedStoreId"
                        className="label-control"
                      >
                        <Select
                          allowClear={true}
                          onChange={onChangeStoreHandler}
                          data-testid="all-stores"
                          options={allStores.map((el: any) => {
                            return { value: el.storeId, label: el.storeName };
                          })}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </StyledCol>
                <StyledDateCol span={6} className="gutter-row">
                  <Row>
                    <Col span={13} className="border">
                      <Form.Item
                        label="Transaction Date Range"
                        name="TransactionFromDate"
                        className="label-control"
                      >
                        <StyledTransactionRow>
                          <Input addonBefore={TRANSACTION_FROM} type="hidden" />
                          <DatePickerMUI
                            iconSize="font-size-16"
                            onChange={datePickerMax}
                            dataTestId="date-from"
                          />
                        </StyledTransactionRow>
                      </Form.Item>
                    </Col>

                    <Col span={11} className="border-right ">
                      <Form.Item
                        label="Date Range"
                        name="TransactionToDate"
                        className="label-control label-visibility"
                      >
                        <StyledTransactionRow>
                          <Input
                            addonBefore={TRANSACTION_TO}
                            className="transactionTo"
                            type="hidden"
                          />
                          <DatePickerMUI
                            iconSize="font-size-16"
                            onChange={datePickerMin}
                            dataTestId="date-to"
                          />
                        </StyledTransactionRow>
                      </Form.Item>
                    </Col>
                  </Row>
                </StyledDateCol>

                <StyledCol span={6} className="gutter-row">
                  <Row>
                    <Col span={12} className="border text-right">
                      <Form.Item
                        label="Price Range"
                        name="priceRangeMin"
                        className="label-control"
                      >
                        <InputDecimal
                          className="input-control"
                          addonBefore={PRICE_RANGE_MIN}
                          placeholder={false}
                          size="large"
                          onKeyUp={onInputKeyUp}
                          onPressEnter={onSubmitSearchForm}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12} className="border-right text-right">
                      <Form.Item
                        label="Price Range Max"
                        name="priceRangeMax"
                        className="label-control label-visibility"
                      >
                        <InputDecimal
                          className="input-control"
                          addonBefore={PRICE_RANGE_MAX}
                          placeholder={false}
                          size="large"
                          onKeyUp={onInputKeyUp}
                          onPressEnter={onSubmitSearchForm}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </StyledCol>
              </Row>

              <Row gutter={25}>
                <StyledCol span={12} className="gutter-row">
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Product Description"
                        name="Description"
                        className="label-control"
                      >
                        <Input
                          className="input-control"
                          placeholder={DESCRIPTION_PLACEHOLDER}
                          onKeyUp={onInputKeyUp}
                          onPressEnter={onSubmitSearchForm}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </StyledCol>
                <StyledCol span={6} className="gutter-row">
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Metal Type"
                        name="MetalType"
                        className="label-control"
                      >
                        <Select
                          allowClear={true}
                          onChange={onChangeMetalHandler}
                          options={metalType.map((el: any) => {
                            return { label: el.description, value: el.id };
                          })}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </StyledCol>
                <StyledCol span={6} className="gutter-row">
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Fineness"
                        name="Fineness"
                        className="label-control"
                      >
                        <Select
                          allowClear={true}
                          onChange={onChangeFinessHandler}
                          options={fineness.map((el: any) => {
                            return { label: el.description, value: el.id };
                          })}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </StyledCol>
              </Row>

              <Row gutter={25}>
                <StyledCol span={6} className="gutter-row">
                  <Row>
                    <Col span={12} className="border">
                      <Form.Item
                        label="Weight Range"
                        name="weightRangeMin"
                        className="label-control"
                      >
                        <InputDecimal
                          className="input-control"
                          addonBefore={WEIGHT_RANGE_MIN}
                          placeholder={false}
                          size="large"
                          onKeyUp={onInputKeyUp}
                          onPressEnter={onSubmitSearchForm}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12} className="border-right">
                      <Form.Item
                        label="Weight Range Max"
                        name="weightRangeMax"
                        className="label-control label-visibility"
                      >
                        <InputDecimal
                          className="input-control"
                          addonBefore={WEIGHT_RANGE_MAX}
                          placeholder={false}
                          size="large"
                          onKeyUp={onInputKeyUp}
                          onPressEnter={onSubmitSearchForm}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </StyledCol>

                <StyledCol span={6} className="gutter-row">
                  <Row>
                    <Col span={12} className="border">
                      <Form.Item
                        label="Stone Size"
                        name="stoneSizeFrom"
                        className="label-control"
                      >
                        <InputDecimal
                          className="input-control"
                          addonBefore={STONE_SIZE_FROM}
                          placeholder={false}
                          size="large"
                          onKeyUp={onInputKeyUp}
                          onPressEnter={onSubmitSearchForm}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12} className="border-right">
                      <Form.Item
                        label="Stone Size To"
                        name="stoneSizeTo"
                        className="label-control label-visibility"
                      >
                        <InputDecimal
                          className="input-control"
                          addonBefore={STONE_SIZE_TO}
                          placeholder={false}
                          size="large"
                          onKeyUp={onInputKeyUp}
                          onPressEnter={onSubmitSearchForm}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </StyledCol>
                <StyledColLeft span={12}>
                  <Row>
                    <Col span={7} offset={17}>
                      <StyledButton
                        itemProp="secondary"
                        key="back"
                        onClick={onResetClick}
                      >
                        {RESET}
                      </StyledButton>
                      <Button
                        key="submit"
                        disabled={disabledSearch}
                        onClick={onSubmitSearchForm}
                      >
                        {SEARCH}
                      </Button>
                    </Col>
                  </Row>
                </StyledColLeft>
              </Row>
            </StyledContentContainer>
          </Form>
          <StyledTableWrapper
            span={24}
            className="retail-summary-popover-wrapper"
          >
            {isContinueClicked ? (
              <RefundExchangePopup
                refundExchangeCustomer={true}
                onRefundExchangeCustomerCancelClick={
                  onRefundExchangeCustomerCancelClick
                }
                recieptData={recieptDetails}
              />
            ) : null}
            <FindSoldItemSummary
              parentCallback={onCallbackHandler}
              onRowChange={onRowChange}
            ></FindSoldItemSummary>
          </StyledTableWrapper>
        </Col>
      </Row>
    </Modal>
  );
};
export default FindSoldItemPopup;
