import React from 'react';
import { Modal, Row, Col, Form, Input } from 'antd';
import styled from 'styled-components';
import { PRINT_OR_SEND_QUOTE } from './constants';
import { Button } from '../common/Button';

const StyledButton = styled(Button)`
  margin: 26px 0 16px 26px;
`;

const StyledPrintQuoteButton = styled(Button)`
  margin: 8px 0;
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

interface PrintQuotePopupProps {
  visible: boolean;
  setPrintQuotePopupVisible: () => void;
}

const PrintQuotePopup = ({
  visible,
  setPrintQuotePopupVisible,
}: PrintQuotePopupProps) => {
  const [form] = Form.useForm();

  const onCancelClick = () => {
    setPrintQuotePopupVisible();
  };

  return (
    <>
      <Modal
        title={PRINT_OR_SEND_QUOTE}
        open={visible}
        footer={[
          <Row key={0} justify="end">
            <Col>
              <Button itemProp="secondary" onClick={onCancelClick}>
                Cancel
              </Button>
            </Col>
          </Row>,
        ]}
        width={480}
        onCancel={onCancelClick}
        destroyOnClose={true}
        maskClosable={false}
      >
        <Form layout="vertical" form={form}>
          <Row>
            <Col span={18}>
              <Form.Item label="Email" name="email" className="label-control">
                <Input className="input-control" />
              </Form.Item>
            </Col>
            <StyledCol span={6}>
              <StyledButton itemProp="secondary">Send</StyledButton>
            </StyledCol>
          </Row>
          <Row>
            <Col span={18}>
              <Form.Item label="Mobile" name="mobile" className="label-control">
                <Input className="input-control" />
              </Form.Item>
            </Col>
            <StyledCol span={6}>
              <StyledButton itemProp="secondary">Send</StyledButton>
            </StyledCol>
          </Row>
          <Row>
            <Col>
              <StyledPrintQuoteButton itemProp="secondary" htmlType="submit">
                Print Quote
              </StyledPrintQuoteButton>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default PrintQuotePopup;
