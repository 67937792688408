import { call, put, takeEvery } from 'redux-saga/effects';
import {
  CURRENCIES_REQUEST,
  BUYBACK_CHARGES_REQUEST,
  CONVERT_CURRENCY_REQUEST,
  UPDOWN_CURRENCY_REQUEST,
  CURRENCY_LEDGER_BALANCE,
  CURRENCY_STOCK_BALANCE_REQUEST,
  CURRENCY_BUYBACK_HISTORY_REQUEST,
  POST_FX_TRANSACTION,
} from 'action_creators/travelmoney';
import {
  postConvertCurrency,
  postRoundUpDownCurrency,
  postLedgerBalanceCurrency,
  postStockBalanceCurrency,
  getBuyback,
  postFxTransaction,
  getCurrencies,
  getBuybackCharges,
} from 'services/travelMoney';
import { cacheStaticData } from 'utils/cacheServices';
import { actions } from 'redux/reducers/travelmoney';
import { actions as basketActions } from 'redux/reducers/basket/index';

/**
 * this saga handles the GET currencies
 * (1) get currencies
 * (2) handle response
 */
export function* getCurrenciesSaga(action) {
  try {
    //request get currencies from API
    const payload = yield call(cacheStaticData, getCurrencies);
    if (payload?.data) {
      yield put(actions.currenciesSuccess(payload.data));
    } else if (payload?.errors) {
      yield put(actions.currenciesError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.currenciesError(e));
  }
}

/**
 * this saga handles the GET currencies
 * (1) get currencies
 * (2) handle response
 */
export function* getBuybackChargesSaga(action) {
  try {
    //request get currencies from API
    const payload = yield call(cacheStaticData, getBuybackCharges);
    if (payload?.data) {
      yield put(actions.buybackChargesSuccess(payload.data));
    } else if (payload?.errors) {
      yield put(actions.buybackChargesError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.buybackChargesError(e));
  }
}

/**
 * this saga handles the post Convert currency
 * (1) post postConvertCurrency
 * (2) handle response
 */
export function* postConvertCurrencySaga(action) {
  const {
    payload: { params },
  } = action;
  try {
    yield put(actions.initConvertedCurrency());
    //request get currencies from API
    const payload = yield call(postConvertCurrency, params);
    if (payload?.data) {
      payload.data = {
        ...payload.data,
        isRoundDown: params.isRoundDown,
      };
      yield put(actions.setConvertedCurrency(payload.data));
      yield put(actions.checkRateGuaranteePopup(true));
    } else if (payload?.errors) {
      yield put(actions.convertedCurrencyError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.convertedCurrencyError(e));
  }
}

/**
 * this saga handles the post Convert currency
 * (1) post postUpDownCurrency
 * (2) handle response
 */
export function* postUpDownCurrencySaga(action) {
  const {
    payload: { params },
  } = action;
  try {
    yield put(actions.initUpDownCurrency());
    //request get currencies from API
    const payload = yield call(postRoundUpDownCurrency, params);
    if (payload?.data) {
      payload.data = {
        ...payload.data,
        isRoundDown: !params.isRoundDown,
      };
      yield put(actions.setConvertedCurrency(payload.data));
    } else if (payload?.errors) {
      yield put(actions.convertedCurrencyError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.convertedCurrencyError(e));
  }
}

/**
 * cuncurrency call for postLedgerBalanceCurrencySaga , postStockLogcashSumSaga, postStockBalanceCurrencySaga
 * this saga handles the get Closing Date balance
 * (1) post getStockClosingBalanceDate
 * (2) handle response
 */
export function* postLedgerBalanceCurrencySaga(action) {
  const {
    payload: { params },
  } = action;

  try {
    yield put(actions.initCurrencyStockBalance());
    //request post postLedgerBalanceCurrency from API
    const payload = yield call(postLedgerBalanceCurrency, params);
    if (payload?.data) {
      const payloadParams = {
        payload: {
          params: {
            ...payload.data,
            // storeBalance: 500, //TODO remove because not handle in service when null
          },
        },
      };
      yield call(postStockBalanceCurrencySaga, payloadParams);
    } else if (payload?.errors) {
      yield put(actions.currencyStockBalanceError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.currencyStockBalanceError(e));
  }
}

/**
 * this saga handles the get currency stock
 * (1) get getCurrencyStockBalance
 * (2) handle response
 */
export function* postStockBalanceCurrencySaga(action) {
  const {
    payload: { params },
  } = action;
  try {
    yield put(actions.initCurrencyStockBalance());
    //request get stock currencies from API
    const payload = yield call(postStockBalanceCurrency, params);
    if (payload?.data) {
      yield put(actions.setCurrencyStockBalance(payload.data));
    } else if (payload?.errors) {
      yield put(actions.currencyStockBalanceError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.currencyStockBalanceError(e));
  }
}

/**
 * this saga handles the get currency buyback history
 * (1) get getBuyback
 * (2) handle response
 */
export function* getGetBuybackHistorySaga(action) {
  const {
    payload: { params },
  } = action;
  try {
    yield put(actions.initBuybackHistory());
    //request get buyback history from API
    const payload = yield call(getBuyback, params);
    if (payload?.data) {
      yield put(actions.setBuybackHistory(payload.data));
    } else if (payload?.errors) {
      yield put(actions.buybackHistoryError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.buybackHistoryError(e));
  }
}

/**
 * this saga handles the post transaction for save
 * (1) post postFxTransaction
 * (2) handle response
 */
export function* postFxTransactionSaga(action) {
  const {
    payload: { params, currencySymbols },
  } = action;
  try {
    yield put(actions.initTransaction());
    //request post transaction from API
    const payload = yield call(postFxTransaction, params);
    if (payload?.data) {
      yield put(actions.setTransactionSuccess());
      yield put(basketActions.createBasket(payload?.data));
      yield put(actions.setCurrencySymbol(currencySymbols));
      yield put(actions.setCurrencyStockBalance({}));
    } else if (payload?.errors) {
      yield put(actions.setTransactionError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.setTransactionError(e));
  }
}

export function* watchGetCurrenciesSaga() {
  yield takeEvery(CURRENCIES_REQUEST, getCurrenciesSaga);
}

export function* watchGetBuybackChargesSaga() {
  yield takeEvery(BUYBACK_CHARGES_REQUEST, getBuybackChargesSaga);
}

export function* watchPostConvertCurrencySaga() {
  yield takeEvery(CONVERT_CURRENCY_REQUEST, postConvertCurrencySaga);
}

export function* watchPostUpDownCurrencySaga() {
  yield takeEvery(UPDOWN_CURRENCY_REQUEST, postUpDownCurrencySaga);
}

export function* watchLedgerBalanceCurrencySaga() {
  yield takeEvery(CURRENCY_LEDGER_BALANCE, postLedgerBalanceCurrencySaga);
}

export function* watchStockBalanceCurrencySaga() {
  yield takeEvery(CURRENCY_STOCK_BALANCE_REQUEST, postStockBalanceCurrencySaga);
}

export function* watchGetBuybackHistorySaga() {
  yield takeEvery(CURRENCY_BUYBACK_HISTORY_REQUEST, getGetBuybackHistorySaga);
}

export function* watchPostFxTransactionSaga() {
  yield takeEvery(POST_FX_TRANSACTION, postFxTransactionSaga);
}
