import React, { useState } from 'react';
import './index.less';
import { Popover } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import hdata from './help.json';

const StyledDivCentredContainer = styled.div`
  text-align: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
`;
const StyledSpanContainer = styled.span`
  margin: 0px 5px;
  height: 100%;
  vertical-align: middle;
`;

const StyledHelpIcon = styled.img`
  padding: 0px 0px;
  background: none;
  border: none;
  height: 22px;
  vertical-align: middle;
  font-weight: 500;
  color: #11a1fd;
  cursor: pointer;
`;

const HelpPopoverInfo = (props: any) => {
  const {
    help: { help },
  } = useSelector((state: any) => {
    return {
      help: state.help,
    };
  });

  const { linkedID, position, helpPosition, attachedComp } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);
  const popovertoggle = () => setPopoverOpen(!popoverOpen);
  //console.log('LinkedID:' + linkedID + '  Position:' + helpPosition);
  let title = '',
    content = '',
    linkText = '',
    url = '',
    videolink = '';

  // file deepcode ignore PureMethodReturnValueIgnored: <please specify a reason of ignoring this>
  hdata.Help.map((item) => {
    if (item.id === linkedID) {
      title = item.title;
      content = item.content;
      linkText = item.linkText;
      url = item.url;
      videolink = item.videolink;
    }
    return '';
  });

  const EmbededVideoLink = () => {
    let videolinkstr;
    if (videolink !== '') {
      videolinkstr = (
        <iframe
          width="660"
          height="415"
          src={videolink}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      );
    } else {
      videolinkstr = <span></span>;
    }
    return videolinkstr;
  };
  const fullPopoverContent = () => {
    const ContentStr = (
      <div>
        <span dangerouslySetInnerHTML={{ __html: content }}></span>
        <StyledDivCentredContainer>
          {EmbededVideoLink()}
        </StyledDivCentredContainer>
        <StyledDivCentredContainer>
          <span>
            <a href={url} target="blank">
              {linkText}
            </a>
          </span>
        </StyledDivCentredContainer>
      </div>
    );
    return ContentStr;
  };

  const onHelpClick = () => {
    setPopoverOpen(!popoverOpen);
  };

  const shouldDisplay = () => {
    return help.enabled && content !== '';
  };

  return (
    <>
      {helpPosition !== 'BEFORE' ? (
        <span>
          {props.children}
          <StyledSpanContainer>
            <StyledHelpIcon
              src={require('assets/images/Help_info_ic.svg')}
              className="HelpButton"
              onClick={onHelpClick}
              alt="HelpIcon"
              style={{
                display: shouldDisplay() ? 'inline' : 'none',
                marginTop: attachedComp === 'BUTTON' ? '10px' : '',
              }}
            />
            <Popover
              style={{ width: '500px' }}
              content={fullPopoverContent}
              title={title}
              trigger="click"
              open={popoverOpen}
              onOpenChange={popovertoggle}
              arrowPointAtCenter
              placement={position ? position : 'rightTop'}
              className="PopoverLimit"
            ></Popover>
          </StyledSpanContainer>
        </span>
      ) : (
        <span>
          <StyledSpanContainer>
            <StyledHelpIcon
              src={require('assets/images/Help_info_ic.svg')}
              className="HelpButton"
              // title={title}
              onClick={onHelpClick}
              alt="HelpIcon"
              style={{
                display: shouldDisplay() ? 'inline' : 'none',
                marginTop: attachedComp === 'BUTTON' ? '10px' : '',
              }}
            />
            <Popover
              style={{ width: '500px' }}
              content={fullPopoverContent}
              title={title}
              trigger="click"
              open={popoverOpen}
              onOpenChange={popovertoggle}
              arrowPointAtCenter
              placement={position ? position : 'rightTop'}
              className="PopoverLimit"
            ></Popover>
          </StyledSpanContainer>
          {props.children}
        </span>
      )}
    </>
  );
};

const StyledHelpPopoverInfoForButtons = styled(HelpPopoverInfo)`
  margin-top: 10px;
  /* margin-left: 10px; */
  display: inline;
  float: left;
`;

export default HelpPopoverInfo;
export { HelpPopoverInfo, StyledHelpPopoverInfoForButtons };
