import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  submiting: false, //loading status for submit button
  searching: false, //loading status for for search button
  deleting: false, //loading status for for delete button
  authorizing: false,
  isAuthorized: false,
  isErrorVisible: false,
  isSuccessMsgVisible: false,

  error: '',
  successMsg: '',
  authorizerName: '',

  documentsData: [],
  documentsImages: [],
};

const slice = createSlice({
  name: 'westernUnionActions',
  initialState,
  reducers: {
    initLoading(state, action) {
      state.loading = action.payload;
    },
    initSubmit(state, action) {
      state.submiting = action.payload;
    },
    initSearch(state, action) {
      state.searching = action.payload;
    },
    initDelete(state, action) {
      state.deleting = action.payload;
    },
    initAuthorization(state, action) {
      state.authorizing = action.payload;
    },
    setIsErrorVisible(state, action) {
      state.isErrorVisible = action.payload;
    },
    setIsSuccessMsgVisible(state, action) {
      state.isSuccessMsgVisible = action.payload;
    },
    setDocumentsData(state, action) {
      state.documentsData.push(action.payload);
    },
    setDocumentsImages(state, action) {
      state.documentsImages.push(action.payload);
    },
    logSuccess(state, action) {
      state.successMsg = action.payload;
    },
    logError(state, action) {
      state.error = action.payload;
    },
    clearDocumentsData(state) {
      state.documentsData = [];
      state.documentsImages = [];
    },
  },
});

export default slice.reducer;
export const { reducer, actions } = slice;
export { initialState };
