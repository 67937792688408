import React from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Row, Col, Input } from 'antd';
import { USER_MANAGER } from 'components/admin/constants';

const StyledRow = styled(Row)`
  margin: 15px 0;
`;
const StyledIdRow = styled(Row)`
  margin: 9px 0;
`;
const StyledNameRow = styled(Row)`
  margin: 20px 0;
`;

const StyledTitle = styled.span`
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-18);
  line-height: 30px;
  color: var(--black);
`;

const StyledSpan = styled.span`
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
`;

interface LoginDetailsProps {
  isEditMode: boolean;
  userPermissions: any;
}

const LoginDetails = ({
  isEditMode = false,
  userPermissions = {},
}: LoginDetailsProps) => {
  return (
    <>
      <StyledRow>
        <Col span={24}>
          <StyledTitle>
            {USER_MANAGER.TITLES.LOGIN_DETAILS} &apos;
            {!isEmpty(userPermissions)
              ? userPermissions?.userName
              : `#username`}
            &apos;
          </StyledTitle>
        </Col>
      </StyledRow>
      <StyledIdRow>
        <Col span={8}>
          <StyledSpan>{USER_MANAGER.LABELS.EMPLOYEE_ID}</StyledSpan>
          <Input
            disabled
            className="input-control"
            value={userPermissions?.employeeId}
          />
        </Col>
        <Col span={8} offset={1}>
          <StyledSpan>{USER_MANAGER.LABELS.USER_ID}</StyledSpan>
          <Input
            disabled
            className="input-control"
            value={userPermissions?.userId}
          />
        </Col>
      </StyledIdRow>
      <StyledNameRow>
        <Col span={8}>
          <StyledSpan>{USER_MANAGER.LABELS.EMPLOYEE_NAME}</StyledSpan>
          <Input
            disabled
            className="input-control"
            value={userPermissions?.userName}
          />
        </Col>
        <Col span={8} offset={1}>
          <StyledSpan>{USER_MANAGER.LABELS.DISPLAY_NAME}</StyledSpan>
          <Input
            disabled={!isEditMode}
            className="input-control"
            value={userPermissions?.employeeName}
          />
        </Col>
      </StyledNameRow>
    </>
  );
};

export default LoginDetails;
