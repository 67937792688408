import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Spin } from 'antd';
import { Table } from '@devexpress/dx-react-grid-material-ui';

import VirtualGrid from 'components/common/virtualGrid/index';
import ImageLoader from 'components/common/ImageLoader';

import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';
import { actions as pledgeMngmtActions } from 'redux/reducers/pledgeManagement';
import { getPledgeAgreementItems } from 'services/pledge';

const TableRow = ({ row, onClickTableRow, ...restProps }: any) => {
  const onRowClick = () => {
    onClickTableRow(row);
  };

  const Cell = () => <Table.Cell onClick={onRowClick} {...restProps} />;

  return <>{Cell()}</>;
};

const ConfiscationsSummary = ({ onRowClick }: any) => {
  const { confiscationList } = useSelector(
    (state: any) => state.pledgeManagement
  );

  const [columns] = useState([
    {
      name: '',
      title: '',
      getCellValue: (row: any) => (
        <ImageLoader
          customerId={row.customerId}
          imageUrl={`/api/customers/images/${row.customerId}`}
          classes={'mx-small-avatar'}
          fallbackImg={'customer-pic.svg'}
          borderRadius={'50%'}
          customPreview={true}
        />
      ),
    },
    {
      name: 'customerName',
      title: 'Customer Name',
      getCellValue: (row: any) => row?.customerName,
    },
    {
      name: 'serviceType',
      title: 'Service Type',
      getCellValue: (row: any) => row?.serviceType,
    },
    {
      name: 'agreement',
      title: 'Agreement',
      getCellValue: (row: any) => row?.loanNumber,
    },
    {
      name: 'amount',
      title: 'Amount',
      getCellValue: (row: any) => '£' + row?.amount?.toFixed(2),
    },
    {
      name: 'startDate',
      title: 'Start Date',
      getCellValue: (row: any) =>
        row && row?.startDate
          ? format(new Date(row?.startDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'endDate',
      title: 'End Date ',
      getCellValue: (row: any) =>
        row && row?.dueDate
          ? format(new Date(row?.dueDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'confiscated',
      title: 'Confiscated',
      getCellValue: (row: any) =>
        row && row?.confiscatedDate
          ? format(new Date(row?.confiscatedDate), FNS_DATE_FORMAT)
          : null,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: '', width: 50 },
    { columnName: 'customerName', width: 300 },
    { columnName: 'serviceType', width: 150 },
    { columnName: 'agreement', width: 200 },
    { columnName: 'amount', width: 100, align: 'right' },
    { columnName: 'startDate', width: 200 },
    { columnName: 'endDate', width: 200 },
    { columnName: 'confiscated', width: 200 },
  ]);

  const onSelectionChange = () => undefined;

  const onClickTableRow = (row: any) => {
    onRowClick(row);
  };

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        virtualScroll={true}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={confiscationList || []}
        cellComponent={(restProps: any) => (
          <TableRow onClickTableRow={onClickTableRow} {...restProps} />
        )}
        rowSelectionEnable={true}
        showSelectionColumn={false}
        showSelectAll={false}
        selectByRowClick={true}
        onSelectionChange={onSelectionChange}
        expandableComponent={PledgeExpandableTable}
      />
    </>
  );
};

export const PledgeExpandableTable = ({ row }: any) => {
  const { confiscationList } = useSelector(
    (state: any) => state.pledgeManagement
  );

  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!Object.keys(row).includes('items')) getPledgeDetails(row.loanId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getPledgeDetails = async (id: any) => {
    try {
      setLoading(true);

      const response = await getPledgeAgreementItems(id);
      if (response?.data?.items) {
        const summary = JSON.parse(JSON.stringify(confiscationList));
        const index = confiscationList.findIndex(
          (obj: any) => obj.loanId === row.loanId
        );
        summary[index].items = response.data.items;

        dispatch(pledgeMngmtActions.setConfiscationList(summary));
        setLoading(false);
      }
    } catch (e: any) {
      setLoading(false);
    }
  };

  const [columns] = useState([
    {
      name: 'image',
      title: 'Item Image',
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row.id}`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
        />
      ),
    },
    {
      name: 'description',
      title: 'Description',
      getCellValue: (row: any) => row?.description,
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) =>
        (row?.weight > 0 ? row?.weight?.toFixed(2) : '0.00') + 'g',
    },
    {
      name: 'amount',
      title: 'Amount',
      getCellValue: (row: any) => '£' + row?.amount?.toFixed(2),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'image', width: '10%', align: 'center' },
    { columnName: 'description', width: '30%' },
    { columnName: 'weight', width: '20%', align: 'right' },
    { columnName: 'amount', width: '20%', align: 'right' },
  ]);

  return (
    <Spin spinning={loading}>
      <div className="pledge-expandable-table-rows">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={row.items || []}
        />
      </div>
    </Spin>
  );
};

export default ConfiscationsSummary;
