import React, { useState } from 'react';
import VirtualGrid from 'components/common/virtualGrid/index';
import ProgressTick from 'components/common/ProgressCheck';
import TableRow from '../../formatedCell';
import { COLORS_CODE_TABLE, GRID_NO_URL } from 'globalConstants';
import ImageLoader from 'components/common/ImageLoader';
import { currencyFormat } from 'utils/util';
import { RETAIL_ITEM_STATUS } from 'components/retail/constants';

const CheckoutRetail = (props: any) => {
  const rows = props?.item || [];
  const [columns] = useState([
    {
      name: 'serviceRetailTransactionId',
      title: 'Transaction Id',
      getCellValue: (row: any) =>
        ProgressTick(
          row?.retail?.response?.serviceRetailTransactionId,
          row?.serviceStatusText
        ),
    },
    {
      name: 'itemCount',
      title: 'Total Item Count',
      getCellValue: (row: any) => row?.retail?.request?.itemCount,
    },
    {
      name: 'listValue',
      title: 'Total List Value',
      type: 'currency',
      getCellValue: (row: any) => row?.retail?.request?.totalListValue || 0,
    },
    {
      name: 'discount',
      title: 'Total Discount',
      type: 'currency',
      getCellValue: (row: any) => row?.retail?.request?.totalDiscount || 0,
    },
    {
      name: 'balanceDue',
      title: 'Total Balance Due',
      type: 'currency',
      getCellValue: (row: any) => row?.retail?.request?.totalBalanceDue || 0,
    },
    {
      //insert a blank column to create space before To Pay
      name: 'blankColumn',
      title: ' ',
      getCellValue: () => '',
    },
    {
      name: 'toPay',
      title: 'To Pay',
      type: 'currency',
      getCellValue: (row: any) => row?.retail?.request?.totalToPay || 0,
    },
    {
      name: 'toTake',
      title: 'To Take',
      type: 'currency',
      getCellValue: (row: any) => row?.retail?.request?.totalToTake || 0,
    },
    {
      name: '',
      title: '',
      getCellValue: (row: any) => row.print,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'serviceRetailTransactionId', width: '12%' },
    { columnName: 'itemCount', width: '12%' },
    { columnName: 'listValue', width: '13%', align: 'right' },
    { columnName: 'discount', width: '13%', align: 'right' },
    { columnName: 'balanceDue', width: '12%', align: 'right' },
    { columnName: 'blankColumn', width: '8%' },
    { columnName: 'toPay', width: '10%', align: 'right' },
    { columnName: 'toTake', width: '10%', align: 'right' },
    { columnName: '', width: '6%', align: 'right' },
  ]);

  const [formattedColumns] = useState([
    'toPay',
    'toTake',
    'listValue',
    'discount',
    'balanceDue',
  ]);

  return (
    <div key="widgetsRetail">
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        formattedColumns={formattedColumns}
        rowData={rows}
        tableColumnExtensionsProps={tableColumnExtensions}
        headerBackground={COLORS_CODE_TABLE.RETAIL_SHOPPING}
        cellComponent={TableRow}
        expandableComponent={RetailExpandableTable}
        detailRowIconPlacement={true}
      />
    </div>
  );
};

export const RetailExpandableTable = ({ row }: any) => {
  const itemDescription = (row: any) => {
    return {
      title: row.description,
      anchor: row.description,
      placement: 'bottom',
      itemId: row.stockItemId,
      data: {},
    };
  };

  const [columns] = useState([
    {
      name: 'itemImage',
      title: 'Item Image',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row?.stockItemId}?isThumnails=false`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'description',
      title: 'Description',
      type: 'popover',
      getCellValue: (row: any) => itemDescription(row),
    },
    {
      name: 'serviceName',
      title: 'Service',
      getCellValue: (row: any) => {
        switch (row?.status) {
          case RETAIL_ITEM_STATUS.AVAILABLE:
            return 'Sale';
          case RETAIL_ITEM_STATUS.TO_RETURN:
            return 'Return';
        }
      },
    },
    {
      name: 'barcode',
      title: 'Barcode No.',
      getCellValue: (row: any) => row?.barcode,
    },
    {
      name: 'listValue',
      title: 'List Price',
      type: 'currency',
      getCellValue: (row: any) => row?.listValue,
    },
    {
      name: 'discount',
      title: 'Discount',
      type: 'currency',
      getCellValue: (row: any) => row?.discount,
    },
    {
      name: 'balanceDue',
      title: 'Balance Due',
      getCellValue: (row: any) => {
        if (row?.status !== RETAIL_ITEM_STATUS.TO_RETURN) {
          const balanceDue = row?.listValue - row?.discount;
          return <span>{currencyFormat(balanceDue, true)}</span>;
        } else {
          const balanceDue = row?.soldValue;
          return <span>{currencyFormat(balanceDue, true)}</span>;
        }
      },
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'itemImage', width: 130 },
    { columnName: 'description', width: 230 },
    { columnName: 'serviceName', width: 150 },
    { columnName: 'barcode', width: 120 },
    { columnName: 'listValue', width: 120, align: 'right' },
    { columnName: 'discount', width: 120, align: 'right' },
    { columnName: 'balanceDue', width: 120, align: 'right' },
  ]);

  const [formattedColumns] = useState([
    ' ',
    '',
    'description',
    'listValue',
    'discount',
  ]);

  return (
    <div key="widgetRetailExtension">
      <div className="expandable-table-rows">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          formattedColumns={formattedColumns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={row?.retail?.request?.items}
          rowSelectionEnable={true}
          onSelectionChange={() => undefined}
        />
      </div>
    </div>
  );
};

export default CheckoutRetail;
