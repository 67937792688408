import React, { useState } from 'react';
import VirtualGrid from 'components/common/virtualGrid/index';

import { GRID_NO_URL } from 'globalConstants';
import { TILL_LABELS } from '../constants';

interface HistoryDenominationTableProps {
  rows: Array<any>;
  isMessageContentAvailable: boolean;
}

const HistoryDenominationTable = ({
  rows = [],
  isMessageContentAvailable,
}: HistoryDenominationTableProps) => {
  const [columns] = useState([
    {
      name: 'denomination',
      title: 'Denominations',
      getCellValue: (row: any) =>
        row?.currencyCode === TILL_LABELS.DEFAULT_TILL_CURRENCY &&
        row?.denomination < 1 ? (
          <span>{`${row.denomination * 100}p`}</span>
        ) : (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: row?.currencySymbol,
              }}
            ></span>
            <span>{row?.denomination}</span>
          </>
        ),
    },
    {
      name: 'quantity',
      title: 'Quantity',
      getCellValue: (row: any) => row?.quantity || '0',
    },
    {
      name: 'value',
      title: 'Value',
      getCellValue: (row: any) => row?.value.toFixed(2) || '0.00',
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'denomination', width: '50%', align: 'center' },
    { columnName: 'quantity', width: '25%', align: 'center' },
    { columnName: 'value', width: '25%', align: 'center' },
  ]);

  return (
    <span className="denominations-table">
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={rows}
        customNoDataMessage={
          !isMessageContentAvailable && !rows.length
            ? TILL_LABELS.NEO_DENOMINATION_MESSAGE
            : ''
        }
      />
    </span>
  );
};

export default HistoryDenominationTable;
