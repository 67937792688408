import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Modal, Row, Select, Spin } from 'antd';
import { Button } from 'components/common/Button';
import styled from 'styled-components';
import {
  BASE_PLEDGE_CATEGORIES,
  ADD_VALUATION,
  ITEM_ATTRIBUTE_TYPE,
  ITEM_ATTRIBUTE_REQUIRED,
} from '../../constants';
import { UPLOAD_MULTIPLE_IMAGE_REQUEST } from 'action_creators/pledge';
import { cacheStaticDataWithArgs } from 'utils/cacheServices';
import { pledgeActions } from 'redux/reducers/pawnbroking';
import { useSelector, useDispatch } from 'react-redux';
import { reduceWatchHandbagItems, getWatchHandbagFormItem } from 'utils/util';

import MultipleImageUploader from 'components/common/MultipleImageUploader';
import { getItemCategoryAttributes } from 'services/pledge';

const StyledModal = styled(Modal)`
  & .ant-checkbox-wrapper.ant-checkbox-group-item {
    margin: 10px 0px 10px 0px;
    & .ant-checkbox-inner {
      width: 18px;
      height: 18px;
    }
  }
  & .ant-row.ant-form-item.label-control {
    margin-block-end: 18px;
  }
  & .ant-row.ant-form-item.label-control.hands-radio-btn {
    margin-top: 10px;
  }
  & .ant-radio-wrapper .ant-radio-inner {
    width: 18px;
    height: 18px;
    &::after {
      top: 4px;
      left: 4px;
    }
  }
`;

interface BagValuationPopupProps {
  visible: boolean;
  setBagValuePopupVisible: () => void;
}

const BagValuationPopup = ({
  visible,
  setBagValuePopupVisible,
}: BagValuationPopupProps) => {
  const [form] = Form.useForm();
  const [handbagAttributes, setHandbagAttributes] = useState<any>([]);
  const [handbagChecklist, setHandbagChecklist] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const {
    pawnbroking: { handbags, checklists, itemImageCollectionResponse },
    valuationCenter: { isValuationItemToPledge },
  } = useSelector((state: any) => {
    return {
      pawnbroking: state.pawnbroking,
      valuationCenter: state.valuationCenter,
    };
  });

  const uploadImageHandler = (image: any) => {
    dispatch({
      type: UPLOAD_MULTIPLE_IMAGE_REQUEST,
      payload: { image, isDefault: false },
    });
  };

  const updateImageList = (imageList: any) => {
    const params = {
      ...itemImageCollectionResponse,
      images: imageList,
    };
    dispatch(pledgeActions.setItemImageCollectionResponse(params));
  };

  const getFields = () => {
    const count = handbagAttributes;
    const children = [];

    for (let i = 0; i < count.length; i++) {
      const { name, options, attributeId, kind, required } = count[i];
      children.push(
        <Col span={24} key={i}>
          <Form.Item
            name={attributeId}
            label={`${name}${
              required === ITEM_ATTRIBUTE_REQUIRED.YES ? '*' : ''
            }`}
            className="label-control"
            rules={[
              {
                required:
                  required === ITEM_ATTRIBUTE_REQUIRED.YES ? true : false,
                message: `${name} is required`,
              },
            ]}
          >
            {kind === ITEM_ATTRIBUTE_TYPE.DROPDOWN ? (
              <Select className="input-control" placeholder="Select">
                {options.map((item: any, index: number) => (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <Input className="input-control" autoComplete="off" />
            )}
          </Form.Item>
        </Col>
      );
    }
    return children;
  };

  //...Commented as per ticket no. MP-5968 to be used in future for valuations

  // const getCheckList = () => {
  //   return handbagChecklist.map((option: any, index: any) => (
  //     <Col span={24} key={index}>
  //       <Row className="checklist-row">
  //         <Col span={24}>
  //           <Checkbox
  //             value={option?.attributeId}
  //             checked={option?.checked}
  //             onChange={onChecklistChange}
  //           >
  //             {option?.name}
  //           </Checkbox>
  //         </Col>
  //       </Row>
  //     </Col>
  //   ));
  // };

  // const onChecklistChange = (event: any) => {
  //   const { value, checked } = event.target;

  //   let checklistCopy = cloneDeep(handbagChecklist);
  //   const index = handbagChecklist.findIndex(
  //     (option: any) => option.attributeId === value
  //   );

  //   checklistCopy[index]['checked'] = checked;
  //   setHandbagChecklist(checklistCopy);
  // };

  const onCancelClick = () => {
    setBagValuePopupVisible();
  };

  const onHandbagPopupSubmit = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const bagItems = reduceWatchHandbagItems(values);

      const params = { handBags: bagItems, checklists: handbagChecklist };

      dispatch(pledgeActions.updateHandbags(params));
      setBagValuePopupVisible();
    } catch (errorInfo) {/* continue regardless of error */}
  };

  const getHandbagAttributes = async () => {
    try {
      setLoading(true);
      const response = await cacheStaticDataWithArgs(
        'HANDBAG-ATTRIBUTE',
        getItemCategoryAttributes,
        BASE_PLEDGE_CATEGORIES[3].id
      );
      if (response?.data) {
        setLoading(false);
        setHandbagAttributes(response?.data?.itemCategoryAttributes);
      }
    } catch (e: any) {
      setLoading(false);
    }
  };

  //...Commented as per ticket no. MP-5968 to be used in future for valuations

  // const getHandbagChecklist = async () => {
  //   try {
  //      const response = await cacheStaticDataWithArgs(
  //        'HANDBAG-CHECKLIST',
  //        getItemCategoryChecklist,
  //        BASE_PLEDGE_CATEGORIES[3].id
  //      );
  //     if (responsne?.data) setHandbagChecklist(response?.data?.checklists);
  //   } catch (e: any) {
  //     console.log(e);
  //   }
  // };

  useEffect(() => {
    getHandbagAttributes();
    // if (!checklists.length) getHandbagChecklist(); //...Commented as per ticket no. MP-5968 to be used in future for valuations
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (handbags && handbags?.length > 0) {
      const result = getWatchHandbagFormItem(handbags);
      form.setFieldsValue({ ...result });

      setHandbagChecklist(checklists);
    }
  }, [handbags]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StyledModal
        title="Add Bag Details"
        centered
        open={visible}
        width={1500}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={onCancelClick}
        footer={
          <Row>
            <Col span="6" className="mandatory-label">
              (*) Mandatory
            </Col>
            <Col span="18">
              <Button itemProp="secondary" key="back" onClick={onCancelClick}>
                Cancel
              </Button>
              <Button
                key="submit"
                form="form"
                htmlType="submit"
                onClick={onHandbagPopupSubmit}
              >
                {handbags?.length > 0
                  ? ADD_VALUATION.UPDATE
                  : ADD_VALUATION.ADD}
              </Button>
            </Col>
          </Row>
        }
      >
        {loading ? (
          <Spin />
        ) : (
          <Form
            layout="vertical"
            form={form}
            disabled={isValuationItemToPledge}
          >
            <Row>
              <Col span={4}>
                <Row gutter={24} align="top">
                  {getFields()}
                </Row>
              </Col>
              <Col span={8} offset={1} className="checklist-wrapper">
                {/* <Row>
                //...Commented as per ticket no. MP-5968 to be used in future for valuations
                  <p className="title">Tick to answer "YES"</p>
                </Row>
                <Row gutter={24} align="top">
                  {getCheckList()}
                </Row> */}
              </Col>
              <Col span={10} offset={1}>
                <MultipleImageUploader
                  imageList={itemImageCollectionResponse?.images}
                  uploadImageRequest={uploadImageHandler}
                  updateImageList={updateImageList}
                  viewOnly={isValuationItemToPledge}
                />
              </Col>
            </Row>
          </Form>
        )}
      </StyledModal>
    </>
  );
};

export default BagValuationPopup;
