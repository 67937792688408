import React, { useState } from 'react';
import VirtualGrid from 'components/common/virtualGrid/index';
import { format } from 'date-fns';
import { GRID_NO_URL, FNS_DATE_FORMAT_WITH_CAP_TIME } from 'globalConstants';
import { currencyFormat } from 'utils/util';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/reducers/admin';

const StyledText = styled.span<{ color: string }>`
  color: var(--${(props) => props.color});
`;

interface VoidDetailsProps {
  rows: Array<object>;
}

const VoidTransactionsTable = ({ rows }: VoidDetailsProps) => {
  const dispatch = useDispatch();
  const {
    admin: { basketIdList, selectedCheckboxCount, allServicesList },
  } = useSelector((state: any) => {
    return {
      admin: state.admin,
    };
  });

  const [columns] = useState([
    {
      name: 'user',
      title: 'User',
      getCellValue: (row: any) => row?.user,
    },
    {
      name: 'customer',
      title: 'Customer',
      type: 'customerType',
      getCellValue: (row: any) => row?.customerName,
    },
    {
      name: 'service',
      title: 'Service',
      getCellValue: (row: any) => row?.services,
    },
    {
      name: 'dateTime',
      title: 'Date & Time',
      getCellValue: (row: any) =>
        row &&
        row?.createdOn &&
        format(new Date(row?.createdOn), FNS_DATE_FORMAT_WITH_CAP_TIME),
    },
    {
      name: 'amount',
      title: 'Amount',
      getCellValue: (row: any) => {
        if (row?.amount < 0) {
          return (
            <StyledText color="red">
              {currencyFormat(Math.abs(row?.amount), true)}
            </StyledText>
          );
        } else {
          return (
            <StyledText color="green">
              {currencyFormat(row?.amount, true)}
            </StyledText>
          );
        }
      },
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'user', width: '300px', align: 'left' },
    { columnName: 'customer', align: 'left' },
    { columnName: 'service', align: 'left' },
    { columnName: 'dateTime', width: '300px', align: 'left' },
    { columnName: 'amount', width: '100px', align: 'right' },
  ]);

  const [formattedColumns] = useState(['description']);
  const onSelectionChange = (basketIds: any) => {
    const selectedEmpId = rows.find(
      (rw: any) => rw?.basketId === basketIds[basketIds.length - 1]
    );
    dispatch(
      actions.setBasketIdList(
        basketIds?.length > 0 ? [basketIds[basketIds.length - 1]] : []
      )
    );
    dispatch(actions.setSelectedEmpId((selectedEmpId as any)?.employeeId));
    dispatch(actions.setIsSignalRResponse(false));
    dispatch(actions.setServiceIdList([]));
    dispatch(
      actions.setAllServicesList(
        allServicesList?.length
          ? []
          : (selectedEmpId as any)?.basketServices?.map(
              (basket: any) => basket?.serviceId
            )
      )
    );
    dispatch(actions.setSelectedCheckboxCount(selectedCheckboxCount + 1));
    dispatch(
      actions.setBasketIdList(
        basketIds?.length > 0 ? [basketIds[basketIds.length - 1]] : []
      )
    );
  };
  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        formattedColumns={formattedColumns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={rows}
        rowSelectionEnable={true}
        selection={basketIdList}
        onSelectionChange={onSelectionChange}
        virtualScroll={true}
        selectByRowClick={false}
        highlightRow={true}
        showSelectionColumn={true}
        expandableComponent={VoidTransactionsExpandableTable}
      />
    </>
  );
};

export const VoidTransactionsExpandableTable = ({ row }: any) => {
  const {
    admin: { selectedCheckboxCount, allServicesList, serviceIdList },
    tm: { currencies },
  } = useSelector((state: any) => {
    return {
      tm: state.tm,
      admin: state.admin,
    };
  });
  const dispatch = useDispatch();

  const [columns] = useState([
    {
      name: 'service',
      title: 'Service',
      getCellValue: (row: any) => row.serviceActionTypeText,
    },
    {
      name: 'reference',
      title: 'Reference',
      getCellValue: (row: any) => row.reference,
    },
    {
      name: 'paytocustomer',
      title: 'Pay to Customer',
      getCellValue: (row: any) => {
        const toCurrencyCode = currencies?.find(
          (cur: any) => cur?.currencyCode === row?.toCurrencyCode
        );
        if (row?.totalToPay) {
          return (
            <StyledText color="black">
              <span
                dangerouslySetInnerHTML={{
                  __html: toCurrencyCode?.currencySymbol,
                }}
              ></span>
              {currencyFormat(Math.abs(row?.totalToPay))}
            </StyledText>
          );
        } else if (row?.serviceAmount < 0) {
          return (
            <StyledText color="black">
              {currencyFormat(Math.abs(row?.serviceAmount), true)}
            </StyledText>
          );
        } else {
          return <StyledText color="black">{`£0.00`}</StyledText>;
        }
      },
    },
    {
      name: 'takefromcustomer',
      title: 'Take From Customer',
      getCellValue: (row: any) => {
        const fromCurrencyCode = currencies?.find(
          (cur: any) => cur?.currencyCode === row?.fromCurrencyCode
        );
        if (row?.totalToTake) {
          return (
            <StyledText color="black">
              <span
                dangerouslySetInnerHTML={{
                  __html: fromCurrencyCode?.currencySymbol,
                }}
              ></span>
              {currencyFormat(Math.abs(row?.totalToTake))}
            </StyledText>
          );
        } else if (row?.serviceAmount > 0) {
          return (
            <StyledText color="black">
              {currencyFormat(Math.abs(row?.serviceAmount), true)}
            </StyledText>
          );
        } else {
          return <StyledText color="black">{`£0.00`}</StyledText>;
        }
      },
    },
  ]);

  const [formattedColumns] = useState(['description']);
  const [tableColumnExtensions] = useState([
    { columnName: 'service', width: 200 },
    { columnName: 'reference', width: 200 },
    { columnName: 'paytocustomer', width: 200 },
    { columnName: 'takefromcustomer', width: 200 },
  ]);

  const onServiceSelectionChange = (serviceId: any) => {
    dispatch(actions.setBasketIdList([row?.basketId]));
    dispatch(actions.setServiceIdList(serviceId));
    dispatch(actions.setSelectedEmpId(row?.employeeId));
    dispatch(actions.setAllServicesList([]));
    dispatch(actions.setSelectedCheckboxCount(selectedCheckboxCount + 1));
  };

  return (
    <>
      <div className="retail-expandable-table-rows">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          formattedColumns={formattedColumns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={row.basketServices}
          rowSelectionEnable={true}
          showSelectionColumn={true}
          onSelectionChange={onServiceSelectionChange}
          selectByRowClick={true}
          highlightRow={true}
          selection={
            allServicesList?.length > 0 ? allServicesList : serviceIdList
          }
        />
      </div>
    </>
  );
};
export default VoidTransactionsTable;
