import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { Col, Form, Input, Row, Select, notification } from 'antd';

import { format } from 'date-fns';

import DatePickerMUI from 'components/common/DatePickerMUI';

import { Button } from 'components/common/Button';

import { FNS_DATE_FORMAT } from 'globalConstants';
import {
  BUTTON_LABELS,
  NOTIFICATIONS_PAYMENTS_STATUS,
  STATUS_LABELS,
} from 'components/notifications/constants';

import { postPaymentsNotificationsList } from 'services/customer';

import { actions as userActions } from 'redux/reducers/user';

const StyledCol = styled(Col)`
  padding: 0 8px;
`;

const StyledButtonRow = styled(Row)`
  height: 100%;
  padding-bottom: 6px;
  align-items: flex-end;
`;

const BankTransferFilters = () => {
  const { reloadBankTransfers } = useSelector((state: any) => state.user);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const searchPayload = {
    customerName: '',
    referenceNumber: '',
    status: '',
    fromDate: '',
    toDate: '',
  };

  useEffect(() => {
    getAllBankTransfers(searchPayload);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reloadBankTransfers) {
      onResetBtnClick();
      dispatch(userActions.initReloadBankTransfers(false));
    }
  }, [reloadBankTransfers]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllBankTransfers = useCallback(
    async (payload: any) => {
      try {
        dispatch(userActions.setLoading(true));
        const response = await postPaymentsNotificationsList(payload);
        if (response?.data) {
          const finalList = response?.data?.notifications?.map(
            (item: any, index: number) => {
              return { ...item, key: index };
            }
          );

          dispatch(userActions.setBankTransfers(finalList));
          dispatch(userActions.setLoading(false));
        }
      } catch (e: any) {
        dispatch(userActions.setLoading(false));
        notification.error({
          message: e?.response?.data?.error || e?.message,
          duration: 5,
        });
      }
    },
    [dispatch]
  );

  const onSearchClick = () => {
    const {
      customerName = '',
      referenceNumber = '',
      fromDate,
      toDate,
      status,
    } = form.getFieldsValue();

    const payload = {
      ...searchPayload,
      customerName,
      referenceNumber,
      status: status,
      fromDate: fromDate ? format(new Date(fromDate), FNS_DATE_FORMAT) : '',
      toDate: toDate ? format(new Date(toDate), FNS_DATE_FORMAT) : '',
    };

    getAllBankTransfers(payload);
  };

  const onSearchByReference = () => {
    const { referenceNumber } = form.getFieldsValue();
    if (!referenceNumber) return;

    const payload = {
      ...searchPayload,
      referenceNumber,
    };

    getAllBankTransfers(payload);
  };

  const onResetBtnClick = () => {
    form.resetFields();
    getAllBankTransfers(searchPayload);
  };

  return (
    <Form form={form}>
      <Row>
        <StyledCol span={6}>
          <Row>
            <Col span={6} className="heading">
              Filter By
            </Col>
            <Col span={18}>
              <Form.Item label="Status" name="status" className="label-control">
                <Select
                  className="input-control ant-input"
                  placeholder={STATUS_LABELS.ALL_STATUS}
                  onChange={(val: any) => {
                    form.setFieldsValue({ statusType: val });
                  }}
                  options={NOTIFICATIONS_PAYMENTS_STATUS}
                />
              </Form.Item>
            </Col>
          </Row>
        </StyledCol>
        <StyledCol span={6}>
          <Row>
            <Col span={6} className="heading">
              Search By
            </Col>
            <Col span={18}>
              <Form.Item
                label="Reference"
                name="referenceNumber"
                className="label-control"
              >
                <Input.Search
                  placeholder="Enter Reference No."
                  className="input-control"
                  onSearch={onSearchByReference}
                />
              </Form.Item>
            </Col>
          </Row>
        </StyledCol>
        <StyledCol span={6}>
          <Row className="date-wrapper">
            <Col span={12}>
              <Form.Item
                name="fromDate"
                label="From Date"
                className="label-control"
              >
                <DatePickerMUI />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="toDate"
                label="To Date"
                className="label-control"
              >
                <DatePickerMUI />
              </Form.Item>
            </Col>
          </Row>
        </StyledCol>
        <StyledCol span={3}>
          <StyledButtonRow justify="space-between">
            <Button itemProp="secondary" onClick={onSearchClick}>
              {BUTTON_LABELS.SEARCH}
            </Button>
            <Button itemProp="secondary" onClick={onResetBtnClick}>
              {BUTTON_LABELS.RESET}
            </Button>
          </StyledButtonRow>
        </StyledCol>
      </Row>
    </Form>
  );
};

export default BankTransferFilters;
