import React, { useEffect, useState } from 'react';
import { Col, Modal, Row, Switch, notification } from 'antd';
import {
  BUTTONS_LABEL,
  HEADINGS,
  SST,
} from 'components/consumerDuty/constants';
import { Button } from 'components/common/Button';
import styled from 'styled-components';
import TextArea from 'components/common/TextArea';
import InputDecimal from 'components/common/InputDecimal';
import DatePickerMUI from 'components/common/DatePickerMUI';
import { useDispatch } from 'react-redux';
import { actions as consumerDutyActions } from 'redux/reducers/consumerDuty';
import { isEmpty } from 'lodash';
import ControlledAlert from 'components/common/ControlledAlert';
import { PROFANITY_CHECK } from 'globalConstants';

const StyledTitle = styled.span`
  font-weight: var(--font-weight-600);
`;

const StyledDiv = styled.div`
  background-color: var(--catskill-white);
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface NewPledgePermissionPopupProps {
  open: boolean;
  setNewPledgePermissionPopupVisible: () => void;
}
interface ProfanityError {
  profanity: boolean;
  badWords: Array<string>;
}

const NewPledgePermissionPopup = ({
  open,
  setNewPledgePermissionPopupVisible,
}: NewPledgePermissionPopupProps) => {
  const [isNewPledge, setNewPledge] = useState<boolean>(false);
  const [isNewPledgeWithLimitation, setNewPledgeWithLimitation] =
    useState<boolean>(false);
  const [minimumAmount, setMinimumAmount] = useState<string>('5.00');
  const [maximumAmount, setMaximumAmount] = useState<number | string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [newPledgeNote, setNewPledgeNote] = useState<string>('');

  const [profanityError, setProfanityError] = useState<ProfanityError>({
    profanity: false,
    badWords: [''],
  });
  const [isProfanity, setProfanity] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isNewPledge) setNewPledgeWithLimitation(false);
  }, [isNewPledge]);

  useEffect(() => {
    if (!isNewPledgeWithLimitation) {
      resetLimitationFields();
    } else setNewPledge(false);
  }, [isNewPledgeWithLimitation]);

  const resetLimitationFields = () => {
    setMaximumAmount('');
    setStartDate('');
    setEndDate('');
  };

  const onCancelClick = () => {
    setNewPledgePermissionPopupVisible();
  };

  const handleProfanity = (profanity: boolean, badWords: Array<string>) => {
    setProfanityError({ profanity, badWords });
  };

  const onConfirmClick = () => {
    if (profanityError.profanity) {
      setProfanity(true);
    } else {
      const valid = validationsCheck();
      if (valid) {
        const payload = {
          newPledge: isNewPledge || isNewPledgeWithLimitation,
          minimumAmount: isNewPledgeWithLimitation ? minimumAmount : '0',
          maximumAmount: maximumAmount ? maximumAmount : '0',
          startDate: startDate ? startDate : null,
          endDate: endDate ? endDate : null,
          newPledgeNote,
        };

        dispatch(consumerDutyActions.setNewPledgePermissions(payload));
        setNewPledgePermissionPopupVisible();
      }
    }
  };

  const validationsCheck = () => {
    if (isNewPledgeWithLimitation) {
      if (isEmpty(maximumAmount)) {
        notification.error({
          message: SST.PLEDGE_PERMISSION.MANDATORY_AMOUNT,
          duration: 3,
        });
        return false;
      }
      if (Number(minimumAmount) > Number(maximumAmount)) {
        notification.error({
          message: SST.PLEDGE_PERMISSION.AMOUNT,
          duration: 3,
        });
        return false;
      }
      if (isEmpty(startDate) || isEmpty(endDate)) {
        notification.error({
          message: SST.PLEDGE_PERMISSION.MANDATORY_DATE,
          duration: 3,
        });
        return false;
      }
      if (Number(minimumAmount) > Number(maximumAmount)) {
        notification.error({
          message: SST.PLEDGE_PERMISSION.AMOUNT,
          duration: 3,
        });
        return false;
      }
      if (new Date(startDate) > new Date(endDate)) {
        notification.error({
          message: SST.PLEDGE_PERMISSION.DATE,
          duration: 3,
        });
        return false;
      }
    }
    if (isEmpty(newPledgeNote)) {
      notification.error({
        message: SST.PLEDGE_PERMISSION.NOTES,
        duration: 3,
      });
      return false;
    }
    return true;
  };

  const TableRow = (
    label: string,
    value: boolean,
    onSwitchChange: () => void
  ) => {
    return (
      <tr>
        <td>{label}</td>
        <td>
          <span
            style={{
              padding: '10px 13px 10px 0px',
              color: value ? 'var(--black)' : 'var(--gray)',
            }}
          >
            Enable
          </span>
          <Switch checked={!value} onChange={onSwitchChange} />
          <span
            style={{
              padding: '10px 0px 10px 13px',
              color: !value ? 'var(--black)' : 'var(--gray)',
            }}
          >
            Disable
          </span>
        </td>
      </tr>
    );
  };

  const AmountRow = (label: string) => {
    return (
      <tr>
        <td>{label}</td>
        <td className="zero-padding">
          <Row>
            <Col span={3}>
              <StyledDiv>{`from £`}</StyledDiv>
            </Col>
            <Col span={9}>
              <InputDecimal
                placeholder="0.00"
                addonBefore={null}
                className="new-pledge-permission-input amount-from"
                value={minimumAmount}
                onChange={(e: any) => setMinimumAmount(e?.target?.value)}
                disabled
              />
            </Col>
            <Col span={3}>
              <StyledDiv>{`to £`}</StyledDiv>
            </Col>
            <Col span={9}>
              <InputDecimal
                placeholder="0.00"
                addonBefore={null}
                className="new-pledge-permission-input"
                value={maximumAmount}
                onChange={(e: any) => setMaximumAmount(e?.target?.value)}
              />
            </Col>
          </Row>
        </td>
      </tr>
    );
  };

  const TimeRow = (label: string) => {
    return (
      <tr>
        <td>{label}</td>
        <td className="zero-padding">
          <Row>
            <Col span={3}>
              <StyledDiv>from</StyledDiv>
            </Col>
            <Col span={9}>
              <DatePickerMUI
                disablePast
                disableFutureDates={false}
                bordered={false}
                value={startDate}
                onChange={(value: any) => setStartDate(value)}
              />
            </Col>
            <Col span={3}>
              <StyledDiv>to</StyledDiv>
            </Col>
            <Col span={9}>
              <DatePickerMUI
                disablePast
                disableFutureDates={false}
                bordered={false}
                value={endDate}
                onChange={(value: any) => setEndDate(value)}
              />
            </Col>
          </Row>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Modal
        title={BUTTONS_LABEL.NEW_PLEDGE_PERMISSION}
        open={open}
        width={750}
        centered
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        footer={[
          <Row gutter={24} justify="space-between" key="permissionService">
            <Col>
              <Button
                itemProp="secondary"
                key="permissionCancel"
                onClick={onCancelClick}
              >
                {BUTTONS_LABEL.CANCEL}
              </Button>
            </Col>
            <Col>
              <Button key="permissionConfirm" onClick={onConfirmClick}>
                {BUTTONS_LABEL.CONFIRM}
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Row>
          <Col span={24}>
            <table className="service-details-table">
              <tbody>
                {TableRow(HEADINGS.NEW_PLEDGE, isNewPledge, () =>
                  setNewPledge(!isNewPledge)
                )}
                {TableRow(
                  HEADINGS.NEW_PLEDGE_LIMITATION,
                  isNewPledgeWithLimitation,
                  () => setNewPledgeWithLimitation(!isNewPledgeWithLimitation)
                )}
                {isNewPledgeWithLimitation ? AmountRow(HEADINGS.AMOUNT) : <></>}
                {isNewPledgeWithLimitation ? TimeRow(HEADINGS.TIME) : <></>}
              </tbody>
            </table>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <StyledTitle>Notes</StyledTitle>
          </Col>
          <Col span={24}>
            <TextArea
              rows={6}
              maxLength={600}
              value={newPledgeNote}
              onChange={(e: any) => setNewPledgeNote(e?.target?.value)}
              profanityExists={handleProfanity}
              autoComplete="on"
              spellCheck
              profanityCheck
            />
          </Col>
        </Row>
        {isProfanity && (
          <ControlledAlert
            id="caseUpdate"
            width={600}
            visible={isProfanity}
            message={PROFANITY_CHECK.REMOVE_PROFANITY}
            secondaryMessage={
              <>
                {PROFANITY_CHECK.PROFANITY_WORDS}
                <b>&nbsp;{profanityError.badWords.join(', ')}</b>
              </>
            }
            onClick={() => {
              setProfanity(false);
            }}
            yesButtonText={BUTTONS_LABEL.REMOVE_PROFANITY}
            isCancelBtn={false}
          />
        )}
      </Modal>
    </>
  );
};

export default NewPledgePermissionPopup;
