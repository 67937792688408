export const VALUATION_HEADERS = {
  VALUATION_REF: 'Valuation Reference:',
  ITEM_DESC: 'Item Description:',
  ITEM_DETAILS: 'Item Details',
  ITEM_IMAGES: 'Item Images',
  SUBMITTED_BY: 'Submitted by',
  CUSTOMER_DETAILS: 'Customer Details',
  CHECKLIST: 'Store Checklist',
  ITEM_HISTORY: 'Item History',
  VAL_REQUEST: 'Valuation Request',
  NOTES: 'Notes',
  STONES: 'Stone Details',
  COINS: 'Coin Details',
  NOTIFICATION_HEADING: 'Notifications',
};

export const LABELS = {
  DESIRED_AMOUNT: 'Desired Amount',
  PREVIOUS_OFFER: 'Previous Offer',
  VALUATION: 'Valuation',
  NOTES: 'Valuation Notes',
  SEARCH_BY: 'Search by',
  FILTER_BY: 'Filter by',
};

export const BUTTON_LABELS = {
  BACK: 'Back to Listing',
  REJECT: 'Reject',
  EDIT: 'Requires Edit',
  OFFER: 'Offer',
  VIEW: 'View',
  IN_USE: 'In Use',
  SEARCH: 'Search',
  RESET: 'Reset',
};

export const WORKQUEUE_TABS = [
  { key: 0, title: 'Live', requestId: 1 },
  { key: 1, title: 'Closed', requestId: 2 },
];

export const VALUATION_VIEW_TYPE = {
  VIEW_ITEM: 1,
  VIEW_LISTING: 2,
  VIEW_ITEM_IN_PLEDGE: 3,
};

export const VALUATION_ACTIONS = {
  NEW_REQUEST: 1,
  OFFERED: 2,
  EDIT_REQUESTED: 3,
  EDITED: 4,
  RESUBMITTED: 5,
  ACCEPTED: 6,
  REJECTED: 7,
  EXPIRED: 8,
  DECLINED: 9,
};
export const VALUATION_STATUS = {
  OFFERED: 'Offered',
};
export const VALUATION_SERVICE = {
  PLEDGE: 'Pledge',
  PURCHASE: 'Purchase',
};

export const VALUATION_ERROR = {
  NOTES: { VALUE: 1, ERROR: 'Please an add valuation note.' },
  AMOUNT: { VALUE: 2, ERROR: 'Please an add valuation amount.' },
};

export const ERROR_MESSAGE = {
  NO_DETAILS: 'No details found for selected Valuation',
};
