import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GRID_NO_URL, ROUTE_CONFIG } from 'globalConstants';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import VirtualGrid from 'components/common/virtualGrid/index';
import { isNull } from 'lodash';
import { actions } from 'redux/reducers/basket';
import { useNavigate } from 'react-router-dom';
import { actions as chequeCashingActions } from 'redux/reducers/chequeCashing';
import { EDIT_CHEQUE_CASHING_ITEM } from 'action_creators/chequeCashing';

const TableRow = ({ row, serviceId, onClickEditRow, ...restProps }: any) => {
  const onClickTableRow = () => {
    /* do nothing */
  };

  const onEditClick = () => {
    onClickEditRow(row);
  };

  const Cell = () => {
    const { column } = restProps;
    if (column.name === 'edit') {
      if (row?.thirdPartyCheque?.request?.item?.partPaymentStatus) {
        return <Table.Cell {...restProps}>{''}</Table.Cell>;
      }
      return (
        <Table.Cell
          onClick={onEditClick}
          {...restProps}
          style={{
            cursor: 'pointer',
          }}
        />
      );
    }
    return (
      <Table.Cell
        onClick={onClickTableRow}
        {...restProps}
        style={{
          cursor: 'pointer',
        }}
      />
    );
  };

  return <>{Cell()}</>;
};

const BasketChequeCashingTable = (props: any) => {
  const rows = props?.items || [];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isPartPayment } = useSelector((state: any) => state.basket);
  const [selection, setSelection] = useState<Array<any>>([]);

  useEffect(() => {
    if (selection.length > 0) {
      const payload = {
        serviceIds: selection,
      };
      //update redux merge payload when row selected
      dispatch(actions.move(payload));
    }
  }, [selection]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectionChange = (row: any) => {
    setSelection(row);
  };

  const [columns] = useState([
    {
      name: 'chequeType',
      title: 'Cheque Type',
      getCellValue: (row: any) =>
        row?.thirdPartyCheque?.request?.item?.chequeTypeName,
    },
    {
      name: 'issuer',
      title: 'Issuer',
      getCellValue: (row: any) =>
        isNull(row?.thirdPartyCheque?.request?.item?.issuerName)
          ? 'NA'
          : row?.thirdPartyCheque?.request?.item?.issuerName,
    },
    {
      name: 'authorisedStatus',
      title: 'Authorised Status',
      getCellValue: (row: any) =>
        row?.thirdPartyCheque?.request?.item?.preAuthorization !== 0
          ? 'Pre Auth Failed'
          : 'Pre Auth Passed',
    },
    {
      name: 'chequeAmount',
      title: 'Cheque Amount',
      type: 'currency',
      getCellValue: (row: any) => row?.thirdPartyCheque?.request?.item?.amount,
    },

    {
      name: 'totalFee',
      title: 'Total Fee',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.thirdPartyCheque?.request?.item?.totalFee,
    },
    {
      name: 'retained',
      title: 'Retained',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.thirdPartyCheque?.request?.item?.retained,
    },
    {
      name: 'payout',
      title: 'Payout',
      type: 'currency',
      getCellValue: (row: any) => row?.thirdPartyCheque?.request?.item?.payout,
    },
    {
      name: 'edit',
      title: ' ',
      type: `${isPartPayment ? '' : 'edit'}`,
      onClick: () => undefined,
    },
  ]);
  const [tableColumnExtensions] = useState([
    { columnName: 'chequeType' },
    { columnName: 'issuer' },
    { columnName: 'authorisedStatus' },
    { columnName: 'chequeAmount', align: 'right' },
    { columnName: 'totalFee', align: 'right' },
    { columnName: 'retained', align: 'right' },
    { columnName: 'payout', align: 'right' },
    { columnName: 'edit', align: 'right' },
  ]);

  const formattedColumns = [
    'edit',
    'payout',
    'retained',
    'totalFee',
    'chequeAmount',
  ];

  const onClickEditRow = (row: any) => {
    if (isPartPayment) return;
    navigate(`/${ROUTE_CONFIG.CHEQUE_CASHING}`);

    dispatch({
      type: EDIT_CHEQUE_CASHING_ITEM,
      payload: { params: row?.thirdPartyCheque?.request?.item },
    });
    dispatch(chequeCashingActions.setEditRowServiceId(row?.serviceId));

    dispatch({
      type: EDIT_CHEQUE_CASHING_ITEM,
      payload: { params: row?.thirdPartyCheque?.request?.item },
    });
  };

  return (
    <>
      <div className="basket-item-table">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={rows}
          cellComponent={(restProps: any) => (
            <TableRow {...restProps} onClickEditRow={onClickEditRow} />
          )}
          formattedColumns={formattedColumns}
          selection={selection}
          onSelectionChange={onSelectionChange}
          highlightRow={true}
          rowSelectionEnable={true}
          selectByRowClick={true}
        />
      </div>
    </>
  );
};

export default BasketChequeCashingTable;
