import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';

const TableRow = ({ row, ...restProps }: any) => {
  const Cell = () => {
    const { column, tableRow } = restProps;
    if (
      column.name === 'toTake' ||
      column.name === 'toPay' ||
      column.name === 'fromAmount' ||
      column.name === 'toAmount'
    ) {
      return <Table.Cell {...restProps} className="td-checkout-base" />;
    } else if (column.name === 'checkoutToPay' && tableRow?.row?.toPay > 0) {
      return <Table.Cell {...restProps} className="td-checkout-pay" />;
    } else if (column.name === 'checkoutToTake' && tableRow?.row?.toTake > 0) {
      return <Table.Cell {...restProps} className="td-checkout-take" />;
    }
    return <Table.Cell {...restProps} />;
  };

  return <>{Cell()}</>;
};

export default TableRow;
