export const CURRENCY_STOCK_DETAILS = 'Currency Stock Details';
export const AVAILABLE_IN_STORE = '- available in store';
export const AVAILABLE_IN_TILL = '- in all tills';
export const AVAILABLE_IN_MAIN_SAFE = '- in Main safe';
export const CUSTOMER_BUYING_SELLING_LABEL = 'Customer Buying/Selling';
export const CUSTOMER_SELLING_LABEL = 'Customer Selling';
export const TO_TAKE = 'To Take';
export const TO_PAY = 'To Pay';
export const CLEAR_AMOUNT = 'Clear Amount';
export const AMOUNT_TO_RECIEVE = 'Amount to Recieve';
export const TAKE_FROM_CUSTOMER = 'Take from Customer';
export const PAY_TO_CUSTOMER = 'Pay to Customer';
export const PROCEED_TO_BAYBACK = 'Proceed to Buyback';
export const CANCEL = 'Cancel';
export const BUYBACK_SERVICE_LABEL = 'Buy-back service';
export const NOT_AVAILABLE_LABEL = 'Not available';
export const TITLE_LABEL = 'Travel Money';
export const ADD_LABEL = 'ADD';
export const RATE_GUARANTEE_LABEL = 'Rate Guarantee Receipt Number';

export const GUARANTEE_LINE_ONE =
  'Ask the customer if they want to Purchase FX Buyback Guarantee';
export const GUARANTEE_LINE_TWO = `We'll buy back your unused currency at the same rate you bought it for, so if the exchange rate changes, you won't lose out.`;
