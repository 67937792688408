import React from 'react';
import { Row, Col } from 'antd';
import Table from '../../../common/Table/index';
import {
  PLEDGE_COLUMNS,
  PURCHASE_COLUMNS,
  RETAIL_COLUMNS,
  //LAYAWAY_ACCOUNT_COLUMNS, //TODO enable once service is available
  TRAVEL_MONEY_COLUMNS,
  CHEQUE_CASHING_COLUMNS,
  // CHEQUE_CASHING_COLUMNS,
  //PERSONAL_LOAN_COLUMNS,
} from '../../constants';

import Visit from './visit';

const Overview = ({
  pledgeOverview,
  purchaseOverview,
  travelMoneyOverview,
  retailSaleOverview,
  chequeCashingOverview,
}: any) => {
  const overviewSummary = [
    {
      backgroundColor: 'light-alice-blue',
      key: 'pledge',
      title: () => 'Pledge',
      data: pledgeOverview?.isShowOverview ? [pledgeOverview] : [],
      tableColumns: PLEDGE_COLUMNS,
    },
    {
      backgroundColor: 'alice-blue',
      key: 'purchase',
      title: () => 'Purchase',
      data: purchaseOverview?.isShowOverview ? [purchaseOverview] : [],
      tableColumns: PURCHASE_COLUMNS,
    },
    {
      backgroundColor: 'selago',
      key: 'retailShopping',
      title: () => 'Retail Shopping',
      data: retailSaleOverview?.isShowOverview ? [retailSaleOverview] : [],
      tableColumns: RETAIL_COLUMNS,
    },
    // {
    //   backgroundColor: 'pattense-blue-light',
    //   key: 'layawayAccount',
    //   title: () => 'Layaway Account',
    //   data: mockLayawayAccountSummary,
    //   tableColumns: LAYAWAY_ACCOUNT_COLUMNS,
    // },
    {
      backgroundColor: 'narvik',
      key: 'travelMoney',
      title: () => 'Travel Money',
      data: travelMoneyOverview?.isShowOverview ? [travelMoneyOverview] : [],
      tableColumns: TRAVEL_MONEY_COLUMNS,
    },
    {
      backgroundColor: 'vanilla-ice',
      key: 'chequeCashing',
      title: () => 'Cheque Cashing',
      data: chequeCashingOverview?.isShowOverview
        ? [chequeCashingOverview]
        : [],
      tableColumns: CHEQUE_CASHING_COLUMNS,
    },
    // {
    //   backgroundColor: 'fog',
    //   key: 'personalLoan',
    //   title: () => 'Personal Loan',
    //   data: mockPersonalLoanSummary,
    //   tableColumns: PERSONAL_LOAN_COLUMNS,
    // },
  ];

  return (
    <div key={'visitTable'}>
      <Row>
        <Col span={8}>
          <Visit />
        </Col>
        <Col span={16}>
          {overviewSummary.map((item: any, index) => (
            <React.Fragment key={`overview${index}`}>
              {item?.data?.length > 0 && (
                <Table
                  key={`overviewTable${index}`}
                  tableColumns={item.tableColumns}
                  title={item.title}
                  dataSource={item.data}
                  backgroundColor={item.backgroundColor}
                />
              )}
            </React.Fragment>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default Overview;
