import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { Avatar, Row } from 'antd';

import { getLowercaseWithoutSpace, getPledgeIcons } from 'utils/util';
import { LOAN_STATUS } from 'components/pickupRenew/constants';

interface PledgeIconDescriptionProps {
  pledgeSummary: Array<any>;
}

const StyledIconRow = styled(Row)`
  padding: 20px 0;
`;

const StyledIconWrapper = styled.div`
  padding-right: 15px;

  & .ant-avatar {
    margin: 0 8px;
  }
`;

const IconDescription = ({
  pledgeSummary = [],
}: PledgeIconDescriptionProps) => {
  const { customerStatusColor } = useSelector((state: any) => state.customer);
  const [icons, setIcons] = useState<Array<string>>([]);

  useEffect(() => {
    getPledgeStatuses();
  }, [pledgeSummary]); // eslint-disable-line react-hooks/exhaustive-deps

  const getPledgeStatuses = () => {
    let pledgeStauses: any = [];

    pledgeSummary.forEach((pledge: any) => {
      const status = getPledgeIcons(pledge, customerStatusColor);
      pledgeStauses.push(...status);
    });

    const uniquePledgeStatuses = pledgeStauses.filter(
      (status: string, index: number, arr: any) => {
        if (index === arr.indexOf(status) && status !== null) return true;
        return false;
      }
    );

    setIcons(uniquePledgeStatuses);
  };

  const loadSVG = (icon: any) => {
    try {
      const loadedImg = require(`../../../../assets/images/icons/${
        getLowercaseWithoutSpace(icon) + '.svg'
      }`);

      return loadedImg;
    } catch (e: any) {
      return undefined;
    }
  };

  const renderIcons = () => {
    return icons.map((icon: string, index: number) => {
      const image = loadSVG(icon);
      const iconName =
        icon === LOAN_STATUS.IS_AT_RISK ? LOAN_STATUS.LOCKED : icon;

      return (
        <StyledIconWrapper key={index}>
          <Avatar
            className="mx-smaller-icon find-item-popup-grid"
            src={image}
            key={index}
          />
          {iconName}
        </StyledIconWrapper>
      );
    });
  };

  return <StyledIconRow>{renderIcons()}</StyledIconRow>;
};

export default IconDescription;
