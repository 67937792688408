import React from 'react';
import { Row, Col } from 'antd';
import { camelCase, isNull, isUndefined, startCase } from 'lodash';

const ItemDetails = ({ details }: any) => {
  const { metal, itemAdditionalProperties } = details;

  const getFieldValue = (property: any) => {
    if (isUndefined(property) || isNull(property) || property === 0) return '-';
    return startCase(camelCase(property));
  };

  return (
    <Row>
      <Col span={24}>
        <table className="valuation-tables">
          <tbody>
            <tr>
              <td>Condition</td>
              <td>{getFieldValue(metal?.metalConditionText)}</td>
            </tr>
            <tr>
              <td>Metal Type</td>
              <td>{getFieldValue(metal?.metalTypeText)}</td>
            </tr>
            <tr>
              <td>Fineness</td>
              <td>{getFieldValue(metal?.finenessText)}</td>
            </tr>
            <tr>
              <td>Hallmarked</td>
              <td>{getFieldValue(metal?.isHallmark)}</td>
            </tr>
            <tr>
              <td>Weight (g)</td>
              <td>{getFieldValue(metal?.weightInGrams)}</td>
            </tr>
            {itemAdditionalProperties?.map((row: any, index: any) => (
              <tr key={index}>
                <td>{row?.attributeName}</td>
                <td>{getFieldValue(row?.value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Col>
    </Row>
  );
};

export default ItemDetails;
