import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import WebcamItemPhoto from '../Camcorder/WebcamItemPhoto';
import { SingleImage } from './SingleImage';

import './index.less';

const StyledIcon = styled(PlusCircleFilled)`
  font-size: 34px;
  color: var(--blue);
`;

const StyledSpan = styled.span`
  font-weight: 500;
  color: 'var(--blue)';
`;

interface MultipleImageUploaderProps {
  viewOnly?: boolean;
  columns?: number;
  imageList: Array<any>;
  uploadImageRequest?: any;
  updateImageList?: any;
}

const MultipleImageUploader = ({
  viewOnly = false,
  columns = 3,
  imageList = [],
  uploadImageRequest = () => { /* do nothing */ },
  updateImageList = () => { /* do nothing */ },
}: MultipleImageUploaderProps) => {
  const { isImageUploading } = useSelector((state: any) => state.pawnbroking);

  const [isTakeItemPhotoPopupVisible, setIsTakeItemPhotoPopupVisible] =
    useState<boolean>(false);

  const columnSpan = Math.floor(24 / columns);

  const onImageUploadClick = (image: any) => {
    uploadImageRequest(image);
  };

  const onDeleteIconClick = (index: any) => {
    const imageListCopy = JSON.parse(JSON.stringify(imageList));

    imageListCopy.splice(index, 1);
    imageListCopy.forEach(
      (obj: any, index: number) => (obj.sequenceId = index)
    );

    updateImageList(imageListCopy);
  };

  const onLabelChange = (event: any) => {
    const { id: labelId, value } = event.target;
    const id = labelId.split('_')[0];

    const imageListCopy = JSON.parse(JSON.stringify(imageList));

    imageListCopy[id].label = value;
    updateImageList(imageListCopy);
  };

  const onCheckboxChange = (event: any) => {
    const { id, checked } = event.target;

    const imageListCopy = JSON.parse(JSON.stringify(imageList));
    imageListCopy.forEach(
      (obj: any, index: number) =>
        (obj.isDefault = index === +id ? checked : false)
    );

    updateImageList(imageListCopy);
  };

  return (
    <div className="multiple-image-wrapper">
      <Row>
        {imageList.map((imageDetails: any, index: number) => (
          <SingleImage
            viewOnly={viewOnly}
            columnSpan={columnSpan}
            imageDetails={imageDetails}
            onDeleteIconClick={onDeleteIconClick}
            onLabelChange={onLabelChange}
            onCheckboxChange={onCheckboxChange}
            imageKey={index}
            key={index}
          />
        ))}
        {!viewOnly && imageList.length < 12 && (
          <Col span={columnSpan}>
            <div className="single-image-wrapper">
              <Row>
                <Col span={24}>
                  <div className="item-photo-wrapper">
                    <div
                      className="item-photo-div add-image-div"
                      onClick={() => setIsTakeItemPhotoPopupVisible(true)}
                    >
                      <StyledIcon />
                      <StyledSpan>Take Item Photo</StyledSpan>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        )}
      </Row>

      {isTakeItemPhotoPopupVisible && (
        <WebcamItemPhoto
          isOpen={isTakeItemPhotoPopupVisible}
          loading={isImageUploading}
          onImageUploadClick={onImageUploadClick}
          onCancel={() => setIsTakeItemPhotoPopupVisible(false)}
        />
      )}
    </div>
  );
};

export default MultipleImageUploader;
