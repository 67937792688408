import React, { useEffect, useState } from 'react';
import { Col, Modal, Row, Input, Button as AntdButton } from 'antd';
import CheckCircleFilled from '@ant-design/icons/lib/icons/CheckCircleFilled';
import styled from 'styled-components';
import { isUndefined } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { joinValues, currencyFormat } from 'utils/util';
import { BASKET_SERVICE_TYPE, ROUTE_CONFIG } from 'globalConstants';
import { calculateSurplusData, getPledgeAgreementItems } from 'services/pledge';
import { actions } from 'redux/reducers/customer';
import { CREATE_BASKET_SURPLUS_ITEMS } from 'action_creators/basket';
import { Button } from 'components/common/Button';
import { SURPLUS } from 'components/pickupRenew/constants';
import { getCustomerSurplusData } from 'services/pledge';

const StyledModal = styled(Modal)`
  & .ant-modal-title {
    text-align: center;
  }
`;

const StyledAgreementCol = styled(Col)`
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-16);
  line-height: 22px;
  text-align: center;
  padding: 0 55px;
`;

const StyledButton = styled(AntdButton)`
  border: none;
  color: var(--blue);
  & .disabled {
    color: var(--gray);
    cursor: default;
  }
`;

const StyledTd = styled.td`
  text-align: right;
  border-right: none !important;
  font-weight: var(--font-weight-500) !important;
`;

const SurplusPopup = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState<string>();
  // MP-6705 - Surplus Affi Fee removed
  // const [totalAffidavitAmount, setTotalAffidavitAmount] = useState<number>(0.0);
  const totalAffidavitAmount = 0.0;
  const navigate = useNavigate();

  const {
    basket: { basket },
    customer: {
      isSurplusLoading,
      customer,
      selectedCustomer,
      isSurplusPopupVisible,
      customerSurplusData,
      isStoreEnquiryMode,
    },
    user: { user, selectedStore },
  } = useSelector((state: any) => {
    return {
      basket: state.basket,
      customer: state.customer,
      user: state.user,
    };
  });

  const {
    street,
    town,
    property,
    county,
    postcode,
    houseName,
    area,
    firstNames,
    middleName,
    surname,
  } = customer;

  // MP-6705 - Surplus Affi Fee removed
  // useEffect(() => {
  //   if (customerSurplusData?.surplusItems?.length) {
  //     const calcAffidavitAmount = customerSurplusData?.surplusItems
  //       ?.filter(
  //         (x: any) => !isUndefined(x.isPrintAffidavit) && x.isPrintAffidavit
  //       )
  //       .reduce((a: number, b: any) => a + (+b['affidavitAmount'] || 0), 0);
  //     setTotalAffidavitAmount(calcAffidavitAmount);
  //   }
  // }, [customerSurplusData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isSurplusLoading) {
      getCustomerSurplus();
      dispatch(actions.initSurplusCheck(false));
    }
  }, [isSurplusLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSearchClick = (searchValue: any) => {
    const surplusItems = [...customerSurplusData?.surplusItems];
    const index = customerSurplusData?.surplusItems?.findIndex(
      (x: any) => x.loanNumber === searchValue
    );
    if (index !== -1) {
      surplusItems[index] = { ...surplusItems[index], isPrintAffidavit: false };
      const updatedSurplus = { ...customerSurplusData, surplusItems };
      dispatch(actions.setCustomerSurplusData(updatedSurplus));
      setSearchValue(undefined);
    }
  };

  const onCancelClick = () => {
    dispatch(actions.setCustomerSurplusPopopVisible(false));
  };

  const getCustomerSurplus = async () => {
    try {
      const response = await getCustomerSurplusData(
        selectedCustomer?.customerId
      );
      if (response?.data) {
        if (response?.data?.surplusItems?.length) {
          if (!isStoreEnquiryMode) {
            dispatch(actions.setCustomerSurplusPopopVisible(true));
          }
          dispatch(actions.setCustomerSurplusData(response?.data));
        }
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const onPrintAffidavitClick = (loanNumber: string) => {
    const surplusItems = [...customerSurplusData?.surplusItems];
    const index = customerSurplusData?.surplusItems?.findIndex(
      (x: any) => x.loanNumber === loanNumber
    );
    if (index !== -1) {
      surplusItems[index] = { ...surplusItems[index], isPrintAffidavit: true };
      const updatedSurplus = { ...customerSurplusData, surplusItems };
      dispatch(actions.setCustomerSurplusData(updatedSurplus));
      setSearchValue(undefined);
    }
  };

  const onAddToBasketClick = async () => {
    try {
      const filteredSurplusItems = customerSurplusData?.surplusItems?.filter(
        (x: any) => !isUndefined(x.isPrintAffidavit)
      );
      const response = await calculateSurplusData(filteredSurplusItems);
      if (response?.data) {
        const promises = response?.data?.map(async (item: any) => {
          let updatedItem = item;
          updatedItem = {
            ...item,
            pledgeItems: [],
          };
          try {
            const itemsResponse = await getPledgeAgreementItems(item?.loanId);
            if (itemsResponse?.data) {
              updatedItem = {
                ...updatedItem,
                pledgeItems: itemsResponse?.data?.items || [],
              };
              return updatedItem;
            }
          } catch (e: any) {
            return updatedItem;
          }
        });

        const updatedData = await Promise.all(promises);

        const params = {
          customerId: customer.customerId,
          customerName:
            joinValues([customer.firstNames, customer.surname], true) ||
            undefined,
          customer: {
            street,
            town,
            property,
            county,
            postcode,
            houseName,
            area,
            firstNames,
            middleName,
            surname,
          },
          storeId: selectedStore?.storeId,
          storeAddress: selectedStore?.storeAddress,
          storePostalCode: selectedStore?.postalCode,
          storeTelephone: selectedStore?.telephone,
          userId: user?.userId,
          userName: user?.authorizeUsername,
          employeeId: user?.employeeId,
          serviceType: BASKET_SERVICE_TYPE.SURPLUS,
          items: updatedData,
          basketId: basket?.basketId,
        };
        dispatch({
          type: CREATE_BASKET_SURPLUS_ITEMS,
          payload: { params },
        });
        dispatch(actions.setCustomerSurplusPopopVisible(false));
        navigate(`/${ROUTE_CONFIG.BASKET}`);
      }
    } catch (errorInfo) {
      console.log(errorInfo);
      dispatch(actions.setCustomerSurplusPopopVisible(false));
    }
  };

  return (
    <>
      <StyledModal
        title={`${SURPLUS.TITLE} ${currencyFormat(
          customerSurplusData?.totalSurplusAmount,
          true
        )}`}
        open={isSurplusPopupVisible}
        width={560}
        destroyOnClose={true}
        maskClosable={false}
        closable={false}
        onCancel={onCancelClick}
        footer={[
          <Row gutter={24} justify="space-between" key="SurplusPopup">
            <Col>
              <Button
                itemProp="secondary"
                key="cancelSurplus"
                onClick={onCancelClick}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                key="addSurplus"
                htmlType="submit"
                itemProp="secondary"
                onClick={onAddToBasketClick}
                disabled={
                  !customerSurplusData?.surplusItems?.some(
                    (x: any) => !isUndefined(x.isPrintAffidavit)
                  )
                }
              >
                {SURPLUS.ADD_TO_BASKET}
              </Button>
            </Col>
          </Row>,
        ]}
        centered
      >
        <Row>
          <StyledAgreementCol span={24}>
            {SURPLUS.CUSTOMER_ORIGINAL_AGREEMENT}
          </StyledAgreementCol>
        </Row>
        <Row>
          <Col span={24} className="pickup-renew-container">
            <Input.Group
              className="pickup-renew-input-group"
              style={{ width: '100%', padding: '20px 0' }}
            >
              <Input.Search
                placeholder="Search Agreement"
                value={searchValue}
                onSearch={onSearchClick}
                onChange={(e) => setSearchValue(e.target.value)}
                autoFocus
              />
            </Input.Group>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <table className="pledge-history-table">
              <thead>
                <tr>
                  <th>{SURPLUS.AGREEMENT_NUMBER}</th>
                  <th>{''}</th>
                  <th>{SURPLUS.SURPLUS_PAYMENT}</th>
                </tr>
              </thead>
              <tbody>
                {customerSurplusData ? (
                  customerSurplusData?.surplusItems?.map(
                    (x: any, index: number) => (
                      <tr key={index}>
                        <td>
                          <Row>
                            <Col span={21}>{x.loanNumber}</Col>
                            <Col span={3}>
                              {!isUndefined(x.isPrintAffidavit) &&
                              !x.isPrintAffidavit ? (
                                <CheckCircleFilled
                                  style={{
                                    color: 'var(--green)',
                                    fontSize: '18px',
                                  }}
                                />
                              ) : x.isPrintAffidavit ? (
                                <img
                                  src={
                                    require('../../assets/images/icons/affidavit.svg')
                                      .default
                                  }
                                  alt="affidavit-icon"
                                  style={{ height: '22px', width: '22px' }}
                                />
                              ) : null}
                            </Col>
                          </Row>
                        </td>
                        <td>
                          <StyledButton
                            type="link"
                            onClick={() => onPrintAffidavitClick(x?.loanNumber)}
                            disabled={!isUndefined(x.isPrintAffidavit)}
                          >
                            {SURPLUS.PRINT_AFFIDAVIT}
                          </StyledButton>
                        </td>
                        <td>{`${currencyFormat(x?.surplusAmount, true)}`}</td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: 'center' }}>
                      No Items
                    </td>
                  </tr>
                )}
                <tr>
                  <StyledTd colSpan={2}>{SURPLUS.SURPLUS_PAYMENTS}</StyledTd>
                  <StyledTd>{`${currencyFormat(
                    customerSurplusData?.totalSurplusAmount,
                    true
                  )}`}</StyledTd>
                </tr>
                <tr>
                  <StyledTd colSpan={2}>{SURPLUS.AFFIDAVIT}</StyledTd>
                  <StyledTd>{`-${currencyFormat(
                    totalAffidavitAmount,
                    true
                  )}`}</StyledTd>
                </tr>
                <tr>
                  <StyledTd
                    colSpan={2}
                    style={{ backgroundColor: 'var(--catskill-white)' }}
                  >
                    {SURPLUS.PAY_TO_CUSTOMER}
                  </StyledTd>
                  <StyledTd
                    style={{ backgroundColor: 'var(--catskill-white)' }}
                  >
                    {`${currencyFormat(
                      customerSurplusData?.totalSurplusAmount -
                        totalAffidavitAmount,
                      true
                    )}`}
                  </StyledTd>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </StyledModal>
    </>
  );
};

export default SurplusPopup;
