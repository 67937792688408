import React from 'react';
import { Row, Col } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const StoreChecklist = ({ checklist }: any) => {
  return (
    <Row>
      <Col span={24}>
        <table className="valuation-tables checklist-table">
          <tbody>
            <tr className="header-row">
              <td>Checklist</td>
              <td>Yes</td>
              <td>No</td>
            </tr>
            {checklist.map((el: any, index: number) => (
              <tr key={index}>
                <td>{el?.name}</td>
                <td>{el?.checked && <CheckOutlined />}</td>
                <td>{!el?.checked && <CloseOutlined />} </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Col>
    </Row>
  );
};

export default StoreChecklist;
