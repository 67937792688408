import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import VirtualGrid from 'components/common/virtualGrid/index';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';
import { currencyFormat, getPurchaseStatus } from 'utils/util';
import { actions } from 'redux/reducers/pickupRenew';
import './index.less';
import ImageLoader from 'components/common/ImageLoader';
import { CALCULATE_PURCHASE_RETURN_AMOUNT } from 'action_creators/pickupRenew';

interface PurchaseReturnDetailProps {
  rows: Array<object>;
}

const StyledGreen = styled.span`
  color: var(--mountain-meadow);
  font-weight: var(--font-weight-700);
`;
const StyledRed = styled.span`
  color: var(--red);
  font-weight: var(--font-weight-700);
`;

const PurchaseReturnDetailTable = ({ rows }: PurchaseReturnDetailProps) => {
  const dispatch = useDispatch();
  const {
    pickupRenew: { selectedPurchaseRowId },
  } = useSelector((state: any) => {
    return {
      pickupRenew: state.pickupRenew,
    };
  });

  useEffect(() => {
    const rowIds: any[] = [];
    rows.forEach((x: any) => {
      x.purchaseItems.forEach((y: any) => {
        if (y.selectedForReturn === true) rowIds.push(y.id);
      });
    });
    dispatch(actions.setSelectedPurchaseItemId(rowIds));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onRowSelectionChange = (rowId: any) => {
    dispatch(actions.setSelectedPurchaseRow(rowId));
  };

  const getCustomerDetails = (row: any) => {
    if (row.customerPresent) {
      return <StyledGreen>{row.customerName}</StyledGreen>;
    } else {
      return <StyledRed>{row.customerName}</StyledRed>;
    }
  };

  const [columns] = useState([
    {
      name: 'purchaseNumber',
      title: 'Purchase No.',
      getCellValue: (row: any) => row?.purchaseNumber,
    },
    {
      name: 'customerName',
      title: 'Customer Name',
      getCellValue: (row: any) => getCustomerDetails(row),
    },
    {
      name: 'purchaseStatus',
      title: 'Status',
      type: 'icon',
      getCellValue: (row: any) =>
        getPurchaseStatus(row?.isQuarantine, row?.isReturned),
    },
    {
      name: 'purchaseValue',
      title: 'Purchase Amount',
      getCellValue: (row: any) =>
        '£' + currencyFormat(row?.purchaseValue?.toFixed(2)),
    },
    {
      name: 'oneMonthFee',
      title: '1 Month Fee',
      getCellValue: (row: any) =>
        '£' + currencyFormat(row?.oneMonthFee?.toFixed(2)),
    },
    {
      name: 'purchaseDate',
      title: 'Purchase Date',
      getCellValue: (row: any) =>
        row && row?.purchaseDate
          ? format(new Date(row?.purchaseDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'holdDate',
      title: 'Hold Date',
      getCellValue: (row: any) =>
        row && row?.holdDate
          ? format(new Date(row?.holdDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      //insert a blank column to create space before To Take
      name: 'blankColumn',
      title: ' ',
      getCellValue: () => '',
    },
    {
      name: 'toTakeAmount',
      title: 'To Take',
      type: 'input',
      getCellValue: (row: any) => currencyFormat(row?.toTakeAmount?.toFixed(2)),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'purchaseNumber', width: '10%' },
    { columnName: 'customerName', width: '10%' },
    { columnName: 'purchaseStatus', width: '10%' },
    { columnName: 'purchaseValue', width: '10%' },
    { columnName: 'oneMonthFee', width: '12%' },
    { columnName: 'purchaseDate', width: '12%' },
    { columnName: 'holdDate', width: '12%' },
    { columnName: 'blankColumn', width: '4%' },
    { columnName: 'toTakeAmount', width: '10%' },
  ]);

  const [formattedColumns] = useState(['purchaseStatus', 'toTakeAmount']);

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={rows}
        formattedColumns={formattedColumns}
        expandableComponent={PurchaseExpandableTable}
        rowSelectionEnable={true}
        selection={selectedPurchaseRowId}
        onSelectionChange={onRowSelectionChange}
        highlightRow={true}
      />
    </>
  );
};

export const PurchaseExpandableTable = ({ row }: any) => {
  const dispatch = useDispatch();
  const {
    pickupRenew: { selectedPurchaseItemId },
  } = useSelector((state: any) => {
    return {
      pickupRenew: state.pickupRenew,
    };
  });

  const itemDescription = (row: any) => {
    return {
      title: row.description,
      anchor: row.description,
      placement: 'bottom',
      data: {},
    };
  };

  const onRowSelectionChange = (rowId: any) => {
    dispatch(actions.setSelectedPurchaseItemId(rowId));

    const updatedItems = row.purchaseItems.map((x: any) =>
      rowId.includes(x.id)
        ? { ...x, selectedForReturn: true }
        : { ...x, selectedForReturn: false }
    );
    const updatedRow = { ...row, purchaseItems: [...updatedItems] };
    dispatch({
      type: CALCULATE_PURCHASE_RETURN_AMOUNT,
      payload: { updatedRow },
    });
  };

  const [columns] = useState([
    {
      name: 'itemImage',
      title: 'Item Image',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row.id}`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'barcode',
      title: 'Barcode',
      getCellValue: (row: any) => row?.barcode,
    },
    {
      name: 'description',
      title: 'Description',
      type: 'popover-no-api',
      getCellValue: (row: any) => itemDescription(row),
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) => `${row?.weight?.toFixed(2)}g`,
    },
    {
      name: 'amount',
      title: 'Amount',
      getCellValue: (row: any) => `£${currencyFormat(row?.amount?.toFixed(2))}`,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'itemImage', width: 130 },
    { columnName: 'barcode', width: 180 },
    { columnName: 'description', width: 270 },
    { columnName: 'weight', width: 120, align: 'right' },
    { columnName: 'amount', width: 120, align: 'right' },
  ]);

  const [formattedColumns] = useState(['description']);

  return (
    <>
      <div className="expandable-table-rows">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          formattedColumns={formattedColumns}
          rowData={row?.purchaseItems}
          rowSelectionEnable={true}
          selection={selectedPurchaseItemId}
          onSelectionChange={onRowSelectionChange}
          highlightRow={true}
        />
      </div>
    </>
  );
};

export default PurchaseReturnDetailTable;
