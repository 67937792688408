import React, { useEffect, useState } from 'react';
import { Row, Col, Spin } from 'antd';
import { format, differenceInYears } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { CUSTOMER_PROFILE_REQUEST } from 'action_creators/customer';
import { GET_CUSTOMER_PAWNBROKING_TAB_SUMMARY } from 'action_creators/pledge';
import { joinValues, currencyFormat } from 'utils/util';
import { ROUTE_CONFIG, FNS_DATE_FORMAT } from 'globalConstants';
import { Button } from 'components/common/Button';
import CustomerHeader from '../customerHeader/index';
import { getProfileLabel, getCustomerKycStatusIcon } from '../../../utils/util';
import CustomerBottom from 'components/customer/customerBottom';
import ImageLoader from 'components/common/ImageLoader';
import {
  getPledgeOverview,
  getPurchaseSummary,
  getPurchaseOverview,
  getPurchaseItems,
} from 'services/pledge';
import {
  getFxTransactionSummary,
  getFxTransactionOverview,
} from 'services/travelMoney';
import {
  getRetailSummaryByCustomerId,
  getRetailSaleSummary,
} from 'services/retail';
import {
  getChequeCashingSummary,
  getChequeCashingOverview,
} from 'services/chequeCashing';
import { actions } from 'redux/reducers/customer';
import { pledgeActions } from 'redux/reducers/pawnbroking/index';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import { getCustomerVulnerableStatus } from 'services/customer';
import CustomerNotes from '../customerNotes';
import { PROFILE_STATUS } from '../constants';
import './index.less';
import CustomerIdPopover from './customerIdPopover';

import CustomerSummaryTabs from './tabs';

const StyledRow = styled(Row)`
  flex-wrap: nowrap;
  min-height: calc(100vh - 250px);
  background-color: var(--background);
  margin: 0 20px 0 0;
  padding: 0 20px 0 0;
`;

const StyledBottomRow = styled(Row)`
  position: absolute;
  bottom: 0;
  flex-direction: column;

  & button {
    width: 95px;
    height: 34px;
    font-weight: var(--font-weight-500);
    padding: 7px 14px;
    margin: 5px 0;

    &:disabled,
    &:disabled:hover {
      color: var(--red);
      font-weight: var(--font-weight-700);
      opacity: 1;
    }
  }
`;

const StyledRightCol = styled(Col)`
  margin-left: 0;
  width: 100%;
  padding: 0;
`;

const StyledLeftCol = styled(Col)`
  margin-right: 10px;
`;

const StyledCommonRow = styled(Row)`
  margin-block-start: 10px;
`;

const StyledTpaRow = styled(Row)`
  margin-block-start: 10px;
  font-weight: var(--font-weight-500);
`;

const StyledSpin = styled(Spin)`
  display: block;
  position: relative;
  align-items: center;
  top: 50%;
`;

const StyledCustomerIdDiv = styled.div`
  font-weight: var(--font-weight-600);
  padding: 5px 0;
`;

const StyledTick = styled.img`
  padding: 0 12px;
`;

const StyleProfileImageRow = styled(Row)<{ $color: string }>`
  border-radius: 8px;
  padding: 5px 0;
  border: ${(props) => `3px solid var(--${props.$color})`};

  ${(props) => {
    switch (props.$color) {
      case 'purple':
        return css`
          background: var(--purple-bg);
        `;
      case 'green':
        return css`
          background: var(--success-bg);
        `;
      case 'yellow':
        return css`
          background: var(--alert-bg);
        `;
      case 'red':
        return css`
          background: var(--warning-bg);
        `;
      case 'amber':
        return css`
          background: var(--amber-bg);
          border-style: dashed;
        `;
      case 'black':
        return css`
          background: var(--catskill-white-light);
          border-style: dashed;
        `;
    }
  }}

  & .ant-image {
    & img {
      border: none;
    }
  }
`;

const StyledCol = styled(Col)<{
  $isMissing?: boolean;
  $isBlueColor?: boolean;
}>`
  ${(props) =>
    props.$isMissing
      ? css`
          color: var(--red);
          font-weight: var(--font-weight-500);
        `
      : props.$isBlueColor
      ? css`
          color: var(--blue);
          font-weight: var(--font-weight-500);
        `
      : ``}
`;

const StyledProfileStatus = styled.div<{
  $color: string;
}>`
  font-weight: var(--font-weight-600);
  text-align: left;
  font-size: var(--font-size-16);
  ${(props) =>
    props.$color === 'yellow'
      ? css`
          color: var(--black);
        `
      : css`
          color: var(--${props.$color});
        `}
`;

const ageFormat = (dateOfBirth: string): string => {
  const age = differenceInYears(new Date(), new Date(dateOfBirth));
  return `${format(new Date(dateOfBirth), FNS_DATE_FORMAT)} (${age} Yrs)`;
};

const CustomerSummary = ({ isProfileView = false }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    user: { selectedStore },
    customer: {
      customer,
      loading,
      isCustFromHeader,
      resumeJourneyUrl,
      isCustomerAddedToStopList,
    },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
      user: state.user,
    };
  });

  //providing fallback (used in consumer duty screens)
  const { customerId = customer?.customerId } = useParams<any>();

  const [customerNoteVisible, setCustomerNoteVisible] =
    useState<boolean>(false);
  const [profileStatus, setCustomerProfileStatus] = useState<string>('');
  const [purchaseSummary, setPurchaseSummary] = useState<Array<any>>([]);
  const [retailSummary, setRetailSummary] = useState<Array<any>>([]);
  const [travelMoneySummary, setTraveMoneySummary] = useState<Array<any>>([]);
  const [chequeCashingSummary, setChequeCashingSummary] = useState<Array<any>>(
    []
  );
  const [pledgeOverview, setPledgeOverview] = useState<any>({});
  const [purchaseOverview, setPurchaseOverview] = useState<any>({});
  const [travelMoneyOverview, setTravelMoneyOverview] = useState<any>({});
  const [retailSaleOverview, setRetailSaleOverview] = useState<any>({});
  const [chequeCashingOverview, setChequeCashingOverview] = useState<any>({});
  const [isCustomerDocPopupVisible, setIsCustomerDocPopupVisible] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch(pledgeActions.setPawnbrokingSummary([]));
    dispatch({
      type: CUSTOMER_PROFILE_REQUEST,
      payload: { customerId },
    });
    getCustomerPledgeOverview(); // Overview tab - Pledge
    getCustomerPurchaseOverview(); //Overview tab - Purchase
    getTravelMoneyOverview(); //Overview tab - travel Money
    getRetailSummaryOverview(); //Overview tab - Retail
    getChequeCachingSummaryOverview(); //Overview tab - Cheque Caching
    getCustomerPledgeSummaryTab(); // Pawnbroking tab data
    getCustomerPurchaseSummaryTab(); //Purchase - Buy tab data
    getTravelMoneySummaryTab(); // Travel Money tab data
    getChequeCashingSummaryTab(); // ChequeCashing tab data
    getRetailSummaryTab(); // Retail tab data
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getCustomerProfileStatus(customerId);
  }, [customer, customerId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getCustomerProfileStatus(customerId);
  }, [isCustomerAddedToStopList]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomerProfileStatus = async (customerParam: any) => {
    try {
      const response = await getCustomerVulnerableStatus(customerParam);
      if (response?.data?.customerId === +customerParam)
        setCustomerProfileStatus(response?.data?.colorCode);

      if (response?.data?.colorCode === PROFILE_STATUS.RED)
        dispatch(actions.setIsCustomerInStopList(true));
    } catch (e: any) {
      console.log(e);
    }
  };

  const onEditProfileHandler = () => {
    navigate(`/${ROUTE_CONFIG.CUSTOMER}/${customerId}/${ROUTE_CONFIG.EDIT}`);
  };

  const commonRow = (
    label: string,
    value: string,
    isMissing?: boolean,
    isBlueColor?: boolean
  ) => {
    return (
      <StyledCommonRow align="top" gutter={16}>
        <Col span={11}>{label}</Col>
        <StyledCol span={13} $isMissing={isMissing} $isBlueColor={isBlueColor}>
          {value}
        </StyledCol>
      </StyledCommonRow>
    );
  };

  const {
    emailAddress,
    property,
    houseName,
    street,
    area,
    town,
    county,
    dateOfBirth,
    mobile,
    telephone,
    prefersEmailContact,
    postcode,
    marketingEmailAccepted,
    marketingOptOut,
    marketingPhoneAccepted,
    marketingPostAccepted,
    marketingSmsAccepted,
    kycStatus = {},
  } = Object(customer);

  const marketingPreferences = [
    {
      key: 'Mail',
      value: marketingPostAccepted,
    },
    {
      key: 'Text',
      value: marketingSmsAccepted,
    },
    {
      key: 'Email',
      value: marketingEmailAccepted,
    },
    {
      key: 'Phone',
      value: marketingPhoneAccepted,
    },
    {
      key: 'Opt-out',
      value: marketingOptOut,
    },
  ];

  const getCustomerMarketingPreferences = () => {
    return marketingPreferences
      .filter((item) => {
        return item.value === true;
      })
      .map((item) => item.key)
      .join(', ');
  };

  const formattedAmountWithBrackets = (amount: any, count: any) => {
    return (
      currencyFormat(+amount > 0 ? amount : 0.0, true) + ' (' + count + ')'
    );
  };

  const getCustomerPledgeOverview = async () => {
    try {
      const response = await getPledgeOverview(customerId);
      if (response?.data) {
        const {
          totalOpenPledgeAmount,
          totalOpenPledgeCount,
          allTimeLentPledgeAmount,
          allTimeLentPledgeCount,
          allTimePickedUpPledgeAmount,
          allTimePickedUpPledgeCount,
          redemptionRate,
        } = response?.data;
        setPledgeOverview({
          totalOpenPledgeAmount: totalOpenPledgeAmount
            ? `${currencyFormat(
                totalOpenPledgeAmount,
                true
              )} (${totalOpenPledgeCount})`
            : `£0.00 (0)`,
          allTimeLentPledgeAmount: allTimeLentPledgeAmount
            ? `${currencyFormat(
                allTimeLentPledgeAmount,
                true
              )} (${allTimeLentPledgeCount})`
            : `£0.00 (0)`,
          allTimePickedUpPledgeAmount: allTimePickedUpPledgeAmount
            ? `${currencyFormat(
                allTimePickedUpPledgeAmount,
                true
              )} (${allTimePickedUpPledgeCount})`
            : `£0.00 (0)`,
          redemptionRate: redemptionRate
            ? `${redemptionRate?.toFixed(2)}%`
            : `0.00%`,
          isShowOverview:
            totalOpenPledgeCount ||
            allTimeLentPledgeCount ||
            allTimePickedUpPledgeCount > 0
              ? true
              : false,
        });
        dispatch(actions.setAllTimeCustomerLoansCount(allTimeLentPledgeCount));
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const getCustomerPurchaseOverview = async () => {
    try {
      const response = await getPurchaseOverview(
        selectedStore?.storeId,
        customerId
      );
      if (response?.data) {
        const {
          totalOpenPurchaseAmount,
          totalOpenPurchaseCount,
          allTimeSoldPurchaseAmount,
          allTimeSoldPurchaseCount,
          allTimePickedUpPurchaseAmount,
          allTimePickedUpPurchaseCount,
          redemptionRate,
        } = response?.data;
        setPurchaseOverview({
          totalOpenPurchaseAmount: totalOpenPurchaseAmount
            ? `${currencyFormat(
                totalOpenPurchaseAmount,
                true
              )} (${totalOpenPurchaseCount})`
            : `£0.00 (0)`,
          allTimeSoldPurchaseAmount: allTimeSoldPurchaseAmount
            ? `${currencyFormat(
                allTimeSoldPurchaseAmount,
                true
              )} (${allTimeSoldPurchaseCount})`
            : `£0.00 (0)`,
          allTimePickedUpPurchaseAmount: allTimePickedUpPurchaseAmount
            ? `${currencyFormat(
                allTimePickedUpPurchaseAmount,
                true
              )} (${allTimePickedUpPurchaseCount})`
            : `£0.00 (0)`,
          redemptionRate: redemptionRate
            ? `${redemptionRate?.toFixed(2)}%`
            : `0.00%`,
          isShowOverview:
            totalOpenPurchaseCount ||
            allTimeSoldPurchaseCount ||
            allTimePickedUpPurchaseCount > 0
              ? true
              : false,
        });
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const getRetailSummaryOverview = async () => {
    try {
      const response = await getRetailSaleSummary(customerId);
      if (response?.data) {
        const {
          totalAmountPaid,
          totalPaidCount,
          totalRefundAmount,
          totalRefundCount,
          totalExchangeAmount,
          totalExchangeCount,
        } = response?.data;
        setRetailSaleOverview({
          totalSales: formattedAmountWithBrackets(
            totalAmountPaid,
            totalPaidCount
          ),
          totalRefunds: formattedAmountWithBrackets(
            totalRefundAmount,
            totalRefundCount
          ),
          totalExchange: formattedAmountWithBrackets(
            totalExchangeAmount,
            totalExchangeCount
          ),
          isShowOverview:
            totalPaidCount || totalRefundCount || totalExchangeCount > 0
              ? true
              : false,
        });
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const getChequeCachingSummaryOverview = async () => {
    try {
      const response = await getChequeCashingOverview(customerId);

      if (response?.data) {
        const {
          totalAmountPaid,
          totalClearedAmount,
          totalClearedCount,
          totalPaidCount,
          totalReturnAmount,
          totalReturnCount,
        } = response?.data;

        setChequeCashingOverview({
          allTimeCashed: formattedAmountWithBrackets(
            totalAmountPaid,
            totalPaidCount
          ),
          allTimeReturned: formattedAmountWithBrackets(
            totalReturnAmount,
            totalReturnCount
          ),
          allTimeCleared: formattedAmountWithBrackets(
            totalClearedAmount,
            totalClearedCount
          ),
          isShowOverview:
            totalClearedCount || totalPaidCount || totalReturnCount > 0
              ? true
              : false,
        });
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const getCustomerPledgeSummaryTab = async () => {
    dispatch({
      type: GET_CUSTOMER_PAWNBROKING_TAB_SUMMARY,
      payload: { customerId },
    });
  };

  const getCustomerPurchaseSummaryTab = async () => {
    try {
      const response = await getPurchaseSummary(customerId);
      if (response?.data?.purchases) {
        try {
          const itemsData = await Promise.all(
            response?.data?.purchases.map(async (item: any) => {
              const itemsResponse = await getPurchaseItems(
                item.originalPurchaseId,
                item?.isReturned ? 1 : 0
              );
              if (itemsResponse?.data) {
                item.purchaseItems = itemsResponse?.data?.items || [];
                return item;
              } else return Promise.resolve('error');
            })
          );

          itemsData.forEach((item: any) => {
            item.purchaseId = item.id;
            item.id = `${item.purchaseId}-${item?.isReturned ? 1 : 0}`;
          });

          setPurchaseSummary(itemsData);
        } catch (e: any) {
          console.log(e);
        }
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const getRetailSummaryTab = async () => {
    try {
      const response = await getRetailSummaryByCustomerId(customerId);
      if (response?.data) {
        setRetailSummary(response?.data);
      }
    } catch (e: any) {
      console.log(e);
    }
  };
  const getChequeCashingSummaryTab = async () => {
    try {
      const response = await getChequeCashingSummary(customerId);
      if (response?.data) {
        const data = response?.data?.map((el: any, key: any) => {
          el.id = key++;
          return el;
        });
        setChequeCashingSummary(data);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const getTravelMoneySummaryTab = async () => {
    try {
      const response = await getFxTransactionSummary(customerId);
      if (response?.data) {
        setTraveMoneySummary(response.data);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const getTravelMoneyOverview = async () => {
    try {
      const response = await getFxTransactionOverview(customerId);
      if (response?.data) {
        const {
          totalBuy,
          totalBuyCount,
          totalSell,
          totalSellCount,
          rateGuranteeCount,
        } = response?.data;
        setTravelMoneyOverview({
          totalBuy: totalBuy
            ? `${currencyFormat(totalBuy, true)} (${totalBuyCount})`
            : `£0.00 (0)`,
          totalSell: totalSell
            ? `${currencyFormat(totalSell, true)} (${totalSellCount})`
            : `£0.00 (0)`,
          rateGuranteeCount: rateGuranteeCount ? rateGuranteeCount : `0`,
          isShowOverview:
            totalBuyCount || totalSellCount || rateGuranteeCount > 0
              ? true
              : false,
        });
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const onCustomerNotesCancelClick = () => {
    setCustomerNoteVisible(false);
  };

  return (
    <>
      {!isProfileView && (
        <CustomerHeader
          goBackArrow={false}
          title="Customer profile"
          buttonsOption={true}
          customerId={customerId}
          helpSection="CustomerSummary"
        />
      )}
      <StyledRow className="customer-summary-wrapper">
        <StyledLeftCol className="control-box control-box-left" span={8}>
          {loading ? (
            <StyledSpin tip="Loading..." />
          ) : (
            <>
              <StyleProfileImageRow
                className="customer-profile-img"
                gutter={16}
                $color={profileStatus}
              >
                <Col span={11}>
                  <ImageLoader
                    customerId={customerId}
                    imageUrl={
                      customerId &&
                      `/api/customers/images/${customerId}?isThumnails=false`
                    }
                    classes={`mx-larger-avatar`}
                    tip="Loading..."
                    fallbackImg={'customer-pic.svg'}
                    isCaching={false}
                    preview
                  />
                </Col>
                <Col span={13} className="customer-id-info">
                  <Row>
                    <StyledProfileStatus
                      $color={profileStatus}
                      dangerouslySetInnerHTML={{
                        __html: getProfileLabel(profileStatus),
                      }}
                    />
                    <StyledTick
                      alt=""
                      src={getCustomerKycStatusIcon(profileStatus)}
                    />
                  </Row>

                  <StyledBottomRow>
                    <StyledCustomerIdDiv>
                      Customer Number {customerId}
                    </StyledCustomerIdDiv>
                    <Button
                      disabled={customer?.customerDocuments?.length === 0}
                      itemProp="secondary"
                      onClick={() => setIsCustomerDocPopupVisible(true)}
                    >
                      {customer?.customerDocuments?.length === 0
                        ? 'NO ID'
                        : 'View ID'}
                    </Button>
                    <HelpPopoverInfo
                      linkedID="CustomerSummary_EditProfile"
                      position="rightTop"
                      helpPosition="AFTER"
                    >
                      <Button
                        itemProp="secondary"
                        onClick={onEditProfileHandler}
                        disabled={isProfileView}
                        className="edit-profile-disabled"
                      >
                        Edit Profile
                      </Button>
                    </HelpPopoverInfo>
                  </StyledBottomRow>
                </Col>
              </StyleProfileImageRow>

              <div className="customer-details">
                {commonRow(
                  'Date of Birth',
                  (dateOfBirth && ageFormat(dateOfBirth)) || '--'
                )}
                {commonRow(
                  'ID Capture Date',
                  (customer?.customerDocuments?.length &&
                    format(
                      new Date(customer?.customerDocuments[0]?.dateOfCapture),
                      FNS_DATE_FORMAT
                    )) ||
                    '--'
                )}
                {commonRow(
                  'Mobile No.',
                  mobile || 'Mobile number required',
                  !mobile,
                  true
                )}
                {commonRow('Home Tel. No.', telephone || '--')}
                {commonRow(
                  'Email',
                  emailAddress || 'Email required',
                  !emailAddress,
                  true
                )}
                {commonRow(
                  'Address',
                  joinValues([
                    property,
                    houseName,
                    street,
                    area,
                    town,
                    county,
                    postcode,
                  ])
                )}
                {kycStatus?.kycUpdatedDate &&
                  commonRow(
                    'Customer Review/Update Status',
                    `${kycStatus?.description} (Set ${
                      kycStatus &&
                      format(
                        new Date(kycStatus?.kycUpdatedDate),
                        FNS_DATE_FORMAT
                      )
                    })`,
                    new Date(kycStatus?.kycUpdatedDate) > new Date()
                  )}
                {commonRow(
                  'Communication Preference',
                  prefersEmailContact ? 'Electronic' : 'Paper'
                )}
                {commonRow(
                  'Marketing Preference',
                  getCustomerMarketingPreferences()
                )}
                {customer?.customerNominee &&
                  customer?.customerNominee?.length > 0 && (
                    <StyledTpaRow align="top" gutter={16}>
                      <Col span={11}>{'3rd Party'}</Col>
                      <StyledCol span={13}>
                        {customer?.customerNominee?.map((item: any) => {
                          return (
                            <>
                              <Row>
                                {item?.firstName} {item?.surname}
                              </Row>
                              <Row>{item?.relationShip}</Row>
                            </>
                          );
                        })}
                      </StyledCol>
                    </StyledTpaRow>
                  )}
              </div>
              {customerNoteVisible ? (
                <CustomerNotes
                  customerNoteVisible
                  onCustomerNotesCancelClick={onCustomerNotesCancelClick}
                />
              ) : null}
            </>
          )}
          {!isProfileView && (
            <CustomerBottom
              buttonsOption={true}
              isCustFromHeader={isCustFromHeader}
              resumeJourneyUrl={resumeJourneyUrl}
              profileStatus={profileStatus}
            />
          )}
        </StyledLeftCol>
        <StyledRightCol
          key="profile"
          className="control-box right-control-box"
          span={16}
        >
          <CustomerSummaryTabs
            pledgeOverview={pledgeOverview}
            purchaseOverview={purchaseOverview}
            travelMoneyOverview={travelMoneyOverview}
            purchaseSummary={purchaseSummary}
            travelMoneySummary={travelMoneySummary}
            retailSummary={retailSummary}
            retailSaleOverview={retailSaleOverview}
            chequeCashSummary={chequeCashingSummary}
            chequeCashingOverview={chequeCashingOverview}
            isProfileView={isProfileView}
          />
          {!isProfileView && <CustomerBottom />}
        </StyledRightCol>
      </StyledRow>

      {isCustomerDocPopupVisible && (
        <CustomerIdPopover
          visible={isCustomerDocPopupVisible}
          customerDocs={customer?.customerDocuments}
          onCustomerDocPopoverClose={() => setIsCustomerDocPopupVisible(false)}
        />
      )}
    </>
  );
};

export default CustomerSummary;
