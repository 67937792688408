import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Modal, Row, Col, Input, Space, notification, Spin } from 'antd';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import { useSelector, useDispatch } from 'react-redux';
import {
  BUTTON_LABELS,
  BARCODE_PLACEHOLDER,
  LAYAWAY_ITEM_ADD_ERROR,
} from '../constants';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import LayawayItemsTable from './LayawayItemsTable';
import {
  getLayawayItems,
  searchAddLayawayItem,
  deleteLayawayItem,
  generateLayawayReceipt,
  generateLayawayContract,
  generateLayawayStatement,
} from 'services/retail';
import { NOTIFICATION_TYPE } from 'globalConstants';
import { actions } from 'redux/reducers/retail';
import ControlledAlert from 'components/common/ControlledAlert';
import './LayawayModal.less';
import AccountTransaction from './AccountTransaction';
import AddWithdrawModal from '../LayawayAddWithdrawModal';
import { sendToPrinter } from 'services/user';

const StyledModal = styled(Modal)`
  height: calc(100vh - 120px);
  & .ant-modal-body {
    padding-top: 20px;
    height: calc(100vh - 200px);
  }
`;
const LeftAlignedCol = styled(Col)`
  text-align: left;
`;

const StyledSearch = styled(Input.Search)`
  & .ant-input-group-wrapper {
    & .ant-input-wrapper {
      .ant-input-group-addon {
        button {
          padding-top: 5px !important;
        }
      }
    }
  }
`;

type LayawayModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onAddToBasket: (items: any) => void;
  isPhoneEnquiryMode: boolean;
  isStoreEnquiryMode: boolean;
};

type layawayItemsAccountInfoProps = {
  totalAmount: number;
  totalRecordCount: number;
  accountShortfall: number;
};

const layawayItemsAccountInfoInitialProps = {
  totalAmount: 0,
  totalRecordCount: 0,
  accountShortfall: 0,
};

const noop = () => { /* do nothing */ };

function LayawayModal({
  isOpen = false,
  onCancel = noop,
  onAddToBasket = () => { /* do nothing */ },
  isPhoneEnquiryMode = false,
  isStoreEnquiryMode = false,
}: LayawayModalProps) {
  const dispatch = useDispatch();
  const [barcodeState, setBarcodeState] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenAddWithdrawModal, setIsOpenAddWithdrawModal] =
    useState<boolean>(false);
  const [layawayItems, setLayawayItems] = useState<any>([]);
  const [layawayTransactionItems, setLayawayTransactionItems] = useState<any>(
    []
  );
  const [availableBalance, setAvailableBalance] = useState<number>(0);
  const [selectedLayawayItems, setSelectedLayawayItems] = useState<any>([]);
  const [selectedItemDetails, setSelectedItemDetails] = useState<any>([]);
  const [itemToBeDelete, setItemToBeDelete] = useState<number>(0);
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] =
    useState<boolean>(false);
  const [isLoadingPrintContract, setIsLoadingPrintContract] =
    useState<boolean>(false);
  const [isLoadingPrintStatement, setIsLoadingPrintStatement] =
    useState<boolean>(false);
  const {
    retail: { rows: retailData },
    customer: { customer },
    user: { user, selectedStore },
  } = useSelector((state: any) => {
    return {
      retail: state.retail,
      customer: state.customer,
      user: state.user,
    };
  });
  const userName = user?.authorizeUsername;
  const [layawayItemsAccountInfo, setLayawayItemsAccountInfo] =
    useState<layawayItemsAccountInfoProps>(layawayItemsAccountInfoInitialProps);

  const handleAddWithdrawClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpenAddWithdrawModal(true);
  };

  const closeAddWithdrawModal = () => {
    setIsOpenAddWithdrawModal(false);
  };

  const idAddWithDrawFundDisable = useMemo(() => {
    return isPhoneEnquiryMode || isStoreEnquiryMode;
  }, [isPhoneEnquiryMode, isStoreEnquiryMode]);

  const loadlayawayItems = useCallback(async (customerId: string) => {
    try {
      setIsLoading(true);
      const response = await getLayawayItems(customerId);

      if (response?.data) {
        const { layawayTransactionAccountBalance, layawayTransactions } =
          response?.data?.layawayTransactionDetail;

        setIsLoading(false);
        setLayawayItems(response?.data?.layawayItems);
        setLayawayTransactionItems(layawayTransactions || []);

        const accountInfo = {
          ...layawayItemsAccountInfo,
          accountShortfall: response?.data?.layawayAccountShortfall,
          totalAmount: response?.data?.totalAmount || 0,
          totalRecordCount: response?.data?.totalRecordCount || 0,
        };
        setAvailableBalance(layawayTransactionAccountBalance || 0);
        setLayawayItemsAccountInfo(accountInfo);
      }
    } catch (e: any) {
      setIsLoading(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const printReceipt = useCallback(async (params: any) => {
    try {
      const storeReceiptResponse = await generateLayawayReceipt(params, 1); // store receipt
      const customerReceiptResponse = await generateLayawayReceipt(params, 2); // customer receipt

      const values = await Promise.all([
        storeReceiptResponse,
        customerReceiptResponse,
      ]);

      values.forEach((receipt: any) => {
        if (receipt?.data) {
          sendToPrinter(receipt, 'printreceipt', '_PrintReceipt');
        }
      });
    } catch (e: any) {
      notification.error({
        message: e?.message,
        description: e?.message,
        duration: 5,
      });
    }
  }, []);

  const onTransactionSuccess = useCallback(
    (transactionNumber: any, savedData: any) => {
      const customerId = customer?.customerId || 0;
      const payload = {
        transactionNumber,
        customerId,
        userName,
        paymentMethod: savedData?.paymentType === 1 ? 'Cash' : 'Card',
        store: selectedStore,
        customer,
      };

      printReceipt(payload);
      loadlayawayItems(customerId);
    },
    [customer, loadlayawayItems, printReceipt, selectedStore, userName]
  );

  useEffect(() => {
    const customerId = customer?.customerId || 0;
    loadlayawayItems(customerId);
  }, [loadlayawayItems, customer]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelection = useCallback(
    (selectedRows: string[]) => {
      const selectedItems = selectedRows?.map((id: any) => {
        const selectedItem = layawayItems?.find((item: any) => {
          return item.id === Number(id);
        });
        return selectedItem;
      });
      setSelectedItemDetails(selectedItems);

      const selectedItemIds = selectedItems.map((item: any) => {
        return item.itemId;
      });

      setSelectedLayawayItems(selectedItemIds);
    },
    [layawayItems]
  );

  const handleSuccessResponse = useCallback(
    (response: any) => {
      // Store array of items into retailItemResponse
      const {
        layawayItems: resLayawayItems,
        totalAmount,
        totalRecordCount,
        layawayAccountShortfall,
      } = response?.data;
      setLayawayItems(resLayawayItems);
      setLayawayItemsAccountInfo({
        ...layawayItemsAccountInfo,
        totalAmount,
        totalRecordCount,
        accountShortfall: layawayAccountShortfall,
      });
      notification.success({
        message: NOTIFICATION_TYPE.SUCCESS,
        description: response?.data?.message,
        duration: 5,
      });
    },
    [layawayItemsAccountInfo]
  );

  const addToRetailBasket = useCallback(
    (event: any) => {
      event.stopPropagation();
      if (selectedLayawayItems.length) {
        const arrStockItemIds = retailData?.map(
          (stockItemIdObj: any) => stockItemIdObj.stockItemId
        );

        const payloadInfo = layawayItems
          ?.filter(
            (item: any) =>
              selectedLayawayItems.includes(item?.itemId) &&
              !arrStockItemIds.includes(item?.itemId)
          )
          .map((retailItemsObject: any) => retailItemsObject?.itemDetails);

        dispatch(actions.retailItemResponse([...retailData, ...payloadInfo]));
        onAddToBasket(payloadInfo);
        // Store array of items into retailItemResponse
        onCancel();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [layawayItems, retailData, selectedLayawayItems]
  );

  const onDeleteItem = (itemId: any, event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpenDeleteConfirm(true);
    setItemToBeDelete(itemId);
  };

  const closeDeleteConfirmModal = () => {
    setIsOpenDeleteConfirm(false);
    setItemToBeDelete(0);
  };

  const handleDeleteItem = useCallback(async () => {
    if (itemToBeDelete) {
      try {
        const customerId = customer?.customerId || 0;
        const response = await deleteLayawayItem({
          customerId,
          itemId: itemToBeDelete,
        });
        if (response?.data?.recentItemAddedOrDeletedInLayaway) {
          handleSuccessResponse(response);
          closeDeleteConfirmModal();
        } else {
          notification.error({
            message: NOTIFICATION_TYPE.ERROR,
            description: response?.data?.message,
            duration: 5,
          });
        }
      } catch (e: any) {
        notification.error({
          message: NOTIFICATION_TYPE.GENERIC_API_ERROR,
          description: NOTIFICATION_TYPE.GENERIC_API_ERROR,
          duration: 5,
        });
      }
    }
  }, [customer?.customerId, handleSuccessResponse, itemToBeDelete]);

  const onChangeSearchHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setBarcodeState(event.target.value);
    },
    []
  );

  const onSearchHandler = useCallback(
    async (barcode: string, event?: any) => {
      event?.stopPropagation();

      if (barcode) {
        const isItemInRetail = retailData?.find(
          (stockItemIdObj: any) => stockItemIdObj?.barcode === barcode
        );
        if (!isItemInRetail) {
          setBarcodeState(barcode);
          try {
            setIsLoading(true);
            const customerId = customer?.customerId || 0;
            const response = await searchAddLayawayItem({
              customerId,
              barcode,
            });

            if (response?.status === 200) {
              if (response?.data?.recentItemAddedOrDeletedInLayaway === null) {
                notification.error({
                  message: '',
                  description: `Error: ${response?.data?.message}`,
                  duration: 5,
                });
                setIsLoading(false);
              } else {
                printLayawayContract(
                  null,
                  response?.data?.recentItemAddedOrDeletedInLayaway
                );
                handleSuccessResponse(response);
                setIsLoading(false);
                setBarcodeState('');
              }
            }
          } catch (e: any) {
            setIsLoading(false);
            notification.error({
              message: NOTIFICATION_TYPE.GENERIC_API_ERROR,
              description: NOTIFICATION_TYPE.GENERIC_API_ERROR,
              duration: 5,
            });
          }
        } else {
          notification.error({
            message: `"${barcode}" ${LAYAWAY_ITEM_ADD_ERROR}`,
            description: '',
            duration: 10,
          });
          onCancel();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customer?.customerId, onCancel, retailData]
  );

  const onPressEnter = useCallback(() => {
    onSearchHandler(barcodeState);
  }, [barcodeState, onSearchHandler]);

  const printLayawayContract = useCallback(
    async (event?: any, transactionNumber?: any) => {
      setIsLoadingPrintContract(true);
      let isPrintContractClicked = false;
      // event is not null if print contract button is clicked
      if (event) {
        event.stopPropagation();
        isPrintContractClicked = true;
      }
      const customerId = customer?.customerId;
      const payload = {
        transactionNumber: transactionNumber || '',
        customerId,
        userName,
        store: selectedStore,
        customer,
      };
      try {
        if (isPrintContractClicked) {
          selectedItemDetails?.forEach(async (selItem: any) => {
            const layawayContract = await generateLayawayContract({
              ...payload,
              transactionNumber: selItem?.id,
            });
            if (layawayContract?.data) {
              sendToPrinter(
                layawayContract,
                'printcontract',
                '_LayawayContract'
              );
              sendToPrinter(
                layawayContract,
                'printcontract',
                '_LayawayContract'
              );
            }
          });
        } else {
          const layawayContract = await generateLayawayContract(payload);
          if (layawayContract?.data) {
            sendToPrinter(layawayContract, 'printcontract', '_LayawayContract');
            sendToPrinter(layawayContract, 'printcontract', '_LayawayContract');
          }
        }
      } catch (e: any) {
        notification.error({
          message: '',
          description: e?.message,
          duration: 5,
        });
      } finally {
        setIsLoadingPrintContract(false);
      }
    },
    [customer, selectedItemDetails, selectedStore, userName]
  );

  const printLayawayStatement = useCallback(
    async (event?: any) => {
      setIsLoadingPrintStatement(true);
      if (event) {
        event.stopPropagation();
      }
      const customerId = customer?.customerId;
      const payload = {
        transactionNumber: 0,
        customerId,
        userName,
        store: selectedStore,
        customer,
      };
      try {
        const layawayContract = await generateLayawayStatement(payload);
        if (layawayContract?.data) {
          sendToPrinter(layawayContract, 'printcontract', '_LayawayStatement');
        }
      } catch (e: any) {
        notification.error({
          message: '',
          description: e?.message,
          duration: 5,
        });
      } finally {
        setIsLoadingPrintStatement(false);
      }
    },
    [customer, selectedStore, userName]
  );

  return (
    <StyledModal
      open={isOpen}
      title="Layaway Account"
      closable={true}
      centered
      width={1350}
      maskClosable={false}
      onCancel={onCancel}
      footer={[
        <Row key="layawayFooter">
          <LeftAlignedCol span={12}>
            <Space align="start" size="middle">
              <Button itemProp="secondary" key="back" onClick={onCancel}>
                {BUTTON_LABELS.CLOSE}
              </Button>
              <Button
                itemProp="secondary"
                disabled={selectedLayawayItems?.length === 0}
                onClick={printLayawayContract}
              >
                {isLoadingPrintContract ? (
                  <>
                    <Spin /> {BUTTON_LABELS.PRINT_CONTRACT}
                  </>
                ) : (
                  BUTTON_LABELS.PRINT_CONTRACT
                )}
              </Button>
              <Button itemProp="secondary" onClick={printLayawayStatement}>
                {isLoadingPrintStatement ? (
                  <>
                    <Spin /> {BUTTON_LABELS.STATEMENT}
                  </>
                ) : (
                  BUTTON_LABELS.STATEMENT
                )}
              </Button>
            </Space>
          </LeftAlignedCol>
          <Col span={12}>
            <Space size="middle">
              <Button
                itemProp="secondary"
                onClick={handleAddWithdrawClick}
                disabled={idAddWithDrawFundDisable}
              >
                {BUTTON_LABELS.ADD_WITHDRAW_FUND}
              </Button>
              <Button
                key="submit"
                disabled={selectedLayawayItems?.length === 0}
                onClick={addToRetailBasket}
              >
                {BUTTON_LABELS.ADD_TO_BASKET}
              </Button>
            </Space>
          </Col>
        </Row>,
      ]}
    >
      <Row>
        <Col xl={8} lg={12} xs={20} md={12} sm={16}>
          <HelpPopoverInfo
            linkedID={'RETAIL_SEARCHBARCODE'}
            position="rightTop"
            helpPosition="AFTER"
            attachedComp="BUTTON"
          >
            <StyledSearch
              allowClear
              className="barcode-search"
              maxLength={30}
              width={302}
              placeholder={BARCODE_PLACEHOLDER}
              onChange={onChangeSearchHandler}
              value={barcodeState}
              onPressEnter={onPressEnter}
              onSearch={onSearchHandler}
              enterButton
              autoFocus
            />
          </HelpPopoverInfo>
        </Col>
      </Row>
      <Row gutter={16} className="layaway-item-container">
        <Col span={18}>
          <LayawayItemsTable
            rowData={layawayItems}
            totalAmount={layawayItemsAccountInfo.totalAmount}
            totalRecordCount={layawayItemsAccountInfo.totalRecordCount}
            accountShortfall={layawayItemsAccountInfo.accountShortfall}
            isLoading={isLoading}
            onSelection={onSelection}
            onDeleteItem={onDeleteItem}
          />
        </Col>
        <Col span={6} className="border1">
          <AccountTransaction
            rowData={layawayTransactionItems}
            availableBalance={availableBalance}
          />
        </Col>
      </Row>
      {isOpenDeleteConfirm && (
        <ControlledAlert
          visible={isOpenDeleteConfirm}
          alertMessage="Alert"
          width={400}
          message="Are you sure you want to delete ?"
          onClick={handleDeleteItem}
          onCancel={closeDeleteConfirmModal}
        />
      )}
      {isOpenAddWithdrawModal && !idAddWithDrawFundDisable && (
        <AddWithdrawModal
          isOpen={isOpenAddWithdrawModal}
          customerId={customer?.customerId}
          onCancel={closeAddWithdrawModal}
          onSave={onTransactionSuccess}
          accountShortfall={layawayItemsAccountInfo.accountShortfall}
        />
      )}
    </StyledModal>
  );
}

export default LayawayModal;
