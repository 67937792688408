import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Form, Col, Row, Input } from 'antd';

import { Button } from 'components/common/Button';
import { actions } from 'redux/reducers/inventoryManagement';

const StyledCol = styled(Col)`
  padding-left: 20px;
`;

const StyledSpan = styled.span`
  margin-top: 14px;
  font-style: italic;
`;

interface AuthorizationFormProps {
  addonBeforeText: string;
  authorizationHandler: (authorisationHeaders: any) => void;
}

const AuthorizationForm = ({
  addonBeforeText = '',
  authorizationHandler,
}: AuthorizationFormProps) => {
  const { authorizing, isItemAdded } = useSelector(
    (state: any) => state.inventoryManagement
  );

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isItemAdded) {
      form.setFieldsValue({
        authorisationUserId: '',
        authorisationPassword: '',
      });
      dispatch(actions.newItemAdded(false));
    }
  }, [isItemAdded]); // eslint-disable-line react-hooks/exhaustive-deps

  const onConfirmClick = () => {
    const authorisationHeaders = {
      authorisationUserName: form.getFieldValue('authorisationUserId'),
      authorisationPassword: form.getFieldValue('authorisationPassword'),
    };
    authorizationHandler(authorisationHeaders);
  };

  return (
    <>
      <Col span={12}>
        <Row>
          <StyledSpan>{addonBeforeText}</StyledSpan>
        </Row>
      </Col>
      <Col span={12}>
        <Row justify="end">
          <Col span={24}>
            <Form
              name="authorizationForm"
              form={form}
              onFinish={onConfirmClick}
              autoComplete="off"
            >
              <Row>
                <StyledCol span={10}>
                  <Form.Item
                    label="Employee ID"
                    name="authorisationUserId"
                    className="label-control"
                    rules={[
                      { required: true, message: 'Employee ID is required' },
                    ]}
                  >
                    <Input className="input-control" />
                  </Form.Item>
                </StyledCol>
                <StyledCol span={10}>
                  <Form.Item
                    label="Password"
                    name="authorisationPassword"
                    className="label-control"
                    rules={[
                      { required: true, message: 'Password is required' },
                    ]}
                  >
                    <Input type="password" className="input-control" />
                  </Form.Item>
                </StyledCol>
                <StyledCol span={4}>
                  <Button loading={authorizing} htmlType="submit">
                    Confirm
                  </Button>
                </StyledCol>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default AuthorizationForm;
