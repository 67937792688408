import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rows: [] as Array<any>,
  alreadyRefunded: [],
  basketRetailRows: [] as Array<any>,
  retailItems: [] as Array<any>,
  savedRetailItems: [] as Array<any>,
  error: {},
  isLoading: false,
  isRowSelected: false,
  storeId: null,
  tempVATPayload: {},
  showSoldMessage: false,
  retailMessage: '',
  isGuestCustomerAbstained: false,
  isSaveAndContinueClicked: false,
};
const slice = createSlice({
  name: 'retailActions',
  initialState,
  reducers: {
    retailItemResponse(state, action) {
      state.rows = action.payload;
      state.isLoading = false;
    },
    subsequentRefund(state, action) {
      state.alreadyRefunded = action.payload.filter(
        (item: any) => item.status === 'Returned'
      );
    },
    setRetailItemResponse(state, action) {
      state.retailItems = action.payload;
      state.isLoading = false;
    },
    findretailItemMessage(state, action) {
      state.retailMessage = action.payload;
    },
    showSoldMessage(state, action) {
      state.showSoldMessage = action?.payload;
    },
    savedRetailItemReset(state, action) {
      state.savedRetailItems = [];
    },
    updateSingleRetailItems(state, action) {
      state.rows.push(action.payload);
    },
    retailItemDelete(state, action) {
      state.rows = state.rows.filter(
        (item: any) => item.stockItemId !== action.payload
      );
      state.isLoading = false;
    },
    enableAddButton(state, action) {
      let isRowSelected = false;
      const updatedItems = state.retailItems.map((item) => {
        isRowSelected = false;
        if (item.stockItemId === action.payload.row.stockItemId) {
          isRowSelected = true;
        }
        return {
          ...item,
          isRowSelected,
        };
      });
      state.retailItems = updatedItems;
      state.isRowSelected = action.payload.isRowSelected;
    },
    retailItemError(state, action) {
      state.error = action.payload;
      state.rows = initialState.rows;
      state.isLoading = false;
    },
    retailSoldItemError(state, action) {
      state.error = action.payload;
      state.rows = initialState.rows;
      state.isLoading = false;
    },
    retailItemRequest(state, action) {
      state.isLoading = true;
    },
    retailSoldItemRequest(state, action) {
      state.isLoading = true;
    },
    retailItemReset(state, action) {
      state.rows = initialState.rows;
    },
    resetRetailItems(state) {
      state.retailItems = [];
      state.isRowSelected = false; // it will disable the button
    },
    // Need to check - can delete it and the reltated code. 10/01/2023
    retailItemAddToBasket(state, action) {
      state.basketRetailRows = action.payload;
    },
    savedRetailItemAdd(state, action) {
      state.savedRetailItems = action.payload;
    },
    retailItemsDetailResponse(state, action) {
      state.retailItems.map((item) => {
        if (item.stockItemId === action.payload.stockItemId) {
          item.data = {
            weight: action.payload.weight,
            carat: action.payload.carat,
          };
        }
        return item;
      });
    },
    retailItemsNotFound(state, action) {
      state.isLoading = false;
    },
    setTempPayloadWithVAT(state, action) {
      state.tempVATPayload = action.payload;
    },
    clearItems() {
      return { ...initialState };
    },
    setIsGuestCustomerAbstained(state, action) {
      state.isGuestCustomerAbstained = action.payload;
    },
    onSaveAndContinueMode(state, action) {
      state.isSaveAndContinueClicked = action.payload;
    },
  },
});

export default slice.reducer;
export const { reducer, actions } = slice;
export { initialState };
