import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Input } from 'antd';
import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { format } from 'date-fns';
import VirtualGrid from 'components/common/virtualGrid/index';
import ImageLoader from 'components/common/ImageLoader';
import styled from 'styled-components';
import AuctionDetailsExpandableTable from './auctionDetailsExpandableTable';
import { SUMMARY_STATUS_OPTIONS } from 'components/pledgemanagement/constants';
import { currencyFormat } from 'utils/util';
import { UPDATE_SOLD_VALUE } from 'action_creators/pledgeManagement';

const StyledSpan = styled.span`
  text-align: center;
  padding: 0 5px 0 5px;
`;

interface PendingReturnAuctionSummaryProps {
  auctionLoans: Array<any>;
}

const TableRow = ({
  row,
  onStatusSelectChange,
  isSoldValueEnable,
  onSoldValueChange,
  enabledRowId,
  ...restProps
}: any) => {
  const {
    pledgeManagement: { isLoading },
  } = useSelector((state: any) => {
    return {
      pledgeManagement: state.pledgeManagement,
    };
  });

  const onStatusActionChange = (value: any) => {
    onStatusSelectChange(row, value);
  };

  const onSoldValueInputChange = (event: any) => {
    onSoldValueChange(row, event.target.value);
  };

  const Cell = () => {
    const { column, tableRow } = restProps;
    const { row } = tableRow;
    if (
      column.name === 'status' &&
      row.status !== SUMMARY_STATUS_OPTIONS[0].value
    ) {
      return (
        <Table.Cell {...restProps}>
          {' '}
          <Select
            placeholder="Select"
            className="grid-select-option"
            loading={isLoading === row.loanId}
            value={row.status}
            onSelect={onStatusActionChange}
          >
            {SUMMARY_STATUS_OPTIONS.map((item: any, index: number) => {
              if (item.value !== SUMMARY_STATUS_OPTIONS[0].value) {
                return (
                  <Select.Option value={item.value} key={index}>
                    {item.value}
                  </Select.Option>
                );
              }
              return '';
            })}
          </Select>
        </Table.Cell>
      );
    } else if (column.name === 'soldValue') {
      return (
        <Table.Cell {...restProps}>
          <Input
            onChange={onSoldValueInputChange}
            disabled={enabledRowId !== row?.auctionDetailId}
            value={row.soldValue}
          />
        </Table.Cell>
      );
    }
    return <Table.Cell {...restProps} />;
  };

  return <>{Cell()}</>;
};

const PendingReturnAuctionSummary = ({
  auctionLoans = [],
}: PendingReturnAuctionSummaryProps) => {
  const [columns] = useState([
    {
      name: 'customerName',
      title: 'Customer Name',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <>
          <ImageLoader
            customerId={row?.customerId}
            imageUrl={
              row?.customerId &&
              `/api/customers/images/${row?.customerId}?isThumnails=false`
            }
            classes={`mx-small-avatar mx-small-avatar-yellow`}
            borderRadius={'50%'}
            fallbackImg={'customer-pic.svg'}
            preview
          />
          <StyledSpan>{row?.customerName}</StyledSpan>
        </>
      ),
    },
    {
      name: 'lotNumber',
      title: 'Lot No.',
      getCellValue: (row: any) => row?.lotNumber,
    },
    {
      name: 'followsLotNumber',
      title: 'Fellows Lot No.',
      getCellValue: (row: any) => row?.followsLotNumber,
    },
    {
      name: 'loanNumber',
      title: 'Agreement',
      getCellValue: (row: any) => row?.loanNumber,
    },
    {
      name: 'loanValue',
      title: 'Loan Value',
      getCellValue: (row: any) => row?.loanValue,
    },
    {
      name: 'dueDate',
      title: 'Due Date',
      getCellValue: (row: any) =>
        row && row?.dueDate
          ? format(new Date(row?.dueDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'items',
      title: 'Items',
      getCellValue: (row: any) => row?.items,
    },
    {
      name: 'letterDue',
      title: 'Letter Due',
      getCellValue: (row: any) =>
        row && row?.letterDue
          ? format(new Date(row?.letterDue), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'letterSent',
      title: 'Letter Sent',
      getCellValue: (row: any) =>
        row && row?.letterSent
          ? format(new Date(row?.letterSent), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'reserveValue',
      title: 'Reserve Value',
      getCellValue: (row: any) => row?.reserveValue,
    },
    {
      name: 'soldValue',
      title: 'Sold Value',
      getCellValue: (row: any) => currencyFormat(row?.soldValue?.toFixed(2)),
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row: any) => row?.status,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'customerName', width: '14%' },
    { columnName: 'lotNumber', width: '7%' },
    { columnName: 'followsLotNumber', width: '8%' },
    { columnName: 'loanNumber', width: '8%' },
    { columnName: 'loanValue', width: '8%' },
    { columnName: 'dueDate', width: '7%' },
    { columnName: 'items', width: '6%' },
    { columnName: 'letterDue', width: '7%' },
    { columnName: 'letterSent', width: '7%' },
    { columnName: 'reserveValue', width: '8%' },
    { columnName: 'soldValue', width: '8%' },
    { columnName: 'status', width: '7%' },
  ]);

  const dispatch = useDispatch();
  const getRowId = (row: any) => row?.loanId;
  const [isSoldValueEnable, setIsSoldValueEnable] = useState<boolean>(false);
  const [enabledRowId, setEnabledRowId] = useState<number>(0);
  const onStatusSelectChange = (row: any, value: any) => {
    if (value === SUMMARY_STATUS_OPTIONS[1].value) {
      setEnabledRowId(row?.auctionDetailId);
      setIsSoldValueEnable(true);
    } else if (value === SUMMARY_STATUS_OPTIONS[2].value) {
      setEnabledRowId(0);
      setIsSoldValueEnable(false);
    }
  };

  const onSoldValueChange = (row: any, value: any) => {
    if (enabledRowId === row?.auctionDetailId) {
      dispatch({
        type: UPDATE_SOLD_VALUE,
        payload: {
          soldAmount: value,
          auctionDetailId: row?.auctionDetailId,
        },
      });
    }
  };

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={auctionLoans}
        expandableComponent={AuctionDetailsExpandableTable}
        getRowId={getRowId}
        cellComponent={(restProps: any) => (
          <TableRow
            onStatusSelectChange={onStatusSelectChange}
            isSoldValueEnable={isSoldValueEnable}
            onSoldValueChange={onSoldValueChange}
            enabledRowId={enabledRowId}
            {...restProps}
          />
        )}
      />
    </>
  );
};

export default PendingReturnAuctionSummary;
