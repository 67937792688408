import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getItemHistory } from 'services/retail';
import { Spin, Row, Col } from 'antd';
import { getTimestamp } from 'utils/util';
import { format } from 'date-fns';
import { DATE_FORMAT } from '../constants';
import VirtualGrid from 'components/common/virtualGrid';
import { URL_TYPE } from 'globalConstants';
interface ItemHistoryProps {
  rows: any;
  onPopoverHideClick: () => void;
}

const StyledSpin = styled(Spin)`
  display: block;
  position: relative;
  align-items: center;
  top: calc(100% - 75%);
`;

const StyledRow = styled(Row)`
  padding: 10px 0;
`;

const StyledText = styled.span`
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-500);
  padding: 10px 0;
`;

const ItemHistorySummary = ({ rows, onPopoverHideClick }: ItemHistoryProps) => {
  const [itemActivities, setItemActivities] = useState<Array<object>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    loadItemActivity(rows);
  }, [rows]);

  const loadItemActivity = async (rows: any) => {
    try {
      const response = await getItemHistory(rows);
      if (response?.data) {
        setLoading(false);
        setItemActivities(response.data.retailItemHistory);
      }
    } catch (e: any) {
      setLoading(false);
    }
  };

  const [columns] = useState([
    {
      name: 'dateTime',
      title: 'Date & Time',
      getCellValue: (row: any) =>
        format(getTimestamp(row.activityDate), DATE_FORMAT),
    },
    {
      name: 'storeId',
      title: 'Store ID',
      getCellValue: (row: any) => row.storeId,
    },
    {
      name: 'storeName',
      title: 'Store Name',
      getCellValue: (row: any) => row.storeName,
    },

    {
      name: 'itemId',
      title: 'Item ID',
      getCellValue: (row: any) => row.itemId,
    },
    {
      name: 'barcode',
      title: 'Barcode',
      getCellValue: (row: any) => row.barcode,
    },
    {
      name: 'activity',
      title: 'Activity',
      getCellValue: (row: any) => row.status,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'dateTime', align: 'left', width: '23%' },
    { columnName: 'storeId', align: 'left', width: '12%' },
    { columnName: 'storeName', align: 'left', width: '17%' },
    { columnName: 'itemId', align: 'left', width: '12%' },
    { columnName: 'barcode', align: 'left', width: '12%' },
    { columnName: 'activity', align: 'left', width: '20%' },
  ]);

  const [sortingStateColumnExtensions] = useState([
    { columnName: 'dateTime', sortingEnabled: false },
  ]);
  return loading ? (
    <StyledSpin />
  ) : (
    <>
      <StyledRow>
        <Col span={22}>
          <StyledText>{rows?.title}</StyledText>
        </Col>
        <Col span={2}>
          <img
            className="popover-cross"
            onClick={onPopoverHideClick}
            src={require('../../../assets/images/Close.svg')}
            alt="close"
          />
        </Col>
      </StyledRow>
      <Row>
        <Col span={24}>
          <VirtualGrid
            urlType={URL_TYPE.NO_URL}
            columnsProps={columns}
            displayContentWithImage={true}
            tableColumnExtensionsProps={tableColumnExtensions}
            sortingStateColumnExtensions={sortingStateColumnExtensions}
            rowData={itemActivities}
          />
        </Col>
      </Row>
    </>
  );
};

export default ItemHistorySummary;
