import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import styled from 'styled-components';
import CustomerHeader from '../customer/customerHeader';
import { Row, Col, Tabs, Spin, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  MERGE_BASKET_PLEDGE_ITEMS,
  GET_BASKET,
  MOVE_BASKET_PLEDGE_ITEMS,
  MOVE_BASKET_PLEDGE_PURCHASE_ITEMS,
  VALIDATE_ALL_SERVICES_BASKET,
  MERGE_GUEST_BASKET_REQUEST,
  UPDATE_CUSTOMER_DETAILS,
  VALIDATE_CONSUMER_DUTY,
  LOAN_AMOUNT_CALCULATOR_ON_BASKET,
} from 'action_creators/basket';
import './index.less';
import {
  BasketPledge,
  BasketPurchase,
  BasketFx,
  BasketRetail,
  BasketPickup,
  BasketChequeCashing,
  BasketWU,
  BasketPurchaseReturn,
  BasketSurplus,
} from 'components/basket/widgets';
import groupBy from 'lodash/groupBy';

import {
  BASKET_TABS,
  ENABLE_CHILD_TABS,
  BASKET_SERVICE_TYPE,
  ENABLE_NOT_INCLUDE_ITEMS_TABLE,
  BASKET_TABS_COLORS,
  ERROR_MESSAGE,
  FX_TRANSACTION_TYPE,
  CUSTOMER_ALERT_POPUP,
  BASKET_TABS_ORDER,
  CONSUMER_DUTY_ALERT,
} from './constants';
import { PROFILE_STATUS } from 'components/customer/constants';

import {
  allLoanAmounts,
  calculateTotalSumByKey,
  getPledgeAgreementItemsArray,
  isAmountExceed,
  currencyFormat,
  calculateTotalWeight,
} from 'utils/util';
import ContainerButtons from './containerButtons/index';
import PledgeLoanContainer from 'components/pledge/viewPledgePurchase/PledgeLoanContainer';
import FooterButtons from 'components/common/footerButtons';
import {
  BASKET_STATUS,
  EXCEED_AMOUNTS,
  FOOTER_BUTTONS_AND_TEXT,
  GUEST_TYPES,
  ROUTE_CONFIG,
} from 'globalConstants';
import { isEmpty, startsWith } from 'lodash';
import PurchaseTableContainer from 'components/pledge/viewPledgePurchase/PurchaseTableContainer';
import { FxInfo } from 'components/basket/bottomContainerInfo';
import PledgePickupLoanTable from 'components/pickupRenew/PledgePickupLoanTable';
import { actions } from 'redux/reducers/basket';
import { actions as customerActions } from 'redux/reducers/customer';
import EmptyBasket from './emptyBasket';
import CustomerSearchPopup from 'components/common/CustomerSearchPopup';
import AuthInfoPopUp from './authInfoPopup';
import UserBalancePopup from './userBalancePopup';
import {
  CUSTOMER_PROFILE_REQUEST_IN_BACKGROUND,
  LITE_CUSTOMER_DOC_UPLOAD,
  UPDATE_TRAVEL_MONEY_GUEST,
} from 'action_creators/customer';
import { validateFXTransaction } from 'services/travelMoney';
import ControlledAlert from 'components/common/ControlledAlert';

const StyledBasketWrapper = styled(Row)`
  border-radius: 5px;
  margin: 20px;
  color: var(--black);
  display: grid;
`;

const StyledGridRow = styled(Row)`
  padding: 0 15px;
  height: calc(100vh - 598px);
`;

const StyledButtonsRow = styled(Row)`
  padding: 10px 30px;
  position: relative;
  bottom: 40px;
  width: 100%;
`;

const StyledGridCol = styled(Col)`
  padding: 0px 0 10px 0;
  overflow-y: auto;
  height: calc(100vh - 620px);
  margin-top: 10px;
`;

const PledgeButtonsCol = styled(Col)`
  display: flex;
  align-items: flex-end;
  padding: 10px 0;
`;

const StyledEmptBasket = styled.div`
  text-align: center;
`;

const StyledFxInfoCol = styled(Col)`
  padding: 10px 0;
  display: flex;
  align-items: end;
  justify-content: end;
`;

const renderPledgeLoanContainer = (item: any, basketId: any, userId: any) => {
  const allPledgeItems = item?.pledge?.request?.items;
  const loanAmount = calculateTotalSumByKey(allPledgeItems, 'loanAmount');
  const weight = calculateTotalWeight(allPledgeItems);

  return (
    <PledgeLoanContainer
      pledgeItemRows={item}
      basketId={basketId}
      userId={userId}
      loanAmount={loanAmount}
      totalWeight={weight}
    />
  );
};

const TabHeader = ({ title, amount, onTabClick, className, bgcolor }: any) => {
  return (
    <>
      <div
        onClick={onTabClick}
        className={className}
        style={{ background: `var(--${bgcolor})` }}
      >
        <div>{title}</div>
        <div>{currencyFormat(amount, true)}</div>
      </div>
    </>
  );
};

/**
 * Render widgets like Pledge, retail etc as per dynamic tab key
 */
const renderPanes = (key: any, item: any, isMergedLoading: boolean) => {
  let component = null;

  switch (key) {
    case `${BASKET_SERVICE_TYPE.PLEDGE}`:
      component = <BasketPledge {...item} />;
      break;
    case `${BASKET_SERVICE_TYPE.PURCHASE}`:
      component = <BasketPurchase {...item} />;
      break;
    case `${BASKET_SERVICE_TYPE.RETAIL}`:
      component = <BasketRetail {...item} />;
      break;
    case `${BASKET_SERVICE_TYPE.FX}`:
      component = <BasketFx {...item} />;
      break;
    case `${BASKET_SERVICE_TYPE.PICKUPRENEW}`:
      component = <BasketPickup {...item} />;
      break;
    case `${BASKET_SERVICE_TYPE.CHEQUECASHING}`:
      component = <BasketChequeCashing {...item} />;
      break;
    case `${BASKET_SERVICE_TYPE.WU}`:
      component = <BasketWU {...item} />;
      break;
    case `${BASKET_SERVICE_TYPE.PURCHASERETURN}`:
      component = <BasketPurchaseReturn {...item} />;
      break;
    case `${BASKET_SERVICE_TYPE.SURPLUS}`:
      component = <BasketSurplus {...item} />;
      break;
  }
  return (
    <div key={key}>
      <Spin tip="Refreshing..." spinning={isMergedLoading}>
        {component}
      </Spin>
    </div>
  );
};

const Basket = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [panes, setPanes] = useState<any>([]);
  const [parentKey, setParentActiveKey] = useState('');
  const [childKey, setChildActiveKey] = useState('1');
  const [isCustomerSearchVisible, setCustomerSearchVisible] =
    useState<boolean>(false);
  const [isProceeding, setIsProceeding] = useState<boolean>(false);
  const [guestType, setGuestType] = useState<string>('');
  const [isCustomerAlertPopupVisible, setIsCustomerAlertPopupVisible] =
    useState<boolean>(false);
  const [isProceedToCheckoutDisabled, setIsProceedToCheckoutDisabled] =
    useState<boolean>(false);
  const [isConsumerDutyAlertVisible, setIsConsumerDutyAlertVisible] =
    useState<boolean>(false);
  const [interestRateCounter, setInterestRateCounter] = useState<number>(0);

  const {
    customer: {
      customer,
      liteCustomer,
      isStoreEnquiryMode,
      isPhoneEnquiryMode,
      isLiteCustomerLoading,
      isLiteDocUploaded,
      isGdprAccepted,
      isCustomerCompleteProfileLoaded,
      isVATDetailsAvailable,
      customertPastTransactionAmount,
      customerStatusColor,
    },
    basket: {
      basket,
      mergedPayload,
      isMergedLoading,
      isLoading,
      removePledge,
      deletedTab,
      isSplitLoading,
      currentActiveTab,
      validateBasketServicesPayload,
      isServicesValidated,
      currentChildActiveTab,
      isMergeLocking,
      isBasketMerging,
      currenciesAvailable,
      //consumerDuty States
      consumerDutyBlockedServices,
      isConsumerDutyActionsValidated,
      consumerDutyValidationError,
    },
    user: { user },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
      basket: state.basket,
      user: state.user,
    };
  });

  useEffect(() => {
    dispatch({ type: GET_BASKET });

    return () => {
      dispatch(actions.setCurrentChildActiveTab(1));
      dispatch(actions.setCurrentActiveTab(''));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //if cheque is not valid, disabling Proceed to checkout button
    const isChequeValid = validateChequeCashing();
    setIsProceedToCheckoutDisabled(!isChequeValid);
  }, [basket]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (consumerDutyValidationError.error) setIsProceeding(false);

    if (isConsumerDutyActionsValidated) {
      if (consumerDutyBlockedServices.length > 0) {
        setIsProceeding(false);
        setIsConsumerDutyAlertVisible(true);
      } else
        dispatch({
          type: VALIDATE_ALL_SERVICES_BASKET,
        });
    }
  }, [isConsumerDutyActionsValidated, consumerDutyValidationError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (customer?.customerId) {
      if (!isCustomerCompleteProfileLoaded)
        dispatch({
          type: CUSTOMER_PROFILE_REQUEST_IN_BACKGROUND,
          payload: { customerId: customer?.customerId },
        });

      //checking if the basket contains Travel Money item
      const isFXInBasket = basket?.basketServices?.find(
        (x: any) => x.serviceType === BASKET_SERVICE_TYPE.FX
      );

      isFXInBasket && getCustomerFXSummary();
    }
  }, [customer?.customerId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (basket?.basketServices) {
      const basketServices = basket?.basketServices?.filter(
        (x: any) => x.serviceStatus !== BASKET_STATUS.EXPIRED
      ); // expired services not to be shown
      const groupedBySortIndex = groupBy(basketServices, 'sortIndexParent');

      const basketTabArray = Object.keys(groupedBySortIndex).map(
        (sortIndex: string) => {
          return {
            sortIndex: sortIndex,
            data: groupedBySortIndex[sortIndex],
            serviceTypeId: `${BASKET_TABS_ORDER[sortIndex as keyof object]}`,
          };
        }
      );

      resetChildTab(basketTabArray);
      setPanes(basketTabArray);

      if (currentActiveTab) {
        const isActiveAvailable = basketTabArray.find(
          (services: any) => services.serviceTypeId === currentActiveTab
        );
        if (isActiveAvailable) setParentActiveKey(currentActiveTab);
        else if (basketTabArray.length > 0)
          setParentActiveKey(basketTabArray[0].serviceTypeId);
      } else if (basketTabArray.length > 0) {
        setParentActiveKey(basketTabArray[0].serviceTypeId);
      }
    }
  }, [basket, currentActiveTab, deletedTab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (parentKey) dispatch(actions.setCurrentActiveTab(parentKey));
  }, [parentKey]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (removePledge) {
      setChildActiveKey('1');
    }
  }, [removePledge]);

  useEffect(() => {
    if (
      validateBasketServicesPayload?.services &&
      validateBasketServicesPayload?.services.length > 0 &&
      !currenciesAvailable.isCurrenciesNotAvailable
    ) {
      const isValidAuthToken = validateBasketServicesPayload?.services?.find(
        (er: any) => er?.authorizationToken !== null
      );
      if (isValidAuthToken && isServicesValidated) {
        //TODO - to check user till balance for GBP
        setIsProceeding(false);
        dispatch(actions.clearIsServicesValidate({}));
        navigate(`/${ROUTE_CONFIG.CHECKOUT}`);
      }
    } else if (
      validateBasketServicesPayload?.services?.length === 0 &&
      isServicesValidated
    ) {
      setIsProceeding(false);
      dispatch(actions.clearIsServicesValidate({}));
      navigate(`/${ROUTE_CONFIG.CHECKOUT}`);
    }
  }, [
    validateBasketServicesPayload,
    isServicesValidated,
    currenciesAvailable.isCurrenciesNotAvailable,
    navigate,
    dispatch,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      (!isLiteCustomerLoading && isCustomerSearchVisible) ||
      (!isLiteCustomerLoading && isLiteDocUploaded)
    ) {
      if (customer?.customerId || !isEmpty(liteCustomer?.customer)) {
        setGuestType('');
        setCustomerSearchVisible(false);
      }
    }
  }, [isLiteCustomerLoading, isLiteDocUploaded]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomerFXSummary = async () => {
    try {
      const response = await validateFXTransaction(customer);
      if (response?.data)
        dispatch(
          customerActions.setCustomertPastTranactionAmount(
            response?.data?.totalAmount || 0
          )
        );
    } catch (e: any) {
      //not to throw error in case of masked postcode
      if (!startsWith(customer?.postcode, 'XX'))
        notification.error({ message: ERROR_MESSAGE.FX_SUMMARY });
    }
  };

  const onCancelManagerInfoClick = () => {
    setIsProceeding(false);
    dispatch(actions.clearIsServicesValidate({}));
    dispatch(actions.clearValidateCheckoutServices({}));
  };
  const handleParentTabChange = (key: any) => {
    setParentActiveKey(key);
    dispatch(actions.setCurrentActiveTab(key));
    dispatch(actions.setCurrentChildActiveTab(1));
  };

  const handleChildTabChange = (key: any) => {
    setChildActiveKey(key);
    dispatch(actions.setCurrentChildActiveTab(key));
  };

  const onProceedClick = () => {
    const isKYCCustomer =
      customer?.customerId &&
      customer?.town?.trim() &&
      customer?.property?.trim();

    let isKYCCustomerRequired = false;
    let isVATDetailsRequired = false;

    //Case 1 - Pledge/Purchase/3PC - requires a KYC Customer
    if (!isKYCCustomer) isKYCCustomerRequired = validateServices();
    if (isKYCCustomerRequired) return;

    //Case 2 - Travel Money - Will be checked always for all type of Customers (Guest/Lite/KYC)
    const isTMUpdateRequired = validatedTravelMoney();
    if (isTMUpdateRequired) return;

    //Case 3 VAT Customer Registration - will be checked for lite customer and guest
    if (!isKYCCustomer) isVATDetailsRequired = validateRetail();
    if (isVATDetailsRequired) return;

    //MP:5414 - zero customer issue/ no customer details
    if (customer?.customerId && !basket?.customerId) {
      dispatch({
        type: MERGE_GUEST_BASKET_REQUEST,
      });
      return;
    }
    if (customer?.customerId) {
      const params = {
        basketId: basket?.basketId,
        customer: customer,
      };
      dispatch({
        type: UPDATE_CUSTOMER_DETAILS,
        payload: { params },
      });
    }
    setIsProceeding(true);

    const checkInterestRate = validateInterestRate(); // check for 0 interest rate

    if (checkInterestRate) {
      if (interestRateCounter < 2) {
        setInterestRateCounter(interestRateCounter + 1);
        dispatch({
          type: LOAN_AMOUNT_CALCULATOR_ON_BASKET,
        });
        return;
      } else {
        notification.error({
          message:
            'Unable to proceed further as the interest rate for pledge is 0%',
          duration: 3,
        });
        return;
      }
    }

    if (
      customerStatusColor === PROFILE_STATUS.BLACK ||
      customerStatusColor === PROFILE_STATUS.AMBER
    )
      dispatch({ type: VALIDATE_CONSUMER_DUTY });
    else
      dispatch({
        type: VALIDATE_ALL_SERVICES_BASKET,
      });
  };

  //TODO will optimize this on tab level, currently its happening on tab div level
  /** this code is using for merge the pledge items when already selected rows in pledge table */
  const onChildTabClicked = (item: any, isNew = false) => {
    if (mergedPayload?.itemIds?.length > 0) {
      const payload = {
        basketId: basket?.basketId,
        sourceServiceId: mergedPayload.sourceServiceId,
        itemIds: mergedPayload.itemIds,
        userId: user?.userId,
        targetServiceId:
          item !== null &&
          mergedPayload?.sourceServiceType !== item?.serviceType &&
          isNew
            ? null
            : item?.serviceId,
      };

      const type =
        item !== null && mergedPayload?.sourceServiceType !== item?.serviceType
          ? MOVE_BASKET_PLEDGE_PURCHASE_ITEMS
          : isNew
          ? MOVE_BASKET_PLEDGE_ITEMS
          : MERGE_BASKET_PLEDGE_ITEMS;

      dispatch({
        type: type,
        payload: { params: payload },
      });
    }
  };

  const resetChildTab = (groupedItems: any) => {
    groupedItems.forEach((services: any) => {
      let tab = '';

      const pane = services?.data;
      for (let [key, value] of Object.entries(BASKET_TABS)) {
        if (key === services.serviceTypeId) {
          tab = value;
        }
      }
      if (ENABLE_CHILD_TABS.includes(tab) && pane.length >= 1) {
        if (deletedTab) {
          setChildActiveKey('1');
          dispatch(actions.setCurrentChildActiveTab(1));
          dispatch(actions.deletedTab(''));
        } else if (currentChildActiveTab !== '')
          setChildActiveKey(currentChildActiveTab);
        else setChildActiveKey('1');
      }
    });
  };

  const onEdit = (targetKey: any, action: any, tabItems: any) => {
    if (action === 'add') {
      if (mergedPayload?.sourceServiceType === tabItems[0]?.serviceType)
        onChildTabClicked(null, true);
      else if (mergedPayload?.sourceServiceType !== tabItems[0]?.serviceType)
        onChildTabClicked(tabItems[0], true);

      dispatch(actions.setResetChildTabOnSplitAdd(true));
    }
  };

  const renderParentTabs = () => {
    const parentTabItems = panes.map((services: any) => {
      let tabcolor: any =
        BASKET_TABS_COLORS[services.serviceTypeId as keyof object];
      let tab: any = BASKET_TABS[services.serviceTypeId as keyof object];

      const {
        totalItemsCount,
        totalAmounts,
        innerTabsCount,
        totalAmountPerTabs,
      } = allLoanAmounts(services.data, tab);

      const totalLength = ENABLE_CHILD_TABS.includes(tab)
        ? innerTabsCount
        : totalItemsCount;
      const activatingInactive =
        mergedPayload?.itemIds?.length > 0 &&
        !services.serviceTypeId.includes(mergedPayload?.sourceServiceType)
          ? 'inactive-child-tabs'
          : '';

      return {
        label: (
          <TabHeader
            title={`${tab} (${totalLength})`}
            amount={totalAmounts < 0 ? Math.abs(totalAmounts) : totalAmounts}
            bgcolor={tabcolor}
            // file deepcode ignore GlobalReplacementRegex
            className={`${tab
              ?.replace(' ', '-')
              ?.toLowerCase()} ${activatingInactive} tab-header`}
          />
        ),
        key: services.serviceTypeId,
        children: renderChildTabs(
          services.data,
          tab,
          services.serviceTypeId,
          totalAmountPerTabs
        ),
        disabled: isMergedLoading,
        closable: false,
      };
    });

    return (
      <Tabs
        onChange={(key) => !isMergeLocking && handleParentTabChange(key)}
        type="card"
        activeKey={parentKey}
        className={`parent-tabs ${isMergeLocking ? 'disabled' : ''}`}
        tabBarGutter={10}
        items={parentTabItems}
      />
    );
  };

  const activatingInactive =
    mergedPayload?.itemIds?.length > 0 ? 'inactive-child-tabs' : '';
  /**
   *
   * @param tabItems list of items by tab
   * @param tab name of tab like (Pledge, Retail etc)
   * @param tabKey number of tab like 1, 2, 3
   * @param totalLoanAmountPerTabs amounts displaying in tab header per tab
   * @returns
   */
  const renderChildTabs = (
    tabItems: any,
    tab: string,
    tabKey: any,
    totalLoanAmountPerTabs: any
  ) => {
    const { basketId } = basket;
    let pledgeCount = 0;
    //for highlighting inactive child tabs when active tab items are selected

    //Creating child tabs
    if (ENABLE_CHILD_TABS.includes(tab)) {
      // pledgeCount dispaly number in child header per tab
      const childTabItems = tabItems.map((item: any, index: any) => {
        pledgeCount++;
        const itemCount =
          item?.serviceType === BASKET_SERVICE_TYPE.PLEDGE
            ? item?.pledge?.request?.items?.length
            : item?.purchase?.request?.items?.length;

        return {
          label: (
            <TabHeader
              title={`${tab} ${pledgeCount} (${itemCount})`}
              amount={totalLoanAmountPerTabs[index]}
              onTabClick={() => !isMergeLocking && onChildTabClicked(item)}
            />
          ),
          disabled: isMergeLocking,
          key: pledgeCount,
          closable: false,
          children: (
            <>
              <StyledGridRow>
                <Col span={24}>
                  <Row>
                    <StyledGridCol span={24}>
                      {renderPanes(tabKey, item, isMergedLoading)}
                    </StyledGridCol>
                  </Row>
                </Col>
              </StyledGridRow>
              <StyledButtonsRow>
                <PledgeButtonsCol span={11}>
                  <ContainerButtons
                    keyIndex={pledgeCount}
                    item={item}
                    basketId={basket?.basketId}
                    userId={user?.userId}
                    keyName={tab}
                    isSplitLoading={isSplitLoading}
                  />
                </PledgeButtonsCol>
                <Col span={13} style={{ padding: '10px 0' }}>
                  {item.serviceType === BASKET_SERVICE_TYPE.PLEDGE ? (
                    renderPledgeLoanContainer(
                      item,
                      basket?.basketId,
                      user?.userId
                    )
                  ) : (
                    <PurchaseTableContainer
                      purchaseItemRows={item}
                      basketId={basket?.basketId}
                      userId={user?.userId}
                    />
                  )}
                </Col>
              </StyledButtonsRow>
            </>
          ),
        };
      });

      return (
        <>
          <div className="multi-tab-container">
            <Tabs
              tabPosition="top"
              onChange={handleChildTabChange}
              activeKey={childKey}
              className={`child-tabs ${activatingInactive}`}
              tabBarGutter={10}
              type="editable-card"
              onEdit={(targetKey, action) =>
                !isMergeLocking && onEdit(targetKey, action, tabItems)
              }
              items={childTabItems}
            />
          </div>
        </>
      );
    } else if (ENABLE_NOT_INCLUDE_ITEMS_TABLE.includes(tab)) {
      return (
        <>
          <div key={tabKey} className="single-tab-container">
            <>
              <StyledGridRow>
                <Col span={24}>
                  <Row>
                    <StyledGridCol
                      span={24}
                      style={{ height: 'calc(100vh - 485px)' }}
                    >
                      {renderPanes(
                        tabKey,
                        { items: tabItems },
                        isMergedLoading
                      )}
                    </StyledGridCol>
                  </Row>
                </Col>
              </StyledGridRow>
              <StyledButtonsRow>
                <PledgeButtonsCol span={10}>
                  <ContainerButtons
                    basketId={basketId}
                    userId={user?.userId}
                    keyName={tab}
                    item={tabItems[0]}
                    serviceIds={mergedPayload?.serviceIds}
                    itemIds={mergedPayload?.itemIds}
                  />
                </PledgeButtonsCol>
                {tabKey.includes(BASKET_SERVICE_TYPE.FX) ? (
                  <StyledFxInfoCol span={14}>
                    <FxInfo totalFX={basket?.totalFX} />
                  </StyledFxInfoCol>
                ) : null}
                {tabKey.includes(BASKET_SERVICE_TYPE.PICKUPRENEW) ? (
                  <Col span={14} className="pickup-loan-table-basket">
                    <PledgePickupLoanTable
                      allAgreements={getPledgeAgreementItemsArray(tabItems)}
                    />
                  </Col>
                ) : null}
              </StyledButtonsRow>
            </>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div key={tabKey} className="single-tab-container">
            {tabItems.map((item: any, index: any) => (
              <div key={index}>
                <StyledGridRow>
                  <Col span={24}>
                    <Row>
                      <StyledGridCol
                        span={24}
                        style={{ height: 'calc(100vh - 485px)' }}
                      >
                        {renderPanes(tabKey, item, isMergedLoading)}
                      </StyledGridCol>
                    </Row>
                  </Col>
                </StyledGridRow>
                <StyledButtonsRow className="basket-footer-row">
                  <PledgeButtonsCol span={10}>
                    <ContainerButtons
                      keyIndex={pledgeCount}
                      item={item}
                      basketId={basketId}
                      userId={user?.userId}
                      keyName={tab}
                      itemIds={mergedPayload?.itemIds}
                    />
                  </PledgeButtonsCol>
                </StyledButtonsRow>
              </div>
            ))}
          </div>
        </>
      );
    }
  };

  const validateServices = () => {
    const isLiteCustomer = customer?.customerId;
    const isServiceExist = basket?.basketServices?.some(
      (x: any) =>
        x.serviceType === BASKET_SERVICE_TYPE.PLEDGE ||
        x.serviceType === BASKET_SERVICE_TYPE.PURCHASE ||
        x.serviceType === BASKET_SERVICE_TYPE.CHEQUECASHING
    );

    //customer search  or KYC alert popup (for lite customer)
    if (isServiceExist) {
      if (isLiteCustomer) setIsCustomerAlertPopupVisible(true);
      else setCustomerSearchVisible(true);
      return true;
    }
    return false;
  };

  const validateRetail = () => {
    if (isVATDetailsAvailable) return false;

    const isRetailExist = basket?.basketServices?.some(
      (x: any) => x.serviceType === BASKET_SERVICE_TYPE.RETAIL
    );

    if (isRetailExist) {
      const isCalculatedAmtExceed = isAmountExceed(basket);

      if (isCalculatedAmtExceed) {
        setGuestType(GUEST_TYPES.VAT);
        setCustomerSearchVisible(true);
        dispatch(customerActions.setRedirectToOtherPage(true));
        return true;
      }
    }
    return false;
  };

  const validatedTravelMoney = () => {
    //if the document is uploded validation isn't required
    if (isLiteDocUploaded) return false;

    const fxServices = basket?.basketServices?.filter(
      (x: any) => x.serviceType === BASKET_SERVICE_TYPE.FX
    );

    if (fxServices?.length === 0) return false;

    const basketFXAmount = fxServices?.reduce((amount: number, item: any) => {
      return item?.fx?.request?.transactionType === FX_TRANSACTION_TYPE.BUY
        ? amount + item?.fx?.request?.fromAmount
        : amount + item?.fx?.request?.toAmount;
    }, 0);

    const totalFXAmount = customertPastTransactionAmount + basketFXAmount;

    const isAmountAboveTMLimit = totalFXAmount > EXCEED_AMOUNTS.FX;
    const isAmountBelowTMLimit =
      !customer?.customerId &&
      isEmpty(liteCustomer?.customer) &&
      !isAmountAboveTMLimit;

    //Case 1 -  TM below 1000.... (Only for guest Customer) -> lite customer registration
    if (isAmountBelowTMLimit) {
      setGuestType(GUEST_TYPES.FX);
      setCustomerSearchVisible(true);
      return true;
    }

    //Case 2 -  TM above 1000.... -> lite customer registration with id
    if (isAmountAboveTMLimit) {
      setGuestType(GUEST_TYPES.FX);
      setCustomerSearchVisible(true);
      dispatch(customerActions.setRedirectToOtherPage(true));

      if (customer?.customerId || !isEmpty(liteCustomer?.customer))
        dispatch(customerActions.setIsLiteDocRequired(true));
      return true;
    }

    return false;
  };

  const validateChequeCashing = () => {
    let isChequeValid = true;
    const chequeCashingServices = basket?.basketServices?.filter(
      (x: any) => x.serviceType === BASKET_SERVICE_TYPE.CHEQUECASHING
    );

    if (chequeCashingServices?.length > 0) {
      isChequeValid = chequeCashingServices.every((service: any) => {
        const { retained, payout } = service?.thirdPartyCheque?.request?.item;
        if (retained === 0 && payout === 0) return false;
        return true;
      });
    }

    return isChequeValid;
  };

  const onSaveCustomerRegistration = () => {
    if (guestType === GUEST_TYPES.FX) {
      if (!customer?.customerId)
        dispatch({
          type: UPDATE_TRAVEL_MONEY_GUEST,
        });
      else
        dispatch({
          type: LITE_CUSTOMER_DOC_UPLOAD,
        });
    } else {
      setCustomerSearchVisible(false);
      dispatch(actions.clearValidateCheckoutServices({}));
      dispatch({
        type: VALIDATE_ALL_SERVICES_BASKET,
      });
    }
  };

  const onCustomerAlertButtonClick = () => {
    dispatch(
      customerActions.setCustomerFromHeader({
        isCustFromHeader: true,
        resumeJourneyUrl: location.pathname,
      })
    );

    navigate(
      `/${ROUTE_CONFIG.CUSTOMER}/${customer?.customerId}/${ROUTE_CONFIG.EDIT}`
    );
  };

  const renderCustomerAlertContent = () => (
    <div className="customer-alert-content">
      This customer is not a <b>KYC compliant</b>.
      <br />
      {CUSTOMER_ALERT_POPUP.LINE_2}
    </div>
  );

  const onConsumerDutyAlertBtnClick = (): void => {
    setIsConsumerDutyAlertVisible(false);
    dispatch(actions.clearIsServicesValidate({}));
  };

  const validateInterestRate = (): boolean => {
    const filterBasketServices = basket?.basketServices
      ?.filter((x: any) => x.serviceType === BASKET_SERVICE_TYPE.PLEDGE)
      .find((y: any) => y.pledge.request.interestRate === 0);

    if (!isEmpty(filterBasketServices)) {
      setIsProceeding(false);
      return true;
    }
    return false;
  };

  return (
    <>
      <CustomerHeader
        title="Basket"
        gdprAccepted={isGdprAccepted}
        buttonsOption={true}
      />
      {isEmpty(basket?.basketServices) && (
        <StyledEmptBasket className="empty-basket control-box">
          {isLoading ? <Spin /> : <EmptyBasket />}
        </StyledEmptBasket>
      )}
      {!isEmpty(basket?.basketServices) &&
        basket?.basketServices.length > 0 && (
          <StyledBasketWrapper>
            <Row>
              <Col span={24}>
                <div className="basket-container">{renderParentTabs()}</div>
              </Col>
            </Row>
            <FooterButtons
              enablePayContainer
              payLabel={
                basket?.totalAmount > 0
                  ? FOOTER_BUTTONS_AND_TEXT.TAKE_FROM_CUSTOMER
                  : FOOTER_BUTTONS_AND_TEXT.PAY_TO_CUSTOMER
              }
              proceedButtonText={FOOTER_BUTTONS_AND_TEXT.PROCEED_TO_CHECKOUT}
              totalAmount={Math.abs(basket.totalAmount)}
              isDisabled={
                isStoreEnquiryMode ||
                isPhoneEnquiryMode ||
                isMergedLoading ||
                isProceedToCheckoutDisabled
              }
              onProceedClick={onProceedClick}
              isLoading={isProceeding || isBasketMerging}
              helpSection="BASKET"
            />
            {isCustomerSearchVisible && (
              <CustomerSearchPopup
                visible={isCustomerSearchVisible}
                guestType={guestType}
                onOKClick={onSaveCustomerRegistration}
                onCancel={() => setCustomerSearchVisible(false)}
                isCreateInSearch={guestType === GUEST_TYPES.FX ? true : false}
              />
            )}
          </StyledBasketWrapper>
        )}
      <AuthInfoPopUp onCancelClick={onCancelManagerInfoClick} />
      <UserBalancePopup
        isProceeding={isProceeding}
        setIsProceeding={() => setIsProceeding(false)}
        isUserBalanceAlert={
          isServicesValidated && currenciesAvailable.isCurrenciesNotAvailable
        }
      />

      {isCustomerAlertPopupVisible && (
        <ControlledAlert
          visible={isCustomerAlertPopupVisible}
          isCancelBtn={false}
          alertMessage={CUSTOMER_ALERT_POPUP.HEADER}
          message={renderCustomerAlertContent()}
          onClick={onCustomerAlertButtonClick}
          onCancel={() => undefined}
          yesButtonText={CUSTOMER_ALERT_POPUP.BUTTON_LABEL}
        />
      )}

      {isConsumerDutyAlertVisible && (
        <ControlledAlert
          width={500}
          visible={isConsumerDutyAlertVisible}
          maskClosable={false}
          alertMessage={CONSUMER_DUTY_ALERT.ALERT}
          contentMessage={
            customerStatusColor === 'black'
              ? CONSUMER_DUTY_ALERT.CONTENT_SST
              : CONSUMER_DUTY_ALERT.CONTENT_CST
          }
          contentMessageClassName="cd-alert-content-msg"
          message={`${consumerDutyBlockedServices.join(', ')}`}
          secondaryMessage={
            customerStatusColor === 'black'
              ? CONSUMER_DUTY_ALERT.SECONDARY_MSG_SST
              : CONSUMER_DUTY_ALERT.SECONDARY_MSG_CST
          }
          onClick={onConsumerDutyAlertBtnClick}
          //Success button props
          itemProp=""
          yesButtonText={CONSUMER_DUTY_ALERT.SUCCESS_BTN}
          isCancelBtn={false}
        />
      )}
    </>
  );
};

export default Basket;
