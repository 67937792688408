import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const StyledDiv = styled.div`
  padding: 24px;
`;

const CustomerSearchLoader = () => {
  return (
    <StyledDiv>
      <Spin />
    </StyledDiv>
  );
};

export default CustomerSearchLoader;
