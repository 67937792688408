import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Avatar, Row } from 'antd';

import { getLowercaseWithoutSpace, getPurchaseStatus } from 'utils/util';

interface PurchaseIconDescriptionProps {
  purchaseSummary: Array<any>;
}

const StyledIconRow = styled(Row)`
  padding: 20px 0;
`;

const StyledIconWrapper = styled.div`
  padding-right: 15px;

  & .ant-avatar {
    margin: 0 8px;
  }
`;

const IconDescription = ({
  purchaseSummary = [],
}: PurchaseIconDescriptionProps) => {
  const [icons, setIcons] = useState<Array<string>>([]);

  useEffect(() => {
    getPurchaseStatuses();
  }, [purchaseSummary]); // eslint-disable-line react-hooks/exhaustive-deps

  const getPurchaseStatuses = () => {
    let purchaseStauses: any = [];

    purchaseSummary.forEach((purchase: any) => {
      const status = getPurchaseStatus(
        purchase?.isQuarantine,
        purchase?.isReturned
      );
      purchaseStauses.push(status);
    });

    const uniquePurchaseStatuses = purchaseStauses.filter(
      (status: string, index: number, arr: any) => index === arr.indexOf(status)
    );

    setIcons(uniquePurchaseStatuses);
  };

  const loadSVG = (icon: any) => {
    try {
      const loadedImg = require(`../../../../assets/images/icons/${
        getLowercaseWithoutSpace(icon) + '.svg'
      }`);

      return loadedImg;
    } catch (e: any) {
      return undefined;
    }
  };

  const renderIcons = () => {
    return icons.map((icon: string, index: number) => {
      const image = loadSVG(icon);
      return (
        <StyledIconWrapper key={index}>
          <Avatar
            className="mx-smaller-icon find-item-popup-grid"
            src={image}
            key={index}
          />
          {icon}
        </StyledIconWrapper>
      );
    });
  };

  return <StyledIconRow>{renderIcons()}</StyledIconRow>;
};

export default IconDescription;
