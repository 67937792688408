import { call, put, takeEvery } from 'redux-saga/effects';

import {
  GET_VALUATION_ITEM_DETAILS,
  POST_VALUATION_ACTIONS,
  GET_NOTIFICATION_ITEM_DETAILS,
} from 'action_creators/valuationCenter';

import {
  postViewValuationItem,
  postValuationItemAction,
  postNotificationItemDetails,
} from 'services/valuationCenter';

import { actions } from 'redux/reducers/valuationCenter';
import { VALUATION_VIEW_TYPE } from 'components/valuationCenter/constants';

// const genericError = 'Something went wrong. Please try again later';

export function* getValuationItemDetails(action) {
  const {
    payload: { params, viewType },
  } = action;

  try {
    yield put(actions.setIsLoading(true));

    const response = yield call(postViewValuationItem, params);
    if (response?.data) {
      yield put(actions.setIsLoading(false));

      if (viewType === VALUATION_VIEW_TYPE.VIEW_ITEM) {
        yield put(actions.setItemDetails(response?.data));
        yield put(actions.setValuationViewMode(true));
      } else if (viewType === VALUATION_VIEW_TYPE.VIEW_ITEM_IN_PLEDGE) {
        yield put(actions.setItemDetails(response?.data));
        yield put(actions.setValuationItemToPledge(true));
      } else {
        yield put(actions.setItemDetails({}));
        yield put(actions.setValuationViewMode(false));
      }
    }
  } catch (e) {
    yield put(actions.setIsLoading(false));
  }
}

export function* getNotificationItemDetails(action) {
  const {
    payload: { loanNumber, viewType },
  } = action;

  try {
    yield put(actions.setIsLoading(true));

    const response = yield call(postNotificationItemDetails, loanNumber);
    if (response?.data) {
      yield put(actions.setIsLoading(false));
      if (viewType === VALUATION_VIEW_TYPE.VIEW_ITEM_IN_PLEDGE) {
        yield put(actions.setValuationItemDetails(response?.data));
        yield put(actions.setValuationItemToPledge(true));
      } else {
        yield put(actions.setValuationItemDetails({}));
        yield put(actions.setValuationItemToPledge(false));
      }
    }
  } catch (e) {
    yield put(actions.setIsLoading(false));
  }
}

export function* postValuationActions(action) {
  const {
    payload: { params },
  } = action;

  try {
    yield put(actions.setIsActionLoading(true));

    const response = yield call(postValuationItemAction, params);
    if (response?.data) {
      yield put(actions.setIsActionLoading(false));
      yield put(actions.setValuationViewMode(false));
    }
  } catch (e) {
    yield put(actions.setIsActionLoading(false));
  }
}

export function* watchGetValuationItemDetails() {
  yield takeEvery(GET_VALUATION_ITEM_DETAILS, getValuationItemDetails);
}
export function* watchGetNotificationItemDetails() {
  yield takeEvery(GET_NOTIFICATION_ITEM_DETAILS, getNotificationItemDetails);
}

export function* watchPostValuationActions() {
  yield takeEvery(POST_VALUATION_ACTIONS, postValuationActions);
}
