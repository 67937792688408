import Cookies from 'js-cookie';

export const setMatrixToken = (token: string) => {
  Cookies.set('handttoken', token);
};

export const setRefreshToken = (token: string) => {
  Cookies.set('refreshtoken', token);
};

export const setPaymentRefreshToken = (token: string) => {
  Cookies.set('paymentRefreshToken', token);
};

export const setMatrixPaymentAccessToken = (token: string) => {
  Cookies.set('paymentAccessToken', token);
};

export const getMatrixToken = () => {
  return Cookies.get('handttoken');
};

export const removeMatrixToken = () => {
  Cookies.remove('handttoken', {
    path: '',
  });
  Cookies.remove('paymentAccessToken', {
    path: '',
  });
};

export const setMatrixUserId = (userId: string) => {
  Cookies.set('handtuser', userId);
};

export const getMatrixUserId = () => {
  return Cookies.get('handtuser');
};

export const setMatrixStoreId = (store: any) => {
  Cookies.set('handtstore', store.storeId);
};

export const getMatrixStoreId = () => {
  return Cookies.get('handtstore');
};

export const getRefreshToken = () => {
  return Cookies.get('refreshtoken');
};

export const getPaymentRefreshToken = () => {
  return Cookies.get('paymentRefreshToken');
};

export const getPaymentAccessToken = () => {
  return Cookies.get('paymentAccessToken');
};

export const removeAllCookies = () => {
  Cookies.remove('paymentRefreshToken');
  Cookies.remove('paymentAccessToken');
  Cookies.remove('refreshtoken');
  Cookies.remove('handttoken');
  Cookies.remove('handtstore');
  Cookies.remove('handtuser');
};
