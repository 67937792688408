import React, { useEffect, useState } from 'react';
import './index.less';
import { Row, Col } from 'antd';
import {
  RECIEPT_NO_LABEL,
  SOLD_AT_LABEL,
  CUSTOMER_LABEL,
  SALE_DATE_AND_TIME_LABEL,
  DATE_FORMAT,
  SOLD_BY_LABEL,
  GUEST_LABEL,
} from 'components/retail/constants';

import { getTimestamp } from 'utils/util';
import format from 'date-fns/format';
import { getCustomer } from 'services/customer';

const CustomerInfoHeader = ({
  userName,
  storeName,
  customerId,
  transactionId,
  transactionDate,
  isSoldDateExceed = false,
}: any) => {
  const [customerFullName, setCustomerFullName] = useState<string>(GUEST_LABEL);
  const customerDetails = async (customerID: number) => {
    const responseCustomer = await getCustomer(customerID);
    if (responseCustomer?.data) {
      setCustomerFullName(
        `${responseCustomer?.data?.firstNames} - ${responseCustomer?.data?.surname}`
      );
    }
  };
  useEffect(() => {
    customerDetails(customerId);
  }, [customerId]);

  return (
    <>
      <Row className="first-row">
        {transactionId ? (
          <Col span={8}>
            <label>{RECIEPT_NO_LABEL} </label>
            <span>{transactionId}</span>
          </Col>
        ) : null}
        {storeName ? (
          <Col span={8}>
            <label>{SOLD_AT_LABEL} </label>
            <span>{storeName}</span>
          </Col>
        ) : null}

        {customerFullName ? (
          <Col span={8}>
            <label>{CUSTOMER_LABEL} </label>
            <span>{customerFullName}</span>
          </Col>
        ) : null}
      </Row>
      <Row className="second-row">
        <Col span={8} style={{ color: isSoldDateExceed ? 'red' : 'green' }}>
          <label>{SALE_DATE_AND_TIME_LABEL} </label>
          <span>{format(getTimestamp(transactionDate), DATE_FORMAT)}</span>
        </Col>
        <Col span={8}>
          <label>{SOLD_BY_LABEL} </label>
          <span>{userName}</span>
        </Col>
      </Row>
    </>
  );
};

export default CustomerInfoHeader;
