import React, { useState } from 'react';

import VirtualGrid from 'components/common/virtualGrid';

import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';
import { useDispatch, useSelector } from 'react-redux';
import { currencyFormat, dateDisplayFormat, getPledgeIcons } from 'utils/util';

import PaidPaymentDetailsPopup from 'components/pickupRenew/PaidPaymentDetailsPopup';
import WaiveInterestPopup from 'components/consumerDuty/widgets/serviceManager/waiveInterestPopup';

import { GET_PAID_PLEDGE_DETAILS } from 'action_creators/pickupRenew';
import styled from 'styled-components';

import { actions as consumerDutyActions } from 'redux/reducers/consumerDuty';

import { TASK_OWNER_VALUE } from 'components/consumerDuty/constants';

const StyledHyperlink = styled.button`
  font-weight: var(--font-weight-500);
  color: var(--blue);
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const LoanExceptionTable = () => {
  const {
    consumerDuty: { selectedLoanIds },
    user: { lockedLoans },
  } = useSelector((state: any) => {
    return { consumerDuty: state.consumerDuty, user: state.user };
  });

  const dispatch = useDispatch();

  const [columns] = useState([
    {
      name: 'status',
      title: 'Status',
      type: 'icon',
      isMultipleIcons: true, // when isMultipleIcons = true , send an array of icon values
      isIconClickable: false,
      getCellValue: (row: any) => getPledgeIcons(row),
    },
    {
      name: 'agreement',
      title: 'Agreement',
      getCellValue: (row: any) => row?.agreementNumber,
    },
    {
      name: 'startDate',
      title: 'Start Date',
      getCellValue: (row: any) =>
        dateDisplayFormat(row?.startDate, FNS_DATE_FORMAT),
    },
    {
      name: 'dueDtate',
      title: 'Due Dtate',
      getCellValue: (row: any) =>
        dateDisplayFormat(row?.dueDate, FNS_DATE_FORMAT),
    },
    {
      name: 'location',
      title: 'Location',
      getCellValue: (row: any) => row?.location,
    },
    {
      name: 'loanAmount',
      title: 'Loan Amount',
      getCellValue: (row: any) => `${currencyFormat(row?.loanAmount, true)}`,
    },
    {
      name: 'dueToday',
      title: 'Due Today',
      getCellValue: (row: any) =>
        `${currencyFormat(row?.amountDueToday, true)}`,
    },
    {
      name: '6months',
      title: '6 months',
      getCellValue: (row: any) =>
        `${currencyFormat(row?.interest6Months, true)}`,
    },
    {
      name: 'days',
      title: 'Days',
      getCellValue: (row: any) => row?.days,
    },
    {
      name: 'paid',
      title: 'Paid',
      getCellValue: (row: any) => (
        <StyledHyperlink type="button" onClick={() => onPaidPaymentClick(row)}>
          {`${currencyFormat(row?.paidAmount, true)}`}
        </StyledHyperlink>
      ),
    },
    {
      name: 'waived',
      title: 'Waived',
      getCellValue: (row: any) => (
        <StyledHyperlink
          onClick={() => onWaviedInterestClick(row)}
        >{`${currencyFormat(row?.waived, true)}`}</StyledHyperlink>
      ),
    },
    {
      name: 'renewal',
      title: 'Renewal(s)',
      getCellValue: (row: any) => row?.numberOfRenewals,
    },
    {
      name: 'interest',
      title: 'Interest Paid to Date',
      getCellValue: (row: any) =>
        `${currencyFormat(row?.interestPaidTillDate, true)}`,
    },
    {
      name: 'overPayment',
      title: 'Over Payment (Loan + Months)',
      getCellValue: (row: any) => `${currencyFormat(row?.overPayment, true)}`,
    },
    {
      name: 'holdTill',
      title: 'Hold Till',
      getCellValue: (row: any) =>
        dateDisplayFormat(row?.holdTillDate, FNS_DATE_FORMAT),
    },
    {
      name: 'lastReset',
      title: 'Last Reset',
      getCellValue: (row: any) =>
        dateDisplayFormat(row?.lastResetDate, FNS_DATE_FORMAT),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'status', width: '7%' },
    { columnName: 'agreement', width: '6%' },
    { columnName: 'startDate', width: '6%' },
    { columnName: 'dueDtate', width: '6%' },
    { columnName: 'location', width: '8%' },
    {
      columnName: 'loanAmount',
      width: '5%',
      wordWrapEnabled: true,
      align: 'right',
    },
    { columnName: 'dueToday', width: '6%', align: 'right' },
    { columnName: '6months', width: '5%', align: 'right' },
    { columnName: 'days', width: '4%', align: 'center' },
    { columnName: 'paid', width: '4%', align: 'right' },
    { columnName: 'waived', width: '5%', align: 'right' },
    { columnName: 'renewal', width: '6%', align: 'center' },
    {
      columnName: 'interest',
      width: '6%',
      wordWrapEnabled: true,
      align: 'right',
    },
    {
      columnName: 'overPayment',
      width: '8%',
      wordWrapEnabled: true,
      align: 'right',
    },
    { columnName: 'holdTill', width: '6%', align: 'right' },
    { columnName: 'lastReset', width: '6%', align: 'right' },
  ]);

  const [formattedColumns] = useState(['status']);

  const [isPaidPaymentPopupVisible, setPaidPaymentPopupVisible] =
    useState<boolean>(false);
  const [isWaivedInterestPopupVisible, setIsWaivedInterestPopupVisible] =
    useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>({});

  const onPaidPaymentClick = (row: any) => {
    const params = {
      loanId: row?.loanId,
    };
    setPaidPaymentPopupVisible(true);
    dispatch({
      type: GET_PAID_PLEDGE_DETAILS,
      payload: { params },
    });
  };

  const onWaviedInterestClick = (row: any) => {
    setSelectedRow(row);
    setIsWaivedInterestPopupVisible(true);
  };

  const onSelectionChange = (ids: Array<number>) => {
    dispatch(consumerDutyActions.setSelectedLoanIds(ids));
  };

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        formattedColumns={formattedColumns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={lockedLoans || []}
        rowSelectionEnable={true}
        selection={selectedLoanIds}
        onSelectionChange={onSelectionChange}
      />

      {isPaidPaymentPopupVisible && (
        <PaidPaymentDetailsPopup
          visible={isPaidPaymentPopupVisible}
          setPaidPaymentPopupVisible={() => setPaidPaymentPopupVisible(false)}
        />
      )}

      {isWaivedInterestPopupVisible && (
        <WaiveInterestPopup
          open={isWaivedInterestPopupVisible}
          loanDetails={selectedRow}
          taskOwner={
            selectedRow?.isAtRisk
              ? TASK_OWNER_VALUE.AT_RISK
              : TASK_OWNER_VALUE.SSTEAM
          }
          setWaiveInterestPopupVisible={() =>
            setIsWaivedInterestPopupVisible(false)
          }
        />
      )}
    </>
  );
};

export default LoanExceptionTable;
