import React, { useState } from 'react';
import { Col, Modal, Row, Form, notification } from 'antd';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import InputDecimal from 'components/common/InputDecimal';
import Select from 'components/common/Select';
import { depositWithdrawLayawayFunds } from 'services/retail';
import { NOTIFICATION_TYPE } from 'globalConstants';

import {
  BUTTON_LABELS,
  FORM_LABELS,
  TRANSACTION_TYPES_OPTIONS,
  PAYMENT_TYPES_OPTIONS,
  AMOUNT_SYMBOL,
} from '../constants';

const StyledModal = styled(Modal)`
  & .ant-modal-header {
    background: var(--white);
    & .ant-modal-title {
      padding-bottom: 20px;
    }
  }
  & .ant-modal-body {
    background: var(--white);
    padding: 0;
  }
`;

interface AddWithdrawModalProps {
  customerId: number;
  isOpen: boolean;
  onCancel: () => void;
  onSave?: (id: any, payload: any) => void;
  accountShortfall: number;
}

const AddWithdrawModal = ({
  customerId,
  isOpen,
  onCancel,
  onSave,
  accountShortfall,
}: AddWithdrawModalProps) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);

  const onConfirmClick = async () => {
    setIsLoading(true);
    try {
      const payload = {
        customerId,
        transactionType: form.getFieldValue('transactionType'),
        paymentType: form.getFieldValue('paymentType'),
        amount: +form.getFieldValue('amount'),
      };

      const response = await depositWithdrawLayawayFunds(payload);
      if (response?.status === 200) {
        if (response?.data?.success) {
          setIsLoading(false);
          onCancel();
          onSave && onSave(response?.data?.transactionId, payload);
          notification.success({
            message: '',
            description: response?.data?.message,
            duration: 5,
          });
        } else {
          setIsLoading(false);
          notification.error({
            message: '',
            description: response?.data?.message,
            duration: 5,
          });
        }
      }
    } catch (error: any) {
      setIsLoading(false);
      if (error.response?.status === 400 && error.response?.data?.error) {
        notification.error({
          message: '',
          description: error.response?.data?.error,
          duration: 5,
        });
      } else {
        notification.error({
          message: NOTIFICATION_TYPE.GENERIC_API_ERROR,
          description: NOTIFICATION_TYPE.GENERIC_API_ERROR,
          duration: 5,
        });
      }
    }
  };

  const validateAccountSurplus = (_: any, value: any) => {
    setDisableSaveButton(false);
    if (accountShortfall < 0 && value === 1) {
      setDisableSaveButton(true);
      return Promise.reject(
        new Error(FORM_LABELS.ERROR_MSG.TRANSACTION_TYPE_ADD_FAILED_SURPLUS)
      );
    }
    return Promise.resolve();
  };

  return (
    <StyledModal
      title={BUTTON_LABELS.ADD_WITHDRAW_FUND}
      open={isOpen}
      width={480}
      centered
      destroyOnClose={true}
      maskClosable={false}
      onCancel={onCancel}
      footer={[
        <Row gutter={24} justify="space-between" key="cashTransfer">
          <Col>
            <Button
              itemProp="secondary"
              key="cancelCashTransfer"
              onClick={onCancel}
            >
              {BUTTON_LABELS.CANCEL}
            </Button>
          </Col>
          <Col>
            <Button
              form="addWithdrawModalForm"
              key="saveCashTransfer"
              loading={isLoading}
              htmlType="submit"
              disabled={disableSaveButton}
            >
              {BUTTON_LABELS.SAVE}
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <Row style={{ padding: '30px' }}>
        <Col span={24}>
          <Form
            id="addWithdrawModalForm"
            onFinish={onConfirmClick}
            layout="vertical"
            form={form}
            initialValues={{
              currencyCode: 'GBP',
            }}
            className="cash-transfer-form"
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  label={FORM_LABELS.TRANSACTION_TYPE}
                  name="transactionType"
                  className="label-control"
                  rules={[
                    { validator: validateAccountSurplus },
                    {
                      required: true,
                      message: FORM_LABELS.ERROR_MSG.TRANSACTION_TYPE,
                    },
                  ]}
                >
                  <Select options={TRANSACTION_TYPES_OPTIONS} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={FORM_LABELS.PAYMENT_TYPE}
                  name="paymentType"
                  className="label-control"
                  rules={[
                    {
                      required: true,
                      message: FORM_LABELS.ERROR_MSG.PAYMENT_TYPE,
                    },
                  ]}
                >
                  <Select options={PAYMENT_TYPES_OPTIONS} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={FORM_LABELS.AMOUNT}
                  name="amount"
                  className="label-control cash-delivery-amount-input"
                  rules={[
                    {
                      required: true,
                      message: FORM_LABELS.ERROR_MSG.AMOUNT,
                    },
                  ]}
                >
                  <InputDecimal
                    placeholder={false}
                    className="input-control amount-to-pay-input"
                    addonBefore={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: AMOUNT_SYMBOL ? AMOUNT_SYMBOL : ' ',
                        }}
                      ></span>
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default AddWithdrawModal;
