import React from 'react';
import { Col, Modal, Row } from 'antd';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { actions } from 'redux/reducers/customer';
import { actions as basketActions } from 'redux/reducers/basket';
import { actions as checkoutActions } from 'redux/reducers/checkout';
import { actions as pickupActions } from 'redux/reducers/pickupRenew';
import { actions as retailActions } from 'redux/reducers/retail';
import { actions as fxActions } from 'redux/reducers/travelmoney';
import { actions as chequeCashingActions } from 'redux/reducers/chequeCashing';
import { EXIT_AND_SAVE, EXIT_AND_SAVE_MSG } from './constants';
import { Button } from '../Button';

const StyledDiv = styled.div`
  text-align: center;
  font-size: var(--font-size-16);
  line-height: 25px;
  padding: 5px 0 5px 0;
`;

const StyledModal = styled(Modal)`
  & .ant-modal-title {
    text-align: center;
    color: var(--red);
  }
`;

const StyledExitDiv = styled.div`
  text-align: center;
  font-size: var(--font-size-16);
  padding: 15px 0 10px 0;
`;

const ExitAndSavePopup = ({
  visible,
  customerName,
  isSaveJourney,
  saveJourneyHandler,
  setExitAndSavePopupVisible,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onCancelClick = () => {
    setExitAndSavePopupVisible();
  };

  const onConfirmClick = () => {
    if (isSaveJourney) {
      dispatch(basketActions.checkIsExitAndSaveMode(true));
      saveJourneyHandler();
    }
    setExitAndSavePopupVisible();
    dispatch(actions.clearAll({})); //clear customer session
    // dispatch(basketActions.clearItems()); //clear basket - commenting this to fix EVB-1115 and EVB-1093
    dispatch(checkoutActions.clearCheckout()); //clear checkout
    dispatch(pickupActions.clearItems()); //clear pickup
    dispatch(retailActions.clearItems()); // clear retail
    dispatch(fxActions.clearFx()); //clear tavel money
    dispatch(chequeCashingActions.clearItems()); //clear cheque-cashing
    dispatch(basketActions.setIsExitClearBasket(true)); // set to confirm-clear basket on dashboard.
    navigate(`/`);
  };

  return (
    <>
      <StyledModal
        title={EXIT_AND_SAVE}
        open={visible}
        width={420}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        closable={false}
        footer={[
          <Row gutter={24} justify="center" key="exitPopup">
            <Col>
              <Button
                key="confirmExit"
                itemProp="secondary"
                onClick={onConfirmClick}
              >
                Yes
              </Button>
            </Col>
            <Col>
              <Button
                itemProp="secondary"
                key="cancelExit"
                onClick={onCancelClick}
              >
                No
              </Button>
            </Col>
          </Row>,
        ]}
        centered
      >
        <Row>
          <Col span={24}>
            <StyledDiv>{`${EXIT_AND_SAVE_MSG.LINE_ONE} '${customerName}'`}</StyledDiv>
            <StyledExitDiv>{EXIT_AND_SAVE_MSG.LINE_TWO}</StyledExitDiv>
          </Col>
        </Row>
      </StyledModal>
      ;
    </>
  );
};

export default ExitAndSavePopup;
