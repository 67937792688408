export const SEARCH_AUCTION = 'SEARCH_AUCTION';
export const GET_AUCTIONS_OVER_SEVENTYFIVE = 'GET_AUCTIONS_OVER_SEVENTYFIVE';
export const GET_PFI_UNDER_SEVENTYFIVE = 'GET_PFI_UNDER_SEVENTYFIVE';
export const GET_AUCTION_DETAILS = 'GET_AUCTION_DETAILS';
export const PUT_CONFIRM_COLLECTION = 'PUT_CONFIRM_COLLECTION';
export const CONFIRM_AUCTION_SETTLEMENT = 'CONFIRM_AUCTION_SETTLEMENT';
export const SETTLE_AUCTION = 'SETTLE_AUCTION';
export const UPDATE_SOLD_VALUE = 'UPDATE_SOLD_VALUE';
export const PRINT_PASS_LIST = 'PRINT_PASS_LIST';
export const GET_PFI_ITEM_DETAILS = 'GET_PFI_ITEM_DETAILS';
export const GET_LOAN_DETAILS = 'GET_LOAN_DETAILS';
export const GET_PURCHASE_LOAN_DETAILS = 'GET_PURCHASE_LOAN_DETAILS';
export const GET_PARENT_ITEM_CATEGORY = 'GET_PARENT_ITEM_CATEGORY';
export const POST_PFI_BARCODE = 'POST_PFI_BARCODE';
export const PUSH_PLEDGE_TO_PFI = 'PUSH_PLEDGE_TO_PFI';
export const PUSH_PURCHASE_TO_PFI = 'PUSH_PURCHASE_TO_PFI';
export const PASS_AUCTION_LOT = 'PASS_AUCTION_LOT';
export const LOCK_AUCTION = 'LOCK_AUCTION';
export const NOTIFY_AUCTION_LOCKING = 'NOTIFY_AUCTION_LOCKING';
export const GET_PLEDGE_ITEMS = 'GET_PLEDGE_ITEMS';
export const GET_PLEDGE_ITEM_DETAILS = 'GET_PLEDGE_ITEM_DETAILS';
export const REVIEW_ITEMS_PFA = 'REVIEW_ITEMS_PFA';
export const AUCTION_ITEM_PRICE_REQUEST = 'AUCTION_ITEM_PRICE_REQUEST';
export const PFA_LOT_NUMBER = 'PFA_LOT_NUMBER';
export const GENERATE_LOT_STICKER = 'GENERATE_LOT_STICKER';

//confiscate and vulnerable pledge
export const CONFISCATE_PLEDGES = 'CONFISCATE_PLEDGES';
export const VULNERABLE_PLEDGES = 'VULNERABLE_PLEDGES';
export const GET_VULNERABLE_PLEDGES = 'GET_VULNERABLE_PLEDGES';
export const GET_VULNERABLE_NOTES = 'GET_VULNERABLE_NOTES';
export const ADD_VULNERABLE_NOTE = 'ADD_VULNERABLE_NOTE';
export const RELEASE_VULNERABLE_PLEDGE = 'RELEASE_VULNERABLE_PLEDGE';
