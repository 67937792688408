/* eslint-disable no-template-curly-in-string */

export const validateMessages = {
  required: '${label} is required!',

  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  pattern: {
    mismatch: 'Numeric or special characters not allowed in ${label}',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
  string: {
    range: '${label} should not be greater than ${max} characters',
  },
};
/* eslint-enable no-template-curly-in-string */
