import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Form, Select, Divider } from 'antd';
import styled from 'styled-components';

import { currencyFormat, isSoldDateExceed } from 'utils/util';
import RefundExchangeSummary from '../viewRetailSale/RefundExchangeSummary';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/reducers/retail';
import SubsequentRefund from 'components/retail/viewRetailSale/SubsequentRefund';
import {
  CANCEL,
  PUSH_REFUND,
  REFUND_EXCHANGE_ITEM,
  TOTAL_AMOUNT_ITEMS,
  REFUND_REASON_LABEL,
  REFUND_REASON_PLACEHOLDER,
  REFUND_VALUE_LABEL,
  PAYMENT_MODE,
  NOT_AVAILABLE_LABEL,
  TOTAL_TRANSACTION_AMOUNT,
} from '../constants';
import { RefundExchangeProps } from 'components/interface/RefundExchangePopup';
import { Button } from 'components/common/Button';
import { cacheStaticData } from 'utils/cacheServices';
import { RefundReasonProps } from 'components/interface/RefundReasonProps';
import CustomerInfoHeader from 'components/common/CustomerInfoHeader';
import { getRefundReasons } from 'services/retail';
import TextArea from 'components/common/TextArea';
const StyledHeader = styled(Row)`
  margin-bottom: 8px;
  font-size: 2rem;
  white-space: initial;
  color: var(--text-color);
  font-weight: var(--font-weight-500);
  line-height: 23.44px;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
`;
const StyledContentContainer = styled(Col)`
  margin: 6px 0;
`;

const StyledTableWrapper = styled(Row)`
  height: 170px;
`;
const StyledAmount = styled.span`
  float: right;
`;
const StyledRefundAmount = styled.span`
  color: var(--red);
  float: right;
`;

const StyledTotalAmountRow = styled(Row)`
  font-weight: var(--font-weight-500);
  color: black;
  margin-left: 640px;
`;

const RefundExchangePopup = ({
  refundExchangeCustomer,
  onRefundExchangeCustomerCancelClick,
  recieptData,
}: RefundExchangeProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [subsequentRefundVisible, setSubsequentRefundVisible] =
    useState<boolean>(false);
  const [textAreaMessage, setTextAreaMessage] = useState<string>('');
  const [refundValue, setRefundValue] = useState<number>(0);
  const [isHideTeaxtArea, setHideTextarea] = useState<boolean>(true);
  const [subsequentVisibility, setSubsequentVisibility] =
    useState<boolean>(false);
  const [refundReason, setRefundReason] = useState<string>('');
  const [refundReasonList, setRefundReasonList] = useState<
    Array<RefundReasonProps>
  >([]);
  const [storeName, setStoreName] = useState<string>(NOT_AVAILABLE_LABEL);
  const [userName, setUserName] = useState<string>(NOT_AVAILABLE_LABEL);
  const [items, setItems] = useState<any>();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { rows } = useSelector((state: any) => state.retail);

  useEffect(() => {
    setStoreName(recieptData.storeName);
    setUserName(recieptData.createdUserName);
    setTimeout(() => {
      loadRefundReasons();
    }, 3000);
  }, [recieptData]);

  useEffect(() => {
    if (refundExchangeCustomer) {
      setVisible(true);
    }
  }, [refundExchangeCustomer]);

  useEffect(() => {
    setTimeout(() => {
      loadRefundReasons();
    }, 3000);
  }, [recieptData]);

  useEffect(() => {
    if (refundExchangeCustomer) {
      setVisible(true);
    }
  }, [refundExchangeCustomer]);

  const onPushRefundClick = () => {
    if (items === undefined) {
      return;
    }

    const totalItems = [...items];
    const uniqueItems = [];
    const uniqueObject: any = {};
    for (const i in totalItems) {
      const itemIds = totalItems[i]['stockItemId'];
      uniqueObject[itemIds] = totalItems[i];
    }
    for (const i in uniqueObject) {
      uniqueItems.push(uniqueObject[i]);
    }

    dispatch(actions.retailItemResponse([...rows, ...uniqueItems]));
    dispatch(actions.resetRetailItems());
    setVisible(false);
    onRefundExchangeCustomerCancelClick();
  };

  const onReasonForRefundChange = (e: any) => {
    setRefundReason(e);
    if (e === 'Other') {
      setHideTextarea(false);
    } else {
      if (refundReason !== '') {
        const resons = refundReasonList.filter(
          (item: RefundReasonProps) => item.description !== 'Other'
        );
        resons.length - 1 === refundReasonList.length
          ? setHideTextarea(false)
          : setHideTextarea(true);
      } else {
        setHideTextarea(true);
      }
    }
  };
  const loadRefundReasons = async () => {
    const response = await cacheStaticData(getRefundReasons);
    const retailRefundReasons = response?.data?.retailRefundReasons;
    if (retailRefundReasons) {
      setRefundReasonList(retailRefundReasons);
    }
  };

  const onCancelClick = () => {
    setVisible(false);
    dispatch(actions.resetRetailItems());
    onRefundExchangeCustomerCancelClick();
  };

  const selectOptions = refundReasonList.map((el) => (
    <Select.Option value={el['description']} key={el['id']}>
      {el['description']}
    </Select.Option>
  ));

  const grandTotal: number =
    recieptData.retailRefundItemTransactionDetails.reduce(
      (total: number, item: any) => {
        return total + item.saleValue;
      },
      0.0
    );

  const onSelectionChange = (rows: Array<string | number>) => {
    let refundSum = 0.0;
    const selectedItemsById =
      recieptData.retailRefundItemTransactionDetails.filter(
        (recieptItem: any) => rows.includes(recieptItem.id)
      );
    setItems(selectedItemsById);
    // file deepcode ignore ForEachReturns
    selectedItemsById.forEach((receiptItem: any) => {
      if (receiptItem?.status !== 'Returned')
        return (refundSum += receiptItem.saleValue);
    });
    setRefundValue(refundSum);
    const returned = selectedItemsById.some(
      (item: any) => item.status === 'Returned'
    );
    if (returned) {
      setSubsequentRefundVisible(true);
      setSubsequentVisibility(true);
    } else {
      setSubsequentRefundVisible(false);
      setSubsequentVisibility(false);
    }

    dispatch(actions.subsequentRefund(selectedItemsById));
  };

  return (
    <Modal
      open={visible}
      closable={true}
      key={1}
      centered
      width={941}
      maskClosable={false}
      onCancel={onCancelClick}
      footer={[
        <StyledButton itemProp="secondary" key="back" onClick={onCancelClick}>
          {CANCEL}
        </StyledButton>,
        <Button
          key="submit"
          onClick={() => onPushRefundClick()}
          disabled={
            refundValue >= 0 && refundReason !== '' && !subsequentVisibility
              ? isHideTeaxtArea === false &&
                textAreaMessage !== '' &&
                textAreaMessage.trim().length >= 10
                ? false
                : isHideTeaxtArea === true
                ? false
                : true
              : true
          }
        >
          {PUSH_REFUND}
        </Button>,
      ]}
    >
      <StyledHeader>{REFUND_EXCHANGE_ITEM}</StyledHeader>
      <Form layout="vertical" form={form}>
        <StyledContentContainer className="refund-exchange-wrapper">
          <CustomerInfoHeader
            userName={userName}
            storeName={storeName}
            customerId={recieptData?.customerId}
            transactionId={recieptData?.retailTransactionId}
            transactionDate={recieptData?.transactionDate}
            isSoldDateExceed={
              recieptData?.transactionDate &&
              isSoldDateExceed(recieptData?.transactionDate, 28)
            }
          />
          <StyledTableWrapper className="refund-exchange-summary-wrapper">
            <RefundExchangeSummary
              onSelectionChange={onSelectionChange}
              rows={recieptData.retailRefundItemTransactionDetails}
            ></RefundExchangeSummary>
          </StyledTableWrapper>
          <Row>
            {subsequentRefundVisible ? (
              <SubsequentRefund></SubsequentRefund>
            ) : null}
          </Row>
          <Row>
            <StyledTotalAmountRow className="total-amount-row">
              <Col span={21}>{TOTAL_AMOUNT_ITEMS}</Col>
              <Col span={21}>
                {'£' + currencyFormat(grandTotal?.toFixed(2))}
              </Col>
            </StyledTotalAmountRow>
          </Row>
          <Divider type="horizontal"> </Divider>
          <Row className="refund-reason-grid">
            <Col span={10}>
              <table>
                <tbody>
                  <tr>
                    <td>{TOTAL_TRANSACTION_AMOUNT}</td>
                    <td>
                      <StyledAmount>
                        {'£' +
                          currencyFormat(recieptData.amountPaid.toFixed(2))}
                      </StyledAmount>
                    </td>
                  </tr>
                  <tr key={1}>
                    <td>{PAYMENT_MODE.CASH_LABEL}</td>
                    <td>
                      <StyledAmount>
                        {'£' +
                          currencyFormat(recieptData.cashAmount.toFixed(2))}
                      </StyledAmount>
                    </td>
                  </tr>
                  <tr key={2}>
                    <td>{PAYMENT_MODE.CARD_LABEL}</td>
                    <td>
                      <StyledAmount>
                        {'£' +
                          currencyFormat(recieptData.cardAmount.toFixed(2))}
                      </StyledAmount>
                    </td>
                  </tr>
                  <tr key={'payment_modes_0'}>
                    <td style={{ backgroundColor: 'var(--rose-white)' }}>
                      {REFUND_VALUE_LABEL}
                    </td>
                    <td>
                      <StyledRefundAmount>
                        {'£' + currencyFormat(refundValue.toFixed(2))}
                      </StyledRefundAmount>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col className="select-reson" span={12} offset={2}>
              <Form.Item
                label={REFUND_REASON_LABEL}
                className="refund-reason-select"
              >
                <Select
                  placeholder={REFUND_REASON_PLACEHOLDER}
                  onChange={onReasonForRefundChange}
                >
                  {selectOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} offset={12} hidden={isHideTeaxtArea}>
              <Form.Item label="Message" className="refund-reason-message">
                <TextArea maxLength={50}
                  onChange={(event: any) => {
                    if (event.target.value.trim() !== '') {
                      setTextAreaMessage(event.target.value);
                    }
                  }} />
              </Form.Item>
            </Col>
          </Row>
        </StyledContentContainer>
      </Form>
    </Modal>
  );
};
export default RefundExchangePopup;
