import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ValuationsTable from './valuationsTable';

import { pledgeActions } from 'redux/reducers/pawnbroking';
import { actions as basketActions } from 'redux/reducers/basket';
import { actions as customerActions } from 'redux/reducers/customer';
import { actions as valuationActions } from 'redux/reducers/valuationCenter';

import { EDIT_PLEDGE_ITEMS } from 'action_creators/pledge';
import { GET_NOTIFICATION_ITEM_DETAILS } from 'action_creators/valuationCenter';

import { VALUATION_VIEW_TYPE } from 'components/valuationCenter/constants';
import {
  BASKET_SERVICE_TYPE,
  GUEST_TYPES,
  ROUTE_CONFIG,
} from 'globalConstants';

const Valuations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    valuationCenter: { isValuationItemToPledge, valuationItemDetails },
    customer: { customer },
  } = useSelector((state: any) => {
    return {
      valuationCenter: state.valuationCenter,
      customer: state.customer,
    };
  });

  useEffect(() => {
    if (isValuationItemToPledge) {
      onProceedToPledge(valuationItemDetails);
    }
  }, [isValuationItemToPledge]); // eslint-disable-line react-hooks/exhaustive-deps

  const onActionButtonClick = (row: any) => {
    dispatch({
      type: GET_NOTIFICATION_ITEM_DETAILS,
      payload: {
        loanNumber: row.serviceReference,
        viewType: VALUATION_VIEW_TYPE.VIEW_ITEM_IN_PLEDGE,
      },
    });
  };

  const onProceedToPledge = (row: any) => {
    navigate(`/${ROUTE_CONFIG.PLEDGE}`);

    if (row.offeredAmount)
      dispatch(valuationActions.setOfferedAmount(row.offeredAmount));

    dispatch(valuationActions.setValuationItemDesiredAmount(row.desiredAmount));
    dispatch(valuationActions.setOpportunityId(row.serviceReference));
    dispatch(basketActions.addPledgeItemsFromBasket(true));
    dispatch(pledgeActions.updateServiceType(BASKET_SERVICE_TYPE.PLEDGE));
    dispatch({
      type: EDIT_PLEDGE_ITEMS,
      payload: { params: row.valuationItem },
    });
    if (!customer.customerId && !customer.firstNames) {
      dispatch(customerActions.setCustomerDetails(row.customer));
    }
    if (!row?.customer?.customerId) {
      dispatch(customerActions.setGuestType(GUEST_TYPES.VALUATION));
    }
  };

  return <ValuationsTable onActionButtonClick={onActionButtonClick} />;
};

export default Valuations;
