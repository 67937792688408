import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { GRID_NO_URL } from 'globalConstants';
import { Layout, Row, Col, Input } from 'antd';
import {
  VIEW_AUCTION_DETAILS_TITLE,
  VIEW_AUCTION_PENDING_RETURN_TITLE,
  TABLE_HEAD_COLOR,
  CONFIRM_SETTLEMENT_HEADER,
  CONFIRM_SETTLEMENT_CONTENT,
  CONFIRM_SETTLEMENT_TYPE,
  CONFIRM_SETTLEMENT_VOLUME,
  CONFIRM_SETTLEMENT_VALUE,
  PROCEED_SETTLEMENT_HEADER,
  PROCEED_SETTLEMENT_CONTENT,
  PROCEED_SETTLEMENT_WARNING,
  SUMMARY_STATUS_OPTIONS,
  BUTTON_LABELS,
} from 'components/pledgemanagement/constants';
import PendingReturnAuctionSummary from '../auctionDetailsSummary/pendingReturnAuctionSummary';
import VirtualGrid from 'components/common/virtualGrid/index';
import { Button } from 'components/common/Button';
import {
  CONFIRM_AUCTION_SETTLEMENT,
  SETTLE_AUCTION,
} from 'action_creators/pledgeManagement';
import ControlledAlert from 'components/common/ControlledAlert';

const StyledContentLayout = styled(Layout)`
  background: var(--white);
  border-radius: 5px;
`;

const StyledDetailsRow = styled(Row)`
  padding-bottom: 10px;
`;

const StyledTableWrapper = styled.div`
  height: calc(100vh - 455px);
`;

const StyledBottomRow = styled(Row)`
  padding: 15px 30px;
  background: var(--white);
  border: 0.6px solid var(--linkwater);
  border-top: none;
  box-shadow: rgba(1, 78, 169, 0.2) 0px 4px 10px;
  border-radius: 0px 0px 5px 5px;
  box-shadow: none;
  border: 0;
  border-top: 1px solid var(--linkwater);
`;

const StyledButton = styled(Button)`
  margin: 0 15px;
`;

const StyledRow = styled(Row)`
  background: var(--cyan);
  padding: 10px 30px;
  position: relative;
  left: -20px;
  width: calc(100% + 40px);
`;

const StyledText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  flex-basis: 95%;
`;

const StyledTotalValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const StyledMainContainer = styled(Col)`
  padding: 15px 20px 0px 20px;
`;

const StyledAlertText = styled(Row)`
  color: var(--red);
`;

const StyledHeadText = styled(Row)`
  font-size: var(--font-size-20);
  white-space: initial;
  font-weight: var(--font-weight-500);
`;

interface PendingReturnAuctionDetailsProps {
  onExit: () => void;
}
const PendingReturnAuctionDetails = ({
  onExit,
}: PendingReturnAuctionDetailsProps) => {
  const {
    pledgeManagement: { auctionItemDetails, confirmSettlement },
  } = useSelector((state: any) => {
    return {
      pledgeManagement: state.pledgeManagement,
    };
  });

  const { auctionLoans, ...auctionDetailsTable } = auctionItemDetails;
  const [isConfirmSettlementAlertVisible, setIsConfirmSettlementAlertVisible] =
    useState<boolean>(false);
  const [isProceedSettlementAlertVisible, setIsProceedSettlementAlertVisible] =
    useState<boolean>(false);

  const dispatch = useDispatch();

  const [columns] = useState([
    {
      name: 'auctionDate',
      title: 'Auction Date',
      getCellValue: (row: any) => row?.auctionDate,
    },
    {
      name: 'bookDate',
      title: 'Book Date',
      getCellValue: (row: any) => row?.bookDate,
    },
    {
      name: 'collectionDate',
      title: 'Collection Date',
      getCellValue: (row: any) => row?.collectionDate,
    },
    {
      name: 'lastMailDate',
      title: 'Last Mail Sent',
      getCellValue: (row: any) => row?.lastMailDate,
    },
    {
      name: 'reserveValue',
      title: 'Reserve Value',
      getCellValue: (row: any) => row?.reserveValue,
    },
    {
      name: 'numberOfLots',
      title: 'Number of Lots',
      getCellValue: (row: any) => row?.numberOfLots,
    },
    {
      name: 'auctionStatusText',
      title: 'Status',
      getCellValue: (row: any) => row?.auctionStatusText,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'auctionDate', width: '14%' },
    { columnName: 'bookDate', width: '14%' },
    { columnName: 'collectionDate', width: '14%' },
    { columnName: 'lastMailDate', width: '14%' },
    { columnName: 'reserveValue', width: '14%' },
    { columnName: 'numberOfLots', width: '13%' },
    { columnName: 'auctionStatusText', width: '13%' },
  ]);

  const getRowId = (row: any) => row?.auctionId;

  const tableRow = (type: string, volume: any, value: any) => {
    return (
      <tr>
        <td>{type}</td>
        <td>{volume}</td>
        <td>{value}</td>
      </tr>
    );
  };

  const confirmSettleMsgString = () => {
    return (
      <>
        <StyledHeadText>{CONFIRM_SETTLEMENT_HEADER}</StyledHeadText>
        <StyledAlertText>{CONFIRM_SETTLEMENT_CONTENT}</StyledAlertText>
        <table className="auction-settlement-table">
          <thead>
            <tr>
              <th>{CONFIRM_SETTLEMENT_TYPE}</th>
              <th>{CONFIRM_SETTLEMENT_VOLUME}</th>
              <th>{CONFIRM_SETTLEMENT_VALUE}</th>
            </tr>
          </thead>
          <tbody>
            {tableRow(
              SUMMARY_STATUS_OPTIONS[0].value,
              confirmSettlement?.countPass,
              confirmSettlement?.sumPass
            )}
            {tableRow(
              SUMMARY_STATUS_OPTIONS[1].value,
              confirmSettlement?.countSold,
              confirmSettlement?.sumSold
            )}
            {tableRow(
              SUMMARY_STATUS_OPTIONS[2].value,
              confirmSettlement?.countReturn,
              confirmSettlement?.sumReturn
            )}
          </tbody>
        </table>
      </>
    );
  };

  const proceedSettleMsgString = () => {
    return (
      <>
        <StyledHeadText>{PROCEED_SETTLEMENT_HEADER}</StyledHeadText>
        <StyledAlertText>{PROCEED_SETTLEMENT_CONTENT}</StyledAlertText>
        <StyledAlertText>{PROCEED_SETTLEMENT_WARNING}</StyledAlertText>
      </>
    );
  };

  const onSettleClick = useCallback(() => {
    dispatch({
      type: CONFIRM_AUCTION_SETTLEMENT,
      payload: {
        auctionId: auctionDetailsTable?.auctionId,
      },
    });
  }, [auctionDetailsTable, dispatch]);

  const onProceedSettlementClick = () => {
    setIsConfirmSettlementAlertVisible(false);
    setIsProceedSettlementAlertVisible(true);
  };

  const onProceedFinalClick = () => {
    setIsProceedSettlementAlertVisible(false);
    dispatch({
      type: SETTLE_AUCTION,
      payload: {
        auctionId: auctionDetailsTable?.auctionId,
      },
    });
  };

  useEffect(() => {
    if (confirmSettlement?.countPass) {
      setIsConfirmSettlementAlertVisible(true);
    }
  }, [confirmSettlement]);

  return (
    <>
      <StyledMainContainer span={24}>
        <StyledContentLayout>
          <Row className="title-row">
            <Col span={24}>
              {VIEW_AUCTION_DETAILS_TITLE}
              {VIEW_AUCTION_PENDING_RETURN_TITLE}
            </Col>
          </Row>
          <StyledDetailsRow>
            <VirtualGrid
              urlType={GRID_NO_URL}
              columnsProps={columns}
              tableColumnExtensionsProps={tableColumnExtensions}
              rowData={[auctionDetailsTable]}
              getRowId={getRowId}
              headerBackground={TABLE_HEAD_COLOR.PENDING}
            />
          </StyledDetailsRow>
          <StyledTableWrapper>
            <PendingReturnAuctionSummary auctionLoans={auctionLoans} />
          </StyledTableWrapper>
        </StyledContentLayout>

        <StyledRow>
          <Col span={24}>
            <Row>
              <Col offset={18} span={6}>
                <StyledTotalValue className="pickup-input">
                  <StyledText>Total Sold Value</StyledText>
                  <Input
                    className="total-value"
                    disabled
                    addonBefore={'£'}
                    placeholder="0.00"
                    value={auctionDetailsTable.totalSoldValue}
                  />
                </StyledTotalValue>
              </Col>
            </Row>
          </Col>
        </StyledRow>

        <Row>
          <Col span={24}>
            <StyledBottomRow>
              <Col span={16}>
                <Row align="bottom" justify="start">
                  <StyledButton
                    onClick={onExit}
                    itemProp="secondary"
                    style={{ marginLeft: 0 }}
                  >
                    {BUTTON_LABELS.BACK}
                  </StyledButton>
                </Row>
              </Col>
              <Col span={8}>
                <Row align="bottom" justify="end">
                  <StyledButton
                    itemProp="secondary"
                    onClick={onSettleClick}
                    disabled={!auctionLoans.length}
                  >
                    {BUTTON_LABELS.SETTLE_AUCTION}
                  </StyledButton>
                </Row>
              </Col>
            </StyledBottomRow>
          </Col>
        </Row>

        {isConfirmSettlementAlertVisible && (
          <ControlledAlert
            closable={true}
            visible={isConfirmSettlementAlertVisible}
            isCancelBtn={true}
            cancelButtonText={BUTTON_LABELS.CANCEL}
            onCancel={() => setIsConfirmSettlementAlertVisible(false)}
            alertMessage={''}
            message={confirmSettleMsgString()}
            onClick={onProceedSettlementClick}
            yesButtonText={BUTTON_LABELS.PROCEED}
          />
        )}

        {isProceedSettlementAlertVisible && (
          <ControlledAlert
            closable={true}
            visible={isProceedSettlementAlertVisible}
            isCancelBtn={true}
            cancelButtonText={BUTTON_LABELS.CANCEL}
            onCancel={() => setIsProceedSettlementAlertVisible(false)}
            alertMessage={''}
            message={proceedSettleMsgString()}
            onClick={onProceedFinalClick}
            yesButtonText={BUTTON_LABELS.PROCEED}
          />
        )}
      </StyledMainContainer>
    </>
  );
};

export default PendingReturnAuctionDetails;
