import React, { useCallback, useState } from 'react';
import { Modal, Row, Col, Input, AutoComplete } from 'antd';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import {
  FIND_ISSUER_LABEL,
  ADD,
  CLOSE,
  FIND_ISSUER_PLACEHOLDER,
  CLICK_HERE_REGISTER_MESSAGE,
  DONT_FIND_MESSAGE,
} from '../constants';

import './index.less';

import { SelectProps } from 'antd/es/select';
import { searchForIssuer } from 'services/chequeCashing';
import CreateNewIssuer from '../newIssuer';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';

const StyledHeader = styled(Row)`
  margin-bottom: 15px;
  font-size: 2rem;
  white-space: initial;
  color: var(--text-color);
  font-weight: var(--font-weight-500);
  line-height: 23.44px;
`;

const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
  margin-bottom: 38px;
  & .ant-input-affix-wrapper {
    & .anticon-caret-down {
      font-size: 12px;
    }
    & .anticon-close-circle {
      font-size: 24px;
    }
  }

  & input {
    background-color: var(--autocomplete-bg);

    &::placeholder {
      color: var(--gray);
      font-weight: var(--font-weight-400);
      font-size: var(--font-size-16);
      font: roboto;
      line-height: 18.75px;
    }
  }

  & .ant-input-affix-wrapper-lg .ant-input-search-icon::before {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & .ant-select-item-option:first-child {
    padding: 0 !important;
    height: 35px;
    cursor: default !important;
  }

  & .ant-select-item-option {
    padding: 8px 12px !important;
    border-bottom: 1px solid var(--mystic) !important;
  }

  & .ant-select-item-option:not(.ant-select-item-option-disabled):last-child {
    background-color: var(--white) !important;
    cursor: default !important;
    & .see-all-results-container {
      text-align: center;
    }
  }

  & .ant-select-item-empty {
    padding: 0;
    margin-top: -3px;
    cursor: default;
  }
`;

interface FindIssuerProps {
  isFindIssuerVisible: boolean;
  onFindIssuerPopupCancel: () => void;
  onSelection: (selected: any) => void;
  basketDetails: any;
}

const FindIssuer = ({
  isFindIssuerVisible,
  onFindIssuerPopupCancel,
  onSelection,
  basketDetails,
}: FindIssuerProps) => {
  const [issuerAutoSuggestResults, setIssuerAutoSuggestResults] = useState<
    SelectProps<object>[]
  >([]);
  const [isNewIssuerVisible, setIsNewIssuerVisible] = useState<boolean>(false);
  const [isInputAdded, setIsInputAdded] = useState<boolean>(false);
  const [isViewIssuerClick, setIsViewIssuerClick] = useState<boolean>(true);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [selectedIssuerData, setSelectedIssuerData] = useState<any>({});

  const onCloseClick = useCallback(() => {
    onFindIssuerPopupCancel();
    setIsNewIssuerVisible(false);
    setIsViewIssuerClick(false);
  }, [onFindIssuerPopupCancel]);

  const onSearchChange = async (value: string) => {
    try {
      const response = await searchForIssuer(value);
      if (response?.data) {
        setIssuerAutoSuggestResults(response?.data);
        setIsInputAdded(true);
      } else {
        setIssuerAutoSuggestResults([]);
        setIsInputAdded(false);
      }
    } catch (e: any) {
      setIssuerAutoSuggestResults([]);
      console.log(e);
    }
  };

  const onSelect = (e: any) => {
    const issuerData: any = issuerAutoSuggestResults.find(
      (item: any) => item.issuerName === e?.target?.value
    );

    if (issuerData?.issuerName === e?.target.value) {
      setIsInputAdded(true);
    } else {
      setIsInputAdded(false);
    }

    if (issuerData) {
      setSelectedValue(issuerData?.issuerName);
      setSelectedIssuerData(issuerData);
      setIsInputAdded(true);
    } else {
      setSelectedValue('');
    }
  };

  const setNewIssuerData = (data: any) => {
    setSelectedValue(data.issuerName);
    onSelection(data);
  };

  const renderItem = (data: any) => ({
    label: data.issuerName,
    value: data.issuerName,
    id: data.issuerId,
  });

  const onRegisterMessageClick = () => {
    setIsNewIssuerVisible(true);
    setIsViewIssuerClick(false);
  };
  const noResultRow = (
    <div className="no-result-container">
      <span>{DONT_FIND_MESSAGE}</span>
      <button className="click-here-result" onClick={onRegisterMessageClick}>
        {CLICK_HERE_REGISTER_MESSAGE}
      </button>
    </div>
  );

  const options = issuerAutoSuggestResults?.map((el: any, key: any) => {
    return {
      options: [renderItem(el)],
    };
  });

  return (
    <Modal
      open={isFindIssuerVisible}
      closable={true}
      key={1}
      centered
      width={892}
      maskClosable={false}
      onCancel={onCloseClick}
      footer={[
        <Button key={0} itemProp="primary" onClick={onCloseClick}>
          {CLOSE}
        </Button>,

        <Button
          key={1}
          htmlType="submit"
          className="add-btn"
          onClick={() => onSelection(selectedIssuerData)}
          disabled={!isInputAdded}
        >
          {ADD}
        </Button>,
      ]}
    >
      <Row className="find-issuer-popup-body">
        <Col span={24}>
          <Row>
            <Col>
              <StyledHeader>
                <HelpPopoverInfo
                  linkedID={'CHEQUE_FINDISSUER'}
                  position="rightTop"
                  helpPosition="AFTER"
                >
                  {FIND_ISSUER_LABEL}
                </HelpPopoverInfo>
              </StyledHeader>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <StyledAutoComplete
                popupClassName="search-dropdown"
                options={options}
                notFoundContent={
                  issuerAutoSuggestResults.length === 0 && noResultRow
                }
              >
                <Input.Search
                  allowClear
                  size="middle"
                  width={50}
                  placeholder={FIND_ISSUER_PLACEHOLDER}
                  onChange={(e) => {
                    onSearchChange(e?.target?.value);
                  }}
                  onSelect={onSelect}
                  value={selectedValue}
                />
              </StyledAutoComplete>
            </Col>
          </Row>
        </Col>
      </Row>
      {isNewIssuerVisible && (
        <CreateNewIssuer
          onNewIssuerPopupCancel={onCloseClick}
          isNewIssuerVisible
          isViewIssuerClick={isViewIssuerClick}
          isEditable
          issuerName={selectedValue}
          basketDetails={basketDetails}
          issuerDetails={selectedIssuerData}
          setNewIssuerData={setNewIssuerData}
        />
      )}
    </Modal>
  );
};
export default FindIssuer;
