import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  quickQuote: {},
  chequeDetails: {},
  partPaymentData: {},
  error: {},
  chequeData: {},
  isPreAuthPassed: false,
  preAuthLoading: false,
  editRowServiceId: '',
  documentsImages: [] as Array<any>,
  requestedAmount: 0,
  chequeRetainedAmount: 0,
  chequeApprovedAmount: 0,
  sendToSupportCenterClicked: false,
  errorMessage: '',
  basketError: false,
  docNotes: '',
  sentToSupportCenter: false,
  partPaymentClicked: false,
  isProceedToBasket: false,
  isChequeCashingHistoryExists: false,

  preAuthStatusCode: null,
  preAuthText: '',
  thirdPartyChequePreAuthErrorsList: '',
  chequeItemDetails: {},
  isDocUploading: false,
  isApprovedEditable: false,
};
const slice = createSlice({
  name: 'chequeCashingActions',
  initialState,
  reducers: {
    initLoading(state, action) {
      state.loading = action.payload;
    },
    addQuickQuote(state, action) {
      state.quickQuote = action.payload;
    },
    addChequeDetails(state, action) {
      state.chequeDetails = action.payload;
    },
    addPartPaymentDetails(state, action) {
      state.partPaymentData = action.payload;
    },
    setPaymentDataIndex(state, action) {
      state.partPaymentClicked = action.payload;
    },
    setError(state, action) {
      state.error = action.payload?.response?.data;
    },
    setPreAuthStatus(state, action) {
      state.isPreAuthPassed = action.payload;
    },
    setPreAuthLoading(state, action) {
      state.preAuthLoading = action.payload;
    },
    setEditRowServiceId(state, action) {
      state.editRowServiceId = action.payload;
    },
    setDocumentsImages(state, action) {
      state.documentsImages = action.payload;
    },
    setRequestedAmount(state, action) {
      state.requestedAmount = action.payload;
    },
    setRetainedAmount(state, action) {
      state.chequeRetainedAmount = action.payload;
    },
    setApprovedAmount(state, action) {
      state.chequeApprovedAmount = action.payload;
    },
    setSendToSupportCenterClicked(state, action) {
      state.sendToSupportCenterClicked = action.payload;
    },
    clearDocumentsData(state) {
      state.documentsImages = [];
    },
    supportCenterError(state, action) {
      state.basketError = true;
      state.errorMessage = action?.payload;
    },
    setDocNotes(state, action) {
      state.docNotes = action.payload;
    },
    sentToSupportCenter(state, action) {
      state.sentToSupportCenter = action.payload;
    },
    setIsProceedToBasket(state, action) {
      state.isProceedToBasket = action.payload;
    },
    setIsApprovedEditable(state, action) {
      state.isApprovedEditable = action.payload;
    },
    setPreAuthStatusCode(state, action) {
      state.preAuthStatusCode = action.payload;
    },
    setPreAuthText(state, action) {
      state.preAuthText = action.payload;
    },
    setPreAuthErrorsList(state, action) {
      state.thirdPartyChequePreAuthErrorsList = action.payload;
    },
    resetPreAuthStatusCode(state) {
      state.preAuthStatusCode = null;
      state.preAuthText = '';
      state.thirdPartyChequePreAuthErrorsList = '';
      state.chequeApprovedAmount = 0;
    },
    setChequeItemDetails(state, action) {
      state.chequeItemDetails = action.payload;
    },
    initDocumentUpload(state, action) {
      state.isDocUploading = action.payload;
    },
    setIsChequeCashingHistoryExists(state, action) {
      state.isChequeCashingHistoryExists = action.payload;
    },

    clearItems() {
      return {
        ...initialState,
      };
    },
  },
});

export default slice.reducer;
export const { reducer, actions } = slice;
export { initialState };
