import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button as LinkButton } from 'antd';
import {
  BASKET_SERVICE_TYPE,
  COLORS_CODE_TABLE,
  GRID_NO_URL,
  RESPONSE_STATUS,
} from 'globalConstants';
import { currencyFormat } from 'utils/util';
import VirtualGrid from 'components/common/virtualGrid/index';
import ProgressTick from 'components/common/ProgressCheck';
import ImageLoader from 'components/common/ImageLoader';
import { downloadAffidavit } from 'services/checkout';
import {
  AGREEMENT_OPTIONS,
  NEW_CONTRACT,
} from 'components/pickupRenew/constants';
import TableRow from '../../formatedCell';
import { PRINT_CONTRACTS } from 'action_creators/pickupRenew';
import { sendToPrinter } from 'services/user';
import { SUCCESSFUL } from 'components/checkout/constants';

import { actions as checkoutActions } from 'redux/reducers/checkout';

interface PickupContractItem {
  pickupRenew: {
    request: { item: { affidavitAmount: number; selectedActionType: number } };
    response: { pledgeResponses: Array<{ serviceLoanNumber: string }> };
  };
  serviceStatusText: string;
  serviceId: string;
}

const CheckoutPickup = (props: any) => {
  const isServiceSuccess = props?.isServiceSuccess;

  const rows = props?.item || [];
  const dispatch = useDispatch();

  const {
    customer: { isRecallCheckoutCustomer },
    user: { selectedStore },
  } = useSelector((state: any) => {
    return {
      user: state.user,
      customer: state.customer,
    };
  });

  //Count to avoid multiple prints on multiple renders to the grid
  const printCountObj = rows.reduce(
    (o: any, item: any) => ({ ...o, [item.serviceId]: 0 }),
    {}
  );

  //Count for affidavit
  const printCountAffi = rows.reduce(
    (o: any, item: any) => ({ ...o, [item.serviceId]: 0 }),
    {}
  );

  const printAffidavit = async (loanNumber: any, loan: any, serviceId: any) => {
    try {
      const params = {
        ...loan,
        ...selectedStore,
      };

      const affidavit = await downloadAffidavit(params);
      if (affidavit?.data) {
        const printerStatus = await sendToPrinter(
          affidavit,
          'printcontract',
          'Affidavit',
          {
            Simplex: true,
          }
        );

        if (printerStatus === RESPONSE_STATUS.SERVICE_UNAVAILABLE)
          dispatch(checkoutActions.setIsPrinterError(true));
        else
          dispatch(
            checkoutActions.updatePickupAffiPrintStatusCollection(serviceId)
          );
      }
    } catch (e) {
      dispatch(checkoutActions.setContratPrintingError(true));
    }
  };

  const dowloadAllContract = (loanNumber: any, serviceId: any) => {
    dispatch({
      type: PRINT_CONTRACTS,
      payload: {
        params: loanNumber,
        serviceId,
        serviceType: BASKET_SERVICE_TYPE.PICKUPRENEW,
      },
    });
  };

  useEffect(() => {
    // The idea is to wait for SignalR to complete its job.
    //isServiceSuccess is true we will try to go for dowloadAllContract
    if (isServiceSuccess === true) {
      if (rows) {
        const checkForPledgeServiceStatus: number = rows.findIndex(
          (row: PickupContractItem) =>
            String(row?.serviceStatusText).toLowerCase() !== SUCCESSFUL
        );

        if (checkForPledgeServiceStatus !== -1) return;

        rows.forEach((row: PickupContractItem) => {
          //Print contact logic - printing for  Renew/Paydown
          if (
            row?.pickupRenew?.response?.pledgeResponses[0]?.serviceLoanNumber &&
            printCountObj[row?.serviceId] < 1 &&
            NEW_CONTRACT.includes(
              row?.pickupRenew?.request?.item?.selectedActionType
            ) &&
            !isRecallCheckoutCustomer &&
            String(row?.serviceStatusText).toLowerCase() === SUCCESSFUL
          ) {
            printCountObj[row?.serviceId]++;
            dowloadAllContract(
              row?.pickupRenew?.response?.pledgeResponses[0]?.serviceLoanNumber,
              row?.serviceId
            );
          } else if (
            !NEW_CONTRACT.includes(
              row?.pickupRenew?.request?.item?.selectedActionType
            )
          ) {
            dispatch(
              checkoutActions.updatePickupContractPrintStatusCollection(
                row?.serviceId
              )
            );
          }

          //Print affi logic - printing if affidavitAmount is there
          if (
            row?.pickupRenew?.response?.pledgeResponses[0]?.serviceLoanNumber &&
            printCountAffi[row?.serviceId] < 1 &&
            row.pickupRenew.request.item.affidavitAmount > 0 &&
            !isRecallCheckoutCustomer &&
            String(row?.serviceStatusText).toLowerCase() === SUCCESSFUL
          ) {
            printCountAffi[row?.serviceId]++;
            printAffidavit(
              row?.pickupRenew?.response?.pledgeResponses[0]?.serviceLoanNumber,
              row.pickupRenew.request.item,
              row?.serviceId
            );
          } else if (row.pickupRenew.request.item.affidavitAmount === 0)
            dispatch(
              checkoutActions.updatePickupAffiPrintStatusCollection(
                row?.serviceId
              )
            );
        });
      }
    }
  }, [isServiceSuccess]);

  const [columns] = useState([
    {
      name: 'serviceLoanNumber',
      title: 'New Agreement No.',
      getCellValue: (row: any) => {
        return ProgressTick(
          row?.pickupRenew?.response?.pledgeResponses[0]?.serviceLoanNumber,
          row?.serviceStatusText
        );
      },
    },
    {
      name: 'serviceName',
      title: 'Service',
      getCellValue: (row: any) => {
        return AGREEMENT_OPTIONS[
          row?.pickupRenew?.request?.item?.selectedActionType - 1
        ].value;
      },
    },
    {
      name: 'loanAmount',
      title: 'Loan Amount',
      type: 'currency',
      getCellValue: (row: any) => row?.pickupRenew?.request?.item?.loanAmount,
    },
    {
      name: 'interestRate',
      title: 'Interest',
      getCellValue: (row: any) =>
        `${currencyFormat(row?.pickupRenew?.request?.item?.interestRate)}%`,
    },
    {
      name: 'dailyInterestDisplay',
      title: 'Daily',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.pickupRenew?.request?.item?.dailyInterestDisplay,
    },
    {
      name: 'paidAmount',
      title: 'Paid',
      type: 'currency',
      getCellValue: (row: any) => row?.pickupRenew?.request?.item?.paidAmount,
    },
    {
      name: 'waivedOffAmount',
      title: 'Waived',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.pickupRenew?.request?.item?.waivedOffAmount,
    },
    {
      name: 'dueToday',
      title: 'Total Due',
      type: 'currency',
      getCellValue: (row: any) => row?.pickupRenew?.request?.item?.dueToday,
    },
    {
      name: 'affidavitAmount',
      title: 'Affidavit',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.pickupRenew?.request?.item?.affidavitAmount,
    },
    {
      name: 'newLoanAmount',
      title: 'New Loan',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.pickupRenew?.request?.item?.newLoanAmount,
    },
    {
      name: 'loanNumber',
      title: 'Agreement No.',
      getCellValue: (row: any) => row?.pickupRenew?.request?.item?.loanNumber,
    },
    {
      name: 'toPay',
      title: 'To Pay',
      type: 'currency',
      getCellValue: (row: any) => row?.toPay,
    },
    {
      name: 'toTake',
      title: 'To Take',
      type: 'currency',
      getCellValue: (row: any) => row?.pickupRenew?.request?.item?.toTakeAmount,
    },
    {
      name: '',
      title: '',
      getCellValue: (row: any) => {
        if (
          NEW_CONTRACT.includes(
            row?.pickupRenew?.request?.item?.selectedActionType
          )
        ) {
          const serviceLoanNumber =
            row?.pickupRenew?.response?.pledgeResponses[0]?.serviceLoanNumber;
          if (serviceLoanNumber)
            return (
              <LinkButton
                type="link"
                block
                title="download all contract"
                onClick={() =>
                  dowloadAllContract(serviceLoanNumber, row.serviceId)
                }
              >
                Reprint
              </LinkButton>
            );
          else {
            return '-';
          }
        }
      },
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'serviceLoanNumber', width: '11%' },
    { columnName: 'serviceName', width: '5%' },
    { columnName: 'loanAmount', width: '8%', align: 'right' },
    { columnName: 'interestRate', width: '5%', align: 'right' },
    { columnName: 'dailyInterestDisplay', width: '4%', align: 'right' },
    { columnName: 'paidAmount', width: '4%', align: 'right' },
    { columnName: 'waivedOffAmount', width: '5%', align: 'right' },
    { columnName: 'dueToday', width: '6%', align: 'right' },
    { columnName: 'affidavitAmount', width: '6%', align: 'right' },
    { columnName: 'newLoanAmount', width: '7%', align: 'right' },
    { columnName: 'loanNumber', width: '9%' },
    { columnName: 'toPay', width: '10%', align: 'right' },
    { columnName: 'toTake', width: '10%', align: 'right' },
    { columnName: '', width: '6%', align: 'right' },
  ]);

  const [formattedColumns] = useState([
    'toPay',
    'toTake',
    'newLoanAmount',
    'dueToday',
    'waivedOffAmount',
    'paidAmount',
    'dailyInterestDisplay',
    'loanAmount',
    'affidavitAmount',
  ]);

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        formattedColumns={formattedColumns}
        rowData={rows}
        tableColumnExtensionsProps={tableColumnExtensions}
        headerBackground={COLORS_CODE_TABLE.PICKUPRENEW}
        cellComponent={TableRow}
        expandableComponent={PickupExpandableTable}
        detailRowIconPlacement={true}
      />
    </>
  );
};

export const PickupExpandableTable = ({ row }: any) => {
  const [columns] = useState([
    {
      name: 'itemImage',
      title: 'Item Image',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row.id}?isThumnails=false`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'description',
      title: 'Description',
      getCellValue: (row: any) => row.description,
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) => `${row?.weight?.toFixed(2)}g`,
    },
    {
      name: 'amount',
      title: 'Amount',
      type: 'currency',
      getCellValue: (row: any) => row?.amount,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'itemImage', width: 130 },
    { columnName: 'description', width: 230 },
    { columnName: 'weight', width: 120, align: 'right' },
    { columnName: 'amount', width: 120, align: 'right' },
  ]);

  const [formattedColumns] = useState(['amount']);

  return (
    <>
      <div className="expandable-table-rows">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          formattedColumns={formattedColumns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={row?.pickupRenew?.request?.item?.pledgeItems}
          rowSelectionEnable={true}
          onSelectionChange={() => undefined}
        />
      </div>
    </>
  );
};

export default CheckoutPickup;
