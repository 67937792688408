import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Modal, Row, notification } from 'antd';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';

import { joinValues } from 'utils/util';

import { actions as consumerDutyActions } from 'redux/reducers/consumerDuty';
import { actions as userActions } from 'redux/reducers/user';
import { postManageServicesAndNewPledge } from 'services/consumerDuty';

import { Button } from 'components/common/Button';

import {
  BUTTONS_LABEL,
  HEADINGS,
  SST,
  TASK_OWNER_VALUE,
} from 'components/consumerDuty/constants';
import {
  DATE_FORMAT,
  FNS_DATE_FORMAT_YEAR,
  GENERIC_ERROR,
} from 'globalConstants';

import { GET_LOCKED_PLEDGE_DETAILS } from 'action_creators/consumerDuty';

interface ReviewChangesPopupProps {
  isVisible: boolean;
}

const ReviewChangesPopup = ({ isVisible }: ReviewChangesPopupProps) => {
  const {
    consumerDuty: {
      selectedTaskId,
      loanPrivileges,
      newPledgePermissions,
      selectedTaskOwner,
      loanCustomer,
      caseSummary,
    },
    customer: { customer },
  } = useSelector((state: any) => {
    return { consumerDuty: state.consumerDuty, customer: state.customer };
  });

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allowedServicesForLoan, setAllowedServicesForLoan] = useState<
    Array<any>
  >([]);

  const setReviewManagedServices = () => {
    const privileges = Object.values(loanPrivileges);
    const loanAllowedPrivileges: Array<any> = [];

    privileges.forEach((privilege: any) => {
      var servicesDesc = getAllowedServiceDesc(privilege);

      privilege?.loans?.forEach((loan: any) => {
        loanAllowedPrivileges.push({
          agreementNumber: loan?.loanNumber,
          servicesDesc: servicesDesc,
        });
      });
    });

    setAllowedServicesForLoan(loanAllowedPrivileges);
  };
  useEffect(() => {
    setReviewManagedServices();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllowedServiceDesc = (privilege: any) => {
    let { renewal, paydown, partialPayment, pfiAuction } = privilege;
    let allowedServices = [];

    if (
      renewal &&
      paydown &&
      partialPayment &&
      (pfiAuction || selectedTaskOwner === TASK_OWNER_VALUE.AT_RISK)
    )
      return 'All services allowed';
    else if (!renewal && !paydown && !partialPayment && !pfiAuction)
      return 'No service allowed';
    else {
      if (renewal) allowedServices.push(HEADINGS.RENEWAL);
      if (paydown) allowedServices.push(HEADINGS.PAYDOWN);
      if (partialPayment) allowedServices.push(HEADINGS.PARTIAL_PAY);
      if (pfiAuction) allowedServices.push(HEADINGS.PFI);
    }

    return `${allowedServices.join(', ')} Allowed`;
  };

  const onConfirmClick = async () => {
    try {
      const payload = {
        customerId: customer?.customerId || loanCustomer?.customerId,
        customerName: !isEmpty(customer)
          ? joinValues([customer.firstNames, customer.surname], true)
          : loanCustomer?.customerName,
        team: selectedTaskOwner,
        taskId: selectedTaskId,
        customerSupportId:
          selectedTaskOwner === TASK_OWNER_VALUE.SSTEAM
            ? caseSummary.customerSupportId
            : 0,
        manageServices: loanPrivileges.length > 0 ? loanPrivileges : null,
        newPledgePermission: !isEmpty(newPledgePermissions)
          ? {
              ...newPledgePermissions,
              startDate: newPledgePermissions?.startDate
                ? format(
                    new Date(newPledgePermissions?.startDate),
                    FNS_DATE_FORMAT_YEAR
                  )
                : null,
              endDate: newPledgePermissions?.endDate
                ? format(
                    new Date(newPledgePermissions?.endDate),
                    FNS_DATE_FORMAT_YEAR
                  )
                : null,
            }
          : null,
      };

      setIsLoading(true);
      const response = await postManageServicesAndNewPledge(payload);
      if (response?.data) {
        if (response?.data?.success) {
          dispatch(consumerDutyActions.setLoanPrivileges([]));
          dispatch(consumerDutyActions.resetNewPledgePermissions());
          dispatch(consumerDutyActions.setIsManagedCase(true));

          notification.success({
            message: response?.data?.message,
            duration: 5,
          });

          if (!isEmpty(customer))
            dispatch({
              type: GET_LOCKED_PLEDGE_DETAILS,
              payload: {
                customerId: customer?.customerId,
                team: selectedTaskOwner,
              },
            });
          else dispatch(userActions.setLockedLoans([]));

          onClose();
        } else
          notification.error({
            message: response?.data?.message || GENERIC_ERROR,
            duration: 5,
          });

        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      notification.error({
        message: error?.response?.data?.error || GENERIC_ERROR,
        duration: 5,
      });
    }
  };

  const onClose = () => {
    dispatch(consumerDutyActions.setIsReviewChangesPopupVisible(false));
  };

  return (
    <Modal
      title="Review Changes"
      open={isVisible}
      width={700}
      centered
      destroyOnClose={true}
      maskClosable={false}
      onCancel={onClose}
      footer={[
        <Row gutter={24} justify="space-between" key="reviewChangesCD">
          <Col>
            <Button
              itemProp="secondary"
              key="manageServiceCancel"
              onClick={onClose}
            >
              {BUTTONS_LABEL.CANCEL}
            </Button>
          </Col>
          <Col>
            <Button
              key="manageServiceConfirm"
              loading={isLoading}
              onClick={onConfirmClick}
            >
              {BUTTONS_LABEL.CONFIRM}
            </Button>
          </Col>
        </Row>,
      ]}
    >
      {allowedServicesForLoan?.length > 0 && (
        <table className="review-changes-table">
          <thead>
            <td style={{ width: '20%' }}>Agreement No.</td>
            <td>Service(s) Allowed</td>
            {selectedTaskOwner === TASK_OWNER_VALUE.AT_RISK && (
              <td style={{ width: '15%' }}>Reset Count</td>
            )}
          </thead>
          <tbody>
            {allowedServicesForLoan.map((loan: any) => (
              <tr key={loan.agreementNumber}>
                <td>{loan.agreementNumber}</td>
                <td>{loan.servicesDesc}</td>
                {selectedTaskOwner === TASK_OWNER_VALUE.AT_RISK && <td>Yes</td>}
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div style={{ marginTop: '20px' }}>
        {!isEmpty(newPledgePermissions) && (
          <table className="review-changes-table">
            <thead>
              <td style={{ width: '20%' }}>
                {BUTTONS_LABEL.NEW_PLEDGE_PERMISSION}
              </td>
              <td></td>
            </thead>
            <tbody>
              {newPledgePermissions?.newPledge ? (
                <>
                  {newPledgePermissions?.endDate ? (
                    <>
                      <tr>
                        <td>{HEADINGS.NEW_PLEDGE_LIMITATION}</td>
                        <td>{SST.ENABLED}</td>
                      </tr>
                      <tr>
                        <td>{HEADINGS.AMOUNT_RANGE}</td>
                        <td>{`£${newPledgePermissions.minimumAmount} - £${newPledgePermissions.maximumAmount}`}</td>
                      </tr>
                      <tr>
                        <td>{HEADINGS.DATE_RANGE}</td>
                        <td>{`${newPledgePermissions.startDate?.format(
                          DATE_FORMAT
                        )} - ${newPledgePermissions.endDate?.format(
                          DATE_FORMAT
                        )}`}</td>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <td>{HEADINGS.NEW_PLEDGE}</td>
                      <td>{SST.ENABLED}</td>
                    </tr>
                  )}
                </>
              ) : (
                <tr>
                  <td>{HEADINGS.NEW_PLEDGE}</td>
                  <td>{SST.DISABLED}</td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </Modal>
  );
};

export default ReviewChangesPopup;
