import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from '@devexpress/dx-react-grid-material-ui';

import { GRID_NO_URL } from 'globalConstants';
import { actions } from 'redux/reducers/admin';

import { ADMIN_GET_USER_PERMISSIONS } from 'action_creators/admin';

import VirtualGrid from 'components/common/virtualGrid/index';

interface UserSummaryProps {
  users: Array<any>;
}

const TableRow = ({ row, onClickTableRow, userId, ...restProps }: any) => {
  const onRowClick = () => {
    onClickTableRow(row);
  };

  const Cell = () => {
    const { tableRow } = restProps;
    const isSelected = tableRow?.row?.userId === userId;

    return (
      <Table.Cell
        onClick={onRowClick}
        {...restProps}
        style={{
          cursor: 'pointer',
          backgroundColor: isSelected && 'var(--solitude)',
        }}
      />
    );
  };

  return <>{Cell()}</>;
};

const UserSummary = ({ users = [] }: UserSummaryProps) => {
  const { selectedUser } = useSelector((state: any) => state.admin);
  const dispatch = useDispatch();

  const [columns] = useState([
    {
      name: 'userName',
      title: 'Employee Name',
      getCellValue: (row: any) => row?.authorizeUsername,
    },
    {
      name: 'employeeId',
      title: 'Employee ID',
      getCellValue: (row: any) => row?.employeeId,
    },
    {
      name: 'active',
      title: 'Active',
      getCellValue: (row: any) =>
        row?.isEnabled ? 'True' : row?.isEnabled === false ? 'False' : '-',
    },
    {
      name: 'displayName',
      title: 'Display Name',
      getCellValue: (row: any) => row?.displayName,
    },
    {
      name: 'roleCustomised',
      title: 'Role Customised',
      getCellValue: (row: any) =>
        row?.isElevated ? 'True' : row?.isElevated === false ? 'False' : '-',
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'userName', width: '230', align: 'Right' },
    { columnName: 'employeeId', width: '130' },
    { columnName: 'active', width: '60' },
    { columnName: 'displayName', width: '250' },
    { columnName: 'roleCustomised', width: '130' },
  ]);

  const onSelectionChange = () => undefined;

  const onClickTableRow = (row: any) => {
    dispatch(actions.initLoading(true));
    dispatch(actions.setSelectedUser(row));
    dispatch({
      type: ADMIN_GET_USER_PERMISSIONS,
      payload: { userId: row?.userId },
    });
  };

  const getRowId = (row: any) => row?.userId;

  return (
    <>
      <VirtualGrid
        virtualScroll={true}
        urlType={GRID_NO_URL}
        getRowId={getRowId}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={users}
        cellComponent={(restProps: any) => (
          <TableRow
            onClickTableRow={onClickTableRow}
            userId={selectedUser?.userId}
            {...restProps}
          />
        )}
        rowSelectionEnable={true}
        showSelectionColumn={false}
        showSelectAll={false}
        onSelectionChange={onSelectionChange}
        selectByRowClick={true}
      />
    </>
  );
};

export default UserSummary;
