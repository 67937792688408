import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import CustomerSmartSearch from '../CustomerSmartSearch';
import NotificationsCenter from 'components/notifications';

import { actions as basketActions } from 'redux/reducers/basket';


const HomePage = () => {
  const { isExitClearBasket } = useSelector((state: any) => state.basket);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isExitClearBasket) {
      dispatch(basketActions.setIsExitClearBasket(false));
    }
  }, [isExitClearBasket, dispatch]);

  return (
    <>
      <CustomerSmartSearch />

      <NotificationsCenter />
    </>
  );
};

export default HomePage;
