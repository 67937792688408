import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Col, Modal, Row, Form, notification } from 'antd';

import { Button } from 'components/common/Button';
import VirtualGrid from 'components/common/virtualGrid/index';

import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';
import { actions as pledgeMngmtActions } from 'redux/reducers/pledgeManagement';
import {
  BUTTON_LABELS,
  PLEDGE_NOTICE_TYPE,
} from 'components/pledgemanagement/constants';
import TextArea from 'components/common/TextArea';

const StyledCols = styled(Col)`
  padding-bottom: 19px;
`;

interface PledgeNotesPopupProps {
  width?: number;
  visible: boolean;
  title: string;
  selectedAgreement: Array<any>;
  noticeType: string;
  onConfirm: (note: string) => void;
  isReleaseEnabled?: boolean;
  onSaveNote?: (note: string) => void;
}

const PledgeNotesPopup = ({
  width = 650,
  visible,
  title,
  selectedAgreement,
  noticeType,
  onConfirm,
  isReleaseEnabled = false,
  onSaveNote = () => {/* do nothing */},
}: PledgeNotesPopupProps) => {
  const { loading, isUpdating } = useSelector(
    (state: any) => state.pledgeManagement
  );

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [notesHistory, setNotesHistory] = useState<Array<any>>([]);
  const [hiddenColumnArray, setHiddenColumnArray] = useState<Array<any>>([]);

  useEffect(() => {
    if (isReleaseEnabled)
      setNotesHistory(
        selectedAgreement[0]?.notes || selectedAgreement[0]?.notesList || []
      );
    else setHiddenColumnArray(['actionDate']);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [columns] = useState([
    {
      name: 'agreement',
      title: 'Agreement',
      getCellValue: (row: any) => row?.loanNumber,
    },
    {
      name: 'amount',
      title: 'Amount',
      getCellValue: (row: any) => '£' + row?.amount?.toFixed(2),
    },
    {
      name: 'startDate',
      title: 'Start Date',
      getCellValue: (row: any) =>
        format(new Date(row?.startDate), FNS_DATE_FORMAT),
    },
    {
      name: 'dueDate',
      title: 'Due Date',
      getCellValue: (row: any) =>
        format(new Date(row?.dueDate), FNS_DATE_FORMAT),
    },
    {
      name: 'actionDate',
      title:
        noticeType === PLEDGE_NOTICE_TYPE.CONFISCATE
          ? 'Confiscated'
          : 'Flagged',
      getCellValue: (row: any) =>
        row?.confiscatedDate
          ? format(new Date(row?.confiscatedDate), FNS_DATE_FORMAT)
          : row?.vulnerableDate
          ? format(new Date(row?.vulnerableDate), FNS_DATE_FORMAT)
          : '',
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'agreement', align: 'center' },
    { columnName: 'amount', align: 'center' },
    { columnName: 'startDate' },
    { columnName: 'dueDate' },
    { columnName: 'actionDate' },
  ]);

  const onCancelClick = () => {
    dispatch(pledgeMngmtActions.setIsPledgeNotesPopupVisible(false));
    dispatch(pledgeMngmtActions.setPledgeNoticeType(''));
  };

  const onSaveNoteClick = () => {
    const fieldValues = form.getFieldsValue();
    if (!fieldValues.noteText) {
      return notification.error({
        message: 'Please add a note.',
        duration: 5,
      });
    }

    onSaveNote(fieldValues.noteText);
  };

  const onConfirmClick = () => {
    const fieldValues = form.getFieldsValue();
    if (!fieldValues.noteText && !isReleaseEnabled) {
      return notification.error({
        message: 'Please add a note.',
        duration: 5,
      });
    }
    onConfirm(!isReleaseEnabled ? fieldValues.noteText : '');
  };

  return (
    <>
      <Modal
        open={visible}
        centered
        width={width}
        title={title}
        className="mark-vulnerable-wrapper"
        destroyOnClose={true}
        maskClosable={false}
        closable={false}
        onCancel={onCancelClick}
        footer={[
          <Row key="markVulnerablePopup">
            <Col span={4} style={{ textAlign: 'left' }}>
              <Button
                itemProp="secondary"
                key="markVulnerablePopupCancel"
                onClick={onCancelClick}
              >
                {BUTTON_LABELS.CLOSE}
              </Button>
            </Col>
            <Col span={10} offset={10}>
              <Button
                loading={loading}
                itemProp="secondary"
                key="markVulnerableConfirm"
                onClick={() => onConfirmClick()}
              >
                {isReleaseEnabled
                  ? BUTTON_LABELS.RELEASE
                  : BUTTON_LABELS.CONFIRM}
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <>
          <Row>
            <Col className="heading">
              Customer: {selectedAgreement[0].customerName}
            </Col>
          </Row>
          <Row>
            <StyledCols span={24}>
              <VirtualGrid
                urlType={GRID_NO_URL}
                columnsProps={columns}
                tableColumnExtensionsProps={tableColumnExtensions}
                rowData={selectedAgreement}
                hiddenColumnNames={hiddenColumnArray}
              />
            </StyledCols>
          </Row>
          <Row>
            <Col span={24}>
              <label className="heading">Notes (Internal Use Only)</label>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form layout="vertical" form={form}>
                <Form.Item
                  label=""
                  name="noteText"
                  className="label-control form-textarea"
                >
                  <TextArea
                    showCount
                    maxLength={512}
                    placeholder="Add a note"
                    history={notesHistory}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          {isReleaseEnabled && (
            <Row>
              <Col span={24}>
                <Button
                  loading={isUpdating}
                  itemProp="secondary"
                  onClick={onSaveNoteClick}
                >
                  {BUTTON_LABELS.SAVE_NOTE}
                </Button>
              </Col>
            </Row>
          )}
        </>
      </Modal>
    </>
  );
};

export default PledgeNotesPopup;
