import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  COLORS_CODE_TABLE,
  GRID_NO_URL,
  RESPONSE_STATUS,
} from 'globalConstants';
import { Spin } from 'antd';
import { getPledgeAgreementItems } from 'services/pledge';
import VirtualGrid from 'components/common/virtualGrid/index';
import ProgressTick from 'components/common/ProgressCheck';
import ImageLoader from 'components/common/ImageLoader';
import TableRow from '../../formatedCell';
import { SERVICE_NAME, SUCCESSFUL } from 'components/checkout/constants';
import { downloadAffidavit } from 'services/checkout';
import { sendToPrinter } from 'services/user';
import { actions as checkoutActions } from 'redux/reducers/checkout';

const CheckoutSurplus = (props: any) => {
  const rows = props?.item || [];
  const dispatch = useDispatch();
  const {
    user: { selectedStore },
  } = useSelector((state: any) => {
    return {
      user: state.user,
    };
  });

  //Count for affidavit
  const printCountAffi = rows.reduce(
    (o: any, item: any) => ({ ...o, [item.serviceId]: 0 }),
    {}
  );

  const printAffidavit = async (loanNumber: any, loan: any, serviceId: any) => {
    try {
      const params = {
        ...loan,
        ...selectedStore,
      };
      const affidavit = await downloadAffidavit(params);
      if (affidavit?.data) {
        const printerStatus = await sendToPrinter(
          affidavit,
          'printcontract',
          'Affidavit',
          {
            Simplex: true,
          }
        );
        if (printerStatus === RESPONSE_STATUS.SERVICE_UNAVAILABLE)
          dispatch(checkoutActions.setIsPrinterError(true));
        else
          dispatch(
            checkoutActions.updateSurplusContractPrintStatusCollection(
              serviceId
            )
          );
      }
    } catch (e: any) {
      dispatch(checkoutActions.setContratPrintingError(true));
    }
  };

  const [columns] = useState([
    {
      name: 'serviceLoanNumber',
      title: 'Processed',
      getCellValue: (row: any) => {
        if (
          row?.surplus?.response?.loanNumber &&
          printCountAffi[row?.serviceId] < 1 &&
          row?.surplus?.request?.surplusItem?.isPrintAffidavit &&
          String(row?.serviceStatusText).toLowerCase() === SUCCESSFUL
        ) {
          printCountAffi[row?.serviceId]++;
          printAffidavit(
            row?.surplus?.response?.loanNumber,
            row?.surplus?.request?.surplusItem,
            row?.serviceId
          );
        }
        return ProgressTick(
          row?.surplus?.response?.loanNumber,
          row?.serviceStatusText
        );
      },
    },
    {
      name: 'loanNumber',
      title: 'Agreement Number',
      getCellValue: (row: any) =>
        row?.surplus?.request?.surplusItem?.loanNumber,
    },
    {
      name: 'serviceName',
      title: 'Service',
      getCellValue: () => SERVICE_NAME.SURPLUS,
    },
    {
      name: 'affidavitAmount',
      title: 'Affidavit Fee',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.surplus?.request?.surplusItem?.affidavitAmount,
    },
    {
      name: 'surplusAmount',
      title: 'Surplus Payment',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.surplus?.request?.surplusItem?.surplusAmount,
    },
    {
      name: 'toPay',
      title: 'To Pay',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.surplus?.request?.surplusItem?.toPayAmount || 0,
    },
    {
      name: 'toTake',
      title: 'To Take',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.surplus?.request?.surplusItem?.toTakeAmount || 0,
    },
    {
      name: '',
      title: '',
      getCellValue: (row: any) => '',
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'serviceLoanNumber', width: '15%' },
    { columnName: 'loanNumber', width: '16%' },
    { columnName: 'serviceName', width: '14%' },
    { columnName: 'affidavitAmount', width: '12%', align: 'right' },
    { columnName: 'surplusAmount', width: '13%', align: 'right' },
    { columnName: 'toPay', width: '10%', align: 'right' },
    { columnName: 'toTake', width: '10%', align: 'right' },
    { columnName: '', width: '6%', align: 'right' },
  ]);

  const [formattedColumns] = useState([
    'toPay',
    'toTake',
    'surplusAmount',
    'affidavitAmount',
  ]);

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        formattedColumns={formattedColumns}
        rowData={rows}
        tableColumnExtensionsProps={tableColumnExtensions}
        headerBackground={COLORS_CODE_TABLE.PICKUPRENEW} //same color as pickup
        cellComponent={TableRow}
        expandableComponent={SurplusExpandableTable}
        detailRowIconPlacement={true}
      />
    </>
  );
};

export const SurplusExpandableTable = ({ row }: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [rowData, setRowData] = useState<Array<any>>([]);

  useEffect(() => {
    getPledgeDetails(row?.surplus?.request?.surplusItem?.loanId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getPledgeDetails = async (id: any) => {
    try {
      setLoading(true);
      const response = await getPledgeAgreementItems(id);
      if (response?.data?.items) {
        setRowData(response?.data?.items);
        setLoading(false);
      }
    } catch (e: any) {
      console.log(e);
      setLoading(false);
    }
  };

  const [columns] = useState([
    {
      name: 'itemImage',
      title: 'Item Image',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row.id}?isThumnails=false`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'description',
      title: 'Description',
      getCellValue: (row: any) => row?.description,
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) => `${row?.weight?.toFixed(2)}g`,
    },
    {
      name: 'amount',
      title: 'Amount',
      type: 'currency',
      getCellValue: (row: any) => row?.amount,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'itemImage', width: 130 },
    { columnName: 'description', width: 230 },
    { columnName: 'weight', width: 120, align: 'right' },
    { columnName: 'amount', width: 120, align: 'right' },
  ]);

  const [formattedColumns] = useState(['amount']);

  return (
    <Spin spinning={loading}>
      <div className="expandable-table-rows">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          formattedColumns={formattedColumns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={rowData}
        />
      </div>
    </Spin>
  );
};

export default CheckoutSurplus;
