import { downloadCheckCashingContracts } from 'services/checkout';
import { sendToPrinter } from 'services/user';

export default function preCheckcashing(basketId: string) {
  const download = async (basketId: string) => {
    try {
      const result = await downloadCheckCashingContracts(basketId);
      if (result?.data) {
        sendToPrinter(result, 'printcontract', '_CheckcashingContract');
      }
    } catch (e: any) {
      console.log('download pre contract', e);
    }
  };
  download(basketId);
  return true;
}
