import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Col, Layout, Row } from 'antd';
import styled from 'styled-components';
import VulnerableSummary from './vulnerableSummary';
import FooterButtons from 'components/common/footerButtons';
import {
  PLEDGE_ACTION_NOTES_TITLES,
  PLEDGE_MANAGEMENT_TITLES,
  PLEDGE_NOTICE_TYPE,
  RELEASE_PLEDGE_POPUP,
} from 'components/pledgemanagement/constants';
import {
  GET_VULNERABLE_PLEDGES,
  GET_VULNERABLE_NOTES,
  ADD_VULNERABLE_NOTE,
  RELEASE_VULNERABLE_PLEDGE,
} from 'action_creators/pledgeManagement';
import PledgeNotesPopup from '../common/pledgeNotesPopup';
import PledgeReleasePopup from '../common/pledgeReleasePopup';
import { actions as pledgeMngmtActions } from 'redux/reducers/pledgeManagement';

const StyledContentLayout = styled(Layout)`
  background: var(--white);
  padding: 10px 20px;
  border-radius: 5px;
`;

const Vulnerable = () => {
  const {
    pledgeManagement: {
      isPledgeNotesPopupVisible,
      selectedVulnerableAgreement,
      isPledgeReleasePopupVisible,
    },
    user: { user },
  } = useSelector((state: any) => {
    return {
      pledgeManagement: state.pledgeManagement,
      user: state.user,
    };
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onConfirmExitOnlyClick = () => {
    navigate('/');
  };

  const [selectedLoanId, setSelectedLoanId] = useState<number>(0);
  const [selectedCustomerName, setSelectedCustomerName] = useState<string>('');
  const [selectedCustomerId, setSelectedCustomerId] = useState<number>(0);

  useEffect(() => {
    dispatch({ type: GET_VULNERABLE_PLEDGES });
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(pledgeMngmtActions.clearAuctions());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onAgreementSelection = (row: any) => {
    dispatch({ type: GET_VULNERABLE_NOTES, payload: row?.loanId });
    setSelectedLoanId(row?.loanId);
    setSelectedCustomerName(row?.customerName);
    setSelectedCustomerId(row?.customerId);
  };

  const onSaveNotesClick = async (note: string) => {
    const params = {
      loanId: selectedLoanId,
      note,
      userName: user?.displayName,
    };
    dispatch({ type: ADD_VULNERABLE_NOTE, payload: params });
  };

  const onReleaseClick = (note?: string) => {
    dispatch(pledgeMngmtActions.setIsPledgeReleasePopupVisible(true));
    dispatch(pledgeMngmtActions.setIsPledgeNotesPopupVisible(false));
  };

  const onConfirmRelease = (note: string) => {
    const params = {
      loanId: selectedLoanId,
      ReasonNote: note,
      userName: user?.displayName,
    };
    dispatch({
      type: RELEASE_VULNERABLE_PLEDGE,
      payload: params,
      customerId: selectedCustomerId,
    });
  };

  return (
    <>
      <StyledContentLayout className="vulnerable-wrapper">
        <Row className="header">
          <Col span={24}>{PLEDGE_MANAGEMENT_TITLES.VULNERABLE}</Col>
        </Row>
        <Row className="content">
          <Col span={24}>
            <div className="table-wrapper">
              <VulnerableSummary
                onRowClick={onAgreementSelection}
                selectedLoanId={selectedLoanId}
              />
            </div>
          </Col>
        </Row>
      </StyledContentLayout>

      <FooterButtons
        enableExtraClass
        isExitOnly
        extraButtonItemProp="secondary"
        enableExitPopup={false}
        onConfirmExitClick={onConfirmExitOnlyClick}
      />

      {isPledgeNotesPopupVisible && (
        <PledgeNotesPopup
          visible={isPledgeNotesPopupVisible}
          width={850}
          isReleaseEnabled={true}
          title={PLEDGE_ACTION_NOTES_TITLES.VULNERABLE_NOTES_TITLE}
          noticeType={PLEDGE_NOTICE_TYPE.VULNERABLE}
          selectedAgreement={selectedVulnerableAgreement}
          onConfirm={onReleaseClick}
          onSaveNote={onSaveNotesClick}
        />
      )}

      {isPledgeReleasePopupVisible && (
        <PledgeReleasePopup
          visible={isPledgeReleasePopupVisible}
          customerName={selectedCustomerName}
          title={RELEASE_PLEDGE_POPUP.VULNERABLE_TITLE}
          content={RELEASE_PLEDGE_POPUP.CONTENT}
          noticeType={PLEDGE_NOTICE_TYPE.VULNERABLE}
          releaseValidationMsg={RELEASE_PLEDGE_POPUP.VALIDATION_ERROR}
          selectedAgreement={selectedVulnerableAgreement}
          onConfirm={onConfirmRelease}
        />
      )}
    </>
  );
};

export default Vulnerable;
