import React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';

const StyledContentLayout = styled(Layout)`
  background: var(--white);
  padding: 15px 30px;
  border-radius: 5px;
`;

const AuditManager = () => {
  return (
    <>
      <StyledContentLayout className="ledger-wrapper">
        Audit Manager comming soon
      </StyledContentLayout>
    </>
  );
};

export default AuditManager;
