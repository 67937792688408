import React from 'react';
import { Col, Modal, Row } from 'antd';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { actions } from 'redux/reducers/customer';
import { EXIT, EXIT_ONLY } from './constants';
import { Button } from '../Button';

const StyledDiv = styled.div`
  text-align: center;
  font-size: var(--font-size-16);
  line-height: var(--font-size-16);
  padding: 5px 0 5px 0;
`;

const StyledModal = styled(Modal)`
  & .ant-modal-title {
    text-align: center;
    color: var(--red);
  }
`;

const ExitPopup = ({
  visible,
  setExitOnlyPopupVisible,
  onConfirmExitClick,
}: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onCancelClick = () => {
    setExitOnlyPopupVisible();
  };

  const onConfirmClick = () => {
    setExitOnlyPopupVisible();
    dispatch(actions.clearAll({})); //clear customer session
    navigate(`/`);
    onConfirmExitClick();
  };

  return (
    <>
      <StyledModal
        title={EXIT}
        open={visible}
        width={420}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        closable={false}
        footer={[
          <Row gutter={24} justify="center" key="exitPopup">
            <Col>
              <Button
                key="confirmExit"
                itemProp="secondary"
                onClick={onConfirmClick}
              >
                Yes
              </Button>
            </Col>
            <Col>
              <Button
                itemProp="secondary"
                key="cancelExit"
                onClick={onCancelClick}
              >
                No
              </Button>
            </Col>
          </Row>,
        ]}
        centered
      >
        <Row>
          <Col span={24}>
            <StyledDiv>{EXIT_ONLY.LINE_ONE}</StyledDiv>
            <StyledDiv>{EXIT_ONLY.LINE_TWO}</StyledDiv>
          </Col>
        </Row>
      </StyledModal>
      ;
    </>
  );
};

export default ExitPopup;
