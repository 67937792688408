import React, { useEffect } from 'react';
import styled from 'styled-components';
import './index.less';
import { Form, Col, Row, Input } from 'antd';
import FooterButtons from 'components/common/footerButtons';
import { FOOTER_BUTTONS_AND_TEXT } from 'globalConstants';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/reducers/admin';
import { USER_MANAGER } from '../../constants';

const StyledCol = styled(Col)`
  margin: 0px;
`;

const StyledAuthRow = styled(Row)`
  gap: 20px;
  margin-bottom: 5px;
`;

interface AuthorizationFormProps {
  addonBeforeText: string;
  authorizationHandler: (authorisationHeaders: any) => void;
  setDisableVoidTransactionBtn: (authorisationHeaders: any) => void;
  disableVoidTransactionBtn: boolean;
  setAuthFormData: any;
  authFormData: object;
  isUserRoleITAdmin: any;
  clearAllFields: any;
  setClearAllFields: any;
}

const AuthorizationForm = ({
  authorizationHandler,
  disableVoidTransactionBtn,
  setDisableVoidTransactionBtn,
  isUserRoleITAdmin,
  clearAllFields,
  setClearAllFields,
}: AuthorizationFormProps) => {
  const [form] = Form.useForm();
  const {
    admin: {
      selectedCheckboxCount,
      selectedEmpId,
      serviceIdList,
      basketIdList,
    },
    user: { roles },
  } = useSelector((state: any) => {
    return {
      admin: state.admin,
      user: state.user.user,
    };
  });

  const dispatch = useDispatch();

  const onConfirmClick = () => {
    const authorisationHeaders = {
      authorisationUserName: form.getFieldValue('authorisationUserId'),
      authorisationPassword: form.getFieldValue('authorisationPassword'),
      reasonToVoid: form.getFieldValue('reasonToVoid'),
      basketId: basketIdList?.[0],
    };
    authorizationHandler(authorisationHeaders);
    form.setFieldsValue({
      authorisationUserId: '',
      authorisationPassword: '',
      reasonToVoid: '',
    });
  };

  const onConfirmExitOnlyClick = () => {
    dispatch(actions.setBasketIdList([]));
    dispatch(actions.setServiceIdList([]));
  };
  useEffect(() => {
    form.setFieldsValue({
      authorisationUserId: selectedEmpId,
      authorisationPassword: '',
      reasonToVoid: '',
    });
  }, [selectedCheckboxCount]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    clearAllFields &&
      form.setFieldsValue({
        authorisationUserId: '',
        authorisationPassword: '',
        reasonToVoid: '',
      });
    setClearAllFields(false);
  }, [clearAllFields]); // eslint-disable-line react-hooks/exhaustive-deps
  const onFormLayoutChange = (any: string) => {
    const authorisationUserId = form.getFieldValue('authorisationUserId');
    const authorisationPassword = form.getFieldValue('authorisationPassword');
    const reasonToVoid = form.getFieldValue('reasonToVoid');

    const isUserRoleManager = roles?.find(
      (obj: any) => obj.roleId === USER_MANAGER.ROLE_IDS.MANAGER
    );

    if (
      String(authorisationUserId) &&
      authorisationPassword &&
      reasonToVoid &&
      isUserRoleManager
    ) {
      setDisableVoidTransactionBtn(false);
    } else {
      setDisableVoidTransactionBtn(true);
    }
  };

  return (
    <>
      <StyledCol span={24} className="auth-section">
        <Form
          name="authorizationForm"
          form={form}
          onFinish={onConfirmClick}
          onValuesChange={onFormLayoutChange}
          autoComplete="off"
        >
          <Row>
            <Col span={24}>
              {(basketIdList?.length > 0 || serviceIdList?.length > 0) && (
                <StyledAuthRow>
                  <Col span={15}>
                    <Form.Item
                      name="reasonToVoid"
                      className="label-control"
                      rules={[
                        { required: true, message: 'Reason is required' },
                      ]}
                    >
                      <Input
                        className="input-control"
                        maxLength={200}
                        autoComplete="off"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="authorisationUserId"
                      className="label-control"
                    >
                      <Input
                        className={`input-control ${
                          isUserRoleITAdmin ? '' : 'input-background'
                        }`}
                        disabled={!isUserRoleITAdmin}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="authorisationPassword"
                      className="label-control"
                      rules={[
                        { required: true, message: 'Password is required' },
                      ]}
                    >
                      <Input
                        type="password"
                        className="input-control"
                        autoComplete="off"
                      />
                    </Form.Item>
                  </Col>
                </StyledAuthRow>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FooterButtons
                enableExtraClass
                isExitOnly
                onConfirmExitClick={onConfirmExitOnlyClick}
                onProceedClick={onConfirmClick}
                proceedButtonText={FOOTER_BUTTONS_AND_TEXT.PROCEED_TO_VOID}
                isDisabled={disableVoidTransactionBtn}
              />
            </Col>
          </Row>
        </Form>
      </StyledCol>
    </>
  );
};

export default AuthorizationForm;
