import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Modal, Col, Checkbox, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { postReleaseCustomerAccount } from 'services/consumerDuty';
import { RESET_CUSTOMER_DATA } from 'action_creators/consumerDuty';
import { Button } from 'components/common/Button';
import {
  BUTTONS_LABEL,
  CONSUMER_DUTY,
  SST,
  VULNERABILITYOWNER,
} from '../constants';
import { isNull } from 'lodash';
import { GENERIC_ERROR, PROFANITY_CHECK } from 'globalConstants';
import TextArea from 'components/common/TextArea';
import ControlledAlert from 'components/common/ControlledAlert';

const StyledRow = styled(Row)`
  padding: 22px 0 30px 0;
`;

interface ProfanityError {
  profanity: boolean;
  badWords: Array<string>;
}

const ReleaseAccountModal = ({ visible, onClose }: any) => {
  const {
    user: { selectedStore, user },
    customer: { customer },
  } = useSelector((state: any) => {
    return {
      user: state.user,
      customer: state.customer,
    };
  });

  const dispatch = useDispatch();

  const [releaseNotes, setReleaseNotes] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isConsentGranted, setConsentGranted] = useState<any>(null);
  const [isConsent, setIsContent] = useState<boolean>(false);
  const [profanityError, setProfanityError] = useState<ProfanityError>({
    profanity: false,
    badWords: [''],
  });
  const [isProfanity, setProfanity] = useState<boolean>(false);

  const onCheckboxChange = (event: any) => {
    const { value } = event.target;

    setConsentGranted(value);
    if (value === 1) setIsContent(true);
    else setIsContent(false);
  };

  const onReleaseNotesChange = (event: any) => {
    const { value } = event.target;
    setReleaseNotes(value);
  };

  const onSaveButtonClick = async () => {
    const params = {
      CustomerId: customer?.customerId,
      CustomerConsent: isConsent,
      StoreId: selectedStore?.storeId,
      UserId: user?.userId,
      Resolution: releaseNotes,
      VulnerabilityOwner: VULNERABILITYOWNER.STORE_MANAGER,
      VulnerabilityOriginator: CONSUMER_DUTY.STORE,
    };

    try {
      if (profanityError.profanity) {
        setProfanity(true);
      } else {
        setLoading(true);
        const response = await postReleaseCustomerAccount(params);
        if (response?.data) {
          setLoading(false);
          dispatch({ type: RESET_CUSTOMER_DATA });
          onClose();
        }
      }
    } catch (e: any) {
      setLoading(false);
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  const handleProfanity = (profanity: boolean, badWords: Array<string>) => {
    setProfanityError({ profanity, badWords });
  };

  return (
    <Modal
      open={visible}
      width={844}
      mask={false}
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
      style={{ top: '25vh', right: '12vw' }}
      className="release-modal-wrapper"
      footer={
        <Row justify="space-between">
          <Button itemProp="secondary" onClick={onClose}>
            {BUTTONS_LABEL.CLOSE}
          </Button>
          <Button
            disabled={!releaseNotes}
            loading={loading}
            onClick={onSaveButtonClick}
          >
            {BUTTONS_LABEL.SAVE}
          </Button>
        </Row>
      }
    >
      <Row className="title">Release customer from store assisted status</Row>
      <Row className="title-msg">
        This action will remove the store assisted flag
      </Row>
      <StyledRow className="heading">
        <span>Please read to customer</span>
        <br />
        <span style={{ fontSize: '14px' }}>
          In order for us to use this information, do you give me consent to
          make a note of what we have discussed on your account.
        </span>
        <Col span={8} style={{ padding: '5px 0px' }}>
          <Checkbox
            value={1}
            checked={isConsentGranted}
            onChange={onCheckboxChange}
            className="explicit-consent-granted"
          >
            <span className="consent-row">{SST.EXPLICIT_CONSENT_GRANTED}</span>
          </Checkbox>
        </Col>
        <Col span={8} style={{ padding: '5px 0px' }}>
          <Checkbox
            value={0}
            checked={!isNull(isConsentGranted) && !isConsentGranted}
            onChange={onCheckboxChange}
            className="explicit-consent-refused"
          >
            <span className="consent-row">{SST.EXPLICIT_CONSENT_REFUSED}</span>
          </Checkbox>
        </Col>
      </StyledRow>
      <Row className="heading">Reason to Release</Row>
      <Row>
        <Col span={24}>
          <TextArea
            value={releaseNotes}
            rows={8}
            maxLength={600}
            autoComplete="on"
            spellCheck
            profanityCheck
            profanityExists={handleProfanity}
            onChange={onReleaseNotesChange}
          />
        </Col>
      </Row>
      {isProfanity && (
        <ControlledAlert
          id="releaseAccount"
          width={600}
          visible={isProfanity}
          message={PROFANITY_CHECK.REMOVE_PROFANITY}
          secondaryMessage={
            <>
              {PROFANITY_CHECK.PROFANITY_WORDS}
              <b>&nbsp;{profanityError.badWords.join(', ')}</b>
            </>
          }
          onClick={() => {
            setProfanity(false);
          }}
          yesButtonText={BUTTONS_LABEL.REMOVE_PROFANITY}
          isCancelBtn={false}
        />
      )}
    </Modal>
  );
};

export default ReleaseAccountModal;
