import React, { useEffect, useState } from 'react';
import { Layout, Row, Col } from 'antd';
import { format } from 'date-fns';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import Avatar from 'antd/lib/avatar/avatar';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth } from '../../../providers/AuthProvider';
import { ROUTE_CONFIG, BASKET_STATUS } from 'globalConstants';
import { isEmpty } from 'lodash';
import UserDrawer from '../UserDrawer/UserDrawer';
import CashTransferPopup from 'components/cashManagement/CashTransfer';
import { actions as cashManagementActions } from 'redux/reducers/cashManagement';
import { actions as userActions } from 'redux/reducers/user';
import AccessoriesDrawer from '../AccessoriesDrawer';
import RecallMenu from '../RecallMenu';

const StyledLayoutHeader = styled(Layout.Header)<{ $collapsed: boolean }>`
  background-color: var(--white);
  padding: 0 18px 0 0;
  white-space: nowrap;
  border: 1px solid #dfe0e3;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  height: 60px;
  position: fixed;
  z-index: 10;
  width: ${(props) =>
    props.$collapsed ? 'calc(100vw - 84px)' : 'calc(100vw - 244px)'};
`;
const StyledRowHeader = styled(Row)`
  flex-wrap: nowrap;
`;

const StyledColLeft = styled(Col)``;

const StyledColCenter = styled(Col)`
  text-align: center;
`;

const StyledColRight = styled(Col)`
  text-align: left;
`;

const StyledColImageContainer = styled(Col)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledColTitleContainer = styled(Col)`
  font-size: 1.6rem;
  margin-left: 15px;
  margin-right: 16px;
`;

const StyledColIcon = styled.img`
  margin: 0 11px;
  cursor: pointer;
`;

const StyledWeatherIcon = styled.img`
  margin: 0;
`;

const StyledImgAvatar = styled(Avatar)`
  margin-bottom: 8px;
  background: var(--white);
  &:hover {
    background: var(--solitude);
  }
`;

const StyledDivSeparator = styled.div`
  width: 2px;
  background-color: var(--header-divider);
  height: 30px;
`;

const StyledDivUserNameContainer = styled.div`
  text-align: right;
  cursor: pointer;
`;

const StyledAvatar = styled(Avatar)`
  border: 2px solid var(--avatar-border);
`;

const StyledWeatherUnit = styled.span`
  font-size: 1.2rem;
  position: relative;
  top: -0.1rem;
  padding: 0 3px;
  color: var(--black);
`;

const StyledColBranchName = styled(Col)`
  font-weight: 500;
  font-size: 18px;
`;

const getTimeAndStoreHeader = (): string => {
  return `${format(new Date(), 'hh:mm aaaa EEEE, dd LLLL yyyy')}`;
};

function HeaderContent(props: any) {
  const { collapsed, toggle } = props;
  const [userDetailsVisible, setUserDetaillsVisible] = useState(false);
  const [isAccessoriesDrawerVisible, setIsAccessoriesDrawerVisible] =
    useState(false);
  const [currentDateTime, setCurrentDateTime] = useState<string>(
    getTimeAndStoreHeader()
  );

  const { userFullName, userProfilePhoto } = useAuth();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    user: { selectedStore, isRecallMenuVisible },
    basket: { basket },
    checkout: { basket: checkoutBasket },
    cashManagement: { isCashTransferPopupVisible },
  } = useSelector((state: any) => {
    return {
      user: state.user,
      basket: state.basket,
      checkout: state.checkout,
      cashManagement: state.cashManagement,
    };
  });

  const onUserNameClick = () => {
    setUserDetaillsVisible(!userDetailsVisible);
  };

  const onAccessoriesDrawerClick = () => {
    setIsAccessoriesDrawerVisible(!isAccessoriesDrawerVisible);
  };

  const onBasketIconClick = () => {
    navigate(`/${ROUTE_CONFIG.BASKET}`);
  };

  const onCashTransferClick = () => {
    dispatch(cashManagementActions.setCashTransferPopupVisibility(true));
  };
  const setCashTransferPopupVisible = () => {
    dispatch(cashManagementActions.setCashTransferPopupVisibility(false));
  };

  const onRecallBasketClick = () => {
    dispatch(userActions.setRecallMenuVisibility(true));
  };

  useEffect(() => {
    setInterval(() => {
      setCurrentDateTime(getTimeAndStoreHeader());
    }, 5000);
  }, []);
  return (
    <>
      <StyledLayoutHeader role="header" $collapsed={collapsed}>
        <StyledRowHeader>
          <StyledColLeft span={9}>
            <Row>
              <StyledColImageContainer>
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: 'trigger',
                    title: 'collapsed menu',
                    onClick: toggle,
                    style: { marginLeft: '20px', fontSize: '2.2rem' },
                  }
                )}
              </StyledColImageContainer>

              <StyledColTitleContainer>
                <StyledImgAvatar size={35}>
                  <StyledWeatherIcon
                    alt="weather"
                    src={require('../../../assets/images/weather.svg').default}
                  />
                </StyledImgAvatar>
                15
                <StyledWeatherUnit>°C | °F</StyledWeatherUnit>
              </StyledColTitleContainer>
              <Col>{currentDateTime}</Col>
            </Row>
          </StyledColLeft>
          <StyledColCenter span={6}>
            <Row justify="center" align="middle" gutter={16}>
              <StyledColBranchName>
                {selectedStore.storeId
                  ? `${selectedStore.storeName} - ${selectedStore.storeId}`
                  : ''}
              </StyledColBranchName>
            </Row>
          </StyledColCenter>
          <StyledColRight span={9}>
            <Row justify="end" align="middle" gutter={24}>
              <Col>
                <StyledDivSeparator />
              </Col>
              <StyledColIcon
                alt="recall"
                src={require('../../../assets/images/recall_icon.svg').default}
                onClick={onRecallBasketClick}
              />
              {(!isEmpty(basket) &&
                !isEmpty(basket?.basketId) &&
                basket?.basketId !== null &&
                basket.basketStatus === BASKET_STATUS.PENDING &&
                isEmpty(checkoutBasket)) ||
              checkoutBasket.basketStatus === BASKET_STATUS.PENDING ? (
                <StyledColIcon
                  alt="basket"
                  src={require('../../../assets/images/basket.svg').default}
                  onClick={onBasketIconClick}
                />
              ) : null}
              <StyledColIcon
                alt="cashTransfer"
                src={
                  require('../../../assets/images/cash_transfer.svg').default
                }
                onClick={onCashTransferClick}
              />
              <StyledColIcon
                alt="accessories"
                src={
                  require('../../../assets/images/Accessories_ic.svg').default
                }
                onClick={onAccessoriesDrawerClick}
              />
              <Col>
                <StyledDivSeparator />
              </Col>
              <Col>
                <StyledDivUserNameContainer onClick={onUserNameClick}>
                  {userFullName}
                </StyledDivUserNameContainer>
                <UserDrawer
                  visible={userDetailsVisible}
                  onClose={onUserNameClick}
                ></UserDrawer>
              </Col>
              <StyledColImageContainer onClick={onUserNameClick}>
                <StyledAvatar
                  src={
                    userProfilePhoto ||
                    require('../../../assets/images/guest_thumbnail.svg')
                      .default
                  }
                  size={40}
                />
              </StyledColImageContainer>
            </Row>
          </StyledColRight>
        </StyledRowHeader>
      </StyledLayoutHeader>

      {isCashTransferPopupVisible && (
        <CashTransferPopup
          visible={isCashTransferPopupVisible}
          setCashTransferPopupVisible={() => setCashTransferPopupVisible()}
        />
      )}
      {isAccessoriesDrawerVisible && (
        <AccessoriesDrawer
          visible={isAccessoriesDrawerVisible}
          onClose={onAccessoriesDrawerClick}
        />
      )}

      {isRecallMenuVisible && <RecallMenu />}
    </>
  );
}

export default HeaderContent;
