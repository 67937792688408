import {
  DELETE_BASKET_PLEDGE_ITEMS,
  DELETE_BASKET_RETAIL_ITEMS,
  DELETE_BASKET_FX_ITEMS,
  DELETE_BASKET_PICKUP_RENEW_ITEMS,
  DELETE_BASKET_CHEQUE_CASHING_ITEMS,
  DELETE_BASKET_WESTERN_UNION_ITEMS,
  DELETE_BASKET_PURCHASE_RETURN_ITEMS,
  DELETE_BASKET_SURPLUS_ITEMS,
} from 'action_creators/basket';
import { COLORS_CODE } from 'globalConstants';
export const REMOVE = 'Remove'; //TODO - to be changed on actual implementation
export const ADD_PLEDGE = 'Add';
export const PAY_TO_CUSTOMER = 'Pay to Customer';
export const EXIT = 'Exit';
export const CHECKOUT = 'Checkout';
export const BASKET_TABS = {
  1: 'Pledge',
  2: 'Purchase',
  4: 'Retail',
  5: 'Travel Money',
  8: 'Pickup/Renew',
  6: 'Cheque Cashing',
  9: 'Western Union',
  11: 'Purchase Return',
  16: 'Surplus',
};

export const BASKET_TABS_ORDER = {
  1: 1, //Pledge
  2: 16, //Surplus
  3: 2, //Purchase
  4: 8, //Pickup/Renew
  5: 11, //Purchase Return
  6: 4, //Retail
  // 7: X //Layway
  8: 5, //Travel Money
  9: 9, //Western Union
  10: 6, //Cheque Cashing
};

export const BASKET_TABS_COLORS = {
  1: COLORS_CODE.PLEDGE,
  2: COLORS_CODE.PURCHASE,
  4: COLORS_CODE.RETAIL_SHOPING,
  5: COLORS_CODE.TRAVELMONY,
  8: COLORS_CODE.PICKUPRENEW,
  6: COLORS_CODE.CHEQUE_CASHING,
  9: COLORS_CODE.WESTERN_UNION,
  11: COLORS_CODE.PLEDGE_MANAGEMENT,
  16: COLORS_CODE.SURPLUS,
};

export const BASKET_SERVICE_TYPE = {
  PLEDGE: 1,
  PURCHASE: 2,
  RETAIL: 4,
  FX: 5,
  PICKUPRENEW: 8,
  CHEQUECASHING: 6,
  WU: 9,
  PURCHASERETURN: 11,
  SURPLUS: 16,
};

export const ENABLE_CHILD_TABS = ['Pledge', 'Purchase'];
export const ENABLE_NOT_INCLUDE_ITEMS_TABLE = [
  'Travel Money',
  'Pickup/Renew',
  'Cheque Cashing',
  'Western Union',
  'Purchase Return',
  'Surplus',
]; //If items = [] object not present only bind with basket service request object

export const BASKET_ADD_PLEDGE = {
  CREATE_NEW_PLEDGE: 'Create New',
  ADD_NEW_ITEM_TO_PLEDGE: 'Add Item(s) to this',
};

export const ENABLE_ADD_ANOTHER_BUTTON = ['Cheque', 'Western Union'];

export const REMOVE_ACTION_TYPES = {
  Pledge: DELETE_BASKET_PLEDGE_ITEMS,
  Purchase: DELETE_BASKET_PLEDGE_ITEMS,
  Retail: DELETE_BASKET_RETAIL_ITEMS,
  'Travel Money': DELETE_BASKET_FX_ITEMS,
  'Cheque Cashing': DELETE_BASKET_CHEQUE_CASHING_ITEMS,
  'Pickup/Renew': DELETE_BASKET_PICKUP_RENEW_ITEMS,
  'Western Union': DELETE_BASKET_WESTERN_UNION_ITEMS,
  'Purchase Return': DELETE_BASKET_PURCHASE_RETURN_ITEMS,
  Surplus: DELETE_BASKET_SURPLUS_ITEMS,
};

export const REMOVE_PLEDGE_ALERT =
  'Removing this pledge will permanently delete the contained items. Are you sure you wish to remove the pledge as you will not be able to retrieve it?';
export const REMOVE_PURCHASE_ALERT =
  'Removing this purchase will permanently delete the contained items. Are you sure you wish to remove the purchase as you will not be able to retrieve it?';
export const REMOVE_PICKUP_ALERT =
  'Any actions selected for this agreement will be removed. Are you sure you wish to remove this agreement?';
export const REMOVE_ALERT =
  'Are you sure you want to remove this item from the basket?';
export const REMOVE_CHEQUE_CASHING_ALERT =
  'Removing this cheque cashing will permanently delete the contained items. Are you sure you wish to remove the cheque cashing as you will not be able to retrieve it?';
export const AMOUNT_TO_PAYOUT = 'Amount to Payout';
export const AMOUNT_TO_RECEIVE = 'Amount to Receive';

export const EMPTY_BASKET = {
  EMPTY: 'Your basket is empty!',
  SERVICE:
    'Select a service to continue serving your customer <p style="font-size: 22px; font-weight: 500; margin:0;">OR</p> Press Exit & Clear to end this customer session',
};

export const USER_BALANCE_ALERT =
  'There are insufficient funds available in your till for this transaction, please complete a till transfer for the currency(s) listed below in order to continue.';

export const PAYMENT_METHOD = {
  CARD: 'Pay customer by card',
  FASTER_PAYMENT: 'Pay customer by faster payment',
};

export const ERROR_MESSAGE = {
  FX_SUMMARY: 'Unable to fetch Travel Money summary',
};

export const FX_TRANSACTION_TYPE = {
  BOUGHT: 'Bought',
  BUY: 'Buy',
  SELL: 'Sell',
};

export const CUSTOMER_ALERT_POPUP = {
  BUTTON_LABEL: 'Update Customer',
  HEADER: 'Update Required',
  LINE_2: 'Please update the customer profile.',
};

export const CONSUMER_DUTY_ALERT = {
  ALERT: 'Restricted Service Attempt',
  CONTENT_SST: 'Service(s) mentioned below have been restricted by SSTeam.',
  CONTENT_CST: 'Service(s) mentioned below have been restricted by CSTeam.',
  SECONDARY_MSG_SST: 'Please contact with SSTeam for further help.',
  SECONDARY_MSG_CST: 'Please contact with CSTeam for further help.',
  SUCCESS_BTN: 'OK',
  CANCEL_BTN: 'Cancel',
};
