import React from 'react';
import { PaymentContextProvider } from 'components/payment/PaymentContext';

import Payment from './index';

function PaymentContainer() {
  return (
    <PaymentContextProvider>
      <Payment />
    </PaymentContextProvider>
  );
}

export default PaymentContainer;
