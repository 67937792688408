import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';
import VirtualGrid from 'components/common/virtualGrid/index';
import ImageLoader from 'components/common/ImageLoader';
import styled from 'styled-components';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import {
  GET_PLEDGE_ITEMS,
  GET_LOAN_DETAILS,
} from 'action_creators/pledgeManagement';

const StyledSpan = styled.span`
  text-align: center;
  padding: 0 5px 0 5px;
`;

interface AuctionOverSummaryProps {
  auctionsOverSeventyFiveList: Array<any>;
}

const TableRow = ({
  row,
  onClickTableRow,
  loadAuctionOverDetails,
  ...restProps
}: any) => {
  const onAgreementNoClick = () => {
    loadAuctionOverDetails(row);
  };
  const onRowClick = () => {
    onClickTableRow(row);
  };

  const Cell = () => {
    const { column } = restProps;
    if (column.name === 'loanNumber') {
      return (
        <Table.Cell
          onClick={onAgreementNoClick}
          className="auctionOver-id-cell"
          {...restProps}
        />
      );
    }
    return <Table.Cell onClick={onRowClick} {...restProps} />;
  };

  return <>{Cell()}</>;
};

const AuctionOverSummary = ({
  auctionsOverSeventyFiveList = [],
}: AuctionOverSummaryProps) => {
  const [columns] = useState([
    {
      name: 'customerName',
      title: 'Customer Name',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <>
          <ImageLoader
            customerId={row?.customerId}
            imageUrl={
              row?.customerId && `/api/customers/images/${row?.customerId}`
            }
            classes={`mx-small-avatar mx-small-avatar-yellow`}
            borderRadius={'50%'}
            fallbackImg={'customer-pic.svg'}
            preview
          />
          <StyledSpan>{row?.customerName}</StyledSpan>
        </>
      ),
    },
    {
      name: 'serviceType',
      title: 'Service Type',
      getCellValue: (row: any) => row?.serviceType,
    },
    {
      name: 'loanNumber',
      title: 'Agreement',
      getCellValue: (row: any) => row?.loanNumber,
    },
    {
      name: 'loanValue',
      title: 'Value',
      getCellValue: (row: any) => row?.loanValue,
    },
    {
      name: 'startDate',
      title: 'Start',
      getCellValue: (row: any) =>
        row && row?.startDate
          ? format(new Date(row?.startDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'dueDate',
      title: 'End',
      getCellValue: (row: any) =>
        row && row?.dueDate
          ? format(new Date(row?.dueDate), FNS_DATE_FORMAT)
          : null,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'customerName', width: '20%', align: 'Left' },
    { columnName: 'serviceType', width: '15%' },
    { columnName: 'loanNumber', width: '15%' },
    { columnName: 'loanValue', width: '15%' },
    { columnName: 'startDate', width: '15%' },
    { columnName: 'dueDate', width: '15%' },
  ]);

  const dispatch = useDispatch();
  const getRowId = (row: any) => row?.customerId;
  const onClickTableRow = () => { /* do nothing */ };
  const onSelectionChange = (rowId: any) => { /* do nothing */ };
  const loadAuctionOverDetails = (row: any) => {
    dispatch({
      type: GET_PLEDGE_ITEMS,
      payload: {
        loanId: row.loanId,
        pledgeLoanNumber: row.loanNumber,
      },
    });

    dispatch({
      type: GET_LOAN_DETAILS,
      payload: {
        loanNumber: row.loanNumber,
      },
    });
  };

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={auctionsOverSeventyFiveList}
        getRowId={getRowId}
        cellComponent={(restProps: any) => (
          <TableRow
            onClickTableRow={onClickTableRow}
            loadAuctionOverDetails={loadAuctionOverDetails}
            {...restProps}
          />
        )}
        rowSelectionEnable={true}
        showSelectionColumn={false}
        showSelectAll={false}
        selectByRowClick={true}
        highlightRow={true}
        onSelectionChange={onSelectionChange}
      />
    </>
  );
};

export default AuctionOverSummary;
