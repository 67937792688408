export const AUCTION_MANAGER_TITLE = 'Auction Manager';
export const VIEWING_LABEL = 'Viewing';
export const FROM_DATE_LABEL = 'From';
export const TO_DATE_LABEL = 'To';
export const GENERIC_ERROR = 'Something went wrong. Please try again later';
export const TO_DATE_REQUIRED_MSG = 'To date is required';
export const FROM_DATE_REQUIRED_MSG = 'From date is required';
export const AUCTION_VIEWING_OPTIONS = [
  { LABEL: 'Active Auctions', OPTION_VALUE: 1 },
  { LABEL: 'Closed Auctions', OPTION_VALUE: 2 },
  { LABEL: 'All Auctions', OPTION_VALUE: 3 },
];
export const AUCTION_OVER_TITLE = 'Auction Over \u00A375.00';
export const AUCTION_OVER_REVIEW_TITLE = 'Auction Entry Review - ';
export const PFI_TITLE = 'PFI \u00A375.00 and under';
export const VIEW_AUCTION_DETAILS_TITLE = 'Auction Manager / View Auction - ';
export const VIEW_AUCTION_CLOSED_TITLE = 'Closed';
export const VIEW_AUCTION_LIVE_STORE_TITLE = 'Live at Store';
export const VIEW_AUCTION_PENDING_RETURN_TITLE = 'Pending Return';
export const VIEW_AUCTION_LIVE_AT_AUCTION_TITLE = 'Live At Auction';
export const VIEW_AUCTION_READY_FOR_AUCTION_TITLE = 'Ready For Auction';
export const TABLE_HEAD_COLOR = {
  CLOSED: 'rose-white',
  ACTIVE: 'honeydue',
  PENDING: 'light-yellow',
};
export const SUMMARY_STATUS_OPTIONS = [
  { id: 1, value: 'Pass' },
  { id: 2, value: 'Sold' },
  { id: 3, value: 'Return' },
];
export const CONFIRM_SETTLEMENT_HEADER = 'You are about to settle this auction';
export const CONFIRM_SETTLEMENT_CONTENT = 'Are you sure you wish to continue?';
export const CONFIRM_SETTLEMENT_TYPE = 'Type';
export const CONFIRM_SETTLEMENT_VOLUME = 'Volume';
export const CONFIRM_SETTLEMENT_VALUE = 'Value';
export const PROCEED_SETTLEMENT_HEADER =
  'You have chosen to settle this auction';
export const PROCEED_SETTLEMENT_CONTENT =
  'Are you really sure you wish to settle this auction?';
export const PROCEED_SETTLEMENT_WARNING = 'This action cannot be undone!';
export const AUCTION_PRINT_TYPE = {
  AUCTION_BOOK: 1,
  PASSES: 2,
};
export const AUCTION_PRINT_FOR = {
  LIVE_AT_STORE: 1,
  LIVE_AT_AUCTION: 2,
};

export const PFI_LABELS = {
  PFI_UNDER: 'Pfi Under',
  PFI_REVIEW: 'Pfi Review',
};

export const ADD_STONE_LABELS = {
  LABEL: 'View/Edit Stone Details',
  ANOTHER_STONE: 'Add Another Stone',
};

export const CONFIRM_ITEM_DETAILS = 'Confirm Item Details';
export const ITEM_DESCRIPTION = 'Item Description';
export const VALUATION = {
  METAL_VALUE: 'Metal Value',
  COIN_VALUE: 'Coin Value',
  STONES_TOTAL_VALUE: 'Stones Total Value',
  OVERALL_GUIDANCE: 'Overall Guidance',
  DESIRED_AMOUNT: 'Desired Amount',
  VALUATION: 'Valuation',
};
export const TAKE_ITEM_PHOTO = 'Take Item Photo';
export const TOTAL_ITEM_VALUE = 'Item Value';
export const ITEM_TYPE = 'COINS';
export const CONFIRM_LOCK_HEADER = 'You are about to lock this auction';
export const CONFIRM_LOCK_CONTENT = 'Are you sure you wish to continue?';
export const LOCK_AUCTION_DATE = 'Auction Date';
export const LOCK_AUCTION_LOTS = 'No. of Lots';
export const LOCK_AUCTION_RESERVE_VALUE = 'Reserve Value';
export const RECONFIRM_LOCK_HEADER = 'You have chosen to lock this auction';
export const RECONFIRM_LOCK_CONTENT =
  'Are you really sure you wish to lock this auction?';
export const RECONFIRM_LOCK_WARNING = 'This action cannot be undone!';
export const REMINDER_AUCTION_HEADER =
  'This months auction is taking place tomorrow';
export const REMINDER_AUCTION_CONTENT = 'The auction must be locked at 4pm';
export const NOTIFY_LOCK_CALL_EXEC_TIME = 12; //In hours format, 12pm
export const NOTIFY_LOCK_CALL_INTERVAL = 86400000; //In milliseconds i.e 24 hours
export const REMINDER_AUCTION_ALERT_INTERVAL = 1800000; //In milliseconds i.e 30 minutes
export const CONFIRM_PASS_CONTENT =
  'Note: Existing Lot numbers will not be recalculated as labels may already be on the other items.';
export const CONFIRM_PASS_LABEL =
  'Please enter the reason for passing this lot';
export const NEXT_EXEC_TIME_INTERVAL = 86400000; //24 hrs in milliseconds

export const ADD_COIN_LABELS = {
  LABEL: 'View/Edit Coin Details',
  ANOTHER_COIN: 'Add Another Coin',
};
export const REASON_TEXT_PLACEHOLDER = 'Pass no pay - Advised by CR Team';
export const GENERATE_BUTTON_TEXT = 'Generate Lot Number';
export const NEXT_PLEDGE_BUTTON_TEXT = 'Next Pledge';
export const NO_PLEDGE_TEXT = 'No Pledge';
export const BARCODE_FAILED_TEXT = `Barcode's did not process successfully`;
export const BARCODE_STICKER_FAILED_TEXT = `Barcode service is not running, please check.`;
export const SENT_TO_PRINTER = `Sent to printer successfully`;
export const LOT_STICKER_SERVICE_ERROR = `Lot sticker service is not running, please check.`;
export const SUCCESS_RELEASE = 'Pledge released successfully.';

export const PLEDGE_MANAGEMENT_TITLES = {
  CONFISCATIONS: 'Confiscations',
  VULNERABLE: 'Vulnerable',
};

export const PLEDGE_ACTION_NOTES_TITLES = {
  CONFISCATE: 'Confiscate Pledge(s)',
  VULNERABLE: 'Mark Pledges(s) as Vulnerable',
  CONFISCATE_MANAGER: 'Confiscation Details',
  VULNERABLE_NOTES_TITLE: 'Vulnerable Pledge Details',
};

export const VULNERABLE_NOTICE_POPUP = {
  TITLE: 'Vulnerable Notice',
  CONTENT:
    'Are you sure you want to mark the pledge(s) listed above as vulnerable?',
};

export const CONFISCATE_NOTICE_POPUP = {
  TITLE: 'Confiscation Notice',
  CONTENT: `Are you sure you want to mark the pledge(s) listed above? If 'Yes' then please print and present an NPA statement to the officer to read and keep.`,
};

export const PLEDGE_NOTICE_TYPE = {
  CONFISCATE: 'Confiscate',
  VULNERABLE: 'Vulnerable',
};

export const RELEASE_PLEDGE_POPUP = {
  VULNERABLE_TITLE: 'Release Vulnerable Pledge',
  CONFISCATE_TITLE: 'Release Confiscated Pledge',
  CONTENT: `This will return the pledge to an open state and allow
  transactions and processing against it. `,
  VALIDATION_ERROR: 'Please add a reason for release.',
};

export const BUTTON_LABELS = {
  SAVE_NOTE: 'Save Note',
  CANCEL: 'Cancel',
  CONFIRM: 'Confirm',
  PRINT_NPA_STATEMENT: 'Print NPA Statement',
  CONTINUE: 'Continue',
  RELEASE: 'Release',
  REPRINT: 'Repint',
  FAILED: 'Failed',
  NEXT_PLEDGE: 'Next Pledge',
  PUSH_TO_PFI: 'Push To PFI',
  BACK_TO_PFI_MANAGER: 'Back To PFI Manager',
  BACK_TO_AUCTION_MANAGER: 'Back To Auction Manager',
  RETRY_RECHECK: 'Retry/Recheck Status',
  SEND_EMAIL: 'Send Email',
  OK: 'Ok',
  REPRINT_LOT_LABEL: 'Reprint Lot Label',
  BACK: 'Back',
  PROCEED: 'Proceed',
  LOCK_AUCTION: 'Lock Auction',
  PRINT_AUCTION_BOOK: 'Print Auction Book',
  PRINT_PASSES: 'Print Passes',
  CONFIRM_COLLECTION: 'Confirm Collection',
  EMAIL_AUCTION_BOOK: 'Email Auction Book',
  SETTLE_AUCTION: 'Settle Auction',
  YES: 'Yes',
  NO: 'No',
  SEND_TO_AUCTION_HOUSE: 'Send To Auction House',
  PASS_LOAN: 'Pass Loan',
  SEARCH: 'Search',
  CLOSE: 'Close'
};
