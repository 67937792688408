import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Modal, Row, Tabs, Form, Input, notification } from 'antd';
import styled from 'styled-components';
import { GENERIC_ERROR, REGEX_AMOUNT } from 'globalConstants';
import { Button } from 'components/common/Button';
import {
  GET_CURRENCIES_REQUEST,
  GET_STORE_LIST_REQUEST,
} from 'action_creators/cashManagement';
import {
  postCashDelivery,
  postFxDelivery,
  getSterlingValueForCashDelivery,
  postFXInboundDelivery,
} from 'services/cashManagement';
import { getUserSterlingBalance } from 'services/basket/validation';
import {
  OUTBOUND_OPTIONS,
  INBOUND_OPTIONS,
  OUTBOUND_LABELS,
  CASH_DELIVERY,
  CASH_MNGNT_ERROR,
  CASH_DELIVERY_TABS,
} from '../constants';
import InboundDeliveryTable from './InboundDeliveryTable';
import InputDecimal from 'components/common/InputDecimal';
import Select from 'components/common/Select';
import { Tab } from 'rc-tabs/lib/interface';
import TextArea from 'components/common/TextArea';

const StyledModal = styled(Modal)`
  & .ant-modal-header {
    background: var(--solitude);
    & .ant-modal-title {
      padding-bottom: 20px;
    }
  }
  & .ant-modal-body {
    background: var(--solitude);
    padding: 0;
  }
`;

const StyledDiv = styled.div`
  margin: 20px 0;
  width: 100%;
  border: 1px solid var(--mystic);
  position: relative;
  left: -30px;
  width: calc(100% + 60px);
`;

const StyledInboundDiv = styled.div`
  min-height: 224px;
`;
const StyledEmptyDiv = styled.div`
  min-height: 339px;
`;

const StyledTableWrapper = styled.div`
  min-height: 225px;
  border: 1px solid var(--light-gray);
`;

enum OutboundMethod {
  Store = 318,
  Bank = 331,
  CmsRecall = 323,
}

enum InboundMethod {
  Store = 308,
  Bank = 307,
  CmsRecall = 1,
  StoreSystem = 2,
}
interface CashDeliveryPopupProps {
  visible: boolean;
  setCashDeliveryPopupVisible: () => void;
}

const CashDeliveryPopup = ({
  visible,
  setCashDeliveryPopupVisible,
}: CashDeliveryPopupProps) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<any>(0);
  const [outboundMethod, setOutboundMethod] = useState<any>();
  const [inboundMethod, setInboundMethod] = useState<any>();
  const [isFXMode, setFXMode] = useState<boolean>(false);
  const [amountSymbol, setAmountSymbol] = useState<string>('&#163;');
  const [inboundTransferId, setInboundTransferId] = useState<string | number>();
  const [disabledSave, setDisabledSave] = useState<boolean>(true);
  const [sterlingAmount, setSterlingAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userSterlingBalance, setUserSterlingBalance] = useState<number>(0);
  //commenting code, feature will be develop in future sprint
  // const [outboundCurrency, setOutboundCurrency] = useState<any>('');
  const [inboundNotesMaxlength, setInboundNotesMaxlength] =
    useState<number>(64); //initial length - 64
  const [outboundNotesMaxlength, setOutboundNotesMaxlength] =
    useState<number>(64); //initial length - 64

  const {
    cashManagement: { currencyList, destinationStoreList },
    user: { selectedStore, user },
  } = useSelector((state: any) => {
    return {
      cashManagement: state.cashManagement,
      user: state.user,
    };
  });

  useEffect(() => {
    if (currencyList?.length === 0) {
      dispatch({
        type: GET_CURRENCIES_REQUEST,
      });
    }
    if (destinationStoreList?.length === 0) {
      dispatch({
        type: GET_STORE_LIST_REQUEST,
      });
    }

    fetchUserSterlingBalance();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleInboundFormChange();
  }, [inboundTransferId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleOutboundFormChange();
  }, [sterlingAmount]); // eslint-disable-line react-hooks/exhaustive-deps

  const onStoreChange = (value: any, type: string) => {
    const storeNameLength = JSON.parse(value)?.storeName?.length;
    if (type === CASH_DELIVERY.INBOUND)
      setInboundNotesMaxlength(64 - storeNameLength);
    if (type === CASH_DELIVERY.OUTBOUND)
      setOutboundNotesMaxlength(64 - storeNameLength);
  };

  const onCancelClick = () => {
    setCashDeliveryPopupVisible();
  };

  const fetchUserSterlingBalance = async () => {
    try {
      const payload = {
        userId: user?.userId,
        storeNumber: selectedStore?.storeId,
        currencies: [{ currency: 'GBP', amount: 0 }],
      };
      const response = await getUserSterlingBalance(payload);
      if (response?.data)
        setUserSterlingBalance(response?.data?.currencies[0]?.amount);
    } catch (e: any) {
      notification.error({
        message: GENERIC_ERROR,
        duration: 5,
      });
    }
  };

  const saveCashDelivery = async (
    payload: any,
    authorisationHeaders: any,
    type: any
  ) => {
    if (
      userSterlingBalance < payload.amount &&
      type === CASH_DELIVERY.OUTBOUND
    ) {
      notification.error({
        message: CASH_MNGNT_ERROR.STERLING_INSUFFICIENT_AMOUNT,
        duration: 5,
      });

      return setIsLoading(false);
    }

    try {
      const response = await postCashDelivery(payload, authorisationHeaders);
      if (response?.data) {
        notification.success({
          message: CASH_DELIVERY.NOTIFICATION.SUCCESS,
          duration: 3,
        });
        setCashDeliveryPopupVisible();
      }
    } catch (e: any) {
      setIsLoading(false);
      notification.error({
        message: e?.response?.data?.error,
        duration: 3,
      });
    }
  };

  const saveFXDelivery = async (payload: any, authorisationHeaders: any) => {
    try {
      const response = await postFxDelivery(payload, authorisationHeaders);
      if (response?.data) {
        notification.success({
          message: CASH_DELIVERY.NOTIFICATION.SUCCESS,
          duration: 3,
        });
        setCashDeliveryPopupVisible();
      }
    } catch (e: any) {
      setIsLoading(false);
      notification.error({
        message: e?.response?.data?.error,
        duration: 3,
      });
    }
  };

  const saveInboundCashDelivery = async (
    fxTransferId: any,
    authorisationHeaders: any
  ) => {
    const payload = {
      fxTransferId,
      userId: user?.userId,
    };
    try {
      const response = await postFXInboundDelivery(
        payload,
        authorisationHeaders
      );
      if (response?.data) {
        notification.success({
          message: CASH_DELIVERY.NOTIFICATION.SUCCESS,
          duration: 3,
        });
        setCashDeliveryPopupVisible();
      }
    } catch (e: any) {
      setIsLoading(false);
      notification.error({
        message: e?.response?.data?.error,
        duration: 3,
      });
      console.log(e);
    }
  };

  const onConfirmOutboundClick = () => {
    const destination =
      outboundMethod !== OutboundMethod.CmsRecall
        ? JSON.parse(form.getFieldValue('outboundDestination'))
        : form.getFieldValue('outboundDestination');
    try {
      const payload = {
        storeId: selectedStore?.storeId,
        userId: user?.userId,
        activityId: form.getFieldValue('outboundMethod'),
        linkedTransactionReference: form.getFieldValue('outboundReference'),
        amount: +form.getFieldValue('outboundAmount'),
        note: form.getFieldValue('outboundNotes'),
        destination: destination?.storeName?.trim() || destination,
        destinationStoreId: destination?.storeNumber || 0,
        // destinationTillUserId:
        //   outboundCurrency === 'GBP'
        //     ? form.getFieldValue('destinationTillUserId')
        //     : 0,
        currencyCode: form.getFieldValue('outboundCurrency'),
        sterlingAmount: sterlingAmount,
      };

      const authorisationHeaders = {
        authorisationUserName: form.getFieldValue('outboundEmpId'),
        authorisationPassword: form.getFieldValue('outboundPassword'),
      };
      setIsLoading(true);

      if (!isFXMode)
        saveCashDelivery(payload, authorisationHeaders, CASH_DELIVERY.OUTBOUND);
      else saveFXDelivery(payload, authorisationHeaders);
    } catch (errorInfo) {
      setIsLoading(false);
      console.log(errorInfo);
    }
  };

  const onConfirmInboundClick = () => {
    const destination =
      inboundMethod === InboundMethod.Store
        ? JSON.parse(form.getFieldValue('inboundSource'))
        : '';
    try {
      const payload = {
        storeId: selectedStore?.storeId,
        userId: user?.userId,
        activityId: form.getFieldValue('inboundMethod'),
        linkedTransactionReference:
          inboundMethod === InboundMethod.Bank
            ? form.getFieldValue('inboundReference')
            : '',
        amount: +form.getFieldValue('inboundAmount'),
        note: form.getFieldValue('inboundNotes'),
        destination: destination?.storeName?.trim() || destination,
        destinationStoreId: destination?.storeNumber || 0,
        // destinationTillUserId: form.getFieldValue('destinationTillUserId'),
        currencyCode: form.getFieldValue('inboundCurrency'),
        sterlingAmount: 0,
      };

      setIsLoading(true);
      const authorisationHeaders = {
        authorisationUserName: form.getFieldValue('inboundEmpId'),
        authorisationPassword: form.getFieldValue('inboundPassword'),
      };

      switch (inboundMethod) {
        case InboundMethod.Bank:
        case InboundMethod.Store:
          if (!isFXMode)
            saveCashDelivery(
              payload,
              authorisationHeaders,
              CASH_DELIVERY.INBOUND
            );
          else saveFXDelivery(payload, authorisationHeaders);
          break;
        case InboundMethod.StoreSystem:
        case InboundMethod.CmsRecall:
          saveInboundCashDelivery(inboundTransferId, authorisationHeaders);
          break;
        default:
          break;
      }
    } catch (errorInfo) {
      setIsLoading(false);
    }
  };

  const onChangeTab = (key: string) => {
    setActiveTab(Number(key));
    form.resetFields();
    setOutboundMethod(null);
    setInboundMethod(null);
    setFXMode(false);
    setAmountSymbol('&#163;');
    setDisabledSave(true);
  };

  const onOutboundMethodChange = (e: any) => {
    setOutboundMethod(e);
    if (e === OutboundMethod.Bank) {
      form.setFieldsValue({
        outboundCurrency: 'GBP',
      });
      setAmountSymbol('&#163;');
      setFXMode(false);
    }
    if (e === OutboundMethod.CmsRecall) {
      form.setFieldsValue({
        outboundDestination: OUTBOUND_LABELS.CMS_RECALL,
      });
    } else form.setFieldsValue({ outboundDestination: null });
  };

  const onCurrencyChange = (value: string) => {
    const isFX = value !== 'GBP' ? true : false;
    setFXMode(isFX);
    const symbol = currencyList.find((x: any) => x.currencyCode === value);
    setAmountSymbol(symbol.currencySymbol);
    form.setFieldsValue({
      outboundAmount: '',
      inboundAmount: '',
    });
  };

  const onAmountChange = async (e: any) => {
    if (!REGEX_AMOUNT.test(form.getFieldValue('outboundAmount'))) {
      return;
    }
    const currencyCode = form.getFieldValue('outboundCurrency');
    if (currencyCode !== 'GBP') {
      const payload = {
        currencyCode,
        currencyAmount: form.getFieldValue('outboundAmount'),
        storeId: selectedStore?.storeId,
      };
      try {
        const response = await getSterlingValueForCashDelivery(payload);
        if (response?.data) {
          if (!response?.data?.denominationExists) {
            setDisabledSave(true);
            setSterlingAmount(0);
            notification.error({
              message: CASH_DELIVERY.NOTIFICATION.DENOMINATION_NOT_EXIST,
              duration: 2,
            });
          } else setSterlingAmount(response?.data?.sterlingAmount);
        }
      } catch (e: any) {
        console.log(e);
      }
    }
  };

  const onInboundMethodChange = (e: any) => {
    setInboundMethod(e);
    if (e === InboundMethod.Bank || e === InboundMethod.Store) {
      form.setFieldsValue({
        inboundCurrency: 'GBP',
      });
    }
  };

  const handleOutboundFormChange = () => {
    const {
      outboundCurrency,
      outboundDestination,
      outboundAmount,
      outboundEmpId,
      outboundPassword,
      // destinationTillUserId,
      outboundReference,
    } = form.getFieldsValue();

    // setOutboundCurrency(outboundCurrency);

    switch (outboundMethod) {
      case OutboundMethod.Store:
        if (
          outboundDestination &&
          outboundCurrency &&
          (outboundCurrency === 'GBP' ? true : sterlingAmount) &&
          outboundAmount &&
          outboundEmpId &&
          outboundPassword
          // (outboundCurrency === 'GBP' ? destinationTillUserId : true)
        ) {
          setDisabledSave(false);
        } else setDisabledSave(true);
        break;
      case OutboundMethod.Bank:
      case OutboundMethod.CmsRecall:
        if (
          outboundReference &&
          outboundCurrency &&
          outboundAmount &&
          outboundEmpId &&
          outboundPassword
          // (outboundCurrency === 'GBP' ? destinationTillUserId : true)
        ) {
          if (
            outboundMethod === OutboundMethod.CmsRecall &&
            outboundCurrency === 'GBP'
              ? false
              : sterlingAmount === 0
          ) {
            setDisabledSave(true);
            break;
          }
          setDisabledSave(false);
        } else setDisabledSave(true);
        break;
      default:
        break;
    }
  };

  const handleInboundFormChange = () => {
    const {
      inboundSource,
      inboundAmount,
      inboundEmpId,
      inboundPassword,
      // destinationTillUserId,
      inboundReference,
    } = form.getFieldsValue();

    switch (inboundMethod) {
      case InboundMethod.Store:
        if (
          inboundSource &&
          inboundAmount &&
          inboundEmpId &&
          inboundPassword
          // destinationTillUserId
        ) {
          setDisabledSave(false);
        } else setDisabledSave(true);
        break;
      case InboundMethod.Bank:
        if (
          inboundReference &&
          inboundAmount &&
          inboundEmpId &&
          inboundPassword
          // destinationTillUserId
        ) {
          setDisabledSave(false);
        } else setDisabledSave(true);
        break;
      case InboundMethod.StoreSystem:
      case InboundMethod.CmsRecall:
        if (
          inboundTransferId &&
          inboundEmpId &&
          inboundPassword
          // destinationTillUserId
        )
          setDisabledSave(false);
        else setDisabledSave(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <StyledModal
        title="Cash Delivery"
        open={visible}
        width={940}
        centered
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        footer={[
          <Row gutter={24} justify="space-between" key="cashDelivery">
            <Col>
              <Button
                itemProp="secondary"
                key="cancelDelivery"
                onClick={onCancelClick}
              >
                Close
              </Button>
            </Col>
            <Col>
              {activeTab === 0 ? (
                <Button
                  key="confirmDelivery"
                  htmlType="submit"
                  form="cashOutboundForm"
                  disabled={disabledSave}
                  loading={isLoading}
                >
                  {'Confirm & Send'}
                </Button>
              ) : (
                <Button
                  key="confirmDelivery"
                  htmlType="submit"
                  onClick={onConfirmInboundClick}
                  disabled={disabledSave}
                  loading={isLoading}
                >
                  {'Confirm & Accept'}
                </Button>
              )}
            </Col>
          </Row>,
        ]}
      >
        <Row>
          <Col span={24} className="tab-container">
            <Tabs
              type="card"
              tabBarGutter={20}
              onChange={onChangeTab}
              items={CASH_DELIVERY_TABS?.map((tab, index) => {
                const id = index;
                const childTab: unknown = { label: tab.title, key: id };
                return childTab as Tab;
              })}
            ></Tabs>
            {activeTab === 0 && (
              <Row
                style={{
                  padding: '30px',
                  backgroundColor: 'var(--white)',
                }}
              >
                <Col span={24}>
                  <Form
                    id="cashOutboundForm"
                    onFinish={onConfirmOutboundClick}
                    layout="vertical"
                    form={form}
                    className="cash-delivery-form"
                    onFieldsChange={handleOutboundFormChange}
                  >
                    <Row>
                      <Col span={8}>
                        <Form.Item
                          label="Method"
                          name="outboundMethod"
                          className="label-control"
                        >
                          <Select
                            onChange={onOutboundMethodChange}
                            options={OUTBOUND_OPTIONS.map((item: any) => {
                              return {
                                value: item.id,
                                label: item.description,
                              };
                            })}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <StyledDiv />
                    <Row>
                      {outboundMethod === OutboundMethod.Bank ? (
                        <Col span={8}>
                          <Form.Item
                            label="Reference"
                            name="outboundReference"
                            className="label-control"
                          >
                            <Input
                              className="input-control"
                              autoComplete="off"
                            />
                          </Form.Item>
                        </Col>
                      ) : (
                        <Col span={8}>
                          <Form.Item
                            label="Destination"
                            name="outboundDestination"
                            className="label-control cash-delivery-selection"
                          >
                            <Select
                              disabled={
                                outboundMethod === OutboundMethod.CmsRecall
                              }
                              options={destinationStoreList?.map(
                                (item: any, index: number) => {
                                  return {
                                    key: index,
                                    value: JSON.stringify(item),
                                    label: item.storeName,
                                  };
                                }
                              )}
                              onChange={(e: any) =>
                                onStoreChange(e, CASH_DELIVERY.OUTBOUND)
                              }
                            />
                          </Form.Item>
                        </Col>
                      )}
                      <Col span={7} offset={1}>
                        <Form.Item
                          label="Currency"
                          name="outboundCurrency"
                          className="label-control"
                        >
                          <Select
                            className="cash-delivery-selection"
                            disabled={outboundMethod === OutboundMethod.Bank}
                            onChange={onCurrencyChange}
                            options={currencyList.map((item: any) => {
                              return {
                                value: item.currencyCode,
                                label: `${item?.currencyDescription} (${item.currencyCode})`,
                              };
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={7} offset={1}>
                        <Form.Item
                          label="Amount"
                          name="outboundAmount"
                          className="label-control cash-delivery-amount-input"
                        >
                          <InputDecimal
                            placeholder={false}
                            className="input-control amount-to-pay-input"
                            addonBefore={
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: amountSymbol ? amountSymbol : ' ',
                                }}
                              ></span>
                            }
                            onChange={onAmountChange}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      {/* commenting code, feature will be develop in future sprint */}
                      {/* {outboundCurrency === 'GBP' && (
                          <Col span={8}>
                            <Form.Item
                              label="Sender"
                              name="destinationTillUserId"
                              className="label-control"
                            >
                              <Select
                                allowClear={true}
                                className="cash-delivery-selection"
                                options={storeTillUsers.map((item: any) => {
                                  return {
                                    value: item.userId,
                                    label: item.displayName,
                                  };
                                })}
                              />
                            </Form.Item>
                          </Col>
                        )} */}
                      {outboundMethod === OutboundMethod.CmsRecall ? (
                        <Col span={8}>
                          <Form.Item
                            label="Reference"
                            name="outboundReference"
                            className="label-control"
                          >
                            <Input
                              className="input-control"
                              autoComplete="off"
                            />
                          </Form.Item>
                        </Col>
                      ) : null}
                    </Row>

                    <Row>
                      <Col span={16}>
                        <Form.Item
                          label="Notes"
                          name="outboundNotes"
                          className="label-control"
                        >
                          <TextArea
                            className="input-control"
                            autoComplete="off"
                            rows={2}
                            maxLength={outboundNotesMaxlength}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <StyledDiv />
                    <Row>
                      <Col span={8}>
                        <Form.Item
                          label="Check by Employee ID"
                          name="outboundEmpId"
                          className="label-control"
                        >
                          <Input
                            className="input-control"
                            autoComplete="new-password"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={7} offset={1}>
                        <Form.Item
                          label="Password"
                          name="outboundPassword"
                          className="label-control"
                        >
                          <Input
                            className="input-control"
                            autoComplete="new-password"
                            type="password"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            )}
            {activeTab === 1 && (
              <Row
                style={{
                  padding: '30px',
                  backgroundColor: 'var(--white)',
                }}
              >
                <Col span={24}>
                  <Form
                    id="cashInboundForm"
                    onFinish={onConfirmInboundClick}
                    layout="vertical"
                    form={form}
                    className="cash-delivery-form"
                    onFieldsChange={handleInboundFormChange}
                  >
                    <Row>
                      <Col span={8}>
                        <Form.Item
                          label="Method"
                          name="inboundMethod"
                          className="label-control"
                        >
                          <Select
                            onChange={onInboundMethodChange}
                            options={INBOUND_OPTIONS.map((item: any) => {
                              return {
                                value: item.id,
                                label: item.description,
                              };
                            })}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <StyledDiv />
                    {inboundMethod ? (
                      <>
                        {inboundMethod === InboundMethod.Bank ||
                        inboundMethod === InboundMethod.Store ? (
                          <StyledInboundDiv>
                            <Row>
                              {inboundMethod === InboundMethod.Bank ? (
                                <Col span={8}>
                                  <Form.Item
                                    label="Reference"
                                    name="inboundReference"
                                    className="label-control"
                                  >
                                    <Input
                                      className="input-control"
                                      autoComplete="off"
                                    />
                                  </Form.Item>
                                </Col>
                              ) : (
                                <Col span={8}>
                                  <Form.Item
                                    label="Source"
                                    name="inboundSource"
                                    className="label-control"
                                  >
                                    <Select
                                      options={destinationStoreList.map(
                                        (item: any, index: number) => {
                                          return {
                                            key: index,
                                            value: JSON.stringify(item),
                                            label: item.storeName,
                                          };
                                        }
                                      )}
                                      onChange={(e: any) =>
                                        onStoreChange(e, CASH_DELIVERY.INBOUND)
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                              <Col span={7} offset={1}>
                                <Form.Item
                                  label="Currency"
                                  name="inboundCurrency"
                                  className="label-control"
                                >
                                  <Select
                                    className="cash-delivery-selection"
                                    disabled={
                                      inboundMethod === InboundMethod.Store ||
                                      inboundMethod === InboundMethod.Bank
                                    }
                                    onChange={onCurrencyChange}
                                    options={currencyList.map((item: any) => {
                                      return {
                                        value: item.currencyCode,
                                        label: `${item?.currencyDescription} (${item.currencyCode})`,
                                      };
                                    })}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={7} offset={1}>
                                <Form.Item
                                  label="Amount"
                                  name="inboundAmount"
                                  className="label-control cash-delivery-amount-input"
                                >
                                  <InputDecimal
                                    placeholder={false}
                                    className="input-control amount-to-pay-input"
                                    addonBefore={
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: amountSymbol
                                            ? amountSymbol
                                            : ' ',
                                        }}
                                      ></span>
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            {/* commenting code, feature will be develop in future sprint */}
                            {/* <Row>
                                <Col span={8}>
                                  <Form.Item
                                    label="Receiver"
                                    name="destinationTillUserId"
                                    className="label-control"
                                  >
                                    <Select
                                      allowClear={true}
                                      className="cash-delivery-selection"
                                      options={storeTillUsers.map(
                                        (item: any) => {
                                          return {
                                            value: item.userId,
                                            label: item.displayName,
                                          };
                                        }
                                      )}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row> */}
                            <Row>
                              <Col span={16}>
                                <Form.Item
                                  label="Notes"
                                  name="inboundNotes"
                                  className="label-control"
                                >
                                  <TextArea
                                    className="input-control"
                                    autoComplete="off"
                                    rows={2}
                                    maxLength={inboundNotesMaxlength}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </StyledInboundDiv>
                        ) : inboundMethod === InboundMethod.StoreSystem ||
                          inboundMethod === InboundMethod.CmsRecall ? (
                          <>
                            <StyledInboundDiv>
                              <StyledTableWrapper>
                                <InboundDeliveryTable
                                  inboundMethod={inboundMethod}
                                  onRowIdChange={(params) =>
                                    setInboundTransferId(params)
                                  }
                                />
                              </StyledTableWrapper>
                            </StyledInboundDiv>
                            {/* commenting code, feature will be develop in future sprint */}
                            {/* <Row>
                                <Col span={8}>
                                  <Form.Item
                                    label="Receiver"
                                    name="destinationTillUserId"
                                    className="label-control"
                                  >
                                    <Select
                                      allowClear={true}
                                      className="cash-delivery-selection"
                                      options={storeTillUsers.map(
                                        (item: any) => {
                                          return {
                                            value: item.userId,
                                            label: item.displayName,
                                          };
                                        }
                                      )}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row> */}
                          </>
                        ) : (
                          <></>
                        )}
                        <StyledDiv />
                        <Row>
                          <Col span={8}>
                            <Form.Item
                              label="Check by Employee ID"
                              name="inboundEmpId"
                              className="label-control"
                            >
                              <Input
                                className="input-control"
                                autoComplete="new-password"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={7} offset={1}>
                            <Form.Item
                              label="Password"
                              name="inboundPassword"
                              className="label-control"
                            >
                              <Input
                                className="input-control"
                                autoComplete="new-password"
                                type="password"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <StyledEmptyDiv></StyledEmptyDiv>
                    )}
                  </Form>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </StyledModal>
    </>
  );
};

export default CashDeliveryPopup;
