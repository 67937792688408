import React, { useEffect } from 'react';
import { Col, Modal, Row, Input, Form } from 'antd';
import { useDispatch } from 'react-redux';
import { Button } from 'components/common/Button';
import { CALCULATE_PLEDGE_AGREEMENT_LOAN_AMOUNT } from 'action_creators/pickupRenew';
import { REGEX_AMOUNT } from 'globalConstants';
import { currencyFormat } from 'utils/util';
import { PAYDOWN } from './constants';
import InputDecimal from 'components/common/InputDecimal';

interface PaydownPopupProps {
  visible: boolean;
  setPaydownPopupVisible: () => void;
  row: any;
  setSelectedActionClear: () => void;
}

const PaydownPopup = ({
  visible,
  setPaydownPopupVisible,
  row,
  setSelectedActionClear,
}: PaydownPopupProps) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onCancelClick = () => {
    setPaydownPopupVisible();
    setSelectedActionClear();
  };

  const onConfirmClick = async () => {
    try {
      await form.validateFields();
      const updatedRow = {
        ...row,
        paydown: {
          ...row.paydown,
          newLoanAmount: +form.getFieldValue('newLoanAmount'),
          wishToPay: +form.getFieldValue('wishToPay'),
        },
      };
      const params = {
        agreement: updatedRow,
      };
      dispatch({
        type: CALCULATE_PLEDGE_AGREEMENT_LOAN_AMOUNT,
        payload: { params },
      });
      setPaydownPopupVisible();
    } catch (errorInfo) {
      console.log(errorInfo);
    }
  };

  const onAmountChange = (e: any, fieldName: string) => {
    if (
      e?.target?.value === '' ||
      e?.target?.value === undefined ||
      isNaN(e?.target?.value)
    )
      return;
    const calculatedAmount = (
      row.paydown.loanAmount +
      row.paydown.interestAmount -
      (row.paidAmount + +e.target.value + row.totalRepaidAmount)
    ).toFixed(2);

    switch (fieldName) {
      case PAYDOWN.NEW_LOAN_AMOUNT:
        form.setFieldsValue({
          wishToPay: calculatedAmount,
        });
        break;
      case PAYDOWN.WISH_TO_PAY:
        form.setFieldsValue({
          newLoanAmount: calculatedAmount,
        });
        break;
    }
  };

  const validateNewLoanAmount = (_: any, value: any) => {
    if (value === undefined || value === '')
      return Promise.reject(new Error(PAYDOWN.VALIDATION_NEW_LOAN.REQUIRED));
    if (+value <= 0)
      return Promise.reject(new Error(PAYDOWN.VALIDATION_NEW_LOAN.ZERO));
    if (!REGEX_AMOUNT.test(value)) {
      return Promise.reject(
        new Error(PAYDOWN.VALIDATION_NEW_LOAN.ONLY_NUMBERS)
      );
    }
    if (+value >= +form.getFieldValue('loanAmount')) {
      return Promise.reject(
        new Error(PAYDOWN.VALIDATION_NEW_LOAN.AMOUNT_LESS_THAN_PRINCIPAL)
      );
    }
    return Promise.resolve();
  };

  const validateWishToPay = (_: any, value: any) => {
    const interest = +form.getFieldValue('interestAmount');

    if (value === undefined || value === '')
      return Promise.reject(new Error(PAYDOWN.VALIDATION_WISH_TO_PAY.REQUIRED));
    if (!REGEX_AMOUNT.test(value)) {
      return Promise.reject(
        new Error(PAYDOWN.VALIDATION_WISH_TO_PAY.ONLY_NUMBERS)
      );
    }
    // if (+value <= +form.getFieldValue('interestAmount') && +value !== 0) {
    //   return Promise.reject(
    //     new Error(PAYDOWN.VALIDATION_WISH_TO_PAY.AMOUNT_MORE_THAN_INTEREST)
    //   );
    // }
    if (+value <= interest - row?.paidAmount) {
      return form.setFields([
        {
          name: 'newLoanAmount',
          errors: [PAYDOWN.VALIDATION_NEW_LOAN.AMOUNT_LESS_THAN_PRINCIPAL],
        },
      ]);
    }

    if (+value >= interest + +form.getFieldValue('loanAmount')) {
      return Promise.reject(
        new Error(PAYDOWN.VALIDATION_WISH_TO_PAY.AMOUNT_LESS_THAN_PICKUP)
      );
    }
    return Promise.resolve();
  };

  useEffect(() => {
    const { loanAmount, interestAmount } = row.paydown;
    form.setFieldsValue({
      loanAmount: currencyFormat(loanAmount),
      interestAmount: currencyFormat(interestAmount),
      dueToday: currencyFormat(row?.dueToday),
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Modal
        title="Paydown"
        open={visible}
        width={510}
        destroyOnClose={true}
        maskClosable={false}
        closable={false}
        onCancel={onCancelClick}
        footer={[
          <Row gutter={24} justify="end" key="paydownPopup">
            <Col>
              <Button
                itemProp="secondary"
                key="cancelPaydown"
                onClick={onCancelClick}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                key="confirmPaydown"
                htmlType="submit"
                onClick={onConfirmClick}
              >
                Confirm
              </Button>
            </Col>
          </Row>,
        ]}
        centered
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              form={form}
              className="partial-payment-form"
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Principal Loan"
                    name="loanAmount"
                    className="label-control"
                  >
                    <Input
                      className="input-control amount-to-pay-input"
                      autoComplete="off"
                      disabled
                      addonBefore={'£'}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Interest to Date"
                    name="interestAmount"
                    className="label-control"
                  >
                    <Input
                      className="input-control amount-to-pay-input"
                      autoComplete="off"
                      disabled
                      addonBefore={'£'}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Total Amount Due"
                    name="dueToday"
                    className="label-control"
                  >
                    <Input
                      className="input-control amount-to-pay-input"
                      autoComplete="off"
                      disabled
                      addonBefore={'£'}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Enter Amount Customer Wishes to Pay"
                    name="wishToPay"
                    className="label-control"
                    rules={[
                      {
                        validator: validateWishToPay,
                      },
                    ]}
                  >
                    <InputDecimal
                      className="input-control amount-to-pay-input"
                      onChange={(e: any) => onAmountChange(e, 'wishToPay')}
                      autoFocus
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Enter New Loan Amount"
                    name="newLoanAmount"
                    className="label-control"
                    rules={[
                      {
                        validator: validateNewLoanAmount,
                      },
                    ]}
                  >
                    <InputDecimal
                      className="input-control amount-to-pay-input"
                      onChange={(e: any) => onAmountChange(e, 'newLoanAmount')}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default PaydownPopup;
