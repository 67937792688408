import React from 'react';
import { Row, Col } from 'antd';

import { joinValues } from 'utils/util';

const CustomerDetails = ({ details }: any) => {
  const onViewCustomerProfileClick = () => { /* do nothing */ };

  return (
    <Row>
      <Col span={24}>
        <table className="valuation-tables">
          <tbody>
            <tr>
              <td>Customer</td>
              <td>
                {joinValues([details?.firstNames, details?.surname], true)}
              </td>
            </tr>
            <tr>
              <td>Customer Type</td>
              <td>
                {details?.customerId ? 'Existing Customer' : 'Guest Customer'}
              </td>
            </tr>
            <tr>
              <td>Address</td>
              <td>
                {joinValues([
                  details?.property,
                  details?.houseName,
                  details?.street,
                  details?.town,
                  details?.postcode,
                ])}
              </td>
            </tr>
            <tr>
              <td>Mobile No.</td>
              <td>{details?.mobile}</td>
            </tr>
            <tr>
              <td>Email ID</td>
              <td>{details?.email}</td>
            </tr>
            <tr>
              <td
                colSpan={2}
                className={details?.customerId ? 'table-link' : ''}
              >
                <span onClick={onViewCustomerProfileClick}>
                  View Complete Customer Profile
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </Col>
    </Row>
  );
};

export default CustomerDetails;
