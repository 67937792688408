import React, { useEffect } from 'react';
import { Col, Modal, Row, Input, Form } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Button } from 'components/common/Button';
import { PARTIAL_PAY } from './constants';
import { CALCULATE_PLEDGE_AGREEMENT_LOAN_AMOUNT } from 'action_creators/pickupRenew';
import { currencyFormat } from 'utils/util';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import InputDecimal from 'components/common/InputDecimal';

const StyledDiv = styled.div`
  font-style: italic;
  font-size: 13px;
  padding: 5px 0;
  line-height: 15.23px;
`;

const StyledAlertDiv = styled.div`
  color: var(--red);
  line-height: 16px;
  padding-bottom: 20px;
`;

const StyledWarningFilled = styled(WarningFilled)`
  margin-right: 4px;
  font-size: var(--font-size-16);
`;

interface PartialPaymentPopupProps {
  visible: boolean;
  setPartialPaymentPopupVisible: () => void;
  row: any;
  setSelectedActionClear: () => void;
}

const PartialPaymentPopup = ({
  visible,
  setPartialPaymentPopupVisible,
  row,
  setSelectedActionClear,
}: PartialPaymentPopupProps) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onCancelClick = () => {
    setPartialPaymentPopupVisible();
    setSelectedActionClear();
  };

  const onConfirmClick = async () => {
    try {
      await form.validateFields();
      const updatedRow = {
        ...row,
        waivedOffAmount: row?.waiveInterest?.waivedOffVulnerableAmount, // only vulnerable amount ---as manual is 0 when partial pay
        partialPayment: {
          ...row.partialPayment,
          wishToPay: +form.getFieldValue('wishToPay'),
        },
        waiveInterest: {
          ...row.waiveInterest,
          waivedOffAmount: row?.waiveInterest?.waivedOffVulnerableAmount, // only vulnerable amount ---as manual is 0 when partial pay
          waivedOffDays: row?.waiveInterest?.waivedOffVulnerableDays, // only vulnerable days ---as manual is 0 when partial pay
          waivedOffNonVulnerableAmount: 0,
          waivedOffNonVulnerableDays: 0,
          reasonNotes: row?.waiveInterest?.waivedOffVulnerableAmount ? row?.waiveInterest?.reasonNotes : '',
        },
      };
      const params = {
        agreement: updatedRow,
      };
      dispatch({
        type: CALCULATE_PLEDGE_AGREEMENT_LOAN_AMOUNT,
        payload: { params },
      });
      setPartialPaymentPopupVisible();
    } catch (errorInfo) {
      console.log(errorInfo);
    }
  };

  const validateAmountToPay = (_: any, value: any) => {
    if (value === undefined || value === '')
      return Promise.reject(
        new Error(PARTIAL_PAY.VALIDATION_AMT_TO_PAY.REQUIRED)
      );
    if (+value === 0)
      return Promise.reject(new Error(PARTIAL_PAY.VALIDATION_AMT_TO_PAY.ZERO));
    if (+value >= +form.getFieldValue('dueToday')) {
      return Promise.reject(
        new Error(PARTIAL_PAY.VALIDATION_AMT_TO_PAY.AMOUNT_LESS_THAN_DUE_DATE)
      );
    }
    if (!+value.match(/^\d+(?:\.\d{1,2})?$/)) {
      return Promise.reject(
        new Error(PARTIAL_PAY.VALIDATION_AMT_TO_PAY.ONLY_NUMBERS)
      );
    }
    return Promise.resolve();
  };

  useEffect(() => {
    const { loanAmount, dueToday } = row.partialPayment;
    form.setFieldsValue({
      loanAmount: currencyFormat(loanAmount.toFixed(2)),
      dueToday: currencyFormat(dueToday.toFixed(2)),
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const LabelForAmountToPay = (
    <div>
      <div style={{ margin: 0 }}>
        {PARTIAL_PAY.LABEL_AMT_TO_PAY}
        <StyledDiv>{PARTIAL_PAY.NOTE}</StyledDiv>
      </div>
    </div>
  );

  return (
    <>
      <Modal
        title={
          <HelpPopoverInfo
            linkedID={`PICKUPRENEW_PARTPAYMENT`}
            position="leftTop"
          >
            <span>Partial Payment</span>
          </HelpPopoverInfo>
        }
        open={visible}
        width={526}
        destroyOnClose={true}
        maskClosable={false}
        closable={false}
        onCancel={onCancelClick}
        footer={[
          <Row gutter={24} justify="end" key="partialPopup">
            <Col>
              <Button
                itemProp="secondary"
                key="cancelPartial"
                onClick={onCancelClick}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                key="confirmPartial"
                htmlType="submit"
                onClick={onConfirmClick}
              >
                Confirm
              </Button>
            </Col>
          </Row>,
        ]}
        centered
      >
        <Row>
          {row.waivedOffAmount > 0 ? (
            <Col span={24}>
              <StyledAlertDiv style={{ color: 'var(--red)' }}>
                <StyledWarningFilled />
                {PARTIAL_PAY.ALERT_WAIVE_INTEREST}
              </StyledAlertDiv>
            </Col>
          ) : null}
          <Col span={24}>
            <Form
              layout="vertical"
              form={form}
              className="partial-payment-form"
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Principal Loan"
                    name="loanAmount"
                    className="label-control"
                  >
                    <Input
                      className="input-control amount-to-pay-input"
                      autoComplete="off"
                      disabled
                      addonBefore={'£'}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Total Due to Date"
                    name="dueToday"
                    className="label-control"
                  >
                    <Input
                      className="input-control amount-to-pay-input"
                      autoComplete="off"
                      disabled
                      addonBefore={'£'}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={LabelForAmountToPay}
                    name="wishToPay"
                    className="label-control"
                    rules={[
                      {
                        validator: validateAmountToPay,
                      },
                    ]}
                  >
                    <InputDecimal
                      className="input-control amount-to-pay-input"
                      autoFocus
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default PartialPaymentPopup;
