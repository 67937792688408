import React, { useState } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';

import { GRID_NO_URL, FNS_DATE_FORMAT_WITH_TIME } from 'globalConstants';
import { format } from 'date-fns';

import VirtualGrid from 'components/common/virtualGrid/index';

interface RateHistoryProps {
  rates: Array<any>;
}

const TableRow = ({ row, ...restProps }: any) => {
  const Cell = () => {
    const { column, tableRow } = restProps;
    const { row } = tableRow;
    return (
      <Table.Cell {...restProps}>
        {column.name === 'sellSpread'
          ? row?.sellSpread + '%'
          : column.name === 'buySpread'
          ? row?.buySpread + '%'
          : ''}
      </Table.Cell>
    );
  };

  return <>{Cell()}</>;
};

const RateHistory = ({ rates = [] }: RateHistoryProps) => {
  const [columns] = useState([
    {
      name: 'userName',
      title: 'User Name',
      getCellValue: (row: any) => row?.userName,
    },
    {
      name: 'sellRate',
      title: 'Sell Rate',
      getCellValue: (row: any) => row?.sellRate,
    },
    {
      name: 'sellSpread',
      title: 'Sell Spread',
      getCellValue: (row: any) => row?.sellSpread,
    },
    {
      name: 'buyRate',
      title: 'Buy Rate',
      getCellValue: (row: any) => row?.buyRate,
    },
    {
      name: 'buySpread',
      title: 'Buy Spread',
      getCellValue: (row: any) => row?.buySpread,
    },
    {
      name: 'notes',
      title: 'Notes',
      getCellValue: (row: any) => row?.note,
    },
    {
      name: 'updated',
      title: 'Updated',
      getCellValue: (row: any) =>
        row && row?.updateddOn
          ? format(new Date(row?.updateddOn), FNS_DATE_FORMAT_WITH_TIME)
          : null,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'userName', width: '18%' },
    { columnName: 'sellRate', width: '9%' },
    { columnName: 'sellSpread', width: '10%' },
    { columnName: 'buyRate', width: '9%' },
    { columnName: 'buySpread', width: '10%' },
    { columnName: 'notes', width: '23%' },
    { columnName: 'updated', width: '19%' },
  ]);

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={rates}
        cellComponent={(restProps: any) => <TableRow {...restProps} />}
      />
    </>
  );
};

export default RateHistory;
