import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Row, Col, Input, Checkbox, Layout, Form, notification } from 'antd';
// import { Button } from 'components/common/Button'; // Commenting as per ticket MP-6265
// import { PlusOutlined } from '@ant-design/icons/lib/icons'; Commenting as per ticket MP-6265
import styled from 'styled-components';
import CustomerHeader from 'components/customer/customerHeader';
import {
  FOOTER_BUTTONS_AND_TEXT,
  ROUTE_CONFIG,
  BASKET_SERVICE_TYPE,
  RESPONSE_STATUS,
} from 'globalConstants';
import FooterButtons from 'components/common/footerButtons';
import InputDecimal from 'components/common/InputDecimal';
import { useDispatch, useSelector } from 'react-redux';
import { CEATE_BASKET_WESTERN_UNION } from 'action_creators/basket';
import { actions as basketActions } from 'redux/reducers/basket';
import './index.less';
import { getMtcnValidation } from 'services/basket';
import {
  ERROR_MESSAGE_ON_MTCN,
  REFUND_AMOUNT_VALIDATION,
  TRANSACTION_TYPE,
  WESTERN_UNION_FORM_ERRORS,
} from './constants';

// import ImageLoader from 'components/common/ImageLoader'; Commenting as per ticket MP-6265
// import { imageFromScanner } from 'services/user'; // Commenting as per ticket MP-6265
// import { UPLOAD_WESTERN_UNION_DOCUMENT_REQUEST } from 'action_creators/westernUnion'; // Commenting as per ticket MP-6265
import { actions as WesternUnionActions } from 'redux/reducers/westernUnion';
import ScannerPopover from 'components/customer/newCustomer/scannerPopover';
import {
  DEFAULT_SCAN_MESSAGE,
  OCR_MESSAGE,
} from 'components/customer/constants';
import TextArea from 'components/common/TextArea';
import { isEmpty } from 'lodash';

const StyledLayout = styled(Layout)`
  background-color: var(--white);
`;

const StyledContentLayout = styled(Layout)`
  background: var(--white);
  padding: 30px;
  border-radius: 5px;
`;

const StyledCheckbox = styled(Col)`
  top: 31px;
`;
// Commenting as per ticket MP-6265
// const DocumentCaptureRow = styled(Row)`
//   top: 31px;

//   & .mx-portrait-avatar {
//     width: 7rem !important;
//     height: 5rem !important;
//   }
// `;

// const StyledButton = styled(Button)`
//   height: 42px;
//   width: 42px;
//   padding: 0 14px;
// `;

const StyledCol = styled(Col)`
  margin-bottom: -6px;
  margin-top: -5px;
`;

/* eslint-disable no-template-curly-in-string */
export const validateMessages = {
  required: '${label} is required!',
};

const WesternUnion = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    basket: { basket, isLoading, error, errorCode },
    user: { user, selectedStore },
    customer: { customer },
    WesternUnion: {
      documentsData,
      // documentsImages, // Commenting as per ticket MP-6265
      searching,
    },
  } = useSelector((state: any) => {
    return {
      basket: state.basket,
      user: state.user,
      customer: state.customer,
      WesternUnion: state.westernUnion,
    };
  });

  const [form] = Form.useForm();

  const [isRefund, setIsRefund] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [paidAmount, setPaidAmount] = useState<number>(0);
  const [receivedAmount, setReceivedAmount] = useState<number>(0);
  const [isMTCNexists, setMTCNexists] = useState<boolean>(false);
  const [isRefundAllowed, SetRefundAllowed] = useState<boolean>(true);
  const [isReceivePaymentAllowed, setReceivePaymentAllowed] =
    useState<boolean>(true);
  const [isPaidPaymentAllowed, setPaidPaymentAllowed] = useState<boolean>(true);
  const [isPaidOrReceivedAmount, setIsPaidOrReceivedAmount] =
    useState<boolean>(false);

  useEffect(() => {
    const isWUCreated = basket?.basketServices?.some(
      (el: any) => el.serviceType === BASKET_SERVICE_TYPE.WU
    );

    if (isSubmitted && !isLoading) {
      if (isWUCreated) navigate(`/${ROUTE_CONFIG.BASKET}`);
      if (error?.description && errorCode !== RESPONSE_STATUS.NO_CONTENT) {
        notification.error({
          message: error?.description,
          duration: 3,
        });
        dispatch(basketActions.clearError());
      }
    }
  }, [isSubmitted, navigate, isLoading, error, errorCode, basket]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const isProceedBtnDisable = Boolean(receivedAmount && paidAmount);
    setIsPaidOrReceivedAmount(isProceedBtnDisable);
  }, [paidAmount, receivedAmount]);

  const onRefundChange = (e: any) => {
    setIsRefund(e.target.checked);
    validateMtcnNumber(e);
  };

  const validateMtcnNumber = async (e: any) => {
    setMTCNexists(false);
    if (form.isFieldTouched('mtcnRefNo')) {
      const { mtcnRefNo, isRefund } = form.getFieldsValue();
      const params = {
        MtcnRefNo: mtcnRefNo,
        IsRefund: isRefund ? isRefund : false,
      };
      const response = await getMtcnValidation(params);
      if (response?.data?.transactions?.length > 0) {
        setMTCNexists(true);
        form.setFieldsValue({
          receivedAmount: response?.data?.transactions[0].receivedAmount,
          paidAmount: response?.data?.transactions[0].paidAmount,
          notes: response?.data?.transactions[0].notes,
        });
        setPaidAmount(response?.data?.transactions[0].paidAmount);
        setReceivedAmount(response?.data?.transactions[0].receivedAmount);
        response?.data?.transactions?.map((service: any) => {
          switch (service.transactionType) {
            case TRANSACTION_TYPE.REFUND:
              SetRefundAllowed(false);
              break;
            case TRANSACTION_TYPE.RECEIVED:
              setReceivePaymentAllowed(false);
              break;
            case TRANSACTION_TYPE.PAID:
              setPaidPaymentAllowed(false);
              SetRefundAllowed(false);
              break;
            default:
              return;
          }
        });
        if (
          response?.data?.transactions[0].transactionType ===
          TRANSACTION_TYPE.REFUND
        ) {
          form.setFields([
            {
              name: 'mtcnRefNo',
              errors: [ERROR_MESSAGE_ON_MTCN.REFUND_ERROR_MESSAGE],
            },
          ]);
        } else if (response?.data?.transactions?.length === 3) {
          form.setFields([
            {
              name: 'mtcnRefNo',
              errors: [ERROR_MESSAGE_ON_MTCN.MTCN_ALREADY_USED],
            },
          ]);
        } else {
          form.setFields([
            {
              name: 'mtcnRefNo',
              errors: [ERROR_MESSAGE_ON_MTCN.ERROR_MESSAGE] || [],
            },
          ]);
        }
      }
    }
    return;
  };

  const onSubmit = (value: any) => {
    if (Boolean(value.isRefund) && !value.paidAmount) {
      return false;
    }
    if (Boolean(value.isRefund) && value.paidAmount) {
      value.receivedAmount = 0;
    }
    if (
      value.receivedAmount &&
      isReceivePaymentAllowed &&
      !isPaidPaymentAllowed
    ) {
      value.paidAmount = 0;
    }
    if (value.paidAmount && isPaidPaymentAllowed && !isReceivePaymentAllowed) {
      value.receivedAmount = 0;
    }
    const {
      street,
      town,
      property,
      county,
      postcode,
      houseName,
      area,
      firstNames,
      middleName,
      surname,
    } = customer;
    const payload = {
      basketId: basket?.basketId || null, //null for creating a basket
      customerId: customer.customerId,
      customer: {
        street,
        town,
        property,
        county,
        postcode,
        houseName,
        area,
        firstNames,
        middleName,
        surname,
      },
      employeeId: user?.employeeId,
      storeId: selectedStore?.storeId,
      storeAddress: selectedStore?.storeAddress,
      storePostalCode: selectedStore?.postalCode,
      storeTelephone: selectedStore?.telephone,
      userId: user?.userId,
      userName: user?.authorizeUsername,
      westernUnion: {
        ...value,
        itemDocumentCollections: {
          documents: documentsData,
        },
      },
    };

    dispatch({
      type: CEATE_BASKET_WESTERN_UNION,
      payload: { params: payload },
    });
    dispatch(WesternUnionActions.clearDocumentsData());
    setTimeout(() => {
      setIsSubmitted(true);
    }, 10);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); //Need this console for checking error
    if (
      Object.values(errorInfo.values).filter((x) => x !== undefined && x !== '')
        .length === 0 &&
      basket?.basketId !== null
    ) {
      navigate(`/${ROUTE_CONFIG.BASKET}`);
    }
  };

  const onProceedClick = () => {
    if (!isRefund) {
      const currentMTCNNo = form.getFieldValue('mtcnRefNo');
      const createdWU = basket?.basketServices?.filter(
        (el: any) => el.serviceType === BASKET_SERVICE_TYPE.WU
      );

      const availableMTCNs =
        createdWU?.reduce((arr: any, el: any) => {
          const mtcnRefNo = el['westernUnion']['request']['mtcnRefNo'];

          return mtcnRefNo ? [...arr, mtcnRefNo] : [...arr];
        }, []) || [];

      if (availableMTCNs?.includes(currentMTCNNo))
        return notification.error({
          message: 'Entered MTCN no. already exists in basket.',
          duration: 3,
        });
    }
    form.submit();
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 8 },
      sm: { span: 24, offset: 8 },
      lg: { span: 24, offset: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24, offset: 8 },
      lg: { span: 8, offset: 8 },
    },
  };

  const paidAmountHandler = (event: any) => {
    const value = event.target.value;
    const receivedAmt = form.getFieldValue('receivedAmount');
    if (isRefund && +value > +receivedAmt && !isRefundAllowed) {
      return;
    }
    setPaidAmount(Number(value));
  };

  const receivedAmountHandler = (event: any) => {
    const value = event.target.value;
    setReceivedAmount(Number(value));
  };

  const refundAmountValidation = useCallback(
    (_: any, value: any) => {
      const { receivedAmount: rvalue, isRefund } = form.getFieldsValue();

      if (+value > +rvalue && isMTCNexists && isRefund) {
        setIsPaidOrReceivedAmount(false);
        return Promise.reject(new Error(REFUND_AMOUNT_VALIDATION));
      }
      if (+value > +rvalue && isMTCNexists && !isPaidPaymentAllowed) {
        setIsPaidOrReceivedAmount(false);
        return Promise.reject(
          new Error(ERROR_MESSAGE_ON_MTCN.MTCN_ALREADY_USED)
        );
      }
      if (+value === 0 && isRefund) {
        setIsPaidOrReceivedAmount(true);
        return Promise.reject(new Error(''));
      }
      if (!Number(value) && !rvalue) {
        setIsPaidOrReceivedAmount(true);
        return Promise.reject(new Error(''));
      }
      setIsPaidOrReceivedAmount(false);
      return Promise.resolve();
    },
    [form, isMTCNexists, isPaidPaymentAllowed]
  );

  const receivedAmountValidation = useCallback(
    (_: any, value: any) => {
      if (!Number(value) && !paidAmount) {
        setIsPaidOrReceivedAmount(true);
        return Promise.reject(new Error(''));
      }
      if (
        isEmpty(Number(value)) &&
        paidAmount === 0 &&
        isReceivePaymentAllowed &&
        isMTCNexists &&
        isRefundAllowed
      ) {
        setIsPaidOrReceivedAmount(true);
        return Promise.reject(
          new Error(ERROR_MESSAGE_ON_MTCN.MTCN_ALREADY_USED)
        );
      }
      if (
        isEmpty(Number(value)) &&
        paidAmount === 0 &&
        !isReceivePaymentAllowed &&
        isMTCNexists
      ) {
        setIsPaidOrReceivedAmount(true);
        return Promise.reject(
          new Error(ERROR_MESSAGE_ON_MTCN.MTCN_ALREADY_USED)
        );
      }
      setIsPaidOrReceivedAmount(false);
      return Promise.resolve();
    },
    [paidAmount, isReceivePaymentAllowed, isRefundAllowed]
  );

  const checkDisable = (): boolean => {
    return (
      !isRefundAllowed && !isPaidPaymentAllowed && !isReceivePaymentAllowed
    );
  };

  const resetFormFields = () => {
    form.setFieldsValue({
      receivedAmount: '',
      paidAmount: '',
      notes: '',
    });
    SetRefundAllowed(true);
  };

  // Commenting as per ticket MP-6265
  // const scanDocument = async () => {
  //   const img = await imageFromScanner();

  //   if (img === undefined) {
  //     return;
  //   }
  //   const data = `data:image/png;base64, ${img}`;

  //   dispatch({
  //     type: UPLOAD_WESTERN_UNION_DOCUMENT_REQUEST,
  //     payload: { image: data },
  //   });
  //   dispatch(WesternUnionActions.setDocumentsImages(data));
  // };

  // const onDocumentCaptureClick = async (index: any) => {
  //   scanDocument();
  // };

  const checkExitOnly = () => {
    if (
      (form.getFieldValue('mtcnRefNo') !== undefined ||
        form.getFieldValue('mtcnRefNo') !== null) &&
      (form.getFieldValue('receivedAmount') > 0 ||
        form.getFieldValue('paidAmount') > 0)
    ) {
      return false;
    } else return true;
  };

  useEffect(() => {
    const isMTCNCreated = form.getFieldValue('mtcnRefNo');

    if (!isMTCNCreated) {
      dispatch(WesternUnionActions.clearDocumentsData());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <CustomerHeader title={'Western Union'} buttonsOption={false} />
      <Col className="western-union-wrapper">
        <StyledLayout className="split-control-box">
          <StyledContentLayout>
            <Form
              {...formItemLayout}
              onFinish={onSubmit}
              onFinishFailed={onFinishFailed}
              form={form}
              layout="vertical"
              validateMessages={validateMessages}
            >
              <Form.Item>
                <Row gutter={24}>
                  <StyledCol span={20}>
                    <Form.Item
                      label="MTCN"
                      name="mtcnRefNo"
                      className="label-control"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          pattern: new RegExp('^[0-9]{10}$'),
                          message:
                            'MTCN minimum and maximum number should be 10 digits',
                        },
                      ]}
                    >
                      <Input
                        className="input-control"
                        autoComplete="off"
                        autoFocus={true}
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onBlur={(e) => {
                          validateMtcnNumber(e);
                        }}
                        onChange={resetFormFields}
                      />
                    </Form.Item>
                  </StyledCol>
                  <StyledCheckbox span={4}>
                    <Form.Item
                      label=""
                      name="isRefund"
                      className="label-control"
                      valuePropName="checked"
                    >
                      <Checkbox
                        disabled={!isPaidPaymentAllowed || !isRefundAllowed}
                        onChange={onRefundChange}
                      >
                        Refund
                      </Checkbox>
                    </Form.Item>
                  </StyledCheckbox>
                </Row>
              </Form.Item>
              <Form.Item
                label="Received from customer"
                name="receivedAmount"
                className="label-control"
                rules={[{ validator: receivedAmountValidation }]}
              >
                <InputDecimal
                  disabled={isRefund}
                  onChange={receivedAmountHandler}
                  className="input-control input-with-border"
                />
              </Form.Item>
              <Form.Item
                label={isRefund ? 'Refunded to customer' : 'Paid to customer'}
                name="paidAmount"
                className="label-control"
                rules={[{ validator: refundAmountValidation }]}
              >
                <InputDecimal
                  disabled={receivedAmount > 0 && !isPaidPaymentAllowed}
                  onChange={paidAmountHandler}
                  className="input-control input-with-border"
                />
              </Form.Item>

              {isPaidOrReceivedAmount && (
                <Form.Item>
                  <Row>
                    <StyledCol className="ant-form-item-explain-error">
                      {isRefund
                        ? WESTERN_UNION_FORM_ERRORS.REFUNDED_TO_CUSTOMER_REQUIRED
                        : WESTERN_UNION_FORM_ERRORS.EITHER_PAID_OR_RECEIVED_TO_CUSTOMER}
                    </StyledCol>
                  </Row>
                </Form.Item>
              )}
              <Form.Item label="Notes" name="notes" className="label-control">
                <TextArea
                  rows={5}
                  autoComplete="off"
                  dataTestId="western-union-textarea"
                />
              </Form.Item>
              {/* Commenting as per Ticket MP-6265 */}
              {/* <Form.Item
                label="MTCN & Supporting Documents"
                name="supportingDocuments"
                className="label-control"
              >
                <DocumentCaptureRow>
                  <Col span={3}>
                    <StyledButton itemProp="secondary">
                      <PlusOutlined />
                    </StyledButton>
                  </Col>
                  <Col span={9}>
                    <Button
                      type="primary"
                      itemProp="secondary"
                      htmlType="button"
                      onClick={onDocumentCaptureClick}
                    >
                      Capture Documents
                    </Button>
                  </Col>
                  <Col span={12}>
                    {documentsImages &&
                      documentsImages?.map((img: any, index: number) => (
                        <ImageLoader
                          key={index}
                          isLoadFromRedux={true}
                          imageUrl={img}
                          classes={'mx-portrait-avatar'}
                          fallbackImg={'no_image_thumbnail.svg'}
                          isCaching={false}
                          preview
                        />
                      ))}
                  </Col>
                </DocumentCaptureRow>
              </Form.Item> */}
            </Form>
          </StyledContentLayout>
          <FooterButtons
            enableExtraClass
            isExitOnly={checkExitOnly()}
            backButtonText={FOOTER_BUTTONS_AND_TEXT.EXIT_AND_SAVE}
            extraButtonText={FOOTER_BUTTONS_AND_TEXT.PRINT_RECIEPT}
            proceedButtonText={FOOTER_BUTTONS_AND_TEXT.PROCEED_TO_BASKET}
            onProceedClick={onProceedClick}
            isLoading={isLoading}
            isDisabled={checkDisable()}
            saveJourneyHandler={() => {
              onProceedClick();
            }}
            isSaveJourney={true}
          />
        </StyledLayout>
      </Col>
      <ScannerPopover
        visible={searching}
        message={searching ? OCR_MESSAGE : DEFAULT_SCAN_MESSAGE}
      />
    </>
  );
};

export default WesternUnion;
