import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Spin, notification } from 'antd';

import BankTransfersTable from './bankTransfersTable';
import PaymentsStatusPopup from '../notificationsWorkQueue/PaymentsStatusPopup';

import { SHOW_ALERT_TYPE, STATUS_LABELS } from '../constants';

import { completeFasterPayments } from 'services/basket';
import { sendToPrinter } from 'services/user';
import { generateReceipt } from 'services/payment';
import { postLedgerBalanceCurrency } from 'services/travelMoney';

import { actions as userActions } from 'redux/reducers/user';

const BankTransfers = () => {
  const {
    user: { isLoading, user, selectedStore, bankTransfers },
    cashManagement: { userTillUpdated },
  } = useSelector((state: any) => {
    return { user: state.user, cashManagement: state.cashManagement };
  });

  const dispatch = useDispatch();

  const [isTillLoading, setIsTillLoading] = useState<boolean>(false);
  const [rowAmount, setRowAmount] = useState<boolean>(true);

  const [clientReference, setClientReference] = useState<any>('');
  const [rowUserName, setRowUserName] = useState<string>('');
  const [customerRequestReviewId, setCustomerRequestReviewId] =
    useState<any>('');
  const [paymentStatusAlertType, setPaymentStatusAlertType] = useState<any>(0);

  const [storeTill, setStoreTill] = useState<any>(0);
  const [userTill, setUserTill] = useState<any>(0);

  const [isPaymentPopupVisible, setIsPaymentPopupVisible] =
    useState<boolean>(false);

  useEffect(() => {
    getStoreTillsStatus();
  }, [userTillUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  const getStoreTillsStatus = async () => {
    const payload = {
      storeNumber: selectedStore?.storeId,
      userId: user?.userId,
      currencyCode: 'GBP',
    };
    try {
      setIsTillLoading(true);
      const currencyBalance = await postLedgerBalanceCurrency(payload);
      if (currencyBalance?.data) {
        setStoreTill(
          currencyBalance?.data?.storeBalance
            ? currencyBalance?.data?.storeBalance
            : 0
        );
        setUserTill(
          currencyBalance?.data?.userBalance
            ? currencyBalance?.data?.userBalance
            : 0
        );
        setIsTillLoading(false);
      }
    } catch (e: any) {
      setIsTillLoading(false);
      notification.error({
        message: e?.response?.data?.error || e?.message,
        duration: 5,
      });
    }
  };

  const printPaymentReceipt = useCallback(async () => {
    try {
      const storeReceipt = await generateReceipt(clientReference, 1); // store receipt
      const customerReceipt = await generateReceipt(clientReference, 2); // customer receipt

      const values = await Promise.all([storeReceipt, customerReceipt]);

      values.forEach((receipt: any) => {
        if (receipt?.data) {
          sendToPrinter(receipt, 'printreceipt', '_PrintReceipt');
        }
      });
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.error || e?.message,
        duration: 5,
      });
    }
  }, [clientReference]);

  const onProcessClick = (processDetails: any) => {
    const [
      userId,
      status,
      amount,
      clientReference,
      customerRequestReviewId,
      userName,
    ] = processDetails;

    setIsPaymentPopupVisible(true);
    setRowAmount(amount);
    setClientReference(clientReference);
    setCustomerRequestReviewId(customerRequestReviewId);
    setRowUserName(userName);

    if (status === STATUS_LABELS.APPROVED) {
      setPaymentStatusAlertType(SHOW_ALERT_TYPE.APPROVED);
      return;
    } else {
      //check store till
      if (+storeTill < +amount) {
        setPaymentStatusAlertType(SHOW_ALERT_TYPE.STORE_TILL);
        return;
      }

      //check user till
      if (+userTill < +amount) {
        setPaymentStatusAlertType(SHOW_ALERT_TYPE.USER_TILL);
        return;
      }

      //check if different user
      if (user?.userId !== userId) {
        setPaymentStatusAlertType(SHOW_ALERT_TYPE.DIFFERENT_USER);
        return;
      }
      setPaymentStatusAlertType(SHOW_ALERT_TYPE.REJECTED);
    }
  };

  const onCompleteFasterPayment = useCallback(async () => {
    const payload = {
      basketId: clientReference,
      clientRequestReviewId: customerRequestReviewId || 0,
      authorizeUsername: user?.authorizeUsername,
      displayName: user?.displayName,
    };

    try {
      dispatch(userActions.setLoading(true));
      const response = await completeFasterPayments(payload);
      if (response?.data?.statusCode === 200) {
        dispatch(userActions.setLoading(false));
        setIsPaymentPopupVisible(false);
        dispatch(userActions.initReloadBankTransfers(true));
        await printPaymentReceipt();
      } else {
        notification.error({
          message: response?.data?.errorMessage,
          duration: 5,
        });
      }
    } catch (e: any) {
      dispatch(userActions.setLoading(false));
      setIsPaymentPopupVisible(false);
      notification.error({
        message: e?.response?.data?.error || e?.message,
        duration: 5,
      });
    }
  }, [
    clientReference,
    customerRequestReviewId,
    printPaymentReceipt,
    user,
    dispatch,
  ]);

  return (
    <>
      <Spin spinning={isTillLoading}>
        <BankTransfersTable
          bankTransfers={bankTransfers}
          onProcessClick={onProcessClick}
        />
      </Spin>

      {isPaymentPopupVisible && (
        <PaymentsStatusPopup
          amount={rowAmount}
          setIsPaymentPopupVisible={setIsPaymentPopupVisible}
          isPaymentPopupVisible={isPaymentPopupVisible}
          paymentStatusAlertType={paymentStatusAlertType}
          onCompleteClick={onCompleteFasterPayment}
          userName={rowUserName}
          isDisableComplete={isLoading}
        />
      )}
    </>
  );
};

export default BankTransfers;
