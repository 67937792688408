import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { STOCK_TRANSFER_LABELS } from 'components/inventoryManagement/constants';
import { actions } from 'redux/reducers/inventoryManagement';

import TransferManager from './TransferManager';
import CreateEditTransfer from './CreateEditTransfer';
import AcceptTransfer from './AcceptTransfer';

const StockTransfer = () => {
  const { transferParams, isAcceptTransfer } = useSelector(
    (state: any) => state.inventoryManagement
  );

  const dispatch = useDispatch();

  const [activeView, setActiveView] = useState<string>(
    STOCK_TRANSFER_LABELS.TITLES.TRANSFER_MANAGER
  );

  useEffect(() => {
    return () => {
      dispatch(actions.clearStockTransfer());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isAcceptTransfer) setViewtoAcceptTransfer();
    else if (transferParams.transferId) setViewtoCreateEditTransfer();
    else setViewtoTransferManager();
  }, [transferParams, isAcceptTransfer]);

  const setViewtoCreateEditTransfer = () => {
    setActiveView(STOCK_TRANSFER_LABELS.TITLES.CREATE_EDIT_TRANSFER);
  };

  const setViewtoTransferManager = () => {
    setActiveView(STOCK_TRANSFER_LABELS.TITLES.TRANSFER_MANAGER);
  };

  const setViewtoAcceptTransfer = () => {
    setActiveView(STOCK_TRANSFER_LABELS.TITLES.ACCEPT_TRANSFER);
  };

  return (
    <>
      {activeView === STOCK_TRANSFER_LABELS.TITLES.TRANSFER_MANAGER && (
        <TransferManager
          createEditTransferHandler={setViewtoCreateEditTransfer}
        />
      )}
      {activeView === STOCK_TRANSFER_LABELS.TITLES.CREATE_EDIT_TRANSFER && (
        <CreateEditTransfer onExit={setViewtoTransferManager} />
      )}
      {activeView === STOCK_TRANSFER_LABELS.TITLES.ACCEPT_TRANSFER && (
        <AcceptTransfer onExit={setViewtoTransferManager} />
      )}
    </>
  );
};

export default StockTransfer;
