import React, { useCallback, useEffect, useState } from 'react';
import {
  CANCEL,
  SAVE,
  ADD_A_NEW_NOTE,
  MORE_INFO,
  PROFANITY_CHECK,
  REMOVE_PROFANITY,
} from 'components/customer/constants';
import { Button } from 'components/common/Button';
import { Row, Col, Form, Modal } from 'antd';
import { getNoteTypes, postCustomerNote } from 'services/customer';
import { cacheStaticData } from 'utils/cacheServices';
import { useSelector } from 'react-redux';

import Select from 'components/common/Select';
import TextArea from 'components/common/TextArea';
import { isUndefined } from 'lodash';
import ControlledAlert from 'components/common/ControlledAlert';

interface NewNotePopupProps {
  isNewNotePopupVisible: boolean;
  onNewNotePopupCancel: () => void;
  onNoteAddClicked: (isAddedNote: boolean) => void;
}

interface ProfanityError {
  profanity: boolean;
  badWords: Array<string>;
}

const NewNotePopup = ({
  isNewNotePopupVisible,
  onNewNotePopupCancel,
  onNoteAddClicked,
}: NewNotePopupProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [noteMessageList, setNoteMessageList] = useState<any>([]);
  const {
    customer: { customer },
    user: { user },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
      user: state.user,
    };
  });
  const [disableAdd, setDisableAdd] = useState<boolean>(true);
  const [form] = Form.useForm();
  useEffect(() => {
    if (isNewNotePopupVisible) {
      setVisible(true);
    }
  }, [isNewNotePopupVisible]);
  const [profanityError, setProfanityError] = useState<ProfanityError>({
    profanity: false,
    badWords: [''],
  });
  const [isProfanity, setProfanity] = useState<boolean>(false);

  const onCloseClick = useCallback(() => {
    onNewNotePopupCancel();
  }, [onNewNotePopupCancel]);

  const loadNoteMessageList = async () => {
    //react-hooks/exhaustive-deps
    try {
      const response = await cacheStaticData(getNoteTypes);
      if (response?.data) setNoteMessageList(response?.data);
    } catch (e: any) {
      console.log(e);
    }
  };

  const addNewNote = async (params: any) => {
    try {
      await postCustomerNote(params);
      onNoteAddClicked(true);
    } catch (e: any) {
      console.log(e);
    }
  };

  useEffect(() => {
    loadNoteMessageList();
  }, []);

  const onInputKeyUp = () => {
    if (
      !isUndefined(form.getFieldValue('customerNoteTypeId')) &&
      form.getFieldValue('noteText') !== '' &&
      !isUndefined(form.getFieldValue('noteText'))
    ) {
      setDisableAdd(false);
    } else {
      setDisableAdd(true);
    }
  };
  const onSaveNoteClicked = useCallback(() => {
    if (profanityError.profanity) {
      setProfanity(true);
    } else {
      const fieldSet: any = {
        customerId: Number(customer.customerId),
        customerNoteTypeId: Number(form.getFieldValue('customerNoteTypeId')),
        createdBy: Number(user?.userId),
        noteText: form.getFieldValue('noteText'),
      };
      addNewNote(fieldSet);
      setDisableAdd(false);
      form.resetFields();
      onCloseClick();
    }
  }, [form, customer.customerId, profanityError]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleProfanityCheck = (
    profanity: boolean,
    badWords: Array<string>
  ) => {
    setProfanityError({ profanity, badWords });
  };

  return (
    <Modal
      className="add-note-popup"
      open={visible}
      closable={true}
      key={1}
      centered
      width={788}
      maskClosable={false}
      onCancel={onCloseClick}
      footer={
        <Row>
          <Col span={24}>
            <Button
              key="cancel"
              onClick={onCloseClick}
              className="cancel-btn"
              itemProp="primary"
            >
              {CANCEL}
            </Button>

            <Button
              key="save"
              className="save-btn"
              disabled={disableAdd}
              onClick={onSaveNoteClicked}
            >
              {SAVE}
            </Button>
          </Col>
        </Row>
      }
    >
      <Form layout="vertical" form={form}>
        <Row>
          <label className="heading">{ADD_A_NEW_NOTE}</label>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Select Note Message"
                  name="customerNoteTypeId"
                  className="label-control customer-note-message"
                >
                  <Select
                    onChange={onInputKeyUp}
                    key="select-note"
                    options={noteMessageList?.map((el: any) => {
                      return {
                        value: el.customerNoteTypeId,
                        label: el.description,
                      };
                    })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label=""
                  name="noteText"
                  className="label-control form-textarea"
                >
                  <TextArea
                    placeholder={MORE_INFO}
                    onChange={onInputKeyUp}
                    maxLength={1024}
                    rows={6}
                    autoComplete="on"
                    spellCheck
                    profanityCheck
                    profanityExists={handleProfanityCheck}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      {isProfanity && (
        <ControlledAlert
          id="caseSummary"
          width={480}
          visible={isProfanity}
          message={PROFANITY_CHECK.REMOVE_PROFANITY}
          secondaryMessage={
            <>
              {PROFANITY_CHECK.PROFANITY_WORDS}
              <b>&nbsp;{profanityError.badWords.join(', ')}</b>
            </>
          }
          onClick={() => setProfanity(false)}
          yesButtonText={REMOVE_PROFANITY}
          isCancelBtn={false}
        />
      )}
    </Modal>
  );
};
export default NewNotePopup;
