import * as urls from '../apiUrls';
import { b64toBlob } from 'utils/util';
import { doRequest } from 'utils/request';

const uploadWesternUnionDoc = (params) => {
  let blob = b64toBlob(params);

  let formData = new FormData();
  formData.append('file', blob);

  return doRequest(
    `${urls.WESTERN_UNION_DOCUMENT}`,
    'post',
    formData,
    'multipart/form-data'
  );
};

export { uploadWesternUnionDoc };
