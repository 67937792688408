import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { groupBy, isEmpty } from 'lodash';
import styled from 'styled-components';
import Avatar from 'antd/lib/avatar/avatar';
import { format } from 'date-fns';
import { Row, Col, Drawer, Radio, Input, Spin } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';

import { actions as userActions } from 'redux/reducers/user';
import { actions as basketActions } from 'redux/reducers/basket';
import { Button } from 'components/common/Button';
import { recallAllGuestBaskets } from 'services/basket';
import { getBasketByBasketId } from 'services/checkout';
import { RECALL_MENU_LABELS } from '../constants';
import {
  FNS_DATE_FORMAT,
  ROUTE_CONFIG,
  SERVICE_TYPE_TITLE_BY_ID,
} from 'globalConstants';
import { RECALL_MERGE_BASKETS } from 'action_creators/basket';
import Alert from 'components/common/ControlledAlert';

import './index.less';

const StyledTitle = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
`;

const StyledRightRow = styled(Row)`
  justify-content: flex-end;
  column-gap: 20px;
  padding-bottom: 10px;
`;

const StyledButton = styled(Button)`
  background-color: white;
  color: black;
`;

const StyledAvatar = styled(Avatar)`
  margin: 0 12px;
  border: 2px solid var(--avatar-border);
`;

const RecallMenu = () => {
  const {
    user: { isRecallMenuVisible, selectedStore },
    customer: { customer },
    basket: { basket, isGuestBasketRestoreLoading, isGuestBasketRestoreError },
  } = useSelector((state: any) => {
    return { user: state.user, customer: state.customer, basket: state.basket };
  });

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [restoreTriggred, setRestoreTriggred] = useState<boolean>(false);
  const [reference, setReference] = useState<any>('');
  const [selectedBasketId, setSelectedBasketId] = useState<string>('');
  const [guestBaskets, setGuestBaskets] = useState<Array<any>>([]);
  const [filteredBaskets, setFilteredBaskets] = useState<Array<any>>([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getAllGuestBasket();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      restoreTriggred &&
      !isGuestBasketRestoreLoading &&
      !isGuestBasketRestoreError
    ) {
      navigate(`/${ROUTE_CONFIG.BASKET}`);
      onRecallMenuClose();
    }
  }, [isGuestBasketRestoreLoading, isGuestBasketRestoreError]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllGuestBasket = async () => {
    try {
      setIsFetching(true);

      const response = await recallAllGuestBaskets(selectedStore.storeId);
      if (response?.data) {
        if (basket?.basketId) {
          const guestBaskets = response?.data?.baskets.filter(
            (el: any) => el.basketId !== basket?.basketId
          );
          setGuestBaskets(guestBaskets);
        } else setGuestBaskets(response?.data?.baskets);
      }

      setIsFetching(false);
    } catch (e: any) {
      setIsFetching(false);
    }
  };

  const onRefrenceChange = (event: any) => {
    const { value } = event.target;
    setReference(value);
  };

  const onRadioChange = (event: any) => {
    const { value } = event.target;
    setSelectedBasketId(value);
  };

  const onSearchClick = () => {
    if (!reference) return setFilteredBaskets([]);

    const filteredBaskets = guestBaskets.filter((obj: any) =>
      obj?.basketId?.includes(reference)
    );
    setFilteredBaskets(filteredBaskets);
  };

  const onRestoreClick = async () => {
    const params = {
      customerBasketId: null,
      guestBasketId: selectedBasketId,
      customerId: null,
      customer: {},
    };

    setRestoreTriggred(true);

    if (!basket.basketId) {
      if (isEmpty(customer)) await getBasketById();
      else {
        params.customerId = customer?.customerId;
        params.customer = customer;

        dispatch({
          type: RECALL_MERGE_BASKETS,
          payload: { params },
        });
      }
    } else {
      params.customerBasketId = basket.basketId;

      dispatch({
        type: RECALL_MERGE_BASKETS,
        payload: { params },
      });
    }
  };

  const getBasketById = async () => {
    try {
      dispatch(basketActions.initGuestBasketRestoreLoading(true));
      const response = await getBasketByBasketId(selectedBasketId);
      if (response?.data) {
        dispatch(basketActions.createBasket(response?.data));
      } else {
        dispatch(basketActions.setGuestBasketRestoreError(true));
      }
      dispatch(basketActions.initGuestBasketRestoreLoading(false));
    } catch (e: any) {
      dispatch(basketActions.setGuestBasketRestoreError(true));
      dispatch(basketActions.initGuestBasketRestoreLoading(false));
    }
  };

  const onRecallMenuClose = () => {
    dispatch(userActions.setRecallMenuVisibility(false));
  };

  const getExpirationDate = (date: any) => {
    const currentDate = format(new Date(), FNS_DATE_FORMAT);
    const formattedDate = format(new Date(date), FNS_DATE_FORMAT);

    return currentDate === formattedDate ? 'Today' : formattedDate;
  };

  const renderGuestBaskets = () => {
    const baskets = filteredBaskets.length > 0 ? filteredBaskets : guestBaskets;

    return baskets.map((basket: any, index: number) => (
      <div className="basket-wrapper" key={`basket-${index}`}>
        <Radio value={basket.basketId}>
          <StyledAvatar
            src={require('../../../assets/images/guest_thumbnail.svg').default}
            size={40}
          />
          <span className="basket-title">{basket.title}</span>
        </Radio>
        <table className="recall-basket-table">
          <thead className="table-header">
            <tr>
              <th style={{ width: '30%' }}>
                {RECALL_MENU_LABELS.TABLE_HEADERS.SERVICE_TYPE}
              </th>
              <th style={{ width: '50%' }}>
                {RECALL_MENU_LABELS.TABLE_HEADERS.AMOUNT}
              </th>
              <th style={{ width: '20%', textAlign: 'right' }}>
                {RECALL_MENU_LABELS.TABLE_HEADERS.EXPIRES}
              </th>
            </tr>
          </thead>
          <tbody>{renderServiceTable(basket)}</tbody>
        </table>
      </div>
    ));
  };

  const renderServiceTable = (basket: any) => {
    const groupedServices = groupBy(basket.basketServices, 'serviceType');

    const serviceTable = Object.keys(groupedServices).map(
      (key: any, serviceIndex: number) => {
        const serviceType = SERVICE_TYPE_TITLE_BY_ID.find(
          (obj: any) => obj.id === +key
        );

        const amount = groupedServices[key].map(
          (entry: any) => `£${Math.abs(entry.amount.toFixed(2))}`
        );

        const expireDate = groupedServices[key][0]?.expiresOn;

        return (
          <tr key={`table-${serviceIndex}`}>
            <td>{serviceType?.title}</td>
            <td>{amount.join(', ')}</td>
            <td style={{ textAlign: 'right' }}>
              {getExpirationDate(expireDate)}
            </td>
          </tr>
        );
      }
    );

    return serviceTable;
  };

  return (
    <>
      <Drawer
        closable={false}
        className="recall-menu-wrapper"
        open={isRecallMenuVisible}
        footer={
          <StyledRightRow>
            <StyledButton onClick={onRecallMenuClose}>
              {RECALL_MENU_LABELS.CANCEL}
            </StyledButton>
            <Button
              loading={isGuestBasketRestoreLoading}
              disabled={!selectedBasketId}
              onClick={onRestoreClick}
            >
              {RECALL_MENU_LABELS.RESTORE}
            </Button>
          </StyledRightRow>
        }
      >
        <Row className="header-row">
          <Col span={24}>
            <Row>
              <Col span={12}>
                <StyledTitle>{RECALL_MENU_LABELS.TITLE}</StyledTitle>
              </Col>
              <Col span={12}>
                <CloseOutlined
                  onClick={onRecallMenuClose}
                  className="recall-close-icon"
                />
              </Col>
            </Row>
            <Row style={{ padding: '20px 0' }}>
              <Col span={2}>
                <div className="search-label">
                  {RECALL_MENU_LABELS.SEARCH_BY}
                </div>
              </Col>
              <Col span={8}>
                <Input
                  type="text"
                  value={reference}
                  className="input-control"
                  placeholder="Quote Reference"
                  onChange={onRefrenceChange}
                  addonAfter={<SearchOutlined onClick={onSearchClick} />}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Spin spinning={isFetching}>
          <Row className="recall-body-wrapper">
            <Col span={24}>
              <Radio.Group
                name="guestBaskets"
                onChange={onRadioChange}
                style={{ width: '100%' }}
              >
                {renderGuestBaskets()}
              </Radio.Group>
            </Col>
          </Row>
        </Spin>
      </Drawer>
      {restoreTriggred &&
        !isGuestBasketRestoreLoading &&
        isGuestBasketRestoreError && (
          <Alert
            visible={true}
            width={460}
            yesButtonText={'OK'}
            itemProp={'secondary'}
            message={RECALL_MENU_LABELS.ERROR_MSG}
            isCancelBtn={false}
            onClick={() => onRecallMenuClose()}
            onCancel={() => undefined}
          />
        )}
    </>
  );
};

export default RecallMenu;
