import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Select } from 'antd';
import styled from 'styled-components';
import { PLEDGE_LOAN, PURCHASE_ITEM_TABLE } from '../../../pledge/constants';
import { SERVICE_TYPE_LABEL_BY_KEY } from 'globalConstants';
import { useEffect } from 'react';
import { actions } from 'redux/reducers/pledgeManagement';

const StyledItemValue = styled.div`
  padding: 5px 15px;
`;

const StyledSelect = styled.div`
  padding: 5px 15px;
  & .ant-select.input-control {
    height: 35px !important;
    & .ant-select-selector {
      height: 33px !important;
      background: var(--white);
    }
  }
  & .ant-select.ant-select-disabled {
    & .ant-select-selector {
      color: var(--black);
    }
  }
`;

const StyledMonthlyRate = styled(Col)`
  display: flex;
  align-items: center;
`;

const StyledBasketCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

const TableRow = (label: string, value: any) => {
  return (
    <tr>
      <td>{label}</td>
      <td>
        {label !== PLEDGE_LOAN.TOTAL_WEIGHT
          ? `£${value?.toFixed(2)}`
          : `${value?.toFixed(2)}g`}
      </td>
    </tr>
  );
};

const PledgeLoanContainer = () => {
  const {
    pledgemanagement: {
      itemCount,
      loanDetails,
      pledgeItemsList,
      purchaseLoanDetails,
      selectedPFIServiceType,
    },
  } = useSelector((state: any) => {
    return {
      pledgemanagement: state.pledgeManagement,
    };
  });
  const dispatch = useDispatch();

  const calculateTotalWeight = (arr?: Array<any>) => {
    const totalWeight =
      arr && arr.length > 0
        ? arr.reduce((a: number, b: any) => {
            return a + b.weight;
          }, 0)
        : 0;

    return totalWeight;
  };

  useEffect(() => {
    dispatch(actions.setTotalWeight(calculateTotalWeight(pledgeItemsList)));
  }, [pledgeItemsList]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row>
      <Col span={24}>
        <Row justify="end">
          <Col lg={15} xl={11} xxl={14}>
            <table className="pledge-valuation-table">
              <tbody>
                {TableRow(
                  PLEDGE_LOAN.TOTAL_WEIGHT,
                  calculateTotalWeight(pledgeItemsList) || 0
                )}
                {TableRow(
                  selectedPFIServiceType === SERVICE_TYPE_LABEL_BY_KEY.PLEDGE
                    ? PLEDGE_LOAN.LOAN_AMOUNT
                    : PURCHASE_ITEM_TABLE.SALE_AMOUNT,
                  selectedPFIServiceType === SERVICE_TYPE_LABEL_BY_KEY.PLEDGE
                    ? loanDetails?.loanAmount
                    : purchaseLoanDetails?.saleAmount
                )}
                {TableRow(
                  selectedPFIServiceType === SERVICE_TYPE_LABEL_BY_KEY.PLEDGE
                    ? PLEDGE_LOAN.DAILY_INTEREST
                    : PURCHASE_ITEM_TABLE.FEE,
                  selectedPFIServiceType === SERVICE_TYPE_LABEL_BY_KEY.PLEDGE
                    ? loanDetails?.dailyInterestDisplay
                    : purchaseLoanDetails?.fee
                )}
                {TableRow(
                  selectedPFIServiceType === SERVICE_TYPE_LABEL_BY_KEY.PLEDGE
                    ? PLEDGE_LOAN.LOAN_AMT_PAYABLE
                    : PURCHASE_ITEM_TABLE.RECLAIM_AMT_PAYABLE,
                  selectedPFIServiceType === SERVICE_TYPE_LABEL_BY_KEY.PLEDGE
                    ? loanDetails?.loanAmountPayable
                    : purchaseLoanDetails?.reclaimAmount
                )}
              </tbody>
            </table>
          </Col>
          <StyledBasketCol lg={9} xl={13} xxl={10}>
            <Row>
              <Col span={24}>
                <StyledItemValue>{`${PLEDGE_LOAN.ITEM_COUNT}: ${
                  itemCount ? itemCount : 0
                }`}</StyledItemValue>
              </Col>
            </Row>
            {selectedPFIServiceType === SERVICE_TYPE_LABEL_BY_KEY.PLEDGE && (
              <Row>
                <Col>
                  <StyledSelect>
                    <Select
                      className="input-control"
                      placeholder="Select"
                      disabled={true}
                      value={`${loanDetails.interestRate}`}
                    >
                      <Select.Option value={`${loanDetails.interestRate}`}>
                        {loanDetails.interestRate}
                      </Select.Option>
                    </Select>
                  </StyledSelect>
                </Col>
                <StyledMonthlyRate>
                  {PLEDGE_LOAN.MONTHLY_RATE}
                </StyledMonthlyRate>
              </Row>
            )}
            {selectedPFIServiceType === SERVICE_TYPE_LABEL_BY_KEY.PLEDGE && (
              <StyledItemValue>{`${loanDetails.apr}% APR`}</StyledItemValue>
            )}
          </StyledBasketCol>
        </Row>
      </Col>
    </Row>
  );
};

export default PledgeLoanContainer;
