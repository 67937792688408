import React, { useState, useEffect } from 'react';
import { Col, Modal, Row, Input, Form, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { format } from 'date-fns';
import { FNS_DATE_FORMAT } from '../../globalConstants';
import { currencyFormat } from 'utils/util';
import { Button } from 'components/common/Button';
import { WAIVE_INTEREST } from './constants';
import { actions } from 'redux/reducers/pickupRenew';
import { CALCULATE_PLEDGE_AGREEMENT_LOAN_AMOUNT } from 'action_creators/pickupRenew';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';

const StyledInput = styled(Input)`
  height: 4.2rem;
  border-radius: 3px;
`;

interface WaiveInterestPopupProps {
  visible: boolean;
  setWaiveInterestPopupVisible: () => void;
}

const WaiveInterestPopup = ({
  visible,
  setWaiveInterestPopupVisible,
}: WaiveInterestPopupProps) => {
  const {
    pickupRenew: { pledgeAgreement, selectedAgreementRowId },
  } = useSelector((state: any) => {
    return {
      pickupRenew: state.pickupRenew,
    };
  });
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [agreement, setPledgeAgreement] = useState<any>({});
  const [waivedInterestPayload, setWaivedInterestPayload] = useState<any>({});

  const onCancelClick = () => {
    dispatch(actions.setSelectedAgreementRow([]));
    setWaiveInterestPopupVisible();
  };

  const onConfirmClick = () => {
    try {
      const updatedWaivedInterest = {
        ...agreement,
        waivedOffAmount: waivedInterestPayload?.waivedOffAmount,
        waiveInterest: waivedInterestPayload,
      };
      const params = {
        agreement: updatedWaivedInterest,
      };
      if (updatedWaivedInterest?.selectedActionType) {
        dispatch({
          type: CALCULATE_PLEDGE_AGREEMENT_LOAN_AMOUNT,
          payload: { params },
        });
      } else {
        dispatch(actions.updatePledgeAgreement(updatedWaivedInterest));
      }
      dispatch(actions.setSelectedAgreementRow([]));
      setWaiveInterestPopupVisible();
    } catch (errorInfo) {
      console.log(errorInfo);
    }
  };

  const onSelectPeriodChange = (e: any) => {
    const amountWaived = waivedInterestPayload?.dailyInterest * e || 0;
    const updatedPayload = {
      ...waivedInterestPayload,
      waivedOffDays: e + waivedInterestPayload?.waivedOffVulnerableDays,
      waivedOffAmount:
        Number(amountWaived) +
        Number(waivedInterestPayload?.waivedOffVulnerableAmount),
      waivedOffNonVulnerableDays: e,
      waivedOffNonVulnerableAmount: amountWaived,
    };
    setWaivedInterestPayload(updatedPayload);
    form.setFieldsValue({
      waivedOffDays: e,
    });
  };

  const onReasonNotesChange = (e: any) => {
    const updatedPayload = {
      ...waivedInterestPayload,
      reasonNotes: e?.target?.value,
    };
    setWaivedInterestPayload(updatedPayload);
    form.setFieldsValue({
      reasonNotes: e?.target?.value,
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); //Need this console for checking error
  };

  useEffect(() => {
    const index = pledgeAgreement.findIndex(
      (x: any) => x.id === selectedAgreementRowId[0]
    );
    setPledgeAgreement(pledgeAgreement[index]);
    setWaivedInterestPayload(pledgeAgreement[index]?.waiveInterest);
    form.setFieldsValue({
      waivedOffDays:
        pledgeAgreement[
          index
        ]?.waiveInterest?.waivedOffNonVulnerableDays.toString(),
      reasonNotes: pledgeAgreement[index]?.waiveInterest?.reasonNotes,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Modal
        title={
          <HelpPopoverInfo
            linkedID={`PICKUPRENEW_WAIVEINTEREST`}
            position="leftTop"
          >
            <span>Waive Interest/Fee</span>
          </HelpPopoverInfo>
        }
        open={visible}
        width={1100}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        footer={[
          <Row gutter={24} justify="end" key="waiveIntPopup">
            <Col>
              <Button
                itemProp="secondary"
                key="cancelWaive"
                onClick={onCancelClick}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                form="waiveInterestForm"
                key="confirmWaive"
                htmlType="submit"
                disabled={!agreement?.isValidForProcess}
              >
                Confirm
              </Button>
            </Col>
          </Row>,
        ]}
        centered
      >
        <Row>
          <Col span={24}>
            <Form
              id="waiveInterestForm"
              onFinish={onConfirmClick}
              onFinishFailed={onFinishFailed}
              form={form}
              layout="vertical"
              initialValues={{
                waivedOffDays: 0,
              }}
            >
              <Col span={24} className="waive-interest-wrapper">
                <table className="waive-interest-table">
                  <thead>
                    <tr>
                      <th>{WAIVE_INTEREST.AGREEMENT_NO}</th>
                      <th>{WAIVE_INTEREST.START_DATE}</th>
                      <th>{WAIVE_INTEREST.DUE_DATE}</th>
                      <th>{WAIVE_INTEREST.NO_OF_DAYS}</th>
                      <th style={{ textAlign: 'right' }}>
                        {WAIVE_INTEREST.DAILY}
                      </th>
                      <th style={{ textAlign: 'right' }}>
                        {WAIVE_INTEREST.DUE_TODAY}
                      </th>
                      <th>{WAIVE_INTEREST.SELECT_PERIOD}</th>
                      <th>{WAIVE_INTEREST.AMOUNT_WAIVED}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{waivedInterestPayload?.loanNumber}</td>
                      <td>
                        {waivedInterestPayload?.startDate
                          ? format(
                              new Date(waivedInterestPayload?.startDate),
                              FNS_DATE_FORMAT
                            )
                          : ''}
                      </td>
                      <td>
                        {waivedInterestPayload?.dueDate
                          ? format(
                              new Date(waivedInterestPayload?.dueDate),
                              FNS_DATE_FORMAT
                            )
                          : ''}
                      </td>
                      <td>{waivedInterestPayload?.totalDays}</td>
                      <td style={{ textAlign: 'right' }}>
                        {currencyFormat(
                          waivedInterestPayload?.dailyInterest,
                          true
                        )}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {currencyFormat(waivedInterestPayload?.dueToday, true)}
                      </td>
                      <td className="pledge-days-waived">
                        <Form.Item
                          label={WAIVE_INTEREST.SELECT_PERIOD}
                          name="waivedOffDays"
                          className="label-control"
                        >
                          <InputNumber
                            disabled={!agreement?.isValidForProcess}
                            min={0}
                            max={
                              waivedInterestPayload?.totalDays -
                              waivedInterestPayload?.waivedOffVulnerableDays
                            }
                            defaultValue={0}
                            step={1}
                            onChange={(e: any) => onSelectPeriodChange(e)}
                            autoFocus
                            className="days-waived-input"
                          />
                        </Form.Item>
                      </td>
                      <td className="pledge-amount-waived">
                        <Input
                          type="text"
                          value={currencyFormat(
                            waivedInterestPayload?.waivedOffNonVulnerableAmount
                          )}
                          disabled
                          className="amount-waived-input"
                          addonBefore={'£'}
                          placeholder="0.00"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
              {agreement?.waiveInterest?.waivedOffVulnerableAmount > 0 && (
                <Row className="vulnerable-days-waived">
                  <Col span={16}>
                    <Row className="font-weight-700">
                      {WAIVE_INTEREST.VULNERABLE_DAYS.TITLE}
                    </Row>
                    <Row>
                      {WAIVE_INTEREST.VULNERABLE_DAYS.DAYS_WAIVED}{' '}
                      <span className="font-weight-700">
                        &nbsp;
                        {`${agreement?.waiveInterest?.waivedOffVulnerableDays} days`}
                        &nbsp;&nbsp;{`|`}&nbsp;&nbsp;
                      </span>
                      {WAIVE_INTEREST.VULNERABLE_DAYS.AMOUNT}&nbsp;
                      <span className="font-weight-700">
                        {currencyFormat(
                          agreement?.waiveInterest?.waivedOffVulnerableAmount,
                          true
                        )}
                      </span>
                    </Row>
                  </Col>
                </Row>
              )}
              <Col span={24}>
                <Row>
                  <Col span={14}>
                    <Form.Item
                      label={WAIVE_INTEREST.REASON_FOR_WAIVING}
                      name="reasonNotes"
                      className="label-control"
                      rules={[
                        {
                          required: true,
                          message:
                            WAIVE_INTEREST.VALIDATION_REASON_WAIVED.REQUIRED,
                        },
                        {
                          max: 50,
                          message: WAIVE_INTEREST.VALIDATION_REASON_WAIVED.MAX,
                        },
                        {
                          min: 10,
                          message: WAIVE_INTEREST.VALIDATION_REASON_WAIVED.MIN,
                        },
                      ]}
                    >
                      <StyledInput
                        disabled={!agreement?.isValidForProcess}
                        onChange={onReasonNotesChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default WaiveInterestPopup;
