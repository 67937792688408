import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { Layout, Row, Col } from 'antd';
import { GET_PFI_UNDER_SEVENTYFIVE } from 'action_creators/pledgeManagement';
import { PFI_TITLE } from 'components/pledgemanagement/constants';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import FooterButtons from 'components/common/footerButtons';
import PfiSummary from './index';

const StyledContentLayout = styled(Layout)`
  background: var(--white);
  padding: 15px 20px;
  border-radius: 5px;
`;

const StyledCol = styled(Col)`
  height: calc(100vh - 295px);
`;

const PfiUnder = () => {
  const {
    pledgeManagement: { pfiUnderSeventyFiveList },
  } = useSelector((state: any) => {
    return {
      pledgeManagement: state.pledgeManagement,
    };
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: GET_PFI_UNDER_SEVENTYFIVE,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onExitClick = () => {
    navigate('/');
  };

  return (
    <>
      <StyledContentLayout className="pfi-management-wrapper">
        <Row className="title-row">
          <Col span={24}>
            <HelpPopoverInfo
              linkedID={''}
              position="rightTop"
              helpPosition="AFTER"
            >
              {PFI_TITLE}
            </HelpPopoverInfo>
          </Col>
        </Row>
        <Row>
          <StyledCol span={24} className="pfi-grid">
            <PfiSummary pfiUnderSeventyFiveList={pfiUnderSeventyFiveList} />
          </StyledCol>
        </Row>
      </StyledContentLayout>
      <FooterButtons
        enableExtraClass
        isExitOnly={true}
        enableExitPopup={false}
        onConfirmExitClick={onExitClick}
      />
    </>
  );
};

export default PfiUnder;
