import React, { useState } from 'react';
import VirtualGrid from 'components/common/virtualGrid/index';
import { format } from 'date-fns';
import { getPurchaseStatus } from 'utils/util';
import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';

import IconDescription from './iconDescription';
import ImageLoader from 'components/common/ImageLoader';

import styled from 'styled-components';

const StyledGridWrapper = styled.div`
  height: calc(65vh - 30px);
`;

const PurchaseSummary = ({ purchaseSummary }: any) => {
  const [columns] = useState([
    {
      name: 'purchaseStatus',
      title: 'Status',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) =>
        getPurchaseStatus(row?.isQuarantine, row?.isReturned),
    },
    {
      name: 'transactionDate',
      title: 'Date',
      getCellValue: (row: any) =>
        row && row?.transactionDate
          ? format(new Date(row?.transactionDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'purchaseId',
      title: 'Receipt No.',
      getCellValue: (row: any) => row?.purchaseId || '', // purchaseId
    },
    {
      name: 'purchaseValue',
      title: 'Value',
      type: 'currency',
      getCellValue: (row: any) => row?.purchaseValue,
    },
    {
      name: 'location',
      title: 'Location',
      getCellValue: (row: any) => row?.location,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'purchaseStatus', width: '18%' },
    { columnName: 'transactionDate', width: '18%' },
    { columnName: 'purchaseId', width: '18%' },
    { columnName: 'purchaseValue', width: '17%' },
    { columnName: 'location', width: '23%' },
  ]);

  const [formattedColumns] = useState(['purchaseStatus', 'purchaseValue']);
  const getRowId = (row: any) => row.id;
  return (
    <>
      <StyledGridWrapper>
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={purchaseSummary}
          formattedColumns={formattedColumns}
          expandableComponent={PurchaseExpandableTable}
          getRowId={getRowId}
        />
      </StyledGridWrapper>
      <IconDescription purchaseSummary={purchaseSummary} />
    </>
  );
};

export const PurchaseExpandableTable = ({ row }: any) => {
  const [columns] = useState([
    {
      name: 'itemImage',
      title: 'Item Image',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={row?.id ? `/api/items/image/${row?.id}` : ``}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'barcode',
      title: 'Barcode',
      getCellValue: (row: any) => row.barcode,
    },
    {
      name: 'description',
      title: 'Description',
      getCellValue: (row: any) => row.description,
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) => `${row?.weight?.toFixed(2)}g`,
    },
    {
      name: 'amount',
      title: 'Amount',
      type: 'currency',
      getCellValue: (row: any) => row?.amount,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'itemImage', width: 150 },
    { columnName: 'barcode', width: 150 },
    { columnName: 'description', width: 300 },
    { columnName: 'weight', width: 130, align: 'right' },
    { columnName: 'amount', width: 130, align: 'right' },
  ]);

  const [formattedColumns] = useState(['amount']);

  return (
    <>
      <div className="expandable-table-rows">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          formattedColumns={formattedColumns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={row?.purchaseItems}
        />
      </div>
    </>
  );
};

export default PurchaseSummary;
