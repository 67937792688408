import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Modal, Row, Switch, notification } from 'antd';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import { actions as consumerDutyActions } from 'redux/reducers/consumerDuty';

import { Button } from 'components/common/Button';
import TextArea from 'components/common/TextArea';

import {
  BUTTONS_LABEL,
  HEADINGS,
  SST,
} from 'components/consumerDuty/constants';
import { TASK_OWNER_VALUE } from 'components/consumerDuty/constants';
import ControlledAlert from 'components/common/ControlledAlert';
import { PROFANITY_CHECK } from 'globalConstants';

const StyledTitle = styled.span`
  font-weight: var(--font-weight-600);
`;

interface ManageServicesPopupProps {
  open: boolean;
  pledgeSummary: Array<any>;
  setManageServicesPopupVisible: () => void;
}

interface ProfanityError {
  profanity: boolean;
  badWords: Array<string>;
}

const ManageServicesPopup = ({
  open,
  pledgeSummary,
  setManageServicesPopupVisible,
}: ManageServicesPopupProps) => {
  const { selectedLoanIds, loanPrivileges, selectedTaskOwner } = useSelector(
    (state: any) => state.consumerDuty
  );

  const dispatch = useDispatch();

  const [notes, setNotes] = useState<string>('');
  const [loanIds, setLoanIds] = useState<Array<number>>([]);
  const [agreementNos, setAgreementNos] = useState<Array<number>>([]);

  const [isRenewalAllowed, setIsRenewalAllowed] = useState<boolean>(false);
  const [isPaydownAllowed, setIsPaydownAllowed] = useState<boolean>(false);
  const [isPartialPayAllowed, setPartialPayAllowed] = useState<boolean>(false);
  const [isAuctionAllowed, setIsAuctionAllowed] = useState<boolean>(false);

  const [profanityError, setProfanityError] = useState<ProfanityError>({
    profanity: false,
    badWords: [''],
  });
  const [isProfanity, setProfanity] = useState<boolean>(false);

  const setManagedServices = () => {
    let selectedAgreements: Array<any> = [];
    let loanIds = [...selectedLoanIds];

    if (selectedLoanIds.length > 0) {
      selectedLoanIds.forEach((id: number) => {
        const agreement = pledgeSummary.find(
          (pledge: any) => pledge.loanId === id
        );
        selectedAgreements.push(agreement.agreementNumber);
      });
    } else
      pledgeSummary.forEach((pledge: any) => {
        selectedAgreements.push(pledge.agreementNumber);
        loanIds.push(pledge.loanId);
      });

    setLoanIds(loanIds);
    setAgreementNos(selectedAgreements);
  };

  useEffect(() => {
    setManagedServices();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onCancelClick = () => {
    setManageServicesPopupVisible();
  };

  const onNoteChange = (event: any) => {
    var { value } = event?.target;
    setNotes(value);
  };

  const handleProfanity = (profanity: boolean, badWords: Array<string>) => {
    setProfanityError({ profanity, badWords });
  };

  const validatePreviousPrivileges = (privileges: any) => {
    var privilegesCopy = cloneDeep(privileges);

    privileges.forEach((privilege: any, index: any) => {
      const loans = privilege.loans.filter(
        (loan: any) => !loanIds.includes(loan.loanId)
      );

      privilegesCopy[index].loans = loans;
    });

    //look if any privilege object now have loans as empty array [], remove those privilege object
    privilegesCopy = privilegesCopy.filter(
      (privilege: any) => privilege.loans.length !== 0
    );

    return privilegesCopy;
  };

  const onConfirmClick = () => {
    if (profanityError.profanity) {
      setProfanity(true);
    } else {
      if (!notes)
        return notification.error({
          message: SST.PLEDGE_PERMISSION.NOTES,
          duration: 5,
        });

      const filteredPrivileges = validatePreviousPrivileges(loanPrivileges);
      let selectedLoans: Array<any> = [];
      const customerLoans = cloneDeep(pledgeSummary);

      loanIds.forEach((id: number) => {
        let agreementIndex = pledgeSummary.findIndex(
          (pledge: any) => pledge.loanId === id
        );

        selectedLoans.push({
          loanId: id,
          loanNumber: pledgeSummary[agreementIndex].agreementNumber,
        });

        customerLoans[agreementIndex].pledgeServicePermissions = {
          renewal: isRenewalAllowed,
          paydown: isPaydownAllowed,
          partialPayment: isPartialPayAllowed,
          pfiAuction: isAuctionAllowed,
        };
      });

      const allowedServicesOnLoan = {
        renewal: isRenewalAllowed,
        paydown: isPaydownAllowed,
        partialPayment: isPartialPayAllowed,
        pfiAuction: isAuctionAllowed,
        loans: [...selectedLoans],
        manageServiceNote: notes,
      };

      dispatch(
        consumerDutyActions.setLoanPrivileges([
          ...filteredPrivileges,
          allowedServicesOnLoan,
        ])
      );
      dispatch(consumerDutyActions.setSelectedLoanIds([]));
      dispatch(consumerDutyActions.setLockedPledgeDetails(customerLoans));

      setManageServicesPopupVisible();
    }
  };

  const TableRow = (
    label: string,
    value: boolean,
    onSwitchChange: () => void
  ) => {
    return (
      <tr>
        <td>{label}</td>
        <td>
          <span
            style={{
              padding: '10px 13px 10px 0px',
              color: value ? 'var(--black)' : 'var(--gray)',
            }}
          >
            Enable
          </span>
          <Switch checked={!value} onChange={onSwitchChange} />
          <span
            style={{
              padding: '10px 0px 10px 13px',
              color: !value ? 'var(--black)' : 'var(--gray)',
            }}
          >
            Disable
          </span>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Modal
        title={BUTTONS_LABEL.MANAGE_SERVICES}
        open={open}
        width={700}
        centered
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        footer={[
          <Row gutter={24} justify="space-between" key="manageService">
            <Col>
              <Button
                itemProp="secondary"
                key="manageServiceCancel"
                onClick={onCancelClick}
              >
                {BUTTONS_LABEL.CANCEL}
              </Button>
            </Col>
            <Col>
              <Button key="manageServiceConfirm" onClick={onConfirmClick}>
                {BUTTONS_LABEL.CONFIRM}
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Row>
          <Col span={24}>
            <StyledTitle>
              {HEADINGS.SERVICES_ALLOWED}{' '}
              {selectedLoanIds.length > 0
                ? agreementNos.join(', ')
                : 'All agreements'}
            </StyledTitle>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <table className="service-details-table">
              <tbody>
                {TableRow(HEADINGS.RENEWAL, isRenewalAllowed, () =>
                  setIsRenewalAllowed(!isRenewalAllowed)
                )}
                {TableRow(HEADINGS.PAYDOWN, isPaydownAllowed, () =>
                  setIsPaydownAllowed(!isPaydownAllowed)
                )}
                {TableRow(HEADINGS.PARTIAL_PAY, isPartialPayAllowed, () =>
                  setPartialPayAllowed(!isPartialPayAllowed)
                )}
                {selectedTaskOwner !== TASK_OWNER_VALUE.AT_RISK && (
                  <>
                    {TableRow(HEADINGS.PFI, isAuctionAllowed, () =>
                      setIsAuctionAllowed(!isAuctionAllowed)
                    )}
                  </>
                )}
              </tbody>
            </table>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <StyledTitle>Notes*</StyledTitle>
          </Col>
          <Col span={24}>
            <TextArea
              rows={6}
              value={notes}
              maxLength={600} //to be changed as per backend
              onChange={onNoteChange}
              profanityExists={handleProfanity}
              autoComplete="on"
              spellCheck
              profanityCheck
            />
          </Col>
        </Row>
        {isProfanity && (
          <ControlledAlert
            id="caseUpdate"
            width={600}
            visible={isProfanity}
            message={PROFANITY_CHECK.REMOVE_PROFANITY}
            secondaryMessage={
              <>
                {PROFANITY_CHECK.PROFANITY_WORDS}
                <b>&nbsp;{profanityError.badWords.join(', ')}</b>
              </>
            }
            onClick={() => {
              setProfanity(false);
            }}
            yesButtonText={BUTTONS_LABEL.REMOVE_PROFANITY}
            isCancelBtn={false}
          />
        )}
      </Modal>
    </>
  );
};

export default ManageServicesPopup;
