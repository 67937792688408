import React, { useState } from 'react';
import VirtualGrid from '../../../common/virtualGrid/index';
import { COLORS_CODE_TABLE, GRID_NO_URL } from 'globalConstants';
import ProgressTick from 'components/common/ProgressCheck';
import TableRow from '../../formatedCell';

const CheckoutWU = (props: any) => {
  const rows = props?.item || [];
  const [columns] = useState([
    {
      name: '',
      title: '',
      getCellValue: (row: any) => '',
    },
    {
      name: 'serviceTransactionId',
      title: 'Processed',
      getCellValue: (row: any) =>
        ProgressTick('Processed', row?.serviceStatusText),
    },
    {
      name: 'serviceName',
      title: 'Service',
      getCellValue: (row: any) =>
        row?.westernUnion?.request?.isRefund
          ? 'Refunded to Customer'
          : row?.westernUnion?.request?.receivedAmount
          ? 'Received from Customer'
          : 'Paid to Customer',
    },
    {
      name: 'mtcnRefNo',
      title: 'MTCN',
      getCellValue: (row: any) => row?.westernUnion?.request?.mtcnRefNo,
    },
    {
      name: 'totalAmount',
      title: 'Amount',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.westernUnion?.request?.isRefund
          ? row?.westernUnion?.request?.paidAmount
          : row?.westernUnion?.request?.receivedAmount,
    },
    {
      //insert a blank column to create space before To Pay
      name: 'blankColumn',
      title: ' ',
      getCellValue: () => '',
    },
    {
      name: 'toPay',
      title: 'To Pay',
      type: 'currency',
      getCellValue: (row: any) => row?.westernUnion?.request?.paidAmount,
    },
    {
      name: 'toTake',
      title: 'To Take',
      type: 'currency',
      getCellValue: (row: any) => row?.westernUnion?.request?.receivedAmount,
    },
    {
      name: '',
      title: '',
      getCellValue: (row: any) => '',
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: '', width: '48px' },
    { columnName: 'serviceTransactionId', width: '17%' },
    { columnName: 'serviceName', width: '18%' },
    { columnName: 'mtcnRefNo', width: '17%' },
    { columnName: 'totalAmount', width: '12%', align: 'right' },
    { columnName: 'blankColumn', width: '6%', align: 'right' },
    { columnName: 'toTake', width: '10%', align: 'right' },
    { columnName: 'toPay', width: '10%', align: 'right' },
    { columnName: '', width: '6%', align: 'right' },
  ]);

  const [formattedColumns] = useState(['totalAmount', 'toPay', 'toTake']);

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        formattedColumns={formattedColumns}
        rowData={rows}
        tableColumnExtensionsProps={tableColumnExtensions}
        headerBackground={COLORS_CODE_TABLE.WESTERN_UNION}
        cellComponent={TableRow}
      />
    </>
  );
};

export default CheckoutWU;
