import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';

import {
  removeMatrixToken,
  setMatrixToken,
  setMatrixUserId,
  setMatrixStoreId,
  setRefreshToken,
  getRefreshToken,
  getMatrixToken,
  removeAllCookies,
  setMatrixPaymentAccessToken,
  setPaymentRefreshToken,
} from 'utils/cookie';

import axios from 'axios';
import Login from 'components/login';
import MultiStore from 'components/login/MultiStore';

import { useDispatch } from 'react-redux';
import { actions } from 'redux/reducers/user';
import { notification, Spin } from 'antd';
import { encryptData } from 'utils/encrypt';
import { ROUTE_CONFIG } from 'globalConstants';

export const AuthContext = createContext<Auth>({
  isAuthenticated: false,
  authenticationError: false,
});

export const useAuth = () => useContext<Auth>(AuthContext);

export interface Auth {
  isAuthenticated: boolean;
  authenticationError: boolean;
  authenticationErrorMessage?: string;
  userFullName?: string;
  userProfilePhoto?: string;
  signOut?: () => void;
  getMatrixAccessToken?: () => Promise<AuthenticationResult | undefined>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userName, setUserName] = useState<string | undefined>('');
  const [currentUserResponse, setCurrentUserResponse] = useState<any>({});
  const [isMultipleStore, setIsMultipleStore] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    //not showing refresh token error on APP LOAD
    if (getRefreshToken() && getMatrixToken()) {
      setIsAuthenticated(true);
      refreshTokenHandler();
    }
    // eslint-disable-next-line
  }, []);

  const getUserAndStore = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/api/users/currentuser`,
        {
          headers: {
            Authorization: `Bearer ${getMatrixToken()}`,
          },
        }
      );

      if (response?.data) {
        if (response?.data?.stores?.length > 0) {
          if (!response?.data?.userId || !response?.data?.stores[0]) {
            notification.error({
              message: 'User/Store details not found.',
              duration: 5,
            });
          }

          setCurrentUserResponse(response?.data);
          setMatrixUserId(response?.data?.userId);
          setUserName(response?.data?.displayName);
          dispatch(actions.setMenuConfigration(response?.data?.menus));

          if (response?.data?.stores?.length > 1) {
            setIsMultipleStore(true);
            dispatch(actions.setUserAndStore(response?.data));
          } else {
            setMatrixStoreId(response?.data?.stores[0]);
            dispatch(
              actions.setUserAndStore({
                ...response?.data,
                selectedStore: response?.data?.stores[0],
              })
            );
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
          signOut();
        }
      }
    } catch (e: any) {
      // For any non-successful status code, do not take any action
      setMatrixUserId('');
      setMatrixStoreId('');
      setIsLoading(false);
    }
  };

  const signOut = () => {
    if (window?.location?.pathname === `/${ROUTE_CONFIG.CONSUMER_DUTY}`) {
      window.location.replace('/');
    }

    //clearing all the sessions
    dispatch({ type: 'SIGN_OUT' });

    setIsAuthenticated(false);
    removeAllCookies();
  };

  //need to be removed
  const getMatrixAccessToken = async (
    accountParam?: AccountInfo
  ): Promise<AuthenticationResult | undefined> => {
    return;
  };

  //HandT User login methods from here
  const loginHandler = async (payload: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/api/users/login`,
        payload
      );

      if (response.status === 200) authenticationHandler(response.data);
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error,
        duration: 3,
      });
      setIsLoading(false);
    }
  };

  const refreshTokenHandler = async () => {
    const payload = {
      refreshToken: getRefreshToken(),
    };
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/api/users/refresh-token`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${getMatrixToken()}`,
          },
        }
      );

      if (response.status === 200) authenticationHandler(response.data);
    } catch (error: any) {
      setIsLoading(false);
      setIsAuthenticated(false);
    }
  };

  const authenticationHandler = async (params: any) => {
    const accessToken = params.access_token;
    const refreshToken = params.refresh_token;
    const paymentRefreshToken = params.payment_refresh_token;
    const paymentAccessToken = params.payment_access_token;

    setAccessToken(accessToken, paymentAccessToken);
    setRefreshToken(refreshToken);
    setPaymentRefreshToken(paymentRefreshToken);
    setIsAuthenticated(true);
    await getUserAndStore();
  };

  const setAccessToken = (
    accessToken: string,
    paymentAccessToken: string = ''
  ) => {
    removeMatrixToken();
    setMatrixToken(accessToken);
    setMatrixPaymentAccessToken(paymentAccessToken);
  };

  const onLoginClick = (params: any) => {
    const payload = {
      userName: params.userName,
      password: encryptData(params.password),
    };

    setIsLoading(true);
    loginHandler(payload);
  };

  const storeHandler = (storeDetails: any) => {
    dispatch(
      actions.setUserAndStore({
        ...currentUserResponse,
        selectedStore: storeDetails,
      })
    );

    setMatrixStoreId(storeDetails);
    setIsMultipleStore(false);
  };

  if (!isAuthenticated)
    return <Login loginHandler={onLoginClick} isLoading={isLoading} />;

  if (isAuthenticated && isLoading)
    return (
      <div style={{ margin: '50px 0', textAlign: 'center' }}>
        <Spin />
      </div>
    );

  if (isMultipleStore)
    return (
      <MultiStore
        userName={userName}
        stores={currentUserResponse?.stores}
        storeHandler={storeHandler}
      />
    );

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        authenticationError: false,
        authenticationErrorMessage: '',
        userFullName: userName,
        userProfilePhoto: '',
        signOut: signOut,
        getMatrixAccessToken: getMatrixAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
