import * as urls from '../apiUrls';
import { doRequest } from 'utils/request';

const postWorkQueueItemsList = (params) => {
  return doRequest(`${urls.WORKQUEUE_ITEMS_LIST}`, 'post', params);
};
const postNotificationsValuationsList = (params) => {
  return doRequest(`${urls.NOTIFICATIONS_VALUATIONS_LIST}`, 'post', params);
};

const postWorkQueueNotification = (params) => {
  return doRequest(`${urls.UPDATE_WORKQUEUE_NOTIFICATION}`, 'post', params);
};

const postViewValuationItem = (params) => {
  return doRequest(`${urls.VIEW_VALUATION_ITEM}`, 'post', params);
};
const postNotificationItemDetails = (loanNumber) => {
  return doRequest(
    `${urls.GET_NOTIFICATION_ITEM_DETAILS}/${loanNumber}`,
    'post'
  );
};

const postValuationItemAction = (params) => {
  return doRequest(`${urls.POST_VALUATION_ITEM_ACTION}`, 'post', params);
};

export {
  postWorkQueueItemsList,
  postWorkQueueNotification,
  postViewValuationItem,
  postValuationItemAction,
  postNotificationsValuationsList,
  postNotificationItemDetails,
};
