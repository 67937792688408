import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { toString } from 'lodash';
import { Row, Col, Button } from 'antd';
import { useDispatch } from 'react-redux';

import * as customerActions from 'action_creators/customer';
import { DOCUMENT_TYPE_OTHER } from 'components/customer/constants';
import './webcam.less';

const StyledImage = styled.div`
  img {
    border-radius: 8px;
    width: ${(props) => (props.width ? `${props.width}px` : '200px')};
    height: ${(props) => (props.height ? `${props.height}px` : '200px')};
  }
`;

//TODO will use in future code
// const StyledCam = styled(CameraFilled)`
//   font-size: 24px;
//   margin-top: -25px;
//   position: relative;
//   border-radius: 100%;
//   height: 50px;
//   width: 50px;
//   padding: 12px;
//   width: 47.37px;
//   height: 47.37px;
//   background: #FFFFFF;
//   box-shadow: 0px 1px 4px rgb(0 0 0 / 20%);
// `;

const StyledFaceFrame = styled.img`
  position: absolute;
  left: 20px;
  top: 10px;
  z-index: 1;
`;

export const IMAGE_CATEGORY = {
  CUSTOMER_PHOTO: 'CUSTOMER_PHOTO',
  DOCUMENT: 'DOCUMENT',
  DOCUMENT_DRIVING_LICENCE: 'DOCUMENT_DRIVING_LICENCE',
  DOCUMENT_PASSPORT: 'DOCUMENT_PASSPORT',
  ITEM_PHOTO: 'ITEM_PHOTO',
  OTHER: 'OTHER',
  THIRD_PARTY_ASSISTANT_PHOTO: 'THIRD_PARTY_ASSISTANT_PHOTO',
};

const svgIcon = (docType) => (
  <svg
    width="100%"
    height="100%"
    className="svg"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      {docType === IMAGE_CATEGORY.CUSTOMER_PHOTO && (
        <mask id="overlay-mask" x="0" y="0" width="100%" height="100%">
          <rect x="0" y="0" width="100%" height="100%" fill="white" />
          <circle cx="50%" cy="50%" r="92" />
        </mask>
      )}
    </defs>
    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      mask="url(#overlay-mask)"
      fillOpacity="0.1"
    />
  </svg>
);

const WebCam = ({
  isMultiple = false,
  isRetake = false,
  width = 200,
  height = 200,
  type = '',
  getItemPhoto = (data) => {},
  photoId = 0,
}) => {
  const dispatch = useDispatch();
  const [isTakePhoto, setIsTakePhoto] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);

  let myStream = null;
  const videoRef = useRef(null);
  const photoRef = useRef(null);
  const stripRef = useRef(null);

  useEffect(() => {
    getVideo();
    return () => {
      if (myStream !== null) {
        myStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [videoRef]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isRetake) {
      setIsTakePhoto(false);
      setIsRefresh(true);
    }
  }, [isRetake]); // eslint-disable-line react-hooks/exhaustive-deps

  const getVideo = async () => {
    await navigator.mediaDevices
      .getUserMedia({ video: { width, height } })
      .then((stream) => {
        myStream = stream;
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
        setIsVideoReady(true);
      })
      .catch((err) => {
        console.error('error:', err);
      });
  };

  const paintToCanvas = () => {
    let video = videoRef.current;
    let photo = photoRef.current;
    let ctx = photo.getContext('2d');

    photo.width = 1000;
    photo.height = 720;
    return setInterval(() => {
      ctx.drawImage(video, 0, 0, 1000, 720);
    }, 200);
  };

  const takePhoto = () => {
    let photo = photoRef.current;
    let strip = stripRef.current;
    const data = photo.toDataURL();
    //const data = photo.toDataURL("image/jpeg");
    // console.warn(strip);

    const link = document.createElement('a');
    link.href = data;
    link.setAttribute('download', 'myWebcam');
    link.innerHTML = `<img src='${data}' alt='thumbnail'/>`;
    if (strip.firstChild && !isMultiple) {
      strip.replaceChild(link, strip.childNodes[0]);
    } else {
      strip.insertBefore(link, strip.firstChild);
    }
    setIsTakePhoto(true);

    if (type === IMAGE_CATEGORY.CUSTOMER_PHOTO) {
      dispatch({
        type: customerActions.UPLOAD_CUSTOMER_IMAGE_REQUEST,
        payload: { image: data },
      });
    }
    if (type === IMAGE_CATEGORY.THIRD_PARTY_ASSISTANT_PHOTO) {
      dispatch({
        type: customerActions.UPLOAD_TPA_IMAGE_REQUEST,
        payload: { image: data, nomineeRowIndex: photoId },
      });
    }
    if (type === IMAGE_CATEGORY.DOCUMENT_DRIVING_LICENCE) {
      dispatch({
        type: customerActions.UPLOAD_CUSTOMER_DOCUMENT_REQUEST,
        payload: { image: data, ocr: IMAGE_CATEGORY.DOCUMENT_DRIVING_LICENCE },
      });
    } else if (type === IMAGE_CATEGORY.DOCUMENT_PASSPORT) {
      dispatch({
        type: customerActions.UPLOAD_CUSTOMER_DOCUMENT_REQUEST,
        payload: { image: data, ocr: IMAGE_CATEGORY.DOCUMENT_PASSPORT },
      });
    } else if (type === IMAGE_CATEGORY.DOCUMENT) {
      dispatch({
        type: customerActions.UPLOAD_CUSTOMER_DOCUMENT_REQUEST,
        payload: { image: data },
      });
    }

    if (
      type === IMAGE_CATEGORY.ITEM_PHOTO ||
      type === toString(DOCUMENT_TYPE_OTHER)
    ) {
      getItemPhoto(data);
    }
  };

  const reTakePhoto = () => {
    getVideo();
    setIsTakePhoto(false);
    setIsRefresh(true);
  };

  let isRefreshed = isRefresh ? 'none' : 'block';
  let isReCapture = isTakePhoto ? 'block' : isRefreshed;
  let isVideo = isTakePhoto ? 'none' : 'block';

  return (
    <>
      <Row align="top" justify="center" key={type}>
        <Col>
          <Row className="webcam-container">
            <video
              onCanPlay={() => paintToCanvas()}
              ref={videoRef}
              style={{
                borderRadius: '8px',
                width: width,
                height: height,
                objectFit: 'cover',
                display: isVideo,
              }}
            />
            <canvas ref={photoRef} style={{ display: 'none' }} width="200" />

            {!isTakePhoto && isVideoReady && !IMAGE_CATEGORY.DOCUMENT && (
              <StyledFaceFrame
                data-testid="dataimage"
                src={require(`../../assets/images/face-frame.svg`)}
              />
            )}
            <StyledImage
              data-testid="styleImage"
              width={width}
              height={height}
              style={{ display: isReCapture }}
              ref={stripRef}
            />
            <div className="overlay-container">{svgIcon(type)}</div>
          </Row>
          <Row align="top" justify="center">
            {/* <StyledCam onClick={() => takePhoto()} /> TODO will use in future code */}
            {!isTakePhoto && (
              <Button
                style={{ marginTop: '10px', fontWeight: 500 }}
                type="link"
                block
                onClick={() => takePhoto()}
              >
                Take Photo
              </Button>
            )}

            {isRetake && isTakePhoto && (
              <Button
                style={{ marginTop: '10px', fontWeight: 500 }}
                type="link"
                block
                onClick={reTakePhoto}
              >
                Change Photo
              </Button>
            )}
          </Row>
        </Col>
      </Row>
      {!type && (
        <Row>
          <StyledImage ref={stripRef} width={width} height={height} />
        </Row>
      )}
    </>
  );
};

export default WebCam;
