import VirtualGrid from 'components/common/virtualGrid';
import { GRID_NO_URL } from 'globalConstants';
import React, { useState } from 'react';
import styled from 'styled-components';
import { currencyFormat, dateDisplayFormat } from 'utils/util';

const StyledTitle = styled.div`
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-16);
`;

const StyledDiv = styled.div`
  padding: 20px 0;
`;

interface ItemDetailsPopupProps {
  row: any;
}

const RenewedPledgeHistoryTable = ({ row }: ItemDetailsPopupProps) => {
  // const pledgeRenewals = row?.pledgeRenewals;
  const [columns] = useState([
    {
      name: 'agreementNumber',
      title: 'Agreement No.',
      getCellValue: (row: any) => row?.agreementNumber,
    },
    {
      name: 'startDate',
      title: 'Start Date',
      getCellValue: (row: any) =>
        row && row?.startDate ? dateDisplayFormat(row?.startDate) : null,
    },
    {
      name: 'dueDate',
      title: 'Due Date',
      getCellValue: (row: any) =>
        row && row?.dueDate ? dateDisplayFormat(row?.dueDate) : null,
    },
    {
      name: 'closedDate',
      title: 'Closed Date',
      getCellValue: (row: any) =>
        row && row?.closedDate ? dateDisplayFormat(row?.closedDate) : null,
    },
    {
      name: 'location',
      title: 'Location',
      getCellValue: (row: any) => row?.location,
    },
    {
      name: 'loanAmount',
      title: 'Loan Amount',
      getCellValue: (row: any) => currencyFormat(row?.loanAmount, true),
    },
    {
      name: 'interest6Months',
      title: 'Interest @ 6 Months',
      getCellValue: (row: any) => currencyFormat(row?.interest6Months, true),
    },
    {
      name: 'days',
      title: 'Days',
      getCellValue: (row: any) => row?.days,
    },
    {
      name: 'interestPaidTillDate',
      title: 'Interest Paid',
      getCellValue: (row: any) =>
        currencyFormat(row?.interestPaidTillDate, true),
    },
    {
      name: 'totalInterestPaidTillDate',
      title: 'Total Interest',
      getCellValue: (row: any) =>
        currencyFormat(row?.totalInterestPaidTillDate, true),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'agreementNumber', width: '10%' },
    { columnName: 'startDate', width: '9%' },
    { columnName: 'dueDate', width: '9%' },
    { columnName: 'closedDate', width: '9%' },
    { columnName: 'location', width: '13%' },
    { columnName: 'loanAmount', width: '10%', align: 'right' },
    { columnName: 'interest6Months', width: '12%', align: 'right' },
    { columnName: 'days', width: '6%', align: 'right' },
    {
      columnName: 'interestPaidTillDate',
      width: '12%',
      align: 'right',
    },
    {
      columnName: 'totalInterestPaidTillDate',
      width: '10%',
      align: 'right',
    },
  ]);

  return (
    <StyledDiv>
      <StyledTitle>Renewed Pledge History</StyledTitle>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={row} //rows
      />
    </StyledDiv>
  );
};

export default RenewedPledgeHistoryTable;
