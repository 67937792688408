import React, { useState } from 'react';
import TableRow from '../../formatedCell';
import { COLORS_CODE_TABLE, GRID_NO_URL } from 'globalConstants';
import { currencyFormat } from 'utils/util';
import VirtualGrid from 'components/common/virtualGrid';

const CheckoutChequeCashing = (props: any) => {
  const rows = props?.item || [];

  const [columns] = useState([
    {
      name: '',
      title: '',
      getCellValue: (row: any) => '',
    },
    {
      name: 'chequeNumber',
      title: 'Cheque Number',
      getCellValue: (row: any) =>
        row?.thirdPartyCheque?.request?.item?.chequeNumber,
    },
    {
      name: 'chequeAmount',
      title: 'Cheque Amount (£)',
      getCellValue: (row: any) =>
        currencyFormat(row?.thirdPartyCheque?.request?.item?.amount),
    },
    {
      name: 'totalFee',
      title: 'Total Fee (£)',
      getCellValue: (row: any) =>
        currencyFormat(row?.thirdPartyCheque?.request?.item?.totalFee),
    },
    {
      name: 'retained',
      title: 'Retained (£)',
      getCellValue: (row: any) =>
        currencyFormat(row?.thirdPartyCheque?.request?.item?.retainedAmount),
    },
    {
      name: 'payout',
      title: 'Payout (£)',
      getCellValue: (row: any) =>
        currencyFormat(row?.thirdPartyCheque?.request?.item?.payout),
    },
    {
      //insert a blank column to create space before To Pay
      name: 'blankColumn',
      title: ' ',
      getCellValue: () => '',
    },
    {
      name: 'toPay',
      title: 'To Pay',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.thirdPartyCheque?.request?.item?.payout || 0,
    },
    {
      name: 'toTake',
      title: 'To Take',
      type: 'currency',
      getCellValue: (row: any) => 0,
    },
    {
      name: '',
      title: '',
      getCellValue: (row: any) => '',
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: '', width: '48px' },
    { columnName: 'chequeNumber', width: '11%' },
    { columnName: 'chequeAmount', width: '11%', align: 'right' },
    { columnName: 'totalFee', width: '14%', align: 'right' },
    { columnName: 'retained', width: '13%', align: 'right' },
    { columnName: 'payout', width: '13%', align: 'right' },
    { columnName: 'blankColumn', width: '8%', align: 'right' },
    { columnName: 'toPay', width: '10%', align: 'right' },
    { columnName: 'toTake', width: '10%', align: 'right' },
    { columnName: '', width: '6%', align: 'right' },
  ]);

  const [formattedColumns] = useState(['toPay', 'toTake']);

  return (
    <div key="widgetsChequeCashing">
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        formattedColumns={formattedColumns}
        rowData={rows}
        tableColumnExtensionsProps={tableColumnExtensions}
        headerBackground={COLORS_CODE_TABLE.CHEQUE_CASHING}
        cellComponent={TableRow}
      />
    </div>
  );
};

export default CheckoutChequeCashing;
