import { createSlice } from '@reduxjs/toolkit';
import { PAYMENT_MODES } from 'globalConstants';

const initialState = {
  paymentSummary: [] as Array<any>,
  totalCalculatedAmount: 0,
  payment: {
    totalCashAmount: 0,
    totalCardAmount: 0,
    totalLayawayAmount: 0,
    totalBankTransferAmount: 0,
  },
  totalPayableAmount: 0,
  isPaymentSuccess: false,
  paymentconfirmationCode: '',
  paymentMethods: [] as Array<string>,
};
const slice = createSlice({
  name: 'paymentActions',
  initialState,
  reducers: {
    addAmounts(state, action) {
      state.paymentSummary = [...state.paymentSummary, action.payload];

      // Store Payment methods
      const paymentMethods = [...state.paymentMethods];
      paymentMethods.push(action.payload?.mode);
      state.paymentMethods = paymentMethods;
    },
    setTotalPayableAmount(state, action) {
      state.totalPayableAmount = action.payload;
    },
    totalAmounts(state, action) {
      return {
        ...state,
        totalCalculatedAmount:
          state.paymentSummary.length > 0
            ? state.paymentSummary.reduce((acc, item) => acc + item.amount, 0)
            : action.payload.amount,
        payment: {
          ...state.paymentSummary.reduce(
            (acc, item) => {
              if (item.mode === PAYMENT_MODES.CASH) {
                acc.totalCashAmount = acc.totalCashAmount + item.amount;
              }
              if (item.mode === PAYMENT_MODES.CARD) {
                acc.totalCardAmount = acc.totalCardAmount + item.amount;
              }
              if (item.mode === PAYMENT_MODES.LAYAWAY_FUNDS) {
                acc.totalLayawayAmount = acc.totalLayawayAmount + item.amount;
              }
              if (item.mode === PAYMENT_MODES.BANKTRANSFER) {
                acc.totalBankTransferAmount =
                  acc.totalBankTransferAmount + item.amount;
              }
              return acc;
            },
            {
              totalCashAmount: 0.0,
              totalCardAmount: 0.0,
              totalLayawayAmount: 0.0,
              totalBankTransferAmount: 0.0,
            }
          ),
        },
      };
    },
    removeAmounts(state, action) {
      const tempPaySummary = [...state.paymentSummary];
      const paymentSummary = tempPaySummary.filter(
        (item: any) => item.key !== action.payload.key
      );
      const removedItem = tempPaySummary.find(
        (item: any) => item.id === action.payload.id
      );

      // Store Payment methods
      const paymentMethods = [...state.paymentMethods];
      const firstOccuranceIndex = paymentMethods.indexOf(removedItem.mode);
      if (firstOccuranceIndex > -1) {
        paymentMethods.splice(firstOccuranceIndex, 1);
        state.paymentMethods = paymentMethods;
      }

      state.paymentSummary = paymentSummary;
      state.payment = {
        ...paymentSummary.reduce(
          (acc, item) => {
            if (item.mode === PAYMENT_MODES.CASH) {
              acc.totalCashAmount = acc.totalCashAmount + item.amount;
            }
            if (item.mode === PAYMENT_MODES.CARD) {
              acc.totalCardAmount = acc.totalCardAmount + item.amount;
            }
            if (item.mode === PAYMENT_MODES.LAYAWAY_FUNDS) {
              acc.totalLayawayAmount = acc.totalLayawayAmount + item.amount;
            }
            if (item.mode === PAYMENT_MODES.BANKTRANSFER) {
              acc.totalBankTransferAmount =
                acc.totalBankTransferAmount + item.amount;
            }
            return acc;
          },
          {
            totalCashAmount: 0.0,
            totalCardAmount: 0.0,
            totalLayawayAmount: 0.0,
            totalBankTransferAmount: 0.0,
          }
        ),
      };
      state.totalCalculatedAmount = paymentSummary.reduce(
        (acc, item) => acc + item.amount,
        0
      );
    },
    updatePaymentSuccess(state, action) {
      state.isPaymentSuccess = action.payload;
    },
    paymentConfirmationCode(state, action) {
      state.paymentconfirmationCode = action.payload;
    },
    clear(state, action) {
      return {
        ...initialState,
      };
    },
  },
});

export default slice.reducer;
export const { reducer, actions } = slice;
export { initialState };
