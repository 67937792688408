import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Modal, Checkbox, notification, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CUSTOMER_SAVE_REQUEST } from 'action_creators/customer';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import { Customer } from 'components/interface/Customer';
import { ROUTE_CONFIG } from 'globalConstants';

import {
  MARKETING_PREFERENCE_TEXT,
  SUCCESS_MESSAGE_ON_ADD_CUSTOMER,
} from '../../constants';
import { actions } from 'redux/reducers/customer';
import { MERGE_GUEST_BASKET_REQUEST } from 'action_creators/basket';

const StyledCol = styled(Col)`
  margin: 10px 0;
`;

interface MarketingPreferencesProps {
  customer: Customer | any;
  visible: boolean;
  setMarketingPopupVisible: () => void;
}

const preferencesObject = [
  {
    key: 'marketingPostAccepted',
    text: 'Mail',
    id: 1,
  },
  {
    key: 'marketingSmsAccepted',
    text: 'Text',
    id: 2,
  },
  {
    key: 'marketingOptOut',
    text: 'Opt-out of all Marketing',
    id: 5,
  },
  {
    key: 'marketingEmailAccepted',
    text: 'Email',
    id: 3,
  },
  {
    key: 'marketingPhoneAccepted',
    text: 'Phone',
    id: 4,
  },
];

const marketingPreferenceText = () => {
  return (
    <>
      <p>{MARKETING_PREFERENCE_TEXT.LINE_ONE}</p>
      <p>{MARKETING_PREFERENCE_TEXT.LINE_TWO}</p>
    </>
  );
};

const MarketingPreferencePopup = ({
  customer,
  visible,
  setMarketingPopupVisible,
}: MarketingPreferencesProps) => {
  const [preferences, setPreferences] = useState<any>({
    marketingPostAccepted: false,
    marketingSmsAccepted: false,
    marketingPhoneAccepted: null,
    marketingEmailAccepted: false,
    marketingOptOut: false,
  });
  const dispatch = useDispatch();
  const {
    customer: customerData,
    isProfileSaved,
    isImageUploading,
    isDocUploading,
    isDocUploaded,
    isSaving,
    isImageUploaded,
    error,
    isRedirectToOtherPage,
    isNewCustomerGenerateFromFinder,
  } = useSelector((state: any) => state.customer);

  const navigate = useNavigate();

  useEffect(() => {
    if (isProfileSaved) {
      if (isImageUploading || isDocUploading) {
        setMarketingPopupVisible();
      } else if (isImageUploaded || isDocUploaded) {
        openNotificationWithIcon();
        if (isNewCustomerGenerateFromFinder) {
          dispatch({
            type: MERGE_GUEST_BASKET_REQUEST,
          });
        }
        if (isRedirectToOtherPage) {
          navigate(
            `/${ROUTE_CONFIG.CUSTOMER}/${ROUTE_CONFIG.SUMMARY}/${customerData.customerId}`
          );
        }

        isImageUploaded && dispatch(actions.clearUploadImage());
        isDocUploaded && dispatch(actions.clearUploadDoc());
      }
    }
  }, [
    customerData,
    isProfileSaved,
    error,
    setMarketingPopupVisible,
    navigate,
    isImageUploading,
    isImageUploaded,
    isDocUploading,
    isDocUploaded,
    dispatch,
    isRedirectToOtherPage,
    isNewCustomerGenerateFromFinder,
  ]); // eslint-disable-next-line react-hooks/exhaustive-deps

  const onOkClick = () => {
    const params: Customer = {
      ...customer,
      ...preferences,
      telephone: customer.telephone || JSON.parse('null'),
    };
    dispatch({
      type: CUSTOMER_SAVE_REQUEST,
      payload: {
        data: params,
      },
    });
  };

  const onCancelClick = () => {
    setMarketingPopupVisible();
  };

  const handleOnChange = (checked: boolean, key: string) => {
    if (key === 'marketingOptOut') {
      setPreferences({
        marketingPostAccepted: false,
        marketingSmsAccepted: false,
        marketingPhoneAccepted: null,
        marketingEmailAccepted: false,
        [key]: checked,
      });
    } else {
      setPreferences({
        ...preferences,
        [key]: checked,
        marketingOptOut: false,
      });
    }
  };

  const openNotificationWithIcon = () => {
    notification.success({
      message: SUCCESS_MESSAGE_ON_ADD_CUSTOMER.message,
      description: SUCCESS_MESSAGE_ON_ADD_CUSTOMER.description,
    });
  };

  return (
    <Modal
      open={visible}
      width={620}
      title="Marketing Preferences"
      maskClosable={false}
      onCancel={onCancelClick}
      onOk={onOkClick}
      footer={[
        <Row key={0} gutter={24} justify="end">
          <Col>
            <Button itemProp="secondary" key="back" onClick={onCancelClick}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              key="submit"
              form="form"
              htmlType="submit"
              onClick={onOkClick}
              loading={isSaving || isImageUploading}
              disabled={
                !Object.keys(preferences).some(
                  (item) => preferences[item] === true
                )
              }
            >
              Continue
            </Button>
          </Col>
        </Row>,
      ]}
    >
      {marketingPreferenceText()}
      <Row>
        {preferencesObject.map((item, index) => {
          const { key, text } = item;
          return (
            <StyledCol span={8} key={index}>
              <Checkbox
                onChange={(e) => handleOnChange(e.target.checked, key)}
                checked={preferences[item.key]}
              >
                {text}
              </Checkbox>
            </StyledCol>
          );
        })}
      </Row>
    </Modal>
  );
};

export default MarketingPreferencePopup;
