import React, { useState } from 'react';
import { format } from 'date-fns';
import { Table } from '@devexpress/dx-react-grid-material-ui';

import { FNS_DATE_FORMAT_WITH_TIME, GRID_NO_URL } from 'globalConstants';

import ImageLoader from 'components/common/ImageLoader';
import VirtualGrid from 'components/common/virtualGrid/index';
import { Button } from 'components/common/Button';

interface WorkQueueSummaryProps {
  workQueue: Array<any>;
  onViewClick: (row: any) => object;
}

const TableRow = ({
  row,
  onClickTableRow,
  loadTransferDetails,
  ...restProps
}: any) => {
  const onRowClick = () => {
    onClickTableRow(row);
  };

  const Cell = () => {
    return <Table.Cell onClick={onRowClick} {...restProps} />;
  };

  return <>{Cell()}</>;
};

const WorkQueueSummary = ({
  workQueue = [],
  onViewClick,
}: WorkQueueSummaryProps) => {
  const [columns] = useState([
    {
      name: 'reference',
      title: 'Reference',
      getCellValue: (row: any) => row?.referenceNumber,
    },
    {
      name: 'store',
      title: 'Store',
      getCellValue: (row: any) => row?.storeName,
    },
    {
      name: 'customerName',
      title: 'Customer Name',
      getCellValue: (row: any) => row?.customerName,
    },
    {
      name: 'creationTimeStamp',
      title: 'Created Date & Time',
      getCellValue: (row: any) =>
        row && row?.createdOn
          ? format(new Date(row?.createdOn), FNS_DATE_FORMAT_WITH_TIME)
          : null,
    },
    {
      name: 'image',
      title: 'Image',
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row.defaultImage}`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'subject',
      title: 'Subject',
      getCellValue: (row: any) => row?.subject,
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row: any) => row?.workqueueServiceStatusName,
    },
    {
      name: 'actions',
      title: 'Actions',
      getCellValue: (row: any) => (
        <Button
          itemProp="secondary"
          // disabled={row?.action !== 'View'}
          onClick={() => onActionButtonClick(row)}
        >
          {row?.action}
        </Button>
      ),
    },
    {
      name: 'agent',
      title: 'Agent',
      getCellValue: (row: any) => row?.agentName,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'reference', width: '10%' },
    { columnName: 'store', width: '14%' },
    { columnName: 'customerName', width: '10%' },
    { columnName: 'creationTimeStamp', width: '14%', align: 'center' },
    { columnName: 'image', width: '6%' },
    { columnName: 'subject', width: '15%' },
    { columnName: 'status', width: '10%' },
    { columnName: 'actions', width: '8%' },
    { columnName: 'agent', width: '11%' },
  ]);

  const [formattedColumns] = useState(['delete']);

  const onActionButtonClick = (row: any) => {
    onViewClick(row);
  };

  const onClickTableRow = () => { /* do nothing */ };

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        // virtualScroll={true} if implemented need to convert column width to fix values insted of dynamic
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        formattedColumns={formattedColumns}
        rowData={workQueue}
        cellComponent={(restProps: any) => (
          <TableRow onClickTableRow={onClickTableRow} {...restProps} />
        )}
        showSelectionColumn={false}
        showSelectAll={false}
      />
    </>
  );
};

export default WorkQueueSummary;
