import React, { useState, SetStateAction } from 'react';
import './index.less';
import { Popover } from 'antd';
import styled from 'styled-components';
import ItemHistorySummary from 'components/retail/viewRetailSale/ItemHistorySummary';
import SummaryGrid from 'components/common/SummaryGrid';

const StyledPara = styled.p`
  font-weight: var(--font-weight-500);
  color: var(--blue);
  cursor: pointer;
  pointer-events: all;
  padding-top: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const CommonPopover = ({
  value,
  content,
  event,
  placement,
  isDataFetchedFromApi,
}: any) => {
  const [visible, setVisible] = useState<boolean>(false);
  const onPopoverHideClick = () => {
    setVisible(false);
  };

  const onPopoverVisibleChange = (visible: SetStateAction<boolean>) =>
    setVisible(visible);

  const isHistoryData = Array.isArray(content?.data);

  return (
    <>
      <Popover
        content={
          isHistoryData ? (
            <ItemHistorySummary
              rows={content}
              onPopoverHideClick={onPopoverHideClick}
            ></ItemHistorySummary>
          ) : (
            <SummaryGrid
              rows={content}
              onPopoverHideClick={onPopoverHideClick}
              isDataFetchedFromApi={isDataFetchedFromApi}
            ></SummaryGrid>
          )
        }
        destroyTooltipOnHide={true}
        placement={placement}
        trigger={event}
        open={visible}
        onOpenChange={onPopoverVisibleChange}
        overlayClassName={isHistoryData ? 'popover-width' : ''}
      >
        <StyledPara>{value}</StyledPara>
      </Popover>
    </>
  );
};

export default CommonPopover;
