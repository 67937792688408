import React, { useState, useEffect } from 'react';
import { Col, Form } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { Button } from 'components/common/Button';
import styled from 'styled-components';

const StyledVerify = styled.span`
  color: var(--mountain-meadow);
`;

const StyledVerifyText = styled.span`
  padding: 0 10px;
`;

const PhoneValidation = (props: any) => {
  const {
    onValidateFail,
    onValidationSMSRequest,
    onVerifyPinRequest,
    onValidationStarted,
    onValidationEnded,
    onValidationSuccess,
    defaultVerified,
  } = props;
  const [isVerified, setVerified] = useState<boolean>(false);
  const [isVerifying, setVerifying] = useState<boolean>(false);
  const [isSending, setSending] = useState<boolean>(false);
  const [isVerifyLoading, setVerifyLoading] = useState<boolean>(false);
  const [requestId, setRequestId] = useState<string>();

  useEffect(() => {
    if (defaultVerified) {
      setVerified(defaultVerified);
    }
  }, [defaultVerified]);

  const SendButton = (props: any) => {
    return (
      <Button
        type="primary"
        itemProp="secondary"
        htmlType="button"
        loading={isSending}
        onClick={handleSendClick}
      >
        Send Code
      </Button>
    );
  };

  const VerifyButton = (prop: any) => {
    const { seconds = 60 } = prop;
    const [secs, setTime] = React.useState(seconds);
    const tick = () => {
      if (secs === 0) {
        setVerifying(false);
        onValidationEnded();
      } else {
        setTime(secs - 1);
      }
    };

    useEffect(() => {
      if (isVerifyLoading) return;
      const timerId = setInterval(() => tick(), 1000);
      return () => clearInterval(timerId);
    });

    return (
      <Button
        type="primary"
        itemProp="secondary"
        htmlType="button"
        onClick={handleVerifyClick}
        loading={isVerifyLoading}
      >
        Verify ({secs}s)
      </Button>
    );
  };

  const handleSendClick = async () => {
    setSending(true);
    try {
      const response = await onValidationSMSRequest();
      if (response) {
        setVerifying(true);
        setRequestId(response.data.requestId);
        onValidationStarted();
      } else {
        setVerifying(false);
        onValidationEnded();
      }
    } catch {
      setVerifying(false);
      onValidationEnded();
      onValidateFail('Failed to send SMS. Please contract adminstrator');
    } finally {
      setSending(false);
    }
  };

  const handleVerifyClick = async () => {
    setVerifyLoading(true);
    try {
      const response = await onVerifyPinRequest(requestId);
      const result = response.data.status === 'Verified Successfully';
      if (result) {
        setVerified(true);
        onValidationSuccess();
      } else {
        setVerifying(false);
        onValidateFail('Incorrect Pin. Please try again');
      }
    } catch {
      setVerifying(false);
      onValidateFail('Failed to verify pin. Please contract adminstrator');
    } finally {
      setVerifyLoading(false);
      onValidationEnded();
    }
  };

  if (isVerified) {
    return (
      <Col span={6}>
        <Form.Item
          label="Verified"
          name="verified"
          className="label-control label-visibility"
        >
          <StyledVerify>
            <CheckCircleFilled />
            <StyledVerifyText>Verified</StyledVerifyText>
          </StyledVerify>
        </Form.Item>
      </Col>
    );
  } else if (isVerifying) {
    return (
      <Col span={6}>
        <Form.Item
          label="Verify Button"
          name="verifyBtn"
          className="label-control label-visibility"
        >
          <VerifyButton />
        </Form.Item>
      </Col>
    );
  } else {
    return (
      <Col span={9}>
        <Form.Item
          label="Send Button"
          name="sendBtn"
          className="label-control label-visibility"
        >
          <SendButton />
        </Form.Item>
      </Col>
    );
  }
};

export default PhoneValidation;
