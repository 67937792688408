import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Col, Row, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import {
  getCustomerTaskNotes,
  validateManagedCases,
} from 'services/consumerDuty';
import FooterButtons from 'components/common/footerButtons';
import { Button } from 'components/common/Button';

import TextArea from 'components/common/TextArea';
import ControlledAlert from 'components/common/ControlledAlert';
import { format } from 'date-fns';

import { actions as consumerDutyAction } from 'redux/reducers/consumerDuty';

import {
  FNS_DATE_FORMAT_WITH_TIME,
  GENERIC_ERROR,
  PROFANITY_CHECK,
} from 'globalConstants';

import {
  BUTTONS_LABEL,
  HEADINGS,
  MANAGED_SERVICES_MSG_ON_CLOSING_CASE,
  TABS_NAME,
  TASK_OWNER_VALUE,
} from 'components/consumerDuty/constants';
import { postCustomerTaskNotes } from 'services/consumerDuty';
import CloseCaseModal from 'components/consumerDuty/modals/closeCaseModal';

const StyledLeftCol = styled(Col)`
  padding: 10px 25px;
  border-right: 1px solid var(--light-gray);
`;

const StyledRightCol = styled(Col)`
  padding: 0 26px;
  margin: 26px 0;

  & > .ant-row {
    height: 100%;
    flex-direction: column;
  }
`;

const StyledRow = styled(Row)`
  margin: 18px 0;
`;

const StyledCommentCol = styled(Col)`
  margin: 3px 0;
  padding-bottom: 5px;

  border-bottom: 1px solid var(--light-gray);
`;

interface AtRiskNotesProps {
  onCaseExit: () => void;
}

interface ProfanityError {
  profanity: boolean;
  badWords: Array<string>;
}

const AtRiskNotes = ({ onCaseExit }: AtRiskNotesProps) => {
  const [profanityError, setProfanityError] = useState<ProfanityError>({
    profanity: false,
    badWords: [''],
  });
  const [isProfanity, setProfanity] = useState<boolean>(false);

  const {
    consumerDuty: { taskSubject, selectedTaskId, selectedTaskOwner },
    customer: { customer },
  } = useSelector((state: any) => {
    return {
      consumerDuty: state.consumerDuty,
      customer: state.customer,
    };
  });

  const dispatch = useDispatch();

  const [newNote, setNewNote] = useState<string>('');
  const [caseComments, setCaseComments] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isCloseCaseModalVisible, setCloseCaseModalVisible] =
    useState<boolean>(false);
  const [isAlertPopupVisible, setAlertPopupVisible] = useState<boolean>(false);

  const onNewNoteChange = (event: any) => {
    const { value } = event.target;
    setNewNote(value);
  };

  const onCloseRiskClick = async () => {
    try {
      const response = await validateManagedCases(
        customer?.customerId,
        TASK_OWNER_VALUE.AT_RISK
      );
      if (response?.data?.managed) {
        setCloseCaseModalVisible(true);
      } else {
        setAlertPopupVisible(true);
      }
    } catch (error: any) {
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  const onPreviousBtnClick = () => {
    dispatch(consumerDutyAction.setSelectedTab(TABS_NAME.SERVICE_MANAGER));
  };

  const handleProfanity = (profanity: boolean, badWords: Array<string>) => {
    setProfanityError({ profanity, badWords });
  };

  const getCustomerCaseNotes = async () => {
    const params = {
      taskId: selectedTaskId,
      team: selectedTaskOwner,
    };
    try {
      const response = await getCustomerTaskNotes(params);
      if (response?.data) {
        setCaseComments(response?.data?.taskNotes);
      }
    } catch (error: any) {
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  const onSaveButtonClick = async () => {
    const params = {
      taskId: selectedTaskId,
      notes: newNote,
      vulnerabilityOriginator: selectedTaskOwner,
      vulnerabilityOwner:
        selectedTaskOwner === TASK_OWNER_VALUE.SSTEAM
          ? TASK_OWNER_VALUE.SSTEAM
          : TASK_OWNER_VALUE.STORE,
    };
    try {
      if (profanityError.profanity) {
        setProfanity(true);
      } else {
        setLoading(true);
        const response = await postCustomerTaskNotes(params);
        if (response?.data) {
          setNewNote('');
          setLoading(false);
          getCustomerCaseNotes();
        }
      }
    } catch {
      setLoading(false);
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  const onCloseCasePopupClose = () => {
    setCloseCaseModalVisible(false);
  };

  useEffect(() => {
    getCustomerCaseNotes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="atrisk-note-wrapper">
      <Row className="content">
        <StyledLeftCol span={18}>
          <Row className="title-row">At Risk Profile</Row>
          <Row className="notes-container" style={{ height: '54px' }}>
            {!isEmpty(taskSubject) && (
              <Col span={24}>
                <div className="sub-text">{taskSubject}</div>
              </Col>
            )}
          </Row>
          <Row className="heading">{HEADINGS.COMMENTS}</Row>
          <Row className="notes-container" style={{ height: '310px' }}>
            {caseComments?.map((comment: any, index: number) => (
              <StyledCommentCol key={index} span={24}>
                <Row className="comment-heading">{comment.subject}</Row>
                {comment?.body}
                <div className="sub-text">
                  {comment?.userName}&nbsp;
                  {comment?.storeName}&nbsp;
                  {format(
                    new Date(comment?.createdOn),
                    FNS_DATE_FORMAT_WITH_TIME
                  )}
                </div>
              </StyledCommentCol>
            ))}
          </Row>
          <Row className="heading">{HEADINGS.ADD_COMMENTS}</Row>
          <Row>
            <Col span={24}>
              <TextArea
                value={newNote}
                onChange={onNewNoteChange}
                maxLength={500}
                autoComplete="on"
                spellCheck
                profanityCheck
                profanityExists={handleProfanity}
              />
            </Col>
          </Row>
          <StyledRow justify="space-between">
            <Button itemProp="secondary" onClick={() => setNewNote('')}>
              {BUTTONS_LABEL.CLEAR}
            </Button>
            <Button
              itemProp="secondary"
              loading={loading}
              disabled={!newNote}
              onClick={onSaveButtonClick}
            >
              {BUTTONS_LABEL.SAVE}
            </Button>
          </StyledRow>
        </StyledLeftCol>

        <StyledRightCol span={6}>
          <Row justify="space-between">
            <Row>
              <Col span={24}>
                <Row className="heading">{HEADINGS.VIEW_ADD_DOCS}</Row>
                <Row>
                  <table className="document-table">
                    <tbody>
                      <tr>
                        <td className="title">Uploaded On</td>
                      </tr>
                      <tr>
                        <td className="no-item">No Item</td>
                      </tr>
                    </tbody>
                  </table>
                </Row>
                <StyledRow>
                  <div className="add-doc-btn">
                    <PlusOutlined />
                  </div>
                  <Button itemProp="secondary" disabled>
                    {BUTTONS_LABEL.CAPTURE_DOCS}
                  </Button>
                </StyledRow>
              </Col>
            </Row>
          </Row>
        </StyledRightCol>
      </Row>

      {isProfanity && (
        <ControlledAlert
          id="caseNotes"
          width={600}
          visible={isProfanity}
          message={PROFANITY_CHECK.REMOVE_PROFANITY}
          secondaryMessage={
            <>
              {PROFANITY_CHECK.PROFANITY_WORDS}
              <b>&nbsp;{profanityError.badWords.join(', ')}</b>
            </>
          }
          onClick={() => {
            setProfanity(false);
          }}
          yesButtonText={BUTTONS_LABEL.REMOVE_PROFANITY}
          isCancelBtn={false}
        />
      )}
      {isCloseCaseModalVisible && (
        <CloseCaseModal
          visible={isCloseCaseModalVisible}
          onClose={onCloseCasePopupClose}
        />
      )}
      {isAlertPopupVisible && (
        <ControlledAlert
          width={465}
          visible={isAlertPopupVisible}
          isCancelBtn={false}
          message={MANAGED_SERVICES_MSG_ON_CLOSING_CASE}
          onClick={() => setAlertPopupVisible(false)}
          onCancel={() => setAlertPopupVisible(false)}
          yesButtonText={BUTTONS_LABEL.OK}
        />
      )}
      <FooterButtons
        enableExtraClass
        //Exit Button Props
        isExitOnly={true}
        enableExitPopup={false}
        onConfirmExitClick={onCaseExit}
        //Previous Button Props
        extraButtonEanable
        extraButtonAlign="right"
        extraButtonItemProp="secondary"
        extraButtonText={BUTTONS_LABEL.PREVIOUS}
        extraButtonOnClick={onPreviousBtnClick}
        //Close Risk Button Props
        proceedButtonItemProp="secondary"
        proceedButtonText={BUTTONS_LABEL.CLOSE_RISK}
        onProceedClick={onCloseRiskClick}
        proceedButtonClassName="at-risk-close-button"
      />
    </div>
  );
};

export default AtRiskNotes;
