import React, { useState } from 'react';
import { Button } from 'components/common/Button';
import styled from 'styled-components';
import { Row, Col, Form, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  CONFIRM_LABEL,
  AMOUNT_LABEL,
  FULL_AMOUNT_LABEL,
  REMAINING_AMOUT_LABEL,
  ERROR_MESSAGE,
} from 'components/payment/constants';
import { actions } from 'redux/reducers/payment';
import InputDecimal from 'components/common/InputDecimal';
import PaymentConfimationCodePopup from 'components/payment/paymentConfirmation/PaymentConfimationPopup';

const StyledText = styled.div`
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-500);
`;
const StyledFormItem = styled(Form.Item)`
  margin-top: 7px;
`;

const AmountInput = ({
  isPayment = false,
  totalAmount = 0.0,
  balanceAmount = 0.0,
  addonBefore = '£',
  isConfirmationRequired = false,
  paymentMode = '',
  totalPayableAmount = 0,
}) => {
  const {
    payment: { totalCalculatedAmount = 0.0 },
  } = useSelector((state: any) => {
    return {
      payment: state.payment,
    };
  });

  const dispatch = useDispatch();
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState<boolean>(false);
  const [form] = Form.useForm();
  const prefix = (
    <span dangerouslySetInnerHTML={{ __html: addonBefore }}></span>
  );

  const onSubmit = () => {
    const amount = form.getFieldValue('amount');

    if (!+amount) return;
    const amt = +amount;
    const requestParams = {
      mode: paymentMode,
      amount: amt,
      key: Date.now(),
    };
    dispatch(actions.addAmounts(requestParams));
    dispatch(actions.totalAmounts({ amount: amt }));
    form.setFieldsValue({
      amount: '',
    });
  };
  const onAmountButtonClick = () => {
    const amount = balanceAmount ? balanceAmount : totalAmount;
    form.setFieldsValue({
      amount: (+amount).toFixed(2),
    });
  };

  const onConfirmClick = () => {
    setIsOpenConfirmPopup(true);
  };

  const onConfimPopupCancelClick = () => {
    setIsOpenConfirmPopup(false);
  };

  const onConfimPopupOkClick = () => {
    setIsOpenConfirmPopup(false);
    onSubmit();
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={isConfirmationRequired ? onConfirmClick : onSubmit}
      >
        <Row align="middle" justify="start">
          <Col span={24}>
            <Space>
              <Form.Item
                label={<StyledText>{AMOUNT_LABEL}</StyledText>}
                name="amount"
                className="label-control"
                rules={[
                  () => ({
                    validator(_, value: any) {
                      if (totalPayableAmount > 0) {
                        return Promise.resolve();
                      } else if (
                        +value >
                        (balanceAmount
                          ? Number(balanceAmount.toFixed(2))
                          : totalAmount)
                      ) {
                        return Promise.reject(ERROR_MESSAGE.MORE_THAN);
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputDecimal
                  addonBefore={prefix}
                  className="input-control"
                  disabled={isPayment}
                />
              </Form.Item>
              <StyledFormItem
                label={' '}
                name="btnFullAmt"
                className="label-control"
              >
                <Button
                  disabled={
                    balanceAmount === 0 ||
                    totalCalculatedAmount > Math.abs(totalPayableAmount)
                  }
                  itemProp="secondary"
                  type="primary"
                  onClick={onAmountButtonClick}
                >
                  {balanceAmount && totalAmount > balanceAmount
                    ? REMAINING_AMOUT_LABEL
                    : FULL_AMOUNT_LABEL}
                </Button>
              </StyledFormItem>
              <StyledFormItem
                label={' '}
                name="btnConfirm"
                className="label-control"
              >
                <Button
                  disabled={
                    balanceAmount === 0 ||
                    totalCalculatedAmount > Math.abs(totalPayableAmount)
                  }
                  itemProp="secondary"
                  type="primary"
                  htmlType="submit"
                >
                  {CONFIRM_LABEL}
                </Button>
              </StyledFormItem>
            </Space>
          </Col>
        </Row>
      </Form>
      {isOpenConfirmPopup && (
        <PaymentConfimationCodePopup
          visible={isOpenConfirmPopup}
          setpaymentConfimationCodePopupVisible={onConfimPopupCancelClick}
          onOkClick={onConfimPopupOkClick}
        />
      )}
    </>
  );
};

export default AmountInput;
