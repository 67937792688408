import { joinValues } from 'utils/util';
import { downloadPreContracts } from 'services/checkout';
import { sendToPrinter } from 'services/user';
import { BASKET_SERVICE_TYPE } from 'globalConstants';

import {
  ENABLE_TERMS_FOR_ACTION,
  PRE_CONTRACT_SERVICES,
} from 'components/checkout/constants';

export default function preContract(
  basket: any,
  selectedStore: any,
  customer: any
) {
  let payload = {
    customerName: joinValues([customer.firstNames, customer.surname], true),
    customerAddress: '',
    storeId: selectedStore?.storeId,
    request: null,
    pickupRenewRequest: null,
    totalPledgeAmount: Math.abs(basket?.totalPledgeAmount),
    isAutoCalculated: null,
  };

  const preContractServices = basket?.basketServices.filter((x: any) =>
    PRE_CONTRACT_SERVICES.includes(x.serviceType)
  );

  preContractServices.forEach((item: any) => {
    if (item.serviceType === BASKET_SERVICE_TYPE.PICKUPRENEW) {
      if (
        ENABLE_TERMS_FOR_ACTION.includes(
          item.pickupRenew.request.item.selectedActionType
        )
      ) {
        payload = {
          ...payload,
          pickupRenewRequest: item.pickupRenew.request,
          request: null,
        };

        (function (payload: any, item: any) {
          setTimeout(function () {
            getPreContract(payload, item?.serviceId);
          }, 300);
        })(payload, item);
      }
    } else {
      payload = {
        ...payload,
        request: item.pledge.request,
        pickupRenewRequest: null,
        isAutoCalculated: item.isAutoCalculated,
      };

      (function (payload: any, item: any) {
        setTimeout(function () {
          getPreContract(payload, item?.serviceId);
        }, 300);
      })(payload, item);
    }
  });

  const getPreContract = async (params: any, serviceId: string) => {
    try {
      const dPreContract = await downloadPreContracts(params);
      if (dPreContract?.data) {
        sendToPrinter(dPreContract, 'printcontract', '_PreContract');
      }
    } catch (e: any) {
      console.log('download pre contract', e);
    }
  };
  return true;
}
