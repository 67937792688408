import { Col, Modal, Row } from 'antd';

import React from 'react';
import { currencyFormat } from 'utils/util';

import { CHEQUE_SERVICE_FEE } from '../constants';

import '../index.less';

const ViewServiceFeeDetails = ({
  visible,
  onCancelClick,
  serviceFeeList,
}: any) => {
  const tableRow = (
    index: number,
    upperLimit: number,
    percentCharge: number
  ) => {
    return (
      <tr key={index}>
        <td>{currencyFormat(upperLimit, true)}</td>
        <td>{`${percentCharge}%`}</td>
      </tr>
    );
  };

  return (
    <Modal
      title="Service Fee"
      open={visible}
      closable={true}
      centered
      width={420}
      onCancel={onCancelClick}
      footer={''}
    >
      <Row>
        <Col span={24}>
          <table className="service-fee-table">
            <thead>
              <tr>
                <td>Up to</td>
                <td>Rate</td>
              </tr>
            </thead>
            <tbody>
              {serviceFeeList?.length > 0 ? (
                <>
                  {serviceFeeList.map((item: any, index: number) => {
                    return tableRow(index, item.upperLimit, item.percentCharge);
                  })}
                  <tr key={CHEQUE_SERVICE_FEE.PERSONAL_CHEQUE.KEY}>
                    <td>{CHEQUE_SERVICE_FEE.PERSONAL_CHEQUE.LABEL}</td>
                    <td>{CHEQUE_SERVICE_FEE.PERSONAL_CHEQUE.FEE}</td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td colSpan={4} style={{ textAlign: 'center' }}>
                    No Items
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Col>
      </Row>
    </Modal>
  );
};

export default ViewServiceFeeDetails;
