import { memoize } from 'lodash';
import { getCustomerVulnerableStatus, getImage } from 'services/customer';

//cacheKey - ImageUrl
const imageService = async (imageUrl: any) => {
  try {
    const result: any = await getImage(imageUrl);
    if (result?.data) {
      return URL.createObjectURL(result.data);
    }
  } catch (e: any) {
    return imageUrl;
  }
};

//cacheKey - customerId
const customerStatus = async (customerParam: any) => {
  try {
    const result = await getCustomerVulnerableStatus(customerParam);
    if (result?.data?.customerId === +customerParam) {
      return result?.data?.colorCode;
    }
  } catch (e: any) {
    return '';
  }
};

//use this method to cache static data for a GET ENDPOINT (without any parameters)
//apiGetter -> endpoint call function
//cacheKey -> endpoint call function
const getStaticData = async (apiGetter: any) => {
  try {
    const response = await apiGetter();
    if (response?.data) return response;
  } catch (e: any) {
    return null;
  }
};

//use this method to cache Static data where GET ENDPOINT is called with some agrument/params
//cacheKey - used by lodash yo save cached data
//apiGetter -> endpoint call function
const getStaticDataWithParams = async (
  cacheKey: any,
  apiGetter: any,
  params: any
) => {
  try {
    const response = await apiGetter(params);
    if (response?.data) return response;
  } catch (e: any) {
    return null;
  }
};

const clearCacheByCacheKey = (cacheFunction: any, cacheKey: any) => {
  cacheFunction.cache.delete(cacheKey);
};

export const cacheImage = memoize(imageService);
export const cacheCustomerStatus = memoize(customerStatus);
export const cacheStaticData = memoize(getStaticData);
export const cacheStaticDataWithArgs = memoize(getStaticDataWithParams);

export { clearCacheByCacheKey };
