import React, { useState, useEffect } from 'react';
import { Select, Avatar } from 'antd';
import styled from 'styled-components';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import VirtualGrid from 'components/common/virtualGrid/index';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';
import { RETAIL_ACTION_ID } from 'components/payment/constants';

import { LOAN_STATUS, AFFIDAVIT_ICON } from 'components/pickupRenew/constants';
import { currencyFormat,getPledgeIcons, joinValues } from 'utils/util';
import {
  AGREEMENT_OPTIONS,
  ACTIONS_ON_CUSTOMER_NOT_PRESENT,
  ACTION_WITHDRAW,
  ACTION_AFFI_ONLY,
  ACTIONS_NOT_WAIVE,
  LOAN_STATUS as LOAN_AGGREMENT_STATUS,
} from './constants';
import { CALCULATE_PLEDGE_AGREEMENT_LOAN_AMOUNT } from 'action_creators/pickupRenew';
import { actions } from 'redux/reducers/pickupRenew';
import './index.less';
import ImageLoader from 'components/common/ImageLoader';
import isEmpty from 'lodash/isEmpty';
import { PROFILE_STATUS } from 'components/customer/constants';

interface PledgeDetailsProps {
  rows: Array<object>;
  onPaidPaymentClick: (row: any) => void;
  onWaiveInterestClick: (row: any) => void;
  onPartialPaymentClick: (params: any) => void;
  onPaydownClick: (params: any) => void;
  onWithdrawClick: (params: any) => void;
  onAffidavitClick: (params: any) => void;
  onAffiOnlyClick: (params: any) => void;
  clearAction: (params: any) => void;
}

const StyledGreen = styled.span`
  color: var(--mountain-meadow);
  font-weight: var(--font-weight-700);
`;
const StyledRed = styled.span`
  color: var(--red);
  font-weight: var(--font-weight-700);
`;
const StyledName = styled.span`
  font-weight: var(--font-weight-700);
  margin: 0 4px 0 0;
`;

const StyledHyperlink = styled.button`
  font-weight: var(--font-weight-500);
  color: var(--blue);
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;
const TableRow = ({
  row,
  onAgreementSelectChange,
  onAgreementSelectedActionClear,
  ...restProps
}: any) => {
  const {
    pickupRenew: { isLoading },
  } = useSelector((state: any) => {
    return {
      pickupRenew: state.pickupRenew,
    };
  });

  const onAgreementActionChange = (value: any) => {
    onAgreementSelectChange(row, value);
  };

  const onSelectedActionClear = () => {
    onAgreementSelectedActionClear(row);
  };

  const Cell = () => {
    const { column, tableRow } = restProps;
    const { row } = tableRow;
    if (column.name === 'actions') {
      return (
        <Table.Cell {...restProps}>
          <Select
            placeholder="Select"
            className="grid-select-option"
            allowClear={!(row.selectedActionType === null)}
            loading={isLoading === row.id}
            value={row.selectedActionType}
            onSelect={onAgreementActionChange}
            onClear={onSelectedActionClear}
            disabled={!row?.isValidForProcess || row?.isAuctionLocked}
          >
            {AGREEMENT_OPTIONS.map((item: any, index: number) => {
              return (
                <Select.Option
                  value={item.id}
                  key={index}
                  disabled={
                    (!row.customerPresent &&
                      !ACTIONS_ON_CUSTOMER_NOT_PRESENT.includes(item.id)) ||
                    row.confiscated ||
                    (ACTION_WITHDRAW.includes(item.id) &&
                      !row.isApplicableForWithdraw) ||
                    (ACTION_AFFI_ONLY.includes(item.id) &&
                      !(
                        row.customerPresent &&
                        (row.changeOwnership.newCustomerId === null ||
                          row.changeOwnership.newCustomerId ===
                            row.changeOwnership.customerId)
                      )) ||
                    (ACTION_AFFI_ONLY.includes(item.id) &&
                      row.affidavitAmount <= 0) ||
                    (row?.pledgeServicePermissions &&
                      RETAIL_ACTION_ID.RENEW === item.id &&
                      !row?.pledgeServicePermissions?.renewal) ||
                    (row?.pledgeServicePermissions &&
                      RETAIL_ACTION_ID.PAYDOWN === item.id &&
                      !row?.pledgeServicePermissions?.paydown) ||
                    (row?.pledgeServicePermissions &&
                      RETAIL_ACTION_ID.PARTIAL_PAY === item.id &&
                      !row?.pledgeServicePermissions?.partialPayment)
                  }
                >
                  {item.value}
                </Select.Option>
              );
            })}
          </Select>
        </Table.Cell>
      );
    }
    return <Table.Cell {...restProps} />;
  };

  return <>{Cell()}</>;
};

const PledgeDetailsTable = ({
  rows,
  onPaidPaymentClick,
  onWaiveInterestClick,
  onPartialPaymentClick,
  onPaydownClick,
  onWithdrawClick,
  onAffidavitClick, // Hyperlink action
  onAffiOnlyClick, // Dropdown action
  clearAction,
}: PledgeDetailsProps) => {
  const dispatch = useDispatch();
  const {
    pickupRenew: { selectedAgreementRowId },
    customer: { customer, customerStatusColor },
  } = useSelector((state: any) => {
    return {
      pickupRenew: state.pickupRenew,
      customer: state.customer,
    };
  });

  const [loans, setLoans] = useState<any>([]);

  useEffect(() => {
    if (customerStatusColor === PROFILE_STATUS.BLACK) {
      const loans = rows.map((pledge: any) => {
        if (pledge.loanStatus === LOAN_AGGREMENT_STATUS.OPEN)
          return { ...pledge, isLocked: true };
        return pledge;
      });

      setLoans(loans);
    } else setLoans(rows);
  }, [rows, customerStatusColor]);

  const {
    street,
    town,
    property,
    county,
    postcode,
    houseName,
    area,
    firstNames,
    middleName,
    surname,
  } = customer;

  useEffect(() => {
    if (!isEmpty(clearAction)) {
      onAgreementSelectedActionClear(clearAction);
    }
  }, [clearAction]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomerPresent = (value: any, row: any) => {
    // if ownership is true- customer is present in front, if ownership is false - check customer session
    const ownerShip =
      value === AGREEMENT_OPTIONS[1].id || value === AGREEMENT_OPTIONS[2].id
        ? row.changeOwnership.isOwnershipChanged
        : false;

    if (ownerShip) return true;
    else if (row.customerId === customer?.customerId) {
      return true;
    }
    return false;
  };

  const onAgreementSelectChange = (row: any, value: any) => {
    const updatedRow = {
      ...row,
      selectedActionType: value,
      changeOwnership: {
        ...row.changeOwnership,
        newCustomerId:
          value === AGREEMENT_OPTIONS[1].id || value === AGREEMENT_OPTIONS[2].id
            ? customer?.customerId
            : null,
        newCustomerName:
          value === AGREEMENT_OPTIONS[1].id || value === AGREEMENT_OPTIONS[2].id
            ? joinValues([customer.firstNames, customer.surname], true)
            : null,
        isOwnershipChanged:
          value === AGREEMENT_OPTIONS[1].id || value === AGREEMENT_OPTIONS[2].id
            ? row.changeOwnership.isOwnershipChanged
            : false, // CO is applicable only on Renew and Paydown
        customer:
          value === AGREEMENT_OPTIONS[1].id || value === AGREEMENT_OPTIONS[2].id
            ? {
                street,
                town,
                property,
                county,
                postcode,
                houseName,
                area,
                firstNames,
                middleName,
                surname,
              }
            : null,
      },
      customerPresent: getCustomerPresent(value, row),
    };
    dispatch(actions.updatePledgeAgreement(updatedRow)); // to update customer present in agreement

    const params = {
      agreement: updatedRow,
    };
    switch (value) {
      case AGREEMENT_OPTIONS[0].id:
      case AGREEMENT_OPTIONS[1].id:
      case AGREEMENT_OPTIONS[5].id:
        dispatch({
          type: CALCULATE_PLEDGE_AGREEMENT_LOAN_AMOUNT,
          payload: { params },
        });
        break;
      case AGREEMENT_OPTIONS[2].id:
        onPaydownClick(params);
        break;
      case AGREEMENT_OPTIONS[3].id:
        onPartialPaymentClick(params);
        break;
      case AGREEMENT_OPTIONS[4].id:
        onWithdrawClick(params);
        break;
      default:
        return null;
    }
  };

  const onAgreementSelectedActionClear = (row: any) => {
    const updatedRow = {
      ...row,
      selectedActionType: null,
      waivedOffAmount: row?.waiveInterest?.waivedOffVulnerableAmount, // only vulnerable amount ---as manual is 0 when cancel action
      newLoanAmount: 0,
      toTakeAmount: 0,
      partialPayment: { ...row.partialPayment, wishToPay: 0 },
      paydown: { ...row.paydown, wishToPay: 0, newLoanAmount: 0 },
      waiveInterest: {
        ...row.waiveInterest,
        waivedOffAmount: row?.waiveInterest?.waivedOffVulnerableAmount, // only vulnerable amount ---as manual is 0 when cancel action
        waivedOffDays: row?.waiveInterest?.waivedOffVulnerableDays, // only vulnerable days ---as manual is 0 when cancel action
        waivedOffNonVulnerableAmount: 0,
        waivedOffNonVulnerableDays: 0,
        reasonNotes: row?.waiveInterest?.waivedOffVulnerableAmount
          ? row?.waiveInterest?.reasonNotes
          : '',
      },
    };
    dispatch(actions.updatePledgeAgreement(updatedRow));
  };

  const onRowSelectionChange = (rowId: any) => {
    dispatch(actions.setSelectedAgreementRow(rowId));
  };

  const getCustomerDetails = (row: any) => {
    if (row.customerPresent) {
      if (
        row.changeOwnership.newCustomerId !== null &&
        row.changeOwnership.customerId !== row.changeOwnership.newCustomerId &&
        row.changeOwnership.isOwnershipChanged
      ) {
        return (
          <div>
            <StyledName>{row.changeOwnership.newCustomerName}</StyledName>
            <Avatar
              className="mx-smaller-icon"
              src={require('assets/images/icons/change_ownership.svg')}
            />
          </div>
        );
      } else return <StyledGreen>{row.customerName}</StyledGreen>;
    } else {
      return <StyledRed>{row.customerName}</StyledRed>;
    }
  };

  const [columns] = useState([
    {
      name: 'loanNumber',
      title: 'Pledge No.',
      getCellValue: (row: any) => row?.loanNumber,
    },
    {
      name: 'customerName',
      title: 'Customer Name',
      type: 'customerType',
      getCellValue: (row: any) => getCustomerDetails(row),
    },
    {
      name: 'loanStatusId',
      title: 'Status',
      type: 'icon',
      isMultipleIcons: true, // when isMultipleIcons = true , send an array of icon values
      isIconClickable: false,
      getCellValue: (row: any) => getPledgeIcons(row, customerStatusColor),
    },
    {
      name: 'startDate',
      title: 'Start Date',
      getCellValue: (row: any) =>
        row && row?.startDate
          ? format(new Date(row?.startDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'dueDate',
      title: 'Due Date',
      getCellValue: (row: any) =>
        row && row?.dueDate
          ? format(new Date(row?.dueDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'loanAmount',
      title: 'Loan Amount',
      getCellValue: (row: any) => currencyFormat(row?.loanAmount, true),
    },
    {
      name: 'interestRate',
      title: 'Interest',
      getCellValue: (row: any) => `${row?.interestRate?.toFixed(2)}%`,
    },
    {
      name: 'dailyInterest',
      title: 'Daily',
      getCellValue: (row: any) =>
        `${currencyFormat(row?.dailyInterestDisplay, true)}`,
    },
    {
      name: 'paidAmount',
      title: 'Paid',
      getCellValue: (row: any) => (
        <StyledHyperlink type="button" onClick={() => onPaidPaymentClick(row)}>
          {`${currencyFormat(row?.paidAmount, true)}`}
        </StyledHyperlink>
      ),
    },
    {
      name: 'affidavitAmount',
      title: 'Affidavit',
      getCellValue: (row: any) =>
        row.customerPresent === true &&
        (row.changeOwnership.newCustomerId === null ||
          row.changeOwnership.newCustomerId ===
            row.changeOwnership.customerId) ? (
          <StyledHyperlink type="button" onClick={() => onAffidavitClick(row)}>
            {`${currencyFormat(row?.affidavitAmount, true)}`}
          </StyledHyperlink>
        ) : (
          `${currencyFormat(row?.affidavitAmount, true)}`
        ),
    },
    {
      name: 'dueToday',
      title: 'Due Today',
      getCellValue: (row: any) => `${currencyFormat(row?.dueToday, true)}`,
    },
    {
      name: 'actions',
      title: 'Actions',
      getCellValue: (row: any) => row?.actions,
    },
    {
      name: 'waivedOffAmount',
      title: 'Waived',
      getCellValue: (row: any) =>
        row?.waivedOffAmount > 0 ||
        (row?.selectedActionType &&
          !ACTIONS_NOT_WAIVE.includes(row?.selectedActionType)) ? (
          <StyledHyperlink
            onClick={() => onWaiveInterestClick(row)}
          >{`${currencyFormat(row?.waivedOffAmount, true)}`}</StyledHyperlink>
        ) : (
          <>{`${currencyFormat(row?.waivedOffAmount, true)}`}</>
        ),
    },
    {
      name: 'newLoanAmount',
      title: 'New Loan',
      type: 'input',
      getCellValue: (row: any) => currencyFormat(row?.newLoanAmount),
    },
    {
      name: 'toTakeAmount',
      title: 'To Take',
      type: 'input',
      getCellValue: (row: any) => currencyFormat(row?.toTakeAmount),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'loanNumber', width: '7%' },
    { columnName: 'customerName', width: '9%' },
    { columnName: 'loanStatusId', width: '7%' },
    { columnName: 'startDate', width: '6%' },
    { columnName: 'dueDate', width: '6%' },
    { columnName: 'loanAmount', width: '8%', align: 'right' },
    { columnName: 'interestRate', width: '5%', align: 'right' },
    { columnName: 'dailyInterest', width: '4%', align: 'right' },
    { columnName: 'paidAmount', width: '4%', align: 'right' },
    { columnName: 'affidavitAmount', width: '5%', align: 'right' },
    { columnName: 'dueToday', width: '6%', align: 'right' },
    { columnName: 'actions', width: '7%' },
    { columnName: 'waivedOffAmount', width: '5%', align: 'right' },
    { columnName: 'newLoanAmount', width: '7%', align: 'right' },
    { columnName: 'toTakeAmount', width: '7%', align: 'right' },
  ]);

  const [formattedColumns] = useState([
    'newLoanAmount',
    'toTakeAmount',
    'paid',
    'loanStatusId',
  ]);

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={loans}
        formattedColumns={formattedColumns}
        expandableComponent={PledgeExpandableTable}
        cellComponent={(restProps: any) => (
          <TableRow
            onAgreementSelectChange={onAgreementSelectChange}
            onAgreementSelectedActionClear={onAgreementSelectedActionClear}
            {...restProps}
          />
        )}
        rowSelectionEnable={true}
        selection={selectedAgreementRowId}
        onSelectionChange={onRowSelectionChange}
        highlightRow={true}
      />
    </>
  );
};

export const PledgeExpandableTable = ({ row }: any) => {
  const itemDescription = (row: any) => {
    return {
      title: row.description,
      anchor: row.description,
      placement: 'bottom',
      data: {},
    };
  };

  const [columns] = useState([
    {
      name: 'itemImage',
      title: 'Item Image',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row.id}`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'barcode',
      title: 'Barcode',
      getCellValue: (row: any) => row?.barcode,
    },
    {
      name: 'description',
      title: 'Description',
      type: 'popover-no-api',
      getCellValue: (row: any) => itemDescription(row),
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) => `${row?.weight?.toFixed(2)}g`,
    },
    {
      name: 'amount',
      title: 'Amount',
      getCellValue: (row: any) => `${currencyFormat(row?.amount, true)}`,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'itemImage', width: 130 },
    { columnName: 'barcode', width: 180 },
    { columnName: 'description', width: 270 },
    { columnName: 'weight', width: 120, align: 'right' },
    { columnName: 'amount', width: 120, align: 'right' },
  ]);

  const [formattedColumns] = useState(['description']);

  return (
    <>
      <div className="expandable-table-rows">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          formattedColumns={formattedColumns}
          rowData={row?.pledgeItems}
        />
      </div>
    </>
  );
};

export default PledgeDetailsTable;
