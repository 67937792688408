import React, { useState, useCallback, useEffect } from 'react';
import { Input, Form } from 'antd';
import Select from 'components/common/Select';
import styled from 'styled-components';
import {
  BASE_PLEDGE_CATEGORIES,
  HALLMARKED_OPTIONS,
  ITEM_TYPE,
  PLEDGE_ITEM,
  JEWELLERY_MANDATORY_FIELDS,
  COINS_OR_WATCHES_MANDATORY_FIELDS,
  HANDBAGS_MANDATORY_FIELDS,
  WATCHES_MANDATORY_FIELDS,
  COIN_DISABLED_FIELDS,
  COIN_SUB_ITEM_CATEGORIES,
} from '../../constants';
import debounce from 'lodash/debounce';
import { useSelector, useDispatch } from 'react-redux';
import { PLEDGE_ITEM_PRICE_REQUEST } from 'action_creators/pledge';
import { coinActions as actions } from 'redux/reducers/pawnbroking/coin';
import { pledgeActions } from 'redux/reducers/pawnbroking/index';
import { reducePledgeItems } from 'utils/util';
import isEmpty from 'lodash/isEmpty';
import AlertsPopup from '../alertsPopup';
import HelpPopoverInfo from '../../../common/HelpPopoverInfo';
import TextArea from 'components/common/TextArea';

const StyledTable = styled.table`
  background: var(--white);
  border: 1px solid var(--light-gray);
  border-radius: 4px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  & tr:last-of-type {
    & td {
      border-bottom: none;
    }
  }
  table-layout: fixed;
  & .error-field {
    border: 1px solid red;
  }
  & .ant-input {
    padding: 0px 11px;
  }
`;

const StyledTableCellLeft = styled.td`
  padding: 0px 10px !important;
  text-align: left;
  border-right: 1px solid var(--light-gray);
  border-bottom: 1px solid var(--light-gray);
  &:last-of-type {
    border-bottom: none;
  }
  width: 150px;
  font-weight: 500 !important;
`;

const StyledTableCellRight = styled.td`
  padding: 0px 0px !important;
  border-bottom: 1px solid var(--light-gray);
  & .ant-select {
    width: 100%;
    padding: 4px 0;
    & .ant-select-selector {
      border: none;
      box-shadow: none !important;
      border-color: transparent !important;
    }
  }
  & .ant-select-disabled > .ant-select-selector {
    background-color: var(--white) !important;
  }
  & .ant-input.ant-input-disabled {
    background-color: var(--white);
  }
  & .error-field {
    border: 1px solid red !important;
  }
`;

const StyledInput = styled(Input)`
  height: 44px;
  border: none;
  width: 100%;
`;

interface PledgeItemDescriptionProps {
  getPrice: (totalWeight: string | number) => void;
  itemFixedAttributes: Array<any>;
  resetItemDescription?: boolean;
  isPledgeItemFormValidate: boolean;
  setPledgeItemFormValidate: () => void;
}

interface ItemAttributeProps {
  id: number;
  description: string;
  itemFixedAttributes: Array<any>;
}

const PledgeItemDescription = ({
  getPrice,
  itemFixedAttributes,
  resetItemDescription,
  isPledgeItemFormValidate,
  setPledgeItemFormValidate,
}: PledgeItemDescriptionProps) => {
  const [form] = Form.useForm();
  const [inputValue, setInputValue] = useState<number | string>('');
  const [errorFields, setErrorFields] = useState<any>([]);
  const [isAlertsVisible, setAlertsVisible] = useState<boolean>(false);
  const [isCoinError, setCoinError] = useState<boolean>(false);

  const dispatch = useDispatch();
  const {
    stone: { stonePrices },
    coin: { coinAllItemsData, coinPrices },
    pawnbroking: {
      itemCategory,
      subItemCategory,
      itemDescription,
      pledgeItemsValue,
      handbags,
      watches,
    },
    basket: { editModeBasketPledgeItems },
    valuationCenter: { isValuationItemToPledge },
  } = useSelector((state: any) => {
    return {
      stone: state.stone,
      coin: state.coin,
      pawnbroking: state.pawnbroking,
      basket: state.basket,
      valuationCenter: state.valuationCenter,
    };
  });
  const item = itemDescription?.split(':');

  const getItemTableRowOptions = (item: string) => {
    return itemFixedAttributes.filter((x) => item === x.description);
  };

  const checkWeightGreaterError = () => {
    if (
      itemCategory.id === BASE_PLEDGE_CATEGORIES[0].id &&
      pledgeItemsValue?.coinPrice?.weight > 0 &&
      form.getFieldValue('weightInGrams') !== undefined &&
      +form.getFieldValue('weightInGrams') !== 0 &&
      pledgeItemsValue?.coinPrice?.weight > form.getFieldValue('weightInGrams')
    ) {
      const weightGreaterError = {
        name: ['weightInGrams'],
        errors: ['Weight must be greater than the coin weight'],
      };
      return weightGreaterError;
    }
    return undefined;
  };
  const validatePledgeItems = async () => {
    const weightGreaterError = checkWeightGreaterError();
    const isHandbagError =
      itemCategory.id === BASE_PLEDGE_CATEGORIES[3].id
        ? !handbags.length
        : false;

    const isWatchError =
      itemCategory.id === BASE_PLEDGE_CATEGORIES[1].id
        ? !watches.length
        : false;

    const coinError = isCoinError
      ? {
          name: ['addCoins'],
          errors: ['Please add a Coin.'],
        }
      : undefined;

    const handbagError = isHandbagError
      ? {
          name: ['addHandbagDetails'],
          errors: ['Please add handbag details.'],
        }
      : undefined;

    const watchError = isWatchError
      ? {
          name: ['addWatchDetails'],
          errors: ['Please add watch details.'],
        }
      : undefined;

    try {
      await form.validateFields();
      if (
        isEmpty(weightGreaterError) &&
        isEmpty(coinError) &&
        !isHandbagError &&
        !isWatchError
      ) {
        setErrorFields([]);
        dispatch(pledgeActions.updateAddToPledgePurchase(true));
      } else {
        setErrorFields([
          coinError,
          weightGreaterError,
          handbagError,
          watchError,
        ]);
      }
    } catch (errorInfo: any) {
      if (isEmpty(weightGreaterError)) {
        setErrorFields([
          ...errorInfo.errorFields,
          coinError,
          handbagError,
          watchError,
        ]);
        dispatch(pledgeActions.updateAddToPledgePurchase(false));
      } else {
        setErrorFields([
          ...errorInfo.errorFields,
          coinError,
          weightGreaterError,
        ]);
      }
    }
    setPledgeItemFormValidate();
  };

  const checkMandatoryFields = (fieldName: string) => {
    switch (itemCategory.id) {
      case BASE_PLEDGE_CATEGORIES[0].id: //Jewellery
        return JEWELLERY_MANDATORY_FIELDS.includes(fieldName);
      case BASE_PLEDGE_CATEGORIES[1].id: // Watches
        return WATCHES_MANDATORY_FIELDS.includes(fieldName);
      case BASE_PLEDGE_CATEGORIES[2].id: // Coins
        if (coinAllItemsData?.length > 0) {
          if (isCoinError) setCoinError(false);
          return false;
        } else if (subItemCategory.id !== COIN_SUB_ITEM_CATEGORIES[0].id) {
          if (isCoinError) setCoinError(false);
          return COINS_OR_WATCHES_MANDATORY_FIELDS.includes(fieldName);
        } else if (
          coinAllItemsData?.length === 0 &&
          form.getFieldValue('metalCondition') === undefined &&
          form.getFieldValue('metalType') === undefined &&
          form.getFieldValue('fineness') === undefined &&
          form.getFieldValue('weightInGrams') === undefined
        ) {
          if (!isCoinError) setCoinError(true);
          return false;
        } else if (
          coinAllItemsData?.length === 0 &&
          (form.getFieldValue('metalCondition') !== undefined ||
            form.getFieldValue('metalType') !== undefined ||
            form.getFieldValue('fineness') !== undefined ||
            form.getFieldValue('weightInGrams') !== undefined)
        ) {
          if (isCoinError) setCoinError(false);
          return COINS_OR_WATCHES_MANDATORY_FIELDS.includes(fieldName);
        } else return false;
      case BASE_PLEDGE_CATEGORIES[3].id: // Handbags
        return HANDBAGS_MANDATORY_FIELDS.includes(fieldName);
      default:
        return false;
    }
  };

  const checkDisabledFields = (fieldName: string) => {
    switch (subItemCategory.id) {
      case COIN_SUB_ITEM_CATEGORIES[0].id: //Coins
        return COIN_DISABLED_FIELDS.includes(fieldName);
    }
    return false;
  };

  const onItemAttributeChange = () => {
    const coinItemsFromAddCoin = reducePledgeItems(coinAllItemsData);
    const params = {
      categoryId: itemCategory?.id,
      metalPrice: {
        metalCondition: form.getFieldValue('metalCondition'),
        metalType: form.getFieldValue('metalType'),
        fineness: form.getFieldValue('fineness'),
        weightInGrams: form.getFieldValue('weightInGrams')?.replace('g', ''),
        isHallMark: form.getFieldValue('isHallmark'),
        notes: form.getFieldValue('notes'),
      },
      coinPrices: [...coinItemsFromAddCoin],
      stonePrices: [...stonePrices],
    };

    if (
      form.getFieldValue('metalCondition') !== undefined &&
      form.getFieldValue('metalType') !== undefined &&
      form.getFieldValue('fineness') !== undefined &&
      form.getFieldValue('weightInGrams') !== undefined &&
      form.getFieldValue('weightInGrams') !== ''
    ) {
      dispatch({
        type: PLEDGE_ITEM_PRICE_REQUEST,
        payload: { params: params },
      });
    }

    dispatch(actions.coinItemsPrice(params.coinPrices));
    dispatch(pledgeActions.updateMetalPrice(params.metalPrice));
    getPrice(form.getFieldValue('weightInGrams'));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(debounce(onItemAttributeChange, 300), [
    itemCategory,
    stonePrices,
    coinPrices,
  ]);

  const onInputChange = (e: any) => {
    const val = e.target.value;
    //allowing only 2 numeric after decimal and 7 before it
    const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
    const value =
      match !== undefined && match !== null && match.length > 0
        ? match[1] + match[2]
        : 0;
    setInputValue(value);
    form.setFieldsValue({ weightInGrams: value });
    delayedQuery();
  };

  const itemDescriptionRow = (
    fieldName: string,
    label: string,
    options: Array<ItemAttributeProps> = [],
    input = false
  ) => {
    const itemOptions = options[0]?.itemFixedAttributes;

    return (
      <tr>
        <StyledTableCellLeft>
          {`${label}${checkMandatoryFields(fieldName) ? '*' : ''}`}
          <span style={{ float: 'right', marginRight: '35px' }}>
            <HelpPopoverInfo
              linkedID={`${fieldName}_${label}`}
            ></HelpPopoverInfo>
          </span>
        </StyledTableCellLeft>
        <StyledTableCellRight
          className={
            errorFields?.some((item: any) => item?.name[0] === fieldName)
              ? 'error-field'
              : ''
          }
        >
          {itemOptions?.length ? (
            <Form.Item
              name={fieldName}
              label={label}
              noStyle
              rules={[
                {
                  required: checkMandatoryFields(fieldName),
                  message: `${label} is required`,
                },
              ]}
            >
              <Select
                placeholder="Select"
                onChange={onItemAttributeChange}
                disabled={item[0] === '' || checkDisabledFields(fieldName)}
                addinputcontrolclass={false}
                options={itemOptions.map((item: any) => {
                  return {
                    key: item.id,
                    value: item.id,
                    label: item.description,
                  };
                })}
              />
            </Form.Item>
          ) : input ? (
            <Form.Item
              label={label}
              name={fieldName}
              noStyle
              rules={[
                {
                  required: checkMandatoryFields(fieldName),
                  message: `${label} is required`,
                },
              ]}
            >
              <StyledInput
                type="text"
                onChange={onInputChange}
                value={inputValue}
                autoComplete="off"
                disabled={item[0] === '' || checkDisabledFields(fieldName)}
                placeholder="0.00"
              />
            </Form.Item>
          ) : (
            <Form.Item name={fieldName} label={label} noStyle>
              <TextArea
                className="ant-input-textarea"
                rows={2}
                maxLength={128} //as per what we have in NEO
                autoComplete="off"
                disabled={item[0] === '' || checkDisabledFields(fieldName)}
                onChange={debounce(onItemAttributeChange, 300)}
              />
            </Form.Item>
          )}
        </StyledTableCellRight>
      </tr>
    );
  };

  useEffect(() => {
    if (resetItemDescription || isEmpty(itemCategory)) form.resetFields();
  }, [resetItemDescription, itemCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEmpty(editModeBasketPledgeItems)) {
      setInputValue(
        editModeBasketPledgeItems?.metal?.weightInGrams?.toFixed(2)
      );
      form.setFieldsValue({
        ...editModeBasketPledgeItems.metal,
        weightInGrams:
          editModeBasketPledgeItems?.metal?.weightInGrams?.toFixed(2),
      });
    }
  }, [editModeBasketPledgeItems]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isPledgeItemFormValidate) validatePledgeItems();
  }, [isPledgeItemFormValidate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorFields?.length > 0) {
      setAlertsVisible(true);
    }
  }, [errorFields]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setErrorFields([]);
    setCoinError(false);
  }, [itemCategory]);

  return (
    <>
      <Form form={form} disabled={isValuationItemToPledge}>
        <StyledTable>
          <tbody>
            {itemDescriptionRow(
              PLEDGE_ITEM.CONDITION.NAME,
              PLEDGE_ITEM.CONDITION.LABEL,
              getItemTableRowOptions(PLEDGE_ITEM.CONDITION.LABEL.toUpperCase()),
              true
            )}
            {itemDescriptionRow(
              PLEDGE_ITEM.METAL_TYPE.NAME,
              PLEDGE_ITEM.METAL_TYPE.LABEL,
              getItemTableRowOptions(ITEM_TYPE.METAL_TYPES),
              true
            )}
            {itemDescriptionRow(
              PLEDGE_ITEM.FINENESS.NAME,
              PLEDGE_ITEM.FINENESS.LABEL,
              getItemTableRowOptions(PLEDGE_ITEM.FINENESS.LABEL.toUpperCase()),
              true
            )}
            {itemDescriptionRow(
              PLEDGE_ITEM.HALLMARKED.NAME,
              PLEDGE_ITEM.HALLMARKED.LABEL,
              HALLMARKED_OPTIONS,
              true
            )}
            {itemDescriptionRow(
              PLEDGE_ITEM.WEIGHT.NAME,
              PLEDGE_ITEM.WEIGHT.LABEL,
              [],
              true
            )}
            {itemDescriptionRow(
              PLEDGE_ITEM.NOTES.NAME,
              PLEDGE_ITEM.NOTES.LABEL,
              []
            )}
          </tbody>
        </StyledTable>
      </Form>
      {isAlertsVisible && (
        <AlertsPopup
          visible={isAlertsVisible}
          setAlertsVisible={() => setAlertsVisible(false)}
          errorFields={errorFields}
        />
      )}
    </>
  );
};

export default PledgeItemDescription;
