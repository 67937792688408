import * as urls from '../apiUrls';
import { doRequest } from 'utils/request';

const getVulnerabilityIndicator = () => {
  return doRequest(`${urls.GET_VULNERABILITY_INDICATOR}`, 'get');
};

const postVulnerabilityValidator = (params) => {
  return doRequest(`${urls.POST_VULNERABILITY_VALIDATOR}`, 'post', params);
};

const getVulnerabilityResolutionTime = () => {
  return doRequest(`${urls.GET_VULNERABILITY_RESOLUTION_TIME}`, 'get');
};

const postCustomerSupportRequest = (params) => {
  return doRequest(
    `${urls.POST_CUSTOMER_SUPPORT_REQUEST_STORE}`,
    'post',
    params
  );
};
const postReleaseCustomerAccount = (params) => {
  return doRequest(`${urls.POST_RELEASE_CUSTOMER_ACCOUNT}`, 'post', params);
};

const postEscalateToSST = (params) => {
  return doRequest(`${urls.POST_ESCALATE_TO_SST}`, 'post', params);
};

const postFetchAllCustomerNotes = (customerId, params) => {
  return doRequest(
    `${urls.POST_FETCH_ALL_NOTES}/${customerId}`,
    'post',
    params
  );
};
const getCustomerNotes = (customerId, notesRequired) => {
  return doRequest(
    `${urls.GET_CUSTOMER_CASE_NOTES}/${customerId}/${notesRequired}`,
    'get'
  );
};

const postCaseSummaryUpdateNotes = (params) => {
  return doRequest(`${urls.POST_CASE_SUMMARY_UPDATE_NOTE}`, 'post', params);
};

const getLockedPledgeDetails = (customerId, team) => {
  return doRequest(
    `${urls.GET_LOCKED_PLEDGE_DETAILS}/${customerId}/${team}`,
    'get'
  );
};

const getRenewalHistory = (loannId) => {
  return doRequest(`${urls.GET_RENEWAL_HISTORY}/${loannId}`, 'get');
};

const postManageServicesAndNewPledge = (params) => {
  return doRequest(`${urls.POST_MANAGE_SERVICES}`, 'post', params);
};

const postCloseCase = (params) => {
  return doRequest(`${urls.POST_CLOSE_CASE}`, 'post', params);
};

const postUpdateCase = (params) => {
  return doRequest(`${urls.POST_UPDATE_CASE}`, 'post', params);
};

const deleteCaseFromWorkqueue = (params) => {
  return doRequest(`${urls.DELETE_FROM_WORKQUEUE_LIST}`, 'post', params);
};

const postSaveAsManagedCase = (params) => {
  return doRequest(`${urls.POST_SAVE_AS_MANAGED_CASE}`, 'post', params);
};

const getCaseSummay = (customerId) => {
  return doRequest(`${urls.GET_CASE_SUMMARY}/${customerId}`, 'get');
};

const getWaivedInterestHistory = (loanId, team) => {
  return doRequest(
    `${urls.GET_WAVIED_INTEREST_HISTORY}/${loanId}/${team}`,
    'get'
  );
};

const postWaivedInterest = (params) => {
  return doRequest(`${urls.POST_WAVIED_INTEREST}`, 'post', params);
};

const postEscalateCstToSst = (params) => {
  return doRequest(`${urls.POST_CST_TO_SST}`, 'post', params);
};

const postMarkAsRead = (params) => {
  return doRequest(`${urls.POST_MARK_AS_READ}`, 'post', params);
};

const postCloseRisk = (params) => {
  return doRequest(`${urls.POST_CLOSE_RISK}`, 'post', params);
};

const getCustomerTaskNotes = (params) => {
  return doRequest(`${urls.GET_CUSTOMER_TASK_NOTES}`, 'post', params);
};

const getCustomerSSTNotes = (customerSupportId, taskId) => {
  return doRequest(
    `${urls.GET_SST_CASE_NOTES}/${customerSupportId}/${taskId}`,
    'get'
  );
};

const postCustomerTaskNotes = (params) => {
  return doRequest(`${urls.POST_CUSTOMER_TASK_NOTES}`, 'post', params);
};

const searchLoanExceptions = (params) => {
  return doRequest(`${urls.POST_SEARCH_LOAN_EXCEPTIONS}`, 'post', params);
};

const validateManagedCases = (customerId, team) => {
  return doRequest(
    `${urls.VALIDATE_MANAGED_CASE}/${customerId}/${team}`,
    'get'
  );
};

export {
  deleteCaseFromWorkqueue,
  getVulnerabilityIndicator,
  postVulnerabilityValidator,
  getVulnerabilityResolutionTime,
  postCustomerSupportRequest,
  postEscalateToSST,
  postFetchAllCustomerNotes,
  postReleaseCustomerAccount,
  getCustomerNotes,
  postCaseSummaryUpdateNotes,
  getRenewalHistory,
  postManageServicesAndNewPledge,
  postCloseCase,
  getCaseSummay,
  postUpdateCase,
  getWaivedInterestHistory,
  postWaivedInterest,
  postSaveAsManagedCase,
  getLockedPledgeDetails,
  postEscalateCstToSst,
  postMarkAsRead,
  searchLoanExceptions,
  postCloseRisk,
  getCustomerTaskNotes,
  postCustomerTaskNotes,
  validateManagedCases,
  getCustomerSSTNotes,
};
