import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isImageUploaded: false,
  isImageUploading: false,
  pledge: [],
  pledgeItemsValue: {},
  error: {},
  itemCategory: {},
  subItemCategory: {},
  itemDescription: '',
  metalPrice: {},
  addToPledgePurchase: false,
  watches: [],
  handbags: [],
  itemAdditionalProperties: [], // this is additional properties for watches and handbags only
  itemAdditionalPropertiesJewelleryCoins: {}, // this is additional properties for jewellery and coins only
  isImageCaptured: false, //one component
  itemImage: '', //base64 string
  itemImageCollectionResponse: {}, //response from multipleImage API
  parentImageUrl: '',
  serviceType: 1, //1 - pledge, 2 - purchase
  pledgeServiceId: '', // service created for pledge - to show in view pledge summary
  purchaseServiceId: '', // service created for purchase - to show in view purchase summary
  pawnbrokingSummary: [], //pawnbroking summary tab on customer profile
  noAgreementSelection: [], // No Agreement - selected loans
  historicalItems: [] as Array<any>,
  selectedHistoricalItem: {},
  checklists: [],
  isItemFromHistory: false,
  interestRateCounter: 0, // counter for interest rate - API for loan-payable-amount, loan-amount gets called when counter <=1 to update interest-rate
};
const slice = createSlice({
  name: 'pledgeActions',
  initialState,
  reducers: {
    updatePledge(state, action) {
      state.pledge = action.payload;
    },
    itemPriceValuationResponse(state, action) {
      state.pledgeItemsValue = action.payload;
    },
    itemError(state, action) {
      state.error = {
        message: action.payload.message,
        description: action?.payload?.response?.data?.error,
      };
    },
    updateItemCategory(state, action) {
      // item category update on create side of pledge
      state.itemCategory = action.payload;
      state.addToPledgePurchase = false;
    },
    updateSubItemCategory(state, action) {
      state.subItemCategory = action.payload;
    },
    updateItemDescription(state, action) {
      state.itemDescription = Array.isArray(action.payload)
        ? action.payload.join(':')
        : action.payload;
    },
    updateMetalPrice(state, action) {
      state.metalPrice = action.payload;
    },
    updateAddToPledgePurchase(state, action) {
      state.addToPledgePurchase = action.payload;
    },
    updateWatches(state, action) {
      state.watches = action.payload.watches || [];
      state.checklists = action.payload.checklists || [];
    },
    updateHandbags(state, action) {
      state.handbags = action.payload.handBags || [];
      state.checklists = action.payload.checklists || [];
    },
    updateItemAdditionalProperties(state, action) {
      state.itemAdditionalProperties = action.payload || [];
    },
    updateItemAdditionalPropertiesJewelleryCoins(state, action) {
      state.itemAdditionalPropertiesJewelleryCoins = action.payload || {};
    },
    updateItemImage(state, action) {
      state.itemImage = action.payload;
    },
    setImageCaptured(state, action) {
      state.isImageCaptured = action.payload;
    },
    updateServiceType(state, action) {
      state.serviceType = action.payload;
    },
    updatePledgeServiceId(state, action) {
      state.pledgeServiceId = action.payload;
    },
    updatePurchaseServiceId(state, action) {
      state.purchaseServiceId = action.payload;
    },
    clearServiceIds(state) {
      state.pledgeServiceId = '';
      state.purchaseServiceId = '';
    },
    setPawnbrokingSummary(state, action) {
      state.pawnbrokingSummary = action.payload;
    },
    setNoAgreementSelection(state, action) {
      state.noAgreementSelection = action.payload;
    },
    setHistoricalItems(state, action) {
      state.historicalItems = action.payload;
    },
    updateHistoricalItems(state, action) {
      state.historicalItems = state.historicalItems.map((data, i) =>
        data.id === action.payload.id ? { ...data, ...action.payload } : data
      );
    },
    setSelectedHistoricalItem(state, action) {
      state.selectedHistoricalItem = action.payload;
    },
    setIsImageUploaded(state, action) {
      state.isImageUploaded = action.payload;
    },
    initImageUpload(state, action) {
      state.isImageUploading = action.payload;
    },
    setItemImageCollectionResponse(state, action) {
      const parentImage = action.payload?.images?.find(
        (obj: any) => obj.isDefault === true
      );

      state.parentImageUrl = parentImage
        ? `/api/items/image/${parentImage.containerId}/${parentImage.blobId}?isThumnails=false`
        : '';
      state.itemImageCollectionResponse = action.payload || {};
    },
    setIsItemFromHistory(state, action) {
      state.isItemFromHistory = action.payload;
    },
    setInterestRateCounter(state, action) {
      state.interestRateCounter = action.payload;
    },

    clearItems(state) {
      return {
        ...state,
        pledgeItemsValue: {},
        itemCategory: {},
        subItemCategory: {},
        itemDescription: '',
        metalPrice: {},
        watches: [],
        handbags: [],
        itemAdditionalProperties: [],
        itemAdditionalPropertiesJewelleryCoins: {},
        itemImage: '',
        error: {},
        pawnbrokingSummary: [],
        noAgreementSelection: [],
        parentImageUrl: '',
        itemImageCollectionResponse: {},
        checklists: [],
        historicalItems: [],
        isItemFromHistory: false,
      };
    },
  },
});

export default slice.reducer;
export const { reducer, actions: pledgeActions } = slice;
export { initialState };

export { default as coin } from './coin';
export { default as stone } from './stone';
