import React from 'react';
import { Button } from 'components/common/Button';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

type VerifyResultProps = {
  verified: boolean;
};

const VerifyResult = (props: VerifyResultProps) => {
  const { verified } = props;
  if (verified) {
    return <Verified />;
  } else {
    return <Unverified />;
  }
};

const Verified = () => (
  <span
    style={{
      color: 'var(--mountain-meadow)',
      display: 'block',
      marginTop: '34px',
    }}
  >
    <CheckCircleFilled />
    <span style={{ padding: '0 8px' }}>Verified</span>
  </span>
);

const Unverified = () => (
  <span style={{ color: 'var(--red)', display: 'block', marginTop: '34px' }}>
    <CloseCircleFilled />
    <span style={{ padding: '0 8px' }}>Unverified address</span>
  </span>
);

const CustomerValidation = ({ onButtonClick = () => { /* do nothing */ } }: any) => {
  return (
    <Button
      style={{ marginTop: '24px' }}
      type="primary"
      itemProp="secondary"
      htmlType="button"
      onClick={onButtonClick}
      loading={false}
    >
      Call Validate
    </Button>
  );
};

export { CustomerValidation, VerifyResult };
