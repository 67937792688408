import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { format } from 'date-fns';
import { FNS_DATE_FORMAT, GRID_NO_URL } from 'globalConstants';
import styled from 'styled-components';
import { currencyFormat, getPledgeIcons } from 'utils/util';
import { Row, Spin } from 'antd';
import { GET_PAID_PLEDGE_DETAILS } from 'action_creators/pickupRenew';

import VirtualGrid from 'components/common/virtualGrid';
import ItemDetailsPopup from '../itemsTable';
import WaiveInterestPopup from '../waiveInterestPopup';
import PaidPaymentDetailsPopup from 'components/pickupRenew/PaidPaymentDetailsPopup';
import IconDescription from 'components/customer/customerSummary/pledge/iconDescription';

import { actions as consumerDutyActions } from 'redux/reducers/consumerDuty';
import { TASK_OWNER_VALUE } from 'components/consumerDuty/constants';

const StyledGridWrapper = styled.div`
  height: calc(65vh - 10px);
`;

const StyledHyperlink = styled.button`
  font-weight: var(--font-weight-500);
  color: var(--blue);
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const StyledRow = styled(Row)`
  border-top: 1px solid var(--light-gray);
`;

const ServiceManagerTable = ({ loading, pledgeSummary }: any) => {
  const {
    consumerDuty: { selectedLoanIds, selectedTaskOwner },
    customer: { customerStatusColor },
  } = useSelector((state: any) => {
    return { consumerDuty: state.consumerDuty, customer: state.customer };
  });
  const [hiddenColumns, setHiddenColumns] = useState<Array<any>>([]);

  useEffect(() => {
    if (selectedTaskOwner === TASK_OWNER_VALUE.SSTEAM) {
      setHiddenColumns(['lastReset']);
    }
  }, [selectedTaskOwner]); // eslint-disable-line react-hooks/exhaustive-deps

  const [columns] = useState([
    {
      name: 'loanStatusId',
      title: 'Status',
      type: 'icon',
      displayContent: false,
      isMultipleIcons: true, // when isMultipleIcons = true , send an array of icon values
      isIconClickable: false,
      getCellValue: (row: any) => getPledgeIcons(row, customerStatusColor),
    },
    {
      name: 'agreementNumber',
      title: 'Agreement',
      getCellValue: (row: any) => (
        <StyledHyperlink type="button" onClick={() => onAgreementClick(row)}>
          {`${row?.agreementNumber}`}
        </StyledHyperlink>
      ),
    },
    {
      name: 'startDate',
      title: 'Start Date',
      getCellValue: (row: any) =>
        row && row?.startDate
          ? format(new Date(row?.startDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'dueDate',
      title: 'Due Date',
      getCellValue: (row: any) =>
        row && row?.dueDate
          ? format(new Date(row?.dueDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'location',
      title: 'Location',
      getCellValue: (row: any) => row?.location,
    },
    {
      name: 'loanAmount',
      title: 'Loan Amount',
      getCellValue: (row: any) => currencyFormat(row?.loanAmount, true),
    },
    {
      name: 'amountDueToday',
      title: 'Pickup Today',
      getCellValue: (row: any) =>
        `${currencyFormat(row?.amountDueToday, true)}`,
    },
    {
      name: 'renewToday',
      title: 'Renew Today',
      getCellValue: (row: any) =>
        `${currencyFormat(row?.amountDueToday - row?.loanAmount, true)}`,
    },
    {
      name: 'interest6Months',
      title: '6 Months',
      getCellValue: (row: any) =>
        `${currencyFormat(row?.interest6Months, true)}`,
    },
    {
      name: 'days',
      title: 'Days',
      getCellValue: (row: any) => row?.days,
    },
    {
      name: 'paidAmount',
      title: 'Paid',
      getCellValue: (row: any) => (
        <StyledHyperlink type="button" onClick={() => onPaidPaymentClick(row)}>
          {`${currencyFormat(row?.paidAmount, true)}`}
        </StyledHyperlink>
      ),
    },
    {
      name: 'waived',
      title: 'Waived',
      getCellValue: (row: any) => (
        <StyledHyperlink
          onClick={() => onWaviedInterestClick(row)}
        >{`${currencyFormat(row?.waived, true)}`}</StyledHyperlink>
      ),
    },
    {
      name: 'numberOfRenewals',
      title: 'Renewal(s)',
      getCellValue: (row: any) => row?.numberOfRenewals,
    },
    {
      name: 'interestPaidTillDate',
      title: 'Interest Paid',
      getCellValue: (row: any) =>
        currencyFormat(row?.interestPaidTillDate, true),
    },
    {
      name: 'overPayment',
      title: 'Over Payment (Loan + Months)',
      getCellValue: (row: any) => currencyFormat(row?.overPayment, true),
    },
    {
      name: 'waiveUntil',
      title: 'Waive Until',
      getCellValue: (row: any) =>
        row && row?.dueDate
          ? format(new Date(row?.dueDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'lastReset',
      title: 'Last Reset',
      getCellValue: (row: any) =>
        row && row?.lastResetDate
          ? format(new Date(row?.lastResetDate), FNS_DATE_FORMAT)
          : null,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'loanStatusId', width: '4%' },
    { columnName: 'agreementNumber', width: '6%' },
    { columnName: 'startDate', width: '6%' },
    { columnName: 'dueDate', width: '6%' },
    { columnName: 'location', width: '10%' },
    { columnName: 'loanAmount', width: '6%', align: 'right' },
    {
      columnName: 'amountDueToday',
      width: '5%',
      align: 'right',
      wordWrapEnabled: true,
    },
    {
      columnName: 'renewToday',
      width: '5%',
      align: 'right',
      wordWrapEnabled: true,
    },
    { columnName: 'interest6Months', width: '5%', align: 'right' },
    { columnName: 'days', width: '4%', align: 'right' },
    { columnName: 'paidAmount', width: '4%', align: 'right' },
    { columnName: 'waived', width: '5%', align: 'right' },
    {
      columnName: 'numberOfRenewals',
      width: '6%',
      align: 'right',
    },
    {
      columnName: 'interestPaidTillDate',
      width: '6%',
      align: 'right',
      wordWrapEnabled: true,
    },
    {
      columnName: 'overPayment',
      width: '7%',
      align: 'right',
      wordWrapEnabled: true,
    },
    { columnName: 'waiveUntil', width: '6%' },
    { columnName: 'lastReset', width: '5%' },
  ]);

  const [formattedColumns] = useState(['loanStatusId']);

  const [selectedRow, setSelectedRow] = useState<object>({});
  const [isItemDetailsPopupVisible, setIsItemDetailsPopupVisible] =
    useState<boolean>(false);
  const [isPaidPaymentPopupVisible, setPaidPaymentPopupVisible] =
    useState<boolean>(false);
  const [isWaivedInterestPopupVisible, setIsWaivedInterestPopupVisible] =
    useState<boolean>(false);

  const dispatch = useDispatch();

  const onSelectionChange = (ids: Array<number>) => {
    dispatch(consumerDutyActions.setSelectedLoanIds(ids));
  };

  const onPaidPaymentClick = (row: any) => {
    const params = {
      loanId: row?.loanId,
    };
    setPaidPaymentPopupVisible(true);
    dispatch({
      type: GET_PAID_PLEDGE_DETAILS,
      payload: { params },
    });
  };

  const onWaviedInterestClick = (row: any) => {
    setSelectedRow(row);
    setIsWaivedInterestPopupVisible(true);
  };

  const onAgreementClick = (row: any) => {
    setSelectedRow(row);
    setIsItemDetailsPopupVisible(true);
  };

  return (
    <>
      <Spin spinning={loading}>
        <StyledGridWrapper>
          <VirtualGrid
            urlType={GRID_NO_URL}
            columnsProps={columns}
            tableColumnExtensionsProps={tableColumnExtensions}
            rowData={pledgeSummary} //rows
            formattedColumns={formattedColumns}
            rowSelectionEnable={true}
            highlightRow={true}
            selection={selectedLoanIds}
            onSelectionChange={onSelectionChange}
            hiddenColumnNames={hiddenColumns}
          />
        </StyledGridWrapper>
      </Spin>

      <StyledRow>
        <IconDescription pledgeSummary={pledgeSummary} />
      </StyledRow>

      {isItemDetailsPopupVisible && (
        <ItemDetailsPopup
          open={isItemDetailsPopupVisible}
          setItemDetailsPopupVisible={() => setIsItemDetailsPopupVisible(false)}
          selectedRow={selectedRow}
        />
      )}

      {isPaidPaymentPopupVisible && (
        <PaidPaymentDetailsPopup
          visible={isPaidPaymentPopupVisible}
          setPaidPaymentPopupVisible={() => setPaidPaymentPopupVisible(false)}
        />
      )}

      {isWaivedInterestPopupVisible && (
        <WaiveInterestPopup
          open={isWaivedInterestPopupVisible}
          loanDetails={selectedRow}
          taskOwner={selectedTaskOwner}
          setWaiveInterestPopupVisible={() =>
            setIsWaivedInterestPopupVisible(false)
          }
        />
      )}
    </>
  );
};

export default ServiceManagerTable;
