import React from 'react';
import styled from 'styled-components';
import { Tabs } from 'antd';
import { isEmpty } from 'lodash';

import TabPane from 'antd/lib/tabs/TabPane';

const StyledTab = styled(Tabs)`
  &.custom-card-tabs {
    & .ant-tabs-nav {
      padding: 0 10px;
      margin-bottom: 0px;
      &::before {
        border-bottom: 1px solid var(--ink-blue);
      }

      & .ant-tabs-nav-wrap {
        & .ant-tabs-nav-list {
          & .ant-tabs-tab {
            width: 150px;
            height: 50px;
            color: var(--black);
            line-height: 25px;
            margin: 0 15px 0 10px;
            font-weight: var(--font-weight-500);

            border-radius: 5px 5px 0px 0px;
            border: 1px solid var(--ink-blue);

            background: var(--white);
            justify-content: center;

            &.ant-tabs-tab-active {
              background: var(--blue);

              & .ant-tabs-tab-btn {
                color: var(--white) !important;
              }
            }
          }
        }
      }
    }
  }
`;

interface CardTabsProps {
  activeKey?: string;
  onMenuClick: (clickedMenu: string) => void;
  tabslist: Array<any>;
  recordCount?: any;
  className?: any;
}

const CardTabs = ({
  onMenuClick = () => { /* do nothing */ },
  activeKey,
  tabslist = [],
  recordCount = [],
  className = '',
}: CardTabsProps) => {
  const handleTabChange = (event: any) => {
    //event is the index of selected tab
    onMenuClick(event);
  };

  const renderTab = (tabProps: any) => {
    const key = tabProps.key;
    const liveCount =
      !isEmpty(recordCount) && recordCount[key] && recordCount[key] > 0
        ? `(${recordCount[key]})`
        : '';

    return (
      <TabPane
        id={key}
        key={key}
        tab={`${tabProps.title} ${liveCount}`}
        active={activeKey === key}
      />
    );
  };

  return (
    <>
      <StyledTab
        activeKey={activeKey}
        type="card"
        className={`custom-card-tabs ${className}`}
        onTabClick={handleTabChange}
      >
        {tabslist.map((tab) => renderTab(tab))}
      </StyledTab>
    </>
  );
};

export default CardTabs;
