import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/reducers/customer';
import { ROUTE_CONFIG } from 'globalConstants';
import { Button } from 'components/common/Button';
import { actions as pickupActions } from 'redux/reducers/pickupRenew';
import {
  getPledgeAgreementItems,
  searchPledgeAgreements,
} from 'services/pledge';

import { PLEDGE_NOTICE_TYPE } from 'components/pledgemanagement/constants';
import { actions as pledgeMngmtActions } from 'redux/reducers/pledgeManagement';
import { actions as consumerDutyActions } from 'redux/reducers/consumerDuty';
import {
  BUTTONS_LABEL,
  ESCALATED_TO_SSTEAM_SUCCESS_MSG,
  ESCALATED_TO_SSTEAM_SUCCESS_TITLE,
  STORE_MANAGED_SUCCESS_MSG,
  STORE_MANGEED_SUCCESS_TITLE,
} from 'components/consumerDuty/constants';

import CustomerSupportModal from 'components/consumerDuty/modals/customerSupportModal';
import ReleaseAccountModal from 'components/consumerDuty/modals/releaseAccountModal';
import CaseUpdateModal from 'components/consumerDuty/modals/caseUpdateModal';
import ControlledAlert from 'components/common/ControlledAlert';
import { PROFILE_STATUS } from '../constants';

const StyledRightBottomRow = styled(Row)`
  flex-wrap: nowrap;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const StyledButtonRow = styled(Row)`
  padding: 12px 15px;
`;

const StyledHr = styled.hr`
  border: 0;
  height: 1px;
  margin: 0;
  background-color: var(--linkwater);
`;

const StyledButton = styled(Button)`
  margin-inline-start: 0px;
  margin: 0 15px;
`;

interface CustomerBottomProps {
  buttonsOption?: boolean;
  isCustFromHeader?: boolean;
  resumeJourneyUrl?: string;
  profileStatus?: string;
}

const CustomerBottom = ({
  buttonsOption = false,
  isCustFromHeader = false,
  resumeJourneyUrl = '/',
  profileStatus,
}: CustomerBottomProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isCustomerSupportModalVisible, setIsCustomerSupportModalVisible] =
    useState<boolean>(false);
  const [isReleaseAccountModalVisible, setIsReleaseAccountModalVisible] =
    useState<boolean>(false);
  const [isCaseUpdateModalVisible, setIsCaseUpdateModalVisible] =
    useState<boolean>(false);

  const {
    pawnbroking: { noAgreementSelection },
    pledgeManagement: { isNoticeButtonEnable },
    consumerDuty: {
      isSupportRequestRefPopupVisible,
      isSSTeamRequestRefPopupVisible,
      ssteamRequestTaskId,
    },
    customer: { isStoreEnquiryMode, isPhoneEnquiryMode },
  } = useSelector((state: any) => {
    return {
      pawnbroking: state.pawnbroking,
      pledgeManagement: state.pledgeManagement,
      consumerDuty: state.consumerDuty,
      customer: state.customer,
    };
  });

  const onExitClick = () => {
    dispatch(actions.clearAll({}));
    navigate('/');
  };
  const onBackToJourney = () => {
    dispatch(
      actions.setCustomerFromHeader({
        isCustFromHeader: false,
      })
    );
    navigate(resumeJourneyUrl);
  };

  const onNoAgreementClick = async () => {
    try {
      setLoading(true);
      const response = await searchPledgeAgreements(noAgreementSelection);
      if (response?.data) {
        const promises = response?.data?.map(async (item: any) => {
          let updatedItem = item;
          updatedItem = {
            ...item,
            affidavitAmount:
              isStoreEnquiryMode || isPhoneEnquiryMode
                ? 0
                : item?.affidavitAmount, //as per EVB-625, changing affidavitAmount as 0 for enquiry mode
            pledgeItems: [],
            customerPresent: true,
          };
          try {
            const itemsResponse = await getPledgeAgreementItems(
              item?.originalLoanId
            );
            if (itemsResponse?.data) {
              updatedItem = {
                ...updatedItem,
                pledgeItems: itemsResponse?.data?.items || [],
              };
              return updatedItem;
            }
          } catch (e: any) {
            setLoading(false);
            return updatedItem;
          }
        });

        const updatedData = await Promise.all(promises);
        dispatch(pickupActions.updateNoAgreements(updatedData));
        setLoading(false);
        navigate(`/${ROUTE_CONFIG.PICKUPRENEW}`);
      }
    } catch (e: any) {
      setLoading(false);
      console.log(e);
    }
  };

  const onPledgeAgreementActionClick = (actionType: string) => {
    dispatch(pledgeMngmtActions.setIsPledgeNoticePopupVisible(true));
    dispatch(pledgeMngmtActions.setPledgeNoticeType(actionType));
  };

  useEffect(() => {
    dispatch(pledgeMngmtActions.setIsNoticeButtonEnable(false));
  }, [dispatch]);

  const onCustomerSupportBtnClick = () => {
    if (profileStatus && profileStatus === PROFILE_STATUS.BLACK) {
      setIsCaseUpdateModalVisible(true);
    } else if (profileStatus) setIsCustomerSupportModalVisible(true);
  };

  const onReleaseAccountBtnClick = () => {
    setIsReleaseAccountModalVisible(true);
  };

  const onOKClick = () => {
    dispatch(consumerDutyActions.setIsSupportRequestRefPopupVisible(false));
    dispatch(consumerDutyActions.setIsSSTeamRequestRefPopupVisible(false));
  };

  return (
    <>
      <StyledRightBottomRow>
        <Col flex={1}>
          <StyledHr />
          <StyledButtonRow
            align="bottom"
            justify={buttonsOption ? 'space-between' : 'end'}
          >
            {buttonsOption && (
              <>
                <StyledButton
                  itemProp="secondary"
                  onClick={isCustFromHeader ? onBackToJourney : onExitClick}
                >
                  {isCustFromHeader ? 'Back to Journey' : 'Exit'}
                </StyledButton>

                {profileStatus === 'purple' && (
                  <StyledButton
                    itemProp="secondary"
                    onClick={onReleaseAccountBtnClick}
                  >
                    {BUTTONS_LABEL.RELEASE_ACC}
                  </StyledButton>
                )}

                <StyledButton
                  itemProp="secondary"
                  onClick={onCustomerSupportBtnClick}
                >
                  {BUTTONS_LABEL.CUST_SUPPORT}
                </StyledButton>
              </>
            )}
            {/* Commenting as per Jira CDA-276 - Consumer Duty - Store End - Hide/Turn off current Vulnerable status
            {!buttonsOption && (
              <StyledButton
                itemProp="secondary"
                onClick={() =>
                  onPledgeAgreementActionClick(PLEDGE_NOTICE_TYPE.VULNERABLE)
                }
                disabled={!isNoticeButtonEnable}
              >
                Vulnerable
              </StyledButton>
            )} */}
            {!buttonsOption && (
              <StyledButton
                itemProp="secondary"
                onClick={() =>
                  onPledgeAgreementActionClick(PLEDGE_NOTICE_TYPE.CONFISCATE)
                }
                disabled={!isNoticeButtonEnable}
              >
                Confiscate
              </StyledButton>
            )}
            {!buttonsOption && (
              <StyledButton
                itemProp="secondary"
                onClick={onNoAgreementClick}
                loading={isLoading}
              >
                No Agreement View
              </StyledButton>
            )}
            {!buttonsOption && (
              <StyledButton itemProp="secondary">Statement</StyledButton>
            )}
          </StyledButtonRow>
        </Col>
      </StyledRightBottomRow>

      {isCustomerSupportModalVisible && (
        <CustomerSupportModal
          visible={isCustomerSupportModalVisible}
          onClose={() => setIsCustomerSupportModalVisible(false)}
        />
      )}

      {isReleaseAccountModalVisible && (
        <ReleaseAccountModal
          visible={isReleaseAccountModalVisible}
          onClose={() => setIsReleaseAccountModalVisible(false)}
        />
      )}

      {isCaseUpdateModalVisible && (
        <CaseUpdateModal
          visible={isCaseUpdateModalVisible}
          onClose={() => setIsCaseUpdateModalVisible(false)}
        />
      )}

      {isSupportRequestRefPopupVisible && (
        <ControlledAlert
          width={550}
          isCancelBtn={false}
          visible={isSupportRequestRefPopupVisible}
          alertMessage={STORE_MANGEED_SUCCESS_TITLE}
          message={STORE_MANAGED_SUCCESS_MSG}
          onClick={onOKClick}
          yesButtonText={BUTTONS_LABEL.OK}
          alertMessageStyle={{ color: `var(--black)`, padding: '20px 0' }}
        />
      )}
      {isSSTeamRequestRefPopupVisible && (
        <ControlledAlert
          width={550}
          isCancelBtn={false}
          visible={isSSTeamRequestRefPopupVisible}
          alertMessage={ESCALATED_TO_SSTEAM_SUCCESS_TITLE}
          message={ESCALATED_TO_SSTEAM_SUCCESS_MSG}
          secondaryMessage={`Reference: ${ssteamRequestTaskId}`}
          secondaryMsgClassName="message-bold"
          onClick={onOKClick}
          yesButtonText={BUTTONS_LABEL.OK}
          alertMessageStyle={{ color: `var(--black)`, padding: '20px 0' }}
        />
      )}
    </>
  );
};

export default CustomerBottom;
