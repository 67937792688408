import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Modal, Row, Col, notification } from 'antd';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import { GDPR_INFO } from './constants';
import { ROUTE_CONFIG, SERVICE_TYPE } from 'globalConstants';
import { useDispatch } from 'react-redux';
import { actions } from 'redux/reducers/customer';
import { sendToPrinter } from 'services/user';
import { privacyNotice } from 'services/basket';

const StyledModal = styled(Modal)`
  font-size: var(--font-size-16);
  & .ant-modal-header {
    padding: 27px 30px;
    text-align: center;
    background: var(--dark-blue);
    & .ant-modal-title {
      color: var(--white);
    }
  }
  & .ant-modal-body {
    text-align: center;
  }
`;

const StyledColOne = styled(Col)`
  font-weight: var(--font-weight-700);
  padding-bottom: 10px;
`;

const StyledColTwo = styled(Col)`
  padding: 10px;
`;

const StyledColThree = styled(Col)`
  padding-top: 10px;
  font-weight: var(--font-weight-700);
`;

const DeclineButton = styled(Button)`
  background: var(--red);
  margin-right: 20px;
  &:hover,
  &:focus {
    background: var(--red);
  }
`;

const ProceedButton = styled(Button)`
  background: var(--mountain-meadow);
  &:hover,
  &:focus {
    background: var(--mountain-meadow);
  }
`;

interface GdprProps {
  visible: boolean;
  setGdprMessageVisible: () => void;
  setGdprDeclineVisible: (visible: boolean) => void;
  serviceType?: string;
}

const GdprMessagePopup = ({
  visible,
  setGdprMessageVisible,
  setGdprDeclineVisible,
  serviceType = '',
}: GdprProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const onProceedClick = () => {
    if (serviceType)
      dispatch(
        actions.setCustomerFromHeader({
          isCustFromHeader: true,
          resumeJourneyUrl: location.pathname,
        })
      );

    if (serviceType !== SERVICE_TYPE.LITE_CUSTOMER)
      navigate(`/${ROUTE_CONFIG.CUSTOMER}/${ROUTE_CONFIG.NEW}`);

    setGdprMessageVisible();
    dispatch(actions.setGdprAcceptance(true));

    if (serviceType === SERVICE_TYPE.RETAIL) {
      dispatch(actions.setNewCustomerGenerateFromFinder(true));
    }

    if (serviceType && serviceType !== SERVICE_TYPE.LITE_CUSTOMER)
      dispatch(actions.setRedirectToOtherPage(false));
  };

  const onDeclineClick = () => {
    setGdprMessageVisible();
    setGdprDeclineVisible(true);
  };

  const onPrintClick = async () => {
    //Print Privacy Notice
    try {
      const pdf = await privacyNotice();
      if (pdf?.data) {
        sendToPrinter(pdf, 'printcontract', '_PrivacyNotice');
      }
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.error,
        duration: 3,
      });
    }
  };

  return (
    <StyledModal
      title="GDPR Message"
      open={visible}
      closable={false}
      width={655}
      onOk={onProceedClick}
      onCancel={onDeclineClick}
      centered
      maskClosable={false}
      footer={[
        <Button
          itemProp="secondary"
          key="privacyNotice"
          style={{ float: 'left' }}
          onClick={onPrintClick}
        >
          {GDPR_INFO.PRINT_PRIVACY_NOTICE}
        </Button>,
        <DeclineButton key="back" onClick={onDeclineClick}>
          {GDPR_INFO.DECLINE}
        </DeclineButton>,
        <ProceedButton key="submit" onClick={onProceedClick}>
          {GDPR_INFO.PROCEED}
        </ProceedButton>,
      ]}
    >
      <Row>
        <StyledColOne span={24}>{GDPR_INFO.GDPR_MESSAGE.LINE_ONE}</StyledColOne>
      </Row>
      <Row>
        <StyledColTwo span={24}>{GDPR_INFO.GDPR_MESSAGE.LINE_TWO}</StyledColTwo>
      </Row>
      <Row>
        <StyledColThree span={24}>
          {GDPR_INFO.GDPR_MESSAGE.LINE_THREE}
        </StyledColThree>
      </Row>
    </StyledModal>
  );
};

export default GdprMessagePopup;
