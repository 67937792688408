import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { currencyFormat, calculateTotalSumByKey } from 'utils/util';
import { PURCHASE_RETURN_DETAILS } from './constants';

const StyledTableRow = styled(Row)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

interface PurchaseReturnCostTableProps {
  allPurchases: Array<any>;
}

const LoanRow = (label: string, value: number) => {
  return (
    <tr>
      <td>{label}</td>
      <td style={{ width: '40%' }}>{`£${currencyFormat(value.toFixed(2))}`}</td>
    </tr>
  );
};

const PurchaseReturnCostTable = ({
  allPurchases,
}: PurchaseReturnCostTableProps) => {
  return (
    <>
      <StyledTableRow>
        <Col span={24}>
          <table className="pledge-details-table">
            <tbody>
              {LoanRow(
                PURCHASE_RETURN_DETAILS.COST_TO_PICKUP_SELECTED,
                calculateTotalSumByKey(allPurchases, 'toTakeAmount')
              )}
              {LoanRow(
                PURCHASE_RETURN_DETAILS.COST_TO_PICKUP_ALL,
                calculateTotalSumByKey(allPurchases, 'totalAmount')
              )}
            </tbody>
          </table>
        </Col>
      </StyledTableRow>
    </>
  );
};

export default PurchaseReturnCostTable;
