import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';
import { format } from 'date-fns';
import VirtualGrid from 'components/common/virtualGrid/index';
import ImageLoader from 'components/common/ImageLoader';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import AuctionDetailsExpandableTable from './auctionDetailsExpandableTable';
import { PASS_AUCTION_LOT } from 'action_creators/pledgeManagement';

const StyledSpan = styled.span`
  text-align: center;
  padding: 0 5px 0 5px;
`;

const StyledButton = styled(Button)`
  margin: 0 15px;
  position: inherit;
`;

interface LiveAtStoreSummaryProps {
  auctionLoans: Array<any>;
  auctionId: number;
}

const LiveAtStoreSummary = ({
  auctionLoans = [],
  auctionId,
}: LiveAtStoreSummaryProps) => {
  const [columns] = useState([
    {
      name: 'customerName',
      title: 'Customer Name',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <>
          <ImageLoader
            customerId={row?.customerId}
            imageUrl={
              row?.customerId &&
              `/api/customers/images/${row?.customerId}?isThumnails=false`
            }
            classes={`mx-small-avatar mx-small-avatar-yellow`}
            borderRadius={'50%'}
            fallbackImg={'customer-pic.svg'}
            preview
          />
          <StyledSpan>{row?.customerName}</StyledSpan>
        </>
      ),
    },
    {
      name: 'lotNumber',
      title: 'Lot No.',
      getCellValue: (row: any) => row?.lotNumber,
    },
    {
      name: 'loanNumber',
      title: 'Agreement',
      getCellValue: (row: any) => row?.loanNumber,
    },
    {
      name: 'loanValue',
      title: 'Loan Value',
      getCellValue: (row: any) => row?.loanValue,
    },
    {
      name: 'dueDate',
      title: 'Due Date',
      getCellValue: (row: any) =>
        row && row?.dueDate
          ? format(new Date(row?.dueDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'items',
      title: 'Items',
      getCellValue: (row: any) => row?.items,
    },
    {
      name: 'letterDue',
      title: 'Letter Due',
      getCellValue: (row: any) =>
        row && row?.letterDue
          ? format(new Date(row?.letterDue), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'letterSent',
      title: 'Letter Sent',
      getCellValue: (row: any) =>
        row && row?.letterSent
          ? format(new Date(row?.letterSent), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'reserveValue',
      title: 'Reserve Value',
      getCellValue: (row: any) => row?.reserveValue,
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row: any) => row?.status,
    },
    {
      name: '',
      title: '',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <StyledButton
          itemProp="secondary"
          disabled={row?.status === 'Pass'}
          onClick={() => onPassLoanClick(auctionId, row?.auctionDetailId)}
        >
          {' Pass Loan'}
        </StyledButton>
      ),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'customerName', width: '16%' },
    { columnName: 'lotNumber', width: '8%' },
    { columnName: 'loanNumber', width: '8%' },
    { columnName: 'loanValue', width: '8%' },
    { columnName: 'dueDate', width: '8%' },
    { columnName: 'items', width: '6%' },
    { columnName: 'letterDue', width: '8%' },
    { columnName: 'letterSent', width: '8%' },
    { columnName: 'reserveValue', width: '8%' },
    { columnName: 'status', width: '7%' },
    { columnName: '', width: '10%' },
  ]);

  const getRowId = (row: any) => row?.loanId;
  const dispatch = useDispatch();

  const onPassLoanClick = (auctionId: number, auctionDetailId: number) => {
    dispatch({
      type: PASS_AUCTION_LOT,
      payload: {
        auctionId: auctionId,
        auctionDetailId: auctionDetailId,
      },
    });
  };

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={auctionLoans}
        expandableComponent={AuctionDetailsExpandableTable}
        getRowId={getRowId}
      />
    </>
  );
};

export default LiveAtStoreSummary;
