import {
  watchAcceptStockTransferSaga,
  watchDeleteTransferItemsSaga,
  watchGetStoresSaga,
  watchGetTransferItemsSaga,
  watchSearchTransferSaga,
  watchSendStockTransferSaga,
  watchStockTransferAuthorizationSaga,
} from './inventoryManagement';
import {
  watchAffidavitReasonSaga,
  watchPaidPaymentSaga,
  watchPledgePickupAmountSaga,
  watchPrintContractsSaga,
  watchPurchaseReturnAmountSaga,
} from './pickupRenew';
import {
  watchBasket,
  watchBasketByBasketId,
  watchCalculatePledgeLoanBasket,
  watchCreateChequeCashingBasket,
  watchCreatePickupRenewBasket,
  watchCreatePledgeBasket,
  watchCreatePledgeOrPurchaseReturnBasket,
  watchCreateRetailBasket,
  watchDeleteBasket,
  watchDeleteBasketService,
  watchDeleteChequeChashingBasket,
  watchDeleteFxTransactionSaga,
  watchDeletePickupRenewBasket,
  watchDeletePledgeBasket,
  watchDeletePurchaseReturnBasket,
  watchDeleteRetailBasket,
  watchDeleteSurplusBasketSaga,
  watchDeleteWesternUnionBasket,
  watchEditChequeCashingBasket,
  watchEditPledgeBasket,
  watchEditPledgeItemsForReducer,
  watchEditRetailBasket,
  watchMergeBasketsSaga,
  watchMergeGuestBasket,
  watchMergePledgeBasket,
  watchMovePledgeItemsBasket,
  watchMovePledgePurchaseItemsBasket,
  watchPostWesternUnionSaga,
  watchSplitPledgeBasket,
  watchSurplusBasketSaga,
  watchTenItemsInBasket,
  watchUpdateCustomerDetailsBasketSaga,
} from './basket/index';
import { watchCheckoutProgress, watchGetCheckout } from './checkout/index';
import {
  watchCreateLiteCustomerProfileSaga,
  watchCustomerProfileSave,
  watchGetCustomerLastVisitsSaga,
  watchGetCustomerProfile,
  watchGetCustomerProfileInBackground,
  watchUpdateTravelMoneyGuestSaga,
  watchUploadCustomerDocument,
  watchUploadCustomerImage,
  watchUploadLiteCustomerDocumentsSaga,
  watchUploadTMGuestDocumentsSaga,
  watchGetCustomerProfileStatusSaga,
  watchUploadTpaImageSaga,
} from './customer/index';
import {
  watchCreateTravelMoneyRatesSaga,
  watchGetDefaultPermissionsSaga,
  watchGetGroupPermissionsSaga,
  watchGetStoreUsersSaga,
  watchGetTravelMoneyRatesHistorySaga,
  watchGetTravelMoneyRatesSaga,
  watchGetUserDetailsSaga,
  watchGetUserPermissionsSaga,
  watchGetVoidTransactionsSaga,
  watchPostSaveUserPermissionsSaga,
  watchUpdateUserDetailsSaga,
  watchVoidTransactionAuthorizationSaga,
} from './admin';
import {
  watchCustomerPawnbrokingSummary,
  watchGetItemPriceValuation,
  watchGetLoanPayable,
  watchUploadMultipleImageSaga,
} from './pledge/index';
import {
  watchFindRetailItems,
  watchFindSoldItems,
  watchGetRetailItems,
} from './retail/index';
import {
  watchGetAuctionDetailsSaga,
  watchGetAuctionsOverSaga,
  watchGetConfirmAuctionSettlementSaga,
  watchGetGenerateLotStickerSaga,
  watchGetItemPriceValuationSaga,
  watchGetNotifyAuctionLockingSaga,
  watchGetParentItemIdSaga,
  watchGetPfiUnderSaga,
  watchGetPledgeItemsDetailsSaga,
  watchGetPledgeItemsListSaga,
  watchGetPledgeLoanDetailsSaga,
  watchGetPrintPassListSaga,
  watchGetPurchaseLoanDetailsSaga,
  watchGetVulnerableNotesSaga,
  watchGetVulnerablePledgesSaga,
  watchPledgeConfiscationSaga,
  watchPledgeVulnerableSaga,
  watchPostAddVulnerableNoteSaga,
  watchPostPfiBarcodeSaga,
  watchPostPledgeToPfiSaga,
  watchPostPurchaseToPfiSaga,
  watchPostRemoveVulnerableNoteSaga,
  watchPostReviewItemsPfaSaga,
  watchPutConfirmCollectionSaga,
  watchPutLockAuctionSaga,
  watchPutPassAuctionLotSaga,
  watchPutPfaLotNumberSaga,
  watchPutSettleAuctionSaga,
  watchPutUpdateSoldValueSaga,
  watchSearchAuctionSaga,
} from './pledgeManagement';
import {
  watchGetBuybackChargesSaga,
  watchGetBuybackHistorySaga,
  watchGetCurrenciesSaga,
  watchLedgerBalanceCurrencySaga,
  watchPostConvertCurrencySaga,
  watchPostFxTransactionSaga,
  watchPostUpDownCurrencySaga,
  watchStockBalanceCurrencySaga,
} from './travelmoney';
import {
  watchGetCurrencyListSaga,
  watchGetDestinationStoreListSaga,
  watchGetStoreTillHistorySaga,
} from './cashManagement';
import {
  watchGetNotificationItemDetails,
  watchGetValuationItemDetails,
  watchPostValuationActions,
} from './valuationCenter';

import {
  watchEditChequeCashingSaga,
  watchPostPreAuthentication,
  watchUploadChequeCashingDocumentSaga,
} from './chequeCashing/index';

import {
  watchValidateCreatedServicesBasket,
  watchValidateFxServiceBasket,
  watchValidateLoanActionsSaga,
  watchConsumerDutyValidationSaga,
  watchValidatePickupServiceBasket,
  watchValidatePledgeServiceBasket,
  watchValidatePurchaseReturnServiceBasket,
  watchValidatePurchaseServiceBasket,
  watchValidateRetailServiceBasket,
  watchValidateSurplusServiceBasket,
  watchValidateTpcServiceBasket,
  watchValidateUserTillBalance,
  watchValidateWUServiceBasket,
} from './basket/validation';

import { spawn } from 'redux-saga/effects';
import { watchLoanServiceBasket } from './basket/loancalulator';
import {
  watchPrintCocmCertificateSaga,
  watchPrintAffidavitSaga,
  watchPrintCustomerMandateSaga,
} from './accessories';
import { watchUploadWesternUnionDocumentSaga } from './westernUnion';

import {
  watchProcessConsumerDutySaga,
  watchResetCustomerDataSaga,
  watchGetCaseSummarySaga,
  watchGetLockedPledgeDetailsSaga,
} from './consumerDuty';

export default function* rootSaga() {
  yield spawn(watchGetCustomerProfile);
  yield spawn(watchGetRetailItems);
  yield spawn(watchUploadCustomerImage);
  yield spawn(watchCustomerProfileSave);
  yield spawn(watchUploadCustomerDocument);
  yield spawn(watchGetCustomerProfileInBackground);
  yield spawn(watchGetCustomerProfileStatusSaga);
  yield spawn(watchCustomerPawnbrokingSummary);
  yield spawn(watchGetCustomerLastVisitsSaga);
  yield spawn(watchCreateLiteCustomerProfileSaga);
  yield spawn(watchUploadLiteCustomerDocumentsSaga);
  yield spawn(watchUpdateTravelMoneyGuestSaga);
  yield spawn(watchUploadTMGuestDocumentsSaga);
  yield spawn(watchUploadTpaImageSaga);

  yield spawn(watchGetItemPriceValuation);
  yield spawn(watchFindRetailItems);
  yield spawn(watchFindSoldItems);
  yield spawn(watchGetLoanPayable);

  yield spawn(watchUploadMultipleImageSaga);

  yield spawn(watchLoanServiceBasket);
  yield spawn(watchBasket);
  yield spawn(watchBasketByBasketId);
  yield spawn(watchMergeGuestBasket);
  yield spawn(watchDeleteBasket);
  yield spawn(watchDeleteBasketService);
  yield spawn(watchCreatePledgeBasket);
  yield spawn(watchDeletePledgeBasket);
  yield spawn(watchEditPledgeBasket);
  yield spawn(watchEditPledgeItemsForReducer);
  yield spawn(watchSplitPledgeBasket);
  yield spawn(watchMergePledgeBasket);
  yield spawn(watchMovePledgeItemsBasket);
  yield spawn(watchCalculatePledgeLoanBasket);
  yield spawn(watchMovePledgePurchaseItemsBasket);
  yield spawn(watchCreatePickupRenewBasket);
  yield spawn(watchDeletePickupRenewBasket);
  yield spawn(watchDeleteFxTransactionSaga);
  yield spawn(watchDeleteChequeChashingBasket);
  yield spawn(watchCreateChequeCashingBasket);
  yield spawn(watchPostWesternUnionSaga);
  yield spawn(watchDeleteWesternUnionBasket);
  yield spawn(watchCreatePledgeOrPurchaseReturnBasket);
  yield spawn(watchDeletePurchaseReturnBasket);
  yield spawn(watchTenItemsInBasket);
  yield spawn(watchMergeBasketsSaga);
  yield spawn(watchSurplusBasketSaga);
  yield spawn(watchDeleteSurplusBasketSaga);
  yield spawn(watchUpdateCustomerDetailsBasketSaga);

  yield spawn(watchCreateRetailBasket);
  yield spawn(watchDeleteRetailBasket);
  yield spawn(watchEditRetailBasket);
  yield spawn(watchEditChequeCashingBasket);

  yield spawn(watchGetCurrenciesSaga);
  yield spawn(watchGetBuybackChargesSaga);
  yield spawn(watchPostConvertCurrencySaga);
  yield spawn(watchPostUpDownCurrencySaga);
  yield spawn(watchLedgerBalanceCurrencySaga);
  yield spawn(watchStockBalanceCurrencySaga);
  yield spawn(watchGetBuybackHistorySaga);
  yield spawn(watchPostFxTransactionSaga);

  yield spawn(watchPaidPaymentSaga);
  yield spawn(watchPledgePickupAmountSaga);
  yield spawn(watchAffidavitReasonSaga);
  yield spawn(watchPurchaseReturnAmountSaga);
  yield spawn(watchPrintContractsSaga);

  yield spawn(watchGetCheckout);
  yield spawn(watchCheckoutProgress);
  yield spawn(watchPostPreAuthentication);

  yield spawn(watchValidateCreatedServicesBasket);
  yield spawn(watchValidatePledgeServiceBasket);
  yield spawn(watchValidateRetailServiceBasket);
  yield spawn(watchValidatePickupServiceBasket);
  yield spawn(watchValidateFxServiceBasket);
  yield spawn(watchValidateTpcServiceBasket);
  yield spawn(watchValidatePurchaseServiceBasket);
  yield spawn(watchValidateWUServiceBasket);
  yield spawn(watchValidatePurchaseReturnServiceBasket);
  yield spawn(watchValidateUserTillBalance);
  yield spawn(watchValidateSurplusServiceBasket);
  yield spawn(watchValidateLoanActionsSaga);
  yield spawn(watchConsumerDutyValidationSaga);

  yield spawn(watchGetCurrencyListSaga);
  yield spawn(watchGetDestinationStoreListSaga);
  yield spawn(watchGetStoreTillHistorySaga);

  yield spawn(watchSearchTransferSaga);
  yield spawn(watchGetStoresSaga);
  yield spawn(watchGetTransferItemsSaga);
  yield spawn(watchDeleteTransferItemsSaga);
  yield spawn(watchStockTransferAuthorizationSaga);
  yield spawn(watchSendStockTransferSaga);
  yield spawn(watchAcceptStockTransferSaga);

  yield spawn(watchVoidTransactionAuthorizationSaga);
  yield spawn(watchGetVoidTransactionsSaga);
  yield spawn(watchGetStoreUsersSaga);
  yield spawn(watchGetUserDetailsSaga);
  yield spawn(watchUpdateUserDetailsSaga);
  yield spawn(watchGetUserPermissionsSaga);
  yield spawn(watchGetGroupPermissionsSaga);
  yield spawn(watchGetDefaultPermissionsSaga);
  yield spawn(watchPostSaveUserPermissionsSaga);

  yield spawn(watchGetTravelMoneyRatesSaga);
  yield spawn(watchGetTravelMoneyRatesHistorySaga);
  yield spawn(watchCreateTravelMoneyRatesSaga);

  yield spawn(watchSearchAuctionSaga);
  yield spawn(watchGetAuctionsOverSaga);
  yield spawn(watchGetPfiUnderSaga);
  yield spawn(watchGetAuctionDetailsSaga);
  yield spawn(watchPutConfirmCollectionSaga);
  yield spawn(watchGetConfirmAuctionSettlementSaga);
  yield spawn(watchPutSettleAuctionSaga);
  yield spawn(watchPutUpdateSoldValueSaga);
  yield spawn(watchGetPrintPassListSaga);
  yield spawn(watchGetPledgeItemsDetailsSaga);
  yield spawn(watchGetPledgeLoanDetailsSaga);
  yield spawn(watchGetPurchaseLoanDetailsSaga);
  yield spawn(watchGetParentItemIdSaga);
  yield spawn(watchPostReviewItemsPfaSaga);
  yield spawn(watchGetItemPriceValuationSaga);
  yield spawn(watchPutPassAuctionLotSaga);
  yield spawn(watchPutLockAuctionSaga);
  yield spawn(watchGetNotifyAuctionLockingSaga);
  yield spawn(watchPostPledgeToPfiSaga);
  yield spawn(watchPostPurchaseToPfiSaga);
  yield spawn(watchPostPfiBarcodeSaga);

  yield spawn(watchGetPledgeItemsListSaga);
  yield spawn(watchPutPfaLotNumberSaga);
  yield spawn(watchPrintCocmCertificateSaga);
  yield spawn(watchPrintAffidavitSaga);
  yield spawn(watchPrintCustomerMandateSaga);
  yield spawn(watchGetGenerateLotStickerSaga);
  yield spawn(watchPledgeConfiscationSaga);
  yield spawn(watchPledgeVulnerableSaga);
  yield spawn(watchGetVulnerablePledgesSaga);
  yield spawn(watchGetVulnerableNotesSaga);
  yield spawn(watchPostAddVulnerableNoteSaga);
  yield spawn(watchPostRemoveVulnerableNoteSaga);

  yield spawn(watchUploadChequeCashingDocumentSaga);
  yield spawn(watchUploadWesternUnionDocumentSaga);
  yield spawn(watchEditChequeCashingSaga);

  yield spawn(watchGetValuationItemDetails);
  yield spawn(watchGetNotificationItemDetails);
  yield spawn(watchPostValuationActions);

  yield spawn(watchResetCustomerDataSaga);
  yield spawn(watchProcessConsumerDutySaga);
  yield spawn(watchGetCaseSummarySaga);
  yield spawn(watchGetLockedPledgeDetailsSaga);
}
