import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Modal, Radio, Row, Select, Space, Spin } from 'antd';
import { Button } from 'components/common/Button';
import styled from 'styled-components';
import {
  BASE_PLEDGE_CATEGORIES,
  ADD_VALUATION,
  ITEM_ATTRIBUTE_REQUIRED,
  ITEM_ATTRIBUTE_TYPE,
} from '../../constants';
import { UPLOAD_MULTIPLE_IMAGE_REQUEST } from 'action_creators/pledge';
import { cacheStaticDataWithArgs } from 'utils/cacheServices';
import { useSelector, useDispatch } from 'react-redux';
import { pledgeActions } from 'redux/reducers/pawnbroking';
import { reduceWatchHandbagItems, getWatchHandbagFormItem } from 'utils/util';

import MultipleImageUploader from 'components/common/MultipleImageUploader';
import TextArea from 'components/common/TextArea';
import { getItemCategoryAttributes } from 'services/pledge';

const StyledModal = styled(Modal)`
  & .ant-checkbox-wrapper.ant-checkbox-group-item {
    margin: 10px 0px 10px 0px;
    & .ant-checkbox-inner {
      width: 18px;
      height: 18px;
    }
  }
  & .ant-row.ant-form-item.label-control {
    margin-block-end: 18px;
  }
  & .ant-row.ant-form-item.label-control.hands-radio-btn {
    margin-top: 10px;
    & .ant-form-item-label {
      display: none;
    }
  }
  & .ant-radio-wrapper .ant-radio-inner {
    width: 22px;
    height: 22px;
    &::after {
      top: 6px;
      left: 6px;
    }
  }
`;

interface WatchValuationPopupProps {
  visible: boolean;
  setWatchValuePopupVisible: () => void;
}

const WatchValuationPopup = ({
  visible,
  setWatchValuePopupVisible,
}: WatchValuationPopupProps) => {
  const [form] = Form.useForm();
  const [watchAttributes, setWatchAttributes] = useState<any>([]);
  const [watchChecklist, setWatchChecklist] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const {
    pawnbroking: { watches, itemImageCollectionResponse, checklists },
    valuationCenter: { isValuationItemToPledge },
  } = useSelector((state: any) => {
    return {
      pawnbroking: state.pawnbroking,
      valuationCenter: state.valuationCenter,
    };
  });

  const uploadImageHandler = (image: any) => {
    dispatch({
      type: UPLOAD_MULTIPLE_IMAGE_REQUEST,
      payload: { image, isDefault: false },
    });
  };

  const updateImageList = (imageList: any) => {
    const params = {
      ...itemImageCollectionResponse,
      images: imageList,
    };
    dispatch(pledgeActions.setItemImageCollectionResponse(params));
  };

  const getFields = () => {
    const count = watchAttributes;
    const columns = 3;
    const rows = Math.ceil(count.length / columns);
    const children = [];

    for (let i = 0; i < columns; i++) {
      const rowEls = [];

      for (let j = 0; j < rows; j++) {
        const key = i * rows + j;
        if (key >= count.length) break;

        const { name, options, attributeId, kind, required } = count[key];
        rowEls.push(
          <Row>
            <Col span={24}>
              <Form.Item
                name={attributeId}
                label={
                  kind === ITEM_ATTRIBUTE_TYPE.RADIO
                    ? ''
                    : `${name}${
                        required === ITEM_ATTRIBUTE_REQUIRED.YES ? '*' : ''
                      }`
                }
                className="label-control"
                rules={[
                  {
                    required:
                      required === ITEM_ATTRIBUTE_REQUIRED.YES ? true : false,
                    message: `${name} is required`,
                  },
                ]}
              >
                {kind === ITEM_ATTRIBUTE_TYPE.DROPDOWN ? (
                  <Select className="input-control" placeholder="Select">
                    {options.map((item: any, index: number) => (
                      <Select.Option key={index} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                ) : kind === ITEM_ATTRIBUTE_TYPE.RADIO ? (
                  <Radio.Group>
                    <Space direction="vertical">
                      {options.map((item: any, index: number) => (
                        <Radio key={index} value={item}>
                          {item}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                ) : kind === ITEM_ATTRIBUTE_TYPE.TEXTAREA ? (
                  // Test new textarea UI befor code merge
                  <TextArea
                    showCount
                    maxLength={200}
                    className="input-control no-border"
                    rows={9}
                  />
                ) : (
                  <Input className="input-control" autoComplete="off" />
                )}
              </Form.Item>
            </Col>
          </Row>
        );
      }

      children.push(
        <Col span={8} key={i}>
          {rowEls}
        </Col>
      );
    }
    return children;
  };

  //...Commented as per ticket no. MP-5968 to be used in future for valuations

  // const getCheckList = () => {
  //   return watchChecklist.map((option: any, index: any) => (
  //     <Col span={24} key={index}>
  //       <Row className="checklist-row">
  //         <Col span={24}>
  //           <Checkbox
  //             value={option?.attributeId}
  //             checked={option?.checked}
  //             onChange={onChecklistChange}
  //           >
  //             {option?.name}
  //           </Checkbox>
  //         </Col>
  //       </Row>
  //     </Col>
  //   ));
  // };

  // const onChecklistChange = (event: any) => {
  //   const { value, checked } = event.target;

  //   let checklistCopy = cloneDeep(watchChecklist);
  //   const index = watchChecklist.findIndex(
  //     (option: any) => option.attributeId === value
  //   );

  //   checklistCopy[index]['checked'] = checked;
  //   setWatchChecklist(checklistCopy);
  // };

  const onCancelClick = () => {
    setWatchValuePopupVisible();
  };

  const onWatchPopupSubmit = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const watchItems = reduceWatchHandbagItems(values);

      const params = { watches: watchItems, checklists: watchChecklist };
      dispatch(pledgeActions.updateWatches(params));
      setWatchValuePopupVisible();
    } catch (errorInfo) {/* continue regardless of error */}
  };

  const getWatchAttributes = async () => {
    try {
      setLoading(true);
      const response = await cacheStaticDataWithArgs(
        'WATCH-ATTRIBUTE',
        getItemCategoryAttributes,
        BASE_PLEDGE_CATEGORIES[1].id
      );
      if (response?.data) {
        setLoading(false);
        setWatchAttributes(response?.data?.itemCategoryAttributes);
      }
    } catch (e: any) {
      setLoading(false);
    }
  };

  //...Commented as per ticket no. MP-5968 to be used in future for valuations
  // const getWatchChecklist = async () => {
  //   try {
  // const response = await cacheStaticDataWithArgs(
  //        'WATCH-CHECKLIST',
  //        getItemCategoryChecklist,
  //        BASE_PLEDGE_CATEGORIES[q].id
  //      );
  //     if (response?.data) setWatchChecklist(response?.data?.checklists);
  //   } catch (e: any) {
  //     console.log(e);
  //   }
  // };

  useEffect(() => {
    getWatchAttributes();
    // if (!checklists.length) getWatchChecklist(); //...Commented as per ticket no. MP-5968 to be used in future for valuations
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (watches && watches?.length > 0) {
      const result = getWatchHandbagFormItem(watches);
      form.setFieldsValue({ ...result });

      setWatchChecklist(checklists);
    }
  }, [watches]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StyledModal
        title="Add Watch Details"
        centered
        open={visible}
        width={1500}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        footer={
          <Row>
            <Col span="6" className="mandatory-label">
              (*) Mandatory
            </Col>
            <Col span="18">
              <Row gutter={24} justify="end" key="footer">
                <Col key="1">
                  <Button
                    itemProp="secondary"
                    key="back"
                    onClick={onCancelClick}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col key="2">
                  <Button
                    key="submit"
                    form="form"
                    htmlType="submit"
                    onClick={onWatchPopupSubmit}
                  >
                    {watches?.length > 0
                      ? ADD_VALUATION.UPDATE
                      : ADD_VALUATION.ADD}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        }
      >
        {loading ? (
          <Spin />
        ) : (
          <Form
            layout="vertical"
            form={form}
            disabled={isValuationItemToPledge}
          >
            <Row>
              <Col span={10}>
                <Row gutter={24} align="top">
                  {getFields()}
                </Row>
              </Col>
              <Col span={5} offset={1} className="checklist-wrapper">
                {/* //...Commented as per ticket no. MP-5968 to be used in future for valuations
                <Row>
                  <p className="title">Tick to answer "YES"</p>
                </Row>
                
                <Row gutter={24} align="top">
                  {getCheckList()}
                </Row> */}
              </Col>
              <Col span={8}>
                <Row>
                  <Col span={24}>
                    <h2 style={{ margin: 0 }}>Item Images</h2>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <MultipleImageUploader
                      imageList={itemImageCollectionResponse?.images}
                      uploadImageRequest={uploadImageHandler}
                      updateImageList={updateImageList}
                      viewOnly={isValuationItemToPledge}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </StyledModal>
    </>
  );
};

export default WatchValuationPopup;
