import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button } from 'antd';
import styled from 'styled-components';
import { PlusCircleFilled, CloseOutlined } from '@ant-design/icons/lib/icons';
import PledgeItemDescription from './PledgeItemDescription';
import { Button as CustomButton } from 'components/common/Button';
import 'components/pledge/index.less';
import {
  ITEM_DESCRIPTION,
  TOTAL_ITEM_VALUE,
  TAKE_ITEM_PHOTO,
  VALUATION,
  CONFIRM_ITEM_DETAILS,
  ADD_COIN_LABELS,
  ADD_STONE_LABELS,
} from 'components/pledgemanagement/constants';
import AddStonePopup from 'components/pledge/addPledge/addStonePopup';
import AddCoinPopup from 'components/pledge/addPledge/addCoinPopup';
import CategoriesPopup from 'components/pledge/addPledge/categoriesPopup/CategoriesPopup';
import { useSelector, useDispatch } from 'react-redux';
import { stoneActions } from 'redux/reducers/pawnbroking/stone';
import { coinActions } from 'redux/reducers/pawnbroking/coin';
import isEmpty from 'lodash/isEmpty';
import {
  PLEDGE_ITEM_PRICE_REQUEST,
  UPLOAD_MULTIPLE_IMAGE_REQUEST,
} from 'action_creators/pledge';
import { reducePledgeItems } from 'utils/util';
import ItemPhotoPopup from 'components/common/ItemPhotoPopup';
import ImageLoader from 'components/common/ImageLoader';
import HelpPopoverInfo, {
  StyledHelpPopoverInfoForButtons,
} from 'components/common/HelpPopoverInfo';
import {
  REVIEW_ITEMS_PFA,
  GET_PARENT_ITEM_CATEGORY,
} from 'action_creators/pledgeManagement';

import { cacheStaticData } from 'utils/cacheServices';
import { getPledgeCategoryFixedAttributes } from 'services/pledge';

const StyledRow = styled(Row)`
  margin: 0px 15px 15px 15px;
  width: 97%;
  &:first-of-type {
    margin: 8px;
  }
`;

const StyledText = styled.div`
  font-weight: 500;
  padding: 8px 0px;
`;

const StyledQuickCatDescription = styled(Col)`
  width: 100%;
  height: 4.2rem;
  background: var(--catskill-white);
  padding: 10px 12px;
`;

const StyledItemValueRow = styled(Row)`
  background: var(--catskill-white);
  & .ant-row {
    flex-flow: nowrap;
  }
`;

const StyledItemValueWrapper = styled(Row)`
  margin: 8px 16px;
`;

const StyledPhotoContainer = styled(Col)`
  background: #fafafa;
  border: 1px dashed #bfc5cf;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  max-height: 100vh;
  overflow: hidden;
`;

const StyledPhotoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 10px;
  &:first-of-type {
    padding: 40px 0 0;
  }
`;

const StyledAddButton = styled(Button)`
  width: 100%;
  margin: 0 15px 15px 0;
  background: var(--white);
  &:focus,
  &:hover,
  &:active {
    background: var(--white);
    color: var(--blue);
  }
  height: 44px;
  font-weight: var(--font-weight-500);
  margin-right: 15px;
  color: var(--blue);
  border: 1px solid var(--light-gray);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: left;
`;

const StyledTotalLentRow = styled(Row)`
  background: var(--cyan);
  padding: 8px 16px;
  width: 100%;
`;

const StyledTotalLentCol = styled(Col)`
  display: flex;
  font-size: 16px;
  align-items: center;
`;

const StyledAmountInput = styled(Input)`
  border: none;
  text-align: right;
  padding: 0;
  outline: 0;
  font-weight: var(--font-weight-500);
`;

const StyledItemBox = styled.div<{ rowItems: boolean }>`
  max-height: ${(props: any) => (props.rowItems ? '116px' : '205px')};
`;

const StyledRowHelp = styled.span`
  position: relative;
  bottom: 60%;
  left: 100%;
`;

const StyledTableCell = styled.td`
  &.text-danger {
    color: var(--dark-red) !important;
    font-weight: var(--font-weight-500) !important;
  }
`;

interface StoneRowProps {
  id?: any;
  stoneType?: any;
  stoneClarity?: any;
  stoneClass?: any;
  stoneShape?: any;
  stonePoint?: number | string;
  stoneQuantity?: number | string;
  stoneNotes?: string;
}

interface CoinRowProps {
  id?: any;
  coinType?: any;
  coinYear?: number | string;
  quantity?: number | string;
}

const ItemDescriptionValuation = () => {
  const [isStonePopupVisible, setStonePopupVisible] = useState<boolean>(false);
  const [isCoinPopupVisible, setCoinPopupVisible] = useState<boolean>(false);
  const [isPledgeItemFormValidate, setPledgeItemFormValidate] =
    useState<boolean>(false);
  const [itemFixedAttributes, setItemFixedAttributes] = useState<any[]>([]);
  const [stoneEditRow, setStoneEditRow] = useState<StoneRowProps>();
  const [coinEditRow, setCoinEditRow] = useState<CoinRowProps>();
  const [isTakeItemPhotoPopupVisible, setTakeItemPhotoPopupVisible] =
    useState<boolean>(false);
  const [isCategoriesPopupVisible, setCategoriesPopupVisible] =
    useState<boolean>(false);
  const [itemReview, setItemReview] = useState<any>({});
  const dispatch = useDispatch();
  const {
    pledgeManagement: {
      pledgeItemDetails,
      parentItemId,
      selectedItemId,
      auctionItemsValue,
      loanDetails,
      confirmItemDetails,
    },
    stone: { stoneAllItemsData, stonePrices },
    coin: { coinAllItemsData, coinPrices },
    pawnbroking: { itemDescription, isImageUploading, parentImageUrl },
  } = useSelector((state: any) => {
    return {
      pledgeManagement: state.pledgeManagement,
      stone: state.stone,
      coin: state.coin,
      customer: state.customer,
      pawnbroking: state.pawnbroking,
    };
  });

  const tableRow = (
    label: string,
    valueOne: any,
    valueTwo: any = null,
    input = false,
    originalValueOne: any
  ) => {
    const roundedValueOne = valueOne ? valueOne.toFixed(2) : '0.00';
    const roundedValueTwo = valueTwo ? valueTwo.toFixed(2) : '0.00';

    const isValueChanged =
      valueOne === undefined ? false : valueOne !== originalValueOne;

    return (
      <tr>
        <td>{label}</td>
        <StyledTableCell className={isValueChanged ? 'text-danger' : ''}>
          {input ? (
            <StyledAmountInput value={`£${roundedValueOne}`} />
          ) : valueTwo ? (
            `£${roundedValueOne} - £${roundedValueTwo}`
          ) : (
            `£${roundedValueOne}`
          )}
        </StyledTableCell>
      </tr>
    );
  };

  const tableRowWithHelp = (
    label: string,
    valueOne: any,
    valueTwo: any = null,
    input = false
  ) => {
    return (
      <tr>
        <td>{label}</td>
        <td>
          {input ? (
            <StyledAmountInput value={`£${valueOne}`} />
          ) : valueTwo ? (
            `£${valueOne} - £${valueTwo}`
          ) : (
            `£${valueOne}`
          )}
        </td>
        <td>
          <span style={{ float: 'right' }}>
            <HelpPopoverInfo
              linkedID={`PLEDGEACTION_${label}`}
              position="rightTop"
            ></HelpPopoverInfo>
          </span>
        </td>
      </tr>
    );
  };

  const getItemFixedAttributes = async () => {
    try {
      const response = await cacheStaticData(getPledgeCategoryFixedAttributes);
      if (response?.data)
        setItemFixedAttributes(response?.data?.itemFixedAttributeTypes);
    } catch (e: any) {
      console.log(e);
    }
  };

  const getItemPriceValuation = async (params: any) => {
    dispatch({
      type: PLEDGE_ITEM_PRICE_REQUEST,
      payload: { params: params },
    });
  };

  const onAddStoneClick = () => {
    setStoneEditRow({});
    if (pledgeItemDetails?.description) setStonePopupVisible(true);
  };

  const onStoneDescriptionClick = (row: StoneRowProps) => {
    setStoneEditRow(row);
    setStonePopupVisible(true);
  };

  const onStoneCloseIconClick = (item: any) => {
    const stoneData = stoneAllItemsData.filter(
      (stoneItem: any) => stoneItem.id !== item.id
    );

    const stoneItems = reducePledgeItems(stoneData);
    const params = {
      categoryId: pledgeItemDetails?.loanItemType?.id,
      metalPrice: pledgeItemDetails?.metal,
      coinPrices,
      stonePrices: stoneItems,
    };

    getItemPriceValuation(params);
    dispatch(stoneActions.stoneAllItems([...stoneData]));
    dispatch(stoneActions.stoneItemsPrice(params.stonePrices));
  };

  const onAddCoinClick = () => {
    setCoinEditRow({});
    if (pledgeItemDetails?.description) setCoinPopupVisible(true);
  };

  const onCoinDescriptionClick = (row: any) => {
    setCoinEditRow(row);
    setCoinPopupVisible(true);
  };

  const onCoinCloseIconClick = (item: any) => {
    const coinData = coinAllItemsData.filter(
      (coinItem: any) => coinItem.id !== item.id
    );

    const coinItems = reducePledgeItems(coinData);
    const params = {
      categoryId: pledgeItemDetails?.loanItemType?.id,
      metalPrice: pledgeItemDetails?.metal,
      coinPrices: [...coinItems],
      stonePrices,
    };

    getItemPriceValuation(params);
    dispatch(coinActions.coinAllItems([...coinData]));
    dispatch(coinActions.coinItemsPrice(params.coinPrices));
  };

  const onTakeItemPhotoClick = () => {
    setTakeItemPhotoPopupVisible(true);
  };

  const parentCategoryItemId = (row: any) => {
    dispatch({
      type: GET_PARENT_ITEM_CATEGORY,
      payload: { Id: pledgeItemDetails?.loanItemType?.id },
    });
  };

  const onDescriptionClick = async () => {
    await parentCategoryItemId(pledgeItemDetails);
    setCategoriesPopupVisible(true);
  };

  const resetItems = () => undefined;

  useEffect(() => {
    getItemFixedAttributes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onConfirmItemDetailsClick = () => {
    const params = {
      itemreview: {
        itemReviewId: 0,
        itemId: selectedItemId,
        condition: isEmpty(itemReview)
          ? pledgeItemDetails?.metal?.metalCondition
          : itemReview?.metalPrice?.metalCondition,
        metalType: isEmpty(itemReview)
          ? pledgeItemDetails?.metal?.metalType
          : itemReview?.metalPrice?.metalType,
        fineness: isEmpty(itemReview)
          ? pledgeItemDetails?.metal?.fineness
          : itemReview?.metalPrice?.fineness,
        clarity: 0,
        hallmark: isEmpty(itemReview)
          ? pledgeItemDetails?.metal?.isHallmark?.toLowerCase() === 'yes'
          : itemReview?.metalPrice?.isHallMark?.toLowerCase() === 'yes',
        subItemTypeId: isEmpty(itemReview)
          ? pledgeItemDetails?.subItemType?.id
          : itemReview?.categoryId,
        subItemTypeEntityId: 0,
        subItemCount: 0,
        subItemPoints: 0,
        weight: isEmpty(itemReview)
          ? pledgeItemDetails?.metal?.weightInGrams
          : itemReview?.metalPrice?.weightInGrams,
        storeId: 6909,
        notes: isEmpty(itemReview)
          ? pledgeItemDetails?.metal?.notes
          : itemReview?.metalPrice?.notes,
      },
    };
    dispatch({
      type: REVIEW_ITEMS_PFA,
      payload: {
        params,
        confirmItemDetails,
      },
    });
  };

  const updateItemReview = (row: any) => {
    setItemReview(row);
  };

  const uploadImageHandler = (image: any) => {
    dispatch({
      type: UPLOAD_MULTIPLE_IMAGE_REQUEST,
      payload: { image, isDefault: true },
    });
  };
  return (
    <>
      <StyledRow>
        <StyledQuickCatDescription span={24}>
          <div
            onClick={onDescriptionClick}
            style={{
              cursor: 'pointer',
            }}
          >
            {itemDescription
              ? itemDescription
              : pledgeItemDetails.description || ITEM_DESCRIPTION}
          </div>
        </StyledQuickCatDescription>
      </StyledRow>
      <StyledRow style={{ minHeight: 'calc(100vh - 499px)', flexGrow: 1 }}>
        <Col span={24}>
          <Row>
            <Col lg={15} xl={12} xxl={11}>
              <PledgeItemDescription
                itemFixedAttributes={itemFixedAttributes}
                isPledgeItemFormValidate={isPledgeItemFormValidate}
                setPledgeItemFormValidate={() =>
                  setPledgeItemFormValidate(false)
                }
                updateItemReview={updateItemReview}
              />
            </Col>
            <Col lg={9} xl={12} xxl={13} style={{ paddingLeft: '15px' }}>
              <Row>
                <Col span={24}>
                  {stoneAllItemsData && stoneAllItemsData?.length > 0 ? (
                    <StyledItemBox
                      className="stone-details-wrapper"
                      rowItems={
                        !isEmpty(stoneAllItemsData) &&
                        !isEmpty(coinAllItemsData)
                      }
                    >
                      {stoneAllItemsData?.map((item: any) => {
                        return (
                          <Row className="stone-details-row" key={item?.id}>
                            <Col
                              style={{ cursor: 'pointer' }}
                              span={22}
                              className="stone-description"
                              onClick={() => onStoneDescriptionClick(item)}
                            >{`${item?.stoneType?.description} - ${item?.stoneShape?.description} - ${item?.stonePoint}pts x${item?.stoneQuantity} - ${item?.stoneClass?.description} - ${item?.stoneClarity?.description}`}</Col>
                            <Col span={2} className="stone-closed-icon">
                              <CloseOutlined
                                onClick={() => onStoneCloseIconClick(item)}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                    </StyledItemBox>
                  ) : null}
                  {stoneAllItemsData && stoneAllItemsData?.length > 0 ? (
                    <StyledAddButton onClick={onAddStoneClick}>
                      {ADD_STONE_LABELS.ANOTHER_STONE}
                    </StyledAddButton>
                  ) : (
                    <Row>
                      <Col span={24}>
                        <StyledAddButton onClick={onAddStoneClick}>
                          {ADD_STONE_LABELS.LABEL}
                        </StyledAddButton>
                        <StyledRowHelp>
                          <HelpPopoverInfo
                            linkedID="PLEDGEACTION_ADDSTONE"
                            position="rightTop"
                          ></HelpPopoverInfo>
                        </StyledRowHelp>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {coinAllItemsData && coinAllItemsData?.length > 0 ? (
                    <StyledItemBox
                      className="stone-details-wrapper"
                      rowItems={
                        !isEmpty(stoneAllItemsData) &&
                        !isEmpty(coinAllItemsData)
                      }
                    >
                      {coinAllItemsData?.map((item: any) => {
                        return (
                          <Row className="stone-details-row" key={item?.id}>
                            <Col
                              style={{ cursor: 'pointer' }}
                              span={22}
                              className="stone-description"
                              onClick={() => onCoinDescriptionClick(item)}
                            >{`${item?.coinType?.description} ${
                              item?.coinYear ? ` - ${item.coinYear}` : ''
                            } x${item?.quantity}`}</Col>
                            <Col span={2} className="stone-closed-icon">
                              <CloseOutlined
                                onClick={() => onCoinCloseIconClick(item)}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                    </StyledItemBox>
                  ) : null}
                  {coinAllItemsData && coinAllItemsData?.length > 0 ? (
                    <>
                      {/* <StyledAddButton onClick={onAddCoinClick}>
                      //{ADD_COIN_LABELS.ANOTHER_COIN}
                    </StyledAddButton> */}
                    </>
                  ) : (
                    <StyledAddButton onClick={onAddCoinClick}>
                      {ADD_COIN_LABELS.LABEL}
                    </StyledAddButton>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={28}>
                  <table className="pledge-valuation-table">
                    <tbody>
                      {tableRowWithHelp(
                        VALUATION.DESIRED_AMOUNT,
                        '0.00',
                        '0.00',
                        true
                      )}
                      {tableRowWithHelp(VALUATION.VALUATION, '0.00')}
                    </tbody>
                  </table>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ margin: '13px 0', textAlign: 'right' }}>
                  <StyledHelpPopoverInfoForButtons
                    linkedID="CONFIRM_ITEM_DETAILS"
                    position="top"
                    helpPosition="AFTER"
                  >
                    <CustomButton
                      itemProp="secondary"
                      onClick={onConfirmItemDetailsClick}
                      disabled={isEmpty(pledgeItemDetails)}
                    >
                      {CONFIRM_ITEM_DETAILS}
                    </CustomButton>
                  </StyledHelpPopoverInfoForButtons>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </StyledRow>

      <StyledItemValueRow>
        <Col span={24}>
          <StyledItemValueWrapper justify="space-between">
            <Col lg={12} xl={12} xxl={9}>
              <table className="pledge-valuation-table">
                <tbody>
                  {tableRow(
                    VALUATION.METAL_VALUE,
                    auctionItemsValue?.metalPrice?.minimumLoanValue,
                    auctionItemsValue?.metalPrice?.maximumLoanValue,
                    false,
                    pledgeItemDetails?.itemValuation?.metalPrice
                      ?.minimumLoanValue
                  )}
                  {tableRow(
                    VALUATION.COIN_VALUE,
                    auctionItemsValue?.coinPrice?.amount,
                    null,
                    false,
                    pledgeItemDetails?.itemValuation?.coinPrice?.amount
                  )}
                  {tableRow(
                    VALUATION.STONES_TOTAL_VALUE,
                    auctionItemsValue?.stonePrice?.maximumLoanValue,
                    null,
                    false,
                    pledgeItemDetails?.itemValuation?.stonePrice
                      ?.maximumLoanValue
                  )}
                  {tableRow(
                    VALUATION.OVERALL_GUIDANCE,
                    auctionItemsValue?.guidance?.minimumLoanValue,
                    auctionItemsValue?.guidance?.maximumLoanValue,
                    false,
                    pledgeItemDetails?.itemValuation?.guidance?.minimumLoanValue
                  )}
                </tbody>
              </table>
            </Col>

            <StyledPhotoContainer
              lg={5}
              xl={5}
              xxl={6}
              onClick={onTakeItemPhotoClick}
            >
              {parentImageUrl === '' ? (
                <>
                  <StyledPhotoDiv>
                    <PlusCircleFilled
                      style={{ fontSize: '34px', color: '#11a1fd' }}
                    />
                  </StyledPhotoDiv>
                  <StyledPhotoDiv>
                    <span style={{ fontWeight: 500, color: '#11a1fd' }}>
                      {TAKE_ITEM_PHOTO}
                    </span>
                  </StyledPhotoDiv>
                </>
              ) : (
                <ImageLoader
                  imageUrl={
                    parentImageUrl
                      ? parentImageUrl
                      : pledgeItemDetails?.itemImage
                  }
                  classes={'ant-image image-min-height'}
                  fallbackImg={'no_image_thumbnail.svg'}
                />
              )}
            </StyledPhotoContainer>
          </StyledItemValueWrapper>
        </Col>
      </StyledItemValueRow>
      <StyledTotalLentRow>
        <Col span={24}>
          <Row>
            <StyledTotalLentCol lg={24} xl={24} xxl={24}>
              <Col lg={3} xl={3} xxl={3}>
                <StyledText>{TOTAL_ITEM_VALUE}</StyledText>
              </Col>
              <Col lg={5} xl={5} xxl={5}>
                <Input
                  className="total-item-value"
                  disabled
                  addonBefore={'£'}
                  placeholder="0.00"
                  value={
                    !isEmpty(pledgeItemDetails)
                      ? loanDetails.loanAmount
                      : '0.00'
                  }
                />
              </Col>
            </StyledTotalLentCol>
          </Row>
        </Col>
      </StyledTotalLentRow>
      {isCategoriesPopupVisible &&
        Object.values(parentItemId).length > 0 &&
        parentItemId?.id > 0 && (
          <CategoriesPopup
            visible={isCategoriesPopupVisible}
            setCategoriesPopupVisible={() => setCategoriesPopupVisible(false)}
            itemCategory={parentItemId}
            resetItems={resetItems}
            disableAddCoin={() => {}}
          />
        )}
      {isStonePopupVisible && (
        <AddStonePopup
          visible={isStonePopupVisible}
          setStonePopupVisible={() => setStonePopupVisible(false)}
          itemFixedAttributes={itemFixedAttributes}
          stoneEditRow={stoneEditRow}
        />
      )}
      {isCoinPopupVisible && (
        <AddCoinPopup
          visible={isCoinPopupVisible}
          setCoinPopupVisible={() => setCoinPopupVisible(false)}
          itemFixedAttributes={itemFixedAttributes}
          metalPrice={pledgeItemDetails?.itemValuation?.metalPrice}
          coinEditRow={coinEditRow}
        />
      )}
      {isTakeItemPhotoPopupVisible && (
        <ItemPhotoPopup
          visible={isTakeItemPhotoPopupVisible}
          loading={isImageUploading}
          onImageUploadClick={uploadImageHandler}
          photoPopupHandler={() => setTakeItemPhotoPopupVisible(false)}
        />
      )}
    </>
  );
};

export default ItemDescriptionValuation;
