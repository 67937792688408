import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { isEmpty, toString } from 'lodash';
import { Col, Row } from 'antd';

import { actions as consumerDutyActions } from 'redux/reducers/consumerDuty';

import { Button } from 'components/common/Button';

import {
  BUTTONS_LABEL,
  TABS_NAME,
  TASK_OWNER_VALUE,
} from 'components/consumerDuty/constants';

const StyledCol = styled(Col)`
  text-align: right;
`;

const StyledCenterCol = styled(Col)`
  text-align: center;
`;

interface FooterProps {
  onManageServicesClick: () => void;
  onNewPledgePermissionClick: () => void;
  onExitButtonClick: () => void;
}

const Footer = ({
  onManageServicesClick,
  onNewPledgePermissionClick,
  onExitButtonClick,
}: FooterProps) => {
  const {
    loanPrivileges,
    selectedTab,
    newPledgePermissions,
    selectedTaskOwner,
    lockedPledgeDetails,
    isStoreEnquiryModeActiveForCase,
    isViewOnlyMode,
  } = useSelector((state: any) => state.consumerDuty);

  const dispatch = useDispatch();

  const onPreviousClick = () => {
    const tab = toString(+selectedTab - 1);
    dispatch(consumerDutyActions.setSelectedTab(tab));
  };

  const onNextClick = () => {
    if (isEmpty(loanPrivileges) && isEmpty(newPledgePermissions)) {
      if (selectedTaskOwner === TASK_OWNER_VALUE.AT_RISK)
        dispatch(consumerDutyActions.setSelectedTab(TABS_NAME.AT_RISK_NOTES));
      else {
        const tab = toString(+selectedTab + 1);
        dispatch(consumerDutyActions.setSelectedTab(tab));
      }
    } else dispatch(consumerDutyActions.setIsReviewChangesPopupVisible(true));
  };

  return (
    <>
      <Col span={24}>
        <Row>
          <Col span={8}>
            <Button itemProp="secondary" onClick={onExitButtonClick}>
              {BUTTONS_LABEL.EXIT}
            </Button>
          </Col>
          <StyledCenterCol span={8}>
            <Button
              disabled={
                !lockedPledgeDetails.length ||
                isStoreEnquiryModeActiveForCase ||
                isViewOnlyMode
              }
              onClick={onManageServicesClick}
            >
              {BUTTONS_LABEL.MANAGE_SERVICES}
            </Button>
            {selectedTaskOwner !== TASK_OWNER_VALUE.AT_RISK && (
              <Button
                onClick={onNewPledgePermissionClick}
                disabled={isStoreEnquiryModeActiveForCase || isViewOnlyMode}
              >
                {BUTTONS_LABEL.NEW_PLEDGE_PERMISSION}
              </Button>
            )}
          </StyledCenterCol>
          <StyledCol span={8}>
            <Button itemProp="secondary" onClick={onPreviousClick}>
              {BUTTONS_LABEL.PREVIOUS}
            </Button>
            <Button itemProp="secondary" onClick={onNextClick}>
              {BUTTONS_LABEL.NEXT}
            </Button>
          </StyledCol>
        </Row>
      </Col>
    </>
  );
};

export default Footer;
