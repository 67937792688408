export const GET_BASKET = 'GET_BASKET'; // get basket by customerId
export const GET_BASKET_BY_BASKETID = 'GET_BASKET_BY_BASKETID'; // get basket by basketId
export const DELETE_BASKET = 'DELETE_BASKET'; //delete a customer basket using basket id
export const DELETE_BASKET_SERVICE = 'DELETE_BASKET_SERVICE'; //delete a basket service
export const LOAN_AMOUNT_CALCULATOR_ON_BASKET =
  'LOAN_AMOUNT_CALCULATOR_ON_BASKET';

//CREATE
export const CREATE_BASKET_PLEDGE_ITEMS = 'CREATE_BASKET_PLEDGE_ITEMS';
export const CREATE_BASKET_RETAIL_ITEMS = 'CREATE_BASKET_RETAIL_ITEMS';
export const CREATE_BASKET_PICKUP_RENEW_ITEMS =
  'CREATE_BASKET_PICKUP_RENEW_ITEMS';
export const CREATE_BASKET_CHEQUE_CASHING_ITEMS =
  'CREATE_BASKET_CHEQUE_CASHING_ITEMS';
export const CEATE_BASKET_WESTERN_UNION = 'CEATE_BASKET_WESTERN_UNION';
export const CREATE_BASKET_FOR_PICKUP_OR_PURCHASE =
  'CREATE_BASKET_FOR_PICKUP_OR_PURCHASE';
export const PUT_TEN_ITEMS_IN_BASKET = 'PUT_TEN_ITEMS_IN_BASKET';

//EDIT
export const EDIT_BASKET_PLEDGE_ITEMS = 'EDIT_BASKET_PLEDGE_ITEMS';
export const EDIT_BASKET_RETAIL_ITEMS = 'EDIT_BASKET_RETAIL_ITEMS';
export const EDIT_BASKET_CHEQUE_CASHING_ITEMS =
  'EDIT_BASKET_CHEQUE_CASHING_ITEMS';

//CALCULATION
export const SPLIT_BASKET_PLEDGE_ITEMS = 'SPLIT_BASKET_PLEDGE_ITEMS';
export const MERGE_BASKET_PLEDGE_ITEMS = 'MERGE_BASKET_PLEDGE_ITEMS';
export const MOVE_BASKET_PLEDGE_ITEMS = 'MOVE_BASKET_PLEDGE_ITEMS'; //move items to new tab
export const MOVE_BASKET_PLEDGE_PURCHASE_ITEMS =
  'MOVE_BASKET_PLEDGE_PURCHASE_ITEMS'; //move items from pledge to purchase and viec-versa
export const BASKET_PLEDGE_LOAN_CALCULATE = 'BASKET_PLEDGE_LOAN_CALCULATE';

//DELETE
export const DELETE_BASKET_PLEDGE_ITEMS = 'DELETE_BASKET_PLEDGE_ITEMS';
export const DELETE_BASKET_FX_ITEMS = 'DELETE_BASKET_FX_ITEMS';
export const DELETE_BASKET_CHEQUE_CASHING_ITEMS =
  'DELETE_BASKET_CHEQUE_CASHING_ITEMS';
export const DELETE_BASKET_RETAIL_ITEMS = 'DELETE_BASKET_RETAIL_ITEMS';
export const DELETE_BASKET_PICKUP_RENEW_ITEMS =
  'DELETE_BASKET_PICKUP_RENEW_ITEMS';
export const DELETE_BASKET_WESTERN_UNION_ITEMS =
  'DELETE_BASKET_WESTERN_UNION_ITEMS';
export const DELETE_BASKET_PURCHASE_RETURN_ITEMS =
  'DELETE_BASKET_PURCHASE_RETURN_ITEMS';

//MERGE
export const MERGE_GUEST_BASKET_REQUEST = 'MERGE_GUEST_BASKET_REQUEST';
export const RECALL_MERGE_BASKETS = 'RECALL_MERGE_BASKETS';
export const UPDATE_CUSTOMER_DETAILS = 'UPDATE_CUSTOMER_DETAILS';

//BASKET SERVICES VALIDATION
export const VALIDATE_ALL_SERVICES_BASKET = 'VALIDATE_ALL_SERVICES_BASKET';
export const VALIDATE_PLEDGE_SERVICE_BASKET = 'VALIDATE_PLEDGE_SERVICE_BASKET';
export const VALIDATE_RETAIL_SERVICE_BASKET = 'VALIDATE_RETAIL_SERVICE_BASKET';
export const VALIDATE_PICKUP_SERVICE_BASKET = 'VALIDATE_PICKUP_SERVICE_BASKET';
export const VALIDATE_FX_SERVICE_BASKET = 'VALIDATE_FX_SERVICE_BASKET';
export const VALIDATE_TPC_SERVICE_BASKET = 'VALIDATE_TPC_SERVICE_BASKET';
export const VALIDATE_PURCHASE_SERVICE_BASKET =
  'VALIDATE_PURCHASE_SERVICE_BASKET';
export const VALIDATE_WU_SERVICE_BASKET = 'VALIDATE_WU_SERVICE_BASKET';
export const VALIDATE_PURCHASE_RETURN_SERVICE_BASKET =
  'VALIDATE_PURCHASE_RETURN_SERVICE_BASKET';
export const VALIDATE_USER_TILL_BALANCE = 'VALIDATE_USER_TILL_BALANCE';

export const CREATE_BASKET_SURPLUS_ITEMS = 'CREATE_BASKET_SURPLUS_ITEMS';
export const DELETE_BASKET_SURPLUS_ITEMS = 'DELETE_BASKET_SURPLUS_ITEMS';
export const VALIDATE_SURPLUS_SERVICE_BASKET =
  'VALIDATE_SURPLUS_SERVICE_BASKET';

//consumer duty validations
export const VALIDATE_CONSUMER_DUTY = 'VALIDATE_CONSUMER_DUTY';
export const VALIDATE_LOAN_ACTIONS = 'VALIDATE_LOAN_ACTIONS';
export const VALIDATE_NEW_PLEDGE_PERMISSIONS =
  'VALIDATE_NEW_PLEDGE_PERMISSIONS';
