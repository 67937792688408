import * as urls from '../apiUrls';
import { doRequest } from 'utils/request';

const getStoreUsers = (storeId) => {
  return doRequest(`${urls.GET_STORE_USERS}`, 'get');
};

const getTillCurrencies = (userId) => {
  return doRequest(`${urls.GET_TILL_CURRENCIES}/${userId}`, 'get');
};

const getCurrencies = () => {
  return doRequest(`${urls.GET_CURRENCIES}`, 'get');
};

const getCurrencyDenominations = (currencyCode) => {
  return doRequest(`${urls.GET_CURRENCY_DENOMINATIONS}/${currencyCode}`, 'get');
};

const getLedgerDenominationsHistory = (selectedId) => {
  return doRequest(
    `${urls.CASH_MNGMT_LEDGER_DENOMINATION_HISTORY}/${selectedId}`,
    'get'
  );
};

const getFXDenominationsHistory = (selectedId) => {
  return doRequest(
    `${urls.CASH_MNGMT_FX_DENOMINATION_HISTORY}/${selectedId}`,
    'get'
  );
};

const getPettyCashCategories = (direction) => {
  return doRequest(`${urls.GET_PETTY_CASH_CATEGORIES}/${direction}`, 'get');
};

const getTillVariance = (params) => {
  return doRequest(`${urls.GET_TILL_VARIANCE}`, 'post', params);
};

const getFxTillVariance = (params) => {
  return doRequest(`${urls.GET_FX_TILL_VARIANCE}`, 'post', params);
};

const postCashUp = (params, authorisationHeaders) => {
  return doRequest(
    `${urls.POST_CASH_UP}`,
    'post',
    params,
    '',
    false,
    '',
    authorisationHeaders
  );
};

const postFxCashUp = (params, authorisationHeaders) => {
  return doRequest(
    `${urls.POST_FX_CASH_UP}`,
    'post',
    params,
    '',
    false,
    '',
    authorisationHeaders
  );
};

const getStoreTillStatus = () => {
  return doRequest(`${urls.GET_STORE_TILL_STATUS}`, 'get');
};

const getStoreTillHistory = (params) => {
  return doRequest(`${urls.GET_STORE_TILL_HISTORY}`, 'post', params);
};

const generateCashTransferSlip = (id, authorisationHeaders) => {
  return doRequest(
    `${urls.GENERATE_CASHTRANSFERSLIP_GBP}/${id}`,
    'GET',
    '',
    'application/json',
    false,
    'arraybuffer',
    authorisationHeaders
  );
};

const generateCashTransferSlipFx = (id, authorisationHeaders) => {
  return doRequest(
    `${urls.GENERATE_CASHTRANSFERSLIP_FX}/${id}`,
    'GET',
    '',
    'application/json',
    false,
    'arraybuffer',
    authorisationHeaders
  );
};

const postCashTransfer = (params, authorisationHeaders) => {
  return doRequest(
    `${urls.POST_CASH_TRANSFER}`,
    'post',
    params,
    '',
    false,
    '',
    authorisationHeaders
  );
};

const postFXTransfer = (params, authorisationHeaders) => {
  return doRequest(
    `${urls.POST_FX_TRANSFER}`,
    'post',
    params,
    '',
    false,
    '',
    authorisationHeaders
  );
};

const postPettyCash = (params, authorisationHeaders) => {
  return doRequest(
    `${urls.POST_PETTY_CASH}`,
    'post',
    params,
    '',
    false,
    '',
    authorisationHeaders
  );
};

const getDestinationStoreList = () => {
  return doRequest(`${urls.DESTINATION_STORE_LIST}`, 'get');
};

const getSterlingValueForCashDelivery = (params) => {
  return doRequest(
    `${urls.GET_STERLING_VALUE_FOR_DELIVERY}`,
    'post',
    params,
    '',
    true
  );
};

const getFxPendingTransfers = () => {
  return doRequest(`${urls.GET_FXSTORE_PENDING_TRANSFERS}`, 'get');
};

const postCashDelivery = (params, authorisationHeaders) => {
  return doRequest(
    `${urls.POST_CASH_DELIVERY}`,
    'post',
    params,
    '',
    false,
    '',
    authorisationHeaders
  );
};

const postFxDelivery = (params, authorisationHeaders) => {
  return doRequest(
    `${urls.POST_FX_DELIVERY}`,
    'post',
    params,
    '',
    false,
    '',
    authorisationHeaders
  );
};

const postFXInboundDelivery = (params, authorisationHeaders) => {
  return doRequest(
    `${urls.POST_FX_INBOUND_DELIVERY}`,
    'post',
    params,
    '',
    false,
    '',
    authorisationHeaders
  );
};

const postEndOfDay = () => {
  return doRequest(`${urls.END_OF_DAY}`, 'post');
};

export {
  getStoreUsers,
  getTillCurrencies,
  getCurrencyDenominations,
  getTillVariance,
  getFxTillVariance,
  postCashUp,
  postFxCashUp,
  getStoreTillStatus,
  getStoreTillHistory,
  getCurrencies,
  postCashTransfer,
  postFXTransfer,
  getPettyCashCategories,
  postPettyCash,
  getDestinationStoreList,
  postCashDelivery,
  postFxDelivery,
  getFxPendingTransfers,
  getSterlingValueForCashDelivery,
  getLedgerDenominationsHistory,
  getFXDenominationsHistory,
  postFXInboundDelivery,
  generateCashTransferSlip,
  generateCashTransferSlipFx,
  postEndOfDay,
};
