import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Modal, Row, Spin } from 'antd';
import styled from 'styled-components';
import { format } from 'date-fns';
import { FNS_DATE_FORMAT } from '../../globalConstants';
import { PAID_PAYMENT_DETAILS } from './constants';
import { actions } from 'redux/reducers/pickupRenew';

const StyledCol = styled(Col)`
  max-height: 335px !important;
  overflow: auto;
`;

interface PaidPaymentDetailsPopupProps {
  visible: boolean;
  setPaidPaymentPopupVisible: () => void;
}

const PaidPaymentDetailsPopup = ({
  visible,
  setPaidPaymentPopupVisible,
}: PaidPaymentDetailsPopupProps) => {
  const {
    pickupRenew: { paidPledgeDetails, isApiLoading },
  } = useSelector((state: any) => {
    return {
      pickupRenew: state.pickupRenew,
    };
  });
  const dispatch = useDispatch();

  const tableRow = (
    index: number,
    date: any,
    amount: number,
    paymentLocation: string,
    methodOfPayment: string
  ) => {
    return (
      <tr key={index}>
        <td>{format(new Date(date), FNS_DATE_FORMAT)}</td>
        <td>{`£${amount?.toFixed(2)}`}</td>
        <td>{paymentLocation}</td>
        <td>{methodOfPayment}</td>
      </tr>
    );
  };
  const onCancel = () => {
    setPaidPaymentPopupVisible();
    dispatch(actions.getPaidPledgePayment([]));
  };

  return (
    <>
      <Modal
        title="Paid Payment Details"
        open={visible}
        width={650}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancel}
        footer={null}
        centered
      >
        <Row>
          <StyledCol span={24}>
            {isApiLoading ? (
              <Spin />
            ) : (
              <table className="paid-payment-table">
                <thead>
                  <tr>
                    <th>{PAID_PAYMENT_DETAILS.DATE}</th>
                    <th>{PAID_PAYMENT_DETAILS.AMOUNT_PAID}</th>
                    <th>{PAID_PAYMENT_DETAILS.PAYMENT_LOCATION}</th>
                    <th>{PAID_PAYMENT_DETAILS.METHOD_OF_PAYMENT}</th>
                  </tr>
                </thead>
                <tbody>
                  {paidPledgeDetails?.length > 0 ? (
                    paidPledgeDetails.map((item: any, index: number) => {
                      return tableRow(
                        index,
                        item.transactionDate,
                        item.paidAmount,
                        item.paymentLocation,
                        item.paymentMethod
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4} style={{ textAlign: 'center' }}>
                        No Items
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </StyledCol>
        </Row>
      </Modal>
    </>
  );
};

export default PaidPaymentDetailsPopup;
