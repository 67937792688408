import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { getStoreTillStatus } from 'services/cashManagement';
import { actions } from 'redux/reducers/cashManagement';
import { TILL_LABELS } from '../constants';
import StoreTillStatusTable from './StoreTillStatusTable';
import '../index.less';

const StyledRow = styled(Row)`
  padding: 16px;
  flex-grow: 1;
`;

const StyledText = styled.div<{ fontSize: string; padding: string }>`
  font-weight: var(--font-weight-500);
  font-size: var(--${(props) => props.fontSize});
  padding: ${(props) => props.padding};
`;

const StyledTextRow = styled(Row)`
  margin-block-end: 2px;
`;

const StyledGridCol = styled(Col)`
  height: 70vh;
`;

const ViewTill = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    getStoreTillsStatus();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getStoreTillsStatus = async () => {
    try {
      const response = await getStoreTillStatus();
      if (response?.data) {
        dispatch(actions.setStoreTillStatusData(response.data));
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <>
      <StyledRow>
        <Col span={24}>
          <StyledTextRow justify="space-between">
            <Col span={24}>
              <StyledText fontSize="font-size-16" padding="0">
                {TILL_LABELS.STORE_TILL_STATUS}
              </StyledText>
            </Col>
          </StyledTextRow>

          <Row justify="space-between">
            <StyledGridCol className="cc-table-wrapper" span={24}>
              <StoreTillStatusTable />
            </StyledGridCol>
          </Row>
        </Col>
      </StyledRow>
    </>
  );
};

export default ViewTill;
