import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';

import InterestRateTable from './InterestRateTable';

import { cacheStaticData } from 'utils/cacheServices';
import { getPledgeInterestRate } from 'services/pledge';

const ViewInterestTable = ({ onCancelClick }: any) => {
  const valueArray: any = [];
  let lowerLimit = 0;

  const [interestRateData, setInterestRateData] = useState<Array<any>>([]);

  useEffect(() => {
    getInterestRateBand();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getInterestRateBand = async () => {
    try {
      const response = await cacheStaticData(getPledgeInterestRate);
      if (response?.data) {
        const maxBand = 999999;

        response?.data?.interestRates?.forEach((item: any) => {
          if (item?.loanAmountUpperLimit < maxBand) {
            const obj = {
              loanAmountLowerLimit:
                (item?.loanAmountUpperLimit > 500 ? lowerLimit + 1 : 0) + '-',
              loanAmountUpperLimit: item?.loanAmountUpperLimit,
              monthlyInterestPercentCharge: item?.monthlyInterestPercentCharge,
              publishedInterestAnnualPercentageRate:
                item?.publishedInterestAnnualPercentageRate,
            };

            lowerLimit = obj?.loanAmountUpperLimit;
            valueArray.push(obj);
          }
          if (
            item?.loanAmountUpperLimit === maxBand &&
            item?.loanInterestRateId === 140
          ) {
            const obj = {
              loanAmountLowerLimit: lowerLimit + 1,
              loanAmountUpperLimit: '+',
              monthlyInterestPercentCharge: item?.monthlyInterestPercentCharge,
              publishedInterestAnnualPercentageRate:
                item?.publishedInterestAnnualPercentageRate,
            };
            valueArray.push(obj);
          }
        });

        setInterestRateData(valueArray);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <Modal
      open
      centered
      title={'Interest Rate Bands'}
      closable={true}
      width={654}
      maskClosable={false}
      onCancel={onCancelClick}
      footer={''}
    >
      <InterestRateTable interestRateBand={interestRateData} />
    </Modal>
  );
};

export default ViewInterestTable;
