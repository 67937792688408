import React, { useState, useEffect } from 'react';
import { Col, Modal, Row, Input, Form, Select, Checkbox } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { currencyFormat } from 'utils/util';
import { Button } from 'components/common/Button';
import { actions } from 'redux/reducers/pickupRenew';
import {
  GET_AFFIDAVIT_REASONS,
  CALCULATE_PLEDGE_AGREEMENT_LOAN_AMOUNT,
} from 'action_creators/pickupRenew';
import { AFFIDAVIT, ACTION_AFFI_ONLY, WAIVE_INTEREST } from './constants';
import './index.less';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import { CloseOutlined } from '@ant-design/icons/lib/icons';

const StyledAgreementNum = styled.td`
  font-weight: var(--font-weight-500) !important;
`;

const StyledCustomerName = styled.td`
  font-weight: var(--font-weight-500) !important;
  color: var(--mountain-meadow) !important;
`;

const StyledSelect = styled(Select)`
  & .ant-select-selector {
    height: 42px !important;
  }
`;

const StyledRemove = styled(CloseOutlined)`
  cursor: pointer;
  color: var(--red);
  font-size: var(--font-size-20);
  padding: 0 5px;
  position: relative;
  top: 3px;
`;

const StyledRow = styled(Row)`
  padding-bottom: 20px;
  font-weight: var(--font-weight-500);
`;

const StyledCol = styled(Col)`
  padding-left: 10px;
`;

interface AffidavitPopupProps {
  visible: boolean;
  setAffidavitPopupVisible: () => void;
  affidavitData: any;
  setSelectedActionClear: (params: any) => void;
}

const AffidavitPopup = ({
  visible,
  setAffidavitPopupVisible,
  affidavitData,
  setSelectedActionClear,
}: AffidavitPopupProps) => {
  const {
    pickupRenew: { affidavitReasons },
  } = useSelector((state: any) => {
    return {
      pickupRenew: state.pickupRenew,
    };
  });

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [affidavitFee, setAffidavitFee] = useState<number>(0);
  const [selectedReason, setSelectedReason] = useState<any>(null);
  const [isClosedPledge, setIsClosedPledge] = useState<boolean>(false);
  const [isWaivedOffAffidavit, setIsWaivedOffAffidavit] =
    useState<boolean>(false);

  const onCancelClick = () => {
    dispatch(actions.setSelectedAgreementRow([]));
    setAffidavitPopupVisible();
  };

  const onConfirmClick = () => {
    const updatedAgreement = {
      ...affidavitData,
      affidavitFees: {
        ...affidavitData.affidavitFees,
        reasonId: form.getFieldValue('reasonAffidavit'),
      },
      affidavitAmount: affidavitFee,
      waiveInterest: {
        ...affidavitData.waiveInterest,
        isWaivedOffAffidavitAmount:
          affidavitFee <= 0 ? false : isWaivedOffAffidavit,
      },
    };

    if (affidavitData.selectedActionType === null) {
      dispatch(actions.updatePledgeAgreement(updatedAgreement));
    } else if (
      ACTION_AFFI_ONLY.includes(affidavitData.selectedActionType) &&
      affidavitFee === 0
    ) {
      dispatch(actions.updatePledgeAgreement(updatedAgreement));
      setSelectedActionClear(updatedAgreement);
    } else {
      const params = {
        agreement: updatedAgreement,
      };
      dispatch({
        type: CALCULATE_PLEDGE_AGREEMENT_LOAN_AMOUNT,
        payload: { params },
      });
    }
    dispatch(actions.setSelectedAgreementRow([]));
    setAffidavitPopupVisible();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); //Need this console for checking error
  };

  const onAffidavitReasonsChange = (e: any) => {
    setSelectedReason(e);
    form.setFieldsValue({
      reasonAffidavit: e,
    });
  };

  const onRemoveClick = () => {
    if (form.getFieldValue('reasonAffidavit') !== null) {
      setAffidavitFee(0);
      setSelectedReason(null);
      form.setFieldsValue({
        reasonAffidavit: null,
      });
    }
  };

  const onWaiveAffidavitFeeClick = (e: any) => {
    setIsWaivedOffAffidavit(e?.target?.checked);
  };

  useEffect(() => {
    setIsClosedPledge(!affidavitData?.isValidForProcess);
    dispatch({
      type: GET_AFFIDAVIT_REASONS,
    });
    setAffidavitFee(affidavitData?.affidavitFees?.totalFees);
    setSelectedReason(affidavitData?.affidavitFees?.reasonId);
    form.setFieldsValue({
      reasonAffidavit: affidavitData?.affidavitFees?.reasonId,
    });
    setIsWaivedOffAffidavit(
      affidavitData?.waiveInterest?.isWaivedOffAffidavitAmount
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Modal
        title={
          <HelpPopoverInfo
            linkedID={`PICKUPRENEW_PRINTAFFIDAVIT`}
            position="leftTop"
          >
            <span>Print Affidavit</span>
          </HelpPopoverInfo>
        }
        open={visible}
        width={850}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        footer={[
          <Row gutter={24} justify="end" key="affidavitPopup">
            <Col>
              <Button
                itemProp="secondary"
                key="cancelAffidavit"
                onClick={onCancelClick}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                key="confirmAffidavit"
                htmlType="submit"
                form="affidavitForm"
                disabled={affidavitFee > 0 && selectedReason === null}
              >
                Confirm
              </Button>
            </Col>
          </Row>,
        ]}
        centered
      >
        <Row>
          <Col span={24}>
            <Col span={24} className="waive-interest-wrapper">
              <table className="waive-interest-table">
                <thead>
                  <tr>
                    <th>{AFFIDAVIT.AGREEMENT_NO}</th>
                    <th>{AFFIDAVIT.CUSTOMER_NAME}</th>
                    <th style={{ textAlign: 'right' }}>
                      {AFFIDAVIT.LOAN_AMOUNT}
                    </th>
                    <th style={{ textAlign: 'right' }}>
                      {AFFIDAVIT.AFFIDAVIT_FEE}
                    </th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <StyledAgreementNum>
                      {affidavitData?.affidavitFees?.loanNumber}
                    </StyledAgreementNum>
                    <StyledCustomerName>
                      {affidavitData?.affidavitFees?.customerName}
                    </StyledCustomerName>
                    <td style={{ textAlign: 'right' }}>
                      {`£${currencyFormat(
                        affidavitData?.affidavitFees?.loanAmount?.toFixed(2)
                      )}`}
                    </td>
                    <td
                      style={{ textAlign: 'right' }}
                      className="pledge-amount-waived"
                    >
                      <Input
                        type="text"
                        value={`£${currencyFormat(affidavitFee.toFixed(2))}`}
                        disabled
                        className="amount-waived-input"
                        addonBefore={'£'}
                        placeholder="0.00"
                      />
                    </td>
                    <td>
                      {!isClosedPledge ? (
                        <div>
                          <span>Remove</span>
                          <span>
                            <StyledRemove onClick={onRemoveClick} />
                          </span>
                        </div>
                      ) : null}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Form
              form={form}
              layout="vertical"
              id="affidavitForm"
              onFinish={onConfirmClick}
              onFinishFailed={onFinishFailed}
            >
              <Col span={24}>
                <StyledRow>
                  <Col span={5}>{`${
                    WAIVE_INTEREST.WAIVE_AFFIDAVIT_FEE
                  }: ${currencyFormat(
                    affidavitData?.affidavitAmount,
                    true
                  )}`}</Col>
                  <StyledCol span={4}>
                    <Checkbox
                      disabled={
                        affidavitData?.affidavitAmount <= 0 ||
                        !affidavitData?.isValidForProcess
                      }
                      onChange={onWaiveAffidavitFeeClick}
                      checked={isWaivedOffAffidavit}
                    ></Checkbox>
                  </StyledCol>
                </StyledRow>

                <Row>
                  <Col span={14}>
                    <Form.Item
                      label={
                        <HelpPopoverInfo
                          linkedID={`PICKUPRENEW_REASONAFFIDAVIT`}
                          position="leftTop"
                        >
                          <span>{AFFIDAVIT.REASON_FOR_AFFIDAVIT}</span>
                        </HelpPopoverInfo>
                      }
                      name="reasonAffidavit"
                      className="label-control"
                    >
                      <StyledSelect
                        showSearch
                        placeholder="Select"
                        value={selectedReason}
                        onChange={onAffidavitReasonsChange}
                        filterOption={(input: any, option: any) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={isClosedPledge}
                      >
                        {affidavitReasons.map((item: any, index: number) => {
                          return (
                            <Select.Option
                              value={item.reasonId}
                              key={item.reasonId}
                            >
                              {item.description}
                            </Select.Option>
                          );
                        })}
                      </StyledSelect>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default AffidavitPopup;
