import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Row, Col, notification } from 'antd';
import styled from 'styled-components';
import {
  getFxTillVariance,
  getTillVariance,
  // postEndOfDay,
} from 'services/cashManagement';
import { actions } from 'redux/reducers/cashManagement';
import CustomerHeader from 'components/customer/customerHeader';
import FooterButtons from 'components/common/footerButtons';
import { Button } from 'components/common/Button';
import ControlledAlert from 'components/common/ControlledAlert';
import ExpenseInOutPopup from 'components/cashManagement/ExpenseInOut';
import CashDeliveryPopup from 'components/cashManagement/CashDelivery';
import CreateTill from './CreateTill';
import ViewTill from './StoreTill';
import { END_OF_DAY_POPUP } from './constants';
import TillHistoryPopup from './TillHistory';

const StyledMainContainer = styled.div<{ $leftContainer: boolean }>`
  background: var(--white);
  border: 0.6px solid var(--linkwater);
  border-bottom: 1px solid var(--linkwater);
  box-shadow: 0px 1px 10px rgba(1, 78, 169, 0.2);
  border-radius: 5px 5px 0 0;
  margin: ${(props) =>
    props.$leftContainer ? '0px 5px 0px 20px' : '0px 20px 0px 5px'};
  color: var(--black);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledFooterRow = styled(Row)<{ margin: string }>`
  margin: ${(props) => props.margin};
`;

const StyledGridRow = styled(Row)`
  height: 91%;
`;

const StyledBottomRow = styled(Row)`
  padding: 15px 30px;
  background: var(--white);
  border: 0.6px solid var(--linkwater);
  border-top: none;
  box-shadow: rgba(1, 78, 169, 0.2) 0px 4px 10px;
  border-radius: 0px 0px 5px 5px;
`;

const StyledButton = styled(Button)`
  margin: 0 15px;
`;

const StyledFooterDiv = styled.div`
  & .footer-buttons {
    padding: 15px 30px;
  }
`;

interface TillDataProps {
  currencyCode?: string;
  userTillTotal?: number;
  userId?: number;
}

interface TillVarianceProps {
  userInputTotal: number;
  systemBalance: number;
  variance: number;
}

const CashManagement = () => {
  const [userTillValues, setUserTillValues] = useState<TillDataProps>();
  const [userTillVarianceValues, setUserTillVarianceValues] =
    useState<TillVarianceProps>();
  const [isTillLoading, setTillLoading] = useState<boolean>(false);
  const [isTillHistoryPopupVisible, setTillHistoryPopupVisible] =
    useState<boolean>(false);
  const [isEndOfDayPopupVisible, setEndOfDayPopupVisible] =
    useState<boolean>(false);
  const [isDenominationAmountValid, setIsDenominationAmountValid] =
    useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    cashManagement: {
      isExpenseInOutPopupVisible,
      isCashDeliveryPopupVisible,
      // isEndOfDayAvailable,
      currencyDenominations,
    },
  } = useSelector((state: any) => {
    return {
      cashManagement: state.cashManagement,
    };
  });

  useEffect(() => {
    const isValuesValid = currencyDenominations.every(
      (el: any) => !el?.isInvalidAmount
    );
    setIsDenominationAmountValid(isValuesValid);
  }, [currencyDenominations]); // eslint-disable-line react-hooks/exhaustive-deps

  const setUserTillData = (params: any) => {
    setUserTillValues(params);
  };

  const onTillCheckClick = async () => {
    const selectedDenominations = currencyDenominations.reduce(
      (arr: any, obj: any) => {
        return [
          ...arr,
          {
            currencyDenomination: +obj.denomination,
            quantity: +obj?.quantity || 0,
            value: +obj?.amount || 0,
          },
        ];
      },
      []
    );

    const params = {
      tillUserId: userTillValues?.userId,
      totalCountBalance: userTillValues?.userTillTotal,
      currencyCode: userTillValues?.currencyCode,
      denominations: selectedDenominations,
    };

    try {
      setTillLoading(true);
      if (userTillValues?.currencyCode === 'GBP') {
        const response = await getTillVariance(params);
        if (response?.data) {
          setTillLoading(false);
          setUserTillVarianceValues(response?.data);
        }
      } else {
        const response = await getFxTillVariance(params);
        if (response?.data) {
          setTillLoading(false);
          setUserTillVarianceValues(response?.data);
        }
      }
    } catch (e: any) {
      setTillLoading(false);
      console.log(e);
      notification.error({
        message: e?.response?.data?.error || e?.message,
        duration: 2,
      });
    }
  };

  const setCashDeliveryPopupVisible = () => {
    dispatch(actions.setCashDeliveryPopupVisiblity(false));
  };

  const setExpenseInOutPopupVisible = () => {
    dispatch(actions.setExpenseInOutPopupVisibility(false));
  };

  const onExitClick = () => {
    dispatch(actions.clearItems());
    navigate('/');
  };

  const onResetClick = () => {
    setUserTillVarianceValues(undefined);
    dispatch(actions.updateReset(true));
  };

  const onTillHistoryClick = () => {
    setTillHistoryPopupVisible(true);
  };

  // const onEndOfDayClick = async () => {
  //   try {
  //     const response = await postEndOfDay();
  //     if (response?.data?.success) {
  //       setEndOfDayPopupVisible(true);
  //     } else {
  //       notification.error({
  //         message: response?.data?.message,
  //         duration: 2,
  //       });
  //     }
  //   } catch (e: any) {
  //     console.log(e);
  //     notification.error({
  //       message: e?.response?.data?.error || e?.message,
  //       duration: 2,
  //     });
  //   }
  // };

  return (
    <>
      <CustomerHeader
        title="Cash Management"
        buttonsOption={false}
        cashMgmtButtons={true}
        isCustomerNeeded={false}
      />
      <Row>
        <Col span={11}>
          <StyledGridRow>
            <Col span={24}>
              <StyledMainContainer $leftContainer={true}>
                <CreateTill
                  setUserTillData={setUserTillData}
                  userTillVarianceValues={userTillVarianceValues}
                  resetTillVariance={() => setUserTillVarianceValues(undefined)}
                />
              </StyledMainContainer>
            </Col>
          </StyledGridRow>
          <StyledFooterRow margin="0px 5px 0px 20px">
            <Col span={24}>
              <StyledBottomRow>
                <Col span={18}>
                  <Row align="bottom" justify="start">
                    <StyledButton
                      itemProp="secondary"
                      onClick={onExitClick}
                      style={{ marginLeft: 0 }}
                    >
                      Exit
                    </StyledButton>
                    <StyledButton
                      itemProp="secondary"
                      onClick={onTillCheckClick}
                      disabled={
                        !userTillValues?.currencyCode ||
                        !isDenominationAmountValid
                      }
                      loading={isTillLoading}
                    >
                      Till Check
                    </StyledButton>
                    <StyledButton
                      itemProp="secondary"
                      onClick={onResetClick}
                      disabled={!userTillValues?.currencyCode}
                    >
                      Reset
                    </StyledButton>
                  </Row>
                </Col>
                <Col span={6}>
                  <Row align="bottom" justify="end">
                    <Button itemProp="secondary" onClick={onTillHistoryClick}>
                      Till History
                    </Button>
                  </Row>
                </Col>
              </StyledBottomRow>
            </Col>
          </StyledFooterRow>
        </Col>
        <Col span={13}>
          <StyledGridRow>
            <Col span={24}>
              <StyledMainContainer $leftContainer={false}>
                <ViewTill />
              </StyledMainContainer>
            </Col>
          </StyledGridRow>
          <StyledFooterRow margin="0px 20px 0px 5px">
            <Col span={24}>
              <StyledFooterDiv>
                <FooterButtons
                  //Commenting this code as per Story MP - 5352
                  //isProceedButtonRequired={true}
                  // proceedButtonText={TILL_LABELS.BUTTON.END_DAY} //End Day
                  // onProceedClick={onEndOfDayClick}
                  // proceedButtonClassName={
                  //   !isEndOfDayAvailable
                  //     ? 'end-day-button-disabled' // visibility-hidden to be removed when EOD fixed MP-4702 in sprint 37.
                  //     : 'end-day-button-enabled'
                  // }
                  isExitRequired={false}
                  // isDisabled={!isEndOfDayAvailable ? true : false}
                  // isDisabled // disabling until EOD issue MP-4702 is fixed in sprint 37.
                />
              </StyledFooterDiv>
            </Col>
          </StyledFooterRow>
        </Col>
      </Row>

      {isCashDeliveryPopupVisible && (
        <CashDeliveryPopup
          visible={isCashDeliveryPopupVisible}
          setCashDeliveryPopupVisible={() => setCashDeliveryPopupVisible()}
        />
      )}
      {isExpenseInOutPopupVisible && (
        <ExpenseInOutPopup
          visible={isExpenseInOutPopupVisible}
          setExpenseInOutPopupVisible={() => setExpenseInOutPopupVisible()}
        />
      )}
      {isTillHistoryPopupVisible && (
        <TillHistoryPopup
          visible={isTillHistoryPopupVisible}
          setTillHistoryPopupVisible={() => setTillHistoryPopupVisible(false)}
        />
      )}

      {isEndOfDayPopupVisible && (
        <ControlledAlert
          visible={isEndOfDayPopupVisible}
          alertMessage={END_OF_DAY_POPUP.TITLE}
          alertMessageStyle={{ color: 'var(--text-color)' }}
          contentMessage={END_OF_DAY_POPUP.CONTENT}
          message={END_OF_DAY_POPUP.MESSAGE}
          onClick={() => setEndOfDayPopupVisible(false)}
          yesButtonText={'OK'}
          isCancelBtn={false}
        />
      )}
    </>
  );
};

export default CashManagement;
