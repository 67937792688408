import React from 'react';
import { Col, Row, Space } from 'antd';

type Props = {
  text: string;
  classes: string;
};

function GreenTickStrip({ text, classes }: Props) {
  return (
    <Row className={`success-light-bg p-10 ${classes}`}>
      <Col>
        <Space>
          <img
            alt="green tick"
            className="green-tick"
            src={require('../../assets/images/tick.svg').default}
          />
          <span className="bold">{text}</span>
        </Space>
      </Col>
    </Row>
  );
}

function GreenTickBankTransferCompleted({ classes, children }: any) {
  return (
    <>
      <Row className={`p-10 ${classes}`}>
        <Col>
          <Row justify="center">
            <Col>
              <img
                alt="green tick"
                className="green-tick"
                src={require('../../assets/images/tick.svg').default}
              />
            </Col>
          </Row>
          <Row justify="center">
            <Col>{children}</Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export { GreenTickBankTransferCompleted, GreenTickStrip };

export default GreenTickStrip;
