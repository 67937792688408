export const INVENTORY_MANAGEMENT_TABS = {
  STOCK_TRANSFER: 'Stock Transfer',
  AUDIT_MANAGER: 'Audit Manager',
  BARCODE_MANAGER: 'Barcode Manager',
  LAYAWAY_MANAGER: 'Layaway Manager',
  INITIAL_PANES: [
    { title: 'Stock Transfer' },
    { title: 'Audit Manager' },
    { title: 'Barcode Manager' },
    { title: 'Layaway Manager' },
  ],
};

export const STOCK_TRANSFER_LABELS = {
  TITLES: {
    TRANSFER_MANAGER: 'Transfer Manager',
    CREATE_EDIT_TRANSFER: 'Create Edit Transfer',
    CREATE_NEW_TRANSFER: 'Create New Transfer',
    EDIT_TRANSFER: 'Edit Transfer',
    ACCEPT_TRANSFER: 'Accept Transfer',
    CLOSED_TRANSFER: 'Closed Transfer',
  },
  TRANSFER_MANAGER: {
    VIEWING: 'Viewing',
    FROM: 'From',
    TO: 'To',
  },
  CREATE_NEW_TRANSFER: {
    DESTINATION: 'Destination',
    BARCODE: 'Barcode',
    APPROVAL_TYPE: 'Sent',
  },
  ACCEPT_TRANSFER: {
    SOURCE: 'Source',
    BARCODE: 'Barcode',
    APPROVAL_TYPE: 'Recieved',
  },
  ALERT_BUTTON_LABEL: 'Save and Create New Transfer',
  AUTHORIZATION_MSG:
    'The colleague entered has confirmed the content(s) of this transfer are correct and true.',
  ERROR_MSG: {
    BARCODE_NOT_FOUND: 'Entered barcode not found.',
    BARCODE_REQUIRED: 'Barcode is required',
  },
  PRINT_LABEL: 'Print Transfer',
};

export const BARCODE_MANAGER_LABELS = {
  TITLES: {
    BARCODE_MANAGER: 'Barcode Manager',
  },
  TABLE_HEADINGS: {
    BARCODE: 'Barcode',
    DESCRIPTION :'Description',
    ITEMTYPE:'Item Type ',
    LOCATION:'Location',
    QUCIKKAT:'QuickCat/SKU',
    PRICE:'List Price/Due Customer/ Supplier'
  },
  FOOTER_BUTTON_LABEL: 'Print Barcode',
};

export const GENERIC_ERROR = 'Something went wrong. Please try again later';
