import React from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';

import ViewValuations from './viewValuations';
import ValuationWorkQueue from './valuationWorkQueue';

import './index.less';

const ValuationCenter = () => {
  const { isValuationViewMode } = useSelector(
    (state: any) => state.valuationCenter
  );

  return (
    <div className="valuation-center-wrapper">
      <Layout className="split-control-box">
        <div className="main-container-wrapper">
          {!isValuationViewMode ? <ValuationWorkQueue /> : <ViewValuations />}
        </div>
      </Layout>
    </div>
  );
};

export default ValuationCenter;
