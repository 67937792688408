export const ERROR_MESSAGE_ON_MTCN = {
  REFUND_ERROR_MESSAGE: 'Amount has already been refunded.',
  ERROR_MESSAGE: 'MTCN number already exists.',
  MTCN_ALREADY_USED: 'This MTCN has been used previously',
};

export const REFUND_AMOUNT_VALIDATION =
  'Refund amount cannot be more than received amount.';

export const WESTERN_UNION_FORM_ERRORS = {
  REFUNDED_TO_CUSTOMER_REQUIRED:
    "'Refunded to customer' field is required and value should be greater than zero.",
  EITHER_PAID_OR_RECEIVED_TO_CUSTOMER:
    "One of the fields- 'Received from customer' OR 'Paid to customer' is required and value should be greater than zero.",
};

export const TRANSACTION_TYPE = {
  RECEIVED : 'Received' , 
  PAID : 'Paid' ,
  REFUND : 'Refund' ,
}

