import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as checkoutActions from 'action_creators/checkout';
import { getBasketByBasketId, postCheckout } from 'services/checkout';
import { actions } from 'redux/reducers/checkout';

/**
 * this saga handles the GET checkout
 * (1) get checkout
 * (2) handle response
 */
export function* getCheckoutSaga(action) {
  try {
    const { basket } = yield select((state) => state.basket);
    if (!basket?.basketId) {
      return;
    }
    const { basketId } = basket;
    //request get checkout from API
    yield put(actions.initLoading(true));
    const payload = yield call(getBasketByBasketId, basketId);

    if (payload?.data) {
      yield put(actions.success(payload.data));
    } else if (payload?.errors) {
      yield put(actions.failed(payload?.errors));
    }
  } catch (e) {
    yield put(actions.failed(e));
  }
}

/**
 * this saga handles the GET checkout
 * (1) get checkout
 * (2) handle response
 */
export function* startCheckoutProgressSaga(action) {
  try {
    const {
      basket: { validateBasketServicesPayload },
      checkout: { basket },
      user: { selectedStore, user },
    } = yield select((state) => {
      return {
        checkout: state.checkout,
        basket: state.basket,
        user: state.user,
      };
    });

    const { basketId } = Object(basket);
    const employeeId = user?.employeeId;
    const userName = user?.displayName;

    //request checkout progress from API
    yield put(actions.initProgress());
    yield put(actions.clearRecheckAlert());
    const payload = yield call(postCheckout, basketId, {
      ...validateBasketServicesPayload,
      ...selectedStore,
      employeeId,
      userName,
    });
    if (payload?.data) {
      yield put(actions.success(payload.data));
    } else if (payload?.errors) {
      yield put(actions.failed(payload?.errors));
    }
  } catch (e) {
    yield put(actions.failed(e));
  }
}

export function* watchGetCheckout() {
  yield takeEvery(checkoutActions.GET_CHECKOUT, getCheckoutSaga);
}

export function* watchCheckoutProgress() {
  yield takeEvery(
    checkoutActions.CHECKOUT_PROGRESS_REQUEST,
    startCheckoutProgressSaga
  );
}
