import React, { useState } from 'react';
import { URL_TYPE } from 'globalConstants';
import VirtualGrid from 'components/common/virtualGrid';
import ImageLoader from 'components/common/ImageLoader';
import { currencyFormat } from 'utils/util';

const SubsequentRefundSummary = (props: any) => {
  const rows = props.rows;
  const [columns] = useState([
    {
      name: '',
      title: '',
    },
    {
      name: 'image',
      title: 'Image',
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row.stockItemId}`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
      allowSorting: false,
    },

    {
      name: 'barcode',
      title: 'Barcode',
      getCellValue: (row: any) => row?.barcode,
    },

    {
      name: 'description',
      title: 'Description',

      getCellValue: (row: any) => row?.description,
    },

    {
      name: 'listValue',
      title: 'List Value',

      getCellValue: (row: any) =>
        '£' + currencyFormat(row?.listValue?.toFixed(2)),
    },
    {
      name: 'discount',
      title: 'Discount',

      getCellValue: (row: any) =>
        '£' + currencyFormat(row?.discount?.toFixed(2)),
    },
    {
      name: 'amountPaid',
      title: 'Amount Paid',
      getCellValue: (row: any) =>
        '£' + currencyFormat(row?.saleValue?.toFixed(2)),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: '', width: 60 },
    { columnName: 'image', width: 100 },
    { columnName: 'barcode', width: 130 },
    { columnName: 'description', width: 160 },
    { columnName: 'listValue', width: 115, align: 'right' },
    { columnName: 'discount', width: 160, align: 'right' },
    { columnName: 'amountPaid', width: 155, align: 'right' },
  ]);

  return (
    <>
      <VirtualGrid
        urlType={URL_TYPE.NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={rows}
      />
    </>
  );
};

export default SubsequentRefundSummary;
