import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Row, Col, Input, Form, Switch } from 'antd';
import styled from 'styled-components';
import { RetailPopupProps } from 'components/interface/RetailPopUp';
import {
  MetalTypeProps,
  FinenessProps,
} from 'components/interface/metalTypeAndFineness';
import { Button } from 'components/common/Button';
import RetailSaleSummaryPopup from './RetailSaleSummaryPopup';
import { getMetalTypeAndFineness } from 'services/retail';
import { FIND_RETAIL_ITEMS } from 'action_creators/retail';
import { useDispatch, useSelector } from 'react-redux';
import {
  SELECT_PLACEHOLDER,
  RESET,
  STONE_SIZE_TO,
  STONE_SIZE_FROM,
  WEIGHT_RANGE_MAX,
  WEIGHT_RANGE_MIN,
  DESCRIPTION_PLACEHOLDER,
  CANCEL,
  ADD,
  PRICE_RANGE_MIN,
  PRICE_RANGE_MAX,
  FIND_ITEM,
  SEARCH,
  METAL_TYPE_ATTRIBUTE_ID,
  FINENESS_ATTRIBUTE_ID,
  RETAIL_SWITCH,
} from '../constants';
import { actions } from 'redux/reducers/retail';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import InputDecimal from 'components/common/InputDecimal';
import Select from 'components/common/Select';

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  pattern: {
    mismatch: '${label} is not a valid number!',
  },
};
/* eslint-disable no-template-curly-in-string */
const StyledHeader = styled(Row)`
  margin-bottom: 8px;
  font-size: 2rem;
  white-space: initial;
  color: var(--text-color);
  font-weight: var(--font-weight-500);
  line-height: 23.44px;
`;

const StyledCol = styled(Col)`
  margin-right: 0px;
`;
const StyledColLeft = styled(Col)`
  left: 20px;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

const StyledContentContainer = styled.div`
  padding: 5px 20px;
  margin: 10px 0;
  background: var(--catskill-white-light);
  width: 1284px;
  border-radius: 6px;
`;

const StyledMarginContainer = styled.div`
  margin: 4px 0px 18px 0px;
`;

const StyledTableWrapper = styled(Col)`
  height: 40vh;
  min-height: 40vh;
`;

const StyledModal = styled(Modal)`
  height: calc(100vh -220px);
`;

const StyledDiv = styled.div`
  display: flex;
  margin: 0 15px;

  & span {
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-base);
    margin: 0 10px;
  }

  & .ant-switch {
    background-color: var(--blue);
  }
`;

interface FindRetailItemsRequest {
  storeId: number;
  barcode: string;
  description: string;
  metalType: number;
  fineness: number;
  weightRange: {
    min: number;
    max: number;
  };
  priceRange: {
    min: number;
    max: number;
  };
  stoneSize: {
    min: number;
    max: number;
  };
  isLocalSearch: boolean;
}
const RetailPopup = ({
  isRetailPopVisible,
  onRetailPopupCancel,
}: RetailPopupProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleRows, setVisibleRows] = useState<boolean>(false);
  const [row, setRow] = useState<Array<object>>([]);
  const [flag, setFlag] = useState<boolean>(false);
  const [disabledSearch, setDisabledSearch] = useState<boolean>(false);
  const [metalType, setMetalType] = useState<Array<MetalTypeProps>>([]);
  const [fineness, setFineness] = useState<Array<FinenessProps>>([]);
  const [isLocalSearch, setIsLocalSearch] = useState<boolean>(true);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    retail: { rows, retailItems, isRowSelected },
    user: { selectedStore },
  } = useSelector((state: any) => {
    return {
      retail: state.retail,
      user: state.user,
    };
  });

  useEffect(() => {
    if (isRetailPopVisible) {
      setVisible(true);
    }
  }, [isRetailPopVisible]);

  useEffect(() => {
    loadMetalTypeAndFineness();
  }, []);

  const loadMetalTypeAndFineness = async () => {
    const response = await getMetalTypeAndFineness(
      METAL_TYPE_ATTRIBUTE_ID,
      FINENESS_ATTRIBUTE_ID
    );
    if (response?.data) {
      response.data.itemFixedAttributeTypes.forEach((item: any) => {
        if (item.id === METAL_TYPE_ATTRIBUTE_ID) {
          setMetalType(item.itemFixedAttributes);
        } else if (item.id === FINENESS_ATTRIBUTE_ID) {
          setFineness(item.itemFixedAttributes);
        }
      });
    }
  };

  const findRetailItems = (params: FindRetailItemsRequest) => {
    if (retailItems.length > 0) {
      dispatch(actions.resetRetailItems());
    }
    dispatch({
      type: FIND_RETAIL_ITEMS,
      payload: {
        params: params,
      },
    });
  };
  const onCancelClick = useCallback(() => {
    setVisible(false);
    onRetailPopupCancel();
    dispatch(actions.resetRetailItems());
  }, [onRetailPopupCancel, dispatch]);

  const onSubmitSearchForm = () => {
    const params: FindRetailItemsRequest = {
      storeId: selectedStore?.storeId,
      barcode: form.getFieldValue('barcode'),
      description: form.getFieldValue('description'),
      metalType: parseInt(form.getFieldValue('metalType')),
      fineness: parseInt(form.getFieldValue('fineness')),
      weightRange: {
        min: form.getFieldValue('weightRangeMin'),
        max: form.getFieldValue('weightRangeMax'),
      },
      priceRange: {
        min: form.getFieldValue('priceRangeMin'),
        max: form.getFieldValue('priceRangeMax'),
      },
      stoneSize: {
        min: form.getFieldValue('stoneSizeFrom'),
        max: form.getFieldValue('stoneSizeTo'),
      },
      isLocalSearch: isLocalSearch,
    };
    const fieldValues = Object.values(form.getFieldsValue());
    for (const item of fieldValues) {
      if (item !== undefined && item !== '') {
        findRetailItems(params);
        break;
      }
    }
  };

  const onInputKeyUp = () => {
    if (
      form.getFieldError('stoneSizeFrom').length > 0 ||
      form.getFieldError('stoneSizeTo').length > 0 ||
      form.getFieldError('weightRangeMin').length > 0 ||
      form.getFieldError('weightRangeMax').length > 0 ||
      form.getFieldError('priceRangeMin').length > 0 ||
      form.getFieldError('priceRangeMax').length > 0
    ) {
      setDisabledSearch(true);
    } else setDisabledSearch(false);

    const fieldValues = Object.values(form.getFieldsValue());
    for (const i of fieldValues) {
      if (i !== 'undefined' && i !== '') {
        setFlag(true);
        break;
      } else {
        setFlag(false);
        break;
      }
    }
  };

  useEffect(() => {
    if (flag) {
      setDisabledSearch(false);
    } else {
      setDisabledSearch(true);
    }
  }, [flag]);

  const onChangeMetalHandler = (e: any) => {
    onInputKeyUp();
    form.setFieldsValue({ metalType: e });
  };

  const onClearMetalHandler = () => {
    form.setFieldsValue({ metalType: SELECT_PLACEHOLDER });
  };

  const onChangeFinessHandler = (e: any) => {
    onInputKeyUp();
    form.setFieldsValue({ fineness: e });
  };

  const onClearFinessHandler = () => {
    form.setFieldsValue({ fineness: SELECT_PLACEHOLDER });
  };

  const onResetClick = (e: any) => {
    e.preventDefault();
    form.resetFields();
    dispatch(actions.resetRetailItems());
    setDisabledSearch(true);
  };
  const addToRetailStore = () => {
    setVisibleRows(true);
    if (Object.keys(row).length > 0) {
      dispatch(actions.updateSingleRetailItems(row));
    }
    onCancelClick();
  };
  const onCallbackHandler = (row: any) => {
    setRow({ ...row });
    setVisibleRows(false);
  };
  useEffect(() => {
    if (visibleRows) {
      onCancelClick();
    }
  }, [visibleRows, row, onCancelClick]);

  const onRowChange = (row: any) => {
    const stockItemFound = rows.some((item: any) => {
      return row.stockItemId === item.stockItemId;
    });
    if (rows.length === 0 || !stockItemFound) {
      setRow(row);
    }

    const payload = { row: row, isRowSelected: true };
    dispatch(actions.enableAddButton(payload));
  };

  const onSwitchToggle = () => {
    dispatch(actions.resetRetailItems());
    setIsLocalSearch((prevState: boolean) => !prevState);
  };

  return (
    <StyledModal
      open={visible}
      closable={true}
      key={1}
      centered
      width={1344}
      maskClosable={false}
      onCancel={onCancelClick}
      footer={[
        <div key="retailFooter" className="custom-btn">
          <StyledButton itemProp="secondary" key="back" onClick={onCancelClick}>
            {CANCEL}
          </StyledButton>
          <Button
            key="submit"
            disabled={!isRowSelected}
            onClick={addToRetailStore}
          >
            {ADD}
          </Button>
        </div>,
      ]}
    >
      <Row>
        <Col>
          <Row>
            <StyledHeader>
              <HelpPopoverInfo
                linkedID={'RETAILPOPUP_FINDITEM'}
                position="rightTop"
                helpPosition="AFTER"
              >
                {FIND_ITEM}
              </HelpPopoverInfo>

              <StyledDiv>
                <span>{RETAIL_SWITCH.LOCAL}</span>
                <Switch checked={!isLocalSearch} onChange={onSwitchToggle} />
                <span>{RETAIL_SWITCH.GLOBAL}</span>
              </StyledDiv>
            </StyledHeader>
          </Row>
          <Form
            layout="vertical"
            form={form}
            validateMessages={validateMessages}
          >
            <StyledContentContainer className="retail-wrapper">
              <Row gutter={25}>
                <StyledCol span={6} className="gutter-row">
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Barcode"
                        name="barcode"
                        className="label-control"
                      >
                        <Input
                          className="input-control"
                          onKeyUp={onInputKeyUp}
                          onPressEnter={onSubmitSearchForm}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </StyledCol>
                <StyledCol span={12} className="gutter-row">
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Product Description"
                        name="description"
                        className="label-control"
                      >
                        <Input
                          className="input-control"
                          placeholder={DESCRIPTION_PLACEHOLDER}
                          onKeyUp={onInputKeyUp}
                          onPressEnter={onSubmitSearchForm}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </StyledCol>

                <StyledCol span={6} className="gutter-row">
                  <Row>
                    <Col span={12} className="border text-right">
                      <Form.Item
                        label="Price Range"
                        name="priceRangeMin"
                        className="label-control"
                      >
                        <InputDecimal
                          className="input-control"
                          addonBefore={PRICE_RANGE_MIN}
                          placeholder={false}
                          size="large"
                          onKeyUp={onInputKeyUp}
                          onPressEnter={onSubmitSearchForm}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12} className="border-right text-right">
                      <Form.Item
                        label="Price Range Max"
                        name="priceRangeMax"
                        className="label-control label-visibility"
                      >
                        <InputDecimal
                          className="input-control"
                          addonBefore={PRICE_RANGE_MAX}
                          placeholder={false}
                          size="large"
                          onKeyUp={onInputKeyUp}
                          onPressEnter={onSubmitSearchForm}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </StyledCol>
              </Row>
              <StyledMarginContainer>
                <Row gutter={25}>
                  <StyledCol span={6} className="gutter-row">
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Metal Type"
                          name="metalType"
                          className="label-control"
                        >
                          <Select
                            allowClear={true}
                            onChange={onChangeMetalHandler}
                            onClear={onClearMetalHandler}
                            data-testid="all-metalType"
                            options={metalType.map((el: any) => {
                              return { label: el.description, value: el.id };
                            })}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </StyledCol>
                  <StyledCol span={6} className="gutter-row">
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Fineness"
                          name="fineness"
                          className="label-control"
                        >
                          <Select
                            allowClear={true}
                            onChange={onChangeFinessHandler}
                            onClear={onClearFinessHandler}
                            data-testid="all-finenessType"
                            options={fineness.map((el: any) => {
                              return { label: el.description, value: el.id };
                            })}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </StyledCol>

                  <StyledCol span={6} className="gutter-row">
                    <Row>
                      <Col span={12} className="border">
                        <Form.Item
                          label="Weight Range"
                          name="weightRangeMin"
                          className="label-control"
                        >
                          <InputDecimal
                            className="input-control"
                            addonBefore={WEIGHT_RANGE_MIN}
                            placeholder={false}
                            size="large"
                            onKeyUp={onInputKeyUp}
                            onPressEnter={onSubmitSearchForm}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12} className="border-right">
                        <Form.Item
                          label="Weight Range Max"
                          name="weightRangeMax"
                          className="label-control label-visibility"
                        >
                          <InputDecimal
                            className="input-control"
                            addonBefore={WEIGHT_RANGE_MAX}
                            placeholder={false}
                            size="large"
                            onKeyUp={onInputKeyUp}
                            onPressEnter={onSubmitSearchForm}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </StyledCol>

                  <StyledCol span={6} className="gutter-row">
                    <Row>
                      <Col span={12} className="border">
                        <Form.Item
                          label="Stone Size"
                          name="stoneSizeFrom"
                          className="label-control"
                        >
                          <InputDecimal
                            type="number"
                            className="input-control"
                            addonBefore={STONE_SIZE_FROM}
                            placeholder={false}
                            size="large"
                            onKeyUp={onInputKeyUp}
                            onPressEnter={onSubmitSearchForm}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12} className="border-right">
                        <Form.Item
                          label="Stone Size To"
                          name="stoneSizeTo"
                          className="label-control label-visibility"
                        >
                          <InputDecimal
                            type="number"
                            className="input-control"
                            addonBefore={STONE_SIZE_TO}
                            placeholder={false}
                            size="large"
                            onKeyUp={onInputKeyUp}
                            onPressEnter={onSubmitSearchForm}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </StyledCol>
                </Row>
              </StyledMarginContainer>
              <StyledMarginContainer>
                <Row>
                  <StyledColLeft span={5} offset={20}>
                    <StyledButton
                      itemProp="secondary"
                      key="back"
                      onClick={onResetClick}
                    >
                      {RESET}
                    </StyledButton>
                    <Button
                      className="custom-button"
                      key="submit"
                      disabled={disabledSearch}
                      onClick={onSubmitSearchForm}
                    >
                      {SEARCH}
                    </Button>
                  </StyledColLeft>
                </Row>
              </StyledMarginContainer>
            </StyledContentContainer>
          </Form>
          <StyledTableWrapper
            span={24}
            className="retail-summary-popover-wrapper"
          >
            <RetailSaleSummaryPopup
              parentCallback={onCallbackHandler}
              onRowChange={onRowChange}
            ></RetailSaleSummaryPopup>
          </StyledTableWrapper>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default RetailPopup;
