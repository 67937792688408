import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Spin } from 'antd';
import styled from 'styled-components';
import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';
import { currencyFormat } from 'utils/util';
import { getPledgeAgreementItems } from 'services/pledge';
import { GET_PAID_PLEDGE_DETAILS } from 'action_creators/pickupRenew';
import VirtualGrid from 'components/common/virtualGrid/index';
import { actions } from 'redux/reducers/basket';
import ImageLoader from 'components/common/ImageLoader';
import PaidPaymentDetailsPopup from 'components/pickupRenew/PaidPaymentDetailsPopup';

const StyledHyperlink = styled.button`
  font-weight: var(--font-weight-500);
  color: var(--blue);
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const BasketSurplusTable = (item: any) => {
  const dispatch = useDispatch();

  const {
    basket: { surplusData },
  } = useSelector((state: any) => {
    return {
      basket: state.basket,
    };
  });

  const [selection, setSelection] = useState<any>([]);
  const [isPaidPaymentPopupVisible, setPaidPaymentPopupVisible] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch(actions.setSurplusData(item?.items));
  }, [item?.items]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const payload = {
      serviceIds: selection,
    };
    //update redux merge payload when row selected
    dispatch(actions.move(payload));
  }, [selection]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectionChange = (row: any) => {
    setSelection(row);
  };

  const onPaidPaymentClick = (row: any) => {
    const params = {
      loanId: row?.surplus?.request?.surplusItem?.loanId,
    };
    setPaidPaymentPopupVisible(true);
    dispatch({
      type: GET_PAID_PLEDGE_DETAILS,
      payload: { params },
    });
  };

  const [columns] = useState([
    {
      name: 'loanNumber',
      title: 'Pledge No.',
      getCellValue: (row: any) =>
        row?.surplus?.request?.surplusItem?.loanNumber,
    },
    {
      name: 'loanStatusId',
      title: 'Status',
      type: 'icon',
      isMultipleIcons: true,
      isIconClickable: false,
      getCellValue: (row: any) => {
        if (row?.surplus?.request?.surplusItem?.isPrintAffidavit) {
          return [row?.surplus?.request?.surplusItem?.loanStatus, 'affidavit'];
        } else return [row?.surplus?.request?.surplusItem?.loanStatus, null];
      },
    },
    {
      name: 'startDate',
      title: 'Start Date',
      getCellValue: (row: any) =>
        format(
          new Date(row?.surplus?.request?.surplusItem?.startDate),
          FNS_DATE_FORMAT
        ),
    },
    {
      name: 'dueDate',
      title: 'Due Date',
      getCellValue: (row: any) =>
        format(
          new Date(row?.surplus?.request?.surplusItem?.dueDate),
          FNS_DATE_FORMAT
        ),
    },
    {
      name: 'loanAmount',
      title: 'Loan Amount',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.surplus?.request?.surplusItem?.loanAmount,
    },
    {
      name: 'interestRate',
      title: 'Interest',
      getCellValue: (row: any) =>
        `${row?.surplus?.request?.surplusItem?.interestRate?.toFixed(2)}%`,
    },
    {
      name: 'dailyInterest',
      title: 'Daily',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.surplus?.request?.surplusItem?.dailyInterestDisplay,
    },
    {
      name: 'paidAmount',
      title: 'Paid',
      getCellValue: (row: any) => (
        <StyledHyperlink type="button" onClick={() => onPaidPaymentClick(row)}>
          {`${currencyFormat(
            row?.surplus?.request?.surplusItem?.paidAmount,
            true
          )}`}
        </StyledHyperlink>
      ),
    },
    {
      name: 'affidavitAmount',
      title: 'Affidavit',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.surplus?.request?.surplusItem?.affidavitAmount,
    },
    {
      name: 'toPayAmount',
      title: 'To Pay',
      type: 'input',
      getCellValue: (row: any) =>
        currencyFormat(row?.surplus?.request?.surplusItem?.toPayAmount),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'loanNumber', width: '12%' },
    { columnName: 'loanStatusId', width: '10%' },
    { columnName: 'startDate', width: '7%' },
    { columnName: 'dueDate', width: '7%' },
    { columnName: 'loanAmount', width: '10%', align: 'right' },
    { columnName: 'interestRate', width: '7%', align: 'right' },
    { columnName: 'dailyInterest', width: '8%', align: 'right' },
    { columnName: 'paidAmount', width: '10%', align: 'right' },
    { columnName: 'affidavitAmount', width: '8%', align: 'right' },
    { columnName: 'toPayAmount', width: '12%' },
  ]);

  const [formattedColumns] = useState([
    'toPayAmount',
    'loanStatusId',
    'loanAmount',
    'dailyInterest',
    'affidavitAmount',
  ]);

  return (
    <>
      <div className="basket-item-table">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={surplusData}
          formattedColumns={formattedColumns}
          expandableComponent={PledgeExpandableTable}
          rowSelectionEnable={true}
          selection={selection}
          onSelectionChange={onSelectionChange}
          highlightRow={true}
        />
      </div>
      {isPaidPaymentPopupVisible && (
        <PaidPaymentDetailsPopup
          visible={isPaidPaymentPopupVisible}
          setPaidPaymentPopupVisible={() => setPaidPaymentPopupVisible(false)}
        />
      )}
    </>
  );
};

export const PledgeExpandableTable = ({ row }: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const {
    basket: { surplusData },
  } = useSelector((state: any) => {
    return {
      basket: state.basket,
    };
  });

  useEffect(() => {
    if (!Object.keys(row?.surplus?.request?.surplusItem).includes('items'))
      getPledgeDetails(row?.surplus?.request?.surplusItem?.loanId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getPledgeDetails = async (id: any) => {
    try {
      setLoading(true);
      const response = await getPledgeAgreementItems(id);
      if (response?.data?.items) {
        const updatedData = JSON.parse(JSON.stringify(surplusData));
        const index = surplusData.findIndex(
          (obj: any) =>
            obj.surplus.request.surplusItem.loanId ===
            row?.surplus?.request?.surplusItem?.loanId
        );
        updatedData[index].surplus.request.surplusItem.items =
          response.data.items;

        dispatch(actions.setSurplusData(updatedData));
        setLoading(false);
      }
    } catch (e: any) {
      console.log(e);
      setLoading(false);
    }
  };

  const [columns] = useState([
    {
      name: 'image',
      title: 'Item Image',
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row?.id}?isThumnails=false`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'description',
      title: 'Description',
      getCellValue: (row: any) => row?.description,
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) => `${row?.weight?.toFixed(2)}g`,
    },
    {
      name: 'amount',
      title: 'Amount',
      type: 'currency',
      getCellValue: (row: any) => row?.amount,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'image', width: '10%', align: 'center' },
    { columnName: 'description', width: '20%' },
    { columnName: 'weight', width: '10%', align: 'right' },
    { columnName: 'amount', width: '10%', align: 'right' },
  ]);

  const [formattedColumns] = useState(['amount']);

  return (
    <Spin spinning={loading}>
      <div className="expandable-table-rows">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={row?.surplus?.request?.surplusItem?.items || []}
          formattedColumns={formattedColumns}
        />
      </div>
    </Spin>
  );
};

export default BasketSurplusTable;
