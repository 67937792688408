import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { Button } from 'components/common/Button';
import { getCutList, getStoneSizeData } from 'services/accessories';
import styled from 'styled-components';
import { STONE_SIZE_CALCULATION_EXAMPLE } from 'components/layout/constants';
import { cacheStaticData } from 'utils/cacheServices';
import InputDecimal from 'components/common/InputDecimal';
import Select from 'components/common/Select';

const StyledDiv = styled.div`
  height: 43px;
  text-align: center;
  margin-left: -30px;
  width: 820px;
  align-items: center;
  background-color: #eaf6fe;
  margin-left: -30px;
  margin-top: 5px;
`;

const StyledResultDiv = styled.div`
  height: 86px;
  background-color: #f1f5fc;
  margin: 5px 0px 0px -30px;

  & .ant-row {
    padding: 10px;
  }
`;

const StyledFooterRow = styled(Row)`
  padding: 55px 50px;
`;

const StyledInput = styled(InputDecimal)`
  &::placeholder {
    font-weight: 700;
    color: var(--black);
  }
  & .ant-input-group-addon {
    width: 48px;
    background: var(--catskill-white);
  }
`;

const StyledText = styled.div`
  font-weight: var(--font-weight-500);
  line-height: 16px;
  margin-top: 10px;
  text-align: left;
  padding: 15px;
  margin-left: 20px;
`;

const StyledLabel = styled.div`
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-500);
  line-height: 16px;
  margin-top: 10px;
`;

const StoneSizeCalculator = ({ onCancelClick }: any) => {
  const [form] = Form.useForm();
  const [keyArray] = useState<any>([]);
  const [valueArray] = useState<any>([]);
  const [cutList, setCutList] = useState<any>([]);

  useEffect(() => {
    loadCutList();
  }, [!cutList.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadCutList = async () => {
    try {
      const response = await cacheStaticData(getCutList);
      if (response) {
        setCutList([response?.data]);
        keyArray.push(Object.keys(response?.data));
        valueArray.push(Object.values(response?.data));
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const calculateStoneSize = async () => {
    const { cutType, length, width, depth } = form.getFieldsValue();
    const params = { Cut: cutType, Length: length, Width: width, Depth: depth };
    try {
      const response = await getStoneSizeData(params);
      if (response?.data) form.setFieldsValue({ caratsize: response?.data });
    } catch (e: any) {
      console.log(e);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <>
      <div className="stone-size-wrapper">
        <div className="stone-size-table">
          <div>
            <Form
              form={form}
              labelAlign="left"
              layout="horizontal"
              colon={false}
            >
              <div className="stone-size-calculator">
                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item
                      label={<StyledLabel>Cut</StyledLabel>}
                      name="cutType"
                      className="label-control custom-select"
                      rules={[
                        {
                          required: true,
                          message: 'Required',
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        options={valueArray[0]?.map(
                          (item: any, index: number) => {
                            return {
                              value: keyArray[0][index],
                              label: item,
                            };
                          }
                        )}
                        onChange={calculateStoneSize}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={<StyledLabel>Width</StyledLabel>}
                      name="width"
                      className="label-control"
                      rules={[
                        {
                          required: true,
                          message: 'Required',
                        },
                      ]}
                    >
                      <StyledInput
                        size="large"
                        placeholder={'0.00'}
                        addonBefore={'mm'}
                        onChange={calculateStoneSize}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item
                      label={<StyledLabel>Length</StyledLabel>}
                      name="length"
                      className="label-control"
                      rules={[
                        {
                          required: true,
                          message: 'Required',
                        },
                      ]}
                    >
                      <StyledInput
                        size="large"
                        placeholder={'0.00'}
                        addonBefore={'mm'}
                        onChange={calculateStoneSize}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={<StyledLabel>Depth </StyledLabel>}
                      name="depth"
                      className="label-control"
                      rules={[
                        {
                          required: true,
                          message: 'Required',
                        },
                      ]}
                    >
                      <StyledInput
                        size="large"
                        placeholder={'0.00'}
                        addonBefore={'mm'}
                        onChange={calculateStoneSize}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <StyledDiv>
                <StyledText>{STONE_SIZE_CALCULATION_EXAMPLE}</StyledText>
              </StyledDiv>
              <StyledResultDiv>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label={<StyledText>Carat Size</StyledText>}
                      name="caratsize"
                      colon={false}
                      className="label-control"
                    >
                      <StyledInput className="amount-input" size="large" />
                    </Form.Item>
                  </Col>
                </Row>
              </StyledResultDiv>
            </Form>

            <StyledFooterRow>
              <Col span={3}>
                <Button itemProp="secondary" onClick={onReset}>
                  Clear
                </Button>
              </Col>
              <Col span={3}>
                <Button itemProp="secondary" onClick={onCancelClick}>
                  Close
                </Button>
              </Col>
            </StyledFooterRow>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoneSizeCalculator;
