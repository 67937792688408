import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VirtualGrid from 'components/common/virtualGrid/index';
import {
  BASKET_SERVICE_TYPE,
  COLORS_CODE_TABLE,
  FNS_DATE_FORMAT,
  GRID_NO_URL,
} from 'globalConstants';
import { Button as LinkButton } from 'antd';
import format from 'date-fns/format';
import { currencyFormat, getParentImageUrl } from 'utils/util';
import ProgressTick from 'components/common/ProgressCheck';
import TableRow from '../../formatedCell';
import ImageLoader from 'components/common/ImageLoader';
import { SERVICE_NAME, SUCCESSFUL } from 'components/checkout/constants';
import { PRINT_CONTRACTS } from 'action_creators/pickupRenew';

interface PledgeContractItem {
  pledge: { response: { serviceLoanNumber: string } };
  serviceStatusText: string;
  serviceId: string;
}

const CheckoutPledge = (props: any) => {
  const isServiceSuccess = props?.isServiceSuccess;

  const rows = props?.item || [];
  const dispatch = useDispatch();

  const { isRecallCheckoutCustomer } = useSelector(
    (state: any) => state.customer
  );

  //Count to avoid multiple prints on multiple renders to the grid
  const printCountObj = rows.reduce(
    (o: any, item: any) => ({ ...o, [item.serviceId]: 0 }),
    {}
  );

  const dowloadAllContract = async (loanNumber: any, serviceId: any) => {
    dispatch({
      type: PRINT_CONTRACTS,
      payload: {
        params: loanNumber,
        serviceId,
        serviceType: BASKET_SERVICE_TYPE.PLEDGE,
      },
    });
  };

  useEffect(() => {
    // The idea is to wait for SignalR to complete its job.
    //isServiceSuccess is true we will try to go for dowloadAllContract
    if (isServiceSuccess === true) {
      if (rows) {
        const checkForPledgeServiceStatus: number = rows.findIndex(
          (row: PledgeContractItem) =>
            String(row?.serviceStatusText).toLowerCase() !== SUCCESSFUL
        );

        if (checkForPledgeServiceStatus !== -1) return;

        rows.forEach((row: PledgeContractItem) => {
          if (
            row?.pledge?.response?.serviceLoanNumber &&
            printCountObj[row?.serviceId] < 1 &&
            !isRecallCheckoutCustomer &&
            String(row?.serviceStatusText).toLowerCase() === SUCCESSFUL
          ) {
            printCountObj[row?.serviceId]++;
            dowloadAllContract(
              row?.pledge?.response?.serviceLoanNumber,
              row?.serviceId
            );
          }
        });
      }
    }
  }, [isServiceSuccess]);

  const [columns] = useState([
    {
      name: 'serviceLoanNumber',
      title: 'Agreement No.',
      getCellValue: (row: any) => {
        return ProgressTick(
          row?.pledge?.response?.serviceLoanNumber,
          row?.serviceStatusText
        );
      },
    },
    {
      name: 'serviceName',
      title: 'Service',
      getCellValue: (row: any) => SERVICE_NAME.PLEDGE,
    },
    {
      name: 'dueDate',
      title: 'Due Date',
      getCellValue: (row: any) =>
        format(new Date(row?.pledge?.request?.dueDate), FNS_DATE_FORMAT),
    },
    {
      name: 'loanAmount',
      title: 'Loan Amount',
      type: 'currency',
      getCellValue: (row: any) => row?.pledge?.request?.loanAmount,
    },
    {
      name: 'interestRate',
      title: 'Interest',
      getCellValue: (row: any) =>
        `${currencyFormat(row?.pledge?.request?.interestRate)}%`,
    },
    {
      name: 'dailyInterestRate',
      title: 'Daily',
      type: 'currency',
      getCellValue: (row: any) => row?.pledge?.request?.dailyInterestRate,
    },
    {
      name: 'loanPayableAmount',
      title: 'Total Amount Payable',
      type: 'currency',
      getCellValue: (row: any) =>
        row?.pledge?.request?.currentLoanPayableAmount,
    },
    {
      //insert a blank column to create space before To Pay
      name: 'blankColumn',
      title: ' ',
      getCellValue: () => '',
    },
    {
      name: 'toPay',
      title: 'To Pay',
      type: 'currency',
      getCellValue: (row: any) => row?.serviceAmount * -1,
    },
    {
      name: 'toTake',
      title: 'To Take',
      type: 'currency',
      getCellValue: (row: any) => 0,
    },
    {
      name: '',
      title: '',
      getCellValue: (row: any) => {
        const serviceLoanNumber = row?.pledge?.response?.serviceLoanNumber;
        if (serviceLoanNumber)
          return (
            <LinkButton
              type="link"
              block
              title="download all contract"
              onClick={() =>
                dowloadAllContract(serviceLoanNumber, row?.serviceId)
              }
            >
              Reprint
            </LinkButton>
          );
        else {
          return '-';
        }
      },
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'serviceLoanNumber', width: '11%' },
    { columnName: 'serviceName', width: '8%' },
    { columnName: 'dueDate', width: '8%' },
    { columnName: 'loanAmount', width: '8%', align: 'right' },
    { columnName: 'interestRate', width: '8%', align: 'right' },
    { columnName: 'dailyInterestRate', width: '8%', align: 'right' },
    { columnName: 'loanPayableAmount', width: '16%', align: 'right' },
    { columnName: 'blankColumn', width: '3%' },
    { columnName: 'toPay', width: '10%', align: 'right' },
    { columnName: 'toTake', width: '10%', align: 'right' },
    { columnName: '', width: '6%', align: 'right' },
  ]);

  const [formattedColumns] = useState([
    'toPay',
    'toTake',
    'loanAmount',
    'dailyInterestRate',
    'loanPayableAmount',
  ]);

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        formattedColumns={formattedColumns}
        rowData={rows}
        tableColumnExtensionsProps={tableColumnExtensions}
        headerBackground={COLORS_CODE_TABLE.PLEDGE}
        cellComponent={TableRow}
        expandableComponent={PledgeExpandableTable}
        detailRowIconPlacement={true}
      />
    </>
  );
};

export const PledgeExpandableTable = ({ row }: any) => {
  const [columns] = useState([
    {
      name: 'itemImage',
      title: 'Item Image',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={getParentImageUrl(row.itemImageCollections)}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'description',
      title: 'Description',
      getCellValue: (row: any) => row.description,
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) => `${row?.totalWeight?.toFixed(2)}g`,
    },
    {
      name: 'amount',
      title: 'Amount',
      type: 'currency',
      getCellValue: (row: any) => row?.loanAmount,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'itemImage', width: 130 },
    { columnName: 'description', width: 230 },
    { columnName: 'weight', width: 120, align: 'right' },
    { columnName: 'amount', width: 120, align: 'right' },
  ]);

  const [formattedColumns] = useState(['amount']);

  return (
    <>
      <div className="expandable-table-rows">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          formattedColumns={formattedColumns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={row?.pledge?.request?.items}
          rowSelectionEnable={true}
          onSelectionChange={() => undefined}
        />
      </div>
    </>
  );
};

export default CheckoutPledge;
