import React, { useEffect, useState } from 'react';
import { Col, Modal, Row, Button as LinkButton, Checkbox } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { actions as customerActions } from 'redux/reducers/customer';
import { actions as retailActions } from 'redux/reducers/retail';
import { isEmpty } from 'lodash';
import CustomerSmartSearch from 'components/layout/CustomerSmartSearch';
import { GUEST_TYPES, SERVICE_TYPE, VAT_CUSTOMER_LABEL } from 'globalConstants';
import { Button } from './Button';
import GuestCustomer from './GuestCustomer';
import GdprMessagePopup from 'components/customer/GdprMessagePopup';
import GdprDeclinePopup from 'components/customer/GdprDeclinePopup';

interface CustomerSearchPopupProps {
  visible: boolean;
  onCancel: () => void;
  onOKClick: () => void;
  guestType?: string;
  isCreateInSearch?: boolean; //if true, create customer option will come inside the search
}

const StyledModal = styled(Modal)`
  margin-top: 16px;
  & .ant-modal-body {
    padding: 10px 30px 30px;
  }

  .search-control-box {
    margin: 0;
  }
`;

const StyledLinkButton = styled(LinkButton)`
  padding: 0;
`;

const StyledOR = styled.div`
  background: var(--solitude);
  border-radius: 100%;
  height: 35px;
  width: 35px;
  position: relative;
  text-align: center;
  & span {
    top: 7px;
    position: relative;
  }
  margin-bottom: 20px;
`;

const CustomerSearchPopup = ({
  visible,
  onCancel,
  onOKClick,
  guestType = '',
  isCreateInSearch = false,
}: CustomerSearchPopupProps) => {
  const {
    customer,
    isRedirectToOtherPage,
    isLiteCustomerLoading,
    isLiteDocUploading,
  } = useSelector((state: any) => state.customer);

  const dispatch = useDispatch();

  const [isGuestCustomerFormVisible, setIsGuestCustomerFormVisible] =
    useState<boolean>(false);
  const [isGdprMessageVisible, setIsGdprMessageVisible] =
    useState<boolean>(false);
  const [isGdprDeclinePopupVisible, setIsGdprDeclinePopupVisible] =
    useState<boolean>(false);

  useEffect(() => {
    if (customer?.customerId || customer?.firstNames)
      setIsGuestCustomerFormVisible(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onCancelClick = () => {
    onCancel();
  };

  const onOkClicked = () => {
    if (guestType === GUEST_TYPES.VALUATION)
      dispatch(customerActions.setGuestType(guestType));
    else if (guestType === GUEST_TYPES.VAT)
      dispatch(customerActions.setVATDetailsAvailable({ value: true }));

    onOKClick();
  };

  const onFindCustomerClick = () => {
    if (isEmpty(customer)) {
      dispatch(customerActions.setRedirectToOtherPage(true));
    }
    onCancel();
  };

  useEffect(() => {
    if (!isRedirectToOtherPage) onFindCustomerClick();
  }, [isRedirectToOtherPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRecordCustomerDetailsClick = () => {
    if (guestType === GUEST_TYPES.FX) setIsGdprMessageVisible(true);
    else setIsGuestCustomerFormVisible(true);
  };

  const onGdprMessageAccept = () => {
    setIsGdprMessageVisible(false);
    setIsGuestCustomerFormVisible(true);
  };

  const onGdprMessageDecline = (visibility: boolean) => {
    setIsGdprMessageVisible(false);
    setIsGdprDeclinePopupVisible(visibility);
  };

  const onGdprDeclinePopupclose = (visibility: boolean) => {
    setIsGdprDeclinePopupVisible(visibility);
    onCancel();
  };

  const onActionChange = () => {
    dispatch(retailActions.setIsGuestCustomerAbstained(true));
  };

  const onSaveAndContinueClick = () => {
    dispatch(retailActions.onSaveAndContinueMode(true));
  };

  return (
    <>
      <StyledModal
        title={
          isGuestCustomerFormVisible || customer?.customerId
            ? 'Enter Customer Details'
            : 'Find Customer'
        }
        open={visible}
        width={920}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        footer={
          isGuestCustomerFormVisible && (
            <Row>
              <Col span="10" className="mandatory-label">
                {guestType === GUEST_TYPES.VAT && (
                  <Checkbox
                    disabled={!isGuestCustomerFormVisible}
                    onChange={onActionChange}
                  >
                    {VAT_CUSTOMER_LABEL.CUSTOMER_ABSTAINED}
                  </Checkbox>
                )}
              </Col>
              <Col span="14">
                <Button
                  type="primary"
                  itemProp="secondary"
                  htmlType="button"
                  onClick={onCancelClick}
                  style={{ marginRight: '10px' }}
                >
                  Cancel
                </Button>
                <Button
                  loading={isLiteCustomerLoading || isLiteDocUploading}
                  form="guestCustomerForm"
                  type="primary"
                  key="submit"
                  htmlType="submit"
                  onClick={onSaveAndContinueClick}
                  disabled={!isGuestCustomerFormVisible}
                >
                  Save & Continue
                </Button>
              </Col>
            </Row>
          )
        }
      >
        {guestType === GUEST_TYPES.VAT && <p>{VAT_CUSTOMER_LABEL.HEADING} </p>}
        <Row>
          <Col span={24}>
            {!customer?.customerId && (
              <>
                <CustomerSmartSearch
                  width={24}
                  serviceType={SERVICE_TYPE.RETAIL}
                  guestType={isCreateInSearch ? guestType : ''}
                  onCreateCustomerClick={onRecordCustomerDetailsClick}
                />
                {[GUEST_TYPES.VAT].some(
                  (guest: any) => guest === guestType
                ) && (
                  <>
                    <StyledOR>
                      <span>OR</span>
                    </StyledOR>
                    <StyledLinkButton
                      type="link"
                      onClick={onRecordCustomerDetailsClick}
                    >
                      {guestType === GUEST_TYPES.VAT
                        ? 'Record customer details for VAT.'
                        : ''}
                    </StyledLinkButton>
                    &nbsp; (This will not create
                    {guestType === GUEST_TYPES.FX ? <> full KYC </> : <> a </>}
                    customer profile)
                  </>
                )}
              </>
            )}
            {isGuestCustomerFormVisible && (
              <GuestCustomer guestType={guestType} onOKClick={onOkClicked} />
            )}
          </Col>
        </Row>
      </StyledModal>

      {isGdprMessageVisible && (
        <GdprMessagePopup
          visible={isGdprMessageVisible}
          setGdprMessageVisible={onGdprMessageAccept}
          setGdprDeclineVisible={(visibility: boolean) =>
            onGdprMessageDecline(visibility)
          }
          serviceType={SERVICE_TYPE.LITE_CUSTOMER}
        />
      )}

      {isGdprDeclinePopupVisible && (
        <GdprDeclinePopup
          visible={isGdprDeclinePopupVisible}
          setGdprDeclineVisible={(visibility: boolean) =>
            onGdprDeclinePopupclose(visibility)
          }
          resetSearch={() => setIsGdprDeclinePopupVisible(false)}
        />
      )}
    </>
  );
};

export default CustomerSearchPopup;
