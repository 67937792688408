import React, { useState } from 'react';
import { format } from 'date-fns';
import {
  GRID_NO_URL,
  FNS_DATE_FORMAT,
  SERVICE_TYPE_LABEL_BY_KEY,
} from 'globalConstants';
import VirtualGrid from 'components/common/virtualGrid/index';
import ImageLoader from 'components/common/ImageLoader';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import {
  GET_PLEDGE_ITEMS,
  GET_LOAN_DETAILS,
  GET_PURCHASE_LOAN_DETAILS,
} from 'action_creators/pledgeManagement';
import { useDispatch } from 'react-redux';
import { actions } from 'redux/reducers/pledgeManagement';

interface PfiUnderSummaryProps {
  pfiUnderSeventyFiveList: Array<any>;
}

const TableRow = ({
  row,
  onClickTableRow,
  loadLoanDetails,
  ...restProps
}: any) => {
  const onRowClick = () => {
    onClickTableRow(row);
  };

  const onLoanNoClick = () => {
    loadLoanDetails(row);
  };

  const Cell = () => {
    const { column } = restProps;
    if (column.name === 'loanNumber') {
      return (
        <Table.Cell
          onClick={onLoanNoClick}
          className="loan-id-cell"
          {...restProps}
        />
      );
    }
    return <Table.Cell onClick={onRowClick} {...restProps} />;
  };

  return <>{Cell()}</>;
};

const PfiSummary = ({ pfiUnderSeventyFiveList = [] }: PfiUnderSummaryProps) => {
  const [columns] = useState([
    {
      name: '',
      title: '',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <ImageLoader
          customerId={row?.customerId}
          imageUrl={
            row?.customerId && `/api/customers/images/${row.customerId}`
          }
          classes={`mx-small-avatar mx-small-avatar-yellow`}
          borderRadius={'50%'}
          fallbackImg={'customer-pic.svg'}
          preview
        />
      ),
    },
    {
      name: 'customerName',
      title: 'Customer Name',
      getCellValue: (row: any) => row?.customerName,
    },
    {
      name: 'serviceType',
      title: 'Service Type',
      getCellValue: (row: any) => row?.serviceType,
    },
    {
      name: 'loanNumber',
      title: 'Pledge/Purchase No.',
      getCellValue: (row: any) => row?.loanNumber,
    },
    {
      name: 'loanValue',
      title: 'Value',
      getCellValue: (row: any) => `£${row.loanValue.toFixed(2)}`,
    },
    {
      name: 'startDate',
      title: 'Start',
      getCellValue: (row: any) =>
        row && row?.startDate
          ? format(new Date(row?.startDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'dueDate',
      title: 'End',
      getCellValue: (row: any) =>
        row && row?.dueDate
          ? format(new Date(row?.dueDate), FNS_DATE_FORMAT)
          : null,
    },
  ]);

  const dispatch = useDispatch();
  const onClickTableRow = () => {/* do nothing */};

  const loadLoanDetails = (row: any) => {
    dispatch({
      type: GET_PLEDGE_ITEMS,
      payload: {
        loanId: row.loanId,
      },
    });
    dispatch({
      type:
        row.serviceType === SERVICE_TYPE_LABEL_BY_KEY.PLEDGE
          ? GET_LOAN_DETAILS
          : GET_PURCHASE_LOAN_DETAILS,
      payload: {
        loanNumber: row.loanNumber,
      },
    });
    dispatch(actions.setSelectedLoanNumber(row.loanNumber));
    dispatch(actions.setSelectedPFIServiceType(row.serviceType));
  };

  const [tableColumnExtensions] = useState([
    { columnName: '', width: '5%' },
    { columnName: 'customerName', width: '16%', align: 'Left' },
    { columnName: 'serviceType', width: '16%' },
    { columnName: 'loanNumber', width: '13%', align: 'left' },
    { columnName: 'loanValue', width: '18%', align: 'right' },
    { columnName: 'startDate', width: '16%', align: 'center' },
    { columnName: 'dueDate', width: '16%', align: 'center' },
  ]);

  const getRowId = (row: any) => row?.loanId;
  const onSelectionChange = () => undefined;

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={pfiUnderSeventyFiveList}
        getRowId={getRowId}
        cellComponent={(restProps: any) => (
          <TableRow
            onClickTableRow={onClickTableRow}
            loadLoanDetails={loadLoanDetails}
            {...restProps}
          />
        )}
        rowSelectionEnable={true}
        showSelectionColumn={false}
        showSelectAll={false}
        onSelectionChange={onSelectionChange}
        selectByRowClick={true}
        highlightRow={true}
      />
    </>
  );
};

export default PfiSummary;
