import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Row, Col } from 'antd';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import {
  TERM_COND_CHECK_CASHING_CONTENT,
  TERM_COND_PLDEGE_TITLE,
  TERM_COND_PLEDGE_CONTENT,
  TERM_COND_CHECK_CASHING_TITLE,
  PRE_CONTRACT_INFO_TITLE,
  PRE_CONTRACTS_INFO,
  TERMS_CONDITION,
  PRE_CONTRACTS_PLEDGE_TITLE,
} from '../constants';
import { add, format } from 'date-fns';
import {
  BASKET_SERVICE_TYPE,
  BASKET_STATUS,
  FNS_DATE_FORMAT,
} from 'globalConstants';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/reducers/checkout';
import { actions as customerActions } from 'redux/reducers/customer';
import { getPledgeOverview } from 'services/pledge';
import {
  downloadPreContract,
  downloadCheckCashing,
} from 'components/checkout/printDataModule';
import FAQQuestionsPopup from 'components/common/FAQPopup/FAQQuestionsPopup';

const StyledDescription = styled(Row)`
  color: var(--text-color);
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-16);
  text-align: left;
`;

const StyledRow = styled(Row)`
  padding: 30px 30px 10px 0;
`;
const StyledDiv = styled.div`
  height: 120px;
`;

const TermsConditionAlert = ({ serviceType }: any) => {
  const dispatch = useDispatch();
  const [faqQuesPopupVisible, setfaqQuesPopupVisible] =
    useState<boolean>(false);
  const [visible, setDisplay] = useState<boolean>(false);
  const [isPreContractsVisible, setIsPreContractsVisible] =
    useState<boolean>(false);
  const {
    checkout: { basket, termAndConditionRead = [] },
    user: { selectedStore },
    customer: { customer },
  } = useSelector((state: any) => {
    return {
      checkout: state.checkout,
      user: state.user,
      customer: state.customer,
    };
  });

  const [content, setContent] = useState<any>({
    headerTitle: '',
    contentDescription: '',
  });

  /** Terms and condition button clicked  for open TC modal*/
  const onOkTermAndCondClick = useCallback(() => {
    if (serviceType === `${BASKET_SERVICE_TYPE.CHEQUECASHING}`) {
      onReprintClick();
    }
    setDisplay(true);
  }, [setDisplay]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCancelClick = useCallback(() => {
    setDisplay(false);
  }, [setDisplay]);

  const onOkClick = useCallback(() => {
    switch (serviceType) {
      /** 'proceed' button clicked on open TC modal for open pre contract modal*/
      case `${BASKET_SERVICE_TYPE.PICKUPRENEW}`:
      case `${BASKET_SERVICE_TYPE.PLEDGE}`:
        setIsPreContractsVisible(true);
        break;
      case `${BASKET_SERVICE_TYPE.CHEQUECASHING}`:
        dispatch(actions.setTermCondReaded(serviceType));
        break;
    }
    setDisplay(false);
  }, [setDisplay]); // eslint-disable-line react-hooks/exhaustive-deps

  /** 'no' button clicked on open pre contract modal*/
  const onPreContractsCancelClicked = () => {
    setIsPreContractsVisible(false);
    dispatch(actions.setTermCondReaded(serviceType));
  };

  /** 'yes' button clicked on open pre contract modal*/
  const onPreContractsClicked = () => {
    const pre = downloadPreContract(basket, selectedStore, customer);
    if (pre) {
      setIsPreContractsVisible(false);
      dispatch(actions.setTermCondReaded(serviceType));
    }
  };

  /**Reprint checkcashing mandate */
  const onReprintClick = () => {
    const pre = downloadCheckCashing(basket?.basketId);
    if (pre) {
      console.log('download checkcashing mandate');
    }
  };

  useEffect(() => {
    getAlertMessage();
  }, [serviceType]); // eslint-disable-line react-hooks/exhaustive-deps

  const onQuestionsClick = () => {
    setfaqQuesPopupVisible(true);
  };
  const getAlertMessage = useCallback(() => {
    switch (serviceType) {
      case `${BASKET_SERVICE_TYPE.PLEDGE}`:
      case `${BASKET_SERVICE_TYPE.PICKUPRENEW}`: {
        const dueDate = format(
          add(new Date(), {
            months: 6,
          }),
          FNS_DATE_FORMAT
        );
        setContent({
          headerTitle: TERM_COND_PLDEGE_TITLE,
          contentDescription: TERM_COND_PLEDGE_CONTENT.replace(
            '{date}',
            dueDate
          ),
        });
        break;
      }
      case `${BASKET_SERVICE_TYPE.CHEQUECASHING}`:
        setContent({
          headerTitle: TERM_COND_CHECK_CASHING_TITLE,
          contentDescription: TERM_COND_CHECK_CASHING_CONTENT,
        });
        break;
    }
  }, [serviceType]); // eslint-disable-line react-hooks/exhaustive-deps

  const getFooterButtons = () => {
    let footerButtons = null;
    switch (serviceType) {
      case `${BASKET_SERVICE_TYPE.PLEDGE}`:
      case `${BASKET_SERVICE_TYPE.PICKUPRENEW}`:
        footerButtons = [
          <Button
            itemProp="secondary"
            key="questions"
            style={{ float: 'left' }}
            onClick={onQuestionsClick}
          >
            Questions
          </Button>,
          <Button
            itemProp="secondary"
            style={{ marginRight: '20px' }}
            key="back"
            onClick={onCancelClick}
          >
            Cancel
          </Button>,
          <Button key="submit" onClick={onOkClick}>
            Proceed
          </Button>,
        ];
        break;
      case `${BASKET_SERVICE_TYPE.CHEQUECASHING}`:
        footerButtons = [
          <Button
            itemProp="secondary"
            style={{ float: 'left' }}
            key="reprint"
            onClick={onReprintClick}
          >
            Reprint
          </Button>,
          <Button key="submit" onClick={onOkClick}>
            Confirm
          </Button>,
        ];
        break;
    }
    return footerButtons;
  };

  return (
    <>
      {termAndConditionRead[+serviceType] !== undefined ? (
        <StyledRow
          key={`terms-condition_${serviceType}`}
          justify="end"
          align="middle"
        >
          <HelpPopoverInfo
            linkedID={'CHECKOUT_TCS'}
            position="leftTop"
            helpPosition="BEFORE"
          >
            <Button
              key="submit"
              onClick={onOkTermAndCondClick}
              disabled={
                !termAndConditionRead[serviceType] ||
                basket.basketStatus !== BASKET_STATUS.PENDING
              }
            >
              {TERMS_CONDITION}
            </Button>
          </HelpPopoverInfo>
        </StyledRow>
      ) : null}
      {isPreContractsVisible && (
        <PreContractsAlert
          visible={isPreContractsVisible}
          onClick={onPreContractsClicked}
          onCancel={onPreContractsCancelClicked}
        />
      )}
      <Modal
        open={visible}
        closable={false}
        title={
          <HelpPopoverInfo
            linkedID={`CHECKOUT_${content.headerTitle}`}
            position="rightTop"
          >
            {content.headerTitle}
          </HelpPopoverInfo>
        }
        onOk={onOkClick}
        onCancel={onCancelClick}
        className="alert-modal-wrapper"
        footer={getFooterButtons()}
        maskClosable={false}
      >
        <Row>
          <Col span={24}>
            <StyledDescription justify="center" align="middle">
              <div
                dangerouslySetInnerHTML={{ __html: content.contentDescription }}
              ></div>
            </StyledDescription>
          </Col>
        </Row>
        {faqQuesPopupVisible && (
          <FAQQuestionsPopup
            visible={faqQuesPopupVisible}
            setFAQPopupVisible={() => setfaqQuesPopupVisible(false)}
          />
        )}
      </Modal>
    </>
  );
};

const PreContractsAlert = ({ visible, onClick, onCancel }: any) => {
  const dispatch = useDispatch();
  const {
    customer: { customer, allTimeCustomerLoansCount },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
    };
  });

  const [isNoBtnDisable, setNoBtnDisable] = useState<boolean>(false);

  const onOkClick = () => {
    onClick(false);
  };

  const onCancelClick = useCallback(() => {
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    checkIsNoBtnDisabled();
  }, [allTimeCustomerLoansCount]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkIsNoBtnDisabled = async () => {
    if (allTimeCustomerLoansCount !== null) {
      if (allTimeCustomerLoansCount === 0) setNoBtnDisable(true);
    } else {
      try {
        const response = await getPledgeOverview(customer?.customerId);
        if (response?.data) {
          const { allTimeLentPledgeCount } = response?.data;
          if (allTimeLentPledgeCount === 0) setNoBtnDisable(true);
          dispatch(
            customerActions.setAllTimeCustomerLoansCount(allTimeLentPledgeCount)
          );
        }
      } catch (e: any) {
        // do nothing
      }
    }
  };

  return (
    <Modal
      open={visible}
      closable={false}
      width={455}
      title={
        <HelpPopoverInfo
          linkedID={`CHECKOUT_${PRE_CONTRACT_INFO_TITLE}`}
          position="rightTop"
        >
          {PRE_CONTRACT_INFO_TITLE}
        </HelpPopoverInfo>
      }
      onOk={onOkClick}
      onCancel={onCancelClick}
      className="alert-modal-wrapper"
      maskClosable={false}
      footer={[
        <Button
          itemProp="secondary"
          style={{ marginRight: '20px' }}
          key="back"
          onClick={onCancelClick}
          disabled={isNoBtnDisable}
        >
          No
        </Button>,
        <Button itemProp="secondary" key="submit" onClick={onOkClick}>
          Yes
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          <StyledDescription justify="center" align="middle">
            <StyledDiv
              dangerouslySetInnerHTML={{
                __html: isNoBtnDisable
                  ? PRE_CONTRACTS_INFO
                  : PRE_CONTRACTS_INFO + PRE_CONTRACTS_PLEDGE_TITLE,
              }}
            ></StyledDiv>
          </StyledDescription>
        </Col>
      </Row>
    </Modal>
  );
};

export { TermsConditionAlert, PreContractsAlert };
