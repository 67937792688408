import React, { useCallback, useEffect, useRef, useState } from 'react';

import { AutoComplete, Input, Row, Col, notification, InputRef } from 'antd';
import { SelectProps } from 'antd/es/select';
import styled from 'styled-components';
import { format } from 'date-fns';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import CustomerPopup from '../../customer/CustomerPopup';
// import SideLinks from '../SideLinks/SideLinks'; //Commenting as per MP-6311
import { searchCustomers } from 'services/customer';
import { joinValues, removeHTMLTags } from 'utils/util';
import { CustomerPopupProps } from 'components/interface/CustomerPopUp';
import { CaretDownOutlined } from '@ant-design/icons';
import CustomerList from 'components/customer/customerList/CustomerList';
import ImageLoader from 'components/common/ImageLoader/index';
import { actions } from 'redux/reducers/customer';
import { useSelector, useDispatch } from 'react-redux';
import {
  CUSTOMER_HEADER_COLUMNS,
  SORRY_MESSAGE,
  CLICK_HERE_REGISTER_MESSAGE,
} from '../constants';
import './index.less';
import GdprMessagePopup from 'components/customer/GdprMessagePopup';
import GdprDeclinePopup from 'components/customer/GdprDeclinePopup';
import {
  ROUTE_CONFIG,
  BASKET_STATUS,
  FNS_DATE_FORMAT,
  RESPONSE_STATUS,
  GUEST_TYPES,
} from 'globalConstants';

import { useLocation, useNavigate } from 'react-router-dom';
import RecallPopup from 'components/common/recall';
import { CUSTOMER_PROFILE_REQUEST } from 'action_creators/customer';
import { getBasket } from 'services/basket';
import { RECALL_MSG, RECALL_TITLE } from 'components/customer/constants';
import { MERGE_GUEST_BASKET_REQUEST } from 'action_creators/basket';

import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import CustomerSearchLoader from './CustomerSearchLoader';

interface AppContentProps {
  width?: any;
  serviceType?: string;
  guestType?: string;
  onCreateCustomerClick?: () => void;
  singleCustomerToFind?: any;
  setIsImportCustomer?: () => void;
  isImportCustomer?: boolean;
  onCustomerYesClick?: () => void;
}

const StyledColSearchContainer = styled(Col)`
  background-color: var(--autocomplete-bg);
  border: 0.6px solid var(--autocomplete-border);
  border-radius: 5px;
  box-shadow: 0px 1px 10px rgba(1, 78, 169, 0.2);
  height: 52px;
  z-index: 5;
`;

const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
  & .ant-input-affix-wrapper {
    & .anticon-caret-down {
      display: none;
      font-size: 12px;
    }
    & .anticon-close-circle {
      font-size: 24px;
    }
  }

  & input {
    background-color: var(--autocomplete-bg);

    &::placeholder {
      color: var(--autocomplete-placeholder);
      font-size: var(--font-size-16);
    }
  }

  & .ant-input-affix-wrapper-lg .ant-input-search-icon::before {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & .ant-select-item-option:first-child {
    padding: 0 !important;
    height: 35px;
    cursor: default !important;
  }

  & .ant-select-item-option {
    padding: 8px 12px !important;
    border-bottom: 1px solid var(--mystic) !important;
  }

  & .ant-select-item-option:not(.ant-select-item-option-disabled):last-child {
    background-color: var(--white) !important;
    cursor: default !important;
    & .see-all-results-container {
      text-align: center;
    }
  }

  & .ant-select-item-empty {
    padding: 0;
    margin-top: -3px;
    cursor: default;
  }

  & button {
    &.ant-input-search-button {
      background: var(--light-sea-blue);

      & svg {
        color: var(--white);
      }
    }
  }
`;

const StyledRow = styled(Row)`
  flex-wrap: nowrap;
`;

// const StyledColRightLinks = styled(Col)`
//   flex: 0 0 70px;
//   height: calc(100vh - 60px);
//   overflow: hidden;
//   background: #f1f9ff;
//   border: 1px solid #c4e0f2;
//   border-bottom: none;
//   position: fixed;
//   right: 0;
//   z-index: 1;
// `;

const StyledAutoCompleteDivContainer = styled.div`
  & em {
    background-color: var(--cyan);
    font-style: normal;
    border-radius: 6px;
    padding: 1px;
    font-weight: 500;
  }
`;

const StyledAutoCompleteRowContainer = styled(Row)`
  font-size: 1.3rem;
  line-height: 15px;
  color: var(--text-color);

  & .ant-col {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledAvatarCol = styled(Col)``;

const StyledCaretDownOutlined = styled(CaretDownOutlined)`
  font-size: 16px;
  color: var(--gray);
`;

const StyledColSmartSearch = styled(Col)`
  margin: 5px 0;
`;

const StyledHeader = styled.div`
  background-color: var(--catskill-white-light);
  padding: 4px 10px 4px 10px;
  color: var(--black);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  padding-top: 5px;
  cursor: default;
`;
const suffix = <StyledCaretDownOutlined />;

const searchResult = (data: any) => {
  const customers = data.map((customer: any, index: number) => ({
    value: customer.customerId.toString(), //toString use here because of fix warning value should be string
    label: (
      <div>
        <StyledAutoCompleteDivContainer>
          <StyledAutoCompleteRowContainer gutter={16}>
            <StyledAvatarCol span={9}>
              <Row justify="start">
                <Col md={12} xxl={4} xl={6}>
                  <ImageLoader
                    customerId={customer.customerId}
                    imageUrl={`/api/customers/images/${customer.customerId}`}
                    classes={`mx-small-avatar mx-small-avatar`}
                    fallbackImg={'customer-pic-38px.svg'}
                    borderRadius={'50%'}
                    statusIcon
                  />
                </Col>
                <StyledColSmartSearch md={12} xxl={20} xl={18}>
                  <div
                    style={{ margin: '0 5px' }}
                    dangerouslySetInnerHTML={{
                      __html: joinValues(
                        [customer.firstNames, customer.surname],
                        true
                      ),
                    }}
                  ></div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: customer.emailAddress,
                    }}
                  ></div>
                </StyledColSmartSearch>
              </Row>
            </StyledAvatarCol>

            <StyledColSmartSearch span={3}>
              {customer.dateOfBirthHighlight ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      '<em>' +
                      format(new Date(customer.dateOfBirth), FNS_DATE_FORMAT) +
                      '</em>',
                  }}
                />
              ) : (
                <span>
                  {format(new Date(customer.dateOfBirth), FNS_DATE_FORMAT)}
                </span>
              )}
            </StyledColSmartSearch>
            <StyledColSmartSearch span={7}>
              <span
                dangerouslySetInnerHTML={{
                  __html: joinValues([
                    customer.property,
                    customer.street,
                    customer.town,
                    customer.county,
                  ]),
                }}
              />
            </StyledColSmartSearch>
            <StyledColSmartSearch span={2}>
              <span
                dangerouslySetInnerHTML={{
                  __html: customer.postcode,
                }}
              />
            </StyledColSmartSearch>
            <StyledColSmartSearch span={3}>
              <span
                dangerouslySetInnerHTML={{
                  __html: customer.mobile,
                }}
              />
            </StyledColSmartSearch>
          </StyledAutoCompleteRowContainer>
        </StyledAutoCompleteDivContainer>
      </div>
    ),
  }));

  return customers;
};

const renderHeader = () => {
  return (
    <StyledHeader>
      <StyledAutoCompleteRowContainer gutter={16}>
        <StyledColSmartSearch span={9}>
          <Row justify="start">
            <Col md={12} xxl={4} xl={6}></Col>
            <Col md={12} xxl={20} xl={18}>
              <span>{CUSTOMER_HEADER_COLUMNS.CUSTOMER_NAME}</span>
            </Col>
          </Row>
        </StyledColSmartSearch>
        <StyledColSmartSearch span={3}>
          <span>{CUSTOMER_HEADER_COLUMNS.DATE_OF_BIRTH}</span>
        </StyledColSmartSearch>
        <StyledColSmartSearch span={7}>
          <span>{CUSTOMER_HEADER_COLUMNS.ADDRESS}</span>
        </StyledColSmartSearch>
        <StyledColSmartSearch span={2}>
          <span>{CUSTOMER_HEADER_COLUMNS.POST_CODE}</span>
        </StyledColSmartSearch>
        <StyledColSmartSearch span={3}>
          <span>{CUSTOMER_HEADER_COLUMNS.MOBILE_NUMBER}</span>
        </StyledColSmartSearch>
      </StyledAutoCompleteRowContainer>
    </StyledHeader>
  );
};
const CustomerSmartSearch = ({
  width,
  serviceType,
  guestType = '',
  onCreateCustomerClick = () => {
    /* do nothing */
  },
  singleCustomerToFind,
  setIsImportCustomer = () => {
    /* do nothing */
  },
  isImportCustomer,
  onCustomerYesClick = () => {},
}: AppContentProps) => {
  const { inputValue } = useSelector((state: any) => state.customer);

  const dispatchCustomer = useDispatch();
  const location = useLocation();

  const [showCustomerResults, setShowCustomerResults] = useState(false);
  const [customerAutosuggestResults, setCustomerAutosuggestResults] = useState<
    SelectProps<object>['options']
  >([]);
  const [customerResults, setCustomerResults] = useState([]);
  const [selectedCustomer, setSelectedCustomer] =
    useState<CustomerPopupProps>();
  const [searchInProgress, setSearchInProgress] = useState<boolean>(false);
  const [noResult, setNoResult] = useState<boolean>(false);
  const [isGdprMessageVisible, setGdprMessageVisible] =
    useState<boolean>(false);
  const [isGdprDeclineVisible, setGdprDeclineVisible] =
    useState<boolean>(false);
  const [isRecallPopupVisible, setRecallPopupVisible] =
    useState<boolean>(false);
  const [recallMessage, setRecallMessage] = useState<string>('');
  const [recallTitle, setRecallTitle] = useState<string>('');

  const lastQuery = useRef<string>();
  const searchInput = useRef<InputRef>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (inputValue === '') {
      setCustomerAutosuggestResults([]);
      setNoResult(false);
    }
  }, [inputValue]);

  const debouncedSearch = React.useRef(
    debounce(async (searchValue: any, callback: any) => {
      const response = await searchCustomers(searchValue, true);
      setCustomerAutosuggestResults([]);
      setSearchInProgress(false);

      if (searchValue !== '' && searchValue.length >= 3) {
        callback(
          response?.data?.customers,
          response?.data?.totalCount,
          searchValue
        );
      }
    }, 500)
  ).current;

  const customersResult = useCallback(
    async (searchValue: string, callback: any) => {
      if (searchValue === '') {
        setCustomerAutosuggestResults([]);
        setSearchInProgress(false);
        return;
      }
      try {
        setSearchInProgress(true);
        debouncedSearch(searchValue, callback);
      } catch (e: any) {
        if (e?.message === 'Network Error') {
          setSearchInProgress(false);
          notification.error({
            message: e?.message,
            description: e?.message,
            duration: 5,
          });
        }
      }
    },
    [debouncedSearch]
  );

  const onRegisterMessageClick = useCallback(() => {
    dispatchCustomer(actions.clearAll({}));
    setGdprMessageVisible(true);
  }, [dispatchCustomer]);

  const renderRegisterCustomerLink = useCallback(() => {
    return guestType ? (
      <>
        Didn&apos;t find the right match?
        <button className="click-here-result" onClick={onCreateCustomerClick}>
          Click here to
          {guestType === GUEST_TYPES.VALUATION
            ? ' record guest details for valuation purpose'
            : guestType === GUEST_TYPES.FX
            ? ' create TM lite customer'
            : ''}
        </button>
      </>
    ) : (
      <button className="click-here-result" onClick={onRegisterMessageClick}>
        {CLICK_HERE_REGISTER_MESSAGE}
      </button>
    );
  }, [guestType, onCreateCustomerClick, onRegisterMessageClick]);

  const seeAllResultsRow = useCallback(
    (searchValue: string) => {
      return (
        <div className="see-all-results-container">
          <button
            className="see-all-results"
            onClick={() => {
              setCustomerAutosuggestResults([]);
              setShowCustomerResults(true);
              if (!serviceType) {
                navigate(`/${ROUTE_CONFIG.CUSTOMERSEARCH}`);
              }
            }}
          >
            {`See all results for "${searchValue}"`}
          </button>
          <div>{renderRegisterCustomerLink()}</div>
        </div>
      );
    },
    [navigate, renderRegisterCustomerLink, serviceType]
  );

  const newCustomerRow = useCallback(() => {
    return (
      <div className="new-customer-container">
        {renderRegisterCustomerLink()}
      </div>
    );
  }, [renderRegisterCustomerLink]);

  const onSearch = useCallback(
    (searchValue: string) => {
      setCustomerAutosuggestResults([]);
      setShowCustomerResults(false);
      setNoResult(false);
      lastQuery.current = searchValue;
      const headerOptions = [
        { value: '', label: renderHeader(), disabled: 'disabled' },
      ];

      customersResult(
        searchValue,
        (data: any, totalCount: number, searchKey: string) => {
          if (data.length === 0) {
            setNoResult(true);
            setCustomerAutosuggestResults([...searchResult(data)]);
          }
          if (totalCount > 10) {
            const extraOption = [
              { value: searchValue, label: seeAllResultsRow(searchKey) },
            ];
            setCustomerAutosuggestResults([
              ...headerOptions,
              ...searchResult(data),
              ...extraOption,
            ]);
          } else if (data.length > 0 && totalCount <= 10) {
            const extraOption = [{ label: newCustomerRow() }];
            setCustomerAutosuggestResults([
              ...headerOptions,
              ...searchResult(data),
              ...extraOption,
            ]);
          }
          setCustomerResults(data);
        }
      );
    },
    [customersResult, newCustomerRow, seeAllResultsRow]
  );

  const noResultRow = () => {
    return (
      <>
        {renderHeader()}
        <div className="no-result-container">
          <div className="no-results">
            {`${SORRY_MESSAGE}`}
            <span className="capitalize"> &apos;{inputValue}&apos;</span>
          </div>
          {renderRegisterCustomerLink()}
        </div>
      </>
    );
  };

  const onSelect = (data: any): void => {
    const selectedData: any = customerResults.find(
      ({ customerId }) => customerId === +data
    );
    setSelectedCustomer(selectedData);
  };

  const onCustomerPopupCancel = () => {
    dispatchCustomer(actions.setInputValue(''));
    setSelectedCustomer(undefined);
    if (serviceType) dispatchCustomer(actions.setRedirectToOtherPage(false));
    if (isImportCustomer) {
      setIsImportCustomer();
    }
  };

  const onCustomerPopupOk = async (customer: any) => {
    if (serviceType) {
      const customerCopy = JSON.parse(JSON.stringify(customer));
      customerCopy.firstNames = removeHTMLTags(customer.firstNames);
      customerCopy.surname = removeHTMLTags(customer.surname);
      customerCopy.postcode = removeHTMLTags(customer.postcode);
      customerCopy.emailAddress = removeHTMLTags(customer?.emailAddress);
      dispatchCustomer(actions.setRedirectToOtherPage(false));
      dispatchCustomer(actions.getCustomerResponse(customerCopy)); //customer session
      !isImportCustomer &&
        dispatchCustomer({
          type: MERGE_GUEST_BASKET_REQUEST,
        });

      //initating Surplus check
      dispatchCustomer(actions.initSurplusCheck(true));
    } else {
      try {
        const response = await getBasket(customer?.customerId);
        if (response?.data) {
          if (response?.data?.basket?.basketId !== null) {
            const customerId = customer?.customerId;
            dispatchCustomer({
              type: CUSTOMER_PROFILE_REQUEST,
              payload: { customerId },
            });
            !isImportCustomer && setRecallPopupVisible(true);
            if (
              response?.data?.basket?.basketStatus === BASKET_STATUS.PENDING
            ) {
              setRecallMessage(RECALL_MSG.START_BASKET_JOURNEY);
              setRecallTitle(RECALL_TITLE.BASKET);
            } else if (
              response?.data?.basket?.basketStatus ===
                BASKET_STATUS.INITIALISED ||
              response?.data?.basket?.basketStatus ===
                BASKET_STATUS.SUCCESSFUL ||
              response?.data?.basket?.basketStatus === BASKET_STATUS.FAILED
            ) {
              setRecallMessage(RECALL_MSG.START_CHECKOUT_JOURNEY);
              setRecallTitle(RECALL_TITLE.CHECKOUT);
            }
          } else redirectToCustomerSummary(customer);
        } else if (response?.status === RESPONSE_STATUS.NO_CONTENT)
          redirectToCustomerSummary(customer);
      } catch (e: any) {
        redirectToCustomerSummary(customer);
      }
    }
    if (isImportCustomer) {
      onCustomerYesClick();
      setIsImportCustomer();
    }
  };

  const redirectToCustomerSummary = (customer: any) => {
    dispatchCustomer(actions.initSurplusCheck(true));
    navigate(
      `/${ROUTE_CONFIG.CUSTOMER}/${ROUTE_CONFIG.SUMMARY}/${customer?.customerId}`
    );
  };

  const resetSearch = () => {
    setShowCustomerResults(false);
    onCustomerPopupCancel();
  };

  useEffect(() => {
    if (location?.pathname === `/${ROUTE_CONFIG.CUSTOMERSEARCH}`) {
      setShowCustomerResults(true);
    } else {
      dispatchCustomer(actions.setInputValue(''));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      singleCustomerToFind?.id > 0 &&
      singleCustomerToFind?.name !== '' &&
      isImportCustomer
    ) {
      customersResult(
        singleCustomerToFind?.name,
        (data: any, totalCount: number, searchKey: string) => {
          if (data.length > 0 && totalCount > 0) {
            setCustomerResults(data);
            const selectedData: any = data.find(
              ({ customerId }: any) => customerId === +singleCustomerToFind?.id
            );
            if (!selectedCustomer) setSelectedCustomer(selectedData);
          }
        }
      );
    }
  }, [
    singleCustomerToFind,
    selectedCustomer,
    isImportCustomer,
    customersResult,
  ]);

  const dropdownClassName = noResult ? 'no-search-list' : 'smart-search-list';

  useEffect(() => {
    if (searchInput) {
      searchInput.current?.focus();
    }
  }, []);

  return (
    <StyledRow data-testid="customer-search-container">
      {/* <Col span={23}> // Commenting as per MP-6311*/}
      {/* {isEmpty(singleCustomerToFind) && singleCustomerToFind?.name === '' && ( */}
      <Col span={24}>
        <Row align="middle" className="search-control-box">
          <Col span={width || 24}>
            <Row align="middle">
              <StyledColSearchContainer span={width || 24}>
                <StyledAutoComplete
                  options={customerAutosuggestResults}
                  data-testid="search-autocomplete"
                  onSelect={onSelect}
                  value={inputValue}
                  searchValue={lastQuery.current}
                  popupClassName={dropdownClassName}
                  onSearch={onSearch}
                  notFoundContent={
                    searchInProgress ? (
                      <CustomerSearchLoader />
                    ) : inputValue !== '' && noResult ? (
                      noResultRow()
                    ) : null
                  }
                  virtual={false}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  <Input.Search
                    ref={searchInput}
                    allowClear
                    size="middle"
                    width={50}
                    placeholder="Customer search: Search by Name or Phone Number"
                    value={inputValue}
                    onChange={(e) => {
                      dispatchCustomer(actions.setInputValue(e.target.value));
                      if (e.type === 'click') {
                        navigate(`/`);
                      }
                    }}
                    suffix={suffix}
                    onPressEnter={() => {
                      setCustomerAutosuggestResults([]);
                      inputValue && setShowCustomerResults(true);
                      if (!serviceType) {
                        navigate(`/${ROUTE_CONFIG.CUSTOMERSEARCH}`);
                      }
                    }}
                    onSearch={() => {
                      setCustomerAutosuggestResults([]);
                      inputValue && setShowCustomerResults(true);
                      if (!serviceType)
                        navigate(`/${ROUTE_CONFIG.CUSTOMERSEARCH}`);
                    }}
                  />
                </StyledAutoComplete>
              </StyledColSearchContainer>
              <HelpPopoverInfo
                linkedID={'CUSTOMERSEARCH_SEARCHBAR'}
                position="rightTop"
              ></HelpPopoverInfo>
            </Row>
          </Col>
          <Col span={4}></Col>
        </Row>
        {showCustomerResults && !noResult && (
          <CustomerList
            searchParam={inputValue}
            resetSearch={resetSearch}
            serviceType={serviceType}
          />
        )}
      </Col>

      {/* {width ? (
        ''
      ) : (
        <StyledColRightLinks span={1}>
          <SideLinks />
        </StyledColRightLinks> //Commenting as per MP-6311
      )} */}
      {!isEmpty(selectedCustomer) && (
        <CustomerPopup
          customer={selectedCustomer}
          onCustomerPopupCancel={onCustomerPopupCancel}
          onCustomerPopupOk={onCustomerPopupOk}
        />
      )}
      {isGdprMessageVisible && (
        <GdprMessagePopup
          visible={isGdprMessageVisible}
          setGdprMessageVisible={() => setGdprMessageVisible(false)}
          setGdprDeclineVisible={(visibility: boolean) =>
            setGdprDeclineVisible(visibility)
          }
          serviceType={serviceType}
        />
      )}
      {isGdprDeclineVisible && (
        <GdprDeclinePopup
          visible={isGdprDeclineVisible}
          setGdprDeclineVisible={(visibility: boolean) =>
            setGdprDeclineVisible(visibility)
          }
          resetSearch={resetSearch}
        />
      )}
      {isRecallPopupVisible && (
        <RecallPopup
          visible={isRecallPopupVisible}
          setRecallPopupVisible={() => setRecallPopupVisible(false)}
          recallMessage={recallMessage}
          recallTitle={recallTitle}
        />
      )}
    </StyledRow>
  );
};

export default CustomerSmartSearch;
