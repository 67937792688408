import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  basket: {
    basketId: null, //null for creating a basket
    customerId: null,
    storeId: null,
    userId: null,
    serviceId: null,
    basketStatus: null,
    basketServices: [],
    totalAmount: 0,
  },
  editModeBasketPledgeItems: {},
  error: {},
  isLoading: false,
  isGuestBasketRestoreLoading: false,
  isGuestBasketRestoreError: false, // checking if merge-guest-basket is success or not
  deleteLoadingSpin: {},
  mergedPayload: {
    basketId: null,
    userId: null,
    sourceServiceId: null,
    targetServiceId: null,
    itemIds: [],
    serviceIds: [],
    sourceServiceType: null,
  },
  isAddPledgeItemsFromBasket: false,
  isMonthlyInterestPercentageLoading: false,
  isMergedLoading: false,
  isSplitLoading: false,
  isSelectedInterestOnSplit: false,
  removePledge: false,
  isMoveToLoading: false, // moving from pledge to purchase and vice-versa,
  currentActiveTab: '', //for active selected tab on basket,
  validateBasketServicesPayload: {
    basketId: null,
    services: [],
  },
  isServicesValidated: false,
  resetChildTabOnSplitAdd: false,
  isMergeLocking: false,
  isBasketMerging: false,
  isBasketMergeError: false, // checking if basket is merged when searching for a customer ; merge-guest-basket on finding customer
  initialInterestRate: 9.99, // for pledge loan calculation
  initialApr: 155.8, // for pledge loan calculation
  interestRateList: [],
  isAutoCalculated: true, // true for Basket % default
  currenciesAvailable: {
    //Always validate available currencies
    currencies: [],
    isCurrenciesNotAvailable: false,
    isPayByOtherMethods: [], //pay by card, pay by faster payments
  },
  noCurrenciesAvailableCheck: false,
  surplusData: [],
  isExitandSaveMode: false,
  errorCode: 0,
  isPartPayment: false,

  deletedTab: '',
  isExitClearBasket: false,
  //consumerDuty validation state
  consumerDutyBlockedServices: [],
  isConsumerDutyActionsValidated: false,
  consumerDutyValidationError: {
    message: '',
    error: false,
  },
};
const slice = createSlice({
  name: 'basketActions',
  initialState,
  reducers: {
    createBasket(state, action) {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        deleteLoadingSpin: {},
        removePledge: false,
        isMonthlyInterestPercentageLoading: false,
        isMergedLoading: false,
        isBasketMerging: false,
        isSplitLoading: false,
        mergedPayload: {
          ...state.mergedPayload,
          itemIds: [],
          serviceIds: [],
        },
        isMoveToLoading: false,
      };
    },
    getBasket(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setBasketPledgeItemsEditMode(state, action) {
      state.editModeBasketPledgeItems = action.payload;
      state.isLoading = false;
      state.deleteLoadingSpin = {};
    },
    clearEditMode(state) {
      state.editModeBasketPledgeItems = {};
    },
    initLoading(state, action) {
      state.isLoading = action.payload;
    },
    initGuestBasketRestoreLoading(state, action) {
      state.isGuestBasketRestoreLoading = action.payload;
    },
    setGuestBasketRestoreError(state, action) {
      state.isGuestBasketRestoreError = action.payload;
    },
    setBasketMergeError(state, action) {
      state.isBasketMergeError = action.payload;
    },
    initDeleteLoadingSpin(state, action) {
      state.deleteLoadingSpin = action.payload;
      state.removePledge = true;
      state.mergedPayload = {
        ...state.mergedPayload,
        itemIds: [],
        serviceIds: [],
      };
      // state.isSelectedInterestOnSplit = false;
    },
    deletedTab(state, action) {
      state.deletedTab = action.payload;
    },
    itemError(state, action) {
      state.error = {
        message: action.payload.message,
        description: action?.payload?.response?.data?.error,
      };
      state.isLoading = false;
      state.deleteLoadingSpin = {};
      state.removePledge = false;
      state.isMergedLoading = false;
      state.isBasketMerging = false;
      state.isSplitLoading = false;
      state.isSelectedInterestOnSplit = false;
      state.isMoveToLoading = false;
    },
    removeBasket(state, action) {
      state.basket = {};
      state.error = {
        message: action?.payload?.message,
        description: action?.payload?.response?.data?.error,
      };
      state.isLoading = false;
      state.deleteLoadingSpin = {};
      state.isMergedLoading = false;
      state.isBasketMerging = false;
      state.isSelectedInterestOnSplit = false;
      state.isMoveToLoading = false;
      state.isBasketMergeError = false;
    },
    move(state, action) {
      state.mergedPayload = {
        sourceServiceId: action.payload.sourceServiceId,
        targetServiceId: action.payload.targetServiceId,
        itemIds: action.payload.itemIds,
        serviceIds: action.payload.serviceIds,
        userId: action.payload.userId,
        basketId: action.payload.basketId,
        sourceServiceType: action.payload.sourceServiceType,
      };
      state.isMergeLocking = false;
    },
    addPledgeItemsFromBasket(state, action) {
      state.isAddPledgeItemsFromBasket = action.payload; // Add New Items to pledge, Create new pledge from basket screen
    },
    clearMove(state) {
      state.mergedPayload = initialState.mergedPayload;
    },
    setMoveToLoading(state, action) {
      state.isMoveToLoading = action.payload;
    },
    addNewPledge(state, action) {
      state.basket.basketServices.push(action.payload);
    },
    interestPercentageLoading(state, action) {
      state.isMonthlyInterestPercentageLoading = true;
    },
    updateInitialInterestRate(state, action) {
      state.initialInterestRate = action.payload;
    },
    setInterestRateList(state, action) {
      state.interestRateList = action.payload;
    },
    setAutoCalculatedBasketLoanAmount(state, action) {
      state.isAutoCalculated = action.payload;
    },
    initMerge(state, action) {
      state.isMergedLoading = true;
    },
    lockMerge(state, action) {
      state.isMergeLocking = true;
    },
    unlockMerge(state, action) {
      state.isMergeLocking = false;
      state.mergedPayload = initialState.mergedPayload;
    },
    initSplit(state, action) {
      state.isSplitLoading = true;
      // state.isSelectedInterestOnSplit = true;  // MP-4784
    },
    onChangeInterest(state, action) {
      state.isSelectedInterestOnSplit = action.payload;
    },
    updateRetailDiscount(state, action) {
      const index = state.basket.basketServices.findIndex(
        (x) => x.serviceId === action.payload.serviceId
      );
      state.basket.basketServices[index].retail.request.items = [
        ...action.payload.changedRows,
      ];
    },
    setCurrentActiveTab(state, action) {
      state.currentActiveTab = action.payload;
    },
    setValidateCheckoutServices(state, action) {
      state.validateBasketServicesPayload = {
        basketId: state?.basket?.basketId,
        services: [
          ...state.validateBasketServicesPayload.services,
          action.payload,
        ],
      };
      state.isServicesValidated = false;
    },
    setProceedForCheckout(state, action) {
      const isValid = state.validateBasketServicesPayload.services.some(
        ({ authorizationToken }) => authorizationToken === null
      );
      state.isServicesValidated = action?.payload ? !isValid : true;
    },
    setResetChildTabOnSplitAdd(state, action) {
      state.resetChildTabOnSplitAdd = true;
    },
    setCurrentChildActiveTab(state, action) {
      state.currentChildActiveTab = action.payload;
    },
    setCurrenciesAvailable(state, action) {
      state.currenciesAvailable = {
        currencies: action.payload.currencies,
        isCurrenciesNotAvailable: action.payload.isCurrenciesNotAvailable,
        isPayByOtherMethods: [...state.currenciesAvailable.isPayByOtherMethods],
      };
    },
    clearCurrenciesAvailable(state, action) {
      state.currenciesAvailable = {
        currencies: [],
        isCurrenciesNotAvailable: false,
        isPayByOtherMethods: [],
      };
    },
    updatePayByOtherMethods(state, action) {
      state.currenciesAvailable = {
        ...state.currenciesAvailable,
        isPayByOtherMethods: action.payload,
      };
    },
    updateCurrenciesNotAvailable(state) {
      state.currenciesAvailable = {
        ...state.currenciesAvailable,
        isCurrenciesNotAvailable: false,
      };
    },
    setIsBasketMerging(state, action) {
      state.isBasketMerging = action.payload;
    },
    setSurplusData(state, action) {
      state.surplusData = action.payload;
    },
    clearResetChildTabOnSplitAdd(state, action) {
      state.resetChildTabOnSplitAdd = false;
    },
    clearCurrentChildActiveTab(state, action) {
      state.currentChildActiveTab = '';
    },
    clearValidateCheckoutServices(state, action) {
      state.validateBasketServicesPayload = {
        basketId: null,
        services: [],
      };
    },
    clearIsServicesValidate(state, action) {
      state.isServicesValidated = false;
      state.isConsumerDutyActionsValidated = false;
      state.consumerDutyBlockedServices = [];
    },
    clearBasket(state) {
      return {
        ...state,
        basket: { ...state.basket, serviceId: null, basketServices: [] },
        isAddPledgeItemsFromBasket: false,
        editModeBasketPledgeItems: {},
        mergedPayload: initialState.mergedPayload,
        isSelectedInterestOnSplit: false,
        isMoveToLoading: false,
        isGuestBasketRestoreError: false,
        isBasketMergeError: false,
      };
    },
    setErrorCode(state, action) {
      state.errorCode = action.payload;
    },
    clearError(state) {
      state.error = {};
      state.errorCode = 0;
    },
    clearItems() {
      return {
        ...initialState,
      };
    },
    setIsExitClearBasket(state, action) {
      state.isExitClearBasket = action.payload;
    },
    checkIsExitAndSaveMode(state, action) {
      state.isExitandSaveMode = action.payload;
    },
    setIsPartPayment(state, action) {
      state.isPartPayment = action.payload;
    },

    //consumerDuty validation Actions
    setConsumerDutyBlockedServices(state, action) {
      state.consumerDutyBlockedServices = action.payload;
    },
    setConsumerDutyActionsValidated(state, action) {
      state.isConsumerDutyActionsValidated = action.payload;
    },
    setConsumerDutyValidationError(state, action) {
      state.consumerDutyValidationError = action.payload;
    },
  },
});

export default slice.reducer;
export const { reducer, actions } = slice;
export { initialState };
