import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomerHeader from '../customer/customerHeader';
import { Row, Col, notification } from 'antd';
import styled from 'styled-components';
import {
  LOAN_AMOUNT_CALCULATOR_ON_BASKET,
  PUT_TEN_ITEMS_IN_BASKET,
} from 'action_creators/basket';
import AddPledge from './addPledge';
import ViewPledgePurchase from './viewPledgePurchase';
import {
  INCOMPLETE_PLEDGE_WARNING,
  PLEDGE_ACTIONS,
  PROCEED_TO_BASKET_LOADER_TIP,
} from './constants';
import { ROUTE_CONFIG } from '../../globalConstants';
import PrintQuotePopup from './PrintQuotePopup';
import { actions } from 'redux/reducers/basket';
import { useDispatch, useSelector } from 'react-redux';
import FooterButtons from 'components/common/footerButtons';
import { pledgeActions } from 'redux/reducers/pawnbroking';
import { actions as customerActions } from 'redux/reducers/customer';
import { actions as valuationActions } from 'redux/reducers/valuationCenter';
import { coinActions } from 'redux/reducers/pawnbroking/coin';
import { stoneActions } from 'redux/reducers/pawnbroking/stone';
import { isEmpty } from 'lodash';
import AddFromHistoryPopup from 'components/pledge/addPledge/addFromHistoryPopup';
import AddFromValuationPopup from './addPledge/addFromValuationPopup';
import { filterBasketItemsOnServiceId } from 'utils/util';

import ControlledAlert from 'components/common/ControlledAlert';
import Spinner from 'components/common/Spinner';
import { BASKET_SERVICE_TYPE } from 'components/basket/constants';

const StyledMainWrapper = styled(Col)`
  min-height: calc(100vh - 200px);
  & .ant-select-selector {
    color: var(--black) !important;
  }
  & .ant-input-disabled {
    color: var(--black) !important;
  }
`;

const StyledMainContainer = styled.div<{ $addPledgeContainer: boolean }>`
  background: var(--white);
  border: 0.6px solid var(--linkwater);
  border-bottom: none;
  box-shadow: 0px 1px 10px rgba(1, 78, 169, 0.2);
  border-radius: 5px 5px 0 0;
  margin: ${(props) =>
    props.$addPledgeContainer ? '0px 5px 0px 15px' : '0px 15px 0px 5px'};
  color: var(--black);
  display: flex;
  flex-direction: column;
`;

const StyledButtonContainerRow = styled(Row)`
  margin: 0px 15px 10px;
`;

const Pledge = () => {
  const {
    customer: { customer, resumeJourneyUrl },
    basket: {
      basket,
      isAddPledgeItemsFromBasket,
      editModeBasketPledgeItems,
      serviceType,
      serviceId,
    },
    pawnbroking: {
      pledgeServiceId,
      purchaseServiceId,
      itemDescription,
      itemImageCollectionResponse,
      interestRateCounter,
    },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
      basket: state.basket,
      pawnbroking: state.pawnbroking,
    };
  });

  const [isPrintQuotePopupVisible, setPrintQuotePopupVisible] =
    useState<boolean>(false);
  const [isAddFromHistoryPopupVisible, setAddFromHistoryPopupVisible] =
    useState<boolean>(false);
  const [isAddFromValuationPopupVisible, setIsAddFromValuationPopupVisible] =
    useState<boolean>(false);
  const [showIncompletePledgeWarning, setShowIncompletePledgeWarning] =
    useState<boolean>(false);
  const [basketProceedingLoader, setBasketProceedingLoader] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onProceedToBasketClick = () => {
    if (
      serviceType === BASKET_SERVICE_TYPE.PLEDGE &&
      !isEmpty(pledgeServiceId)
    ) {
      const isPledgeFound = basket?.basketServices?.find(
        (x: any) => serviceId === x.serviceId
      );

      if (isPledgeFound?.pledge?.request?.interestRate === 0) {
        if (interestRateCounter < 2) {
          dispatch(
            pledgeActions.setInterestRateCounter(interestRateCounter + 1)
          );
          dispatch({
            type: LOAN_AMOUNT_CALCULATOR_ON_BASKET,
          });
          return;
        } else {
          notification.error({
            message:
              'Unable to proceed further as the interest rate for pledge is 0%',
            duration: 3,
          });
          return;
        }
      }
    }

    setBasketProceedingLoader(true);
    dispatch(actions.addPledgeItemsFromBasket(false));
    dispatch(actions.clearMove());
    dispatch(actions.clearEditMode());
    dispatch(pledgeActions.clearServiceIds());
    dispatch(valuationActions.setValuationItemToPledge(false));
    dispatch(valuationActions.setOpportunityId(''));
    dispatch(pledgeActions.setInterestRateCounter(0));

    if (pledgeServiceId || purchaseServiceId) {
      dispatch({
        type: PUT_TEN_ITEMS_IN_BASKET,
        navigate,
      });
    } else {
      setBasketProceedingLoader(false);
      navigate(`/${ROUTE_CONFIG.BASKET}`);
    }
  };

  const onConfirmExitClick = () => {
    dispatch(actions.clearBasket());
    dispatch(pledgeActions.clearItems());
    dispatch(coinActions.clearCoinItems());
    dispatch(stoneActions.clearStoneItems());
    dispatch(valuationActions.clearValuation());
  };

  const onAddFromHistoryClick = () => {
    setAddFromHistoryPopupVisible(true);
  };

  const onAddFromValuationClick = () => {
    setIsAddFromValuationPopupVisible(true);
  };

  useEffect(() => {
    if (!isAddPledgeItemsFromBasket && isEmpty(editModeBasketPledgeItems)) {
      // not clearing view pledge summary when returning to same journey from customer header.
      if (resumeJourneyUrl === `/${ROUTE_CONFIG.PLEDGE}`) {
        dispatch(
          customerActions.setCustomerFromHeader({
            resumeJourneyUrl: '',
          })
        );
      } else {
        dispatch(pledgeActions.clearServiceIds());
      }
      dispatch(pledgeActions.clearItems());
      dispatch(coinActions.clearCoinItems());
      dispatch(stoneActions.clearStoneItems());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isExitAndSaveEnabled = () => {
    const itemsInPledgeSummary =
      filterBasketItemsOnServiceId(basket, pledgeServiceId) || [];
    const itemsInPurchaseSummary =
      filterBasketItemsOnServiceId(basket, purchaseServiceId) || [];
    if (itemsInPledgeSummary?.length > 0 || itemsInPurchaseSummary?.length > 0)
      return true;
    return false;
  };

  const checkForDescribedPledge = () => {
    if (basket?.basketId !== null) {
      if (itemDescription || !isEmpty(itemImageCollectionResponse))
        setShowIncompletePledgeWarning(true);
      else onProceedToBasketClick();
    }
  };

  return (
    <>
      <CustomerHeader
        title="Create"
        buttonsOption={true}
        helpSection="Pledge"
      />
      <Spinner
        tip={PROCEED_TO_BASKET_LOADER_TIP}
        size="large"
        spinning={basketProceedingLoader}
      >
        <Row>
          <StyledMainWrapper span={14}>
            <StyledMainContainer $addPledgeContainer={true}>
              <AddPledge />
            </StyledMainContainer>
          </StyledMainWrapper>
          <StyledMainWrapper span={10}>
            <StyledMainContainer $addPledgeContainer={false}>
              <ViewPledgePurchase />
            </StyledMainContainer>
          </StyledMainWrapper>
        </Row>
      </Spinner>
      <StyledButtonContainerRow>
        <Col span={24}>
          <FooterButtons
            proceedButtonText={PLEDGE_ACTIONS.PROCEED_TO_BASKET}
            footerBtnRowPadding="7px 30px !important"
            onProceedClick={checkForDescribedPledge}
            onConfirmExitClick={onConfirmExitClick}
            isExitAndClear={
              !(basket?.totalPledgeAmount + basket?.totalPurchaseAmount)
            }
            isExitAndSaveEnabled={isExitAndSaveEnabled()}
            helpSection="PLEDGE"
            enableBackButton
            backButtonText="Add from History"
            isBackButtonDisable={!customer.customerId}
            onBackButtonClick={onAddFromHistoryClick}
            // Add from valuation button props
            extraButtonEanable={true}
            extraButtonAlign="left"
            extraButtonText={PLEDGE_ACTIONS.ADD_FROM_VALUATION}
            extraButtonItemProp="secondary"
            extraButtonOnClick={onAddFromValuationClick}
          />
        </Col>
      </StyledButtonContainerRow>

      {isPrintQuotePopupVisible && (
        <PrintQuotePopup
          visible={isPrintQuotePopupVisible}
          setPrintQuotePopupVisible={() => setPrintQuotePopupVisible(false)}
        />
      )}
      {isAddFromHistoryPopupVisible && (
        <AddFromHistoryPopup
          visible={isAddFromHistoryPopupVisible}
          setAddFromHistoryPopupVisible={() =>
            setAddFromHistoryPopupVisible(false)
          }
        />
      )}

      {isAddFromValuationPopupVisible && (
        <AddFromValuationPopup
          visible={isAddFromValuationPopupVisible}
          setAddFromValuationPopupVisible={() =>
            setIsAddFromValuationPopupVisible(false)
          }
        />
      )}

      {showIncompletePledgeWarning && (
        <ControlledAlert
          width={480}
          closable={true}
          visible={showIncompletePledgeWarning}
          message={INCOMPLETE_PLEDGE_WARNING}
          onClick={onProceedToBasketClick}
          yesButtonText={'Proceed'}
          cancelButtonText={'Cancel'}
          onCancel={() => setShowIncompletePledgeWarning(false)}
        />
      )}
    </>
  );
};

export default Pledge;
