import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
// import { Col, Row, Modal, Checkbox, notification } from 'antd';
import { Col, Row, Modal, notification } from 'antd';

import { Button } from '../Button';
import { postWorkQueueNotification } from 'services/valuationCenter';

const StyledModal = styled(Modal)`
  &.valuation-popover {
    font-weight: var(--font-weight-500);
    text-align: center;

    & .ant-modal-body {
      font-size: var(--font-size-16);

      & .custom-content {
        font-weight: var(--font-weight-400);
      }

      & .custom-checkbox {
        & label {
          font-weight: var(--font-weight-600);
        }
      }
    }
  }

  .ant-modal-footer {
    text-align: center;
  }
`;

const StyledRow = styled(Row)`
  margin: 0 0 15px 0;
  justify-content: center;
`;

const StyledTitle = styled.div`
  font-size: var(--font-size-20);
  line-height: 25px;
`;

// const options = [
//   { label: 'Send Text', value: 'text' },
//   { label: 'Send Email', value: 'email' },
//   { label: 'Print', value: 'print' },
// ];

const ValuationPopover = ({
  visible,
  onValuationPopoverClose,
  details = {},
}: any) => {
  const { user } = useSelector((state: any) => state.user);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);

  const onOkClick = async () => {
    const params = {
      workqueueRequestId: details?.workqueueRequestId,
      workqueueRequestDataId: details?.workqueueRequestDataId,
      // sendText: selectedOptions?.includes('text'),
      // sendEmail: selectedOptions?.includes('email'),
      // print: selectedOptions?.includes('print'),
      userId: user?.userId,
    };

    try {
      setIsLoading(true);

      const response = await postWorkQueueNotification(params);
      if (response?.data) {
        if (response?.data?.success) onValuationPopoverClose();
        else
          notification.error({
            message: response?.data?.message,
            duration: 5,
          });
      } else
        notification.error({
          message: 'Something went wrong, Please try again',
          duration: 5,
        });

      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
    }
  };

  // const onChange = (event: any) => {
  //   setSelectedOptions(event);
  // };

  return (
    <StyledModal
      centered
      open={visible}
      closable={false}
      width={457}
      onOk={onOkClick}
      className="valuation-popover"
      footer={[
        <Button
          loading={isLoading}
          itemProp="secondary"
          key="submit"
          onClick={onOkClick}
        >
          Ok
        </Button>,
      ]}
    >
      <Row justify="center">
        <Col span="24" style={{ margin: '0 60px' }}>
          <StyledRow>
            <StyledTitle>Valuation Sent Successfully</StyledTitle>
          </StyledRow>
          <StyledRow className="custom-content">
            You will be notified when the valuation team has provided a response
            via Notifications.
          </StyledRow>
          <StyledRow>
            <div>Reference Number: {details?.opportunity?.opportunitY_ID}</div>
          </StyledRow>
          {/* <StyledRow>
            <Checkbox.Group
              className="custom-checkbox"
              options={options}
              onChange={onChange}
            />
          </StyledRow> */}
        </Col>
      </Row>
    </StyledModal>
  );
};
export default ValuationPopover;
