import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Input, Tabs, Dropdown, Menu } from 'antd';
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons/lib/icons';
import PledgeSummary from './PledgeSummary';
import { MOVE_BASKET_PLEDGE_PURCHASE_ITEMS } from 'action_creators/basket';
import { DropdownButton } from 'components/common/DropdownButton';
import { BASKET_SERVICE_TYPE } from 'components/basket/constants';
import {
  calculateTotalSumByKey,
  calculateTotalWeight,
  filterBasketItemsOnServiceId,
} from 'utils/util';
import PurchaseSummary from './PurchaseSummary';
import PledgeLoanContainer from './PledgeLoanContainer';
import PurchaseTableContainer from './PurchaseTableContainer';
import { PLEDGE_ACTIONS, PURCHASE_ITEM_TABLE } from '../constants';
import '../index.less';
import isEmpty from 'lodash/isEmpty';
import { pledgeActions } from 'redux/reducers/pawnbroking';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';

const StyledRow = styled(Row)`
  background: var(--solitude);
  flex-grow: 1;
`;

const StyledText = styled.div`
  font-weight: var(--font-weight-500);
  line-height: 16px;
  padding: 0px;
`;

const StyledTotalLoanRow = styled(Row)`
  background: var(--cyan);
  padding: 5px 30px 5px 25px;
`;

const StyledTotalLoanCol = styled(Col)`
  display: flex;
  align-items: center;
  font-size: var(--font-size-16);
`;

const StyledTableWrapper = styled.div`
  min-height: 294px;
`;

const PledgeLoanWrapper = styled.div`
  padding: 10px 30px 10px 20px;
  background: var(--catskill-white);
`;

const renderPledgeLoanContainer = (item: any, basketId: any, userId: any) => {
  const allPledgeItems = item?.pledge?.request?.items;
  const loanAmount = calculateTotalSumByKey(allPledgeItems, 'loanAmount');
  const weight = calculateTotalWeight(allPledgeItems);
  return (
    <PledgeLoanContainer
      pledgeItemRows={item}
      basketId={basketId}
      userId={userId}
      loanAmount={loanAmount}
      totalWeight={weight}
    />
  );
};

const ViewPledgePurchase = () => {
  const {
    pawnbroking: { serviceType, pledgeServiceId, purchaseServiceId },
    basket: { basket, isAddPledgeItemsFromBasket, isMoveToLoading },
    user: { user },
  } = useSelector((state: any) => {
    return {
      pawnbroking: state.pawnbroking,
      basket: state.basket,
      user: state.user,
    };
  });
  const [pledgeSummaryItems, setPledgeSummaryItems] = useState<any>([]);
  const [purchaseSummaryItems, setPurchaseSummaryItems] = useState<any>([]);
  const [activeKey, setActiveKey] = useState<string>('1');
  const [itemsToMove, setItemsToMove] = useState<any>({});

  const dispatch = useDispatch();

  const { basketId } = Object(basket);

  const filterItemsToViewInPledge = (serviceType: number) => {
    if (serviceType === BASKET_SERVICE_TYPE.PLEDGE)
      return filterBasketItemsOnServiceId(basket, pledgeServiceId) || [];
    if (serviceType === BASKET_SERVICE_TYPE.PURCHASE)
      return filterBasketItemsOnServiceId(basket, purchaseServiceId) || [];
  };

  const getActiveKey = () => {
    if (isAddPledgeItemsFromBasket) {
      if (serviceType === BASKET_SERVICE_TYPE.PLEDGE) {
        setActiveKey('1');
      } else if (serviceType === BASKET_SERVICE_TYPE.PURCHASE) {
        setActiveKey('2');
      }
    } else {
      if (
        pledgeSummaryItems?.length > 0 &&
        serviceType === BASKET_SERVICE_TYPE.PLEDGE
      ) {
        setActiveKey('1');
      } else if (
        purchaseSummaryItems?.length > 0 &&
        serviceType === BASKET_SERVICE_TYPE.PURCHASE
      ) {
        setActiveKey('2');
      }
    }
  };

  const onChangeTab = (key: string) => {
    setActiveKey(key);
    dispatch(pledgeActions.updateServiceType(+key));
  };

  const onPledgeSelectionChange = (payload: any) => {
    setItemsToMove(payload);
  };

  const onPurchaseSelectionChange = (payload: any) => {
    setItemsToMove(payload);
  };

  const getTargetServiceIdToMove = () => {
    switch (itemsToMove?.sourceServiceType) {
      case BASKET_SERVICE_TYPE.PLEDGE:
        if (purchaseSummaryItems && purchaseSummaryItems?.length > 0)
          return purchaseSummaryItems[0].serviceId;
        else return null;
      case BASKET_SERVICE_TYPE.PURCHASE:
        if (pledgeSummaryItems && pledgeSummaryItems?.length > 0)
          return pledgeSummaryItems[0].serviceId;
        else return null;
      default:
        return null;
    }
  };

  const onMoveItemsClick = () => {
    if (!isEmpty(itemsToMove) && itemsToMove?.itemIds?.length > 0) {
      const payload = {
        ...itemsToMove,
        targetServiceId: getTargetServiceIdToMove(),
      };
      dispatch(
        pledgeActions.updateServiceType(
          itemsToMove?.sourceServiceType === BASKET_SERVICE_TYPE.PLEDGE
            ? BASKET_SERVICE_TYPE.PURCHASE
            : BASKET_SERVICE_TYPE.PLEDGE
        )
      );
      dispatch({
        type: MOVE_BASKET_PLEDGE_PURCHASE_ITEMS,
        payload: { params: payload, isActionFromBasket: false },
      });
      setItemsToMove({});
    }
  };

  useEffect(() => {
    const itemsInPledgeSummary = filterItemsToViewInPledge(
      BASKET_SERVICE_TYPE.PLEDGE
    );
    setPledgeSummaryItems(itemsInPledgeSummary);
    const itemsInPurchaseSummary = filterItemsToViewInPledge(
      BASKET_SERVICE_TYPE.PURCHASE
    );

    setPurchaseSummaryItems(itemsInPurchaseSummary);
  }, [basket, pledgeServiceId, purchaseServiceId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getActiveKey();
  }, [pledgeSummaryItems, purchaseSummaryItems]); // eslint-disable-line react-hooks/exhaustive-deps

  const menuLabel = isEmpty(itemsToMove)
    ? PLEDGE_ACTIONS.MOVE_TO_PURCHASE
    : itemsToMove?.sourceServiceType === BASKET_SERVICE_TYPE.PLEDGE
    ? PLEDGE_ACTIONS.MOVE_TO_PURCHASE
    : PLEDGE_ACTIONS.MOVE_TO_PLEDGE;

  const menuItem = [
    {
      label: menuLabel,
      key: '1',
      disabled: isEmpty(itemsToMove) || itemsToMove?.itemIds?.length === 0,
    },
  ];

  const menu = <Menu items={menuItem} onClick={onMoveItemsClick} />;

  const tabActions = (
    <>
      <span>
        <HelpPopoverInfo
          linkedID={`PLEDGEPURCHASE_ACTION`}
          position="leftTop"
        ></HelpPopoverInfo>
      </span>
      <Dropdown overlay={menu}>
        <DropdownButton loading={isMoveToLoading}>
          Actions <DownOutlined />
        </DropdownButton>
      </Dropdown>
    </>
  );

  const tabItems = [
    {
      children: (
        <>
          <StyledTableWrapper className="item-added-table">
            <PledgeSummary
              rows={
                pledgeSummaryItems && pledgeSummaryItems?.length > 0
                  ? pledgeSummaryItems[pledgeSummaryItems?.length - 1]
                  : []
              }
              onPledgeSelectionChange={onPledgeSelectionChange} // selected pledge row for move to purchase
            />
          </StyledTableWrapper>
          <PledgeLoanWrapper>
            {renderPledgeLoanContainer(
              pledgeSummaryItems[pledgeSummaryItems?.length - 1],
              basketId,
              user?.userId
            )}
          </PledgeLoanWrapper>
          <StyledTotalLoanRow>
            <Col span={24}>
              <Row>
                <Col
                  lg={{ offset: 0 }}
                  xl={{ offset: 6 }}
                  xxl={{ offset: 9 }}
                ></Col>
                <StyledTotalLoanCol lg={12} xl={9} xxl={7}>
                  <StyledText>{PURCHASE_ITEM_TABLE.PURCHASE_VALUE}</StyledText>
                </StyledTotalLoanCol>
                <Col lg={12} xl={9} xxl={8}>
                  <Input
                    className="total-value"
                    disabled
                    addonBefore={'£'}
                    placeholder="0.00"
                    value={
                      pledgeSummaryItems && pledgeSummaryItems?.length > 0
                        ? calculateTotalSumByKey(
                            pledgeSummaryItems[pledgeSummaryItems?.length - 1]
                              ?.pledge?.request?.items,
                            'loanAmount'
                          )?.toFixed(2)
                        : '0.00'
                    }
                  />
                </Col>
              </Row>
            </Col>
          </StyledTotalLoanRow>
        </>
      ),
      label: `Pledge Items ${
        pledgeSummaryItems &&
        pledgeSummaryItems?.length > 0 &&
        pledgeSummaryItems[pledgeSummaryItems?.length - 1]?.pledge?.request
          ?.items?.length > 0
          ? `(${
              pledgeSummaryItems[pledgeSummaryItems?.length - 1]?.pledge
                ?.request?.items?.length
            })`
          : ''
      }`,
      key: `1`,
      closable: false,
    },
    {
      children: (
        <>
          <StyledTableWrapper className="item-added-table">
            <PurchaseSummary
              rows={
                purchaseSummaryItems && purchaseSummaryItems?.length > 0
                  ? purchaseSummaryItems[purchaseSummaryItems?.length - 1]
                  : []
              }
              onPurchaseSelectionChange={onPurchaseSelectionChange} // selected purchase row for move to pledge
            />
          </StyledTableWrapper>
          <PledgeLoanWrapper>
            <PurchaseTableContainer
              purchaseItemRows={
                purchaseSummaryItems && purchaseSummaryItems?.length > 0
                  ? purchaseSummaryItems[purchaseSummaryItems?.length - 1]
                  : {}
              }
              basketId={basketId}
              userId={user?.userId}
            />
          </PledgeLoanWrapper>
          <StyledTotalLoanRow>
            <Col span={24}>
              <Row>
                <Col
                  lg={{ offset: 0 }}
                  xl={{ offset: 6 }}
                  xxl={{ offset: 9 }}
                ></Col>
                <StyledTotalLoanCol lg={12} xl={9} xxl={7}>
                  <StyledText>{PURCHASE_ITEM_TABLE.PURCHASE_VALUE}</StyledText>
                </StyledTotalLoanCol>
                <Col lg={12} xl={9} xxl={8}>
                  <Input
                    className="total-value"
                    disabled
                    addonBefore={'£'}
                    placeholder="0.00"
                    value={
                      purchaseSummaryItems && purchaseSummaryItems?.length > 0
                        ? calculateTotalSumByKey(
                            purchaseSummaryItems[
                              purchaseSummaryItems?.length - 1
                            ]?.purchase?.request?.items,
                            'loanAmount'
                          )?.toFixed(2)
                        : '0.00'
                    }
                  />
                </Col>
              </Row>
            </Col>
          </StyledTotalLoanRow>
        </>
      ),
      label: `Purchase Items ${
        purchaseSummaryItems &&
        purchaseSummaryItems?.length > 0 &&
        purchaseSummaryItems[purchaseSummaryItems?.length - 1]?.purchase
          ?.request?.items?.length > 0
          ? `(${
              purchaseSummaryItems[purchaseSummaryItems?.length - 1]?.purchase
                ?.request?.items?.length
            })`
          : ''
      }`,
      key: `2`,
      closable: false,
    },
  ];

  return (
    <StyledRow>
      <Col span={24}>
        <div className="tab-container">
          <Tabs
            tabBarExtraContent={tabActions}
            type="card"
            tabBarGutter={20}
            activeKey={activeKey}
            onChange={onChangeTab}
            defaultActiveKey="1"
            items={tabItems}
          />
        </div>
      </Col>
    </StyledRow>
  );
};

export default ViewPledgePurchase;
