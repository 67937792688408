import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, notification, Space } from 'antd';
import styled from 'styled-components';
import {
  PlusCircleFilled,
  PlusOutlined,
  CloseOutlined,
} from '@ant-design/icons/lib/icons';
import PledgeItemDescription from './pledgeItemDescription/PledgeItemDescription';
import { Button as CustomButton } from 'components/common/Button';
import CategoriesPopup from './categoriesPopup/CategoriesPopup';
import WatchValuationPopup from './watchValuationPopup/WatchValuationPopup';
import BagValuationPopup from './bagValuationPopup/BagValuationPopup';
import ItemAdditionalDetailsPopup from './itemAdditionalDetailsPopup/ItemAdditionalDetailsPopup.tsx';
import '../index.less';
import {
  PLEDGE_ACTIONS,
  ADD_COIN_LABELS,
  ADD_STONE_LABELS,
  TAKE_ITEM_PHOTO,
  TOTAL_ITEM_VALUE,
  BASE_PLEDGE_CATEGORIES,
  ITEM_DESCRIPTION,
  VALUATION,
  CATEGORY_COLORS,
  ADD_VALUATION,
  LOWER_VALUE_WARNING,
  HIGHER_VALUE_WARNING,
  PARENT_CATEGORIES,
  ADDITIONAL_DETAILS_ITEMS_LIST,
  PAWNBROKING_ERRORS,
  HIGHER_VALUATION_WARNING,
  COIN_SUB_ITEM_CATEGORIES,
  BASE_CATEGORY_BULLION_COIN,
} from '../constants';
import {
  getPledgeCategoryFixedAttributes,
  postValuationRequest,
} from 'services/pledge/index';
import AddStonePopup from './addStonePopup';
import AddCoinPopup from './addCoinPopup';
import { useSelector, useDispatch } from 'react-redux';
import { pledgeActions } from 'redux/reducers/pawnbroking/index';
import { stoneActions } from 'redux/reducers/pawnbroking/stone';
import { coinActions } from 'redux/reducers/pawnbroking/coin';
import { actions as basketActions } from 'redux/reducers/basket';
import { actions as valuationActions } from 'redux/reducers/valuationCenter';
import isEmpty from 'lodash/isEmpty';
import {
  PLEDGE_ITEM_PRICE_REQUEST,
  UPLOAD_MULTIPLE_IMAGE_REQUEST,
} from 'action_creators/pledge';
import {
  CREATE_BASKET_PLEDGE_ITEMS,
  EDIT_BASKET_PLEDGE_ITEMS,
} from 'action_creators/basket';
import {
  reducePledgeItems,
  errorHandling,
  joinValues,
  filterBasketItemsOnServiceId,
  currencyFormat,
} from 'utils/util';
import { BASKET_SERVICE_TYPE } from 'components/basket/constants';
import ImageLoader from 'components/common/ImageLoader';
import HelpPopoverInfo, {
  StyledHelpPopoverInfoForButtons,
} from 'components/common/HelpPopoverInfo';
import InputDecimal from 'components/common/InputDecimal';
import ControlledAlert from 'components/common/ControlledAlert';
import ValuationPopover from 'components/common/ValuationPopover';
import CustomerSearchPopup from 'components/common/CustomerSearchPopup';
import { GENERIC_ERROR, GUEST_TYPES } from 'globalConstants';
import WebcamItemPhoto from 'components/common/Camcorder/WebcamItemPhoto';
import AlertsPopup from './alertsPopup';
import { cacheStaticData } from 'utils/cacheServices';

const StyledRow = styled(Row)`
  margin: 0px 30px 10px 20px;
  &:first-of-type {
    margin: 8px 30px 10px 20px;
  }
`;

const StyledText = styled.div`
  font-weight: 500;
  padding: 8px 0px;
`;

const StyledButton = styled(Button)<{ background: string }>`
  background: var(--${(props) => props.background});
  &:focus,
  &:hover,
  &:active {
    background: var(--${(props) => props.background});
    color: var(--black);
  }
  border-radius: 4px;
  height: 44px;
  border: none;
  font-weight: 500;
  margin-right: 15px;
  color: var(--black);
`;

const StyledQuickCatDescription = styled(Col)`
  width: 100%;
  height: 4.2rem;
  background: var(--catskill-white);
  padding: 10px 12px;
`;

const StyledItemValueRow = styled(Row)`
  background: var(--catskill-white);
  & .ant-row {
    flex-flow: nowrap;
  }
`;

const StyledItemValueWrapper = styled(Row)`
  margin: 10px 30px 10px 20px;
`;

const StyledTableCol = styled(Col)`
  margin: 0px 10px;
`;

const StyledPhotoContainer = styled(Col)`
  background: #fafafa;
  border: 1px dashed #bfc5cf;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  max-height: 144px;

  & .ant-image {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    & img {
      position: absolute;
    }
  }
`;

const StyledPhotoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 10px;
  &:first-of-type {
    padding: 25px 0 0;
  }
`;

const StyledAddButton = styled(Button)`
  width: 100%;
  margin: 0 15px 15px 0;
  background: var(--white);
  &:focus,
  &:hover,
  &:active {
    background: var(--white);
    color: var(--blue);
  }
  height: 44px;
  font-weight: 500;
  margin-right: 15px;
  color: var(--blue);
  border: 1px solid var(--light-gray);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: left;
`;

const StyledTotalLentRow = styled(Row)`
  background: var(--cyan);
  padding: 5px 10px 5px 20px;
`;

const StyledAmountInput = styled(Input)`
  border: none;
  text-align: right;
  padding: 0;
  outline: 0;
  font-weight: 500;
`;

const StyledAmountDecimalInput = styled(InputDecimal)`
  border: none;
  text-align: right;
  padding: 0;
  outline: 0;
  font-weight: 500;

  & input {
    text-align: right;
  }
`;

const StyledItemBox = styled.div<{ rowItems: boolean }>`
  max-height: ${(props: any) => (props.rowItems ? '116px' : '205px')};
`;

const StyledRowHelp = styled.span`
  position: relative;
  bottom: 60%;
  left: 100%;
`;

interface ItemCategoryProps {
  name: string;
  id: string;
}

interface StoneRowProps {
  id?: any;
  stoneType?: any;
  stoneClarity?: any;
  stoneClass?: any;
  stoneShape?: any;
  stonePoint?: number | string;
  stoneQuantity?: number | string;
  stoneNotes?: string;
}

interface CoinRowProps {
  id?: any;
  coinType?: any;
  coinYear?: number | string;
  quantity?: number | string;
}

const AddPledge = () => {
  const [category, setCategory] = useState<ItemCategoryProps>();
  const [isCategoriesPopupVisible, setCategoriesPopupVisible] =
    useState<boolean>(false);
  const [isWatchValuePopupVisible, setWatchValuePopupVisible] =
    useState<boolean>(false);
  const [isBagValuePopupVisible, setBagValuePopupVisible] =
    useState<boolean>(false);
  const [
    isItemAdditinalDetailsPopupVisible,
    setItemAddtionalDetailsPopupVisible,
  ] = useState<boolean>(false);
  const [isStonePopupVisible, setStonePopupVisible] = useState<boolean>(false);
  const [isCoinPopupVisible, setCoinPopupVisible] = useState<boolean>(false);
  const [isPledgeItemFormValidate, setPledgeItemFormValidate] =
    useState<boolean>(false);
  const [itemFixedAttributes, setItemFixedAttributes] = useState<any[]>([]);
  const [resetItemDescription, setResetItemDescription] =
    useState<boolean>(false);
  const [stoneEditRow, setStoneEditRow] = useState<StoneRowProps>();
  const [coinEditRow, setCoinEditRow] = useState<CoinRowProps>();
  const [totalItemValue, setTotalItemValue] = useState<string | number>();
  const [itemWeight, setItemWeight] = useState<string | number>();
  const [basketPledgeItemId, setBasketPledgeItemId] = useState<string | null>(
    null
  );
  const [serviceType, setServiceType] = useState<number>(1);
  const [isTakeItemPhotoPopupVisible, setTakeItemPhotoPopupVisible] =
    useState<boolean>(false);
  const [isGuidanceWarningPopupVisible, setIsGuidanceWarningPopupVisible] =
    useState<boolean>(false);
  const [isProceedAfterWarning, setIsProceedAfterWarning] =
    useState<boolean>(false);
  const [guidanceWarningMessage, setGuidanceWarningMessage] =
    useState<string>('');

  const [desiredValuationAmount, setDesiredValuationAmount] =
    useState<string>('');
  const [isValuationPopupVisible, setIsValuationPopupVisible] =
    useState<boolean>(false);
  const [isValuationRequested, setIsValuationRequested] =
    useState<boolean>(false);
  const [valuationRequestResponse, setValuationRequestResponse] =
    useState<any>('');
  const [isCustomerSearchVisible, setIsCustomerSearchVisible] =
    useState<boolean>(false);
  const [isDesireAmountEnable, setIsDesireAmountEnable] =
    useState<boolean>(false);
  const [isValueError, setIsValueError] = useState<boolean>(false);
  const [isAlertsVisible, setAlertsVisible] = useState<boolean>(false);
  const [errorList, setErrorList] = useState<Array<any>>([]);
  const [isAddCoinDisabled, setAddCoinDisabled] = useState<boolean>(false);

  const dispatch = useDispatch();

  const {
    pawnbroking: {
      pledgeItemsValue,
      itemCategory,
      subItemCategory,
      itemDescription,
      metalPrice,
      error,
      interestRate,
      addToPledgePurchase,
      watches,
      handbags,
      itemAdditionalProperties,
      itemAdditionalPropertiesJewelleryCoins,
      itemImageCollectionResponse,
      serviceType: refServiceType,
      pledgeServiceId,
      purchaseServiceId,
      selectedHistoricalItem,
      parentImageUrl,
      isImageUploading,
      checklists,
      isItemFromHistory,
    },
    stone: { stoneAllItemsData, stonePrices },
    coin: { coinAllItemsData, coinPrices },
    customer: { customer, guestType },
    basket: { basket, editModeBasketPledgeItems, isLoading },
    user: { user, selectedStore },
    valuationCenter: {
      isLoading: isValuationLoading,
      isValuationItemToPledge,
      offeredAmount,
      valuationItemDesiredAmount,
      opportunityId,
    },
  } = useSelector((state: any) => {
    return {
      pawnbroking: state.pawnbroking,
      stone: state.stone,
      coin: state.coin,
      customer: state.customer,
      basket: state.basket,
      user: state.user,
      valuationCenter: state.valuationCenter,
    };
  });

  const {
    street,
    town,
    property,
    county,
    postcode,
    houseName,
    area,
    firstNames,
    middleName,
    surname,
    title,
    mobile,
    emailAddress,
  } = customer;

  const pledgeItemRows = filterBasketItemsOnServiceId(basket, pledgeServiceId);
  const purchaseItemRows = filterBasketItemsOnServiceId(
    basket,
    purchaseServiceId
  );

  useEffect(() => {
    if (isValuationItemToPledge) setResetItemDescription(false);
  }, [isValuationItemToPledge]); // eslint-disable-line react-hooks/exhaustive-deps

  const getServiceId = () => {
    if (serviceType === BASKET_SERVICE_TYPE.PLEDGE) {
      if (pledgeItemRows) {
        const length = pledgeItemRows?.length;
        return pledgeItemRows[length - 1]?.serviceId;
      } else return null;
    } else if (serviceType === BASKET_SERVICE_TYPE.PURCHASE) {
      if (purchaseItemRows) {
        const length = purchaseItemRows?.length;
        return purchaseItemRows[length - 1]?.serviceId;
      } else return null;
    }
  };

  const getFinenessText = () => {
    const filterFineness: any = itemFixedAttributes.filter((x: any) => {
      if (x?.description === 'FINENESS') {
        return x?.itemFixedAttributes;
      }
      return null;
    });

    return (
      filterFineness[0]?.itemFixedAttributes?.find(
        (y: any) => metalPrice?.fineness === y?.id
      )?.description || undefined
    );
  };

  const getTotalWeight = () => {
    if (+itemCategory?.id === +BASE_PLEDGE_CATEGORIES[2].id) {
      //As per MP-6289, this is to add coin weight and total weight from user input if category is Coins/Bullion only.
      //for other categories (jewellery/watches/handbags) without adding coin weight simply returning total weight from input.
      if (subItemCategory?.id === COIN_SUB_ITEM_CATEGORIES[0].id) {
        // Add this if condition to fix EVB-1112
        return +pledgeItemsValue?.coinPrice?.weight;
      }
      return (
        +pledgeItemsValue?.coinPrice?.weight + (itemWeight ? +itemWeight : 0)
      );
    } else return itemWeight;
  };

  const pledgeItemPayload: any = {
    basketId: basket?.basketId || null, //null for creating a basket
    customerId: customer.customerId || undefined,
    customerName:
      joinValues([customer.firstNames, customer.surname], true) || undefined,
    customer: {
      street,
      town,
      property,
      county,
      postcode,
      houseName,
      area,
      firstNames,
      middleName,
      surname,
    },
    storeId: selectedStore?.storeId,
    storeAddress: selectedStore?.storeAddress,
    storePostalCode: selectedStore?.postalCode,
    storeTelephone: selectedStore?.telephone,
    userId: user?.userId,
    userName: user?.displayName,
    employeeId: user?.employeeId,
    serviceId: getServiceId(),
    serviceType: serviceType,
    entity: {
      interestRate: interestRate,
      items: [
        {
          loanItemType: itemCategory,
          subItemType: subItemCategory,
          opportunityId: opportunityId,
          description: itemDescription,
          metal: {
            ...metalPrice,
            ...pledgeItemsValue?.metalPrice?.averageWholesaleValue,
            finenessText: getFinenessText(), // fineness text needed in create-basket for Receipt
          },
          stones:
            stoneAllItemsData && stoneAllItemsData?.length > 0
              ? [...stoneAllItemsData]
              : [],
          stoneAverageWholeSaleValue:
            pledgeItemsValue?.stonePrice?.averageWholesaleValue,
          coins: [...coinAllItemsData],
          loanAmount: totalItemValue || '0.00',
          totalWeight: getTotalWeight(),
          itemValuation: {
            ...pledgeItemsValue,
            ...itemAdditionalPropertiesJewelleryCoins,
          },
          watches: watches,
          handbags: handbags,
          itemAdditionalProperties: itemAdditionalProperties,
          containerId: null,
          blobId: null,
          thumbnailImageId: null,
          itemImageCollections: itemImageCollectionResponse,
          checklists: checklists,
        },
      ],
    },
  };

  const tableRow = (
    label: string,
    valueOne: any,
    valueTwo: any = null,
    input = false
  ) => {
    return (
      <tr>
        <td>{label}</td>
        <td>
          {input ? (
            <StyledAmountInput value={`£${valueOne}`} />
          ) : valueTwo ? (
            `£${valueOne} - £${valueTwo}`
          ) : (
            `£${valueOne}`
          )}
        </td>
      </tr>
    );
  };
  const tableRowWithHelp = (
    label: string,
    valueOne: any,
    valueTwo: any = null,
    input = false,
    errorClass = '',
    background = 'white'
  ) => {
    return (
      <tr style={{ background: `var(--${background})` }} className={errorClass}>
        <td
          width={130}
          style={{ borderRight: errorClass ? '2px solid var(--red)' : '' }}
        >
          {label}
        </td>
        <td>
          {input ? (
            <StyledAmountDecimalInput
              addonBefore={false}
              onBlur={() => setIsDesireAmountEnable(false)}
              onFocus={() => setIsDesireAmountEnable(true)}
              placeholder={currencyFormat(valueOne, true)}
              value={
                isDesireAmountEnable ? valueOne : currencyFormat(valueOne, true)
              }
              onChange={onDesiredValuationAmountChange}
            />
          ) : valueTwo ? (
            `£${valueOne} - £${valueTwo}`
          ) : (
            `£${valueOne}`
          )}
        </td>
        <td>
          <span style={{ float: 'right' }}>
            <HelpPopoverInfo
              linkedID={`PLEDGEACTION_${label}`}
              position="rightTop"
            ></HelpPopoverInfo>
          </span>
        </td>
      </tr>
    );
  };

  const onCategoryClick = async (item: ItemCategoryProps) => {
    setCategory(item);
    if (!isValuationItemToPledge) {
      setCategoriesPopupVisible(true);
    }
    setResetItemDescription(false);
  };

  const onWatchValuationClick = () => {
    setWatchValuePopupVisible(true);
  };

  const onHandbagValuationClick = () => {
    setBagValuePopupVisible(true);
  };

  const onItemAdditionalDetailsClick = () => {
    setItemAddtionalDetailsPopupVisible(true);
  };

  const showValuationPopup = () => {
    const selectedItemDescription = itemDescription?.split(':')[0];

    if (selectedItemDescription?.includes(BASE_PLEDGE_CATEGORIES[1].name))
      return PARENT_CATEGORIES.WATCHES;
    if (selectedItemDescription?.includes(BASE_PLEDGE_CATEGORIES[3].name))
      return PARENT_CATEGORIES.HANDBAGS;
    if (selectedItemDescription?.includes(BASE_PLEDGE_CATEGORIES[0].name))
      return PARENT_CATEGORIES.JEWELLERY;
    if (
      selectedItemDescription?.includes(BASE_PLEDGE_CATEGORIES[2].name) ||
      selectedItemDescription?.includes(BASE_CATEGORY_BULLION_COIN)
    )
      return PARENT_CATEGORIES.COINS;
    return false;
  };
  const getItemFixedAttributes = async () => {
    try {
      const response = await cacheStaticData(getPledgeCategoryFixedAttributes);
      if (response?.data)
        setItemFixedAttributes(response?.data?.itemFixedAttributeTypes);
    } catch (e: any) {
      console.log(e);
    }
  };

  const getItemPriceValuation = async (params: any) => {
    dispatch({
      type: PLEDGE_ITEM_PRICE_REQUEST,
      payload: { params: params },
    });
  };

  const onAddStoneClick = () => {
    setStoneEditRow({});
    if (itemDescription) setStonePopupVisible(true);
  };

  const onStoneDescriptionClick = (row: StoneRowProps) => {
    setStoneEditRow(row);
    setStonePopupVisible(true);
  };

  const onStoneCloseIconClick = (item: any) => {
    const stoneData = stoneAllItemsData.filter(
      (stoneItem: any) => stoneItem.id !== item.id
    );

    const stoneItems = reducePledgeItems(stoneData);
    const params = {
      categoryId: itemCategory?.id,
      metalPrice,
      coinPrices,
      stonePrices: stoneItems,
    };

    getItemPriceValuation(params);
    dispatch(stoneActions.stoneAllItems([...stoneData]));
    dispatch(stoneActions.stoneItemsPrice(params.stonePrices));
  };

  const onAddCoinClick = () => {
    setCoinEditRow({});
    if (itemDescription) setCoinPopupVisible(true);
  };

  const onCoinDescriptionClick = (row: any) => {
    setCoinEditRow(row);
    setCoinPopupVisible(true);
  };

  const onCoinCloseIconClick = (item: any) => {
    const coinData = coinAllItemsData.filter(
      (coinItem: any) => coinItem.id !== item.id
    );

    const coinItems = reducePledgeItems(coinData);
    const params = {
      categoryId: itemCategory?.id,
      metalPrice,
      coinPrices: [...coinItems],
      stonePrices,
    };

    getItemPriceValuation(params);
    dispatch(coinActions.coinAllItems([...coinData]));
    dispatch(coinActions.coinItemsPrice(params.coinPrices));
  };

  const clearItem = () => {
    dispatch(pledgeActions.clearItems());
    dispatch(pledgeActions.setImageCaptured(false));
    dispatch(coinActions.clearCoinItems());
    dispatch(stoneActions.clearStoneItems());
    dispatch(basketActions.setBasketPledgeItemsEditMode({}));
    dispatch(valuationActions.clearValuation());
    setResetItemDescription(true);
    setDesiredValuationAmount('');
    setIsValuationRequested(false);
  };

  const resetItems = () => {
    if (
      !isEmpty(itemCategory) &&
      Number(category?.id) !== Number(itemCategory.id)
    ) {
      clearItem();
    }
  };

  const onTotalItemValueChange = (e: any) => {
    const value = e.target.value;
    setTotalItemValue(value);
  };

  const onAddToPledgePurchaseClick = (serviceType: number) => {
    if (!isEmpty(itemCategory)) {
      setIsValuationRequested(false);
      setPledgeItemFormValidate(true);
      setServiceType(serviceType);
    }
  };

  const getPrice = (itemWeight?: string | number) => {
    setItemWeight(itemWeight);
  };

  const onTakeItemPhotoClick = () => {
    if (!isValuationItemToPledge) {
      setTakeItemPhotoPopupVisible(true);
    }
  };

  const validateTotalValue = () => {
    if (isValuationItemToPledge) {
      if (totalItemValue && totalItemValue > offeredAmount) {
        setGuidanceWarningMessage(HIGHER_VALUATION_WARNING);
        setIsGuidanceWarningPopupVisible(true);
      } else setIsProceedAfterWarning(true);
    } else {
      if (
        totalItemValue &&
        totalItemValue < pledgeItemsValue?.guidance?.minimumLoanValue
      ) {
        setGuidanceWarningMessage(LOWER_VALUE_WARNING);
        setIsGuidanceWarningPopupVisible(true);
      } else if (
        totalItemValue &&
        totalItemValue > pledgeItemsValue?.guidance?.maximumLoanValue
      ) {
        setGuidanceWarningMessage(HIGHER_VALUE_WARNING);
        setIsGuidanceWarningPopupVisible(true);
      } else setIsProceedAfterWarning(true);
    }
  };

  const onProceedAfterWarningClick = () => {
    setIsProceedAfterWarning(true);
    setIsGuidanceWarningPopupVisible(false);
  };

  useEffect(() => {
    if (addToPledgePurchase && !isPledgeItemFormValidate) {
      setIsValueError(false);

      if (isValuationRequested) validateValuation();
      else if (totalItemValue && +totalItemValue > 0) {
        validateTotalValue();
      } else if (!isValuationRequested && !totalItemValue) {
        setIsValueError(true);
        setAlertsVisible(true);
        setErrorList((prevState: any) => [
          ...prevState,
          PAWNBROKING_ERRORS.TOTAL_ITEM_VALUE,
        ]);
      }
    }
  }, [addToPledgePurchase, isPledgeItemFormValidate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isProceedAfterWarning) {
      if (
        !isEmpty(editModeBasketPledgeItems) &&
        isEmpty(selectedHistoricalItem) &&
        !isValuationItemToPledge &&
        !isItemFromHistory
      ) {
        const pledgeItemId = { itemId: basketPledgeItemId };
        pledgeItemPayload.entity.items[0] = {
          ...pledgeItemPayload.entity.items[0],
          ...pledgeItemId,
        };
        dispatch({
          type: EDIT_BASKET_PLEDGE_ITEMS,
          payload: { params: pledgeItemPayload },
        });
        dispatch(basketActions.clearEditMode());
        setIsProceedAfterWarning(false);
      } else {
        dispatch(pledgeActions.updateServiceType(serviceType));
        dispatch({
          type: CREATE_BASKET_PLEDGE_ITEMS,
          payload: { params: pledgeItemPayload },
        });

        setDesiredValuationAmount('');
        setIsProceedAfterWarning(false);
        dispatch(basketActions.clearEditMode());
        dispatch(pledgeActions.setSelectedHistoricalItem({}));
        dispatch(pledgeActions.setInterestRateCounter(0)); // interest rate counter to make API call when interest-rate is 0 | EVB-1595
      }
    }
  }, [isProceedAfterWarning]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getItemFixedAttributes();

    return () => {
      dispatch(pledgeActions.updateAddToPledgePurchase(false));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    errorHandling(error);
  }, [error]);

  useEffect(() => {
    if (isEmpty(itemCategory)) {
      setTotalItemValue('');
      setItemWeight(0);
    }
  }, [itemCategory]);

  useEffect(() => {
    if (!isEmpty(editModeBasketPledgeItems)) {
      if (!isValuationItemToPledge) {
        setTotalItemValue(editModeBasketPledgeItems.loanAmount.toFixed(2));
      }
      setBasketPledgeItemId(editModeBasketPledgeItems.itemId);
      setItemWeight(editModeBasketPledgeItems.totalWeight);

      setDesiredValuationAmount(valuationItemDesiredAmount);
      const params = {
        categoryId: editModeBasketPledgeItems?.loanItemType?.id,
        metalPrice: editModeBasketPledgeItems?.metal,
        coinPrices: reducePledgeItems(editModeBasketPledgeItems?.coins),
        stonePrices: reducePledgeItems(editModeBasketPledgeItems?.stones),
      };
      getItemPriceValuation(params);
    }
  }, [editModeBasketPledgeItems]); // eslint-disable-line react-hooks/exhaustive-deps

  const uploadImageHandler = (image: any) => {
    dispatch({
      type: UPLOAD_MULTIPLE_IMAGE_REQUEST,
      payload: { image, isDefault: true },
    });
  };

  const onContinueAsValuationGuest = () => {
    //'Valuation Guest' - call createValuationRequest for valuation call on popup close....IF NEEDED.....
    setIsCustomerSearchVisible(false);
  };

  const onPushToValuationClick = () => {
    setIsValuationRequested(true);
    setPledgeItemFormValidate(true);
  };

  const validateValuation = () => {
    const errors: any[] = [];

    if (!desiredValuationAmount && isEmpty(itemImageCollectionResponse)) {
      setIsValueError(true);
      errors.push(
        PAWNBROKING_ERRORS.ITEM_IMAGE,
        PAWNBROKING_ERRORS.DESIRED_AMOUNT
      );
    } else if (isEmpty(itemImageCollectionResponse))
      errors.push(PAWNBROKING_ERRORS.ITEM_IMAGE);
    else if (!desiredValuationAmount) {
      setIsValueError(true);
      errors.push(PAWNBROKING_ERRORS.DESIRED_AMOUNT);
    }

    if (errors.length) {
      setAlertsVisible(true);
      setErrorList((prevState: any) => [...prevState, ...errors]);
      return;
    }

    if (!customer?.customerId && guestType !== GUEST_TYPES.VALUATION)
      setIsCustomerSearchVisible(true);
    else createValuationRequest();
  };

  const createValuationRequest = async () => {
    const createPayload = { ...pledgeItemPayload };

    const valuationPayload = {
      desiredAmount: desiredValuationAmount,
      workqueueService: {
        workqueueServiceId: 1,
        serviceName: 'Valuation',
      },
      valuationItem: createPayload?.entity?.items[0],
      customer: {
        ...createPayload?.customer,
        customerId: customer?.customerId || '',
        title,
        mobile,
        email: emailAddress,
      },
      store: {
        postalCode: selectedStore?.postalCode,
        storeAddress: selectedStore?.storeAddress,
        storeId: selectedStore?.storeId,
        storeName: selectedStore?.storeName,
        telephone: selectedStore?.telephone,
      },
      userId: createPayload?.userId,
      storeId: createPayload?.storeId,
      subject: subItemCategory?.name,
    };

    try {
      dispatch(valuationActions.setIsLoading(true));

      const response = await postValuationRequest(valuationPayload);
      if (response?.data) {
        if (response?.data) {
          setValuationRequestResponse(response?.data);
          setIsValuationPopupVisible(true);
          dispatch(valuationActions.setIsLoading(false));
        }
      }
    } catch (e: any) {
      notification.error({ message: GENERIC_ERROR, duration: 5 });
      dispatch(valuationActions.setIsLoading(false));
    }
  };

  const onValuationPopoverClose = () => {
    clearItem();
    setIsValuationPopupVisible(false);
  };

  const onDesiredValuationAmountChange = (event: any) => {
    const { value } = event.target;
    setDesiredValuationAmount(value);
  };

  const onAlertPopupClose = () => {
    setErrorList([]);
    setAlertsVisible(false);
  };

  const getOfferedAmountAdvisoryColor = () => {
    if (isValuationItemToPledge && desiredValuationAmount) {
      if (offeredAmount >= +desiredValuationAmount) return 'light-green';
      else return 'mustard';
    }

    return 'white';
  };

  const addCoinDisableHandler = () => {
    if (
      (itemCategory?.id === BASE_PLEDGE_CATEGORIES[2].id &&
        subItemCategory?.id !== COIN_SUB_ITEM_CATEGORIES[0].id) ||
      isAddCoinDisabled
    )
      return true;
    return false;
  };

  const onAddMoreDetailsForValuationClick = () => {
    if (itemDescription) {
      if (showValuationPopup() === PARENT_CATEGORIES.WATCHES)
        return onWatchValuationClick();
      else if (showValuationPopup() === PARENT_CATEGORIES.HANDBAGS)
        return onHandbagValuationClick();
      else onItemAdditionalDetailsClick();
    }
  };

  return (
    <>
      <StyledRow>
        <Col span={24}>
          <StyledHelpPopoverInfoForButtons
            linkedID={'Pledge_CATEGORIES'}
            position="rightTop"
            helpPosition="AFTER"
          >
            {BASE_PLEDGE_CATEGORIES.map((item, index) => (
              <StyledButton
                key={item.id}
                background={CATEGORY_COLORS[index]}
                onClick={() => onCategoryClick(item)}
              >
                {item.name}
              </StyledButton>
            ))}
          </StyledHelpPopoverInfoForButtons>
        </Col>
      </StyledRow>
      <StyledRow>
        <StyledQuickCatDescription span={24}>
          <div>{itemDescription ? itemDescription : ITEM_DESCRIPTION}</div>
        </StyledQuickCatDescription>
      </StyledRow>
      <StyledRow style={{ minHeight: 'calc(100vh - 534px)', flexGrow: 1 }}>
        <Col span={24}>
          <Row>
            <Col lg={15} xl={12} xxl={11}>
              <PledgeItemDescription
                getPrice={getPrice}
                itemFixedAttributes={itemFixedAttributes}
                resetItemDescription={resetItemDescription}
                isPledgeItemFormValidate={isPledgeItemFormValidate}
                setPledgeItemFormValidate={() =>
                  setPledgeItemFormValidate(false)
                }
              />
            </Col>
            <Col lg={9} xl={12} xxl={13} style={{ paddingLeft: '15px' }}>
              <Row>
                <Col span={24}>
                  {stoneAllItemsData && stoneAllItemsData?.length > 0 ? (
                    <StyledItemBox
                      className="stone-details-wrapper"
                      rowItems={
                        !isEmpty(stoneAllItemsData) &&
                        !isEmpty(coinAllItemsData)
                      }
                    >
                      {stoneAllItemsData?.map((item: any) => {
                        return (
                          <Row className="stone-details-row" key={item.id}>
                            <Col
                              style={{ cursor: 'pointer' }}
                              span={22}
                              className="stone-description"
                              onClick={() => onStoneDescriptionClick(item)}
                            >{`${item.stoneType.description} - ${item.stoneShape.description} - ${item.stonePoint}pts x${item.stoneQuantity} - ${item.stoneClass.description} - ${item.stoneClarity.description}`}</Col>
                            <Col span={2} className="stone-closed-icon">
                              <CloseOutlined
                                onClick={() => onStoneCloseIconClick(item)}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                    </StyledItemBox>
                  ) : null}
                  {stoneAllItemsData && stoneAllItemsData?.length > 0 ? (
                    <StyledAddButton onClick={onAddStoneClick}>
                      <PlusOutlined />
                      {ADD_STONE_LABELS.ANOTHER_STONE}
                    </StyledAddButton>
                  ) : (
                    <Row>
                      <Col span={24}>
                        <StyledAddButton onClick={onAddStoneClick}>
                          <PlusOutlined />
                          {ADD_STONE_LABELS.LABEL}
                        </StyledAddButton>
                        <StyledRowHelp>
                          <HelpPopoverInfo
                            linkedID="PLEDGEACTION_ADDSTONE"
                            position="rightTop"
                          ></HelpPopoverInfo>
                        </StyledRowHelp>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {coinAllItemsData && coinAllItemsData?.length > 0 ? (
                    <StyledItemBox
                      className="stone-details-wrapper"
                      rowItems={
                        !isEmpty(stoneAllItemsData) &&
                        !isEmpty(coinAllItemsData)
                      }
                    >
                      {coinAllItemsData?.map((item: any) => {
                        return (
                          <Row className="stone-details-row" key={item.id}>
                            <Col
                              style={{ cursor: 'pointer' }}
                              span={22}
                              className="stone-description"
                              onClick={() => onCoinDescriptionClick(item)}
                            >{`${item?.coinType?.description} ${
                              item?.coinYear ? ` - ${item.coinYear}` : ''
                            } x${item?.quantity}`}</Col>
                            <Col span={2} className="stone-closed-icon">
                              <CloseOutlined
                                onClick={() => onCoinCloseIconClick(item)}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                    </StyledItemBox>
                  ) : null}
                  {coinAllItemsData && coinAllItemsData?.length > 0 ? (
                    <>
                      {/* <StyledAddButton onClick={onAddCoinClick}>
                        <PlusOutlined />
                        {ADD_COIN_LABELS.ANOTHER_COIN}
                      </StyledAddButton> */}
                    </>
                  ) : (
                    <StyledAddButton
                      disabled={addCoinDisableHandler()}
                      onClick={onAddCoinClick}
                    >
                      <PlusOutlined />
                      {ADD_COIN_LABELS.LABEL}
                    </StyledAddButton>
                  )}

                  <StyledAddButton onClick={onAddMoreDetailsForValuationClick}>
                    <PlusOutlined />
                    {isValuationItemToPledge
                      ? ADD_VALUATION.VIEW
                      : watches?.length > 0 ||
                        handbags?.length > 0 ||
                        itemAdditionalProperties.length > 0 ||
                        !isEmpty(itemAdditionalPropertiesJewelleryCoins)
                      ? ADD_VALUATION.UPDATE_MORE
                      : ADD_VALUATION.ADD_MORE}
                  </StyledAddButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </StyledRow>

      <StyledItemValueRow>
        <Col span={24}>
          <StyledItemValueWrapper justify="space-between">
            <Col lg={11} xl={11} xxl={9}>
              <table className="pledge-valuation-table">
                <tbody>
                  {tableRow(
                    VALUATION.METAL_VALUE,
                    pledgeItemsValue?.metalPrice?.minimumLoanValue.toFixed(2) ||
                      '0.00',
                    pledgeItemsValue?.metalPrice?.maximumLoanValue.toFixed(2) ||
                      '0.00'
                  )}
                  {tableRow(
                    VALUATION.COIN_VALUE,
                    pledgeItemsValue?.coinPrice?.amount.toFixed(2) || '0.00'
                  )}
                  {tableRow(
                    VALUATION.STONES_TOTAL_VALUE,
                    pledgeItemsValue?.stonePrice?.maximumLoanValue.toFixed(2) ||
                      '0.00'
                  )}
                  {tableRow(
                    VALUATION.OVERALL_GUIDANCE,
                    pledgeItemsValue?.guidance?.minimumLoanValue.toFixed(2) ||
                      '0.00',
                    pledgeItemsValue?.guidance?.maximumLoanValue.toFixed(2) ||
                      '0.00'
                  )}
                </tbody>
              </table>
            </Col>

            <StyledTableCol lg={8} xl={8} xxl={9}>
              <table className="pledge-valuation-table">
                <tbody>
                  {tableRowWithHelp(
                    VALUATION.DESIRED_AMOUNT,
                    desiredValuationAmount,
                    '0.00',
                    true,
                    isValuationRequested && isValueError ? 'error' : ''
                  )}
                  {tableRowWithHelp(
                    VALUATION.VALUATION,
                    offeredAmount.toFixed(2),
                    null,
                    false,
                    '',
                    getOfferedAmountAdvisoryColor()
                  )}
                </tbody>
              </table>

              <Row>
                <Col
                  span={24}
                  style={{ margin: '10px 0 0px 0', textAlign: 'center' }}
                >
                  <StyledHelpPopoverInfoForButtons
                    linkedID="PLEDGEACTION_PUSHTOVALUATION"
                    position="top"
                    helpPosition="AFTER"
                  >
                    <CustomButton
                      loading={isValuationLoading}
                      disabled={
                        isEmpty(itemCategory) || isValuationItemToPledge
                      }
                      itemProp="secondary"
                      onClick={onPushToValuationClick}
                    >
                      {PLEDGE_ACTIONS.PUSH_TO_VALUATION}
                    </CustomButton>
                  </StyledHelpPopoverInfoForButtons>
                </Col>
              </Row>
            </StyledTableCol>

            <StyledPhotoContainer
              lg={5}
              xl={5}
              xxl={6}
              onClick={onTakeItemPhotoClick}
            >
              {parentImageUrl === '' ? (
                <>
                  <StyledPhotoDiv>
                    <PlusCircleFilled
                      style={{ fontSize: '34px', color: '#11a1fd' }}
                    />
                  </StyledPhotoDiv>
                  <StyledPhotoDiv>
                    <span style={{ fontWeight: 500, color: '#11a1fd' }}>
                      {TAKE_ITEM_PHOTO}
                    </span>
                  </StyledPhotoDiv>
                </>
              ) : (
                <ImageLoader
                  imageUrl={parentImageUrl}
                  classes={'ant-image'}
                  fallbackImg={'no_image_thumbnail.svg'}
                />
              )}
            </StyledPhotoContainer>
          </StyledItemValueWrapper>
        </Col>
      </StyledItemValueRow>
      <StyledTotalLentRow>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={12}>
                  <Space align="center">
                    <StyledText>{TOTAL_ITEM_VALUE}</StyledText>
                    <InputDecimal
                      className={`total-item-value ${
                        !isValuationRequested && isValueError ? 'error' : ''
                      }`}
                      onChange={onTotalItemValueChange}
                      value={totalItemValue}
                    />
                  </Space>
                </Col>
                <Col span={12}>
                  <Row justify="end">
                    <CustomButton
                      itemProp="secondary"
                      onClick={() => onAddToPledgePurchaseClick(1)}
                      loading={
                        isLoading && serviceType === BASKET_SERVICE_TYPE.PLEDGE
                      }
                      style={{ padding: '9px 18px' }}
                      disabled={
                        !isEmpty(editModeBasketPledgeItems) &&
                        isEmpty(selectedHistoricalItem) &&
                        refServiceType === BASKET_SERVICE_TYPE.PURCHASE
                      }
                    >
                      {!isEmpty(editModeBasketPledgeItems) &&
                      refServiceType === BASKET_SERVICE_TYPE.PLEDGE &&
                      isEmpty(selectedHistoricalItem) &&
                      !isValuationItemToPledge &&
                      !isItemFromHistory
                        ? PLEDGE_ACTIONS.UPDATE_PLEDGE
                        : PLEDGE_ACTIONS.ADD_TO_PLEDGE}
                    </CustomButton>
                    <StyledHelpPopoverInfoForButtons
                      linkedID="PLEDGEACTION_ADDPLEGETOBASKET"
                      position="top"
                      attachedComp="BUTTON"
                    />

                    <CustomButton
                      itemProp="secondary"
                      onClick={() => onAddToPledgePurchaseClick(2)}
                      loading={
                        isLoading &&
                        serviceType === BASKET_SERVICE_TYPE.PURCHASE
                      }
                      disabled={
                        !isEmpty(editModeBasketPledgeItems) &&
                        isEmpty(selectedHistoricalItem) &&
                        refServiceType === BASKET_SERVICE_TYPE.PLEDGE &&
                        !isValuationItemToPledge
                      }
                    >
                      {!isEmpty(editModeBasketPledgeItems) &&
                      refServiceType === BASKET_SERVICE_TYPE.PURCHASE &&
                      isEmpty(selectedHistoricalItem) &&
                      !isValuationItemToPledge
                        ? PLEDGE_ACTIONS.UPDATE_PURCHASE
                        : PLEDGE_ACTIONS.ADD_TO_PURCHASE}
                    </CustomButton>
                    <StyledHelpPopoverInfoForButtons
                      linkedID="PLEDGEACTION_ADDPURCHASETOBASKET"
                      position="top"
                      attachedComp="BUTTON"
                    />

                    <CustomButton
                      itemProp="secondary"
                      onClick={() => clearItem()}
                    >
                      {PLEDGE_ACTIONS.CLEAR_ITEM}
                    </CustomButton>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </StyledTotalLentRow>
      {isCategoriesPopupVisible && (
        <CategoriesPopup
          visible={isCategoriesPopupVisible}
          setCategoriesPopupVisible={() => setCategoriesPopupVisible(false)}
          itemCategory={category}
          resetItems={resetItems}
          disableAddCoin={(e: any) => setAddCoinDisabled(e)}
        />
      )}
      {isWatchValuePopupVisible && (
        <WatchValuationPopup
          visible={isWatchValuePopupVisible}
          setWatchValuePopupVisible={() => setWatchValuePopupVisible(false)}
        />
      )}
      {isBagValuePopupVisible && (
        <BagValuationPopup
          visible={isBagValuePopupVisible}
          setBagValuePopupVisible={() => setBagValuePopupVisible(false)}
        />
      )}
      {isItemAdditinalDetailsPopupVisible && (
        <ItemAdditionalDetailsPopup
          category={showValuationPopup()}
          visible={isItemAdditinalDetailsPopupVisible}
          setItemAddtionalDetailsPopupVisible={() =>
            setItemAddtionalDetailsPopupVisible(false)
          }
        />
      )}
      {isStonePopupVisible && (
        <AddStonePopup
          visible={isStonePopupVisible}
          setStonePopupVisible={() => setStonePopupVisible(false)}
          itemFixedAttributes={itemFixedAttributes}
          stoneEditRow={stoneEditRow}
        />
      )}
      {isCoinPopupVisible && (
        <AddCoinPopup
          visible={isCoinPopupVisible}
          setCoinPopupVisible={() => setCoinPopupVisible(false)}
          itemFixedAttributes={itemFixedAttributes}
          metalPrice={metalPrice}
          coinEditRow={coinEditRow}
        />
      )}
      {isTakeItemPhotoPopupVisible && (
        <WebcamItemPhoto
          isOpen={isTakeItemPhotoPopupVisible}
          loading={isImageUploading}
          onImageUploadClick={uploadImageHandler}
          onCancel={() => setTakeItemPhotoPopupVisible(false)}
        />
      )}

      {isGuidanceWarningPopupVisible && (
        <ControlledAlert
          closable={true}
          visible={isGuidanceWarningPopupVisible}
          message={guidanceWarningMessage}
          onClick={onProceedAfterWarningClick}
          yesButtonText={'Proceed'}
          cancelButtonText={'Cancel'}
          onCancel={() => setIsGuidanceWarningPopupVisible(false)}
        />
      )}

      {isValuationPopupVisible && (
        <ValuationPopover
          visible={isValuationPopupVisible}
          details={valuationRequestResponse}
          onValuationPopoverClose={onValuationPopoverClose}
        />
      )}

      {isCustomerSearchVisible && (
        <CustomerSearchPopup
          visible={isCustomerSearchVisible}
          isCreateInSearch={true}
          guestType={GUEST_TYPES.VALUATION}
          onOKClick={onContinueAsValuationGuest}
          onCancel={() => setIsCustomerSearchVisible(false)}
        />
      )}

      {isAlertsVisible && (
        <AlertsPopup
          visible={isAlertsVisible}
          setAlertsVisible={onAlertPopupClose}
          errorFields={errorList}
        />
      )}
    </>
  );
};

export default AddPledge;
