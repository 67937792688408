import React, { useState } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { GRID_NO_URL } from 'globalConstants';
import VirtualGrid from 'components/common/virtualGrid/index';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/reducers/payment';
import { SUMMARY_TITLE } from '../constants';
import './index.less';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';

const TableRow = ({
  row,
  isPayment,
  serviceId,
  onClickDeleteRow,
  onClickEditRow,
  ...restProps
}: any) => {
  const { column } = restProps;
  const onDeleteClick = () => {
    if (!isPayment) onClickDeleteRow(row);
  };

  if (column.name === 'delete') {
    return (
      <Table.Cell
        onClick={onDeleteClick}
        {...restProps}
        className={isPayment ? 'grey-cross' : ''}
        style={{
          cursor: 'pointer',
        }}
      />
    );
  } else {
    return <Table.Cell {...restProps} />;
  }
};

const PaymentSummary = ({ isPayment = false, onRemoveTransaction }: any) => {
  const dispatch = useDispatch();
  const { paymentSummary } = useSelector((state: any) => state.payment);
  const [columns] = useState([
    {
      name: 'mode',
      title: 'Method',
      getCellValue: (row: any) => row.mode,
    },
    {
      name: 'amount',
      title: 'Amount',
      getCellValue: (row: any) => `£${row.amount?.toFixed(2)}`,
    },
    {
      name: 'delete',
      title: ' ',
      type: 'delete',
      crossicon: true,
      disabled: isPayment,
      onClick: () => { /* do nothing */ },
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'mode', width: '40%' },
    { columnName: 'amount', width: '35%', align: 'right' },
    { columnName: 'delete', width: '25%', align: 'right' },
  ]);

  const formattedColumns = ['delete'];

  const onClickDeleteRow = (row: any) => {
    const payload = { ...row };
    onRemoveTransaction && onRemoveTransaction(payload);
    dispatch(actions.removeAmounts(payload));
  };

  return (
    <>
      <div className="payment-summary-table">
        <div className="mx-h1">
          <HelpPopoverInfo
            linkedID="PAYMENT_SUMMARY"
            position="rightTop"
            helpPosition="AFTER"
          >
            {SUMMARY_TITLE}
          </HelpPopoverInfo>
        </div>
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={paymentSummary}
          cellComponent={(restProps: any) => (
            <TableRow
              {...restProps}
              isPayment={isPayment}
              onClickDeleteRow={onClickDeleteRow}
            />
          )}
          formattedColumns={formattedColumns}
        />
      </div>
    </>
  );
};

export default PaymentSummary;
