import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { GRID_NO_URL, ROUTE_CONFIG } from 'globalConstants';
import { DELETE_BASKET_PLEDGE_ITEMS } from 'action_creators/basket';
import { EDIT_PLEDGE_ITEMS } from 'action_creators/pledge';
import { actions } from 'redux/reducers/basket';
import { pledgeActions } from 'redux/reducers/pawnbroking';
import VirtualGrid from 'components/common/virtualGrid/index';
import { BASKET_SERVICE_TYPE } from 'components/basket/constants';
import ImageLoader from 'components/common/ImageLoader';
import { getParentImageUrl } from 'utils/util';

const TableRow = ({
  row,
  serviceId,
  onClickDeleteRow,
  onClickEditRow,
  ...restProps
}: any) => {
  const onClickTableRow = () => {
    //onClickStoneRow(row);
  };

  const onDeleteClick = () => {
    onClickDeleteRow(row);
  };

  const onEditClick = () => {
    onClickEditRow(row);
  };

  const Cell = () => {
    const { column } = restProps;
    if (column.name === 'delete') {
      return (
        <Table.Cell
          onClick={onDeleteClick}
          {...restProps}
          style={{
            cursor: 'pointer',
          }}
        />
      );
    }
    if (column.name === 'edit') {
      return (
        <Table.Cell
          onClick={onEditClick}
          {...restProps}
          style={{
            cursor: 'pointer',
          }}
        />
      );
    }
    if (column.name === 'image') {
      return (
        <Table.Cell
          {...restProps}
          style={{
            cursor: 'pointer',
          }}
        />
      );
    }
    return (
      <Table.Cell
        onClick={onClickTableRow}
        {...restProps}
        style={{
          cursor: 'pointer',
        }}
      />
    );
  };

  return <>{Cell()}</>;
};

const BasketPurchaseTable = (item: any) => {
  const rows = item?.purchase?.request?.items || [];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selection, setSelection] = useState<Array<any>>([]);

  const {
    basket: { basket, removePledge },
    user: { user },
  } = useSelector((state: any) => {
    return {
      basket: state.basket,
      user: state.user,
    };
  });

  useEffect(() => {
    if (selection.length > 0 && removePledge) {
      setSelection([]);
    } else {
      const payload = {
        sourceServiceId: item?.serviceId,
        itemIds: selection,
        sourceServiceType: item?.serviceType,
      };
      //update redux merge payload when row selected
      dispatch(actions.move(payload));
    }
  }, [selection, removePledge]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectionChange = (row: any) => {
    setSelection(row);
  };

  const onClickEditRow = (row: any) => {
    navigate(`/${ROUTE_CONFIG.PLEDGE}`);
    dispatch(actions.addPledgeItemsFromBasket(true));
    dispatch(pledgeActions.updatePurchaseServiceId(item?.serviceId));
    dispatch(pledgeActions.updateServiceType(BASKET_SERVICE_TYPE.PURCHASE));
    dispatch({
      type: EDIT_PLEDGE_ITEMS,
      payload: { params: row },
    });
  };

  const onClickDeleteRow = (row: any) => {
    const deleteItemPayload = {
      basketId: basket?.basketId,
      serviceId: item?.serviceId,
      itemIds: [row.itemId],
      userId: user?.userId,
    };
    dispatch({
      type: DELETE_BASKET_PLEDGE_ITEMS,
      payload: { params: deleteItemPayload },
    });
  };

  const [columns] = useState([
    {
      name: 'image',
      title: 'Item Image',
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={getParentImageUrl(row.itemImageCollections)}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'description',
      title: 'Description',
      getCellValue: (row: any) => row?.description,
    },
    {
      name: 'weight',
      title: 'Weight',
      type: 'weight',
      getCellValue: (row: any) => row?.totalWeight,
    },
    {
      name: 'value',
      title: 'Value',
      type: 'currency',
      getCellValue: (row: any) => row?.loanAmount,
    },
    {
      name: 'edit',
      title: ' ',
      type: 'edit',
      onClick: () => undefined,
    },
    {
      name: 'delete',
      title: ' ',
      type: 'delete',
      onClick: () => undefined,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'image', width: '10%' },
    { columnName: 'description', width: '35%' },
    { columnName: 'weight', width: '10%', align: 'right' },
    { columnName: 'value', width: '10%', align: 'right' },
    { columnName: 'edit', width: '10%', align: 'right' },
    { columnName: 'delete', width: '10%', align: 'right' },
  ]);

  const formattedColumns = ['edit', 'delete', 'weight', 'value'];

  return (
    <>
      <div className="basket-item-table">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={rows}
          cellComponent={(restProps: any) => (
            <TableRow
              {...restProps}
              serviceId={item.serviceId}
              onClickDeleteRow={onClickDeleteRow}
              onClickEditRow={onClickEditRow}
            />
          )}
          formattedColumns={formattedColumns}
          selection={selection}
          onSelectionChange={onSelectionChange}
          highlightRow={true}
          rowSelectionEnable={true}
          //selectByRowClick={true} //MP-3756 Pledge/Purchase Item Photo Zoom is not working comment this line due to zoom is not working
        />
      </div>
    </>
  );
};

export default BasketPurchaseTable;
