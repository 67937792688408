import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Row, Col, Input, Menu, Dropdown } from 'antd';
import { joinValues, toFixedNoRound } from 'utils/util';
import { FOOTER_BUTTONS_AND_TEXT, BASKET_STATUS } from 'globalConstants';
import { Button } from 'components/common/Button';
import ExitPopup from '../exitPopUp/exitPopup';
import ExitAndClearPopup from '../exitPopUp/exitAndClearPopup';
import ExitAndSavePopup from '../exitPopUp/exitAndSavePopup';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import HelpPopoverInfo from '../HelpPopoverInfo';

const StyledTotalValueWrapper = styled(Row)<{
  $background: string;
}>`
  background: var(--cyan);
  ${(props) =>
    props.$background &&
    css`
      background: var(--${props.$background});
    `}
  padding: 15px 30px;
`;

const StyledTotalValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const StyledText = styled.div`
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-500);
  line-height: 16px;
  flex-basis: 95%;
`;

const StyledFooterButtonRow = styled(Row)<{
  $enableExtraClass?: boolean;
  $padding?: string;
}>`
  padding: 10px 30px;
  ${({ $padding }) =>
    $padding &&
    css`
      padding: ${$padding};
    `}
  background: var(--white);
  border: 0.6px solid var(--linkwater);
  border-top: none;
  box-shadow: rgba(1, 78, 169, 0.2) 0px 4px 10px;
  border-radius: 0px 0px 5px 5px;
  ${(props) =>
    props.$enableExtraClass &&
    css`
      box-shadow: none;
      border: 0;
      border-top: 1px solid var(--linkwater);
    `}
`;

const StyledLeftRow = styled(Row)<{ $extraButtonJustifyContent?: boolean }>`
  justify-content: ${(props) =>
    props.$extraButtonJustifyContent ? 'space-between' : 'flex-start'};
  column-gap: 20px;
`;

const StyledRightRow = styled(Row)`
  justify-content: flex-end;
  column-gap: 20px;
`;

const StyledInput = styled(Input)<{
  $background: string;
  $border: string;
}>`
  & .ant-input {
    border-radius: 3px;
    height: 4.2rem;
    color: var(--text);
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-500);
    text-align: right;
    background: var(--${(props) => props.$background});
    border: ${(props) =>
      props.$border ? `1px solid var(--${props.$border})` : ''};
  }

  & .ant-input-group-addon {
    padding: 5px 15px;
    border-radius: 3px 0px 0px 3px;
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-500);
    text-align: right;
    background: var(--${(props) => props.$background});
    border: ${(props) =>
      props.$border ? `1px solid var(--${props.$border})` : ''};
  }
`;

type FooterButtonProps = {
  enablePayContainer?: boolean;
  enableBackButton?: boolean;
  payHeader?: any;
  payLabel?: any;
  totalAmount?: any;
  isExitRequired?: boolean;
  enableExitPopup?: boolean;
  isProceedButtonRequired?: boolean;
  backButtonText?: any;
  proceedButtonText?: any;
  proceedButtonItemProp?: any;
  proceedButtonClassName?: any;
  onBackButtonClick?: () => void;
  onProceedClick?: () => void;
  isLoading?: boolean;
  isBackButtonLoading?: boolean;
  isExtraButtonLoading?: boolean;
  backgroundColor?: any;
  isDisabled?: boolean; //proceed button disable
  extraButtonOnClick?: () => void;
  extraButtonText?: any;
  extraButtonAlign?: any;
  extraButtonEanable?: boolean;
  extraButtonBgColor?: any;
  extraButtonJustifyContent?: boolean;
  extraButtonClassName?: any;
  extraButtonDisabled?: boolean;
  extraButtonItemProp?: any;
  exitAndSave?: boolean;
  isExitOnly?: boolean; // Exit, Exit & Save, Exit & Clear
  isBackButtonOnly?: boolean; // only Back without Exit
  onConfirmExitClick?: () => void;
  isBackButtonDisable?: boolean;
  helpSection?: any;
  enableExtraClass?: boolean;
  footerBtnRowPadding?: any;
  isExitAndClear?: boolean;
  isExitAndSaveDisabled?: boolean;
  isSaveJourney?: boolean;
  isExitAndSaveEnabled?: boolean;
  saveJourneyHandler?: () => void;
  exitAndSaveHandler?: () => void;
  secondaryExtraButtonEnable?: boolean;
  secondaryExtraButtonClass?: any;
  secondaryExtraButtonText?: any;
  secondaryExtraButtonItemProp?: string;
  onSecondaryExtraButtonClick?: () => void;
};

const FooterButtons = ({
  enablePayContainer = false,
  enableBackButton = false,
  payHeader = '',
  payLabel = '',
  totalAmount = 0.0,
  isExitRequired = true,
  enableExitPopup = true,
  isProceedButtonRequired = true,
  backButtonText = '',
  proceedButtonText = '', // can be React Element
  proceedButtonItemProp = '',
  proceedButtonClassName = '',
  onBackButtonClick = () => { /* do nothing */ },
  onProceedClick = () => { /* do nothing */ },
  isLoading = false,
  isBackButtonLoading = false,
  isExtraButtonLoading = false,
  extraButtonJustifyContent = false,
  backgroundColor = '',
  isDisabled = false, //proceed button disable
  extraButtonOnClick = () => { /* do nothing */ },
  extraButtonText = '',
  extraButtonAlign = 'right',
  extraButtonEanable = false,
  extraButtonBgColor = '',
  extraButtonClassName = '',
  extraButtonDisabled = false,
  extraButtonItemProp = '',
  exitAndSave = false,
  isExitOnly = false, // Exit, Exit & Save, Exit & Clear
  isBackButtonOnly = false, // only Back without Exit
  onConfirmExitClick = () => { /* do nothing */ },
  isBackButtonDisable = false,
  helpSection = '',
  enableExtraClass = false,
  footerBtnRowPadding = '10px 30px',
  isExitAndClear = false,
  isExitAndSaveDisabled = false,
  isSaveJourney = false,
  isExitAndSaveEnabled = true,
  saveJourneyHandler = () => { /* do nothing */ },
  exitAndSaveHandler = () => { /* do nothing */ },
  secondaryExtraButtonEnable = false,
  secondaryExtraButtonClass = '',
  secondaryExtraButtonText = '',
  onSecondaryExtraButtonClick = () => { /* do nothing */ },
  secondaryExtraButtonItemProp = '',
}: FooterButtonProps) => {
  const [isExitOnlyPopupVisible, setExitOnlyPopupVisible] =
    useState<boolean>(false);
  const [isExitAndClearPopupVisible, setExitAndClearPopupVisible] =
    useState<boolean>(false);
  const [isExitAndSavePopupVisible, setExitAndSavePopupVisible] =
    useState<boolean>(false);

  const {
    checkout: { basket: checkoutBasket },
    customer: { customer },
  } = useSelector((state: any) => {
    return {
      checkout: state.checkout,
      customer: state.customer,
    };
  });
  const onExitOnlyClick = () => {
    enableExitPopup ? setExitOnlyPopupVisible(true) : onConfirmExitClick();
  };

  const onExitAndClearClick = () => {
    setExitAndClearPopupVisible(true);
  };

  const onExitAndSaveClick = () => {
    if (isExitAndSaveEnabled) setExitAndSavePopupVisible(true);
    else exitAndSaveHandler();
  };

  const onConfirmExitOnlyClick = () => {
    onConfirmExitClick();
  };

  const EXIT_OPTIONS =
    isExitAndClear && !customer?.customerId
      ? [{ id: 1, value: 'Exit & Clear', onClick: onExitAndClearClick }]
      : !isExitAndSaveEnabled
      ? [{ id: 1, value: 'Exit & Clear', onClick: onExitAndClearClick }]
      : [
          { id: 1, value: 'Exit & Clear', onClick: onExitAndClearClick },
          { id: 2, value: 'Exit & Save', onClick: onExitAndSaveClick },
        ];

  const ExitOptions = (
    <Menu
      items={EXIT_OPTIONS.map((item) => ({
        key: item.id,
        onClick: item.onClick,
        label: item.value,
      }))}
    />
  );
  return (
    <>
      {enablePayContainer && (
        <>
          <StyledTotalValueWrapper align="middle" $background={backgroundColor}>
            <Col span={12}>
              <StyledLeftRow $extraButtonJustifyContent={false}>
                <Col>
                  <StyledText>
                    <HelpPopoverInfo
                      linkedID={`${helpSection}_${payHeader}`}
                      position="rightBottom"
                    >
                      {payHeader}
                    </HelpPopoverInfo>
                  </StyledText>
                </Col>
              </StyledLeftRow>
            </Col>
            <Col span={12}>
              <StyledRightRow>
                <Col>
                  <StyledTotalValue>
                    <StyledText>{payLabel}</StyledText>
                    <StyledInput
                      disabled
                      addonBefore={'£'}
                      placeholder="0.00"
                      value={toFixedNoRound(totalAmount)}
                      $background={
                        payLabel === FOOTER_BUTTONS_AND_TEXT.TAKE_FROM_CUSTOMER
                          ? 'honeydue'
                          : 'misty-rose'
                      }
                      $border={
                        payLabel === FOOTER_BUTTONS_AND_TEXT.TAKE_FROM_CUSTOMER
                          ? 'mountain-meadow'
                          : 'red'
                      }
                    />
                  </StyledTotalValue>
                </Col>
              </StyledRightRow>
            </Col>
          </StyledTotalValueWrapper>
        </>
      )}
      <StyledFooterButtonRow
        $enableExtraClass={enableExtraClass}
        $padding={footerBtnRowPadding}
        className="footer-buttons"
      >
        {isExitRequired && (
          <Col span={isExitRequired && !isProceedButtonRequired ? 24 : 12}>
            <StyledLeftRow
              $extraButtonJustifyContent={extraButtonJustifyContent}
              align="middle"
            >
              {!isBackButtonOnly && (
                <Col>
                  {exitAndSave ? (
                    <HelpPopoverInfo
                      linkedID={`${helpSection}_${FOOTER_BUTTONS_AND_TEXT.EXIT_AND_SAVE}`}
                      position="rightBottom"
                      style={{
                        float: 'left',
                        display: 'inline',
                      }}
                    >
                      <Button
                        itemProp="secondary"
                        onClick={onExitAndSaveClick}
                        disabled={isExitAndSaveDisabled}
                        style={{ display: 'inline' }}
                      >
                        {FOOTER_BUTTONS_AND_TEXT.EXIT_AND_SAVE}
                      </Button>
                    </HelpPopoverInfo>
                  ) : isExitOnly ? (
                    <HelpPopoverInfo
                      linkedID={`${helpSection}_${FOOTER_BUTTONS_AND_TEXT.EXIT}`}
                      position="rightBottom"
                      style={{ float: 'left' }}
                    >
                      <Button
                        itemProp="secondary"
                        onClick={onExitOnlyClick}
                        style={{ display: 'inline' }}
                      >
                        {FOOTER_BUTTONS_AND_TEXT.EXIT}
                      </Button>
                    </HelpPopoverInfo>
                  ) : (
                    <HelpPopoverInfo
                      linkedID={`${helpSection}_${FOOTER_BUTTONS_AND_TEXT.EXIT}`}
                      position="rightBottom"
                    >
                      <Dropdown
                        overlay={ExitOptions}
                        placement="topLeft"
                        disabled={
                          !isEmpty(checkoutBasket) &&
                          checkoutBasket?.basketStatus !== BASKET_STATUS.PENDING
                        }
                      >
                        <Button
                          className="other-options"
                          itemProp="secondary"
                          style={{ display: 'inline' }}
                        >
                          {FOOTER_BUTTONS_AND_TEXT.EXIT}
                        </Button>
                      </Dropdown>
                    </HelpPopoverInfo>
                  )}
                </Col>
              )}
              {extraButtonEanable && extraButtonAlign === 'left' && (
                <Button
                  style={{
                    background: extraButtonBgColor
                      ? `var(--${extraButtonBgColor})`
                      : '',
                    display: 'inline',
                  }}
                  onClick={extraButtonOnClick}
                  loading={isExtraButtonLoading}
                  itemProp={extraButtonItemProp}
                  disabled={extraButtonDisabled}
                >
                  {extraButtonText}
                </Button>
              )}
              {enableBackButton && (
                <Col>
                  <HelpPopoverInfo
                    linkedID={`${helpSection}_BACK`}
                    position="rightBottom"
                  >
                    <Button
                      loading={isBackButtonLoading}
                      onClick={onBackButtonClick}
                      itemProp="secondary"
                      disabled={isBackButtonDisable}
                    >
                      {backButtonText}
                    </Button>
                  </HelpPopoverInfo>
                </Col>
              )}
            </StyledLeftRow>
          </Col>
        )}

        {isProceedButtonRequired && (
          <Col span={!isExitRequired && isProceedButtonRequired ? 24 : 12}>
            <StyledRightRow>
              {secondaryExtraButtonEnable && (
                <Button
                  onClick={onSecondaryExtraButtonClick}
                  className={`${secondaryExtraButtonClass}`}
                  itemProp={secondaryExtraButtonItemProp}
                >
                  {secondaryExtraButtonText}
                </Button>
              )}
              {extraButtonEanable && extraButtonAlign === 'right' && (
                <HelpPopoverInfo
                  linkedID={`${helpSection}_${extraButtonText}`}
                  position="rightBottom"
                  helpPosition="BEFORE"
                >
                  <Button
                    style={{
                      background: extraButtonBgColor
                        ? `var(--${extraButtonBgColor})`
                        : '',
                      display: 'inline',
                    }}
                    onClick={extraButtonOnClick}
                    itemProp={extraButtonItemProp}
                    disabled={extraButtonDisabled}
                    className={`${extraButtonClassName}`}
                  >
                    {extraButtonText}
                  </Button>
                </HelpPopoverInfo>
              )}
              <HelpPopoverInfo
                linkedID={`${helpSection}_${proceedButtonText}`}
                position="rightBottom"
                helpPosition="BEFORE"
              >
                <Button
                  loading={isLoading}
                  onClick={onProceedClick}
                  disabled={isDisabled}
                  itemProp={proceedButtonItemProp}
                  className={`${proceedButtonClassName}`}
                >
                  {proceedButtonText}
                </Button>
              </HelpPopoverInfo>
            </StyledRightRow>
          </Col>
        )}
      </StyledFooterButtonRow>
      {isExitOnlyPopupVisible && (
        <ExitPopup
          visible={isExitOnlyPopupVisible}
          setExitOnlyPopupVisible={() => setExitOnlyPopupVisible(false)}
          onConfirmExitClick={() => onConfirmExitOnlyClick()}
        />
      )}
      {isExitAndClearPopupVisible && (
        <ExitAndClearPopup
          visible={isExitAndClearPopupVisible}
          setExitAndClearPopupVisible={() => setExitAndClearPopupVisible(false)}
        />
      )}
      {isExitAndSavePopupVisible && (
        <ExitAndSavePopup
          visible={isExitAndSavePopupVisible}
          isSaveJourney={isSaveJourney}
          saveJourneyHandler={saveJourneyHandler}
          customerName={
            joinValues([customer.firstNames, customer.surname], true) || ''
          }
          setExitAndSavePopupVisible={() => setExitAndSavePopupVisible(false)}
        />
      )}
    </>
  );
};

export default FooterButtons;
