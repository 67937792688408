import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Col, Layout, notification, Row, Spin } from 'antd';
import styled from 'styled-components';

import ConfiscationsSummary from './confiscationsSummary';
import FooterButtons from 'components/common/footerButtons';
import PledgeNotesPopup from '../common/pledgeNotesPopup';
import PledgeReleasePopup from '../common/pledgeReleasePopup';

import {
  GENERIC_ERROR,
  PLEDGE_ACTION_NOTES_TITLES,
  PLEDGE_MANAGEMENT_TITLES,
  PLEDGE_NOTICE_TYPE,
  RELEASE_PLEDGE_POPUP,
} from 'components/pledgemanagement/constants';
import {
  addConfiscationNotes,
  releaseConfiscation,
  getConfiscationLoans,
  getConfiscationNotesByLoanId,
} from 'services/pledgeManagement';
import { actions as pledgeMngmtActions } from 'redux/reducers/pledgeManagement';
import { RESPONSE_STATUS } from 'globalConstants';

const StyledContentLayout = styled(Layout)`
  background: var(--white);
  padding: 10px 20px;
  border-radius: 5px;
`;

const Confiscations = () => {
  const {
    pledgeManagement: {
      isPledgeNotesPopupVisible,
      isPledgeReleasePopupVisible,
    },
    user: { user },
  } = useSelector((state: any) => {
    return { pledgeManagement: state.pledgeManagement, user: state.user };
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedLoanId, setSelectedLoanId] = useState<number>(0);
  const [selectedCustomerName, setSelectedCustomerName] = useState<string>('');
  const [selectedAgreementDetails, setSelectedAgreementDetails] = useState<
    Array<any>
  >([]);

  useEffect(() => {
    getConfiscationList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getConfiscationList = async () => {
    try {
      setIsLoading(true);

      const response = await getConfiscationLoans();
      if (response?.data) {
        setIsLoading(false);
        dispatch(pledgeMngmtActions.setConfiscationList(response?.data?.loans));
      }
    } catch (e: any) {
      setIsLoading(false);
      dispatch(pledgeMngmtActions.setConfiscationList([]));
      catchBlockError();
    }
  };

  const getConfiscateNotes = async (loanId: number) => {
    try {
      setIsLoading(true);

      const response = await getConfiscationNotesByLoanId(loanId);
      if (response?.data) {
        setIsLoading(false);
        setSelectedAgreementDetails([response?.data]);
        dispatch(pledgeMngmtActions.setIsPledgeNotesPopupVisible(true));
      }
    } catch (e: any) {
      setIsLoading(false);
      catchBlockError();
    }
  };

  const onSaveNotesClick = async (note: string) => {
    try {
      const params = {
        loanId: selectedLoanId,
        note,
        userName: user?.displayName,
      };

      dispatch(pledgeMngmtActions.initUpdate(true));
      const response = await addConfiscationNotes(params);
      if (response?.status === RESPONSE_STATUS.SUCCESS) {
        dispatch(pledgeMngmtActions.setIsPledgeNotesPopupVisible(false));
        dispatch(pledgeMngmtActions.initUpdate(false));
      }
    } catch (e: any) {
      catchBlockError();
      dispatch(pledgeMngmtActions.initUpdate(false));
    }
  };

  const onReleaseClick = () => {
    dispatch(pledgeMngmtActions.setIsPledgeReleasePopupVisible(true));
    dispatch(pledgeMngmtActions.setIsPledgeNotesPopupVisible(false));
  };

  const catchBlockError = () => {
    notification.error({
      message: GENERIC_ERROR,
      duration: 5,
    });
  };

  const onAgreementSelection = (row: any) => {
    getConfiscateNotes(row?.loanId);
    setSelectedLoanId(row?.loanId);
    setSelectedCustomerName(row?.customerName);
  };

  const onReleaseConfirm = async (note: string) => {
    try {
      const params = {
        loanId: selectedLoanId,
        note,
        userName: user?.displayName,
      };

      dispatch(pledgeMngmtActions.initLoading(true));
      const response = await releaseConfiscation(params);
      if (response?.status === RESPONSE_STATUS.SUCCESS) {
        getConfiscationList();
        dispatch(pledgeMngmtActions.setIsPledgeReleasePopupVisible(false));
      }

      dispatch(pledgeMngmtActions.initLoading(false));
    } catch (e: any) {
      catchBlockError();
      dispatch(pledgeMngmtActions.initLoading(false));
    }
  };

  const onConfirmExitOnlyClick = () => {
    navigate('/');
  };

  useEffect(() => {
    return () => {
      dispatch(pledgeMngmtActions.clearAuctions());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StyledContentLayout className="confiscations-wrapper">
        <Row className="header">
          <Col span={24}>{PLEDGE_MANAGEMENT_TITLES.CONFISCATIONS}</Col>
        </Row>
        <Spin spinning={isLoading}>
          <Row className="content">
            <Col span={24}>
              <div className="table-wrapper">
                <ConfiscationsSummary onRowClick={onAgreementSelection} />
              </div>
            </Col>
          </Row>
        </Spin>
      </StyledContentLayout>

      <FooterButtons
        enableExtraClass
        isExitOnly
        extraButtonItemProp="secondary"
        enableExitPopup={false}
        onConfirmExitClick={onConfirmExitOnlyClick}
      />

      {isPledgeNotesPopupVisible && (
        <PledgeNotesPopup
          visible={isPledgeNotesPopupVisible}
          width={850}
          isReleaseEnabled={true}
          title={PLEDGE_ACTION_NOTES_TITLES.CONFISCATE_MANAGER}
          noticeType={PLEDGE_NOTICE_TYPE.CONFISCATE}
          selectedAgreement={selectedAgreementDetails}
          onConfirm={onReleaseClick}
          onSaveNote={onSaveNotesClick}
        />
      )}

      {isPledgeReleasePopupVisible && (
        <PledgeReleasePopup
          visible={isPledgeReleasePopupVisible}
          customerName={selectedCustomerName}
          title={RELEASE_PLEDGE_POPUP.CONFISCATE_TITLE}
          content={RELEASE_PLEDGE_POPUP.CONTENT}
          noticeType={PLEDGE_NOTICE_TYPE.CONFISCATE}
          selectedAgreement={selectedAgreementDetails}
          onConfirm={onReleaseConfirm}
        />
      )}
    </>
  );
};

export default Confiscations;
