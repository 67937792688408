import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Col, Row, notification } from 'antd';
import { isEmpty } from 'lodash';

import CardTabs from 'components/common/CardTabs';
import CustomerHeader from 'components/customer/customerHeader';
import ControlledAlert from 'components/common/ControlledAlert';
import {
  CaseNotes,
  ProfileOverview,
  CaseSummary,
  ServiceManager,
  AtRiskNotes,
} from './widgets';
import ReviewChangesPopup from './widgets/serviceManager/reviewChangesPopup';

import { actions as consumerDutyAction } from 'redux/reducers/consumerDuty';
import { actions as customerActions } from 'redux/reducers/customer';

import { postViewNotificationItem } from 'services/workQueue';

import {
  BUTTONS_LABEL,
  CONSUMER_DUTY_TABS,
  CONSUMER_DUTY_CST_TABS,
  EXIT_MSG,
  TASK_OWNER_VALUE,
  TABS_NAME,
  CONSUMER_DUTY_VIEW_HEADING,
} from './constants';
import { GENERIC_ERROR } from 'globalConstants';
import { VIEW_TYPE } from 'components/notifications/constants';

import './index.less';

const ConsumerDuty = () => {
  const {
    user: { selectedStore, user },
    consumerDuty: {
      selectedTab,
      selectedTaskId: taskId,
      loanPrivileges,
      newPledgePermissions,
      isReviewChangesPopupVisible,
      selectedTaskOwner,
    },
  } = useSelector((state: any) => {
    return { user: state.user, consumerDuty: state.consumerDuty };
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [tabsList, setTabsList] = useState<Array<any>>([]);
  const [isExitPopupVisible, setExitPopupVisible] = useState<boolean>(false);

  useEffect(() => {
    if (selectedTaskOwner === TASK_OWNER_VALUE.AT_RISK) {
      setTabsList(CONSUMER_DUTY_CST_TABS);
      dispatch(consumerDutyAction.setSelectedTab(TABS_NAME.PROFILE_OVERVIEW));
    } else setTabsList(CONSUMER_DUTY_TABS);
  }, [selectedTaskOwner]); // eslint-disable-line react-hooks/exhaustive-deps

  const onMenuClick = (key: any) => {
    dispatch(consumerDutyAction.setSelectedTab(key));
  };

  const onCaseExit = (isAlertRequired = true) => {
    if (!isAlertRequired) onOkToCaseExitClick();
    else {
      if (isEmpty(loanPrivileges) && isEmpty(newPledgePermissions)) {
        setExitPopupVisible(true);
      } else dispatch(consumerDutyAction.setIsReviewChangesPopupVisible(true));
    }
  };

  const onOkToCaseExitClick = async () => {
    const payload = {
      storeId: selectedStore?.storeId,
      taskId,
      agentId: user?.userId,
      agentName: user?.displayName,
      viewType: VIEW_TYPE.BACK_TO_LISTING,
    };
    try {
      setLoading(true);
      const response = await postViewNotificationItem(payload);
      if (response?.data) {
        setLoading(false);
        dispatch(consumerDutyAction.resetConsumerDuty());
        dispatch(customerActions.clearAll({}));
        navigate('/');
      }
    } catch (error: any) {
      setLoading(false);
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  return (
    <>
      <div className="consumer-duty-wrapper">
        <Row>
          <Col span={24}>
            <CustomerHeader
              title={
                selectedTaskOwner === TASK_OWNER_VALUE.AT_RISK
                  ? CONSUMER_DUTY_VIEW_HEADING.CUSTOMER_RISK_REVIEW
                  : CONSUMER_DUTY_VIEW_HEADING.CASE_FILE
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <CardTabs
              activeKey={selectedTab}
              onMenuClick={onMenuClick}
              tabslist={tabsList}
            />
          </Col>
        </Row>
        <div className="content-wrapper">
          {selectedTab === TABS_NAME.CASE_SUMMARY && (
            <CaseSummary onCaseExit={onCaseExit} />
          )}
          {selectedTab === TABS_NAME.PROFILE_OVERVIEW && (
            <ProfileOverview onCaseExit={onCaseExit} />
          )}
          {selectedTab === TABS_NAME.SERVICE_MANAGER && (
            <ServiceManager onCaseExit={onCaseExit} />
          )}
          {selectedTab === TABS_NAME.CASE_NOTES && (
            <CaseNotes onCaseExit={onCaseExit} />
          )}
          {selectedTab === TABS_NAME.AT_RISK_NOTES && (
            <AtRiskNotes onCaseExit={onCaseExit} />
          )}
        </div>
      </div>

      {isExitPopupVisible && (
        <ControlledAlert
          visible={isExitPopupVisible}
          message={EXIT_MSG.PRIMARY}
          secondaryMessage={EXIT_MSG.SECONDARY}
          //Ok button props
          isSuccessBtnOnRight
          itemProp=""
          isLoading={isLoading}
          onClick={onOkToCaseExitClick}
          yesButtonText={BUTTONS_LABEL.OK}
          //Cancel button props
          onCancel={() => setExitPopupVisible(false)}
          cancelButtonText={BUTTONS_LABEL.CANCEL}
        />
      )}

      {isReviewChangesPopupVisible && (
        <ReviewChangesPopup isVisible={isReviewChangesPopupVisible} />
      )}
    </>
  );
};

export default ConsumerDuty;
