import React, { useState } from 'react';
import { Menu, Button as LinkButton, MenuProps } from 'antd';

import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import { ROUTE_CONFIG, BASKET_STATUS, COLORS_CODE } from 'globalConstants';
import { actions } from 'redux/reducers/basket';
import * as BuildNumber from '../../../BuildNumber';
import ControlledAlert from 'components/common/ControlledAlert';
import { useAuth } from 'providers/AuthProvider';
import { CASH_MANAGEMENT_ALERT } from '../constants';
const menuItems = [
  {
    text: 'Home',
    background: COLORS_CODE.HOME,
    shortName: '',
    path: '/',
  },
  {
    text: 'Pawnbroking/Buy',
    background: COLORS_CODE.PLEDGE,
    shortName: 'PB',
    path: `/${ROUTE_CONFIG.PLEDGE}`,
  },
  {
    text: 'Pickup/Renew',
    background: COLORS_CODE.PICKUPRENEW,
    shortName: 'PR',
    path: `/${ROUTE_CONFIG.PICKUPRENEW}`,
  },
  {
    text: 'Retail Shopping',
    background: COLORS_CODE.RETAIL_SHOPING,
    shortName: 'RS',
    path: `/${ROUTE_CONFIG.RETAILSALES}`,
  },

  /* TODO - Features disabled which are not developed yet  */

  // {
  //   text: 'Layaway Service',
  //   background: COLORS_CODE.LAYWAYS,
  //   shortName: 'LA',
  //   path: '',
  // },
  {
    text: 'Travel Money',
    background: COLORS_CODE.TRAVELMONY,
    shortName: 'TM',
    path: '/travelmoney',
  },
  {
    text: 'Western Union',
    background: COLORS_CODE.WESTERN_UNION,
    shortName: 'WU',
    path: `/${ROUTE_CONFIG.WESTERN_UNION}`,
  },
  {
    text: 'Cheque Cashing',
    background: COLORS_CODE.CHEQUE_CASHING,
    shortName: 'CC',
    path: '/chequeCashing',
  },

  // {
  //   text: 'Repair Services',
  //   background: COLORS_CODE.REPAIR_SERVICES,
  //   shortName: 'RE',
  //   path: '',
  // },
  {
    text: 'Cash Management',
    background: COLORS_CODE.CASH_MGMT,
    shortName: 'CM',
    path: '/cashManagement',
  },
  {
    text: 'Inventory Management',
    background: COLORS_CODE.IVENTORY_MGMT,
    shortName: 'IM',
    path: `/${ROUTE_CONFIG.INVENTORY_MANAGEMENT}`,
  },
  {
    text: 'Valuation Center',
    background: COLORS_CODE.ADMIN,
    shortName: 'VC',
    path: `/${ROUTE_CONFIG.VALUATION_CENTER}`,
  },
  {
    text: 'Payment',
    background: COLORS_CODE.TRAVELMONY,
    shortName: 'TM',
    path: '/payment',
  },
  {
    text: 'Pledge Management',
    background: COLORS_CODE.PLEDGE_MANAGEMENT,
    shortName: 'PM',
    path: `/${ROUTE_CONFIG.PLEDGE_MANAGEMENT}`,
  },
  {
    text: 'Admin',
    background: COLORS_CODE.ADMIN,
    shortName: 'AD',
    path: `/${ROUTE_CONFIG.ADMIN}`,
  },
  {
    text: 'SST/CST',
    background: COLORS_CODE.CONSUMER_DUTY,
    shortName: 'CS',
    path: `/${ROUTE_CONFIG.CONSUMER_DUTY}`,
  },
];

/* TODO - Features disabled which are not developed yet  */
// const bottomMenuItems = ['Reports', 'Admin'];

const StyledMenu = styled(Menu)<{ $isTopMenu: boolean }>`
  background: var(--menu-background);
  color: var(--white);
  border: 0;
  &.ant-menu-inline {
    & .ant-menu-item {
      width: 100%;
      height: 53px;
      padding: 0px 16px 0px 24px;
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
  &.ant-menu-inline-collapsed {
    width: 64px !important;
    & .ant-menu-item {
      height: 53px;
      padding: 0px 16px 0px 24px;
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
  & .ant-menu-item-active {
    color: var(--white);
    background-color: var(--midnight-blue);
    width: 100%;
    height: 50px;
    margin-top: 0px;
  }
`;

const StyledBoxFilled = styled.div<{ background: string }>`
  background: var(--${(props) => props.background});
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 33px;
  display: inline-block;
  text-align: center;
  line-height: 33px;
  font-weight: bold;
  color: var(--white);
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.55);
`;

const StyledContent = styled.span`
  margin-left: 16px;
`;

const StyledMenuItem = styled.span`
  color: var(--winkleGray);
  font-size: 1.4rem;
`;

/* TODO - Features disabled which are not developed yet  */
// const StyledBottomMenuItem = styled(Menu.Item)`
//   color: var(--white);
//   font-size: 1.4rem;
//   border-top: 1px solid #203a6c;
// `;

const StyledTopContainer = styled.div`
  position: relative;
`;

const StyledBottomContainer = styled.div`
  margin: 10px 0;
  position: absolute;
  bottom: 55px;
  width: 100%;
`;

const StyledSignOut = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--biscay);
  background-color: var(--downriver);
  button {
    color: var(--white);
    height: 62px;
    font-weight: var(--font-weight-500);
    padding: 17px 0px;
  }
`;
const BasketIdDiv = styled.div`
  font-size: 12px;
  color: var(--white);
  text-align: center;
`;

const StyledSignOutImg = styled.img`
  padding-right: 4px;
`;

const AppDrawer = () => {
  const { signOut } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [enableAlert, setEnableAlert] = useState(false);
  const {
    customer: { isCustomerSessionActive },
    basket: { basket },
    checkout: { basket: checkoutBasket },
    user: { menus },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
      basket: state.basket,
      checkout: state.checkout,
      user: state.user,
    };
  });
  const onOkClick = () => {
    setEnableAlert(false);
  };

  const onMenuItemClick: MenuProps['onClick'] = (event: any) => {
    if (event) {
      const ctaPath = event?.key?.split('-ctapath-');
      if (ctaPath.length > 1) {
        const meuItemPath = ctaPath[1];

        if (meuItemPath === '/' && isCustomerSessionActive) {
          return;
        }
        if (meuItemPath === `/${ROUTE_CONFIG.PLEDGE}`) {
          dispatch(actions.addPledgeItemsFromBasket(false));
        }
        if (location.pathname === `/${ROUTE_CONFIG.CASH_MGMT}`) {
          return;
        }
        if (
          isCustomerSessionActive &&
          (meuItemPath === `/${ROUTE_CONFIG.CASH_MGMT}` ||
            meuItemPath === `/${ROUTE_CONFIG.INVENTORY_MANAGEMENT}` ||
            meuItemPath === `/${ROUTE_CONFIG.PLEDGE_MANAGEMENT}`)
        ) {
          setEnableAlert(true);
          return;
        }
        if (
          location.pathname === `/${ROUTE_CONFIG.CHECKOUT}` &&
          !isEmpty(checkoutBasket) &&
          checkoutBasket?.basketStatus !== BASKET_STATUS.PENDING
        ) {
          return;
        }
        if (
          location.pathname === `/${ROUTE_CONFIG.PAYMENT}` &&
          !isEmpty(checkoutBasket)
        ) {
          return;
        }
        !enableAlert && navigate(meuItemPath);
      }
    }
  };

  const menuItemsElements = menuItems
    .filter((itemFilter) => {
      const isMenuAvailable = menus.find(
        (el: any) => itemFilter.text?.toLowerCase() === el.url?.toLowerCase()
      );

      if (isMenuAvailable) return !isMenuAvailable?.isDisabled;
      else return false;
    })
    .map((menuItem, index: number) => {
      return {
        label: (
          <StyledMenuItem key={`appdrawer-menu-item-${index}`}>
            <StyledBoxFilled background={menuItem.background}>
              {menuItem.shortName ? (
                menuItem.shortName
              ) : (
                <img
                  src={require('../../../assets/images/home_icon.svg').default}
                  alt="home icon"
                />
              )}
            </StyledBoxFilled>
            <StyledContent>{menuItem.text}</StyledContent>
          </StyledMenuItem>
        ),
        key: `appdrawer-menu-item-${index}-ctapath-${menuItem?.path}`,
      };
    });

  return (
    <>
      <StyledTopContainer>
        <StyledMenu
          mode="inline"
          selectable={false}
          $isTopMenu={true}
          onClick={onMenuItemClick}
          items={menuItemsElements}
        ></StyledMenu>
        {/* <StyledMenu mode="inline" selectable={false} $isTopMenu={true}>
          {menuItems.map((menuItem, index) => {
            let isMenuAvailable = menus.find(
              (el: any) => menuItem.path.slice(1) === el.url
            );
            if (isMenuAvailable?.isDisabled) return [];

            return [
              <StyledMenuItem
                key={index}
                onClick={() => onMenuItemClick(menuItem)}
              >
                <StyledBoxFilled background={menuItem.background}>
                  {menuItem.shortName ? (
                    menuItem.shortName
                  ) : (
                    <img
                      src={
                        require('../../../assets/images/home_icon.svg')
                      }
                      alt="home icon"
                    />
                  )}
                </StyledBoxFilled>
                <StyledContent>{menuItem.text}</StyledContent>
              </StyledMenuItem>,
            ];
          })}
        </StyledMenu> */}
      </StyledTopContainer>
      <StyledBottomContainer>
        <BasketIdDiv>{basket?.basketId}</BasketIdDiv>
        <BasketIdDiv>v{BuildNumber?.BuildNumber}</BasketIdDiv>

        {/* TODO - Features disabled which are not developed yet  */}
        {/* <StyledMenu mode="inline" selectable={false} $isTopMenu={false}>
        {/* {bottomMenuItems.map((menuItem, index) => {
            return [
              <StyledBottomMenuItem key={index}>
                <StyledColIcon
                  alt={`${menuItem?.toLowerCase()}`}
                  src={require(`../../../assets/images/${menuItem?.toLowerCase()}.svg`)}
                />
                <StyledContent>{menuItem}</StyledContent>
              </StyledBottomMenuItem>,
            ];
          })} */}
        {/* </StyledMenu> */}
      </StyledBottomContainer>
      <StyledSignOut>
        <LinkButton type="link" block title="Sign out" onClick={signOut}>
          <StyledSignOutImg
            src={require('../../../assets/images/signout.svg').default}
            alt="sign out"
          />
          Sign out
        </LinkButton>
      </StyledSignOut>
      <ControlledAlert
        width={457}
        visible={enableAlert}
        message={CASH_MANAGEMENT_ALERT}
        onClick={onOkClick}
        onCancel={onOkClick}
        yesButtonText={'OK'}
        isCancelBtn={false}
      />
    </>
  );
};

export default AppDrawer;
