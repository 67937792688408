import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Modal, Row, Form, notification } from 'antd';
import { BUTTON_LABELS } from 'components/pledgemanagement/constants';
import { Button } from 'components/common/Button';

import { actions as pledgeMngmtActions } from 'redux/reducers/pledgeManagement';
import { PLEDGE_NOTICE_TYPE } from 'components/pledgemanagement/constants';
import TextArea from 'components/common/TextArea';

interface PledgeReleasePopupProps {
  width?: number;
  visible: boolean;
  title: string;
  customerName: string;
  selectedAgreement: Array<any>;
  noticeType: string;
  onConfirm: (note: string) => void;
  content: string;
  releaseValidationMsg?: string;
}

const PledgeReleasePopup = ({
  width = 510,
  visible,
  title,
  customerName,
  selectedAgreement,
  noticeType,
  onConfirm,
  content,
  releaseValidationMsg = 'Please add a reason for release.',
}: PledgeReleasePopupProps) => {
  const { loading } = useSelector((state: any) => state.pledgeManagement);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onCancelClick = () => {
    dispatch(pledgeMngmtActions.setIsPledgeReleasePopupVisible(false));
    dispatch(pledgeMngmtActions.setPledgeNoticeType(''));
  };

  const onConfirmClick = () => {
    const fieldValues = form.getFieldsValue();
    if (!fieldValues.noteText) {
      return notification.error({
        message: releaseValidationMsg,
        duration: 5,
      });
    }

    onConfirm(fieldValues.noteText);
  };

  return (
    <>
      <Modal
        open={visible}
        centered
        width={width}
        title={title}
        className="mark-vulnerable-wrapper"
        destroyOnClose={true}
        maskClosable={false}
        closable={false}
        onCancel={onCancelClick}
        footer={[
          <Row key="markVulnerablePopup">
            <Col span={4} style={{ textAlign: 'left' }}>
              <Button
                itemProp="secondary"
                key="markVulnerablePopupCancel"
                onClick={onCancelClick}
              >
                {BUTTON_LABELS.CANCEL}
              </Button>
            </Col>
            <Col span={10} offset={10}>
              <Button
                loading={loading}
                itemProp="secondary"
                key="markVulnerableConfirm"
                onClick={() => onConfirmClick()}
              >
                {BUTTON_LABELS.CONFIRM}
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <>
          <Row className="heading">Customer: {customerName}</Row>
          <Row className="agreement-num">
            {noticeType === PLEDGE_NOTICE_TYPE.CONFISCATE ? (
              <Col span={24}>
                Release Pledge {selectedAgreement[0]?.loanNumber} from
                Confiscation?
              </Col>
            ) : (
              <Col span={24}>
                Release Vulnerable Pledge: {selectedAgreement[0]?.loanNumber}
              </Col>
            )}
          </Row>
          <Row className="release-txt">
            <Col span={24}>{content}</Col>
          </Row>
          <Row className="release-txt">
            <Col span={24}>Reason for Release (Internal Use Only)</Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form layout="vertical" form={form}>
                <Form.Item
                  label=""
                  name="noteText"
                  className="label-control form-textarea"
                >
                  <TextArea showCount placeholder="Add a reason" />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </>
      </Modal>
    </>
  );
};

export default PledgeReleasePopup;
