export const NOTIFICATION_HEADING = 'Notifications';

export const WORKQUEUE_TABS_SST = [
  { key: '1', title: 'Loan Exception' },
  { key: '2', title: 'Managed Cases' },
];

export const WORKQUEUE_TABS_CST = [{ key: '1', title: 'Loan Exception' }];

export const DASHBOARD_TABS = [
  { key: '3', title: 'Bank Transfer' },
  { key: '4', title: 'Valuations' },
];

export const MY_ACTION_TAB = [{ key: '0', title: 'My Actions' }];

export const TABS_BY_ID = {
  MY_ACTIONS: '0',
  LOAN_EXCEPTION: '1',
  MANAGE_CASES: '2',
  BANK_TRANSFER: '3',
  VALUATION: '4',
};

export const LABELS = {
  SEARCH_BY: 'Search by',
  FILTER_BY: 'Filter by',
};

export const BUTTON_LABELS = {
  SEARCH: 'Search',
  RESET: 'Reset',
  EXIT: 'Exit',
  VIEW: 'View',
  SAVE_AND_EXIT: 'Save and Exit',
  PROFILE: 'Profile',
  MANAGE: 'Manage Services',
  REVIEW: 'Review',
  REVIEW_CHANGES: 'Review Changes',
  PROCESS: 'Process',
  IN_USE: 'In Use',
  WORK_IT: 'Work It',
  CANCEL: 'Cancel',
  PROCEED: 'Proceed',
  CLOSE: 'Close',
  COMPLETE: 'Complete',
  COMPLETE_AS_CASH: 'Complete as Cash',
  COMPLETE_AS_FASTERPAYMENT: 'Complete as Faster Payment',
};

export const VIEW_TYPE = {
  VIEW_TASK_DETAILS: 1,
  BACK_TO_LISTING: 2,
};

export const WORKQUEUE_NOTIFICATIONS_TABS = [
  { key: 0, title: 'Valuations', requestId: 1 },
  { key: 1, title: 'Bank Transfers', requestId: 2 },
];

export const NOTIFICATIONS_TABS_KEY = {
  VALUATIONS: 0,
  FASTER_PAYMENTS: 1,
};

export const NOTIFICATIONS_PAYMENTS_STATUS = [
  { value: '', label: 'All Status' },
  { value: 'Approved', label: 'Approved' },
  { value: 'Rejected', label: 'Rejected' },
  { value: 'Auto Paid', label: 'Auto Paid' },
  { value: 'Pending', label: 'Pending' },
];
export const SHOW_ALERT_TYPE = {
  APPROVED: 1,
  REJECTED: 2,
  STORE_TILL: 3,
  USER_TILL: 4,
  DIFFERENT_USER: 5,
};
export const PAYMENT_POPUP_TEXT = {
  NO_FUND_STORE_TILL:
    'Note: There are insufficient funds in the store, you cannot process this request at this time.',
  NO_FUND_USER_TILL:
    'Note: There are insufficient funds in your till. A cash transfer will be required.',
  DIFFERENT_USER_BEFORE: 'The original transaction was processed from ',
  DIFFERENT_USER_AFTER:
    " 's till. Please process a cash transfer for the funds to ",
  NO_PHYSICAL_TRANSFER:
    '(No physical transfer of cash is required between yourself and the original user)',
  APPROVED_MESSAGE:
    'The bank transfer for this transaction has been approved. Please inform the customer that the funds should appear in their account today. ',
  REJECTED_MESSAGE:
    'The bank transfer for this transaction has been rejected. Please contact the customer to arrange payment.',
  TITLE_APPROVED: 'Bank Transfer Approved',
  TITLE_REJECTED: 'Bank Transfer Rejected',
  AMOUNT_PAID: 'Amount paid',
  AMOUNT_DUE: 'Amount due',
};
export const STATUS_LABELS = {
  ALL_STATUS: 'All Status',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  AUTO_PAID: 'Auto Paid',
  PENDING: 'Pending',
  TILL_CASHED_UP: 'Cashed Up',
  COMPLETED: 'Completed',
};

export const MULTIPLE_USERS_ALERT =
  'The selected task is already being processed by another user. Do you still want to continue?';
export const PAY_MY_CUSTOMER_APP = 'PayMyCustomer';

export const PAGINATION_GRID = {
  PAGE_SIZE: 100,
};
