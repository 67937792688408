export const USER_MANAGER = {
  LABELS: {
    EMPLOYEE_ID: 'Employee ID',
    DISPLAY_NAME: 'Display Name',
    EMPLOYEE_NAME: 'Employee Name',
    USER_ID: 'User ID',
    STORE_FILTER: 'Default Store Filter',
    SERVICE_ENABLE_NAME: 'OK to do',
  },
  CHECKBOX_LABELS: {
    PASSWORD: 'Must Change Password',
    ACTIVE: 'Active (can log-in)',
    GLOBAL_USER:
      'Global User (You should NOT toggle this without advice from IT)',
    SYSTEM_ADMIN:
      'System Administrator (Users with this privileage can access this screen)',
    AUDITOR: 'Auditor (Can log into Audrey)',
    MANAGER: 'Manager',
    ELEVATED: 'Elevated',
    USER: 'User',
  },
  ROLES_CHECKBOX: {
    SELF_AUTH: 'Self Auth',
    ENTER_TRANS: 'Enter Transactions',
    MAIN_SAFE: 'Main Safe',
    CREATE_DRAWERS: 'Create Drawers',
    CREATE_CUSTOMERS: 'Create Customers',
    ACCESS_USER_MANAGER: 'Access user Manager',
    RUN_REPORTS: 'Run Reports',
    CAN_REFUND: 'Can Refund',
    AUTH_BASKET: 'Co-Authorise Basket',
    AUTH_CUSTOMER: 'Can Co-Authorise Customer Creation/Merging',
    TILL_USER: 'Till User',
    VOID_ADMIN: 'Void Administrator',
    STANDARD: 'Standard',
    BANKER: 'Banker',
    REPORTER: 'Reporter',
    EDITOR: 'Editor',
    PLEDGE_TRANS_AUTHORISER: 'Pledge transaction Authoriser',
    RETAIL_TRANS_AUTHORISER: 'Retail transaction Authoriser',
  },
  TITLES: {
    USER_MANAGER: 'User Manager',
    LOGIN_DETAILS: 'Login Details for ',
    APPLICATION_PRIVILAGES: 'Application privileges for ',
    POLICIES: 'Limit',
    STORES: 'Add Stores',
  },
  PARENT_ROLES: {
    NONE: 0,
    MANAGER: 1,
    ELEVATED_USER: 2,
    USER: 3,
  },
  POLICIES: [
    {
      content: 'Authorisation Limit',
      name: 'AuthLimit',
      policyId: 1,
      prefix: '£',
    },
    {
      content: 'Pledge/Purchase value item',
      name: 'PledgeAuthLimit',
      policyId: 2,
      prefix: '£',
    },
    {
      content: 'Pledge/Purchase basket tab total',
      name: 'PledgeBasketLimit',
      policyId: 3,
      prefix: '£',
    },
    {
      content: 'Retail sale value basket tab total',
      name: 'RetailLimit',
      policyId: 4,
      prefix: '£',
    },
    {
      content: 'Retail refund value basket tab total',
      name: 'RetailRefundLimit',
      policyId: 5,
      prefix: '£',
    },
    {
      content: 'Retail discount percentage',
      name: 'RetailDiscountLimit',
      policyId: 6,
      prefix: '%',
    },
  ],
  ROLE_IDS: {
    STANDARD: 1,
    ADMIN: 2,
    MANAGER: 3,
    BANKER: 4,
    TRANSACTION: 5,
    REPORTER: 6,
    PLEDGE_TRANS_AUTHORISER: 7,
    RETAIL_TRANS_AUTHORISER: 8,
    AUDITOR: 9,
    CUSTOMER_EDITOR: 10,
    EDITOR: 11,
    TILL_USER: 12,
    VOID_ADMIN: 13,
  },
  GROUP_IDS: {
    IT_ADMIN: 3,
  },
  POLICIES_LIMIT: {
    MANAGER: {
      ALL: 5000,
      RETAIL_DISCOUNT_LIMIT: 100,
    },
    ELEVATED: {
      ALL: 2500,
      RETAIL_DISCOUNT_LIMIT: 50,
    },
    USER: {
      ALL: 250,
      RETAIL_DISCOUNT_LIMIT: 10,
    },
  },
  DEFAULT_ROLES: {
    MANAGER: [4, 5, 6, 7, 8, 10, 11, 12, 13],
    ELEVATED: [5, 10],
    USER: [5, 10],
  },
  MESSAGES: {
    MIN_VALUE_MESSAGE: 'The entered value should not be less than ',
  },
};

export const FEATURES = [
  { name: 'pledge', title: 'Pledge/Purchase' },
  { name: 'pickup', title: 'Pickup/Renew' },
  { name: 'retail', title: 'Retail' },
  { name: '3pc', title: 'Cheque Cashing' },
  { name: 'fx', title: 'Travel Money' },
];

export const VOID_SUCCESS_MESSAGE =
  'The transaction has successfully been voided';

export const TRAVEL_MONEY_RATES = {
  TITLES: {
    TRAVEL_MONEY_RATES: 'Travel Money Rates',
    RATE_HISTORY: 'Rate History',
  },
  LABELS: {
    SELECTED_CURRENCY: 'Selected Currency: ',
    BASE_RATE: 'Base Rate',
    CURRENCY: 'Currency',
    COMPANY_RATE: 'Company Rate',
    MY_STORE_RATE: 'My Store Rate',
    SELL_RATE: 'Sell Rate',
    PER_SPREAD: '% Spread',
    SPREAD_RATE: 'Spread Rate',
    BUY_RATE: 'Buy Rate',
    RESET: 'Reset',
    SUBMIT: 'Submit',
  },
  BASE_RATE_CURRENCY: 'GBP',
};
