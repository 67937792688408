import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Col, Row, Form, Input, Spin } from 'antd';

import { actions as valuationActions } from 'redux/reducers/valuationCenter';
import { postWorkQueueItemsList } from 'services/valuationCenter';
import { GET_VALUATION_ITEM_DETAILS } from 'action_creators/valuationCenter';

import {
  BUTTON_LABELS,
  LABELS,
  VALUATION_HEADERS,
  VALUATION_VIEW_TYPE,
  WORKQUEUE_TABS,
} from '../constants';
import { FNS_DATE_FORMAT } from 'globalConstants';

import Select from 'components/common/Select';
import CardTabs from 'components/common/CardTabs';
import DatePickerMUI from 'components/common/DatePickerMUI';
import { Button } from 'components/common/Button';
import WorkQueueSummary from './WorkQueueSummary';
import { format } from 'date-fns';

const StyledHeaderRow = styled(Row)`
  padding: 15px 20px 20px;

  font-weight: var(--font-weight-500);
  font-size: var(--font-size-18);
  line-height: 21px;
`;

const StyledSearch = styled(Input.Search)`
  &.barcode-search {
    & .ant-input-wrapper {
      width: 225px !important;

      & .ant-input-search-button {
        width: 50px;
        height: 42px !important;
        color: var(--white) !important;
        background: var(--blue);
        border-radius: 0px 3px 3px 0px;
      }
    }
  }
`;

const StyledLabel = styled.span`
  font-weight: 500;
  display: flex;
  padding: 10px 15px;
`;

const StyledButton = styled(Button)`
  height: 40px;
  margin-left: 20px;
`;

const ValuationWorkQueue = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const {
    user: { selectedStore, user },
    valuationCenter: { isLoading, liveValuationCount },
  } = useSelector((state: any) => {
    return { user: state.user, valuationCenter: state.valuationCenter };
  });

  const [activeTab, setActiveTab] = useState<any>(WORKQUEUE_TABS[0]);
  const [workqueueList, setWorkqueueList] = useState<any>([]);

  useEffect(() => {
    onResetClick();
    getValuationList();
  }, [activeTab]); // eslint-disable-line react-hooks/exhaustive-deps

  const getValuationList = async () => {
    const payload = {
      storeId: selectedStore?.storeId,
      workqueueServiceId: 1,
      workqueueRequestType: activeTab?.requestId,
      referenceNumber: form.getFieldValue('reference') || '',
      workqueueServiceStatusId: form.getFieldValue('statusType') || '',
      fromDate: form.getFieldValue('fromDate')
        ? format(new Date(form.getFieldValue('fromDate')), FNS_DATE_FORMAT)
        : '',
      toDate: form.getFieldValue('toDate')
        ? format(new Date(form.getFieldValue('toDate')), FNS_DATE_FORMAT)
        : '',
    };

    try {
      dispatch(valuationActions.setIsLoading(true));

      const response = await postWorkQueueItemsList(payload);
      if (response?.data) {
        dispatch(valuationActions.setIsLoading(false));
        dispatch(
          valuationActions.setLiveValuationCount(
            response?.data?.totalLiveRecordCount
          )
        );
        setWorkqueueList(response?.data?.workqueueItems);
      }
    } catch (e: any) {
      dispatch(valuationActions.setIsLoading(false));
      console.log(e);
    }
  };

  const requestValuationDetails = async (row: any) => {
    const params = {
      storeId: row?.storeId,
      workqueueRequestId: row?.workqueueRequestId,
      agentId: user?.userId,
      agentName: user?.displayName,
      viewType: VALUATION_VIEW_TYPE.VIEW_ITEM,
    };

    dispatch({
      type: GET_VALUATION_ITEM_DETAILS,
      payload: { params, viewType: VALUATION_VIEW_TYPE.VIEW_ITEM },
    });
  };

  const onMenuClick = (key: any) => {
    setActiveTab(WORKQUEUE_TABS[key]);
  };

  const onSearchClick = () => {
    getValuationList();
  };

  const onResetClick = () => {
    form.resetFields();
  };

  return (
    <>
      <StyledHeaderRow>
        <Col span={24}>{VALUATION_HEADERS.VAL_REQUEST}</Col>
      </StyledHeaderRow>
      <Row>
        <Col span={24}>
          <CardTabs
            onMenuClick={onMenuClick}
            tabslist={WORKQUEUE_TABS}
            recordCount={[liveValuationCount]}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form
            name="stockTransferManagerForm"
            form={form}
            onFinish={onSearchClick}
            className="valuation-work-queue-form"
            autoComplete="off"
          >
            <Row style={{ padding: '15px 10px' }}>
              <StyledLabel>{LABELS.SEARCH_BY}</StyledLabel>
              <Form.Item name="reference">
                <StyledSearch
                  className="input-control barcode-search"
                  placeholder="Reference/Customer"
                  onSearch={onSearchClick}
                />
              </Form.Item>
              <StyledLabel style={{ marginLeft: '20px' }}>
                {LABELS.FILTER_BY}
              </StyledLabel>
              <Form.Item name="statusType">
                <Select
                  style={{ width: '125px' }}
                  options={[{ value: 'all', label: 'All Status' }]}
                />
              </Form.Item>

              <div className="datepicker-group">
                <Form.Item name="fromDate">
                  <DatePickerMUI
                    placeholder="From: DD/MM/YYYY"
                    onChange={(date: any) =>
                      form.setFieldsValue({ fromDate: date })
                    }
                  />
                </Form.Item>
                <Form.Item name="toDate">
                  <DatePickerMUI
                    placeholder="From: DD/MM/YYYY"
                    onChange={(date: any) =>
                      form.setFieldsValue({ toDate: date })
                    }
                  />
                </Form.Item>
              </div>
              <StyledButton
                //   loading={searching}
                itemProp="secondary"
                htmlType="submit"
              >
                {BUTTON_LABELS.SEARCH}
              </StyledButton>
              <StyledButton itemProp="secondary" onClick={onResetClick}>
                {BUTTON_LABELS.RESET}
              </StyledButton>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row>
        <Spin spinning={isLoading}>
          <div className="table-wrapper">
            <WorkQueueSummary
              workQueue={workqueueList}
              onViewClick={requestValuationDetails}
            />
          </div>
        </Spin>
      </Row>
    </>
  );
};

export default ValuationWorkQueue;
