import React, { useCallback } from 'react';
import { Col, Modal, Row } from 'antd';
import styled from 'styled-components';
import { Button } from 'components/common/Button';

const StyledModal = styled(Modal)`
  & .ant-modal-title {
    color: var(--red);
    text-align: center;
  }
  & .ant-modal-body {
    & p {
      font-size: var(--font-size-16);
    }
    text-align: center;
  }
  & .ant-modal-footer {
    text-align: center;
  }
`;

const StyledMessage = styled.div`
  font-size: var(--font-size-16);
  text-align: center;
`;

interface AttentionPopupProps {
  visible: boolean;
  setAttentionPopupVisible: () => void;
  agreementData: any;
  message: any;
  onAttentionPopupOk: (agreementData: any) => void;
}

const AttentionPopup = ({
  visible,
  setAttentionPopupVisible,
  agreementData,
  message,
  onAttentionPopupOk,
}: AttentionPopupProps) => {
  const onOkClick = useCallback(() => {
    setAttentionPopupVisible();
    onAttentionPopupOk(agreementData);
  }, [onAttentionPopupOk, setAttentionPopupVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StyledModal
        title="Attention!"
        open={visible}
        width={470}
        destroyOnClose={true}
        maskClosable={false}
        closable={false}
        footer={[
          <Button itemProp="secondary" key="attentionOk" onClick={onOkClick}>
            OK
          </Button>,
        ]}
        centered
      >
        <Row>
          <Col span={24}>
            <StyledMessage>{message}</StyledMessage>
          </Col>
        </Row>
      </StyledModal>
    </>
  );
};

export default AttentionPopup;
