import React, { useState, useReducer } from 'react';
import styled, { css } from 'styled-components';
import { Plugin, Getter } from '@devexpress/dx-react-core';
import {
  VirtualTableState,
  IntegratedSorting,
  SortingState,
  DataTypeProvider,
  RowDetailState,
  SelectionState,
  IntegratedSelection,
  EditingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable,
  Table,
  TableHeaderRow,
  TableSelection,
  TableRowDetail,
  TableInlineCellEditing,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui';
import {
  customerReducer,
  initialState,
  START_LOADING,
} from '../../customer/customerList/reducer/index';

import './index.less';
import { NO_ITEMS, URL_TYPE } from '../../../globalConstants';
import FormattedComponent from './formatter/index';
import {
  PlusCircleFilled,
  MinusCircleFilled,
} from '@ant-design/icons/lib/icons';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';

const StyledGrid = styled(Grid)`
  height: calc(50vh - 10px);
`;

const StyledTableHeader = styled(Table.TableHead)<{ $background: string }>`
  ${(props) =>
    props.$background &&
    css`
      & th {
        background-color: var(--${props.$background});
      }
    `}
`;

const getRowId = (row: any) =>
  row.id ||
  row.key ||
  row.itemId ||
  row.stockItemId ||
  row.retailTransactionId ||
  row.retailTransactionDetailId ||
  row.serviceId ||
  row.transactionId ||
  row.currencyDenominationId ||
  row.fxTransferId ||
  row.userId ||
  row.tillUserId ||
  row.basketId ||
  row.auctionId ||
  row.tillOperationId ||
  row.loanId ||
  row.denomination ||
  row.metalId ||
  row.referenceNumber ||
  row.serviceReference ||
  row.thirdPartyChequeId ||
  row.loanId ||
  row.chequeNumber ||
  row.storeNumber ||
  row.customerRequestReviewId ||
  row.transferId;

const CommonDataTypeProvider = (props: any) => {
  return (
    <DataTypeProvider
      formatterComponent={(props) => <FormattedComponent props={props} />}
      {...props}
    />
  );
};

// to move the expandable icon to right most column
const tableColumnsWithLastDetailColumn = ({ tableColumns }: any) => {
  const detailColumnIndex = tableColumns.findIndex(
    (col: any) => col.type === TableRowDetail.COLUMN_TYPE
  );
  if (detailColumnIndex > -1) {
    const detailColumn = tableColumns.splice(detailColumnIndex, 1)[0];
    return [...tableColumns, detailColumn];
  }
  return tableColumns;
};

//Component for rendering the plus(+) icon on grid row expansion on the right most column.
//If icon is needed on left most column, donot call this component
const DetailRowColumnOrder = () => (
  <Plugin dependencies={[{ name: 'TableRowDetail' }]}>
    <Getter name="tableColumns" computed={tableColumnsWithLastDetailColumn} />
  </Plugin>
);
// custom icon for expandable rows
const toggleCellComponent = ({ onToggle, expanded }: any) => (
  <td className="toggle-icon">
    <span onClick={onToggle}>
      {!expanded ? (
        <PlusCircleFilled className="expandable-icon" />
      ) : (
        <MinusCircleFilled className="expandable-icon" />
      )}
    </span>
  </td>
);

const loadingSpin = ({ colSpan }: any) => {
  return (
    <td colSpan={colSpan} className="no-data-row">
      <Spin />
    </td>
  );
};

const VirtualGrid = ({
  columnsProps,
  tableColumnExtensionsProps,
  urlType,
  virtualTable = false,
  virtualScroll = false,
  sortingStateColumnExtensions,
  showSortingControls = false,
  rowData,
  formattedColumns = [],
  expandableComponent, // expandable row component
  rowSelectionEnable,
  onSelectionChange,
  cellComponent,
  selection, // contains row index which are selected
  selectByRowClick = false, //select row on click
  highlightRow = false, // highlight selected row
  showSelectAll = true, // show Select All Checkbox
  showSelectionColumn = true, // show checkboxes for selection
  headerBackground = null,
  detailRowIconPlacement = false, //false - icon on right , true - icon on left
  rowEditingEnabled = false, // allows editind a row's column input entry
  createRowChange = () => { /* do nothing */ }, // allows to edit a row's column - used when rowEditingEnabled is true
  commitEditChanges = () => { /* do nothing */ }, // allows to apply the edited changes - used when rowEditingEnabled is true
  columnsToEdit = [], // column Names to be edited
  EditProvider = () => { /* do nothing */ }, // edit formatter component - extend this for your editable columns
  hiddenColumnNames = [], // pass column names to array to hide those columns
  setHiddenColumnNames = () => { /* do nothing */ },
  customSelectionRequired = false, // enable disable selection for particular rows based on condition
  customTableSelection = <></>, // enable disable selection for particular rows based on condition
  customIntegratedSelection = <></>, // enable disable selection for particular rows based on condition
  customNoDataMessage = '',
}: any) => {
  const {
    retail: { isLoading },
  } = useSelector((state: any) => {
    return {
      retail: state.retail,
      customer: state.customer,
    };
  });

  const [state, dispatch] = useReducer(customerReducer, initialState);
  const [columns] = useState([...columnsProps]);
  const [tableColumnExtensions] = useState([...tableColumnExtensionsProps]);
  const [formattedColumn] = useState([...formattedColumns]);
  const getRemoteRows = (requestedSkip: number, take: number) => {
    dispatch({ type: START_LOADING, payload: { requestedSkip, take } });
  };

  const { rows, skip, totalCount, loading, pageSize } = state;

  const tableHeaderTemplatre = (props: any) => (
    <StyledTableHeader {...props} $background={headerBackground} />
  );

  const noDataCellComponent = ({ colSpan }: any) => (
    <td colSpan={colSpan} className="no-data-row">
      {customNoDataMessage ? <>{customNoDataMessage}</> : NO_ITEMS}
    </td>
  );

  return (
    <span id="dx-grid">
      <StyledGrid
        rows={urlType === URL_TYPE.NO_URL ? rowData : rows}
        columns={columns}
        getRowId={getRowId}
      >
        <SortingState columnExtensions={sortingStateColumnExtensions} />
        <CommonDataTypeProvider for={formattedColumn} />
        {rowEditingEnabled ? <EditProvider for={columnsToEdit} /> : null}

        {virtualTable ? (
          <VirtualTableState
            infiniteScrolling={false}
            loading={loading}
            totalRowCount={totalCount}
            pageSize={pageSize}
            skip={skip}
            getRows={getRemoteRows}
          />
        ) : null}
        <IntegratedSorting />
        <RowDetailState />
        <SelectionState
          selection={selection}
          onSelectionChange={(selectedRows) => onSelectionChange(selectedRows)}
        />
        {customSelectionRequired ? (
          customIntegratedSelection()
        ) : (
          <IntegratedSelection />
        )}

        {/* only used when you want to edit a row's column  */}
        {rowEditingEnabled ? (
          <>
            <EditingState
              onCommitChanges={commitEditChanges}
              createRowChange={createRowChange}
              columnExtensions={tableColumnExtensions}
            />
          </>
        ) : null}

        {(virtualTable || virtualScroll) && cellComponent ? (
          <VirtualTable
            height="auto"
            columnExtensions={tableColumnExtensions}
            noDataCellComponent={
              isLoading || loading ? loadingSpin : noDataCellComponent
            }
            cellComponent={cellComponent}
          />
        ) : virtualTable || virtualScroll ? (
          <VirtualTable
            height="auto"
            columnExtensions={tableColumnExtensions}
            noDataCellComponent={
              isLoading || loading ? loadingSpin : noDataCellComponent
            }
          />
        ) : cellComponent ? (
          <Table
            headComponent={tableHeaderTemplatre}
            columnExtensions={tableColumnExtensions}
            noDataCellComponent={
              isLoading || loading ? loadingSpin : noDataCellComponent
            }
            cellComponent={cellComponent}
          />
        ) : (
          <Table
            headComponent={tableHeaderTemplatre}
            columnExtensions={tableColumnExtensions}
            noDataCellComponent={
              isLoading || loading ? loadingSpin : noDataCellComponent
            }
          />
        )}
        <TableHeaderRow showSortingControls={showSortingControls} />
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        {rowSelectionEnable ? (
          <TableSelection
            selectByRowClick={selectByRowClick}
            highlightRow={highlightRow}
            showSelectionColumn={showSelectionColumn}
            showSelectAll={showSelectAll}
          />
        ) : customSelectionRequired ? (
          customTableSelection()
        ) : (
          ''
        )}
        {expandableComponent && (
          <TableRowDetail
            contentComponent={expandableComponent}
            toggleCellComponent={toggleCellComponent}
          />
        )}
        {expandableComponent && !detailRowIconPlacement && (
          <DetailRowColumnOrder />
        )}

        {/* only used when you want to edit a row's column */}
        {rowEditingEnabled ? (
          <>
            <TableInlineCellEditing
              selectTextOnEditStart={true}
              startEditAction={'click'}
            />
          </>
        ) : null}
      </StyledGrid>
    </span>
  );
};

export default VirtualGrid;
