import {
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Button, Space } from 'antd';
import React from 'react';

function WebcamIcons({
  isDisableIcons = true,
  onRotate = (rotateTo: number, event: any) => { /* do nothing */ },
  onZoom = (zoomTo: number, event: any) => { /* do nothing */ },
}) {
  return (
    <Space>
      <Button
        type="text"
        onClick={onRotate.bind(null, 30)}
        title="Rotate Right Side"
        disabled={isDisableIcons}
      >
        <RotateRightOutlined style={{ fontSize: '18px' }} />
      </Button>
      <Button
        type="text"
        onClick={onRotate.bind(null, -30)}
        title="Rotate Left Side"
        disabled={isDisableIcons}
      >
        <RotateLeftOutlined style={{ fontSize: '18px' }} />
      </Button>

      <Button
        type="text"
        onClick={onZoom.bind(null, -0.1)}
        title="Zoom Out"
        disabled={isDisableIcons}
      >
        <ZoomOutOutlined style={{ fontSize: '18px' }} />
      </Button>
      <Button
        type="text"
        onClick={onZoom.bind(null, 0.1)}
        title="Zoom In"
        disabled={isDisableIcons}
      >
        <ZoomInOutlined style={{ fontSize: '18px' }} />
      </Button>
    </Space>
  );
}

export default WebcamIcons;
