import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import VirtualGrid from 'components/common/virtualGrid';
import FooterButtons from 'components/common/footerButtons';

import { FNS_DATE_FORMAT_WITH_TIME, GRID_NO_URL } from 'globalConstants';
import { useDispatch, useSelector } from 'react-redux';
import { dateDisplayFormat } from 'utils/util';
import { Button } from 'components/common/Button';
import { PROCESS_CONSUMERDUTY_REQUEST } from 'action_creators/consumerDuty';
import { BUTTON_LABELS, STATUS_LABELS, VIEW_TYPE } from '../constants';
import { actions as consumerDutyActions } from 'redux/reducers/consumerDuty';

interface ManagedCasesProps {
  onExit: () => void;
}

const ManagedCases = ({ onExit }: ManagedCasesProps) => {
  const [columns] = useState([
    {
      name: 'reference',
      title: 'Reference',
      getCellValue: (row: any) => row?.reference,
    },
    {
      name: 'source',
      title: 'Source',
      getCellValue: (row: any) => row?.storeName,
    },
    {
      name: 'serviceName',
      title: 'Service',
      getCellValue: (row: any) => row?.serviceName,
    },
    {
      name: 'customerName',
      title: 'Customer Name',
      getCellValue: (row: any) => row?.customerName,
    },
    {
      name: 'createdTimeStamp',
      title: 'Created Date and Time',
      getCellValue: (row: any) =>
        dateDisplayFormat(row?.createdOn, FNS_DATE_FORMAT_WITH_TIME),
    },
    {
      name: 'subject',
      title: 'Subject',
      getCellValue: (row: any) => row?.subject,
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row: any) => row?.statusName,
    },
    {
      name: 'actions',
      title: 'Actions',
      getCellValue: (row: any) => (
        <Button itemProp="secondary" onClick={() => onActionButtonClick(row)}>
          {row?.statusName === STATUS_LABELS.COMPLETED
            ? BUTTON_LABELS.REVIEW
            : BUTTON_LABELS.WORK_IT}
        </Button>
      ),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'reference', width: 120 },
    { columnName: 'source', width: 220 },
    { columnName: 'serviceName', width: 180 },
    { columnName: 'customerName', width: 250 },
    { columnName: 'createdTimeStamp', width: 250 },
    { columnName: 'subject', width: 320 },
    { columnName: 'status', width: 150 },
    { columnName: 'actions', width: 140, align: 'center' },
  ]);

  const { managedCases, selectedStore, user } = useSelector(
    (state: any) => state.user
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onActionButtonClick = (row: any) => {
    if (!row?.customerId) return;

    const params = {
      storeId: selectedStore?.storeId,
      taskId: row?.id,
      agentId: user?.userId,
      agentName: user?.displayName,
      viewType: VIEW_TYPE.VIEW_TASK_DETAILS,
    };

    dispatch({
      type: PROCESS_CONSUMERDUTY_REQUEST,
      payload: {
        taskDetails: row,
        params,
        navigate,
      },
    });
    dispatch(consumerDutyActions.setIsManagedCase(true));
  };

  return (
    <>
      <div className="manage-case-grid-wrapper">
        <VirtualGrid
          virtualScroll
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={managedCases}
        />
      </div>

      <FooterButtons
        isExitOnly={true}
        enableExitPopup={false}
        onConfirmExitClick={onExit}
      />
    </>
  );
};

export default ManagedCases;
