import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as PICKUP_ACTIONS from 'action_creators/pickupRenew';
import {
  getPaidPledgePaymentDetails,
  calculatePledgeAgreementAmount,
  calculatePurchaseReturnAmt,
  getAffidavitReasons,
} from 'services/pledge';
import { downloadContract } from 'services/checkout';
import { sendToPrinter } from 'services/user';
import { BASKET_SERVICE_TYPE, RESPONSE_STATUS } from 'globalConstants';

import { actions } from 'redux/reducers/pickupRenew/index';
import { actions as checkoutActions } from 'redux/reducers/checkout';
import { actions as customerActions } from 'redux/reducers/customer';
import { cacheStaticData } from 'utils/cacheServices';

export function* getPaidPledgePaymentSaga(action) {
  try {
    const {
      payload: { params },
    } = action;
    yield put(actions.setApiLoading(true));
    const payload = yield call(getPaidPledgePaymentDetails, params.loanId);
    if (payload?.data) {
      yield put(actions.getPaidPledgePayment(payload.data?.paymentDetails));
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* watchPaidPaymentSaga() {
  yield takeEvery(
    PICKUP_ACTIONS.GET_PAID_PLEDGE_DETAILS,
    getPaidPledgePaymentSaga
  );
}

/**
 * this saga handles the POST loan calculations for Pickup, Renew, Partial Pay, Paydown request
 * (1)post loan calculations - New Loan, To Take
 * (2) handle response
 */
export function* calculatePledgePickupAmountSaga(action) {
  try {
    const {
      payload: { params },
    } = action;
    const selectedActionType = params.agreement.selectedActionType;
    yield put(actions.setLoading(params.agreement.id));
    const payload = yield call(
      calculatePledgeAgreementAmount,
      params.agreement
    );
    if (payload?.data) {
      payload.data = { selectedActionType, ...payload.data };
      yield put(actions.updatePledgeAgreement(payload.data));
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* watchPledgePickupAmountSaga() {
  yield takeEvery(
    PICKUP_ACTIONS.CALCULATE_PLEDGE_AGREEMENT_LOAN_AMOUNT,
    calculatePledgePickupAmountSaga
  );
}

export function* getAffidavitReasonSaga() {
  try {
    const payload = yield call(cacheStaticData, getAffidavitReasons);
    if (payload?.data) {
      yield put(actions.setAffidavitReasons(payload.data?.affidavitReasons));
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* watchAffidavitReasonSaga() {
  yield takeEvery(PICKUP_ACTIONS.GET_AFFIDAVIT_REASONS, getAffidavitReasonSaga);
}

export function* getCalculatedPurchaseReturnSaga(action) {
  try {
    const payload = yield call(
      calculatePurchaseReturnAmt,
      action.payload.updatedRow
    );
    if (payload?.data) {
      yield put(actions.updatePurchaseReturn(payload.data));
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* printContractsSaga(action) {
  const {
    payload: { params, serviceId, serviceType },
  } = action;
  const { printContractCounter } = yield select((state) => state.pickupRenew);
  const { pledgeContractPrintAttempts } = yield select(
    (state) => state.checkout
  );

  yield put(checkoutActions.setPledgeContractPrintAttempts(serviceId));
  try {
    yield put(customerActions.setIsDocumentPrinting(true));

    const payloadData = yield call(downloadContract, params);
    if (payloadData?.data) {
      let isPrinterError = false;
      for (let i = 0; i <= 1; i++) {
        const printerStatus = yield call(
          sendToPrinter,
          payloadData,
          'printcontract',
          '_PrintContract'
        );

        if (printerStatus === RESPONSE_STATUS.SERVICE_UNAVAILABLE)
          isPrinterError = true;
      }
      yield put(customerActions.setIsDocumentPrinting(false));

      if (isPrinterError)
        return yield put(checkoutActions.setIsPrinterError(true));

      yield put(actions.resetPrintContractCounter());

      if (serviceType === BASKET_SERVICE_TYPE.PLEDGE) {
        yield put(
          checkoutActions.updatePledgeContractPrintStatusCollection(serviceId)
        );
      }
      if (serviceType === BASKET_SERVICE_TYPE.PICKUPRENEW) {
        yield put(
          checkoutActions.updatePickupContractPrintStatusCollection(serviceId)
        );
      }
    }
    if (payloadData?.errors) {
      yield put(checkoutActions.setContratPrintingError(true));
    }
  } catch (e) {
    yield put(customerActions.setIsDocumentPrinting(false));

    const idValidReAttempt =
      printContractCounter > 0 &&
      (pledgeContractPrintAttempts[serviceId] < 3 ||
        pledgeContractPrintAttempts[serviceId] === undefined);

    if (e?.response?.status === 404 && idValidReAttempt) {
      //Handle if print contract is not ready in back end so again call for printing
      yield put(actions.decrementPrintContractCounter());
      yield put({
        type: PICKUP_ACTIONS.PRINT_CONTRACTS,
        payload: { params: params, serviceType, serviceId },
      });
    } else {
      yield put(actions.resetPrintContractCounter());
      yield put(checkoutActions.setContratPrintingError(true));
    }
  }
}

export function* watchPurchaseReturnAmountSaga() {
  yield takeEvery(
    PICKUP_ACTIONS.CALCULATE_PURCHASE_RETURN_AMOUNT,
    getCalculatedPurchaseReturnSaga
  );
}

export function* watchPrintContractsSaga() {
  yield takeEvery(PICKUP_ACTIONS.PRINT_CONTRACTS, printContractsSaga);
}
