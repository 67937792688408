import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import styled from 'styled-components';

import { isEmpty } from 'lodash';

import Footer from './footer';
import { actions as consumerDutyActions } from 'redux/reducers/consumerDuty';
import ManageServicesPopup from './manageServicesPopup';
import NewPledgePermissionPopup from './newPledgePermissionPopup';
import ServiceManagerTable from './serviceManagerTable';

import { GET_LOCKED_PLEDGE_DETAILS } from 'action_creators/consumerDuty';
import CustomerPopup from 'components/customer/CustomerPopup';

interface ServiceManagerProps {
  onCaseExit: () => void;
}

const StyledCol = styled(Col)`
  padding: 15px 20px;
`;

const StyledTableRow = styled(Row)`
  height: calc(100vh - 250px);
`;

const ServiceManager = ({ onCaseExit }: ServiceManagerProps) => {
  const {
    customer: { customer },
    consumerDuty: {
      selectedTaskOwner,
      isLoading,
      lockedPledgeDetails,
      loanPrivileges,
      isCustomerIdentityVerified,
    },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
      consumerDuty: state.consumerDuty,
    };
  });

  const dispatch = useDispatch();

  const [isManageServicesPopupVisible, setManageServicesPopupVisible] =
    useState<boolean>(false);
  const [isCustomerPopupVisible, setCustomerPopupVisible] =
    useState<boolean>(false);
  const [
    isNewPledgePermissionPopupVisible,
    setNewPledgePermissionPopupVisible,
  ] = useState<boolean>(false);

  const onCustomerPopupOkClick = () => {
    setManageServicesPopupVisible(true);
    setCustomerPopupVisible(false);
    dispatch(consumerDutyActions.setIsCustomerIdentityVerified(true));
  };
  const onCustomerPopupCancelClick = () => {
    setCustomerPopupVisible(false);
  };

  useEffect(() => {
    if (isEmpty(loanPrivileges)) getRenewalPledgeHistory();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getRenewalPledgeHistory = () => {
    dispatch({
      type: GET_LOCKED_PLEDGE_DETAILS,
      payload: {
        customerId: customer?.customerId,
        team: selectedTaskOwner,
      },
    });
  };

  return (
    <>
      <StyledTableRow>
        <StyledCol span={24}>
          <ServiceManagerTable
            loading={isLoading}
            pledgeSummary={lockedPledgeDetails}
          />
        </StyledCol>
      </StyledTableRow>
      <Row className="footer-wrapper">
        <Footer
          onManageServicesClick={() =>
            isCustomerIdentityVerified
              ? setManageServicesPopupVisible(true)
              : setCustomerPopupVisible(true)
          }
          onNewPledgePermissionClick={() =>
            setNewPledgePermissionPopupVisible(true)
          }
          onExitButtonClick={onCaseExit}
        />
      </Row>
      {isManageServicesPopupVisible && (
        <ManageServicesPopup
          open={isManageServicesPopupVisible}
          pledgeSummary={lockedPledgeDetails}
          setManageServicesPopupVisible={() =>
            setManageServicesPopupVisible(false)
          }
        />
      )}
      {isNewPledgePermissionPopupVisible && (
        <NewPledgePermissionPopup
          open={isNewPledgePermissionPopupVisible}
          setNewPledgePermissionPopupVisible={() =>
            setNewPledgePermissionPopupVisible(false)
          }
        />
      )}
      {isCustomerPopupVisible && (
        <CustomerPopup
          customer={customer}
          onCustomerPopupCancel={onCustomerPopupCancelClick}
          onCustomerPopupOk={onCustomerPopupOkClick}
          isPopupFromManagedServices={true}
        />
      )}
    </>
  );
};

export default ServiceManager;
