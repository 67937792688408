import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Spin, Form, Input } from 'antd';
import { Button } from 'components/common/Button';

import { TRAVEL_MONEY_RATES } from 'components/admin/constants';
import { CURRENCIES_REQUEST } from 'action_creators/travelmoney';
import {
  GET_TRAVEL_MONEY_RATES,
  GET_TRAVEL_MONEY_RATES_HISTORY,
  CREATE_TRAVEL_MONEY_RATES,
} from 'action_creators/admin';
import { actions } from 'redux/reducers/admin';

import Select from 'components/common/Select';
import FooterButtons from 'components/common/footerButtons';
import RateHistory from './rateHistory';
import { toFixedNoRound } from 'utils/util';
import TextArea from 'components/common/TextArea';

const StyledCol = styled(Col)`
  border-right: 1px solid var(--linkwater);
  margin-right: 25px;
`;

const StyledRow = styled(Row)`
  margin-block-end: 10px;
  margin-top: 10px;
`;

const StyledSpan = styled.span`
  display: inline-block;
  padding-top: 32%;
`;

const BoldLabelSpan = styled.span`
  font-weight: bold;
  font-size: 16px !important;
`;

const StyledButton = styled(Button)`
  position: absolute;
  right: 0px;
`;

const StyledTableWrapper = styled.div`
  height: 55vh;
`;

const TravelMoneyRates = () => {
  const {
    admin: {
      loading,
      gridLoading,
      selectedUser,
      travelMoneyRateHistory,
      travelMoneyRates,
    },
    tm: { currencies },
  } = useSelector((state: any) => {
    return {
      admin: state.admin,
      tm: state.tm,
    };
  });

  const [selectedCurrency, setSelectedCurrency] = useState<string>('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(actions.clearTravelMoneyRates());
    dispatch({
      type: CURRENCIES_REQUEST,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (travelMoneyRates) {
      form.setFieldsValue({
        baseRate: toFixedNoRound(travelMoneyRates?.rate, 5),
        sellRate: toFixedNoRound(travelMoneyRates?.sellRate, 5),
        sellRateStore: toFixedNoRound(travelMoneyRates?.myStoreSellRate, 5),
        spreadRateStore: toFixedNoRound(travelMoneyRates?.sellSpread, 5),
        buyRate: toFixedNoRound(travelMoneyRates?.buyRate, 5),
        buyRateStore: toFixedNoRound(travelMoneyRates?.myStoreBuyRate, 5),
        buySpreadRateStore: toFixedNoRound(travelMoneyRates?.buySpread, 5),
        notes: travelMoneyRates?.note,
      });
      dispatch({
        type: GET_TRAVEL_MONEY_RATES_HISTORY,
        payload: { CurrencyCode: selectedCurrency },
      });
    }
  }, [travelMoneyRates, form]); // eslint-disable-line react-hooks/exhaustive-deps

  const onExitClick = () => {
    navigate('/');
  };

  const onCurrencySelection = (value: string) => {
    dispatch({
      type: GET_TRAVEL_MONEY_RATES,
      payload: { CurrencyCode: value },
    });
    setSelectedCurrency(value);
  };

  const onResetForm = () => {
    form.resetFields();
    dispatch(actions.clearTravelMoneyRates());
    setSelectedCurrency('');
  };

  const onSubmmitForm = () => {
    if (selectedCurrency) {
      const params = {
        currencyCode: selectedCurrency,
        rate: form.getFieldValue('baseRate'),
        note: form.getFieldValue('notes'),
        buySpread: form.getFieldValue('buySpreadRateStore'),
        sellSpread: form.getFieldValue('spreadRateStore'),
        buyRate: form.getFieldValue('buyRateStore'),
        sellRate: form.getFieldValue('sellRateStore'),
      };
      dispatch({
        type: CREATE_TRAVEL_MONEY_RATES,
        payload: params,
      });
    }
  };

  const prefix = <span dangerouslySetInnerHTML={{ __html: '%' }}></span>;

  const onSellRateChange = () => {
    const sellRate = form.getFieldValue('sellRateStore');
    const sellSpread =
      Number(sellRate) * (1 - Number(travelMoneyRates?.sellSpread) / 100);
    form.setFieldsValue({
      spreadRateStore: toFixedNoRound(sellSpread, 5),
    });
  };

  const onSellSpreadRateChange = () => {
    const sellSpread = form.getFieldValue('spreadRateStore');
    const sellRate =
      Number(travelMoneyRates?.sellRate) * (1 - Number(sellSpread) / 100);

    form.setFieldsValue({
      sellRateStore: toFixedNoRound(sellRate, 5),
    });
  };

  const onBuyRateChange = () => {
    const buyRate = form.getFieldValue('buyRateStore');
    const buySpread =
      Number(buyRate) * (1 - Number(travelMoneyRates?.buySpread) / 100);
    form.setFieldsValue({
      buySpreadRateStore: toFixedNoRound(buySpread, 5),
    });
  };

  const onBuySpreadRateChange = () => {
    const buySpread = form.getFieldValue('buySpreadRateStore');
    const buyRate =
      Number(travelMoneyRates?.buyRate) * (1 - Number(buySpread) / 100);

    form.setFieldsValue({
      buyRateStore: toFixedNoRound(buyRate, 5),
    });
  };

  return (
    <Spin spinning={loading}>
      <div className="travel-money-rates-wrapper">
        <div className="main-wrapper">
          <Row>
            <StyledCol span={8}>
              <div className="left-wrapper">
                <Row>
                  <Col span={24} className="title">
                    {TRAVEL_MONEY_RATES.TITLES.TRAVEL_MONEY_RATES}
                  </Col>
                </Row>
                <Row>
                  <Col span={23}>
                    <Form form={form} layout="vertical" initialValues={{}}>
                      <StyledRow justify="space-between">
                        <Col span={24}>
                          <Form.Item
                            label={
                              <span>{TRAVEL_MONEY_RATES.LABELS.CURRENCY}</span>
                            }
                            name="currencyCode"
                            className="label-control"
                          >
                            <Select
                              onChange={onCurrencySelection}
                              options={currencies.map((el: any) => {
                                return {
                                  key: `from_${el?.currencyCode}`,
                                  value: el?.currencyCode,
                                  label: el?.currencyDescription,
                                  disabled:
                                    el?.currencyCode ===
                                    TRAVEL_MONEY_RATES.BASE_RATE_CURRENCY
                                      ? true
                                      : false,
                                };
                              })}
                            />
                          </Form.Item>
                        </Col>
                      </StyledRow>
                      <StyledRow justify="space-between">
                        <Col span={24}>
                          <Form.Item
                            label={
                              <span>{TRAVEL_MONEY_RATES.LABELS.BASE_RATE}</span>
                            }
                            name="baseRate"
                            className="label-control"
                          >
                            <Input
                              type="number"
                              className="input-control"
                              name="baseRateInput"
                              disabled={true}
                              autoComplete="off"
                            />
                          </Form.Item>
                        </Col>
                      </StyledRow>
                      <StyledRow justify="space-between">
                        <Col span={12}>
                          <span className="select-label">
                            {TRAVEL_MONEY_RATES.LABELS.COMPANY_RATE}
                          </span>
                        </Col>
                        <Col span={12}>
                          <span className="select-label">
                            {TRAVEL_MONEY_RATES.LABELS.MY_STORE_RATE}
                          </span>
                        </Col>
                      </StyledRow>

                      <Row justify="space-between">
                        <Col span={12}>
                          <StyledRow justify="space-around">
                            <Col span={11}>
                              <Form.Item
                                label={
                                  <span>
                                    {TRAVEL_MONEY_RATES.LABELS.SELL_RATE}
                                  </span>
                                }
                                name="sellRate"
                                className="label-control"
                              >
                                <Input
                                  className="input-control"
                                  name="sellRateInput"
                                  disabled={true}
                                  autoComplete="off"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={10}>
                              <StyledSpan>
                                {travelMoneyRates?.sellSpread
                                  ? travelMoneyRates?.sellSpread
                                  : '0.00'}
                                {TRAVEL_MONEY_RATES.LABELS.PER_SPREAD}
                              </StyledSpan>
                            </Col>
                          </StyledRow>
                        </Col>
                        <Col span={12}>
                          <StyledRow justify="space-between">
                            <Col span={11}>
                              <Form.Item
                                label={
                                  <span>
                                    {TRAVEL_MONEY_RATES.LABELS.SELL_RATE}
                                  </span>
                                }
                                name="sellRateStore"
                                className="label-control"
                              >
                                <Input
                                  onChange={onSellRateChange}
                                  className="input-control"
                                  name="sellRateStoreInput"
                                  autoComplete="off"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span>
                                    {TRAVEL_MONEY_RATES.LABELS.SPREAD_RATE}
                                  </span>
                                }
                                name="spreadRateStore"
                                className="label-control"
                              >
                                <Input
                                  onChange={onSellSpreadRateChange}
                                  className="input-control"
                                  name="spreadRateStoreInput"
                                  autoComplete="off"
                                  addonAfter={prefix}
                                />
                              </Form.Item>
                            </Col>
                          </StyledRow>
                        </Col>
                      </Row>

                      <Row justify="space-between">
                        <Col span={12}>
                          <StyledRow justify="space-around">
                            <Col span={11}>
                              <Form.Item
                                label={
                                  <span>
                                    {TRAVEL_MONEY_RATES.LABELS.BUY_RATE}
                                  </span>
                                }
                                name="buyRate"
                                className="label-control"
                              >
                                <Input
                                  className="input-control"
                                  name="buyRateInput"
                                  disabled={true}
                                  autoComplete="off"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={10}>
                              <StyledSpan>
                                {travelMoneyRates?.buySpread
                                  ? travelMoneyRates?.buySpread
                                  : '0.00'}
                                {TRAVEL_MONEY_RATES.LABELS.PER_SPREAD}
                              </StyledSpan>
                            </Col>
                          </StyledRow>
                        </Col>
                        <Col span={12}>
                          <StyledRow justify="space-between">
                            <Col span={11}>
                              <Form.Item
                                label={
                                  <span>
                                    {TRAVEL_MONEY_RATES.LABELS.BUY_RATE}
                                  </span>
                                }
                                name="buyRateStore"
                                className="label-control"
                              >
                                <Input
                                  onChange={onBuyRateChange}
                                  className="input-control"
                                  name="buyRateStoreInput"
                                  autoComplete="off"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span>
                                    {TRAVEL_MONEY_RATES.LABELS.SPREAD_RATE}
                                  </span>
                                }
                                name="buySpreadRateStore"
                                className="label-control"
                              >
                                <Input
                                  onChange={onBuySpreadRateChange}
                                  className="input-control"
                                  name="buySpreadRateStoreInput"
                                  autoComplete="off"
                                  addonAfter={prefix}
                                />
                              </Form.Item>
                            </Col>
                          </StyledRow>
                        </Col>
                      </Row>

                      <StyledRow justify="space-between">
                        <Col span={24}>
                          <Form.Item
                            label="Notes"
                            name="notes"
                            className="label-control"
                          >
                            <TextArea
                              rows={4}
                              autoComplete="off"
                              maxLength={100}
                            />
                          </Form.Item>
                        </Col>
                      </StyledRow>

                      <StyledRow justify="space-between">
                        <Col span={24}>
                          <Button
                            onClick={onResetForm}
                            itemProp="secondary"
                            key="reset"
                          >
                            {TRAVEL_MONEY_RATES.LABELS.RESET}
                          </Button>
                          <StyledButton
                            onClick={onSubmmitForm}
                            itemProp="secondary"
                            className="amount-add-button"
                            key="add"
                          >
                            {TRAVEL_MONEY_RATES.LABELS.SUBMIT}
                          </StyledButton>
                        </Col>
                      </StyledRow>
                    </Form>
                  </Col>
                </Row>
              </div>
            </StyledCol>
            <Col span={15}>
              <div className="right-wrapper">
                <Row>
                  <Col span={24} className="title">
                    {TRAVEL_MONEY_RATES.TITLES.RATE_HISTORY}
                  </Col>
                </Row>

                <Row style={{ padding: '20px 0' }}>
                  <BoldLabelSpan>
                    {TRAVEL_MONEY_RATES.LABELS.SELECTED_CURRENCY}
                    {selectedCurrency}
                  </BoldLabelSpan>
                  <Col span={24}>
                    <Spin spinning={gridLoading}>
                      <StyledTableWrapper>
                        <RateHistory rates={travelMoneyRateHistory} />
                      </StyledTableWrapper>
                    </Spin>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>

        <FooterButtons
          enableExtraClass
          isExitOnly={true}
          isDisabled={isEmpty(selectedUser)}
          enableExitPopup={false}
          onConfirmExitClick={onExitClick}
        />
      </div>
    </Spin>
  );
};

export default TravelMoneyRates;
