import React, { useState } from 'react';
import { Button } from 'components/common/Button';
import styled from 'styled-components';
import { Row, Col, Form, Space } from 'antd';
import { useDispatch } from 'react-redux';
import {
  BUTTON_LABEL,
  AMOUNT_LABEL,
  ERROR_MESSAGE,
} from 'components/payment/constants';
import { actions } from 'redux/reducers/payment';
import InputDecimal from 'components/common/InputDecimal';
import PaymentConfimationCodePopup from 'components/payment/paymentConfirmation/PaymentConfimationPopup';

const StyledText = styled.div`
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-500);
`;

const LayawayFundsInput = ({
  isPayment = false,
  totalAmount = 0.0,
  balanceAmount = 0.0,
  addonBefore = '£',
  isConfirmationRequired = false,
  paymentMode = '',
  availableLayawayFunds = 0.0,
  onAddPayment = (params: any) => {/* do nothing */},
}) => {
  const dispatch = useDispatch();
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState<boolean>(false);
  const [form] = Form.useForm();
  const prefix = (
    <span dangerouslySetInnerHTML={{ __html: addonBefore }}></span>
  );

  const onSubmit = () => {
    const amount = form.getFieldValue('amount');

    if (!+amount) return;
    const amt = +amount;
    const requestParams = {
      mode: paymentMode,
      amount: amt,
      key: Date.now(),
    };
    dispatch(actions.addAmounts(requestParams));
    onAddPayment(requestParams);
    form.resetFields();
    dispatch(actions.totalAmounts({ amount: amt }));
    form.setFieldsValue({
      amount: '',
    });
  };

  const onConfirmClick = () => {
    setIsOpenConfirmPopup(true);
  };

  const onConfimPopupCancelClick = () => {
    setIsOpenConfirmPopup(false);
  };

  const onConfimPopupOkClick = () => {
    setIsOpenConfirmPopup(false);
    onSubmit();
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={isConfirmationRequired ? onConfirmClick : onSubmit}
      >
        <Row align="middle" justify="start">
          <Col span={24}>
            <div className="available-funds">
              Available Funds: £{availableLayawayFunds}
            </div>

            <Form.Item
              validateTrigger="onChange"
              label={<StyledText>{AMOUNT_LABEL}</StyledText>}
              name="amount"
              className="label-control validation-class"
              rules={[
                () => ({
                  validator(_, value) {
                    if (+value > availableLayawayFunds) {
                      return Promise.reject(
                        ERROR_MESSAGE.VALIDATION_MESSAGE_LAYAWAY_INPUT
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Space>
                <InputDecimal
                  addonBefore={prefix}
                  className="input-control mr-0"
                  disabled={isPayment}
                  max={availableLayawayFunds}
                />

                <Button
                  disabled={balanceAmount === 0}
                  itemProp="secondary"
                  type="primary"
                  htmlType="submit"
                >
                  {BUTTON_LABEL.LAYAWAY_ADD}
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {isOpenConfirmPopup && (
        <PaymentConfimationCodePopup
          visible={isOpenConfirmPopup}
          setpaymentConfimationCodePopupVisible={onConfimPopupCancelClick}
          onOkClick={onConfimPopupOkClick}
        />
      )}
    </>
  );
};

export default LayawayFundsInput;
