import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Spin } from 'antd';

import { USER_MANAGER } from 'components/admin/constants';
import { FNS_DATE_FORMAT_WITH_TIME } from 'globalConstants';
import { format } from 'date-fns';

import {
  ADMIN_STORE_USERS,
  SAVE_USER_PERMISSIONS,
  ADMIN_GET_DEFAULT_PERMISSIONS,
} from 'action_creators/admin';

import Select from 'components/common/Select';
import FooterButtons from 'components/common/footerButtons';
import UserSummary from './userSummary';
import LoginDetails from './loginDetails';
import ApplicationPrivileges from './applicationPrivileges';

const StyledCol = styled(Col)`
  border-right: 1px solid var(--linkwater);
  margin-right: 25px;
`;

const StyledTableWrapper = styled.div`
  height: 39vh;
  border-bottom: 1px solid var(--mystic);
`;

const StyledLoginWrapper = styled.div`
  border: 1px solid var(--light-gray);
  border-radius: 5px;
  padding: 0 15px;
`;

const StyledLastLoginSpan = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const UserManager = () => {
  const {
    admin: {
      loading,
      gridLoading,
      selectedUser,
      assignedPolicies,
      assignedRoles,
      assignedStores,
      isSuccessMsgVisible,
      defaultPermissions,
      userPermissions,
      selectedGroup,
    },
    user: { selectedStore: userStore, user },
  } = useSelector((state: any) => {
    return {
      admin: state.admin,
      user: state.user,
    };
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedStore, setSelectedStore] = useState<any>('');
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  useEffect(() => {
    dispatch({
      type: ADMIN_GET_DEFAULT_PERMISSIONS,
      payload: { userId: user?.userId },
    });

    setSelectedStore(userStore.storeId);
  }, [user, userStore, dispatch]);

  useEffect(() => {
    if (isSuccessMsgVisible) setIsEditMode(false);
  }, [isSuccessMsgVisible]);

  useEffect(() => {
    setIsEditMode(false);
  }, [selectedUser]);

  const onStoreChange = (storeId: any) => {
    dispatch({
      type: ADMIN_STORE_USERS,
      payload: { storeId },
    });
  };

  const onEditDetailsClick = () => {
    if (!isEditMode) setIsEditMode(true);
    else {
      const newStores = assignedStores.map((storeId: any) => {
        return {
          storeId: storeId,
        };
      });
      const details = {
        employeeId: selectedUser?.userId,
        groupId: selectedGroup,
        roles: assignedRoles,
        policies: assignedPolicies,
        stores: newStores,
      };

      dispatch({
        type: SAVE_USER_PERMISSIONS,
        payload: { params: details },
      });
    }
  };

  const onExitClick = () => {
    navigate('/');
  };

  return (
    <Spin spinning={loading}>
      <div className="user-manager-wrapper">
        <div className="main-wrapper">
          <Row>
            <StyledCol span={12}>
              <div className="left-wrapper">
                <Row>
                  <Col span={24} className="title">
                    {USER_MANAGER.TITLES.USER_MANAGER}
                  </Col>
                </Row>
                <Row>
                  <Col span={10}>
                    <span className="select-label">
                      {USER_MANAGER.LABELS.STORE_FILTER}
                    </span>
                    <Select
                      className="store-select"
                      value={selectedStore}
                      onChange={onStoreChange}
                      options={defaultPermissions?.userStore?.map((el: any) => {
                        return {
                          value: el.storeId,
                          label: `${el.storeId} ${el.name}`,
                        };
                      })}
                    />
                  </Col>
                  <Col span={14}>
                    {defaultPermissions?.lastLogin ? (
                      <StyledLastLoginSpan>
                        Last login:{' '}
                        {format(
                          new Date(defaultPermissions?.lastLogin),
                          FNS_DATE_FORMAT_WITH_TIME
                        )}
                      </StyledLastLoginSpan>
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>

                <Row style={{ padding: '15px 0px 20px' }}>
                  <Col span={24}>
                    <Spin spinning={gridLoading}>
                      <StyledTableWrapper>
                        <UserSummary users={defaultPermissions?.storeUsers} />
                      </StyledTableWrapper>
                    </Spin>
                  </Col>
                </Row>

                <StyledLoginWrapper>
                  <LoginDetails
                    userPermissions={userPermissions}
                    isEditMode={isEditMode}
                  />
                </StyledLoginWrapper>
              </div>
            </StyledCol>
            <Col span={11}>
              <div className="right-wrapper">
                <ApplicationPrivileges
                  isEditMode={isEditMode}
                  allGroups={defaultPermissions?.groups}
                  userServiceList={defaultPermissions?.serviceList}
                  userPermissions={userPermissions}
                  userStores={defaultPermissions?.userStore}
                  userGroup={defaultPermissions?.userGroup}
                />
              </div>
            </Col>
          </Row>
        </div>

        <FooterButtons
          enableExtraClass
          isExitOnly={true}
          isDisabled={isEmpty(selectedUser)}
          enableExitPopup={false}
          onConfirmExitClick={onExitClick}
          proceedButtonText={isEditMode ? 'Save Changes' : 'Edit Details'}
          onProceedClick={onEditDetailsClick}
        />
      </div>
    </Spin>
  );
};

export default UserManager;
