import React, { useState, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import { Image, Spin, Popover } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { getImage } from 'services/customer';
import { getCustomerVulnerableStatus } from 'services/customer';
import { useSelector } from 'react-redux';
import { cacheCustomerStatus, cacheImage } from 'utils/cacheServices';
import './index.less';
import { getCustomerKycStatusIcon } from 'utils/util';

const StyledSpin = styled(Spin)`
  display: block;
  position: relative;
  align-items: center;
  top: calc(100% - 75%);
`;

const StyledCloseIcon = styled(CloseOutlined)`
  top: -10px;
  right: -14px;
  position: absolute;
  cursor: pointer;
  font-size: 14.41px;
  background: var(--white);
  box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
  border-radius: 100%;
  color: var(--gray);
  padding: 9px;
  z-index: 1;
`;

const StyledImage = styled(Image)<{ $borderRadius?: string }>`
  border-radius: ${(props) => props.$borderRadius};
  > img {
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: ${(props) => props.$borderRadius};
  }
`;

const StyledTick = styled.img`
  padding: 0px 0px 5px 5px;
  height: 20px;
  width: 20px;
  margin-bottom: 15px;
`;

const ImageLoader = ({
  customerId,
  classes,
  imageUrl,
  tip = '',
  fallbackImg = '',
  preview = false,
  borderRadius = null,
  onClick = () => {
    /* do nothing */
  },
  isLoadFromRedux = false,
  customPreview = false,
  isCaching = true,
  index,
  statusIcon = false,
}: any) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [imageData, setImageData] = useState<any>(undefined);
  const [profileStatus, setCustomerProfileStatus] = useState<string>('');

  const { isCustomerAddedToStopList } = useSelector(
    (state: any) => state.customer
  );

  useEffect(() => {
    setTimeout(() => {
      if (isVisible) {
        setIsVisible(false);
      }
    }, 10000);
  }, [isVisible]);

  useEffect(() => {
    if (isLoadFromRedux) setImageData(imageUrl);
    else if (imageUrl) loadImage();
    else setImageData(fallbackImg);
  }, [imageUrl, isLoadFromRedux]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isCustomerAddedToStopList) getCustomerProfileStatus(customerId);
  }, [isCustomerAddedToStopList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (customerId) getCustomerProfileStatus(customerId);
  }, [customerId]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadImage = async () => {
    //caching Image to reduce endpoint recalls
    if (isCaching) {
      const image = await cacheImage(imageUrl);
      setImageData(image);
    } else {
      try {
        const result: any = await getImage(imageUrl);
        if (result?.data) {
          const imgUrl = URL.createObjectURL(result.data);
          setImageData(imgUrl);
        }
      } catch (e: any) {
        setImageData(imageUrl);
        console.log(e);
        setImageData(fallbackImg);
      }
    }
  };

  const onCloseClick = () => {
    setIsVisible(false);
  };

  const getCustomerProfileStatus = async (customerParams: any) => {
    let customerStatus = '';
    if (isCaching) {
      customerStatus = await cacheCustomerStatus(customerParams);
    } else {
      const result = await getCustomerVulnerableStatus(customerParams);
      customerStatus = result?.data?.colorCode;
    }
    setCustomerProfileStatus(customerStatus);
  };

  const onVisibleChange = (visible: SetStateAction<boolean>) =>
    setIsVisible(visible);

  if (customPreview) {
    return (
      <Popover
        content={
          <div>
            <StyledCloseIcon onClick={onCloseClick} />
            <StyledImage
              preview={false}
              className={'mx-larger-avatar'}
              src={imageData}
              placeholder={imageUrl && <StyledSpin tip={tip} />}
              fallback={require(`../../../assets/images/customer-pic.svg`)}
            ></StyledImage>
          </div>
        }
        placement="right"
        overlayClassName="preview-image-loader"
        trigger={['hover', 'click']}
        destroyTooltipOnHide={true}
        open={isVisible}
        onOpenChange={onVisibleChange}
        key={index}
      >
        <StyledImage
          $borderRadius={borderRadius}
          preview={false}
          className={
            profileStatus
              ? `${classes} ${classes.trim()}-${profileStatus}`
              : classes
          }
          src={imageData}
          placeholder={imageUrl && <StyledSpin tip={tip} />}
          key={index}
          fallback={require(`../../../assets/images/${fallbackImg}`)}
        ></StyledImage>
        {statusIcon && (
          <StyledTick alt="" src={getCustomerKycStatusIcon(profileStatus)} />
        )}
      </Popover>
    );
  } else {
    return (
      <>
        <StyledImage
          $borderRadius={borderRadius}
          preview={preview}
          className={
            profileStatus
              ? `${classes} ${classes.trim()}-${profileStatus}`
              : classes
          }
          src={imageData}
          placeholder={!isLoadFromRedux && imageUrl && <StyledSpin tip={tip} />}
          fallback={require(`../../../assets/images/${fallbackImg}`)}
          onClick={onClick}
          key={index}
        ></StyledImage>
        {statusIcon && (
          <StyledTick alt="" src={getCustomerKycStatusIcon(profileStatus)} />
        )}
      </>
    );
  }
};

export default ImageLoader;
