import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { format } from 'date-fns';
import { FNS_DATE_FORMAT } from 'globalConstants';
import { Layout, Row, Col, Form, Select } from 'antd';

import { SEARCH_TRANSFER } from 'action_creators/inventoryManagement';
import { Button } from 'components/common/Button';
import { DATE_FORMAT_TWO_DIGIT_YEAR } from 'globalConstants';
import {
  STOCK_TRANSFER_LABELS,
  GENERIC_ERROR,
} from 'components/inventoryManagement/constants';
import { postCreateTransfer } from 'services/inventoryManagement';
import { actions } from 'redux/reducers/inventoryManagement';

import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import FooterButtons from 'components/common/footerButtons';
import TransferManagerSummary from './viewTransferManager/TransferManagerSummary';
import DatePickerMUI from 'components/common/DatePickerMUI';

const StyledContentLayout = styled(Layout)`
  background: var(--white);
  padding: 15px 30px;
  border-radius: 5px;
`;

const StyledTableWrapper = styled.div`
  height: calc(100vh - 390px);
`;

const StyledDateLabel = styled.span`
  font-weight: 500;
  display: flex;
  padding: 10px 32px;
`;

const StyledButton = styled(Button)`
  height: 40px;
  margin-left: 20px;
`;

interface TransferManagerProps {
  createEditTransferHandler: () => void;
}

const TransferManager = ({
  createEditTransferHandler,
}: TransferManagerProps) => {
  const {
    inventoryManagement: { submiting, searching, searchTransferList },
    user: { user, selectedStore },
  } = useSelector((state: any) => {
    return {
      inventoryManagement: state.inventoryManagement,
      user: state.user,
    };
  });

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    onSearchClick();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSearchClick = () => {
    const searchFromDate = form.getFieldValue('searchFromDate')
      ? format(new Date(form.getFieldValue('searchFromDate')), FNS_DATE_FORMAT)
      : '';
    const searchToDate = form.getFieldValue('searchToDate')
      ? format(new Date(form.getFieldValue('searchToDate')), FNS_DATE_FORMAT)
      : '';

    const params = {
      transferBatchReference: '',
      transferReference: '',
      transactionFromDate: searchFromDate,
      transactionToDate: searchToDate,
      transferStatus: form.getFieldValue('viewing'),
      skip: 0,
      take: 10,
    };

    dispatch({
      type: SEARCH_TRANSFER,
      payload: { params, transferStatus: form.getFieldValue('viewing') },
    });
  };

  const onCreateNewTransferClick = async () => {
    const params = {
      transferReference: '',
      sourceStoreId: selectedStore.storeId,
      destinationStoreId: -1,
      employeeId: user.employeeId,
      transferId: '0',
    };

    try {
      dispatch(actions.initSubmit(true));
      const response = await postCreateTransfer(params);
      if (response?.data) {
        dispatch(actions.initSubmit(false));
        dispatch(actions.setTransferParams(response.data));
        createEditTransferHandler();
      }
    } catch (e: any) {
      dispatch(actions.initSubmit(false));
      dispatch(actions.logError(e?.response?.data?.error || GENERIC_ERROR));
      dispatch(actions.setIsErrorVisible(true));
    }
  };

  const onExitClick = () => {
    navigate('/');
  };

  return (
    <>
      <StyledContentLayout>
        <Row className="title-row">
          <Col span={24}>
            <HelpPopoverInfo
              linkedID={''}
              position="rightTop"
              helpPosition="AFTER"
            >
              {STOCK_TRANSFER_LABELS.TITLES.TRANSFER_MANAGER}
            </HelpPopoverInfo>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form
              name="stockTransferManagerForm"
              form={form}
              onFinish={onSearchClick}
              autoComplete="off"
              className="stock-transfer-manager"
              initialValues={{
                viewing: 'active',
              }}
            >
              <Row style={{ padding: '15px 10px' }}>
                <Col span={4}>
                  <Form.Item
                    label={STOCK_TRANSFER_LABELS.TRANSFER_MANAGER.VIEWING}
                    name="viewing"
                    className="label-control"
                  >
                    <Select
                      className="input-control"
                      placeholder="Select Viewing"
                    >
                      <Select.Option value={'active'}>
                        Active Transfer
                      </Select.Option>
                      <Select.Option value={'closed'}>
                        Closed Transfer
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <StyledDateLabel>Filter by</StyledDateLabel>
                </Col>
                <Col span={3}>
                  <Form.Item
                    label={STOCK_TRANSFER_LABELS.TRANSFER_MANAGER.FROM}
                    name="searchFromDate"
                    className="label-control"
                  >
                    <DatePickerMUI
                      format={DATE_FORMAT_TWO_DIGIT_YEAR}
                      placeholder={DATE_FORMAT_TWO_DIGIT_YEAR}
                      onChange={(date: any) =>
                        form.setFieldsValue({ searchFromDate: date })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={3} offset={1}>
                  <Form.Item
                    label={STOCK_TRANSFER_LABELS.TRANSFER_MANAGER.TO}
                    name="searchToDate"
                    className="label-control"
                  >
                    <DatePickerMUI
                      format={DATE_FORMAT_TWO_DIGIT_YEAR}
                      placeholder={DATE_FORMAT_TWO_DIGIT_YEAR}
                      onChange={(date: any) =>
                        form.setFieldsValue({ searchToDate: date })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <StyledButton
                    loading={searching}
                    itemProp="secondary"
                    htmlType="submit"
                  >
                    Search
                  </StyledButton>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row>
          <StyledTableWrapper className="item-added-table transfer-manager-grid">
            <TransferManagerSummary transferManagerList={searchTransferList} />
          </StyledTableWrapper>
        </Row>
      </StyledContentLayout>
      <FooterButtons
        enableExtraClass
        isLoading={submiting}
        isExitOnly={true}
        enableExitPopup={false}
        onConfirmExitClick={onExitClick}
        proceedButtonText="Create New Transfer"
        onProceedClick={onCreateNewTransferClick}
      />
    </>
  );
};

export default TransferManager;
