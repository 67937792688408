import React from 'react';
import { Modal, Row, Col } from 'antd';
import styled from 'styled-components';
import { toString } from 'lodash';

import Webcam, { IMAGE_CATEGORY } from 'components/webcam';
import {
  DOCUMENT_CAPTURE_TITLE,
  DOCUMENT_TYPE_OTHER,
} from 'components/customer/constants';
import { Button } from 'components/common/Button';
import './DocumentCapture.less';

const StyledModal = styled(Modal)`
  font-size: var(--font-size-16);
  text-align: center;
  & .ant-modal-header {
    padding: 27px 30px;
    text-align: center;
  }
`;

const StyledColOne = styled(Col)`
  padding: 20px 40px;
`;

const DocumentCapture = ({
  visible,
  onCancel,
  docType,
  onDocCapture,
  onAddClick,
}: any) => {
  const onOkClick = () => { /* do nothing */ };

  const onCancelClick = () => {
    onCancel();
  };

  let supportedType = IMAGE_CATEGORY.DOCUMENT;
  if (docType === 1 || docType === 2) {
    //Driving Licence
    //TODO: enable IMAGE_CATEGORY.DOCUMENT_DRIVING_LICENCE;
    supportedType = IMAGE_CATEGORY.DOCUMENT;
  }
  //else if (docType === 2) {
  //   //Passport
  //   //TODO: enable IMAGE_CATEGORY.DOCUMENT_PASSPORT
  //   supportedType = IMAGE_CATEGORY.DOCUMENT;
  // }
  else if (docType === DOCUMENT_TYPE_OTHER)
    supportedType = toString(DOCUMENT_TYPE_OTHER);

  return (
    <StyledModal
      title={DOCUMENT_CAPTURE_TITLE}
      open={visible}
      className="document-capture-modal"
      width={720}
      onOk={onOkClick}
      onCancel={onCancelClick}
      centered
      footer={[
        <Row gutter={24} justify="end" key="footer">
          <Col key="1">
            <Button itemProp="secondary" key="back" onClick={onCancelClick}>
              Cancel
            </Button>
          </Col>
          <Col key="2">
            <Button htmlType="submit" onClick={onAddClick}>
              Add
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <Row>
        <StyledColOne span={24}>
          <Webcam
            isRetake={true}
            type={supportedType}
            width={350}
            getItemPhoto={onDocCapture}
          />
        </StyledColOne>
      </Row>
    </StyledModal>
  );
};

export default DocumentCapture;
