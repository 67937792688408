import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, notification } from 'antd';
import styled from 'styled-components';
import HorizontalTabs from 'components/common/HorizontalTabs';
import {
  Reports,
  VoidTransactions,
  UserManager,
  TravelMoneyRates,
} from './widgets';
import { ADMIN_TABS } from 'globalConstants';

import { actions } from 'redux/reducers/admin';

import './index.less';

const StyledMenuLayout = styled(Layout)`
  &.control-box {
    padding: 0;
    margin: 15px 20px;
  }
`;
const StyledLayout = styled(Layout)`
  &.split-control-box {
    min-height: calc(100vh - 160px);
    margin: 0 20px 0 20px;
  }
`;

const Admin = () => {
  const { isErrorVisible, isSuccessMsgVisible, successMsg, error } =
    useSelector((state: any) => state.admin);

  const dispatch = useDispatch();

  const [activeMenu, setActiveMenu] = useState<string>(ADMIN_TABS.VOID);
  const onMenuClick = (clickedMenu: string) => {
    setActiveMenu(clickedMenu);
  };

  useEffect(() => {
    if (isErrorVisible) {
      notification.error({
        message: error,
        duration: 3,
      });
      dispatch(actions.setIsErrorVisible(false));
    }

    if (isSuccessMsgVisible) {
      notification.success({
        message: successMsg,
        duration: 5,
      });
      dispatch(actions.setIsSuccessMsgVisible(false));
    }
  }, [isErrorVisible, isSuccessMsgVisible, error, successMsg, dispatch]);

  return (
    <>
      <StyledMenuLayout className="control-box">
        <HorizontalTabs
          onMenuClick={onMenuClick}
          initialPanes={ADMIN_TABS.INITIALPANES}
        />
      </StyledMenuLayout>
      <StyledLayout className="split-control-box">
        {activeMenu === ADMIN_TABS.VOID && <VoidTransactions />}
        {activeMenu === ADMIN_TABS.USER_MANAGER && <UserManager />}
        {activeMenu === ADMIN_TABS.TRAVEL_MONEY_RATES && <TravelMoneyRates />}
        {activeMenu === ADMIN_TABS.REPORTS && <Reports />}
      </StyledLayout>
    </>
  );
};

export default Admin;
