import React, { useEffect, useState } from 'react';
import { Col, Modal, Row, Spin, notification } from 'antd';
import ItemDetailsTable from './itemDetailsTable';
import RenewedPledgeHistoryTable from './renewedPledgeHistory';

import { getPledgeAgreementItems } from 'services/pledge';
import { getRenewalHistory } from 'services/consumerDuty';

import { GENERIC_ERROR } from 'globalConstants';
import { currencyFormat } from 'utils/util';

interface ItemDetailsPopupProps {
  open: boolean;
  setItemDetailsPopupVisible: () => void;
  selectedRow: any;
}

const ItemDetailsPopup = ({
  open,
  setItemDetailsPopupVisible,
  selectedRow,
}: ItemDetailsPopupProps) => {
  const [items, setItems] = useState<Array<any>>([]);
  const [renewalHistory, setRenewalHistory] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalInterestPaid, setTotalInterestPaid] = useState<any>(0);

  useEffect(() => {
    getPledgeDetails(selectedRow);
    getPledgeRenewalHistory(selectedRow);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getPledgeDetails = async (row: any) => {
    try {
      setLoading(true);
      const response = await getPledgeAgreementItems(row?.originalLoanId);
      if (response?.data?.items) {
        setItems(response?.data?.items);
        setLoading(false);
      }
    } catch (e: any) {
      setLoading(false);
    }
  };

  const getPledgeRenewalHistory = async (row: any) => {
    try {
      const response = await getRenewalHistory(row?.originalLoanId);
      if (response?.data) {
        setRenewalHistory(response?.data);
        const totalInterestPaid = response?.data?.reduce((acc: any, x: any) => {
          return acc + x.interestPaidTillDate;
        }, 0);
        setTotalInterestPaid(totalInterestPaid);
      }
    } catch (e: any) {
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  return (
    <>
      <Modal
        title=""
        open={open}
        width={1400}
        centered
        destroyOnClose={true}
        maskClosable={false}
        onCancel={() => setItemDetailsPopupVisible()}
        footer={null}
      >
        <Spin spinning={loading}>
          <ItemDetailsTable rows={items} />
          <RenewedPledgeHistoryTable row={renewalHistory} />
          <Row className="cd-renewed-history-row">
            <Col span={22}>
              <div className="cd-total-renewed-interest">{`Total Interest Paid: ${currencyFormat(
                totalInterestPaid,
                true
              )}`}</div>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </>
  );
};

export default ItemDetailsPopup;
