import { createSlice } from '@reduxjs/toolkit';
import { VOID_STATUS } from 'globalConstants';
import { USER_MANAGER } from 'components/admin/constants';

const initialState = {
  loading: false,
  gridLoading: false,
  isErrorVisible: false,
  isSuccessMsgVisible: false,
  voidTransactionsList: [],
  error: '',
  successMsg: '',
  voidSignalRSuccess: false,
  voidErrorStopTimer: false,
  storeUsers: [],
  assignedRoles: [] as any,
  assignedStores: [],
  assignedPolicies: [] as any,
  selectedUser: {},
  userDetails: {},
  travelMoneyRates: {},
  travelMoneyRateHistory: [],
  userPermissions: {},
  groupPermissions: {},
  defaultPermissions: {},
  groupRoles: [],
  groupPolicy: [],
  selectedGroup: 0,
  serviceIdList: [],
  basketIdList: [],
  selectedEmpId: null,
  selectedCheckboxCount: 0,
  allServicesList: [],
  isSignalRResponse: false,
};
const slice = createSlice({
  name: 'adminActions',
  initialState,
  reducers: {
    initLoading(state, action) {
      state.loading = action.payload;
    },
    initGridLoading(state, action) {
      state.gridLoading = action.payload;
    },
    setIsErrorVisible(state, action) {
      state.isErrorVisible = action.payload;
    },
    setIsSuccessMsgVisible(state, action) {
      state.isSuccessMsgVisible = action.payload;
    },
    logSuccess(state, action) {
      state.successMsg = action.payload;
    },
    logError(state, action) {
      state.error = action.payload;
    },
    setVoidTransactionsList(state, action) {
      state.voidTransactionsList = action.payload;
    },
    setDisableVoidTransaction(state, action) {
      state.voidSignalRSuccess = false;
    },
    setVoidErrorStopTimer(state, action) {
      state.voidErrorStopTimer = true;
    },
    setStoreUsers(state, action) {
      state.storeUsers = action.payload;
    },
    setSelectedUser(state, action) {
      state.selectedUser = action.payload;
    },
    setUserDetails(state, action) {
      state.userDetails = action.payload;
    },
    setAssignedRoles(state, action) {
      if (Array.isArray(action.payload)) {
        state.assignedRoles = action.payload;
      } else {
        const index = state.assignedRoles?.findIndex(
          (x: any) => x?.roleId === action?.payload?.roleId
        );
        if (index > -1) {
          state.assignedRoles[index] = {
            ...state.assignedRoles[index],
            ...action.payload,
          };
        } else {
          state.assignedRoles = [...state.assignedRoles, action.payload];
        }
      }
    },
    setAssignedStores(state, action) {
      state.assignedStores = action.payload;
    },
    setAssignedPolicies(state, action) {
      const index = state.assignedPolicies?.findIndex(
        (x: any) => x?.policyId === action?.payload?.policyId
      );
      if (index > -1) {
        state.assignedPolicies[index] = {
          ...state.assignedPolicies[index],
          ...action.payload,
        };
      } else {
        state.assignedPolicies = [...state.assignedPolicies, action.payload];
      }
    },
    setSelectedGroup(state, action) {
      state.selectedGroup = action.payload
        ? action.payload
        : USER_MANAGER.GROUP_IDS.IT_ADMIN;
    },
    setUserPermissions(state, action) {
      state.userPermissions = action.payload;
    },
    setGroupPermissions(state, action) {
      state.groupPermissions = action.payload;
    },
    setGroupRoles(state, action) {
      state.groupRoles = action.payload;
    },
    setGroupPolicy(state, action) {
      state.groupPolicy = action.payload;
    },
    setDefaultPermissions(state, action) {
      state.defaultPermissions = action.payload;
    },
    setTravelMoneyRates(state, action) {
      state.travelMoneyRates = action.payload;
    },
    setTravelMoneyRateHistory(state, action) {
      state.travelMoneyRateHistory = action.payload;
    },
    setServiceIdList(state, action) {
      state.serviceIdList = action.payload;
    },
    setBasketIdList(state, action) {
      state.basketIdList = action.payload;
    },
    setSelectedEmpId(state, action) {
      state.selectedEmpId = action.payload;
    },
    setSelectedCheckboxCount(state, action) {
      state.selectedCheckboxCount = action.payload;
    },
    setAllServicesList(state, action) {
      state.allServicesList = action.payload;
    },
    clearTravelMoneyRates(state) {
      state.travelMoneyRates = {};
      state.travelMoneyRateHistory = [];
    },
    setIsSignalRResponse(state, action) {
      state.isSignalRResponse = action.payload;
    },

    clearUserDetails(state) {
      state.userDetails = {};
      state.selectedUser = {};
      state.assignedPolicies = [];
      state.assignedRoles = [];
      state.userPermissions = {};
      state.groupPermissions = {};
      state.groupRoles = [];
      state.groupPolicy = [];
      state.assignedStores = [];
    },
    signalrResponse(state, action) {
      if (
        action?.payload?.voidStatus === VOID_STATUS.VOID_SUCCESS ||
        action?.payload?.voidStatus === VOID_STATUS.PARTIAL_VOID_SUCCESS
      ) {
        return {
          ...state,
          voidTransactionsList: action?.payload?.transactions,
          voidSignalRSuccess: true,
          isSignalRResponse: true,
        };
      } else {
        state.voidErrorStopTimer = true;
      }
    },
  },
});

export default slice.reducer;
export const { reducer, actions } = slice;
export { initialState };
