import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  stonePrices: [],
  stoneItemsTempData: [] as Array<any>,
  stoneAllItemsData: [] as Array<any>,
  stoneRemoveData: [] as Array<any>,
  stoneEditData: [] as Array<any>,
  isStoneRowRemoved: false,
  stoneRowId: 1,
  isStoneRowEdit: false,
};
const slice = createSlice({
  name: 'stoneActions',
  initialState,
  reducers: {
    stoneItemsPrice(state, action) {
      state.stonePrices = action.payload;
    },
    stoneItemsTemp(state, action) {
      return {
        ...state,
        stoneItemsTempData: action.payload,
        stoneRemoveData: [],
        stoneEditData: [],
        isStoneRowRemoved: false,
        isStoneRowEdit: false,
      };
    },
    stoneAllItems(state, action) {
      return {
        ...state,
        stoneAllItemsData: action.payload,
        stoneItemsTempData: [],
        stoneRemoveData: [],
        stoneEditData: [],
        isStoneRowRemoved: false,
        isStoneRowEdit: false,
      };
    },
    removeStoneRow(state, action) {
      state.stoneRemoveData = [...state.stoneAllItemsData];
      const allData = [...state.stoneAllItemsData];
      const tempData = [...state.stoneItemsTempData];
      state.stoneItemsTempData = tempData.filter(
        (item: any) => item.id !== action.payload.id
      );
      state.stoneAllItemsData = allData.filter(
        (item: any) => item.id !== action.payload.id
      );
      if (state.stoneAllItemsData?.length !== allData?.length) {
        state.isStoneRowRemoved = true;
      }
    },
    editStoneRow(state, action) {
      state.stoneEditData = [...state.stoneAllItemsData];
      state.isStoneRowEdit = true;
      if (action.payload.isIndexEdit)
        state.stoneAllItemsData[action.payload.index] =
          action.payload.stoneData;
      else
        state.stoneItemsTempData[action.payload.index] =
          action.payload.stoneData;
    },
    stoneRowId(state, action) {
      state.stoneRowId = action.payload;
    },
    clearStoneItems() {
      return {
        ...initialState,
      };
    },
  },
});

export default slice.reducer;
export const { reducer, actions: stoneActions } = slice;
export { initialState };
