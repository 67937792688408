import { CloseOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';

const ProgressTick = (aggreementNo: any, status: any) => {
  if (status === 'Initialised') return <span>{status}...</span>;
  else if (status === 'Successful') {
    return (
      <Row>
        <Col span={17}>
          <Row>
            <Col>{aggreementNo}</Col>
          </Row>
        </Col>
        <Col span={4}>
          <img alt="" src={require('../../assets/images/tick.svg').default} />
        </Col>
      </Row>
    );
  } else if ((status === 'Failed' || status === 'Cancelled') && !aggreementNo) {
    return (
      <Row className="chekout-failed">
        <Col span={18}>
          <Row className="top-border"></Row>
          <Row>
            <Col>Failed to Process</Col>
          </Row>
        </Col>
        <Col span={4}>
          <CloseOutlined />
        </Col>
      </Row>
    );
  } else {
    return '-';
  }
};

export default ProgressTick;
