import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Layout, Row, Col } from 'antd';
import styled from 'styled-components';
import FooterButtons from 'components/common/footerButtons';
import PledgeLoanSummary from 'components/pledgemanagement/widgets/common/pleadgeItemSummary';
import ItemDescriptionValuation from 'components/pledgemanagement/widgets/common/itemDescriptionValuation';
import CustomerHeader from 'components/customer/customerHeader';
import {
  AUCTION_OVER_REVIEW_TITLE,
  GENERATE_BUTTON_TEXT,
  NEXT_PLEDGE_BUTTON_TEXT,
  NO_PLEDGE_TEXT,
} from 'components/pledgemanagement/constants';
import PledgeLoanContainer from 'components/pledgemanagement/widgets/common/pledgeLoanContainer';
import { actions } from 'redux/reducers/pledgeManagement';
import { pledgeActions } from 'redux/reducers/pawnbroking';
import { coinActions } from 'redux/reducers/pawnbroking/coin';
import { stoneActions } from 'redux/reducers/pawnbroking/stone';
import {
  PFA_LOT_NUMBER,
  GENERATE_LOT_STICKER,
  GET_PLEDGE_ITEMS,
  GET_LOAN_DETAILS,
} from 'action_creators/pledgeManagement';
import isEmpty from 'lodash/isEmpty';
import ControlledAlert from 'components/common/ControlledAlert';
import { BUTTON_LABELS } from 'components/pledgemanagement/constants';

const StyledContentLayout = styled(Layout)`
  background: var(--white);
  padding: 5px 0px;
  border-radius: 5px;
  height: calc(100vh - 207px);
`;

const StyledLayout = styled(Layout)`
  background: var(--background);
`;

const StyledItemSummary = styled(Col)`
  height: calc(100vh - 372px);
`;

const StyledLoanContainer = styled(Col)`
  margin: 8px;
`;
const StyledRow = styled(Row)`
  background: var(--catskill-white);
`;
const StyledMainContainer = styled.div<{ $pledgeContainer: boolean }>`
  background: var(--white);
  border: 0.6px solid var(--linkwater);
  border-bottom: none;
  box-shadow: 0px 1px 10px rgba(1, 78, 169, 0.2);
  border-radius: 5px 5px 0 0;
  margin: ${(props) =>
    props.$pledgeContainer ? '0px 5px 0px 0px' : '0px 0px 0px 5px'};
  color: var(--black);
  display: flex;
  flex-direction: column;
`;

const StyledAlertText = styled(Row)`
  padding: 10px 0 15px 0;
`;

interface AuctionOverReviewProps {
  onExit: () => void;
}

const AuctionOverReview = ({ onExit }: AuctionOverReviewProps) => {
  const {
    pledgeManagement: {
      pledgeItemsList,
      pledgeInReview,
      confirmedItems,
      loanDetails,
      totalLoanWeight,
      generatedLotNumber,
      auctionsOverSeventyFiveList,
      itemCount,
      confirmItemDetails,
    },
    user: { selectedStore, userId },
  } = useSelector((state: any) => {
    return {
      pledgeManagement: state.pledgeManagement,
      user: state.user,
    };
  });

  const onGenerateLotNoClick = () => {
    if (!isNextPledgeEnable) {
      const loanPfaDto = {
        loanId: loanDetails?.id,
        auctionId: loanDetails?.auctionId,
        loanNumber: loanDetails?.loanNumber,
        storeId: selectedStore?.storeId,
        itemCount: itemCount,
        reserveValue: loanDetails?.loanAmount,
        interestAmount: loanDetails?.interestAmount,
        totalWeight: totalLoanWeight,
      };

      const pushToPfaParams = {
        loanId: loanDetails?.id,
        loanNumber: loanDetails?.loanNumber,
        storeId: selectedStore?.storeId,
        itemCount: itemCount,
        loanValue: loanDetails?.loanAmount,
        interestAmount: loanDetails?.interestAmount,
        totalWeight: totalLoanWeight,
        auctionItems: confirmItemDetails,
        userId: userId,
      };

      dispatch({
        type: PFA_LOT_NUMBER,
        payload: { params: { loanPfaDto }, pushToPfaParams: pushToPfaParams },
      });
    } else {
      setNextPledgeEnable(false);
      setGenerateButtonText(GENERATE_BUTTON_TEXT);
      if (auctionsOverSeventyFiveList?.length === 1) {
        onExitClick();
      } else {
        index = auctionsOverSeventyFiveList.findIndex(
          (nextPledge: any) => nextPledge?.loanId === loanDetails?.id
        );
        if (index === auctionsOverSeventyFiveList?.length - 1) {
          setNextIndex(index - 1);
        } else {
          setNextIndex(index + 1);
        }
        dispatch(actions.clearPFA());
        dispatch(pledgeActions.clearItems());
        dispatch(coinActions.clearCoinItems());
        dispatch(stoneActions.clearStoneItems());
        dispatch({
          type: GET_PLEDGE_ITEMS,
          payload: {
            loanId: auctionsOverSeventyFiveList[nextIndex]?.loanId,
            pledgeLoanNumber:
              auctionsOverSeventyFiveList[nextIndex]?.loanNumber,
          },
        });

        dispatch({
          type: GET_LOAN_DETAILS,
          payload: {
            loanNumber: auctionsOverSeventyFiveList[nextIndex]?.loanNumber,
          },
        });
      }
    }
  };

  const onReprintLotLabelClick = () => {
    dispatch({
      type: GENERATE_LOT_STICKER,
      payload: loanDetails?.id,
    });
  };

  const [isLotNumberAlertVisible, setLotNumberAlertVisible] =
    useState<boolean>(false);
  const [isNextPledgeEnable, setNextPledgeEnable] = useState<boolean>(false);
  const [generateButtonText, setGenerateButtonText] =
    useState<string>(GENERATE_BUTTON_TEXT);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let index = 0;

  const [nextIndex, setNextIndex] = useState<number>(0);

  const onExitClick = () => {
    dispatch(actions.clearPFA());
    dispatch(pledgeActions.clearItems());
    dispatch(coinActions.clearCoinItems());
    dispatch(stoneActions.clearStoneItems());
    onExit();
  };

  const onExitJourney = () => {
    dispatch(actions.clearAuctions());
    dispatch(pledgeActions.clearItems());
    dispatch(coinActions.clearCoinItems());
    dispatch(stoneActions.clearStoneItems());
    navigate('/');
  };

  useEffect(() => {
    if (!isEmpty(generatedLotNumber)) {
      setLotNumberAlertVisible(true);
    }
  }, [generatedLotNumber]);

  const lotNumberMsgString = () => {
    return (
      <>
        <StyledAlertText>
          Lot {generatedLotNumber?.lotNumber}, Agreement Number{' '}
          {loanDetails?.loanNumber}
        </StyledAlertText>
        <StyledAlertText>
          Auction Date: {generatedLotNumber?.auctionDate}
        </StyledAlertText>
        <StyledAlertText>
          Last Mail Date: {generatedLotNumber?.lastMailSentDate}
        </StyledAlertText>
      </>
    );
  };

  const onOkButtonClick = () => {
    setLotNumberAlertVisible(false);
    if (auctionsOverSeventyFiveList?.length === 1) {
      setGenerateButtonText(NO_PLEDGE_TEXT);
    } else {
      setNextPledgeEnable(true);
      setGenerateButtonText(NEXT_PLEDGE_BUTTON_TEXT);
    }
  };

  return (
    <>
      <StyledLayout className="main-pfi-review-layout">
        <CustomerHeader
          title={AUCTION_OVER_REVIEW_TITLE + pledgeInReview}
          buttonsOption={false}
          isCustomerNeeded={false}
        />

        <StyledContentLayout className="pfi-review-wrapper">
          <Row className="container-row">
            <Col span={11}>
              <StyledMainContainer $pledgeContainer={true}>
                <Row>
                  <StyledItemSummary>
                    <PledgeLoanSummary pledgeItemsList={pledgeItemsList} />
                  </StyledItemSummary>
                  <StyledRow>
                    <StyledLoanContainer>
                      <PledgeLoanContainer />
                    </StyledLoanContainer>
                  </StyledRow>
                </Row>
              </StyledMainContainer>
            </Col>
            <Col span={13}>
              <StyledMainContainer $pledgeContainer={false}>
                <Row>
                  <ItemDescriptionValuation />
                </Row>
              </StyledMainContainer>
            </Col>
          </Row>
        </StyledContentLayout>
        <Row>
          <Col span={24}>
            <FooterButtons
              isExitOnly
              enableExtraClass
              extraButtonItemProp="secondary"
              proceedButtonText={generateButtonText}
              isDisabled={confirmedItems?.length !== pledgeItemsList?.length}
              onProceedClick={onGenerateLotNoClick}
              extraButtonEanable={true}
              extraButtonText={BUTTON_LABELS.BACK_TO_AUCTION_MANAGER}
              extraButtonAlign="left"
              onConfirmExitClick={onExitJourney}
              extraButtonOnClick={onExitClick}
            />
          </Col>
        </Row>
      </StyledLayout>

      {isLotNumberAlertVisible && (
        <ControlledAlert
          closable={false}
          visible={isLotNumberAlertVisible}
          isCancelBtn={true}
          cancelButtonText={BUTTON_LABELS.OK}
          onCancel={onOkButtonClick}
          alertMessage={''}
          message={lotNumberMsgString()}
          onClick={onReprintLotLabelClick}
          yesButtonText={BUTTON_LABELS.REPRINT_LOT_LABEL}
        />
      )}
    </>
  );
};

export default AuctionOverReview;
