import React, { useEffect, useState } from 'react';
import { Row, Col, Layout } from 'antd';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import CurrencyConversionForm from './currencyConversionForm';
import FooterButtons from 'components/common/footerButtons';
import {
  FOOTER_BUTTONS_AND_TEXT,
  ROUTE_CONFIG,
  BASKET_SERVICE_TYPE,
} from 'globalConstants';
import PayFooter from 'components/common/payFooter';
import { Button } from 'components/common/Button';
import CustomerHeader from 'components/customer/customerHeader';
import {
  TO_PAY,
  TO_TAKE,
  TAKE_FROM_CUSTOMER,
  TITLE_LABEL,
  ADD_LABEL,
  PAY_TO_CUSTOMER,
} from './constants';
import { BuyingTable } from './transactions'; //SellingTable
import SearchBuyback from './searchbuyback';
import StockDetails from './stockDetails';
import './index.less';
import { POST_FX_TRANSACTION } from 'action_creators/travelmoney';
import groupBy from 'lodash/groupBy';
import { calculateTotalSumByKey, joinValues } from 'utils/util';
import { StyledHelpPopoverInfoForButtons } from 'components/common/HelpPopoverInfo';

const { BASKET } = ROUTE_CONFIG;

const StyledLayout = styled(Layout)`
  background-color: var(--background);
`;
const StyledRow = styled(Row)`
  flex-wrap: nowrap;
  background-color: var(--background);
  margin: 0 30px 0 0;
  padding: 0 20px 0 0;
`;

const StyledRightCol = styled(Col)`
  margin-left: 0;
  width: 100%;
  padding: 0;
`;

const StyledRowHelp = styled(Row)`
  margin-block-end: 10px;
  margin-top: -30px;
  margin-left: 70px;
`;

const StyledLeftCol = styled(Col)`
  margin-right: 10px;
`;

const StyledBottomFooter = styled(Row)`
  position: absolute;
  bottom: 10px;
  width: 100%;
`;

const StyledTranRow = styled(Row)`
  padding: 20px;
`;

const StyledFormControl = styled(Row)`
  padding: 15px 30px;
  min-height: 590px;
`;

const TravelMoney = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    tm: {
      totalCurrencyAmount,
      convertedCurrencies,
      currenciesDescription,
      currencyStockBalance, //TODO enable once testing done
      buybackCharges,
      currencySymbol,
      isBuybackApply,
      proceedToBuyback,
      buybackStoreId,
      isBuySellTransLoading,
    },
    customer: { customer, customerFxDocumentId },
    basket: { basket },
    user: { user, selectedStore },
  } = useSelector((state: any) => {
    return {
      tm: state.tm,
      customer: state.customer,
      basket: state.basket,
      user: state.user,
    };
  });

  const [fxData, setFxData] = useState<any>({
    Sell: [],
    Buy: [],
    items: [],
    totalAmount: 0, //If amount is positive then it should pe 'Pay to customer' if -ve then 'Amount to recieve'
  });

  const [fxTotalAmount, setFxTotalAmount] = useState<any>('');

  useEffect(() => {
    if (basket?.basketServices) {
      const groupedItems = groupBy(basket.basketServices, 'serviceType');
      const fxs = groupedItems[BASKET_SERVICE_TYPE.FX] || [];
      const totalAmount = calculateTotalSumByKey(fxs, 'serviceAmount');
      setFxData({ totalAmount: totalAmount, items: fxs });
      setFxTotalAmount(Math.abs(totalAmount)?.toFixed(2));
    }
  }, [basket, basket.basketServices]);

  const onProceedClick = () => {
    navigate(`/${BASKET}`);
  };

  const isDisabled =
    !currencyStockBalance?.storeBalance ||
    currencyStockBalance?.storeBalance <= 0 ||
    totalCurrencyAmount.toAmount > currencyStockBalance?.storeBalance ||
    totalCurrencyAmount?.toAmount <= 0 ||
    totalCurrencyAmount?.fromAmount <= 0;

  const {
    street,
    town,
    property,
    county,
    postcode,
    houseName,
    area,
    firstNames,
    middleName,
    surname,
  } = customer;

  const onSubmit = () => {
    const params = {
      basketId: basket?.basketId || null, //null for creating a basket
      customerId: customer.customerId,
      customerName:
        joinValues([customer.firstNames, customer.surname], true) || undefined,
      customer: {
        street,
        town,
        property,
        county,
        postcode,
        houseName,
        area,
        firstNames,
        middleName,
        surname,
      },
      employeeId: user?.employeeId,
      storeId: proceedToBuyback ? buybackStoreId : selectedStore?.storeId,
      storeAddress: selectedStore?.storeAddress,
      storePostalCode: selectedStore?.postalCode,
      storeTelephone: selectedStore?.telephone,
      userName: user?.authorizeUsername,
      userId: user?.userId,
      fx: {
        originalReceiptNumber: convertedCurrencies?.receiptNumber || 0,
        ...convertedCurrencies,
        ...totalCurrencyAmount,
        ...currenciesDescription,
        rateGuarantee: isBuybackApply ? buybackCharges.buybackCharge : 0,
        fxCustomerDocumentId:
          customerFxDocumentId > 0 ? customerFxDocumentId : null,
      },
    };
    // file deepcode ignore DeleteOfNonProperty
    delete params?.fx?.receiptNumber;
    // file deepcode ignore DeleteOfNonProperty
    delete params?.fx?.transactionId;

    const currencySymbols = {
      fromCurrencySymbol: '&#163;',
      toCurrencySymbol: '',
    };

    dispatch({
      type: POST_FX_TRANSACTION,
      payload: { params, currencySymbols },
    });
  };

  const isExitAndSaveEnabled = () => {
    if (fxData?.items?.length > 0) return true;
    return false;
  };

  return (
    <>
      <CustomerHeader
        title={TITLE_LABEL}
        buttonsOption={false}
        helpSection="TRAVELMONEY"
      />
      <StyledLayout className="travel-money-wrapper">
        <Row>
          <Col span={24}>
            <StyledRow>
              <StyledLeftCol span={13} className="split-control-box">
                <StyledFormControl>
                  <Col span={13}>
                    <SearchBuyback />
                    <CurrencyConversionForm />
                  </Col>
                  <Col span={2}></Col>
                  <Col span={9}>
                    <StockDetails />
                  </Col>
                </StyledFormControl>
                <StyledBottomFooter className="split-pay-footer">
                  <Col>
                    <Row gutter={16}>
                      <Col span={10}>
                        <PayFooter
                          payLabel={TO_TAKE}
                          amount={
                            totalCurrencyAmount?.fromAmount > 0
                              ? (
                                  totalCurrencyAmount?.fromAmount +
                                  totalCurrencyAmount?.buybackCharge
                                ).toFixed(2)
                              : (0).toFixed(2)
                          }
                          addonBefore={currencySymbol?.fromCurrencySymbol}
                          helpSection="TRAVELMONEY"
                          data-testid="toTake"
                        />
                      </Col>
                      <Col span={10}>
                        <PayFooter
                          payLabel={TO_PAY}
                          amount={
                            totalCurrencyAmount?.toAmount > 0
                              ? totalCurrencyAmount?.toAmount?.toFixed(2)
                              : 0.0
                          }
                          addonBefore={currencySymbol?.toCurrencySymbol}
                          helpSection="TRAVELMONEY"
                          data-testid="toPay"
                        />
                      </Col>

                      <Col span={4}>
                        <Button
                          itemProp="secondary"
                          disabled={isDisabled}
                          onClick={onSubmit}
                          loading={isBuySellTransLoading}
                          className="btnAddTravelMoney"
                        >
                          {ADD_LABEL}
                        </Button>
                        <StyledRowHelp>
                          <StyledHelpPopoverInfoForButtons
                            linkedID={'TRAVELMONEY_TakePayAdd'}
                            position="leftTop"
                            helpPosition="AFTER"
                            attchedComp="BUTTON"
                          ></StyledHelpPopoverInfoForButtons>
                        </StyledRowHelp>
                      </Col>
                    </Row>
                  </Col>
                </StyledBottomFooter>
              </StyledLeftCol>
              <StyledRightCol className="split-control-box" span={11}>
                <StyledTranRow>
                  <BuyingTable items={fxData.items} />
                  {/* <SellingTable items={fxData.Sell} /> TODO may be remove */}
                </StyledTranRow>
                <StyledBottomFooter className="split-pay-footer" justify="end">
                  <Col>
                    <Row justify="end" gutter={32}>
                      <Col span={24}>
                        <PayFooter
                          payLabel={
                            fxData.totalAmount > 0
                              ? TAKE_FROM_CUSTOMER
                              : PAY_TO_CUSTOMER
                          }
                          amount={fxTotalAmount}
                        />
                      </Col>
                    </Row>
                  </Col>
                </StyledBottomFooter>
              </StyledRightCol>
            </StyledRow>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="split-footer-box-tm">
              <FooterButtons
                payLabel={FOOTER_BUTTONS_AND_TEXT.PAY_TO_CUSTOMER}
                proceedButtonText={FOOTER_BUTTONS_AND_TEXT.PROCEED_TO_BASKET}
                onProceedClick={onProceedClick}
                isExitAndClear={!basket?.totalFX?.totalFXAmount}
                isExitAndSaveEnabled={isExitAndSaveEnabled()}
                helpSection="TRAVELMONEY"
              />
            </div>
          </Col>
        </Row>
      </StyledLayout>
    </>
  );
};

export default TravelMoney;
