import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row,
  Col,
  Checkbox,
  Switch,
  Button,
  Collapse,
  Input,
  Form,
  notification,
} from 'antd';
import { PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import groupBy from 'lodash/groupBy';
import { currencyFormat } from 'utils/util';
import Select from 'components/common/Select';
import { USER_MANAGER } from 'components/admin/constants';
import { actions } from 'redux/reducers/admin';
import { GET_GROUP_PERMISSIONS } from 'action_creators/admin';

const StyledRow = styled(Row)`
  margin: 10px 0;
`;

const StyledTitleRow = styled(Row)`
  margin: 3px 0px 15px 0px;
`;

const StyledGroupSelectRow = styled(Row)`
  margin-bottom: 20px;
`;

const StyledTitle = styled.span`
  font-weight: var(--font-weight-500);
  font-size: 18px;
  line-height: 30px;
  color: var(--black);
`;

const StyledWrapper = styled.div`
  padding: 10px 20px;
  border: 1px solid var(--light-gray);
  border-radius: 5px;
`;

const StyledSpan = styled.span`
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-18);
`;

const StyledStoreTitleRow = styled(Row)`
  margin-top: 20px;
  margin-bottom: 8px;
`;

const StyledCol = styled(Col)`
  padding-top: 10px;
`;

const StyledInputCol = styled(Col)`
  padding: 0 !important;
`;

const StyledLinkButton = styled(Button)`
  text-align: left !important;
  padding: 15px 0 0 0;
  height: 30px;
  font-weight: var(--font-weight-500);
`;

interface ApplicationPrivilegesProps {
  isEditMode: boolean;
  allGroups: Array<any>;
  userServiceList: Array<any>;
  userPermissions: any;
  userStores: Array<any>;
  userGroup: any;
}

const ApplicationPrivileges = ({
  isEditMode = false,
  allGroups = [],
  userServiceList = [],
  userPermissions = {},
  userStores = [],
  userGroup = {},
}: ApplicationPrivilegesProps) => {
  const {
    admin: { assignedStores, groupRoles, groupPolicy, selectedGroup },
  } = useSelector((state: any) => {
    return {
      admin: state.admin,
    };
  });

  const dispatch = useDispatch();
  const [checkedRoles, setcheckedRoles] = useState<any>([]);
  const [userCheckedRoles, setUserCheckedRoles] = useState<any>([]);
  const [isAllowedChangeStore, setIsAllowedChangeStore] =
    useState<boolean>(false);
  const [isResetPermissions, setIsResetPermissions] = useState<boolean>(false);
  const { Panel } = Collapse;
  const [form] = Form.useForm();

  useEffect(() => {
    filterCheckedRoles(userPermissions?.userRole);
  }, [userPermissions]);

  useEffect(() => {
    if (isResetPermissions) {
      filterCheckedRoles(groupRoles);
      setIsResetPermissions(false);
    }
  }, [groupRoles, isResetPermissions]);

  useEffect(() => {
    if (groupRoles?.length > 0 && userCheckedRoles.length > 0) {
      const displayRoles = groupRoles?.map((option: any) => {
        return {
          roleId: option?.roleId,
          value: userCheckedRoles?.includes(option?.roleId) ? true : false,
        };
      });

      dispatch(actions.setAssignedRoles(displayRoles));
    }
  }, [groupRoles, userCheckedRoles, dispatch]);

  useEffect(() => {
    if (userGroup?.groupId === USER_MANAGER.GROUP_IDS.IT_ADMIN) {
      setIsAllowedChangeStore(true);
    }
  }, [userGroup]);

  const filterCheckedRoles = (rolesObj: any) => {
    const rolesEnabled = Array.from(
      new Set(
        rolesObj
          ?.filter(function (el: any) {
            return el?.value === true;
          })
          ?.map((el: any) => {
            return el?.roleId;
          })
      )
    );
    setcheckedRoles(rolesEnabled);
    setUserCheckedRoles(rolesEnabled);
  };

  const checkServiceEnabled = (serviceObj: any) => {
    if (
      serviceObj?.filter(
        (e: any) => e.name === USER_MANAGER.LABELS.SERVICE_ENABLE_NAME
      ).length > 0
    ) {
      return true;
    }
    return false;
  };

  const onChecklistChange = (checked: boolean, roleId: any) => {
    if (checked) {
      setcheckedRoles([...checkedRoles, roleId]);
    } else {
      const index = checkedRoles.indexOf(roleId);
      if (index > -1) {
        checkedRoles.splice(index, 1);
        setcheckedRoles([...checkedRoles]);
      }
    }

    dispatch(
      actions.setAssignedRoles({
        roleId: roleId,
        value: checked,
      })
    );
  };

  const onStoreChange = (selectedStores: any) => {
    dispatch(actions.setAssignedStores(selectedStores));
  };

  const onGroupChange = (groupId: any) => {
    dispatch(actions.setSelectedGroup(groupId));
    dispatch({
      type: GET_GROUP_PERMISSIONS,
      payload: { groupId },
    });
  };

  const onPolicyChange = (value: any, policyId: number, minValue: any) => {
    if (value >= minValue) {
      dispatch(
        actions.setAssignedPolicies({
          policyId: policyId,
          value: value.toString(),
        })
      );
    } else {
      notification.error({
        message: USER_MANAGER.MESSAGES.MIN_VALUE_MESSAGE + minValue,
        duration: 3,
      });
    }
  };

  const renderRoles = () => {
    return groupRoles.map((option: any, index: any) => (
      <>
        {index % 2 === 0 && (
          <Row>
            <StyledCol span={8}>
              <Checkbox
                disabled={!isEditMode}
                key={index}
                checked={checkedRoles?.includes(option?.roleId) ? true : false}
                onChange={(e) =>
                  onChecklistChange(e.target.checked, option?.roleId)
                }
              >
                {option?.name}
              </Checkbox>
            </StyledCol>
            {groupRoles[index + 1] && (
              <StyledCol span={12}>
                <Checkbox
                  disabled={!isEditMode}
                  key={index + 1}
                  checked={
                    checkedRoles?.includes(groupRoles[index + 1]?.roleId)
                      ? true
                      : false
                  }
                  onChange={(e) =>
                    onChecklistChange(
                      e.target.checked,
                      groupRoles[index + 1]?.roleId
                    )
                  }
                >
                  {groupRoles[index + 1]?.name}
                </Checkbox>
              </StyledCol>
            )}
          </Row>
        )}
      </>
    ));
  };

  const renderFeatures = () => {
    const groupedItems = groupBy(groupPolicy, 'serviceId');
    return userServiceList?.map((el: any, index: any) => (
      <Collapse
        defaultActiveKey={isEditMode ? [index] : []}
        expandIconPosition={'right'}
        className="feature-collapse"
        expandIcon={({ isActive }) =>
          isActive ? (
            <MinusCircleFilled
              style={
                isEditMode
                  ? { fontSize: '25px', color: '#11A1FD', top: 10 }
                  : { fontSize: '25px', color: '#C2CFE0', top: 10 }
              }
            />
          ) : (
            <PlusCircleFilled
              style={
                isEditMode
                  ? { fontSize: '25px', color: '#11A1FD', top: 10 }
                  : { fontSize: '25px', color: '#C2CFE0', top: 10 }
              }
            />
          )
        }
        key={'collapse' + index}
      >
        <Panel
          header={
            <>
              <Row>
                <Col
                  span={12}
                  className="feature-title collapse-service-head-left"
                >
                  {el.name}
                </Col>
                <Col span={12} className="collapse-service-head-right">
                  Disable &nbsp;
                  <Switch
                    disabled={
                      !isEditMode ||
                      !checkServiceEnabled(groupedItems[el?.serviceId])
                    }
                    checked={checkServiceEnabled(groupedItems[el?.serviceId])}
                  />{' '}
                  &nbsp; Enable
                </Col>
              </Row>
            </>
          }
          key={index}
          disabled={
            !isEditMode || !checkServiceEnabled(groupedItems[el?.serviceId])
          }
        >
          {isEditMode &&
            groupedItems[el?.serviceId]?.map((policy: any) => {
              const userPolicy = userPermissions?.userPolicy?.find(
                (elem: any) =>
                  elem?.serviceId === policy?.serviceId &&
                  elem?.policyId === policy?.policyId
              );
              return (
                <>
                  {policy?.name !== USER_MANAGER.LABELS.SERVICE_ENABLE_NAME && (
                    <Row className="feature-wrapper" key={policy?.policyId}>
                      <Col span={12} className="feature-title">
                        {policy?.name}
                      </Col>

                      {policy?.type === 'bool' ? (
                        <Col span={12}>
                          Disable &nbsp;
                          <Switch
                            disabled={!isEditMode}
                            defaultChecked={
                              userPolicy?.value
                                ? userPolicy.value === 'true'
                                  ? true
                                  : false
                                : policy?.value
                            }
                            onChange={(value: any) => {
                              onPolicyChange(
                                value,
                                policy?.policyId,
                                userPolicy?.minValue
                                  ? userPolicy?.minValue
                                  : policy?.minValue
                              );
                            }}
                          />{' '}
                          &nbsp; Enable
                        </Col>
                      ) : (
                        <StyledInputCol span={12}>
                          <Form.Item
                            label={``}
                            name={'policy' + policy?.policyId}
                            className="label-control"
                          >
                            <Input
                              onBlur={(e: any) => {
                                onPolicyChange(
                                  e.target.value,
                                  policy?.policyId,
                                  userPolicy?.minValue
                                    ? userPolicy?.minValue
                                    : policy?.minValue
                                );
                              }}
                              type="text"
                              className="input-control"
                              name={'policyInput' + policy?.policyId}
                              autoComplete="off"
                              defaultValue={
                                userPolicy?.value
                                  ? currencyFormat(
                                      Number(
                                        userPolicy?.value?.replaceAll(',', '')
                                      )
                                    )
                                  : currencyFormat(
                                      Number(policy?.value?.replaceAll(',', ''))
                                    )
                              }
                              addonBefore={policy?.sign}
                            />
                          </Form.Item>
                        </StyledInputCol>
                      )}
                    </Row>
                  )}
                </>
              );
            })}
        </Panel>
      </Collapse>
    ));
  };

  return (
    <>
      <StyledTitleRow>
        <Col span={24}>
          <StyledTitle>
            {USER_MANAGER.TITLES.APPLICATION_PRIVILAGES} &apos;
            {!isEmpty(userPermissions) ? userPermissions.userName : `#username`}
            &apos;
          </StyledTitle>
        </Col>
      </StyledTitleRow>
      <StyledGroupSelectRow>
        <Col flex="400px">
          <Select
            disabled={!isEditMode}
            value={selectedGroup}
            onChange={onGroupChange}
            options={allGroups?.map((el: any, index: any) => {
              return {
                key: index,
                value: el.groupId,
                label: `${el.groupName}`,
              };
            })}
          />
        </Col>
        <Col flex="auto"></Col>
      </StyledGroupSelectRow>
      <StyledRow>
        <Col span={24}>
          <StyledWrapper>
            {renderRoles()}
            <Row>
              <Col span={6}>
                <StyledLinkButton
                  type="link"
                  block
                  title="Reset to Default Permission"
                  onClick={() => setIsResetPermissions(true)}
                  disabled={!isEditMode}
                >
                  Reset to Default Permission
                </StyledLinkButton>
              </Col>
            </Row>
          </StyledWrapper>
        </Col>
      </StyledRow>
      <StyledStoreTitleRow>
        <StyledSpan>{USER_MANAGER.TITLES.STORES}</StyledSpan>
      </StyledStoreTitleRow>
      <StyledRow>
        <Col span={24}>
          <Select
            mode="multiple"
            style={{ height: 'auto' }}
            disabled={!isEditMode || !isAllowedChangeStore}
            value={assignedStores}
            onChange={onStoreChange}
            options={userStores?.map((el: any, index: any) => {
              return {
                key: index,
                value: el.storeId,
                label: `${el.storeId} ${el.name}`,
              };
            })}
          />
        </Col>
      </StyledRow>
      <StyledRow style={{ marginBottom: '5px' }}>
        <Col span={24}>
          <Form form={form} name="policy-form">
            {renderFeatures()}
          </Form>
        </Col>
      </StyledRow>
    </>
  );
};

export default ApplicationPrivileges;
