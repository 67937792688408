import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  AUCTION_OVER_TITLE,
  AUCTION_OVER_REVIEW_TITLE,
} from 'components/pledgemanagement/constants';
import AuctionOver from './auctionOverSummary/index';
import AuctionOverReview from './auctionOverReview/index';

const AuctionOverManager = () => {
  const { pledgeItemsList } = useSelector(
    (state: any) => state.pledgeManagement
  );

  const [activeView, setActiveView] = useState<string>(AUCTION_OVER_TITLE);

  useEffect(() => {
    if (pledgeItemsList.length > 0) setViewtoAuctionReview();
    else setViewtoAuctionOver();
  }, [pledgeItemsList]);

  const setViewtoAuctionOver = () => {
    setActiveView(AUCTION_OVER_TITLE);
  };

  const setViewtoAuctionReview = () => {
    setActiveView(AUCTION_OVER_REVIEW_TITLE);
  };

  return (
    <>
      {activeView === AUCTION_OVER_TITLE && <AuctionOver />}
      {activeView === AUCTION_OVER_REVIEW_TITLE && (
        <AuctionOverReview onExit={setViewtoAuctionOver} />
      )}
    </>
  );
};

export default AuctionOverManager;
