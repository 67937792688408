import React from 'react';
import { Row, Col } from 'antd';
import { camelCase, isNull, isUndefined, startCase } from 'lodash';

const StoneDetails = ({ details, index }: any) => {
  const {
    stoneType,
    stoneShape,
    stonePoint,
    stoneQuantity,
    stoneNotes,
    stoneClarity,
    reportNumber,
    stoneClass,
  } = details;

  const getFieldValue = (property: any) => {
    if (isUndefined(property) || isNull(property) || property === 0) return '-';
    return startCase(camelCase(property));
  };

  const getTableRow = (name: any, value: any) => {
    return (
      <tr>
        <td>{name}</td>
        <td>{getFieldValue(value)}</td>
      </tr>
    );
  };

  return (
    <Row>
      <Col span={24}>
        <table className="valuation-tables">
          <tbody>
            {getTableRow(`Stone Type ${index + 1}`, stoneType?.description)}
            {getTableRow('Shape', stoneShape?.description)}
            {getTableRow('Color', stoneClarity?.description)}
            {getTableRow('Clarity', stoneClass?.description)}
            {getTableRow('Size (g)', stonePoint)}
            {getTableRow('Quantity', stoneQuantity)}
            {getTableRow('Report Number', reportNumber)}
            {getTableRow('Notes', stoneNotes)}
          </tbody>
        </table>
      </Col>
    </Row>
  );
};

export default StoneDetails;
