import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as pledgeActions from 'action_creators/pledge';
import {
  getItemPrice,
  getLoanPayableAmount,
  uploadImageCollection,
  getPledgeSummary,
} from 'services/pledge';
import { calculatePledgeLoanBasketSaga } from 'sagas/basket/index';
import { pledgeActions as actions } from 'redux/reducers/pawnbroking/index';
import { RESPONSE_STATUS } from 'globalConstants';

export function* getItemPriceValuationSaga(action) {
  try {
    const {
      payload: { params },
    } = action;

    const payload = yield call(getItemPrice, params);

    if (payload?.data) {
      yield put(actions.itemPriceValuationResponse(payload.data));
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* watchGetItemPriceValuation() {
  yield takeEvery(
    pledgeActions.PLEDGE_ITEM_PRICE_REQUEST,
    getItemPriceValuationSaga
  );
}

export function* getLoanPayableSaga(action) {
  try {
    const {
      payload: { params },
    } = action;
    const payload = yield call(getLoanPayableAmount, params);
    if (payload?.data) {
      const basketPayload = {
        ...params,
        ...payload.data,
      };
      if (params.basketId) {
        return yield call(calculatePledgeLoanBasketSaga, {
          payload: { params: basketPayload },
        });
      }
    } else if (payload?.errors) {
      yield put(actions.itemError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.itemError(e));
  }
}

export function* watchGetLoanPayable() {
  yield takeEvery(pledgeActions.PLEDGE_LOAN_PAYABLE, getLoanPayableSaga);
}

export function* getCustomerPawnbrokingTabSummary(action) {
  try {
    const {
      payload: { customerId },
    } = action;
    const payload = yield call(getPledgeSummary, customerId);
    if (payload?.data) {
      yield put(actions.setPawnbrokingSummary(payload?.data?.loans));
    } else if (
      payload?.status === RESPONSE_STATUS.NO_CONTENT ||
      payload?.errors
    ) {
      yield put(actions.setPawnbrokingSummary([]));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* watchCustomerPawnbrokingSummary() {
  yield takeEvery(
    pledgeActions.GET_CUSTOMER_PAWNBROKING_TAB_SUMMARY,
    getCustomerPawnbrokingTabSummary
  );
}

export function* uploadMulipleImageSaga(action) {
  try {
    const {
      payload: { image, isDefault },
    } = action;

    const { itemImageCollectionResponse: params } = yield select(
      (state) => state.pawnbroking
    );

    if (image) {
      yield put(actions.initImageUpload(true));

      const response = yield call(
        uploadImageCollection,
        image,
        params,
        isDefault
      );
      if (response?.data) {
        yield put(actions.setItemImageCollectionResponse(response.data));
        yield put(actions.initImageUpload(false));
      }
    }
  } catch (e) {
    yield put(actions.itemError(e));
    yield put(actions.initImageUpload(false));
  }
}

export function* watchUploadMultipleImageSaga() {
  yield takeEvery(
    pledgeActions.UPLOAD_MULTIPLE_IMAGE_REQUEST,
    uploadMulipleImageSaga
  );
}
