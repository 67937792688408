import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Row, Col } from 'antd';
import { Button } from 'components/common/Button';

import Webcam, { IMAGE_CATEGORY } from 'components/webcam';

const StyledModal = styled(Modal)`
  font-size: var(--font-size-16);
  text-align: center;
  & .ant-modal-header {
    padding: 27px 30px;
    text-align: center;
  }
`;

const StyledColOne = styled(Col)`
  padding: 20px 40px;
`;

const ItemPhotoPopup = ({
  visible,
  loading,
  photoPopupHandler,
  onImageUploadClick,
}: any) => {
  const [itemPhoto, setItemPhoto] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    if (!loading) {
      isUploading && photoPopupHandler();
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const getItemPhoto = (data: any) => {
    setItemPhoto(data);
  };

  const onAddClick = () => {
    onImageUploadClick(itemPhoto);
    setIsUploading(true);
  };

  const onCancelClick = () => {
    photoPopupHandler();
  };

  return (
    <StyledModal
      title="Add Item Photo"
      open={visible}
      width={720}
      destroyOnClose={true}
      centered
      onCancel={onCancelClick}
      footer={[
        <Row gutter={24} justify="end" key="footer">
          <Col key="1">
            <Button itemProp="secondary" key="back" onClick={onCancelClick}>
              Cancel
            </Button>
          </Col>
          <Col key="2">
            <Button loading={loading} htmlType="submit" onClick={onAddClick}>
              Add
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <Row>
        <StyledColOne span={24}>
          <Webcam
            isRetake={true}
            type={IMAGE_CATEGORY.ITEM_PHOTO}
            width={450}
            height={300}
            getItemPhoto={getItemPhoto}
          />
        </StyledColOne>
      </Row>
    </StyledModal>
  );
};

export default ItemPhotoPopup;
