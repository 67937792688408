import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  coinPrices: [],
  coinItemsTempData: [] as Array<any>,
  coinAllItemsData: [] as Array<any>,
  coinRemoveData: [] as Array<any>,
  coinEditData: [] as Array<any>,
  isCoinRowRemoved: false,
  coinRowId: 1,
  isCoinRowEdit: false,
};
const slice = createSlice({
  name: 'coinActions',
  initialState,
  reducers: {
    coinItemsPrice(state, action) {
      state.coinPrices = action.payload;
    },
    coinItemsTemp(state, action) {
      return {
        ...state,
        coinItemsTempData: action.payload,
        coinRemoveData: [],
        coinEditData: [],
        isCoinRowRemoved: false,
        isCoinRowEdit: false,
      };
    },
    coinAllItems(state, action) {
      return {
        ...state,
        coinAllItemsData: action.payload,
        coinItemsTempData: [],
        coinRemoveData: [],
        coinEditData: [],
        isCoinRowRemoved: false,
        isCoinRowEdit: false,
      };
    },
    removeCoinRow(state, action) {
      state.coinRemoveData = [...state.coinAllItemsData];
      const allData = [...state.coinAllItemsData];
      const tempData = [...state.coinItemsTempData];
      state.coinItemsTempData = tempData.filter(
        (item: any) => item.id !== action.payload.id
      );
      state.coinAllItemsData = allData.filter(
        (item: any) => item.id !== action.payload.id
      );
      if (state.coinAllItemsData?.length !== allData?.length) {
        state.isCoinRowRemoved = true;
      }
    },
    editCoinRow(state, action) {
      state.coinEditData = [...state.coinAllItemsData];
      state.isCoinRowEdit = true;
      if (action.payload.isIndexEdit)
        state.coinAllItemsData[action.payload.index] = action.payload.coinData;
      else
        state.coinItemsTempData[action.payload.index] = action.payload.coinData;
    },
    coinRowId(state, action) {
      state.coinRowId = action.payload;
    },
    clearCoinItems() {
      return {
        ...initialState,
      };
    },
  },
});

export default slice.reducer;
export const { reducer, actions: coinActions } = slice;
export { initialState };
