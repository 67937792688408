import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GRID_NO_URL } from 'globalConstants';
import VirtualGrid from 'components/common/virtualGrid/index';
import { actions } from 'redux/reducers/basket';

const BasketFxTable = (props: any) => {
  const rows = props?.items || [];
  const dispatch = useDispatch();
  const [selection, setSelection] = useState<Array<any>>([]);

  useEffect(() => {
    const payload = {
      serviceIds: selection,
    };
    //update redux merge payload when row selected
    dispatch(actions.move(payload));
  }, [selection]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectionChange = (row: any) => {
    setSelection(row);
  };

  const [columns] = useState([
    {
      name: 'transactionType',
      title: 'Service',
      getCellValue: (row: any) => row?.fx?.request?.transactionType,
    },
    {
      name: 'fromCurrencyCode',
      title: 'Currency From',
      type: 'currency-tooltip',
      getCellValue: (row: any) => ({
        currencyCode: row?.fx?.request?.fromCurrencyCode,
        amount: row?.fx?.request?.fromAmount,
        currencyDesc: row?.fx?.request?.fromCurrencyDescription,
      }),
      allowSorting: false,
    },
    {
      name: 'currencyExchangeRate',
      title: 'Exchange Rate',
      getCellValue: (row: any) => row?.fx?.request?.currencyExchangeRate,
    },
    {
      name: 'rateGuarantee',
      title: 'Guarantee',
      getCellValue: (row: any) => row?.fx?.request?.rateGuarantee,
    },
    {
      name: 'toCurrencyCode',
      title: 'Currency To',
      type: 'currency-tooltip',
      getCellValue: (row: any) => ({
        currencyCode: row?.fx?.request?.toCurrencyCode,
        amount: row?.fx?.request?.toAmount,
        currencyDesc: row?.fx?.request?.toCurrencyDescription,
      }),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'service', width: 200 },
    { columnName: 'fromCurrencyCode', width: 300 },
    { columnName: 'currencyExchangeRate', width: 250, align: 'right' },
    { columnName: 'rateGuarantee', width: 250, align: 'right' },
    { columnName: 'toCurrencyCode', width: 250, align: 'right' },
  ]);

  const formattedColumns = ['service', 'fromCurrencyCode', 'toCurrencyCode'];

  return (
    <>
      <div className="basket-item-table">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={rows}
          formattedColumns={formattedColumns}
          selection={selection}
          onSelectionChange={onSelectionChange}
          highlightRow={true}
          rowSelectionEnable={true}
        />
      </div>
    </>
  );
};

export default BasketFxTable;
