import React from 'react';
import { Modal, Row, Col } from 'antd';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import {
  RETRY,
  RECHECK_REMOVE_WARNING,
  RECHECK_TRANS_MESSAGE,
  REMOVE_FAILED_TRANSACTIONS,
  TRANS_MESSAGE,
  RECHECK_STATUS,
} from '../constants';

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    text-align: center;
  }
`;

const StyledWarningRow = styled(Row)`
  color: var(--red);
`;

const RecheckAlert = ({ visible, onClick }: any) => {
  const onOkClick = () => {
    onClick(false);
  };

  return (
    <StyledModal
      open={visible}
      closable={false}
      width={457}
      onOk={onOkClick}
      footer={false}
      className="alert-modal-wrapper"
    >
      <Row>
        <Col span={24}>
          <Row className="alert-modal-header" justify="center" align="middle">
            Alert
          </Row>
          <Row className="alert-modal-body" justify="center" align="middle">
            {TRANS_MESSAGE}
          </Row>
        </Col>
      </Row>
      <Row className="alert-modal-body" justify="center" align="middle">
        <Button itemProp="secondary" key="submit" onClick={onOkClick}>
          {RECHECK_STATUS}
        </Button>
      </Row>
    </StyledModal>
  );
};

const RecheckRetryAlert = ({ visible, onClick, onRemoveTransaction }: any) => {
  const onOkClick = () => {
    onClick(false);
  };

  return (
    <Modal
      open={visible}
      closable={false}
      width={466}
      onOk={onOkClick}
      className="alert-modal-wrapper"
      footer={false}
      maskClosable={false}
    >
      <Row>
        <Col span={24}>
          <Row className="alert-modal-header" justify="center" align="middle">
            Alert
          </Row>
          <Row className="alert-modal-body" justify="center" align="middle">
            <div
              dangerouslySetInnerHTML={{ __html: RECHECK_TRANS_MESSAGE }}
            ></div>
          </Row>
          <Row className="alert-modal-body" justify="center" align="middle">
            <Button
              itemProp="secondary"
              key="submit"
              style={{ margin: '10px 0' }}
              onClick={onOkClick}
            >
              {RETRY}
            </Button>
          </Row>
          <Row className="alert-modal-body" justify="center" align="middle">
            <Button
              itemProp="secondary"
              key="submit"
              style={{ margin: '10px 0' }}
              onClick={onRemoveTransaction}
            >
              {REMOVE_FAILED_TRANSACTIONS}
            </Button>
          </Row>
          <StyledWarningRow
            className="alert-modal-body"
            justify="center"
            align="middle"
          >
            {RECHECK_REMOVE_WARNING}
          </StyledWarningRow>
        </Col>
      </Row>
    </Modal>
  );
};

export { RecheckAlert, RecheckRetryAlert };
