import React, { useState } from 'react';
import { GRID_NO_URL } from 'globalConstants';
import VirtualGrid from 'components/common/virtualGrid/index';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  height: 82%;
`;

const TelephoneListTable = ({ telephoneList = [] }: any) => {
  const [columns] = useState([
    {
      name: 'storeId',
      title: 'Store ID',
      getCellValue: (row: any) => row?.storeId,
    },
    {
      name: 'storeName',
      title: 'Store Name',
      getCellValue: (row: any) => row?.storeName,
    },
    {
      name: 'areaManager',
      title: 'Area Manager',
      getCellValue: (row: any) => row?.areaManager,
    },
    {
      name: 'storeManager',
      title: 'Store Manager',
      getCellValue: (row: any) => row?.storeManager,
    },
    {
      name: 'storeAddress',
      title: 'Store Address',
      getCellValue: (row: any) => row?.storeAddress,
    },
    {
      name: 'postCode',
      title: 'Postcode',
      getCellValue: (row: any) => row?.postcode,
    },
    {
      name: 'telephoneNumber',
      title: 'Telephone Number',
      getCellValue: (row: any) => {
        return <a href="tel:+">{row?.telephoneNumber}</a>;
      },
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'storeId', width: '8%' },
    { columnName: 'storeName', width: '14%' },
    { columnName: 'areaManager', width: '14%' },
    { columnName: 'storeManager', width: '10%' },
    { columnName: 'storeAddress', width: '30%' },
    { columnName: 'postCode', width: '10%', align: 'center' },
    { columnName: 'telephoneNumber', width: '14%', align: 'center' },
  ]);

  return (
    <>
      <StyledWrapper>
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={telephoneList}
        />
      </StyledWrapper>
    </>
  );
};

export default TelephoneListTable;
