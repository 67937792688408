import React, { useState, useEffect, useCallback } from 'react';
import { Select, Input, Form } from 'antd';
import styled from 'styled-components';
import {
  BASE_PLEDGE_CATEGORIES,
  HALLMARKED_OPTIONS,
  ITEM_TYPE,
  PLEDGE_ITEM,
  JEWELLERY_MANDATORY_FIELDS,
  COINS_OR_WATCHES_MANDATORY_FIELDS,
} from 'components/pledge/constants';
import debounce from 'lodash/debounce';
import { useSelector, useDispatch } from 'react-redux';
import { pledgeActions } from 'redux/reducers/pawnbroking/index';
import isEmpty from 'lodash/isEmpty';
import AlertsPopup from 'components/pledge/addPledge/alertsPopup';
import HelpPopoverInfo from '../../../common/HelpPopoverInfo';
import { reducePledgeItems } from 'utils/util';
import { AUCTION_ITEM_PRICE_REQUEST } from 'action_creators/pledgeManagement';

const StyledTable = styled.table`
  background: var(--white);
  border: 1px solid var(--light-gray);
  border-radius: 4px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  & tr:last-of-type {
    & td {
      border-bottom: none;
    }
  }
  table-layout: fixed;
  & .error-field {
    border: 1px solid red;
  }
  & .ant-input {
    padding: 0px 11px;
  }
`;

const StyledTableCellLeft = styled.td`
  padding: 0px 10px !important;
  text-align: left;
  border-right: 1px solid var(--light-gray);
  border-bottom: 1px solid var(--light-gray);
  &:last-of-type {
    border-bottom: none;
  }
  width: 150px;
  font-weight: 500 !important;
`;

const StyledTableCellRight = styled.td`
  padding: 0px 0px !important;
  border-bottom: 1px solid var(--light-gray);
  & .ant-select {
    width: 100%;
    padding: 4px 0;
    & .ant-select-selector {
      border: none;
      box-shadow: none !important;
      border-color: transparent !important;
    }
  }
  & .ant-select-disabled > .ant-select-selector {
    background-color: var(--white) !important;
  }
  & .ant-input.ant-input-disabled {
    background-color: var(--white);
  }
  & .error-field {
    border: 1px solid red !important;
  }
  & .text-danger {
    color: var(--dark-red);
    font-weight: var(--font-weight-500);

    & .ant-select-selection-item {
      font-weight: var(--font-weight-500);
    }
  }
`;

const StyledTextArea = styled(Input.TextArea)`
  resize: none;
  border: none;
  &:focus {
    outline: 0 !important;
    border: none !important;
  }
`;

const StyledInput = styled(Input)`
  height: 44px;
  border: none;
  width: 100%;
`;

interface PledgeItemDescriptionProps {
  itemFixedAttributes: Array<any>;
  isPledgeItemFormValidate: boolean;
  setPledgeItemFormValidate: () => void;
  updateItemReview: (row: any) => void;
}

interface ItemAttributeProps {
  id: number;
  description: string;
  itemFixedAttributes: Array<any>;
}

const PledgeItemDescription = ({
  itemFixedAttributes,
  isPledgeItemFormValidate,
  setPledgeItemFormValidate,
  updateItemReview,
}: PledgeItemDescriptionProps) => {
  const {
    stone: { stonePrices },
    coin: { coinAllItemsData },
    pawnbroking: { itemCategory, pledgeItemsValue },
    pledgeManagement: { pledgeItemDetails },
  } = useSelector((state: any) => {
    return {
      stone: state.stone,
      coin: state.coin,
      pawnbroking: state.pawnbroking,
      pledgeManagement: state.pledgeManagement,
    };
  });

  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState<number | string>('');
  const [errorFields, setErrorFields] = useState<any>([]);
  const [isAlertsVisible, setAlertsVisible] = useState<boolean>(false);
  const [isCoinError, setCoinError] = useState<boolean>(false);

  useEffect(() => {
    if (stonePrices.length > 0 || coinAllItemsData.length > 0)
      onItemAttributeChange();
  }, [stonePrices, coinAllItemsData]);

  const getItemTableRowOptions = (item: string) => {
    return itemFixedAttributes.filter((x) => item === x.description);
  };

  const checkWeightGreaterError = () => {
    if (
      itemCategory.id === BASE_PLEDGE_CATEGORIES[0].id &&
      pledgeItemsValue?.coinPrice?.weight > 0 &&
      form.getFieldValue('weightInGrams') !== undefined &&
      +form.getFieldValue('weightInGrams') !== 0 &&
      pledgeItemsValue?.coinPrice?.weight > form.getFieldValue('weightInGrams')
    ) {
      const weightGreaterError = {
        name: ['weightInGrams'],
        errors: ['Weight must be greater than the coin weight'],
      };
      return weightGreaterError;
    }
    return undefined;
  };
  const validatePledgeItems = async () => {
    const weightGreaterError = checkWeightGreaterError();
    const coinError = isCoinError
      ? {
          name: ['addCoins'],
          errors: ['Please add a Coin.'],
        }
      : undefined;
    try {
      await form.validateFields();
      if (isEmpty(weightGreaterError) && isEmpty(coinError)) {
        setErrorFields([]);
        dispatch(pledgeActions.updateAddToPledgePurchase(true));
      } else {
        setErrorFields([coinError, weightGreaterError]);
      }
    } catch (errorInfo: any) {
      if (isEmpty(weightGreaterError)) {
        setErrorFields([...errorInfo.errorFields, coinError]);
        dispatch(pledgeActions.updateAddToPledgePurchase(false));
      } else {
        setErrorFields([
          ...errorInfo.errorFields,
          coinError,
          weightGreaterError,
        ]);
      }
    }
    setPledgeItemFormValidate();
  };

  const checkMandatoryFields = (fieldName: string) => {
    switch (itemCategory.id) {
      case BASE_PLEDGE_CATEGORIES[0].id: //Jewellery
        return JEWELLERY_MANDATORY_FIELDS.includes(fieldName);
      case BASE_PLEDGE_CATEGORIES[1].id: // Watches
        return COINS_OR_WATCHES_MANDATORY_FIELDS.includes(fieldName);
      case BASE_PLEDGE_CATEGORIES[2].id: // Coins
        if (coinAllItemsData?.length > 0) {
          if (isCoinError) setCoinError(false);
          return false;
        } else if (
          coinAllItemsData?.length === 0 &&
          form.getFieldValue('metalCondition') === undefined &&
          form.getFieldValue('metalType') === undefined &&
          form.getFieldValue('fineness') === undefined &&
          form.getFieldValue('weightInGrams') === undefined
        ) {
          if (!isCoinError) setCoinError(true);
          return false;
        } else if (
          coinAllItemsData?.length === 0 &&
          (form.getFieldValue('metalCondition') !== undefined ||
            form.getFieldValue('metalType') !== undefined ||
            form.getFieldValue('fineness') !== undefined ||
            form.getFieldValue('weightInGrams') !== undefined)
        ) {
          if (isCoinError) setCoinError(false);
          return COINS_OR_WATCHES_MANDATORY_FIELDS.includes(fieldName);
        } else return false;
      default:
        return false;
    }
  };

  const onItemAttributeChange = () => {
    const coinItemsFromAddCoin = reducePledgeItems(coinAllItemsData);
    const params = {
      categoryId: itemCategory?.id,
      metalPrice: {
        metalCondition: form.getFieldValue('metalCondition'),
        metalType: form.getFieldValue('metalType'),
        fineness: form.getFieldValue('fineness'),
        weightInGrams: form.getFieldValue('weightInGrams'),
        isHallMark: form.getFieldValue('isHallmark'),
        notes: form.getFieldValue('notes'),
      },
      coinPrices: [...coinItemsFromAddCoin],
      stonePrices: [...stonePrices],
    };
    if (
      form.getFieldValue('metalCondition') !== undefined &&
      form.getFieldValue('metalType') !== undefined &&
      form.getFieldValue('fineness') !== undefined &&
      form.getFieldValue('weightInGrams') !== undefined &&
      form.getFieldValue('weightInGrams') !== ''
    ) {
      dispatch({
        type: AUCTION_ITEM_PRICE_REQUEST,
        payload: { params: params },
      });
    }
    updateItemReview(params);
  };

  const delayedQuery = useCallback(() => {
    debounce(onItemAttributeChange, 300)();
  }, [itemCategory, stonePrices, reducePledgeItems(coinAllItemsData)]); // eslint-disable-line react-hooks/exhaustive-deps

  let start = 0;

  const onInputChange = (e: any) => {
    start = e.target.selectionStart;
    let val = e.target.value;
    val = val.replace(/([^0-9.]+)/, '');
    val = val.replace(/^(0|\.)/, '');
    const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
    const value =
      match !== undefined && match !== null && match.length > 0
        ? match[1] + match[2]
        : '0';
    e.target.value = value;
    if (+value === 0) {
      setInputValue('');
      form.setFieldsValue({ weightInGrams: undefined });
    } else {
      setInputValue(value);
      form.setFieldsValue({ weightInGrams: value });
    }
    if (val.length > 0) {
      let weightInGrams = Number(value).toFixed(2);
      weightInGrams = Number.isNaN(+weightInGrams)
        ? Number(0.0).toFixed(1)
        : weightInGrams;

      const isDecimalIncluded = val.includes('.');
      weightInGrams =
        !isDecimalIncluded && val.length > 1
          ? Number(+weightInGrams / 100).toFixed(2)
          : weightInGrams;

      e.target.value = weightInGrams;
      e.target.setSelectionRange(start, start);
      setInputValue(weightInGrams);
      form.setFieldsValue({ weightInGrams: weightInGrams });
    }
    delayedQuery();
  };

  useEffect(() => {
    form.setFieldsValue({ ...pledgeItemDetails?.metal });
  }, [pledgeItemDetails, form]);

  const itemDescriptionRow = (
    fieldName: string,
    label: string,
    options: Array<ItemAttributeProps> = [],
    input = false,
    rowValue: any
  ) => {
    const itemOptions = options[0]?.itemFixedAttributes;
    const value = form.getFieldValue(fieldName);
    const isValueChanged =
      value === undefined
        ? false
        : value !== pledgeItemDetails?.metal?.[fieldName];

    return (
      <tr>
        <StyledTableCellLeft>
          {label}
          <span style={{ float: 'right', marginRight: '35px' }}>
            <HelpPopoverInfo
              linkedID={`${fieldName}_${label}`}
            ></HelpPopoverInfo>
          </span>
        </StyledTableCellLeft>
        <StyledTableCellRight
          className={
            errorFields?.some((item: any) => item?.name[0] === fieldName)
              ? 'error-field'
              : ''
          }
        >
          {itemOptions?.length ? (
            <Form.Item
              name={fieldName}
              label={label}
              noStyle
              rules={[
                {
                  required: checkMandatoryFields(fieldName),
                  message: `${label} is required`,
                },
              ]}
            >
              <Select
                placeholder="Select"
                onChange={onItemAttributeChange}
                disabled={!pledgeItemDetails?.metal}
                className={isValueChanged ? 'text-danger' : ''}
              >
                {itemOptions.map((item: any) => {
                  return (
                    <Option value={item.id} key={item.id}>
                      {item.description}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          ) : input ? (
            <Form.Item
              label={label}
              name={fieldName}
              noStyle
              rules={[
                {
                  required: checkMandatoryFields(fieldName),
                  message: `${label} is required`,
                },
              ]}
            >
              <StyledInput
                type="text"
                onChange={onInputChange}
                value={inputValue ? inputValue : rowValue}
                autoComplete="off"
                disabled={!pledgeItemDetails?.metal}
                placeholder="0.00"
                className={isValueChanged ? 'text-danger' : ''}
              />
            </Form.Item>
          ) : (
            <Form.Item name={fieldName} label={label} noStyle>
              <StyledTextArea
                rows={2}
                maxLength={128} //as per what we have in NEO
                autoComplete="off"
                disabled={!pledgeItemDetails?.metal}
                onChange={debounce(onItemAttributeChange, 300)}
                className={isValueChanged ? 'text-danger' : ''}
              />
            </Form.Item>
          )}
        </StyledTableCellRight>
      </tr>
    );
  };

  useEffect(() => {
    if (isEmpty(itemCategory)) form.resetFields();
  }, [itemCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isPledgeItemFormValidate) validatePledgeItems();
  }, [isPledgeItemFormValidate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorFields?.length > 0) {
      setAlertsVisible(true);
    }
  }, [errorFields]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setErrorFields([]);
    setCoinError(false);
  }, [itemCategory]);

  return (
    <>
      <Form form={form}>
        <StyledTable>
          <tbody>
            {itemDescriptionRow(
              PLEDGE_ITEM.CONDITION.NAME,
              PLEDGE_ITEM.CONDITION.LABEL,
              getItemTableRowOptions(PLEDGE_ITEM.CONDITION.LABEL.toUpperCase()),
              true,
              pledgeItemDetails?.metal?.metalCondition
            )}
            {itemDescriptionRow(
              PLEDGE_ITEM.METAL_TYPE.NAME,
              PLEDGE_ITEM.METAL_TYPE.LABEL,
              getItemTableRowOptions(ITEM_TYPE.METAL_TYPES),
              true,
              pledgeItemDetails?.metal?.metalType
            )}
            {itemDescriptionRow(
              PLEDGE_ITEM.FINENESS.NAME,
              PLEDGE_ITEM.FINENESS.LABEL,
              getItemTableRowOptions(PLEDGE_ITEM.FINENESS.LABEL.toUpperCase()),
              true,
              pledgeItemDetails?.metal?.fineness
            )}
            {itemDescriptionRow(
              PLEDGE_ITEM.HALLMARKED.NAME,
              PLEDGE_ITEM.HALLMARKED.LABEL,
              HALLMARKED_OPTIONS,
              true,
              pledgeItemDetails?.metal?.isHallmark
            )}
            {itemDescriptionRow(
              PLEDGE_ITEM.WEIGHT.NAME,
              PLEDGE_ITEM.WEIGHT.LABEL,
              [],
              true,
              pledgeItemDetails?.metal?.weightInGrams
            )}
            {itemDescriptionRow(
              PLEDGE_ITEM.NOTES.NAME,
              PLEDGE_ITEM.NOTES.LABEL,
              [],
              false,
              pledgeItemDetails?.metal?.notes
            )}
          </tbody>
        </StyledTable>
      </Form>
      {isAlertsVisible && (
        <AlertsPopup
          visible={isAlertsVisible}
          setAlertsVisible={() => setAlertsVisible(false)}
          errorFields={errorFields}
        />
      )}
    </>
  );
};

export default PledgeItemDescription;
