import * as urls from 'services/apiUrls';
import { doPaymentRequest, doRequest } from 'utils/request';

/** start pure basket serivces */

const getBasket = (params) => {
  return doRequest(`${urls.GET_BASKET}/${params}`, 'get'); // get basket by customerId
};

const deleteBasket = (params) => {
  return doRequest(`${urls.BASKET_CREATE_PLEDGE_ITEMS}`, 'delete', params);
};

const deleteCustomerBasket = (params) => {
  return doRequest(`${urls.BASKET_BY_BASKET_ID}/${params}`, 'delete');
};

/** End pure basket serivces */

/** pledge retated basket serivces */
/** create pledge and pledge item into basket */
const postPledgeIntoBasket = (params) => {
  return doRequest(`${urls.BASKET_CREATE_PLEDGE_ITEMS}`, 'post', params);
};
const postUpdatedCustomerDetails = (params) => {
  return doRequest(`${urls.UPDATE_CUSTOMER_DETAILS}`, 'post', params);
};

/** update pledge into basket */
const putPledgeIntoBasket = (params) => {
  return doRequest(`${urls.BASKET_UPDATE_PLEDGE_ITEMS}`, 'put', params);
};

/** delete pledge from basket */
const deletePledgeFromBasket = (params) => {
  return doRequest(`${urls.BASKET_DELETE_PLEDGE_ITEMS}`, 'delete', params);
};

/** split pledge basket */
const splitPledgeIntoBasket = (params) => {
  return doRequest(`${urls.BASKET_SPLIT_PLEDGE_ITEMS}`, 'post', params);
};

/** move pledge item from exiting to new create pledge into basket */
const movePledgeItemsBasket = (params) => {
  return doRequest(`${urls.BASKET_MOVE_PLEDGE_ITEMS}`, 'post', params);
};

/** merge pledge basket */
const mergePledgeIntoBasket = (params) => {
  return doRequest(`${urls.BASKET_MERGE_PLEDGE_ITEMS}`, 'post', params);
};

/** move pledge/purchase items from exiting to a purchase/pledge into basket */
const movePledgePurchaseItemsBasket = (params) => {
  return doRequest(`${urls.BASKET_MOVE_PLEDGE_PURCHASE_ITEMS}`, 'post', params);
};

/** update pledge loan amount, loan amount payable and daily interest into basket */
const putPledgeLoanAmountIntoBasket = (params) => {
  return doRequest(`${urls.BASKET_PLEDGE_LOAN_AMOUNT}`, 'put', params);
};
/** end pledge related basket services */

/** start retail retated basket serivces */
/** create retail and retail item into basket */
const postRetailIntoBasket = (params) => {
  return doRequest(`${urls.BASKET_CREATE_RETAIL_ITEMS}`, 'post', params);
};

/** update retail into basket */
const putRetailIntoBasket = (params) => {
  return doRequest(`${urls.BASKET_UPDATE_RETAIL_ITEMS}`, 'put', params);
};

/** delete retail from basket */
const deleteRetailFromBasket = (params) => {
  return doRequest(`${urls.BASKET_DELETE_RETAIL_ITEMS}`, 'delete', params);
};
/** end retail related basket serivces */

/** create/update pickup-renew basket  */
const postPickupRenewIntoBasket = (params) => {
  return doRequest(`${urls.BASKET_CREATE_PICKUP_ITEMS}`, 'post', params);
};

/** delete pickup-renew item from basket */
const deletePickupRenewFromBasket = (params) => {
  return doRequest(`${urls.BASKET_DELETE_PICKUP_ITEMS}`, 'delete', params);
};

/**delete fx from basket */
const deleteFxTransaction = (params) => {
  return doRequest(`${urls.FX_CURRENCY_TRANSACTION}`, 'delete', params);
};

/** Create/Update cheque cashing basket items */
const postChequeCashingIntoBasket = (params) => {
  return doRequest(`${urls.BASKET_CHEQUE_CASHING_ITEMS}`, 'post', params);
};

const putChequeCashingIntoBasket = (params) => {
  return doRequest(`${urls.BASKET_CHEQUE_CASHING_ITEMS}`, 'put', params);
};

/** delete cheque cashing from basket */
const deleteChequeCashingFromBasket = (params) => {
  return doRequest(
    `${urls.DELETE_CHECK_CASHING_FROM_BASKET}`,
    'delete',
    params
  );
};

/** delete any service from basket */
const deleteBasketService = (params) => {
  return doRequest(`${urls.DELETE_BASKET_SERVICE}`, 'delete', params);
};

/** merge guest basket */
const postMergeGuestBasket = (params) => {
  return doRequest(`${urls.MERGE_GUEST_BASKET}`, 'post', params);
};

/**post western union */
const postWesternUnionBasket = (params) => {
  return doRequest(`${urls.WESTERN_UNION}`, 'post', params);
};

/** delete western union from basket */
const deleteWesternUnionFromBasket = (params) => {
  return doRequest(`${urls.WESTERN_UNION}`, 'delete', params);
};

/** create/update purchase return basket  */
const postPurchaseReturnIntoBasket = (params) => {
  return doRequest(`${urls.BASKET_CREATE_PURCHASE_RETURN}`, 'post', params);
};

/** delete purchase return item from basket */
const deletePurchaseReturnItemFromBasket = (params) => {
  return doRequest(`${urls.BASKET_DELETE_PURCHASE_RETURN}`, 'delete', params);
};

/** max 10 items in pledge/purchase */
const putTenItemsInPledge = (id) => {
  return doRequest(`${urls.MAX_TEN_ITEMS_PLEDGE}/${id}`, 'put');
};
const privacyNotice = (params) => {
  return doRequest(
    `${urls.DOWNLOAD_PRIVACY_NOTICE}`,
    'get',
    params,
    'application/json',
    false,
    'arraybuffer'
  );
};

const recallAllGuestBaskets = (storeId) => {
  return doRequest(`${urls.RECALL_ALL_GUEST_BASKETS}/${storeId}`, 'get');
};

const postSurplusBasket = (params) => {
  return doRequest(`${urls.BASKET_SURPLUS_ITEMS}`, 'post', params);
};

const deleteSurplusItemFromBasket = (params) => {
  return doRequest(`${urls.BASKET_SURPLUS_ITEMS}`, 'delete', params);
};

const getMtcnValidation = (params) => {
  return doRequest(`${urls.VALIDATE_MTCN_WESTERNUNION}`, 'post', params);
};

const updateFXCustomerDocId = (params) => {
  return doRequest(`${urls.UPDATE_FX_CUSTOMER_DOCUMENT_ID}`, 'post', params);
};

const completeFasterPayments = (params) => {
  return doPaymentRequest(`${urls.ACKNOWLEDGE_FASTER_PAYMENT}`, 'post', params);
};

export {
  getBasket,
  deleteBasket,
  deleteCustomerBasket,
  postPledgeIntoBasket,
  putPledgeIntoBasket,
  deletePledgeFromBasket,
  splitPledgeIntoBasket,
  movePledgeItemsBasket,
  mergePledgeIntoBasket,
  movePledgePurchaseItemsBasket,
  putPledgeLoanAmountIntoBasket,
  postRetailIntoBasket,
  putRetailIntoBasket,
  deleteRetailFromBasket,
  postPickupRenewIntoBasket,
  deletePickupRenewFromBasket,
  deleteFxTransaction,
  putChequeCashingIntoBasket,
  postChequeCashingIntoBasket,
  deleteChequeCashingFromBasket,
  deleteBasketService,
  postMergeGuestBasket,
  postWesternUnionBasket,
  deleteWesternUnionFromBasket,
  postPurchaseReturnIntoBasket,
  deletePurchaseReturnItemFromBasket,
  putTenItemsInPledge,
  privacyNotice,
  recallAllGuestBaskets,
  postSurplusBasket,
  deleteSurplusItemFromBasket,
  getMtcnValidation,
  postUpdatedCustomerDetails,
  updateFXCustomerDocId,
  completeFasterPayments,
};
