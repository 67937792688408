import React from 'react';
import { Col, Form, Input, Row } from 'antd';
import styled from 'styled-components';
import { FNS_DATE_FORMAT } from 'globalConstants';
import InputDecimal from 'components/common/InputDecimal';
import { getInterestCalculatedData } from 'services/accessories';
import { DAILY_INTEREST_TABS } from 'components/layout/constants';
import { add } from 'date-fns';
import moment from 'moment';

import { Button } from 'components/common/Button';
import DatePickerMUI from 'components/common/DatePickerMUI';

const StyledButton = styled(Button)`
  width: 73px;
  height: 42px;
`;

const StyledDiv = styled.div`
  background-color: #f1f5fc;
  margin-bottom: 30px;
  padding: 25px 55px;
`;

const StyledInputDecimal = styled(InputDecimal)`
  width: 70% !important;
  &::placeholder {
    font-weight: 700;
    color: var(--black);
  }
  & .ant-input-group-addon {
    width: 48px;
    background: var(--catskill-white);
  }
  & input {
    height: 42px;
  }
`;

const StyledInput = styled(Input)`
  &::placeholder {
    font-weight: 700;
    color: var(--black);
  }
  & .ant-input-group-addon {
    width: 48px;
    background: var(--catskill-white);
  }
  & input {
    height: 42px;
  }
`;

const StyledORCol = styled(Col)`
  font-weight: 500;
  text-align: center;
  padding: 35px 0 15px;
  color: var(--black);
`;

const StyledMainContainer = styled(Col)`
  margin: 0px;
`;

const StyledRow = styled(Row)`
  margin-bottom: 15px;

  & .label-col {
    text-align: right;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-500);
    line-height: 16px;

    padding: 12px 15px;
  }
`;

const StyledFooter = styled(Row)`
  padding: 5px 0 30px;
  justify-content: center;
`;

const DailyInterestCalculator = ({ onCancelClick }: any) => {
  const [form] = Form.useForm();

  const onReset = () => {
    form.resetFields();
  };

  const getCalculatedData = async () => {
    const { amount, noOfDays, monthlyInterestRate } = form.getFieldsValue();
    const paramsWithDate = {
      amount,
      monthlyInterestRate: monthlyInterestRate || 0,
      dueDate:
        add(new Date(), {
          months: 6,
        }) || '',
    };
    const paramsWithDays = {
      amount,
      monthlyInterestRate: monthlyInterestRate || 0,
      noOfDays,
    };

    const params = noOfDays ? paramsWithDays : paramsWithDate;
    try {
      const response = await getInterestCalculatedData(params);

      if (response?.data) {
        form.setFieldsValue({
          monthlyInterestRate: response?.data?.monthlyInterestPercentCharge,
          dailyInterestRate: (response?.data?.dailyInterestRate).toFixed(2),
          publishedInterestAnnualPercentageRate:
            (response?.data?.publishedInterestAnnualPercentageRate).toFixed(2),
          loanPayableAmount: (response?.data?.loanPayableAmount).toFixed(2),
        });
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const onDateChange = (value: any) => {
    form.setFieldsValue({ dueDate: value });
    getCalculatedData();
  };

  return (
    <>
      <StyledMainContainer span={24} className="accesories-container">
        <div className="daily-item-table">
          <Form
            layout="vertical"
            className="daily-interest-calculator"
            form={form}
          >
            <StyledDiv>
              <Row gutter={15}>
                <Col span={7}>
                  <Form.Item
                    label="Loan Value"
                    name="amount"
                    className="label-control"
                    rules={[
                      {
                        required: true,
                        message: 'Enter loan Amount',
                      },
                    ]}
                  >
                    <StyledInput
                      addonBefore={'£'}
                      placeholder="0.00"
                      onBlur={getCalculatedData}
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    label="Due Date"
                    name="dueDate"
                    className="label-control"
                  >
                    <DatePickerMUI
                      disableFutureDates={false}
                      initialValue={moment(
                        add(new Date(), {
                          months: 6,
                        }),
                        FNS_DATE_FORMAT
                      )}
                      onChange={onDateChange}
                    />
                  </Form.Item>
                </Col>
                <StyledORCol span={3}>Or</StyledORCol>
                <Col span={7}>
                  <Form.Item
                    label="No Of Days"
                    name="noOfDays"
                    className="label-control"
                    rules={[
                      {
                        message: 'Enter no. of days',
                      },
                    ]}
                  >
                    <StyledInput
                      size="large"
                      className="no-of-days"
                      onChange={getCalculatedData}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </StyledDiv>

            <Row>
              <Col span={24}>
                <StyledRow>
                  <Col span={11} className="label-col">
                    {DAILY_INTEREST_TABS.MONTHLY_INTEREST_RATE}
                  </Col>
                  <Col span={11} className="input-col">
                    <Form.Item
                      name="monthlyInterestRate"
                      className="label-control"
                    >
                      <StyledInputDecimal
                        addonBefore={'%'}
                        onBlur={getCalculatedData}
                      />
                    </Form.Item>
                  </Col>
                </StyledRow>
                <StyledRow>
                  <Col span={11} className="label-col">
                    {DAILY_INTEREST_TABS.DAILY_INTEREST}
                  </Col>
                  <Col span={11} className="input-col">
                    <Form.Item
                      name="dailyInterestRate"
                      className="label-control"
                    >
                      <StyledInputDecimal />
                    </Form.Item>
                  </Col>
                </StyledRow>
                <StyledRow>
                  <Col span={11} className="label-col">
                    {DAILY_INTEREST_TABS.APR}
                  </Col>
                  <Col span={11} className="input-col">
                    <Form.Item
                      name="publishedInterestAnnualPercentageRate"
                      className="label-control"
                    >
                      <StyledInputDecimal addonBefore={'%'} />
                    </Form.Item>
                  </Col>
                </StyledRow>
                <StyledRow>
                  <Col span={11} className="label-col">
                    {DAILY_INTEREST_TABS.DUE}
                  </Col>
                  <Col span={11} className="input-col">
                    <Form.Item
                      name="loanPayableAmount"
                      className="label-control"
                    >
                      <StyledInputDecimal />
                    </Form.Item>
                  </Col>
                </StyledRow>
              </Col>
            </Row>

            <StyledFooter gutter={10}>
              <Col span={3}>
                <StyledButton itemProp="secondary" onClick={onReset}>
                  Clear
                </StyledButton>
              </Col>
              <Col span={3}>
                <StyledButton itemProp="secondary" onClick={onCancelClick}>
                  Close
                </StyledButton>
              </Col>
            </StyledFooter>
          </Form>
        </div>
      </StyledMainContainer>
    </>
  );
};

export default DailyInterestCalculator;
