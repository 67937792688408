import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash';
import { notification } from 'antd';

import * as customerActions from 'action_creators/customer';
import * as basketActions from 'action_creators/basket';
import {
  getCustomer,
  uploadCustomerImage,
  postCustomer,
  uploadCustomerDoc,
  performOCRDrivingLicence,
  performOCRPassport,
  getCustomerLastVisits,
  createLiteCustomer,
  updateTMCustomerImage,
  getCustomerVulnerableStatus,
  uploadNomineeImage,
} from 'services/customer';
import {
  getTMGuestDocumenList,
  uploadTravelMoneyGuestDocuments,
  validateFXTransaction,
} from 'services/travelMoney';
import {
  postUpdatedCustomerDetails,
  updateFXCustomerDocId,
} from 'services/basket';

import { IMAGE_CATEGORY } from 'components/webcam';
import { actions } from 'redux/reducers/customer';
import { actions as basketReduxActions } from 'redux/reducers/basket';
import { actions as customerReduxActions } from 'redux/reducers/customer';

import {
  FNS_DATE_FORMAT,
  GENERIC_ERROR,
  RESPONSE_STATUS,
} from 'globalConstants';
import {
  ERROR_MESSAGE_ON_NOIMINEE_UPLOAD_IMAGE,
  SUCCESS_MESSAGE_ON_NOMINEE_UPLOAD_IMAGE,
} from 'components/customer/constants';

/**
 * this saga handles the GET customer profile
 * (1) get customer profile
 * (2) handle response
 */
export function* getCustomerProfileSaga(action) {
  try {
    const {
      payload: { customerId },
    } = action;

    yield put(actions.getCustomerRequest());

    //request get customer profile from API
    const payload = yield call(getCustomer, customerId);
    if (payload?.data) {
      yield put(actions.setIsCustomerCompleteProfileLoaded(true));
      yield put(actions.getCustomerResponse(payload.data));
      yield put({ type: basketActions.GET_BASKET });
      yield put({
        type: customerActions.GET_PROFILE_STATUS,
        payload: { customerId },
      });
      yield put(actions.setGuestType(''));
    } else if (payload?.errors) {
      yield put(actions.getCustomerError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.getCustomerError(e));
  }
}

/**
 * this saga handles the GET customer profile in background and sync with redux store without page relaod
 * (1) get customer profile
 * (2) handle response
 */
export function* getCustomerProfileSagaInBackground(action) {
  try {
    const {
      payload: { customerId },
    } = action;

    //request get customer profile from API
    const payload = yield call(getCustomer, customerId);
    if (payload?.data) {
      yield put(actions.setIsCustomerCompleteProfileLoaded(true));
      yield put(actions.getCustomerResponse(payload.data));
      yield put({
        type: customerActions.GET_PROFILE_STATUS,
        payload: { customerId },
      });
      yield put(actions.setGuestType(''));
    } else if (payload?.errors) {
      yield put(actions.getCustomerError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.getCustomerError(e));
  }
}

/**
 * this saga handles the POST customer profile image
 * (1) post customer profile
 * (2) handle response
 */
export function* uploadCustomerImageSaga(action) {
  try {
    const {
      payload: { image, isCustomerCreated },
    } = action;
    if (image) {
      yield put(actions.setImage(image));
    }

    const { customer, imageData } = yield select((state) => state.customer);

    if (!customer?.customerId) {
      return;
    }
    yield put(actions.uploadImageRequest());
    //send post customer profile image from API
    const payload = yield call(
      uploadCustomerImage,
      imageData,
      customer.customerId
    );
    if (payload?.status) {
      if (isCustomerCreated) {
        yield put(
          actions.uploadImageAndDocResponse({
            message: payload?.status,
            isImageUploading: false,
          })
        );
      } else {
        yield put(actions.uploadImageResponse(payload?.status));
        yield delay(5);
        yield put(actions.clearUploadImage());
      }
    }
    return payload;
  } catch (e) {
    yield put(actions.uploadImageError(e));
  }
}

/**
 * this saga handles the POST customer profile image
 * (1) post customer profile
 * (2) handle response
 */
export function* customerProfileSaveSaga(action) {
  try {
    const {
      payload: { data },
    } = action;

    const { newCustomerDocs } = yield select((state) => state.customer);
    const documentsCopy = cloneDeep(newCustomerDocs);

    yield put(actions.customerSaveRequest());
    //send post customer profile image from API
    const payload = yield call(postCustomer, data);
    if (payload?.data) {
      yield put(actions.setGdprAcceptance(false));
      yield put(actions.setRedirectToOtherPage(true));
      yield put(actions.customerSaveResponse(payload.data));

      notification.success({
        message: 'Customer created successfully',
        duration: 5,
      });

      //Making synchronus call which is avoid deadlock in api
      try {
        let isAllDocUploaded = true;
        const cDocsUploadStatus = [];

        yield put(actions.setIsCustomerDocUploading(true));
        notification.success({
          message: 'Uploading customer Image and Id(s)',
          duration: 5,
        });

        const cImageData = yield call(uploadCustomerImageSaga, {
          payload: { ...action.payload, isCustomerCreated: true },
        });

        //MP:5089 - uploading multiple docs
        for (let i = 0; i < newCustomerDocs.length; i++) {
          let docResponse = yield call(uploadCustomerDocumentSaga, {
            payload: {
              ...action.payload,
              isCustomerCreated: true,
              documentsDetails: newCustomerDocs[i],
            },
          });

          cDocsUploadStatus.push(docResponse?.status);
        }

        yield put(actions.setIsCustomerDocUploading(false));
        cDocsUploadStatus.forEach((status, index) => {
          if (status !== RESPONSE_STATUS.SUCCESS) {
            isAllDocUploaded = false;
            documentsCopy[index]['isUploaded'] = false;
          } else documentsCopy[index]['isUploaded'] = true;
        });

        if (
          cImageData &&
          isAllDocUploaded &&
          cImageData?.status === RESPONSE_STATUS.SUCCESS
        ) {
          yield put(actions.uploadImageAndDocUploaded());
          yield put(actions.clearCustomerDocs());
        }
      } catch (e) {
        yield put(actions.uploadImageAndDocUploadingError());
      }
    } else if (payload?.errors) {
      yield put(actions.customerSaveError(payload?.errors));
    }
  } catch (e) {
    yield put(actions.customerSaveError(e));
  }
}

/**
 * this saga handles the POST customer document
 * (1) post customer Document
 * (2) handle response
 */
export function* uploadCustomerDocumentSaga(action) {
  const {
    payload: {
      image,
      ocr,
      isCustomerCreated = false,
      index,
      documentsDetails = {},
    },
  } = action;

  const { customer, newCustomerDocs } = yield select((state) => state.customer);

  try {
    if (image) {
      let docsCopy = cloneDeep(newCustomerDocs);
      docsCopy[index]['image'] = image;
      docsCopy[index]['idCaptureDate'] = format(new Date(), FNS_DATE_FORMAT);
      docsCopy[index]['isScanning'] = false;

      yield put(actions.setNewCustomerDocs(docsCopy));
    }

    if (ocr) {
      yield put(actions.initOCRRequest());
      var performOCR;
      if (ocr === IMAGE_CATEGORY.DOCUMENT_DRIVING_LICENCE) {
        performOCR = performOCRDrivingLicence;
      } else if (ocr === IMAGE_CATEGORY.DOCUMENT_PASSPORT) {
        performOCR = performOCRPassport;
      }
      try {
        const ocrResult = yield call(performOCR, image);
        if (ocrResult?.data) {
          const {
            firstNames,
            middleName,
            surname,
            dateOfBirth,
            postcode,
            idExpiryDate,
            title,
          } = ocrResult.data;
          yield put(
            actions.setOCRResult({
              ...customer,
              firstNames: firstNames ? firstNames : customer?.firstNames,
              middleName: middleName ? middleName : customer?.middleName,
              surname: surname ? surname : customer?.surname,
              idExpiryDate: idExpiryDate
                ? idExpiryDate
                : customer?.idExpiryDate,
              dateOfBirth: dateOfBirth ? dateOfBirth : customer?.dateOfBirth,
              postcode: postcode ? postcode : customer?.postcode,
              title: title ? title : customer?.title,
            })
          );
        }
      } catch (e) {
        yield put(actions.setOCRResult({ ...customer }));
      }
    }

    if (!customer.customerId) {
      return;
    }

    const { customerId } = customer;
    yield put(actions.uploadDocRequest());

    if (image) {
      documentsDetails.image = image;
      documentsDetails.documentTypeId =
        newCustomerDocs[index]['documentTypeId'];
    }
    //send post customer document from API
    const payload = yield call(uploadCustomerDoc, documentsDetails, customerId);
    if (payload?.status) {
      yield put(actions.setIsLiteDocUploaded(true));
      if (isCustomerCreated) {
        yield put(
          actions.uploadImageAndDocResponse({
            message: payload?.status,
            isDocUploading: false,
          })
        );
      } else {
        yield put(actions.uploadDocResponse(payload?.status));
        yield delay(5);
        yield put(actions.clearUploadDoc());
      }
    } else if (payload?.errors) {
      let docsCopy = cloneDeep(newCustomerDocs);
      docsCopy[index]['isScanning'] = false;
      yield put(actions.setNewCustomerDocs(docsCopy));

      yield put(actions.uploadDocError(payload));
      yield delay(5);
      yield put(actions.clearUploadDoc());
    }
    return payload;
  } catch (e) {
    let docsCopy = cloneDeep(newCustomerDocs);
    docsCopy[index]['isScanning'] = false;
    yield put(actions.setNewCustomerDocs(docsCopy));

    yield put(actions.uploadDocError(e));
    yield delay(5);
    yield put(actions.clearUploadDoc());
  }
}

export function* getCustomerLastVisitsSaga(action) {
  try {
    const {
      payload: { customerId },
    } = action;

    yield put(actions.initLastVisitsLoading(true));
    const response = yield call(getCustomerLastVisits, customerId);
    if (response?.data) {
      yield put(actions.setLastVisitsData(response.data));
      yield put(actions.initLastVisitsLoading(false));
    }
  } catch (e) {
    yield put(actions.initLastVisitsLoading(false));
  }
}

export function* createLiteCustomerProfileSaga() {
  try {
    const { liteCustomer, isLiteDocRequired } = yield select(
      (state) => state.customer
    );

    yield put(actions.setIsLiteCustomerLoading(true));
    const response = yield call(createLiteCustomer, liteCustomer?.customer);
    if (response?.data) {
      const customerId = response?.data?.customerId;

      // loading customer profile to redux in background
      yield call(getCustomerProfileSagaInBackground, {
        payload: { customerId },
      });

      //updating customer profile with default TM image
      yield call(updateTMCustomerImage, customerId);

      //uploading attached docs if any
      if (isLiteDocRequired) yield call(uploadLiteCustomerDocumentsSaga);

      yield put(actions.setGdprAcceptance(false));

      //merging guest basket with new customer
      yield put({
        type: basketActions.MERGE_GUEST_BASKET_REQUEST,
      });

      yield put(actions.setIsLiteCustomerLoading(false));
    }
  } catch (e) {
    yield put(actions.setIsLiteCustomerLoading(false));
    notification.alert({
      message: 'Something went wrong.',
      duration: 5,
    });
  }
}

export function* uploadLiteCustomerDocumentsSaga() {
  try {
    const {
      customer: { liteCustomer },
      basket: { basket },
    } = yield select((state) => {
      return {
        customer: state.customer,
        basket: state.basket,
      };
    });

    const docIdArr = [];

    yield put(actions.setIsLiteDocUploading(true));
    for (let i = 0; i < liteCustomer?.docs?.length; i++) {
      const docResponse = yield call(uploadCustomerDocumentSaga, {
        payload: {
          isCustomerCreated: true,
          documentsDetails: liteCustomer?.docs[i],
        },
      });

      if (docResponse?.data) docIdArr.push(docResponse?.data);
    }

    notification.success({
      message: 'Documents uploaded successfully!',
      duration: 5,
    });

    const payload = {
      basketId: basket?.basketId,
      fxCustomerDocumentId: docIdArr[0],
    };

    yield put(customerReduxActions.setCustomerFxDocumentId(docIdArr[0]));
    const response = yield call(updateFXCustomerDocId, payload);
    if (response?.data)
      yield put(basketReduxActions.createBasket(response?.data));

    yield put(actions.setIsLiteDocUploading(false));
    yield put(actions.setIsLiteDocRequired(false));
    yield put(actions.setIsLiteDocUploaded(true));
  } catch (e) {
    yield put(actions.setIsLiteDocUploading(false));
    notification.error({
      message: 'Unable to uploaded customer documents.',
      duration: 5,
    });
  }
}

export function* updateTravelMoneyGuestSaga() {
  try {
    const {
      customer: { customer, liteCustomer, isLiteDocRequired },
      basket: { basket },
    } = yield select((state) => {
      return {
        customer: state.customer,
        basket: state.basket,
      };
    });

    const params = {
      basketId: basket?.basketId,
      customer: liteCustomer?.customer,
    };

    yield put(actions.setIsLiteCustomerLoading(true));
    const response = yield call(postUpdatedCustomerDetails, params);

    if (response?.data) {
      yield put(actions.setGdprAcceptance(false));

      //uploading attached docs if any
      if (isLiteDocRequired) yield call(uploadTMGuestDocumentsSaga);
      else {
        const transactionParams = {
          customerId: 0,
          ...liteCustomer?.customer,
        };

        //fetching TM guest fx transaction history
        const transactionResponse = yield call(
          validateFXTransaction,
          transactionParams
        );

        if (transactionResponse?.data) {
          yield put(
            actions.setCustomertPastTranactionAmount(
              transactionResponse?.data?.totalAmount
            )
          );
        }

        //fetching TM guest uploaded documents
        const documentListResponse = yield call(
          getTMGuestDocumenList,
          liteCustomer?.customer
        );
        if (documentListResponse?.data) {
          const guestDetails = {
            ...customer,
            customerDocuments: documentListResponse?.data,
          };
          yield put(actions.setCustomerDetails(guestDetails));
        }
      }

      yield put(actions.setIsLiteCustomerLoading(false));
    }
  } catch (e) {
    yield put(actions.setIsLiteCustomerLoading(false));
  }
}

export function* uploadTMGuestDocumentsSaga() {
  try {
    const {
      customer: { liteCustomer },
      basket: { basket },
    } = yield select((state) => {
      return {
        customer: state.customer,
        basket: state.basket,
      };
    });

    const docIdArr = [];

    yield put(actions.setIsLiteDocUploading(true));
    for (let i = 0; i < liteCustomer?.docs?.length; i++) {
      const docResponse = yield call(
        uploadTravelMoneyGuestDocuments,
        liteCustomer?.customer,
        liteCustomer?.docs[i]
      );

      if (docResponse?.data) docIdArr.push(docResponse?.data);
    }

    notification.success({
      message: 'Documents uploaded successfully!',
      duration: 5,
    });

    const payload = {
      basketId: basket?.basketId,
      fxCustomerDocumentId: docIdArr[0],
    };

    yield put(customerReduxActions.setCustomerFxDocumentId(docIdArr[0]));
    const response = yield call(updateFXCustomerDocId, payload);
    if (response?.data)
      yield put(basketReduxActions.createBasket(response?.data));

    yield put(actions.setIsLiteDocRequired(false));
    yield put(actions.setIsLiteDocUploading(false));
    yield put(actions.setIsLiteDocUploaded(true));
  } catch (e) {
    yield put(actions.setIsLiteDocUploading(false));
    notification.error({
      message: GENERIC_ERROR,
      duration: 5,
    });
  }
}

export function* getCustomerProfileStatusSaga(action) {
  const {
    payload: { customerId },
  } = action;

  try {
    const response = yield call(getCustomerVulnerableStatus, customerId);
    if (response?.data)
      yield put(
        actions.setCustomerVulnerabilityStatus(response?.data?.colorCode)
      );
  } catch (error) {
    notification.error({
      message: GENERIC_ERROR,
      duration: 5,
    });
  }
}

export function* watchGetCustomerProfileStatusSaga() {
  yield takeEvery(
    customerActions.GET_PROFILE_STATUS,
    getCustomerProfileStatusSaga
  );
}

export function* uploadTpaImageSaga(action) {
  const {
    payload: { image, nomineeRowIndex },
  } = action;

  try {
    if (image) yield put(actions.setTpaImageData({ image, nomineeRowIndex }));

    const { customer, tpaImageData, tpaList, nomineeImageUploadingStatus } =
      yield select((state) => state.customer);

    if (!tpaList[nomineeRowIndex].nomineeId) return;

    let initUploadingStatus = {
      ...nomineeImageUploadingStatus,
      [nomineeRowIndex]: true,
    };
    yield put(actions.setNomineeImageUploadingStatus(initUploadingStatus));

    const payload = yield call(
      uploadNomineeImage,
      tpaImageData[nomineeRowIndex],
      customer?.customerId,
      tpaList[nomineeRowIndex].nomineeId
    );

    if (payload?.status)
      notification.success({
        description: SUCCESS_MESSAGE_ON_NOMINEE_UPLOAD_IMAGE.description,
        message: SUCCESS_MESSAGE_ON_NOMINEE_UPLOAD_IMAGE.message,
        duration: 5,
      });

    let finalUploadingStatus = {
      ...nomineeImageUploadingStatus,
      [nomineeRowIndex]: false,
    };
    yield put(actions.setNomineeImageUploadingStatus(finalUploadingStatus));
  } catch (e) {
    const { nomineeImageUploadingStatus } = yield select(
      (state) => state.customer
    );

    let finalUploadingStatus = {
      ...nomineeImageUploadingStatus,
      [nomineeRowIndex]: false,
    };
    yield put(actions.setNomineeImageUploadingStatus(finalUploadingStatus));

    notification.error({
      description: ERROR_MESSAGE_ON_NOIMINEE_UPLOAD_IMAGE.description,
      message: ERROR_MESSAGE_ON_NOIMINEE_UPLOAD_IMAGE.message,
      duration: 5,
    });
  }
}

export function* watchGetCustomerProfile() {
  yield takeEvery(
    customerActions.CUSTOMER_PROFILE_REQUEST,
    getCustomerProfileSaga
  );
}

export function* watchGetCustomerProfileInBackground() {
  yield takeEvery(
    customerActions.CUSTOMER_PROFILE_REQUEST_IN_BACKGROUND,
    getCustomerProfileSagaInBackground
  );
}

export function* watchUploadCustomerImage() {
  yield takeEvery(
    customerActions.UPLOAD_CUSTOMER_IMAGE_REQUEST,
    uploadCustomerImageSaga
  );
}

export function* watchCustomerProfileSave() {
  yield takeEvery(
    customerActions.CUSTOMER_SAVE_REQUEST,
    customerProfileSaveSaga
  );
}

export function* watchUploadCustomerDocument() {
  yield takeEvery(
    customerActions.UPLOAD_CUSTOMER_DOCUMENT_REQUEST,
    uploadCustomerDocumentSaga
  );
}

export function* watchGetCustomerLastVisitsSaga() {
  yield takeEvery(
    customerActions.CUSTOMER_LAST_VISITS,
    getCustomerLastVisitsSaga
  );
}

export function* watchCreateLiteCustomerProfileSaga() {
  yield takeEvery(
    customerActions.CREATE_LITE_CUSTOMER,
    createLiteCustomerProfileSaga
  );
}

export function* watchUploadLiteCustomerDocumentsSaga() {
  yield takeEvery(
    customerActions.LITE_CUSTOMER_DOC_UPLOAD,
    uploadLiteCustomerDocumentsSaga
  );
}

export function* watchUpdateTravelMoneyGuestSaga() {
  yield takeEvery(
    customerActions.UPDATE_TRAVEL_MONEY_GUEST,
    updateTravelMoneyGuestSaga
  );
}

export function* watchUploadTMGuestDocumentsSaga() {
  yield takeEvery(
    customerActions.UPLOAD_TM_GUEST_DOCUMENTS,
    uploadTMGuestDocumentsSaga
  );
}

export function* watchUploadTpaImageSaga() {
  yield takeEvery(customerActions.UPLOAD_TPA_IMAGE_REQUEST, uploadTpaImageSaga);
}
