import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, notification, Spin } from 'antd';
import { groupBy, isEmpty } from 'lodash';
import useSignalRConnection from 'middleware/signalR';
import { actions } from 'redux/reducers/checkout';
import { actions as basketActions } from 'redux/reducers/basket';
import { generateCollectionSlip } from 'services/checkout';
import { sendToPrinter } from 'services/user';
import {
  CheckoutPledge,
  CheckoutRetail,
  CheckoutFX,
  CheckoutPurchase,
  CheckoutPickup,
  CheckoutChequeCashing,
  CheckoutWU,
  CheckoutPurchaseReturn,
  CheckoutSurplus,
} from 'components/checkout/widgets';
import {
  FOOTER_BUTTONS_AND_TEXT,
  BASKET_SERVICE_TYPE,
  SERVICE_TYPE_LABEL_BY_KEY,
  ROUTE_CONFIG,
  BASKET_STATUS,
  SIGNALR_TIMEOUT,
} from 'globalConstants';
import FooterButtons from 'components/common/footerButtons';
import {
  GET_CHECKOUT,
  CHECKOUT_PROGRESS_REQUEST,
} from 'action_creators/checkout';
import {
  ENABLE_TERMS_FOR_SERVICE,
  ENABLE_TERMS_FOR_ACTION,
  PRINT_ALERT,
} from './constants';
import { TermsConditionAlert } from './termsConditionAlert';
import { RecheckAlert, RecheckRetryAlert } from './recheckAlert';
import { useNavigate } from 'react-router';
import CustomerHeader from 'components/customer/customerHeader';
import { CURRENCIES_REQUEST } from 'action_creators/travelmoney';
import { DELETE_BASKET_SERVICE } from 'action_creators/basket';

import EmptyCheckout from './emptyCheckout/index';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import ControlledAlert from 'components/common/ControlledAlert';
import CheckoutSummaryRow from './summaryRow';

const StyledLayout = styled(Layout)`
  flex-wrap: nowrap;
  min-height: calc(100vh - 155px);
  & .footer-buttons {
    border: none;
    box-shadow: none;
    padding: 10px 0px;
  }
`;

const StyledTableContainer = styled.div`
  min-height: calc(100vh - 350px);
`;

const StyledFooter = styled.div`
  padding: 20px 0 10px 0;
`;

const StyledConainter = styled.div`
  margin: 15px auto;
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-500);
  font-style: normal;
  line-height: 21px;
`;

const StyledEmpty = styled.div`
  text-align: center;
`;

const StyledTimer = styled.div`
  position: static;
  font-size: 3rem;
  left: 55%;
  text-align: center;
`;
const toPayToTake = (amount: number) => {
  return {
    toPay: amount < 0 ? Math.abs(amount) : 0.0,
    toTake: amount > 0 ? amount : 0.0,
  };
};

/**
 * Render widgets like Pledge, retail etc as per dynamic key
 */
const RenderPanes = ({
  itemKey,
  item,
  basket,
  isServiceSuccess = null,
}: any) => {
  let component = null;
  let serviceName = '';
  let amtPerService = { toTake: 0.0, toPay: 0.0 };
  const serviceItem = item[0]['serviceType'].toString();
  switch (serviceItem) {
    case `${BASKET_SERVICE_TYPE.PLEDGE}`: {
      component = (
        <CheckoutPledge item={item} isServiceSuccess={isServiceSuccess} />
      );
      serviceName = SERVICE_TYPE_LABEL_BY_KEY.PLEDGE;
      const pledgeAmounts = toPayToTake(basket?.totalPledgeAmount);
      amtPerService = { ...amtPerService, ...pledgeAmounts };
      break;
    }
    case `${BASKET_SERVICE_TYPE.RETAIL}`: {
      component = <CheckoutRetail item={item} />;
      serviceName = SERVICE_TYPE_LABEL_BY_KEY.RETAIL;
      const retailAmount = toPayToTake(basket?.totalRetailAmount);
      amtPerService = {
        ...amtPerService,
        ...retailAmount,
      };
      break;
    }
    case `${BASKET_SERVICE_TYPE.FX}`: {
      component = <CheckoutFX item={item} />;
      serviceName = SERVICE_TYPE_LABEL_BY_KEY.FX;
      const fxAmount = toPayToTake(basket?.totalFX?.totalFXAmount);
      amtPerService = {
        ...amtPerService,
        ...fxAmount,
      };
      break;
    }
    case `${BASKET_SERVICE_TYPE.PURCHASE}`: {
      component = <CheckoutPurchase item={item} />;
      serviceName = SERVICE_TYPE_LABEL_BY_KEY.PURCHASE;
      const purchaseAmounts = toPayToTake(basket?.totalPurchaseAmount);
      amtPerService = { ...amtPerService, ...purchaseAmounts };
      break;
    }
    case `${BASKET_SERVICE_TYPE.PICKUPRENEW}`: {
      component = (
        <CheckoutPickup item={item} isServiceSuccess={isServiceSuccess} />
      );
      serviceName = SERVICE_TYPE_LABEL_BY_KEY.PICKUPRENEW;
      const pickupAmounts = toPayToTake(basket?.totalPickupRenewAmount);
      amtPerService = { ...amtPerService, ...pickupAmounts };
      break;
    }
    case `${BASKET_SERVICE_TYPE.CHEQUECASHING}`: {
      component = <CheckoutChequeCashing item={item} />;
      serviceName = SERVICE_TYPE_LABEL_BY_KEY.CHEQUECASHING;
      const chequeCashingAmount = toPayToTake(
        basket?.totalThirdPartyChequeAmount
      );
      amtPerService = {
        ...amtPerService,
        ...chequeCashingAmount,
      };
      break;
    }
    case `${BASKET_SERVICE_TYPE.WU}`: {
      component = <CheckoutWU item={item} />;
      serviceName = SERVICE_TYPE_LABEL_BY_KEY.WU;
      const wuAmounts = toPayToTake(basket?.totalWesternUnionAmount);
      amtPerService = { ...amtPerService, ...wuAmounts };
      break;
    }
    case `${BASKET_SERVICE_TYPE.PURCHASERETURN}`: {
      component = <CheckoutPurchaseReturn item={item} />;
      serviceName = SERVICE_TYPE_LABEL_BY_KEY.PURCHASERETURN;
      const purchaseReturnAmounts = toPayToTake(
        basket?.totalPurchaseReturnAmount
      );
      amtPerService = { ...amtPerService, ...purchaseReturnAmounts };
      break;
    }
    case `${BASKET_SERVICE_TYPE.SURPLUS}`: {
      component = <CheckoutSurplus item={item} />;
      serviceName = SERVICE_TYPE_LABEL_BY_KEY.SURPLUS;
      const surplusAmounts = toPayToTake(basket?.totalAmount);
      amtPerService = { ...amtPerService, ...surplusAmounts };
      break;
    }
  }

  const summaryData = [
    {
      payLabel: serviceName,
      toTake: amtPerService.toTake,
      toPay: amtPerService.toPay,
      itemId: itemKey,
    },
  ];

  return (
    <div key={serviceItem}>
      <StyledConainter>
        <HelpPopoverInfo
          linkedID={`CHECKOUT_${serviceName}`}
          position="rightTop"
          helpPosition="AFTER"
        >
          {serviceName}
        </HelpPopoverInfo>
      </StyledConainter>
      {component}
      <CheckoutSummaryRow summaryData={summaryData} />
      <TermsConditionAlert serviceType={serviceItem} />
    </div>
  );
};

const Checkout = () => {
  useSignalRConnection();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    basket: { basket: originalBasket, isPartPayment },
    checkout: {
      basket,
      isLoading,
      isRecheckRetryAlert,
      recheckRetryCount,
      error,
      isPrinterError,
      isTimeoutSignalR,
      termAndConditionRead = [],
      contractPrinitingError,
      pledgeContractPrintStatus,
      pickupAffiPrintStatus,
      pickupContractPrintStatus,
      surplusContractPrintStatus,
    },
    tm: { currencies },
    customer: { isRecallCheckoutCustomer, isDocumentPrinting },
  } = useSelector((state: any) => {
    return {
      basket: state.basket,
      checkout: state.checkout,
      tm: state.tm,
      customer: state.customer,
    };
  });
  const [isRecheck, setRecheck] = useState<boolean>(false);
  const [isTermsReadByUser, setTermsReadByUser] = useState<boolean>(false);
  const [second, setSecond] = useState<any>('00');
  const [minute, setMinute] = useState<any>('00');
  const [isTimerActive, setIsTimerActive] = useState<boolean>(false);
  const [isServiceSuccess, setIsServiceSuccess] = useState<boolean>(false);
  const [counter, setTimerCounter] = useState<number>(0);
  const [isPrintAlertVisible, setIsPrintAlertVisible] =
    useState<boolean>(false);
  const [isCheckoutDisableForPrinitng, setIsCheckoutDisableForPrinitng] =
    useState<boolean>(false);
  const [printAttemptCount, setPrintAttemptCount] = useState<number>(0);

  //setting up redux state (checkout) for contract/affi printing
  useEffect(() => {
    if (!isRecallCheckoutCustomer) {
      //checking for pledge in basket
      const pledgeServices = originalBasket?.basketServices?.filter(
        (x: any) => x.serviceType === BASKET_SERVICE_TYPE.PLEDGE
      );

      if (pledgeServices.length > 0) pledgeContractHandler(pledgeServices);

      //checking for pickupRenew
      const pickupServices = originalBasket?.basketServices?.filter(
        (x: any) => x.serviceType === BASKET_SERVICE_TYPE.PICKUPRENEW
      );

      if (pickupServices.length > 0) pickupContractHandler(pickupServices);

      //checking for surplus in basket
      const surplusServices = originalBasket?.basketServices?.filter(
        (x: any) => x.serviceType === BASKET_SERVICE_TYPE.SURPLUS
      );

      if (surplusServices.length > 0) surplusContractHandler(surplusServices);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch({
      type: CURRENCIES_REQUEST,
    });
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currencies.length > 0) dispatch({ type: GET_CHECKOUT });
  }, [dispatch, currencies]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => {
      renderWidgets();
      termsConditionVisibility(basket?.basketServices);

      if (contractPrinitingError || isPrinterError)
        setIsPrintAlertVisible(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [basket, isPrinterError, contractPrinitingError, isPartPayment]
  );

  //Auto redirecting to Payment page on print of all contract/Affi
  useEffect(
    () => {
      if (
        !isPrinterError &&
        isGotoPayment &&
        !isRecallCheckoutCustomer &&
        !contractPrinitingError
      ) {
        const pledgeContracts = Object.values(pledgeContractPrintStatus);
        const pickupContracts = Object.values(pickupContractPrintStatus);
        const pickupAffi = Object.values(pickupAffiPrintStatus);
        const surplusContracts = Object.values(surplusContractPrintStatus);

        const pledgeContractsStatus = pledgeContracts.every(
          (contract: any) => contract
        );
        const pickupContractsStatus = pickupContracts.every(
          (contract: any) => contract
        );
        const surplusContractsStatus = surplusContracts.every(
          (contract: any) => contract
        );
        const pickupAffiStatus = pickupAffi.every((affi: any) => affi);

        if (
          pledgeContractsStatus &&
          pickupContractsStatus &&
          pickupAffiStatus &&
          surplusContractsStatus
        )
          onProceedToPaymentClick();
        else if (printAttemptCount === 0) setIsCheckoutDisableForPrinitng(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      basket,
      pledgeContractPrintStatus,
      pickupContractPrintStatus,
      pickupAffiPrintStatus,
      surplusContractPrintStatus,
      contractPrinitingError,
    ]
  );

  //** this timer is use for start/stop signalR if not getting response within the time period */
  useEffect(() => {
    let intervalId: any;
    const isBasketServiceSuccess =
      basket?.basketStatus === BASKET_STATUS.SUCCESSFUL;
    const isAnyServiceFailed = basket?.basketServices?.some(
      (item: any) => item.serviceStatus === BASKET_STATUS.FAILED
    );

    const isAnyServiceInitialised = basket?.basketServices?.some(
      (item: any) => item.serviceStatus === BASKET_STATUS.INITIALISED
    );

    const secondCounterTimer = counter % 60;
    if (isBasketServiceSuccess) {
      stopTimer();
      clearInterval(intervalId);
      dispatch(actions.setIsTimeoutSignalR());
      setRecheck(false);
      dispatch(actions.clearRecheckAlert());

      //Lets give atleast 2 seconds for contracts to be ready in back-end before we go for printing
      setTimeout(() => setIsServiceSuccess(isBasketServiceSuccess), 2000);
    } else if (secondCounterTimer === SIGNALR_TIMEOUT && isAnyServiceFailed) {
      if (!isRecheckRetryAlert) {
        stopTimer();
        clearInterval(intervalId);
        dispatch(actions.recheckCounter(recheckRetryCount + 1));
      }
    } else if (
      secondCounterTimer === SIGNALR_TIMEOUT &&
      isAnyServiceInitialised
    ) {
      stopTimer();
      clearInterval(intervalId);
      dispatch(actions.setIsTimeoutSignalR());
      if (recheckRetryCount === 0) {
        setRecheck(true);
      }
    }
    if (isTimerActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);
        const computedSecond: any =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        const computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);

        setTimerCounter((counter) => counter + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isTimerActive, counter, basket, basket?.basketServices]); // eslint-disable-line react-hooks/exhaustive-deps

  const stopTimer = () => {
    setIsTimerActive(false);
    setTimerCounter(0);
    setSecond('00');
    setMinute('00');
  };

  useEffect(() => {
    if (error?.description)
      notification.error({
        message: error.message,
        description: error.description,
        duration: 10,
      });
  }, [error]);

  const pickupContractHandler = (services: any) => {
    const pickupContractObj: any = {};
    services.forEach(
      (pickup: any) => (pickupContractObj[pickup.serviceId] = false)
    );

    dispatch(actions.setPickupAffiPrintStatusCollection(pickupContractObj));
    dispatch(actions.setPickupContractPrintStatusCollection(pickupContractObj));
  };

  const pledgeContractHandler = (services: any) => {
    const pledgeContractObj: any = {};
    services.forEach(
      (pledge: any) => (pledgeContractObj[pledge.serviceId] = false)
    );

    dispatch(actions.setPledgeContractPrintStatusCollection(pledgeContractObj));
  };

  const surplusContractHandler = (services: any) => {
    const surplusContractObj: any = {};
    services.forEach(
      (surplus: any) => (surplusContractObj[surplus.serviceId] = false)
    );

    dispatch(
      actions.setSurplusContractPrintStatusCollection(surplusContractObj)
    );
  };

  const renderWidgets = () => {
    const groupedItems = groupBy(basket?.basketServices, 'sortIndexParent');
    return Object.keys(groupedItems).map((itemKey) => {
      const widget = groupedItems[itemKey].sort((a, b) => {
        return a['sortIndexChild'] - b['sortIndexChild'];
      });
      return (
        <div className="checkout-table" key={itemKey}>
          <RenderPanes
            key={itemKey}
            itemKey={itemKey}
            item={widget}
            basket={basket}
            isServiceSuccess={isServiceSuccess}
          />
        </div>
      );
    });
  };

  const termsConditionVisibility = (basketServices: any) => {
    const isTermsButtonApply = basketServices?.reduce(
      (acc: any, currentValue: any) => {
        if (currentValue.serviceType === BASKET_SERVICE_TYPE.PICKUPRENEW) {
          if (
            ENABLE_TERMS_FOR_ACTION.includes(
              currentValue?.pickupRenew?.request?.item?.selectedActionType
            )
          ) {
            acc[currentValue.serviceType] = true;
          }
        } else if (
          ENABLE_TERMS_FOR_SERVICE.includes(currentValue.serviceType)
        ) {
          acc[currentValue.serviceType] = true;
        }
        return acc;
      },
      []
    );

    if (isTermsButtonApply && isTermsButtonApply.length > 0) {
      const isPledgeExists = Object.keys(isTermsButtonApply).some(
        (x) => +x === BASKET_SERVICE_TYPE.PLEDGE
      );
      const isPickupRenew = Object.keys(isTermsButtonApply).some(
        (x) => +x === BASKET_SERVICE_TYPE.PICKUPRENEW
      );
      const isChequeCaching = Object.keys(isTermsButtonApply).some(
        (x) => +x === BASKET_SERVICE_TYPE.CHEQUECASHING
      );

      if (isPledgeExists && isPickupRenew) {
        delete isTermsButtonApply[BASKET_SERVICE_TYPE.PLEDGE];
      }
      if (isPartPayment && isChequeCaching) {
        delete isTermsButtonApply[BASKET_SERVICE_TYPE.CHEQUECASHING];
      }

      setTermsReadByUser(true);
    } else {
      setTermsReadByUser(false);
    }
    dispatch(actions.setTermAndConditionRead(isTermsButtonApply || []));
  };

  useEffect(() => {
    if (termAndConditionRead?.length) {
      const isTermReaded =
        termAndConditionRead.filter((o: any) => o)?.length > 0 || false;
      setTermsReadByUser(isTermReaded);
    }
  }, [termAndConditionRead]);

  /** main button (next step) call */
  const onProceedClick = () => {
    dispatch({ type: CHECKOUT_PROGRESS_REQUEST });
    startSignalR();
  };

  const startSignalR = () => {
    if (isTimeoutSignalR) dispatch(actions.clearIsTimeoutSignalR());
    setIsTimerActive(true);
  };

  /** main button (next step) call */
  const onProceedToPaymentClick = () => {
    navigate(`/${ROUTE_CONFIG.PAYMENT}`);
  };

  /** transaction alert failed pop up 'recheck' button clicked  */
  const onRecheckClicked = () => {
    setRecheck(false);
    dispatch({
      type: GET_CHECKOUT,
    });
    startSignalR();
  };

  /** retry transaction alert failed pop up 'retry' button clicked  */
  const onRecheckRetryClicked = () => {
    dispatch({
      type: CHECKOUT_PROGRESS_REQUEST,
      payload: { recheckRetryCount: recheckRetryCount + 1 },
    });
    startSignalR();
  };

  /** retry transaction alert failed pop up 'remove failed transaction' button clicked  */
  const onRemoveTransactionClicked = () => {
    const failedServices = basket?.basketServices
      .filter((item: any) => item.serviceStatus === BASKET_STATUS.FAILED)
      .map((item: any) => item.serviceId);

    dispatch(actions.clearRecheckAlert());
    dispatch({
      type: DELETE_BASKET_SERVICE,
      payload: {
        basketId: basket?.basketId,
        serviceIds: failedServices,
      },
    });
  };

  const onBackButtonClick = () => {
    dispatch(basketActions.clearIsServicesValidate({})); //this is for beacuase isServicesValidate is true if its true its not going to basket page in first click
    dispatch(basketActions.updatePayByOtherMethods([]));
    navigate(`/${ROUTE_CONFIG.BASKET}`);
  };

  const isGotoPayment = basket?.basketServices?.every(
    (item: any) => item.serviceStatus === BASKET_STATUS.SUCCESSFUL
  );

  const printCollectionslip = async () => {
    const receiptData = await generateCollectionSlip(basket?.basketId);

    if (receiptData?.data) {
      sendToPrinter(receiptData, 'printreceipt', '_PrintCollectionslip');
    }
  };

  const onPrintCollectionslipClick = async () => {
    await printCollectionslip();
  };

  const isPrintCollectionslip = () => {
    //const featureEnabled = false;
    const isPickupRenew = basket?.basketServices.some(
      (x: any) => !!x.pickupRenew || !!x.purchaseReturn
    );

    return isPickupRenew;
    // return featureEnabled && isGotoPayment && isPickupRenew;
  };

  const onProceedButtonClick = () => {
    isGotoPayment ? onProceedToPaymentClick() : onProceedClick();
  };

  const onPrintAlertOkClick = () => {
    setIsPrintAlertVisible(false);
    setIsCheckoutDisableForPrinitng(false);
    setPrintAttemptCount((prevState: any) => prevState + 1);
    dispatch(actions.setContratPrintingError(false));
  };

  return (
    <>
      <CustomerHeader
        title="Checkout"
        buttonsOption={true}
        helpSection="CHECKOUT"
      />
      {isEmpty(basket?.basketServices) && (
        <StyledEmpty className="control-box">
          {isLoading ? <Spin /> : null}
        </StyledEmpty>
      )}
      {!isEmpty(basket) &&
        isEmpty(basket?.basketServices) &&
        basket?.basketStatus !== BASKET_STATUS.PENDING && (
          <StyledEmpty className="control-box">
            <EmptyCheckout />
          </StyledEmpty>
        )}
      {!isEmpty(basket?.basketServices) && (
        <StyledLayout className="control-box">
          {isTimerActive && (
            <StyledTimer>
              <span className="minute">{minute}</span>
              <span>:</span>
              <span className="second">{second}</span>
            </StyledTimer>
          )}
          <StyledTableContainer>{renderWidgets()}</StyledTableContainer>
          <StyledFooter>
            <FooterButtons
              enablePayContainer
              enableBackButton
              isLoading={isTimerActive}
              totalAmount={Math.abs(basket?.totalAmount)}
              payHeader={FOOTER_BUTTONS_AND_TEXT.ALL_SERVICE_LABEL}
              backButtonText={FOOTER_BUTTONS_AND_TEXT.BACK_TO_BASKET}
              helpSection="CHECKOUT"
              payLabel={
                basket?.totalAmount > 0
                  ? FOOTER_BUTTONS_AND_TEXT.TAKE_FROM_CUSTOMER
                  : FOOTER_BUTTONS_AND_TEXT.PAY_TO_CUSTOMER
              }
              extraButtonOnClick={onPrintCollectionslipClick}
              extraButtonEanable={isPrintCollectionslip()}
              extraButtonItemProp="secondary"
              extraButtonAlign="right"
              extraButtonText="Print Collection Slip"
              proceedButtonText={FOOTER_BUTTONS_AND_TEXT.PROCEED_TO_NEXT_STEP}
              proceedButtonClassName={isGotoPayment ? 'lg-red-payment-btn' : ''}
              backgroundColor={'catskill-white-light'}
              onProceedClick={onProceedButtonClick}
              onBackButtonClick={onBackButtonClick}
              isBackButtonDisable={
                basket.basketStatus !== BASKET_STATUS.PENDING
              }
              isDisabled={
                (isTermsReadByUser &&
                  basket.basketStatus === BASKET_STATUS.PENDING) ||
                isDocumentPrinting ||
                isCheckoutDisableForPrinitng
              } //proceed button disable condition - T&C not read and basket status is not moved to initialized yet
            />
          </StyledFooter>
        </StyledLayout>
      )}
      {!isRecheckRetryAlert && (
        <RecheckAlert visible={isRecheck} onClick={onRecheckClicked} />
      )}
      <RecheckRetryAlert
        visible={isRecheckRetryAlert}
        onClick={onRecheckRetryClicked}
        onRemoveTransaction={onRemoveTransactionClicked}
      />

      {isPrintAlertVisible && (
        <ControlledAlert
          width={560}
          visible={isPrintAlertVisible}
          isCancelBtn={false}
          alertMessage={PRINT_ALERT.TITLE}
          message={PRINT_ALERT.CONTENT_1}
          secondaryMessage={PRINT_ALERT.CONTENT_2}
          onClick={onPrintAlertOkClick}
          yesButtonText={PRINT_ALERT.BUTTON_LABEL}
        />
      )}
    </>
  );
};

export default Checkout;
