// to set valuation center information
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isActionLoading: false,
  isValuationViewMode: false,
  liveValuationCount: 0,
  itemDetials: {},

  //selectedValuation state (PAWNBROWKING UI)
  isValuationItemToPledge: false,
  notificationsWorkqueueList: [],
  valuationItemDesiredAmount: null,
  offeredAmount: 0,
  opportunityId: '',
  valuationItemDetails: {},
};
const slice = createSlice({
  name: 'valuationActions',
  initialState,
  reducers: {
    setValuationViewMode(state, action) {
      state.isValuationViewMode = action.payload;
    },
    setItemDetails(state, action) {
      state.itemDetials = action.payload;
    },
    setValuationItemDetails(state, action) {
      state.valuationItemDetails = action.payload;
    },
    setLiveValuationCount(state, action) {
      state.liveValuationCount = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsActionLoading(state, action) {
      state.isActionLoading = action.payload;
    },
    setValuationItemToPledge(state, action) {
      state.isValuationItemToPledge = action.payload;
    },
    setNotificationsWorkqueueList(state, action) {
      state.notificationsWorkqueueList = action.payload;
    },
    setValuationItemDesiredAmount(state, action) {
      state.valuationItemDesiredAmount = action.payload;
    },
    setOpportunityId(state, action) {
      state.opportunityId = action.payload;
    },
    setOfferedAmount(state, action) {
      state.offeredAmount = action.payload;
    },
    clearValuation(state) {
      return {
        ...state,
        isValuationItemToPledge: false,
        valuationItemDesiredAmount: null,
        offeredAmount: 0,
        opportunityId: '',
        valuationItemDetails: {},
      };
    },
  },
});

export default slice.reducer;
export const { reducer, actions } = slice;
export { initialState };
