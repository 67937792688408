import { Col, Form, Button as LinkButton, Row } from 'antd';
import {
  FORM_BUTTON_LABELS,
  ITEM_FEE,
  QUICK_QUOTE_TITLE,
  RESET_BUTTON,
  SERVICE_FEE,
  TOTAL_CHARGES,
} from '../constants';
import React, { useEffect, useState } from 'react';
import { currencyFormat, toFixedNoRound } from 'utils/util';
import { getFeeDetails, getServiceFee } from 'services/chequeCashing';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common/Button';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import InputDecimal from 'components/common/InputDecimal';
import ViewServiceFeeDetails from './ViewServiceFeeDetails';
import { actions } from 'redux/reducers/chequeCashing';
import { cacheStaticData } from 'utils/cacheServices';
import styled from 'styled-components';
import { isEmpty, isNull } from 'lodash';

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

interface QuickQuoteProps {
  totalAmount: (amount: any) => void;
  setAmount: (amount: any) => void;
}

const QuickQuote = ({ totalAmount, setAmount }: QuickQuoteProps) => {
  const {
    preAuthStatusCode,
    partPaymentClicked,
    chequeItemDetails,
    quickQuote,
  } = useSelector((state: any) => state.chequeCashing);

  const [serviceFeeTableVisible, setServiceFeeTableVisible] =
    useState<boolean>(false);
  const [serviceFeeList, setServiceFeeList] = useState<Array<any>>([]);
  const [itemFee, setItemFee] = useState<number>(0.0);
  const [totalCharge, setTotalCharges] = useState<any>(3.99);
  const [quickQuoteDetails, setQuickQuoteDetails] = useState<any>({
    chequeAmount: 0,
    serviceFee: 0,
    itemFee: 0,
    totalCharges: 0,
  });

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    getItemFee();
    getServiceFeeList();

    //if (!isEmpty(quickQuote)) initQuickQuote(); //commented out for release
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (itemFee && chequeItemDetails?.itemFee) {
      form.setFieldsValue({
        serviceFeeAmount: chequeItemDetails?.serviceFee,
        chequeAmount:
          chequeItemDetails?.amount || chequeItemDetails?.chequeAmount,
        totalCharges: chequeItemDetails?.totalFee,
        itemFee: chequeItemDetails?.itemFee,
      });
      totalAmount(chequeItemDetails?.totalAmount);
      onServiceFeeChange();
    }
  }, [chequeItemDetails, itemFee]); // eslint-disable-line react-hooks/exhaustive-deps

  const initQuickQuote = () => {
    const { chequeAmount, serviceFee, itemFee: itemFeeQQ } = quickQuote;
    const percentage = ((serviceFee * 100) / chequeAmount || 0.0).toFixed(2);

    form.setFieldsValue({
      percentage,
      chequeAmount: chequeAmount.toFixed(2),
      serviceFeeAmount: serviceFee,
    });

    const params: any = {
      itemFee: itemFeeQQ,
      serviceFee,
      chequeAmount: chequeAmount,
    };
    const totalChequeAmount = getTotalChequeAmount(params);
    totalAmount(totalChequeAmount);
    setTotalCharges(itemFeeQQ + serviceFee);
  };

  const getItemFee = async () => {
    try {
      const response = await cacheStaticData(getFeeDetails);
      if (response) {
        const itemFee = response.data.thirdPartyChequeItemFees.find(
          (x: any) => x.keyName === 'TPC_ITEM_FEE'
        );
        setItemFee(+itemFee.currentValue);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const getServiceFeeList = async () => {
    try {
      const response = await cacheStaticData(getServiceFee);
      if (response) {
        setServiceFeeList(response?.data?.thirdPartyChequeFees);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  //total cheque amount the customer will receive
  const getTotalChequeAmount = (params: any) => {
    let totalAmt = 0.0;
    for (const key in params) {
      if (key === 'chequeAmount') totalAmt += params[key];
      else totalAmt -= params[key];
    }
    return totalAmt;
  };

  const onChequeAmountChange = () => {
    const chequeAmount = +form.getFieldValue('chequeAmount');

    const option = serviceFeeList?.filter(
      (el: any, key: any) => chequeAmount <= el?.upperLimit
    );
    form.setFieldsValue({ percentage: option[0]?.percentCharge || 0.0 });
    form.setFieldsValue({
      serviceFeeAmount: toFixedNoRound(
        (option[0]?.percentCharge / 100) * chequeAmount || 0.0
      ),
    });

    const serviceFeeAmount = +form.getFieldValue('serviceFeeAmount');

    const params: any = {
      itemFee: itemFee,
      serviceFee: serviceFeeAmount,
      chequeAmount: chequeAmount === undefined ? 0 : chequeAmount,
    };

    const total = getTotalChequeAmount(params);
    totalAmount(total);

    setTotalCharges(itemFee + serviceFeeAmount);
    const quickQuoteParams = {
      ...quickQuoteDetails,
      chequeAmount,
      itemFee,
      serviceFee: serviceFeeAmount,
      totalCharges: itemFee + serviceFeeAmount,
    };

    setQuickQuoteDetails(quickQuoteParams);
    dispatch(actions.addQuickQuote(quickQuoteParams));

    if (!isNull(preAuthStatusCode)) dispatch(actions.resetPreAuthStatusCode());
  };

 

  const onServiceFeeChange = (feeType: string = 'fee') => {
    var serviceFeeAmount = +form.getFieldValue('serviceFeeAmount');
    var percentageEntered = +form.getFieldValue('percentage');

    const chequeAmount = +form.getFieldValue('chequeAmount');

    if (feeType === 'fee') {
      form.setFieldsValue({
        percentage: ((serviceFeeAmount * 100) / chequeAmount || 0.0).toFixed(2),
      });
    } else {
      const serviceFee = (
        (percentageEntered / 100) * chequeAmount || 0.0
      ).toFixed(2);

      form.setFieldsValue({
        serviceFeeAmount: serviceFee,
      });
      serviceFeeAmount = +serviceFee;
    }

    const params: any = {
      itemFee,
      serviceFee: serviceFeeAmount,
      chequeAmount: chequeAmount === undefined ? 0 : chequeAmount,
    };
    const totalChequeAmount = getTotalChequeAmount(params);
    totalAmount(totalChequeAmount);

    setTotalCharges(itemFee + serviceFeeAmount);

    const quickQuoteParams = {
      ...quickQuoteDetails,
      chequeAmount,
      itemFee,
      serviceFee: serviceFeeAmount,
      totalCharges: itemFee + serviceFeeAmount,
    };

    setQuickQuoteDetails(quickQuoteParams);
    dispatch(actions.addQuickQuote(quickQuoteParams));
  };

  const onResetClicked = () => {
    form.resetFields();
    setTotalCharges(itemFee);
    totalAmount(0);
    setAmount(0);
    dispatch(actions.setRequestedAmount(0));
    setQuickQuoteDetails({
      chequeAmount: 0,
      serviceFee: 0,
      itemFee: 0,
      totalCharges: 0,
    });
    dispatch(actions.addQuickQuote({}));
  };

  return (
    <>
      <Form layout="vertical" form={form}>
        <Row className="title-row " gutter={[0, 12]}>
          <Col span={24}>
            <HelpPopoverInfo
              linkedID={'CHEQUE_QUICKQUOTE'}
              position="rightTop"
              helpPosition="AFTER"
            >
              {QUICK_QUOTE_TITLE}
            </HelpPopoverInfo>
          </Col>
        </Row>
        <Row gutter={[0, 12]}>
          <Col span={24}>
            <Form.Item
              label={FORM_BUTTON_LABELS.CHEQUE_AMOUNT_LABEL}
              name="chequeAmount"
              className="label-control"
            >
              <InputDecimal
                className="cheque-amount-input"
                onChange={onChequeAmountChange}
                disabled={partPaymentClicked}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="grid-warpper">
          <Col span={24} className="quick-quote-grid">
            <Row>
              <Col span={8}>
                <LinkButton
                  type="link"
                  onClick={() => setServiceFeeTableVisible(true)}
                >
                  {SERVICE_FEE}
                </LinkButton>
              </Col>
              <Col span={8}>
                <Form.Item name="serviceFeeAmount" className="label-control">
                  <InputDecimal
                    addonBefore={'£'}
                    disabled={partPaymentClicked}
                    onChange={() => onServiceFeeChange('fee')}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="percentage" className="label-control">
                  <InputDecimal
                    disabled={partPaymentClicked}
                    addonBefore={'%'}
                    onChange={() => onServiceFeeChange('percent')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>{ITEM_FEE}</Col>
              <Col span={16}>
                <Form.Item name="itemFee" className="label-control">
                  <div className="ant-form-item-control-input-content item-fee">
                    <span className="ant-input-group-wrapper">
                      <span className="ant-input-wrapper ant-input-group">
                        <span className="ant-input-group-addon">£</span>
                        <p style={{ fontWeight: 'var(--font-weight-500)' }}>
                          {currencyFormat(itemFee)}
                        </p>
                      </span>
                    </span>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>{TOTAL_CHARGES}</Col>
              <Col span={16}>
                <Form.Item name="totalCharges" className="label-control">
                  <div className="ant-form-item-control-input-content total-charges">
                    <span className="ant-input-group-wrapper">
                      <span className="ant-input-wrapper ant-input-group">
                        <span className="ant-input-group-addon">£</span>
                        <p style={{ fontWeight: 'var(--font-weight-500)' }}>
                          {currencyFormat(totalCharge)}
                        </p>
                      </span>
                    </span>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col span={24}>
                <StyledButton
                  itemProp="secondary"
                  key="back"
                  onClick={onResetClicked}
                  disabled={partPaymentClicked}
                >
                  {RESET_BUTTON}
                </StyledButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      {serviceFeeTableVisible && (
        <ViewServiceFeeDetails
          visible={serviceFeeTableVisible}
          onCancelClick={() => setServiceFeeTableVisible(false)}
          serviceFeeList={serviceFeeList}
        />
      )}
    </>
  );
};

export default QuickQuote;
