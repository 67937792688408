import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Modal, Row, Spin, notification } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { format } from 'date-fns';

import { Button } from 'components/common/Button';
import TextArea from 'components/common/TextArea';
import VirtualGrid from 'components/common/virtualGrid';
import DatePickerMUI from 'components/common/DatePickerMUI';
import ControlledAlert from 'components/common/ControlledAlert';

import { currencyFormat, dateDisplayFormat } from 'utils/util';

import {
  FNS_DATE_FORMAT_YEAR,
  GENERIC_ERROR,
  GRID_NO_URL,
  PROFANITY_CHECK,
} from 'globalConstants';
import {
  BUTTONS_LABEL,
  CD_ERRORS,
  HEADINGS,
  SST,
} from 'components/consumerDuty/constants';
import {
  getWaivedInterestHistory,
  postWaivedInterest,
} from 'services/consumerDuty';

import { GET_LOCKED_PLEDGE_DETAILS } from 'action_creators/consumerDuty';

const StyledTitle = styled.span`
  width: 100%;
  text-align: left;
  padding-left: 20px;
  font-weight: var(--font-weight-600);
`;

const StyledRow = styled(Row)`
  margin-block-end: 20px;
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledHistoryTableWrapper = styled.div`
  max-height: 200px;
  overflow: auto;
`;

interface WaiveInterestPopupProps {
  open: boolean;
  loanDetails: any;
  taskOwner: any;
  setWaiveInterestPopupVisible: () => void;
}
interface ProfanityError {
  profanity: boolean;
  badWords: Array<string>;
}

const WaiveInterestPopup = ({
  open,
  loanDetails = {},
  taskOwner,
  setWaiveInterestPopupVisible,
}: WaiveInterestPopupProps) => {
  const {
    user: { selectedStore, user },
    customer: { customer },
    consumerDuty: { selectedTaskOwner },
  } = useSelector((state: any) => {
    return {
      user: state.user,
      customer: state.customer,
      consumerDuty: state.consumerDuty,
    };
  });

  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const [noOfDays, setNoOfDays] = useState<number>(0);
  const [notes, setNotes] = useState<string>('');
  const [waivedLoanDetails, setWaivedLoanDetails] = useState<any>({});
  const [agreementStartDate, setAgreementStartDate] = useState<any>(null);
  const [waiveInterestMaxDate, setWaiveInterestMaxDate] = useState<any>(null);

  const [profanityError, setProfanityError] = useState<ProfanityError>({
    profanity: false,
    badWords: [''],
  });
  const [isProfanity, setProfanity] = useState<boolean>(false);

  useEffect(() => {
    getLoanWaivedInterestHistory();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleProfanity = (profanity: boolean, badWords: Array<string>) => {
    setProfanityError({ profanity, badWords });
  };

  const getLoanWaivedInterestHistory = async () => {
    try {
      setIsFetching(true);
      const response = await getWaivedInterestHistory(
        loanDetails?.loanId,
        taskOwner
      );
      if (response?.data) {
        const { startDate } = response?.data;
        const yearAddedStartDate = moment(startDate).add(1, 'years');

        setWaivedLoanDetails(response?.data);
        setAgreementStartDate(startDate);
        setWaiveInterestMaxDate(yearAddedStartDate);
        setIsFetching(false);
      }
    } catch (error: any) {
      setIsFetching(false);
      notification.error({ message: GENERIC_ERROR, duration: 5 });
    }
  };

  const onFromDateChange = (date: any) => {
    if (date) setFromDate(date);
    if (toDate) calculateDays(new Date(date), new Date(toDate));
  };

  const onToDateChange = (date: any) => {
    if (date) setToDate(date);
    if (fromDate) calculateDays(new Date(fromDate), new Date(date));
  };

  const calculateDays = (dateFrom: any, dateTo: any) => {
    const diffTime = dateTo - dateFrom;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    setNoOfDays(diffDays);
  };

  const checkForOverlappingPeriod = () => {
    const { waiveInterestHistory } = waivedLoanDetails;

    for (let i = 0; i < waiveInterestHistory.length; i++) {
      let { dateFrom, dateTo } = waiveInterestHistory[i];

      //setting dates to midnight to skip time comparison
      const indexDateFrom = new Date(new Date(dateFrom).setHours(0, 0, 0, 0));
      const indexDateTo = new Date(new Date(dateTo).setHours(0, 0, 0, 0));

      if (
        new Date(indexDateFrom) < new Date(toDate) &&
        new Date(fromDate) < new Date(indexDateTo)
      )
        return notification.error({
          message: CD_ERRORS.DATE_RANGE_OVERLAP,
          duration: 5,
        });
    }

    onConfirmClick();
  };

  const onCancelClick = () => {
    setWaiveInterestPopupVisible();
  };

  const onConfirmClick = async () => {
    if (profanityError.profanity) {
      setProfanity(true);
    } else {
      if (!notes)
        return notification.error({
          message: SST.PLEDGE_PERMISSION.NOTES,
          duration: 5,
        });

      try {
        const payload = {
          loanId: waivedLoanDetails?.loanId,
          storeId: selectedStore?.storeId,
          waivedBy: user?.userId,
          waivedFrom: format(new Date(fromDate), FNS_DATE_FORMAT_YEAR),
          waivedTo: format(new Date(toDate), FNS_DATE_FORMAT_YEAR),
          reasonNotes: notes,
        };

        setIsLoading(true);
        const response = await postWaivedInterest(payload);
        if (response?.data?.success) {
          notification.success({
            message: response?.data?.message,
            duration: 5,
          });

          dispatch({
            type: GET_LOCKED_PLEDGE_DETAILS,
            payload: {
              customerId: customer?.customerId,
              team: selectedTaskOwner,
            },
          });

          setWaiveInterestPopupVisible();
        } else
          notification.error({
            message: response?.data?.message || GENERIC_ERROR,
            duration: 5,
          });

        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        notification.error({ message: GENERIC_ERROR, duration: 5 });
      }
    }
  };

  const [columns] = useState([
    {
      name: 'loanNumber',
      title: 'Agreement',
      getCellValue: (row: any) => row?.agreementNumber,
    },
    {
      name: 'startDate',
      title: 'Start Date',
      getCellValue: (row: any) => dateDisplayFormat(row?.startDate),
    },
    {
      name: 'dueDate',
      title: 'Due Date',
      getCellValue: (row: any) => dateDisplayFormat(row?.dueDate),
    },
    {
      name: 'days',
      title: 'Days',
      getCellValue: (row: any) => row?.days,
    },
    {
      name: 'daily',
      title: 'Daily',
      getCellValue: (row: any) => currencyFormat(row?.daily, true),
    },
    {
      name: 'original',
      title: 'Original Loan',
      getCellValue: (row: any) =>
        `${currencyFormat(row?.originalLoanAmount, true)}`,
    },
    {
      name: 'interest',
      title: 'Interest',
      getCellValue: (row: any) => `${currencyFormat(row?.interest, true)}`,
    },

    {
      name: 'amountWaived',
      title: 'Amount Waived',
      getCellValue: (row: any) => currencyFormat(row?.amountWaived),
    },
    {
      name: 'dueToday',
      title: 'Due Today',
      getCellValue: (row: any) => currencyFormat(row?.dueToday),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'loanNumber', width: '12%' },
    { columnName: 'startDate', width: '11%' },
    { columnName: 'dueDate', width: '11%' },
    { columnName: 'days', width: '6%' },
    { columnName: 'daily', width: '11%', align: 'right' },
    { columnName: 'original', width: '12%', align: 'right' },
    { columnName: 'interest', width: '12%', align: 'right' },
    {
      columnName: 'amountWaived',
      width: '13%',
      align: 'right',
    },
    {
      columnName: 'dueToday',
      width: '12%',
      align: 'right',
    },
  ]);

  return (
    <>
      <Modal
        title={HEADINGS.WAIVE_INTEREST}
        open={open}
        width={1050}
        centered
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        footer={[
          <Row gutter={24} justify="space-between" key="manageService">
            <Col>
              <Button
                itemProp="secondary"
                key="manageServiceCancel"
                onClick={onCancelClick}
              >
                {BUTTONS_LABEL.CANCEL}
              </Button>
            </Col>
            <Col>
              <Button
                disabled={noOfDays <= 0}
                loading={isLoading}
                onClick={checkForOverlappingPeriod}
              >
                {BUTTONS_LABEL.CONFIRM}
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Spin spinning={isFetching}>
          <StyledRow>
            <Col span={24}>
              <VirtualGrid
                urlType={GRID_NO_URL}
                columnsProps={columns}
                tableColumnExtensionsProps={tableColumnExtensions}
                rowData={isEmpty(waivedLoanDetails) ? [] : [waivedLoanDetails]}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col span={24}>
              <StyledHistoryTableWrapper>
                <table className="waive-interest-details-table">
                  <thead>
                    <tr style={{ backgroundColor: 'var(--catskill-white)' }}>
                      <td colSpan={4}>
                        <StyledTitle>
                          {HEADINGS.WAIVED_INTEREST_HISTORY}
                        </StyledTitle>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{HEADINGS.DATE_FROM}</td>
                      <td>{HEADINGS.DATE_TO}</td>
                      <td>{HEADINGS.NO_OF_DAYS}</td>
                      <td>{HEADINGS.REASON}</td>
                    </tr>

                    {waivedLoanDetails?.waiveInterestHistory?.length > 0 ? (
                      <>
                        {waivedLoanDetails?.waiveInterestHistory?.map(
                          (loan: any, index: number) => (
                            <tr key={index}>
                              <td>{dateDisplayFormat(loan?.dateFrom)}</td>
                              <td>{dateDisplayFormat(loan?.dateTo)}</td>
                              <td>{Math.round(loan?.numberOfDays)}</td>
                              <td>{loan?.reason}</td>
                            </tr>
                          )
                        )}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={4} style={{ textAlign: 'center' }}>
                          No Items
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </StyledHistoryTableWrapper>
            </Col>
          </StyledRow>
          <Row>
            <Col span={4}>From Date</Col>
            <Col span={4} offset={1}>
              To Date
            </Col>
          </Row>
          <StyledRow>
            <Col span={4}>
              <DatePickerMUI
                disableFutureDates={false}
                minDate={agreementStartDate}
                maxDate={waiveInterestMaxDate}
                onChange={onFromDateChange}
              />
            </Col>
            <Col span={4} offset={1}>
              <DatePickerMUI
                disableFutureDates={false}
                minDate={agreementStartDate}
                maxDate={waiveInterestMaxDate}
                onChange={onToDateChange}
              />
            </Col>
            <StyledCol span={4}>
              <StyledTitle>{noOfDays} days</StyledTitle>
            </StyledCol>
          </StyledRow>
          <Row>
            <Col span={24}>
              <span>Notes*</span>
            </Col>
            <Col span={24}>
              <TextArea
                rows={6}
                maxLength={600}
                onChange={(event: any) => setNotes(event?.target?.value)}
                profanityExists={handleProfanity}
                autoComplete="on"
                spellCheck
                profanityCheck
              />
            </Col>
          </Row>
        </Spin>
        {isProfanity && (
          <ControlledAlert
            id="caseUpdate"
            width={600}
            visible={isProfanity}
            message={PROFANITY_CHECK.REMOVE_PROFANITY}
            secondaryMessage={
              <>
                {PROFANITY_CHECK.PROFANITY_WORDS}
                <b>&nbsp;{profanityError.badWords.join(', ')}</b>
              </>
            }
            onClick={() => {
              setProfanity(false);
            }}
            yesButtonText={BUTTONS_LABEL.REMOVE_PROFANITY}
            isCancelBtn={false}
          />
        )}
      </Modal>
    </>
  );
};

export default WaiveInterestPopup;
