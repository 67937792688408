import React, { useEffect } from 'react';
import './TimeOutLayout.less';
import styled from 'styled-components';
import Avatar from 'antd/lib/avatar/avatar';
import { Button, Col } from 'antd';
import { LOGOFFALERTTIME } from '../constants';

const StyledColImageContainer = styled(Col)`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
`;

const StyledAvatar = styled(Avatar)`
  border: 2px solid var(--avatar-border);
  width: 150px;
  height: 150px;
`;

function TimeOutLayout(props: any) {
  let { sessionTimeOut } = props;
  const { signOut } = props;
  const userProfilePhoto = '';
  sessionStorage.setItem('isTimeOut', '1');
  setTimeout(() => {
    if (sessionTimeOut && sessionStorage.getItem('isTimeOut') === '1') {
      signOut();
    }
  }, 1000 * LOGOFFALERTTIME);

  useEffect(() => () => {
    //eslint-disable-next-line
    sessionTimeOut = false;
    sessionStorage.setItem('isTimeOut', '0');
  });

  return (
    <div className="timeout-cont">
      <div className="time-main-cont">
        <div className="user-cont">
          <StyledColImageContainer>
            <StyledAvatar
              src={
                userProfilePhoto ||
                require('../../../assets/images/guest_thumbnail.svg')
              }
              size={150}
            />
          </StyledColImageContainer>
        </div>
        <div className="timeout-message">
          Your system will time out in 60 seconds and then you will need to log
          back in
        </div>
        <div className="timer-content">
          <div className="countdown">
            <svg viewBox="-50 -50 100 100" strokeWidth="10">
              <circle r="45"></circle>
              <circle
                r="45"
                strokeDasharray="282.7433388230814"
                strokeDashoffset="282.7433388230814px"
              ></circle>
            </svg>
          </div>
        </div>
        <div className="timeout-message">Click anywhere to continue or</div>
        <div className="logooff-cont">
          <Button>Log Off</Button>
        </div>
      </div>
    </div>
  );
}

export default TimeOutLayout;
