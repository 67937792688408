import * as urls from '../apiUrls';
import { doRequest } from 'utils/request';

const getFiltersAllServices = () => {
  return doRequest(`${urls.GET_FILTER_ALL_SERVICES}`, 'get');
};

const getFiltersAllStatuses = () => {
  return doRequest(`${urls.GET_FILTER_ALL_STATUSES}`, 'get');
};

const getManagedCaseStatuses = () => {
  return doRequest(`${urls.GET_MANAGED_CASE_STATUSES}`, 'get');
};

const postGetAllNotifications = (params) => {
  return doRequest(`${urls.POST_GET_ALL_NOTIFICATIONS}`, 'post', params);
};

const postGetAllNotificationQuery = (params, skip, take) => {
  const payload = { ...params, skip, take };

  return doRequest(`${urls.POST_GET_ALL_NOTIFICATIONS}`, 'post', payload);
};

const postViewNotificationItem = (params) => {
  return doRequest(`${urls.POST_VIEW_NOTIFICATION_ITEM}`, 'post', params);
};

const postGetAllManagedCases = (params) => {
  return doRequest(`${urls.POST_GET_ALL_MANAGED_CASES}`, 'post', params);
};

const getTaskByCustomerId = (customerId, team) => {
  return doRequest(`${urls.GET_CUSTOMER_TASK}/${customerId}/${team}`, 'get');
};

const getUserTeams = () => {
  return doRequest(`${urls.GET_USER_TEAMS}`, 'get');
};

export {
  getFiltersAllServices,
  getFiltersAllStatuses,
  getManagedCaseStatuses,
  postGetAllNotifications,
  postGetAllNotificationQuery,
  postViewNotificationItem,
  postGetAllManagedCases,
  getTaskByCustomerId,
  getUserTeams,
};
