import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  basket: {},
  error: {},
  isLoading: false,
  isProgressLoading: false,
  recheckRetryCount: 0,
  isRecheckRetryAlert: false,
  isTimeoutSignalR: false,
  isRestartConnection: false,
  termAndConditionRead: [],

  //contract printing state
  pledgeContractPrintAttempts: {},
  pledgeContractPrintStatus: {},
  pickupContractPrintStatus: {},
  pickupAffiPrintStatus: {},
  surplusContractPrintStatus: {},
  contractPrinitingError: false,
  isPrinterError: false,
};
const slice = createSlice({
  name: 'checkoutActions',
  initialState,
  reducers: {
    initLoading(state, action) {
      state.isLoading = action.payload;
    },
    success(state, action) {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        isProgressLoading: false,
      };
    },
    signalrResponse(state, action) {
      if (action.payload?.basket?.basketId === state.basket?.basketId) {
        return {
          ...state,
          ...action.payload,
          isProgressLoading: false,
        };
      } else {
        return {
          ...state,
          isProgressLoading: false,
        };
      }
    },
    failed(state, action) {
      state.error = {
        message: action.payload.message,
        description: action?.payload?.response?.data?.error,
      };
      state.isLoading = false;
      state.isProgressLoading = false;
    },
    initProgress(state) {
      state.isProgressLoading = true;
    },
    recheckCounter(state, action) {
      state.recheckRetryCount = action.payload;
      if (action.payload > 0) {
        state.isRecheckRetryAlert = true;
      }
    },
    clearFailed(state, action) {
      state.error = {};
    },
    setIsTimeoutSignalR(state) {
      state.isTimeoutSignalR = true;
      state.isRestartConnection = false;
    },
    setTermAndConditionRead(state, action) {
      state.termAndConditionRead = [...action.payload];
    },
    setTermCondReaded(state, action) {
      state.termAndConditionRead = state.termAndConditionRead.map(
        (item, inx) => {
          if (inx === +action.payload) {
            item = false;
          }
          return item;
        }
      );
    },
    clearIsTimeoutSignalR(state) {
      state.isTimeoutSignalR = false;
      state.isRestartConnection = true;
    },
    clearRecheckAlert(state) {
      state.isRecheckRetryAlert = false;
    },

    //Contract/Affi print actions
    setPickupContractPrintStatusCollection(state, action) {
      state.pickupContractPrintStatus = action.payload;
    },
    setPickupAffiPrintStatusCollection(state, action) {
      state.pickupAffiPrintStatus = action.payload;
    },
    setPledgeContractPrintAttempts(state, action) {
      if (
        state.pledgeContractPrintAttempts[action.payload] > 0 &&
        state.pledgeContractPrintAttempts[action.payload] < 3
      ) {
        state.pledgeContractPrintAttempts[action.payload] =
          state.pledgeContractPrintAttempts[action.payload] + 1;
      } else {
        state.pledgeContractPrintAttempts[action.payload] = 1;
      }
    },
    setPledgeContractPrintStatusCollection(state, action) {
      state.pledgeContractPrintStatus = action.payload;
    },
    setSurplusContractPrintStatusCollection(state, action) {
      state.surplusContractPrintStatus = action.payload;
    },

    updatePledgeContractPrintStatusCollection(state, action) {
      let pledgeContracts = { ...state.pledgeContractPrintStatus };
      pledgeContracts[action.payload] = true;

      state.pledgeContractPrintStatus = pledgeContracts;
    },
    updatePickupContractPrintStatusCollection(state, action) {
      let pickupContracts = { ...state.pickupContractPrintStatus };
      pickupContracts[action.payload] = true;

      state.pickupContractPrintStatus = pickupContracts;
    },
    updatePickupAffiPrintStatusCollection(state, action) {
      let pickupAffi = { ...state.pickupAffiPrintStatus };
      pickupAffi[action.payload] = true;

      state.pickupAffiPrintStatus = pickupAffi;
    },
    updateSurplusContractPrintStatusCollection(state, action) {
      let surplusContracts = { ...state.surplusContractPrintStatus };
      surplusContracts[action.payload] = true;

      state.surplusContractPrintStatus = surplusContracts;
    },

    setContratPrintingError(state, action) {
      state.contractPrinitingError = action.payload;
    },
    setIsPrinterError(state, action) {
      state.isPrinterError = action.payload;
    },

    clearCheckout() {
      return {
        ...initialState,
      };
    },
  },
});

export default slice.reducer;
export const { reducer, actions } = slice;
export { initialState };
