import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import { uniqBy } from 'lodash';

const StyledModal = styled(Modal)`
  border-radius: 6px;
  color: var(--red);
  & .ant-modal-header > .ant-modal-title {
    color: var(--red);
  }
`;

interface AlertsProps {
  visible: boolean;
  setAlertsVisible: () => void;
  errorFields: Array<any>;
}

const AlertsPopup = ({
  visible,
  setAlertsVisible,
  errorFields,
}: AlertsProps) => {
  const onCancelClick = () => {
    setAlertsVisible();
  };

  let uniqueErrorFields = errorFields.filter((x) => x !== undefined);
  uniqueErrorFields = uniqBy(uniqueErrorFields, 'name[0]');

  return (
    <>
      <StyledModal
        title="Alert"
        open={visible}
        width={330}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={onCancelClick}
        footer={null}
        centered
      >
        {uniqueErrorFields?.map((field, index) => {
          return (
            <div key={index} style={{ padding: '4px 0' }}>{`${index + 1}. ${
              field?.errors[0]
            }`}</div>
          );
        })}
      </StyledModal>
    </>
  );
};

export default AlertsPopup;
