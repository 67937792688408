import React from "react";
import { Layout } from "antd";
import styled from "styled-components";
import FooterButtons from 'components/common/footerButtons';

const StyledContentLayout = styled(Layout)`
  background: var(--white);
  padding: 15px 30px;
  border-radius: 5px;
`;
const Reports = () => {
  // TODO in future
  const onConfirmExitOnlyClick = () => {/* do nothing */};
  return (
    <>
      <StyledContentLayout className="reports-wrapper">
        Reports comming soon
      </StyledContentLayout>
      <FooterButtons
        isExitOnly
        enableExtraClass
        extraButtonItemProp="secondary"
        onConfirmExitClick={onConfirmExitOnlyClick}
      />
    </>
  );
};

export default Reports;
