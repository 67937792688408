import React, { useCallback } from 'react';
import { Modal, Row, Col, Form, Input } from 'antd';

import styled from 'styled-components';
import { Button } from 'components/common/Button';
import { useDispatch } from 'react-redux';
import { VALIDATE_ALL_SERVICES_BASKET } from 'action_creators/basket';

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    text-align: center;
  }
`;

const StyledHeader = styled(Row)`
  font-size: var(--font-size-20);
  white-space: initial;
  color: var(--red);
  font-weight: var(--font-weight-500);
  line-height: 25px;
  text-align: center;
`;

const StyledDescription = styled(Row)`
  color: var(--text-color);
  font-style: normal;
  font-weight: normal;
  margin: 10px 45px 0 45px;
  font-size: var(--font-size-16);
  text-align: center;
`;

const StyledRow = styled(Row)`
  margin-block-end: 10px;
  margin-top: 10px;
`;

const StyledButton = styled(Button)`
  margin: 5px 20px 0px 20px;
`;

/* eslint-disable no-template-curly-in-string */
export const validateMessages = {
  required: '${label} is required!',
};

const ManagerInfoPopup = ({ visible, onClick, onCancel }: any) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onSubmit = (data: any) => {
    const authorisationHeaders = {
      ...data,
      authorisationPassword: data.authorisationPassword,
    };
    dispatch({
      type: VALIDATE_ALL_SERVICES_BASKET,
      payload: { authorisationHeaders: authorisationHeaders },
    });
    onClick(false);
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); //Need this console for checking error
  };

  const onCancelClick = useCallback(() => {
    onCancel();
  }, [onCancel]);

  return (
    <StyledModal
      open={visible}
      closable={false}
      width={457}
      onCancel={onCancelClick}
      className="alert-modal-wrapper"
      footer={null}
    >
      <Row>
        <Col span={24}>
          <StyledHeader justify="center" align="middle">
            Alert
          </StyledHeader>
          <StyledDescription>
            <Col span={24}>
              <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                onFinishFailed={onFinishFailed}
                validateMessages={validateMessages}
              >
                <StyledRow align="middle" justify="space-around" gutter={32}>
                  <Col span={24}>
                    <Form.Item
                      label="Authorisation Username"
                      name="authorisationUserName"
                      className="label-control"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input className="input-control" autoComplete="off" />
                    </Form.Item>
                  </Col>
                </StyledRow>
                <StyledRow align="middle" justify="space-around" gutter={32}>
                  <Col span={24}>
                    <Form.Item
                      label="Authorisation Password"
                      name="authorisationPassword"
                      className="label-control"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        className="input-control"
                        autoComplete="off"
                        type="password"
                      />
                    </Form.Item>
                  </Col>
                </StyledRow>
                <StyledRow align="middle" justify="center" gutter={32}>
                  <StyledButton
                    itemProp="secondary"
                    key="submit"
                    htmlType="submit"
                  >
                    Submit
                  </StyledButton>
                  <StyledButton
                    itemProp="secondary"
                    key="back"
                    onClick={onCancelClick}
                  >
                    Cancel
                  </StyledButton>
                </StyledRow>
              </Form>
            </Col>
          </StyledDescription>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ManagerInfoPopup;
