export const ACCOUNT_SHORTFALL = 'Account Shortfall';
export const ACCOUNT_SURPLUS = 'Account Surplus';
export const BALANCE_DUE = 'Balance Due';
export const EXIT = 'Exit';
export const PROCEED_TO_BASKET = 'Proceed to Basket';
export const LAYAWAY = 'Layaway';
export const REFUND_OPTIONS = 'Refund/Exchange Options';
export const FIND_ITEM = 'Find Item';
export const REFUND_EXCHANGE_ITEM = 'Refund/Exchange Item';
export const TOTAL_AMOUNT_ITEMS = 'Total Value';
export const REFUND_REASON_LABEL = 'Select Reason For Refund';
export const REFUND_REASON_PLACEHOLDER = 'Reason for Refund';
export const BARCODE_PLACEHOLDER = 'Enter Item Barcode';
export const SOLD_ITEM = 'Find Sold Item';
export const RECIEPT_PLACEHOLDER = 'Enter Receipt Number';
export const HEADER_TITLE = 'Retail Shopping';
export const USERNAME = 'Guest';
export const NO_URL = 'noUrl';
export const RECIEPT_NO_LABEL = 'Receipt No.:';
export const SOLD_AT_LABEL = 'Sold At:';
export const CUSTOMER_LABEL = 'Customer:';
export const SALE_DATE_AND_TIME_LABEL = 'Sale Date & Time:';
export const SOLD_BY_LABEL = 'Sold By:';
export const REFUND_VALUE_LABEL = 'Refund Value';
export const ADD_RETAIL_ITEM = 'Add Retail Item';
export const ADD_LAYAWAY_ITEM = 'Add Layaway Item';
export const REMOVE_FROM_BASKET = 'Remove from Basket';
export const FIND_CUSTOMER = 'Find Customer';
export const RETURN_DATE_AND_TIME = 'Return Date & Time: ';
export const RETURN_AT = 'Return at: ';
export const PAYMENT_MODE = {
  CARD_LABEL: 'Paid by Card',
  CASH_LABEL: 'Paid by Cash',
};
export const NOT_AVAILABLE_LABEL = 'Not Available';
export const GUEST_LABEL = 'Guest';
export const METAL_TYPE = [
  'Yellow Gold',
  'White Gold',
  'Rose Gold',
  'Bicolour Gold',
  'Tricolour Gold',
  'Silver',
  'Sterling',
  'Platinum',
  'Palladium',
  'Stainless steel',
  'Coloured metals',
  'Titanium',
  'Ceramic',
  'White Metal',
];
export const GOLD_FINENESS = [
  '9ct',
  '10ct',
  '14ct',
  '15ct',
  '18ct',
  '21ct',
  '22ct',
];
export const REFUND_REASONS = [
  'Refund Under Store Exchange Policy',
  'Purchased In Error',
  'Article(s) Faulty',
  'Item Not Suitable',
  'Other',
];
export const PLATINUM_FINENESS = ['950'];
export const SILVER_FINENESS = ['925'];

export const OTHER_METALS = [
  'Stainless steel',
  'Coloured metals',
  'Titanium',
  'Ceramic',
  'White Metal',
  'Yellow Metal',
];

export const GOLD_TYPES = [
  'Yellow Gold',
  'White Gold',
  'Rose Gold',
  'Bicolour Gold',
  'Tricolour Gold',
];

export const ENTIQUE_METALS = ['Sterling', 'Platinum', 'Palladium'];
export const SILVER_TYPE = ['Silver'];

export const SEARCH = 'Search';
export const SELECT_PLACEHOLDER = 'Select';
export const CANCEL = 'Cancel';
export const ADD = 'Add';
export const PUSH_REFUND = 'Push Refund';
export const CONTINUE = 'Continue';
export const RESET = 'Reset';
export const DESCRIPTION_PLACEHOLDER = 'e.g. Wedding Ring';

export const PRICE_RANGE_MIN = 'Min (£)';
export const PRICE_RANGE_MAX = 'Max (£)';

export const TRANSACTION_FROM = 'From';
export const TRANSACTION_TO = 'To';

export const WEIGHT_RANGE_MIN = 'Min';
export const WEIGHT_RANGE_MAX = 'Max';

export const STONE_SIZE_FROM = 'From (pts)';
export const STONE_SIZE_TO = 'To (pts)';
export const EXIT_AND_SAVE = 'Exit & Save';
export const EXIT_AND_CLEAR = 'Exit & Clear';

export const ERROR_MESSAGE_ON_CUSTOMER_AVAILABLE = {
  message: 'Error Fetching Customer',
  description: 'Customer Name is not available here.',
};

export const ERROR_MESSAGE_ON_RECEIPT_NOT_FOUND = {
  message: 'Error Fetching Receipt',
  description: 'Receipt is not available here.',
};

export const NOTIFICATION_FOR = {
  NOTIFY_FOR: 'Receipt',
};

export const METAL_TYPE_ATTRIBUTE_ID = 2;
export const FINENESS_ATTRIBUTE_ID = 3;

export const CUSTOMER_DETAILS_LABEL = {
  ENTER_CUSTOMER_DETAILS: 'Enter Customer Details',
  SECOND_HAND_GOODS_MESSAGE:
    'Second hand goods VAT scheme requires a customers full name and address',
};
export const DATE_FORMAT = 'dd/MM/yyyy, hh:mm aaaa';
export const ERROR_MESSAGE_ON_RETAIL_BASKET_INVALID_INPUT = {
  message: 'Invalid Input',
  description: 'Discount cannot be greater than the List Value',
};

export const TOTAL_TRANSACTION_AMOUNT = 'Total Transaction Value';

export const INVENTORY_TYPE = {
  PREOWNED: 'Pre-owned',
  NEW: 'new',
};

export const ITEM_STATUS = {
  SOLD: 'sold',
  RETURNED: 'returned',
};

export const RETAIL_ITEM_STATUS = {
  TO_RETURN: 'To Return',
  AVAILABLE: 'Available',
};

export const RETAIL_BALANCE = {
  TAKE_FROM: 'Take from Customer',
  PAY_TO: 'Pay to Customer',
};

export const RETAIL_SWITCH = {
  LOCAL: 'Local',
  GLOBAL: 'Global',
};

export const BUTTON_LABELS = {
  CLOSE: 'Close',
  PROCEED_TO_BASKET: PROCEED_TO_BASKET,
  LAYAWAY: LAYAWAY,
  ADD_TO_BASKET: 'Add to Basket',
  ADD_WITHDRAW_FUND: 'Add/Withdraw Funds',
  STATEMENT: 'Statement',
  PRINT_CONTRACT: 'Print Contract',
  CANCEL: CANCEL,
  SAVE: 'Save',
};

export const FORM_LABELS = {
  TRANSACTION_TYPE: 'Transaction Type',
  AMOUNT: 'Amount',
  PAYMENT_TYPE: 'Payment Type',
  ERROR_MSG: {
    TRANSACTION_TYPE: 'Transaction type required.',
    AMOUNT: 'Amount is required.',
    PAYMENT_TYPE: 'Payment type is required.',
    TRANSACTION_TYPE_ADD_FAILED_SURPLUS:
      'The account is in surplus, you cannot add more funds',
  },
};

export const TRANSACTION_TYPES_OPTIONS = [
  {
    value: 1,
    label: 'Deposit Funds',
  },
  {
    value: 2,
    label: 'Withdraw Funds',
  },
];
export const PAYMENT_TYPES_OPTIONS = [
  {
    value: 1,
    label: 'Cash',
  },
  {
    value: 2,
    label: 'Card',
  },
];

export const AMOUNT_SYMBOL = '&#163;';
export const LAYAWAY_ITEM_ADD_ERROR =
  "is already added to the retail page. If you wish to add it to the layaway account, please remove the item from retail.";
