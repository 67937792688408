import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

import { actions } from 'redux/reducers/cashManagement';

import { TILL_LABELS } from '../constants';
import { Row, Input } from 'antd';
import { toFixedNoRound } from 'utils/util';

const StyledRow = styled(Row)<{ textalign: string }>`
  & .ant-input {
    color: var(--black) !important;
    font-weight: var(--font-weight-500);
    text-align: ${(props) => props.textalign};
  }
  & .ant-input-prefix {
    font-weight: var(--font-weight-500);
    margin: 0;
  }
`;

const StyledDenomination = styled.div`
  font-weight: var(--font-weight-500);
  text-align: center;
`;

const DenominationsTable = () => {
  const { currencyDenominations } = useSelector(
    (state: any) => state.cashManagement
  );

  const [denominationsDetails, setDenominationsDetails] = useState<any>([]);
  const [isSorted, setIsSorted] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSorted) setDenominationsDetails([...currencyDenominations].reverse());
    else setDenominationsDetails([...currencyDenominations]);
  }, [isSorted, currencyDenominations]);

  const decimalRestrictionHandler = (event: any) => {
    // file deepcode ignore ConfusedRegex: <this works fine>
    if (!/[0-9]/.test(event.key)) event.preventDefault();
  };

  const restrictionHandler = (event: any) => {
    //restricting all the input values except numeric and decimal(.)
    if (!/[0-9.]/.test(event.key)) event.preventDefault();
  };

  const onInputChange = (event: any) => {
    const { value, name } = event.target;
    const [columnName, denomination] = name.split('-');
    const denominationArr = JSON.parse(JSON.stringify(currencyDenominations));

    const index = currencyDenominations.findIndex(
      (el: any) => el.denomination === +denomination
    );

    if (index !== -1) {
      if (columnName === 'quantity') {
        denominationArr[index][columnName] = value;
        denominationArr[index]['amount'] = toFixedNoRound(
          +value * denomination
        );
      } else if (columnName === 'amount') {
        //Some decimal numbers can't be represented in IEEE 754, rounding off to whole no for better output
        const multiplier = 1000;
        const quantity = (+value * multiplier) / (denomination * multiplier);

        denominationArr[index][columnName] = value;
        denominationArr[index]['quantity'] = quantity;

        //logic for showing error values
        denominationArr[index]['isInvalidAmount'] =
          Math.ceil(quantity) === quantity ? false : true;
      }
    }

    dispatch(actions.updateCurrencyDenominationsRow(denominationArr));
  };

  return (
    <table className="html-denominations-table">
      <thead>
        <tr>
          <th style={{ width: '43%' }}>
            <div
              className="table-sort"
              onClick={() => setIsSorted((initState) => !initState)}
            >
              Denominations &nbsp;
              <div className="sort-indicators">
                <CaretUpOutlined className={!isSorted ? 'active' : ''} />
                <CaretDownOutlined className={isSorted ? 'active' : ''} />
              </div>
            </div>
          </th>
          <th style={{ width: '27%' }}>Quantity</th>
          <th style={{ width: '30%' }}>Value</th>
        </tr>
      </thead>
      <tbody>
        {denominationsDetails.length === 0 ? (
          <tr>
            <td align="center" colSpan={3}>
              No Items
            </td>
          </tr>
        ) : (
          denominationsDetails.map((row: any, index: any) => (
            <tr key={index}>
              <td>
                <StyledDenomination>
                  {row?.currencyCode === TILL_LABELS.DEFAULT_TILL_CURRENCY &&
                  row?.denomination < 1 ? (
                    <span>{`${row.denomination * 100}p`}</span>
                  ) : (
                    <>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: row?.currencySymbol,
                        }}
                      ></span>
                      <span>{row?.denomination}</span>
                    </>
                  )}
                </StyledDenomination>
              </td>
              <td>
                <StyledRow textalign="center">
                  <Input
                    name={`quantity-${row?.denomination}`}
                    type="text"
                    value={row?.quantity}
                    placeholder="0"
                    bordered={false}
                    tabIndex={index + 1}
                    onChange={onInputChange}
                    onKeyPress={decimalRestrictionHandler}
                  />
                </StyledRow>
              </td>
              <td className="domination-value">
                <StyledRow textalign={'right'}>
                  <Input
                    className={
                      row?.isInvalidAmount ? 'error' : 'input-padding-left'
                    }
                    name={`amount-${row?.denomination}`}
                    type="text"
                    value={row?.amount}
                    placeholder="0.00"
                    bordered={false}
                    tabIndex={index + currencyDenominations.length + 1}
                    addonBefore={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: row?.currencySymbol,
                        }}
                      ></span>
                    }
                    onChange={onInputChange}
                    onKeyPress={restrictionHandler}
                  />
                </StyledRow>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

export default DenominationsTable;
