import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { currencyFormat, getPurchaseStatus } from 'utils/util';
import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';
import { actions } from 'redux/reducers/basket';
import VirtualGrid from 'components/common/virtualGrid/index';
import ImageLoader from 'components/common/ImageLoader';

const BasketPurchaseReturnTable = (item: any) => {
  const [rows, setRows] = useState<Array<any>>([]);
  const [selection, setSelection] = useState<Array<any>>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const purchaseData = item?.items?.reduce((acc: any, itm: any) => {
      const mergedData = itm?.purchaseReturn?.request?.item?.purchaseItems
        ?.filter((x: any) => x.selectedForReturn)
        .map((pi: any) => {
          return { ...itm?.purchaseReturn?.request?.item, ...pi };
        });
      return [...acc, ...mergedData];
    }, []);
    setRows(purchaseData);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const itemsToRemove = rows
      ?.filter((x: any) => {
        if (selection.includes(x.id)) {
          return true;
        } else return false;
      })
      .map((item: any) => item.itemId);

    const payload = {
      itemIds: itemsToRemove,
    };
    dispatch(actions.move(payload));
  }, [selection]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectionChange = (row: any) => {
    setSelection(row);
  };

  const [columns] = useState([
    {
      name: 'image',
      title: 'Image',
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row.id}?isThumnails=false`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
    },
    {
      name: 'purchaseNumber',
      title: 'Purchase No.',
      getCellValue: (row: any) => row.purchaseNumber,
    },
    {
      name: 'purchaseStatus',
      title: 'Status',
      type: 'icon',
      getCellValue: (row: any) =>
        getPurchaseStatus(row?.isQuarantine, row?.isReturned),
    },
    {
      name: 'barcode',
      title: 'Barcode',
      getCellValue: (row: any) => row.barcode,
    },
    {
      name: 'description',
      title: 'Description',
      getCellValue: (row: any) => row.description,
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) => `${row?.weight?.toFixed(2)}g`,
    },
    {
      name: 'purchaseValue',
      title: 'Purchase Amount',
      type: 'currency',
      getCellValue: (row: any) => row?.amount,
    },
    {
      name: 'oneMonthFee',
      title: 'Fee',
      type: 'currency',
      getCellValue: (row: any) => row?.oneMonthFee,
    },
    {
      name: 'purchaseDate',
      title: 'Purchase Date',
      getCellValue: (row: any) =>
        row && row?.purchaseDate
          ? format(new Date(row?.purchaseDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'holdDate',
      title: 'Hold Date',
      getCellValue: (row: any) =>
        row && row?.holdDate
          ? format(new Date(row?.holdDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'toTakeAmount',
      title: 'To Take',
      type: 'input',
      getCellValue: (row: any) => currencyFormat(row?.toTakeAmount),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'image', width: '5%' },
    { columnName: 'purchaseNumber', width: '9%' },
    { columnName: 'purchaseStatus', width: '7%' },
    { columnName: 'barcode', width: '9%' },
    { columnName: 'description', width: '14%' },
    { columnName: 'weight', width: '8%' },
    { columnName: 'purchaseValue', width: '10%', align: 'right' },
    { columnName: 'oneMonthFee', width: '7%', align: 'right' },
    { columnName: 'purchaseDate', width: '9%' },
    { columnName: 'holdDate', width: '9%' },
    { columnName: 'toTakeAmount', width: '8%' },
  ]);

  const formattedColumns = [
    'purchaseStatus',
    'toTakeAmount',
    'purchaseValue',
    'oneMonthFee',
  ];

  return (
    <>
      <div className="basket-item-table">
        <VirtualGrid
          urlType={GRID_NO_URL}
          columnsProps={columns}
          tableColumnExtensionsProps={tableColumnExtensions}
          rowData={rows}
          formattedColumns={formattedColumns}
          selection={selection}
          onSelectionChange={onSelectionChange}
          highlightRow={true}
          rowSelectionEnable={true}
          selectByRowClick={true}
        />
      </div>
    </>
  );
};

export default BasketPurchaseReturnTable;
