import { call, takeEvery, select, all, put } from 'redux-saga/effects';
import * as ACTIONS from 'action_creators/basket';
import { groupBy } from 'lodash';
import { BASKET_SERVICE_TYPE } from 'globalConstants';
import { calculateTotalSumByKey, calculateTotalWeight } from 'utils/util';
import { getLoanPayableSaga } from '../pledge';
import { actions } from 'redux/reducers/basket/index';

//calculating interest rate band based on loan amount
const calculateInterestRate = (
  loanAmount,
  interestRateList,
  isSelectedInterestOnSplit
) => {
  if (
    loanAmount !== undefined &&
    loanAmount > 0 &&
    !isSelectedInterestOnSplit
  ) {
    for (let i = 0; i < interestRateList?.interestRates?.length; i++) {
      if (loanAmount <= 500) {
        return interestRateList.interestRates[i];
      } else if (
        loanAmount > interestRateList.interestRates[i].loanAmountUpperLimit &&
        loanAmount <= interestRateList.interestRates[i + 1].loanAmountUpperLimit
      ) {
        return interestRateList.interestRates[i + 1];
      }
    }
  }
};

export function* loanCalculatorBasketSaga(action) {
  const {
    basket,
    interestRateList,
    initialInterestRate,
    initialApr,
    isSelectedInterestOnSplit,
    isAutoCalculated,
  } = yield select((state) => state.basket);
  if (!basket?.basketServices) {
    return;
  }
  const groupedItems = groupBy(basket.basketServices, 'serviceType');
  let parallelCalls = [];
  Object.keys(groupedItems).forEach((serviceItem) => {
    const service = groupedItems[serviceItem];
    service.forEach((item) => {
      let params = {};
      switch (serviceItem) {
        case `${BASKET_SERVICE_TYPE.PLEDGE}`: {
          const allPledgeItems = item?.pledge?.request?.items;
          const loanAmount = calculateTotalSumByKey(allPledgeItems,'loanAmount');
          const weight = calculateTotalWeight(allPledgeItems);
          const interestRateBasedOnBand = calculateInterestRate(
            loanAmount,
            interestRateList,
            isSelectedInterestOnSplit
          ); //calculating interest rate band based on loan amount

          params = {
            storeId: basket?.storeId,
            loanAmount: loanAmount,
            interestRate: isSelectedInterestOnSplit
              ? item?.pledge?.request?.interestRate || initialInterestRate
              : interestRateBasedOnBand?.monthlyInterestPercentCharge,
            publishedInterestAnnualPercentageRate: isSelectedInterestOnSplit
              ? item?.pledge?.request?.annualPercentageRate || initialApr
              : interestRateBasedOnBand?.publishedInterestAnnualPercentageRate,
            basketId: basket?.basketId,
            serviceId: item.serviceId,
            userId: basket?.userId,
            totalWeight: weight,
            serviceType: BASKET_SERVICE_TYPE.PLEDGE,
            isAutoCalculated: isAutoCalculated,
            totalPledgeAmount: Math.abs(basket.totalPledgeAmount),
            isManualInterestSet: isSelectedInterestOnSplit, // to be changed - may need a new var
          };
          parallelCalls.push(
            call(getLoanPayableSaga, {
              payload: { params },
            })
          );
          break;
        }
        case `${BASKET_SERVICE_TYPE.PURCHASE}`: {
          const allPurchageItems = item?.purchase?.request?.items;
          const purchaseLoanAmount = calculateTotalSumByKey(
            allPurchageItems,
            'loanAmount'
          );
          const purchaseWeight = calculateTotalWeight(allPurchageItems);
          params = {
            storeId: basket?.storeId,
            loanAmount: purchaseLoanAmount,
            interestRate:
              item?.purchase?.request?.interestRate || initialInterestRate,
            basketId: basket?.basketId,
            serviceId: item.serviceId,
            userId: basket?.userId,
            totalWeight: purchaseWeight,
            serviceType: BASKET_SERVICE_TYPE.PURCHASE,
          };
          parallelCalls.push(
            call(getLoanPayableSaga, {
              payload: { params },
            })
          );
          break;
        }
        default:
          break;
      }
    });
  });
  if (parallelCalls.length > 0) {
    try {
      const response = yield all(parallelCalls);
      if (response.length > 0) {
        yield put({ type: ACTIONS.GET_BASKET_BY_BASKETID });
        yield put(actions.clearResetChildTabOnSplitAdd());
        yield put(actions.unlockMerge());
      }
    } catch (e) {
      console.log('payload', e);
      yield put(actions.clearResetChildTabOnSplitAdd());
      yield put(actions.unlockMerge());
    }
  }
}

export function* watchLoanServiceBasket() {
  yield takeEvery(
    ACTIONS.LOAN_AMOUNT_CALCULATOR_ON_BASKET,
    loanCalculatorBasketSaga
  );
}
