import React from 'react';
import styled from 'styled-components';
import { Modal, Row, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    border: 2px solid var(--primary-color);
    border-radius: 12px;

    & .ant-modal-body {
      text-align: center;
      font-size: var(--font-size-20);

      & .anticon-spin {
        font-size: var(--font-size-35);
      }
    }

    & .ant-modal-close-x,
    & .ant-modal-footer {
      display: none;
    }
  }
`;

const StyledHeader = styled(Row)`
  font-weight: var(--font-weight-600);
`;

const StyledDescription = styled(Row)`
  text-align: center;
  font-weight: normal;
  margin: 10px;
`;

const ScannerPopover = ({
  visible,
  title = 'Document being Scanned.',
  message = 'Please wait....',
}: any) => {
  return (
    <StyledModal centered open={visible} width={340}>
      <Row>
        <Col span={24}>
          <StyledHeader>
            <Col span={24}>
              <Row style={{ margin: '10px 0' }}>
                <Col span={24}>
                  <LoadingOutlined />
                </Col>
              </Row>
              <Row>
                <Col span={24}>{title}</Col>
              </Row>
            </Col>
          </StyledHeader>
          <StyledDescription>
            <Col span={24}>{message}</Col>
          </StyledDescription>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ScannerPopover;
