import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  SEARCH_AUCTION,
  GET_AUCTIONS_OVER_SEVENTYFIVE,
  GET_PFI_UNDER_SEVENTYFIVE,
  GET_AUCTION_DETAILS,
  PUT_CONFIRM_COLLECTION,
  CONFIRM_AUCTION_SETTLEMENT,
  SETTLE_AUCTION,
  UPDATE_SOLD_VALUE,
  PRINT_PASS_LIST,
  GET_PLEDGE_ITEMS,
  GET_LOAN_DETAILS,
  GET_PURCHASE_LOAN_DETAILS,
  GET_PARENT_ITEM_CATEGORY,
  REVIEW_ITEMS_PFA,
  POST_PFI_BARCODE,
  AUCTION_ITEM_PRICE_REQUEST,
  PASS_AUCTION_LOT,
  LOCK_AUCTION,
  NOTIFY_AUCTION_LOCKING,
  GET_PLEDGE_ITEM_DETAILS,
  PFA_LOT_NUMBER,
  GENERATE_LOT_STICKER,
  PUSH_PLEDGE_TO_PFI,
  PUSH_PURCHASE_TO_PFI,
  CONFISCATE_PLEDGES,
  VULNERABLE_PLEDGES,
  GET_VULNERABLE_PLEDGES,
  GET_VULNERABLE_NOTES,
  ADD_VULNERABLE_NOTE,
  RELEASE_VULNERABLE_PLEDGE,
} from 'action_creators/pledgeManagement';
import {
  postAuctionSearch,
  getAuctionsOver,
  getPfiUnder,
  getPfiPurchaseUnder,
  getAuctionDetails,
  putConfirmCollection,
  getConfirmAuctionSettlement,
  putSettleAuction,
  putUpdateSoldValue,
  getPrintPassList,
  getPledgeItemsDetails,
  getLoanDetails,
  getPurchaseLoanDetails,
  getParentItemCategory,
  postReviewItemsPfa,
  putPassAuctionLot,
  putLockAuction,
  getNotifyAuctionLocking,
  postPfiBarcode,
  putPfaLotNumber,
  getGenerateLotSticker,
  postPushToPFA,
  postPledgeToPfi,
  postPurchaseToPfi,
  postConfiscatePledges,
  postVulnerablePledges,
  getVulnerablePledges,
  getVulnerableNotes,
  postVulnerableNote,
  postRemoveVulnerableNote,
} from 'services/pledgeManagement';
import { getItemPrice, getPledgeAgreementItems } from 'services/pledge';
import { cacheCustomerStatus, clearCacheByCacheKey } from 'utils/cacheServices';
import { actions } from 'redux/reducers/pledgeManagement';
import { sendToPrinter } from 'services/user';
import {
  SENT_TO_PRINTER,
  LOT_STICKER_SERVICE_ERROR,
  GENERIC_ERROR,
  SUCCESS_RELEASE,
} from 'components/pledgemanagement/constants';

import { RESPONSE_STATUS } from 'globalConstants';
import { GET_CUSTOMER_PAWNBROKING_TAB_SUMMARY } from 'action_creators/pledge';
import { CUSTOMER_PROFILE_REQUEST } from 'action_creators/customer';
import { notification } from 'antd';

export function* searchAuctionSaga(action) {
  const {
    payload: { params },
  } = action;

  try {
    yield put(actions.initSearch(true));
    yield put(actions.initLoading(true));
    const response = yield call(postAuctionSearch, params);
    if (response?.data?.auctions) {
      yield put(actions.setAuctionSearchList(response?.data?.auctions));
    }
    yield put(actions.initSearch(false));
    yield put(actions.initLoading(false));
  } catch (e) {
    yield put(actions.initSearch(false));
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* getAuctionsOverSaga() {
  try {
    yield put(actions.initLoading(true));
    const response = yield call(getAuctionsOver);
    if (response?.data?.auctionLoans) {
      yield put(actions.setAuctionsOverSeventyFive(response.data.auctionLoans));
    }

    yield put(actions.initLoading(false));
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* getPfiUnderSaga() {
  try {
    yield put(actions.initLoading(true));
    const response = yield call(getPfiUnder);
    const purchaseResponse = yield call(getPfiPurchaseUnder);
    if (response?.data.length > 0 || purchaseResponse?.data.length > 0) {
      yield put(
        actions.setPfiUnderSeventyFive([
          ...response.data,
          ...purchaseResponse?.data,
        ])
      );
    }

    yield put(actions.initLoading(false));
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* getAuctionDetailsSaga(action) {
  try {
    const {
      payload: { auctionId, auctionStatus },
    } = action;
    yield put(actions.initLoading(true));
    const response = yield call(getAuctionDetails, auctionId, auctionStatus);
    if (response?.data) {
      yield put(actions.initLoading(false));
      yield put(actions.setAuctionItemDetails(response.data));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* putConfirmCollectionSaga(action) {
  try {
    const {
      payload: { auctionId },
    } = action;
    yield put(actions.initLoading(true));
    const response = yield call(putConfirmCollection, auctionId);
    if (response?.data?.success) {
      yield put(actions.setConfirmedCollection(response.data));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* getConfirmAuctionSettlementSaga(action) {
  try {
    const {
      payload: { auctionId },
    } = action;
    yield put(actions.initLoading(true));
    const response = yield call(getConfirmAuctionSettlement, auctionId);
    if (response?.data) {
      yield put(actions.initLoading(false));
      yield put(actions.setConfirmSettlement(response.data));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* putSettleAuctionSaga(action) {
  try {
    const {
      payload: { auctionId },
    } = action;
    yield put(actions.initLoading(true));
    const response = yield call(putSettleAuction, auctionId);
    if (response?.data?.success) {
      yield put(actions.initLoading(false));
      yield put(actions.setSuccessSettlement(response.data));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* putUpdateSoldValueSaga(action) {
  try {
    const response = yield call(putUpdateSoldValue, action.payload);
    if (response?.data?.success) {
      yield put(actions.initLoading(false));
      yield put(actions.setUpdateSoldValue(response.data));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* getPrintPassListSaga(action) {
  try {
    const {
      payload: { auctionId, printFor, printType },
    } = action;
    yield put(actions.initLoading(true));
    const response = yield call(
      getPrintPassList,
      auctionId,
      printFor,
      printType
    );
    if (response?.data) {
      yield put(actions.initLoading(false));
      sendToPrinter(response, 'printpass', '_PrintPassList');
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* getPledgeItemsListSaga(action) {
  try {
    const {
      payload: { loanId, pledgeLoanNumber },
    } = action;
    yield put(actions.initLoading(true));
    const response = yield call(getPledgeAgreementItems, loanId);
    if (response?.data) {
      yield put(actions.initLoading(false));
      yield put(actions.setActivePfiReview(true));
      yield put(actions.setPledgeItemsList(response?.data?.items));
      yield put(actions.setPledgeInReview(pledgeLoanNumber));
      yield put(actions.setItemCount(response?.data?.items?.length));
      if (response?.data?.items?.length > 0) {
        yield put(actions.setTabsVisible(false));
      } else {
        yield put(actions.logError('No items found.'));
        yield put(actions.setIsErrorVisible(true));
      }
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}
export function* getPledgeItemsDetailsSaga(action) {
  try {
    const {
      payload: { itemId },
    } = action;
    yield put(actions.initLoading(true));
    const response = yield call(getPledgeItemsDetails, itemId);
    if (response?.data) {
      yield put(actions.setPledgeItemDetails(response?.data));
      yield put(
        actions.itemPriceValuationResponse(response?.data?.itemValuation)
      );

      yield put(actions.initLoading(false));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* putPassAuctionLotSaga(action) {
  try {
    yield put(actions.initLoading(true));
    const response = yield call(putPassAuctionLot, action.payload);
    if (response?.data) {
      yield put(actions.setSuccessPassAuctionLot(true));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* getPledgeLoanDetailsSaga(action) {
  try {
    const {
      payload: { loanNumber },
    } = action;
    yield put(actions.initLoading(true));
    const response = yield call(getLoanDetails, loanNumber);
    if (response?.data) {
      yield put(actions.setLoanDetails(response?.data));
      yield put(actions.initLoading(false));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* getPurchaseLoanDetailsSaga(action) {
  try {
    const {
      payload: { loanNumber },
    } = action;
    yield put(actions.initLoading(true));
    const response = yield call(getPurchaseLoanDetails, loanNumber);
    if (response?.data) {
      yield put(actions.setPurchaseLoanDetails(response?.data));
      yield put(actions.initLoading(false));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* getParentItemIdSaga(action) {
  try {
    const {
      payload: { Id },
    } = action;
    yield put(actions.initLoading(true));
    const response = yield call(getParentItemCategory, Id);
    if (response?.data) {
      yield put(actions.setParentItemId(response?.data));
      yield put(actions.initLoading(false));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}
export function* postPfiBarcodeSaga(action) {
  const {
    payload: { barCodeParams, newPledgeItemsList },
  } = action;

  try {
    yield put(actions.initLoading(true));
    yield put(actions.setFetchBarCodeStatus(false));
    const barcodeResponse = yield call(postPfiBarcode, barCodeParams);
    if (barcodeResponse.data) {
      const checkObjExist = newPledgeItemsList.find(
        (item) => item.id === action.payload.obj.id
      );
      if (!checkObjExist) {
        yield put(
          actions.setNewPledgeItemsList({
            ...barCodeParams.obj,
            barcode: barcodeResponse?.data?.itemBarcode,
          })
        );
        yield put(actions.setFetchBarCodeStatus(true));
      }
      yield put(actions.initLoading(false));
    }
  } catch (e) {
    const checkObjExist = newPledgeItemsList.find(
      (item) => item.id === action.payload.obj.id
    );
    if (!checkObjExist) {
      yield put(actions.setNewPledgeItemsList(barCodeParams.obj));
    }
    yield put(actions.setFetchBarCodeStatus(false));
    yield put(actions.initLoading(false));
    yield put(actions.setFailedPfiBarcodeList(barCodeParams));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* postPledgeToPfiSaga(action) {
  const {
    payload: { params },
  } = action;
  try {
    yield put(actions.initLoading(true));
    const Response = yield call(postPledgeToPfi, params);
    if (Response?.data) {
      yield put(actions.initLoading(false));
      yield put(actions.setPfiStatus(true));
    }
    yield put(actions.initLoading(false));
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}
export function* postPurchaseToPfiSaga(action) {
  const {
    payload: { params },
  } = action;
  try {
    yield put(actions.initLoading(true));
    const Response = yield call(postPurchaseToPfi, params);
    if (Response?.data) {
      yield put(actions.initLoading(false));
      yield put(actions.setPfiStatus(true));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}
export function* postReviewItemsPfaSaga(action) {
  const {
    payload: { params },
  } = action;

  try {
    yield put(actions.initLoading(true));
    const response = yield call(postReviewItemsPfa, params);
    if (response?.data) {
      yield put(actions.initLoading(false));
      yield put(actions.setConfirmItemsDetails(response?.data));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* putLockAuctionSaga(action) {
  try {
    const {
      payload: { auctionId },
    } = action;
    yield put(actions.initLoading(true));
    const response = yield call(putLockAuction, auctionId);
    if (response?.data?.success) {
      yield put(actions.initLoading(false));
      yield put(actions.setSuccessLockAuction(response.data));
    } else {
      yield put(actions.initLoading(false));
      yield put(actions.logError(response?.data?.message));
      yield put(actions.setIsErrorVisible(true));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* getItemPriceValuationSaga(action) {
  try {
    const {
      payload: { params },
    } = action;

    const response = yield call(getItemPrice, params);

    if (response?.data) {
      yield put(actions.itemPriceValuationResponse(response.data));
    }
  } catch (e) {
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* getNotifyAuctionLockingSaga() {
  try {
    const response = yield call(getNotifyAuctionLocking);
    if (response?.status === 200) {
      yield put(actions.setIsAuctionLocked(response?.data));
    }
  } catch (e) {
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* putPfaLotNumberSaga(action) {
  try {
    const {
      payload: { params, pushToPfaParams },
    } = action;
    yield put(actions.initLoading(true));
    const response = yield call(putPfaLotNumber, params);
    if (response?.data) {
      yield put(actions.setGeneratedLotNumber(response.data));
      const PushToPfaRes = yield call(postPushToPFA, {
        auctionId: response?.data?.auctionId,
        ...pushToPfaParams,
      });
      if (PushToPfaRes?.data) {
        yield put(actions.initLoading(false));
        yield put(actions.setPfaStatus(true));
      } else {
        yield put(actions.initLoading(false));
        yield put(actions.logError('Push to PFA failed.'));
        yield put(actions.setIsErrorVisible(true));
      }
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* getGenerateLotStickerSaga(action) {
  try {
    yield put(actions.initLoading(true));
    const response = yield call(getGenerateLotSticker, action.payload);
    if (response?.data) {
      yield put(actions.initLoading(false));
      sendToPrinter(response, 'printbarcode', '_Lot_Sticker');
      yield put(actions.logSuccess(SENT_TO_PRINTER));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(LOT_STICKER_SERVICE_ERROR));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* pledgeConfiscationSaga(action) {
  try {
    const {
      payload: { params },
    } = action;

    const { customer } = yield select((state) => state.customer);

    yield put(actions.initLoading(true));
    const response = yield call(postConfiscatePledges, params);
    if (response?.status === RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: GET_CUSTOMER_PAWNBROKING_TAB_SUMMARY,
        payload: { customerId: customer?.customerId },
      });

      yield put(actions.initLoading(false));
      yield put(actions.setIsPledgeNotesPopupVisible(false));
      yield put(actions.setPledgeNoticeType(''));
      yield put(actions.setIsNoticeButtonEnable(false));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    notification.error({
      message: GENERIC_ERROR,
      duration: 5,
    });
  }
}

export function* pledgeVulnerableSaga(action) {
  try {
    const {
      payload: { params },
    } = action;

    const { customer } = yield select((state) => state.customer);

    yield put(actions.initLoading(true));
    const response = yield call(postVulnerablePledges, params);
    if (response?.status === RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: GET_CUSTOMER_PAWNBROKING_TAB_SUMMARY,
        payload: { customerId: customer?.customerId },
      });
      yield put({
        type: CUSTOMER_PROFILE_REQUEST,
        payload: { customerId: customer?.customerId },
      });

      yield call(
        clearCacheByCacheKey,
        cacheCustomerStatus,
        customer?.customerId
      );

      yield put(actions.initLoading(false));
      yield put(actions.setIsPledgeNotesPopupVisible(false));
      yield put(actions.setPledgeNoticeType(''));
      yield put(actions.setIsNoticeButtonEnable(false));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    notification.error({
      message: GENERIC_ERROR,
      duration: 5,
    });
  }
}

export function* getVulnerablePledgesSaga() {
  try {
    yield put(actions.initLoading(true));
    const response = yield call(getVulnerablePledges);
    if (response?.data) {
      yield put(actions.setVulnerablePledgesList(response?.data));
      yield put(actions.initLoading(false));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* getVulnerableNotesSaga(action) {
  try {
    yield put(actions.initLoading(true));
    const response = yield call(getVulnerableNotes, action.payload);
    if (response?.data) {
      yield put(actions.setSelectedVulnerableAgreement([response?.data]));
      yield put(actions.initLoading(false));
      yield put(actions.setIsPledgeNotesPopupVisible(true));
    }
  } catch (e) {
    yield put(actions.setIsPledgeNotesPopupVisible(true));
    yield put(actions.initLoading(false));
    yield put(actions.logError(e?.response?.data?.error));
    yield put(actions.setIsErrorVisible(true));
  }
}

export function* postAddVulnerableNoteSaga(action) {
  try {
    yield put(actions.initUpdate(true));
    const response = yield call(postVulnerableNote, action.payload);
    if (response?.status === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.setIsPledgeNotesPopupVisible(false));
      yield put(actions.initUpdate(false));
    }
  } catch (e) {
    yield put(actions.initUpdate(false));
    notification.error({
      message: GENERIC_ERROR,
      duration: 5,
    });
  }
}

export function* postRemoveVulnerableNoteSaga(action) {
  try {
    yield put(actions.initLoading(true));
    const response = yield call(postRemoveVulnerableNote, action.payload);
    if (response?.status === RESPONSE_STATUS.SUCCESS) {
      yield call(clearCacheByCacheKey, cacheCustomerStatus, action?.customerId);

      yield put({ type: GET_VULNERABLE_PLEDGES });
      yield put(actions.setIsPledgeReleasePopupVisible(false));
      yield put(actions.initLoading(false));
      yield put(actions.logSuccess(SUCCESS_RELEASE));
    }
  } catch (e) {
    yield put(actions.initLoading(false));
    notification.error({
      message: GENERIC_ERROR,
      duration: 5,
    });
  }
}

export function* watchSearchAuctionSaga() {
  yield takeEvery(SEARCH_AUCTION, searchAuctionSaga);
}

export function* watchGetAuctionsOverSaga() {
  yield takeEvery(GET_AUCTIONS_OVER_SEVENTYFIVE, getAuctionsOverSaga);
}
export function* watchGetPfiUnderSaga() {
  yield takeEvery(GET_PFI_UNDER_SEVENTYFIVE, getPfiUnderSaga);
}

export function* watchGetAuctionDetailsSaga() {
  yield takeEvery(GET_AUCTION_DETAILS, getAuctionDetailsSaga);
}

export function* watchPutConfirmCollectionSaga() {
  yield takeEvery(PUT_CONFIRM_COLLECTION, putConfirmCollectionSaga);
}

export function* watchGetConfirmAuctionSettlementSaga() {
  yield takeEvery(CONFIRM_AUCTION_SETTLEMENT, getConfirmAuctionSettlementSaga);
}

export function* watchPutSettleAuctionSaga() {
  yield takeEvery(SETTLE_AUCTION, putSettleAuctionSaga);
}

export function* watchPutUpdateSoldValueSaga() {
  yield takeEvery(UPDATE_SOLD_VALUE, putUpdateSoldValueSaga);
}

export function* watchGetPrintPassListSaga() {
  yield takeEvery(PRINT_PASS_LIST, getPrintPassListSaga);
}
export function* watchGetPledgeLoanDetailsSaga() {
  yield takeEvery(GET_LOAN_DETAILS, getPledgeLoanDetailsSaga);
}
export function* watchGetPurchaseLoanDetailsSaga() {
  yield takeEvery(GET_PURCHASE_LOAN_DETAILS, getPurchaseLoanDetailsSaga);
}
export function* watchGetParentItemIdSaga() {
  yield takeEvery(GET_PARENT_ITEM_CATEGORY, getParentItemIdSaga);
}
export function* watchPostReviewItemsPfaSaga() {
  yield takeEvery(REVIEW_ITEMS_PFA, postReviewItemsPfaSaga);
}
export function* watchPostPfiBarcodeSaga() {
  yield takeEvery(POST_PFI_BARCODE, postPfiBarcodeSaga);
}
export function* watchGetItemPriceValuationSaga() {
  yield takeEvery(AUCTION_ITEM_PRICE_REQUEST, getItemPriceValuationSaga);
}
export function* watchPutPassAuctionLotSaga() {
  yield takeEvery(PASS_AUCTION_LOT, putPassAuctionLotSaga);
}

export function* watchPutLockAuctionSaga() {
  yield takeEvery(LOCK_AUCTION, putLockAuctionSaga);
}

export function* watchGetNotifyAuctionLockingSaga() {
  yield takeEvery(NOTIFY_AUCTION_LOCKING, getNotifyAuctionLockingSaga);
}
export function* watchPostPledgeToPfiSaga() {
  yield takeEvery(PUSH_PLEDGE_TO_PFI, postPledgeToPfiSaga);
}
export function* watchPostPurchaseToPfiSaga() {
  yield takeEvery(PUSH_PURCHASE_TO_PFI, postPurchaseToPfiSaga);
}

export function* watchGetPledgeItemsListSaga() {
  yield takeEvery(GET_PLEDGE_ITEMS, getPledgeItemsListSaga);
}

export function* watchGetPledgeItemsDetailsSaga() {
  yield takeEvery(GET_PLEDGE_ITEM_DETAILS, getPledgeItemsDetailsSaga);
}

export function* watchPutPfaLotNumberSaga() {
  yield takeEvery(PFA_LOT_NUMBER, putPfaLotNumberSaga);
}

export function* watchGetGenerateLotStickerSaga() {
  yield takeEvery(GENERATE_LOT_STICKER, getGenerateLotStickerSaga);
}

export function* watchPledgeConfiscationSaga() {
  yield takeEvery(CONFISCATE_PLEDGES, pledgeConfiscationSaga);
}

export function* watchPledgeVulnerableSaga() {
  yield takeEvery(VULNERABLE_PLEDGES, pledgeVulnerableSaga);
}

export function* watchGetVulnerablePledgesSaga() {
  yield takeEvery(GET_VULNERABLE_PLEDGES, getVulnerablePledgesSaga);
}

export function* watchGetVulnerableNotesSaga() {
  yield takeEvery(GET_VULNERABLE_NOTES, getVulnerableNotesSaga);
}

export function* watchPostAddVulnerableNoteSaga() {
  yield takeEvery(ADD_VULNERABLE_NOTE, postAddVulnerableNoteSaga);
}

export function* watchPostRemoveVulnerableNoteSaga() {
  yield takeEvery(RELEASE_VULNERABLE_PLEDGE, postRemoveVulnerableNoteSaga);
}
