import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { format } from 'date-fns';
import moment from 'moment';
import styled from 'styled-components';
import { Layout, Collapse, Row, Col, Spin } from 'antd';

import { RightOutlined, DownOutlined } from '@ant-design/icons';

import { FNS_DATE_FORMAT_TWO_DIGIT_WITH_TIME } from 'globalConstants';
import { WESTERN_UNION_DIRECTION } from 'components/customer/constants';
import {
  LAST_VISITS_TITLE,
  VISIT_SERVICE_TYPE,
  LAST_SERVICE,
} from '../../../constants';

import HelpPopoverInfo from 'components/common/HelpPopoverInfo';

import { CUSTOMER_LAST_VISITS } from 'action_creators/customer';
import { currencyFormat } from 'utils/util';

const StyledLayout = styled(Layout)`
  padding: 0 10px 0 10px;
  margin: 0 10px 10px 0;
  background-color: var(--alert-bg);
  height: calc(70vh - 25px);

  border-radius: 4px;
  border: 1px solid var(--light-grayish-orange);

  & .ant-table-wrapper {
    margin-bottom: 0;
  }
`;

const StyledHeader = styled.span`
  margin: 20px 0;
  font-style: normal;
  font-weight: var(--font-weight-500);
  font-size: 1.6rem;
  color: var(--black);
`;

const StyledRow = styled(Row)`
  font-weight: var(--font-weight-500);
  height: 42px;
  padding: 14px 40px 12px 10px;
  background: var(--white);
  border: 0.8px solid var(--light-gray);
`;

const StyledCol = styled(Col)`
  text-align: end;
`;

const Visit = () => {
  const { lastVisits, isLastVisitsLoading } = useSelector(
    (state: any) => state.customer
  );

  const dispatch = useDispatch();
  const { Panel } = Collapse;

  const { customerId } = useParams<any>();

  const [openPanel, setOpenPanel] = useState<any>(undefined);

  useEffect(() => {
    dispatch({
      type: CUSTOMER_LAST_VISITS,
      payload: { customerId },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onPanelClick = (key: string | string[]) => {
    setOpenPanel(key);
  };
  const serviceStatusData = (data: any) => {
    const { pledgesList } = data;
    const newList = {
      payDown: [],
      renew: [],
      withdraw: [],
      pickUp: [],
      partialPayment: [],
      affidavit: [],
      surplus: [],
      newPledge: [],
    };
    pledgesList.forEach((obj: any) => {
      if (obj?.renewPaydown?.repaymentType === 'RENEWAL_PAYDOWN') {
        newList.payDown.push(obj as never);
      }
      if (obj?.renewPaydown?.repaymentType === 'RENEWAL') {
        newList.renew.push(obj as never);
      }
      if (obj?.withDrawPickUp?.repaymentType === 'WITHDRAW') {
        newList.withdraw.push(obj as never);
      }
      if (obj?.withDrawPickUp?.repaymentType === 'PICKUP') {
        newList.pickUp.push(obj as never);
      }
      if (obj?.partialPayment?.repaymentType === 'CASH') {
        newList.partialPayment.push(obj as never);
      }
      if (obj?.affidavitInfo && obj?.affidavitInfo.isAffidavit) {
        newList.affidavit.push(obj as never);
      }
      if (obj?.surplusPayment && obj?.surplusPayment?.surplusAmount) {
        newList.surplus.push(obj as never);
      }
      if (
        !obj?.withDrawPickUp &&
        !obj.renewPaydown &&
        !obj.partialPayment &&
        !obj.affidavitInfo &&
        !obj.surplusPayment
      ) {
        newList.newPledge.push(obj as never);
      }
    });
    return { ...data, pledgesList, newList };
  };

  const getVisitColor = (durationInDays: number) => {
    if (durationInDays <= 2) return 'bg-visit-red';
    else if (durationInDays <= 7) return 'bg-visit-amber';
    else if (durationInDays <= 30) return 'bg-visit-green';
    else return '';
  };

  const renderPanel = (details: any, panelIndex: number) => {
    return (
      <div className="details-wrapper" key={`panel${panelIndex}`}>
        {details?.newList?.newPledge.map(
          (obj: any, pledgeHistoryIdx: number) => (
            <div key={`pledge${pledgeHistoryIdx}`}>
              <div className="title">{VISIT_SERVICE_TYPE.PLEDGE}</div>
              <div className="divider" />
              <div className="content">
                <Row>
                  <Col span="14">{LAST_SERVICE.PLEDGE_AGREEMENT_REF}</Col>
                  <StyledCol span="8">{obj?.loanNumber}</StyledCol>
                </Row>
                <Row>
                  <Col span="14">{LAST_SERVICE.NUMBER_OF_ITEMS}</Col>
                  <StyledCol span="8">
                    {obj?.itemDetail?.numberOfitems}
                  </StyledCol>
                </Row>
                <Row>
                  <Col span="14">{LAST_SERVICE.WEIGHT}</Col>
                  <StyledCol span="8">
                    {details?.newList?.newPledge[0]?.itemDetail?.weight?.toFixed(
                      2
                    ) && <>{obj?.itemDetail?.weight?.toFixed(2)} g</>}
                  </StyledCol>
                </Row>
                <Row>
                  <Col span="14">{LAST_SERVICE.VALUE}</Col>
                  <StyledCol span="8">
                    {details.pledgesList[0]?.itemDetail?.amount?.toFixed(2) && (
                      <>{currencyFormat(obj?.itemDetail?.amount, true)}</>
                    )}
                  </StyledCol>
                </Row>
              </div>
            </div>
          )
        )}

        {details?.newList?.payDown.map(
          (obj: any, pledgeRenewPayDownIdx: number) => (
            <div key={`pledgeRenew${pledgeRenewPayDownIdx}`}>
              <div className="title">{VISIT_SERVICE_TYPE.PLEDGE_PAYDOWN}</div>
              <div className="divider" />
              <div className="content">
                <Row>
                  <Col span="14">{LAST_SERVICE.ORIGINAL_PLEDGE_REF}</Col>
                  <StyledCol span="8">
                    {obj?.renewPaydown?.originalPledgeRef}
                  </StyledCol>
                </Row>
                <Row>
                  <Col span="14">{LAST_SERVICE.NEW_PLEDGE_REF}</Col>
                  <StyledCol span="8">{obj?.loanNumber}</StyledCol>
                </Row>
                <Row>
                  <Col span="14">{LAST_SERVICE.NEW_LOAN_AMOUNT}</Col>
                  <StyledCol span="8">
                    {obj?.renewPaydown?.cost && (
                      <>{currencyFormat(obj?.renewPaydown?.cost, true)}</>
                    )}
                  </StyledCol>
                </Row>
              </div>
            </div>
          )
        )}
        {details?.newList?.renew.map((obj: any, pledgePaydownIdx: number) => (
          <div key={`pledgePaydown${pledgePaydownIdx}`}>
            <div className="title">{VISIT_SERVICE_TYPE.PLEDGE_RENEWAL}</div>
            <div className="divider" />
            <div className="content">
              <Row>
                <Col span="14">{LAST_SERVICE.ORIGINAL_PLEDGE_REF}</Col>
                <StyledCol span="8">
                  {obj?.renewPaydown?.originalPledgeRef}
                </StyledCol>
              </Row>
              <Row>
                <Col span="14">{LAST_SERVICE.NEW_PLEDGE_REF}</Col>
                <StyledCol span="8">{obj?.loanNumber}</StyledCol>
              </Row>
              <Row>
                <Col span="14">{LAST_SERVICE.LOAN_AMOUNT}</Col>
                <StyledCol span="8">
                  {obj?.renewPaydown?.cost && (
                    <>{currencyFormat(obj?.renewPaydown?.cost, true)}</>
                  )}
                </StyledCol>
              </Row>
            </div>
          </div>
        ))}
        {details?.newList?.pickUp.map((obj: any, pledgePickupIdx: number) => (
          <div key={`pledgePickUp${pledgePickupIdx}`}>
            <div className="title">{VISIT_SERVICE_TYPE.PLEDGE_PICKUP}</div>
            <div className="divider" />
            <div className="content">
              <Row>
                <Col span="14">{LAST_SERVICE.PLEDGE_REF}</Col>
                <StyledCol span="8">{obj?.loanNumber}</StyledCol>
              </Row>
              <Row>
                <Col span="14">{LAST_SERVICE.AMOUNT_PAID}</Col>
                <StyledCol span="8">
                  {obj?.withDrawPickUp?.cost && (
                    <>{currencyFormat(obj?.withDrawPickUp?.cost, true)}</>
                  )}
                </StyledCol>
              </Row>
            </div>
          </div>
        ))}
        {details?.newList?.withdraw.map(
          (obj: any, pledgeWithdrawIdx: number) => (
            <div key={`pledgeWithdraw${pledgeWithdrawIdx}`}>
              <div className="title">
                {VISIT_SERVICE_TYPE.PLEDGE_WITHDRAWAL}
              </div>
              <div className="divider" />
              <div className="content">
                <Row>
                  <Col span="14">{LAST_SERVICE.PLEDGE_REF}</Col>
                  <StyledCol span="8">{obj?.loanNumber}</StyledCol>
                </Row>
                <Row>
                  <Col span="14">{LAST_SERVICE.AMOUNT_PAID}</Col>
                  <StyledCol span="8">
                    {obj?.withDrawPickUp?.cost && (
                      <>{currencyFormat(obj?.withDrawPickUp?.cost, true)}</>
                    )}
                  </StyledCol>
                </Row>
              </div>
            </div>
          )
        )}
        {details?.newList?.partialPayment.map(
          (obj: any, pledgePartialPaymentIdx: number) => (
            <div key={`pledgePartialPayment${pledgePartialPaymentIdx}`}>
              <div className="title">
                {VISIT_SERVICE_TYPE.PLEDGE_PARTIAL_PAYMENT}
              </div>
              <div className="divider" />
              <div className="content">
                <Row>
                  <Col span="14">{LAST_SERVICE.PLEDGE_REF}</Col>
                  <StyledCol span="8">{obj?.loanNumber}</StyledCol>
                </Row>
                <Row>
                  <Col span="14">{LAST_SERVICE.AMOUNT_PAID}</Col>
                  <StyledCol span="8">
                    {obj?.partialPayment?.partPaymentAmount && (
                      <>
                        {currencyFormat(
                          obj?.partialPayment?.partPaymentAmount,
                          true
                        )}
                      </>
                    )}
                  </StyledCol>
                </Row>
              </div>
            </div>
          )
        )}
        {details?.newList?.surplus.map((obj: any, pledgeSurplusIdx: number) => (
          <div key={`pledgeSurplus${pledgeSurplusIdx}`}>
            <div className="title">
              {VISIT_SERVICE_TYPE.PLEDGE_SURPLUS_PAYMENT}
            </div>
            <div className="divider" />
            <div className="content">
              <Row>
                <Col span="14">{LAST_SERVICE.PLEDGE_REF}</Col>
                <StyledCol span="8">{obj?.loanNumber}</StyledCol>
              </Row>
              <Row>
                <Col span="14">{LAST_SERVICE.LOAN_AMOUNT}</Col>
                <StyledCol span="8">
                  {obj?.itemDetail?.amount && (
                    <>{currencyFormat(obj?.itemDetail?.amount, true)}</>
                  )}
                </StyledCol>
              </Row>
              <Row>
                <Col span="14">{LAST_SERVICE.SURPLUS_AMOUNT}</Col>
                <StyledCol span="8">
                  {obj?.surplusPayment?.surplusAmount && (
                    <>
                      {currencyFormat(obj?.surplusPayment?.surplusAmount, true)}
                    </>
                  )}
                </StyledCol>
              </Row>
            </div>
          </div>
        ))}

        {details?.retailHistory?.map((obj: any, retailHistoryIdx: number) => (
          <div key={`retail${retailHistoryIdx}`}>
            <div className="title">
              {obj.type === LAST_SERVICE.SOLD
                ? VISIT_SERVICE_TYPE.RETAIL_SALE
                : VISIT_SERVICE_TYPE.RETAIL_REFUND}
            </div>
            <div className="content">
              <Row>
                <Col span="14">{LAST_SERVICE.RETAIL_REF}</Col>
                <StyledCol span="8">{obj?.retailTransactionId}</StyledCol>
              </Row>
              <Row>
                <Col span="14">{LAST_SERVICE.NUMBER_OF_ITEMS}</Col>
                <StyledCol span="8">{obj?.numberOfItems}</StyledCol>
              </Row>
              <Row>
                <Col span="14">{LAST_SERVICE.AMOUNT}</Col>
                <StyledCol span="8">
                  {currencyFormat(obj?.amountPaid, true)}
                </StyledCol>
              </Row>
            </div>
          </div>
        ))}

        {details?.chequeHistory?.map((obj: any, checkHistoryIdx: number) => (
          <div key={`cheque${checkHistoryIdx}`}>
            <div className="title">{VISIT_SERVICE_TYPE.CHEQUE_CASHING}</div>
            <div className="content">
              <Row>
                <Col span="14">{LAST_SERVICE.DRAWER_NAME}</Col>
                <StyledCol span="8">{obj?.drawerName}</StyledCol>
              </Row>
              <Row>
                <Col span="14">{LAST_SERVICE.CHEQUE_AMOUNT}</Col>
                <StyledCol span="8">
                  {currencyFormat(obj?.chequeAmount, true)}
                </StyledCol>
              </Row>
              <Row>
                <Col span="14">{LAST_SERVICE.RETAINED_AMOUNT}</Col>
                <StyledCol span="8">
                  {currencyFormat(obj?.retainedAmount, true)}
                </StyledCol>
              </Row>
              <Row>
                <Col span="14">{LAST_SERVICE.PAID_AMOUNT}</Col>
                <StyledCol span="8">
                  {currencyFormat(obj?.paidAmount, true)}
                </StyledCol>
              </Row>
            </div>
          </div>
        ))}

        {details?.fxHistory?.map((obj: any, fxHistoryIdx: number) => (
          <div key={`fx${fxHistoryIdx}`}>
            <div className="title">{VISIT_SERVICE_TYPE.TRAVEL_MONEY}</div>
            <div className="content">
              <Row>
                <Col span="6"></Col>
                <StyledCol span="8">{LAST_SERVICE.CURRENCY}</StyledCol>
                <StyledCol span="8">{LAST_SERVICE.AMOUNT}</StyledCol>
              </Row>
              <Row>
                <Col span="6">{LAST_SERVICE.FROM}</Col>
                <StyledCol span="8">{obj?.currencyFrom}</StyledCol>
                <StyledCol span="8">{obj?.amountFrom}</StyledCol>
              </Row>
              <Row>
                <Col span="6">{LAST_SERVICE.TO}</Col>
                <StyledCol span="8">{obj?.currencyTo}</StyledCol>
                <StyledCol span="8">{obj?.amountTo}</StyledCol>
              </Row>
            </div>
          </div>
        ))}
        {details?.westernUnionHistory?.map((obj: any, wuHistoryIdx: number) => (
          <div key={`wu${wuHistoryIdx}`}>
            <div className="title">{VISIT_SERVICE_TYPE.WESTERN_UNION}</div>
            <div className="divider" />
            <div className="content">
              <Row>
                <Col span="10">{LAST_SERVICE.MTCN_REF}</Col>
                <StyledCol span="12">{obj?.mtcnRefNumber}</StyledCol>
              </Row>
              <Row>
                <Col span="10">{LAST_SERVICE.DIRECTION}</Col>
                <StyledCol span="12">{obj?.direction}</StyledCol>
              </Row>
              <Row>
                <Col span="10">{LAST_SERVICE.AMOUNT}</Col>
                <StyledCol span="12">
                  {obj?.direction === WESTERN_UNION_DIRECTION.RECEIVED
                    ? currencyFormat(obj?.receivedAmount, true)
                    : currencyFormat(obj?.paidAmount, true)}
                </StyledCol>
              </Row>
            </div>
          </div>
        ))}
        {details?.purchaseHistory?.map(
          (obj: any, purchaseHistoryIdx: number) => (
            <div key={`purchase${purchaseHistoryIdx}`}>
              <div className="title">
                {obj.returnPurchase === LAST_SERVICE.RETURN
                  ? VISIT_SERVICE_TYPE.PURCHASE_RETURN
                  : VISIT_SERVICE_TYPE.PURCHASE}
              </div>
              <div className="divider" />
              <div className="content">
                <Row>
                  <Col span="14">{LAST_SERVICE.PURCHASE_REF}</Col>
                  <StyledCol span="8">{obj?.purchaseId}</StyledCol>
                </Row>
                <Row>
                  <Col span="14">{LAST_SERVICE.NUMBER_OF_ITEMS}</Col>
                  <StyledCol span="8">{obj?.itemCount}</StyledCol>
                </Row>
                <Row>
                  <Col span="14">{LAST_SERVICE.WEIGHT}</Col>
                  <StyledCol span="8">
                    {details?.purchaseHistory[0]?.weight?.toFixed(2) && (
                      <>{obj?.weight?.toFixed(2)} g</>
                    )}
                  </StyledCol>
                </Row>
                <Row>
                  <Col span="14">{LAST_SERVICE.VALUE}</Col>
                  <StyledCol span="8">
                    {details.purchaseHistory[0]?.amount?.toFixed(2) && (
                      <>{currencyFormat(obj?.amount, true)}</>
                    )}
                  </StyledCol>
                </Row>
              </div>
            </div>
          )
        )}
        {details?.laywayHistory?.map((obj: any, layawayHistoryIdx: number) => (
          <div key={`layaway${layawayHistoryIdx}`}>
            <div className="title">{VISIT_SERVICE_TYPE.LAYAWAY}</div>
            <div className="divider" />
            <div className="content">
              <Row>
                <Col span="14">{LAST_SERVICE.DEPOSIT_WITHDRAWAL}</Col>
                <StyledCol span="8">{obj?.type}</StyledCol>
              </Row>
              <Row>
                <Col span="14">{LAST_SERVICE.AMOUNT}</Col>
                <StyledCol span="8">
                  {details?.laywayHistory[0]?.amount?.toFixed(2) && (
                    <>{currencyFormat(Math.abs(obj?.amount), true)}</>
                  )}
                </StyledCol>
              </Row>
            </div>
          </div>
        ))}
        {details?.newList?.affidavit.map((obj: any, affidavitIdx: number) => (
          <div key={`affidavit${affidavitIdx}`}>
            <div className="title">{VISIT_SERVICE_TYPE.AFFIDAVIT}</div>
            <div className="divider" />
            <div className="content">
              <Row>
                <Col span="14">{LAST_SERVICE.PLEDGE_REF}</Col>
                <StyledCol span="8">{obj?.loanNumber}</StyledCol>
              </Row>
            </div>
          </div>
        ))}
        {details?.customerLastStoreVisit && (
          <div>
            <div className="title">
              {VISIT_SERVICE_TYPE.CUSTOMER_DETAILS_UPDATE}
            </div>
            <div className="divider" />
            <div className="content">
              <Row>
                <Col span="8">{LAST_SERVICE.STORE}</Col>
                <StyledCol span="14">
                  {details?.customerLastStoreVisit?.storeName}
                </StyledCol>
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderPanelWrapper = (visit: any, index: number) => {
    let visitOn = visit?.createdOn;
    let today = moment();
    const durationInDays = today.diff(visitOn, 'days');

    const visitColor = getVisitColor(durationInDays);

    return (
      <Panel
        className={visitColor}
        showArrow={false}
        header={
          <Row>
            <Col span="13">{visit.storeName}</Col>
            <Col span="2">
              {durationInDays <= 2 && (
                <img
                  alt="green tick"
                  className={'green-tick'}
                  src={
                    require('../../../../../assets/images/Alert_icon.svg')
                      .default
                  }
                />
              )}
            </Col>
            <Col span="8">
              {visit.totalHoursPassed
                ? visit.totalHoursPassed
                : format(
                    new Date(visit.createdOn),
                    FNS_DATE_FORMAT_TWO_DIGIT_WITH_TIME
                  )}
            </Col>
            <Col span="1">
              {openPanel === `${index}` ? <DownOutlined /> : <RightOutlined />}
            </Col>
          </Row>
        }
        key={index}
      >
        {renderPanel(serviceStatusData(visit), index)}
      </Panel>
    );
  };

  return (
    <>
      <StyledLayout>
        <StyledHeader>
          {LAST_VISITS_TITLE.LAST_SEEN}
          <HelpPopoverInfo
            linkedID="CustomerSummary_LastSeen"
            position="rightTop"
          ></HelpPopoverInfo>
        </StyledHeader>
        <div className="collapse-wrapper">
          <StyledRow>
            <Col span="14">{LAST_VISITS_TITLE.STORE}</Col>
            <Col span="9" style={{ textAlign: 'right' }}>
              {LAST_VISITS_TITLE.DATE_AND_TIME}
            </Col>
          </StyledRow>
          <Spin spinning={isLastVisitsLoading}>
            {lastVisits?.length === 0 ? (
              <div className="no-data">{LAST_VISITS_TITLE.NO_RECORD_FOUND}</div>
            ) : (
              <Collapse
                className="custom-collapse"
                accordion
                onChange={onPanelClick}
              >
                {lastVisits?.map((obj: any, index: number) =>
                  renderPanelWrapper(obj, index)
                )}
              </Collapse>
            )}
          </Spin>
        </div>
      </StyledLayout>
    </>
  );
};

export default Visit;
