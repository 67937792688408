import React, { useState } from 'react';
import { GRID_NO_URL, FNS_DATE_FORMAT } from 'globalConstants';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Input } from 'antd';
import { format } from 'date-fns';
import VirtualGrid from 'components/common/virtualGrid/index';
import ImageLoader from 'components/common/ImageLoader';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import { currencyFormat } from 'utils/util';
import AuctionDetailsExpandableTable from './auctionDetailsExpandableTable';

const StyledSpan = styled.span`
  text-align: center;
  padding: 0 5px 0 5px;
`;

const StyledButton = styled(Button)`
  margin: 0 15px;
  position: inherit;
`;

interface LiveAtAuctionSummaryProps {
  auctionLoans: Array<any>;
}

const TableRow = ({ row, ...restProps }: any) => {
  const Cell = () => {
    const { column, tableRow } = restProps;
    const { row } = tableRow;
    if (column.name === 'followsLotNumber') {
      return (
        <Table.Cell {...restProps}>
          <Input value={row.followsLotNumber} />
        </Table.Cell>
      );
    }
    return <Table.Cell {...restProps} />;
  };

  return <>{Cell()}</>;
};

const LiveAtAuctionSummary = ({
  auctionLoans = [],
}: LiveAtAuctionSummaryProps) => {
  const [columns] = useState([
    {
      name: 'customerName',
      title: 'Customer Name',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <>
          <ImageLoader
            customerId={row?.customerId}
            imageUrl={
              row?.customerId &&
              `/api/customers/images/${row?.customerId}?isThumnails=false`
            }
            classes={`mx-small-avatar mx-small-avatar-yellow`}
            borderRadius={'50%'}
            fallbackImg={'customer-pic.svg'}
            preview
          />
          <StyledSpan>{row?.customerName}</StyledSpan>
        </>
      ),
    },
    {
      name: 'lotNumber',
      title: 'Lot No.',
      getCellValue: (row: any) => row?.lotNumber,
    },
    {
      name: 'followsLotNumber',
      title: 'Fellows Lot No.',
      getCellValue: (row: any) => row?.followsLotNumber,
    },
    {
      name: 'loanNumber',
      title: 'Agreement',
      getCellValue: (row: any) => row?.loanNumber,
    },
    {
      name: 'loanValue',
      title: 'Loan Value',
      getCellValue: (row: any) =>
        '£' + currencyFormat(row?.loanValue?.toFixed(2)),
    },
    {
      name: 'dueDate',
      title: 'Due Date',
      getCellValue: (row: any) =>
        row && row?.dueDate
          ? format(new Date(row?.dueDate), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'items',
      title: 'Items',
      getCellValue: (row: any) => row?.items,
    },
    {
      name: 'letterDue',
      title: 'Letter Due',
      getCellValue: (row: any) =>
        row && row?.letterDue
          ? format(new Date(row?.letterDue), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'letterSent',
      title: 'Letter Sent',
      getCellValue: (row: any) =>
        row && row?.letterSent
          ? format(new Date(row?.letterSent), FNS_DATE_FORMAT)
          : null,
    },
    {
      name: 'reserveValue',
      title: 'Reserve Value',
      getCellValue: (row: any) =>
        '£' + currencyFormat(row?.reserveValue?.toFixed(2)),
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row: any) => row?.status,
    },
    {
      name: '',
      title: '',
      type: 'icon',
      displayContent: false,
      getCellValue: (row: any) => (
        <StyledButton
          itemProp="secondary"
          disabled={row?.status === 'Pass'}
          onClick={() => onPassLoanClick(row?.auctionId)}
        >
          {' Pass Loan'}
        </StyledButton>
      ),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'customerName', width: '15%' },
    { columnName: 'lotNumber', width: '6%' },
    { columnName: 'followsLotNumber', width: '8%' },
    { columnName: 'loanNumber', width: '8%' },
    { columnName: 'loanValue', width: '8%' },
    { columnName: 'dueDate', width: '7%' },
    { columnName: 'items', width: '5%' },
    { columnName: 'letterDue', width: '7%' },
    { columnName: 'letterSent', width: '7%' },
    { columnName: 'reserveValue', width: '8%' },
    { columnName: 'status', width: '6%' },
    { columnName: '', width: '10%' },
  ]);

  const getRowId = (row: any) => row?.auctionId;

  const onPassLoanClick = (auctionId: number) => { /* do nothing */ };

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={auctionLoans}
        expandableComponent={AuctionDetailsExpandableTable}
        getRowId={getRowId}
        cellComponent={(restProps: any) => <TableRow {...restProps} />}
      />
    </>
  );
};

export default LiveAtAuctionSummary;
