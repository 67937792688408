import React, { useState, useEffect } from 'react';
import VirtualGrid from 'components/common/virtualGrid';
import { GRID_NO_URL } from 'globalConstants';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { isEmpty } from 'lodash';

interface StoneDetailsProps {
  stoneRow: Array<any>;
  removeStoneRow: (row: any) => void;
  onClickStoneRow: (row: any) => void;
  clearSelection: boolean;
  stoneEditRow?: StoneRowProps | any;
  isValuationItemToPledge?: boolean | undefined;
}

interface StoneRowProps {
  id?: any;
  stoneType?: any;
  stoneClarity?: any;
  stoneClass?: any;
  stoneShape?: any;
  stonePoint?: number | string;
  stoneQuantity?: number | string;
  stoneNotes?: string;
}

const TableRow = ({ row, onClickStoneRow, ...restProps }: any) => {
  const onClickTableRow = () => {
    onClickStoneRow(row);
  };

  const Cell = () => {
    const { column } = restProps;
    if (column.name === 'delete') {
      return (
        <Table.Cell
          {...restProps}
          style={{
            cursor: 'pointer',
          }}
        />
      );
    }
    return (
      <Table.Cell
        onClick={onClickTableRow}
        {...restProps}
        style={{
          cursor: 'pointer',
        }}
      />
    );
  };

  return <>{Cell()}</>;
};

const StoneDetailsTable = ({
  stoneRow,
  removeStoneRow,
  onClickStoneRow,
  clearSelection,
  stoneEditRow,
  isValuationItemToPledge,
}: StoneDetailsProps) => {
  const [selection, setSelection] = useState<Array<any>>([]);
  const [isRowDeleted, setRowDeleted] = useState<boolean>(false);

  if (clearSelection && selection.length !== 0) {
    setSelection([]);
    setRowDeleted(false);
  }

  if (clearSelection && isRowDeleted) {
    setRowDeleted(false);
  }

  const onSelectionChange = (row: any) => {
    const lastSelected = row.find(
      (selected: any) => selection.indexOf(selected) === -1
    );
    if (lastSelected !== undefined && !isRowDeleted) {
      setSelection([lastSelected]);
      setRowDeleted(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(stoneEditRow)) {
      onSelectionChange([stoneEditRow.id]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [columns] = useState([
    {
      name: 'id',
      title: 'Ref No.',
      getCellValue: (row: any) => row.id,
    },
    {
      name: 'stoneType',
      title: 'Type',
      getCellValue: (row: any) => row.stoneType.description,
    },
    {
      name: 'stoneShape',
      title: 'Shape',
      getCellValue: (row: any) => row.stoneShape?.description,
    },
    {
      name: 'stoneClarity',
      title: 'Colour',
      getCellValue: (row: any) => row?.stoneClarity?.description,
    },
    {
      name: 'stoneClass',
      title: 'Clarity',
      getCellValue: (row: any) => row?.stoneClass?.description,
    },
    {
      name: 'stonePoint',
      title: 'Size (pts)',
      getCellValue: (row: any) => row?.stonePoint,
    },
    {
      name: 'stoneQuantity',
      title: 'Quantity',
      getCellValue: (row: any) => row?.stoneQuantity,
    },
    {
      name: 'reportNumber',
      title: 'Report Number',
      getCellValue: (row: any) => row?.reportNumber,
    },
    {
      name: 'stoneNotes',
      title: 'Notes',
      getCellValue: (row: any) => row?.stoneNotes,
    },
    {
      name: 'delete',
      title: ' ',
      type: 'delete',
      onClick: (row: any) => {
        if (!isValuationItemToPledge) {
          removeStoneRow(row);
          setRowDeleted(true);
        }
      },
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'id', width: '7%', align: 'left' },
    { columnName: 'stoneType', width: '15%' },
    { columnName: 'stoneShape', width: '10%' },
    { columnName: 'stoneClarity', width: '11%' },
    { columnName: 'stoneClass', width: '11%' },
    { columnName: 'stonePoint', width: '8%', align: 'right' },
    { columnName: 'stoneQuantity', width: '8%', align: 'right' },
    { columnName: 'reportNumber', width: '11%' },
    { columnName: 'stoneNotes', width: '15%' },
    { columnName: 'delete', width: '3%', align: 'right' },
  ]);
  const formattedColumns = ['delete'];

  return (
    <>
      <VirtualGrid
        urlType={GRID_NO_URL}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        rowData={stoneRow}
        formattedColumns={formattedColumns}
        cellComponent={(restProps: any) => (
          <TableRow onClickStoneRow={onClickStoneRow} {...restProps} />
        )}
        rowSelectionEnable={true}
        showSelectionColumn={false}
        showSelectAll={false}
        selection={selection}
        onSelectionChange={onSelectionChange}
        selectByRowClick={true}
        highlightRow={true}
      />
    </>
  );
};

export default StoneDetailsTable;
