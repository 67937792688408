import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Checkbox, InputNumber, Space } from 'antd';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  CURRENCIES_REQUEST,
  BUYBACK_CHARGES_REQUEST,
  CONVERT_CURRENCY_REQUEST,
  UPDOWN_CURRENCY_REQUEST,
  CURRENCY_LEDGER_BALANCE,
} from 'action_creators/travelmoney';
import { actions } from 'redux/reducers/travelmoney';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import Select from 'components/common/Select';
import Alert from 'components/common/ControlledAlert';
import { GUARANTEE_LINE_ONE, GUARANTEE_LINE_TWO } from '../constants';
import InputDecimal from 'components/common/InputDecimal';

const StyledRow = styled(Row)`
  margin-block-end: 10px;
`;

const StyledRowHelp = styled(Row)`
  margin-block-end: 10px;
  margin-top: -30px;
  margin-left: -28px;
`;

const StyledBorderRow = styled(Row)`
  margin-block-end: 10px;
  margin-top: 20px;
  border: 1px solid var(--light-gray);
  margin-left: 0 !important;
  box-sizing: border-box;
  border-radius: 4px;
`;

const StyledRateGuranteeInput = styled(Input)`
  background: var(--catskill-white) !important;
  border: 1px solid var(--light-gray);
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`;

const StyledCheckbox = styled(Col)`
  padding-top: 16px;
`;

const initialFromCurrencies = {
  baseCurrencyCode: 'GBP',
  baseCurrencyDescription: 'Pound Sterling(GBP)',
  currencyBaseExchangeRate: 0.0,
  currencyBuyExchangeRate: 0.0,
  currencySellExchangeRate: 0.0,
  currencyCode: 'GBP',
  currencyDescription: 'Pound Sterling(GBP)',
};

const ConversionForm = () => {
  const dispatch = useDispatch();
  const {
    tm: {
      currencies,
      buybackCharges,
      convertedCurrencies,
      isConvertLoading,
      isUpDownLoading,
      isStockBlanceLoading,
      proceedToBuyback,
      originalFromAmount,
      originalToAmount,
      resetForm,
      buybackHistory,
      isBuybackApply: reduxIsBuybackApply,
      showRateGauranteePopup,
    },
    user: { user, selectedStore },
  } = useSelector((state: any) => {
    return {
      tm: state.tm,
      user: state.user,
    };
  });

  const [fromCurrency, setFromCurrency] = useState<any>(initialFromCurrencies);
  const [toCurrencyLabel, setToCurrencyLabel] = useState<string>('');
  const [fromCurrencyLabel, setFromCurrencyLabel] = useState<string>('');
  const [isBuybackApply, setBuybackApply] = useState<boolean>(false);
  const [isConvertDisabled, setConnvertDisabled] = useState<boolean>(true);
  const [isUpDownDisabled, setUpDownDisabled] = useState<boolean>(true);
  const [isRoundUpDownChange, setRoundUpDownChange] = useState<any>({
    fromAmount: false,
    toAmount: false,
  });
  const [originalAmounts, setOriginalAmount] = useState<any>({
    originalFromAmount: '',
    originalToAmount: '',
  });
  const [isRateAllow, setIsRateAllow] = useState<boolean>(false);
  const [validateFieldsName] = useState<string[]>([]);
  const [currencyExchangeType, setCurrencyExchangeType] = useState<any>({
    exchangeType: '',
    currencyBaseExchangeRate: 0,
  });

  const [isBuybackAlert, setIsBuybackAlert] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (showRateGauranteePopup) {
      const { gbpMinimumAmount } = buybackCharges;
      const value = convertedCurrencies?.fromAmount;
      if (
        value &&
        value >= gbpMinimumAmount &&
        !isBuybackAlert &&
        convertedCurrencies?.fromCurrencyCode === 'GBP'
      ) {
        setIsBuybackAlert(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showRateGauranteePopup,
    convertedCurrencies?.fromAmount,
    buybackCharges,
    convertedCurrencies,
  ]);

  useEffect(() => {
    dispatch({
      type: CURRENCIES_REQUEST,
    });
    dispatch({ type: BUYBACK_CHARGES_REQUEST });
    return () => {
      dispatch(actions.clearFx());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currencies.length > 0) getSelectedFromCurrency('GBP');
  }, [currencies]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (buybackCharges) {
      form.setFieldsValue({ rateGuarantee: buybackCharges.buybackCharge });
    }
  }, [form, buybackCharges]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (resetForm) {
      form.resetFields();
      dispatch(actions.resetForm({}));
      resetFormValues();
      getSelectedFromCurrency('GBP');
      form.setFieldsValue({
        fromCurrencyCode: 'GBP',
      });
      setToCurrencyLabel('');
    }
  }, [dispatch, resetForm]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (convertedCurrencies?.fromCurrencyCode) {
      form.setFieldsValue({
        ...convertedCurrencies,
        toAmount: convertedCurrencies?.toAmount?.toFixed(2),
        fromAmount: convertedCurrencies?.fromAmount?.toFixed(2),
      });
      if (proceedToBuyback) {
        getSelectedFromCurrency(convertedCurrencies?.fromCurrencyCode);
        getSelectedToCurrency(convertedCurrencies?.toCurrencyCode);
        form.setFieldsValue({
          currencyExchangeRate: convertedCurrencies.currencyExchangeRate,
        });
        setConnvertDisabled(false);
      }
    } else {
      form.setFieldsValue({
        toAmount: convertedCurrencies.toAmount,
        fromAmount: convertedCurrencies.fromAmount,
      });
    }
    if (convertedCurrencies.toAmount > 0) {
      setUpDownDisabled(false);
    }
  }, [convertedCurrencies]); // eslint-disable-line react-hooks/exhaustive-deps

  const isCheckGuaranteeEnable = (value: any) => {
    /**if 'currencyFrom' is GBP then enable 'checkGuarantee' checkbox control*/
    if (value !== 'GBP' || !form.getFieldValue('fromAmount')) {
      setBuybackApply(true);
    } else {
      setBuybackApply(false);
    }
  };

  const resetOnSameCurrencyFrom = () => {
    form.resetFields();
    dispatch(actions.resetForm({}));
    form.setFieldsValue({
      fromCurrencyCode: 'GBP',
      toCurrencyCode: '',
    });
    setFromCurrencyLabel('GBP');
    setToCurrencyLabel('');
    onClearAmountClick();
  };

  const resetOnSameCurrencyTo = () => {
    form.resetFields();
    dispatch(actions.resetForm({}));
    form.setFieldsValue({
      fromCurrencyCode: '',
      toCurrencyCode: 'GBP',
    });
    setFromCurrencyLabel('');
    setToCurrencyLabel('GBP');
    onClearAmountClick();
  };

  /**Control up/down button
   * debounce is use because of after enter the input as soon as this reflect into redux,
   * and redux reture default value is 0 so wait some ms for update the redux only one time
   */

  // const handleUpdownWithAmount = (key: any, value: any) => {
  //   let convertedCurrenciesParams = {
  //     ...convertedCurrencies,
  //   };
  //   if (key === 'fromAmountInput') {
  //     convertedCurrenciesParams = {
  //       ...convertedCurrencies,
  //       isRoundDown: true,
  //       fromAmount: value ? +value : '',
  //       toAmount: '',
  //     };
  //   } else if (key === 'toAmountInput') {
  //     convertedCurrenciesParams = {
  //       ...convertedCurrencies,
  //       isRoundDown: false,
  //       toAmount: value ? +value : '',
  //       fromAmount: '',
  //     };
  //   }
  //   dispatch(actions.setConvertedCurrency(convertedCurrenciesParams));
  // };

  /**onAmountChange
   * if 'currencyFrom' is GBP then enable 'checkGuarantee' checkbox control
   * and also amount should be between 'gbpMinimumAmount' and 'gbpMaximumAmount' from 'buybackCharges'
   */
  const onAmountChange = (e: any) => {
    const { value, name } = e.target;
    const selectedBuyBackFromAmount =
      buybackHistory.length > 0 && buybackHistory[0].fromAmount
        ? buybackHistory[0].fromAmount
        : '';
    const selectedBuyBackToAmount =
      buybackHistory.length > 0 && buybackHistory[0].toAmount
        ? buybackHistory[0].toAmount
        : '';

    if (name === 'fromAmountInput') {
      form.setFieldsValue({
        toAmount: '',
      });
      if (
        proceedToBuyback &&
        parseInt(value) > parseInt(selectedBuyBackToAmount)
      ) {
        form.setFieldsValue({
          fromAmount: selectedBuyBackToAmount,
        });
      }
      if (!isRoundUpDownChange.fromAmount) {
        setRoundUpDownChange({ fromAmount: true, toAmount: false });
      }
      //handleUpdownWithAmount(name, value);
      setOriginalAmount({ originalFromAmount: value, originalToAmount: 0 });
    } else if (name === 'toAmountInput') {
      form.setFieldsValue({
        fromAmount: '',
      });
      if (
        proceedToBuyback &&
        parseInt(value) > parseInt(selectedBuyBackFromAmount)
      ) {
        form.setFieldsValue({
          toAmount: selectedBuyBackFromAmount,
        });
      }
      if (!isRoundUpDownChange.toAmount) {
        setRoundUpDownChange({ fromAmount: false, toAmount: true });
      }
      //handleUpdownWithAmount(name, value);
      setOriginalAmount({ originalFromAmount: 0, originalToAmount: value });
    }
    const { gbpMinimumAmount } = buybackCharges;

    if (
      fromCurrency?.currencyCode !== 'GBP' ||
      !value ||
      (fromCurrency?.currencyCode === 'GBP' &&
        value &&
        value <= gbpMinimumAmount) ||
      name === 'toAmountInput'
    ) {
      setBuybackApply(true);
      form.setFieldsValue({
        checkGuarantee: false,
      });
    } else {
      setBuybackApply(false);
      form.setFieldsValue({
        checkGuarantee: false,
      });
      if (reduxIsBuybackApply) {
        dispatch(
          actions.setBuyBackApply({
            isBuybackApply: false,
            buybackCharge: buybackCharges.buybackCharge,
          })
        );
      }
    }
    if (value !== '' && value > 0 && !isRateAllow) {
      setConnvertDisabled(false);
    } else if (value === '' || value === 0) {
      setConnvertDisabled(true);
      setUpDownDisabled(true);
    }
  };

  const getSelectedFromCurrency = (value: any) => {
    const selectedCurrency = currencies.find(
      (curr: any) => curr.currencyCode === value
    );
    setFromCurrency(selectedCurrency);
    setFromCurrencyLabel(selectedCurrency?.currencyCode);
    isCheckGuaranteeEnable(value);
    /** if 'currencyFrom' is other than GBP then display rate should be 'currencyBuyExchangeRate' from 'currencyFrom' for 'rate' input box */
    if (value !== 'GBP') {
      form.setFieldsValue({
        currencyExchangeRate: selectedCurrency?.currencyBuyExchangeRate,
        toCurrencyCode: 'GBP',
      });
      setToCurrencyLabel('GBP');
      getSelectedToCurrency('GBP');
      setCurrencyExchangeType({
        exchangeType: 'buy',
        currencyBaseExchangeRate: selectedCurrency.currencyBaseExchangeRate,
      });
    } else {
      if (form.getFieldValue('toCurrencyCode') === 'GBP') {
        resetOnSameCurrencyFrom();
      }
    }
    dispatch(
      actions.setCurrencySymbol({
        fromCurrencySymbol: selectedCurrency?.currencySymbol,
      })
    );
  };

  const getSelectedToCurrency = (value: any) => {
    if (!proceedToBuyback) {
      dispatch(actions.resetForm({}));
      onClearAmountClick();
    }
    const selectedCurrency = currencies.find(
      (curr: any) => curr.currencyCode === value
    );
    /** if 'toCurrency' is GBP and from currency is other than GBP then display rate should be 'currencyBuyExchangeRate' from 'fromCurrency' for 'rate' input box */
    if (value === 'GBP') {
      if (form.getFieldValue('fromCurrencyCode') === 'GBP') {
        resetOnSameCurrencyTo();
      }
      dispatch(actions.setSelectedToCurrencyCode(value));
    } else {
      form.setFieldsValue({
        currencyExchangeRate: selectedCurrency.currencySellExchangeRate,
        fromCurrencyCode: 'GBP',
      });
      getSelectedFromCurrency('GBP');
      setCurrencyExchangeType({
        exchangeType: 'sell',
        currencyBaseExchangeRate: selectedCurrency.currencyBaseExchangeRate,
      });
    }
    setToCurrencyLabel(value);
    setToCurrencyLabel(selectedCurrency.currencyCode);

    if (toCurrencyLabel === 'GBP' && fromCurrency.currencyCode === 'GBP') {
      dispatch(actions.setSelectedToCurrencyCode(''));
    }

    dispatch(
      actions.setCurrencySymbol({
        toCurrencySymbol: selectedCurrency.currencySymbol,
      })
    );

    const params = {
      storeNumber: selectedStore?.storeId,
      userId: user?.userId,
      currencyCode: value,
    };

    dispatch({ type: CURRENCY_LEDGER_BALANCE, payload: { params } });
  };

  const onChangeCurrencyFrom = (value: string) => {
    getSelectedFromCurrency(value);
  };

  const onChangeCurrencyTo = (value: string) => {
    getSelectedToCurrency(value);
  };

  const onBuyBackChange = (e: any) => {
    dispatch(
      actions.setBuyBackApply({
        isBuybackApply: e.target.checked,
        buybackCharge: buybackCharges.buybackCharge,
      })
    );
  };

  const onClearAmountClick = () => {
    resetFormValues();
    dispatch(actions.resetTotalCurrency({}));
    isCheckGuaranteeEnable('GBP');
  };

  const resetFormValues = () => {
    form.setFieldsValue({
      fromAmount: '',
      toAmount: '',
      checkGuarantee: false,
    });
    setConnvertDisabled(true);
    setUpDownDisabled(true);
  };

  const disableConvertButton = () => {
    if (
      form.getFieldValue('fromAmount') === 0 ||
      form.getFieldValue('fromAmount') === ''
    ) {
      setConnvertDisabled(true);
      setUpDownDisabled(true);
    } else {
      setConnvertDisabled(false);
    }
  };

  const getCurrencyDescription = (currencyCode: string) => {
    const selectedCurrencyDetails = currencies.find(
      (currencyDetails: any) => currencyDetails?.currencyCode === currencyCode
    );

    return selectedCurrencyDetails?.currencyDescription?.split('(')[0].trim();
  };

  const onConvertClicked = async () => {
    if (isBuybackApply === false) {
      setIsBuybackAlert(true);
    }
    dispatch(
      actions.setOriginalAamount({
        fromAmount: originalAmounts.originalFromAmount,
        toAmount: originalAmounts.originalToAmount,
      })
    );
    const params = {
      ...form.getFieldValue([]),
      isRoundDown: convertedCurrencies.isRoundDown,
    };

    const currienciesDescription = {
      fromCurrencyDescription: getCurrencyDescription(
        form.getFieldValue('fromCurrencyCode')
      ),
      toCurrencyDescription: getCurrencyDescription(
        form.getFieldValue('toCurrencyCode')
      ),
    };

    params.toAmount = params.toAmount === '' ? 0 : params.toAmount;
    params.fromAmount = params.fromAmount === '' ? 0 : params.fromAmount;

    dispatch(actions.setCurrenciesDescription(currienciesDescription));
    dispatch({ type: CONVERT_CURRENCY_REQUEST, payload: { params } });
  };

  const onRoundUpAndDownClicked = async (e: any) => {
    const params = {
      ...form.getFieldValue([]),
      isRoundDown: convertedCurrencies.isRoundDown,
      originalFromAmount: originalFromAmount,
      originalToAmount: originalToAmount,
    };
    onAmountChange(e);
    dispatch({ type: UPDOWN_CURRENCY_REQUEST, payload: { params } });
  };

  const handleValidateFieldNames = (name: string) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return 'onChange';
    return 'onBlur';
  };

  const onAcceptClick = () => {
    form.setFieldsValue({
      checkGuarantee: true,
    });
    setBuybackApply(false);
    dispatch(
      actions.setBuyBackApply({
        isBuybackApply: true,
        buybackCharge: buybackCharges.buybackCharge,
      })
    );
    setIsBuybackAlert(false);
    dispatch(actions.checkRateGuaranteePopup(false));
  };
  const onDeclineClick = () => {
    form.setFieldsValue({
      checkGuarantee: false,
    });
    dispatch(
      actions.setBuyBackApply({
        isBuybackApply: false,
        buybackCharge: buybackCharges.buybackCharge,
      })
    );
    setIsBuybackAlert(false);
    dispatch(actions.setBuyBackApply(false));
  };

  return (
    <>
      {isBuybackAlert && (
        <Alert
          visible={true}
          width={457}
          yesButtonText={'Decline'}
          itemProp={'secondary'}
          contentMessage={GUARANTEE_LINE_ONE}
          message={GUARANTEE_LINE_TWO}
          alertMessage={''}
          cancelButtonText={'Accept'}
          onCancel={onAcceptClick}
          onClick={onDeclineClick}
        />
      )}
      <Row align="top">
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              fromCurrencyCode: fromCurrency?.currencyCode,
              rateGuarantee: buybackCharges?.buybackCharge,
              fromAmount: '',
              toAmount: '',
              checkGuarantee: false,
            }}
          >
            <StyledRow align="top" justify="space-around" gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={
                    <HelpPopoverInfo
                      linkedID={'TRAVELMONEY_CurrencyFrom'}
                      position="rightTop"
                    >
                      <span>Currency From</span>
                    </HelpPopoverInfo>
                  }
                  name="fromCurrencyCode"
                  className="label-control"
                >
                  <Select
                    disabled={proceedToBuyback}
                    onChange={onChangeCurrencyFrom}
                    options={currencies.map((el: any) => {
                      return {
                        key: `from_${el.currencyCode}`,
                        value: el.currencyCode,
                        label: el.currencyDescription,
                      };
                    })}
                    data-testid="currency-list"
                  />
                </Form.Item>
              </Col>
            </StyledRow>
            <StyledRow align="top" justify="space-around" gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={
                    <HelpPopoverInfo
                      linkedID={'TRAVELMONEY_CurrencyTo'}
                      position="rightTop"
                    >
                      <span>Currency To</span>
                    </HelpPopoverInfo>
                  }
                  name="toCurrencyCode"
                  className="label-control"
                  dependencies={['fromCurrencyCode']}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    disabled={proceedToBuyback}
                    onChange={onChangeCurrencyTo}
                    loading={isStockBlanceLoading}
                    options={currencies.map((el: any) => {
                      return {
                        key: `to_${el.currencyCode}`,
                        value: el.currencyCode,
                        label: el.currencyDescription,
                      };
                    })}
                    data-testid="currency-list"
                  />
                </Form.Item>
              </Col>
            </StyledRow>
            <StyledRow align="middle" justify="space-around" gutter={32}>
              <Col span={10}>
                <Form.Item
                  label={
                    <HelpPopoverInfo
                      linkedID={'TRAVELMONEY_Rate'}
                      position="rightTop"
                    >
                      <span>Rate</span>
                    </HelpPopoverInfo>
                  }
                  name="currencyExchangeRate"
                  className="label-control"
                  validateTrigger={handleValidateFieldNames(
                    'currencyExchangeRate'
                  )}
                  rules={[
                    {
                      pattern: new RegExp(/^\d*(\.\d{0,5})?$/),
                    },
                    {
                      validator(_, value) {
                        const { exchangeType, currencyBaseExchangeRate } =
                          currencyExchangeType;
                        if (
                          !value ||
                          !currencyBaseExchangeRate ||
                          !exchangeType ||
                          (exchangeType === 'sell' &&
                            value <= currencyBaseExchangeRate) ||
                          (exchangeType === 'buy' &&
                            value >= currencyBaseExchangeRate)
                        ) {
                          setIsRateAllow(false);
                          disableConvertButton();
                          return Promise.resolve();
                        }
                        onClearAmountClick();
                        return Promise.reject('Please change rate');
                      },
                    },
                  ]}
                >
                  <InputDecimal
                    className="input-control input-with-border"
                    min={0}
                    step="0.00001"
                    autoComplete="off"
                    disabled={proceedToBuyback}
                    numberofdecimalrequired={5}
                    data-testid="rateInput"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <HelpPopoverInfo
                      linkedID={'TRAVELMONEY_RateGuarantee'}
                      position="rightTop"
                    >
                      <span>Rate Guarantee</span>
                    </HelpPopoverInfo>
                  }
                  name="rateGuarantee"
                  className="label-control"
                >
                  <StyledRateGuranteeInput className="input-control" disabled />
                </Form.Item>
              </Col>
              <StyledCheckbox span={6}>
                <Form.Item
                  label=""
                  name="checkGuarantee"
                  className="label-control"
                  valuePropName="checked"
                  noStyle
                >
                  <Checkbox
                    disabled={isBuybackApply}
                    onChange={onBuyBackChange}
                  >
                    Yes
                  </Checkbox>
                </Form.Item>
              </StyledCheckbox>
            </StyledRow>
            <StyledBorderRow align="top" justify="space-around">
              <Col span={22}>
                <StyledRow align="middle" justify="space-between" gutter={16}>
                  <Col span={11}>
                    <Form.Item
                      label={
                        <HelpPopoverInfo
                          linkedID={'TRAVELMONEY_ConversionEntry'}
                          position="rightTop"
                        >
                          <span>{`Amount in ${fromCurrencyLabel}`}</span>
                        </HelpPopoverInfo>
                      }
                      name="fromAmount"
                      className="label-control"
                    >
                      <InputDecimal
                        addonBefore={fromCurrencyLabel}
                        className="input-control input-with-border"
                        name="fromAmountInput"
                        disabled={!form.getFieldValue('toCurrencyCode')}
                        onChange={onAmountChange}
                        autoComplete="off"
                        placeholder={''}
                        data-testid="fromAmountInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      label={`Amount in ${toCurrencyLabel}`}
                      name="toAmount"
                      className="label-control"
                    >
                      <InputDecimal
                        addonBefore={toCurrencyLabel || ' '}
                        className="input-control input-with-border"
                        name="toAmountInput"
                        onChange={onAmountChange}
                        autoComplete="off"
                        placeholder={''}
                        data-testid="toAmountInput"
                      />
                    </Form.Item>
                  </Col>
                </StyledRow>
                <StyledRow align="middle" justify="space-around" gutter={16}>
                  <Col span={24}>
                    <Space>
                      <Button
                        itemProp="secondary"
                        onClick={onConvertClicked}
                        loading={isConvertLoading}
                        disabled={isConvertDisabled}
                      >
                        Convert
                      </Button>
                      <Button
                        itemProp="secondary"
                        loading={isUpDownLoading}
                        onClick={onRoundUpAndDownClicked}
                        disabled={isUpDownDisabled || toCurrencyLabel === 'GBP'}
                      >
                        {convertedCurrencies.isRoundDown
                          ? 'Round Down'
                          : 'Round Up'}
                      </Button>

                      <Button itemProp="secondary" onClick={onClearAmountClick}>
                        Clear Amount
                      </Button>
                      <StyledRowHelp>
                        <HelpPopoverInfo
                          linkedID={'TRAVELMONEY_CONVERTROUNDCLEAR'}
                          position="rightTop"
                          helpPosition="AFTER"
                        ></HelpPopoverInfo>
                      </StyledRowHelp>
                    </Space>
                  </Col>
                </StyledRow>
              </Col>
            </StyledBorderRow>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ConversionForm;
