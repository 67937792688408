import React, { useCallback, useEffect, useState } from 'react';
import {
  CANCEL,
  SAVE,
  ADD_TO_STOP_LIST_HEADING,
  REMOVE_FROM_STOP_LIST_HEADING,
  REMOVE_FROM_STOP_LIST_DESCRIPTION,
  ADD_TO_STOP_LIST_DESCRIPTION,
  CUSTOMER_NOTE_TYPE_ID,
} from 'components/customer/constants';
import { Button } from 'components/common/Button';
import { Row, Col, Form, Modal, notification } from 'antd';
import { postCustomerStopListNote } from 'services/customer';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'redux/reducers/customer';
import { cacheCustomerStatus, clearCacheByCacheKey } from 'utils/cacheServices';

import TextArea from 'components/common/TextArea';

interface StopListNotePopupProps {
  isStopListNotePopupVisible: boolean;
  onStopListNotePopupCancel: () => void;
  onNoteAddClicked: (isAddedNote: boolean) => void;
}

const StopListNotePopup = ({
  isStopListNotePopupVisible,
  onStopListNotePopupCancel,
  onNoteAddClicked,
}: StopListNotePopupProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const {
    customer: { customer, isCustomerInStopList },
    user: { user },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
      user: state.user,
    };
  });
  const [disableAdd, setDisableAdd] = useState<boolean>(true);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isStopListNotePopupVisible) {
      setVisible(true);
    }
  }, [isStopListNotePopupVisible]);

  const onCloseClick = useCallback(() => {
    onStopListNotePopupCancel();
    dispatch(actions.setIsCustomerAddedToStopList(false));
  }, [onStopListNotePopupCancel]); // eslint-disable-line react-hooks/exhaustive-deps

  const addNewNote = async (params: any) => {
    try {
      const response = await postCustomerStopListNote(params);
      if (response?.data) {
        if (
          response?.data?.customerNoteType?.customerNoteTypeId ===
          CUSTOMER_NOTE_TYPE_ID.CUSTOMER_ADDED_TO_STOP_LIST
        ) {
          onNoteAddClicked(true);
          dispatch(actions.setIsCustomerInStopList(true));
          dispatch(actions.setIsCustomerAddedToStopList(true));
        }
        if (
          response?.data?.customerNoteType?.customerNoteTypeId ===
          CUSTOMER_NOTE_TYPE_ID.CUSTOMER_REMOVED_FROM_STOP_LIST
        ) {
          onNoteAddClicked(true);
          dispatch(actions.setIsCustomerInStopList(false));
          dispatch(actions.setIsCustomerAddedToStopList(true));
        }

        clearCacheByCacheKey(cacheCustomerStatus, params?.customerId);
      }
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.error || e?.message,
        duration: 2,
      });
    }
  };

  const onInputKeyUp = () => {
    if (form.getFieldValue('noteText') !== '') {
      setDisableAdd(false);
    } else {
      setDisableAdd(true);
    }
  };
  const onSaveNoteClicked = useCallback(() => {
    const fieldSet: any = {
      customerId: Number(customer.customerId),
      customerNoteTypeId: isCustomerInStopList
        ? CUSTOMER_NOTE_TYPE_ID.CUSTOMER_REMOVED_FROM_STOP_LIST
        : CUSTOMER_NOTE_TYPE_ID.CUSTOMER_ADDED_TO_STOP_LIST,
      createdBy: Number(user?.userId),
      noteText: form.getFieldValue('noteText'),
    };
    addNewNote(fieldSet);
    setDisableAdd(false);
    form.resetFields();
    onCloseClick();
  }, [form, customer.customerId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      className="add-stop-list-note-popup"
      open={visible}
      closable={true}
      key={1}
      centered
      width={788}
      maskClosable={false}
      onCancel={onCloseClick}
      footer={
        <Row>
          <Col span={24} className="add-stop-list-footer-btn-row">
            <Button
              key="cancel"
              onClick={onCloseClick}
              className="cancel-btn"
              itemProp="primary"
            >
              {CANCEL}
            </Button>

            <Button
              key="save"
              className="save-btn"
              disabled={disableAdd}
              onClick={onSaveNoteClicked}
            >
              {SAVE}
            </Button>
          </Col>
        </Row>
      }
    >
      <Form layout="vertical" form={form}>
        <Row>
          <label className="heading">
            {isCustomerInStopList
              ? REMOVE_FROM_STOP_LIST_HEADING
              : ADD_TO_STOP_LIST_HEADING}
          </label>
        </Row>
        <Row>
          <label>
            {isCustomerInStopList
              ? REMOVE_FROM_STOP_LIST_DESCRIPTION
              : ADD_TO_STOP_LIST_DESCRIPTION}
          </label>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Reason"
                  name="noteText"
                  className="label-control form-textarea"
                >
                  <TextArea showCount rows={4} onChange={onInputKeyUp} maxLength={600} value={form.getFieldValue('noteText')} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default StopListNotePopup;
