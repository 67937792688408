import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Row, Col, notification } from 'antd';
import styled from 'styled-components';
import FooterButtons from 'components/common/footerButtons';
import PledgeLoanSummary from 'components/pledgemanagement/widgets/common/pleadgeItemSummary';
import PledgeLoanContainer from 'components/pledgemanagement/widgets/common/pledgeLoanContainer';
import ItemDescriptionValuation from 'components/pledgemanagement/widgets/common/itemDescriptionValuation';
import Alert from 'components/common/ControlledAlert';
import CustomerHeader from 'components/customer/customerHeader';
import { actions } from 'redux/reducers/pledgeManagement';
import { pledgeActions } from 'redux/reducers/pawnbroking';
import { coinActions } from 'redux/reducers/pawnbroking/coin';
import { stoneActions } from 'redux/reducers/pawnbroking/stone';
import { BARCODE_FAILED_TEXT } from '../../../constants';
import {
  POST_PFI_BARCODE,
  PUSH_PLEDGE_TO_PFI,
  PUSH_PURCHASE_TO_PFI,
  GET_PLEDGE_ITEMS,
  GET_LOAN_DETAILS,
  GET_PURCHASE_LOAN_DETAILS,
} from 'action_creators/pledgeManagement';
import { useState, useEffect } from 'react';
import { SERVICE_TYPE_LABEL_BY_KEY } from 'globalConstants';
import { BUTTON_LABELS } from 'components/pledgemanagement/constants';

const StyledContentLayout = styled(Col)`
  background: var(--white);
  border-radius: 5px;
  height: calc(100vh - 210px);
`;

const StyledMainWrapper = styled(Col)``;

const StyledMainContainer = styled.div<{ $pledgeContainer: boolean }>`
  background: var(--white);
  border: 0.6px solid var(--linkwater);
  border-bottom: none;
  box-shadow: 0px 1px 10px rgba(1, 78, 169, 0.2);
  border-radius: 5px 5px 0 0;
  margin: ${(props) =>
    props.$pledgeContainer ? '0px 5px 0px 0px' : '0px 0px 0px 5px'};
  color: var(--black);
  display: flex;
  flex-direction: column;
`;

const StyledItemSummary = styled(Col)`
  height: calc(100vh - 384px);
  padding: 15px;
`;

const StyledLayout = styled(Layout)`
  background: var(--background);
`;
const StyledLoanContainer = styled(Col)`
  padding: 16px 8px;
  background: #f1f5fc;
`;
interface PfiReviewProps {
  PfiReviewHandler: () => void;
  onExit: () => void;
}

const PfiReview = ({ PfiReviewHandler, onExit }: PfiReviewProps) => {
  const {
    pledgeManagement: {
      pledgeItemsList,
      selectedLoanNumber,
      confirmItemDetails,
      loanDetails,
      itemCount,
      totalLoanWeight,
      failedPfiBarcodeList,
      pfiUnderSeventyFiveList,
      pfiStatus,
      newPledgeItemsList,
      confirmedItems,
      fetchBarCodeStatus,
      selectedPFIServiceType,
      purchaseLoanDetails,
    },
    user: { user, selectedStore, userId },
  } = useSelector((state: any) => {
    return {
      pledgeManagement: state.pledgeManagement,
      user: state.user,
    };
  });

  const dispatch = useDispatch();
  const [reCheckAlert, setReCheckAlert] = useState<boolean>();
  const [reCheckRetryAlert, setReCheckRetryAlert] = useState<boolean>();
  let index = 0;
  const [nextIndex, setNextIndex] = useState<number>(0);

  const onPushToPfiClick = (list: any) => {
    const barCodeList = pledgeItemsList?.filter((obj: any) => !!obj?.barcode);
    list.map((obj: any, index: number) => {
      const barCodeParams = {
        userId: user.userId,
        storeId: selectedStore.storeId,
        itemId: obj.id,
        index,
        obj,
      };
      if (barCodeList?.length === pledgeItemsList?.length) {
        return dispatch(actions.setFetchBarCodeStatus(true));
      } else {
        return dispatch({
          type: POST_PFI_BARCODE,
          payload: {
            barCodeParams,
            newPledgeItemsList,
          },
        });
      }
    });
  };

  const onNextPledgeClick = () => {
    dispatch(actions.setPfiStatus(false));
    if (pfiUnderSeventyFiveList.length === 1) {
      dispatch(actions.setTabsVisible(false));
      dispatch(actions.setActivePfiReview(true));
      dispatch(actions.clearPFA());
      dispatch(pledgeActions.clearItems());
      dispatch(coinActions.clearCoinItems());
      dispatch(stoneActions.clearStoneItems());
    } else {
      index = pfiUnderSeventyFiveList.findIndex(
        (nextPledge: any) => nextPledge.loanId === loanDetails.id
      );
      if (index === pfiUnderSeventyFiveList.length - 1) {
        setNextIndex(index - 1);
      } else {
        setNextIndex(index + 1);
      }
      dispatch(actions.clearPFA());
      dispatch(pledgeActions.clearItems());
      dispatch(coinActions.clearCoinItems());
      dispatch(stoneActions.clearStoneItems());
      dispatch({
        type: GET_PLEDGE_ITEMS,
        payload: {
          loanId: pfiUnderSeventyFiveList[nextIndex].loanId,
          pledgeLoanNumber: pfiUnderSeventyFiveList[nextIndex].loanNumber,
        },
      });

      dispatch({
        type: GET_LOAN_DETAILS,
        payload: {
          loanNumber: pfiUnderSeventyFiveList[nextIndex].loanNumber,
        },
      });
      dispatch({
        type: GET_PURCHASE_LOAN_DETAILS,
        payload: {
          loanNumber: pfiUnderSeventyFiveList[nextIndex].loanNumber,
        },
      });
      dispatch(
        actions.setSelectedLoanNumber(
          pfiUnderSeventyFiveList[nextIndex].loanNumber
        )
      );
      dispatch(
        actions.setSelectedPFIServiceType(
          pfiUnderSeventyFiveList[nextIndex].serviceType
        )
      );
    }
  };

  const onExitClick = () => {
    dispatch(actions.setTabsVisible(true));
    dispatch(actions.setActivePfiReview(false));
    onExit();
    dispatch(actions.clearPFA());
    dispatch(pledgeActions.clearItems());
    dispatch(coinActions.clearCoinItems());
    dispatch(stoneActions.clearStoneItems());
  };

  const onConfirmExitOnlyClick = () => {
    dispatch(actions.setTabsVisible(true));
    dispatch(actions.clearPFA());
    dispatch(pledgeActions.clearItems());
    dispatch(coinActions.clearCoinItems());
    dispatch(stoneActions.clearStoneItems());
  };

  const onRecheckClicked = async () => {
    setReCheckAlert(false);
    try {
      const response: any = await onPushToPfiClick(failedPfiBarcodeList);
      if (response?.data) {
        setReCheckAlert(false);
      } else {
        setReCheckRetryAlert(true);
      }
    } catch (e: any) {
      setReCheckRetryAlert(true);
      notification.error({
        message: e?.response?.data?.error,
        duration: 3,
      });
    }
  };

  useEffect(() => {
    if (failedPfiBarcodeList.length) {
      setReCheckAlert(true);
    }
  }, [failedPfiBarcodeList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchBarCodeStatus) {
      if (selectedPFIServiceType === SERVICE_TYPE_LABEL_BY_KEY.PLEDGE) {
        const params = {
          loanId: loanDetails?.id,
          auctionId: loanDetails?.auctionId,
          loanNumber: loanDetails?.loanNumber,
          storeId: selectedStore?.storeId,
          itemCount: itemCount,
          loanValue: loanDetails?.loanAmount,
          interestAmount: loanDetails?.interestAmount,
          totalWeight: totalLoanWeight,
          userId: userId,
          auctionItems: confirmItemDetails,
        };
        dispatch({
          type: PUSH_PLEDGE_TO_PFI,
          payload: {
            params,
          },
        });
      } else {
        const params = {
          purchaseId: purchaseLoanDetails?.purchaseId,
          storeId: selectedStore?.storeId,
          createdBy: user?.userId,
          items: confirmedItems?.map((item: any) => {
            return { ...item, itemId: item };
          }),
        };
        dispatch({
          type: PUSH_PURCHASE_TO_PFI,
          payload: {
            params,
          },
        });
      }
    }
  }, [
    fetchBarCodeStatus,
    loanDetails,
    purchaseLoanDetails,
    selectedStore,
    confirmedItems,
    itemCount,
    totalLoanWeight,
    confirmItemDetails,
    selectedPFIServiceType,
    userId,
    user,
    dispatch,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StyledLayout className="main-pfi-review-layout">
        <CustomerHeader
          title={`PFI Review ${selectedPFIServiceType} - ${selectedLoanNumber}`}
          buttonsOption={false}
          isCustomerNeeded={false}
        />

        <StyledContentLayout className="pfi-review-wrapper">
          <Row className="container-row">
            <StyledMainWrapper span={11}>
              <StyledMainContainer $pledgeContainer={true}>
                <Row>
                  <StyledItemSummary>
                    <PledgeLoanSummary pledgeItemsList={pledgeItemsList} />
                  </StyledItemSummary>
                  <Row>
                    <StyledLoanContainer>
                      <PledgeLoanContainer />
                    </StyledLoanContainer>
                  </Row>
                </Row>
              </StyledMainContainer>
            </StyledMainWrapper>
            <StyledMainWrapper span={13}>
              <StyledMainContainer $pledgeContainer={false}>
                <ItemDescriptionValuation />
              </StyledMainContainer>
            </StyledMainWrapper>
          </Row>
        </StyledContentLayout>
        <Row>
          <Col span={24}>
            <FooterButtons
              isExitOnly
              enableExtraClass={true}
              extraButtonItemProp="secondary"
              onConfirmExitClick={onConfirmExitOnlyClick}
              proceedButtonText={
                pfiStatus
                  ? BUTTON_LABELS.NEXT_PLEDGE
                  : BUTTON_LABELS.PUSH_TO_PFI
              }
              onProceedClick={
                pfiStatus
                  ? () => onNextPledgeClick()
                  : () => onPushToPfiClick(pledgeItemsList)
              }
              extraButtonEanable={true}
              extraButtonText={BUTTON_LABELS.BACK_TO_PFI_MANAGER}
              extraButtonAlign="left"
              extraButtonOnClick={onExitClick}
              isDisabled={confirmedItems.length !== pledgeItemsList.length}
            />
          </Col>
        </Row>
      </StyledLayout>
      {reCheckAlert && (
        <Alert
          visible={true}
          width={200}
          yesButtonText={BUTTON_LABELS.RETRY_RECHECK}
          itemProp={'secondary'}
          message={BARCODE_FAILED_TEXT}
          alertMessage={'Alert'}
          onClick={() => onRecheckClicked()}
          isCancelBtn={false}
          onCancel={() => undefined}
        />
      )}
      {reCheckRetryAlert && (
        <Alert
          visible={true}
          width={200}
          yesButtonText={BUTTON_LABELS.SEND_EMAIL}
          itemProp={'secondary'}
          message="Contact IT Team for Help"
          alertMessage={'Alert'}
          onClick={() => onExitClick()}
          isCancelBtn={false}
          onCancel={() => undefined}
        />
      )}
    </>
  );
};

export default PfiReview;
