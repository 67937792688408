export const TITLE_LABEL = 'Payment';
export const PAY_TO_CUSTOMER = 'Pay to Customer';
export const TAKE_FROM_CUSTOMER = 'Take from Customer';
export const TAKEN_FROM_CUSTOMER = 'Taken from Customer';
export const PAID_TO_CUSTOMER = 'Paid to Customer';
export const CONFIRM_LABEL = 'Add';
export const AMOUNT_LABEL = 'Amount';
export const FULL_AMOUNT_LABEL = 'Full Amt.';
export const REMAINING_AMOUT_LABEL = 'Remaining Amt.';
export const BUTTON_LABEL = {
  LAYAWAY_ADD: 'Add',
  LAYAWAY_CONFIRM: 'Confirm',
};
export const SUMMARY_TITLE = 'Summary';
export const FINAL_CHECK_TITLE = 'Final Checks';
export const SUCCESS_MESSAGE_ON_UPDATE = {
  message: 'Payment',
  description: 'Payment has been done successfully.',
};

export const BAGSLIP_SERVICES = [1, 2, 8]; // Pledge, Purchase, Pickup (Renewal/Paydown)
export const BAGSLIP_PICKUP_ACTION = [2, 3]; // Renewal, Paydown
export const ERROR_MESSAGE = {
  LESS_THAN:
    'The amount entered is less than the amount to be paid to the customer',
  MORE_THAN:
    'The amount entered is more than the amount to be paid to the customer',
  LAYAWAY_TRANSACTION_FAILED:
    'There is some error while fetching layaway transactions and available funds. Please check.',
  VALIDATION_MESSAGE_LAYAWAY_INPUT:
    'Amount should not be greater than the available funds.',
  PLEASE_HOLD: 'Please hold...',
  AWAITING_BANK_TRANSFER:
    'Awaiting payment reference to confirm bank transaction request.',
  MIN_AMOUNT_BANK_TRANFER_VALIDATION:
    'This amount is less than the minimum payment allowed via Faster Payment - please choose an alternative payment method',
  ACCOUNT_VALIDATION_UNSUPPORTED:
    'Manual bank check needed ( Metro, Chase, etc. )',
  POTIENTIAL_MATCH_TEXT: 'Potential match found by Bank',
  ONLY_NUMERIC_VALUE: 'Enter numeric value.',
  VALIDATE_PAYEE_ACCOUNT_FAILED:
    'Failed to confirm bank payment request. Please re-try or change to alternative payment method',
  CHANGE_DUE:
    'Transaction cannot be completed as Change Due is more than £49.99',
};

export const PAYMENT_PROMPT = {
  MESSAGE: 'Did the receipt(s) print successfully?',
  COMPLETE: 'Yes - Complete',
  REPRINT: 'No - Reprint',
};

export const RETAIL_ACTION_ID = {
  PICKUP: 1,
  RENEW: 2,
  PAYDOWN: 3,
  PARTIAL_PAY: 4,
  WITHDRAW: 5,
  AFFIDAVIT: 6,
};

export const CHECK_LIST = {
  PLEDGE: {
    service: 'Pledge',
    action: 'Secure items with ticket',
  },
  PURCHASE: {
    service: 'Purchase',
    action: 'Secure items with ticket',
  },
  PURCHASERETURN: {
    service: 'Purchase Return',
    action: 'Obtain payment and give item(s) to customer',
  },
  PICKUP: {
    service: 'Pickup',
    action: 'Return item to customer',
  },
  RENEW: {
    service: 'Renew',
    action: 'Secure items with ticket',
  },
  PAYDOWN: {
    service: 'Paydown',
    action: 'Secure items with ticket',
  },
  PARTIAL_PAYMENT: {
    service: 'Partial Payment',
    action: '',
  },
  WITHDRAW: {
    service: 'Withdraw',
    action: 'return item(s) to customer',
  },
  RETAIL: {
    service: 'Retail',
    action: 'Give item to customer',
  },
  REFUND: {
    service: 'Refund',
    action: 'Take item from customer and attach barcode',
  },
  AFFIDAVIT: {
    service: 'Affidavit',
    action: 'Give the affidavit to the customer',
  },
  TRAVEL_MONEY: {
    service: 'Travel money',
    action:
      'Currency amount that should have been received or given to the customer',
  },
  CHEQUE_CASHING: {
    service: 'Cheque Cashing',
    action: 'Secure the cheque and archive the mandate',
  },
  WESTERN_UNION: {
    service: 'Western Union',
    action: '-',
  },
  SURPLUS: {
    service: 'Surplus',
    action: 'Ensure receipt is signed',
  },
};

export const AMOUNT_SYMBOL = '&#163;';

export const BANK_TRANSFER_LABELS = {
  ACCOUNT_HOLDER_NAME: 'Account Holder Name',
  ACCOUNT_NUMBER: 'Account Number',
  AMOUNT: AMOUNT_LABEL,
  SORT_CODE: 'Sort Code',
  VERIFY_ACCOUNT_DETAILS: 'Verify Account Details',
  RESET: 'Reset',
  FULL_AMOUNT: FULL_AMOUNT_LABEL,
  REMAINING_AMOUT: REMAINING_AMOUT_LABEL,
  ADD: 'Add',
  CLEAR: 'Clear',
  ACCEPT: 'Accept',
  EDIT: 'Edit',
  OK: 'Ok',
};

export const BANK_TRANSFER_STATUS = {
  PENDING: 'PENDING',
};

export const PAYMENT_TAB_KEYS = {
  CASH: '1',
  CARD: '2',
  LAYAWAY_FUNDS: '3',
  BANK_TRANSFER: 'bank-transfer',
  VOUCHER: '4',
};

export const ACCOUNT_VALIDATION_RESULT_CODES = {
  PASS: 1,
  FAIL: 2,
  CLOSE_MATCH: 3,
  UNSUPPORTED: 4,
};

export const PAYMENT_SUCCESS_MESSAGE = {
  BANK_TRANSFER: 'Bank Transfer',
  BANK_TRANSFER_REQUEST: 'Bank Transfer Request',
  REQUEST_COMPLETED: 'Request Completed',
  AMOUNT_VISIBLE_IN_2_HOURS:
    "The requested amount will be visible in the customer's account the same day.",
  SEND_TO_SUPPORT_CENTER: 'Sent to Support Centre',
  LSC_MSG_PENDING:
    "If approved, then the requested amount will be visible in the customer's account the same day.",
  TRANSACTION_SUCCESSFULL: 'Transaction Successful',
  PAYMENT_COMPLETE: 'Payment Complete',
};

export const PAYMENT_ERROR_MESSAGE = {
  FAILED_TO_CONFIRM_BANK_PAYMENT: 'Failed to confirm bank payment request',
  CHANGE_ALTERNATIVE_PAYMENT_METHOD:
    'Please change to alternative payment method',
  BANK_TRANSFER_ALREADY_IN_SUMMARY:
    'Bank transfer request has been already added to summary.',
};

export const MIN_AMT_BANK_TRANSFER = 5;
export const MAX_ACCOUNT_VALIDATION_ATTEMPTS = 5;

export const WU_ACTIONS = {
  TAKE_PAYMENT: 'Take Payment',
  PAY_CUSTOMER: 'Pay Customer',
};
