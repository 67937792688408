import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Row, Col, Spin } from 'antd';
import styled, { css } from 'styled-components';
import groupBy from 'lodash/groupBy';
import { Button } from 'components/common/Button';
import { LeftOutlined, CheckCircleFilled } from '@ant-design/icons';
import ImageLoader from 'components/common/ImageLoader';
import { useSelector, useDispatch } from 'react-redux';
import { joinValues } from 'utils/util';
import { CUSTOMER_HEADER_LABEL } from '../constants';
import CustomerNotes from 'components/customer/customerNotes';
import HelpPopoverInfo, {
  StyledHelpPopoverInfoForButtons,
} from 'components/common/HelpPopoverInfo';
import {
  BASKET_SERVICE_TYPE,
  ROUTE_CONFIG,
  DISABLED_CUSTOMER_HEAD,
  GUEST_TYPES,
} from 'globalConstants';

import { actions as cashManagementActions } from 'redux/reducers/cashManagement';
import { actions } from 'redux/reducers/customer';
import GuestSearchPopup from '../GuestSearchPopup';

const StyledRow = styled(Row)`
  flex-wrap: nowrap;
`;

const StyledHr = styled.hr`
  border: 0;
  height: 1px;
  margin: 0;
  margin-bottom: 15px;
  background-color: var(--linkwater);
`;

const StyledButton = styled(Button)`
  margin-inline-start: 1px;
`;

const StyledCol = styled(Col)<{
  $isDisableCustLink: boolean;
}>`
  text-align: center;
  ${(props) =>
    !props.$isDisableCustLink &&
    css`
      cursor: pointer;
    `}
`;

const StyledCustomerName = styled.span`
  padding: 0 10px;
`;

const StyledTitleCol = styled(Col)`
  font-weight: 500;
`;

const StyledStoreEnquiry = styled.span`
  padding: 0 10px;
  color: var(--red);
`;

const StyledMultipleButtonsCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

interface CustomerHeaderProps {
  goBackArrow?: boolean;
  title: string;
  name?: string | JSX.Element;
  buttonsOption?: boolean;
  preferredName?: string;
  profilePic?: boolean;
  customerId?: number;
  gdprAccepted?: boolean;
  helpSection?: string;
  cashMgmtButtons?: boolean;
  isCustomerNeeded?: boolean;
}

const GdprTermsAccepted = () => (
  <span style={{ color: 'var(--mountain-meadow)' }}>
    <CheckCircleFilled />
    <span style={{ padding: '0 10px' }}>
      {CUSTOMER_HEADER_LABEL.GDPR_ACCEPTED}
    </span>
  </span>
);

const CustomerHeader = ({
  goBackArrow = false,
  title,
  buttonsOption = true,
  gdprAccepted,
  helpSection,
  cashMgmtButtons = false,
  isCustomerNeeded = true, // whether we need customer name or guest on header
}: CustomerHeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    customer: {
      customer,
      loading,
      guestType,
      isStoreEnquiryMode,
      isPhoneEnquiryMode,
      isVATDetailsAvailable,
      isTpaEnquiryMode,
    },
    basket: { basket },
  } = useSelector((state: any) => {
    return {
      customer: state.customer,
      basket: state.basket,
    };
  });

  const dispatch = useDispatch();

  const [customerNoteVisible, setCustomerNoteVisible] =
    useState<boolean>(false);
  const [isNotesEnabled, setNotesEnabled] = useState<boolean>(false);
  const [isVATHeaderVisible, setVATHeaderVisible] = useState<boolean>(false);
  const [isDisableCustLink, setIsDisableCustLink] = useState<boolean>(false);
  const [guestSearchVisible, setGuestSearchVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!customer?.customerId && basket?.basketServices?.length > 0) {
      const groupedItems = groupBy(basket.basketServices, 'serviceType');
      const groupedItemsKeys = Object.keys(groupedItems);

      const showVATDetails =
        groupedItemsKeys.every((val) => +val === BASKET_SERVICE_TYPE.RETAIL) &&
        isVATDetailsAvailable;
      setVATHeaderVisible(showVATDetails);
    }

    if (DISABLED_CUSTOMER_HEAD.includes(location?.pathname?.split('/')[1])) {
      setIsDisableCustLink(true);
    }
  }, [basket.basketServices]); // eslint-disable-line react-hooks/exhaustive-deps

  const { customerId, firstNames, surname, preferredName } = customer;

  const name = joinValues([firstNames, surname], true) || 'Guest';
  const preferName = preferredName
    ? ` (${preferredName}) `
    : isVATHeaderVisible
    ? ` (VAT) `
    : '';

  const onExitClick = () => {
    navigate('/');
  };

  const onCustomerNoteClicked = () => {
    setCustomerNoteVisible(true);
  };
  const onCustomerNotesCancelClick = () => {
    setCustomerNoteVisible(false);
  };

  const onCustomerNameClick = () => {
    if (customer && customerId) {
      dispatch(
        actions.setCustomerFromHeader({
          isCustFromHeader: true,
          resumeJourneyUrl: location.pathname,
        })
      );
      navigate(
        `/${ROUTE_CONFIG.CUSTOMER}/${ROUTE_CONFIG.SUMMARY}/${customer?.customerId}`
      );
    } else {
      setGuestSearchVisible(true);
    }
  };

  const closeGuestSearchPop = () => {
    setGuestSearchVisible(false);
  };

  useEffect(() => {
    if (customerId) {
      setNotesEnabled(true);
    } else {
      setNotesEnabled(false);
    }
  }, [customerId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StyledRow>
        <Col flex={1}>
          <Row align="middle" className="customer-header mx-h1-normal">
            <StyledTitleCol span={8}>
              {goBackArrow && (
                <LeftOutlined onClick={onExitClick} aria-label="exit" />
              )}
              <div>
                <HelpPopoverInfo
                  linkedID={`${helpSection}_${title}`}
                  position="rightTop"
                >
                  <span>{`${title}`}</span>
                </HelpPopoverInfo>
                {isStoreEnquiryMode ? (
                  <StyledStoreEnquiry>{`(Store Enquiry Mode)`}</StyledStoreEnquiry>
                ) : isPhoneEnquiryMode ? (
                  <StyledStoreEnquiry>{`(Phone Enquiry Mode)`}</StyledStoreEnquiry>
                ) : isTpaEnquiryMode ? (
                  <StyledStoreEnquiry>{`(3rd Party Mode)`}</StyledStoreEnquiry>
                ) : null}
              </div>
            </StyledTitleCol>
            <StyledCol
              span={8}
              $isDisableCustLink={isDisableCustLink}
              onClick={!isDisableCustLink ? onCustomerNameClick : undefined}
            >
              {isCustomerNeeded && (
                <div>
                  {!gdprAccepted &&
                    (loading ? (
                      <Spin spinning={loading} />
                    ) : (
                      <ImageLoader
                        customerId={customerId}
                        imageUrl={
                          customerId &&
                          `/api/customers/images/${customerId}?isThumnails=false`
                        }
                        classes={
                          name === 'Guest'
                            ? 'mx-small-avatar-custom mx-small-avatar'
                            : `mx-small-avatar`
                        }
                        fallbackImg={
                          name === 'Guest'
                            ? 'guest_thumbnail.svg'
                            : 'customer-pic-38px.svg'
                        }
                        borderRadius={'50%'}
                        isCaching={false}
                        preview={name !== 'Guest'} // We can remove preview from customerHeader ?
                      />
                    ))}
                  <StyledCustomerName>
                    {gdprAccepted ? (
                      <GdprTermsAccepted />
                    ) : (
                      `${name} ${preferName} ${
                        guestType === GUEST_TYPES.VALUATION
                          ? `(${GUEST_TYPES.VALUATION})`
                          : ''
                      }`
                    )}
                  </StyledCustomerName>
                </div>
              )}
            </StyledCol>
            <Col span={8}>
              {buttonsOption && (
                <Row align="middle" justify="end">
                  <StyledHelpPopoverInfoForButtons
                    linkedID="CustomerSummary_Notes"
                    position="leftTop"
                    helpPosition="BEFORE"
                  >
                    <StyledButton
                      itemProp="secondary"
                      onClick={onCustomerNoteClicked}
                      disabled={!isNotesEnabled}
                    >
                      Notes
                    </StyledButton>
                  </StyledHelpPopoverInfoForButtons>
                </Row>
              )}
              {customerNoteVisible && buttonsOption ? (
                <CustomerNotes
                  customerNoteVisible
                  onCustomerNotesCancelClick={onCustomerNotesCancelClick}
                />
              ) : null}
              {cashMgmtButtons && (
                <Row justify="end">
                  <StyledMultipleButtonsCol span={7}>
                    <StyledButton
                      itemProp="secondary"
                      onClick={() =>
                        dispatch(
                          cashManagementActions.setCashTransferPopupVisibility(
                            true
                          )
                        )
                      }
                    >
                      Cash Transfer
                    </StyledButton>
                  </StyledMultipleButtonsCol>
                  <StyledMultipleButtonsCol
                    span={7}
                    style={{ marginRight: '5px' }}
                  >
                    <StyledButton
                      itemProp="secondary"
                      onClick={() =>
                        dispatch(
                          cashManagementActions.setCashDeliveryPopupVisiblity(
                            true
                          )
                        )
                      }
                    >
                      Cash Delivery
                    </StyledButton>
                  </StyledMultipleButtonsCol>
                  <StyledMultipleButtonsCol span={7}>
                    <StyledButton
                      itemProp="secondary"
                      onClick={() =>
                        dispatch(
                          cashManagementActions.setExpenseInOutPopupVisibility(
                            true
                          )
                        )
                      }
                    >
                      Expense In/Out
                    </StyledButton>
                  </StyledMultipleButtonsCol>
                </Row>
              )}
            </Col>
          </Row>
        </Col>

        {guestSearchVisible && (
          <GuestSearchPopup
            visible={true}
            closeGuestSearchPupup={() => closeGuestSearchPop()}
          />
        )}
      </StyledRow>
      <StyledHr />
    </>
  );
};

export default CustomerHeader;
