import React, { useEffect, useState } from 'react';
import { Row, Col, Spin } from 'antd';
import styled from 'styled-components';
import {
  CURRENCY_STOCK_DETAILS,
  AVAILABLE_IN_STORE,
  AVAILABLE_IN_MAIN_SAFE,
  AVAILABLE_IN_TILL,
} from '../constants';
import { useSelector } from 'react-redux';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import { currencyFormat } from 'utils/util';
import { isEmpty } from 'lodash';

const StyledRow = styled.div`
  background: var(--white);
  border: 1px dashed #c2cfe0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px;
  margin: 10px auto;
`;

const StyledCommonRow = styled(Row)`
  margin: 10px auto;
`;

const StyledDots = styled.div`
  width: 6px;
  height: 6px;
  background: var(--light-gray);
  margin-right: 5px;
  border-radius: 100%;
`;

const StyledSpan = styled.span`
  font-weight: 400;
`;

const StyledSpin = styled(Spin)`
  position: absolute;
  left: 50%;
`;

const StockDetails = () => {
  const { currencyStockBalance, isStockBlanceLoading, currencySymbol } =
    useSelector((state: any) => state.tm);
  const [totalTillBalance, setTotalTillBalance] = useState<number>(0);

  useEffect(() => {
    if (!isEmpty(currencyStockBalance)) {
      setTotalTillBalance(
        currencyStockBalance?.tillBalance +
          currencyStockBalance?.otherTillBalance
      );
    }
    if (!currencySymbol.toCurrencySymbol) {
      setTotalTillBalance(0);
    }
  }, [currencyStockBalance]); // eslint-disable-line react-hooks/exhaustive-deps

  const commonRow = (balance = 0, title: string) => {
    return (
      <StyledCommonRow align="middle">
        <Col>
          <StyledDots />
        </Col>
        <Col>
          <span
            dangerouslySetInnerHTML={{
              __html: currencySymbol?.toCurrencySymbol,
            }}
          ></span>
          <StyledSpan>
            {currencyFormat(balance?.toFixed(2)) || '0.00'} {title}
          </StyledSpan>
        </Col>
      </StyledCommonRow>
    );
  };

  return (
    <>
      <StyledRow>
        <StyledSpin spinning={isStockBlanceLoading} />
        <div className="form-h4-title">
          <HelpPopoverInfo
            linkedID={'TRAVELMONEY_CurrencyStockDetails'}
            position="rightTop"
          >
            <span>{CURRENCY_STOCK_DETAILS}</span>
            <span>
              {currencyStockBalance?.currencyDescription
                ? `(${currencyStockBalance?.currencyDescription})`
                : ''}
            </span>
          </HelpPopoverInfo>
        </div>
        {commonRow(currencyStockBalance?.storeBalance, AVAILABLE_IN_STORE)}
        {commonRow(totalTillBalance, AVAILABLE_IN_TILL)}
        {commonRow(
          currencyStockBalance?.mainSafeBalance,
          AVAILABLE_IN_MAIN_SAFE
        )}
      </StyledRow>
    </>
  );
};

export default StockDetails;
