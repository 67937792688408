import React, { useState, useEffect } from 'react';
import { Col, Form, Input, Modal, Row } from 'antd';
import { Button } from 'components/common/Button';
import styled from 'styled-components';
import { ADD_COIN_LABELS, COIN_FROM } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  PLEDGE_ITEM_PRICE_REQUEST,
  UPLOAD_MULTIPLE_IMAGE_REQUEST,
} from 'action_creators/pledge';
import { coinActions } from 'redux/reducers/pawnbroking/coin';
import { pledgeActions } from 'redux/reducers/pawnbroking/index';
import isEmpty from 'lodash/isEmpty';
import { reducePledgeItems } from 'utils/util';
import Select from 'components/common/Select';

import CoinDetailsTable from './CoinDetailsTable';
import MultipleImageUploader from 'components/common/MultipleImageUploader';

const StyledModal = styled(Modal)`
  & .ant-row.ant-form-item.label-control {
    margin-block-end: 18px;
  }
`;

const StyledHeading = styled.div`
  margin: 5px 0;
  font-weight: var(--font-weight-500);
`;

const StyledHeadingImages = styled.div`
  margin: 5px 0;
  font-weight: var(--font-weight-500);
`;

interface CoinPopupProps {
  visible: boolean;
  setCoinPopupVisible: () => void;
  itemFixedAttributes: Array<any>;
  metalPrice?: object;
  coinEditRow?: CoinRowProps | any;
}
interface CoinTypeOptionProps {
  id?: number;
  description?: string;
  sortOrder?: number;
}

interface CoinRowProps {
  id?: any;
  coinType?: any;
  coinCondition?: number;
  coinYear?: number | string;
  quantity?: number | string;
}

enum FormButton {
  Add = 'Add',
  Update = 'Update',
}

enum CoinAttributeLabels {
  Type = 'Coin Type',
  Condition = 'Condition',
}

enum CoinAttributeNames {
  Type = 'coinType',
  Condition = 'coinCondition',
}

enum CoinAttributeTypes {
  Type = 'COINS',
  Condition = 'CONDITION',
}

const AddCoinPopup = ({
  visible,
  setCoinPopupVisible,
  itemFixedAttributes,
  metalPrice,
  coinEditRow,
}: CoinPopupProps) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    stone: { stonePrices },
    coin: {
      coinRowId,
      coinItemsTempData,
      coinAllItemsData,
      coinRemoveData,
      isCoinRowRemoved,
      isCoinRowEdit,
      coinEditData,
    },
    pawnbroking: { itemCategory, itemImageCollectionResponse },
    valuationCenter: { isValuationItemToPledge },
  } = useSelector((state: any) => {
    return {
      stone: state.stone,
      coin: state.coin,
      pawnbroking: state.pawnbroking,
      valuationCenter: state.valuationCenter,
    };
  });

  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [clearSelection, setClearSelection] = useState<boolean>(false);

  const onCoinPopupCancelClick = () => {
    dispatch(coinActions.coinRowId(coinRowId - coinItemsTempData.length));
    if (isCoinRowRemoved)
      dispatch(coinActions.coinAllItems([...coinRemoveData]));
    if (isCoinRowEdit) dispatch(coinActions.coinAllItems([...coinEditData]));
    else dispatch(coinActions.coinItemsTemp([]));
    setCoinPopupVisible();
    setClearSelection(false);
  };

  const onCoinPopupSubmitClick = () => {
    const data = [...coinAllItemsData, ...coinItemsTempData];
    const coinItems = reducePledgeItems(data);

    const params = {
      categoryId: itemCategory?.id,
      metalPrice,
      coinPrices: coinItems,
      stonePrices,
    };

    dispatch({
      type: PLEDGE_ITEM_PRICE_REQUEST,
      payload: { params: params },
    });
    dispatch(
      coinActions.coinAllItems([...coinAllItemsData, ...coinItemsTempData])
    );
    dispatch(coinActions.coinItemsPrice(params.coinPrices));
    setCoinPopupVisible();
    setClearSelection(false);
  };

  const onFormSubmit = () => {
    dispatch(coinActions.coinRowId(coinRowId + 1));
    const obj: object = {
      id: coinRowId,
      coinType: JSON.parse(form.getFieldValue('coinType')),
      coinCondition: form.getFieldValue('coinCondition'),
      coinYear: form.getFieldValue('coinYear'),
      quantity: +form.getFieldValue('quantity'),
      coinFrom: COIN_FROM.ADD_COIN,
    };

    dispatch(coinActions.coinItemsTemp([...coinItemsTempData, obj]));
    form.resetFields();
    setClearSelection(false);
  };

  const onFormEdit = () => {
    const id = form.getFieldValue('id');
    const tempIndex = coinItemsTempData.findIndex((x: any) => x.id === id);
    const allIndex = coinAllItemsData.findIndex((x: any) => x.id === id);
    const isIndexEdit = allIndex !== -1 ? true : false;
    const coinData: object = {
      id: id,
      coinType: JSON.parse(form.getFieldValue('coinType')),
      coinCondition: form.getFieldValue('coinCondition'),
      coinYear: form.getFieldValue('coinYear'),
      quantity: +form.getFieldValue('quantity'),
      coinFrom: COIN_FROM.ADD_COIN,
    };

    dispatch(
      coinActions.editCoinRow({
        index: isIndexEdit ? allIndex : tempIndex,
        coinData: coinData,
        isIndexEdit: isIndexEdit,
      })
    );
    setEditMode(false);
    form.resetFields();
    setClearSelection(true);
  };

  const onCancelClick = () => {
    setEditMode(false);
    form.resetFields();
    setClearSelection(true);
  };

  const getCoinRowData = () => {
    return [...coinAllItemsData, ...coinItemsTempData];
  };

  const removeCoinRow = (row: CoinRowProps) => {
    dispatch(coinActions.removeCoinRow(row));
    if (form.getFieldValue('id') === row.id) {
      onCancelClick();
    }
  };

  const onClickCoinRow = (row: any) => {
    setClearSelection(false);
    setEditMode(true);
    getCoinEditDetails(row);
  };

  const getCoinEditDetails = (coinEditRow: CoinRowProps) => {
    const { id, coinCondition, coinYear, quantity } = coinEditRow;
    let { coinType } = coinEditRow;

    coinType = JSON.stringify({
      id: coinType.id,
      description: coinType.description,
      sortOrder: coinType.sortOrder,
    });

    form.setFieldsValue({
      id,
      coinType,
      coinCondition,
      coinYear,
      quantity,
    });
  };

  useEffect(() => {
    if (!isEmpty(coinEditRow)) {
      setEditMode(true);
      getCoinEditDetails(coinEditRow);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const uploadImageHandler = (image: any) => {
    dispatch({
      type: UPLOAD_MULTIPLE_IMAGE_REQUEST,
      payload: { image, isDefault: false },
    });
  };

  const updateImageList = (imageList: any) => {
    const params = {
      ...itemImageCollectionResponse,
      images: imageList,
    };
    dispatch(pledgeActions.setItemImageCollectionResponse(params));
  };

  const getCoinAttributes = (item: string) => {
    return itemFixedAttributes.filter((x) => item === x.description);
  };

  const CoinSelectOptions = (
    label: string,
    name: string,
    itemOptions?: any
  ) => {
    return (
      <Row>
        <Col span={24}>
          <Form.Item
            label={`${label}*`}
            name={name}
            className="label-control"
            rules={[{ required: true, message: `${label} is required` }]}
          >
            <Select
              options={
                itemOptions &&
                itemOptions[0]?.itemFixedAttributes.map(
                  (el: CoinTypeOptionProps) => {
                    return {
                      key: el.id,
                      value:
                        name === CoinAttributeNames.Condition
                          ? el.id
                          : JSON.stringify(el),
                      label: el.description,
                    };
                  }
                )
              }
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <StyledModal
        title={ADD_COIN_LABELS.TITLE}
        open={visible}
        width={1500}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={onCoinPopupCancelClick}
        onOk={onCoinPopupSubmitClick}
        centered
        footer={[
          <Row gutter={24} justify="end" key="stoneFooter">
            <Col>
              <Button
                itemProp="secondary"
                key="back"
                onClick={onCoinPopupCancelClick}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                key="submit"
                form="form"
                htmlType="submit"
                onClick={onCoinPopupSubmitClick}
                disabled={
                  coinItemsTempData?.length === 0 &&
                  !isCoinRowRemoved &&
                  !isCoinRowEdit
                }
              >
                Submit
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Row>
          <Col span={6}>
            <Row align="top">
              <Col span={21}>
                <Form
                  onFinish={!isEditMode ? onFormSubmit : onFormEdit}
                  layout="vertical"
                  form={form}
                  disabled={
                    isValuationItemToPledge ||
                    ((coinItemsTempData?.length > 0 ||
                      coinAllItemsData?.length > 0) &&
                      !isEditMode)
                  }
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item label="id" name="id" noStyle>
                        <Input type="hidden" />
                      </Form.Item>
                    </Col>
                  </Row>
                  {CoinSelectOptions(
                    CoinAttributeLabels.Type,
                    CoinAttributeNames.Type,
                    getCoinAttributes(CoinAttributeTypes.Type)
                  )}
                  {CoinSelectOptions(
                    CoinAttributeLabels.Condition,
                    CoinAttributeNames.Condition,
                    getCoinAttributes(CoinAttributeTypes.Condition)
                  )}
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Year*"
                        name="coinYear"
                        className="label-control"
                        rules={[
                          { required: true, message: 'Coin Year is required' },
                          {
                            pattern: new RegExp('^[0-9]{4}$'),
                            message: 'Invalid Year',
                          },
                        ]}
                      >
                        <Input className="input-control" autoComplete="off" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Quantity*"
                        name="quantity"
                        className="label-control"
                        rules={[
                          {
                            required: true,
                            message: 'Quantity is required',
                          },
                          {
                            pattern: new RegExp('^0*[1-9][0-9]*$'),
                            message:
                              'Quantity should be a number and greater than 0',
                          },
                        ]}
                      >
                        <Input className="input-control" autoComplete="off" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24} justify="end">
                    <Col>
                      <Button
                        itemProp="secondary"
                        key="back"
                        onClick={onCancelClick}
                      >
                        Clear
                      </Button>
                    </Col>
                    <Col>
                      <Button type="primary" htmlType="submit">
                        {!isEditMode ? FormButton.Add : FormButton.Update}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col span={18}>
            <Row>
              <Col span={24}>
                <StyledHeadingImages>
                  {ADD_COIN_LABELS.IMAGES}
                </StyledHeadingImages>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <MultipleImageUploader
                  columns={6}
                  imageList={itemImageCollectionResponse?.images}
                  uploadImageRequest={uploadImageHandler}
                  updateImageList={updateImageList}
                  viewOnly={isValuationItemToPledge}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <StyledHeading>{ADD_COIN_LABELS.COIN_DETAILS}</StyledHeading>
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                className="stone-details-table-wrapper"
                style={{ height: '232px' }}
              >
                <CoinDetailsTable
                  coinRow={getCoinRowData()}
                  removeCoinRow={removeCoinRow}
                  onClickCoinRow={onClickCoinRow}
                  clearSelection={clearSelection} // for clearing the selection on actions
                  coinEditRow={coinEditRow} // for selection on popup open
                  conditionAttributes={getCoinAttributes(
                    CoinAttributeTypes.Condition
                  )}
                  isValuationItemToPledge={isValuationItemToPledge}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledModal>
    </>
  );
};

export default AddCoinPopup;
