import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { GRID_NO_URL } from 'globalConstants';
import { Layout, Row, Col } from 'antd';
import {
  VIEW_AUCTION_DETAILS_TITLE,
  VIEW_AUCTION_READY_FOR_AUCTION_TITLE,
  AUCTION_PRINT_FOR,
  AUCTION_PRINT_TYPE,
  TABLE_HEAD_COLOR,
  CONFIRM_PASS_CONTENT,
  CONFIRM_PASS_LABEL,
  REASON_TEXT_PLACEHOLDER,
  BUTTON_LABELS,
} from 'components/pledgemanagement/constants';
import ReadyForAuctionSummary from '../auctionDetailsSummary/readyForAuctionSummary';
import VirtualGrid from 'components/common/virtualGrid/index';
import { Button } from 'components/common/Button';
import {
  PRINT_PASS_LIST,
  PASS_AUCTION_LOT,
  GET_AUCTION_DETAILS,
} from 'action_creators/pledgeManagement';
import ControlledAlert from 'components/common/ControlledAlert';
import { actions } from 'redux/reducers/pledgeManagement';
import TextArea from 'components/common/TextArea';
import "./auctionDetails.less";

const StyledContentLayout = styled(Layout)`
  background: var(--white);
  padding: 15px 30px;
  border-radius: 5px;
`;

const StyledDetailsRow = styled(Row)`
  padding-bottom: 10px;
`;

const StyledTableWrapper = styled.div`
  height: calc(100vh - 407px);
`;

const StyledBottomRow = styled(Row)`
  padding: 15px 30px;
  background: var(--white);
  border: 0.6px solid var(--linkwater);
  border-top: none;
  box-shadow: rgba(1, 78, 169, 0.2) 0px 4px 10px;
  border-radius: 0px 0px 5px 5px;
  box-shadow: none;
  border: 0;
  border-top: 1px solid var(--linkwater);
`;

const StyledButton = styled(Button)`
  margin: 0 15px;
`;

const StyledAlertText = styled(Row)`
  color: var(--red);
  padding: 10px 0 15px 0;
`;

const StyledHeadText = styled(Row)`
  font-size: var(--font-size-20);
  white-space: initial;
  font-weight: var(--font-weight-500);
`;

interface ReadyForAuctionDetailsProps {
  onExit: () => void;
}
const ReadyForAuctionDetails = ({ onExit }: ReadyForAuctionDetailsProps) => {
  const {
    pledgeManagement: { auctionItemDetails, successPassAuctionLot },
  } = useSelector((state: any) => {
    return {
      pledgeManagement: state.pledgeManagement,
    };
  });

  const { auctionLoans, ...auctionDetailsTable } = auctionItemDetails;

  const [columns] = useState([
    {
      name: 'auctionDate',
      title: 'Auction Date',
      getCellValue: (row: any) => row?.auctionDate,
    },
    {
      name: 'bookDate',
      title: 'Book Date',
      getCellValue: (row: any) => row?.bookDate,
    },
    {
      name: 'collectionDate',
      title: 'Collection Date',
      getCellValue: (row: any) => row?.collectionDate,
    },
    {
      name: 'lastMailDate',
      title: 'Last Mail Sent',
      getCellValue: (row: any) => row?.lastMailDate,
    },
    {
      name: 'reserveValue',
      title: 'Reserve Value',
      getCellValue: (row: any) => row?.reserveValue,
    },
    {
      name: 'numberOfLots',
      title: 'Number of Lots',
      getCellValue: (row: any) => row?.numberOfLots,
    },
    {
      name: 'auctionStatusText',
      title: 'Status',
      getCellValue: (row: any) => row?.auctionStatusText,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'auctionDate', width: '14%' },
    { columnName: 'bookDate', width: '14%' },
    { columnName: 'collectionDate', width: '14%' },
    { columnName: 'lastMailDate', width: '14%' },
    { columnName: 'reserveValue', width: '14%' },
    { columnName: 'numberOfLots', width: '13%' },
    { columnName: 'auctionStatusText', width: '13%' },
  ]);

  const getRowId = (row: any) => row?.auctionId;
  const dispatch = useDispatch();
  const [isPassLoanConfirmVisible, setIsPassLoanConfirmVisible] =
    useState<boolean>(false);
  const [selectedRowObj, setSelectedRowObj] = useState<object>({});
  const [reasonText, setReasonText] = useState<string>('');
  const printPassList = (printType: number) => {
    dispatch({
      type: PRINT_PASS_LIST,
      payload: {
        auctionId: auctionDetailsTable?.auctionId,
        printFor: AUCTION_PRINT_FOR.LIVE_AT_AUCTION,
        printType: printType,
      },
    });
  };

  const onPassLoanClick = (selectedRowObj: any) => {
    dispatch({
      type: PASS_AUCTION_LOT,
      payload: {
        auctionId: selectedRowObj?.auctionId,
        auctionDetailId: selectedRowObj?.auctionDetailId,
        reasonText: reasonText,
      },
    });
    setIsPassLoanConfirmVisible(false);
  };

  const passConfirmMsgString = (selectedRowObj: any) => {
    return (
      <>
        <StyledHeadText>
          Pass lot {selectedRowObj?.lotNumber} (Fellows Lot no.{' '}
          {selectedRowObj?.followsLotNumber}),
        </StyledHeadText>
        <StyledHeadText>
          agreement {selectedRowObj?.loanNumber} from auction?
        </StyledHeadText>
        <StyledAlertText>{CONFIRM_PASS_CONTENT}</StyledAlertText>
        <StyledAlertText>{CONFIRM_PASS_LABEL}</StyledAlertText>
        <TextArea
          rows={3}
          placeholder={REASON_TEXT_PLACEHOLDER}
          maxLength={200}
          onBlur={(e: any) => {
            setReasonText(e?.target?.value);
          }}
          containerClassName='textarea-container'
        />
      </>
    );
  };

  useEffect(() => {
    if (successPassAuctionLot) {
      dispatch(actions.initLoading(true));
      dispatch(actions.setSuccessPassAuctionLot(false));
      dispatch({
        type: GET_AUCTION_DETAILS,
        payload: {
          auctionId: auctionDetailsTable?.auctionId,
          auctionStatus: auctionDetailsTable?.auctionStatus,
        },
      });
    }
  }, [successPassAuctionLot, auctionDetailsTable, dispatch]);

  return (
    <>
      <StyledContentLayout>
        <Row className="title-row">
          <Col span={24}>
            {VIEW_AUCTION_DETAILS_TITLE}
            {VIEW_AUCTION_READY_FOR_AUCTION_TITLE}
          </Col>
        </Row>
        <StyledDetailsRow>
          <VirtualGrid
            urlType={GRID_NO_URL}
            columnsProps={columns}
            tableColumnExtensionsProps={tableColumnExtensions}
            rowData={[auctionDetailsTable]}
            getRowId={getRowId}
            headerBackground={TABLE_HEAD_COLOR.ACTIVE}
          />
        </StyledDetailsRow>
        <StyledTableWrapper>
          <ReadyForAuctionSummary
            setIsPassLoanConfirmVisible={setIsPassLoanConfirmVisible}
            setSelectedRowObj={setSelectedRowObj}
            auctionLoans={auctionLoans || []}
          />
        </StyledTableWrapper>
      </StyledContentLayout>

      <Row>
        <Col span={24}>
          <StyledBottomRow>
            <Col span={16}>
              <Row align="bottom" justify="start">
                <StyledButton
                  onClick={onExit}
                  itemProp="secondary"
                  style={{ marginLeft: 0 }}
                >
                  {BUTTON_LABELS.BACK}
                </StyledButton>
                <StyledButton
                  itemProp="secondary"
                  onClick={() => printPassList(AUCTION_PRINT_TYPE.PASSES)}
                >
                  {BUTTON_LABELS.PRINT_PASSES}
                </StyledButton>
                <StyledButton
                  itemProp="secondary"
                  onClick={() => printPassList(AUCTION_PRINT_TYPE.AUCTION_BOOK)}
                >
                  {BUTTON_LABELS.PRINT_AUCTION_BOOK}
                </StyledButton>
              </Row>
            </Col>
            <Col span={8}>
              <Row align="bottom" justify="end">
                <StyledButton itemProp="secondary">
                  {BUTTON_LABELS.SEND_TO_AUCTION_HOUSE}
                </StyledButton>
              </Row>
            </Col>
          </StyledBottomRow>
        </Col>
      </Row>

      {isPassLoanConfirmVisible && (
        <ControlledAlert
          closable={true}
          visible={isPassLoanConfirmVisible}
          isCancelBtn={true}
          cancelButtonText={BUTTON_LABELS.NO}
          onCancel={() => setIsPassLoanConfirmVisible(false)}
          alertMessage={''}
          message={passConfirmMsgString(selectedRowObj)}
          onClick={() => onPassLoanClick(selectedRowObj)}
          yesButtonText={BUTTON_LABELS.YES}
        />
      )}
    </>
  );
};

export default ReadyForAuctionDetails;
