import * as urls from '../apiUrls';
import { doRequest } from 'utils/request';

const getBasketByBasketId = (id) => {
  return doRequest(`${urls.GET_BASKET_BY_BASKETID}/${id}`, 'get');
};

const postCheckout = (id, params) => {
  return doRequest(
    `${urls.GET_CHECKOUT_WITH_AUTH}?basketId=${id}`,
    'post',
    params
  );
};

const downloadContract = (loannumber) => {
  return doRequest(
    `${urls.DOWNLOAD_CONTRACT}/${loannumber}`,
    'get',
    '',
    'application/json',
    false,
    'arraybuffer'
  );
};

//new-api for downloading pre-contracts for pledge, renewal, paydown
const downloadPreContracts = (params) => {
  return doRequest(
    `${urls.DOWNLOAD_PRE_CONTRACT}`,
    'post',
    params,
    'application/json',
    false,
    'arraybuffer'
  );
};

//new-api for downloading pre-contracts for pledge, renewal, paydown
const downloadCheckCashingContracts = (params) => {
  return doRequest(
    `${urls.DOWNLOAD_CHECKCASHING_CONTRACT}/${params}`,
    'get',
    '',
    'application/json',
    false,
    'arraybuffer'
  );
};

const downloadAffidavit = (params) => {
  return doRequest(
    `${urls.DOWNLOAD_AFFIDAVIT}`,
    'post',
    params,
    'application/json',
    false,
    'arraybuffer'
  );
};

const generateCollectionSlip = (id) => {
  return doRequest(`${urls.GENERATE_COLLECTIONSLIP}/${id}`,
  'get', '',
  'application/json',
    false,
    'arraybuffer'
    );
}

export {
  getBasketByBasketId,
  postCheckout,
  downloadContract,
  downloadPreContracts,
  downloadCheckCashingContracts,
  downloadAffidavit,
  generateCollectionSlip
};
