import React from 'react';
import { GreenTickBankTransferCompleted } from 'components/common/GreenTick';
import styled from 'styled-components';
import { BANK_TRANSFER_STATUS, PAYMENT_SUCCESS_MESSAGE } from '../constants';

const styledDiv = styled.div`
  font-weight: 600;
  font-size: 26px;
  padding-top: 20px;
  text-align: center;
`;
const StyledSuccessDiv = styled(styledDiv)`
  color: var(--mountain-meadow);
`;

const WarningDiv = styled(styledDiv)`
  color: var(--red);
`;

const StyledMsgDiv = styled.div`
  font-weight: 500;
  font-size: 20px;
  color: var(--black);
  text-align: center;
  padding-bottom: 20px;
`;

const WarningMsgDiv = styled(StyledMsgDiv)`
  color: var(--red);
  padding-bottom: 5px;
`;

export const BankTransactionCompleted = () => {
  return (
    <GreenTickBankTransferCompleted classes="mb-12">
      <StyledSuccessDiv className="bold pt-5">
        {PAYMENT_SUCCESS_MESSAGE.BANK_TRANSFER}
      </StyledSuccessDiv>
      <StyledSuccessDiv className="bold pt-2">
        {PAYMENT_SUCCESS_MESSAGE.REQUEST_COMPLETED}
      </StyledSuccessDiv>
      <StyledMsgDiv>
        {PAYMENT_SUCCESS_MESSAGE.AMOUNT_VISIBLE_IN_2_HOURS}
      </StyledMsgDiv>
    </GreenTickBankTransferCompleted>
  );
};

export const BankTransactionSendToLSCCompleted = ({
  bankTransferStatus = null,
}: any) => {
  return (
    <>
      <WarningDiv>{PAYMENT_SUCCESS_MESSAGE.BANK_TRANSFER_REQUEST}</WarningDiv>
      <WarningMsgDiv>
        {PAYMENT_SUCCESS_MESSAGE.SEND_TO_SUPPORT_CENTER}
      </WarningMsgDiv>
      {bankTransferStatus === BANK_TRANSFER_STATUS.PENDING ? (
        <StyledMsgDiv>{PAYMENT_SUCCESS_MESSAGE.LSC_MSG_PENDING}</StyledMsgDiv>
      ) : null}
    </>
  );
};

export const TransactionCompleted = () => {
  return (
    <>
      <StyledSuccessDiv>
        {PAYMENT_SUCCESS_MESSAGE.TRANSACTION_SUCCESSFULL}
      </StyledSuccessDiv>
      <StyledMsgDiv>{PAYMENT_SUCCESS_MESSAGE.PAYMENT_COMPLETE}</StyledMsgDiv>
    </>
  );
};
