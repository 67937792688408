import React, { useState, useEffect } from 'react';
import VirtualGrid from 'components/common/virtualGrid/index';
import { URL_TYPE, FNS_DATE_FORMAT } from 'globalConstants';

import { useSelector } from 'react-redux';
import ImageLoader from 'components/common/ImageLoader';
import { currencyFormat } from 'utils/util';
import format from 'date-fns/format';

interface FindSoldItemSummaryProps {
  parentCallback: any;
  onRowChange: (row: object) => void;
}

const FindSoldItemSummary = ({ onRowChange }: FindSoldItemSummaryProps) => {
  const { retailItems } = useSelector((state: any) => state.retail);

  const itemDescription = (row: any) => {
    return {
      title: row.description,
      anchor: row.description,
      placement: 'bottom',
      itemId: row.stockItemId,
      data: {},
    };
  };

  const [columns] = useState([
    {
      name: ' ',
      title: ' ',
      type: 'radio',
      getCellValue: (row: any) => row.stockItemId,
      onClick: (row: any) => {
        onRowChange(row);
      },
      onChange: (row: object) => {
        onRowChange(row);
      },
    },
    {
      name: 'image',
      title: 'Image',
      getCellValue: (row: any) => (
        <ImageLoader
          imageUrl={`/api/items/image/${row.stockItemId}`}
          classes={'item-photo-img'}
          fallbackImg={'no_image_thumbnail.svg'}
          borderRadius={'5%'}
          preview
        />
      ),
      allowSorting: false,
    },
    {
      name: 'inventoryType',
      title: 'Inventory Type',
      getCellValue: (row: any) => row.inventoryType,
    },
    {
      name: 'barcode',
      title: 'Barcode',
      getCellValue: (row: any) => row.barcode,
    },

    {
      name: 'description',
      title: 'Description',
      type: 'popover',
      getCellValue: (row: any) => itemDescription(row),
    },
    {
      name: 'store',
      title: 'Store',
      allowSorting: true,
      getCellValue: (row: any) => row?.store,
    },
    {
      name: 'metalType',
      title: 'Metal Type',
      getCellValue: (row: any) => row?.metalType,
    },
    {
      name: 'fineness',
      title: 'Fineness',
      getCellValue: (row: any) => row?.fineness,
    },
    {
      name: 'weight',
      title: 'Weight',
      getCellValue: (row: any) =>
        row?.weight > 0 ? row?.weight?.toFixed(2) + 'g' : row?.weight,
    },

    {
      name: 'soldValue',
      title: 'Sold Value',
      getCellValue: (row: any) =>
        '£' + currencyFormat(row?.soldValue?.toFixed(2)),
    },
    {
      name: 'paymentMethod',
      title: 'Payment Method',
      getCellValue: (row: any) => row?.paymentType,
    },
    {
      name: 'soldDate',
      title: 'Sold Date',
      getCellValue: (row: any) =>
        format(new Date(row?.dateSold), FNS_DATE_FORMAT),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: ' ', width: 45 },
    { columnName: 'image', width: 65 },
    { columnName: 'inventoryType' },
    { columnName: 'barcode' },
    { columnName: 'description', width: 120 },
    { columnName: 'store' },
    { columnName: 'metalType' },
    { columnName: 'fineness', width: 80, align: 'right' },
    { columnName: 'weight', width: 90, align: 'right' },
    { columnName: 'soldValue', width: 115, align: 'right' },
    { columnName: 'paymentMethod', width: 130, align: 'right' },
    { columnName: 'soldDate', width: 100 },
  ]);
  const [formattedColumns] = useState([' ', 'description']);
  const [sortingStateColumnExtensions] = useState([
    { columnName: 'store', sortingEnabled: true },
  ]);

  const [isComponentMounted, setIsComponentMounted] = useState(false);
  useEffect(() => {
    setIsComponentMounted(true);
  }, []);

  return isComponentMounted ? (
    <>
      <VirtualGrid
        formattedColumns={formattedColumns}
        urlType={URL_TYPE.NO_URL}
        virtualScroll={true}
        columnsProps={columns}
        tableColumnExtensionsProps={tableColumnExtensions}
        sortingStateColumnExtensions={sortingStateColumnExtensions}
        rowData={retailItems}
      />
    </>
  ) : null;
};

export default FindSoldItemSummary;
