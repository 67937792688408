import React, { useCallback, useState } from 'react';
import VirtualGrid, {
  PatchedIntegratedSelection,
  PatchedTableSelection,
} from 'components/common/virtualGrid/index';
import { URL_TYPE } from 'globalConstants';
import { currencyFormat } from 'utils/util';
import { Row, Col, Space, Spin, Button } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import ImageLoader from 'components/common/ImageLoader';
import ModalHeaderStrip from './ModalHeaderStrip';
import ModalFooterStrip from './ModalFooterStrip';
import { dateDisplayFormat } from 'utils/util';
import styled from 'styled-components';
import { ACCOUNT_SHORTFALL, ACCOUNT_SURPLUS } from '../constants';

const LeftAlignedCol = styled(Col)`
  text-align: left;
`;
const RightAlignedCol = styled(Col)`
  text-align: right;
`;

const StyledSpin = styled(Spin)`
  display: block;
  position: relative;
  align-items: center;
  top: calc(100% - 75%);
`;

const rowSelectionEnabled = (row: any) => {
  return !row.purchasedOn;
};

function LayawayItemsTable({
  accountShortfall,
  rowData,
  isLoading,
  totalAmount,
  totalRecordCount,
  onSelection,
  onDeleteItem,
}: any) {
  const [columns] = useState([
    {
      name: 'createdOn',
      title: 'Date',
      getCellValue: (row: any) => {
        return <span>{dateDisplayFormat(row?.createdOn)}</span>;
      },
    },
    {
      name: 'barcode',
      title: 'Barcode',
      getCellValue: (row: any) => row.barcode,
    },
    {
      name: ' ',
      title: ' ',
      getCellValue: (row: any) => {
        return (
          <ImageLoader
            imageUrl={`/api/items/image/${row?.itemId}`}
            key={`img-${row?.barcode}`}
            classes={'item-photo-img'}
            fallbackImg={'no_image_thumbnail.svg'}
            borderRadius={'5%'}
            preview
          />
        );
      },
    },
    {
      name: 'description',
      title: 'Description',
      getCellValue: (row: any) => row?.description,
    },
    {
      name: 'listValue',
      title: 'List Value',
      getCellValue: (row: any) => row?.listValue,
      type: 'currency',
    },
    {
      name: 'purchasedOn',
      title: 'Purchased',
      getCellValue: (row: any) => dateDisplayFormat(row?.purchasedOn),
    },
    {
      name: 'refundedOn',
      title: 'Refunded',
      getCellValue: (row: any) => dateDisplayFormat(row?.refundedOn),
    },
    {
      name: 'paidAmount',
      title: 'Paid',
      getCellValue: (row: any) => row?.paidAmount,
      type: 'currency',
    },
    {
      name: '',
      title: '',
      getCellValue: (row: any) => {
        return (
          <Button
            type="text"
            key={`btn-${row?.barcode}`}
            icon={<DeleteFilled />}
            title="Delete Item"
            shape="circle"
            size="middle"
            className="btn-delete"
            disabled={row?.purchasedOn}
            onClick={(event: any) => onDeleteItem(row?.itemId, event)}
          />
        );
      },
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'createdOn', width: '11%', align: 'left' },
    { columnName: 'barcode', width: '9%', align: 'left' },
    { columnName: ' ', align: 'right', width: '70px' },
    {
      columnName: 'description',
      width: '20%',
      wordWrapEnabled: true,
    },
    { columnName: 'listValue', width: '10%', align: 'right' },
    { columnName: 'purchasedOn', width: '11%', align: 'left' },
    { columnName: 'refundedOn', width: '10%', align: 'left' },
    { columnName: 'paidAmount', width: '10%', align: 'right' },
    { columnName: '', width: '50px', align: 'right' },
  ]);
  const formattedColumns = ['listValue', 'paidAmount'];

  const onSelectionChange = useCallback(
    (row: any) => {
      onSelection(row);
    },
    [onSelection]
  );

  const shortfallOrSurplusLable =
    accountShortfall < 0 ? ACCOUNT_SURPLUS : ACCOUNT_SHORTFALL;

  return (
    <div className="layaway-item-table">
      <ModalHeaderStrip heading="Layaway Items" />
      {isLoading ? (
        <StyledSpin />
      ) : (
        <VirtualGrid
          rowData={rowData || []}
          formattedColumns={formattedColumns}
          urlType={URL_TYPE.NO_URL}
          onSelectionChange={onSelectionChange}
          columnsProps={columns}
          displayContentWithImage={true}
          tableColumnExtensionsProps={tableColumnExtensions}
          customSelectionRequired={true}
          customTableSelection={(restProps: any) => (
            <PatchedTableSelection
              showSelectAll
              rowSelectionEnabled={rowSelectionEnabled}
              {...restProps}
            />
          )}
          customIntegratedSelection={(restProps: any) => (
            <PatchedIntegratedSelection {...restProps} />
          )}
        />
      )}
      <ModalFooterStrip>
        <Row>
          <LeftAlignedCol span={12}>
            <Space align="start" size="middle">
              {`${totalRecordCount} Item(s), List Value: ${currencyFormat(
                totalAmount,
                true
              )}`}
            </Space>
          </LeftAlignedCol>
          <RightAlignedCol span={12}>
            <Space size="middle">
              {`${shortfallOrSurplusLable}:  ${currencyFormat(
                Math.abs(accountShortfall),
                true
              )}`}
            </Space>
          </RightAlignedCol>
        </Row>
      </ModalFooterStrip>
    </div>
  );
}

export default LayawayItemsTable;
