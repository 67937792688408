import * as chequeCashingActions from 'action_creators/chequeCashing';

import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  postPreAuthentication,
  uploadChequeCashingDoc,
} from 'services/chequeCashing';

import { UPLOAD_CHEQUE_CASHING_DOCUMENT_REQUEST } from 'action_creators/chequeCashing';
import { actions } from 'redux/reducers/chequeCashing';
import { notification } from 'antd';
import { GENERIC_ERROR } from 'globalConstants';

/**
 * this saga handles the Post pre authentication
 * (1) Post preauthentication data
 * (2) handle response
 */

export function* postPreAuthenticationSaga(action) {
  try {
    const { payload } = action;
    yield put(actions.setPreAuthLoading(true));

    const response = yield call(postPreAuthentication, payload);
    if (response?.data) {
      yield put(actions.setPreAuthLoading(false));
      yield put(actions.setPreAuthStatusCode(response?.data?.preAuthResult));
      yield put(actions.setPreAuthText(response?.data?.preAuthText));
      yield put(
        actions.setPreAuthErrorsList(
          response?.data?.thirdPartyChequePreAuthErrorsList
        )
      );
    } else yield put(actions.setError(response?.errors));
  } catch (e) {
    yield put(actions.setPreAuthLoading(false));
    notification.error({
      message: GENERIC_ERROR,
      duration: 5,
    });
  }
}

export function* uploadChequeCashingDocument(action) {
  try {
    const {
      payload: { image },
    } = action;

    const { documentsImages } = yield select((state) => state.chequeCashing);

    yield put(actions.initDocumentUpload(true));
    const response = yield call(uploadChequeCashingDoc, image);

    if (response?.data) {
      yield put(actions.initDocumentUpload(false));
      yield put(
        actions.setDocumentsImages([...documentsImages, response?.data])
      );
    }
  } catch (e) {
    yield put(actions.initDocumentUpload(false));
    notification.error({ message: 'Unable to upload image.', duration: 5 });
  }
}

export function* editChequeCashingSaga(action) {
  try {
    const {
      payload: { params, partPayment = false },
    } = action;

    const { itemDocumentCollections } = params;

    if (partPayment) yield put(actions.setPaymentDataIndex(true));

    //cheque amount fields
    yield put(
      actions.setApprovedAmount(partPayment ? params?.retained : params?.payout)
    );
    yield put(actions.setRetainedAmount(partPayment ? 0 : params?.retained));
    yield put(actions.setRequestedAmount(params?.amount - params?.totalFee));

    //attached documemts
    yield put(actions.setDocumentsImages(itemDocumentCollections?.documents));

    //preAuthorization states
    yield put(actions.setPreAuthStatusCode(params?.preAuthorization));
    yield put(
      actions.setPreAuthText(params?.preAuthText || params?.authorisation)
    );
    yield put(
      actions.setPreAuthErrorsList(params?.thirdPartyChequePreAuthErrorsList)
    );

    yield put(actions.setChequeItemDetails(params));
  } catch (e) {/* continue regardless of error */}
}

export function* watchEditChequeCashingSaga() {
  yield takeEvery(
    chequeCashingActions.EDIT_CHEQUE_CASHING_ITEM,
    editChequeCashingSaga
  );
}

export function* watchUploadChequeCashingDocumentSaga() {
  yield takeEvery(
    UPLOAD_CHEQUE_CASHING_DOCUMENT_REQUEST,
    uploadChequeCashingDocument
  );
}

export function* watchPostPreAuthentication() {
  yield takeLatest(
    chequeCashingActions.POST_PRE_AUTHENTICATION,
    postPreAuthenticationSaga
  );
}
