import React, { useState, useReducer, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Card } from 'antd';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash';

import { joinValues, removeHTMLTags } from 'utils/util';
import { searchCustomers } from 'services/customer';
import {
  VirtualTableState,
  IntegratedSorting,
  SortingState,
  DataTypeProvider,
  Sorting,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { Button } from 'components/common/Button';
import { PlusOutlined } from '@ant-design/icons';
import {
  customerReducer,
  initialState,
  UPDATE_ROWS,
  START_LOADING,
  REQUEST_ERROR,
  FETCH_INIT,
  UPDATE_QUERY,
} from './reducer/index';
import isEmpty from 'lodash/isEmpty';
import CustomerPopup from '../CustomerPopup';
import { CustomerPopupProps } from 'components/interface/CustomerPopUp';
import './CustomerList.less';
import { parse, compareAsc } from 'date-fns';
import ImageLoader from 'components/common/ImageLoader';
import { actions } from 'redux/reducers/customer';
import { useDispatch } from 'react-redux';
import GdprMessagePopup from '../GdprMessagePopup';
import GdprDeclinePopup from '../GdprDeclinePopup';
import {
  ROUTE_CONFIG,
  BASKET_STATUS,
  FNS_DATE_FORMAT,
  RESPONSE_STATUS,
} from 'globalConstants';

import { useNavigate } from 'react-router-dom';
import { RECALL_MSG, RECALL_TITLE } from 'components/customer/constants';
import RecallPopup from 'components/common/recall';
import { CUSTOMER_PROFILE_REQUEST } from 'action_creators/customer';
import { getBasket } from 'services/basket';
import HelpPopoverInfo from 'components/common/HelpPopoverInfo';
import StatusIconDescription from '../customerStatusIcons';

interface CustomerListProps {
  searchParam?: any;
  resetSearch?: any;
  serviceType?: any;
}

const StyledRowContainer = styled(Row)`
  padding: 0px 0 15px 0;
`;

const StyledGridContainer = styled(Row)`
  height: 64vh;
`;

const StyledHelpSpan = styled.span`
  float: right;
  height: 42px;
`;

const getRowId = (row: any) => row.customerId;
const buildQueryString = (skip: number, take: number) =>
  `skip=${skip}&take=${take}`;
const Root = (props: any) => (
  <Grid.Root className="RootBase-root" {...props} style={{ height: '100%' }} />
);

const ProfilePic = ({ value }: any) => (
  <ImageLoader
    customerId={value}
    imageUrl={`/api/customers/images/${value}`}
    classes={'mx-small-avatar'}
    fallbackImg={'customer-pic.svg'}
    borderRadius={'50%'}
    customPreview={true}
    statusIcon
  />
);

const ProfilePicProvider = (props: any) => (
  <DataTypeProvider formatterComponent={ProfilePic} {...props} />
);

const TableRow = ({
  row,
  serviceType,
  setRecallPopupVisible,
  setRecallMessage,
  setRecallTitle,
  ...restProps
}: any) => {
  const [selectedCustomer, setSelectedCustomer] =
    useState<CustomerPopupProps>();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClickTableRow = () => {
    setSelectedCustomer(row);
  };

  const onCustomerPopupCancel = () => {
    setSelectedCustomer(undefined);
    if (serviceType) dispatch(actions.setRedirectToOtherPage(false));
  };

  const onCustomerPopupOk = async (customer: any) => {
    if (serviceType) {
      const customerClone = cloneDeep(customer);

      customerClone.firstNames = removeHTMLTags(customer.firstNames);
      customerClone.surname = removeHTMLTags(customer.surname);
      customerClone.postcode = removeHTMLTags(customer?.postcode);
      customerClone.emailAddress = removeHTMLTags(customer?.emailAddress);
      dispatch(actions.setRedirectToOtherPage(false));
      dispatch(actions.getCustomerResponse(customerClone)); //customer session
    } else {
      try {
        const response = await getBasket(customer?.customerId);
        if (response?.data) {
          if (response?.data?.basket?.basketId !== null) {
            const customerId = customer?.customerId;
            dispatch({
              type: CUSTOMER_PROFILE_REQUEST,
              payload: { customerId },
            });
            setRecallPopupVisible(true);
            if (
              response?.data?.basket?.basketStatus === BASKET_STATUS.PENDING
            ) {
              setRecallMessage(RECALL_MSG.START_BASKET_JOURNEY);
              setRecallTitle(RECALL_TITLE.BASKET);
            } else if (
              response?.data?.basket?.basketStatus ===
                BASKET_STATUS.INITIALISED ||
              response?.data?.basket?.basketStatus ===
                BASKET_STATUS.SUCCESSFUL ||
              response?.data?.basket?.basketStatus === BASKET_STATUS.FAILED
            ) {
              setRecallMessage(RECALL_MSG.START_CHECKOUT_JOURNEY);
              setRecallTitle(RECALL_TITLE.BASKET);
            }
          } else redirectToCustomerSummary(customer);
        } else if (response?.status === RESPONSE_STATUS.NO_CONTENT)
          redirectToCustomerSummary(customer);
      } catch (e: any) {
        redirectToCustomerSummary(customer);
      }
    }
  };

  const redirectToCustomerSummary = (customer: any) => {
    dispatch(actions.initSurplusCheck(true));
    navigate(
      `/${ROUTE_CONFIG.CUSTOMER}/${ROUTE_CONFIG.SUMMARY}/${customer?.customerId}`
    );
  };

  const Cell = () => {
    const { column } = restProps;
    if (column.name === '') {
      return (
        <Table.Cell
          {...restProps}
          style={{
            cursor: 'pointer',
          }}
        />
      );
    }
    return (
      <Table.Cell
        onClick={onClickTableRow}
        {...restProps}
        style={{
          cursor: 'pointer',
        }}
      />
    );
  };

  return (
    <>
      {Cell()}
      {!isEmpty(selectedCustomer) && (
        <CustomerPopup
          customer={selectedCustomer}
          onCustomerPopupCancel={onCustomerPopupCancel}
          onCustomerPopupOk={onCustomerPopupOk}
        />
      )}
    </>
  );
};

const CustomerList = ({
  searchParam,
  resetSearch,
  serviceType,
}: CustomerListProps) => {
  const [state, dispatch] = useReducer(customerReducer, initialState);
  const dispatchCustomer = useDispatch();
  const [isGdprMessageVisible, setGdprMessageVisible] =
    useState<boolean>(false);
  const [isGdprDeclineVisible, setGdprDeclineVisible] =
    useState<boolean>(false);
  const [isRecallPopupVisible, setRecallPopupVisible] =
    useState<boolean>(false);
  const [recallMessage, setRecallMessage] = useState<string>('');
  const [recallTitle, setRecallTitle] = useState<string>('');

  const [columns] = useState([
    {
      name: '',
      title: '',
      getCellValue: (row: any) => row.customerId,
    },
    {
      name: 'firstNames',
      title: 'First Name',
      getCellValue: (row: any) => row.firstNames,
    },
    {
      name: 'surname',
      title: 'Last Name',
      getCellValue: (row: any) => row.surname,
    },
    {
      name: 'dateOfBirth',
      title: 'Date of Birth',
      getCellValue: (row: any) =>
        format(new Date(row.dateOfBirth), FNS_DATE_FORMAT),
    },
    {
      name: 'street',
      title: 'Address',
      getCellValue: (row: any) =>
        joinValues([row.property, row.street, row.town, row.county]),
    },
    {
      name: 'postcode',
      title: 'Postcode',
      getCellValue: (row: any) => row.postcode,
    },
    {
      name: 'mobile',
      title: 'Mobile No',
      getCellValue: (row: any) => row.mobile,
    },
    {
      name: 'emailAddress',
      title: 'Email',
      getCellValue: (row: any) => row.emailAddress,
    },
    {
      name: 'customerNumber',
      title: 'Customer No',
      getCellValue: (row: any) => row.customerId,
    },
  ]);
  const [tableColumnExtensions] = useState([
    { columnName: '', width: 80 },
    { columnName: 'firstNames', width: 130 },
    { columnName: 'surname', width: 130 },
    { columnName: 'dateOfBirth', width: 150 },
    { columnName: 'street', width: 320 },
    { columnName: 'postcode', width: 120 },
    { columnName: 'mobile', width: 120 },
    { columnName: 'emailAddress', width: 250 },
    { columnName: 'customerNumber', width: 140 },
  ]);
  const [firstNamesColumn] = useState(['']);

  const compareDate = (a: any, b: any) => {
    const dateA = parse(a, FNS_DATE_FORMAT, new Date());
    const dateB = parse(b, FNS_DATE_FORMAT, new Date());
    return compareAsc(dateA, dateB);
  };

  const [integratedSortingColumnExtensions] = useState([
    { columnName: 'dateOfBirth', compare: compareDate },
  ]);

  const getRemoteRows = (requestedSkip: number, take: number) => {
    dispatch({ type: START_LOADING, payload: { requestedSkip, take } });
  };

  const [sorting, setSorting] = useState<Sorting[]>([]);

  const loadData = async () => {
    const { requestedSkip, take, lastQuery, loading } = state;

    const query = buildQueryString(requestedSkip, take);
    if (query !== lastQuery && !loading) {
      try {
        dispatch({ type: FETCH_INIT, payload: query });
        const response = await searchCustomers(
          searchParam,
          false,
          requestedSkip,
          take
        );
        dispatch({
          type: UPDATE_ROWS,
          payload: {
            skip: requestedSkip,
            rows: response?.data?.customers,
            totalCount: response?.data?.totalCount,
          },
        });
      } catch (e: any) {
        dispatch({ type: REQUEST_ERROR, payload: query });
      }
      dispatch({ type: UPDATE_QUERY, payload: query });
    }
  };

  useEffect(() => {
    loadData();
  });

  const onAddCustomerClick = () => {
    dispatchCustomer(actions.clearAll({}));
    setGdprMessageVisible(true);
  };

  const { rows, skip, totalCount, loading, pageSize } = state;

  const onSortingChange = (newColumnsSort: any) => {
    let indexToRemove = -1;
    newColumnsSort.forEach((column: any, index: number) => {
      const columnToCheck = sorting.filter(
        (c) => c.columnName === column.columnName
      );
      if (
        columnToCheck.length > 0 &&
        columnToCheck[0].direction !== column.direction &&
        column.direction === 'asc'
      ) {
        indexToRemove = index;
      }
    });
    if (indexToRemove > -1) newColumnsSort.splice(indexToRemove, 1);
    setSorting(newColumnsSort);
  };

  return (
    <>
      <Card className="control-box customer-list-wrapper">
        <StyledRowContainer align="middle">
          <Col span={8} className="mx-h1-normal">
            Customer Search
          </Col>
          <Col span={8} offset={8}>
            <StyledHelpSpan>
              <HelpPopoverInfo
                linkedID={'CUSTOMERSEARCH_ADDCUSTOMER'}
                position="rightTop"
                attachedComp="BUTTON"
              ></HelpPopoverInfo>
              <Button
                key="submit"
                onClick={onAddCustomerClick}
                style={{
                  borderRadius: '100px',
                  float: 'right',
                  fontWeight: 500,
                }}
              >
                <PlusOutlined />
                Add Customer
              </Button>
            </StyledHelpSpan>
          </Col>
          <Col span={1} offset={1}></Col>
        </StyledRowContainer>
        <div className="customer-list-div">
          <Grid
            rows={rows}
            columns={columns}
            getRowId={getRowId}
            rootComponent={Root}
          >
            <ProfilePicProvider for={firstNamesColumn} />
            <SortingState
              sorting={sorting}
              onSortingChange={onSortingChange}
            ></SortingState>
            <VirtualTableState
              infiniteScrolling
              loading={loading}
              totalRowCount={totalCount}
              pageSize={pageSize}
              skip={skip}
              getRows={getRemoteRows}
            />

            <IntegratedSorting
              columnExtensions={integratedSortingColumnExtensions}
            />
            <VirtualTable
              columnExtensions={tableColumnExtensions}
              cellComponent={(restProps: any) => (
                <TableRow
                  serviceType={serviceType}
                  setRecallPopupVisible={setRecallPopupVisible}
                  setRecallMessage={setRecallMessage}
                  setRecallTitle={setRecallTitle}
                  {...restProps}
                />
              )}
            />
            <TableHeaderRow showSortingControls />
          </Grid>
        </div>
        <StatusIconDescription />
      </Card>
      {isGdprMessageVisible && (
        <GdprMessagePopup
          visible={isGdprMessageVisible}
          setGdprMessageVisible={() => setGdprMessageVisible(false)}
          setGdprDeclineVisible={(visibility: boolean) =>
            setGdprDeclineVisible(visibility)
          }
          serviceType={serviceType}
        />
      )}
      {isGdprDeclineVisible && (
        <GdprDeclinePopup
          visible={isGdprDeclineVisible}
          setGdprDeclineVisible={(visibility: boolean) =>
            setGdprDeclineVisible(visibility)
          }
          resetSearch={resetSearch}
        />
      )}
      {isRecallPopupVisible && (
        <RecallPopup
          visible={isRecallPopupVisible}
          setRecallPopupVisible={(visibility: boolean) =>
            setRecallPopupVisible(visibility)
          }
          recallMessage={recallMessage}
          recallTitle={recallTitle}
        />
      )}
    </>
  );
};

export default CustomerList;
