import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Modal, Row, Form, Input, notification } from 'antd';
import styled from 'styled-components';
import { CASH_TRANSFER } from '../constants';
import {
  postCashTransfer,
  postFXTransfer,
  generateCashTransferSlip,
  generateCashTransferSlipFx,
  getCurrencies,
  getStoreUsers,
} from 'services/cashManagement';
import { actions as cashManagementActions } from 'redux/reducers/cashManagement';
import { Button } from 'components/common/Button';
import InputDecimal from 'components/common/InputDecimal';
import Select from 'components/common/Select';
import { sendToPrinter } from 'services/user';
import { cacheStaticData, cacheStaticDataWithArgs } from 'utils/cacheServices';

const StyledModal = styled(Modal)`
  & .ant-modal-header {
    background: var(--white);
    & .ant-modal-title {
      padding-bottom: 20px;
    }
  }
  & .ant-modal-body {
    background: var(--white);
    padding: 0;
  }
`;

interface CashTransferPopupProps {
  visible: boolean;
  setCashTransferPopupVisible: () => void;
}

const CashTransferPopup = ({
  visible,
  setCashTransferPopupVisible,
}: CashTransferPopupProps) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { selectedStore, user } = useSelector((state: any) => state.user);

  const [storeUsersList, setStoreUsersList] = useState<Array<any>>([]);
  const [currencyList, setCurrencyList] = useState<Array<any>>([]);
  const [isFXMode, setFXMode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [amountSymbol, setAmountSymbol] = useState<string>('&#163;');

  useEffect(() => {
    getStoreUsersForTill();
    getStoreCurrency();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getStoreUsersForTill = async () => {
    try {
      const response = await cacheStaticDataWithArgs(
        `STORE_USER-${selectedStore?.storeId}`,
        getStoreUsers,
        selectedStore?.storeId
      );
      if (response?.data) setStoreUsersList(response?.data);
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.error,
        duration: 3,
      });
      console.log(e);
    }
  };

  const getStoreCurrency = async () => {
    try {
      const response = await cacheStaticData(getCurrencies);
      if (response) setCurrencyList(response.data);
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.error,
        duration: 3,
      });
      console.log(e);
    }
  };

  const saveCashTransfer = async (payload: any, authorisationHeaders: any) => {
    try {
      const response = await postCashTransfer(payload, authorisationHeaders);
      if (response?.data?.cashTransferId) {
        dispatch(cashManagementActions.setUserTillUpdated(1));
        try {
          const pdf = await generateCashTransferSlip(
            response?.data?.cashTransferId,
            authorisationHeaders
          );
          if (pdf?.data) {
            sendToPrinter(pdf, 'printreceipt', '_CashTransferSlip');
          }
        } catch (e: any) {
          notification.error({
            message: e?.response?.data?.error,
            duration: 3,
          });
        }

        notification.success({
          message: CASH_TRANSFER.NOTIFICATION.SUCCESS,
          duration: 3,
        });
        setCashTransferPopupVisible();
      }
    } catch (e: any) {
      setIsLoading(false);
      notification.error({
        message: e?.response?.data?.error,
        duration: 3,
      });
      console.log(e);
    }
  };

  const saveFXTransfer = async (payload: any, authorisationHeaders: any) => {
    try {
      const response = await postFXTransfer(payload, authorisationHeaders);
      if (response?.data?.fxInternalTransferId) {
        try {
          const pdf = await generateCashTransferSlipFx(
            response?.data?.fxInternalTransferId,
            authorisationHeaders
          );
          if (pdf?.data) {
            sendToPrinter(pdf, 'printreceipt', '_CashTransferSlipFX');
          }
        } catch (e: any) {
          notification.error({
            message: e?.response?.data?.error,
            duration: 3,
          });
        }
        notification.success({
          message: CASH_TRANSFER.NOTIFICATION.SUCCESS,
          duration: 3,
        });
        setCashTransferPopupVisible();
      }
    } catch (e: any) {
      setIsLoading(false);
      notification.error({
        message: e?.response?.data?.error,
        duration: 3,
      });
      console.log(e);
    }
  };

  const validateFromUserId = (_: any, value: any) => {
    const toUserId = form.getFieldValue('toUserId');

    if (value === toUserId)
      return Promise.reject(new Error(CASH_TRANSFER.ERROR_MSG.FROM.SAME_USER));
    return Promise.resolve();
  };

  const validateToUserId = (_: any, value: any) => {
    const toUserId = form.getFieldValue('fromUserId');

    if (value === toUserId)
      return Promise.reject(new Error(CASH_TRANSFER.ERROR_MSG.TO.SAME_USER));
    return Promise.resolve();
  };

  const onCurrencyChange = (value: string) => {
    form.setFieldsValue({ currencyCode: value });
    const isFX = value !== 'GBP' ? true : false;
    setFXMode(isFX);
    const symbol = currencyList.find((x: any) => x.currencyCode === value);
    setAmountSymbol(symbol.currencySymbol);
  };

  const onCancelClick = () => {
    setCashTransferPopupVisible();
  };

  const onConfirmClick = () => {
    try {
      const payload = {
        storeId: selectedStore?.storeId,
        currencyCode: form.getFieldValue('currencyCode'),
        userId: user?.userId,
        fromUserId: form.getFieldValue('fromUserId'),
        toUserId: form.getFieldValue('toUserId'),
        amount: +form.getFieldValue('amount'),
      };

      const authorisationHeaders = {
        authorisationUserName: form.getFieldValue('authorisationUserId'),
        authorisationPassword: form.getFieldValue('authorisationPassword'),
      };

      setIsLoading(true);
      if (!isFXMode) saveCashTransfer(payload, authorisationHeaders);
      else saveFXTransfer(payload, authorisationHeaders);
    } catch (errorInfo) {
      setIsLoading(false);
      console.log(errorInfo);
    }
  };

  return (
    <>
      <StyledModal
        title="Cash Transfer"
        open={visible}
        width={480}
        centered
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelClick}
        footer={[
          <Row gutter={24} justify="end" key="cashTransfer">
            <Col>
              <Button
                itemProp="secondary"
                key="cancelCashTransfer"
                onClick={onCancelClick}
              >
                Close
              </Button>
            </Col>
            <Col>
              <Button
                form="cashTransferForm"
                key="saveCashTransfer"
                loading={isLoading}
                htmlType="submit"
              >
                Save
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Row style={{ padding: '30px' }}>
          <Col span={24}>
            <Form
              id="cashTransferForm"
              onFinish={onConfirmClick}
              layout="vertical"
              form={form}
              initialValues={{
                currencyCode: 'GBP',
              }}
              className="cash-transfer-form"
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={CASH_TRANSFER.FROM}
                    name="fromUserId"
                    className="label-control"
                    rules={[
                      { validator: validateFromUserId },
                      {
                        required: true,
                        message: CASH_TRANSFER.ERROR_MSG.FROM.REQUIRED,
                      },
                    ]}
                  >
                    <Select
                      options={storeUsersList.map((item: any) => {
                        return {
                          value: item.userId,
                          label: item.displayName,
                        };
                      })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={CASH_TRANSFER.TO}
                    name="toUserId"
                    className="label-control"
                    rules={[
                      { validator: validateToUserId },
                      {
                        required: true,
                        message: CASH_TRANSFER.ERROR_MSG.TO.REQUIRED,
                      },
                    ]}
                  >
                    <Select
                      options={storeUsersList.map((item: any) => {
                        return {
                          value: item.userId,
                          label: item.displayName,
                        };
                      })}
                      data-testid="user-list"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={CASH_TRANSFER.CURRENCY}
                    name="currencyCode"
                    className="label-control"
                    rules={[
                      {
                        required: true,
                        message: CASH_TRANSFER.ERROR_MSG.CURRENCY,
                      },
                    ]}
                  >
                    <Select
                      onChange={onCurrencyChange}
                      options={currencyList.map((item: any) => {
                        return {
                          value: item.currencyCode,
                          label: `${item?.currencyDescription} (${item.currencyCode})`,
                        };
                      })}
                      data-testid="currency-list"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={CASH_TRANSFER.AMOUNT}
                    name="amount"
                    className="label-control cash-delivery-amount-input"
                    rules={[
                      {
                        required: true,
                        message: CASH_TRANSFER.ERROR_MSG.AMOUNT,
                      },
                    ]}
                  >
                    <InputDecimal
                      placeholder={false}
                      className="input-control amount-to-pay-input"
                      addonBefore={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: amountSymbol ? amountSymbol : ' ',
                          }}
                        ></span>
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={CASH_TRANSFER.EMPLOYEE_ID}
                    name="authorisationUserId"
                    className="label-control"
                    rules={[
                      {
                        required: true,
                        message: CASH_TRANSFER.ERROR_MSG.EMPLOYEE_ID,
                      },
                    ]}
                  >
                    <Input
                      className="input-control"
                      autoComplete="new-password"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={CASH_TRANSFER.PASSWORD}
                    name="authorisationPassword"
                    className="label-control"
                    rules={[
                      {
                        required: true,
                        message: CASH_TRANSFER.ERROR_MSG.PASSWORD,
                      },
                    ]}
                  >
                    <Input
                      className="input-control"
                      type="password"
                      autoComplete="new-password"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </StyledModal>
    </>
  );
};

export default CashTransferPopup;
