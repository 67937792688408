import React from 'react';
import { Plugin, Getter } from '@devexpress/dx-react-core';
import { IntegratedSelection } from '@devexpress/dx-react-grid';
import { Table, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { Checkbox } from 'antd';

export const PatchedIntegratedSelection = ({ ...restProps }: any) => {
  return (
    <Plugin>
      <IntegratedSelection {...restProps} />
      <Getter
        name="rows"
        computed={({ rows }: any) => {
          return rows;
        }}
      />
    </Plugin>
  );
};

export const PatchedTableSelection = ({
  showSelectAll,
  rowSelectionEnabled,
  ...restProps
}: any) => {
  return (
    <TableSelection
      cellComponent={(props) =>
        rowSelectionEnabled(props.tableRow.row) ? (
          <TableSelection.Cell {...props} checked={true} />
        ) : (
          <Table.StubCell {...props}>
            <Checkbox {...props} disabled className="disbled-checkbox" />
          </Table.StubCell>
        )
      }
      {...restProps}
    />
  );
};
