export const CUSTOMER_HEADER_COLUMNS = {
  CUSTOMER_NAME: 'Customer Name',
  DATE_OF_BIRTH: 'Date of Birth',
  ADDRESS: 'Address',
  POST_CODE: 'Postcode',
  MOBILE_NUMBER: 'Mobile No.',
};

export const CLICK_HERE_REGISTER_MESSAGE = `Click here to Register Customer`;
export const SORRY_MESSAGE = `Sorry we couldn't find any matches for`;

export const LOGOFFTIME = 60 * 10;
export const LOGOFFALERTTIME = 60;

export const CASH_MANAGEMENT_ALERT = `Please 'Exit and Clear' or 'Exit and Save' customers journey and then open Cash/Inventory/Pledge management`;

export const SAR_FORM_LINK = `https://docs.google.com/forms/d/e/1FAIpQLSdTTk0BrU9QJ-JE7o2AN2e6UvoDQrtc2YvJbOmaNDpiy-QNIg/viewform`;

export const RECALL_MENU_LABELS = {
  TITLE: 'Recall Session',
  SEARCH_BY: 'Search by',
  RESTORE: 'Restore',
  CANCEL: 'Cancel',
  TABLE_HEADERS: {
    SERVICE_TYPE: 'Service Type',
    AMOUNT: 'Amount',
    EXPIRES: 'Expires',
  },
  ERROR_MSG:
    'Please complete the current customers committed journey before recalling from the guest recall',
  MERGE_BASKET_ERROR:
    'The customer already has a committed transaction within the checkout. You will be directed to complete the customers journey before commencing any other services. The current basket will be saved to the guest recall.',
};

export const DAILY_INTEREST_TABS = {
  DAILY_INTEREST: 'Daily Interest',
  APR: 'APR',
  DUE: 'Due',
  MONTHLY_INTEREST_RATE: 'Monthly Interest Rate',
};

export const ACCESSORIES_COMMON_MODAL = {
  INITIAL_PANES: [
    { title: 'Daily Interest Calculator', width: 300 },
    { title: 'Metal Pricing' },
    { title: 'Stone Size Calculator', width: 340 },
  ],
};

export const ACCESSORIES_LINKS = {
  DAILY_INTEREST_CALCULATOR: 'Daily Interest Calculator',
  INTEREST_RATE_BANDS: 'Interest Rate Bands',
  METAL_PRICING: 'Metal Pricing',
  SUSPICIOUS_ACTIVITY_REPORT: 'Suspicious Activity Report',
  CUSTOMER_THIRD_PARTY_CHEQUE_MANDATE: 'Customer Third Party Cheque Mandate',
  CLEAN_MONEY: 'Print Certificate of Clean Money',
  UNDER_75_AFFIDAVIT: 'Under £75 Affidavit',
  OVER_75_AFFIDAVIT: 'Over £75 Affidavit',
  STONE_SIZE_CALCULATOR: 'Stone Size Calculator',
  TELEPHONE_LIST: 'Telephone List',
};

export const AFFIDAVIT_TYPE = {
  UNDER_75_AFFIDAVIT: '3GBP',
  OVER_75_AFFIDAVIT: '5GBP',
};

export const AFFIDAVIT_TO_PRINTER = `Affidavit has been sent to the printer successfully.`;
export const CUSTOMER_MANDATE_TO_PRINTER = `Customer Third Party Cheque Mandate has been sent to the printer successfully.`;

export const TELEPHONE_LIST_TABS = {
  SEARCH_BY_STORE: 'Search by Store',
  SEARCH_BY_COLLEAGUE: 'Search by Colleague',
};

export const STONE_SIZE_CALCULATION_EXAMPLE =
  ' e.g. : (Average Diameter) x (Average Diameter) x Depth x 0.0061=ct';

export const SORTED_INDEX_METALPRICE_LIST = [
  '9 CT',
  '14 CT',
  '18 CT',
  '21 CT',
  '22 CT',
  'PLATINUM',
  'PALLADIUM',
  'SILVER',
];

export const METALTYPE = {
  SILVER: 'SILVER',
};
