import React, { useState } from 'react';
import { Col, Row } from 'antd';
import ModalFooterStrip from './ModalFooterStrip';
import ModalHeaderStrip from './ModalHeaderStrip';
import VirtualGrid from 'components/common/virtualGrid';
import { currencyFormat, capitalize, dateDisplayFormat } from 'utils/util';
import { URL_TYPE } from 'globalConstants';

type AccountTransactionProps = {
  rowData: [];
  availableBalance: number;
};

function AccountTransaction({
  rowData,
  availableBalance = 0,
}: AccountTransactionProps) {
  const [columns] = useState([
    {
      name: 'transactionDateAndTime',
      title: 'Date',
      getCellValue: (row: any) => {
        return (
          <>
            <span>{dateDisplayFormat(row?.transactionDateAndTime)}</span>
          </>
        );
      },
    },
    {
      name: 'amount',
      title: 'Amount',
      getCellValue: (row: any) => row?.amount,
      type: 'currency',
    },
    {
      name: 'type',
      title: 'Type',
      getCellValue: (row: any) => capitalize(row.type),
    },
  ]);

  const formattedColumns = ['amount'];

  const [tableColumnExtensions] = useState([
    { columnName: 'transactionDateAndTime', width: '32%', align: 'left' },
    { columnName: 'amount', width: '30%', align: 'right' },
    { columnName: 'type', width: '38%', align: 'left' },
  ]);

  return (
    <div className="account-transaction-container">
      <ModalHeaderStrip heading="Account Transactions" />
      <VirtualGrid
        rowData={rowData}
        urlType={URL_TYPE.NO_URL}
        formattedColumns={formattedColumns}
        columnsProps={columns}
        displayContentWithImage={true}
        tableColumnExtensionsProps={tableColumnExtensions}
      />
      <ModalFooterStrip className="border-left border-right border-bottom ml-m1  footer-strip-width">
        <Row>
          <Col span={24}>
            Account Balance: {currencyFormat(availableBalance, true)}
          </Col>
        </Row>
      </ModalFooterStrip>
    </div>
  );
}

export default AccountTransaction;
